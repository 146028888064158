import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableRow, Button } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import TableHeader from '../../Commons/components/TableHeader';
import NavBar from '../../../components/NavBar';
import apiCall, {
  getWareHouse, naCustomerOrdersPath, naGeneratePath,
  naListProductsItemsPath, naReGeneratePath,
  naResetPath, naSendNotificationsPath,
} from '../../../api/NetworkHandler';
import SearchBar from '../../Commons/containers/SearchBar';
import Colors from '../../../utils/Colors';
import ConfirmationPopup from '../../Commons/components/ConfirmationPopup';

const COMPONENT_TITLE = 'Non Availability';

const productItemColumnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'cutOffTime',
    numeric: false,
    disablePadding: true,
    label: 'CutOff Time',
  },
  {
    id: 'unitMeasure',
    numeric: false,
    disablePadding: true,
    label: 'Unit Measure',
  },

  {
    id: 'shortage',
    numeric: false,
    disablePadding: true,
    label: 'Shortage',
  },
  {
    id: 'packaging',
    numeric: false,
    disablePadding: true,
    label: 'Packaging',
  },
];

const customerOrderColumnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Customer Order',
  },
  {
    id: 'customerName',
    numeric: false,
    disablePadding: true,
    label: 'Customer Name',
  },
  {
    id: 'productItem',
    numeric: false,
    disablePadding: true,
    label: 'Product Item',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: true,
    label: 'Quantity',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
];


const styles = {

  wrapper: {
    height: 400,
    overflowX: 'auto',
    border: `1px solid ${Colors.lightGrey1}`,
    padding: 10,
  },
  container: {
    display: 'flex1',
    justifyContent: 'center',
    flexDirection: 'row wrap',
    padding: 20,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  root: {
    padding: '0em 2em',
  },
  button: {
    margin: 5,
    marginRight: 10,
    marginLeft: 10,
  },
  tabs: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
};


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class NonAvailabilityComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      deliveryDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      warehouseId: '',
      productItems: [],
      customerOrders: [],
      warehouseList: [],
      open: false,
      orderLineItems: [],
      resetPopup: false,
    };
  }

  componentDidMount() {
    this.fetchAllWarehouse();
  }

  getCustomerOrders = async () => {
    const { warehouseId, deliveryDate } = this.state;
    const url = naCustomerOrdersPath(warehouseId, deliveryDate);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data: customerOrders = [] } } = response;
      let COLineItems = [];
      customerOrders.forEach((co) => {
        const { customerOrderLineItems: lineItems = [] } = co;
        lineItems.forEach((lineItem) => {
          COLineItems = [
            ...COLineItems,
            {
              ...lineItem,
              customerOrderId: co.id,
              customerName: co.customerName,
            },
          ];
        });
      });
      this.setState({
        customerOrders,
        orderLineItems: COLineItems,
      });
    } catch (e) {
      console.log('error');
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleReset = (value) => {
    this.setState({
      resetPopup: value,
    });
  }

  handleButton = (ButtonType) => {
    if (ButtonType === 'reset') {
      this.handleReset(true);
    } else if (ButtonType === 'generate') {
      this.fetchGenerate();
    } else if (ButtonType === 'regenerate') {
      this.fetchReGeneratePath();
    } else if (ButtonType === 'sms') {
      this.fetchSendNotificationsPath();
      this.setState({ open: false });
    }
  }

  handleClickOpen = (value) => {
    this.setState({
      open: value,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      productItems: [],
      customerOrders: [],
      [name]: value,
    }, () => {
      this.fetchAllProductItems();
      this.getCustomerOrders();
    });
  }

  fetchAllWarehouse = () => {
    apiCall.sendRequest('get', getWareHouse)
      .then((response) => {
        const { data: { _embedded } } = response;
        const { warehouse = [] } = _embedded;
        const [firstWarehouse = {}] = warehouse;
        this.setState({
          warehouseList: warehouse,
          warehouseId: firstWarehouse.id || '',
        }, () => {
          this.fetchAllProductItems();
          this.getCustomerOrders();
        });
      });
  }

  fetchAllProductItems = async () => {
    const { warehouseId, deliveryDate } = this.state;
    const url = naListProductsItemsPath(warehouseId, deliveryDate);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data } } = response;
      const productItems = data;
      this.setState({
        productItems,
      });
    } catch (e) {
      console.log('error');
    }
  }


fetchGenerate = async () => {
  const { warehouseId, deliveryDate } = this.state;
  const url = naGeneratePath(warehouseId, deliveryDate);
  try {
    await apiCall.sendRequest('post', url);
    this.fetchAllProductItems();
    this.getCustomerOrders();
  } catch (e) {
    console.log('error');
  }
}

fetchReGeneratePath = async () => {
  const { warehouseId, deliveryDate } = this.state;
  const url = naReGeneratePath(warehouseId, deliveryDate);
  try {
    await apiCall.sendRequest('post', url);
    this.fetchAllProductItems();
    this.getCustomerOrders();
  } catch (e) {
    console.log('error');
  }
}

fetchResetPath = async () => {
  const { warehouseId, deliveryDate } = this.state;
  const url = naResetPath(warehouseId, deliveryDate);
  try {
    await apiCall.sendRequest('post', url);
    this.handleReset(false);
    this.fetchAllProductItems();
    this.getCustomerOrders();
  } catch (e) {
    console.log('error');
  }
}

fetchSendNotificationsPath = async () => {
  const { warehouseId, deliveryDate } = this.state;
  const url = naSendNotificationsPath(warehouseId, deliveryDate);
  try {
    await apiCall.sendRequest('post', url);
  } catch (e) {
    console.log('error');
  }
}

renderCounter = () => {
  const { customerOrders = [], productItems = [], orderLineItems = [] } = this.state;
  return (
    <Grid container style={{ justifyContent: 'flex-end' }} spacing={16}>
      <Grid item >
        <Typography variant="body2" color="secondary">
                  Orders:&emsp;
          <Typography style={{ display: 'inline' }} variant="body2">
            {customerOrders.length}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="secondary">
                  Product Items:&emsp;
          <Typography style={{ display: 'inline' }} variant="body2">
            {productItems.length}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="secondary">
                  Line Items:&emsp;
          <Typography style={{ display: 'inline' }} variant="body2">
            {orderLineItems.length}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}

render() {
  const {
    value, productItems, customerOrders, deliveryDate,
    warehouseList, warehouseId, orderLineItems,
  } = this.state;
  return (
    <div>
      <NavBar />
      <SearchBar title={COMPONENT_TITLE}>
        <div style={styles.container}>
          <Grid container spacing={16}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={6} md={5}>
                  <TextField
                    id="date"
                    type="date"
                    label="Delivery Date"
                    name="deliveryDate"
                    value={deliveryDate}
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                          shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={5}>
                  <FormHelperText style={{ marginTop: 4 }}> Warehouse </FormHelperText>
                  <Select
                    name="warehouseId"
                    value={warehouseId}
                    onChange={this.handleChange}
                    fullWidth
                  >
                    {
                      warehouseList.map(warehouse => (
                        <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {
                    customerOrders && customerOrders.length <= 0 ?
                      <Button
                        value={value}
                        style={styles.button}
                        onClick={() => this.handleButton('generate')}
                        color="secondary"
                        variant="contained"
                      >Generate
                      </Button>
                    : null
                  }
              {customerOrders && customerOrders.length > 0 ?
                <div>
                  <Button
                    value={value}
                    style={styles.button}
                    onClick={() => this.handleButton('regenerate')}
                    color="secondary"
                    variant="contained"
                  >
                    Re-Generate
                  </Button>
                  <Button
                    value={value}
                    style={styles.button}
                    onClick={() => this.handleButton('reset')}
                    color="secondary"
                    variant="contained"
                  >Reset
                  </Button>
                  <Button
                    value={value}
                    style={styles.button}
                    onClick={() => this.handleClickOpen(true)}
                    color="secondary"
                    variant="contained"
                  >Send Notifications
                  </Button>
                </div>
                    : null
                }
            </Grid>
          </Grid>
          <ConfirmationPopup
            open={this.state.open}
            message="Are you sure to send notification ?"
            title="Send Notification"
            rightButton="Send"
            onCancel={() => this.handleClickOpen(false)}
            onSend={() => this.handleButton('sms')}
          />
          <ConfirmationPopup
            open={this.state.resetPopup}
            message="Are you sure you want to reset?"
            title="Reset"
            rightButton="Reset"
            onCancel={() => this.handleReset(false)}
            onSend={this.fetchResetPath}
          />
        </div>
      </SearchBar>
      <div style={styles.root}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <Tabs
              value={value}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.handleTabChange}
              style={styles.tabs}
            >
              <Tab label="Product Items" style={{ backgroundColor: Colors.grey[100] }} />
              {customerOrders && customerOrders.length > 0 ?
                <Tab label="Customer Orders " style={{ backgroundColor: Colors.grey[100] }} />
                 : null }
            </Tabs>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingRight: 16 }}>
            {this.renderCounter()}
          </Grid>
        </Grid>
        {value === 0 &&
        <TabContainer>
          {/* First Container  */}
          <div style={styles.wrapper}>
            <Table aria-labelledby="tableTitle">
              <TableHeader columns={productItemColumnData} />
              <TableBody >
                {productItems.map(item => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={item.id}
                    selected={productItems && productItems.id === item.id}
                    style={{ backgroundColor: Colors.white }}
                  >
                    <TableCell style={{ color: Colors.primary }} scope="row" >
                      {item.name}
                    </TableCell>
                    <TableCell scope="row" >{item.cutOffTime}</TableCell>
                    <TableCell scope="row" padding="none">{item.unitMeasure} {item.uom.code}</TableCell>
                    <TableCell scope="row" padding="none">{item.shortage}</TableCell>
                    <TableCell scope="row" padding="none">{item.packaging}</TableCell>
                  </TableRow>
                                ))}
              </TableBody>
            </Table>
          </div>

        </TabContainer>
            }
        {value === 1 &&
        <TabContainer>
          {/* Second Container  */}
          <div style={styles.wrapper}>
            <Table aria-labelledby="tableTitle">
              <TableHeader columns={customerOrderColumnData} />
              <TableBody>
                {orderLineItems.map(lineItem => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={lineItem.id}
                    selected={customerOrders && customerOrders.id === lineItem.id}
                  >
                    <TableCell style={{ color: Colors.primary }} scope="row" >
                      <Link to={`/edit-co/${lineItem.customerOrderId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {lineItem.customerOrderId}
                      </Link>
                    </TableCell>
                    <TableCell scope="row" >{lineItem.customerName}</TableCell>
                    <TableCell scope="row" padding="none">{lineItem.productName}</TableCell>
                    <TableCell scope="row" padding="none">{lineItem.quantity}</TableCell>
                    <TableCell scope="row" padding="none">{lineItem.status}</TableCell>
                  </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </TabContainer>}
      </div>
    </div>
  );
}
}

export default withRouter(NonAvailabilityComponent);
