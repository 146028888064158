import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import apiCall, { getValidPincodesPrefixes } from '../../../api/NetworkHandler';
import PincodeList from '../components/PincodeList';
import NavBar from '../../../components/NavBar';
import Colors from '../../../utils/Colors';


const Constants = {
  title: 'Pincodes',
};

const styles = {
    topBar: {
        display: "flex",
        backgroundColor: Colors.grey[100],
    },
    title: {
        width: "20%",
        height: '80px',
        paddingLeft: '2em',
        paddingTop: "30px",
    },
  }

var versionDict = {
  'pincodeRequestId': 0,
};
class PincodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pincodePrefixData: [],
    };
  }

  componentDidMount= () => {
    this.fetchValidPincodePrefixes();
  };

  fetchValidPincodePrefixes = async () => {
    try {
        versionDict.pincodeRequestId += 1;
        let prevRequestId = versionDict.pincodeRequestId;
        const response = await apiCall.sendRequest('get', getValidPincodesPrefixes);
        const { data: { data = [] } = {} } = response;
        if (prevRequestId == versionDict.pincodeRequestId) {
          this.setState({
            pincodePrefixData: data,
          });
        }
    } catch (err) {
      this.setState({
        pincodePrefixData: [],
      });
    }
  };

  handleRefresh = () => {
    this.fetchValidPincodePrefixes();
  };

  render() {
    const {
      pincodePrefixData
    } = this.state;

    return (
      <div>
        <NavBar />
        <div style={styles.topBar}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">{Constants.title}</Typography>
          </div>
        </div>
        <PincodeList
          pincodePrefixData={pincodePrefixData}
          onRefresh={this.handleRefresh}
        />
      </div>
    );
  }
}

export default withRouter(PincodeContainer);
