import React from 'react';
import CustomerAddressesComponent from '../Component/CustomerAddressesComponent';

class CustomerAddressesContainer extends React.Component {
  render() {
    return (
      <div>
        <CustomerAddressesComponent />
      </div>
    );
  }
}

export default CustomerAddressesContainer;
