import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchTagsByName } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchTagsByName(searchTerm))
  const { data: { _embedded } } = response;
  const { tags } = _embedded;

  return tags.map(tag => {
    return {
      value: tag.id,
      label: tag.tag,
    }
  })
}

function TagMultiSelector(props) {
  const { selected, onSelect } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isMulti={true}
    />
  )
}

TagMultiSelector.propTypes = {
  /**
   * value should be an array of objects [{value: String, label: String}, ...]
   */
  selected: PropTypes.arrayOf(PropTypes.object),
  
  /**
   * Triggered on option selection and get array of objects in parameter
   */
  onSelect: PropTypes.func.isRequired,
};

TagMultiSelector.defaultProps = {
  selected: null
};

export default TagMultiSelector;
