import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';

const styles = {
  div:{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center'
  },
  title:{
    flex: 1,
    marginLeft: '30px'
  },
  searchBar:{
    flex: 2,
  },
  calendar:{
    flex: 1,
    textAlign: 'center',
  },
  add:{
    flex: 1,
    textAlign: 'center',
  },
  searchContainer:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField:{
    flex: 5
  },
  clear:{
    flex: 1,
    marginLeft: '10px',
    color: '#f50057'
  }
};

function SearchBar(props) {
  const { classes, searchQuery, handleQueryChange, searchVendors, onClear } = props;
  return (
    <div style={styles.div}>
      <div style={styles.title}>
      <Typography variant="title" color="inherit">
        Vendors
      </Typography>
      </div>
      <div style={styles.searchBar}>
        <div style={styles.searchContainer}>
        <div style={styles.searchField}>
          <TextField
            id="input-with-icon-textfield"
            label="Quick Search"
            fullWidth
            value={searchQuery}
            onChange={handleQueryChange}
            onKeyPress={searchVendors}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          </div>
          <div style={styles.clear}>
            <Button color="secondary" variant="outlined" onClick={onClear}>Clear All</Button>
            {/* <Typography variant="body2" color="inherit">
              Clear All
            </Typography> */}
          </div>
        </div>
      </div>
      <div style={styles.add}>
        <Link to='/new-vendor' style={{ textDecoration: 'none' }}><Button variant="contained" size="large" color="secondary">Add Vendor</Button></Link>
      </div>
    </div>
  );
}

export default SearchBar;