import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import BASE_URL from '../../../api/config';
import apiCall, { getAllBrandsPath } from '../../../api/NetworkHandler';
import { Button } from '../../../ui';
import { default as ReactSelect } from 'react-select';
import ImageContainer from '../components/ImageContainer';
import { sortBy } from 'lodash';
import SectionMessage from '@atlaskit/section-message';
import TagMultiSelector from '../../../components/Tag/MultiSelector';

const styles = {
  root:{
    width:'35px',
    height:'31px'
  },
  label:{
    marginRight:'36px',
    marginLeft: '-7px'
  }
};

const productFlag =[{value:'organic',label:'Organic'},{value:"gourmet",label:'Gourmet'},{value:'breakfast',label:'Breakfast'},{value:'care',label:'Care'},{value:'onOffer',label:'Offer'}]

class ProductForm extends Component {
  constructor(props) {
    super(props);
    const { product } = this.props;
    this.state = {
      product,
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      productPatchData: {},
      invalidName: false,
      errorMessage: '',
      selectedTags: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { product } = nextProps;
    const { product: prevProduct } = prevState;
    return {
      product: {
        ...product,
        ...prevProduct,
      },
    };
  }

  updateImageUrls = () => {
    const { urls: prevUrls = [], product } = this.state;
    let { stagingProductImages = [] } = product;
    if (!stagingProductImages) {
      stagingProductImages = []
    }
    let urls = [];
    urls = prevUrls.map((url) => {
      const image = stagingProductImages.find(img => img.position === url.position);
      if (image && !url.imageUrl) return image;
      return {
        ...url,
        stagingProduct: product.id ? `${BASE_URL.BASE_URL}/api/v1/staging-products/${product.id}` : null,
      };
    });
    return urls;
  }

  handleInputChange = prop => (event) => {
    const { product } = this.state;
    const { value } = event.target;
    product[prop] = value;
    this.setState((prevState) => {
      const productPatchData = { ...prevState.productPatchData };
      productPatchData[prop] = value;
      return {
        product,
        productPatchData
      }
    })
  }

  handleInputCheckboxChange = name => event =>{
    const {product,productPatchData} = this.state
    product[name] = event.target.checked
    productPatchData[name] = event.target.checked;
    this.setState({product, productPatchData})
  }

  selectBrand = (brand) => {
    let { product, productPatchData } = this.state;
    if (!brand) {
      brand = {};
    }
    product = {
      ...product,
      brandId: brand.value,
      brandName: brand.label,
    };
    productPatchData = {
      ...productPatchData,
      brandId: brand.value,
      brandName: brand.label,
      brand: `${BASE_URL.BASE_URL}/api/v1/brands/${product.brandId}`,
    }
    this.setState({
      product,
      productPatchData,
    });
  }

  selectCategory = (category) => {
    let { product, productPatchData } = this.state;
    if (!category) {
      category = {};
    }
    product = {
      ...product,
      categoryId: category.value,
      categoryName: category.label,
    };
    productPatchData = {
      ...productPatchData,
      categoryId: category.value,
      categoryName: category.label,
      category: `${BASE_URL.BASE_URL}/api/v1/categories/${product.categoryId}`,
    };
    this.setState({
      product,
      productPatchData,
    });
  }

  validateForm = () => {
    const { name, brandName, categoryName } = this.state.product;
    let isFormValid = true;

    if (!name) {
      this.setState({ invalidName: true });
      isFormValid = false;
    } else {
      this.setState({ invalidName: false });
    }
    if (!brandName) {
      isFormValid = false;
    }
    if (!categoryName) {
      isFormValid = false;
    }

    return isFormValid;
  }

  saveProduct = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { productPatchData, product, urls } = this.state;
    const { onClose, onRefresh } = this.props;
    const stagingProductImages = urls.filter((url) => { if (url.imageUrl) return true; });
    if (stagingProductImages.length) {
      productPatchData.stagingProductImages = stagingProductImages;
    }
    try {
      productPatchData.newProductTags = product.newProductTags;
      await apiCall.sendRequest('patch', `/api/v1/staging-products/${product.id}`, productPatchData);
      onClose();
      onRefresh();
      this.clearState();
    } catch (e) {
      // error handling
      const { data } = e.response;
      const { message } = data;
      if(message){
        this.setState({ errorMessage: message });
      }else if(data && (typeof data == "string")){
        this.setState({ errorMessage: data });
      }
    }
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  handleAttrChange = (event, i, field) => {
    const { product, productPatchData } = this.state;
    const { value } = event.target;
    let { stagingProductAttributes = [] } = product;
    if (!stagingProductAttributes) {
      stagingProductAttributes = [];
    }
    const tempObj = stagingProductAttributes[i] || {};
    if (field === 'value') {
      tempObj.value = value;
    } else {
      tempObj.name = value;
    }
    stagingProductAttributes[i] = tempObj;
    this.setState({
      product: {
        ...product,
        stagingProductAttributes,
      },
      productPatchData: {
        ...productPatchData,
        stagingProductAttributes,
      }
    });
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  clearState = () => {
    const { onClear } = this.props;
    this.setState({
      product: {},
      productPatchData: {},
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
    });
    onClear();
  }

  brandOptions = () => {
    const searchText = '';
    apiCall.sendRequest('get', getAllBrandsPath(searchText))
      .then((response) => {
        const { data = {} } = response;
        const { _embedded = {} } = data;
        const { brands=[] } = _embedded;
        let brandList = brands.map(({ name, id }) => ({ label: name, value: id }));
        brandList = sortBy(brandList, ['label']);
        this.setState({
          brandOptions: brandList,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  categoryOptions = () => {
    const searchText = '';
    apiCall.sendRequest('get', `api/v1/categories/search/findCategories?query=${searchText}`)
      .then((response) => {
        const { categories = [] } = response.data._embedded;
        let categoryOptions = categories.map(({ name, id }) => ({ label: name, value: id }));
        categoryOptions = sortBy(categoryOptions, ['label']);
        this.setState({
          categoryOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setSelectedTags = () => {
    const { product } = this.state;
    const selectedTags = product.productTags.map((tag) => {
      return {
        value: tag.id,
        label: tag.tag,
      }
    })
    this.setState({
      selectedTags
    })
  }

  componentDidMount = () => {
    this.brandOptions();
    this.categoryOptions();
    this.setSelectedTags();
  }

  onTagSelect = (tags) => {
    let productTags = [];
    if (tags) {
      productTags = tags.map(tag => {
        return {
          tagId: tag.value,
          tag: tag.label,
        }
      })
    }

    this.setState((prevState) => {
      const { product, productPatchData } = prevState;

      return {
        selectedTags: tags,
        product: {
          ...product,
          productTags: productTags,
          newProductTags: productTags,
        },
        productPatchData: {
          ...productPatchData,
          newProductTags: productTags,
        }
      }
    })
  }

  render() {
    const { product = {}, brandOptions = [], categoryOptions = [], invalidName, errorMessage, selectedTags } = this.state;
    const {
      name,
      title,
      description,
      brandName,
      categoryName,
      stagingProductAttributes = [],
      tags,
      active,
      categoryId,
      brandId,
    } = product;
    const { onClose,classes } = this.props;
    const urls = this.updateImageUrls();
    let attributes = [{ name: '', value: '' }];
    if (stagingProductAttributes && stagingProductAttributes.length > 0) attributes = stagingProductAttributes;
    return (
      <Grid style={{ width: '50vw' }}>
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose(); this.clearState(); }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  Product Form
              </Typography>
              <Typography color="error" variant="caption">* required</Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose(); this.clearState(); }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={() => { this.saveProduct() }} variant="contained" color="secondary">
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        {errorMessage && <Row>
          <ColMod lg={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Basic Details
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <TextField
              label="Name"
              value={name}
              onChange={this.handleInputChange('name')}
              fullWidth
              required
              inputProps={{ readOnly: true }}
              error={invalidName}
              inputProps={{ readOnly: true }}
            />
          </ColMod>
          <ColMod lg={6}>
            <TextField
              value={title}
              onChange={this.handleInputChange('title')}
              label="Display Name"
              fullWidth
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <InputLabel error={!brandName} style={{ fontSize: '0.7em' }}>Brand<sup style={{ fontSize: '1.2em' }}>*</sup></InputLabel>
            <ReactSelect style={{ border: (!brandName ? '1px solid red' : '') }} value={brandId} options={brandOptions} onChange={(brand) => { this.selectBrand(brand) }} />
          </ColMod>
          <ColMod lg={6}>
            <InputLabel error={!categoryName} style={{ fontSize: '0.7em' }}>Category<sup style={{ fontSize: '1em' }}>*</sup></InputLabel>
            <ReactSelect style={{ border: (!categoryName ? '1px solid red' : '') }} value={categoryId} options={categoryOptions} onChange={(category) => { this.selectCategory(category) }} />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <InputLabel style={{ fontSize: '0.7em' }}>Tags</InputLabel>
            <TagMultiSelector 
              selected={selectedTags}
              onSelect={this.onTagSelect}
            />
          </ColMod>
          <ColMod lg={6}>
            <InputLabel style={{ fontSize: '0.7em' }}>Status</InputLabel>
            <RadioGroup onChange={this.handleInputChange('active')} value={active + ''} style={{ flexDirection: 'row' }}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={12}>
            <TextField
              value={description}
              onChange={this.handleInputChange('description')}
              label="Description"
              fullWidth
              multiline={true}
              rows={2}
              rowsMax={4}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={12}>
            {
              productFlag.map((item,key) => (
                <FormControlLabel
                  key={key}
                  className = {classes.label}
                  control={
                    <Checkbox
                      checked={product[item.value]}
                      onChange={this.handleInputCheckboxChange(item.value)}
                      value={item.value}
                      classes={{
                        root: classes.root,
                      }}
                    />
                  }
                  label={item.label}
                />
              ))
            }
          </ColMod>
        </Row>
        <Row style={{ marginTop: '1.5em' }}>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Product Attributes
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod md={12} sm={12}>
            {
              attributes.map((x, i) => (
                <div key={i} style={{ paddingTop: '0.7em', display: 'flex' }}>
                  <TextField
                    value={attributes[i].name}
                    onFocus={() => { this.setState({ tracer: i, valueSelect: false }); }}
                    onChange={(event) => { this.handleAttrChange(event, i, 'name'); }}
                    label="Name"
                  />
                  <TextField
                    style={{ marginLeft: '0.4em' }}
                    value={attributes[i].value}
                    onFocus={() => { this.setState({ tracer: i, valueSelect: true }); }}
                    onChange={(event) => { this.handleAttrChange(event, i, 'value'); }}
                    label="Value"
                  />
                  {(
                    attributes.length === (i + 1) ?
                      <AddIcon
                        color="secondary"
                        style={{ fontSize: '1.6em' }}
                        onClick={() => {
                          this.setState({
                            product: {
                              ...product,
                              stagingProductAttributes: [
                                ...attributes,
                                { name: '', value: '' },
                              ],
                            },
                          });
                        }}
                      />
                      :
                      <CloseIcon
                        color="secondary"
                        style={{ fontSize: '1.6em' }}
                        onClick={() => {
                          const d = attributes.slice(0); d.splice(i, 1);
                          this.setState({
                            product: {
                              ...product,
                              stagingProductAttributes: d,
                            }
                          });
                        }}
                      />
                  )}
                </div>))

            }
          </ColMod>
        </Row>
        <Row style={{ marginTop: '1.5em' }}>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Files
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6} md={12}>
            <ImageContainer
              urls={urls}
              handleImageUpload={this.handleImageUpload}
              clearImage={this.clearImage}
            />
          </ColMod>
        </Row>
      </Grid >
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(ProductForm);
