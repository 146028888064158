import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button,
    TextField,
    Typography,
    Avatar,
    Select,
    MenuItem,
} from '@material-ui/core';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import apiCall, { getChoiceIcons, createChoice } from '../../../api/NetworkHandler';
import isEmpty from 'lodash/isEmpty';
import ProductImageManager from '../../../components/Core/ProductImageManager/ProductImageManager';

const styles = {
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    width: '70%',
    height: '85%',
  },
  label: {
    fontSize: '0.9em',
  }
}

var versionDict = {
    "iconRequestId": 0,
};

class ChoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choicesData: {},
      invalidChoice: false,
      invalidImage: false,
      invalidStatus: false,
      icons: [],
    };
  }

  componentDidMount() {
      this.fetchIcons();
  }

  fetchIcons = async () => {
      try {
        versionDict.iconRequestId += 1;
        let prevRequestId = versionDict.iconRequestId;
        const response = await apiCall.sendRequest('get', getChoiceIcons);
        const { data: { data: { content = [] } = {} } = {} } = response;
        if (prevRequestId == versionDict.iconRequestId) {
            this.setState({
                icons: content,
            });
        }
      } catch (err) {
        this.setState({
            icons: []
        });
      }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState.choicesData)) {
      const { choicesData } = nextProps;
      return {
        choicesData: {
          ...choicesData,
        }
      };
    } else {
      return {
        ...prevState
      }
    }
  }

  handleInputChange = property => (event) => {
    const value = event.target.value;

    this.setState(prevState => {
      const choicesData = prevState.choicesData;
      choicesData[property] = value;
      return {
        choicesData,
      }
    })
  }

  validateForm = (choicesData) => {
    let isFormValid = true;

    if (!choicesData.choice) {
      this.setState({ invalidChoice: true });
      isFormValid = false;
    } else {
      this.setState({ invalidChoice: false });
    }

    if (!choicesData.imageUrl) {
      this.setState({ invalidImage: true });
      isFormValid = false;
    } else {
      this.setState({ invalidImage: false });
    }

    return isFormValid;
  }

  handleSave = async () => {
    const { choicesData } = this.state;
    const { onSave } = this.props;
    if (!this.validateForm(choicesData)) {
      return;
    }
    try {
        await apiCall.sendRequest('post', createChoice, choicesData);
        onSave();
    } catch (err) {
        console.log(err.response);
    }
  }

  onImageUpload = (imageUrl) => {
      const { choicesData } = this.state;
      this.setState({
        choicesData: {
            ...choicesData,
            imageUrl,
        },
      });
  }

  onDeleteImage = () => {
    const { choicesData } = this.state;
      this.setState({
        choicesData: {
            ...choicesData,
            imageUrl: null
        },
      });
  }

  render() {
    const {
      choicesData,
      invalidChoice,
      invalidImage,
      invalidStatus,
      icons,
    } = this.state;

    const { handleCancel, classes } = this.props;
    const { id, imageUrl } = choicesData;

    return (
      <Grid>
        <Row>
          <ColMod>
            <Row style={{ marginLeft: window.innerWidth * 0.2 }}>
              <Typography variant="title" color="inherit">
                {id ? 'Edit Choice' : 'Add Choice'}
              </Typography>
            </Row>
          </ColMod>
        </Row>
        <Row style={{ marginLeft: window.innerWidth * 0.03 }}>
          <ColMod lg={4}>
            <TextField
              fullWidth
              label='Choice Name'
              value={choicesData.choice || ''}
              onChange={this.handleInputChange('choice')}
              error={invalidChoice}
              helperText={invalidChoice && "*required"}
              required
            />
          </ColMod>
          <ColMod lg={4}>
            <Typography variant="caption"> Icons </Typography>
            <Select
              value={<Avatar alt="choice" src={imageUrl} /> || ''}
              fullWidth
              onChange={this.handleInputChange('imageUrl')}
              name="imageUrl"
            >
              {icons.map((icon, index) => (<MenuItem key={index} value={icon}>{<Avatar alt="choice" src={icon} />}</MenuItem>))}
            </Select>
          </ColMod>
        </Row>
        <Row style={{ marginLeft: window.innerWidth * 0.03 }}>
            <ColMod lg={4}>
                {invalidImage && <span style={{ color: 'red', paddingBottom: '10px' }}>Please upload one image..</span>}
                <ProductImageManager
                    images={[{
                        imageUrl: imageUrl,
                        position: "FRONT",
                        name: choicesData.choice,
                    }]}
                    singleImage={true}
                    onImageUpload={this.onImageUpload}
                    onDelete={this.onDeleteImage}
                    parentId="survey"
                />
          </ColMod>
        </Row>
        <Row end="sm">
          <ColMod>
            <Button color="secondary" variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </ColMod>
          <ColMod>
            <Button variant="contained" color="primary" onClick={this.handleSave}>
              {id ? 'Save' : 'Add'}
            </Button>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

ChoiceForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  choicesData: PropTypes.instanceOf(Object).isRequired,
};

ChoiceForm.defaultProps = {
  handleCancel: () => { },
  onSave: () => { },
  choicesData: {},
}

export default withStyles(styles)(ChoiceForm);