import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import utils from '../../../utils/queryBuilder';
import Colors from '../../../utils/Colors';
import { Badge } from 'antd';


const canView = utils.isCurrentUserAuthorizedToView;

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 2,
    marginLeft: '30px',
    display: "flex",
    flexDirection: "row"
  },
  searchBar: {
    flex: 4,
  },
  calendar: {
    flex: 1,
    textAlign: 'center',
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 5,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: Colors.primary,
  },
};

class SearchBar extends React.Component {
  saveCo = () => {
    const { onSave, status } = this.props;
    onSave(status || 'NEW');
  }

  submitCo = (status) => {
    const { onSave } = this.props;
    onSave(status);
  }

  badgeColorByStatus = (status) => {
    switch (status) {
      case "NEW": return "#FFD54F";
      case "IN_DELIVERY": return "#CDDC39"
      case "ORDERED": return "#4CAF50";
      case "ON_HOLD": return "#BE9800";
      case "CLOSED": return "#FFB74D";
      case "CANCELLED": return "#FF5722";
      default: return "#FFD54F";
    }
  };

  render() {
    const {
      location = {}, header, status, history = {}, id, 
    } = this.props;
    const { pathname = '' } = location;
    const badgeColor = this.badgeColorByStatus(status);
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
            { (header && id) ? header : 'Create Customer Order' }
          </Typography>
          <Badge count={status} style={{ backgroundColor: badgeColor, marginLeft: "10px", color: "black" }}/>
        </div>
        <div style={styles.searchBar} />
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary">
            <Button color="primary" variant="outlined" onClick={() => { history.goBack(); }}>Back</Button>
          </Typography>
        </div>
        {!((pathname.split('/').length === 4) || !canView(['CC_AGENT', 'ADMIN', 'CC_MANAGER']) || id) &&
        <div style={styles.add}>
          <Button
            onClick={this.saveCo}
            variant="contained"
            size="large"
            color="inherit"
            // disabled={(pathname.split('/').length === 4) || !canView(['CC_AGENT', 'ADMIN', 'CC_MANAGER'])}
          >
            Save
          </Button>
        </div>}
        {/* {!((pathname.split('/').length === 4) || (['CANCELLED', 'CLOSED', 'DELIVERED'].indexOf(status) !== -1)) && */}
        {!((pathname.split('/').length === 4) || ['IN_DELIVERY', 'NEW'].includes(status)) &&
          <div style={styles.add}>
            <Button
              onClick={() => this.submitCo(status)}
              variant="contained"
              size="large"
              color="secondary"
            >
              Submit
            </Button>
          </div>
        }
      </div>
    );
  }
}

SearchBar.propTypes = {
  location: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  header: PropTypes.string,
  status: PropTypes.string,
  onSave: PropTypes.func,
};

export default withRouter(SearchBar);
