import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, Drawer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import Cookies from 'universal-cookie';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { logoutUser } from "../actions/index";
import Colors from '../utils/Colors';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import SideBarMenu from './SideBarMenu';

const DoodhwalaIcon = require('../../public/images/FTHDailyIcon.png');
const drawerWidth = 285;

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  // menuButton: {
  //   marginRight: 0,
  // },
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '50px',
    alignItems: 'center',
  },
  manageButton: {
    flex: 1,
    marginLeft: '30px',
  },
  monitorButton: {
    flex: 1,
    textAlign: 'center',
    marginLeft: '30px',
  },
  space: {
    flex: 4,
    textAlign: 'center',
  },
  profile: {
    flex: 2,
    textAlign: 'center',
    color: 'white',
  },
  svg: {
    fill: '#ff3365',
    paddingLeft: '1em',
  },
  logo: {
    height: '64px',
    width: 'auto',
    position: 'relative',
    top: '3px',
  },
  sideMenuLogo: {
    height: '45px',
    width: 'auto',
    position: 'relative',
    top: '1px',
  },
  tabStyle: {
    padding: '0px 15px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: Colors.white,
    textDecoration: 'none',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    height: '65px',
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'space-around',
  },
};

const cssStyles = `
.logo{
  height: 64px;
  width: auto;
  position: relative;
  top:3px;
}
.buttons{
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  height: 67px;
}
@media only screen and (max-width: 600px) {
  .logo{
    height: 40px;
    width: auto;
    position: relative;
    top:3px;
  }
  .buttons{
    display:none;
  }
}`;

document.head.appendChild(document.createElement('style')).textContent = cssStyles;

const cookie = new Cookies();

class ButtonAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      tab: 0,
      open: false,
    };
  }

  componentWillMount() {
    const { match = {} } = this.props;
    const { path = '' } = match;
    if (path === '/monitoring') {
      this.setState({
        tab: 1,
      });
    }
  }

  toggle = () => {
    this.setState({
      showList: !this.state.showList,
    });
  }

  handleClickLogout = async () => {
    const { LogoutUser, history } = this.props;
    cookie.remove('access_token');
    history.push('/login');
    LogoutUser();
  }

  handleMonitor = () => {
    const { tab = 0 } = this.state;
    if (tab === 0) {
      this.props.history.push('/monitoring');
    }
  }

  handleManage = () => {
    const { tab = 0 } = this.state;
    if (tab === 1) {
      this.props.history.goBack();
    } else {
      this.props.history.push('/');
    }
  }

  handleDrawerOpen = () => {
    if (this.props.history.location.pathname === '/') {
      return;
    }
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, User, match = {} } = this.props;
    const { path = '' } = match;
    if (path !== '/apps' && !cookie.get('access_token')) {
      window.location.href = '/login';
    }
    const { tab = 0, open } = this.state;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar style={{ background: 'black' }} disableGutters={!open}>
            <IconButton className={classNames(classes.menuButton, open && classes.hide)} color="inherit" aria-label="Menu" edge='start' onClick={this.handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
            <div style={{ cursor: 'pointer' }}>
              <a href="/">
                <img
                  src={DoodhwalaIcon}
                  alt="Logo"
                  className="logo"
                />
              </a>
            </div>
            <div className="buttons">
              <Button style={{ borderRadius: 0, background: tab === 0 ? Colors.primary : 'inherit' }} onClick={this.handleManage}>
                <div style={{ ...styles.tabStyle }}> Manage</div>
              </Button>
              <Button style={{ borderRadius: 0, background: tab === 1 ? Colors.primary : 'inherit' }} onClick={this.handleMonitor}>
                <div style={{ ...styles.tabStyle }}> Monitor</div>
              </Button>
            </div>
            <div style={styles.div}>
              <div style={styles.space} />
              <div style={{ display: 'inline-flex' }}>
                <Typography variant="body2" color="inherit">{User.name}</Typography>
                <span style={styles.svg}>
                  <AccountCircle />
                </span>
                {this.state.showList &&
                  <ArrowUp onClick={this.toggle} size="small" />}
                {!this.state.showList &&
                  <ArrowDown onClick={this.toggle} size="small" />}
              </div>
              {this.state.showList &&
                <DropList
                  toggle={this.toggle}
                  open={this.state.showList}
                  logout={this.handleClickLogout}
                />
              }
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={this.handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <MenuIcon />
            </IconButton>
            <span style={{ textAlign: 'left' }}>Menu</span>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <SideBarMenu onClose={this.handleDrawerClose} />
        </Drawer>
      </div>
    );
  }
}

ButtonAppBar.propTypes = {
  classes: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  LogoutUser: PropTypes.func,
  User: PropTypes.instanceOf(Object),
};


class DropList extends React.Component {
  render() {
    const { logout } = this.props;
    return (
      <div style={{
        width: '100%',
        maxWidth: 240,
        position: 'absolute',
        top: '50px',
        backgroundColor: 'white',
        zIndex: 200,
        right: 3,
      }}
      >
        <List size="small" component="nav">
          <ListItem onClick={logout} button>
            <ListItemText inset primary="Logout" />
          </ListItem>
        </List>
      </div>
    );
  }
}

DropList.propTypes = {
  logout: PropTypes.func,
};

const mapStateToProps = state => ({
  User: state.User,
});

const mapDispatchToProps = dispatch => ({
  LogoutUser: bindActionCreators(logoutUser, dispatch),
});

export default
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ButtonAppBar)));
