import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty } from 'lodash';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { InputLabel } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { ColMod } from '../../../components/lib';
import moment from 'moment';
import apiCall, {
  searchProductItemByRegion,
} from '../../../api/NetworkHandler';
import RegionSelector from '../../../components/Region/Selector';
import ProgressBar from '../../../components/States/ProgressBar';
import { getOfferItemStartDate } from './OfferUtility';


var versionDict = {
    "productRequestId": 0,
}

class MultiProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            catalogId: '',
            suggestions: [],
            productItems: [],
            showSelectedProducts: false,
            region: null,
            loading: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const { items } = nextProps;
        return {
            productItems: items.filter(el => el.active !== false),
        };
    }

    handleRegionChange = (region) => {
        this.setState({
            region,
            searchText: '',
            suggestions: [],
        });
    }

    handleSearch = (searchText) => {
        this.setState({
            searchText,
            suggestions: []
        }, () => this.searchProducts());
    }

    getStartTime = (startTime) => {
        let today = new Date();
        let dayAfterTomorrow = moment(today).add(2, 'days');
        if (moment(startTime).isSameOrBefore(dayAfterTomorrow, 'days')) {
            return dayAfterTomorrow;
        } else {
            startTime;
        }
    }

    selectProduct = async (product) => {
        const { productItems = [], region = {} } = this.state;
        const { id = null, name = '' } = region;
        const { onChange, offerData, initialOfferProducts } = this.props;
        const { description, startTime, endTime, paybackType } = offerData;
        let item = {};
        let today = new Date();
        const calculatedStartTime = getOfferItemStartDate();
        let dayAfterTomorrow = moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DDT00:00:00");

        if (product && product.productItemId) {
            item = product;
        } else {
            item = {
                active: product.active,
                productItemId: product.id,
                title: product.name,
                price: product.unitPrice,
                offerPrice: product.unitPrice,
                region: name,
                regionId: id,
                startTime: moment(startTime).isSameOrBefore(dayAfterTomorrow, 'days') ? dayAfterTomorrow : moment(startTime).format("YYYY-MM-DDT00:00:00"),
                endTime: moment(endTime).format("YYYY-MM-DDT23:59:59"),
                paybackType: (!paybackType || paybackType === 'DISCOUNT') ? "AMOUNT" : "PERCENT",
                priceDiscountType: !paybackType ? "DISCOUNT" : paybackType,
                offerText: description,
                maxQuantity: null,
                paybackValue: 0,
            };
        }

        let index = 0;
        let modItems = [];

        if (productItems.length > 0) {
            index = productItems.findIndex(a => a.productItemId === item.productItemId);
            let initialDataIndex = initialOfferProducts.findIndex(el => el.productItemId == item.productItemId && el.region == item.region);

            if (index > -1 || initialDataIndex > -1) {
                modItems = [...productItems];
                modItems.splice(index, 1);
                initialOfferProducts.splice(initialDataIndex, 1);
                onChange(modItems);
            } else {
                modItems = [...productItems, item];
                initialOfferProducts.push(item);
                onChange(modItems);
            }
        } else if (productItems.length === 0) {
            modItems.push(item);
            initialOfferProducts.push(item);
            onChange(modItems);
        }
    }

    toggleProgress = (state) => {
        this.setState({
            loading: state,
        });
    }

    searchProducts = debounce( async () => {
        const { searchText, region } = this.state;
        const { id = null } = region;
        this.toggleProgress(true);
        try {
            versionDict.productRequestId += 1;
            let prevRequestId = versionDict.productRequestId;
            const response = await apiCall.sendRequest('get', searchProductItemByRegion(searchText, id));
            const { data = [] } = response;
            if (versionDict.productRequestId == prevRequestId) {
                this.setState({
                    suggestions: data,
                });
            }
            this.toggleProgress(false);
        } catch (err) {
            this.setState({
                suggestions: [],
            });
            this.toggleProgress(false);
        }
    }, 500);


    handleModalClose = (flag) => {
        this.setState({
            showSelectedProducts: false,
        });
        this.props.handleClose(flag);
    }

    render() {
        const { open = false, UOM = [] } = this.props;
        const {
            searchText, suggestions = [], catalogs = [], productItems = [], showSelectedProducts, region, loading,
        } = this.state;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={() => this.handleModalClose(false)}
                onBackdropClick={this.handleModalClose}
            >
            <div style={{
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                width: '70%',
                height: '80%',
            }}
            >
                <ProgressBar isLoading={loading}/>
                <Button style={{ float: 'right' }} onClick={() => this.props.handleClose(false)} color="secondary" aria-label="close">
                    <CloseIcon />
                </Button>
                <Grid style={{ padding: '2em', background: '#FFFFFF' }}>
                    <Row>
                        <ColMod xs md lg={15}>
                            <Row>
                                <Typography variant="body2" color="secondary">
                                    Search Products
                                </Typography>
                            </Row>
                            <Row>
                                <ColMod lg={4}>
                                    <Typography variant="caption"> Region </Typography>
                                    <RegionSelector
                                        onSelect={this.handleRegionChange}
                                        selected={region}
                                        isClearable={false}
                                    />
                                </ColMod>
                                <ColMod lg={4}>
                                    <InputLabel htmlFor="name-readonly">Product Item</InputLabel>
                                    <TextField
                                        fullWidth
                                        value={searchText}
                                        disabled={!region}
                                        helperText={!region && "Please select region first..."}
                                        onChange={(event) => { this.handleSearch(event.target.value); }}
                                    />
                                </ColMod>
                                <ColMod lg={4}>
                                    <Button color="secondary" variant="contained" onClick={this.searchProducts}>
                                        Search
                                    </Button>
                                </ColMod>
                                <ColMod lg={8}>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox
                                            checked={showSelectedProducts}
                                            value={showSelectedProducts}
                                            onChange={() => this.setState({ showSelectedProducts: !showSelectedProducts })}
                                            color="secondary"
                                        />
                                        <Typography style={{ paddingTop: '12px' }} color="secondary" variant="body2">
                                            Show Selected Products
                                        </Typography>
                                    </div>
                                </ColMod>
                            </Row>
                            <Row>
                                <ColMod lg={12}>
                                    <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '300px' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ padding: '0px', width: '50px', textAlign: 'center' }}>Select</TableCell>
                                                    <TableCell>Product</TableCell>
                                                    {/* <TableCell>Unit Measure</TableCell> */}
                                                    <TableCell>Unit Price</TableCell>
                                                    <TableCell>Selling Price</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { showSelectedProducts &&
                                                    productItems.map(item => (
                                                        <TableRow key={item.id}>
                                                            <TableCell style={{ padding: '0px' }}>
                                                            <Checkbox
                                                                checked={!isEmpty(productItems.find(a => a.productItemId === item.productItemId))}
                                                                value={!isEmpty(productItems.find(a => a.productItemId === item.productItemId))}
                                                                onChange={event => this.selectProduct(item, event)}
                                                                color="secondary"
                                                            />
                                                            </TableCell>
                                                            <TableCell>{item.title}</TableCell>
                                                            {/* <TableCell>
                                                                {UOM.map((x) => {
                                                                    if (x.id === item.uomId) {
                                                                    return `${item.unitMeasure} ${x.code}`;
                                                                    }
                                                                    return '';
                                                                })}
                                                            </TableCell> */}
                                                            {/* <TableCell>{item.unitMeasure}</TableCell> */}
                                                            <TableCell>{item.price}</TableCell>
                                                            <TableCell>{item.offerPrice}</TableCell>
                                                        </TableRow>
                                                ))}
                                                { !showSelectedProducts &&
                                                    suggestions.map(suggestion => (
                                                        <TableRow key={suggestion.id}>
                                                        <TableCell style={{ padding: '0px' }}>
                                                            <Checkbox
                                                                checked={!isEmpty(productItems.find(a => a.productItemId === suggestion.id))}
                                                                value={!isEmpty(productItems.find(a => a.productItemId === suggestion.id))}
                                                                onChange={event => this.selectProduct(suggestion, event)}
                                                                color="secondary"
                                                            />
                                                        </TableCell>
                                                        <TableCell>{suggestion.name}</TableCell>
                                                        {/* <TableCell>
                                                            {UOM.map((x) => {
                                                                if (x.id === suggestion.uomId) {
                                                                return `${suggestion.unitMeasure} ${x.code}`;
                                                                }
                                                                return '';
                                                            })}
                                                        </TableCell> */}
                                                        {/* <TableCell>{suggestion.unitMeasure}</TableCell> */}
                                                        <TableCell>{suggestion.unitPrice}</TableCell>
                                                        <TableCell>{suggestion.sellingPrice}</TableCell>
                                                        </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </ColMod>
                            </Row>
                        </ColMod>
                    </Row>
                </Grid>
            </div>
            </Modal>
        );
    }
}

MultiProductSelection.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  searchUrl: PropTypes.func,
  customerId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  UOM: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => ({
  UOM: state.UOM,
});

export default connect(mapStateToProps, null)(MultiProductSelection);

