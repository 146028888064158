import moment from 'moment';


const NEXT_DELIVERY_DAY = 2;
const DATE_FORMAT = 'YYYY-MM-DD';

class PlanDate {
  static getFutureDate(numberOfDays) {
    return moment()
      .add(numberOfDays, 'days')
      .format(DATE_FORMAT);
  }

  static getNextDayForGiven(day) {
    return moment(day)
      .add(1, 'days')
      .format(DATE_FORMAT);
  }

  static getDate(date, numberOfDays) {
    return moment(date)
      .add(numberOfDays, 'days')
      .format(DATE_FORMAT);
  }

  static getTomorrowDate = () =>
    moment(new Date())
      .add(1, 'days')
      .format('YYYY-MM-DD');

  static getMinStartDate(cutOffTime) {
    const getCurrentTime = moment().format('HH:mm:ss');
    let startDateToConsider = PlanDate.getNextDayForGiven(PlanDate.getTomorrowDate());
    if (moment(getCurrentTime, 'HH:mm:ss').isBefore(moment(cutOffTime, 'HH:mm:ss'))) {
      startDateToConsider = PlanDate.getTomorrowDate();
    }

    return startDateToConsider;
  }

  static getPlanDateOfProductFor(startDate, schedule, cutOffTime, addressVerified) {
    const { scheduleType, customFrequency } = schedule;
    const { daysOfWeek } = schedule;

    let startDateToConsider = PlanDate.getMinStartDate(cutOffTime);

    if (!addressVerified) {
      startDateToConsider = PlanDate.getFutureDate(NEXT_DELIVERY_DAY);
    }

    // max of startDateToconsider and incoming startDAte
    const finalStartDate = moment.max([moment(startDateToConsider), moment(startDate)]);

    let deliveryDate;
    switch (scheduleType) {
      case 'DAILY':
      case 'GET_ONCE':
        deliveryDate = finalStartDate;
        break;
      default:
      case 'ALTERNATE_DAYS':
        deliveryDate = finalStartDate;
        break;
      case 'WEEKDAYS':
        deliveryDate = PlanDate.getNextWeekDay(finalStartDate);
        break;
      case 'WEEKENDS':
        deliveryDate = PlanDate.getNextWeekend(finalStartDate);
        break;
      case 'CUSTOM':
        deliveryDate = PlanDate.getCustomNextDay(
          finalStartDate,
          daysOfWeek,
          customFrequency,
        );
        break;
    }

    return deliveryDate;
  }

  static getNextDay(startDate, daysInNumberArray) {
    const dayNumbers = daysInNumberArray;
    const dayOfGivenDate = moment(startDate).day();
    if (dayNumbers.includes(dayOfGivenDate)) {
      return startDate;
    }

    let isWeekDay = true;
    let nextWeekDay = startDate;
    let increment = 0;
    while (isWeekDay && increment >= 0) {
      const nextDay = moment(nextWeekDay).day();
      if (dayNumbers.includes(nextDay)) {
        isWeekDay = false;
        increment += 1;
      } else {
        increment += 1;
        nextWeekDay = PlanDate.getNextDayForGiven(nextWeekDay);
        // return nextWeekDay;
      }
    }
    return nextWeekDay;
  }

  static getNextWeekDay(startDate) {
    const dayNumbers = [1, 2, 3, 4, 5];
    return PlanDate.getNextDay(startDate, dayNumbers);
  }

  static getNextWeekend(startDate) {
    const weekEndDays = [0, 6]; // 0 sunday, 6 saturday
    return PlanDate.getNextDay(startDate, weekEndDays);
  }

  static getCustomNextDay(startDate, customDays, noOfDays) {
    const daysMapping = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 0,
    };

    let deliveryDate = startDate;
    if (customDays && customDays.length > 0) {
      let daysOfWeek = customDays;
      if (daysOfWeek && typeof daysOfWeek === 'string') {
        daysOfWeek = daysOfWeek.split(',');
      }
      const dayNumberArray = daysOfWeek.map(eachDay => daysMapping[eachDay]);
      deliveryDate = PlanDate.getNextDay(startDate, dayNumberArray);
    }

    if (noOfDays) {
      deliveryDate = startDate;
    }

    return deliveryDate;
  }
}

export default PlanDate;
