import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  InputAdornment,
  Typography,
  Modal,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Input,
  Chip,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import apiCall, { getUsers, getRoles, fetchUserByIdPath, fetchAllDepartmentPath } from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import UserSelection from '../../../components/UserSelection';
import { ColMod } from '../../../components/lib';


const styles = {
  popUp: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    height: '90%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: [],
      roles: [],
      departments: [],
    };
  }

  componentDidMount() {
    this.fetchAllDepartments();
    this.fetchAllRoles();
  }

  componentWillReceiveProps(nextProps) {
    const { selectedUser = {} } = nextProps;
    if (nextProps) {
      if (selectedUser !== this.state.selectedUser
      ) {
        this.setState({
          selectedUser,
        });
      }
    }
    this.openAddUser(nextProps);
  }

  onClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  }

  openAddUser = (nextProps) => {
    const { selectedUser = {} } = nextProps;
    this.setState({
      selectedUser: selectedUser || {},
    });
    if (!selectedUser) {
      this.setState({
        selectedRoles: [],
        selectedDepartment: [],
      });
    }
    this.fetchUserById(nextProps);
    this.handleDepartment(nextProps);
    this.handleClick(nextProps);
  }

fetchAllDepartments = () => {
  apiCall.sendRequest('get', fetchAllDepartmentPath)
    .then((response) => {
      const { data: { _embedded: embedded } } = response;
      this.setState({
        departments: embedded.departments,
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

fetchAllRoles = () => {
  apiCall.sendRequest('get', getRoles)
    .then((response) => {
      const { data: { _embedded: embedded } } = response;
      this.setState({
        roles: embedded.roles,
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

handleChange = (event, id) => {
  const { selectedUser = {} } = this.state;
  const { value } = event.target;
  if (id === 'active') {
    selectedUser[id] = value === 'ACTIVE';
    this.setState({ selectedUser });
  } else {
    selectedUser[id] = value;
    this.setState({
      selectedUser,
    });
  }
};

openUserSelector = userType => () => {
  this.setState({
    openUserSelector: true,
    userType,
  });
}


handleClick = (nextProps) => {
  const { roles } = this.state;
  const { selectedUser = {} } = nextProps;
  if (selectedUser) {
    const { _links: links = {} } = selectedUser;
    const href = links.roles.href.split('/').slice(3).join('/');
    apiCall.sendRequest('get', href)
      .then((response) => {
        const { data: { _embedded: embedded } } = response;
        const userRoles = embedded.roles;
        this.setState({
          selectedRoles: roles.filter((role) => {
            for (let i = 0; i < userRoles.length; i += 1) {
              if (userRoles[i].id === role.id) {
                return true;
              }
            }
          }),
        });
      });
    this.setState({
      selectedUser: selectedUser || {},
    });
  }
}

handleDepartment = (nextProps) => {
  const { selectedUser = {} } = nextProps;
  if (selectedUser) {
    const { _links: links } = selectedUser;
    const href = links.departments.href.split('/').slice(3).join('/');
    apiCall.sendRequest('get', href)
      .then((response) => {
        const { data: { _embedded: embedded } } = response;
        const { departments } = embedded;
        const { id } = departments[0];
        this.setState({
          selectedDepartment: id,
        });
      });
    this.setState({
      selectedUser: selectedUser || {},
    });
  }
}

addOrUpdateUser = () => {
  const { selectedRoles = [], selectedDepartment = '' } = this.state;
  if (selectedRoles.length || selectedDepartment) {
    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        roles: prevState.selectedRoles.map(role => `${BASE_URL.BASE_URL}/api/v1/roles/${role.id}`),
        departments: [`${BASE_URL.BASE_URL}/api/v1/departments/${selectedDepartment}`],
      },
    }), () => {
      this.fetchUserById();
      this.postUser();
    });
  } else {
    this.fetchUserById();
    this.postUser();
  }
}

fetchUserById = (nextProps = {}) => {
  const { selectedUser = {} } = nextProps;
  const { supervisorId = '' } = selectedUser;
  if (supervisorId) {
    apiCall.sendRequest('get', fetchUserByIdPath(supervisorId))
      .then((response) => {
        const { data } = response;
        this.setState(prevState => ({
          selectedUser: {
            ...prevState.selectedUser,
            supervisorName: data.name,
          },
        }));
      });
  }
}

postUser = () => {
  const { selectedUser } = this.state;
  const postData = { ...selectedUser };
  apiCall.sendRequest('post', getUsers, postData)
    .then((response) => {
      this.props.handleClose();
    })
    .catch((error) => {
      console.error(error);
    });
}

selectUser = (user) => {
  this.setState(prevState => ({
    selectedUser: {
      ...prevState.selectedUser,
      supervisorId: user.id,
      supervisorName: user.name,
      supervisorMobile: user.mobile,
    },
  }));
  this.onClose();
}

render() {
  const {
    selectedUser, openUserSelector, selectedDepartment, departments, roles, selectedRoles, userType,
  } = this.state;
  const { openAddUser, handleClose } = this.props;
  const {
    name,
    active,
    address,
    email,
    mobile,
    pan,
    latitude,
    longitude,
    supervisorName,
    workPhone,
    bankAccountNo,
    bankAddress,
    bankIfscCode,
    bankName,
  } = selectedUser || {};


  if (isEmpty(selectedUser)) {
    return null;
  }

  return (
    <div>
        <UserSelection
            userType={userType}
            openSearchBox={openUserSelector}
            handleClose={this.onClose}
            selectUser={this.selectUser}
          />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openAddUser}
          handleClose={handleClose}
        >
          <div style={ styles.popUp}
          >
            <Button style={{ float: 'right' }} onClick={handleClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      {'Update User'}
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Name"
                        fullWidth
                        name="name"
                        value={name || ''}
                        onChange={event => this.handleChange(event, 'name')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Email"
                        fullWidth
                        name="email"
                        value={email || ''}
                        onChange={event => this.handleChange(event, 'email')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Status</InputLabel>
                      <MaterialSelect
                    value={active ? 'ACTIVE' : 'INACTIVE'}
                    inputProps={{
                      name: 'selectedStatus',
                      id: 'select-status',
                    }}
                    onChange={event => this.handleChange(event, 'active')}
                    fullWidth
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Work Phone"
                        fullWidth
                        name="workPhone"
                        value={workPhone || ''}
                        onChange={event => this.handleChange(event, 'workPhone')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Mobile"
                        fullWidth
                        name="mobile"
                        value={mobile || ''}
                        onChange={event => this.handleChange(event, 'mobile')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Roles</InputLabel>
                      <MaterialSelect
                        multiple
                        fullWidth
                        value={selectedRoles}
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState({
                            selectedRoles: value,
                          });
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                          <div>
                            {selected.map(value => <Chip style={{ padding: 0 }} key={value.id} label={value.name} />)}
                          </div>
                        )}
                      >
                        {roles.map(role => (
                          <MenuItem
                            key={role}
                            value={role}
                          >
                            {role.name}
                          </MenuItem>
                        ))
                        }
                      </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Address"
                        fullWidth
                        name="address"
                        value={address || ''}
                        onChange={event => this.handleChange(event, 'address')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Latitude"
                        fullWidth
                        name="latitude"
                        value={latitude || ''}
                        onChange={event => this.handleChange(event, 'latitude')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Longitude"
                        fullWidth
                        name="longitude"
                        value={longitude || ''}
                        onChange={event => this.handleChange(event, 'longitude')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={4}>
                          <TextField
                            label="Supervisor Name"
                            fullWidth
                            name="supervisorId"
                            value={supervisorName || '' }
                            onChange={event => this.handleChange(event, 'supervisorId')}
                            onClick={this.openUserSelector('Supervisor')}
                              helperText="Supervisor"
                              InputProps={{
                              readOnly: true,
                              endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                        }}
                      />
                      </ColMod>
                      <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Department</InputLabel>
                      <MaterialSelect
                        fullWidth
                        value={selectedDepartment}
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState({
                            selectedDepartment: value,
                          });
                        }}
                      >
                        {departments.map(department => (
                          <MenuItem
                            key={department}
                            value={department.id}
                          >
                            {department.name}
                          </MenuItem>
                        ))
                        }
                      </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <Typography variant="body2" color="secondary">
                        Payment Details
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Bank Name"
                        fullWidth
                        name="bankName"
                        value={bankName || ''}
                        onChange={event => this.handleChange(event, 'bankName')}
                        />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Account Number"
                        fullWidth
                        name="bankAccountNo"
                        value={bankAccountNo || ''}
                        onChange={event => this.handleChange(event, 'bankAccountNo')}
                        />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="IFSC Code"
                        fullWidth
                        name="bankIfscCode"
                        value={bankIfscCode || ''}
                        onChange={event => this.handleChange(event, 'bankIfscCode')}
                        />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={12}>
                    <TextField
                        label="Address"
                        fullWidth
                        name="bankAddress"
                        value={bankAddress || ''}
                        onChange={event => this.handleChange(event, 'bankAddress')}
                        />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={12}>
                    <TextField
                        label="PAN#"
                        fullWidth
                        name="pan"
                        value={pan || ''}
                        onChange={event => this.handleChange(event, 'pan')}
                        />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button style={{ marginRight: '2em' }} color="secondary" onClick={handleClose}>
              Cancel
              </Button>
              <Button
                onClick={() => {
                this.addOrUpdateUser();
              }}
                variant="contained"
                color="secondary"
              >
                {'Update'}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
  );
}
}

UserProfile.propTypes = {
  selectedUser: PropTypes.instanceOf(Object),
  openAddUser: PropTypes.bool,
  handleClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { deliveryBoyIdCardType, maritalStatuses } = Status;
  return {
    deliveryBoyIdCardType,
    maritalStatuses,
  };
};


export default withRouter(connect(mapStateToProps, null)(UserProfile));
