import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  TablePagination,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { isEmpty } from 'lodash';
import StaticRouteSelection from '../../../components/StaticRouteSelection';
import apiCall, { staticRoute, fetchStaticRouteByIdPath } from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';
import SelectedItemDetail from '../../Commons/components/SelectedItemDetail';
import TableHeader from '../../Commons/components/TableHeader';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { isStaticRouteChangeAllowed, showStaticRouteChangeErrorDialog } from "../../../utils/staticRoutePermission";
import { getApplicationConfigs } from "../../../utils/getAppConfig";


const columnData = [
  {
    id: 'customerName',
    numeric: false,
    disablePadding: true,
    label: 'Customer Name',
  },
  {
    id: 'cityName',
    numeric: false,
    disablePadding: true,
    label: 'City Name',
  },
  {
    id: 'localityName',
    numeric: false,
    disablePadding: true,
    label: 'Locality',
  },
  {
    id: 'buildingName',
    numeric: false,
    disablePadding: true,
    label: 'Building Name',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'timeCreated',
    numeric: false,
    disablePadding: true,
    label: 'Created On',
  },
  {
    id: 'route',
    numeric: false,
    disablePadding: false,
    label: 'Route',
  },

  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 6,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    // padding: 10,
  },
  tableCell: {
    textAlign: 'left',
  },
};

var versionDict = {
  "routeRequestId": 0,
};

class AddressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: {},
      openRouteSelector: false,
      page: 0,
      order: 'desc',
      orderBy: 'locality',
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      staticRouteChangeTimeMin: '',
      staticRouteChangeTimeMax: '',
      hideRightPanel: false,
    };
  }

  componentDidMount() {
    this.setTimeState();
  }

  setTimeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = appConfig;
    this.setState({
      staticRouteChangeTimeMin,
      staticRouteChangeTimeMax,
    });
  };

  // handleClick = (address) => {
  //   this.setState({
  //     selectedAddress: address,
  //   });
  //   this.fetchStaticRouteById(address);
  // };

  handleClick = (address) => {
    const { staticRouteId } = address;
    let newAddress = {...address};
    Object.keys(newAddress).forEach(k => (!newAddress[k] && newAddress[k] !== undefined) && delete newAddress[k]);
    newAddress = {
      ...newAddress,
      active: newAddress.active === true ? 'Yes' : 'No',
      verified: newAddress.verified === true ? 'Yes' : 'No',
      ringBell: newAddress.ringBell === true ? 'Yes' : 'No',
      timeCreated: moment(newAddress.timeCreated).format('ll'),
      timeUpdated: moment(newAddress).format('ll')
    };
    this.setState({
      selectedAddress: newAddress,
      hideRightPanel: false,
    }, () => {
      staticRouteId && this.fetchStaticRouteById(staticRouteId);
    });
  };


  selectStaticRoute = (route = {}) => {
    const { id } = route;
    const { selectedAddress, page } = this.state;
    const { id: addressId } = selectedAddress;
    const { reloadAddresses } = this.props;
    this.handleRouteSelectorClose();
    apiCall.sendRequest('put', staticRoute(id, addressId))
      .then((response) => {
        reloadAddresses(page);
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
  }

  openRouteSelector = (address) => {
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = this.state;
    if (isStaticRouteChangeAllowed(staticRouteChangeTimeMin, staticRouteChangeTimeMax)) {
      this.setState({
        openRouteSelector: true,
        selectedAddress: address,
      });
    } else {
      showStaticRouteChangeErrorDialog(staticRouteChangeTimeMin, staticRouteChangeTimeMax);
    }
  }

  handleRouteSelectorClose = () => {
    this.setState({
      openRouteSelector: false,
    });
  }

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };


  handleRequestSort = (event, property) => {
    let { addresses = [] } = this.props;
    if (['actions', 'mobile', 'status', 'route'].includes(property)) {
        return;
      }
      const orderBy = property;
      let order = 'desc';
  
      if (this.state.orderBy === property && this.state.order === 'desc') {
        order = 'asc';
      }
  
      const data =
        order === 'desc'
          ? addresses.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
          : addresses.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
  
      this.setState({ order, orderBy });
  };


  // fetchStaticRouteById = (address) => {
  //   const { staticRouteId } = address;
  //   if (staticRouteId) {
  //     apiCall.sendRequest('get', fetchStaticRouteByIdPath(staticRouteId))
  //       .then((response) => {
  //         const { data } = response;
  //         this.setState(prevState => ({
  //           selectedAddress: {
  //             ...prevState.selectedAddress,
  //             staticRouteName: data.name,
  //           },
  //         }));
  //       });
  //   }
  // }

  fetchStaticRouteById = async (routeId) => {
    try {
      versionDict.routeRequestId += 1;
      let prevRequestId = versionDict.routeRequestId;
      const response = await apiCall.sendRequest('get', fetchStaticRouteByIdPath(routeId));
      const { data = {} } = response;
      const { name = '' } = data;
      if (versionDict.routeRequestId == prevRequestId) {
        this.setState(prevState => ({
          selectedAddress: {
            ...prevState.selectedAddress,
            staticRouteName: name,
          },
        }));
      }
    } catch (err) {
      this.setState({
        selectedAddress: {},
      });
    }
  };

  handleHideRightPanel = () => {
    this.setState({
      hideRightPanel: true,
    });
  };

  render() {
    const {
      addresses = [], totalElements = 0, rowsPerPage, page = 0, onChangeRowsPerPage, 
      fullTable = false,
    } = this.props;
    const { selectedAddress = {}, rowsPerPageOptions, order, orderBy, hideRightPanel } = this.state;
    const isSelected = addresses.some(el => el.id === selectedAddress.id);
    return (
      <div>
        <StaticRouteSelection
          openSearchBox={this.state.openRouteSelector}
          handleClose={this.handleRouteSelectorClose}
          selectStaticRoute={this.selectStaticRoute}
          selectedAddress={selectedAddress}
          key={Date.now()}
        />
        <div style={styles.div}>
          <div style={{ width: (Object.keys(selectedAddress).length > 0 && !hideRightPanel && isSelected) ? '75%' : '100%', float: 'left', height: '700px', overflowY: 'scroll', paddingLeft: '10px' }}>
            <Table aria-labelledby="tableTitle">
              <TableHeader 
                columns={columnData} 
                cellStyle={styles.tableCell}
                order={order} 
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody key={page}>
                {
                  addresses.map(address => (
                    <TableRow
                      hover
                      onClick={() => this.handleClick(address)}
                      tabIndex={-1}
                      key={address.id}
                      selected={!isEmpty(selectedAddress) && selectedAddress.id === address.id}
                    >
                      <TableCell style={{ ...styles.tableCell, color: Colors.default }} component="th" scope="row" padding="none">
                        {address.customerName}
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none" >
                        <div>
                          {address.cityName}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <Tooltip title={address.localityName} placement="top">
                          <div>
                            {address.localityName && address.localityName.length > 20 ? `${address.localityName.slice(0, 17)}...` : address.localityName}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <Tooltip title={address.buildingName} placement="top">
                          <div>
                            {address.buildingName && address.buildingName.length > 30 ? `${address.buildingName.slice(0, 27)}...` : address.buildingName}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          {address.status}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          {moment(address.timeCreated).format('ll')}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none" >
                        <span>
                          <Button variant="outlined" size="small" color="primary" onClick={(event) => { event.stopPropagation(); this.openRouteSelector(address); }}>Set Route</Button>
                        </span>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="default">
                        <Button size="small" variant="outlined" color="secondary">
                          <Link to={{ pathname: `/edit-customer-address/${address.id}` }} style={styles.link}> Edit </Link>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            {addresses && addresses.length > 0 ?
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                rowsPerPage={rowsPerPage}
                backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
                nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
              : null
            }
          </div>
          {(Object.keys(selectedAddress).length > 0 && !hideRightPanel && isSelected) &&
            <div style={{ width: '25%', float: 'right', height: '700px', overflowY: 'scroll', paddingRight: '10px', paddingLeft: '5px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="secondary" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                  Customer Address Details
                </Typography>
                <IconButton>
                  <CloseIcon onClick={this.handleHideRightPanel} />
                </IconButton>
              </div>
              <div style={{ border: '1px solid black', borderRadius: '10px' }}>
                <SelectedItemDetail item={selectedAddress} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

AddressList.propTypes = {
  addresses: PropTypes.instanceOf(Array),
  reloadAddresses: PropTypes.func,
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  fullTable: PropTypes.bool,
};

export default AddressList;
