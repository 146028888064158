import { isEmpty } from 'lodash';
import moment from 'moment';
import * as type from '../constants';
import apiCall, { customerProfileById, customerOrderByFiltersPath } from '../api/NetworkHandler';

export const getAllWarehouses = q => (dispatch) => {
  apiCall
    .sendRequest('get', customerProfileByFilters(q))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: type.FETCH_ALL_WAREHOUSES_SUCCESS,
        payload: data.data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_ALL_WAREHOUSES_FAILURE,
      });
    });
};
