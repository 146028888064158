import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core/';

const styles = {
  row: {
    display: 'flex',
    padding: 10,
  },
  counter: {
    marginRight: 20,
  },
};

const CounterStrip = (props) => {
  const {
    counters = [],
  } = props;
  return (
    <div style={styles.row}>
      {
          counters.map(dss => (
            <div style={styles.counter}>
              <Typography variant="body2" color="secondary">
                {dss.status}:&emsp;
                <Typography style={{ display: 'inline' }} variant="body2">
                  {dss.count}
                </Typography>
              </Typography>
            </div>
        ))
    }
    </div>
  );
};

CounterStrip.propTypes = {
  counters: PropTypes.instanceOf(Array),
};

export default CounterStrip;
