import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { ColMod } from '../../../components/lib';
import apiCall, {
  getProductCatalogVendors,
  fetchVendorLocationsByQuery
} from '../../../api/NetworkHandler';
import Select from '@atlaskit/select';
import { debounce, isEmpty } from 'lodash';
import CatalogSelector from "../../../components/Catalog/Selector";
import { Input } from 'antd';


const { Search } = Input;


const SearchTypes = [
  { label: 'Catalog', value: 'catalog' },
  { label: 'Vendor Location', value: 'vendor_location' }
];

var versionDict = {
  "vendorLocationRequestId": 0,
  "vendorsRequestId": 0,
}

class VendorLocationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      locations: [],
      selectedOption: null,
      selectedCatalog: null,
    };
  };

  handleCloseLocationSelector = () => {
    const { onClose } = this.props;
    this.setState({
      locations: [],
      searchText: '',
      selectedOption: null,
      selectedCatalog: null
    }, () => onClose());
  };

  handleChangeOption = (selectedOption) => {
    this.setState({
      selectedOption,
      locations: []
    });
  };

  fetchVendorLocationsByCatalogId = async (catalogId, warehouseId) => {
    try {
      versionDict.vendorsRequestId += 1;
      let prevRequestId = versionDict.vendorsRequestId;
      const response = await apiCall.sendRequest('get', getProductCatalogVendors(catalogId, warehouseId));
      const { data = [] } = response;
      if (prevRequestId == versionDict.vendorsRequestId) {
        this.setState({
          locations: data,
        });
      }
    } catch (err) {
      this.setState({
        locations: []
      });
    }
  };

  handleCatalogSelect = (selectedCatalog) => {
    const { warehouseId } = this.props;
    this.setState({
      selectedCatalog
    }, () => {
      if (!isEmpty(selectedCatalog)) {
        this.fetchVendorLocationsByCatalogId(selectedCatalog.value, warehouseId);
      } else {
        this.setState({
          locations: []
        });
      }
    });
  };

  fetchVendorLocationByName = debounce(async (query = "", warehouseId) => {
    try {
      versionDict.vendorLocationRequestId += 1;
      let prevRequestId = versionDict.vendorLocationRequestId;
      const response = await apiCall.sendRequest("get", fetchVendorLocationsByQuery(query, warehouseId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.vendorLocationRequestId) {
        this.setState({
          locations: data,
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        locations: []
      });
    }
  }, 50);

  handleSearchVendorLocation = (searchText) => {
    const { warehouseId } = this.props;
    this.setState({
      searchText,
    }, () => {
      if (searchText.length > 2) {
        this.fetchVendorLocationByName(searchText, warehouseId);
      }
    });
  };

  renderSearchTypeSelection = () => {
    const { selectedOption, selectedCatalog } = this.state;
    const { warehouseId } = this.props;
    if (!isEmpty(selectedOption)) {
      switch (selectedOption.value) {
        case 'catalog':
          return (
            <div>
              <Typography variant="caption"> Select Catalog </Typography>
              <CatalogSelector selected={selectedCatalog} onSelect={this.handleCatalogSelect} warehouseId={warehouseId} />
            </div>
          );
        case 'vendor_location':
          return (
            <div>
              <Typography variant="caption"> Search Location (By Name) </Typography>
              <Search
                placeholder="type to search product"
                allowClear
                enterButton
                size="large"
                onSearch={this.handleSearchVendorLocation}
              />
            </div>
          );
      }
    } else {
      return null;
    }
  };

  handleSelectVendorLocation = (selectedLocation) => {
    const { onSelect } = this.props;
    onSelect(selectedLocation);
    this.handleCloseLocationSelector();
  };

  truncate = (text, len) => text.slice(0, len) + (text.length > len ? '...' : '')

  render() {
    const { locations } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.handleCloseLocationSelector}
        onBackdropClick={this.handleCloseLocationSelector}
      >
        <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '70%',
          height: '70%',
        }}>
          <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.055, borderRadius: "5px", position: "fixed", width: "100%", top: 0 }}>
            <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: window.innerWidth * 0.02, paddingTop: "10px" }}>
              <Typography variant="title" color="inherit"> Vendor Location Selector </Typography>
            </div>
            <Button style={{ float: 'right' }} onClick={this.handleCloseLocationSelector} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </div>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={12}>
                <Row>
                  <ColMod lg={4}>
                    <Typography variant="caption"> Select Search Type </Typography>
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      options={SearchTypes}
                      placeholder="Choose a type"
                      onChange={this.handleChangeOption}
                    />
                  </ColMod>
                  <ColMod lg={8}>
                    {this.renderSearchTypeSelection()}
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowX: "auto", overflowY: "auto", height: window.innerHeight * 0.45 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Location ID</TableCell>
                            <TableCell>Location Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {locations.map((el, index) => (
                            <TableRow key={index}>
                              <TableCell>{el.id}</TableCell>
                              <TableCell>{el.name}</TableCell>
                              <Tooltip title={el.fullAddress} key={el.id}>
                                <TableCell>
                                  {this.truncate(el.fullAddress, 50)}
                                </TableCell>
                              </Tooltip>
                              <TableCell>
                                <Button variant="contained" color="primary" onClick={() => this.handleSelectVendorLocation(el)}>
                                  Select
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div>
      </Modal>
    );
  }
}

VendorLocationSelector.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

export default VendorLocationSelector;
