import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CitySelector from './City/Selector';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 4,
  },
  calendar: {
    flex: 1,
    textAlign: 'center',
  },
  add: {
    flex: 1,
    textAlign: 'center',
    marginLeft: '40px'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchField: {
    flex: 5,
  },
  citySelector: {
    flex: 2,
    marginLeft: '10px'
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
  },
};

function HeaderSearchBar(props) {
  const {
    title = 'Building',
    query,
    handleQueryChange,
    onSearch,
    buttonName = 'New Building',
    openAddBuilding,
    onSelectCity,
    selectedCity,
    clearSearch
  } = props;
  return (
    <div style={styles.div}>
      <div style={styles.title}>
        <Typography variant="title" color="inherit">
          {title}
        </Typography>
      </div>
      <div style={styles.searchBar}>
        <div style={styles.searchContainer}>
          <div style={styles.searchField}>
            <TextField
              id="input-with-icon-textfield"
              label="Quick Search"
              fullWidth
              value={query}
              onChange={handleQueryChange}
              onKeyPress={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={styles.citySelector}>
            <Typography variant="caption">City</Typography>
            <CitySelector
              onSelect={onSelectCity}
              selected={selectedCity}
            />
          </div>
          <div style={styles.clear}>
            <Button color="secondary" onClick={clearSearch}>Clear</Button>
          </div>
        </div>
      </div>
      <div style={styles.add}>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => {
            openAddBuilding(null);
          }}
        >
          {buttonName}
        </Button>
      </div>
    </div>
  );
}

export default HeaderSearchBar;
