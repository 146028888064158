import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import {
  Drawer,
  Modal,
  Typography,
  Button
} from '@material-ui/core';
import apiCall, { getTotMarginsByFilters, downloadTotMarginCSV, uploadTotMarginCSV, getHistory } from '../../../api/NetworkHandler';
import TOTMarginList from '../components/TOTMarginList';
import CreateTOTMargin from '../components/CreateTOTMargin';
import TOTMarginSearch from '../components/TOTMarginSearch';
import moment from 'moment';
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import { ColMod } from '../../../components/lib';
import WarehouseSelector from '../../../components/Warehouse/Selector';
import CancelIcon from '@material-ui/icons/Cancel';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import HistoryContainer from '../../History/containers/HistoryContainer';

import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const styles = {
  container: {
    margin: 0,
  },
};

var versionDict = {
  "totMarginRequestId": 0
};

class TOTMarginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTotMarginForm: false,
      totMarginData: [],
      page: 0,
      rowsPerPage: 25,
      count: 0,
      isLoading: false,
      selectedTotMargin: null,
      filters: {
        name: '',
        marginType: 'ALL',
      },
      disableButton: false,
      selectedWarehouse: null,
      openWarehouseSelectionModal: false,
      loadingProgress: false,
      loadingText: '',
      showHistory: false,
      historyCcount: 0,
      historyPage: 0,
      historyRowsPerPage: ROWS_PER_PAGE,
      historyRowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      data: [],
    };
  };

  componentDidMount() {
    this.setState({
      openWarehouseSelectionModal: true,
    });
  };

  handleChangeWarehouse = (selectedWarehouse) => {
    this.setState({
      selectedWarehouse,
    });
  };

  handleSelectWarehouse = () => {
    this.setState(prevState => {
      const { selectedWarehouse } = prevState;

      return {
        openWarehouseSelectionModal: selectedWarehouse ? false : true,
      }
    }, () => {
      const { selectedWarehouse } = this.state;
      if (selectedWarehouse) {
        this.fetchAllTotMargins();
      }
    });
  }

  fetchAllTotMargins = async () => {
    const { filters, page, rowsPerPage: size, selectedWarehouse } = this.state;
    const verifiedFilters = { name: filters.name, marginType: filters.marginType === 'ALL' ? '' : filters.marginType, warehouseId: selectedWarehouse.value }
    this.toggleProgressBar(true);
    try {
      versionDict.totMarginRequestId += 1;
      let prevRequestId = versionDict.totMarginRequestId;
      const response = await apiCall.sendRequest('get', getTotMarginsByFilters(verifiedFilters, page, size));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (prevRequestId == versionDict.totMarginRequestId) {
        this.setState({
          totMarginData: content,
          count: totalElements
        });
      }
    } catch (error) {
      console.log('error', error);
      this.setState({
        totMarginData: [],
        count: 0,
      });
    }
    this.toggleProgressBar(false);
  };

  handleSearch = () => {
    this.fetchAllTotMargins();
  };

  handleOpenTotMarginForm = () => {
    this.setState({
      openTotMarginForm: true,
      selectedTotMargin: {},
    });
  };

  handleCloseTotMarginForm = () => {
    this.setState({
      openTotMarginForm: false,
      selectedTotMargin: null,
    });
  };

  handleEditTotMargin = (selectedTotMargin) => {
    this.setState({
      openTotMarginForm: true,
      selectedTotMargin,
    });
  };

  toggleProgressBar = (state) => {
    this.setState({
      isLoading: state,
    });
  };

  handleQueryChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  };

  handleClearFilters = () => {
    this.setState({
      filters: {
        name: '',
        marginType: 'ALL',
      }
    }, () => this.fetchAllTotMargins());
  };

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => this.handleSearch());
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({
      rowsPerPage
    }, () => this.handleSearch());
  };

  toggleButtonAndProgressState = (buttonState, loading, text) => {
    this.setState({
      disableButton: buttonState,
      loadingProgress: loading,
      loadingText: text
    });
  };

  handleDownlodTOtMarginCSV = debounce(async () => {
    const { selectedWarehouse } = this.state;
    this.toggleButtonAndProgressState(true, true, 'Downloading in progress..');
    const url = downloadTotMarginCSV(selectedWarehouse.value);
    const filename = `TOT-Margin-${moment().format("DD-MM-YYYY_HH:mm")}.csv`;
    try {
      const response = await apiCall.sendRequest('post', url, [], { Accept: 'text/csv' });
      const { data = {} } = response;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
    } catch (err) {
      console.log(err.response);
    }
    this.toggleButtonAndProgressState(false, false, '');
  }, 500);

  handleUploadTotMarginCSV = async (uploadedFile) => {
    const { selectedWarehouse } = this.state;
    this.toggleButtonAndProgressState(true, true, 'Uploading in progress..');
    try {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      await apiCall.sendRequest('post', uploadTotMarginCSV(selectedWarehouse.value), formData);
      this.fetchAllTotMargins();
    } catch (e) {
      // error handling
      console.error(e.response);
    }
    this.toggleButtonAndProgressState(false, false, '');
  };

  handleCloseWarehouseSelection = () => {
    const { history } = this.props;
    history.goBack();
  };
  setEntityId = (data) => {
    const { id } = data;
    this.setState({
      entityId: id,
      historyCount: 0,
      historyPage: 0,
      historyRowsPerPage: ROWS_PER_PAGE,
      historyRowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    }, () => {
      this.onViewHistory()
    });
  }
  onViewHistory = () => {
    const { historyPage, historyRowsPerPage, entityId } = this.state;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": 'TotMarginHistory',
        "params": JSON.stringify({
          "entityId": entityId,
          "limit": historyRowsPerPage,
          "page": historyPage,
        })
      })
    )
      .then((response) => {
        let { data } = response.data;
        // data = JSON.parse(data);
        const { getTOTMargin, totalElements } = data;
        this.setState({
          data: getTOTMargin,
          showHistory: true,
          historyCount: totalElements,
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }
  handleHistoryChangePage = (event, historyPage) => {
    this.setState({ historyPage }, () => { this.onViewHistory() });
  };

  handleHistoryChangeRowsPerPage = (event) => {
    const { value: historyRowsPerPage } = event.target;
    this.setState({ historyRowsPerPage }, () => { this.onViewHistory() });
  };
  handleCloseHistory = () => {
    this.setState({
      showHistory: false,
    });
  };

  render() {
    const { classes, marginTypes } = this.props;
    const {
      openTotMarginForm,
      totMarginData,
      rowsPerPage,
      page,
      count,
      isLoading,
      selectedTotMargin,
      filters,
      disableButton,
      openWarehouseSelectionModal,
      selectedWarehouse,
      loadingProgress,
      loadingText,
      showHistory,
      data,
      historyCount,
      historyRowsPerPage,
      historyPage,
      historyRowsPerPageOptions,
    } = this.state;

    const isNoData = totMarginData.length === 0;

    return (
      <div>
        <NavBar />
        {!openWarehouseSelectionModal &&
          <React.Fragment>
            <Spin spinning={loadingProgress} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} tip={<span style={{ color: 'black' }}>{loadingText}</span>}>
              <ProgressBar isLoading={isLoading} />
              <TOTMarginSearch
                onCreateTOTMargin={this.handleOpenTotMarginForm}
                onSearch={this.handleSearch}
                onChange={this.handleQueryChange}
                filters={filters}
                onClear={this.handleClearFilters}
                marginTypes={marginTypes}
                onDownloadTotMargin={this.handleDownlodTOtMarginCSV}
                onUploadTotMargin={this.handleUploadTotMarginCSV}
                disableButton={disableButton}
                selectedWarehouse={selectedWarehouse}
              />
              <Grid className={classes.container} fluid>
                <Row lg={12}>
                  <Col lg={12}>
                    <TOTMarginList
                      totMarginData={totMarginData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      count={count}
                      onEdit={this.handleEditTotMargin}
                      isNoData={isNoData}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      viewHistory={this.setEntityId}
                    />
                  </Col>
                </Row>
              </Grid>
              <Drawer anchor="right" open={openTotMarginForm} onClose={this.handleCloseTotMarginForm}>
                <CreateTOTMargin
                  onClose={this.handleCloseTotMarginForm}
                  onRefresh={this.fetchAllTotMargins}
                  selectedTotMargin={selectedTotMargin}
                  marginTypes={marginTypes}
                  selectedWarehouse={selectedWarehouse}
                />
              </Drawer>
            </Spin>
          </React.Fragment>
        }
        <Modal
          open={openWarehouseSelectionModal}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Grid
            fluid
            style={{
              background: 'white',
              width: window.innerWidth < 575 ? window.innerWidth * 0.9 : window.innerWidth * 0.3,
              margin: '2em'
            }}
          >
            <Row xs={12}>
              <ColMod xs={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="title"> Select Warehouse </Typography>
                  <CancelIcon style={{ color: 'red' }} onClick={this.handleCloseWarehouseSelection} />
                </div>
              </ColMod>
              <ColMod xs={12}>
                <WarehouseSelector
                  onSelect={this.handleChangeWarehouse}
                  selected={selectedWarehouse}
                />
              </ColMod>
            </Row>
            <Row xs={12} end="xs">
              <ColMod xs={5}>
                <Button color="primary" variant="contained" onClick={this.handleSelectWarehouse}> Select </Button>
              </ColMod>
            </Row>
          </Grid>
        </Modal>
        <HistoryContainer openHistory={showHistory} handleClose={this.handleCloseHistory}
          data={data}
          title='TOT Margin History'
          count={historyCount}
          page={historyPage}
          rowsPerPage={historyRowsPerPage}
          onChangePage={this.handleHistoryChangePage}
          historyRowsPerPageOptions={historyRowsPerPageOptions}
          onChangeRowsPerPage={this.handleHistoryChangeRowsPerPage}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { Status } = state;
  const { marginTypes = {} } = Status;
  return {
    marginTypes,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(TOTMarginContainer));