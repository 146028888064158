import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  TablePagination,
  TextField,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import CloseIcon from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const columnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'PR Number',
  },
  {
    id: 'productCatalogName',
    numeric: false,
    disablePadding: false,
    label: 'Catalog',
  },
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: false,
    label: 'Warehouse',
  },
  {
    id: 'totalQuantity',
    numeric: false,
    disablePadding: false,
    label: 'Quantity',
  },
  {
    id: 'timeCreated',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: 'totalLineItemPrice',
    numeric: false,
    disablePadding: false,
    label: 'Sell Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const prColumnData = [
  {
    id: 'productName',
    numeric: false,
    disablePadding: false,
    label: 'Items',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: false,
    label: 'Qty',
  },
];


const styles = {
  headerStyle: {
    textAlign: 'center',
  },
  cellStyle: {
    textAlign: 'left',
  },
};

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns = [] } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

class PRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      subTableOrder: 'asc',
      subTableOrderBy: '',
      data: [],
      rowSelected: {},
      itemsList: [],
      searchQuery: '',
      hideSidePanel: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    return {
      data,
    };
  }

  handleClick = (item) => {
    this.setState({
      rowSelected: item,
      itemsList: item.purchaseRequisitionLineItems,
      searchQuery: '',
      hideSidePanel: false,
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  onInputChange = (event) => {
    this.setState({
      searchQuery: event.target.value,
    }, () => this.filterList());
  }

  filterList() {
    let items = this.state.rowSelected.purchaseRequisitionLineItems;
    const q = this.state.searchQuery;

    items = items.filter(item =>
      item.productName.toLowerCase().indexOf(q) !== -1);
    this.setState({ itemsList: items });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSubTableRequestSort = (event, property) => {
    const subTableOrderBy = property;
    let subTableOrder = 'desc';

    if (this.state.subTableOrderBy === property && this.state.subTableOrder === 'desc') {
      subTableOrder = 'asc';
    }

    const itemsList =
    subTableOrder === 'desc'
        ? this.state.itemsList.sort((a, b) => (b[subTableOrderBy] < a[subTableOrderBy] ? -1 : 1))
        : this.state.itemsList.sort((a, b) => (a[subTableOrderBy] < b[subTableOrderBy] ? -1 : 1));

    this.setState({ itemsList, subTableOrder, subTableOrderBy });
  };

  handleHideSidePanel = () => {
    this.setState({
      hideSidePanel: true,
    });
  }

  renderLineItems = () => {
    const { rowSelected = {}, searchQuery = '', itemsList = [], subTableOrder, subTableOrderBy } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="title" color="inherit"> PR Details </Typography>
          <Tooltip title="Hide"><CloseIcon onClick={this.handleHideSidePanel} /></Tooltip>
        </div>
        <div>
          <Typography variant="body1" color="inherit" style={{ marginTop: '10px', paddingBottom: '10px' }}>
            <b>Created On:</b> {moment(rowSelected.timeCreated).format('lll')}
            <br />
            <b>Updated On:</b> {moment(rowSelected.timeUpdated).format('lll')}
          </Typography>
          <TextField
              style={{ marginTop: '10px', paddingBottom: '10px' }}
              id="input-with-icon-textfield"
              placeholder="Search"
              fullWidth
              name="query"
              onChange={this.onInputChange}
              value={searchQuery}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
                columns={prColumnData}
                numSelected={rowSelected.length}
                order={subTableOrder}
                orderBy={subTableOrderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleSubTableRequestSort}
                rowCount={itemsList.length}
                cellStyle={styles.headerStyle}
              />
            <TableBody>
              {itemsList &&
                itemsList.map(n => (
                  <TableRow role="checkbox" tabIndex={-1} key={n.id}>
                    <TableCell component="th" scope="row" padding="default">
                      {n.productName}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      {n.quantity}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  render() {
    const {
      totalElements, rowsPerPage, page = 0, rowsPerPageOptions,
    } = this.props;
    const { rowSelected = {}, order, orderBy, data, hideSidePanel } = this.state;
    const isSelected = data.some(el => el.id === rowSelected.id);
    return (
      <Grid container>
        <div style={{ width: (Object.keys(rowSelected).length > 0 && !hideSidePanel) ? '75%' : '98%', float: 'left', height: window.innerHeight * 0.65, overflowY: 'scroll', paddingLeft: '10px' }}>
          <Table>
            {/* <TableHeader columns={columnData} cellStyle={styles.cellStyle} /> */}
            <EnhancedTableHead
              columns={columnData}
              numSelected={rowSelected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              cellStyle={styles.headerStyle}
            />
            <TableBody>
              {data.map(item => (
                <TableRow
                  hover
                  onClick={() => this.handleClick(item)}
                  role="checkbox"
                  aria-checked={rowSelected.id === item.id}
                  tabIndex={-1}
                  key={item.id}
                  selected={rowSelected && rowSelected.id === item.id}
                >
                  <TableCell style={styles.cellStyle}>{item.id}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.productCatalogName}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.warehouseName}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.totalQuantity}</TableCell>
                  <TableCell style={styles.cellStyle}>
                    {moment(item.timeCreated).format('ddd, DD MMM YYYY')}
                  </TableCell>
                  <TableCell style={styles.cellStyle}>{Math.round((item.totalLineItemPrice + Number.EPSILON) * 100) / 100}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.status}</TableCell>
                  <TableCell style={styles.cellStyle}>
                    <Button color="primary" variant="contained" onClick={() => this.props.history.push(`/purchase-requisition/${item.id}`)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data && data.length > 0 ?
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
              : null
          }
        </div>
        {(rowSelected && isSelected && !hideSidePanel) && 
          <div style={{ width: '25%', float: 'right', height: window.innerHeight * 0.65, overflowY: 'scroll', padding: '10px' }}>
            {this.renderLineItems()}
          </div>
        }
      </Grid>
    );
  }
}

PRList.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default withRouter(PRList);
