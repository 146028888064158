import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';

const styles = {
  div:{
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center'
  },
  title:{
    flex: 1,
    marginLeft: '30px'
  },
  searchBar:{
    flex: 2,
  },
  add:{
    flex: 1,
    textAlign: 'center',
  },
  searchContainer:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField:{
    flex: 5
  },
  clear:{
    flex: 1,
    marginLeft: '10px',
    color: '#f50057'
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
};

class UserSearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendors: [],
			query: "",
		};
	}

	renderVendors = () => {
    return this.state.vendors.map(vendor => {
      return <MenuItem key={vendor.id} value={vendor}>{vendor.name}</MenuItem>;
    });
  }

	onChangeFilter = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    setTimeout(() => {
      this.props.onChangeFilter(this.state);
    }, 1000);
	}
	
	onChangeSearch = (event) => {
		const {name} = event.target;
		const {value} = event.target;
    this.setState({
			[name]: value
		});
	}

  render() {
		return (
			<div style={styles.div}>
				<div style={styles.title}>
				<Typography variant="title" color="inherit">
					Delivery Boys
				</Typography>
				</div>
				<div style={styles.searchBar}>
								<div style={styles.searchContainer}>
								<div style={styles.searchField}>
									<TextField
										id="input-with-icon-textfield"
										label="Quick Search"
										fullWidth
										name="query"
										onChange={this.onChangeSearch}
										value={this.state.query}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Search />
												</InputAdornment>
											),
										}}
									/>
									</div>
									<div style={styles.clear}>
										<Typography variant="body2" color="inherit">
											<Button color="secondary" variant="contained" onClick={() => { this.props.onSearch(this.state.query); }}>
												Search
											</Button>
										</Typography>
									</div>
									<div style={styles.clear}>
										<Typography variant="body2" color="inherit">
											<Button color="secondary" onClick={() => { this.setState({ query: "" }, () => { this.props.onSearch(this.state.query); }); }}>
												Clear All
											</Button>
										</Typography>
									</div>
								</div>
							</div>
				<div style={styles.add}>
					<Button variant="contained" size="large" color="secondary" onClick={() => { this.props.openAddDeliveryBoy(null); }}>Add Delivery Boy</Button>
				</div>
			</div>
		);
	}
}

export default UserSearchBar;