import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import GRNSearch from '../component/GRNSearch';
import GRNList from '../component/GRNList';
import apiCall, { grnListByFilters, getGrnWithFilters } from '../../../api/NetworkHandler';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { withRouter } from 'react-router-dom';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';

const Constants = {
  title: 'GRN',
};
class GRNContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grnList: [],
      filters: {
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      totalElements: 0,
      currentPage: 0,
      rowsPerPage: 25,
    };
  }

  componentDidMount= () => {
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.getGrnDataByFilters(getFilterParams);
    }


  onSearch = (page) => {
    this.setState({
      grnList: [],
      currentPage: page,
    });
    this.getGrnDataByFilters(page);
  };

  getGrnDataByFilters = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    try {
      const response = await apiCall.sendRequest('get', getGrnWithFilters(filters, page, size));
      const { data: { _embedded: { grn = [] } , page: { number = 0, totalElements = 0 }} } = response;
      this.setState({ 
        grnList: grn,
        currentPage: number,
        totalElements: totalElements,
      });
    } catch (error) {
      this.setState({ grnList: [] });
    }
  }


  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.onSearch() })
  }

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.onSearch() })
  }

  render() {
    const {
      filters, totalElements, currentPage, grnList, rowsPerPage, 
    } = this.state;
    const { grnStatuses } = this.props;

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <GRNSearch grnStatuses={grnStatuses} />
        </SearchBar>
        <GRNList
          grnList={grnList}
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={currentPage}
          onPageChange={this.onPageChange}
          onRowsPerPageChange={this.onRowsPerPageChange}
        />
      </div>
    );
  }
}

GRNContainer.propTypes = {
  grnStatuses: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { grnStatuses } = Status;
  return {
    grnStatuses,
  };
};

export default connect(mapStateToProps, null)(withRouter(GRNContainer));

