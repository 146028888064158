import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import ItemText from '../ItemText';
import styles from './styles';
import Lozenge from '@atlaskit/lozenge';
import apiCall, { fetchStaticRouteByIdPath, getHistory } from '../../../api/NetworkHandler';
import HistoryIcon from '@material-ui/icons/History';
import { Button, Tooltip } from '@material-ui/core';
import HistoryContainer from '../../History/containers/HistoryContainer';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';


class AddressInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      route: {},
      data: [],
      showHistory: false,
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    const { address = {} } = this.props;
    const { staticRouteId = null } = address;
    staticRouteId && this.fetchStaticRouteById(staticRouteId);
  }

  fetchStaticRouteById = async (id) => {
    try {

      const response = await apiCall.sendRequest('get', fetchStaticRouteByIdPath(id));
      const { data = {} } = response;
      this.setState({
        route: data,
      });
    } catch (err) {
      this.setState({
        route: {},
      });
    }
  }

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  getVerifiedValue = (verified) => {
    return <Lozenge appearance={verified ? "success" : "removed"} style={styles.city}>{verified ? 'Yes' : 'No'}</Lozenge>
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.viewHistory() });
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ page:0 ,rowsPerPage}, () => { this.viewHistory() });
  };
  setAddress = (data) => {
    const { planItemId } = data;
    this.setState({
      planItemId: planItemId,
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    }, () => {
      this.viewHistory()
    });
  }
  viewHistory = () => {
    const { address } = this.props;
    const { id, customerId } = address;
    const { page, rowsPerPage } = this.state;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": 'AddressHistory',
        "params": JSON.stringify(
          {
            'addressId': id,
            'customerId': customerId,
            'limit': rowsPerPage,
            'page': page,
          }
        )
      })
    )
      .then((response) => {
        let { data } = response.data;
        // data = JSON.parse(data);
        const { AddressPlans, TotalElements } = data;
        this.setState({
          data: AddressPlans,
          showHistory: true,
          count: TotalElements,
        });
      })
      .catch((error) => {
        console.error(error);
      });


  }
  // deleteAddress = (event) => {
  //   const { address } = this.props;
  //   address.active = false;
  //   event.stopPropagation();
  //   this.props.updateAddressChange(address);
  // };

  handleCloseHistory = () => {
    this.setState({
      showHistory: false,
    });
  };
  render() {
    const {
      address, inList = false, style, onSelect, staticRoutes = {},
    } = this.props;
    const { hover, route = {},
    showHistory,count,rowsPerPage,rowsPerPageOptions,data,page ,
  } = this.state;
    const { name = '', id = null } = route;
    const {
      fullAddress, localityName, cityName, staticRouteId, isDefault, verified,
    } = address;
    let addressInfoStyle =
      inList && hover
        ? {
          ...styles.addressInfo, ...style, ...styles.hover,
        }
        : { ...styles.addressInfo, ...style };
    addressInfoStyle =
      inList && isDefault ? { ...addressInfoStyle, ...styles.defaultInfo } : addressInfoStyle;

    return (
      <Paper
        style={addressInfoStyle}
        onClick={() => onSelect(address)}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
      >
        {inList && isDefault && <div style={styles.default}>DEFAULT ADDRESS</div>}
        <ItemText name="Customer Address" value={fullAddress.split(",").filter(el => el).join(", ")} style={styles.fulladdress} />
        {staticRouteId && <ItemText name="Route" value={staticRouteId == id ? name : ''} style={styles.city} />}
        <ItemText name="Locality" value={localityName} style={styles.city} />
        <ItemText name="City" value={cityName} style={styles.city} />
        <ItemText name="Verified" value={this.getVerifiedValue(verified)} style={styles.city} />
        <div style={styles.city}>
          <Tooltip title="History">
            <Button
             onClick={(e) => {
              e.stopPropagation();  this.viewHistory()}}
              color="primary"
              variant="outlined"
              size="small"
              style={styles.actionBtn} >
              <HistoryIcon style={styles.actionIcon} />
            </Button>
          </Tooltip>
        </div>
        <HistoryContainer
          openHistory={showHistory}
          handleClose={this.handleCloseHistory}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={this.handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          data={data}
          title='Address History'
        />
      </Paper >


    );
  }
}
AddressInfo.propTypes = {
  address: PropTypes.instanceOf(Object),
  inList: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  staticRoutes: PropTypes.instanceOf(Object),
  updateAddressChange: PropTypes.func,
  openModal: PropTypes.func,
};


const mapStateToProps = (state) => {
  const { information } = state;
  const { staticRoutes } = information;
  return {
    staticRoutes,
  };
};

export default connect(mapStateToProps, null)(AddressInfo);
