import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons/';
import InputDir from '../InputDir';
import AddressInfo from './AddressInfo';
import apiCall, { allCitiesPath, getPincodeAbsent, validatePincodeByCity, getDeletedCustomerAddresses } from '../../../api/NetworkHandler';
import styles from './styles';
import { isStaticRouteChangeAllowed, showStaticRouteChangeErrorDialog } from "../../../utils/staticRoutePermission";
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import DeleteIcon from '@material-ui/icons/Delete';

const addressTypes = [
  { text: "Apartment", value: "APARTMENT" },
  { text: "Individual House", value: "INDIVIDUAL_HOUSE" },
];

var versionDict = {
  "pincodeAbsentRequestId": 0,
  "pincodeValidationRequestId": 0,
}
class EditAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtnDisabled: true,
      changeAddressOpen: true,
      addAddressOpen: false,
      cityList: [],
      selectedCity: '',
      selectedLocality: '',
      floorErrorText: 'Please enter floor number only',
      pincodeAbsent: false,
      staticRouteChangeTimeMin: '',
      staticRouteChangeTimeMax: '',
      isPincodeValid: true,
      selectedPincode: '',
      openDeletedAddress: false,
      deletedAddresses: []
    };
  }

  componentDidMount() {
    this.getCityList();
    this.getPincodeAbsentStatus();
    this.setTimeState();
  }

  setTimeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = appConfig;
    this.setState({
      staticRouteChangeTimeMin,
      staticRouteChangeTimeMax,
    });
  };

  getPincodeAbsentStatus = async () => {
    try {
      versionDict.pincodeAbsentRequestId += 1;
      let prevRequestId = versionDict.pincodeAbsentRequestId;
      const response = await apiCall.sendRequest('get', getPincodeAbsent);
      const { data: { data: { pincodeAbsent = false } = {} } = {} } = response;
      if (prevRequestId == versionDict.pincodeAbsentRequestId) {
        this.setState({
          pincodeAbsent,
        });
      }
    } catch (err) {
      this.setState({
        pincodeAbsent: false,
      });
    }
  };

  validatePincode = async (pincode, city, regionId) => {
    try {
      versionDict.pincodeValidationRequestId += 1;
      let prevRequestId = versionDict.pincodeValidationRequestId;
      const response = await apiCall.sendRequest('get', validatePincodeByCity(regionId, city, pincode))
      const { data: { data: { valid = false } = {} } = {} } = response;
      if (prevRequestId == versionDict.pincodeValidationRequestId) {
        this.setState({
          isPincodeValid: valid,
          selectedPincode: pincode,
          updateBtnDisabled: valid ? false : true,
        });
      }
    } catch (err) {
      this.setState({
        isPincodeValid: true,
        selectedPincode: '',
        updateBtnDisabled: true,
      });
    }
  };

  onChangeHandler = (e) => {
    const { addAddressOpen, selectedCity, isPincodeValid } = this.state;
    let { name, value } = e.target;
    const { selectedAddress, onAddressEdit, newAddress, defaultAddress } = this.props;
    isPincodeValid && this.setState({ updateBtnDisabled: false });

    if (name === 'ringBell' || name === 'active' || name === 'verified') {
      value = addAddressOpen ? !newAddress[name] : !selectedAddress[name];
    }

    if (name === 'cityName' && value) {
      this.setState({ selectedCity: value });
    }

    if (name === 'localityName') {
      this.setState({ selectedLocality: value });
    }

    if (name === 'pincode') {
      const { regionId } = defaultAddress;
      value.length > 3 && this.validatePincode(value, selectedCity, regionId);
    }

    if (addAddressOpen) {
      onAddressEdit({ [name]: value }, true);
    } else {
      onAddressEdit({ [name]: value });
    }
  };

  onClickHandler = (type) => {
    const { newAddress = {}, selectedAddress = {}, defaultAddress = {} } = this.props;
    const { addAddressOpen, isPincodeValid } = this.state;
    let conditionalAddress = addAddressOpen ? { ...newAddress, regionId: defaultAddress.regionId } : selectedAddress;
    if (addAddressOpen && isPincodeValid && type === 'localityName') {
      this.setState({ updateBtnDisabled: false });
      this.props.openModal('localitySearch', 'address', { obj: conditionalAddress, isNew: addAddressOpen });
    }
    if (type === 'buildingName') {
      this.props.openModal('buildingSearch', 'address', { cityName: addAddressOpen ? newAddress.cityName : selectedAddress.cityName, localityName: addAddressOpen ? newAddress.localityName : selectedAddress.localityName });
    }
    if (addAddressOpen && isPincodeValid && type === 'sublocalityName') {
      this.setState({ updateBtnDisabled: false });
      this.props.openModal('subLocalitySearch', 'address', { obj: conditionalAddress, isNew: addAddressOpen });
    }
  };

  onChangeAddress = () => {
    this.setState({
      changeAddressOpen: true,
      addAddressOpen: false,
      openDeletedAddress: false,
    });
  };

  getCityList = () => {
    apiCall.sendRequest('get', allCitiesPath).then(({ data }) => {
      const { _embedded } = data;
      const { cities } = _embedded;
      this.setState({
        cityList: cities.map(el => ({
          text: el.name,
          value: el.name,
        })),
      });
    });
  };

  getLineItemsConfig = () => [
    {
      label: 'Contact Name',
      type: 'text',
      name: 'customerName',
      disabled: true,
    },
    {
      label: 'Contact Number',
      type: 'number',
      name: 'mobile',
      disabled: true,
    },
    {
      label: 'City',
      type: 'select',
      name: 'cityName',
      options: this.state.cityList,
      disabled: typeof this.props.selectedAddress !== "undefined" && this.props.selectedAddress.cityName && !this.state.addAddressOpen,
    },
    {
      label: 'Pincode',
      type: 'number',
      name: 'pincode',
      error: !this.state.isPincodeValid,
      helperText: !this.state.isPincodeValid && 'Please enter valid pincode',
      disabled: !this.state.addAddressOpen,
    },
    {
      label: 'Locality',
      type: 'search',
      name: 'localityName',
      helperText: 'Please select city and picnode first',
      disabled: !this.state.isPincodeValid || !this.state.addAddressOpen,
    },
    {
      label: 'Sub Locality Name',
      type: 'search',
      name: 'sublocalityName',
      helperText: 'Please select city, picnode and locality first',
      disabled: !this.state.isPincodeValid || !this.state.addAddressOpen,
    },
    {
      label: 'Building Type',
      type: 'select',
      name: 'type',
      options: addressTypes,
    },
    {
      label: 'Building Name',
      type: 'text',
      name: 'buildingName',
    },
    {
      label: 'Block Name',
      type: 'text',
      name: 'blockName',
    },
    {
      label: 'Street Details',
      type: 'text',
      name: 'streetDetails',
    },
    {
      label: 'Floor',
      type: 'number',
      name: 'floor',
      helperText: this.state.floorErrorText,
    },
    {
      label: 'House Number',
      type: 'text',
      name: 'houseNumber',
    },
    {
      label: 'Landmark',
      type: 'text',
      name: 'landmark',
    },
    {
      label: 'Latitude',
      type: 'number',
      name: 'latitude',
      disabled: true,
    },
    {
      label: 'Longitude',
      type: 'number',
      name: 'longitude',
      disabled: true,
    },
    {
      label: 'Address Status',
      type: 'select',
      name: 'status',
      options: this.formatConsts(this.props.addressSatusList),
    },
    {
      name: 'ringBell',
      label: 'Ring Bell',
      type: 'switch',
    }
  ];

  changeSelectedAddress = (obj) => {
    this.props.changeSelectedAddress(obj);
    this.setState({
      changeAddressOpen: false,
      updateBtnDisabled: true,
    });
  };

  formatConsts = (obj) => {
    const arr = Object.keys(obj);
    return arr.map(el => ({
      text: obj[el],
      value: el,
    }));
  };

  addAddress = () => {
    this.setState({
      addAddressOpen: true,
      changeAddressOpen: false,
      updateBtnDisabled: true,
    });
  };

  updateAddressChange = () => {
    const { selectedAddress, newAddress, customerId, customerInfo = {} } = this.props;
    const { name = '', mobile = null } = customerInfo;
    const { addAddressOpen, pincodeAbsent } = this.state;
    if (addAddressOpen) this.props.updateAddressChange({ ...newAddress, pincode: pincodeAbsent ? '' : newAddress.pincode, customerName: name, mobile }, customerId, true);
    else this.props.updateAddressChange({ ...selectedAddress, pincode: pincodeAbsent ? '' : selectedAddress.pincode, customerName: name, mobile });
    this.setState({
      addAddressOpen: false,
      changeAddressOpen: true,
      updateBtnDisabled: true,
    });
  };

  openRouteModal = () => {
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = this.state;
    if (isStaticRouteChangeAllowed(staticRouteChangeTimeMin, staticRouteChangeTimeMax)) {
      this.props.openModal('routeSearch', 'address');
    } else {
      showStaticRouteChangeErrorDialog(staticRouteChangeTimeMin, staticRouteChangeTimeMax);
    }
  };

  onDeletedAddress = async () => {
    const { customerId } = this.props;
    try {
      const response = await apiCall.sendRequest('get', getDeletedCustomerAddresses(customerId));
      const { data = [] } = response;
      this.setState({
        deletedAddresses: data,
      }, () => this.handleOpenDeletedAddress());
    } catch (err) {
      this.setState({
        deletedAddresses: []
      });
    }
  };

  handleOpenDeletedAddress = () => {
    this.setState({
      openDeletedAddress: true,
      addAddressOpen: false,
      changeAddressOpen: false,
    });
  };

  handleCloseDeletedAddress = () => {
    this.setState({
      openDeletedAddress: false,
      addAddressOpen: false,
      changeAddressOpen: true,
    });
  };

  render() {
    const {
      selectedAddress = {},
      newAddress = {},
      customerId,
      customerAddresses,
      setDefaultAddress,
      customerInfo,
    } = this.props;
    const { name = '', mobile = null } = customerInfo;
    const { updateBtnDisabled, changeAddressOpen, addAddressOpen, pincodeAbsent, openDeletedAddress, deletedAddresses } = this.state;
    const { id, isDefault, staticRouteId } = selectedAddress;
    const modifiedNewAddress = { ...newAddress, customerName: name, mobile };
    const modifiedSelectedAddress = { ...selectedAddress, customerName: name, mobile };
    const conditionalLineItemConfigs = this.getLineItemsConfig().filter(el => {
      if (pincodeAbsent) {
        return el.name !== 'pincode';
      } else {
        return el;
      }
    });

    return (
      <div>
        <div style={styles.flexbox}>
          {!(addAddressOpen && changeAddressOpen && openDeletedAddress) && (
            <ArrowBack style={styles.goBack} onClick={this.onChangeAddress} />
          )}
          <Typography style={styles.title}>EDIT CUSTOMER ADDRESS</Typography>
        </div>
        {!(addAddressOpen || changeAddressOpen || openDeletedAddress) && (
          <div style={styles.addressInfoWrap}>
            <AddressInfo address={selectedAddress} updateAddressChange={this.props.updateAddressChange}
              openModal={this.props.openModal}
              key={Date.now()} />
            <div style={styles.changeAddress}>
              {isDefault && <div style={styles.defaultInfoView}>DEFAULT</div>}
              {!isDefault && (
                <Button
                  style={styles.adBtn1}
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => setDefaultAddress(customerId, id)}
                >
                  Set As Default
                </Button>
              )}
              <Button
                style={styles.adBtn1}
                color="primary"
                variant="outlined"
                onClick={this.openRouteModal}
                size="small"
              >
                {!staticRouteId ? 'Set Route' : 'Change Route'}
              </Button>
            </div>
          </div>
        )}

        {(!changeAddressOpen && !openDeletedAddress) && (
          <div style={styles.edit}>
            <Typography color="textSecondary" style={styles.subTitle}>
              {addAddressOpen ? 'NEW ADDRESS' : 'ADDRESS DETAILS'}
            </Typography>
            <div style={styles.editContent}>
              {conditionalLineItemConfigs.map((el) => {
                // if ((el.name === 'cityName' || el.name === 'localityName') && !addAddressOpen) {
                //   return null;
                // }
                if (
                  (el.name === 'verified' || el.name === 'active' || el.name === 'status') &&
                  addAddressOpen
                ) {
                  return null;
                }
                return (
                  <InputDir
                    {...el}
                    onChange={this.onChangeHandler}
                    onClick={() => this.onClickHandler(el.name)}
                    style={styles.inputDir}
                    value={addAddressOpen ? modifiedNewAddress[el.name] : modifiedSelectedAddress[el.name]}
                    inputStyle={styles.inputStyle}
                  />
                );
              })}
            </div>
            <div style={styles.action}>
              {/* <Button
                style={styles.btn}
                // onClick={}
                color="primary"
                variant="outlined"
                size="small"
              >
                Cancel
              </Button> */}
              <Button
                style={styles.btn}
                disabled={updateBtnDisabled}
                onClick={this.updateAddressChange}
                color="primary"
                variant="contained"
                size="small"
              >
                Update
              </Button>
            </div>
          </div>
        )}
        {changeAddressOpen && (
          <div style={styles.edit}>
            <Typography color="textSecondary" style={styles.subTitle}>
              SELECT ADDRESS
            </Typography>
            <div style={styles.editContent}>
              {customerAddresses.map(el => (
                <AddressInfo
                  address={el}
                  inList
                  style={styles.addressListItem}
                  onSelect={this.changeSelectedAddress}
                  updateAddressChange={this.props.updateAddressChange}
                  openModal={this.props.openModal}
                />
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                style={styles.adBtn3}
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.addAddress}
              >
                + ADD NEW ADDRESS
              </Button>
              <Button
                style={styles.adBtn3}
                color="secondary"
                variant="outlined"
                size="small"
                onClick={this.onDeletedAddress}
              >
                <DeleteIcon />
                DELETED ADDRESS
              </Button>
            </div>
          </div>
        )}
        {openDeletedAddress && (
          <div style={styles.edit}>
            <Typography color="textSecondary" style={styles.subTitle}>
              DELETED ADDRESS
            </Typography>
            {deletedAddresses.length > 0 ?
              <div style={styles.editContent}>
                {deletedAddresses.map(el => (
                  <AddressInfo
                    address={el}
                    inList
                    style={styles.addressListItem}
                    onSelect={() => { }}
                  />
                ))}
              </div>
              :
              <div style={styles.empty}>Customer has no deleted address.</div>
            }
          </div>
        )}
      </div>
    );
  }
}

EditAddress.propTypes = {
  customerId: PropTypes.number,
  selectedAddress: PropTypes.instanceOf(Object),
  newAddress: PropTypes.instanceOf(Object),
  onAddressEdit: PropTypes.func,
  addressSatusList: PropTypes.instanceOf(Object),
  updateAddressChange: PropTypes.func,
  changeSelectedAddress: PropTypes.func,
  customerAddresses: PropTypes.instanceOf(Array),
  setDefaultAddress: PropTypes.func,
  openModal: PropTypes.func,
};

export default EditAddress;
