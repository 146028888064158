import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  TextField,
  Select,
  Grid,
  Drawer,
  Typography,
  InputAdornment,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import EditSurvey from "./EditSurvey";
import { getApplicationConfigs } from '../../../utils/getAppConfig';
import { Modal as AntdModal, Input as AntdInput } from "antd";


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
  buttonAlignment: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
};

let clearFilters = false;

class SurveySearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      openEditSurvey: false,
      surveyGlobalCoolOffTime: null,
      openGlobalConfigSetter: false,
    };
  }

  componentDidMount() {
    this.setGlobalTtlState();
  }

  setGlobalTtlState = async () => {
    const appConfig = await getApplicationConfigs();
    const { surveyGlobalCoolOffTime } = appConfig;
    this.setState({
      surveyGlobalCoolOffTime,
    });
  };

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }


  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {};
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
    }, () => {
      clearFilters = !clearFilters;
      onButtonClick('search', 0);
    });
  }


  handleChange = (value, key) => {
    const { onFilterChange } = this.props;
    let newValue = value === 'all' ? '' : value;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [key]: newValue,
      },
    }));
    onFilterChange(key, newValue);
  }

  handleOpenEditSurvey = () => {
    this.setState({
      openEditSurvey: true,
    }, () => this.toggleEditSurvey(true));
  }

  toggleEditSurvey = (state) => {
    this.setState({
      openEditSurvey: state,
    });
  };

  toggleGlobalConfigSetter = (state) => {
    this.setState({
      openGlobalConfigSetter: state,
    }, () => { state && this.setGlobalTtlState() });
  };

  updateGlobalCoolOffTime = () => {
    const { onUpdateGlobalCoolOffTime } = this.props;
    const { surveyGlobalCoolOffTime } = this.state;
    onUpdateGlobalCoolOffTime(surveyGlobalCoolOffTime);
    this.toggleGlobalConfigSetter(false);
  };

  handleChangeGlobalCoolOffTime = (event) => {
    const { value } = event.target;
    if (value < 0) return;
    this.setState({
      surveyGlobalCoolOffTime: value,
    });
  };

  render() {
    const { onButtonClick, surveyTypes, surveyConditionTypes, onRefresh } = this.props;

    const { filters, openEditSurvey, openGlobalConfigSetter, surveyGlobalCoolOffTime } = this.state;

    const {
      question = '',
      type,
      active,
    } = filters;

    return (
      <div style={styles.container} key={clearFilters}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Quick Search (By Question)"
                  fullWidth
                  name="question"
                  onChange={e => this.handleChange(e.target.value, 'question')}
                  value={question.replace(/%20/g, " ")}
                  //   onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="caption"> Type </Typography>
                <Select
                  value={type || 'all'}
                  fullWidth
                  name="type"
                  onChange={e => this.handleChange(e.target.value, 'type')}
                >
                  <MenuItem value="all">All</MenuItem>
                  {Object.keys(surveyTypes).map((type, index) => (<MenuItem key={index} value={type}>{surveyTypes[type]}</MenuItem>))}
                </Select>
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography variant="caption"> Status </Typography>
                <Select
                  value={active || 'all'}
                  fullWidth
                  name="active"
                  onChange={e => this.handleChange(e.target.value, 'active')}
                >
                  <MenuItem value="all">All</MenuItem>
                  {["Active", "Inactive"].map((status, index) => (<MenuItem key={index} value={(status == "Active") + ""}>{status}</MenuItem>))}
                </Select>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} md={5} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search', 0)}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleClear}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                <Tooltip title="Create Survey">
                  <Button color="primary" variant="contained" onClick={this.handleOpenEditSurvey}> Create Survey </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={3} md={3} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                <Tooltip title="Set Global Config">
                  <Button color="primary" variant="outlined" onClick={() => this.toggleGlobalConfigSetter(true)}> Global Config </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Drawer anchor="right" open={openEditSurvey} onClose={() => this.toggleEditSurvey(false)}>
          <EditSurvey
            onClose={() => this.toggleEditSurvey(false)}
            parentComponentId="Create Survey"
            surveyConditionTypes={surveyConditionTypes}
            surveyTypes={surveyTypes}
            onRefresh={onRefresh}
            key={Date.now()}
            selectedSurvey={{ active: true }}
          />
        </Drawer>
        <AntdModal
          title="Global Config Setting"
          visible={openGlobalConfigSetter}
          centered
          onOk={this.updateGlobalCoolOffTime}
          onCancel={() => this.toggleGlobalConfigSetter(false)}
          okText="Save"
          okButtonProps={{ disabled: !surveyGlobalCoolOffTime }}
        >
          <div style={{ paddingLeft: "5%" }}>
            <span>
              <b>Cool Off period between two Survey: </b>
              <AntdInput
                value={surveyGlobalCoolOffTime}
                onChange={this.handleChangeGlobalCoolOffTime}
                type="number"
                style={{ width: window.innerWidth * 0.05 }}
              />
              &nbsp;Days
            </span>
          </div>
        </AntdModal>
      </div>
    );
  }
}

SurveySearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  queryId: PropTypes.string,
};


export default (withRouter(SurveySearch));
