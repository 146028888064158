import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ColMod } from '../../../components/lib';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Colors from '../../../utils/Colors';
import TextField from '@material-ui/core/TextField';
import DummyRouteSelector from '../../../components/StaticRoute/DummyRoute/Selector';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  }
}

function RouteMappingSearch(props) {
  const {
    classes,
    onAddNew,
    onChangeSearch,
    onClearSearch,
    filter: {
      locality,
      subLocality,
      pincode
    },
    selectedRoute,
    onRouteChange,
    showPincode
  } = props;

  return (
    <Grid fluid className={classes.container}>
      <Row md={12} middle="md">
        <Col md={2}>
          <Typography variant="title" color="inherit">
            Dummy Route Mapping
          </Typography>
        </Col>
        <Col md={10}>
          <Row md={12} middle="md">
            <Col md={10}>
              <Row md={12} bottom="md">
                <ColMod md={2}>
                  <TextField
                    label="Locality"
                    name="locality"
                    value={locality}
                    onChange={onChangeSearch}
                    fullWidth
                  />
                </ColMod>
                <ColMod md={2}>
                  <TextField
                    label="Sub Locality"
                    name="subLocality"
                    value={subLocality}
                    onChange={onChangeSearch}
                    fullWidth
                  />
                </ColMod>
                {showPincode && <ColMod md={2}>
                  <TextField
                    label="pincode"
                    name="pincode"
                    value={pincode}
                    onChange={onChangeSearch}
                    fullWidth
                  />
                </ColMod>}
                <ColMod md={3}>
                  <Typography variant="caption">Route</Typography>
                  <DummyRouteSelector
                    onSelect={onRouteChange}
                    selected={selectedRoute}
                  />
                </ColMod>
                <ColMod md={1}>
                  <Button color="secondary" onClick={onClearSearch}> Clear </Button>
                </ColMod>
              </Row>
            </Col>
            <Col md={2}>
              <Button color="secondary" variant="contained" onClick={onAddNew}><AddIcon /> Create </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  )
}

RouteMappingSearch.propTypes = {
  onAddNew: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
}

RouteMappingSearch.defaultProps = {
  searchTerm: '',
}

export default withStyles(styles)(RouteMappingSearch);
