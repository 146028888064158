export default {
  flexbox: {
    display: 'flex',
  },
  goBack: {
    marginRight: '16px',
  },
  title: {
    fontSize: '1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  subTitle: {
    textTransform: 'uppercase',
  },
  action: {
    display: 'flex',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  btn: {
    width: '128px',
    marginLeft: '10px',
  },
  addressInfoWrap: {
    borderBottom: '1px solid rgb(221, 221, 221)',
    padding: '16px 24px',
    display: 'flex',
  },
  addressInfo: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    boxShadow: 'none',
  },
  hover: {
    background: '#fafafa',
    border: '2px solid rgba(63, 81, 181, 0.5)',
    cursor: 'pointer',
  },
  defaultInfo: {
    border: '2px solid rgba(63, 81, 181, 0.5)',
  },
  fulladdress: {
    flexGrow: 1,
  },
  city: {
    marginLeft: '24px',
    textAlign: 'center'
  },
  changeAddress: {
    marginLeft: '32px',
    width: '128px',
  },
  adBtn1: {
    width: '130px',
    marginBottom: '10px',
  },
  adBtn3: {
    minWidth: '130px',
    marginTop: '16px',
  },
  edit: {
    margin: '32px 2%',
    padding: '0 0 16px',
  },
  editContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputDir: {
    minWidth: '27%',
    margin: '16px 3%',
  },
  inputStyle: {
    width: '100%',
  },
  addressListItem: {
    padding: '16px',
    border: '2px solid white',
    flexGrow: 'unset',
    width: '100%',
  },
  default: {
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(63, 81, 181, 0.5)',
    fontSize: '12px',
    padding: '1px 3px',
    color: 'white',
  },
  defaultInfoView: {
    background: 'rgba(63, 81, 181, 1)',
    fontSize: '12px',
    padding: '1px 0',
    color: 'white',
    height: '20px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  empty: {
    margin: '32px 0',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
};
