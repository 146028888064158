import React, { Component } from 'react';
import {
    FormHelperText,
    Button,
    Typography,
    Modal,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core';
import moment from 'moment';
import OfferCampaignItemTable from './OfferCampaignItemTable';
import MultiProductSelection from './MultiProductSelection';
import SaveBar from './SaveBarOfferCampaign';
import NavBar from '../../../components/NavBar';
import apiCall, {
    offerByIdPath,
    offerProductsByIdPath,
    allProductItemsPath,
    validateOfferProductsByOfferId
} from '../../../api/NetworkHandler';
import CloseIcon from '@material-ui/icons/Close';
import { debounce, isEqual, cloneDeep } from 'lodash';
import Lozenge from '@atlaskit/lozenge';
import { getOfferStatus, getOfferItemStartDate } from './OfferUtility';
import ProgressBar from '../../../components/States/ProgressBar';
import RegionSelector from '../../../components/Region/Selector';
import CSVReader from 'react-csv-reader';
import TableHeader from '../../Commons/components/TableHeader';
import { Result, message, Spin, Table as AntdTable } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { CSVDownloader } from 'react-papaparse';


const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
      header
        .toLowerCase()
        .replace(/\W/g, '')
  }

var versionDict = {
    'offerProductsRequestId': 0,
    'offerDetailsRequestId': 0,
    "validateOfferRequestId": 0,
};

var initialOfferData = {};
var initialOfferProducts = [];
var isDataInvalid = false;

const style = {
    cellStyle: {
      textAlign: 'center',
    },
};

var datePattern = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{2,4}$/);

var numPattern = new RegExp(/[^0-9\s]/g);

const columnData = [
    {
        id: 'productItemId', numeric: false, disablePadding: true, label: 'Product Item ID',
    },
    {
        id: 'productName', numeric: false, disablePadding: true, label: 'Product Name',
    },
    {
        id: 'uomCode', numeric: false, disablePadding: true, label: 'UOM',
    },
    {
        id: 'unitMeasure', numeric: false, disablePadding: true, label: 'Unit Measure',
    },
    {
        id: 'packaging', numeric: false, disablePadding: true, label: 'Packaging',
    },
    {
        id: 'region', numeric: false, disablePadding: true, label: 'Region',
    },
    {
        id: 'startTime', numeric: false, disablePadding: true, label: 'Start Date',
    },
    {
        id: 'endTime', numeric: false, disablePadding: true, label: 'End Date',
    },
    {
        id: 'offerText', numeric: false, disablePadding: true, label: 'Offer Text',
    },
    {
        id: 'unitPrice', numeric: false, disablePadding: true, label: 'Offer MRP',
    },
    {
        id: 'offerPrice', numeric: false, disablePadding: true, label: 'Offer Price',
    },
    {
        id: 'discountPercent', numeric: false, disablePadding: true, label: 'Discount Percent',
    },
    {
        id: 'productItemUnitPrice', numeric: false, disablePadding: true, label: 'Product MRP',
    },
    // {
    //     id: 'remarks', numeric: false, disablePadding: true, label: 'Remarks',
    // }
];

const faultyDataColumns = [
    {title: "Row No.", dataIndex: "row", width: 80},
    {title: "Name", dataIndex: "productName", width: 220, ellipsis: true},
    {title: "UOM Code", dataIndex: "uomCode", width: 80},
    {title: "Unit Measure", dataIndex: "unitMeasure", width: 80},
    {title: "Packaging", dataIndex: "packaging", width: 100},
    {title: "Start Time", dataIndex: "startTime", width: 120},
    {title: "End Time", dataIndex: "endTime", width: 120},
    {title: "Offer Price", dataIndex: "offerPrice", width: 80},
    {title: "Price", dataIndex: "price", width: 80},
    {title: "Discount Percent", dataIndex: "paybackValue", width: 80},
    {title: "Remarks", dataIndex: "message", width: 180},
];

var calculatedStartTime = getOfferItemStartDate();

class EditOfferCampaign extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        const { match = {} } = this.props;
        const { params: { id = null } } = match;
        this.state = {
            offerId: id,
            items: [],
            data: {},
            openProductSearchBox: false,
            hideSidePanel: false,
            disableSubmit: false,
            loading: false,
            region: null,
            searchQuery: '',
            products: [],
            regionId: '',
            productItemId: '',
            previewData: [],
            openPreview: false,
            page: 0,
            rowsPerPage: 10,
            totalElements: 0,
            uploadedData: [],
            csvLoading: false,
            faultyCsvRows: [],
          }
    };

    componentDidMount() {
        this._isMounted = true;
        const { offerId } = this.state;
        if (this._isMounted) {
            this.fetchOfferProductsDetails(offerId);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    fetchOfferProductsDetails =  debounce( async (offerId) => {
        const { regionId, query, page, rowsPerPage: size } = this.state;
        this.toggleState(true, true);
        try {
            versionDict.offerProductsRequestId += 1;
            let prevRequestId = versionDict.offerProductsRequestId;
            const response = await apiCall.sendRequest('get', offerProductsByIdPath(offerId, regionId, query, page, size));
            const { data: { data = {} } } = response;
            const { offer = {}, products = [], totalElements } = data;
            if (this._isMounted && versionDict.offerProductsRequestId == prevRequestId) {
                this.setState({
                    data: offer,
                    items: products,
                    totalElements,
                });
                initialOfferData = cloneDeep(offer);
                initialOfferProducts = cloneDeep(products)
            }
            this.toggleState(false, false);
        } catch (err) {
            if (this._isMounted) {
                this.setState({
                    items: [],
                    data: {},
                    totalElements: 0,
                });
            }
            this.toggleState(false, false);
        }
    }, 750);

    onChangeItem = (product) => {
        if (this._isMounted) {
            this.setState({
                items: product,
            });
        }
    };

    handleSubmitOfferData = async (offerId) => {
        const { data } = this.state;
        try {
            await apiCall.sendRequest('put', offerByIdPath(offerId), data);
        } catch (err) {
            console.log(err.response);
        }
    };

    handleSubmitOfferProducts = async (offerId, items) => {
        try {
            await apiCall.sendRequest('put', offerProductsByIdPath(offerId), items);
            await this.fetchOfferProductsDetails(offerId);
        } catch (err) {
            console.log(err.response);
        }
    };

    toggleState = (buttonState, progressState) => {
        if (this._isMounted) {
            this.setState({
                disableSubmit: buttonState,
                loading: progressState,
            });
        }
    }

    getUpdatedOfferData = (offerData) => {
        offerData.filter(el => {
            if(el.isDeleted) {
                el.active = false
            }
            delete el.isDeleted;
            delete el.isModified;
            delete el.isAdded;
            return el;
        });
        return offerData;
    };

    handleSubmit = debounce( async () => {
        const { offerId, data, items } = this.state;
        this.toggleState(true, true);
        let updatedOfferData = this.getUpdatedOfferData(items);

        if (!isEqual(initialOfferData, data)) {
            await this.handleSubmitOfferData(offerId);
            await this.handleSubmitOfferProducts(offerId, items);
            await this.fetchOfferProductsDetails(offerId);
        } else {
            await this.handleSubmitOfferProducts(offerId, updatedOfferData);
            // await this.fetchOfferProductsDetails(offerId);
        }
        this.toggleState(false, false)
    }, 500);

    getModifiedItems = (key, value) => {
        const { items } = this.state
        let modifiedItems = [];
        items.map(el => {
            modifiedItems.push({...el, [key]: key === "startTime" || key === "endTime" ? moment(value).format('YYYY-MM-DDThh:mm:ss') : value});
        });
        return modifiedItems;
    };

    handleSetState = (name, value) => {
        if (this._isMounted) {
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    [name]: name === "startTime" || name === "endTime" ? moment(value).format('YYYY-MM-DDThh:mm:ss') : value,
                },
                // items: this.getModifiedItems(name, value),
            }));
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        const { data: { startTime, endTime } } = this.state;
        const today = new Date();
        const defaultStartDate = moment(today).add(2, 'days').format("YYYY-MM-DD");
        const defaultEndDate = moment(today).add(3, 'days').format("YYYY-MM-DD");

        if (name === "startTime" && (moment(value).isBefore(defaultStartDate, 'days') || moment(value).isSameOrAfter(endTime, 'days'))) {
            this.handleSetState(name, startTime);
            return;
        }
        else if (name === "endTime" && (moment(value).isBefore(defaultEndDate, 'days') || moment(value).isSameOrBefore(startTime, 'days'))) {
            this.handleSetState(name, endTime);
            return;
        } else {
            this.handleSetState(name, value);
        }
    };

    handleRegionChange = (region) => {
        const { offerId } = this.state;
        this.setState({
            region,
            regionId: region.id,
            page: 0,
        }, () => this.fetchOfferProductsDetails(offerId));
    };

    handleQueryChange = (event) => {
        const { offerId } = this.state;
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            page: 0,
        }, () => this.fetchOfferProductsDetails(offerId));
    };

    handleClear = () => {
        const { offerId } = this.state;
        this.setState({
            regionId: '',
            query: '',
            region: {}
        }, () => this.fetchOfferProductsDetails(offerId));
    }

    getAllProducts = async (query = '') => {
        const url = allProductItemsPath(query);
        try {
          const response = await apiCall.sendRequest('get', url);
          const { data: { data = [] } } = response;
          this.setState({
            products: data,
          });
        } catch (e) {
          this.setState({
            products: [],
          });
        }
    };

    validateOfferProducts = async (offerId, csvUploadedData) => {
        this.setState({
            csvLoading: true,
        });
        try {
            versionDict.validateOfferRequestId += 1;
            let prevRequestId = versionDict.validateOfferRequestId;
            const response = await apiCall.sendRequest('put', validateOfferProductsByOfferId(offerId), csvUploadedData);
            const { data: { data = [] } } = response;
            if (versionDict.validateOfferRequestId == prevRequestId) {
                this.setState({
                    previewData: data.map(el => {return  {...el, uomCode: el.uomCode.split(" ").filter(el => el).join(" ")}}),
                    openPreview: true,
                    csvLoading: false,
                });
            }
        } catch (err) {
            this.setState({
                previewData: [],
                openPreview: true,
                csvLoading: false
            });
        }
    };

    validateDate = (date) => {
        const seperator1 = date.split('/'); // ["17", "07", "2021"]
        const seperator2 = date.split('-');
        const format = "YYYY-MM-DD"

        if (seperator1.length > 1 ) {
            if (seperator1[0].length == 4) {
                return moment(seperator1.join("-")).format(format)
            } else {
                return moment(seperator1.reverse().join("-")).format(format)
            }
        } else if (seperator2.length > 1 ) {
            if (seperator2[0].length == 4) {
                return moment(seperator2.join("-")).format(format)
            } else {
                return moment(seperator2.reverse().join("-")).format(format)
            }
        }
    };
      
    getStartTime = (csvInputStartTime, offerStartTime, offerEndTime, offerStatus) => {
        let csvStartTime = csvInputStartTime;
        
        if (!csvStartTime || typeof csvStartTime === 'undefined') {
            if (moment().isSameOrAfter(moment(offerStartTime).subtract(1, 'days'), 'days')) {
                return moment().add(2, 'days').format("YYYY-MM-DDT00:00:00");
            }
            return moment(offerStartTime).format("YYYY-MM-DDT00:00:00");
        } else {
            csvStartTime = this.validateDate(csvInputStartTime);
            if (csvStartTime === "Invalid date") return csvStartTime;

            if (offerStatus === 'ongoing') {
                if (moment(csvStartTime).isSameOrBefore(moment().add(1, 'days'), 'days')) {
                    return moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DDT00:00:00");
                } else {
                    if (moment(csvStartTime).isAfter(offerEndTime, 'days')) {
                        return moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DDT00:00:00");
                    } else {
                        return moment(csvStartTime).format("YYYY-MM-DDT00:00:00");
                    }
                }
            }
            else if (offerStatus === 'upcoming') {
                if (moment(csvStartTime).isBefore(offerStartTime, 'days')) {
                    return moment(offerStartTime).format("YYYY-MM-DDT00:00:00");
                } else {
                    if (moment(csvStartTime).isAfter(offerEndTime, 'days')) {
                        return moment(offerStartTime).format("YYYY-MM-DDT00:00:00");
                    } else {
                        return moment(csvStartTime).format("YYYY-MM-DDT00:00:00");
                    }
                }
            }
            else {
                return moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DDT00:00:00");
            }
        }
    };

    getEndTimes = (csvInputStartTime, csvInputEndTime, offerStartTime, offerEndTime) => {
        let csvStartTime = this.validateDate(csvInputStartTime);
        let csvEndTime = this.validateDate(csvInputEndTime);
        if (!csvEndTime || typeof csvEndTime === 'undefined') {
            return moment(offerEndTime).format("YYYY-MM-DDT23:59:59");
        } else {
            if (moment(csvEndTime).isBefore(offerStartTime, 'days') || moment(csvEndTime).isAfter(offerEndTime, 'days') || moment(csvEndTime).isBefore(csvStartTime, 'days')) {
                return moment(offerEndTime).format("YYYY-MM-DDT23:59:59");
            } else {
                return moment(csvEndTime).format("YYYY-MM-DDT23:59:59");
            }
        }
    };

    getEndTime = (csvInputStartTime, csvInputEndTime, offerStartTime, offerEndTime, offerStatus) => {
        let csvEndTime = csvInputEndTime;

        if (!csvEndTime || typeof csvEndTime === 'undefined') {
            return moment(offerEndTime).format("YYYY-MM-DDT23:59:59");
        } else {
            csvEndTime = this.validateDate(csvInputEndTime);
            if (csvEndTime === "Invalid date") return csvEndTime;

            const calculatedOfferStartTime = this.getStartTime(csvInputStartTime, offerStartTime, offerEndTime, offerStatus);

            if (calculatedOfferStartTime === "Invalid date") return calculatedOfferStartTime;

            if (moment(csvEndTime).isBefore(calculatedOfferStartTime, "days")) {
                return moment(calculatedOfferStartTime).add(1, 'days').format("YYYY-MM-DDT23:59:59");
            } else {
                if (moment(csvEndTime).isAfter(offerEndTime, "days")) {
                    return moment(offerEndTime).format("YYYY-MM-DDT23:59:59");
                }
                return moment(csvEndTime).format("YYYY-MM-DDT23:59:59");
            }
        }
    };
    
    openPreviewSetState = (state) => {
        this.setState({
            openPreview: state,
        });
    };

    findUniqueArrayElement = (array) => {
        let uniqueArray = [];
        array.forEach(el => {
            // Index will be -1 in case array is empty or new element
            if (uniqueArray.findIndex(elm => elm.productitemid === el.productitemid) == -1) {
                uniqueArray.push(el)
            } else {
                // this index will help to replace the latest dupllicate element
                let index = uniqueArray.findIndex(elk => elk.productitemid === el.productitemid);
                uniqueArray[index] = el;
            }
        });
        return uniqueArray;
    };

    csvFileValidation = (csvData, isPaybackDiscount) => {
        let faultyCsvRowCollection = [];
        if (csvData.length === 0) return null;

        csvData.forEach((el, i) => {
            if (!el.startTime || !el.endTime || el.startTime === "Invalid date" || el.endTime === "Invalid date") {
                faultyCsvRowCollection.push({row: i + 2, data: el, message: "Incorrect date format"});
            }
            else if (!el.productItemId) {
                faultyCsvRowCollection.push({row: i + 2, data: el, message: "Item ID missing"})
            }
            else if (isPaybackDiscount) {
                if (!el.offerPrice || typeof(el.offerPrice) !== 'number') {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Offer Price is required"});
                }
                else if (!el.price || typeof(el.price) !== 'number') {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Price is required"});
                }
                else if (el.price < 0) {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Invalid Price"});
                }
                else if (el.offerPrice < 0) {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Invalid Offer Price"});
                }
                else if (el.price < el.offerPrice) {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Price is lower than offer price"});
                }
            }
            else if (!isPaybackDiscount) {
                if (!(el.paybackValue || el.paybackValue == 0)) {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Discount percent is required"});
                }
                else if (el.paybackValue < 0 || el.paybackValue > 100) {
                    faultyCsvRowCollection.push({row: i + 2, data: el, message: "Invalid percent"});
                }
            }
        });

        return faultyCsvRowCollection;
    };

    prepareOfferText = (offerText, orderLimit, description) => {
        if (offerText) {
            if (orderLimit) {
                return `${offerText} - Offer Limited to ${orderLimit} Qty`;
            } else {
                return offerText;
            }
        } else {
            if (orderLimit) {
                return `Offer Limited to ${orderLimit} Qty`;
            } else {
                return description;
            }
        }
    };

    handleUploadFile = (data, fileInfo) => {
        if (fileInfo.type !== "text/csv") {
            message.warn("Please upload csv file..");
        }
        if (data.length === 0) {
            message.warn("Please upload correct csv..!");
            document.getElementById("fileControl").value = '';
            return;
        }
        const uniqueCsvData = this.findUniqueArrayElement(data);
        const { offerId, data: offerData } = this.state;
        const { startTime, endTime, description, paybackType } = offerData;
        const offerStatus = getOfferStatus(startTime, endTime).status;
        const isPaybackDiscount = !paybackType || paybackType === 'DISCOUNT';

        let formattedCsvData = [];
        uniqueCsvData.map(el => {
            const verifiedOrderLimit = numPattern.test(el.orderlimit) ? null : el.orderlimit;
            formattedCsvData.push({
                active: true,
                paybackType: isPaybackDiscount ? "AMOUNT" : "PERCENT",
                priceDiscountType: !paybackType ? "DISCOUNT" : paybackType,
                offerPrice: isPaybackDiscount ? el.offerprice : 0,
                price: isPaybackDiscount ? el.price : 0,
                productItemId: el.productitemid,
                paybackValue: isPaybackDiscount ? el.price - el.offerprice : el.discountpercent,
                title: el.productname,
                productName: el.productname,
                uomCode: el.uomcode,
                unitMeasure: el.unitmeasure,
                packaging: el.packaging || '',
                startTime: this.getStartTime(el.starttime, startTime, endTime, offerStatus),
                endTime: this.getEndTime(el.starttime, el.endtime, startTime, endTime, offerStatus),
                offerText: this.prepareOfferText(el.offertext, verifiedOrderLimit, description),
                limitPerUser: verifiedOrderLimit,
            });
        });

        this.setState({
            uploadedData: formattedCsvData,
        });

        const faultyCsvRows = this.csvFileValidation(formattedCsvData, isPaybackDiscount);

        if (faultyCsvRows && faultyCsvRows.length > 0) {
            this.setState({
                faultyCsvRows,
                openPreview: true,
            });
        } else {
            this.validateOfferProducts(offerId, formattedCsvData);
        }

        document.getElementById("fileControl").value = '';
    };

    handleUploadError = (err) => {
        console.log(err);
    };

    removeItem = i => {
        const { items } = this.state;
        const newItems = items.filter(
            item => item.productItemId !== i.productItemId
        );
    
        if (this._isMounted) {
            this.setState({
                items: newItems
            });
        }
    };

    handleChangeInitiaOfferProducts = (obj) => {
        initialOfferProducts.push(obj);
    };

    toggleOpenProductSearchBox = (state) => {
        if (this._isMounted) {
            this.setState({
                openProductSearchBox: state,
            });
        }
    };

    handleOpenProductSearch = () => {
        this.toggleOpenProductSearchBox(true);
    };

    handleCloseProductSearch = () => {
        this.toggleOpenProductSearchBox(false);
    };

    handleHideSidePanel = () => {
        const { hideSidePanel } = this.state;
        if (this._isMounted) {
            this.setState({
                hideSidePanel: !hideSidePanel,
            });
        }
    };

    handleClosePreview = () => {
        this.setState({
            openPreview: false,
            previewData: [],
            uploadedData: [],
            faultyCsvRows: [],
        });
    };

    handleDisableSave = () => {
        const { previewData } = this.state;
        return previewData.some(el => !el.productItemIsValid);
    };

    handleSubmitOfferPreview = () => {
        const { offerId, previewData } = this.state;
        this.handleSubmitOfferProducts(offerId, previewData);
        this.handleClosePreview();
    };

    handleChangePage = (event, page) => {
        const { offerId } = this.state;
        this.setState({
            page,
        }, () => this.fetchOfferProductsDetails(offerId));
    };

    handleChangeRowsPerPage = (event) => {
        const { value } = event.target;
        const { offerId } = this.state;
        this.setState({
            rowsPerPage: value
        }, () => this.fetchOfferProductsDetails(offerId));
    };

    validateItems = (items, paybackType) => {
        if (paybackType === 'PRICE_DISCOUNT') {
            let isDiscountPercentageValid = items.every(el => parseFloat(el.paybackValue) <= 100);
            return !isDiscountPercentageValid;
        } else {
            let isAllPriceValid = items.every(el => parseFloat(el.price) > 0 && parseFloat(el.offerPrice) > 0);
            let isOfferPriceValid = items.some(el => parseFloat(el.offerPrice) > parseFloat(el.price));
            return !isAllPriceValid || isOfferPriceValid;
        }
    };

    renderFaultyCsvRows = (isPaybackDiscount) => {
        const { faultyCsvRows } = this.state;
        const columnData = isPaybackDiscount ? faultyDataColumns.filter(el => el.dataIndex !== "paybackValue") : faultyDataColumns.filter(el => !["offerPrice", "price"].includes(el.dataIndex))
        const data = faultyCsvRows.map((el, index) => {return {...el.data, message: el.message, startTime: moment(el.data.startTime).format("ll"), endTime: moment(el.data.endTime).format("ll"), row: el.row, key: index}})
        return (
            <div style={{ height: window.innerHeight * 0.55, overflow: "auto" }}>
                <AntdTable columns={columnData} dataSource={data} size="small" sticky />
            </div>
        );
    };

    isSelected = id => this.state.selected === id;

    render() {
        const { 
            data,
            items,
            openProductSearchBox,
            loading,
            disableSubmit,
            region,
            query,
            openPreview,
            previewData,
            page,
            rowsPerPage,
            totalElements,
            uploadedData,
            csvLoading,
            faultyCsvRows
        } = this.state;

        const isAllFieldValid = this.validateItems(items, data.paybackType);
        const disableSubmitButton = disableSubmit || isAllFieldValid;
        const offerStatus = getOfferStatus(data.startTime, data.endTime).status;
        const offerStatusColor = getOfferStatus(data.startTime, data.endTime).color;
        const disableAddItem = offerStatus === 'completed' || moment(data.endTime).isBefore(moment(getOfferItemStartDate()).add(2, 'days'), 'days');
        const noOfActiveProducts = items.filter(el => el.active != false).length;
        const isPaybackDiscount = !data.paybackType || data.paybackType === 'DISCOUNT';
        const conditionalColumnData = isPaybackDiscount ? columnData.filter(el => el.id !== 'discountPercent') : columnData.filter(el => !['unitPrice', 'offerPrice', 'productItemUnitPrice'].includes(el.id));

        return (
            <div>
                <NavBar />
                <ProgressBar isLoading={loading} color="primary" />
                <SaveBar
                    headerTitle="Edit Offer Campaign"
                    submitButtonText="Save Offer"
                    onSubmit={this.handleSubmit}
                    parentComponent="offer-campaign"
                    isDisable={disableSubmitButton}
                />
                <Spin spinning={csvLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
                <div style={{ height: window.innerHeight > 900 ? window.innerHeight * 0.07 : window.innerHeight * 0.1, backgroundColor: 'rgb(224, 224, 224)', display: 'flex', paddingLeft: '10px', paddingRight: '40px' }}>
                    <div style={{ flex: 1, display: 'flex' }}>
                        <div style={{ flex: 1, marginTop: '10px', width: window.innerWidth * 0.12 }}>
                            <CSVReader
                                cssClass="csv-reader-input"
                                label="Select CSV to upload"
                                accept=".csv, text/csv"
                                disabled={disableAddItem}
                                onFileLoaded={this.handleUploadFile}
                                onError={this.handleUploadError}
                                parserOptions={papaparseOptions}
                                inputId="fileControl"
                                inputStyle={{color: 'transparent', outlineStyle: 'none'}}
                            />
                        </div>
                        <div style={{ flex: 1, marginTop: '20px' }}>
                            <CSVDownloader
                                data={`productItemId,productName,uomCode,unitMeasure,packaging,offerPrice,price,startTime,endTime,offerText,orderLimit,discountPercent`}
                                type="link"
                                label="Click to download CSV format"
                                filename={`${data.id}-${data.title}-${moment().format("YYYY-MM-DD")}`}
                                bom={true}
                            >
                                <Button variant="outlined" color="primary" size="small" disabled={disableSubmit || offerStatus === 'completed'}>
                                    Download
                                </Button>
                            </CSVDownloader>
                        </div>
                    </div>
                    <div style={{ flex: 2, display: 'flex' }}>
                        <div style={{ flex: 1, paddingLeft: '20px', paddingTop: '5px' }}>
                            <Typography variant="caption"> Search by Region </Typography>
                            <RegionSelector
                                onSelect={this.handleRegionChange}
                                selected={region}
                                isClearable={false}
                            />
                        </div>
                        <div style={{ flex: 1, paddingLeft: '20px' }}>
                            <FormHelperText> Search by Product Item </FormHelperText>
                            <TextField
                                type="text"
                                name="query"
                                value={query}
                                onChange={this.handleQueryChange}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ flex: 1, paddingLeft: '20px', paddingTop: '15px' }}>
                            <Button variant="outlined" color="primary" size="small" disabled={disableSubmit} onClick={this.handleClear}>
                                Clear
                            </Button>
                        </div>
                    </div>
                    <div style={{ flex: 2, display: 'flex', justifyContent: 'space-evenly', marginLeft: '10px' }}>
                        <div style={{ paddingTop: '10px' }}>
                            <p style={{ margin: 0 }}><b>Start Date:&nbsp;&nbsp;</b>{moment(data.startTime).format('ll')}</p>
                            <p style={{ margin: 0, width: window.innerWidth * 0.11, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}><b>Title:&nbsp;&nbsp;</b>{data.title}</p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                            <p style={{ margin: 0 }}><b>End Date:&nbsp;&nbsp;</b>{moment(data.endTime).format('ll')}</p>
                            <p style={{ margin: 0 }}><b>Status:&nbsp;&nbsp;</b>
                                <Lozenge appearance={offerStatusColor} isBold>{offerStatus}</Lozenge>
                            </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                            <p style={{ margin: 0 }}><b>Total Products</b></p>
                            <p style={{ color: 'green', fontSize: '20px', fontWeight: 'bold', textAlign: 'center', margin: 0 }}>{noOfActiveProducts}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{
                        height: noOfActiveProducts < 10 ? 'auto' : window.innerHeight * 0.65,
                        width: window.innerWidth * 0.96,
                        overflowY: 'scroll',
                        overflowX: 'scroll',
                        marginLeft: window.innerWidth * 0.03,
                    }}>
                        <OfferCampaignItemTable 
                            items={items}
                            offerData={data}
                            removeItem={this.removeItem}
                            onChange={this.onChangeItem}
                            initialOfferProducts={initialOfferProducts}
                            onChangeInitialOfferProducts={this.handleChangeInitiaOfferProducts}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            totalElements={totalElements}
                        />
                    </div>
                    <div style={{ marginLeft: window.innerWidth * 0.03, marginBottom: window.innerHeight * 0.01 }}>
                        <Button onClick={this.handleOpenProductSearch} variant="outlined" color="primary" size="medium" disabled={disableAddItem}>
                            + Add Item
                        </Button>
                    </div>
                </div>
                </Spin>
                <MultiProductSelection
                    open={openProductSearchBox}
                    handleClose={this.handleCloseProductSearch}
                    items={items}
                    offerData={data}
                    removeItem={this.removeItem}
                    onChange={this.onChangeItem}
                    initialOfferProducts={initialOfferProducts}
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openPreview}
                    onClose={this.handleClosePreview}
                    disableBackdropClick
                >
                    <div
                    style={{
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        height: '90%',
                        width: '80%',
                        borderRadius: "5px"
                    }}
                    >
                    <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.065, borderRadius: "5px", position: "fixed", width: "100%" }}>
                        <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: window.innerWidth * 0.02, paddingTop: "10px" }}>
                            <Typography variant="title" color="inherit"> Product Preview </Typography>
                        </div>
                        <Button style={{ float: 'right' }} onClick={this.handleClosePreview} color="secondary" aria-label="close">
                            <CloseIcon />
                        </Button>
                    </div>
                    {(previewData.length > 0) &&
                        <div style={{ paddingLeft: "10px", marginTop: window.innerHeight * 0.065, height: window.innerHeight * 0.75, position: "absolute", overflow: "auto", width: "100%" }}>
                            <Table>
                                <TableHeader
                                    columns={conditionalColumnData}
                                    cellStyle={style.cellStyle}
                                />
                                <TableBody>
                                    {previewData.map(item => {
                                        const isSelected = this.isSelected(item.productItemId);
                                        const uploadedDataObj = uploadedData.find(el => el.productItemId ==item.productItemId);
                                        const isUnitPriceInvalid = (item.productItemUnitPrice - item.price) * 100 / item.productItemUnitPrice > 5;
                                        const isProductNameInvalid = !(uploadedDataObj.productName && (item.productName.toLowerCase() === uploadedDataObj.productName.toLowerCase()));
                                        const isUOMCodeInvalid = !(uploadedDataObj.uomCode && item.uomCode.toLowerCase() === uploadedDataObj.uomCode.toLowerCase());
                                        const isUnitMeasureInvalid = !(uploadedDataObj.unitMeasure && item.unitMeasure === uploadedDataObj.unitMeasure);
                                        const isPackagingInvalid = !(item.packaging && item.packaging.toLowerCase() === uploadedDataObj.packaging.toLowerCase());
                                        const isDiscountPercentInvalid = item.paybackValue < 0 || item.paybackValue > 100;
                                        return (
                                            <TableRow 
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={item.productItemId}
                                                selected={isSelected}
                                            >
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                padding="none"
                                                style={{
                                                    color: !item.productItemIsValid ? "red" : "black",
                                                    fontWeight: !item.productItemIsValid ? "bold" : "normal",
                                                    fontSize: !item.productItemIsValid ? "16px" : "14px",
                                                    width: "70px"
                                                }}
                                            >
                                                {item.productItemId}
                                            </TableCell>
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                padding="none"
                                                style={{
                                                    color: isProductNameInvalid ? "red" : "black",
                                                    fontWeight: isProductNameInvalid ? "bold" : "normal",
                                                    fontSize: isProductNameInvalid ? "16px" : "14px",
                                                }}
                                            >
                                                {item.productName}
                                            </TableCell>
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                padding="none"
                                                style={{
                                                    color: isUOMCodeInvalid ? "red" : "black",
                                                    fontWeight: isUOMCodeInvalid ? "bold" : "normal",
                                                    fontSize: isUOMCodeInvalid ? "18px" : "14px",
                                                }}
                                            >
                                                {item.uomCode}
                                            </TableCell>
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                padding="none"
                                                style={{
                                                    color: isUnitMeasureInvalid ? "red" : "black",
                                                    fontWeight: isUnitMeasureInvalid ? "bold" : "normal",
                                                    fontSize: isUnitMeasureInvalid ? "18px" : "14px",
                                                    width: "70px"
                                                }}
                                            >
                                                {item.unitMeasure}
                                            </TableCell>
                                            <TableCell 
                                                component="th" 
                                                scope="row" 
                                                padding="none"
                                                style={{
                                                    color: isPackagingInvalid ? "red" : "black",
                                                    fontWeight: isPackagingInvalid ? "bold" : "normal",
                                                    fontSize: isPackagingInvalid ? "18px" : "14px",
                                                }}
                                            >
                                                {item.packaging}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none" style={{ width: "100px" }}>{item.region}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{moment(item.startTime).format('ll')}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">{moment(item.endTime).format('ll')}</TableCell>
                                            <TableCell component="th" scope="row" padding="none" style={{ width: "125px" }}>{item.offerText}</TableCell>
                                            {isPaybackDiscount &&
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="default"
                                                    style={{
                                                        color: isUnitPriceInvalid ? "red" : "black",
                                                        fontWeight: isUnitPriceInvalid ? "bold" : "normal",
                                                        fontSize: isUnitPriceInvalid ? "18px" : "14px",
                                                    }}
                                                >
                                                    {item.price}
                                                </TableCell>
                                            }
                                            {isPaybackDiscount &&
                                                <TableCell 
                                                    component="th" 
                                                    scope="row" 
                                                    padding="default"
                                                >
                                                    {item.offerPrice}
                                                </TableCell>
                                            }
                                            {isPaybackDiscount &&
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="default"
                                                    style={{
                                                        color: isUnitPriceInvalid ? "red" : "black",
                                                        fontWeight: isUnitPriceInvalid ? "bold" : "normal",
                                                        fontSize: isUnitPriceInvalid ? "18px" : "14px",
                                                        paddingRight: "10px",
                                                        width: "70px"
                                                    }}
                                                >
                                                    {item.productItemUnitPrice}
                                                </TableCell>
                                            }
                                            {!isPaybackDiscount && 
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="default"
                                                    style={{
                                                        color: isDiscountPercentInvalid ? "red" : "black",
                                                        fontWeight: isDiscountPercentInvalid ? "bold" : "normal",
                                                        fontSize: isDiscountPercentInvalid ? "18px" : "14px",
                                                        paddingRight: "10px",
                                                        width: "70px"
                                                    }}
                                                >
                                                    {item.paybackValue}
                                                </TableCell>
                                            }
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                    </div>}
                    {faultyCsvRows.length > 0 && 
                    <div>
                        <Result
                            status="warning"
                            title="There are some problems with your csv file."
                            subTitle="Please check and modify the following information before resubmitting."
                            extra={this.renderFaultyCsvRows(isPaybackDiscount)}
                        />
                    </div>}
                    {previewData.length > 0 && <div style={{ position: "fixed", bottom: 0, right: 0 }}>
                        <div style={{ paddingRight: '45px', paddingTop: window.innerHeight * 0.015, paddingBottom: window.innerHeight * 0.015 }}>
                            <Button onClick={this.handleSubmitOfferPreview} variant="contained" color="primary" disabled={this.handleDisableSave()}>
                                Save Offer
                            </Button>
                        </div>
                    </div>}
                    </div>
                </Modal>
            </div>
        );
    }
}


export default EditOfferCampaign;
