import * as ActionType from '../actions';

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CURRENCY:
      return action.payload;
    default:
      return state;
  }
};


export default user;