
import React, { Component } from 'react';
import {
  Grid, Paper, Button, Tabs, Tab,
  Table, TableRow, TableHead,
  TableCell, TableBody,
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import NavBar from '../../../components/NavBar';
import API, { investmentDetail, emplyeeInvestment, investmentPath } from '../../../api/NetworkHandler';
import ConfirmationPopup from '../../Commons/components/ConfirmationPopup';
import ProgressBar from '../../Commons/components/ProgressBar';
import AppColors from '../../../utils/Colors';
import utils from '../../../utils/queryBuilder';
import PageNotFound from '../../Commons/components/PageNotFound';

const InvestImage = require('../../../../public/images/InvestmentCreative.png');

const canView = utils.isCurrentUserAuthorizedToView;

const faqs = require('../faq.json');

const styles = {
  progressBar: {
    marginBottom: 1,
    boxShadow: '0px 0px 1px 0px #9c9191',
    padding: '1px 1px',
  },
  mainContainer: {
    width: '90%',
    margin: '10px auto',
  },
  leftContainer: {
    padding: 20,
    marginBottom: 10,
  },
  rightContainer: {
    padding: 20,
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    margin: '10px 0px',
  },
  terms: {
    fontSize: 12,
    marginTop: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    marginTop: 20,
  },
  tableCell: {
    border: 0,
    padding: 0,
    textAlign: 'center',
    fontSize: '16px',
  },
  description: {
    lineHeight: 1.5,
    fontSize: '14px',
  },
  faqContainer: {
    // maxHeight: 500,
    overflowY: 'scroll',
  },
  boldText: {
    marginRight: 0,
  },
  colon: {
    margin: '0px 10px',
  },
};

class Investment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentDetails: {},
      employeeInvestment: {
        sharesAllotted: 0,
        sharesOpted: 0,
      },
      confirmPopup: false,
      tab: 0,
    };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    const { empId } = this.props;
    const { employeeInvestment } = this.state;
    const investmentDetailResponse = await API.sendRequest('get', investmentDetail);
    const { data: investmentDetailData } = investmentDetailResponse;
    const empInvestmentResponse = await API.sendRequest('get', emplyeeInvestment(empId));
    const { data: empInvestmentData } = empInvestmentResponse;
    this.setState({
      investmentDetails: investmentDetailData,
      employeeInvestment: {
        ...employeeInvestment,
        ...empInvestmentData,
      },
    });
  }

  handleQuantityIncrement = () => {
    const { employeeInvestment, investmentDetails = {} } = this.state;
    const { sharesAllotted } = employeeInvestment;
    const { maxSharesAllowed = 10 } = investmentDetails;
    if (sharesAllotted < maxSharesAllowed) {
      this.setState({
        employeeInvestment: {
          ...employeeInvestment,
          sharesAllotted: employeeInvestment.sharesAllotted + 1,
          sharesOpted: employeeInvestment.sharesAllotted + 1,
        },
      });
    }
  }

  handleQuantityDecrement = () => {
    const { employeeInvestment = {} } = this.state;
    const { sharesAllotted } = employeeInvestment;
    if (sharesAllotted && sharesAllotted !== 0) {
      this.setState({
        employeeInvestment: {
          ...employeeInvestment,
          sharesAllotted: employeeInvestment.sharesAllotted - 1,
          sharesOpted: employeeInvestment.sharesAllotted - 1,
        },
      });
    }
  }

  handlePopup = (value) => {
    this.setState({
      confirmPopup: value,
    });
  }

  handleSubmit = async () => {
    const { employeeInvestment, investmentDetails } = this.state;
    const { sharesAllotted } = employeeInvestment;
    const { perSharePrice } = investmentDetails;
    const totalAmount = sharesAllotted * perSharePrice;
    await API.sendRequest('post', investmentPath, { ...employeeInvestment, totalAmount });
    window.location.reload();
  }

  handleTabChange = (event, tab) => {
    this.setState({
      tab,
    });
  }

  render() {
    const {
      employeeInvestment = {},
      investmentDetails = {},
      confirmPopup,
      tab,
    } = this.state;
    const {
      totalInvestmentAllowed = 0,
      remainingInvestment = 0,
      perSharePrice = 0,
    } = investmentDetails;
    const {
      sharesAllotted = 1,
    } = employeeInvestment;
    const totalInvestedTillNow = totalInvestmentAllowed - remainingInvestment;
    const currencyUnit = 100000; // in lakh
    const startDate = moment('2019-04-22').format('ll');
    return (
      <div>
        <style>
          {`
            .dw-button{
              min-width:40px;
            }
          `}
        </style>
        <NavBar />
        <ConfirmationPopup
          open={confirmPopup}
          onCancel={() => this.handlePopup(false)}
          onSend={this.handleSubmit}
          title="Are you sure?"
          message={`By clicking submit, you agree to invest ${perSharePrice * sharesAllotted}. Do you want to proceed?`}
          rightButton="Invest Now"
        />
        {canView(['INVEST_WITH_US']) ?
          <div style={styles.mainContainer}>
            <Grid container>
              <Grid item xs={12}>
                <ProgressBar
                  style={styles.progressBar}
                  height={30}
                  barColor={AppColors.primary}
                  barTextColor={AppColors.white}
                  trackColor={AppColors.lightGrey}
                  value={totalInvestedTillNow || 10}
                  totalValue={totalInvestmentAllowed}
                />
                <div style={{ marginBottom: 20 }}>
                  <Tabs value={tab} onChange={this.handleTabChange} style={{ marginBottom: 10 }}>
                    <Tab label="Investment Details" />
                    <Tab label="FAQs" />
                  </Tabs>
                  {tab === 0 &&
                  <div>
                    <div style={{ margin: '10px 0px' }}>
                      <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                          <Paper elevation={1} square style={styles.leftContainer}>
                            <p><span>Current Investment&emsp;</span>:&emsp; &emsp;&#8377;{(totalInvestmentAllowed - remainingInvestment) / currencyUnit} lakh</p>
                            <p><span>Start Date &emsp;</span>:&emsp; &emsp;{startDate}</p>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Paper elevation={1} square style={styles.leftContainer}>
                            <p><span>Expected Investment&emsp;</span>:&emsp; &emsp;&#8377;{totalInvestmentAllowed / currencyUnit} lakh</p>
                            <p><span>End Date&emsp;</span>:&emsp; &emsp;{moment(startDate).add(45, 'days').format('ll')}</p>
                          </Paper>
                        </Grid>
                      </Grid>
                    </div>
                    <Paper elevation={1} square style={styles.leftContainer}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div style={{ ...styles.title }}>Investment Pledge</div>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={styles.tableCell}>Price Per Share</TableCell>
                                <TableCell style={styles.tableCell} />
                                <TableCell style={styles.tableCell}>No. of Shares</TableCell>
                                <TableCell style={styles.tableCell} />
                                <TableCell style={styles.tableCell}>Your Investment</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={styles.tableCell}>{perSharePrice}</TableCell>
                                <TableCell style={styles.tableCell}>X</TableCell>
                                <TableCell style={styles.tableCell}>
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={this.handleQuantityDecrement}
                                    className="dw-button"
                                  >-
                                  </Button>
                                  <span style={{ paddingLeft: 10, paddingRight: 10 }}>{sharesAllotted}</span>
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={this.handleQuantityIncrement}
                                    className="dw-button"
                                  >+
                                  </Button>
                                </TableCell>
                                <TableCell style={styles.tableCell} >=</TableCell>
                                <TableCell style={styles.tableCell}>&#8377;{perSharePrice * sharesAllotted}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <div style={{ textAlign: 'center', padding: 30 }}>
                            <Button color="secondary" variant="contained" onClick={() => this.handlePopup(true)}>Submit</Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper elevation={1} square style={styles.leftContainer}>
                      <Grid container spacing={16}>
                        <Grid item md={6} xs={12}>
                          <img src={InvestImage} alt="investment" width="100%" style={{ border: '1px solid #f2f2f2' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div style={styles.title}>Investment Details</div>
                          <div style={{ lineHeight: 2 }}>
                            <div>
                              <b style={styles.boldText}>Time</b>
                              <span style={styles.colon}>:</span>
                              <span>45 days from Date of opening</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>Share Price</b>
                              <span style={styles.colon}>:</span>
                              <span>Rs 83,000/-</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>No of shares for sale</b>
                              <span style={styles.colon}>:</span>
                              <span>187 (Rs 1.5 Cr)</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>Minimum No of shares to be bought</b>
                              <span style={styles.colon}>:</span>
                              <span>1 whole share</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>Maximum Cap on shares bought</b>
                              <span style={styles.colon}>:</span>
                              <span>60 whole shares (approx Rs 51 lacs)</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>Through</b>
                              <span style={styles.colon}>:</span>
                              <span>LLP Company</span>
                            </div>
                            <div>
                              <b style={styles.boldText}>Employee Bonus</b>
                              <span style={styles.colon}>:</span>
                              <span>1+1 (1 Share Bonus on allotment/purchase of 1 Share)</span>
                            </div>
                          </div>
                          <br />
                          <div>
                            <b style={styles.boldText}>Liquidation</b>
                            <span style={styles.colon}>:</span><br />
                            <ul style={{ lineHeight: 1.6 }}>
                              <li>Within 12 to 24 months of date of allotment of shares
                              to Employee LLP,company will buyback or arrange for buyout of
                              the shares held by the employee in the LLP
                              </li>
                              <li>Employee is obligated to sell these shares at FMV
                                (fair market value) by the 24th month unless the company deems that
                            the employee can continue to hold the shares
                              </li>
                              <li>
                              If the FMV per share at the time of liquidation is lower than
                             the share price at the date of share allotment, Company will buyback
                             shares at a minimum of the allotment share price per share
                              </li>
                              <li>
                              If Company decides to let Employee hold his/her shares,
                            company may restructure the shareholding
                            at LLP to Banger Tech Pvt Ltd, through a general ESOP policy.
                              </li>
                            </ul>
                          </div>
                          <div>
                            <b style={styles.boldText}>Terms & Conditions</b>
                            <span style={styles.colon}>:</span>
                            <br />
                            <ul style={{ lineHeight: 1.6 }}>
                              <li>LLP will be floated for allotment of shares in the LLP</li>
                              <li>Shares of the LLP will be issued to employees and
                                post completion of share sale,LLP will be allotted
                                Equity shares of Banger Tech Pvt Ltd
                              </li>
                              <li>3 Designated employees may be allotted as Managing Partners
                                 with Signing Authority
                              </li>
                              <li>Any Tax liabilities at the time of liquidation to be borne
                                by the employee
                              </li>
                            </ul>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
              }
                  {tab === 1 &&
                  <Paper elevation={1} square style={styles.rightContainer}>
                    <div style={styles.title}>FAQs</div>
                    <div style={styles.faqContainer}>
                      {
                      faqs.map((faq, index) => (
                        <div style={styles.terms}>
                          <h2>{index + 1}.{faq.title}</h2>
                          <div style={styles.description} dangerouslySetInnerHTML={{ __html: faq.description }} />
                        </div>
                      ))
                    }
                    </div>

                  </Paper>
                }
                </div>
              </Grid>
            </Grid>
          </div> :
          <PageNotFound />
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    empId: User.id,
  };
};

export default connect(mapStateToProps, null)(Investment);
