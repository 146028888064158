import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, TablePagination } from '@material-ui/core';
import moment from 'moment';
import Actions from './Actions';
import EditPlan from './EditPlan';
import PlanDateHelper from './PlanDate';
import styles from './styles';
import apiCall, { getHistory } from './../../../api/NetworkHandler';
import Scheduled from './Scheduled';
import DetailModal from '../DetailModal';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';


class DeletedPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'cancelledPlans': [],
      'showDeletedSchedules': false,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      count:0,
    };
  }
  componentDidMount() {
    this.getDeletedPlans();
  }
 
  formatCancelledPlanItems = (data) => {
    let formattedData = [];
    data.forEach((item) => {
      const { SCHEDULE, PRODUCT, CATEGORY_ID, QUANTITY, SCHEDULE_TYPE, START_DATE, STATUS, ID,
        DAYS_OF_WEEK, PAUSE_FROM, PAUSE_TILL, CATEGORY_PAUSE } = item
      formattedData = [
        ...formattedData,
        {
          planName: SCHEDULE.NAME,
          productItemId: PRODUCT.ID,
          productName: PRODUCT.NAME,
          categoryId: CATEGORY_ID,
          scheduleId: SCHEDULE.ID,
          quantity: QUANTITY,
          scheduleType: SCHEDULE_TYPE,
          startDate: moment(START_DATE).format('YYYY-MM-DD'),
          status: STATUS,
          unitPrice: PRODUCT.OFFER_PRICE ? PRODUCT.OFFER_PRICE : PRODUCT.SELLING_PRICE,
          planItemId: ID,
          // cutOffTime,
          customFrequency: SCHEDULE.CUSTOM_FREQUENCY,
          daysOfWeek: DAYS_OF_WEEK,
          pauseFrom: PAUSE_FROM ? moment(PAUSE_FROM).format('YYYY-MM-DD') : PAUSE_FROM,
          pauseTill: PAUSE_TILL ? moment(PAUSE_TILL).format('YYYY-MM-DD') : PAUSE_TILL,
          // categoryPauseFrom: item.CATEGORY_PAUSE,
          // categoryPauseTill: pauseTo,
          categoryPause: CATEGORY_PAUSE,
          unitMeasure: PRODUCT.UNIT_MEASURE,
          code: PRODUCT.UOM.CODE,
        }];

    });
    return formattedData;

  }
  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.getDeletedPlans() });
  };

  handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    this.setState({ rowsPerPage :value ,page:0 }, () => { this.getDeletedPlans() });
  };
  getDeletedPlans() {
    this.setState({ showDeletedSchedules: true });
    const { customerId } = this.props;
    const { page, rowsPerPage } = this.state;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": "CancelledPlanHistory",
        "params": JSON.stringify({
          "customerId": customerId,
          "limit": rowsPerPage,
          "page": page,
        })
      })
    )
      .then((response) => {

        const { data } = response.data;
        const {CancelledPlans,TotalElements}=data;
        const cancelledPlans = this.formatCancelledPlanItems(CancelledPlans);
        this.setState({
          'cancelledPlans': cancelledPlans,
          'showDeletedSchedules': true,
          'count':TotalElements,
        });
      })

  }
  render() {
    const { customerId, customerInfo, openModal, onClose } = this.props;
    const { rowsPerPage, rowsPerPageOptions ,page,count,cancelledPlans} = this.state;
    return (
      <div>
        <DetailModal unMountComponent={onClose}>
          <Typography style={styles.title}>DELETED PLANS</Typography>
          <Scheduled
            customerId={customerId}
            // schedules={customerSchedules}
            items={cancelledPlans}
            customerInfo={customerInfo}
            openModal={openModal}
            showDeletedSchedules={true}
          />
          <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={this.handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            style={{ paddingRight: window.innerWidth * 0.035 }}
          />
        </DetailModal>
      </div>
    )
  }
}
DeletedPlans.propTypes = {
  customerId: PropTypes.string,
  customerInfo: PropTypes.object,
  openModal: PropTypes.func,
  onClose: PropTypes.func,
}
export default DeletedPlans;