import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const styles = {
  inline: {
    display: 'flex',
  },
};

class ItemText extends Component {
  render() {
    const {
      type, style, name, value,
    } = this.props;
    return (
      <div style={{ ...styles[type], ...style }}>
        <Typography color="textSecondary">{name}</Typography>
        <Typography variant="body2">{value}</Typography>
      </div>
    );
  }
}

ItemText.propTypes = {
  type: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  value: PropTypes.string,
};

export default ItemText;
