import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputLabel, Select, MenuItem, Button } from '@material-ui/core';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  field: {
    flex: 1,
    margin: 10,
    minWidth: '25%',
    textAlign: 'left',
  },
};
class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
  }

  getValidNumber = (numberString) => {
    let value = numberString;
    const numbers = value.split('.');
    const count = (numbers.length > 1 && parseInt(numbers[1], 10)) ?
      `${parseFloat(numbers[1], 10)}`.length : 0;
    if (numbers.length === 2 && numbers[1].length < 1) {
      value = parseFloat(value, 10) ? value : 0;
    } else value = parseFloat(value, 10) ? parseFloat(value, 10).toFixed(Math.min(count, 4)) : 0;
    return value;
  }

  handleFieldValidation = (event, fieldtype) => {
    const { name } = event.target;
    let { value } = event.target;
    const { onChange } = this.props;
    switch (fieldtype) {
      case 'text':
        break;
      case 'number':
        value = this.getValidNumber(value);
        break;
      default: break;
    }
    onChange(name, value);
  }

  renderTextField = ({
    label, name, value, type, style = {},
  }) => (
    <div style={styles.field}>
      <TextField
        label={label}
        type="text"
        name={name}
        style={style}
        fullWidth
        value={value}
        onChange={e => this.handleFieldValidation(e, type)}
      />
    </div>
  );

  renderDateField = ({
    label, name, value, type, style = {},
  }) => (
    <div style={styles.field}>
      <TextField
        label={label}
        type="date"
        name={name}
        style={style}
        fullWidth
        value={value}
        onChange={e => this.handleFieldValidation(e, type)}
      />
    </div>
  );

  renderSelectField = ({
    label, name, value, options, type, style = {},
  }) => (
    <div style={styles.field}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        style={style}
        fullWidth
        onChange={e => this.handleFieldValidation(e, type)}
      >
        {
          options.length > 0 && options.map(option => (
            <MenuItem value={option.value}>{option.key}</MenuItem>
          ))
        }
      </Select>
    </div>
  )

  render() {
    const { formFields = [] } = this.props;
    return (
      <div style={styles.container}>
        {
          formFields.map(field =>
            ((field.type === 'text' || field.type === 'number') && this.renderTextField({ ...field })) ||
            (field.type === 'date' && this.renderDateField({ ...field })) ||
            (field.type === 'select' && this.renderSelectField({ ...field })))
        }
      </div>
    );
  }
}

FormComponent.propTypes = {
  formFields: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
};

export default FormComponent;
