import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from 'react-flexbox-grid/lib/components/Grid';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import { TextField, InputLabel, Button, Typography } from '@material-ui/core';
import Select from 'react-select';
import apiCall from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import { ArrowBack } from 'material-ui-icons';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';


export default class NewCatalogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: {},
      vendorOptions: [],
      query: '',
      invalidName: false,
      invalidVendor: false,
      disableButton: false,
    }
  }

  handleInputChange = property => (event) => {
    const { value } = event.target;

    this.setState(prevState => {
      const postData = prevState.postData;
      postData[property] = value;
      return {
        postData,
      }
    })
  }

  componentDidMount() {
    this.fetchVendors();
  }

  vendorOptions = (value) => {
    this.setState({ query: value }, () => { this.fetchVendors() })
  }

  fetchVendors = () => {
    const { query } = this.state;
    apiCall.sendRequest('get', `api/v1/vendors/search/findVendors?query=${query}`)
      .then((response) => {
        let vendorOptions = response.data._embedded.vendors.map(vendor => {
          return {
            ...vendor,
            label: vendor.name,
            value: vendor.id,
          }
        })
        vendorOptions = sortBy(vendorOptions, ['name']);
        this.setState({
          vendorOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  selectVendor = (vendor) => {
    if (!vendor) {
      vendor = {};
    }

    this.setState(prevState => {
      const postData = prevState.postData;
      postData.vendor = `${BASE_URL.BASE_URL}/api/v1/vendors/${vendor.id}`
      return {
        postData,
        vendorId: vendor.id,
      }
    });
  }

  validateForm = () => {
    const { postData, vendorId } = this.state;
    const { name } = postData;
    let isFormValid = true;

    if (!name) {
      isFormValid = false;
      this.setState({
        invalidName: true,
        nameText: 'Name field is required'
      })
    } else {
      this.setState({
        invalidName: false,
        nameText: ''
      })
    }
    if (!vendorId) {
      isFormValid = false;
      this.setState({
        invalidVendor: true,
        vendorText: 'Vendor field is required'
      })
    } else {
      this.setState({
        invalidVendor: false,
        vendorText: ''
      })
    }
    return isFormValid;
  }

  saveCatalog = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ disableButton: true });
    const { postData } = this.state;
    const { onClose, onRefresh } = this.props;
    postData.status = 'NEW';
    try {
      await apiCall.sendRequest('post', `/api/v1/vendor-catalogs`, postData);
      onRefresh();
      onClose();
    } catch (error) {
      console.log(error.response);
      onClose();
    }
    this.setState({ disableButton: false });
  }, 300);

  render() {
    const {
      postData: { name },
      vendorId,
      vendorOptions,
      invalidName,
      nameText,
      invalidVendor,
      vendorText,
      disableButton,
    } = this.state;
    const { onClose } = this.props;
    return (
      <Grid style={{ width: '40vw' }}>
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose() }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  New Catalog
                </Typography>
                <Typography variant="caption" color="error">
                  *Required
                </Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose() }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={() => { this.saveCatalog() }} variant="contained" color="secondary" disabled={disableButton}>
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        <Row lg={12}>
          <ColMod lg={6}>
            <TextField
              label="Catalog Name"
              name="name"
              fullWidth
              required
              value={name || ''}
              onChange={this.handleInputChange('name')}
              error={invalidName}
              helperText={nameText}
            />
          </ColMod>
          <ColMod lg={6}>
            <InputLabel style={{ fontSize: '1.1em' }}>Vendor *</InputLabel>
            <Select style={{ border: (invalidVendor ? '1px solid red' : '') }}
              value={vendorId || ''}
              options={vendorOptions}
              onInputChange={this.vendorOptions}
              onChange={(vendor) => { this.selectVendor(vendor) }} />
            <small style={{ color: 'red' }}>{vendorText}</small>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

NewCatalogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
}

NewCatalogForm.defaultProps = {
  onClose: () => { },
  onRefresh: () => { },
}
