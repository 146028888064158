import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import NavBar from '../components/NavBar';
import utils from '../utils/queryBuilder';
import DeeplinkUrlGenerator from '../utils/DeeplinkUrlGenerator';
import QRCode from 'qrcode.react';
import { Button, Modal, Select } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getApplicationConfigs } from '../utils/getAppConfig';
// import { Select } from '@material-ui/core/';


const canView = utils.isCurrentUserAuthorizedToView;

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 60px 0px 50px',
    [theme.breakpoints.only('xs')]: {
      padding: '10px',
    },
  },
  grid: {
    margin: '30px 0px',
    [theme.breakpoints.only('xs')]: {
      margin: '10px 0px',
    },
  },
});

const cookies = new Cookies();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: true,
      openDeeplinkModal: false,
      openDeliveryAppQRCodeModal: false,
      openSeggregationAppQRCodeModal: false,
      copied: false,
      app: {},
      apps: [],
      seggregationApp: {},
      seggregationApps: [],
    };
  }

  componentDidMount() {
    this.checkAccessToken();
    this.setAppConfig();
  }

  setAppConfig = async () => {
    const appConfig = await getApplicationConfigs();
    const { deliveryAppBetaApkUrl, deliveryAppStableApkUrl, segregationAppBetaApkUrl, segregationAppStableApkUrl } = appConfig
    this.setState({
      'apps': [{ 'label': 'Delivery App (Stable)', 'value': deliveryAppStableApkUrl },
      { 'label': 'Delivery app (Beta)', 'value': deliveryAppBetaApkUrl }],

      'app': { 'label': 'Delivery App (Stable)', 'value': deliveryAppStableApkUrl },

      'seggregationApps': [{ 'label': 'Seggregation App (Stable)', 'value': segregationAppStableApkUrl },
      { 'label': 'Seggregation App (Beta)', 'value': segregationAppBetaApkUrl }],

      'seggregationApp': { 'label': 'Seggregation App (Stable)', 'value': segregationAppStableApkUrl },
    })
  };
  checkAccessToken = () => {
    if (!cookies.get('access_token')) {
      this.setState({
        loggedIn: false,
      });
    }
  };

  handleOpenDeeplinkModal = () => {
    this.setState({
      openDeeplinkModal: true
    });
  };
  handleCloseQRCode = () => {
    this.setState({
      openDeliveryAppQRCodeModal: false,
      openSeggregationAppQRCodeModal: false,
      copied: false,
    });
  }
  handleOpenQRCodeModal = () => {
    this.setState({
      openDeliveryAppQRCodeModal: true,
    });
  };
  handleOpenSeggregationQRCodeModal = () => {
    this.setState({
      openSeggregationAppQRCodeModal: true
    });
  };

  handleCloseDeeplinkGenerator = () => {
    this.setState({
      openDeeplinkModal: false,
    });
  };
  handleOnChange = (event) => {
    if (this.state.openDeliveryAppQRCodeModal) {
      this.setState({ 'app': event })
    } else {
      this.setState({ 'seggregationApp': event })
    }
  }

  render() {
    const { classes } = this.props;
    const { openDeeplinkModal, openDeliveryAppQRCodeModal, openSeggregationAppQRCodeModal } = this.state;

    if (!this.state.loggedIn) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <NavBar />
        <div className={classes.root}>
          <Grid container className={classes.grid} spacing={40}>
            {canView(['ADMIN', 'VENDOR_MANAGER', 'WAREHOUSE_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Vendor Management{' '}
                </Typography>
                {canView(['ADMIN', 'VENDOR_MANAGER']) && (
                  <Link to="/vendors" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Vendors{' '}
                    </Typography>
                  </Link>
                )}
                {/* {canView(['ADMIN', 'VENDOR_MANAGER']) && (
                  <Link to="/vendor-catalog-view" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Vendor Catalog{' '}
                    </Typography>
                  </Link>
                )} */}
                {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/vendor-stock-ledger" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Vendor Stock Ledger{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  User Management{' '}
                </Typography>
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
                  <Link to="/users" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Users{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'USER_MANAGER']) && (
                  <Link to="/groups" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Groups{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['USER_MANAGER']) && (
                  <Link to="/user-groups" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      User Groups{' '}
                    </Typography>
                  </Link>
                )}
                  {canView(['ADMIN','WAREHOUSE_MANAGER']) && (
                  <Link to="/segregators" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Segregators{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
                  <Link to="/delivery-boys" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Delivery Boys{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
                  <Link to="/delivery-boys-detailed-report" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Delivery Boys Detailed Report{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
                  <Link to="/delivery-boys-consolidated-report" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Delivery Boys Consolidated Report{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (
                  <Link to="/attendance-summary" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Attendance Summary{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'WAREHOUSE_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Warehouse Management{' '}
                </Typography>
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/warehouses" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Warehouses{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/hubs" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Hubs{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/hub-segregation" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Hub Segregation{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/route-segregation" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Route Segregation{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/segregation-management/hub" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Manage Hub Segregation{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/segregation-management/route" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Manage Route Segregation{' '}
                    </Typography>
                  </Link>
                )}
                {/* {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link to="/assign/segregator" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Manage Hub Segregation Ownership{' '}
                    </Typography>
                  </Link>
                )} */}
              </Grid>
            )}
            {canView([
              'ADMIN',
              'ADMIN_DELIVERY',
              'DELIVERY_MANAGER',
              'SUPERVISOR',
              'PICKER',
              'SEGREGATION_BOY',
              'ROUTE_MANAGER',
              'WAREHOUSE_MANAGER'
            ]) && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body2" align="left" color="secondary" gutterBottom>
                    {' '}
                    Delivery Management{' '}
                  </Typography>
                  {canView(['ADMIN', 'ADMIN_DELIVERY', 'ROUTE_MANAGER']) && (
                    <Link to="/static-routes" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="body1" align="left" color="inherit">
                        {' '}
                        Static Routes{' '}
                      </Typography>
                    </Link>
                  )}
                  {canView([
                    'ADMIN',
                    'DELIVERY_MANAGER',
                    'SUPERVISOR',
                    'PICKER',
                    'SEGREGATION_BOY',
                  ]) && (
                      <Link to="/delivery-routes" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography variant="body1" align="left" color="inherit">
                          {' '}
                          Delivery Routes{' '}
                        </Typography>
                      </Link>
                    )}
                  {canView([
                    'ADMIN',
                    'DELIVERY_MANAGER',
                    'SUPERVISOR',
                    'PICKER',
                    'SEGREGATION_BOY',
                  ]) && (
                      <Link
                        to="/delivery-route-orders"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Typography variant="body1" align="left" color="inherit">
                          {' '}
                          Delivery Route Orders{' '}
                        </Typography>
                      </Link>
                    )}
                  {canView(['ADMIN', 'DELIVERY_MANAGER', 'WAREHOUSE_MANAGER', 'CSR']) && (
                    <Link to="/customer-order" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="body1" align="left" color="inherit">
                        {' '}
                        Customer Orders{' '}
                      </Typography>
                    </Link>
                  )}
                  {canView(['ADMIN', 'ADMIN_DELIVERY', 'WAREHOUSE_MANAGER']) && (
                    <Link to="/non-availability" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="body1" align="left" color="inherit">
                        {' '}
                        Non Availability{' '}
                      </Typography>
                    </Link>
                  )}
                  {canView(['ADMIN', 'DELIVERY_MANAGER']) && (
                    <Link
                      to="/default-route-mapping"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <Typography variant="body1" align="left" color="inherit">
                        {' '}Dummy Route Mapping{' '}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              )}
          </Grid>
          <Grid container className={classes.grid} spacing={40}>
            {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Purchasing{' '}
                </Typography>
                {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/purchase-order" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Purchase Orders{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/purchase-indent" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Purchase Indent{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/purchase-requisition" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Purchase Requisition{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/grn" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      GRN{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Product Catalog Management{' '}
                </Typography>
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/catalog-view" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Product Catalog{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/brand" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Brands{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/category" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Categories{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/packaging" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Packaging{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/quantity" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Uoms{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/catalog-approval" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Vendors Catalog Approval{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
                  <Link to="/products" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Products{' '}
                    </Typography>
                  </Link>
                )}
                {/* {canView(['ADMIN']) && (
                  <Link to="/price-list" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Price List{' '}
                    </Typography>
                  </Link>
                )} */}
              </Grid>
            )}
            {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Stock Management{' '}
                </Typography>
                {/* {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/stock-adjustment" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Stock Adjustment{' '}
                    </Typography>
                  </Link>
                )} */}
                {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/stock-inventory" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Stock Inventory{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/stock-ledger" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Stock Ledger{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Admin{' '}
                </Typography>
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/region" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Regions{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/survey" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Survey{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/orders" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Orders{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/pincode" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Pincodes{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER', 'FINANCE', 'CSR']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Customer Management{' '}
                </Typography>
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER', 'CSR']) && (
                  <Link
                    to="/cs-assist"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      {/* Customer Dashboard{' '}<small>(beta)</small> */}
                      Customer Dashboard{' '}
                    </Typography>
                  </Link>
                )}
                {/* {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/customer-info"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Customers{' '}
                    </Typography>
                  </Link>
                )} */}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/customer-addresses"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Customer Addresses{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link to="/buildings" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Buildings{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link to="/localities" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Localities{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link to="/new-customers" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      New Customers{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link to="/unassigned-customers" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      UnAssigned Customers{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER', 'FINANCE']) && (
                  <Link
                    to="/monthly-invoices"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Monthly Invoices{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'APP_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Offer Management{' '}
                </Typography>
                {/* {canView(['ADMIN', 'ADMIN_MASTERDATA','APP_MANAGER']) && (
                  <Link
                    to="/product-offers"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Product offers{' '}
                    </Typography>
                  </Link>
                )} */}
                {/* {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
                  <Link
                    to="/offers"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Offers{' '}
                    </Typography>
                  </Link>
                )} */}
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'APP_MANAGER']) && (
                  <Link
                    to="/offer-campaign"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Offer Campaign{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}

            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Notification Management{' '}
                </Typography>
                {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/send-sms"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Sms{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {/* {canView(['INVEST_WITH_US']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Invest With Us{' '}
                </Typography>
                {canView(['INVEST_WITH_US']) && (
                  <Link
                    to="/investment"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Invest Now{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )} */}
            {canView(['ADMIN', 'DELIVERY_MANAGER', 'CC_AGENT', 'CC_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Customer Complaints{' '}
                </Typography>
                {canView(['ADMIN', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/customer-complaints"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Customer Complaints{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/customer-complaints-by-cc"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Customer Complaints (CC Executive){' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="/customer-complaints-by-hub-route"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Customer Complaints (Hub & Route){' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'DELIVERY_MANAGER', 'CC_AGENT', 'CC_MANAGER']) && (
                  <Link
                    to="/tickets"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Ticket Management
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'APP_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  App Management{' '}
                </Typography>
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link
                    to="/banner-management"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Banners{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link
                    to="/deal-of-the-day-products"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Deal of the day{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link to="/product-widgets" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Product Widgets{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link to="/app-widgets" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      App Widgets{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link to="/home-offers" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Home Offers{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER']) && (
                  <Link to="" onClick={this.handleOpenDeeplinkModal} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Deeplink Generator{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'APP_MANAGER', 'DELIVERY_MANAGER', 'SEGREGATION_BOY']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  App Downloads{' '}
                </Typography>
                {canView(['ADMIN', 'APP_MANAGER', 'DELIVERY_MANAGER']) && (
                  <Link
                    to="" onClick={this.handleOpenQRCodeModal}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Delivery App{' '}
                    </Typography>
                  </Link>
                )}
                {canView(['ADMIN', 'APP_MANAGER', 'SEGREGATION_BOY']) && (
                  <Link
                    to="" onClick={this.handleOpenSeggregationQRCodeModal}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      Seggregation App{' '}
                    </Typography>
                  </Link>
                )}


              </Grid>
            )}
            {canView(['ADMIN']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  Audit
                </Typography>
                {canView(['ADMIN']) && (
                  <Link
                    to="/audit-report"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography variant="body1" align="left" color="inherit">
                      Audit Report
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
            {canView(['ADMIN', 'APP_MANAGER', 'ADMIN_MASTERDATA']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  Audience
                </Typography>
                <Link
                  to="/audience-set"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="body1" align="left" color="inherit">
                    Audience Set
                  </Typography>
                </Link>
              </Grid>
            )}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'APP_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  Referral
                </Typography>
                <Link
                  to="/referral"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="body1" align="left" color="inherit">
                    Referral Generator
                  </Typography>
                </Link>
              </Grid>
            )}
            {canView(['ADMIN', 'VENDOR_MANAGER', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" align="left" color="secondary" gutterBottom>
                  {' '}
                  Margin Management{' '}
                </Typography>
                {canView(['ADMIN', 'VENDOR_MANAGER', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
                  <Link to="/tot-margin" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="body1" align="left" color="inherit">
                      {' '}
                      TOT Margin{' '}
                    </Typography>
                  </Link>
                )}
              </Grid>
            )}
          </Grid>
        </div>
        <DeeplinkUrlGenerator open={openDeeplinkModal} onClose={this.handleCloseDeeplinkGenerator} />

        <Modal
          title={openDeliveryAppQRCodeModal ? "Delivery App" : 'Seggregation App'}
          visible={openDeliveryAppQRCodeModal || openSeggregationAppQRCodeModal}
          onOk={this.handleCloseQRCode}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={false}
          width='560px'
        >


          <div style={{ display: 'block' }}>
            <Typography variant="caption"> Select the apk</Typography>
            <Select style={{ width: "70%", marginBottom: 16 }}
              value={openDeliveryAppQRCodeModal ? this.state.app.value : this.state.seggregationApp.value}
              options={openDeliveryAppQRCodeModal ? this.state.apps : this.state.seggregationApps}
              onChange={(value) => { this.handleOnChange({ value }) }}
            />
            <div style={{ display: 'flex' }}>
              <div>
                <QRCode value={openDeliveryAppQRCodeModal ? this.state.app.value : this.state.seggregationApp.value} />
                <span style={{ display: 'block', fontWeight: 'bold' }}>Scan the QR-Code </span>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <span style={{ display: 'block', marginBottom: '10px' }}>Copy the below link </span>
                <CopyToClipboard text={openDeliveryAppQRCodeModal ? this.state.app.value : this.state.seggregationApp.value} onCopy={() => this.setState({ copied: true })}>
                  <Button >{openDeliveryAppQRCodeModal ? this.state.app.value : this.state.seggregationApp.value}</Button>
                </CopyToClipboard>
                {this.state.copied ? <span style={{ color: 'green' }}>Copied.</span> : null}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
};

const mapStateToProps = state => ({
  User: state.User,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(Home));
