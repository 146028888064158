import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { getProductWidgetsList } from '../../api/NetworkHandler';

const onLoad = async () => {
  const response = await apiCall.sendRequest("get", getProductWidgetsList);
  const { data: { data: productWidgets } } = response;

  return productWidgets.map(widget => {
    return {
      ...widget,
      value: widget.id,
      label: widget.title,
    }
  })
}

function ProductWidgetSelector(props) {
  const { selected, onSelect, isClearable, disabled } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
      disabled={disabled}
    />
  )
}

ProductWidgetSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
  /**
   * Select box can be disabled or not
   */
  disabled: PropTypes.bool,
};

ProductWidgetSelector.defaultProps = {
  selected: null,
  isClearable: true,
  disabled: false,
};

export default ProductWidgetSelector;
