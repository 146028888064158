import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEmpty } from 'lodash';
import {
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Paper,
  Card, CardContent, CardActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Row, Col } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import ItemText from '../ItemText';
import TableBuilder from '../TableBuilder';
import utils from '../../../utils/queryBuilder';
import apiCall, {
  ordersAfterDatePath,
  transactionHistoryDownloadPath,
  updateInitiatedTransactionStatus,
  getPercentageCashbackEnableStatusByCustomerId,
} from '../../../api/NetworkHandler';
import * as actions from '../../../actions/csActions';
import styles from './styles';
import Colors from '../../../utils/Colors';
import Toast from '../../../utils/Toast';
import orderBy from 'lodash/orderBy';
import { getCustomerApplicationConfigs } from '../../../utils/getAppConfig';
import VisibilityIcon from '@material-ui/icons/Visibility';;
import IconButton from '@material-ui/core/IconButton';
import PaymentSummary from './PaymentSummary';

const modalSytle = {
  modalContainer: {
    padding: '10px',
    marginTop: '80px',
    boxShadow: ' 0 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  modalRow: {
    padding: '10px',
    justifyContent: 'center',
  }
};

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#2a2a2a',
    position: 'sticky',
    color: 'white',
    top: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CanView = utils.isCurrentUserAuthorizedToView;
var versionDict = {
  "percentageCashbackRequestId": 0
}

class UserTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      nextRefundDate: moment(),
      lastOrders: null,
      fromDate: moment()
        .subtract('days', 15)
        .format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      showPaymentStatus: false,
      paymentStatus: {},
      paymentMessage: '',
      percentageCashbackEnabled: false,
      showPaymentSummary: false,
    };
  }

  componentDidMount() {
    const { customerId = null } = this.props;
    this.fetchLastOrders();
    customerId && this.getPercentageCashbackEnableStatus(customerId);
  }

  getPercentageCashbackEnableStatus = debounce(async (customerId) => {
    try {
      versionDict.percentageCashbackRequestId += 1;
      let prevRequestId = versionDict.percentageCashbackRequestId;
      const response = await apiCall.sendRequest('get', getPercentageCashbackEnableStatusByCustomerId(customerId));
      const { data: { data: { isPercentageCashbackEnabled = false } = {} } = {} } = response;
      if (prevRequestId == versionDict.percentageCashbackRequestId) {
        this.setState({
          percentageCashbackEnabled: isPercentageCashbackEnabled,
        });
      }
    } catch (err) {
      console.log(err.response);
      this.setState({
        percentageCashbackEnabled: false,
      });
    }
  }, 500);

  getCompletedTransactionsConfig = () => ({
    table: [
      {
        label: 'Id',
        pick: 'id',
        style: { width: '10%' },
      },
      {
        label: 'Payment Mode',
        pick: 'gateway',
        style: { width: '10%' },
      },
      {
        label: 'Payment Type',
        pick: 'paymentType',
        style: { width: '5%' },
      },
      {
        label: 'Transaction Id',
        pick: 'transactionId',
        style: { width: '10%' },
      },
      {
        label: 'Date',
        pick: 'gatewayTransactionTime',
        style: { width: '15%' },
      },
      {
        label: 'Amount',
        pick: 'amount',
        style: { width: '10%' },
      },
      {
        label: 'Status',
        pick: 'status',
        style: { width: '10%' },
      },
      {
        label: 'Comments',
        pick: 'notes',
        style: { width: '25%' },
      },
    ],
  });

  getInitiatedTransactionsConfig = () => ({
    table: [
      {
        label: 'Order Id',
        pick: 'id',
        style: { width: '10%' },
      },
      {
        label: 'Payment Mode',
        pick: 'paymentGateway',
        style: { width: '10%' },
      },
      {
        label: 'Transaction Id',
        pick: 'transactionId',
        style: { width: '20%' },
      },
      {
        label: 'Date',
        pick: 'gatewayTransactionTime',
        style: { width: '15%' },
      },
      {
        label: 'Amount',
        pick: 'amount',
        style: { width: '10%' },
      },
      {
        label: 'Status',
        pick: 'status',
        style: { width: '10%' },
      },
      {
        label: 'Transaction Type',
        pick: 'transactionType',
        style: { width: '25%' },
      },
    ],
  });

  fetchLastOrders = async () => {
    const { customerId } = this.props;
    let nextRefundDate = moment();
    const date3DaysBefore = moment()
      .subtract(3, 'days')
      .format('YYYY-MM-DD');
    apiCall
      .sendRequest('get', ordersAfterDatePath(customerId, date3DaysBefore))
      .then((res) => {
        const { data = [] } = res;
        if (data.length > 0) {
          const [lastOrder] = data.reverse();
          nextRefundDate = moment(lastOrder.deliveryDate)
            .add(3, 'days');
        }
        this.setState({
          lastOrders: data,
          nextRefundDate,
        });
      })
      .catch(() => {
        this.setState({
          lastOrders: [],
          nextRefundDate,
        });
      });
  };

  handleTabChange = (e, v) => {
    this.setState({ tabValue: v });
  };


  handleClose = () => {
    const { paymentStatus } = this.state;

    if (paymentStatus.status === 'COMPLETED') {
      this.props.getTransactionDetails(paymentStatus.customerId);
      this.setState({
        tabValue: 0,
      });
    }

    this.setState({
      showPaymentStatus: false,
      paymentStatus: {},
      paymentMessage: '',
    });
  }

  handlePaymentStatus = async (customerId, transactionId) => {
    apiCall
      .sendRequest('get', updateInitiatedTransactionStatus(customerId, transactionId))
      .then((res) => {
        const { data = [] } = res;
        this.setState({
          paymentStatus: data.data,
          paymentMessage: data.message,
          showPaymentStatus: true,
        });
      })
      .catch((error) => {
        this.setState({
          paymentStatus: {},
        });

        if (error.response && error.response.data && error.response.status === 400 && error.response.data.message) {
          Toast.error(error.response.data.message);
        } else {
          Toast.error('Sorry your request could not be processed');
        }
      });
  }

  handleDownload = () => {
    const { fromDate, toDate } = this.state;
    const { customerId } = this.props;
    const url = transactionHistoryDownloadPath(customerId, fromDate, toDate);
    apiCall.downloadFile(url, `Transaction_History_${customerId}.csv`);
  };
  isValidDateRange = (value, key) => {
    const { fromDate, toDate } = this.state;
    const isDateEmpty = value === '';
    const isNotFutureDate = moment().isSameOrAfter(moment(value));
    const endDateValidation =
      key === 'toDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate)));
    const fromDateValidation =
      key === 'fromDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate)));
    return isDateEmpty || (isNotFutureDate && (endDateValidation || fromDateValidation));
  };

  handleChange = (value, key) => {
    const isNotDateField = key !== 'toDate' && key !== 'fromDate';
    if (isNotDateField || this.isValidDateRange(value, key)) {
      this.setState({
        [key]: value,
      });
    }
  };

  getDefaultAddress = () => {
    const { customerAddresses } = this.props;
    const defaultAddress = customerAddresses.filter(address => address.isDefault == true)
    return defaultAddress[0] || {};
  }

  getDeliverable = () => {
    const defaultAddress = this.getDefaultAddress();
    const { staticRouteId = null, status = '' } = defaultAddress;
    const isNotDeliverable = !staticRouteId || status == 'NON_DELIVERABLE';

    return !isNotDeliverable;
  }

  renderInitiatedTransactions = (initiatedTransactions) => {
    const { customerId } = this.props;
    const tramsactions = orderBy(initiatedTransactions, "id", "desc");
    return (
      <div style={{ flex: 1, overflow: 'auto' }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>
              <StyledTableCell>Payment Mode</StyledTableCell>
              <StyledTableCell>Payment Type</StyledTableCell>
              <StyledTableCell>Transaction Id</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Transaction Type</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tramsactions.length > 0 && tramsactions.map(txn => (
                <TableRow>
                  <TableCell>{txn.id}</TableCell>
                  <TableCell>{txn.paymentGateway}</TableCell>
                  <TableCell>{txn.transactionType}</TableCell>
                  <TableCell>{txn.transactionId}</TableCell>
                  <TableCell>{txn.timeCreated}</TableCell>
                  <TableCell>{txn.amount}</TableCell>
                  <TableCell>{txn.status}</TableCell>
                  <TableCell>{txn.transactionType}</TableCell>
                  <TableCell>
                    <Button download variant="contained" onClick={() => this.handlePaymentStatus(customerId, txn.transactionId)}>Status</Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  }

  viewPaymentSummary = async () => {
    this.setState({
      showPaymentSummary: true,
    });

  }
  handleClosePaymentSummary = () => {
    this.setState({ showPaymentSummary: false })
  }
  
  render() {
    const {
      customerId,
      transactionDetails,
      initiatedTransactions,
      refundTxns,
      walletBalance,
      refundAmount,
      maxRefundAmount,
      handleRefundAmount,
      processRefund,
      totalWalletBalance,
      customerInfo,
      currency,
    } = this.props;
    const {
      tabValue, lastOrders, nextRefundDate, fromDate, toDate, showPaymentStatus, paymentStatus, paymentMessage, percentageCashbackEnabled,
      showPaymentSummary,
    } = this.state;
    let totalAmount = 0;
    let rechargeAmount = 0;
    let cashbackAmount = 0;
    if (!isEmpty(totalWalletBalance)) {
      totalAmount = totalWalletBalance.availableBalance;
      rechargeAmount = totalWalletBalance.rechargeBalance;
      cashbackAmount = totalWalletBalance.cashbackBalance;
    }

    const today = moment();
    const deliverable = this.getDeliverable();
    const { name, email } = customerInfo;

    return (
      <div>
        <div style={styles.actionContainer}>
          <div style={styles.actionLeft}>
            <Row>
              <div style={styles.title}>Wallet Management</div>
            </Row>
            <Row>
              <Col lg={percentageCashbackEnabled ? 4 : 3}>
                {!percentageCashbackEnabled ? <ItemText name="Wallet Balance" value={walletBalance} style={styles.infoItem} /> :
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center", color: "green" }}>{totalAmount}</span>
                      <small style={{ textAlign: "center" }}>Wallet Balance</small>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>{rechargeAmount}</span>
                      <small style={{ textAlign: "center" }}>Recharge Amount</small>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>{cashbackAmount}</span>
                      <small style={{ textAlign: "center" }}>Cashback Amount</small>
                    </div>
                  </div>}
              </Col>
              {maxRefundAmount > 0 && (
                <Col lg={percentageCashbackEnabled ? 3 : 4}>
                  <div style={{ display: "flex", flexDirection: "column", borderLeft: '1px solid' }}>
                    <span style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center", color: "green" }}>{maxRefundAmount}</span>
                    <small style={{ textAlign: "center" }}>Refund Amount Available</small>
                  </div>
                </Col>
              )}
              {maxRefundAmount > 0 && (CanView(['ADMIN']) || (CanView(['REFUND_MANAGER']) && nextRefundDate.isSameOrBefore(today))) && (
                <Col lg={percentageCashbackEnabled ? 4 : 5}>
                  <TextField
                    label="Amount"
                    value={refundAmount}
                    style={{ width: 75 }}
                    autoFocus
                    onChange={e => handleRefundAmount(e.target.value)}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={styles.refundBtn}
                    disabled={refundTxns}
                    onClick={() => processRefund(customerId, refundAmount)}
                  >
                    Initiate Refund
                  </Button>
                  {maxRefundAmount > 0 && !deliverable && refundTxns && (
                    <p style={{ fontSize: 10, color: Colors.primary }}>Refund cannot be initiated as there are pending refunds!</p>
                  )}
                </Col>
              )}
              {maxRefundAmount > 0 && deliverable && (CanView(['REFUND_MANAGER']) && !nextRefundDate.isSameOrBefore(today)) && (
                <Col>
                  <ItemText
                    name="Next Refund Date"
                    value={nextRefundDate.format('ll')}
                    style={styles.infoItem}
                  />
                </Col>
              )}
              {maxRefundAmount > 0 && !CanView(['ADMIN', 'REFUND_MANAGER']) && (
                <Col>
                  <div style={styleMedia.note}>You are not authorised to issue refund</div>
                </Col>
              )}
            </Row>

            <Button
              onClick={() => { this.viewPaymentSummary() }}
              color="primary"
              variant="outlined"
              size="small"
              style={styles.actionBtn} >
              View Payment Summary
            </Button>
          </div>
          <div style={styles.actionRight}>
            <Row>
              <div style={styles.title}>Transactions Statement Download</div>
            </Row>
            <Row style={{ alignItems: 'flex-end', width: '100%' }}>
              <ColMod md={4}>
                <TextField
                  id="date"
                  label="From Date"
                  type="date"
                  value={fromDate}
                  style={{ paddingRight: '16px', float: 'none ' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e.target.value, 'fromDate')}
                />
              </ColMod>
              <ColMod md={4}>
                <TextField
                  id="date"
                  label="To Date"
                  type="date"
                  value={toDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e.target.value, 'toDate')}
                />
              </ColMod>
              <ColMod md={2}>
                <Button
                  download
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={styles.refundBtn}
                  onClick={this.handleDownload}
                >
                  Download
                </Button>
              </ColMod>
            </Row>
          </div>

        </div>


        {/* {!isEmpty(transactionDetails) && ( */}
        <div style={styles.content}>
          <div style={styles.tabBarContent}>
            <Tabs value={tabValue} onChange={this.handleTabChange} style={styles.tabBar}>
              <Tab label="Completed Transactions" />
              {!isEmpty(initiatedTransactions) && <Tab label="Initiated Transactions" />}
            </Tabs>
          </div>
          {tabValue === 0 && (
            <TableBuilder
              config={this.getCompletedTransactionsConfig()}
              data={transactionDetails}
              pagination={false}
            />
          )}
          {tabValue === 1 && !isEmpty(initiatedTransactions) && (
            this.renderInitiatedTransactions(initiatedTransactions)
          )}
        </div>
        {/* )} */}
        <PaymentSummary
          showPaymentSummary={showPaymentSummary}
          handleClosePaymentSummary={this.handleClosePaymentSummary}
          customerId={customerId}
          percentageCashbackEnabled={percentageCashbackEnabled}
          currency={currency} />
      </div >
    );
  }
}

UserTransactions.propTypes = {
  customerId: PropTypes.string,
  transactionDetails: PropTypes.instanceOf(Array),
  initiatedTransactions: PropTypes.instanceOf(Array),
  refundTxns: PropTypes.bool,
  walletBalance: PropTypes.number,
  refundAmount: PropTypes.number,
  maxRefundAmount: PropTypes.number,
  handleRefundAmount: PropTypes.func,
  processRefund: PropTypes.func,
  getTransactionDetails: PropTypes.func,
  currency:PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  const { getTransactionDetails } = actions;
  return {
    getTransactionDetails: bindActionCreators(getTransactionDetails, dispatch),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(UserTransactions);
