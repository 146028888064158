import { isEmpty } from 'lodash';
import moment from 'moment';
import * as type from '../constants';
import apiCall, {
  customerProfileById,
  customerOrderByFiltersPath,
  customerProfileByFilters,
  customerAddressesPath,
  editCustomerOrdersPath,
  getAddressWithCustomerIdPath,
  getSubmitAddressPath,
  setDefaultAddress,
  getTransactionHistory,
  transactionHistoryPath,
  initiatedTransactions,
  refundAmmoutPath,
  upcomingDeliveriesByDate,
  deliveryRouteOrdersPath,
  findDeliveryRouteOrder,
  getDeliveryRouteDetailsById,
  schedulesPath,
  getPlanItemsPath,
  refundProcessPath,
  staticRoute,
  editPlanItem,
  savePlansPath,
  customerInvoiceById,
  getTotalWalletBalance
} from '../api/NetworkHandler';

export const quickSearch = q => (dispatch) => {
  dispatch({
    type: type.QUICK_SEARCH_LOADING,
    payload: q,
  });
  apiCall
    .sendRequest('get', customerProfileByFilters(q, true))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: !isEmpty(data) ? type.QUICK_SEARCH_SUCCESS : type.QUICK_SEARCH_FAILURE,
        payload: data.data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.QUICK_SEARCH_FAILURE,
      });
    });
};

export const clearQuickSearch = () => (dispatch) => {
  dispatch({
    type: type.CLEAR_QUICK_SEARCH,
  });
};

export const quickSearchItemSelect = () => (dispatch) => {
  dispatch({
    type: type.SELECT_QUICK_SEARCH_ITEM,
  });
};

export const getCustomerInfo = id => (dispatch) => {
  apiCall
    .sendRequest('get', customerProfileById(id))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: type.FETCH_CS_INFO_SUCCESS,
        payload: data.data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_CS_INFO_FAILURE,
      });
    });
};

export const getCustomerInvoices = (id, page = 0) => (dispatch) => {
  apiCall
    .sendRequest('get', customerInvoiceById(id, page))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: page === 0 ? type.FETCH_CS_INVOICE_SUCCESS : type.FETCH_CS_MORE_INVOICE_SUCCESS,
        payload: {
          invoiceList: data.data,
          totalInvoicePages: data.totalNoOfPages,
          currentInvoicePage: data.page,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: page === 0 ? type.FETCH_CS_INVOICE_FAILURE : type.FETCH_CS_MORE_INVOICE_FAILURE,
      });
    });
};

export const getCustomerOrders = (id, page = 0) => (dispatch) => {
  apiCall
    .sendRequest(
      'get',
      customerOrderByFiltersPath(`customerId=${id}&page=${page}&sort=timeUpdated,desc`),
    )
    .then((res) => {
      const { data } = res;
      const { _embedded } = data;
      const csOrders = _embedded['customer-orders'];
      dispatch({
        type: page === 0 ? type.FETCH_CS_ORDERS_SUCCESS : type.FETCH_CS_MORE_ORDERS_SUCCESS,
        payload: {
          csOrders,
          page,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: page === 0 ? type.FETCH_CS_ORDERS_FAILURE : type.FETCH_CS_MORE_ORDERS_FAILURE,
      });
    });
};

export const getUpcomingOrder = (
  id,
  addressId,
  date = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD'),
) => (dispatch) => {
  apiCall
    .sendRequest('get', upcomingDeliveriesByDate(date, id, addressId))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: type.NEXT_DELIVERY_ITEMS_SUCCESS,
        payload: {
          data,
          date,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: type.NEXT_DELIVERY_ITEMS_FAILURE,
      });
    });
};

export const getCsPlanDetails = csId => (dispatch) => {
  apiCall
    .sendRequest('get', schedulesPath(csId))
    .then((res) => {
      const { data } = res.data;
      dispatch({
        type: type.GET_CS_PLANS_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.GET_CS_PLANS_FAILURE,
      });
    });
};

export const getCsPlanItems = (csId, addressId) => (dispatch) => {
  apiCall
    .sendRequest('get', getPlanItemsPath(csId, addressId))
    .then((res) => {
      const { data } = res.data;
      dispatch({
        type: type.GET_CS_PLAN_ITEMS_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.GET_CS_PLAN_ITEMS_FAILURE,
      });
    });
};

export const getPlanDetails = (csId, addressId) => (dispatch) => {
  dispatch(getCsPlanDetails(csId));
  dispatch(getCsPlanItems(csId, addressId));
};

export const changeUpcomingDetails = obj => (dispatch) => {
  dispatch({
    type: type.UPDATE_CS_PLAN,
    payload: obj,
  });
};

export const updateUpcomingDetails = (cid, aid, date, obj) => (dispatch) => {
  apiCall
    .sendRequest('post', upcomingDeliveriesByDate(date, cid, aid), obj)
    .then(() => {
      dispatch(getUpcomingOrder(cid, aid, date));
    })
    .catch(() => {
      dispatch(getUpcomingOrder(cid, aid, date));
    });
};

export const addNewUpcomingItem = obj => (dispatch) => {
  dispatch({
    type: type.ADD_NEW_UPCOMING_ITEM,
    payload: obj,
  });
};

export const addNewScheduledItem = obj => (dispatch) => {
  dispatch({
    type: type.ADD_NEW_SCHEDULED_ITEM,
    payload: obj,
  });
};

export const cancelNewScheduledItem = () => (dispatch) => {
  dispatch({
    type: type.CANCEL_NEW_SCHEDULED_ITEM,
  });
};

export const getCustomerAddresses = id => (dispatch) => {
  apiCall
    .sendRequest('get', customerAddressesPath(id))
    .then((res) => {
      const { data } = res;
      dispatch({
        type: type.FETCH_CS_ADDRESSES_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_CS_ADDRESSES_FAILURE,
      });
    });
};

export const completedTransactions = id => (dispatch) => {
  apiCall
    .sendRequest('get', getTransactionHistory(id))
    .then((res) => {
      const { data = {} } = res;
      const { data: transactionsHistory = [] } = data;
      dispatch({
        type: type.FETCH_TRANSACTION_HISTORY_SUCCESS,
        payload: transactionsHistory,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_TRANSACTION_HISTORY_FAILURE,
      });
    });
};

export const InitiatedTransactions = id => (dispatch) => {
  apiCall
    .sendRequest('get', initiatedTransactions(id, 0, 1000))
    .then((res) => {
      const { data = {} } = res.data;
      const { paymentTransaction = [], refundTxns } = data;
      dispatch({
        type: type.FETCH_INITIATED_TRANSACTION_SUCCESS,
        payload: paymentTransaction,
      });
      dispatch({
        type: type.FETCH_REFUND_TRANSACTION,
        payload: refundTxns,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_INITIATED_TRANSACTION_FAILURE,
      });
    });
};

export const getRefundAmount = id => (dispatch) => {
  apiCall
    .sendRequest('get', refundAmmoutPath(id))
    .then((res) => {
      const { data = {} } = res.data;
      dispatch({
        type: type.FETCH_REFUND_AMOUNT_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_REFUND_AMOUNT_FAILURE,
      });
    });
};

export const getWalletDetails = id => (dispatch) => {
  apiCall
    .sendRequest('get', transactionHistoryPath(id))
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: type.FETCH_WALLET_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_WALLET_FAILURE,
      });
    });
};

export const getTotalWalletBalanceDetails = id => (dispatch) => {
  apiCall
    .sendRequest('get', getTotalWalletBalance(id))
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: type.FETCH_TOTAL_WALLET_SUCCESS,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_TOTAL_WALLET_FAILURE,
      });
    });
};

export const getTransactionDetails = id => (dispatch) => {
  dispatch(getWalletDetails(id));
  dispatch(getTotalWalletBalanceDetails(id));
  dispatch(completedTransactions(id));
  dispatch(InitiatedTransactions(id));
  dispatch(getRefundAmount(id));
};

export const dtEditOrder = order => (dispatch) => {
  dispatch({
    type: type.DT_EDIT_ORDER,
    payload: order,
  });
};

export const orderDetailsChange = obj => (dispatch) => {
  dispatch({
    type: type.ORDER_DETAILS_CHANGE,
    payload: obj,
  });
};

export const addNewOrderLineItem = obj => (dispatch) => {
  dispatch({
    type: type.ADD_NEW_LINE_ITEM,
    payload: obj,
  });
};

export const updateOrderDetails = obj => (dispatch) => {
  const { customerId } = obj;
  apiCall
    .sendRequest('post', editCustomerOrdersPath, obj)
    .then(() => {
      dispatch(getCustomerOrders(customerId));
      dispatch({
        type: type.UPDATE_ORDER_DETAILS_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.UPDATE_ORDER_DETAILS_FAILURE,
      });
    });
};

export const changeSelectedAddress = obj => (dispatch) => {
  dispatch({
    type: type.CHANGE_SELECTED_ADDRESS,
    payload: obj,
  });
};

export const addressEdit = (obj, isNew = false) => (dispatch) => {
  if (isNew) {
    dispatch({
      type: type.NEW_ADDRESS_EDIT,
      payload: obj,
    });
  } else {
    dispatch({
      type: type.ADDRESS_EDIT,
      payload: obj,
    });
  }
};

export const updateAddress = obj => (dispatch) => {
  const { id, customerId } = obj;
  apiCall
    .sendRequest('patch', getSubmitAddressPath(id), obj)
    .then(() => {
      dispatch(getCustomerAddresses(customerId));
      dispatch({
        type: type.UPDATE_ADDRESS_CHANGE_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.UPDATE_ADDRESS_CHANGE_FAILURE,
      });
    });
};

export const addAddress = (customerId, obj) => (dispatch) => {
  const postObj = { ...obj, customerId };
  apiCall
    .sendRequest('post', getAddressWithCustomerIdPath, postObj)
    .then(() => {
      dispatch(getCustomerAddresses(customerId));
      dispatch({
        type: type.ADD_NEW_ADDRESS_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.ADD_NEW_ADDRESS_FAILURE,
      });
    });
};

export const updateAddressChange = (obj, customerId, newAddress = false) => (dispatch) => {
  if (newAddress) dispatch(addAddress(customerId, obj));
  else dispatch(updateAddress(obj));
};

export const setAddressDefault = (csId, adrsId) => (dispatch) => {
  apiCall
    .sendRequest('post', setDefaultAddress(csId, adrsId))
    .then(() => {
      dispatch(getCustomerAddresses(csId));
      dispatch({
        type: type.ADDRESS_SET_DEFAULT_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.ADDRESS_SET_DEFAULT_FAILURE,
      });
    });
};

export const setRoute = (routeId, addressId, customerId) => (dispatch) => {
  apiCall
    .sendRequest('put', staticRoute(routeId, addressId))
    .then(() => {
      dispatch(getCustomerAddresses(customerId));
      dispatch({
        type: type.SET_ROUTE_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.SET_ROUTE_FAILURE,
      });
    });
};

export const profileEdit = obj => (dispatch) => {
  dispatch({
    type: type.PROFILE_EDIT,
    payload: obj,
  });
};

export const updateProfileChange = obj => (dispatch) => {
  const { customerId } = obj;
  apiCall
    .sendRequest('post', customerProfileById(customerId), obj)
    .then(() => {
      dispatch(getCustomerInfo(customerId));
      dispatch({
        type: type.UPDATE_PROFILE_CHANGE_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: type.UPDATE_PROFILE_CHANGE_FAILURE,
      });
    });
};

const getDeliveryRouteList = routeId => (dispatch) => {
  apiCall
    .sendRequest('get', getDeliveryRouteDetailsById(routeId))
    .then((res) => {
      const { data } = res;
      const { deliveryRoute, deliveryRouteOrders } = data.data;
      dispatch({
        type: type.FETCH_DELIVERY_ROUTE_LIST_SUCCESS,
        payload: {
          deliveryRoute,
          deliveryRouteOrders,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: type.FETCH_DELIVERY_ROUTE_LIST_FAILURE,
      });
    });
};

const getDeliveryRouteDetails = routeOrderName => (dispatch) => {
  const today = moment().format('YYYY-MM-DD');
  apiCall
    .sendRequest('get', findDeliveryRouteOrder(routeOrderName, today))
    .then((res) => {
      const { data } = res;
      const { _embedded } = data;
      const routeDetails = _embedded['delivery-routes'][0]; // can be modified to match the orderid
      const { id } = routeDetails;
      if (routeDetails) {
        dispatch(getDeliveryRouteList(id));
      }
    })
    .catch(() => {
      dispatch({
        type: type.GET_DELIVERY_ROUTE_DETAILS_FAILURE,
      });
    });
};

export const getOngoingRouteOrders = obj => (dispatch) => {
  const { id, customerId } = obj;
  const filter = {
    customerId,
    orderId: id,
  };
  apiCall
    .sendRequest('get', deliveryRouteOrdersPath(filter, 0))
    .then((res) => {
      const { data } = res;
      const { _embedded } = data;
      const routeOrderObj = _embedded['delivery-route-order'][0]; // can be modified to match the orderid
      const { deliveryRouteName } = routeOrderObj;
      if (deliveryRouteName) {
        dispatch(getDeliveryRouteDetails(deliveryRouteName));
      }
    })
    .catch(() => {
      dispatch({
        type: type.GET_ROUTE_ORDER_FAILURE,
      });
    });
};

export const handleRefundAmount = amount => (dispatch) => {
  dispatch({
    type: type.CHANGE_REFUND_AMOUNT,
    payload: parseInt(amount, 10) || 0,
  });
};

export const processRefund = (cid, amount) => (dispatch) => {
  apiCall
    .sendRequest('post', refundProcessPath(cid), {
      refundAmount: amount,
      customerID: cid,
    })
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: type.PROCESS_REFUND_SUCCESS,
        });
        dispatch(getTransactionDetails(cid));
      }
    })
    .catch(() => {
      dispatch({
        type: type.PROCESS_REFUND_FAILURE,
      });
    });
};

export const editPlannedItem = obj => (dispatch) => {
  const { paramBody, customerId, addressId } = obj;
  const { scheduleId, id: planItemId } = paramBody;
  apiCall
    .sendRequest('post', editPlanItem(scheduleId, customerId, planItemId, addressId), paramBody)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: type.EDIT_PLANNED_ITEM_SUCCESS,
        });
        dispatch(getPlanDetails(customerId, addressId));
      }
    })
    .catch(() => {
      dispatch({
        type: type.EDIT_PLANNED_ITEM_FAILURE,
      });
    });
};

export const addPlannedItem = obj => (dispatch) => {
  const { paramBody, customerId, addressId } = obj;
  apiCall
    .sendRequest('post', savePlansPath(customerId, addressId), [paramBody])
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: type.ADD_PLANNED_ITEM_SUCCESS,
        });
        dispatch(getPlanDetails(customerId, addressId));
      }
    })
    .catch(() => {
      dispatch({
        type: type.ADD_PLANNED_ITEM_FAILURE,
      });
    });
};

export const deletePlannedItem = obj => (dispatch) => {
  const { paramBody, customerId, addressId } = obj;
  const { scheduleId, planItemId } = paramBody;
  apiCall
    .sendRequest('delete', editPlanItem(scheduleId, customerId, planItemId, addressId))
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: type.EDIT_PLANNED_ITEM_SUCCESS,
        });
        dispatch(getPlanDetails(customerId, addressId));
      }
    })
    .catch(() => {
      dispatch({
        type: type.EDIT_PLANNED_ITEM_FAILURE,
      });
    });
};

export const onCreateCustomPlan = (csId, body, cb) => (dispatch) => {
  apiCall
    .sendRequest('post', schedulesPath(csId), body)
    .then((res) => {
      const {
        data: { data },
      } = res;
      const [schedule] = data;
      dispatch(getCsPlanDetails(csId));
      dispatch({
        type: type.NEW_CUSTOM_PLAN_SUCCESS,
      });
      cb(schedule);
    })
    .catch(() => {
      dispatch({
        type: type.NEW_CUSTOM_PLAN_FAILURE,
      });
    });
};
