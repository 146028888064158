import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import { TextField, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReactSelect from 'react-select';

export default function EditForm(props) {
  const {
    vendorCatalog,
    catalogStatuses,
    handleInputChange,
    handleCancel,
    handleSave,
    vendorOptions,
    selectVendor,
    getVendorOptions
  } = props;

  const {
    name,
    status,
    comments,
    vendorCatalogVendorID: vendorId
  } = vendorCatalog;

  return (
    <Grid fluid>
      <Row between='lg'>
        <ColMod>
          <Typography variant='title'>
            Catalog Details
          </Typography>
        </ColMod>
      </Row>
      <Row>
        <ColMod lg={4}>
          <TextField
            label="Catalog Name"
            name="name"
            fullWidth
            value={name || ''}
            onChange={handleInputChange('name')}
            inputProps={{ readOnly: true }}
          />
        </ColMod>
        <ColMod lg={4}>
          <InputLabel style={{ fontSize: '1em', color: (!vendorId ? 'red' : '') }}>Vendor<sup>*</sup></InputLabel>
          <ReactSelect 
            style={{ border: (!vendorId ? '1px solid red' : '') }}
            value={vendorId || ''}
            options={vendorOptions}
            onInputChange={getVendorOptions}
            onChange={(vendor) => { selectVendor(vendor) }} />
        </ColMod>
      </Row>
      <Row>
        <ColMod lg={4}>
          <TextField
            label="Comments"
            name="comments"
            fullWidth
            multiline
            value={comments || ''}
            onChange={handleInputChange('comments')}
          />
        </ColMod>
        <ColMod lg={4}>
          <InputLabel style={{ fontSize: '1em' }} htmlFor="status-type">Status</InputLabel>
          <Select
            value={status || ''}
            inputProps={{
              name: 'status',
              id: 'status-type',
            }}
            fullWidth
            disabled
          >
            {Object.keys(catalogStatuses).map((x, i) => (
              <MenuItem key={i} value={x}>{catalogStatuses[x]}</MenuItem>))}
          </Select>
        </ColMod>
      </Row>
      <Row>
        <ColMod lg={2}>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </ColMod>
      </Row>
    </Grid>
  )
}

EditForm.propTypes = {
  vendorCatalog: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  catalogStatuses: PropTypes.object.isRequired,
}

EditForm.defaultProps = {
  vendorCatalog: {},
  handleInputChange: () => { },
  catalogStatuses: {}
}
