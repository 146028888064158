import React, { Component } from 'react';
import { Grid, Select, TextField, FormHelperText } from '@material-ui/core';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import MonitorCard from '../components/MonitorCard';
import API, { getWareHouse, monitoringPath, getStatusCountByDate, productsMonitoring } from '../../../api/NetworkHandler';
import NaMonitorCard from '../components/NaMonitorCard';

class MatrixDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouses: [],
      warehouse: '',
      date: moment().add(1, 'days').format('YYYY-MM-DD'),
      matrixs: {},
      orderCounter: {},
      productsMatrix: [],
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.fetchMonitoringData();
      this.fetchCustomerOrderCounts();
      this.fetchNaMonitoringData();
    }, 20000);
    this.fetchWarehouseList();
    this.fetchMonitoringData();
    this.fetchCustomerOrderCounts();
    this.fetchNaMonitoringData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchWarehouseList = async () => {
    const response = await API.sendRequest('get', getWareHouse);
    const { data: { _embedded: { warehouse = [] } = {} } = {} } = response;
    this.setState({
      warehouses: warehouse,
    });
  }

  fetchMonitoringData = async () => {
    const { warehouse, date } = this.state;
    const response = await API.sendRequest('get', monitoringPath(date, warehouse));
    const { data: { data = {} } = {} } = response;
    this.setState({ matrixs: data });
  }

  fetchNaMonitoringData = async () => {
    const { warehouse, date } = this.state;
    const response = await API.sendRequest('get', productsMonitoring(date, warehouse));
    const { data: { data = {} } = {} } = response;
    this.setState({ productsMatrix: data });
  }

  fetchCustomerOrderCounts = async () => {
    const { date, warehouse } = this.state;
    const response = await API.sendRequest('get', getStatusCountByDate({ fromDate: date, toDate: date, warehouseId: warehouse }));
    const { data = [] } = response;
    const orderCounter = {};
    let total = 0;
    if (data.length > 0) {
      data.forEach((item) => {
        orderCounter[item.status] = item.count;
        total += item.count;
      });
    }
    orderCounter.ALL = total;
    this.setState({ orderCounter });
  }

  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    }, () => {
      this.fetchMonitoringData();
      this.fetchCustomerOrderCounts();
      this.fetchNaMonitoringData();
    });
  }

  generateQueryString = (filters = {}) => {
    const { warehouse } = this.state;
    let queryString = '?';
    Object.keys(filters).forEach((filter) => {
      queryString += `${filter}=${filters[filter]}&`;
    });
    return `${queryString}warehouseId=${warehouse}&editable=false`;
  }

  render() {
    const {
      date, warehouse, warehouses = [],
      matrixs = {}, orderCounter = {},
      productsMatrix,
    } = this.state;

    const {
      GRN: {
        STOCKUPDATED = 0,
        ALL: allGrns = 0,
        NEW: newGrns = 0,
        RECEIVED: receivedGrns = 0,
      } = {},
      PURCHASE_REQUISITIONS: {
        ALL: allPRs = 0,
        New: newPRs = 0,
      } = {},
      HUB_SEGREGATION: {
        ALL: allHubSeg = 0,
        STARTED: startedHubSeg = 0,
        COMPLETED: completedHubSeg = 0,
      } = {},
      ROUTE_SEGREGATION: {
        ALL: allRouteSeg = 0,
        STARTED: startedRouteReg = 0,
        COMPLETED: completedRouteSeg = 0,
      } = {},
      PURCHASE_ORDER: {
        ALL: allPOs = 0,
        NEW: newPOs = 0,
        RECEIVING: receivingPOs = 0,
        Closed: closedPOs = 0,
        ORDERED: orderedPOs = 0,
      } = {},
    } = matrixs;
    const {
      ALL: allOrders = 0,
      ON_ROUTE = 0,
      DELIVERED = 0,
      NOT_DELIVERED = 0,
      PARTIALLY_DELIVERED = 0,
      CUSTOMER_COMPLAINT = 0,
    } = orderCounter;

    const poDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const monitorData = {
      Purhase_Orders: [
        {
          label: 'Total',
          url: `/purchase-requisition${this.generateQueryString({ startDate: date, endDate: date })}`,
          value: allPRs,
        },
        {
          label: 'Ordered',
          url: `/purchase-order${this.generateQueryString({
            startDate: poDate, endDate: poDate, status: 'ORDERED',
          })}`,
          value: orderedPOs,
        },
        {
          label: 'New',
          url: `/purchase-order${this.generateQueryString({
            startDate: poDate, endDate: poDate, status: 'NEW',
          })}`,
          value: newPOs,
        },
        {
          label: 'Receiving',
          url: `/purchase-order${this.generateQueryString({
            startDate: poDate, endDate: poDate, status: 'RECEIVING',
          })}`,
          value: receivingPOs,
        },
      ],
      Hub_Segregation: [
        {
          label: 'Total',
          url: `/hub-segregation${this.generateQueryString({ startDate: date, endDate: date })}`,
          value: allHubSeg,
        },
        {
          label: 'Completed',
          url: `/hub-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'HUB_COMPLETED' })}`,
          value: completedHubSeg,
        },
        {
          label: 'Started',
          url: `/hub-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'HUB_STARTED' })}`,
          value: startedHubSeg,
        },
        {
          label: 'Pending',
          url: `/hub-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'HUB_NEW' })}`,
          value: allHubSeg - (completedHubSeg + startedHubSeg),
        },
      ],
      Goods_Reciepts_Notes: [
        {
          label: 'Total',
          url: `/grn${this.generateQueryString({ startDate: date, endDate: date })}`,
          value: (newPOs + orderedPOs + receivingPOs),
        },
        {
          label: 'StockUpdated',
          url: `/grn${this.generateQueryString({ startDate: date, endDate: date, status: 'STOCKUPDATED' })}`,
          value: STOCKUPDATED,
        },
        {
          label: 'Received',
          url: `/grn${this.generateQueryString({ startDate: date, endDate: date, status: 'RECEIVED' })}`,
          value: receivedGrns,
        },
        {
          label: 'New',
          url: `/grn${this.generateQueryString({ startDate: date, endDate: date, status: 'NEW' })}`,
          value: newGrns,
        },
      ],
      Route_Segregation: [
        {
          label: 'Total',
          url: `/route-segregation${this.generateQueryString({ startDate: date, endDate: date })}`,
          value: allRouteSeg,
        },
        {
          label: 'Completed',
          url: `/route-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'ROUTE_COMPLETED' })}`,
          value: completedRouteSeg,
        },
        {
          label: 'Started',
          url: `/route-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'ROUTE_STARTED' })}`,
          value: startedRouteReg,
        },
        {
          label: 'Pending',
          url: `/route-segregation${this.generateQueryString({ startDate: date, endDate: date, status: 'ROUTE_NEW' })}`,
          value: allRouteSeg - (completedRouteSeg + startedRouteReg),
        },
      ],
      Customer_Orders: [
        {
          label: 'Total',
          url: `/delivery-route-orders${this.generateQueryString({ fromDate: date, toDate: date })}`,
          value: allOrders,
        },
        {
          label: 'Delivered',
          url: `/delivery-route-orders${this.generateQueryString({
            fromDate: date, toDate: date, status: 'DELIVERED',
          })}`,
          value: DELIVERED,
        },
        {
          label: 'Not Delivered',
          url: `/delivery-route-orders${this.generateQueryString({
            fromDate: date, toDate: date, status: 'NOT_DELIVERED',
          })}`,
          value: NOT_DELIVERED,
        },
        {
          label: 'On Route',
          url: `/delivery-route-orders${this.generateQueryString({
            fromDate: date, toDate: date, status: 'ON_ROUTE',
          })}`,
          value: ON_ROUTE,
        },
        {
          label: 'Partially Delivered',
          url: `/delivery-route-orders${this.generateQueryString({
            fromDate: date, toDate: date, status: 'PARTIALLY_DELIVERED',
          })}`,
          value: PARTIALLY_DELIVERED,
        },
        {
          label: 'Customer Complaint',
          url: `/delivery-route-orders${this.generateQueryString({
            fromDate: date, toDate: date, status: 'CUSTOMER_COMPLAINT',
          })}`,
          value: CUSTOMER_COMPLAINT,
        },
      ],
    };

    const products = productsMatrix.slice(0, 5);
    return (
      <div style={{ background: '#F2F2F2' }}>
        <NavBar />
        <div style={{ padding: '2em' }}>
          <Grid container spacing={16}>
            <Grid item md={10}>
              <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                <Grid item md={4} style={{ fontWeight: 600, fontSize: 20 }}>Dashboard</Grid>
                <Grid item md={4} style={{ textAlign: 'center' }}>
                  <FormHelperText style={{ marginTop: 4 }}>Warehouse</FormHelperText>
                  <Select
                    native
                    value={warehouse}
                    fullWidth
                    onChange={e => this.handleChange(e.target.value, 'warehouse')}
                  >
                    <option value="">Select Warehouse</option>
                    {warehouses.map(wh => (
                      <option value={wh.id}>{wh.name}</option>
                  ))}
                  </Select>
                </Grid>
                <Grid item md={4} style={{ textAlign: 'right' }}>
                  <TextField
                    id="date"
                    type="date"
                    label="Delivery Date"
                    value={date}
                    onChange={e => this.handleChange(e.target.value, 'date')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                {
                  Object.keys(monitorData).map(matrix => (
                    <Grid item md={monitorData[matrix].length < 5 ? 6 : 12}>
                      <MonitorCard name={matrix} data={monitorData[matrix]} />
                    </Grid>
                  ))
                }
                <Grid item md={12}>
                  <NaMonitorCard
                    data={products}
                    showDetailsButton
                    date={date}
                    warehouseId={warehouse}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default MatrixDashboard;
