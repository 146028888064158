export const styles = {
  wrapper: {
    width: '75%',
    margin: '49px auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  topContainer: {
    display: 'flex',
    width: '100%',
  },
  textField: {
    marginLeft: 20,
    marginRight: 20,
  },
  datePicker: {
    marginTop: 20,
  },
  bottomContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  downloadButton: {
    marginLeft: 20,
  },
  selectBox: {
    marginLeft: 10,
    marginRight: 10,
  },
  textarea: {
    marginTop: 10,
  },
  middleLeftContainer: {
    display: 'flex',
    marginLeft: 0,
    flexDirection: 'row',
    width: '100%',
  },
  middleRightContainer: {
    textAlign: 'end',
    marginTop: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  checkbox: {
    display: 'flex',
  },

  message: {
    resize: 'none',
    height: '80px',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '5px',
    borderColor: '#bbbbbb',
    fontSize: '100%',
  },
};
