import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import QueueIcon from '@material-ui/icons/Queue';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { Typography, Grid, TextField, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchWithSuggestions from './SearchWithSuggestions';
import API, {findVendorCatalogByProductId} from '../../../api/NetworkHandler';
import Modal from '@material-ui/core/Modal';
import { ColMod } from '../../../components/lib';
import { Row } from 'react-flexbox-grid';
import Drawer from '@material-ui/core/Drawer';
import CreateProductForm from './CreateProductForm';

class ProductItemMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappings: [],
      selectedMapping: {},
      vendorCatalogName: '',
      vendorCatalogId: null,
      openAddProductItem: false,
      showCreateProduct: false,
    };
  }

  componentDidMount() {
    this.findVendorCatalogByProductId(this.props.productId);
  }


  static getDerivedStateFromProps(props, state) {
    const { data = [] } = props;
    return {
      mappings: data,
    };
  }

  findVendorCatalogByProductId = async (productId) => {
    if (productId) {
      try {
        const response = await API.sendRequest('get', findVendorCatalogByProductId(productId));
        const { _embedded: embedded } = response.data;
        this.setState({ 
          vendorCatalogName: embedded['vendor-catalogs'][0]['name'],
          vendorCatalogId: embedded['vendor-catalogs'][0]['id']
         });
      } catch (error) {
        console.log(error);
      }
    }
  }

  openAddProductItem = () => {
    this.setState({ openAddProductItem: true });
  }

  handleClose = () => {
    this.setState({
      openAddProductItem: false,
      selectedMapping: {},
    });
  }

  handleAddIcon = () => {
    const { onChange } = this.props;
    let { mappings = [] } = this.state;
    mappings = [...mappings, { }];
    onChange(mappings);
  }

  handleDeleteIcon = (i) => {
    const { onChange } = this.props;
    const { mappings = [] } = this.state;
    this.setState({ selectedMapping: {} });
    mappings.splice(i, 1);
    onChange(mappings);
  }

  handleChange = (value, key) => {
    let { selectedMapping } = this.state;
    if (key === 'wastage') {
      if (value < 0) {
        selectedMapping = {
          ...selectedMapping,
          wastage: 0,
        };
        this.setState({ selectedMapping });
        return;
      }
      else if (value > 100) {
        return;
      }
      selectedMapping = {
        ...selectedMapping,
        yield: 100 - value,
        wastage: value,
      };
      this.setState({ selectedMapping });
    }
    if (key === 'yield') {
      selectedMapping = {
        ...selectedMapping,
        wastage: 100 - value,
      };
      this.setState({ selectedMapping });
    }
    if (key === 'conversionFactor') {
      if (value < 0) {
        return;
      }
      selectedMapping = {
        ...selectedMapping,
        conversionFactor: value,
      };
      this.setState({ selectedMapping });
    }
  }

  searchProductItem = (query) => {
    const { onProductItemSearch } = this.props;
    const { vendorCatalogId } = this.state;
    onProductItemSearch(vendorCatalogId, query);
  }

  openCreateProductForm = () => {
    this.setState({
      showCreateProduct: true,
    });
  }

  handleFormClose = () => {
    this.setState({
      showCreateProduct: false,
    })
  }

  onCreateItem = (i) => {
    const { selectedMapping } = this.state;
    const { onChange } = this.props;
    this.setState({
      openAddProductItem: false,
    });
    let { mappings = [] } = this.state;
    mappings.splice(i, 1, selectedMapping);
    onChange(mappings);
  }

  handleClick = (i, mappingObj) => {
    this.searchProductItem(this.props.productName);
    const { productItem = {} } = mappingObj; 
    const { id = null } = productItem; 
    this.setState({ 
      openAddProductItem: true,
      isEdit: true,
      itemIndex: i,
      productItemId: id,
      selectedMapping: mappingObj,
    });
  }

  getName = (item) => {
    let productItemName = '';
    if (Object.keys(item).length > 0) {
      if (Object.keys(item.productItem).length > 0) {
	      productItemName = item.productItem.name;
	    }
    }
    return typeof productItemName === 'undefined' ? '' : productItemName;
  }

  hideItemAttributes = () => {
    const { selectedMapping } = this.state;
    if (Object.keys(selectedMapping).length > 0) {
      if (Object.keys(selectedMapping.productItem).length > 0) {
        return false;
	    }
    }
    return true;
  };

  handleChangeFilter = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.findProductItem(value));
  };

  findProductItem = (id) => {
    const { productItems = [] } = this.props;
    let { selectedMapping } = this.state;
    let newProductItem = {};
    productItems.map(item => {
      if (item.id === id) {
        Object.assign(newProductItem, item)
      }
    });
    selectedMapping = {
      ...selectedMapping,
      productItem: newProductItem,
    };
    this.setState({ selectedMapping });
  }

  render() {
    const {
      catalogs = [],
      productItems = [],
      onCatalogSearch,
      title,
      productName,
      UOM,
      cutOffTime,
      productId,
      productItemData,
      data,
    } = this.props;
    let { mappings = [], vendorCatalogName, showCreateProduct, openAddProductItem, itemIndex, selectedMapping = {}, duplicateError } = this.state;
    if (mappings.length === 0) { mappings = [{ catalog: {}, productItem: {} }]; };
    let { id = null } = selectedMapping;
    return (
      <div>
        <div>
          <span style={{ float: 'left' }}>
          <TextField
            label="Vendor Catalog Name"
            value={vendorCatalogName}
            disabled
            style={{ width: '550px' }}
          />
          </span>
          <span style={{ marginLeft: '30px' }}><Button variant="outlined" size='small' color="secondary" onClick={this.handleAddIcon}> <QueueIcon /> Add Mapping </Button></span>
          <span style={{ float: 'right' }}><Button variant="outlined" size='small' color="primary" disabled={productItemData['cutOffTime', 'packaging', 'unitPrice', 'costPrice'] === undefined} onClick={this.openCreateProductForm}> Create Vendor Product Item </Button></span>
        </div>
        {mappings.map((item, i) => (
          <div style={{ paddingTop: '0.7em', marginTop: '15px' }} key={i}>
            <Grid container spacing={16}>
              <Grid item xs md={5}>
                <TextField
                  label="Vendor Product Item"
                  value={this.getName(item)}
                  inputProps={{readOnly: true}}
                  style={{ width: '550px' }}
                  onClick={() => this.handleClick(i, item)}
                  InputLabelProps={{ shrink: true }}
                  autoFocus={true}
                  />
                <small style={{ color: 'red' }}>{i == itemIndex ? duplicateError : null}</small>
              </Grid>
              <div style={{ marginTop: '30px', marginLeft: '150px' }}>
                <DeleteIcon
                  color="secondary"
                  style={{ fontSize: '1.8em' }}
                  onClick={() => this.handleDeleteIcon(i)}
                />
              </div>
            </Grid>
          </div>
        ))}
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openAddProductItem}
            onClose={this.handleClose}
          >
            <div style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'white',
                  width: '50%',
                  margin: '0 auto',
                  height: '50%',
                  overflowX: 'auto',
                  overflowY: 'auto',
                }}
            >
              <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <div>
                      <Typography variant="body2" color="secondary" style={{ textAlign: 'center' }}>
                        {`${Object.keys(selectedMapping).length != 0 ? 'Edit' : 'Create'} Vendor Product Mapping`}
                      </Typography>
                    </div>
                    <Row>
                      <ColMod lg={12}>
                          <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination"> Vendor Product Item </InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '450px' }}
                            value={this.state.productItemId || ''}
                            onChange={this.handleChangeFilter}
                            disabled={id}
                            inputProps={{
                              name: 'productItemId',
                              id: 'select-destination',
                            }}
                            >
                            {productItems.map((item, index) => (<MenuItem key={index} value={item.id}> {item.name} </MenuItem>))}
                          </Select>
                        </FormControl>
                      </ColMod>
                    </Row>
                    {!this.hideItemAttributes() &&
                    <Row>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <ColMod lg={4}>
                            <TextField
                              label="Conversion Factor"
                              type="number"
                              value={selectedMapping.conversionFactor}
                              defaultValue={1}
                              onChange={e => this.handleChange(e.target.value, 'conversionFactor')}
                              required
                              style={{ width: '130px' }}
                            />
                          </ColMod>
                          <ColMod lg={4}>
                            <TextField
                              label="Wastage(%)"
                              type="number"
                              value={selectedMapping.wastage}
                              defaultValue={0}
                              onChange={e => this.handleChange(e.target.value, 'wastage')}
                              required
                              style={{ width: '130px' }}
                            />
                          </ColMod>
                          <ColMod lg={4}>
                            <TextField
                              label="Yield(%)"
                              type="number"
                              value={selectedMapping.yield}
                              defaultValue={100}
                              onChange={e => this.handleChange(e.target.value, 'yield')}
                              required
                              style={{ width: '130px' }}
                              inputProps={{readOnly: true}}
                            />
                          </ColMod>
                      </div>
                    </Row>
                    }
                  </ColMod>
                </Row>
              </Grid>
              <div style={{ float: 'right', paddingRight: '2em' }}>
                {/* <Button style={{ marginRight: '2em' }} variant="outlined" color="secondary" onClick={this.handleClose}>
                  Cancel
                </Button> */}
                <Button
                  onClick={() => {
                    this.onCreateItem(itemIndex);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {Object.keys(selectedMapping).length != 0 ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </Modal>
          <Drawer anchor="right" open={showCreateProduct} onClose={this.handleFormClose}>
            <CreateProductForm 
              onClose={this.handleFormClose}
              UOM={UOM}
              productName={productName}
              productId={productId}
              productItemData={productItemData}
            />
          </Drawer>
      </div>
    );
  }
}

ProductItemMapping.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onProductItemSearch: PropTypes.func,
  onCatalogSearch: PropTypes.func,
  catalogs: PropTypes.instanceOf(Array),
  productItems: PropTypes.instanceOf(Array),
};

export default ProductItemMapping;
