

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Select, InputLabel, MenuItem, FormHelperText, TextField } from '@material-ui/core/';
import MaterialSelect from '@material-ui/core/Select';
import Colors from '../../../utils/Colors';
import apiCall, { attendanceDownloadCSV, routesByHubIdPath, getWareHouse, fetchAllDepartmentPath, fetchDepartmentById } from '../../../api/NetworkHandler';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import HubSelector from '../../../components/Hub/Selector';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  searchContainer: {
    flex: 7,
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchField: {
    width: 200,
    marginRight: 40,
  },
  buttonField: {
    flex: 1,
    lineHeight: 'normal',
    marginBottom: 20,
  },
  or: {
    flex: 1,
  },
  clear: {
    flex: 1,
    color: Colors.primary,
    marginLeft: '30px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginTop: 4,
  },
};


class AttendanceSearch extends Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      departments: [],
      hubList: [],
      routeList: [],
      warehouses: [],
    };
  }


  componentWillMount() {
    document.addEventListener('keypress', this.handleKeyPress, false);
  }

  componentDidMount() {
    this.fetchWarehouses();
    this.fetchAllDepartments();
  }

  componentWillUnmount() {
    document.addEventListener('keypress', this.handleKeyPress, false);
  }


  fetchAllDepartments = () => {
    apiCall.sendRequest('get', fetchAllDepartmentPath)
      .then((response) => {
        const { data: { _embedded: embedded } } = response;
        this.setState({
          departments: embedded.departments,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchWarehouses = async () => {
    try {
      const response = await apiCall.sendRequest('get', getWareHouse);
      const { data: { _embedded = {} } } = response;
      this.setState({
        warehouses: _embedded.warehouse,
      });
    } catch (e) {
      this.setState({
        warehouses: [],
      });
    }
  }

  handleRoutesSearch = async () => {
    try {
      const { filters: { hubId = '' } } = this.state;
      if (hubId && hubId !== '') {
        const response = await apiCall.sendRequest('get', routesByHubIdPath(hubId));
        const { data: { _embedded = {} } } = response;
        this.setState({
          routeList: _embedded['static-routes'],
        });
      }
    } catch (e) {
      this.setState({
        routeList: [],
      });
    }
  }


  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }


  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {
      month: moment().format('MMMM'),
      year: moment().format('YYYY'),
      departmentId: '',
      hubId: '',
      routeId: '',
      warehouseId: '',
      userType: '',
    };
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
    }, () => {
      onButtonClick('search', 0);
    });
  }

  handleChange = (value, key) => {
    let { filters, selectedHub } = this.state;
    const { onFilterChange } = this.props;
    filters = {
      ...filters,
      [key]: value,
    };
    onFilterChange(key, value);
    if(key == 'warehouseId'){
      selectedHub = null;
      filters.warehouseId = null;
    }
    this.setState({
      filters,
      selectedHub
    },()=>{
      if(key == 'warehouseId'){
        this.setState({
          filters: {
            ...filters,
            [key]: value,
          }
        })
      }
    });
    this.handleDepartment(filters);
  }

  handleDepartment = (filters) => {
    const { departmentId } = filters;
    if (departmentId) {
      apiCall.sendRequest('get', fetchDepartmentById(departmentId))
        .then((response) => {
          const { data = {} } = response;
          const { id } = data;
          this.setState({
            departmentId: id,
          });
        });
    }
  }

  handleDownload = () => {
    const { filters } = this.state;
    const {
      month, year, ...requiredFilters
    } = filters;
    const filterByDate = `${month} ${year}`;
    const filterData = {
      ...requiredFilters,
      startDate: moment(filterByDate).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(filterByDate).endOf('month').format('YYYY-MM-DD'),
    };
    const url = attendanceDownloadCSV(filterData);
    apiCall.downloadFile(url, `${month}_${year}.csv`);
  }

  onHubChange = (hub) => {
    this.handleChange(hub ? hub.id : null, 'hubId');
    this.setState({ selectedHub: hub });
  }

  render() {
    const {
      onButtonClick, months, years, userTypes = {},
    } = this.props;
    const {
      filters, departments, warehouses, routeList, selectedHub
    } = this.state;

    const {
      month,
      year,
      departmentId = '',
      userType = '',
      routeId = '',
      warehouseId = '',
    } = filters;

    let routeName = '';
    if (routeId && routeId !== '' && routeName === '') {
      const routeData = routeList.find(rt => rt.id === (+routeId));
      routeName = routeData ? routeData.name : '';
    }

    return (
        <div style={styles.contentContainer}>
            <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="Months"
                value={month}
                onChange={e => this.handleChange(e.target.value, 'month')}
              >
                <option value="">Select Month</option>
                {Object.values(months).map(month => (
                  <option value={month}>{month}</option>
                  ))}
              </Select>
            </div>
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="Years"
                value={year}
                onChange={e => this.handleChange(e.target.value, 'year')}
              >
                <option value="">Select Year</option>
                {Object.keys(years).map(year => (
                  <option value={year}>{year}</option>
                  ))}
              </Select>
            </div>
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="User_Type"
                value={userType}
                onChange={e => this.handleChange(e.target.value, 'userType')}
              >
                <option value="">Select UserType</option>
                {Object.keys(userTypes).map(userType => (
                  <option value={userType}>{userType}</option>
                  ))}
              </Select>
            </div>
            <div style={styles.searchField}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Department</InputLabel>
                      <MaterialSelect
                        fullWidth
                        value={departmentId}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => this.handleChange(e.target.value, 'departmentId')}
                        disabled={userType === 'DELIVERY_BOY'}
                      >
                        {departments.map(department => (
                          <MenuItem
                            key={department}
                            value={department.id}
                          >
                            {department.name}
                          </MenuItem>
                        ))
                        }
                      </MaterialSelect>
            </div>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onButtonClick('search', 0)}
              >
                      Search
              </Button>
            </div>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleClear}
              >
                      Clear All
              </Button>
            </div>

            <div style={styles.buttonField}>
            <Button
                color="secondary"
                variant="contained"
                onClick={this.handleDownload}
              >Download
              </Button>
            </div>
            {userType === 'DELIVERY_BOY' &&
            <div style={styles.searchField}>
            <FormHelperText style={styles.label}>WareHouse</FormHelperText>
            <Select
              name="warehouseId"
              fullWidth
              label = ""
              onChange={e => this.handleChange(e.target.value, 'warehouseId')}
              value={(+warehouseId) || ''}
            >
              {warehouses.map(warehouse => (
                <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                      ))}

            </Select>
          </div>
            }
        {userType === 'DELIVERY_BOY' &&
          <div style={styles.searchField}>
              <div style={{ width: '100%' }}>
              <FormHelperText style={styles.label}>Hub</FormHelperText>
                {warehouseId && <HubSelector 
                  warehouseId={filters.warehouseId}
                  onSelect={this.onHubChange}
                  selected={selectedHub}
                />}
                {!warehouseId && 
                <TextField
                  value="Select Warehouse First"
                  disabled
                />}
              </div>
            </div>
        }
          {userType === 'DELIVERY_BOY' &&
            <div style={styles.searchField}>
              <div style={{ marginTop: 16, width: '100%' }}>
                <SearchWithSuggestions
                  key={routeName}
                  searchQuery={routeName}
                  placeholder="Search by Route"
                  suggestions={routeList}
                  onChange={this.handleRoutesSearch}
                  onSelect={selected => this.handleChange(selected.id, 'routeId')}
                />
              </div>
            </div>
          }
          </div>
        </div>
    );
  }
}

AttendanceSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  months: PropTypes.instanceOf(Object),
  years: PropTypes.instanceOf(Object),
  userTypes: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.instanceOf(Object),
  onButtonClick: PropTypes.func.isRequired,
};

export default AttendanceSearch;
