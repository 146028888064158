import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';
import apiCall, { resetPassword } from '../api/NetworkHandler';
import TableHeader from '../modules/Commons/components/TableHeader';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';

const columnData = [
  {
    id: 'username', numeric: false, disablePadding: true, label: 'Username (Email ID)',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
    };
  }

  componentDidMount() {
    const { users, links } = this.props;
    this.setState({
      data: users,
    });
    if (links.next || links.prev) {
      this.setState({
        page: users.length ? Math.floor((users.length - 1) / 20) : 0,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.users !== this.state.data) {
        this.setState({
          data: nextProps.users,
        });
        if (nextProps.links.next || nextProps.links.prev) {
          this.setState({
            page: nextProps.users.length ? Math.floor((nextProps.users.length - 1) / 20) : 0,
          });
        }
      }
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleChangePage = (event, page) => {
    const { searchedResult } = this.props;
    if (searchedResult) {
      this.setState({ page });
    } else {
      let url = '';
      if (this.state.page < page) {
        url = this.props.links.next.href;
      } else {
        url = this.props.links.prev.href;
      }
      this.props.fetchPaginatedUser(url);
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected === id

  resetPassword = async (userId) => {
    try {
      await apiCall.sendRequest('post', resetPassword(userId));
    } catch (error) {

    }
  }

  render() {
    const {
      classes, handleClick, handleDepartment, openAddUser,
    } = this.props;
    const {
      data, rowsPerPage, page,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
            <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
              <TableHeader columns={columnData} />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={() => { handleClick(n); handleDepartment(n); this.setState({ selected: n.id }); }}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      {n.email}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.mobile}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.active ? 'Active' : 'Inactive' }</TableCell>
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      <Button color="secondary" size="small" onClick={() => { openAddUser(n); }}>
                        Edit
                      </Button>
                      &emsp;|&emsp;
                      <Button color="secondary" size="small" onClick={(e) => { e.stopPropagation(); this.resetPassword(n.id); }}>
                        Reset
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        {(this.props.searchedResult) ?
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          : <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              disabled: !this.props.links.prev,
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              disabled: !this.props.links.next,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        }
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  handleClick: PropTypes.instanceOf(Object),
  handleDepartment: PropTypes.instanceOf(Object),
  openAddUser: PropTypes.instanceOf(Object),
  searchedResult: PropTypes.instanceOf(Object),
  fetchPaginatedUser: PropTypes.instanceOf(Object),
  links: PropTypes.instanceOf(Object),
  users: PropTypes.func.isRequired,
};

export default withStyles(styles)(EnhancedTable);
