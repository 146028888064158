import React, { Component } from 'react';
import { Grid, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import Colors from '../../../utils/Colors';
import API, { appLinksPath } from '../../../api/NetworkHandler';

const styles = {
  link: {
    textDecoration: 'none',
    color: Colors.white,
    padding: 10,
    background: Colors.primary,
    borderRadius: 5,
    marginRight: 10,
  },
  logo: {
    padding: 20,
    background: Colors.lightGrey,
  },
};

class AppLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
  }

  componentDidMount() {
    // call api to get latest link
    this.getAppLinks();
  }

  getAppLinks = async () => {
    const response = await API.sendRequest('get', appLinksPath);
    const { data: { data = [] } } = response;
    this.setState({
      apps: data,
    });
  }

  copyToClipboard = (index) => {
    const { links } = this.state;
    const elm = document.createElement('textarea');
    elm.value = links[index];
    elm.setAttribute('readonly', '');
    elm.style.position = 'absolute';
    elm.style.left = '-9999px';
    document.body.appendChild(elm);
    elm.select();
    document.execCommand('copy');
    document.body.removeChild(elm);
  };

  handleDownload = async (url, name) => {
    await API.downloadFile(url, name);
  }

  render() {
    const { apps = [] } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar title="SCM Apps" />
        <Grid container>
          <Grid md={8} xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="dense">Logo</TableCell>
                  <TableCell padding="dense">Name</TableCell>
                  <TableCell padding="dense">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apps && apps.length > 0 && apps.map(app => (
                  <TableRow>
                    <TableCell padding="dense"><img src={app.imageUrl} alt="logo" style={styles.logo} /></TableCell>
                    <TableCell padding="dense">{app.appName.replace('_', ' ')}</TableCell>
                    <TableCell padding="dense">
                      <a href={app.apkUrl} rel="noopener noreferrer" style={styles.link}>Download</a>
                    </TableCell>
                  </TableRow>
              ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AppLink;
