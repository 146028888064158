import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Delete from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import UploadSingleImageFile from "./UploadSingleImageFile";

const styles = {
  container: {
    width: "48%",
    height: "40vw",
    maxHeight: "200px",
    marginTop: "10px",
    backgroundColor: "white"
  },
  singleImageContainer: {
    width: "95%",
    height: "40vw",
    maxHeight: "200px",
    marginTop: "10px",
    backgroundColor: "white"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  height100: {
    height: "100%"
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column"
  },
  imageDiv: {
    height: "calc(100% - 40px)"
  },
  image: {
    height: "70%",
    width: "auto"
  },
  textDiv: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    justifyContent: "space-between"
  }
};

const ProductImageItem = props => {
  const {
    classes,
    productInfo,
    uploadFileHandler,
    deleteFileHandler,
    index,
    loading,
  } = props;
  const { singleImage } = props.props;

  return (
    <div className={singleImage ? classes.singleImageContainer : classes.container}>
      {/* upload one image file withh progress bar */}
      {!productInfo.imageUrl && (
        <UploadSingleImageFile
          label={productInfo.position.split("_")[0]}
          // progressBarStatus={productInfo.uploadProgressStatus}
          error={productInfo.error}
          imageUrl={productInfo.imageUrl}
          uploadFileHandler={uploadFileHandler(index)}
          loading={loading}
        />
      )}

      {/* when file is uploaded or image file already uploaded */}
      {productInfo.imageUrl && (
        <Card className={classes.height100}>
          <div className={`${classes.height100} ${classes.imageContainer}`}>
            <div className={`${classes.center} ${classes.imageDiv}`}>
              <img className={classes.image} src={productInfo.imageUrl} />
            </div>
            <div className={classes.textDiv}>
              <div>
                {productInfo.position && productInfo.position.split("_")[0]}
              </div>
              <Delete
                style={{
                  width: "22px",
                  height: "22px",
                  color: "#d00a0ab0",
                  cursor: "pointer"
                }}
                onClick={() => deleteFileHandler(index)}
              />
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

ProductImageItem.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ProductImageItem);
