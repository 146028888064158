import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  TextField,
  Select,
  TablePagination,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import TableHeader from '../../Commons/components/TableHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import UndoIcon from '@material-ui/icons/Undo';
import { Modal } from 'antd';
import { ExclamationCircleOutlined, CopyFilled } from '@ant-design/icons';
import { getOfferStatus, getOfferItemStartDate } from './OfferUtility';

const { confirm } = Modal;


const columnData = [
  {
    id: 'product', numeric: false, disablePadding: true, label: 'Product Item',
  },
  {
    id: 'region', numeric: false, disablePadding: true, label: 'Region',
  },
  {
    id: 'startTime', numeric: false, disablePadding: true, label: 'Start Date',
  },
  {
    id: 'endTime', numeric: false, disablePadding: true, label: 'End Date',
  },
  {
    id: 'offerText', numeric: false, disablePadding: true, label: 'Offer Text',
  },
  {
    id: 'unitPrice', numeric: false, disablePadding: true, label: 'Unit Price (MRP)',
  },
  {
    id: 'offerPrice', numeric: false, disablePadding: true, label: 'Offer Price',
  },
  {
    id: 'paybackValue', numeric: false, disablePadding: true, label: 'Payback Value',
  },
  {
    id: 'discountPercent', numeric: false, disablePadding: true, label: 'Discount Percent',
  },
  {
    id: 'limitPerUser', numeric: false, disablePadding: true, label: 'Limit Per User',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class OfferCampaignItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selected: [],
      data: [],
      offerData: {},
      rowsPerPageOptions: [10, 25],
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps) {
      const { items = [], offerData = {} } = nextProps;
      return {
        data: items,
        offerData,
      };
    }
  }

  handleChange = (event, item) => {
    const { name, value } = event.target;
    const { onChange } = this.props;
    const { data, offerData } = this.state;
    const { startTime: offerStartTime, endTime: offerEndTime } = offerData;
    let modifiedItems = [...data];
    const { price, paybackType, paybackValue, startTime, endTime, offerPrice } = item;
    let today = moment().format("YYYY-MM-DDT05:00:00")
    if (name === 'paybackValue' && value < 0) return;
    if (name === 'price') {
        if (value < 0) return;
        item = {
            ...item,
            [name]: value,
            // offerPrice: value,
            paybackValue: (value - offerPrice).toFixed(2),
        }
    }
    else if (name === 'limitPerUser') {
      if (value < 0) return;
      else {
        item = {
          ...item,
          [name]: value,
          offerText: `Offer Limited to ${value} Qty`
        }
      }
    }
    else if (name === 'offerPrice') {
      if (value < 0) return;
      item = {
        ...item,
        [name]: value,
        paybackValue: (price - value).toFixed(2),
      }
    }
    else if (name === 'endTime' && (moment(value).isBefore(moment(startTime).format("YYYY-MM-DD"), 'days') || moment(value).isAfter(offerEndTime, 'days'))) {
      return;
    }
    else if (name === 'startTime') {
      if (moment(value).isBefore(moment().add(2, 'days').format("YYYY-MM-DD"), 'days') || moment(value).isBefore(offerStartTime, 'days')) {
        return;
      }
      if (moment(value).isSameOrAfter(endTime, 'days')) return;
      if (item.isAdded) {
        if (moment(value).isSameOrBefore(moment().add(2, 'days'), 'days')) return;
        item = {
          ...item,
          [name]: moment(value).format("YYYY-MM-DDT00:00:00"),
        }
        let prevItemIndex = modifiedItems.findIndex(el => el.productItemId == item.productItemId && !el.isAdded);
        let prevItem = modifiedItems.find(el => el.productItemId == item.productItemId && !el.isAdded);
        moment(prevItem.endTime).isAfter(moment().add(2, 'days'), 'days') && (modifiedItems[prevItemIndex] = {...prevItem, endTime: moment(value).subtract(1, 'days').format("YYYY-MM-DDT23:59:59"), isModified: false});
      } else {
        item = {
          ...item,
          [name]: moment(value).format("YYYY-MM-DDT00:00:00"),
        }
      }
    }
    else {
        item = {
          ...item,
          [name]: ['endTime'].includes(name) ? moment(value).format("YYYY-MM-DDT23:59:59") : value,
        }
    }
    let itemIndex = 0;
    if (item.isAdded) {
      itemIndex = modifiedItems.findIndex(el => el.productItemId == item.productItemId && el.isAdded);
    } else {
      itemIndex = modifiedItems.findIndex(el => el.productItemId == item.productItemId);
    }
    modifiedItems[itemIndex] = {...item, isModified: true};
    onChange(modifiedItems);
  }

  showConfirm = (item) => {
    confirm({
      title: 'Do you want to delete selected product item?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => { this.removeItem(item) },
      onCancel: () => {},
    });
  }

  removeItem = (item) => {
    const { onChange, initialOfferProducts } = this.props;
    const { data: modItems } = this.state;
    item = {
      ...item,
      isDeleted: true,
    }
    let itemIndex = 0;
    if (item.isAdded) {
      itemIndex = modItems.findIndex(el => el.productItemId == item.productItemId && el.isAdded);
      let initialPrevObj = initialOfferProducts.find(el => el.productItemId == item.productItemId && el.region === item.region && !el.isAdded);
      let prevObjIndex = modItems.findIndex(el => el.productItemId == item.productItemId && !el.isAdded);
      modItems[prevObjIndex] = initialPrevObj;
    } else {
      itemIndex = modItems.findIndex(el => el.productItemId == item.productItemId);
    }
    modItems[itemIndex] = item;
    // modItems.splice(index, 1);
    this.setState({
      data: modItems
    });
    onChange(modItems);
  }

  getStartTime = (startTime) => {
    const today = new Date();
    const calculatedStartTime = getOfferItemStartDate();
    if (moment(startTime).isSame(moment(today).add(1, 'days'), 'days')) {
      return moment(calculatedStartTime).add(3, 'days').format("YYYY-MM-DDT00:00:00");
    }
    else {
      return moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DDT00:00:00");
    }
  }

  handleAddItem = (item) => {
    const { onChange, onChangeInitialOfferProducts } = this.props;
    const { data, offerData } = this.state;
    let count = 0;
    data.forEach(el => (el.productItemId == item.productItemId && el.isAdded) ? count += 1 : count);
    if (count > 0) return;
    const today = new Date();
    const { endTime: offerEndTime } = offerData;
    const { startTime, endTime } = item;
    delete item["id"];
    item = {
      ...item,
      startTime: this.getStartTime(startTime),
      endTime: moment(offerEndTime).format("YYYY-MM-DDT23:59:59"),
      isAdded: true,
    }
    let itemIndex = data.findIndex(el => el.productItemId == item.productItemId && el.region === item.region);
    let itemIndexObj = data.find(el => el.productItemId == item.productItemId && el.region === item.region);
    if (moment(endTime).isAfter(today, 'days')) data[itemIndex] = { ...itemIndexObj, endTime: moment(item.startTime).subtract(1, 'days').format("YYYY-MM-DDT23:59:59")};
    data.splice(itemIndex + 1, 0, item);
    onChangeInitialOfferProducts(item);
    this.setState({
      data,
    });
    onChange(data);
  }

  handleUndo = (obj) => {
    const { data } = this.state;
    const { initialOfferProducts, onChange } = this.props;
    let initialObj = {};
    let objIndex = 0;
    if (obj.isAdded) {
      initialObj = initialOfferProducts.find(el => el.productItemId == obj.productItemId && el.region === obj.region && el.isAdded);
      objIndex = data.findIndex(el => el.productItemId == obj.productItemId && el.isAdded);
      let initialPrevObj = initialOfferProducts.find(el => el.productItemId == obj.productItemId && el.region === obj.region && !el.isAdded);
      let prevObjIndex = data.findIndex(el => el.productItemId == obj.productItemId && !el.isAdded);
      moment(initialPrevObj.endTime).isAfter(moment().add(2, 'days'), 'days') && (data[prevObjIndex] = { ...initialPrevObj, endTime: moment(initialObj.startTime).subtract(1, 'days').format("YYYY-MM-DDT23:59:59") });
    } else {
      initialObj = initialOfferProducts.find(el => el.productItemId == obj.productItemId && el.region === obj.region);
      objIndex = data.findIndex(el => el.productItemId == obj.productItemId);
    }
    // let objIndex = data.findIndex(el => el.productItemId == obj.productItemId);
    data[objIndex] = {...initialObj, isModified: false, isDeleted: false};
    this.setState({
      data,
    });
    onChange(data);
}

  isSelected = id => this.state.selected === id;

  getModifiedData = (data) => {
    const { offerData: { paybackValue = '' } } = this.state;
    let modifiedData = [];
    if (paybackValue === 'DISCOUNT') {
      data.map(el => {
        modifiedData.push({...el, paybackValue: Number.parseFloat(el.paybackValue).toFixed(2), isModified: el.isModified || false, isDeleted: el.isDeleted || false})
      });
    } else {
      data.map(el => {
        modifiedData.push({...el, isModified: el.isModified || false, isDeleted: el.isDeleted || false})
      });
    }
    return modifiedData.filter(el => el.active != false);
  }

  checkAddItemCondition = (itemEndTime) => {
    const { offerData } = this.state;
    const { startTime, endTime } = offerData;
    let today = moment();
    if (getOfferStatus(startTime, endTime).status === 'completed') {
      return true;
    }
    else if (moment(today).isAfter(moment(endTime).subtract(3, 'days'), 'days')) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {
    const { classes, page, rowsPerPage, onChangePage, onChangeRowsPerPage, totalElements } = this.props;
    const { data = [], rowsPerPageOptions, offerData } = this.state;
    const modifiedData = this.getModifiedData(data);
    const calculatedStartTime = getOfferItemStartDate();
    const { paybackType } = offerData;
    const conditionalColumnData = (!paybackType || paybackType === 'DISCOUNT') ? columnData.filter(el => el.id !== 'discountPercent') : columnData.filter(el => !['unitPrice', 'offerPrice', 'paybackValue'].includes(el.id));

    return (
        <div className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <TableHeader columns={conditionalColumnData} />
                    <TableBody>
                    {modifiedData.map((n, index) => {
                        const isSelected = n.productItemId ? this.isSelected(n.productItemId) : false;
                        const isDisable = moment(n.startTime).isSameOrBefore(moment(calculatedStartTime).add(1, 'days'), 'days');
                        return (
                          <React.Fragment key={index}>
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                selected={isSelected}
                                style={{
                                  backgroundColor: isDisable ? 'none' : '#DCDCDC',
                                  textDecoration: n.isDeleted ? 'line-through' : 'none',
                                  border: n.isDeleted ? '2px solid red' : 'none',
                                }}
                              >
                                <TableCell component="th" scope="row" padding="none">{n.title}</TableCell>
                                <TableCell component="th" scope="row" padding="none">{n.region}</TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <TextField
                                    type="date"
                                    name="startTime"
                                    disabled={isDisable}
                                    value={moment(n.startTime).format('YYYY-MM-DD')}
                                    onChange={e => this.handleChange(e, n)}
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <TextField
                                    type="date"
                                    name="endTime"
                                    disabled={isDisable}
                                    value={moment(n.endTime).format('YYYY-MM-DD')}
                                    onChange={e => this.handleChange(e, n)}
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <TextField
                                    type="text"
                                    name="offerText"
                                    disabled={isDisable}
                                    value={n.offerText}
                                    onChange={e => this.handleChange(e, n)}
                                  />
                                </TableCell>
                                {(!paybackType || paybackType === 'DISCOUNT') &&
                                  <TableCell component="th" scope="row" padding="default">
                                    <TextField
                                      type="number"
                                      name="price"
                                      style={{ width: '60px' }}
                                      disabled={isDisable}
                                      value={n.price}
                                      onChange={e => this.handleChange(e, n)}
                                      autoComplete="off"
                                      error={!!(!n.price || n.price <= 0)}
                                      helperText={(!n.price || n.price <= 0) && "*required"}
                                    />
                                  </TableCell>
                                }
                                {(!paybackType || paybackType === 'DISCOUNT') &&
                                  <TableCell component="th" scope="row" padding="none">
                                    <TextField
                                      type="number"
                                      name="offerPrice"
                                      style={{ width: '70px' }}
                                      disabled={isDisable}
                                      value={n.offerPrice}
                                      onChange={e => this.handleChange(e, n)}
                                      autoComplete="off"
                                      error={!!(!n.offerPrice || parseFloat(n.offerPrice) <= 0 || parseFloat(n.offerPrice) > parseFloat(n.price))}
                                      helperText={(!n.offerPrice || parseFloat(n.offerPrice) <= 0) && "*required" || parseFloat(n.offerPrice) > parseFloat(n.price) && 'Invalid price'}
                                    />
                                  </TableCell>
                                }
                                {(!paybackType || paybackType === 'DISCOUNT') && <TableCell component="th" scope="row" padding="default">{n.paybackValue}</TableCell>}
                                {paybackType === 'PRICE_DISCOUNT' &&
                                  <TableCell component="th" scope="row" padding="default">
                                    <TextField
                                      type="number"
                                      name="paybackValue"
                                      style={{ width: '70px' }}
                                      disabled={isDisable}
                                      value={n.paybackValue}
                                      onChange={e => this.handleChange(e, n)}
                                      autoComplete="off"
                                      error={!!(!n.paybackValue || parseFloat(n.paybackValue) <= 0 || parseFloat(n.paybackValue) > 100)}
                                      helperText={(!n.paybackValue || parseFloat(n.paybackValue) <= 0) && "*required" || parseFloat(n.paybackValue) > 100 && 'Invalid discount'}
                                    />
                                  </TableCell>
                                }
                                <TableCell component="th" scope="row" padding="default">
                                  <div style={{ flex: 1 }}>
                                    <TextField
                                      type="number"
                                      name="limitPerUser"
                                      style={{ width: '50px' }}
                                      disabled={isDisable}
                                      value={n.limitPerUser || ''}
                                      onChange={e => this.handleChange(e, n)}
                                      autoComplete="off"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  {isDisable ? 
                                    <Tooltip title="Copy">
                                      <div>
                                        <IconButton disabled={this.checkAddItemCondition(n.endTime)}>
                                          <CopyFilled style={{ fontSize: '20px', color: this.checkAddItemCondition(n.endTime) ? 'default' : '#08c' }} onClick={() => this.handleAddItem(n)} />
                                        </IconButton>
                                      </div>
                                    </Tooltip>
                                    :
                                    <IconButton>
                                      {(!n.isModified && !n.isDeleted) && <Tooltip title="Delete"><DeleteIcon onClick={() => this.showConfirm(n)} color="action" variant="outlined" /></Tooltip>}
                                      {((n.isModified || n.isDeleted)) && <Tooltip title="Undo"><UndoIcon color="primary" onClick={() => this.handleUndo(n)} /></Tooltip>}
                                    </IconButton>
                                  }
                                </TableCell>
                              </TableRow>
                          </React.Fragment>
                        );
                    })}
                    </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                />
            </div>
        </div>
    );
  }
}

OfferCampaignItemTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  items: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
};

const mapStateToProps = state => ({
  currency: state.currency,
});

export default connect(mapStateToProps, null)(withStyles(styles)(OfferCampaignItemTable));
