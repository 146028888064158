import React, { Component } from 'react';
import {
  Button,
  Typography,
  Snackbar
} from '@material-ui/core/';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavBar from '../../../components/NavBar';
import apiCall, {
  purchaseOrderById,
  grnPath,
  fetchAllGrnByPoId
} from '../../../api/NetworkHandler';
import POItemList from '../components/POItemList';
import GrnTitleBar from '../components/GrnTitleBar';
import Title from '../../Commons/components/Title';
import { isEqual, cloneDeep, debounce } from "lodash";
import { Modal, Spin } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getLatestGRNQuantitySummary, fetchMaxExtraAllowed } from './GrnUtils';

const { confirm, warn } = Modal;

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
};

var versionDict = {
  "grnRequestId": 0
};

class AddGRNContainer extends Component {
  constructor(props) {
    super(props);
    const { match = {}, history = {} } = this.props;
    const { params = {} } = match;
    const { location: { state: { detail: invoiceNumber } = {} } = {} } = history;
    const { poId } = params;
    this.state = {
      poId,
      invoiceNumber,
      grn: {},
      data: [],
      isLoading: false,
      disableSave: false,
      initialGrn: {},
      poReceivableDate: '',
      maxExtraAllowed: null,
      openSnackbar: false,
    };
  }

  componentDidMount() {
    this.fetchGRNS();
    this.getMaxExtraAllowed();
  }

  checkPurchaseOrderLineItemIdPresence = (data) => {
    let result = true;
    if (data.length > 0) {
      data.forEach(el => {
        const { grnLineItems } = el;
        result = grnLineItems.every(elm => elm.purchaseOrderLineItemId !== null)
      });
      return result;
    } else {
      return result;
    }
  };

  fetchGRNS = async (source = '') => {
    const { poId } = this.state;
    let result = false;
    try {
      versionDict.grnRequestId += 1;
      let prevRequestId = versionDict.grnRequestId;
      const response = await apiCall.sendRequest('get', fetchAllGrnByPoId(poId));
      const { data: { _embedded: { grn = [] } = {} } = {} } = response;
      if (prevRequestId === versionDict.grnRequestId) {
        this.setState({
          data: grn
        }, () => {
          if (source === 'save') {
            result = this.checkFinalGrnQuantity().invalidGrn;
          } else {
            this.getPurchaseOrder();
          }
        });
      }
      return result;
    } catch (err) {
      this.setState({
        data: []
      });
    }
  };

  getMaxExtraAllowed = () => {
    fetchMaxExtraAllowed((err, res) => {
      if (err) {
        this.setState({
          maxExtraAllowed: null
        });
      }

      if (res) {
        this.setState({
          maxExtraAllowed: res
        });
      }
    })
  }

  getGrnDataFormat = (po) => {
    const { invoiceNumber, data } = this.state;
    const shouldAddPOLineItemId = this.checkPurchaseOrderLineItemIdPresence(data);
    const grn = {
      active: true,
      comment: '',
      deliveryDate: po.receivableDate,
      invoiceNumber: invoiceNumber || '',
      poNumber: po.id,
      status: 'RECEIVED',
      warehouseName: '',
      warehouse: `/api/v1/warehouse/${po.warehouseId}`,
      warehouseId: po.warehouseId,
      vendorName: po.vendorName,
      grnLineItems: [],
    };

    grn.grnLineItems = po.purchaseOrderLineItems.map(item => ({
      active: true,
      category: item.category,
      discount: item.discount,
      expiryDate: '',
      lineItemReceivedPrice: 0,
      lineItemRejectedPrice: 0,
      packaging: item.packaging,
      productName: item.productName,
      productItemId: item.productItemId,
      vendorProductItemId: shouldAddPOLineItemId ? `${item.vendorProductItemId}-${item.id}` : `${item.vendorProductItemId}-${item.sellingPrice}-${item.purchaseItemType}`,
      quantity: item.quantity,
      receivedQuantity: 0,
      rejectedQuantity: 0,
      rejectionReason: '',
      sku: item.sku,
      tax: item.tax,
      unitMeasure: item.unitMeasure,
      unitPrice: item.sellingPrice,
      costPrice: item.sellingPrice,
      uomId: item.uomId,
      hsnCode: item.hsnCode,
      purchaseItemType: item.purchaseItemType,
      purchaseOrderLineItemId: shouldAddPOLineItemId ? item.id : null
    }));

    this.setState({
      grn,
      initialGrn: cloneDeep(grn),
      poReceivableDate: po.receivableDate,
    });
  }


  getPurchaseOrder = async () => {
    const { poId } = this.state;
    try {
      const response = await apiCall.sendRequest('get', purchaseOrderById(poId));
      const { data = {} } = response;
      this.getGrnDataFormat(data);
    } catch (e) {
      // error handling
    }
  }

  handleChange = (items) => {
    const { grn = {} } = this.state;
    grn.grnLineItems = items;
    this.setState({
      grn,
    });
  }

  goBack = () => {
    const { history } = this.props;
    history.push(`/po/${this.state.poId}`)
  }

  preparePostData = (grnData) => {
    const { grnLineItems = [] } = grnData;
    const newGrnLineItems = grnLineItems.map(el => { return { ...el, vendorProductItemId: el.vendorProductItemId.split("-")[0] } });
    return { ...grnData, grnLineItems: newGrnLineItems }
  };

  toggleButtonAndLoading = (loadingState, buttonState) => {
    this.setState({
      isLoading: loadingState,
      disableSave: buttonState
    });
  };

  checkFinalGrnQuantity = () => {
    const { maxExtraAllowed, grn, data } = this.state;
    const { grnLineItems = [] } = grn;
    const maxAllowedOrderedQuantity = grnLineItems.map(el => {
      return {
        vendorProductItemId: el.vendorProductItemId,
        orderedQuantity: Math.round(el.quantity * (1.0 + (maxExtraAllowed / 100)).toFixed(1))
      }
    });
    const updatedReceivedQuantitySummary = getLatestGRNQuantitySummary(data, ['STOCKUPDATED', 'RECEIVED']);
    const remainingGrnQuantitySummary = maxAllowedOrderedQuantity.map(el => {
      const updatedQuantity = updatedReceivedQuantitySummary.length > 0 ? updatedReceivedQuantitySummary.find(elm => elm.vendorProductItemId === el.vendorProductItemId).quantity : 0;
      return {
        vendorProductItemId: el.vendorProductItemId,
        quantity: el.orderedQuantity - updatedQuantity
      }
    });
    const invalidGrn = grnLineItems.some(el => el.receivedQuantity > remainingGrnQuantitySummary.find(elm => elm.vendorProductItemId === el.vendorProductItemId).quantity);
    return { invalidGrn, remainingGrnQuantitySummary };
  };

  showWarning = () => {
    warn({
      title: 'Duplicate GRN',
      content: 'We found duplicate GRN for the same Purchase Order which is not STOCK UPDATED. Please update the stock before creating a new GRN.'
    });
  };

  toggleSnackBar = (snackBarState) => {
    this.setState({
      openSnackbar: snackBarState
    });
  };

  handleSave = debounce(async () => {
    const { grn } = this.state;
    const { history } = this.props;

    if (!this.validateGRNLineItems()) {
      this.toggleSnackBar(true);
      return;
    }

    const isGrnInvalid = await this.fetchGRNS('save');
    if (isGrnInvalid) {
      console.warn('Some grn line items are exceeding max allowed po quantity.');
      this.showWarning();
      return;
    };
    this.toggleButtonAndLoading(true, true);
    // let modifiedGRN = this.getModifiedGRN(grn);
    // grn.grnLineItems = modifiedGRN;
    const newGrnData = this.preparePostData(grn);
    try {
      const response = await apiCall.sendRequest('post', grnPath, newGrnData);
      const { data } = response;
      history.push({
        pathname: `/po/${grn.poNumber}/grn/${data.id}`,
      });
    } catch (e) {
      // error handling
    }
    this.toggleButtonAndLoading(false, false);
  }, 500);

  getModifiedGRN = (grn) => {
    let modifiedGRN = { ...grn }
    let { grnLineItems = [] } = modifiedGRN;
    let modifiedGRNLineItems = grnLineItems.filter(el => !(el.receivedQuantity == 0 && el.rejectedQuantity == 0));
    return modifiedGRNLineItems;
  }

  showConfirm = () => {
    confirm({
      title: 'Do you want to save changes?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Save & Continue',
      cancelText: 'Exit',
      onOk: () => {
        this.handleSave();
      },
      onCancel: () => {
        this.goBack();
      },
    });
  }

  handleGrnChange = (event) => {
    const { name, value } = event.target;
    const { grn, poReceivableDate } = this.state;
    let today = new Date;
    if (name === "deliveryDate" && !moment(value).isBetween(poReceivableDate, today, undefined, '[)')) {
      return;
    }
    this.setState({
      grn: {
        ...grn,
        [name]: value,
      }
    });
  }

  validateGRNLineItems = () => {
    const { grn = {}, maxExtraAllowed, data } = this.state;
    const { grnLineItems = [] } = grn;
    let isValid = true;
    let addedQuantityData = getLatestGRNQuantitySummary(data, ['STOCKUPDATED']);
    grnLineItems.forEach(lineItem => {
      let quantitySum = parseInt(lineItem.receivedQuantity) + parseInt(lineItem.rejectedQuantity);
      let maxAllowedOrderedQuantity = Math.round(lineItem.quantity * (1.0 + (maxExtraAllowed / 100)).toFixed(1));
      let remainingQuantity = 0;
      if (addedQuantityData.length > 0) {
        let addedQuantityObj = addedQuantityData.find(el => el.vendorProductItemId == lineItem.vendorProductItemId);
        if (typeof addedQuantityObj === 'undefined' || Object.keys(addedQuantityObj).length == 0) {
          remainingQuantity = parseInt(maxAllowedOrderedQuantity);
        } else {
          remainingQuantity = parseInt(maxAllowedOrderedQuantity) - parseInt(addedQuantityObj.quantity);
        }
      } else {
        remainingQuantity = parseInt(maxAllowedOrderedQuantity);
      }
      if (quantitySum > remainingQuantity) {
        isValid = false
      }
    });
    return isValid
  }

  render() {
    const { grn = {}, isLoading, disableSave, initialGrn, poReceivableDate, data, openSnackbar } = this.state;
    const { grnLineItems = [] } = grn;
    let isValid = grnLineItems.some(el => el.receivedQuantity > 0 || el.rejectedQuantity > 0);
    let isGrnModified = isEqual(initialGrn, grn);
    return (
      <div>
        <NavBar />
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
          <GrnTitleBar title="Add GRN" grn={grn} onChange={this.handleGrnChange} poReceivableDate={poReceivableDate} />
          <div style={styles.div}>
            <Title title="GRN Line Items" />
            <Typography variant="body2" color="secondary">
              <Button
                style={{ marginTop: '20px', marginRight: '10px' }}
                size="small"
                color="secondary"
                variant="contained"
                onClick={!isGrnModified ? this.showConfirm : this.goBack}
              >
                Back
              </Button>
            </Typography>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <POItemList
              items={grnLineItems}
              onChange={this.handleChange}
              getReceivedQuantity={getLatestGRNQuantitySummary(data, ['STOCKUPDATED'])}
            />
          </div>
          <div style={{ textAlign: 'center', margin: '30px 0px' }}>
            <Button disabled={!isValid || disableSave} variant="contained" color="secondary" onClick={this.handleSave} style={{ marginRight: 10 }}>Save GRN & Continue</Button>
          </div>
        </Spin>
        <Snackbar
          open={openSnackbar}
          onClose={() => this.toggleSnackBar(false)}
          message="Invalid Received Quantity Entered"
          style={{ marginBottom: "20px" }}
        />
      </div>
    );
  }
}

AddGRNContainer.propTypes = {
  match: PropTypes.instanceOf(Object),
};

export default withRouter(AddGRNContainer);
