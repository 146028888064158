import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Select, MenuItem, Button } from '@material-ui/core';
import OrderChat from './OrderChat';
import DeliveryOrderImage from '../../Commons/components/DeliveryOrderImage';
import BASE_URL from '../../../api/config';
import API, { complaintReasonPath, searchDeliverOrderChats, deliveryOrderChats } from '../../../api/NetworkHandler';

const styles = {
  orderNumber: {
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '3px solid #eeeeee',
  },
  messageBox: {
    resize: 'none',
    width: '97%',
    height: '75px',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '5px',
    borderColor: '#bbbbbb',
    fontSize: '100%',
  },
};

class MessageBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complaintReasons: [],
      chats: [],
      message: '',
    };
  }
    componentDidMount = () => {
      this.getComplaintResons();
      this.fetchAllDeliveryOrderChats();
    }

    getComplaintResons = async () => {
      const { selectedDeliveryRouteOrder = {} } = this.state;
      const { customerOrder } = selectedDeliveryRouteOrder;
      const path = complaintReasonPath(customerOrder);
      try {
        const response = await API.sendRequest('get', path);
        const { data = [] } = response;
        this.setState({
          complaintReasons: data,
        });
      } catch (e) {
        // error handling
      }
    }

    fetchAllDeliveryOrderChats = async () => {
      try {
        const { order: { id } } = this.props;
        const response = await API.sendRequest('get', searchDeliverOrderChats(id));
        const { _embedded: embedded } = response.data;
        this.setState({
          chats: embedded['delivery-order-chats'],
        });
      } catch (e) {
        // error handling
      }
    }

    updateMessage = (event) => {
      const { value } = event.target;
      this.setState({
        message: value,
      });
    }

    sendMessage = async () => {
      const { User = {}, order = {} } = this.props;
      const { message } = this.state;
      const postData = {
        message,
        deliveryRouteOrder: `${BASE_URL.BASE_URL}/api/v1/delivery-route-order/${order.id}`,
        userId: User.id,
        userName: User.name,
      };
      await API.sendRequest('post', deliveryOrderChats, postData);
      this.fetchAllDeliveryOrderChats();
      this.setState({
        message: '',
      });
    }
    render() {
      const { complaintReasons = [], chats = [], message = '' } = this.state;
      const { order = {}, reasonMapping = {}, User } = this.props;
      const {
        complaintReason,
        status: orderStatus = '',
        deliveryImageUrl,
        latitude,
        longitude,
      } = order;
      return (
        <div>
          <Typography variant="display1" gutterBottom headlineMapping={{ display1: 'h6' }}>
            <span>Message Board</span>
          </Typography>
          <Typography variant="title" gutterBottom>{order.customerName}</Typography>
          <Typography variant="body2" color="secondary" style={styles.orderNumber}>
            <span>Order #{String(order.customerOrder).padStart(6, '0')}</span>
            {
                orderStatus === 'CUSTOMER_COMPLAINT' ?
                  <span style={{ marginLeft: '10%', marginRight: '10%' }}>
                    <span style={{ margin: '0px 5px' }}>Reason: </span>
                    <Select
                      value={complaintReason || 'Select Reason'}
                      onChange={e => this.updateStatus(e.target.value, 'complaintReason')}
                    >
                      {
                        complaintReasons.map(status => (
                          <MenuItem key={status} value={status}>
                            {reasonMapping[status]}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </span>
                : null
            }
            <span style={{ float: 'right' }}>
              {orderStatus !== 'CUSTOMER_COMPLAINT' ?
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => this.updateStatus('CUSTOMER_COMPLAINT', 'status')}
                >
                Complaint
                </Button>
              :
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleResolve}
                  disabled={!complaintReason || complaintReason === ''}
                  style={{ marginLeft: 10 }}
                >
                Resolve
                </Button>
                  }
            </span>
          </Typography>
          {
              chats.map(chat => (
                <OrderChat chat={chat} user={User} />
              ))
            }
          <div>
            <textarea
              value={message}
              style={styles.messageBox}
              placeholder="Enter a message"
              onChange={this.updateMessage}
              onKeyPress={(event) => { if (event.key === 'Enter') { this.sendMessage(); } }}
            />
            <Button color="secondary" variant="contained" style={{ float: 'right', marginTop: '10px' }} onClick={this.sendMessage}>
                  Post
            </Button>
          </div>
          {
              deliveryImageUrl &&
              <DeliveryOrderImage
                latitude={+latitude}
                longitude={+longitude}
                imageUrl={deliveryImageUrl}
              />
            }
        </div>
      );
    }
}

MessageBoard.propTypes = {
  order: PropTypes.instanceOf(Object),
  reasonMapping: PropTypes.instanceOf(Object),
  User: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { User, Status = {} } = state;
  return {
    User,
    reasonMapping: Status.complaintReasons,
  };
};

export default connect(mapStateToProps, null)(MessageBoard);
