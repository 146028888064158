import React from 'react';
import Select from '@atlaskit/select/Select';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

function DisplayOptionsSelector(props) {
  const { onDisplayOptionChange, selected, isMulti = true } = props;

  const prepareDisplayOptions = () => {
    const { options } = props;
    if (Object.keys(options).length === 0) return [];
    let newOptions = Object.keys(options).map(el => ({ label: capitalize(options[el]), value: el }))
    return newOptions;
  }

  return (
    <Select
      options={prepareDisplayOptions()}
      value={selected}
      onChange={onDisplayOptionChange}
      isMulti={isMulti}
    />
  )
}

DisplayOptionsSelector.propTypes = {
  onDisplayOptionChange: PropTypes.func.isRequired,
}

export default DisplayOptionsSelector;
