import React from 'react';
import { withRouter } from 'react-router-dom';
import apiCall, {
  getRegionProducts,
  regionsPath,
  getFetchAllCategories
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import ProductSearch from '../components/ProductSearch';
import ProductList from '../components/ProductList';
import ProductForm from '../components/ProductForm';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import { debounce } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Colors from '../../../utils/Colors';

const styles = {
  searchContainer: {
    backgroundColor: Colors.grey[100],
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    height: '2em',
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '1.4em',
  },
};

class App extends React.Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      data: [],
      regions: [],
      regionId: 1,
      totalProducts: 0,
      allProductStatus: {},
      currentSubProducts: [],
      showSubProducts: false,
      searchQuery: "",
      selectedProduct: {},
      productCatalog: {},
      productFormDialog: false,
      categoryId: '',
      status: '',
      showProgress: false,
    };
  }


  componentDidMount() {
    this.fetchAllRegions();
    this.fetchAllCategories();
  }

  fetchAllCategories = async () => {
    const res = await apiCall.sendRequest('get', getFetchAllCategories(''));
    const { data = {} } = res;
    const { _embedded = {} } = data;
    this.setState({
      categories: _embedded.categories,
    });
  }

  fetchAllRegions = async () => {
    const res = await apiCall.sendRequest('get', regionsPath);
    const { data = {} } = res;
    const { _embedded: { regions } } = data;

    this.setState({
      regions,
      regionId: regions[0].id,
    },() => { this.fetchRegionProducts() });
  }

  fetchRegionProducts = async () => {
    const { regionId, rowsPerPage, page, searchQuery, categoryId, status } = this.state;
    this.showProgress();
    try{
      const response = await apiCall.sendRequest('get', getRegionProducts(regionId, rowsPerPage, page, searchQuery, categoryId, status));
      this.hideProgress();
      const { data: { data } } = response;
      this.setState((prevState) => {
        return {
          data: data.stagingProducts,
          totalProducts: data.count ? data.count : prevState.totalProducts,
        }
      })
    }catch(error){
      this.hideProgress();
      this.setState({ data: [], totalProducts: 0 })
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchRegionProducts() });
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    this.setState({ rowsPerPage }, () => { this.fetchRegionProducts() });
  };

  onChangeFilter = (event) => {
    const id = event.target.value;
    this.setState({
      regionId: id,
      page: 0,
    }, () => { this.fetchRegionProducts() });
  }

  onSearch = () => {
    this.setState({ page: 0 }, () => { this.fetchRegionProducts() })
  }

  onSearchChange = debounce(() => {
    this.onSearch();
   }, 400)

  onClearSearch = () => {
    this.setState({
      searchQuery: "",
      page: 0,
      categoryId: '',
      status: '',
    }, () => {
      this.fetchRegionProducts();
    })
  }

  editHandler = async (product) => {
    this.setState(prevState => ({
      productFormDialog: true,
      selectedProduct: product,
    }));
  }

  handlePopupClose = (popup) => {
    this.setState({
      [popup]: false,
    });
  }

  handleClear = () => {
    this.setState({
      selectedProduct: {},
    });
    this.fetchRegionProducts();
  }

  onchangeCategory = (event) => {
    let id = event.target.value;
    if (id == 'all') {
      id = '';
    }
    this.setState({
      categoryId: id,
      page: 0,
    }, () => { this.fetchRegionProducts() });
  }

  onchangeStatus = (event) => {
    let status = event.target.value;
    if (status == 'all') {
      status = '';
    }
    this.setState({
      status,
      page: 0,
    }, () => { this.fetchRegionProducts() });
  }

  showProgress = () => {
    this.setState({ showProgress: true });
  }

  hideProgress = () => {
    this.setState({ showProgress: false });
  }

  render() {
    const {
      data, regions,
      regionId, searchQuery,
      productFormDialog, selectedProduct,
      page, rowsPerPage, totalProducts,
      categories, categoryId, status,
      showProgress
    } = this.state;
    return (
      <div>
        <NavBar />
        {showProgress ? <LinearProgress color="secondary" /> : <div style={{ minHeight: '5px' }}></div>}
        <div style={styles.searchContainer}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">
              Products
            </Typography>
          </div>
          <ProductSearch
            regions={regions}
            regionId={regionId}
            onChangeFilter={this.onChangeFilter}
            onSearch={this.onSearch}
            onChange={(event) => { this.onSearchChange(); this.setState({ searchQuery: event.target.value }); }}
            searchQuery={searchQuery}
            clearSearch={this.onClearSearch}
            categories={categories}
            onChangeCategory={this.onchangeCategory}
            categoryId={categoryId}
            onChangeStatus={this.onchangeStatus}
            status={status}
          />
        </div>
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          <ProductList
            editHandler={this.editHandler}
            data={data}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={totalProducts}
          />
        </div>
        <Drawer anchor="right" open={productFormDialog} onClose={() => { this.handlePopupClose('productFormDialog') }}>
          <ProductForm
            product={selectedProduct}
            onRefresh={this.fetchRegionProducts}
            onClear={this.handleClear}
            onClose={() => this.handlePopupClose('productFormDialog')}
          />
        </Drawer>
      </div>
    );
  }
}

export default withRouter(App);
