import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { TextField, Select } from '@material-ui/core';
import SectionMessage from '@atlaskit/section-message';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHeader from '../../Commons/components/TableHeader';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import apiCall, { updatePackagingDetailsByOrderId } from "../../../api/NetworkHandler";

const style = {
    cellStyle: {
      textAlign: 'center',
    },
  };

const columnData = [
    {
        id: 'productItemName', numeric: false, disablePadding: true, label: 'Product',
    },
    {
        id: 'quantity', numeric: false, disablePadding: true, label: 'Requested Quantity',
    },
    {
        id: 'deliveredQuantity', numeric: false, disablePadding: true, label: 'Quantity to be Delivered',
    },
    {
        id: 'packageStatus', numeric: false, disablePadding: true, label: 'Line Item Package Status',
    },
    {
        id: 'primaryPackageName', numeric: false, disablePadding: true, label: 'Primary Package',
    },
    {
        id: 'primaryPackageQuantity', numeric: false, disablePadding: true, label: 'Primary Package Quantity',
    },
    {
        id: 'secondaryPackageName', numeric: false, disablePadding: true, label: 'Secondary Package',
    },
    {
        id: 'secondaryPackageQuantity', numeric: false, disablePadding: true, label: 'Secondary Package Quantity',
    },
  ];

var versionDict = {
    'initialRequestId': 0,
    'initalStateId': 0,
}

class COPackagingView extends Component {
  constructor(props) {
    super(props);
    const { packagingData } = this.props;
    this.state = {
      packagingData,
      errorText: '',
    };
  }

  handleCOLineItemChange = (event, item, index) => {
    const { name, value } = event.target;
    if (name === 'primaryPackageQuantity' && parseInt(value) < 0) return;
    if (name === 'secondaryPackageQuantity' && parseInt(value) < 0) return;
    item = {
        ...item,
        [name]: value,
    };
    let { packagingData } = this.state;
    const { deliveredQuantity, primaryPackageName, primaryPackageQuantity, secondaryPackageName, secondaryPackageQuantity } = item;
    if ((parseInt(primaryPackageQuantity || 0) + parseInt(secondaryPackageQuantity || 0)) > parseInt(deliveredQuantity)) {
        this.setState({
            errorText: "Package Quantity can't be greater than Delivered Quantity",
        });
        return;
    }
    if (primaryPackageName === secondaryPackageName) {
        this.setState({
            errorText: 'Same package is not allowed for selected item'
        });
        item = {
            ...item,
            [name]: null,
        };
        packagingData[index] = item;
        this.setState({
            packagingData,
            errorText: '',
        });
        return;
    }
    packagingData[index] = item;
    this.setState({
        packagingData,
        errorText: '',
    });
  }

  handleSubmitPackagingData = async () => {
      const { packagingData } = this.state;
      const { data, handleClose } = this.props;
      const { id = null } = data;
      try {
        await apiCall.sendRequest('post', updatePackagingDetailsByOrderId(id), packagingData);
        handleClose();
      } catch (err) {
        console.log(err.response);
      }
  }

  isSelected = id => this.state.selected === id;

  render() {
    const { handleClose, openPackagingView, packageNames, packagingResponse } = this.props;
    const { packagingData, errorText } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openPackagingView}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <div
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            height: '85%',
            width: '80%',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        >
          <Button
            style={{ float: 'right' }}
            onClick={handleClose}
            color="secondary"
            aria-label="close"
          >
            <CloseIcon />
          </Button>
          <div style={{ paddingLeft: '3%', display: 'flex', justifyContent: 'initial' }}>
              <p style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '10px', paddingRight: '20px' }}>Packaging Details</p>
              {errorText &&
                <SectionMessage appearance="error">
                    {errorText}
                </SectionMessage>
                }
          </div>
          {packagingData.length ?
            <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                <Table>
                    <TableHeader
                        columns={columnData}
                        cellStyle={style.cellStyle}
                    />
                    <TableBody>
                        {packagingData.map((item, index) => {
                            const isSelected = this.isSelected(item.id);
                            return (
                                <TableRow 
                                    hover
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={item.id}
                                    selected={isSelected}
                                    style={{
                                        backgroundColor: (item.packageStatus === 'ADD_REQUIRED') ? 'rgb(255, 255, 200)' : (item.packageStatus === 'REMOVE_REQUIRED') ? '#DCDCDC' : 'transparent',
                                        border: (item.packageStatus === 'REMOVE_REQUIRED') ? '1px solid red' : 'none',
                                        borderWidth: (item.packageStatus === 'REMOVE_REQUIRED') ? 'medium' : 'none',
                                    }}
                                >
                                <TableCell component="th" scope="row" padding="none">{item.productItemName}</TableCell>
                                <TableCell component="th" scope="row" padding="default">{item.quantity}</TableCell>
                                <TableCell component="th" scope="row" padding="default">{item.deliveredQuantity}</TableCell>
                                <TableCell component="th" scope="row" padding="default">{item.packageStatus}</TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                    <Select
                                        name="primaryPackageName"
                                        onChange={e => this.handleCOLineItemChange(e, item, index)}
                                        value={item.primaryPackageName}
                                        style={{ width: '140px' }}
                                    >
                                        {packageNames.filter(n => n.value !== 'all').map((el, index) => (<option key={index} value={el.value}>{el.label}</option>))}
                                    </Select>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="default">
                                    <TextField
                                        name="primaryPackageQuantity"
                                        type="number"
                                        style={{ width: '50px' }}
                                        value={item.primaryPackageQuantity || ''}
                                        onChange={e => this.handleCOLineItemChange(e, item, index)}
                                        autoComplete='off'
                                        // autoFocus={item.packageStatus === 'REMOVE_REQUIRED'}
                                        error={item.packageStatus === 'REMOVE_REQUIRED'}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                    <Select
                                        name="secondaryPackageName"
                                        onChange={e => this.handleCOLineItemChange(e, item, index)}
                                        value={item.secondaryPackageName}
                                        style={{ width: '140px' }}
                                    >
                                        {packageNames.filter(n => n.value !== 'all').map((el, index) => (<option key={index} value={el.value}>{el.label}</option>))}
                                    </Select>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="default">
                                    <TextField
                                        name="secondaryPackageQuantity"
                                        type="number"
                                        style={{ width: '50px' }}
                                        value={item.secondaryPackageQuantity || ''}
                                        onChange={e => this.handleCOLineItemChange(e, item, index)}
                                        autoComplete='off'
                                    />
                                </TableCell>
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            :
            <div>
                <p style={{ textAlign: 'center', fontSize: '18px', color: 'red' }}>{packagingResponse}</p>
            </div>
          }
          <div style={{ float: 'right' }}>
            <div style={{ paddingRight: '45px', paddingTop: '20px', paddingBottom: '20px' }}>
                <Button onClick={this.handleSubmitPackagingData} variant="contained" color="primary"> Submit </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

COPackagingView.propTypes = {
  openPackagingView: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectStaticRoute: PropTypes.func.isRequired,
};

export default COPackagingView;
