import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
    TextField,
    Typography,
    InputAdornment,
    Select,
    MenuItem
} from '@material-ui/core/';
import Search from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  },
};

function ReferralSearch(props) {
    const {
        classes,
        onSearch,
        onClear,
        onChange,
        onGenerateReferral,
        filters,
        referralStatuses
    } = props;

    const { name, referralCode, status } = filters;

    return (
        <Grid fluid className={classes.container}>
            <Typography variant="title" color="inherit" style={{ padding: "10px" }}> Referral </Typography>
            <Row md={12} middle="md">
                <Col md={12}>
                    <Row md={12} middle="md">
                        <Col md={12}>
                        <Row md={12} bottom="md">
                            <ColMod custompadding="true" md={2}>
                                <TextField
                                    id="name-input-with-icon-textfield"
                                    label="Search Referral (By Name)"
                                    name="name"
                                    fullWidth
                                    value={name || ''}
                                    onChange={onChange}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </ColMod>
                            <ColMod custompadding="true" md={2}>
                                <TextField
                                    id="referral-code-input-with-icon-textfield"
                                    label="Referral Code"
                                    name="referralCode"
                                    fullWidth
                                    value={referralCode || ''}
                                    onChange={onChange}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </ColMod>
                            <ColMod custompadding="true" md={2}>
                                <Typography variant="caption"> Status </Typography>
                                <Select
                                    value={status}
                                    fullWidth
                                    name="status"
                                    onChange={onChange}
                                >
                                    {Object.keys(referralStatuses).map((status, index) => (<MenuItem key={index} value={status}>{referralStatuses[status]}</MenuItem>)) }
                                </Select>
                            </ColMod>
                            <ColMod custompadding="true" md={1}>
                                <Button color="inherit" variant="contained" onClick={onSearch}> Search </Button>
                            </ColMod>
                            <ColMod custompadding="true" md={1}>
                                <Button color="secondary" variant="outlined" onClick={onClear}> Clear </Button>
                            </ColMod>
                            <ColMod custompadding="true" md={2}>
                                <Button color="primary" variant="outlined" onClick={onGenerateReferral}> Generate Referral </Button>
                            </ColMod>
                        </Row>
                        </Col>  
                    </Row>
                </Col>
            </Row>
        </Grid>
    )
}

ReferralSearch.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onGenerateReferral: PropTypes.func,
  onClear: PropTypes.func,
  filters: PropTypes.instanceOf(Object)
};

ReferralSearch.defaultProps = {
  filters: {},
};

export default withStyles(styles)(ReferralSearch);
