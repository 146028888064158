import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import NavBar from '../../components/NavBar';
import SearchBar from '../../components/BuildingSearchBar';
import apiCall from '../../api/NetworkHandler';
import { ColMod } from '../../components/lib';
import TableHeader from '../Commons/components/TableHeader';
import SelectedItemDetail from '../Commons/components/SelectedItemDetail';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../constants';
import SectionMessage from '@atlaskit/section-message';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import CitySelector from '../../components/City/Selector';

const allColumnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City',
  },
  {
    id: 'pincode',
    numeric: false,
    disablePadding: true,
    label: 'Pincode',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

class LocalitiesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddLocality: false,
      selectedLocality: {},
      locality: [],
      links: {},
      filters: {
        query: '',
      },
      searchedResult: false,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      openUserSelector: false,
      postData: {
        name: '',
        city: '',
        pincode: '',
        active: true,
      },
      error: {},
      errorMessage: '',
      isIndia: true,
      columnData: allColumnData,
      selectedCity: null,
      formSelectedCity: null,
      pincodeErrorText: ''
    };
  }

  componentDidMount() {
    this.fetchAllLocalities();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currency } = nextProps;
    if (isEmpty(currency)) {
      return {
        ...prevState
      }
    } else {
      if (currency == "AED") {
        const columnData = allColumnData.filter(column => column.id != 'pincode');
        return {
          columnData,
          isIndia: false
        }
      } else {
        return {
          columnData: allColumnData,
          isIndia: true,
        }
      }
    }
  }

  onSearch = (event) => {
    if (event.key === 'Enter') {
      this.fetchAllLocalities();
    }
  };

  onChangeValue = (event, id) => {
    const { postData } = this.state;
    const val = event.target.value;

    postData[id] = event.target.value;
    this.setState({ postData });
  };

  fetchAllLocalities = () => {
    const { filters: { query = '' } = {}, selectedCity } = this.state;
    if (query || selectedCity) {
      let url = `/api/v1/localities/search/findLocalities?name=${query}`
      if (selectedCity) {
        url += `&city=${selectedCity.name}`
      }
      apiCall
        .sendRequest('get', url)
        .then(({ data }) => {
          const { _embedded, _links } = data;
          this.setState({
            locality: _embedded.localities,
            links: _links,
            searchedResult: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      apiCall
        .sendRequest('get', '/api/v1/localities')
        .then(({ data }) => {
          const { _embedded, _links } = data;
          this.setState({
            locality: _embedded.localities,
            links: _links,
            searchedResult: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  fetchPaginatedLocality = (url) => {
    apiCall
      .sendRequest('get', url)
      .then(({ data }) => {
        const { _embedded = {}, _links } = data;
        this.setState({
          locality: _embedded.localities,
          links: _links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleQueryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value,
      },
    }));
  };

  handleClick = (selectedLocality) => {
    this.setState({
      selectedLocality,
    });
  };

  handleAddLocality = () => {
    this.setState({
      openAddLocality: true,
    });
  };

  editLocality = (n) => {
    this.setState(
      {
        postData: { ...n },
      },
      () => {
        this.handleAddLocality(n);
      },
    );
  };

  handleNewLocalityClose = () => {
    this.setState({
      openAddLocality: false,
      selectedLocality: {},
      postData: {
        name: '',
        city: '',
        pincode: ''
      },
      error: {},
      errorMessage: '',
      formSelectedCity: null,
      pincodeErrorText: ''
    });
  };

  handleChangePage = (event, page) => {
    const { links: { prev = {}, next = {} } = {}, searchedResult } = this.state;
    const { href: prevHref } = prev;
    const { href: nextHref } = next;
    if (searchedResult) {
      this.setState({ page });
    } else {
      let url = '';
      if (this.state.page < page) {
        url = nextHref;
      } else {
        url = prevHref;
      }
      if (url) {
        this.fetchPaginatedLocality(url);
      }
    }
  };

  createNewLocality = () => {
    if (!this.validateForm()) {
      return;
    }
    const { postData } = this.state;
    if (postData.id) {
      apiCall
        .sendRequest('patch', `/api/v1/localities/${postData.id}`, postData)
        .then((response) => {
          this.fetchAllLocalities();
          this.handleNewLocalityClose();
        })
        .catch((error) => {
          const { response: { data } } = error;
          let errorMessage = '';
          if (!data.message) {
            errorMessage = data;
          } else {
            errorMessage = data.message;
          }
          this.setState({ errorMessage });
        });
    } else {
      apiCall
        .sendRequest('post', '/api/v1/localities', postData)
        .then((response) => {
          this.fetchAllLocalities();
          this.handleNewLocalityClose();
        })
        .catch((error) => {
          const { response: { data } } = error;
          let errorMessage = '';
          if (!data.message) {
            errorMessage = data;
          } else {
            errorMessage = data.message;
          }
          this.setState({ errorMessage });
        });
    }
  };

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  isPropertyValid = (property, value) => {
    if (!value) {
      if (property == 'pincode') {
        this.setState({ pincodeErrorText: 'Please enter 6 digit pincode' })
      }
      this.setError(property, true);
      return false;
    } else if(property == 'pincode') {
      if (value.length !== 6){
        this.setState({ pincodeErrorText: 'Please enter 6 digit pincode' })
        this.setError(property, true);
        return false;
      }
      this.setState({ pincodeErrorText: '' });
    }
    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;

    const { postData, isIndia } = this.state;

    const validEntityTypes = ["name", "city"];

    if (isIndia) {
      validEntityTypes.push("pincode");
    }

    for (const property in postData) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, postData[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  onSelectCity = (city) => {
    this.setState({ selectedCity: city }, ()=>{ this.fetchAllLocalities() });
  }

  onClearSearch = () => {
    this.setState(prevState => {
      return {
        filters: {
          query: ''
        },
        selectedCity: null
      }
    },()=>{ this.fetchAllLocalities() })
  }

  onFormCitySelect = (formSelectedCity = {}) => {
    const { name, regionId } = formSelectedCity ? formSelectedCity : {};
    
    this.setState(prevState => {
      const { postData } = prevState;

      return {
        postData: {
          ...postData,
          city: name,
          regionId
        },
        formSelectedCity
      }
    })
  }

  render() {
    const {
      rowsPerPage, page, locality, selectedLocality, searchedResult, postData, rowsPerPageOptions, error, errorMessage, isIndia, columnData,
      selectedCity, formSelectedCity, pincodeErrorText
    } = this.state;
    const { id, city, name, pincode } = postData;
    return (
      <div>
        <NavBar />
        <SearchBar
          title="Locality"
          onSearch={this.onSearch}
          openAddBuilding={this.handleAddLocality}
          query={this.state.filters.query}
          handleQueryChange={this.handleQueryChange}
          buttonName="New Locality"
          onSelectCity={this.onSelectCity}
          selectedCity={selectedCity}
          clearSearch={this.onClearSearch}
        />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
              <TableHeader columns={columnData} />
              <TableBody>
                {locality.map(n => (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(n)}
                    tabIndex={-1}
                    key={n.id}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    selected={selectedLocality && selectedLocality.id === n.id}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      {n.name}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.city}
                      </div>
                    </TableCell>
                    {isIndia && <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.pincode}
                      </div>
                    </TableCell>}
                    <TableCell component="th" scope="row" padding="none">
                      <span>
                        <Button size="small" color="secondary" onClick={(event) => { event.stopPropagation(); this.editLocality(n); }}>EDIT</Button>
                      </span>
                    </TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
            {(searchedResult) ?
              <TablePagination
                component="div"
                count={locality.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions} 
              />
              : <TablePagination
                component="div"
                count={locality.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                    disabled: !this.state.links.prev,
                  }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                    disabled: !this.state.links.next,
                  }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />}
          </div>
          <div style={styles.paperRight}>
            { (!isEmpty(selectedLocality) && Object.keys(selectedLocality).length > 0)
              ?
                <SelectedItemDetail item={selectedLocality} />
              : <div />}
          </div>
        </div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openAddLocality}
          onClose={this.handleNewLocalityClose}
        >
          <div style={{
            background: 'white',
            width: '70%',
            height: '60%',
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleNewLocalityClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      Create/Update Locality
                    </Typography>
                  </Row>
                  {errorMessage && <Row xs={12}>
                    <ColMod xs={12}>
                      <SectionMessage appearance="error">
                        {errorMessage}
                      </SectionMessage>
                    </ColMod>
                  </Row>}
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={name || ''}
                        onChange={event => this.onChangeValue(event, 'name')}
                        required
                        error={error.name}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <Typography variant="caption" color={error.city ? "error" : "default"}>City *</Typography>
                      {!id ? <CitySelector
                        onSelect={this.onFormCitySelect}
                        selected={formSelectedCity}
                      /> :
                      <TextField
                        value={city}
                        disabled
                        fullWidth
                      />}
                    </ColMod>
                    {isIndia && <ColMod lg={4}>
                      <TextField
                        type="number"
                        label="Pincode"
                        fullWidth
                        value={pincode || ''}
                        onChange={event => this.onChangeValue(event, 'pincode')}
                        required
                        error={error.pincode}
                        helperText={pincodeErrorText}
                      />
                    </ColMod>}
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button onClick={this.handleNewLocalityClose} style={{ marginRight: '2em' }} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.createNewLocality();
                }}
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currency } = state;
  return {
    currency
  };
};

export default withRouter(connect(mapStateToProps, null)(LocalitiesComponent));
