import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall,
{
  getFetchAllRegionsPath,
  getVendorLocationById,
  getRegionByLocationId,
} from "../api/NetworkHandler";
import {
  Checkbox, FormHelperText,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { INDIA_STATE_LIST, UAE_CITIES_LIST } from '../constants';


const styles = theme => ({
  FormLabel: {
    fontSize: '0.7em',
  },
});

class EditVendorLocation extends Component {
  constructor(props) {
    super();
    this.state = {
      locationData: {},
      regions: [],
      locationPatchData: {},
      temporaryEmailText: '',
      emails: [],
      emailHelperText: 'Please type email and press add button',
      invalidEmail: false,
    }
  }

  componentDidMount() {
    this.fetchAllRegions();
    this.fetchLocationData();
    const { vendorLocation = {} } = this.props;
    const { email = '' } = vendorLocation;
    if (email) {
      this.setState({ emails: email.split(',').map(e => e.trim()) });
    } else {
      this.setState({ emails: [] });
    }
  }

  fetchAllRegions = async () => {
    const response = await apiCall.sendRequest("get", getFetchAllRegionsPath(""));
    const { data: { _embedded } } = response;
    const regions = _embedded.regions;
    this.setState({ regions });
  }

  fetchLocationData = async () => {
    const { locationId } = this.props;
    const response = await apiCall.sendRequest("get", getVendorLocationById(locationId));
    const { data } = response;
    this.setState({ locationData: data });
    this.fetchRegionData();
  }

  fetchRegionData = async () => {
    const { locationId } = this.props;
    const response = await apiCall.sendRequest("get", getRegionByLocationId(locationId));
    const { data } = response;

    this.setState((prevState) => {
      const { locationData } = prevState;
      return {
        locationData: {
          ...locationData,
          regionId: data.id,
        }
      }
    })
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const { locationData, locationPatchData } = prevState
      return {
        locationData: {
          ...locationData,
          [name]: value,
        },
        locationPatchData: {
          ...locationPatchData,
          [name]: value,
        }
      }
    })
  }

  validateFields = (data) => {
    let isFieldValid = Object.keys(data).some(el => !data['country'] || !data['state']);
    return isFieldValid;
  };

  handleSave = async () => {
    const { locationId, updateVendorLocation, onClose } = this.props;
    const { locationPatchData, locationData } = this.state;
    if (this.validateFields(locationData)) {
      return;
    }
    const response = await apiCall.sendRequest("patch", getVendorLocationById(locationId), locationPatchData);
    const { data } = response;
    updateVendorLocation(data);
    onClose();
  }

  handleSelectCheckBox = (event, name) => {
    const { locationData, locationPatchData } = this.state;
    if (name === 'localPurchase') {
      const { checked } = event.target;
      locationData[name] = checked;
      locationPatchData[name] = checked;
      this.setState({
        locationData,
      });
      this.setState({
        locationPatchData,
      })
    }
  }


  handleEmailChange = (event) => {
    const { value } = event.target;
    this.setState({ temporaryEmailText: value.trim().toLowerCase() });
  };

  handleEmailAdd = () => {
    let { temporaryEmailText, emails, locationData, locationPatchData } = this.state;

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(temporaryEmailText)) {
      this.setState({ emailHelperText: 'Invalid email', invalidEmail: true });
      return;
    }

    if (emails.includes(temporaryEmailText.toLowerCase())) {
      this.setState({ 
        duplicateEmailText: "Duplicate email",
        temporaryEmailText: '',
      });
      return;
    }
    emails = [...emails, temporaryEmailText];
    locationData = {
      ...locationData,
      email: emails.join(),
    }
    locationPatchData = {
      ...locationPatchData,
      email: emails.join(),
    }
    this.setState({ 
      emails,
      temporaryEmailText: '',
      locationData,
      locationPatchData,
      emailHelperText: 'Please type email and press add button',
      invalidEmail: false,
      duplicateEmailText: '',
    });
  };

  handleEmailDelete = (email) => {
    let { emails, locationData, locationPatchData } = this.state;
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    locationData = {
      ...locationData,
      email: emails.join(),
    }
    locationPatchData = {
      ...locationPatchData,
      email: emails.join(),
    }
    this.setState({ emails, locationData, locationPatchData });
  };


  render() {
    const { classes, onClose } = this.props;
    const { locationData, regions, emails = [], temporaryEmailText, emailHelperText, invalidEmail, duplicateEmailText } = this.state;
    const {
      name,
      active,
      building,
      floor,
      house,
      regionId,
      street,
      locality,
      subLocality,
      landmark,
      city,
      pincode,
      phoneNumber,
      email,
      contact,
      cin,
      gstin,
      pan,
      companyName,
      localPurchase,
      country,
      state,
    } = locationData;
    const newEmails = emails.filter(el => el);
    const stateOrCityList = country === 'India' ? INDIA_STATE_LIST : UAE_CITIES_LIST;
    return (
      <Grid>
        <Row lg={12} between="lg">
          <ColMod>
            <Typography variant="title" middle="ld">
              Edit Vendor Location
            </Typography>
          </ColMod>
          <ColMod custompadding="true" bumphigh="true">
            <Button onClick={onClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </ColMod>
        </Row>
        <Row lg={12} middle="lg">
          <ColMod zerobottom lg={4}>
            <TextField
              value={name || ''}
              label="Name"
              name="name"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <FormControl fullWidth>
              <InputLabel> Region </InputLabel>
              <Select
                name="regionId"
                value={regionId || ''}
                fullWidth
                disabled>
                {regions.map((region, index) => (
                  <MenuItem
                    key={index}
                    value={region.id}
                  >
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ColMod>
          <ColMod zerobottom lg={4}>
            <FormControl>
              <FormLabel className={classes.FormLabel}>Status</FormLabel>
              <RadioGroup onChange={this.handleInputChange} name='active' value={active + ""} row>
                <FormControlLabel value="true" control={<Radio />} label="Active" />
                <FormControlLabel value="false" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </FormControl>
          </ColMod>
        </Row>
        <Row lg={12} middle="lg">
          <ColMod zerobottom lg={4}>
            <TextField
              value={building || ''}
              label="Building"
              name="building"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={house || ''}
              label="House No."
              name="house"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={floor || ''}
              label="Floor"
              name="floor"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row lg={12}>
          <ColMod zerobottom lg={4}>
            <TextField
              value={street || ''}
              label="Street"
              name="street"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={locality || ''}
              label="Locality"
              name="locality"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={subLocality || ''}
              label="Sub Locality"
              name="subLocality"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row lg={12}>
          <ColMod zerobottom lg={4}>
            <TextField
              value={landmark || ''}
              label="Landmark"
              name="landmark"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={city || ''}
              label="City"
              name="city"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={pincode || ''}
              label="Pincode"
              name="pincode"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row lg={12}>
          <ColMod zerobottom lg={4}>
            <FormControl fullWidth>
              <InputLabel> Country </InputLabel>
              <Select
                name="country"
                value={country || ''}
                fullWidth
                onChange={this.handleInputChange}
                error={!country}
              >
                {['India', 'UAE'].map((country, index) => (<MenuItem key={index} value={country}> {country} </MenuItem>))}
              </Select>
              <FormHelperText error>{!country && '*required'}</FormHelperText>
            </FormControl>
          </ColMod>
          <ColMod zerobottom lg={4}>
            <FormControl fullWidth>
              <InputLabel> State </InputLabel>
              <Select
                name="state"
                value={state || ''}
                fullWidth
                onChange={this.handleInputChange}
                disabled={!country}
                error={!state}
              >
                {stateOrCityList.map((state, index) => (<MenuItem key={index} value={state}> {state} </MenuItem>))}
              </Select>
              <FormHelperText error>{!state && '*required'}</FormHelperText>
            </FormControl>
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={phoneNumber || ''}
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row lg={12}>
          <ColMod zerobottom lg={4}>
            <TextField
              value={contact || ''}
              label="Contact"
              name="contact"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={temporaryEmailText || ''}
              label="Email"
              name="email"
              helperText={emailHelperText}
              error={invalidEmail}
              style={{ width: '300px', marginRight: '10px' }}
              onChange={this.handleEmailChange}
            />
            <Button onClick={this.handleEmailAdd} variant="fab" mini color="secondary" aria-label="add">
              <AddIcon />
            </Button>
          </ColMod>
        </Row>
        {newEmails.length != 0 && 
        <div>
          {newEmails.map((e, index) => (
            <Chip
              key={index}
              label={e}
              variant="outlined"
              color="primary"
              style={{ marginRight: '10px', marginBottom: '10px' }}
              onDelete={() => { this.handleEmailDelete(e) }}
            />
          ))}
        </div>}
        {duplicateEmailText && <span style={{ color: 'red' }}>{duplicateEmailText}</span>}
        <Row lg={12}>
          <ColMod zerobottom lg={4}>
            <TextField
              value={cin || ''}
              label="CIN"
              name="cin"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={gstin || ''}
              label="GSTIN"
              name="gstin"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <TextField
              value={pan || ''}
              label="Pan"
              name="pan"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod zerobottom lg={4}>
            <TextField
              value={companyName || ''}
              label="Company Name"
              name="companyName"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod zerobottom lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localPurchase === true}
                  value={localPurchase}
                  onChange={event => this.handleSelectCheckBox(event, 'localPurchase')}
                  color="secondary"
                  />
                }
                label="Local Purchase"
            />
          </ColMod>
        </Row>
        <Row lg={12} end="lg">
          <ColMod>
            <Button variant="outlined" onClick={onClose} color="secondary">
              Cancel
            </Button>
          </ColMod>
          <ColMod>
            <Button onClick={this.handleSave} variant="contained" color="primary">
              Save
            </Button>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(EditVendorLocation);
