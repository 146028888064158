import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  TextField,
  Select,
  Typography,
  InputAdornment,
  Button,
  Tooltip,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import ProductWidgetSelector from '../../../components/ProductWidget/Selector';
import RegionSelector from '../../../components/Region/Selector';
import Colors from '../../../utils/Colors';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CSVReader from 'react-csv-reader';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  },
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '')
}

function WidgetSearch(props) {
  const {
    classes,
    onAddNew,
    onProductWidgetChange,
    productWidget,
    onRegionChange,
    region,
    query,
    handleQueryChange,
    OnSearch,
    productStatusTypes,
    currentStatus,
    onStatusChange,
    isProductWidgetSelected,
    onDownloadProductItemsCsv,
    onUploadFile,
    onUploadError
  } = props;

  return (
    <Grid fluid className={classes.container}>
      <Typography variant="title" color="inherit" style={{ paddingTop: "10px" }}>Product Widgets</Typography>
      <Row md={12} middle="md">
        <Col md={12}>
          <Row md={12} middle="md">
            <Col md={12}>
              <Row md={12} bottom="md">
                <ColMod custompadding="true" zerobottom="true" md={3}>
                  <TextField
                      id="input-with-icon-textfield"
                      label="Search Product Item"
                      fullWidth
                      value={query || ''}
                      onChange={handleQueryChange}
                      // onKeyPress={onSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                </ColMod>
                <ColMod custompadding="true" zerobottom="true" md={2}>
                <Typography variant="caption"> Status </Typography>
                <Select
                  style={{ marginTop: 16 }}
                  native
                  fullWidth
                  label="Status"
                  value={currentStatus}
                  onChange={e => onStatusChange(e.target.value)}
                >
                  {Object.keys(productStatusTypes).map((status, i) => (
                    <option key={i} value={status}>{status}</option>
                    ))}
                </Select>
                </ColMod>
                <ColMod custompadding="true" zerobottom="true" md={2}>
                  <Typography variant="caption"> Product Widget </Typography>
                  <ProductWidgetSelector
                    onSelect={onProductWidgetChange}
                    selected={productWidget}
                    isClearable={false}
                  />
                </ColMod>
                <ColMod custompadding="true" zerobottom="true" md={2}>
                  <Typography variant="caption"> Region </Typography>
                  <RegionSelector
                    onSelect={onRegionChange}
                    selected={region}
                    isClearable={false}
                  />
                </ColMod>
                <ColMod custompadding="true" zerobottom="true" md={1}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={OnSearch}
                  >
                    Search
                  </Button>
                </ColMod>
                {/* <ColMod md={2}>
                  <Button color="primary" variant="outlined" onClick={onAddNew}> Add Product </Button>
                </ColMod> */}
                <ColMod custompadding="true" zerobottom="true" md={1}>
                  <Typography variant="caption"> Download File </Typography>
                  <Tooltip title="Click to download">
                    <div>
                      <Button variant="outlined" color="primary" onClick={onDownloadProductItemsCsv} disabled={!isProductWidgetSelected} style={{ padding: "5px" }}><CloudDownloadIcon /></Button>
                    </div>
                  </Tooltip>
                </ColMod>
                <ColMod custompadding="true" zerobottom="true" md={1}>
                  <Typography variant="caption"> Upload File </Typography>
                  <CSVReader
                    cssClass="csv-reader-input"
                    accept=".csv, text/csv"
                    disabled={!isProductWidgetSelected}
                    onFileLoaded={onUploadFile}
                    onError={onUploadError}
                    parserOptions={papaparseOptions}
                    inputId="fileControl"
                    inputStyle={{color: 'transparent', outlineStyle: 'none', width: "100px" }}
                  />
                </ColMod>
              </Row>
            </Col>  
          </Row>
        </Col>
      </Row>
    </Grid>
  )
}

WidgetSearch.propTypes = {
  onRegionChange: PropTypes.func.isRequired,
  region: PropTypes.instanceOf(Object),
  onProductWidgetChange: PropTypes.func.isRequired,
  productWidget: PropTypes.instanceOf(Object),
  onAddNew: PropTypes.func.isRequired,
  query: PropTypes.string,
  handleQueryChange: PropTypes.func.isRequired,
  OnSearch: PropTypes.func.isRequired,
  productStatusTypes: PropTypes.instanceOf(Object),
  currentStatus: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
};

WidgetSearch.defaultProps = {
  region: null,

  productWidget: null,
  query: '',
  currentStatus: 'ALL',
};

export default withStyles(styles)(WidgetSearch);
