import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../../src/components/lib';
import { TablePagination } from '@material-ui/core';

class HistoryContainer extends React.Component {

  handleClose = () => {
    this.props.handleClose();
  }
  getOnceTableData = () => {
    const { data } = this.props;
    return (
      <Table>
        <TableHead>

          <TableRow>
            <TableCell>Changes Made</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} style={index % 2 ? { background: "#EEEEEE" } : { background: "white" }}>
              <TableCell>{item.ChangesMade}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
  renderTable = () => {
    const { data } = this.props;
    return (
      <Table>

        <TableHead>

          <TableRow>
            <TableCell>When</TableCell>
            <TableCell style={{ width: '150px' }}>Modified By</TableCell>
            <TableCell>Changes Made</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} style={index % 2 ? { background: "#EEEEEE" } : { background: "white" }}>
              <TableCell>{item.Date}</TableCell>
              <TableCell><span>{item.ModifiedBy.split('(')[0]}<br />
                {item.ModifiedBy.substring(
                  item.ModifiedBy.indexOf("("),
                  item.ModifiedBy.lastIndexOf(")") + 1)}
              </span></TableCell>
              <TableCell>{item.ChangesMade}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table >
    )
  }
  render() {
    const { title,
      data,
      openHistory,
      count, page,
      rowsPerPage, onChangePage,parent,
      rowsPerPageOptions, onChangeRowsPerPage } = this.props;
    return (

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openHistory}
        onClose={this.handleClose}
        disableBackdropClick
      >

        <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '70%',
          height: '85%',
        }}
        >
          <Button style={{ float: 'right' }} onClick={() => { this.handleClose() }} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <Typography variant="body2" color="secondary">
                    {title}
                  </Typography>
                </Row>

                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '450px' }}>
                      {parent && parent === 'getOnce' ? this.getOnceTableData() : this.renderTable()}

                    </div>

                    {count > 5 && <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      onChangePage={onChangePage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      onChangeRowsPerPage={onChangeRowsPerPage}
                      style={{ paddingRight: window.innerWidth * 0.035 }}
                    />
                    }
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div>
      </Modal>
    );
  }
}

HistoryContainer.propTypes = {
  title: PropTypes.string,
  openHistory: PropTypes.bool,
  data: PropTypes.array,
  handleClose: PropTypes.func,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  onChangeRowsPerPage: PropTypes.func,
  parent:PropTypes.string,
};

export default HistoryContainer;
