import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiCall, {
  getUserRolesByUserId,
  getAllGroups,
  getAndSetGroupsByUserId
} from '../../../api/NetworkHandler';
import {
  Button,
  Typography,
  Grid,
  Modal,
  Paper,
  Chip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Transfer } from 'antd';
import { debounce, capitalize, sortBy } from 'lodash';
import ProgressBar from '../../../components/States/ProgressBar';


var versionDict = {
  "allGroupRequestId": 0,
  "userGroupRequestId": 0,
  "userRoleRequestId": 0,
};

class UserGroupSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetKeys: [],
      selectedKeys: [],
      allGroups: [],
      userXPaths: [],
      roles: [],
      disableButton: false,
      loading: false
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.fetchAllGroups();
    this.fetchGroupsByUserId(data.id);
    this.fetchUserRolesByUserId(data.id);
  };

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState
    });
  };

  fetchAllGroups = async () => {
    this.toggleLoading(true);
    try {
      versionDict.allGroupRequestId += 1;
      let prevRequestId = versionDict.allGroupRequestId;
      const response = await apiCall.sendRequest('get', getAllGroups);
      const { data: { data = [] } = {} } = response;
      if (prevRequestId === versionDict.allGroupRequestId) {
        this.setState({
          allGroups: data
        });
      }
    } catch (err) {
      this.setState({
        allGroups: []
      });
    }
    this.toggleLoading(false);
  };

  fetchGroupsByUserId = async (userId) => {
    this.toggleLoading(true);
    try {
      versionDict.userGroupRequestId += 1;
      let prevRequestId = versionDict.userGroupRequestId;
      const response = await apiCall.sendRequest('get', getAndSetGroupsByUserId(userId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId === versionDict.userGroupRequestId) {
        this.setState({
          targetKeys: data.map(el => el.groupId)
        });
      }
    } catch (err) {
      this.setState({
        targetKeys: []
      });
    }
    this.toggleLoading(false);
  };

  fetchUserRolesByUserId = async (userId) => {
    this.toggleLoading(true);
    try {
      versionDict.userRoleRequestId += 1;
      let prevRequestId = versionDict.userRoleRequestId;
      const response = await apiCall.sendRequest('get', getUserRolesByUserId(userId));
      const { data: { _embedded: { roles = [] } = {} } = {} } = response;
      if (prevRequestId === versionDict.userRoleRequestId) {
        this.setState({
          roles
        });
      }
    } catch (err) {
      this.setState({
        roles: []
      });
    }
    this.toggleLoading(false);
  };

  handleModalClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({
      targetKeys: nextTargetKeys,
    });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  renderUserInfo = (userData = {}, keys = []) => {
    return keys.map(el => {
      return (
        <span style={{ display: 'flex', flexDirection: 'row' }} key={el}>
          <Typography color="default" variant="body1" style={{ fontWeight: 'bold', paddingRight: '5px' }}>{capitalize(el)}:</Typography>
          <Typography color="primary">{userData[el]}</Typography>
        </span>
      );
    });
  };

  toggleButtonState = (buttonState) => {
    this.setState({
      disableButton: buttonState
    });
  };

  handleSaveGroups = debounce(async () => {
    this.toggleButtonState(true);
    this.toggleLoading(true);
    const { data: { id: userId } = {} } = this.props;
    const { targetKeys } = this.state;
    try {
      await apiCall.sendRequest('post', getAndSetGroupsByUserId(userId), targetKeys);
    } catch (err) {
      console.error("----problem while sending data------");
    }
    this.fetchGroupsByUserId(userId);
    this.fetchUserRolesByUserId(userId);
    this.toggleButtonState(false);
    this.toggleLoading(false);
  }, 500);

  getSortedTargetKeys = (data = []) => {
    const { allGroups } = this.state;
    const result = allGroups.filter(el => data.some(elm => el.id === elm));
    return sortBy(result, 'name').map(el => el.id);
  };

  render() {
    const { open = false, data } = this.props;
    const {
      targetKeys,
      selectedKeys,
      allGroups,
      roles,
      disableButton,
      loading
    } = this.state;
    const sourceData = sortBy(allGroups.map(el => { return { key: el.id, title: el.name } }), ['title']);
    const sortedTargetKeys = this.getSortedTargetKeys(targetKeys);
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={this.handleModalClose}
        disableBackdropClick
      >
        <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '60%',
          height: '90%',
          borderRadius: '5px',
        }}
        >
          <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", width: "100%", top: 0 }}>
            <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: window.innerWidth * 0.02, paddingTop: "10px" }}>
              <Typography variant="title" color="inherit"> User Groups </Typography>
            </div>
            <Button style={{ float: 'right' }} onClick={this.handleModalClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </div>
          <ProgressBar isLoading={loading} color="primary" />
          <Grid style={{ padding: '1em', background: '#FFFFFF', marginTop: window.innerHeight * 0.05, overflowX: "auto", height: "90%", position: "absolute", width: "100%" }}>
            <div>
              <Paper elevation={3} style={{ padding: '10px', marginBottom: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  {this.renderUserInfo(data, ['name', 'mobile', 'email'])}
                </div>
                {roles.map(role => <Chip key={role.id} label={role.name} variant="outlined" style={{ marginRight: '10px', marginBottom: '10px' }} />)}
              </Paper>
              <Transfer
                dataSource={sourceData}
                titles={['All Groups', 'User Groups']}
                targetKeys={sortedTargetKeys}
                selectedKeys={selectedKeys}
                onChange={this.handleChange}
                onSelectChange={this.handleSelectChange}
                render={item => item.title}
                listStyle={{ width: '50%', height: window.innerHeight * 0.58 }}
              />
              <div style={{ position: "fixed", bottom: "15px", width: "55%", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveGroups}
                  disabled={disableButton}
                >
                  Save Groups
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </Modal>
    );
  }
}

UserGroupSelector.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

UserGroupSelector.defaultProps = {
  open: false,
  onClose: () => { },
  data: {}
};

export default UserGroupSelector;
