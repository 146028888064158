
import React, { Component } from 'react';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../../modules/Commons/containers/SearchBar';
import AttendanceSearch from '../components/AttendanceSearch';
import AttendanceList from '../components/AttendanceList';
import apiCall, { attendanceSummaryURL } from '../../../api/NetworkHandler';


const Constants = {
  title: 'Attendance Summary',
};

const months = moment.months();

const years = {
  2015: '2015',
  2016: '2016',
  2017: '2017',
  2018: '2018',
  2019: '2019',
  2020: '2020',
  2021: '2021',
  2022: '2022',
  2023: '2023',
  2024: '2024',
  2026: '2026',
  2027: '2027',
  2028: '2028',
  2029: '2029',
  2030: '2030',
};

const userTypes = {
  SCM_USER: 'SCM_USER',
  DELIVERY_BOY: 'DELIVERY_BOY',
};

class AttendanceSummaryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        month: moment().format('MMMM'),
        year: moment().format('YYYY'),
        departmentId: '',
        hubId: '',
        routeId: '',
        warehouseId: '',
        userType: '',
      },
      currentPage: 0,
      totalElements: 0,
    };
  }


  componentDidMount() {
    this.fetchAttendanceSummary();
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    });
    this.fetchAttendanceSummary(filters, page);
  }

  fetchAttendanceSummary = async (filters = {}, page = 0) => {
    try {
      const {
        month, year, ...requiredFilters
      } = filters;
      const filterByDate = `${month} ${year}`;
      const filterData = {
        ...requiredFilters,
        startDate: moment(filterByDate).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(filterByDate).endOf('month').format('YYYY-MM-DD'),
      };
      const url = attendanceSummaryURL(filterData, page);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data: { content = [], totalElements } } } = res;
      this.setState({
        attendaceList: content,
        totalElements,
      });
    } catch (e) {
      // error handling
    }
  }


  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };

  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.onSearch(currentPage);
      }
    });
  };

  render() {
    const {
      filters, attendaceList, totalElements, currentPage,
    } = this.state;
    const { month, year } = filters;
    const numberOfDays = moment(`${month} ${year}`).daysInMonth() || 0;

    return (
        <div>
            <NavBar />
            <SearchBar
                title={Constants.title}
                filters={filters}
                onChange={this.handleChange}
                onButtonClick={this.handleClick}
                >
                <AttendanceSearch
                months={months}
                years={years}
                userTypes={userTypes}
                />
                </SearchBar>
                <AttendanceList
                  attendaceList={attendaceList}
                  filters={filters}
                  totalDays = {numberOfDays}
                  totalElements={totalElements}
                  rowsPerPage={20}
                  page={currentPage}
                  onPageChange={this.onSearch}
                />
        </div>
    );
  }
}

export default AttendanceSummaryContainer;
