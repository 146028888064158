import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TextField, Button, Table, TableBody,
  ExpansionPanel, ExpansionPanelSummary,
  TableCell, TableRow,
  ExpansionPanelDetails,
  Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions,
  Select,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MGrid from '@material-ui/core/Grid';
import { ColMod } from '../../../components/lib';
import PurchaseOrderItemTable from '../components/PurchaseOrderItemTable';
import NavBar from '../../../components/NavBar';
import Title from '../../Commons/components/Title';
import API, {
  purchaseOrderByPrId,
  prByPrId,
  generatePObyPR,
  availableVendorForPRPath,
  purchaseIndentCSV,
  cancelPoPath,
} from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';
import TableHeader from '../../Commons/components/TableHeader';
import { Modal, Switch, notification } from 'antd';
import { getApplicationConfigs } from "../../../utils/getAppConfig";


const columnData = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'PO Number',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Vendor',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'created_date', numeric: false, disablePadding: true, label: 'Created Time',
  },
  {
    id: 'update_time', numeric: false, disablePadding: true, label: 'Updated Time',
  },
  {
    id: 'receivableDate', numeric: false, disablePadding: true, label: 'Receivable Date',
  },
  {
    id: 'taxRateType', numeric: false, disablePadding: true, label: 'Tax Rate Type',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const prColumnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Product Name',
  },
  {
    id: 'hsncode', numeric: false, disablePadding: true, label: 'HSN Code',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'sellingPrice', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  // {
  //   id: 'costPrice', numeric: false, disablePadding: true, label: 'Cost Price (Exclusive of Tax)',
  // },
  {
    id: 'unitPrice', numeric: false, disablePadding: true, label: 'MRP',
  },
  {
    id: 'tax', numeric: false, disablePadding: true, label: 'Tax (%)',
  },
  {
    id: 'productItemType', numeric: false, disablePadding: true, label: 'Product Item Type',
  },
  {
    id: 'margin', numeric: false, disablePadding: true, label: 'Margin (%)',
  },
];

const styles = {
  cellStyle: {
    textAlign: 'center',
  },
};

class ViewPRContainer extends Component {
  constructor(props) {
    super(props);
    const { match: { params = {} } } = this.props;
    this.state = {
      prId: params.id,
      purchaseRequisition: {},
      purchaseOrders: [],
      vendors: [],
      openVendorAndRateSelection: false,
      vendor: '',
      taxRateType: '',
      error: '',
      visible: false,
      confirmLoading: false,
      cancelError: '',
      sendCancellationEmail: false,
      isEmailSent: false,
      taxType: '',
      poPermittedUsers: '',
    };
  }

  componentDidMount() {
    this.getPRDetail();
    this.getAllOrdersOfPR();
    this.getAvailableVendorsOfPR();
    this.setTaxTypeState();
  }


  setTaxTypeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { taxType, poPermittedUsers } = appConfig;
    this.setState({
      taxType,
      poPermittedUsers,
    });
  };

  getPRDetail = async () => {
    const { prId } = this.state;
    try {
      const response = await API.sendRequest('get', prByPrId(prId));
      const { data = {} } = response;
      this.setState({
        purchaseRequisition: data,
      });
    } catch (e) {
      // error handling
    }
  }

  getAllOrdersOfPR = async () => {
    const { prId } = this.state;
    try {
      const response = await API.sendRequest('get', purchaseOrderByPrId(prId));
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      this.setState({
        purchaseOrders: embedded['purchase-orders'],
      });
    } catch (e) {
      // error handling
    }
  }

  getAvailableVendorsOfPR = async () => {
    const { prId } = this.state;
    try {
      const response = await API.sendRequest('get', availableVendorForPRPath(prId));
      const { data = [] } = response;
      this.setState({
        vendors: data,
      });
    } catch (e) {
      // error handling
    }
  }

  handlePopup = (state) => {
    this.setState({
      openVendorAndRateSelection: state,
      error: '',
      taxRateType: '',
      vendor: '',
    });
  }

  onChangeItem = (items) => {
    const { purchaseRequisition = {} } = this.state;
    this.setState({
      purchaseRequisition: {
        ...purchaseRequisition,
        purchaseRequisitionLineItems: items,
      },
    });        
  }

  validateFields = () => {
    const { vendor, taxRateType, taxType } = this.state;
    let isInvalid = true;
    if (!vendor) {
      this.setState({
        error: 'Please select vendor',
      });
      isInvalid = false;
    }

    if (taxType === 'gst' && !taxRateType) {
      this.setState({
        error: 'Please select tax rate type',
      });
      isInvalid = false;
    }
    return isInvalid;
  }

  handlePOGeneration = async () => {
    const { vendor, prId, taxRateType, taxType } = this.state;
    const { history = {}, User } = this.props;
    if (!this.validateFields()) {
      return;
    }
    let taxRateTypeOnTaxType = taxType === 'gst' ? taxRateType : 'TAX_COMBINED';

    try {
      const response = await API.sendRequest('get', generatePObyPR(prId, vendor, taxRateTypeOnTaxType));
      const { data = {}, status } = response;
      if (status === 200 && data.id) {
        this.handlePopup(false);
        history.push({
          pathname: `/edit-po/${data.id}`,
          state: taxRateType,
        });
      } else {
        this.setState({
          error: 'There are no more pending items in this purchase requisition',
        });
      }
    } catch (e) {
      // error handling
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handlePOCancel = async (id) => {
    await API.sendRequest('put', cancelPoPath(id), {});
    // window.location.reload();
    this.getAllOrdersOfPR();
  }

  openNotificationWithIcon = type => {
    notification[type]({
      description: this.state.successMessage,
      top: '100px'
    });
  };

  getMailStatus = (emailSent) => {
    if (!emailSent) return false;
    if (emailSent) return true;
  }

  handleOpenPopUp = (poId, emailSent) => {
    this.setState({
      visible: true,
      orderId: poId,
      isEmailSent: emailSent,
      sendCancellationEmail: this.getMailStatus(emailSent),
      cancelError: null,
    });
  }

  handleOk = async () => {
    const { orderId, sendCancellationEmail } = this.state;
    this.setState({ confirmLoading: true })
    try {
      const response = await API.sendRequest('put', cancelPoPath(orderId, sendCancellationEmail), {});
      this.setState({ 
        confirmLoading: false, 
        visible: false,
        successMessage: response.data.message,
      });
      this.getAllOrdersOfPR();
      // this.openNotificationWithIcon('info');
    } catch (error) {
      this.setState({ 
        confirmLoading: false, 
        cancelError: error.response.data.message,
        visible: false,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      cancelError: null,
    });
  };

  handleToggle = () => {
    this.setState({
      sendCancellationEmail: !this.state.sendCancellationEmail,
    });
  };

  renderPurchaseOrders = () => {
    const { purchaseOrders = [], taxType } = this.state;
    const newColumnData = taxType === 'gst' ? columnData : columnData.filter(el => el.id !== 'taxRateType')
    return (
      <div style={{ flex: 1 }}>
        <Table aria-labelledby="tableTitle">
          <TableHeader columns={newColumnData} cellStyle={styles.cellStyle} />
          <TableBody>
            {
           purchaseOrders.map(po => (
             <TableRow>
               <TableCell padding="none">
                 <Link to={`/edit-po/${po.id}`} style={{ color: Colors.primary, textDecoration: 'none' }}>{po.id}</Link>
               </TableCell>
               <TableCell padding="none">{po.vendorName}</TableCell>
               <TableCell padding="none">{po.totalQuantity}</TableCell>
               <TableCell padding="none">{moment(po.timeCreated).format('MMM Do YYYY, hh:mm')}</TableCell>
               <TableCell padding="none">{moment(po.timeUpdated).format('MMM Do YYYY, hh:mm')}</TableCell>
               <TableCell padding="none">{moment(po.receivableDate).format('MMM Do YYYY, hh:mm')}</TableCell>
               {taxType === 'gst' && <TableCell padding="none">{po.taxRateType ? po.taxRateType.split("_").join(" ") : 'NA'}</TableCell>}
               <TableCell padding="none">{po.status}</TableCell>
               <TableCell padding="none">
                 <Button
                   color="primary"
                   variant="outlined"
                   disabled={po.status === 'CANCELLED'}
                   onClick={() => this.handleOpenPopUp(po.id, po.emailSent)}
                 >Cancel
                 </Button>
               </TableCell>
             </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </div>
    );
  }

  numberValidation = (n) => {
    if (!n) return 'NA';
    else return n.toFixed(2)
  }

  renderPurchaseOrderItemTable = () => {
    const { purchaseRequisition = {} } = this.state;
    const { purchaseRequisitionLineItems = [] } = purchaseRequisition;
    return (
      <div style={{ flex: 1 }}>
        <Table aria-labelledby="tableTitle">
          <TableHeader columns={prColumnData} cellStyle={styles.cellStyle} />
          <TableBody>
            {purchaseRequisitionLineItems.map(item => (
             <TableRow>
               <TableCell component="th" scope="row" padding="none">{item.productName}</TableCell>
               <TableCell component="th" scope="row" padding="none">{item.hsncode}</TableCell>
               <TableCell component="th" scope="row" padding="default">{item.quantity}</TableCell>
               <TableCell component="th" scope="row" padding="default">{this.numberValidation(item.sellingPrice)}</TableCell>
               {/* <TableCell component="th" scope="row" padding="default">{item.costPrice}</TableCell> */}
               <TableCell component="th" scope="row" padding="none">{this.numberValidation(item.unitPrice)}</TableCell>
               <TableCell component="th" scope="row" padding="default">{item.tax}</TableCell>
               <TableCell component="th" scope="row" padding="default">{item.productItemType}</TableCell>
               <TableCell component="th" scope="row" padding="default">{this.numberValidation(item.margin)}</TableCell>
             </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </div>
    );
  }

  renderGeneratePODialog = () => {
    const { openVendorAndRateSelection, vendors = [], error = '', taxType, poPermittedUsers } = this.state;
    const { taxRateTypes, User } = this.props;
    const newTaxRateTypes = typeof taxRateTypes === 'undefined' ? {} : taxRateTypes;
    return (
      <Dialog
        open={openVendorAndRateSelection}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Generate Purchase Order</DialogTitle>
        <DialogContent>
          <div style={{ color: Colors.primary, fontSize: 12, paddingBottom: '10px' }}>*{error}</div>
          {taxType === 'gst' && 
            <div>
              <Select
                native
                margin="dense"
                label="Rate Type"
                type="text"
                name="taxRateType"
                fullWidth
                onChange={this.handleChange}
              >
                <option key="" value="">Select Rate Type</option>
                {Object.keys(newTaxRateTypes).map(el => <option key={el} value={el}>{newTaxRateTypes[el]}</option>)}
              </Select>
              &nbsp;&nbsp;&nbsp;
            </div>
          }
          <DialogContentText>
            Please enter a vendor to generate purchse order
            {/* <div style={{ color: Colors.primary, fontSize: 12 }}>*{error}</div> */}
          </DialogContentText>
          <Select
            native
            margin="dense"
            id="vendor"
            label="Vendor"
            type="text"
            name="vendor"
            fullWidth
            onChange={this.handleChange}
          >
            <option key="" value="">Select Vendor</option>
            {vendors.map(vendor => (<option key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</option>))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handlePopup(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handlePOGeneration} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>);
  }

  handleDownload = () => {
    const { purchaseRequisition: PR = {} } = this.state;
    const {
      warehouseId, productCatalogId, deliveryDate, productCatalogName,
    } = PR;
    API.downloadFile(purchaseIndentCSV(warehouseId, deliveryDate, productCatalogId), `${productCatalogName}-${deliveryDate}.csv`);
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { prId, purchaseOrders = [], purchaseRequisition = {}, visible, confirmLoading, cancelError, sendCancellationEmail, isEmailSent } = this.state;
    const {
      purchaseRequisitionLineItems = [],
      productCatalogName = '',
      warehouseName = '',
    } = purchaseRequisition;
    return (
      <div>
        <NavBar />
        <MGrid container style={{ background: Colors.grey[100] }}>
          <MGrid item xs md lg={6}>
            <Title title="View Purchase Requisition" />
          </MGrid>
          <MGrid item xs md lg={6}>
            <div style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleBack}
              >
                Back
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleDownload}
                style={{ margin: 10 }}
              >Download
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.handlePopup(true)}
                style={{ margin: 10 }}
              >
                Create Purchase Order
              </Button>
            </div>
          </MGrid>
        </MGrid>
        <div style={{ width: window.innerWidth * 0.9, position: 'absolute', marginRight: window.innerWidth * 0.4, marginLeft: window.innerWidth * 0.08 }}>
          <ColMod xs md lg={15}>
            <Row>
              <ColMod lg={11}>
                {
                    purchaseOrders.length > 0 &&
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h3 style={{ margin: 0 }}>Purchase Orders ({purchaseOrders.length})</h3>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {this.renderPurchaseOrders()}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
              </ColMod>
              <ColMod lg={11}>
                <Row>
                  <div style={{ flex: 1, margin: '10px' }}>
                    <TextField
                      label="Purchase Requisition Number"
                      InputLabelProps={{ shrink: true }}
                      value={prId}
                      fullWidth
                      disabled
                    />
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1, margin: '10px' }}>
                    <TextField
                      label="Catalog"
                      value={productCatalogName}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      fullWidth
                    />
                  </div>
                  <div style={{ flex: 1, margin: '10px' }}>
                    <TextField
                      label="Warehouse"
                      value={warehouseName}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      fullWidth
                    />
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1 }}>
                    {/* <PurchaseOrderItemTable
                      editable={false}
                      items={purchaseRequisitionLineItems}
                      removeItem={this.removeItem}
                      onChange={this.onChangeItem}
                      hideCol={["sellingPrice", "tax", "total_price"]}
                    /> */}
                    {this.renderPurchaseOrderItemTable()}
                  </div>
                </Row>
              </ColMod>
            </Row>
          </ColMod>
        </div>
        {this.renderGeneratePODialog()}
        <Modal
          title="Cancel Purchase Order"
          centered
          visible={visible}
          confirmLoading={confirmLoading}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={sendCancellationEmail ? 'Cancel and Send Email' : 'Cancel Order'}
          cancelText="Close"
        >
          {cancelError ? <p style={{ color: 'red' }}>{cancelError}</p> : 
          <div>
          <p>Do you want to cancel the order ?</p>
          {/* <p>Note: Vendor will receive cancellation email in registered mail id.</p> */}
          {typeof isEmailSent === 'undefined' && <p>If you don't want to send email, toggle the switch <span style={{ paddingLeft: '5px' }}><Switch size="small" onChange={this.handleToggle}/></span></p>}
        </div>}
        </Modal>
      </div>
    );
  }
}


ViewPRContainer.propTypes = {
  match: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  taxRateTypes: state.Status.taxRateTypes,
  User: state.User,
});

export default connect(mapStateToProps, null)(withRouter(ViewPRContainer));