import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Button from '@material-ui/core/Button';
import ToolTip from '@material-ui/core/Tooltip';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import moment from 'moment';
import Lozenge from '@atlaskit/lozenge';

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Product Item',
  },
  {
    id: "sortId", numeric: false, disablePadding: true, label: 'Sort Id',
  },
  {
    id: "updatedAt", numeric: false, disablePadding: true, label: 'Last Updated At',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: "actions", numeric: false, disablePadding: true, label: 'Actions',
  }
]

class DealOfTheDayProductList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      name: {},
      updatedAt: {},
      status: {}
    };
    const { deals } = this.props;

    deals.map((deal, index) => {
      customComponents['name'][index] = deal.productItem.name;
      customComponents['updatedAt'][index] = <ToolTip title={moment(deal.timeUpdated).format(PRODUCT_TIME_FORMAT)}><span>{moment(deal.timeUpdated).fromNow()}</span></ToolTip>;
      customComponents['status'][index] = <Lozenge appearance={deal.active ? "success" : "removed"}>{deal.active ? "Active" : "Inactive"}</Lozenge>;
    })
    return customComponents;
  }

  getActions = (deal) => (
    <React.Fragment>
      <Button color="secondary" onClick={() => { this.props.onEdit(deal) }}>Edit</Button>
    </React.Fragment>
  )

  render() {
    const {
      deals,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      onRequestSort,
      isLoading,
      isNoData,
      searchTerm,
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={deals}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        customComponents={this.getCustomComponents()}
        isLoading={isLoading}
        isNoData={isNoData}
        searchTerm={searchTerm}
      />
    )
  }
}

DealOfTheDayProductList.propTypes = {
  deals: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  searchTerm: PropTypes.string,
}

DealOfTheDayProductList.defaultProps = {
  order: 'acs',
  orderBy: '',
  isLoading: false,
  isNoData: false,
  searchTerm: '',
}

export default DealOfTheDayProductList;
