import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const styles = {
  searchConatiner: {
    overflow: 'hidden',
    height: '100%',
  },
  formDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 0px 20px',
  },
  jsonDiv: {
    overflow: 'scroll',
    marginTop: '5px',
    padding: '0px 20px',
  },
};

class AuditJsonSearch extends React.Component {

  componentDidUpdate = (prevProps, prevState) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.highlightSearch();
    }
  }

  highlightSearch = () => {
    const text = document.getElementById('query').value;
    if (text) {
      const query = new RegExp('(' + text + ')', 'gim');
      const e = document.getElementById('searchtext').innerHTML;
      const enew = e.replace(/(<span>|<\/span>)/gim, '');
      document.getElementById('searchtext').innerHTML = enew;
      const newe = enew.replace(query, '<span>$1</span>');
      document.getElementById('searchtext').innerHTML = newe;
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  render() {
    const { data, onCloseHandler, height } = this.props;
    return (
      <div style={styles.searchConatiner}>
        <form>
          <div style={styles.formDiv}>
            <div>
              Search: <input
                        name="query"
                        id="query"
                        type="text"
                        onChange={this.highlightSearch}
                        onKeyPress={this.handleKeyPress}
                        autoComplete="off"
                    />
            </div>
            <input type="button" value="Close" onClick={onCloseHandler} />
          </div>
        </form>
        <div style={{ ...styles.jsonDiv, height: `calc(100vh - ${height + 'px'} - 30px)` }}>
            <pre id="searchtext">{JSON.stringify(data, null, 2)}</pre>
        </div>
      </div>
    );
  }
}

AuditJsonSearch.propTypes = {
  data: PropTypes.object,
  onCloseHandler: PropTypes.func,
  height: PropTypes.number,
};

AuditJsonSearch.defaultProps = {
  data: {},
  onCloseHandler: () => {},
  height: 0,
};

export default AuditJsonSearch;
