import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Row } from 'react-flexbox-grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import 'react-select/dist/react-select.css';
import MaterialSelect from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { ColMod } from './lib';
import BASE_URL from "../api/config";
import apiCall, { getFetchAllRegionsPath, getVendors } from "../api/NetworkHandler";
import NavBar from './NavBar';
import SaveBar from './SaveBar';
import { validateEmails } from '../utils/Validators';
import EditVendorLocation from './EditVendorLocation';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select
} from '@material-ui/core';
import { INDIA_STATE_LIST, UAE_CITIES_LIST } from '../constants';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  modal: {
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    height: '80%',
    overflow: 'auto',
    marginTop: window.innerHeight * 0.09,
    marginBottom: window.innerHeight * 0.3,
  }
});

const STATUSES = [{ value: 'ACTIVE', text: 'Active' }, { value: 'INACTIVE', text: 'Inactive' }];

class SimpleModal extends React.Component {
  state = {
    regions: [],
    selectedCity: '',
    selectedCountry: '',
    selectedState: '',
    cities: [],
    temporaryEmailText: '',
    emails: [],
    emailHelperText: 'Please type email and press add button',
    invalidEmail: false,
    vendorLocations: {
      house: '',
      floor: '',
      block: '',
      building: '',
      street: '',
      subLocality: '',
      locality: '',
      landmark: '',
      city: '',
      pincode: '',
      state: '',
      country: '',
      phoneNumber: '',
      email: '',
      contact: null,
      contactMobile: '',
      name: '',
      cutOffTime: null,
      region: `${BASE_URL.BASE_URL}/api/v1/regions/1`,
      regionName: '',
      openRegionSelector: false,
      localPurchase: false,
    },
  }

  componentDidMount() {
    apiCall.sendRequest('get', getFetchAllRegionsPath(''))
      .then((response) => {
        if (response.data && response.data._embedded && response.data._embedded.regions) {
          this.setState({
            cities: response.data._embedded.regions,
          });
        }
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ regions: selectedOption });
  }

  handleRegionChange = (event) => {
    const {value} = event.target;
    this.setState(prevState => ({
      selectedCity: value,
      vendorLocations: {
        ...prevState.vendorLocations,
        region: `${BASE_URL.BASE_URL}/api/v1/regions/${value}`,
      },
    }));
  };

  handleCountryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      selectedCountry: value,
      vendorLocations: {
        ...prevState.vendorLocations,
        country: value,
      },
    }));
  };

  handleStateChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      selectedState: value,
      vendorLocations: {
        ...prevState.vendorLocations,
        state: value,
      },
    }));
  };

  handleSelectCheckBox = (event, name) => {
    const { vendorLocations } = this.state;
    if (name === 'localPurchase') {
      const { checked } = event.target;
      vendorLocations[name] = checked;
      this.setState({
        vendorLocations,
      });
    }
  };

  validateFields = (data) => {
    let isFieldValid = Object.keys(data).some(el => !data['country'] || !data['state']);
    return isFieldValid;
  };

  handleLocationAdd = () => {
    const { vendorLocations } = this.state;
    if (this.validateFields(vendorLocations)) {
      return;
    }
    const { onClose, handleAddVendorLocation } = this.props;
    handleAddVendorLocation(vendorLocations);
    onClose();
    this.setState({ emails: [] });
  }

  handleEmailChange = (event) => {
    const { value } = event.target;
    this.setState({ temporaryEmailText: value.trim().toLowerCase() });
  };

  handleEmailAdd = () => {
    let { temporaryEmailText, emails, vendorLocations } = this.state;

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(temporaryEmailText)) {
      this.setState({ emailHelperText: 'Invalid email', invalidEmail: true });
      return;
    }

    if (emails.includes(temporaryEmailText.toLowerCase())) {
      this.setState({ 
        duplicateEmailText: "Duplicate email",
        temporaryEmailText: '',
      });
      return;
    }
    emails = [...emails, temporaryEmailText];
    vendorLocations = {
      ...vendorLocations,
      email: emails.join(),
    }
    this.setState({ 
      emails,
      temporaryEmailText: '',
      vendorLocations,
      emailHelperText: 'Please type email and press add button',
      invalidEmail: false,
      duplicateEmailText: '',
    });
  };

  handleEmailDelete = (email) => {
    let { emails, vendorLocations } = this.state;
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    vendorLocations = {
      ...vendorLocations,
      email: emails.join(),
    }
    this.setState({ emails, vendorLocations });
  };

  handleCloseForm = () => {
    this.setState({
      selectedCity: '',
      selectedCountry: '',
      selectedState: '',
      vendorLocation: {},
      emails: [],
    });
    const { onClose } = this.props;
    onClose()
  };


  render() {
    const { classes } = this.props;
    const { emails = [], temporaryEmailText, emailHelperText, invalidEmail, duplicateEmailText, selectedCountry } = this.state;
    const newEmails = emails.filter(el => el);
    const stateOrCityList = selectedCountry === 'India' ? INDIA_STATE_LIST : UAE_CITIES_LIST;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.handleCloseForm}
        >
          <div className={classes.modal}>
            <Button style={{ float: 'right' }} onClick={this.handleCloseForm} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="title" middle="ld">
                        New Vendor Location
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Name"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  name: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Status"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  active: value.toLowerCase() === 'active',
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <InputLabel style={{ fontSize: '0.9em' }} htmlFor="select-multiple-chip">Region</InputLabel>
                      <MaterialSelect
                        value={this.state.selectedCity}
                        onChange={this.handleRegionChange}
                        inputProps={{
                            name: 'selectedCity',
                            id: 'city-type',
                            }}
                        fullWidth
                      >
                        {this.state.cities.map((city, index) => (
                          <MenuItem
                            key={index}
                            value={city.id}
                          >
                            {city.name}
                          </MenuItem>
                            ))}
                      </MaterialSelect>
                    </ColMod>
                  </Row>

                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Building"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  building: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="House No"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  house: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Floor"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  floor: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                  </Row>

                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Street"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  street: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Locality"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  locality: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Sub Locality"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  subLocality: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                  </Row>

                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Landmark"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  landmark: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="City"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  city: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Pincode"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  pincode: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                  </Row>

                  <Row>
                    <ColMod zerobottom lg={4}>
                      <FormControl fullWidth>
                        <InputLabel> Country </InputLabel>
                        <Select
                          name="country"
                          value={this.state.selectedCountry}
                          onChange={this.handleCountryChange}
                          fullWidth
                          error={!this.state.selectedCountry}
                        >
                          {['India', 'UAE'].map((country, index) => (<MenuItem key={index} value={country}> {country} </MenuItem>))}
                        </Select>
                        <FormHelperText error>{!this.state.selectedCountry && '*required'}</FormHelperText>
                      </FormControl>
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <FormControl fullWidth>
                        <InputLabel> State </InputLabel>
                        <Select
                          value={this.state.selectedState}
                          onChange={this.handleStateChange}
                          disabled={!selectedCountry}
                          fullWidth
                          error={!this.state.selectedState}
                        >
                          {stateOrCityList.map((state, index) => (<MenuItem key={index} value={state}> {state} </MenuItem>))}
                        </Select>
                        <FormHelperText error>{!this.state.selectedState && '*required'}</FormHelperText>
                      </FormControl>
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Mobile"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  phoneNumber: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                  </Row>


                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Contact"
                        fullWidth
                        onChange={(event) => {
                            const {value} = event.target;
                            this.setState(prevState => ({
                                vendorLocations: {
                                  ...prevState.vendorLocations,
                                  contact: value,
                                },
                              }));
                          }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        value={temporaryEmailText}
                        label="Email"
                        helperText={emailHelperText}
                        error={invalidEmail}
                        style={{ width: '290px', marginRight: '10px' }}
                        onChange={this.handleEmailChange}
                      />
                      <Button onClick={this.handleEmailAdd} variant="fab" mini color="secondary" aria-label="add">
                        <AddIcon />
                      </Button>
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      
                    </ColMod>
                    {newEmails.length != 0 && 
                    <div>
                      {newEmails.map((e, index) => (
                        <Chip
                          key={index}
                          label={e}
                          variant="outlined"
                          color="primary"
                          style={{ marginRight: '10px', marginBottom: '10px' }}
                          onDelete={() => { this.handleEmailDelete(e) }}
                        />
                      ))}
                    </div>}
                    {duplicateEmailText && <span style={{ color: 'red' }}>{duplicateEmailText}</span>}
                  </Row>
                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="CIN"
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            vendorLocations: {
                              ...prevState.vendorLocations,
                              cin: value,
                            },
                          }));
                        }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="GSTIN"
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            vendorLocations: {
                              ...prevState.vendorLocations,
                              gstin: value,
                            },
                          }));
                        }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Pan"
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            vendorLocations: {
                              ...prevState.vendorLocations,
                              pan: value,
                            },
                          }));
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod zerobottom lg={4}>
                      <TextField
                        label="Company Name"
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            vendorLocations: {
                              ...prevState.vendorLocations,
                              companyName: value,
                            },
                          }));
                        }}
                      />
                    </ColMod>
                    <ColMod zerobottom lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.vendorLocations.localPurchase == true}
                            value={this.state.vendorLocations.localPurchase}
                            onChange={event => this.handleSelectCheckBox(event, 'localPurchase')}
                            color="secondary"
                            />
                          }
                        label="Local Purchase"
                      />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
              <Row lg={12} end="lg">
                <ColMod>
                  <Button variant="outlined" onClick={this.handleCloseForm} color="secondary">
                    Cancel
                  </Button>
                </ColMod>
                <ColMod>
                  <Button
                    onClick={this.handleLocationAdd}
                    variant="contained"
                    color="primary"
                  >
                    ADD
              </Button>
                </ColMod>
              </Row>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

class DashboardComponent extends Component {
  state = {
    open: false,
    status: STATUSES[0].value,
    vendor: {},
    openLocationEdit: false,
    editLocationId: null,
    editLocationIndex: null,
    selectedLocation: {},
  };

  componentDidMount() {
    this.setState({
      vendor: this.props.data,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data !== this.state.vendor) {
        this.setState({
          vendor: nextProps.data,
        });
      }
    }
  }

  handleDelete = (name) => {
    let vendorLocationIndex = null;
    vendorLocationIndex = this.state.vendor.vendorLocations.findIndex(vendorLocation => vendorLocation.name === name);
    if (vendorLocationIndex !== -1) {
      this.setState(prevState => ({
        vendor: {
          ...prevState.vendor,
          vendorLocations: [
            ...prevState.vendor.vendorLocations.slice(0, vendorLocationIndex),
            ...prevState.vendor.vendorLocations.slice(vendorLocationIndex + 1),
          ],
        },
      }));
    }
  };

  handleLocationEdit = (vendorLocation, locationIndex) => {
    this.setState({
      openLocationEdit: true,
      editLocationId: vendorLocation.id,
      editLocationIndex: locationIndex,
      selectedLocation: vendorLocation,
    })
  }

  handleLocationEditClose = () => {
    this.setState({
      openLocationEdit: false,
      editLocationId: null,
      editLocationIndex: null,
    })
  }

  updateVendorLocation = (vendorLocation) => {
    const { editLocationIndex: index } = this.state;
    this.setState((prevState)=>{
      const { vendorLocations } = prevState.vendor;
      vendorLocations[index] = vendorLocation;
      return{
        vendor: {
          ...prevState.vendor,
        }
      }
    })
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    const {name} = event.target;
    const {value} = event.target;
    if (name === 'status') {
      this.setState(prevState => ({
        vendor: {
          ...prevState.vendor,
          active: value === 'ACTIVE',
        },
      }));
    } else {
      this.setState(prevState => ({
        vendor: {
          ...prevState.vendor,
          [event.target.name]: event.target.value,
        },
      }));
    }
  }

  handleAddVendorLocation = (vendorLocation) => {
    this.setState(prevState => ({
      vendor: {
        ...prevState.vendor,
        vendorLocations: [
          ...prevState.vendor.vendorLocations,
          vendorLocation,
        ],
      },
    }));
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handleSave = () => {
    // apiCall.sendRequest('post', `/api/v1/vendors`, this.state.vendor)
    apiCall.sendRequest('post', getVendors, this.state.vendor)
      .then((response) => {
        console.log(response.data);
        this.goBack();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <NavBar />
        <SaveBar goBack={this.goBack} handleSave={this.handleSave} header="Edit Vendor" />
        <Grid>
          <ColMod xs md lg={15}>
            <Row>
              <Typography variant="body2" color="secondary">
                  Vendor Details
              </Typography>
            </Row>
            <Row>
              <ColMod lg={4}>
                <TextField
                  label="Name"
                  fullWidth
                  value={this.state.vendor.name ? this.state.vendor.name : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            name: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="Email"
                  fullWidth
                  value={this.state.vendor.email ? this.state.vendor.email : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            email: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Status</InputLabel>
                <MaterialSelect
                  value={this.state.vendor.active ? 'ACTIVE' : 'INACTIVE'}
                  onChange={this.handleChange}
                  inputProps={{
                      name: 'status',
                      id: 'status-type',
                    }}
                  fullWidth
                >
                  { STATUSES.map((x, i) => (<MenuItem key={i} value={x.value}>{x.text}</MenuItem>)) }
                </MaterialSelect>
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={4}>
                <TextField
                  label="Work Phone"
                  fullWidth
                  value={this.state.vendor.workPhone ? this.state.vendor.workPhone : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            workPhone: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="Mobile"
                  fullWidth
                  value={this.state.vendor.mobile ? this.state.vendor.mobile : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            mobile: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="Fax"
                  fullWidth
                  value={this.state.vendor.fax ? this.state.vendor.fax : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            fax: value,
                          },
                        }));
                    }}
                />
              </ColMod>
            </Row>
            <Row style={{ marginBottom: '30px' }}>
              <ColMod lg={4}>
                <TextField
                  label="Website"
                  fullWidth
                  value={this.state.vendor.website ? this.state.vendor.website : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            website: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="CIN"
                  fullWidth
                  value={this.state.vendor.cin ? this.state.vendor.cin : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            cin: value,
                          },
                        }));
                    }}
                />
              </ColMod>
            </Row>

            <Row>
              <Typography variant="body2" color="secondary">
                    Vendor Location
              </Typography>
            </Row>
            <Row style={{ marginBottom: '30px' }}>
              { this.state.vendor && this.state.vendor.vendorLocations && this.state.vendor.vendorLocations.length ?
                this.state.vendor.vendorLocations.map((vendorLocation, index) => (
                  <Chip
                    key={index}
                    label={vendorLocation.name}
                    style={{ marginRight: '10px' }}
                    onDelete={() => { this.handleDelete(vendorLocation.name); }}
                    onClick={()=>{ this.handleLocationEdit(vendorLocation, index) }}
                    title="Click to edit"
                  />
                ))
                : null
              }
              <Button onClick={this.handleOpen} variant="fab" mini color="secondary" aria-label="add">
                <AddIcon />
              </Button>
            </Row>

            <Row>
              <Typography variant="body2" color="secondary">
                  Payment Details
              </Typography>
            </Row>
            <Row>
              <ColMod lg={4}>
                <TextField
                  label="Bank Name"
                  fullWidth
                  value={this.state.vendor.bankName ? this.state.vendor.bankName : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            bankName: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="Account Number"
                  fullWidth
                  value={this.state.vendor.bankAccountNo ? this.state.vendor.bankAccountNo : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            bankAccountNo: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="IFSC Code"
                  fullWidth
                  value={this.state.vendor.bankIfscCode ? this.state.vendor.bankIfscCode : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            bankIfscCode: value,
                          },
                        }));
                    }}
                />
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={12}>
                <TextField
                  label="Address"
                  fullWidth
                  value={this.state.vendor.bankAddress ? this.state.vendor.bankAddress : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            bankAddress: value,
                          },
                        }));
                    }}
                />
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={4}>
                <TextField
                  label="PAN#"
                  fullWidth
                  value={this.state.vendor.pan ? this.state.vendor.pan : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            pan: value,
                          },
                        }));
                    }}
                />
              </ColMod>
              <ColMod lg={4}>
                <TextField
                  label="GSTIN"
                  fullWidth
                  value={this.state.vendor.gstin ? this.state.vendor.gstin : ''}
                  onChange={(event) => {
                      const {value} = event.target;
                      this.setState(prevState => ({
                          vendor: {
                            ...prevState.vendor,
                            gstin: value,
                          },
                        }));
                    }}
                />
              </ColMod>
            </Row>
          </ColMod>
        </Grid>
        <SimpleModal open={this.state.open} onClose={this.handleClose} handleAddVendorLocation={this.handleAddVendorLocation} {...this.props}/>
        <Modal
          open={this.state.openLocationEdit}
          onClose={this.handleLocationEditClose}
          onBackdropClick={this.handleLocationEditClose}
        >
          <div className={classes.modal}>
            <EditVendorLocation locationId={this.state.editLocationId} vendorLocation={this.state.selectedLocation} updateVendorLocation={this.updateVendorLocation} onClose={this.handleLocationEditClose}/>
          </div>
        </Modal>
      </div>
    );
  }
}


DashboardComponent.propTypes = {
  TextAction: PropTypes.func.isRequired,
  StrictTextAction: PropTypes.func.isRequired,
  NumberAction: PropTypes.func.isRequired,
  TextIsError: PropTypes.bool,
  TextErrorMessage: PropTypes.string,
  NumberIsError: PropTypes.bool,
  NumberMessage: PropTypes.string,
  valueStrictText: PropTypes.string,

};


export default withRouter(withStyles(styles)(DashboardComponent));
