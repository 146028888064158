import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button } from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import moment from 'moment';
import ToolTip from '@material-ui/core/Tooltip';
import { Tag, Tooltip as AntdTooltip } from 'antd';


const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'regions', numeric: false, disablePadding: true, label: 'Regions',
  },
  {
    id: 'entityType', numeric: false, disablePadding: true, label: 'Type',
  },
  {
    id: 'sortId', numeric: false, disablePadding: true, label: 'Sort Id',
  },
  {
    id: 'updatedAt', numeric: false, disablePadding: true, label: 'Last Updated At',
  },
  {
    id: 'displayOptions', numeric: false, disablePadding: true, label: 'Display Options',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Action',
  }
]

class BannerList extends Component {
  
  getCustomComponents = () => {
    const customComponents = {
      status: {},
      regions: {},
      updatedAt: {},
      entityType: {},
      displayOptions: {},
    };
    const { banners } = this.props;

    banners.map((banner, index) => {
      customComponents['status'][index] = this.getStatus(banner);
      customComponents['regions'][index] = banner.bannerRegions.map(region => region.name).join(", ");
      customComponents['updatedAt'][index] = <ToolTip title={moment(banner.timeUpdated).format(PRODUCT_TIME_FORMAT)}><span>{moment(banner.timeUpdated).fromNow()}</span></ToolTip>;
      customComponents['entityType'][index] = banner.entityType;
      customComponents['displayOptions'][index] = banner.bannerScreens.filter((el, i) => i < 2).map(el => (<AntdTooltip key={el.id} title={banner.bannerScreens.map(el => el.displayName).filter(el => el).join(", ")}><Tag key={el.id} color="geekblue">{el.screenType}</Tag></AntdTooltip>))
    })
    return customComponents;
  }

  getStatus = (banner) => {
    const { active, endTime, startTime } = banner;
    let message = '';
    let color = '';
    if (active && moment().isBefore(startTime, 'days')) {
      message = 'Yet to start'
      color = 'inprogress'
    }
    else if (active && !(moment().isBefore(startTime) || moment().isAfter(endTime))) {
      message = 'Ongoing'
      color = 'success'
    }
    else if (active && moment().isAfter(endTime, 'days')) {
      message = 'Completed'
      color = 'moved'
    } else {
      message = 'inactive';
      color = 'removed';
    }
    return <Lozenge appearance={color}>{message}</Lozenge>;
  }

  getActions = (banner) => (
    <ToolTip title="Edit"><Button variant="outlined" color="secondary" onClick={() => { this.onBannerEdit(banner) }}>Edit</Button></ToolTip>
  )

  onBannerEdit = (banner) => {
    const { onEdit } = this.props;
    onEdit(banner);
  }

  render() {
    const {
      banners,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      handleChangeRowsPerPage,
      handleChangePage,
      onRequestSort,
      isNoData,
      isLoading,
      searchTerm,
    } = this.props;
    return (
      <PaginatedTable
        columnData={columnData}
        data={banners}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        customComponents={this.getCustomComponents()}
        isNoData={isNoData}
        isLoading = {isLoading}
        searchTerm={searchTerm}
      />
    )
  }
}

BannerList.propTypes = {
  banners: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  isNoData: PropTypes.bool,
  isLoading: PropTypes.bool,
  searchTerm: PropTypes.string,
}

BannerList.defaultProps = {
  order: 'acs',
  orderBy: '',
  isNoData: false,
  isLoading: false,
  searchTerm: '',
}

export default BannerList;
