import * as ActionType from '../actions';

const initialState = {
  addressStatuses: {},
  colineStatuses: {},
  addressTypes: {},
  catalogStatuses: {},
  adjustmentStates: {},
  complaintReasons: {},
  costatuses: {},
  deliveryPickListStatuses: {},
  deliveryRemarks: {},
  deliveryStatuses: {},
  postatuses: {},
  stockUnavailabilityReasons: {},
  orderPriority: {},
  customerStatuses: {},
  grnStatuses: {},
  deliveryBoyIdCardType: {},
  segregationStatuses: {},
  genderTypes: {},
  maritalStatuses: {},
  ticketDispositionTypes: {},
  segregatorRoles: {},

};

const status = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ActionType.GET_ALL_ENUM:
      return {
        ...state,
        ...payload,
        addressStatuses: payload.addressStatuses,
        colineStatuses: payload.colineStatuses,
        addressTypes: payload.addressTypes,
        catalogStatuses: payload.catalogStatuses,
        adjustmentStates: payload.adjustmentStates,
        complaintReasons: payload.complaintReasons,
        costatuses: payload.costatuses,
        deliveryPickListStatuses: payload.deliveryPickListStatuses,
        deliveryRemarks: payload.deliveryRemarks,
        deliveryStatuses: payload.deliveryStatuses,
        postatuses: payload.postatuses,
        stockUnavailabilityReasons: payload.stockUnavailabilityReasons,
        orderPriority: payload.orderPriority,
        customerStatuses: payload.customerStatuses,
        grnStatuses: payload.grnstatuses,
        offerTypes: payload.offerTypes,
        payBackType: payload.payBackType,
        payBackSubType: payload.paybackSubType,
        offerSubType: payload.offerSubType,
        offerAppliedFor: payload.offerAppliedFor,
        offerFrequency: payload.offerFrequency,
        deliveryBoyIdCardType: payload.deliveryBoyIdCardType,
        segregationStatuses: payload.segregationStatuses,
        genderTypes: payload.genderTypes,
        maritalStatuses: payload.maritalStatuses,
        ticketDispositionTypes: payload.ticketDispositionTypes,
        segregatorRoles: payload.segregatorRoles,
      };
    default:
      return state;
  }
};


export default status;

