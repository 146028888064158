import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import Drawer from '@material-ui/core/Drawer';
import apiCall, { getDummyRouteMappings, searchDummyRouteMappings } from '../../../api/NetworkHandler';
import RouteMappingSearch from '../components/RouteMappingSearch';
import DefaultRouteMappingList from '../components/DefaultRouteMappingList';
import DefaultRouteMappingForm from '../components/DefaultRouteMappingForm';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import DeleteDialog from '../components/DeleteDialog';

const styles = {
  container: {
    margin: 0,
  }
}

class LocalityRouteMappingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        locality: '',
        subLocality: '',
        pincode: '',
        staticRouteName: ''
      },
      routeMappings: [],
      rowsPerPage: 10,
      page: 0,
      count: 0,
      openRouteMappingForm: false,
      editRouteMapping: {},
      isLoading: false,
      selectedRoute: null,
      isIndia: true,
      openDeleteDialog: false,
      deleteId: null
    }
  }

  componentDidMount() {
    this.fetchRoutemappings();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currency } = nextProps;
    if (isEmpty(currency)) {
      return {
        ...prevState
      }
    } else {
      if (currency == "AED") {
        return {
          isIndia: false
        }
      } else {
        return {
          isIndia: true,
        }
      }
    }
  }


  fetchRoutemappings = async () => {
    this.toggleProgressBar();
    try {
      const { page, rowsPerPage: size } = this.state;
      let response;
      if (this.isSearch()) {
        const queryParams = this.getQueryparams();
        response = await apiCall.sendRequest('get', searchDummyRouteMappings(page, size, queryParams))
      } else {
        response = await apiCall.sendRequest('get', getDummyRouteMappings(page, size));
      }
      const { data: { data: { content, totalElements } } } = response;
      const routeMappings = sortBy(content, "locality");

      this.setState({
        routeMappings,
        count: totalElements,
      });
      this.toggleProgressBar();
    } catch (error) {
      this.toggleProgressBar();
    }
  }

  isSearch = () => {
    const { filter } = this.state;
    let isSearch = false;
    for (const property in filter) {
      if (filter[property]) {
        isSearch = true;
        break;
      }
    }
    return isSearch;
  }

  getQueryparams = () => {
    const { filter } = this.state;

    let queryParans = '';

    for (const property in filter) {
      if (filter[property]) {
        queryParans += `&${property}=${filter[property]}`
      }
    }
    return queryParans;
  }

  onAddNew = () => {
    this.setState({ openRouteMappingForm: true })
  }

  onSearch = debounce(() => {
    this.fetchRoutemappings()
  }, 400)

  onChangeSearch = ({ target: { name, value } }) => {
    this.setState(prevState => {
      const { filter } = prevState;

      if(name == "pincode"){
        if(isNaN(value)){
          value = filter.pincode;
        }
        if(value.length > 6){
          value = value.slice(0, 6);
        }
      }

      return {
        filter: {
          ...filter,
          [name]: value
        },
        page: 0
      }
    });
    this.onSearch();
  }

  onClearSearch = () => {
    this.setState({
      filter: {
        locality: '',
        subLocality: '',
        pincode: '',
        staticRouteName: ''
      },
      selectedRoute: null,
    }, () => { this.fetchRoutemappings() });
  }

  onEditMapping = (mapping) => {
    this.setState({ openRouteMappingForm: true, editRouteMapping: mapping })
  }

  onClose = () => {
    this.setState({ openRouteMappingForm: false, editRouteMapping: {} })
  }

  toggleProgressBar = () => {
    this.setState(prevState => {
      const { isLoading } = prevState;

      return {
        isLoading: !isLoading,
      }
    })
  }

  onRouteChange = (selectedRoute) => {
    this.setState({ selectedRoute });
    const event = {
      target: {
        name: "staticRouteName",
        value: selectedRoute ? selectedRoute.label : '',
      }
    }
    this.onChangeSearch(event);
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchRoutemappings() });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.fetchRoutemappings() });
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      deleteId: null
    })
  }

  openDeleteDialog = (dummyRouteId) => {
    this.setState({
      openDeleteDialog: true,
      deleteId: dummyRouteId
    })
  }

  render() {
    const {
      filter,
      routeMappings,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      editRouteMapping,
      openRouteMappingForm,
      isLoading,
      selectedRoute,
      isIndia,
      openDeleteDialog,
      deleteId
    } = this.state;

    return (
      <React.Fragment>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <RouteMappingSearch
          onAddNew={this.onAddNew}
          onChangeSearch={this.onChangeSearch}
          filter={filter}
          onClearSearch={this.onClearSearch}
          selectedRoute={selectedRoute}
          onRouteChange={this.onRouteChange}
          showPincode={isIndia}
        />
        {!isLoading && <DefaultRouteMappingList
          routeMappings={routeMappings}
          rowsPerPage={rowsPerPage}
          page={page}
          order={order}
          orderBy={orderBy}
          count={count}
          onEdit={this.onEditMapping}
          showPincode={isIndia}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.openDeleteDialog}
        />}
        <Drawer anchor="right" open={openRouteMappingForm} onClose={this.onClose}>
          <DefaultRouteMappingForm
            onClose={this.onClose}
            onRefresh={this.fetchRoutemappings}
            routeMapping={editRouteMapping}
            showPincode={isIndia}
          />
        </Drawer>
        <DeleteDialog
          open={openDeleteDialog}
          onClose={this.closeDeleteDialog}
          dummyRouteId={deleteId}
          onRefresh={this.fetchRoutemappings}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { currency } = state;
  return {
    currency
  };
};

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(LocalityRouteMappingContainer)));
