import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import {
  Typography,
  TextField
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Colors from '../../../utils/Colors';
import { Button, Input as AntdInput } from 'antd';
import { DownloadOutlined, CloudUploadOutlined, HomeOutlined, SearchOutlined } from '@ant-design/icons';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  }
}

class VendorProductItemSearch extends Component {
  constructor(props) {
    super(props);
    this.inputCSVFileRef = [];
  }

  handleUploadStockInfo = (event, id) => {
    const { handleUpload } = this.props;
    let selectedFile = event.target.files[0];
    handleUpload(selectedFile);
    this.inputCSVFileRef[id].value = '';
  }

  render() {
    const {
      classes,
      searchTerm,
      onChange,
      selectedWarehouse,
      clearSearch,
      handleDownload,
      disableButton = false,
      onClickSearch
    } = this.props;
    const { id = null } = selectedWarehouse;
    return (
      <div>
        <Grid fluid className={classes.container}>
          <Typography variant="caption" color="inherit" style={{ paddingTop: "10px", fontSize: "14px", fontWeight: "bold", textAlign: "right" }}><HomeOutlined /> {selectedWarehouse.label}</Typography>
          <Row xs={12} middle="xs" between="xs">
            <Col xs={2}>
              <Typography variant="title" color="inherit">Vendor Stock Ledger</Typography>
            </Col>
            <Col xs={10}>
              <Row xs={12} middle="xs" between="md">
                <ColMod md={4} custompadding="true">
                  <AntdInput
                    placeholder="type to search..."
                    allowClear
                    size="large"
                    onChange={onChange}
                    onPressEnter={onClickSearch}
                    style={{ width: window.innerWidth * 0.25 }}
                    prefix={<SearchOutlined />}
                  />
                </ColMod>
                <ColMod md={2} custompadding="true">
                  <Button size="large" type="secondary" onClick={onClickSearch}> {' '}Search{' '} </Button>
                </ColMod>
                <ColMod md={2} custompadding="true">
                  <Button size="large" type="secondary" danger onClick={clearSearch}> {' '}Clear{' '} </Button>
                </ColMod>
                <ColMod md={2} custompadding="true">
                  <Button type="primary" icon={<DownloadOutlined />} size="large" onClick={handleDownload} disabled={disableButton}> Download </Button>
                </ColMod>
                <ColMod md={2} custompadding="true">
                  <input ref={ref => this.inputCSVFileRef[id] = ref} type="file" accept=".csv" style={{ display: 'none' }} onChange={(e) => this.handleUploadStockInfo(e, id)} />
                  <Button type="primary" icon={<CloudUploadOutlined />} size="large" onClick={() => this.inputCSVFileRef[id].click()} disabled={disableButton}>
                    Upload{' '}
                  </Button>
                </ColMod>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}


VendorProductItemSearch.propTypes = {
  onChange: PropTypes.func,
  selectedWarehouse: PropTypes.object,
  onAddNew: PropTypes.func,
  searchTerm: PropTypes.string,
}

VendorProductItemSearch.defaultProps = {
  searchTerm: '',
  selectedWarehouse: null,
}

export default withStyles(styles)(VendorProductItemSearch);
