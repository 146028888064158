import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import apiCall, { postDummyRoutePath, getDummyRouteById } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import DummyRouteSelector from '../../../components/StaticRoute/DummyRoute/Selector';
import isEmpty from 'lodash/isEmpty';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  }
}

class DefaultRouteMappingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: {
        locality: '',
        subLocality: '',
        pincode: '',
        staticRouteId: '',
      },
      error: {},
      errorMessage: '',
      selectedRoute: null,
      pincodeErrorText: ''
    }
  }

  componentDidMount() {
    const { routeMapping } = this.props;
    if (!isEmpty(routeMapping)) {
      this.setState({
        postData: routeMapping,
        selectedRoute: {
          label: routeMapping.staticRouteName,
          value: routeMapping.staticRouteId,
        }
      })
    }
  }

  onChange = (event) => {
    let { name, value } = event.target;
    this.setState(prevState => {
      const { postData } = prevState;
      if(name == "pincode"){
        if(isNaN(value)){
          value = postData.pincode;
        }
        value = Math.abs(value);
      }
      return {
        postData: {
          ...postData,
          [name]: value,
        }
      }
    })
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  isPropertyValid = (property, value) => {
    value = (value+"").trim();
    if (!value) {
      if (property == 'pincode') {
        this.setState({ pincodeErrorText: 'Please enter 6 digit pincode' })
      }
      this.setError(property, true);
      return false;
    } else if (property == 'pincode') {
      if (value.length !== 6) {
        this.setState({ pincodeErrorText: 'Please enter 6 digit pincode' })
        this.setError(property, true);
        return false;
      }
      this.setState({ pincodeErrorText: '' });
    }

    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;
    const { postData } = this.state;

    const validateProperties = ["locality", "subLocality", "staticRouteId"];

    if (this.props.showPincode) {
      validateProperties.push("pincode");
    }

    validateProperties.forEach(property => {
      if (!this.isPropertyValid(property, postData[property])) {
        isFormValid = false;
      }
    })

    return isFormValid;
  }

  onSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { postData } = this.state;
    const { onClose, onRefresh } = this.props;

    try {
      if (!postData.id) {
        await apiCall.sendRequest("post", postDummyRoutePath, postData);
      } else {
        await apiCall.sendRequest("put", getDummyRouteById(postData.id), postData);
      }
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }
  }

  onRouteChange = (selectedRoute) => {
    const { postData } = this.state;
    this.setState({
      selectedRoute,
      postData: {
        ...postData,
        staticRouteId: selectedRoute ? selectedRoute.id : '',
      }
    });
  }

  render() {
    const { classes, onClose, showPincode } = this.props;
    const { postData, error, errorMessage, selectedRoute, pincodeErrorText } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Dummy Route Mapping</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
                <Button type="submit" variant="contained" color="secondary" onClick={this.onSave}>{postData.id ? "Save" : "Add"}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            <SectionMessage appearance="error">{errorMessage}</SectionMessage>
          </ColMod>
        </Row>}
        <Row md={12}>
          <ColMod md={6}>
            <Typography variant="caption" color={error.staticRouteId ? 'error' : 'default'}>Route *</Typography>
            <DummyRouteSelector
              selected={selectedRoute}
              onSelect={this.onRouteChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              label="Locality"
              name="locality"
              value={postData.locality || ''}
              onChange={this.onChange}
              required
              fullWidth
              error={error.locality}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              label="Sub Locality"
              name="subLocality"
              value={postData.subLocality || ''}
              onChange={this.onChange}
              required
              fullWidth
              error={error.subLocality}
            />
          </ColMod>
          {showPincode && <ColMod md={6}>
            <TextField
              label="Pincode"
              name="pincode"
              value={postData.pincode || ''}
              onChange={this.onChange}
              required
              fullWidth
              error={error.pincode}
              helperText={pincodeErrorText}
            />
          </ColMod>}
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(DefaultRouteMappingForm);
