import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Button,
    Grid,
    Modal,
    Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import { debounce, isEmpty } from 'lodash';
import NoData from '../../../components/States/NoData';

import AddIcon from '@material-ui/icons/Add';
import apiCall, { getHistory } from '../../../api/NetworkHandler';

class PaymentSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentSummary: {},
            show: false,
            showClosed: false,
            viewSummary: false,

        }
    }
    componentDidMount() {
        this.getPaymemtSummary();

    }
    getPaymemtSummary = debounce(async () => {
        const { customerId } = this.props;

        const reqBody = {
            "methodName": 'TransactionSummary',
            "params": JSON.stringify(
                {
                    'customerId': customerId,
                }
            )
        };
        try {
            const response = await apiCall.sendRequest('post', getHistory(), reqBody);
            const { data: { data = {} } = {} } = response;
            this.setState({
                paymentSummary: data,
            });
        }
        catch (error) {
            console.error(error);
            this.setState({
                paymentSummary: {},
            })
        }
    }, 500)


    toSentenceCase = (str) => {
        const result = str.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }
    render() {
        const { showPaymentSummary = false, handleClosePaymentSummary, percentageCashbackEnabled, currency } = this.props;
        const { paymentSummary = {}, viewSummary, show, showClosed } = this.state;
        const { walletBalanceStats = {}, paymentStats = {}, openOrderStats = {}, closedOrderStats = {} } = paymentSummary;
        const { balanceAmount, rechargeAmount, cashbackAmount } = walletBalanceStats;
        const { totalAmount, totalRecharge, totalCashback } = paymentStats;
        const { count, orderAmount, debitedAmount, debitedRecharge, debitedCashback } = openOrderStats;
        const { count: closedCount, debitedAmount: closedDebitedAmount, debitedCashback: closedDebitedCashback, debitedRecharge: closedDebitedRecharge,
            orderAmount: closedOrderAmount } = closedOrderStats;
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onClose={() => { handleClosePaymentSummary() }}
                open={showPaymentSummary}
            >
                <div style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'white',
                    width: '40%',
                    margin: '0 auto',
                    minHeight: '50vh',
                    overflowX: 'auto',
                    overflowY: 'auto',
                    borderRadius: '5px',
                    maxHeight: "95vh",
                }}
                >
                    <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", width: "100%", top: 0, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ paddingLeft: window.innerWidth * 0.02, alignItems: "center" }}>
                            <Typography variant="title" color="inherit"> Payment Summary </Typography>
                        </div>
                        <Button style={{
                            height: '20px',
                            minWidth: '20px',
                            width: "20px",
                            minHeight: "20px",
                        }} onClick={() => { handleClosePaymentSummary() }} color="secondary" aria-label="close" >
                            <CloseIcon />
                        </Button>
                    </div>

                    <Grid style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        {!isEmpty(paymentSummary) &&
                            <Row>
                                <ColMod xs md lg={15}>
                                    <Row>
                                        <ColMod xs md lg={15}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", paddingTop: "10px" }}>
                                                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Wallet Balance</div>
                                                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "19px" }}>{currency} {balanceAmount}
                                                    <div style={{ margin: "0 auto", flexWrap: "wrap", justifyContent: "center", textAlign: "center", fontSize: "12px", color: "gray", display: "flex" }}>
                                                        (
                                                        <div style={{ color: "#f50057", paddingLeft: "2px", paddingRight: "2px" }}> Payment Total Amount </div>
                                                        - (
                                                        <div style={{ color: "#00875A", paddingLeft: "2px", paddingRight: "2px" }}> Closed Order Debited Amount </div>
                                                        +
                                                        <div style={{ color: "#3f51b5", paddingLeft: "2px", paddingRight: "2px" }}> Open Order Debited Amount </div>
                                                        ) )</div>
                                                    {percentageCashbackEnabled && <div style={{ fontSize: "12px", color: "gray" }}> {`Recharge Amount : ${currency} ${rechargeAmount}`}</div>}
                                                    {percentageCashbackEnabled && <div style={{ fontSize: "12px", color: "gray" }}> {`Cashback Amount : ${currency} ${cashbackAmount}`}</div>}
                                                </div>
                                            </div>
                                            <Paper style={{ display: "flex", flexDirection: "row", backgroundColor: 'lightgray', paddingLeft: "5px", paddingRight: "5px", marginTop: "10px", justifyContent: "space-between", height: "25px" }}>
                                                <Typography variant="body2" color="inherit">Summary Details</Typography>
                                                <Button style={{
                                                    height: '20px',
                                                    minWidth: '20px',
                                                    width: "20px",
                                                    minHeight: "20px",
                                                    margin: '0 5px',
                                                    marginTop: "2px",
                                                }} onClick={() => { this.setState({ viewSummary: !viewSummary }) }} variant="fab" aria-label="close">
                                                    {!viewSummary ? <AddIcon /> : <div style={{ fontSize: '15px', fontWeight: "bold" }}>- </div>}
                                                </Button>
                                            </Paper>

                                            {viewSummary && <React.Fragment>
                                                <div style={{ marginTop: "20px", overflowX: 'auto', }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        paddingLeft: "10px",
                                                        padding: "5px",
                                                        flexWrap: "wrap",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        borderBottom: "1px solid lightgray",

                                                    }}>
                                                        <div style={{ flex: "100%", fontWeight: "bold" }}>
                                                            Payment Order
                                                        </div>
                                                        <div style={{ flex: "100%" }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Total Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px", color: "#f50057" }}> {currency} {totalAmount}</div>
                                                            </div>
                                                            {percentageCashbackEnabled && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Total Recharge Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {totalRecharge}</div>
                                                            </div>}
                                                            {percentageCashbackEnabled && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Total Cashback :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {totalCashback}</div>
                                                            </div>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div style={{
                                                    marginTop: "20px", minHeight: "115px", overflowX: 'auto',
                                                    overflowY: 'auto',
                                                }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        paddingLeft: "10px",
                                                        padding: "5px",
                                                        flexWrap: "wrap",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        borderBottom: "1px solid lightgray",
                                                        backgroundColor: "#fcf6f2",

                                                    }}>
                                                        <div style={{ flex: "100%", fontWeight: "bold" }}>  Open Order
                                                        </div>
                                                        <div style={{ flex: "100%" }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Count :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {count}</div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Order Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {orderAmount}</div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Debit Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px", marginRight: "10px", color: "#3f51b5" }}> {currency} {debitedAmount}</div>
                                                                {!show && percentageCashbackEnabled && <Button style={{
                                                                    height: '20px',
                                                                    minWidth: '20px',
                                                                    width: "20px",
                                                                    minHeight: "20px",
                                                                    margin: '0 5px',
                                                                }} onClick={() => { this.setState({ show: !show }) }} variant="fab" aria-label="add">
                                                                    <AddIcon style={{ fontSize: '15px' }} />
                                                                </Button>}
                                                                {show && percentageCashbackEnabled && <Button style={{
                                                                    height: '20px',
                                                                    minWidth: '20px',
                                                                    width: "20px",
                                                                    minHeight: "20px",
                                                                    margin: '0 5px',
                                                                }} onClick={() => { this.setState({ show: !show }) }} variant="fab" aria-label="add">
                                                                    <div style={{ fontSize: '15px', fontWeight: "bold" }}>- </div>
                                                                </Button>}

                                                            </div>
                                                            {show && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "10px", fontSize: "13px", color: "gray" }}>Debited Recharge :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {debitedRecharge}</div>
                                                            </div>}
                                                            {show && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "10px", fontSize: "13px", color: "gray" }}>Debited Cashback :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {debitedCashback}</div>
                                                            </div>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                <div style={{
                                                    marginTop: "20px", minHeight: "115px", overflowX: 'auto',
                                                    overflowY: 'auto',
                                                }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        paddingLeft: "10px",
                                                        padding: "5px",
                                                        flexWrap: "wrap",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        borderBottom: "1px solid lightgray",
                                                        backgroundColor: "#f0fceb",

                                                    }}>
                                                        <div style={{ flex: "100%", fontWeight: "bold" }}>  Closed Order
                                                        </div>
                                                        <div style={{ flex: "100%" }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Count :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {closedCount}</div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Order Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {closedOrderAmount}</div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "5px" }}>Debit Amount :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px", marginRight: "10px", color: "#00875A" }}> {currency} {closedDebitedAmount}</div>
                                                                {!showClosed && percentageCashbackEnabled && <Button style={{
                                                                    height: '20px',
                                                                    minWidth: '20px',
                                                                    width: "20px",
                                                                    minHeight: "20px",
                                                                    margin: '0 5px',
                                                                }} onClick={() => { this.setState({ showClosed: !showClosed }) }} variant="fab" aria-label="add">
                                                                    <AddIcon style={{ fontSize: '15px' }} />
                                                                </Button>}
                                                                {showClosed && percentageCashbackEnabled && <Button style={{
                                                                    height: '20px',
                                                                    minWidth: '20px',
                                                                    width: "20px",
                                                                    minHeight: "20px",
                                                                    margin: '0 5px',
                                                                }} onClick={() => { this.setState({ showClosed: !showClosed }) }} variant="fab" aria-label="add">
                                                                    <div style={{ fontSize: '15px', fontWeight: "bold" }}>- </div>
                                                                </Button>}</div>
                                                            {showClosed && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "10px", fontSize: "13px", color: "gray" }}>Debited Recharge :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {closedDebitedRecharge}</div>
                                                            </div>}
                                                            {showClosed && <div style={{ display: 'flex', flexDirection: 'row', padding: "3px", paddingLeft: "10px", fontSize: "13px", color: "gray" }}>Debited Cashback :
                                                                <div style={{ fontWeight: 'bold', paddingLeft: "2px" }}> {currency} {closedDebitedCashback}</div>
                                                            </div>
                                                            }
                                                        </div>


                                                    </div>

                                                </div>

                                            </React.Fragment>}
                                        </ColMod>
                                    </Row>


                                </ColMod>
                            </Row>}
                        {isEmpty(paymentSummary) && <NoData searchTerm={''} />}
                    </Grid>
                </div >
            </Modal >

        )
    }
}
PaymentSummary.propTypes = {
    showPaymentSummary: PropTypes.bool,
    handleClosePaymentSummary: PropTypes.func,
    customerId: PropTypes.string,
    percentageCashbackEnabled: PropTypes.bool,
    currency: PropTypes.string,
}
export default PaymentSummary