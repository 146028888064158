import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { TextField, Select, Typography, InputAdornment } from '@material-ui/core/';
import Search from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import RegionSelector from '../../../components/Region/Selector';
import Colors from '../../../utils/Colors';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  },
};

function DealSearch(props) {
  const {
    classes,
    onAddNew,
    onRegionChange,
    region,
    query,
    handleQueryChange,
    OnSearch,
    productStatusTypes,
    currentStatus,
    onStatusChange,
  } = props;

  return (
    <Grid fluid className={classes.container}>
      <Row md={12} middle="md">
        <Col md={2}>
          <Typography variant="title" color="inherit">
            Deal Of The Day
          </Typography>
        </Col>
        <Col md={10}>
          <Row md={12} middle="md">
            <Col md={10}>
              <Row md={12} bottom="md">
              <ColMod md={3}>
                  <TextField
                      id="input-with-icon-textfield"
                      label="Search Product Item"
                      fullWidth
                      value={query || ''}
                      onChange={handleQueryChange}
                      // onKeyPress={onSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                </ColMod>
                <ColMod md={3}>
                <Typography variant="caption"> Status </Typography>
                <Select
                  style={{ marginTop: 16 }}
                  native
                  fullWidth
                  label="Status"
                  value={currentStatus}
                  onChange={e => onStatusChange(e.target.value)}
                >
                  {Object.keys(productStatusTypes).map(status => (
                    <option value={status}>{status}</option>
                    ))}
                </Select>
                </ColMod>
                <ColMod md={3}>
                  <Typography variant="caption"> Region </Typography>
                  <RegionSelector
                    onSelect={onRegionChange}
                    selected={region}
                    isClearable={false}
                  />
                </ColMod>
                <ColMod md={3}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={OnSearch}
                  >
                    Search
              </Button>
                </ColMod>
              </Row>
            </Col>
            <Col md={2}>
              <Button color="secondary" variant="contained" onClick={onAddNew}><AddIcon /> Add Product </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  )
}

DealSearch.propTypes = {
  onRegionChange: PropTypes.func.isRequired,
  region: PropTypes.arrayOf(PropTypes.object),
  onAddNew: PropTypes.func.isRequired,
  query: PropTypes.string,
  handleQueryChange: PropTypes.func.isRequired,
  OnSearch: PropTypes.func.isRequired,
  productStatusTypes: PropTypes.arrayOf(PropTypes.object),
  currentStatus: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
};

DealSearch.defaultProps = {
  region: null,
  query: '',
  currentStatus: 'ALL',
};

export default withStyles(styles)(DealSearch);
