import React from 'react';
import MainComponent from '../components/StockInventory';

class App extends React.Component {
  static propTypes ={

  }

  render() {
    return (
      <div>
        <MainComponent />
      </div>
    );
  }
}


export default (App);

