import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
import apiCall, { allBrandsPathImages, vendorProductMappingPath } from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import TableHeader from '../../Commons/components/TableHeader';
import ProductItemForm from './ProductItemForm';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { Tooltip } from 'antd';


const columnData = [
  {
    id: 'Brand', numeric: false, disablePadding: true, label: 'Brand',
  },
  {
    id: 'Name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'DisplayName', numeric: false, disablePadding: true, label: 'Display Name',
  },
  {
    id: 'Category', numeric: false, disablePadding: true, label: 'Category',
  },
  {
    id: 'Description', numeric: false, disablePadding: true, label: 'Description',
  },
  {
    id: 'Tags', numeric: false, disablePadding: true, label: 'Tags',
  },
  {
    id: 'Images', numeric: false, disablePadding: true, label: 'Images',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const columnData2 = [
  // {
  //   id: 'id', numeric: false, disablePadding: true, label: 'Product Id',
  // },
  {
    id: 'Name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'Unit_Price', numeric: false, disablePadding: true, label: 'Unit Price',
  },
  {
    id: 'Selling_Price', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  {
    id: 'Discount', numeric: false, disablePadding: true, label: 'Discount(%)',
  },
  {
    id: 'CGST', numeric: false, disablePadding: true, label: 'CGST',
  },
  {
    id: 'Images', numeric: false, disablePadding: true, label: 'Images',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      selectedProductItem: {},
      // page: 0,
      // rowsPerPage: 10,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      page2: 0,
      rowsPerPage2: 10,
      itemTable: [],
      editItem: false,
      updateItem: false,
      productId: null,
      productname: '',
      selectedProductId: null,
      productItemList: [],
      product_item_id: null,
      product_item_version_id: null,
      new_product_attrs: [{ name: '', value: '' }],
      vendorMapping: [],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
    };
  }

  handleClick = (event, data) => {
    this.setState({ selected: data.v_id });
  }

  // handleChangePage = (event, page) => {
  //   this.setState({ page });
  // }

  handleChangePage2 = (event, page2) => {
    this.setState({ page2 });
  }

  // handleChangeRowsPerPage = (event) => {
  //   this.setState({ rowsPerPage: event.target.value });
  // }

  handleChangeRowsPerPage2 = (event) => {
    this.setState({ rowsPerPage2: event.target.value });
  }

  fetchVendorProductMapping = async (id) => {
    const url = vendorProductMappingPath(id);
    const res = await apiCall.sendRequest('get', url);
    const { data: { data = [] } = {} } = res;
    this.setState({ vendorMapping: data });
  }


  truncate = (text, len) => text.slice(0, len) + (text.length > len ? '...' : '')

  isSelected = id => this.state.selected === id

  showItemDialog(n) {
    this.setState(prevState => ({
      selectedProductItem: n,
      itemTable: [],
      updateItem: true,
      adding: false,
      product_item_id: n.id,
      product_item_sku: n.sku,
      product_item_version_id: n.versionId,
      new_product_attrs: n.productItemAttributes.length ? n.productItemAttributes : [{ name: '', value: '' }],
      urls: [
        ...prevState.urls.map((url) => {
          const obj = n.productItemImages.find(imgs => imgs.position === url.position);
          return {
            ...url,
            imageUrl: obj ? obj.imageUrl : null,
            id: obj ? obj.id : null,
            versionId: obj ? obj.versionId : null,
          };
        }),
      ],
    }));
    this.fetchVendorProductMapping(n.id);
  }

  fetchProductItemList = () => {
    const { productId } = this.state;
    if (productId) {
      apiCall.sendRequest('get', `api/v1/staging-products/${productId}/product-items`)
        .then((response) => {
          const { data: { data = {} } } = response;
          this.setState({
            productItemList: data,
            selectedProductId: productId,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('No productId');
    }
  };
  handleInputChange = (key, event) => {
    let { value } = event.target;
    const { selectedProductItem } = this.state;
    if (key === 'active') {
      value = value === 'ACTIVE';
    }
    selectedProductItem[key] = value;
    this.setState({
      selectedProductItem,
    });
  }

  handleAttrChange = (event, i, field) => {
    const tem = this.state.new_product_attrs;
    if (field === 'value') {
      tem[i].value = event.target.value;
    } else {
      tem[i].name = event.target.value;
    }
    this.setState({ new_product_attrs: tem });
  }

  clearState = () => {
    this.setState({
      itemTable: [],
      product_item_id: null,
      product_item_version_id: null,
      new_product_attrs: [{ name: '', value: '' }],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
    });
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  updateCatalog = () => {
    apiCall.sendRequest('patch', `/api/v1/product-catalogs/${this.props.productCatalogId}`, this.state.selectedProductItem)
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderImages = imgArr =>
    imgArr.map(x =>
      <img alt="imageUrl" style={{ width: '60px', height: 'auto' }} src={x.imageUrl} />)


  renderList = () => {
    const { classes, currency, taxType } = this.props;
    const {
      page2, rowsPerPage2, productItemList,
    } = this.state;

    const newColumnData2 = taxType !== 'gst' ? columnData2.filter(col => col.id !== 'CGST') : columnData2;

    return (
      <Modal
        open={this.state.editItem}
        onClose={() => { this.setState({}); }}
      >
        <div style={{
          background: 'white',
          width: '70%',
          marginLeft: '15%',
          height: '80%',
          marginTop: window.innerHeight * 0.09,
          marginBottom: window.innerHeight * 0.3,
          padding: '10px',
          overflow: 'scroll',
        }}
        >

          <Button style={{ float: 'right' }} onClick={() => { this.setState({ editItem: false, productItemList: [] }); }} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Typography style={{ marginTop: '2em', marginLeft: window.innerWidth * 0.03 }} color="secondary" variant="title">View/Edit Product Items</Typography>
          <Table style={{ width: '90%', marginLeft: '5%', marginTop: '2%' }} className={classes.table} aria-labelledby="tableTitle">
            <TableHeader columns={newColumnData2} />
            <TableBody>
              {productItemList.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((n, i) => (
                <TableRow
                  tabIndex={-1}
                  key={n.id}
                >
                  {/* <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">{n.id}</TableCell> */}
                  <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{currency} {n.unitPrice}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{currency} {n.sellingPrice}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.discount}%</TableCell>
                  {taxType === 'gst' && <TableCell component="th" scope="row" padding="none">{n.cgst}%</TableCell>}
                  <TableCell component="th" scope="row" padding="none">{this.renderImages(n.productItemImages)}</TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Typography color="secondary" style={{ fontSize: '0.9em' }}>
                      {/* <span onClick={() => { this.showItemDialog(n); }}>EDIT &emsp;| </span> <IconButton onClick={() => { this.removeItem(n); }} color="secondary"><RemoveIcon style={{ fontSize: '0.6em' }} /></IconButton> */}
                      <span onClick={() => { this.showItemDialog(n); }}> EDIT </span>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            style={{ marginRight: '3em' }}
            component="div"
            count={this.state.itemTable.length}
            rowsPerPage={rowsPerPage2}
            page={page2}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage2}
            onChangeRowsPerPage={this.handleChangeRowsPerPage2}
          />
        </div>
      </Modal>
    );
  }

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    console.log(page);
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;

    onRowsPerPageChange(value);
  };

  render() {
    const { classes, data, UOM, count, page, rowsPerPage } = this.props;
    const {
      // rowsPerPage, page,
      selectedProductItem = {},
      selectedProductId,
      updateItem,
      vendorMapping = [],
      productName,
      rowsPerPageOptions
    } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          {this.renderList()}
          <ProductItemForm
            UOM={UOM}
            vendorMapping={vendorMapping}
            open={updateItem}
            productItem={selectedProductItem}
            productId={selectedProductId}
            onClose={() => this.setState({ selectedProductItem: {}, updateItem: false })}
            onProductItemChange={productItem => this.setState({ selectedProductItem: productItem })}
            onMappingChange={mapping => this.setState({ vendorMapping: mapping })}
            onRefresh={this.fetchProductItemList}
            productName={productName}
          />
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader columns={columnData} />
            <TableBody>
              {data.map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" padding="none">{n.brandName}</TableCell>
                    <TableCell component="th" scope="row" padding="none" style={{ maxWidth: 200 }}>{n.name}</TableCell>
                    <TableCell component="th" scope="row" padding="none" style={{ maxWidth: 200 }}>{n.title}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.categoryName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Tooltip key={n.id} title={n.description}>
                        {this.truncate(n.description, 50)}
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.newProductTags.map(tag => tag.tag).join(", ")}</TableCell>
                    <TableCell component="th" scope="row" padding="none" style={{ paddingTop: '10px', paddingBottom: '10px' }}>{this.renderImages(n.stagingProductImages)}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Typography color="secondary" style={{ fontSize: '0.9em' }}>
                        {/* eslint-disable */}
                        <span onClick={() => { this.props.editHandler(n); }} style={{ cursor: 'pointer' }}>
                          EDIT&emsp;
                        </span>
                        |
                        <span
                          onClick={() => {
                            this.setState({
                              updateItem: true,
                              adding: true,
                              selectedProductId: n.id,
                              selectedProductItem: {},
                              vendorMapping: [],
                              productName: n.name
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          &emsp;ADD ITEM&emsp;
                        </span>
                        |
                        <span onClick={() => { this.setState({ editItem: true, adding: false, productId: n.id, productName: n.name }, () => { this.fetchProductItemList(); }); }} style={{ cursor: 'pointer' }}>
                          &emsp;VIEW/EDIT ITEM &emsp;
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};


class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const { handleImageUpload, side } = this.props;
    const image = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(image),
    });
    const formData = new FormData();
    formData.append('images', image);
    apiCall.sendRequest('post', allBrandsPathImages, formData)
      .then((response) => {
        handleImageUpload(Object.values(response.data)[0], side);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { clearImage, url } = this.props;
    if (this.state.file || url) {
      return (
        <div style={{ height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px' }}>
          <img style={{ height: '5em', width: 'auto' }} src={url || this.state.file} />
          <CloseIcon
            color="secondary"
            style={{
              fontSize: '1.6em',
              position: 'absolute',
              top: '5px',
              right: '5px',
              cursor: 'pointer',
            }}
            onClick={() => {
              clearImage(this.props.side);
              this.setState({ file: null });
            }}
          />
        </div>
      );
    }
    let textDisplay = this.props.side;
    if (textDisplay === 'RIGHT_SIDE') {
      textDisplay = 'RIGHT';
    } else if (textDisplay === 'LEFT_SIDE') {
      textDisplay = 'LEFT';
    }
    return (
      <div style={{ height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px' }}>
        <label style={{ lineHeight: '5em' }}>{textDisplay}
          <input accept="image/*" type="file" style={{ visibility: 'hidden' }} onChange={this.handleChange} />
        </label>
      </div>
    );
  }
}

Upload.propTypes = {
  handleImageUpload: PropTypes.func,
  clearImage: PropTypes.func,
  side: PropTypes.string,
  url: PropTypes.string,
};


const mapStateToProps = state => ({
  UOM: state.UOM,
  currency: state.currency,
  taxType: state.taxType,
});

export default connect(mapStateToProps, null)(withStyles(styles)(EnhancedTable));
