import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { ColMod } from '../../../components/lib';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, TextField } from '@material-ui/core';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import sortBy from 'lodash/sortBy';
import SectionMessage from '@atlaskit/section-message';
import RouteSelector from '../../../components/StaticRoute/Selector';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import apiCall, { staticRouteAddressesPath, updateLineItems, staticRoute } from '../../../api/NetworkHandler';
import StaticRouteSelection from '../../../components/StaticRouteSelection';
import { isStaticRouteChangeAllowed } from "../../../utils/staticRoutePermission";
import { getApplicationConfigs } from "../../../utils/getAppConfig";

const styles = {
  container: {
    background: 'white',
    width: '70%',
    height: '650px',
    overflow: 'auto'
  },
  header: {
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 1,
  }
}

const columnData = [
  {
    id: 'sequence', numeric: false, disablePadding: true, label: 'Sequence',
  },
  {
    id: 'customerName', numeric: false, disablePadding: true, label: 'Customer Name',
  },
  {
    id: 'address', numeric: false, disablePadding: true, label: 'Address',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: "Actions",
  }
]

class RouteAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      editingAddressId: null,
      oldAddress: null,
      isAnyEdited: false,
      errorMessage: '',
      editingAddressRoute: null,
      openRouteSelector: false,
      selectedAddress: {},
      staticRouteChangeTimeMin: '',
      staticRouteChangeTimeMax: '',
    }
  }

  componentDidMount() {
    this.fetchStaticRouteAddresses();
    this.setTimeState();
  }

  setTimeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = appConfig;
    this.setState({
      staticRouteChangeTimeMin,
      staticRouteChangeTimeMax,
    });
  };

  fetchStaticRouteAddresses = async () => {
    const { selectedRoute: { id } } = this.props;
    const response = await apiCall.sendRequest('get', staticRouteAddressesPath(id));
    const { data: { _embedded: embedded } } = response;
    let addresses = embedded['static-route-line-item'];

    //sort the response data by sequence and if there are gaps in between sequence it needs to be removed
    addresses = sortBy(addresses, 'sequence').map((address, index) => {
      return {
        ...address,
        sequence: index + 1,
      }
    })

    this.setState({
      addresses
    })
  }

  getCustomComponents = () => {
    const customComponents = {
      address: {},
      sequence: {}
    };
    const { addresses, editingAddressId, editingAddressRoute } = this.state;
    const { selectedRoute: { name: routeName } } = this.props;

    addresses.map((staticRouteLineItem, index) => {
      const { address, id, sequence } = staticRouteLineItem;
      const addressLength = address ? address.length : 0;
      const displayAddress = address;
      customComponents['address'][index] = <Tooltip title={address}><span>{displayAddress}</span></Tooltip>;
      customComponents['sequence'][index] = id == editingAddressId ?
        <TextField
          value={sequence}
          type="number"
          onChange={this.onChangeSequence}
          inputProps={{
            max: addresses.length,
            min: 1
          }}
          autoFocus
        />
        : sequence;
    })
    return customComponents;
  }

  onEditingAddressRouteChange = (route) => {
    this.setState({ editingAddressRoute: route });
  }

  getActions = (address) => {
    const { id } = address;
    const { editingAddressId } = this.state;

    const isEditing = (id == editingAddressId);
    return (
      <React.Fragment>
        {isEditing ?
          <Button color="primary" size="small" variant="contained" onClick={this.onSaveAddress}>
            Save
          </Button>
          :
          <Button variant="outlined" size="small" color="primary" onClick={() => { this.editAddress(address) }}>
            Set Sequence
          </Button>
        }
        &nbsp;&nbsp;&nbsp;
        {!isEditing ?
          <Button color="secondary" size="small" variant="outlined" onClick={() => this.handleOpenRouteSelector(address)}>
            Set Route
          </Button>
          :
          <Button size="small" color="secondary" variant="outlined" onClick={this.onCancelEdit}>
            Cancel
          </Button>
        }
      </React.Fragment>
    )
  }

  editAddress = (address) => {
    const { selectedRoute: { id: routeId, name } } = this.props;
    const { id } = address;
    const { addresses, editingAddressId } = this.state;
    if (editingAddressId) {
      const { edittingAddressIndex: previousEditIndex, oldAddress } = this.state;
      addresses[previousEditIndex] = oldAddress;
    }
    const edittingAddressIndex = addresses.findIndex(address => address.id === id);

    const editingAddressRoute = {
      label: name,
      value: routeId
    }
    this.setState({
      editingAddressId: id,
      oldAddress: { ...address },
      edittingAddressIndex,
      errorMessage: '',
      editingAddressRoute
    });
  }

  onCancelEdit = () => {

    this.setState(prevState => {
      const { addresses, oldAddress, edittingAddressIndex } = prevState;
      if (edittingAddressIndex >= 0) {
        addresses[edittingAddressIndex] = oldAddress;
      }

      return {
        addresses,
        editingAddressId: null,
        editingAddressRoute: null,
        edittingAddressIndex: null,
        errorMessage: '',
      }
    })
  }

  onSaveAddress = async () => {

    this.setState(prevState => {
      const { addresses, edittingAddressIndex } = prevState;
      //current sequence index
      const oldIndex = edittingAddressIndex;
      const newSequenceValue = addresses[oldIndex].sequence;

      if (newSequenceValue < 1 || newSequenceValue > addresses.length) {
        const errorMessage = `sequence should be between 1 to ${addresses.length}`
        return {
          errorMessage
        }
      }
      // get index of sequence where old sequence needs to be placed
      const newIndex = addresses.findIndex((address, index) => (address.sequence == newSequenceValue) && (oldIndex != index));


      const newAddresses = [...addresses].map(address => {
        return {
          ...address
        }
      });
      if (newIndex > -1) {
        const newAddress = newAddresses.splice(oldIndex, 1)[0];
        newAddresses.splice(newIndex, 0, newAddress);

        if (newIndex < oldIndex) {
          for (let index = (newIndex + 1); index <= oldIndex; index++) {
            newAddresses[index].sequence++;
          }
        } else if (newIndex > oldIndex) {
          for (let index = oldIndex; index < newIndex; index++) {
            newAddresses[index].sequence--;
          }
        }
      }

      return {
        isAnyEdited: true,
        editingAddressId: null,
        addresses: newAddresses,
        editingAddressRoute: null,
        edittingAddressIndex: null,
        errorMessage: ''
      }
    })
  }

  onChangeSequence = (event) => {
    const { value } = event.target;

    this.setState(prevState => {
      const { addresses, edittingAddressIndex } = prevState;

      addresses[edittingAddressIndex].sequence = value;

      return {
        addresses
      }
    })
  }

  onSave = async () => {
    const { addresses } = this.state;
    const { selectedRoute: { id }, onClose } = this.props;

    const sequenceData = addresses.reduce((sequence, address) => {
      sequence[address.id] = address.sequence;
      return sequence;
    }, {})

    try {
      await apiCall.sendRequest('put', updateLineItems(id), sequenceData);
      onClose();
    } catch (error) {
      const { response: { data: { message } } } = error;
      this.setState({ errorMessage: message });
    }
  }

  changeAddressRoute = async (route) => {
    const { selectedRoute: { id: selectedRouteId } } = this.props;
    // const { editingAddressRoute: { value: editingAddressRouteId }, oldAddress: { addressId } } = this.state;
    const { selectedAddress: { addressId = null } } = this.state;
    const { id: editingAddressRouteId } = route;
    if (selectedRouteId != editingAddressRouteId) {
      try {
        await apiCall.sendRequest('put', staticRoute(editingAddressRouteId, addressId));
        this.setState({
          errorMessage: '',
          editingAddressId: null,
          editingAddressRoute: null,
          edittingAddressIndex: null,
          openRouteSelector: false,
        });
        this.fetchStaticRouteAddresses();
      } catch (error) {
        const { response: { data: { message } } } = error;
        this.setState({
          errorMessage: message,
          openRouteSelector: false,
        });
      }
    } else {
      this.setState({ errorMessage: 'Please select different route' });
      this.handleRouteSelectorClose();
    }
  }

  getAdditionalRows = (dataIndex) => {
    const { edittingAddressIndex, editingAddressRoute } = this.state;
    return (
      edittingAddressIndex == dataIndex ?
        <TableRow>
          <TableCell component="td" scope="row" padding="none">
            <Typography variant="caption">
              Change route to
            </Typography>
          </TableCell>
          <TableCell component="td" scope="row" style={{ minWidth: '150px' }} padding="none">
            <RouteSelector
              selected={editingAddressRoute}
              onSelect={this.onEditingAddressRouteChange}
              isClearable={false}
            />
          </TableCell>
          <TableCell component="td" scope="row" padding="none">
            <Button color="secondary" variant="contained" onClick={this.changeAddressRoute}>
              Submit
            </Button>
          </TableCell>
        </TableRow> : null
    )
  }

  handleOpenRouteSelector = (address) => {
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = this.state;
    if (isStaticRouteChangeAllowed(staticRouteChangeTimeMin, staticRouteChangeTimeMax)) {
      this.setState({
        openRouteSelector: true,
        selectedAddress: address,
        errorMessage: '',
      });
    } else {
      this.setState({
        errorMessage: `Static route change is not allowed in between ${staticRouteChangeMaxTime} to ${staticRouteChangeMinTime}`,
      });
    }
  }

  handleRouteSelectorClose = () => {
    this.setState({
      openRouteSelector: false,
    });
  }

  render() {
    const { addresses, isAnyEdited, errorMessage, openRouteSelector, selectedAddress } = this.state;
    const { classes, selectedRoute, onClose } = this.props;
    const { name } = selectedRoute;
    const modifiedSelectedAddress = { id: selectedAddress.addressId };
    // const calculateZoom = (75 + ((window.innerHeight - 640) / 12.48)).toFixed(2);
    // document.body.style.zoom = `${calculateZoom}%`;
    return (
      <Grid className={classes.container} fluid tabIndex={-1}>
        <Row md={12} between="sm" middle="sm" className={classes.header}>
          <ColMod>
            <Typography variant="title">
              {name}
            </Typography>
          </ColMod>
          <ColMod>
            <Button color="secondary" variant="contained" disabled={!isAnyEdited} onClick={this.onSave}>
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button color="secondary" onClick={onClose}>
              <CloseIcon />
            </Button>
          </ColMod>
          {errorMessage && <ColMod sm={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>}
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            <PaginatedTable
              columnData={columnData}
              data={addresses}
              customComponents={this.getCustomComponents()}
              actions={this.getActions}
              count={addresses.length}
              showAdditionalRow={false}
              getAdditionalRows={this.getAdditionalRows}
            />
          </ColMod>
        </Row>
        {openRouteSelector &&
          <StaticRouteSelection
            openSearchBox={openRouteSelector}
            handleClose={this.handleRouteSelectorClose}
            selectStaticRoute={this.changeAddressRoute}
            selectedAddress={modifiedSelectedAddress}
            key={Date.now()}
          />
        }
      </Grid>
    )
  }
}

RouteAddresses.propTypes = {
  selectedRoute: PropTypes.object.isRequired,
};

export default withStyles(styles)(RouteAddresses);