import React from 'react';
import PropTypes from 'prop-types';
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchRegionsByName, findRegionsByBrandId } from '../../api/NetworkHandler';

const onLoad = async (searchTerm, options, defaultRegions) => {
  if (defaultRegions) {
    const response = await apiCall.sendRequest('get', searchRegionsByName(''));
    const { data: { _embedded } } = response;
    const { regions } = _embedded;

    return regions.map(region => ({
      value: region.id,
      label: region.name,
    }));
  } else if (options.length === 0) {
    const response = await apiCall.sendRequest('get', findRegionsByBrandId(searchTerm));
    const { data: { _embedded } } = response;
    const { regions } = _embedded;

    return regions.map(region => ({
      value: region.id,
      label: region.name,
    }));
  }
  return options;
};

function RegionMultiSelector(props) {
  const {
    selected, onSelect, searchTerm = '', options = [], defaultRegions = true,
  } = props;

  return (
    <SearchAndSelect
      onLoad={() => onLoad(searchTerm, options, defaultRegions)}
      selected={selected}
      onSelect={onSelect}
      isMulti
    />
  );
}

RegionMultiSelector.propTypes = {
  /**
   * value should be an array of objects [{value: String, label: String}, ...]
   */
  selected: PropTypes.arrayOf(PropTypes.object),

  /**
   * Triggered on option selection and get array of objects in parameter
   */
  onSelect: PropTypes.func.isRequired,

  searchTerm: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array),
  defaultRegions: PropTypes.bool,
};

RegionMultiSelector.defaultProps = {
  selected: null,
};

export default RegionMultiSelector;
