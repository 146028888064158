import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
} from '@material-ui/core';
import { Grid, Row, Col } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { debounce, isEmpty } from 'lodash';
import { ColMod } from '../../../components/lib';
import apiCall, {
  searchProductItemByRegion,
} from '../../../api/NetworkHandler';
import RegionSelector from '../../../components/Region/Selector';
import ProgressBar from '../../../components/States/ProgressBar';
import { Input } from 'antd';


const { Search } = Input;

var versionDict = {
    "productRequestId": 0,
}

class MultiProductItemSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            suggestions: [],
            productItems: [],
            showSelectedProducts: false,
            region: null,
            loading: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const { items, region } = nextProps;
        return {
            productItems: items.filter(el => el.active !== false),
            region: !isEmpty(region) ? region : null,
        };
    }

    handleRegionChange = (region) => {
        this.setState({
            region,
            searchText: '',
            suggestions: [],
        });
    }

    handleSearch = (searchText) => {
        console.log(searchText);
        this.setState({
            searchText,
            suggestions: []
        }, () => this.searchProducts());
    }

    selectProduct = (product) => {
        const { productItems = [] } = this.state;
        const { onChange } = this.props;
        let item = {};

        if (product && product.productItemId) {
            item = product;
        } else {
            item = {
                active: product.active,
                discount: product.discount,
                lineItemTotalPrice: 0,
                packaging: product.packaging,
                productItemId: product.id,
                productName: product.name,
                quantity: 0,
                sku: product.sku,
                tax: product.tax,
                unitMeasure: product.unitMeasure,
                unitPrice: product.sellingPrice,
                uomId: product.uom.id,
                deliveredQuantity: 0,
                isAdded: true,
            };
        }
        let index = 0;
        let modItems = [];

        if (productItems.length > 0) {
            index = productItems.findIndex(a => a.productItemId === item.productItemId);

            if (index > -1) {
                modItems = [...productItems];
                modItems.splice(index, 1);
                // modItems.filter(el => el.productItemId != item.productItemId);
                onChange(modItems);
            } else {
                modItems = [...productItems, item];
                onChange(modItems);
            }
        } else if (productItems.length === 0) {
            modItems.push(item);
            onChange(modItems);
        }
    }

    toggleProgress = (state) => {
        this.setState({
            loading: state,
        });
    }

    searchProducts = debounce( async () => {
        const { searchText, region } = this.state;
        const { id = null } = region;
        this.toggleProgress(true);
        try {
            versionDict.productRequestId += 1;
            let prevRequestId = versionDict.productRequestId;
            const response = await apiCall.sendRequest('get', searchProductItemByRegion(searchText, id));
            const { data = [] } = response;
            if (versionDict.productRequestId == prevRequestId) {
                this.setState({
                    suggestions: data,
                });
            }
            this.toggleProgress(false);
        } catch (err) {
            this.setState({
                suggestions: [],
            });
            this.toggleProgress(false);
        }
    }, 500);


    handleModalClose = (flag) => {
        this.setState({
            showSelectedProducts: false,
        });
        this.props.handleClose(flag);
    };

    getModifiedSuggestions = (suggestions, productItems) => {
        const modifiedSuggestions = []
        suggestions.forEach(el => {
            const obj = productItems.find(elm => elm.id && elm.productItemId == el.id);
            if (!obj) {
                modifiedSuggestions.push(el);
            }
        });
        return modifiedSuggestions;
    };

    render() {
        const { open = false, disabled = false } = this.props;
        const { searchText, suggestions = [], productItems = [], showSelectedProducts, region, loading } = this.state;
        const newSuggestions = this.getModifiedSuggestions(suggestions, productItems);
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={() => this.handleModalClose(false)}
                onBackdropClick={this.handleModalClose}
            >
            <div style={{
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                width: '70%',
                height: '80%',
            }}
            >
                <ProgressBar isLoading={loading}/>
                <Button style={{ float: 'right' }} onClick={() => this.props.handleClose(false)} color="secondary" aria-label="close">
                    <CloseIcon />
                </Button>
                <Grid style={{ padding: '2em', background: '#FFFFFF' }}>
                    <Row>
                        <ColMod xs md lg={12} custompadding="true" zerobottom="true">
                            <Row>
                                <Col lg={4}>
                                    <Typography variant="caption"> Region </Typography>
                                    <RegionSelector
                                        onSelect={this.handleRegionChange}
                                        selected={region}
                                        isClearable={false}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg={8}>
                                    <Typography variant="caption"> Search Product (By Name) </Typography>
                                    <Search
                                        placeholder="type to search product"
                                        allowClear
                                        enterButton
                                        size="large"
                                        onSearch={this.handleSearch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8}>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox
                                            checked={showSelectedProducts}
                                            value={showSelectedProducts}
                                            onChange={() => this.setState({ showSelectedProducts: !showSelectedProducts })}
                                            color="secondary"
                                        />
                                        <Typography style={{ paddingTop: '12px' }} color="secondary" variant="body2">
                                            Show Selected Products
                                        </Typography>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div style={{ overflowX: 'hidden', overflowY: 'auto', height: window.innerHeight * 0.57 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ padding: '0px', width: '50px', textAlign: 'center' }}>Select</TableCell>
                                                    <TableCell>Product</TableCell>
                                                    <TableCell>Unit Measure</TableCell>
                                                    <TableCell>Unit Price</TableCell>
                                                    <TableCell>Selling Price</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { showSelectedProducts &&
                                                    productItems.map(item => (
                                                        <TableRow key={item.productItemId}>
                                                            <TableCell style={{ padding: '0px' }}>
                                                            <Checkbox
                                                                checked={!isEmpty(productItems.find(a => a.productItemId === item.productItemId))}
                                                                value={!isEmpty(productItems.find(a => a.productItemId === item.productItemId))}
                                                                onChange={event => this.selectProduct(item, event)}
                                                                color="secondary"
                                                                disabled={!!(item.id)}
                                                            />
                                                            </TableCell>
                                                            <TableCell>{item.productName}</TableCell>
                                                            <TableCell>{item.unitMeasure}</TableCell>
                                                            <TableCell>{item.unitPrice}</TableCell>
                                                            <TableCell>{item.offerPrice}</TableCell>
                                                        </TableRow>
                                                ))}
                                                { !showSelectedProducts &&
                                                    newSuggestions.map(suggestion => (
                                                        <TableRow key={suggestion.id}>
                                                        <TableCell style={{ padding: '0px' }}>
                                                            <Checkbox
                                                                checked={!isEmpty(productItems.find(a => a.productItemId === suggestion.id))}
                                                                value={!isEmpty(productItems.find(a => a.productItemId === suggestion.id))}
                                                                onChange={event => this.selectProduct(suggestion, event)}
                                                                color="secondary"
                                                            />
                                                        </TableCell>
                                                        <TableCell>{suggestion.name}</TableCell>
                                                        <TableCell>{suggestion.unitMeasure}</TableCell>
                                                        <TableCell>{suggestion.unitPrice}</TableCell>
                                                        <TableCell>{suggestion.sellingPrice}</TableCell>
                                                        </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </ColMod>
                    </Row>
                </Grid>
            </div>
            </Modal>
        );
    }
}

MultiProductItemSelection.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  searchUrl: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default (MultiProductItemSelection);

