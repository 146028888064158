import React, { Component } from 'react';
import { Button } from '@material-ui/core';

class DwButton extends Component {
    disabled = false;
    handleClick = (e) =>{
        const { onClick } = this.props;
        if(!this.disabled){
            this.disabled = true;
            onClick(e);
            setTimeout(()=>{
                this.disabled = false;
            }, 1000);
        }
    }

    render(){
        const { variant, color, onClick, children, disabled = false , ...rest} = this.props;
        return (
            <Button
                variant={variant}
                color={color}
                onClick={this.handleClick}
                disabled={this.disabled || disabled }
                {...rest}
            >
            {children}
            </Button>
        );
    }
}

export default DwButton;