import * as ActionType from '../actions';

const initialState = [];

const information = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ALL_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      };
    case ActionType.GET_TICKET_TYPE_SUBTYPE_MAPPING:
      return {
        ...state,
        ticketTypeMapping: action.payload,
      };
    case ActionType.SET_STATIC_ROUTES:
      return {
        ...state,
        staticRoutes: action.payload,
      };
    default:
      return state;
  }
};


export default information;

