import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  barStyle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: 'rgb(113, 109, 109)',
    borderRadius: '5px',
    justifyContent: 'flex-end',
  },
  trackStyle: {
    display: 'flex',
    borderRadius: '5px',
  },
};


class ProgressBar extends Component {
  render() {
    const {
      value = 10,
      totalValue = 100,
      height = 10,
      barColor = '#990000',
      trackColor = '#f2f2f2',
      barTextColor = '#909090',
      style,
    } = this.props;
    let progress = (value / totalValue) * 100;
    if (progress > 100) {
      progress = 100;
    }
    const trackStyle = {
      ...style,
      ...styles.trackStyle,
      height,
      backgroundColor: trackColor,
    };
    const barStyle = {
      ...styles.barStyle,
      width: `${progress}%`,
      backgroundColor: barColor,
      backgroundImage: 'linear-gradient(to right, #8e1d56 , #ff5594)',
      color: barTextColor,
    };
    return (
      <div>
        <div style={trackStyle}>
          <div style={barStyle}>
            <div style={{ marginRight: 10 }}>{value}</div>
          </div>
        </div>
        <div style={{ textAlign: 'right', fontSize: '14px' }}>{totalValue}</div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  totalValue: PropTypes.number,
  height: PropTypes.number,
  barColor: PropTypes.string,
  trackColor: PropTypes.string,
  style: PropTypes.string,
  barTextColor: PropTypes.string,
};

export default ProgressBar;
