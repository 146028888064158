import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './styles';

class DetailPanel extends Component {
  render() {
    const { showCross, unMountFromDetail } = this.props;
    return (
      <Card style={styles.card}>
        {showCross && <ClearIcon style={styles.cross} onClick={unMountFromDetail} />}
        <CardContent style={styles.content}>{this.props.children}</CardContent>
      </Card>
    );
  }
}

DetailPanel.propTypes = {
  children: PropTypes.node,
  showCross: PropTypes.bool,
  unMountFromDetail: PropTypes.func,
};

export default DetailPanel;
