import React from 'react';
import {
  InputLabel,
  Paper,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Table, TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Modal,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Grid, Row } from 'react-flexbox-grid';
import Checked from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import MaterialSelect from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Lozenge from '@atlaskit/lozenge';
import NavBar from '../../../components/NavBar';
import { ColMod } from '../../../components/lib';
import apiCall, { getWareHouse, staticRouteSequenceListPath, pageableStaticRoutesByFilter, fetchStaticRouteByIdPath, createStaticRoutes } from '../../../api/NetworkHandler';
import UserSelection from '../../../components/UserSelection';
import StaticRouteSelection from '../../../components/StaticRouteSelection';
import TableHeader from '../../Commons/components/TableHeader';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import RouteAddresses from '../components/RouteAddresses';
import DeactivateDialog from '../components/DeactivateDialog';
import utils from '../../../utils/queryBuilder';
import { debounce, isEmpty } from "lodash";
import { isStaticRouteChangeAllowed, showStaticRouteChangeErrorDialog } from "../../../utils/staticRoutePermission";
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const hasRole = utils.isCurrentUserAuthorizedToView;

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Routes',
  },
  {
    id: 'defaultDeliveryBoyName', numeric: false, disablePadding: true, label: 'Delivery Boy',
  },
  {
    id: 'defaultSegregationBoyName', numeric: false, disablePadding: true, label: 'Segregation Boy',
  },
  {
    id: 'defaultSupervisorName', numeric: false, disablePadding: true, label: 'Supervisor',
  },
  {
    id: 'hubName', numeric: false, disablePadding: true, label: 'Hub',
  },
  {
    id: 'active', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const style = {
  cellStyle: {
    textAlign: 'left',
  },
};

var versionDict = {
  "staticRoutesRequestId": 0
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      useContainer: false,
      list: [],
      links: {},
      active: 'true',
      unassignedLinks: {},
      error: {},
      deliveryData: [],
      selectedStaticRoute: null,
      staticRouteLineItem: [],
      editableStaticRouteLineItem: [],
      deliveryRouteAddresses: [],
      warehouseList: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      count: 0,
      order: 'asc',
      orderBy: 'name',
      postData: {
        active: true,
      },
      openAddNewRoute: false,
      selectIndex: 0,
      openUserSelector: false,
      selectorIndex: null,
      filters: {
        staticRoutesQuery: '',
        warehouseId: '',
        unassignedAddressQuery: '',
      },
      searchedResult: false,
      openAddressesModal: false,
      isRouteManager: false,
      openDeactivateDialog: false,
      currentRouteId: null,
      staticRouteChangeTimeMin: '',
      staticRouteChangeTimeMax: '',
      isNoData: false,
      loading: false,
      patchData: {},
      disableButton: false
    };
  }

  componentDidMount() {
    this.fetchStaticRoutesByParams();
    this.fetchAllWarehouse();
    this.checkRoutemanagerRole();
    this.setTimeState();
  }

  setTimeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = appConfig;
    this.setState({
      staticRouteChangeTimeMin,
      staticRouteChangeTimeMax,
    });
  };

  checkRoutemanagerRole = () => {
    if (hasRole(['ROUTE_MANAGER'])) {
      this.setState({ isRouteManager: true });
    }
  }

  cancelDeactivate = () => {
    this.setState({
      openDeactivateDialog: false,
      currentRouteId: null
    })
  }

  confirmDeactivate = () => {
    const { currentRouteId, withinForm } = this.state;
    if (!withinForm) {
      this.handleActiveButon(currentRouteId, false);
    } else {
      this.updateStaticRoute(currentRouteId);
    }
    this.cancelDeactivate();
  }

  openDeactivateDialog = (id, withinForm = false) => {
    this.setState({
      openDeactivateDialog: true,
      currentRouteId: id,
      withinForm
    })
  }

  onListChange = (oldIndex, sequence) => {
    const { staticRouteLineItem } = this.state;
    const lineItem = staticRouteLineItem[oldIndex];
    this.updateSequenceAddresses(sequence, lineItem.id);
  }

  _toggleContainer() {
    this.setState({ useContainer: !this.state.useContainer });
  }

  onClear = () => {
    this.setState({
      filters: {
        staticRoutesQuery: '',
        warehouseId: '',
      }
    }, () => { this.fetchStaticRoutesByParams() });
  }

  fetchAllWarehouse = () => {
    apiCall.sendRequest('get', getWareHouse)
      .then((response) => {
        const { data: { _embedded } } = response;

        this.setState({
          warehouseList: _embedded.warehouse,
        });
      });
  }

  updateSequenceAddresses = (sequence, id) => {
    const { selectedStaticRoute } = this.state;
    const { id: staticRouteId } = selectedStaticRoute;
    apiCall.sendRequest('put', staticRouteSequenceListPath(staticRouteId, id, sequence))
      .then((response) => {
        const { data } = response;
        this.handleClick(null, selectedStaticRoute);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  handleInputChange = prop => (event) => {
    const index = prop.split('$')[1];
    if (prop.split('$')[0] == 'delivery') {
      this.setState({ d_boys: this.state.deliveryData[index].d_boy_options });
    }
  }

  dListClick = val => (event) => {
    const temp = this.state.deliveryData;
    temp[this.state.selectIndex].selected_d_boy = val;
    this.setState({ d_boys: [], deliveryData: temp });
  }

  handleDeliveryChange = (event, index) => {
    const { name } = event.target;
    const { value } = event.target;
    this.setState(prevState => ({
      deliveryData: [
        ...prevState.deliveryData.slice(0, index),
        {
          ...prevState.deliveryData[index],
          [name]: value,
        },
        ...prevState.deliveryData.slice(index + 1),
      ],
    }));
  }

  handleDelivery2Change = (event) => {
    const temp = this.state.deliveryData2;
    temp[this.state.selectIndex].assigned_boy = event.target.value;
    this.setState({ deliveryData2: temp });
  }

  handleSegChange = (event) => {
    const temp = this.state.deliveryData;
    temp[this.state.selectIndex].selected_s_boy = event.target.value;
    this.setState({ deliveryData: temp });
  }

  changeData = (id, value) => {
    const temp = this.state.list;
    const idx = temp.map(x => x.id).indexOf(id);
    temp[idx].route_assigned = value;
    this.setState({
      list: temp,
    });
  }

  handleClick = (event, n) => {
    this.setState({
      selectedStaticRoute: n,
      openAddressesModal: true,
    });
  }

  fetchCustomerDataFromAddressId = (deliveryRouteOrder) => {
    apiCall.sendRequest('get', `/api/v1/customer-address/${deliveryRouteOrder.addressId}`)
      .then((response) => {
        const customerData = response.data;
        this.setState(prevState => ({
          deliveryRouteAddresses: [
            ...prevState.deliveryRouteAddresses,
            {
              name: customerData.customerName,
              id: customerData.customerId,
              addressID: customerData.id,
              address: `Floor: ${customerData.floor}, ${customerData.houseNumber} ${customerData.blockName}\n${customerData.buildingName}, ${customerData.streetDetails}\n${customerData.localityName}, ${customerData.cityName} - ${customerData.pincode}`,
              verified: customerData.verified,
              deliveryRouteOrderID: deliveryRouteOrder.id,
              sequence: deliveryRouteOrder.sequence,
              order_status: deliveryRouteOrder.status,
            },
          ],
        }), () => {
          console.log(this.state.deliveryRouteAddresses);
        });
      });
  }

  enableEditStaticRoute = (id) => {
    let selectedindex;
    const staticRoute = this.state.deliveryData.find((staticRoute, index) => {
      if (staticRoute.id === id) {
        selectedindex = index;
      }
      return staticRoute.id === id;
    });
    if (staticRoute) {
      staticRoute.canEditDetails = true;
      this.setState(prevState => ({
        deliveryData: [
          ...prevState.deliveryData.slice(0, selectedindex),
          staticRoute,
          ...prevState.deliveryData.slice(selectedindex + 1),
        ],
      }), () => {
        console.log(this.state.deliveryData);
      });
    }
  }

  disableEditStaticRoute = (id) => {
    let selectedindex;
    const staticRoute = this.state.deliveryData.find((staticRoute, index) => {
      if (staticRoute.id === id) {
        selectedindex = index;
      }
      return staticRoute.id === id;
    });
    if (staticRoute) {
      staticRoute.canEditDetails = false;
      this.setState(prevState => ({
        deliveryData: [
          ...prevState.deliveryData.slice(0, selectedindex),
          staticRoute,
          ...prevState.deliveryData.slice(selectedindex + 1),
        ],
      }), () => {
        console.log(this.state.deliveryData);
      });
    }
  }


  openUserSelector = (userType, index) => () => {
    this.setState({
      openUserSelector: true,
      selectorIndex: index,
      userType,
    });
  }

  handleClose = () => {
    this.setState({
      openUserSelector: false,
      selectorIndex: null,
      error: {},
      userType: '',
    });
  }

  selectNewUser = (user) => {
    const { patchData } = this.state;
    switch (this.state.userType) {
      case 'User':
        break;
      case 'DeliveryBoy':
        this.setState(prevState => ({
          postData: {
            ...prevState.postData,
            defaultDeliveryBoy: user.id,
            defaultDeliveryBoyName: user.name,
            defaultDeliveryBoyMobile: user.mobile,
          },
          patchData: {
            ...patchData,
            defaultDeliveryBoy: user.id,
            defaultDeliveryBoyName: user.name,
            defaultDeliveryBoyMobile: user.mobile,
          }
        }));
        break;
      case 'SegregationBoy':
        this.setState(prevState => ({
          postData: {
            ...prevState.postData,
            defaultSegregationBoy: user.id,
            defaultSegregationBoyName: user.name,
          },
          patchData: {
            ...patchData,
            defaultSegregationBoy: user.id,
            defaultSegregationBoyName: user.name,
          }
        }));
        break;
      case 'Supervisor':
        this.setState(prevState => ({
          postData: {
            ...prevState.postData,
            defaultSupervisor: user.id,
            defaultSupervisorName: user.name,
            defaultSupervisorMobile: user.mobile,
          },
          patchData: {
            ...patchData,
            defaultSupervisor: user.id,
            defaultSupervisorName: user.name,
            defaultSupervisorMobile: user.mobile,
          }
        }));
        break;
      case 'Hub':
        this.setState(prevState => ({
          postData: {
            ...prevState.postData,
            hubId: user.id,
            hubName: user.name,
          },
          patchData: {
            ...patchData,
            hubId: user.id,
            hubName: user.name,
          }
        }));
        break;
      case 'Status':
        this.setState(prevState => ({
          postData: {
            ...prevState.postData,
            statsId: user.id,
            active: user.active,
          },
          patchData: {
            ...patchData,
            statsId: user.id,
            active: user.active,
          }
        }));
        break;
      default:
        break;
    }
    this.handleClose();
  }

  selectUser = (user) => {
    switch (this.state.userType) {
      case 'User':
        break;
      case 'DeliveryBoy':
        this.setState(prevState => ({
          deliveryData: [
            ...prevState.deliveryData.slice(0, prevState.selectorIndex),
            {
              ...prevState.deliveryData[prevState.selectorIndex],
              defaultDeliveryBoy: user.id,
              defaultDeliveryBoyName: user.name,
              defaultDeliveryBoyMobile: user.mobile,
            },
            ...prevState.deliveryData.slice(prevState.selectorIndex + 1),
          ],
        }));
        break;
      case 'SegregationBoy':
        this.setState(prevState => ({
          deliveryData: [
            ...prevState.deliveryData.slice(0, prevState.selectorIndex),
            {
              ...prevState.deliveryData[prevState.selectorIndex],
              defaultSegregationBoy: user.id,
              defaultSegregationBoyName: user.name,
            },
            ...prevState.deliveryData.slice(prevState.selectorIndex + 1),
          ],
        }));
        break;
      case 'Supervisor':
        this.setState(prevState => ({
          deliveryData: [
            ...prevState.deliveryData.slice(0, prevState.selectorIndex),
            {
              ...prevState.deliveryData[prevState.selectorIndex],
              defaultSupervisor: user.id,
              defaultSupervisorName: user.name,
              defaultSupervisorMobile: user.mobile,
            },
            ...prevState.deliveryData.slice(prevState.selectorIndex + 1),
          ],
        }));
        break;
      case 'Hub':
        this.setState(prevState => ({
          deliveryData: [
            ...prevState.deliveryData.slice(0, prevState.selectorIndex),
            {
              ...prevState.deliveryData[prevState.selectorIndex],
              hubId: user.id,
              hubName: user.name,
            },
            ...prevState.deliveryData.slice(prevState.selectorIndex + 1),
          ],
        }));
        break;
      case 'Status':
        this.setState(prevState => ({
          deliveryData: [
            ...prevState.deliveryData.slice(0, prevState.selectorIndex),
            {
              ...prevState.deliveryData[prevState.selectorIndex],
              statusId: user.id,
              active: user.active,
            },
            ...prevState.deliveryData.slice(prevState.selectorIndex + 1),
          ],
        }));
        break;
      default:
        break;
    }
    this.handleClose();
  }

  editStaticRoute = (n) => {
    this.setState({
      postData: n,
    }, () => {
      this.openAddNewRoute();
    });
  }

  deleteStaticRoute = (id) => {
    apiCall.sendRequest('delete', `api/v1/static-routes/${id}`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState
    });
  };

  fetchStaticRoutesByParams = async () => {
    const { filters: { staticRoutesQuery, warehouseId }, page, rowsPerPage: size } = this.state;
    this.toggleLoading(true);
    try {
      versionDict.staticRoutesRequestId += 1;
      let prevRequestId = versionDict.staticRoutesRequestId;
      const response = await apiCall.sendRequest('get', pageableStaticRoutesByFilter(warehouseId, staticRoutesQuery, page, size));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (prevRequestId == versionDict.staticRoutesRequestId) {
        this.setState({
          deliveryData: content,
          count: totalElements,
          isNoData: !!(content.length == 0)
        });
      }
    } catch (err) {
      this.setState({
        deliveryData: [],
        count: 0,
        isNoData: true
      });
    }
    this.toggleLoading(false);
  };

  fetchUnassignedAddresses = () => {
    apiCall.sendRequest('get', `/api/v1/customer-address/search/findUnassignedCustomerAddresses?query=${this.state.filters.unassignedAddressQuery}`)
      .then((response) => {
        if (response) {
          const { data: { _embedded: embedded, _links: links } } = response;
          const tempUnassignedAddressData = embedded['customer-address'];
          this.setState(prevState => ({
            list: [
              ...tempUnassignedAddressData.map((unassignedAddress, index) => ({
                id: unassignedAddress.id,
                name: unassignedAddress.customerName,
                address: `Floor: ${unassignedAddress.floor}, ${unassignedAddress.houseNumber} ${unassignedAddress.blockName}\n${unassignedAddress.buildingName}, ${unassignedAddress.streetDetails}\n${unassignedAddress.localityName}, ${unassignedAddress.cityName} - ${unassignedAddress.pincode}`,
                verified: unassignedAddress.verified,
              })),
            ],
            unassignedLinks: links,
          }));
        }
      });
  }

  fetchPaginatedUnassignedAddress = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        if (response) {
          const { data: { _embedded: embedded, _links: links } } = response;
          const tempUnassignedAddressData = embedded['customer-address'];
          this.setState(prevState => ({
            list: [
              ...tempUnassignedAddressData.map((unassignedAddress, index) => ({
                id: unassignedAddress.id,
                name: unassignedAddress.customerName,
                address: `Floor: ${unassignedAddress.floor}, ${unassignedAddress.houseNumber} ${unassignedAddress.blockName}\n${unassignedAddress.buildingName}, ${unassignedAddress.streetDetails}\n${unassignedAddress.localityName}, ${unassignedAddress.cityName} - ${unassignedAddress.pincode}`,
                verified: unassignedAddress.verified,
              })),
            ],
            unassignedLinks: links,
          }));
        }
      });
  };

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    // Is empty does not word for numbers so if condition
    if (typeof value === 'number') {
      if (value === 0) {
        this.setError(key, true);
        return false;
      }
    } else {
      if (isEmpty(value)) {
        this.setError(key, true);
        return false;
      }
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const route = {};
    route.name = this.state.postData.name;
    route.defaultDeliveryBoyName = this.state.postData.defaultDeliveryBoyName;
    // route.defaultSegregationBoyName = this.state.postData.defaultDeliveryBoyName;
    route.defaultSupervisorName = this.state.postData.defaultSupervisorName;
    route.hubName = this.state.postData.hubName;
    route.warehouseId = this.state.postData.warehouseId;
    let isValid = true;

    const validateEntities = ['name', 'hubName', 'warehouseId', 'defaultDeliveryBoyName', 'defaultSupervisorName'];

    for (const property in route) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, route[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

  toggleButton = (buttonState) => {
    this.setState({
      disableButton: buttonState,
    });
  };

  createNewStaticRoute = debounce(async () => {
    if (!this.isFormValid()) {
      return;
    }
    this.toggleButton(true);
    const postData = { ...this.state.postData };
    if (postData.id) {
      if (typeof (postData.active) == "string" && postData.active == "false") {
        this.openDeactivateDialog(postData.id, true);
      } else {
        this.updateStaticRoute(postData.id);
      }
    } else {
      await apiCall.sendRequest('post', createStaticRoutes, postData);
      this.fetchStaticRoutesByParams();
      this.handleNewRouteClose();
    }
    this.toggleButton(false);
  }, 500);

  updateStaticRoute = async (staticRouteId) => {
    const { patchData } = this.state;
    try {
      await apiCall.sendRequest('patch', fetchStaticRouteByIdPath(staticRouteId), patchData);
      this.fetchStaticRoutesByParams();
      this.handleNewRouteClose();
    } catch (err) {

    }
  };

  handleActiveButon = (id, status) => {
    this.setState(prevState => ({
      postData: {
        ...prevState.postData,
        active: status,
      },
    }), () => {
      const postData = { ...this.state.postData };
      if (id) {
        apiCall.sendRequest('patch', `/api/v1/static-routes/${id}`, postData)
          .then((response) => {
            this.fetchStaticRoutesByParams();
            this.handleNewRouteClose();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  openAddNewRoute = () => {
    this.setState({
      openAddNewRoute: true,
    });
  }

  handleNewRouteClose = () => {
    this.setState({
      openAddNewRoute: false,
      postData: {
        active: true,
      },
      error: {},
    });
  }

  handleNewChange = (event) => {
    const { name, value } = event.target;
    const { patchData, warehouseList } = this.state;
    this.setState(prevState => ({
      postData: {
        ...prevState.postData,
        [name]: value,
      },
      patchData: {
        ...patchData,
        [name]: value,
      }
    }));
    if (name === 'WarehouseId') {
      const warehouse = warehouseList.find(warehouse => warehouse.id === value);
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          warehouseName: warehouse ? warehouse.name : null,
        },
        patchData: {
          ...patchData,
          warehouseName: warehouse ? warehouse.name : null,
        }
      }));
    }
  }

  handleTabChange = (event, value) => {
    this.setState({
      value,
    }, () => {
      switch (this.state.value) {
        case 0: this.fetchStaticRoutesByParams(); break;
        case 1: this.fetchUnassignedAddresses(); break;
        default: break;
      }
    });
  }

  handleFilterChange = (event) => {
    const { name } = event.target;
    const query = event.target.value;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: query,
      },
    }));
  }

  handleRequestSort = (event, property) => {
    if (['actions'].includes(property)) {
      return;
    }
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const deliveryData =
      order === 'desc'
        ? this.state.deliveryData.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.deliveryData.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ deliveryData, order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchStaticRoutesByParams() });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.fetchStaticRoutesByParams() });
  }

  renderTable = () => {
    const { rowsPerPage, page, deliveryData, count, rowsPerPageOptions, order, orderBy, isRouteManager } = this.state;
    return (
      <div>
        <div>
          <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
            <TableHeader
              columns={columnData}
              cellStyle={style.cellStyle}
              onRequestSort={this.handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {deliveryData.map((n, i) => (
                <TableRow
                  hover
                  onClick={event => this.handleClick(event, n)}
                  tabIndex={-1}
                  key={n.id}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                  selected={this.state.selectedStaticRoute && this.state.selectedStaticRoute.id === n.id}
                >
                  <TableCell component="th" scope="row" padding="none">{n.name || '_'}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.defaultDeliveryBoyName || '_'}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.defaultSegregationBoyName || '_'}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.defaultSupervisorName || '_'}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.hubName || '_'}</TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Lozenge appearance={n.active ? "success" : "removed"}>{n.active ? "Active" : "Inactive"}</Lozenge>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <span>
                      <Button size="small" color="primary" variant="outlined" onClick={(event) => { event.stopPropagation(); this.editStaticRoute(n); }}>EDIT</Button>
                    </span>
                    {(!n.active && isRouteManager) &&
                      <span>
                        &emsp;|&emsp;
                        <Button color="primary" variant="contained" onClick={(event) => { event.stopPropagation(); this.handleActiveButon(n.id, true) }}>
                          ACTIVATE
                        </Button>
                      </span>
                    }
                    {(n.active && isRouteManager) &&
                      <span>
                        &emsp;|&emsp;
                        <Button color="default" variant="contained" onClick={(event) => { event.stopPropagation(); this.openDeactivateDialog(n.id) }}>
                          DEACTIVATE
                        </Button>
                      </span>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }

  renderAddress = () => {
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = this.state;
    const l = [];
    const { list } = this.state;
    for (let k = 0; k < (list.length); k += 2) {
      l.push(<Row>
        <ColMod lg={6}>
          <StyledCard
            changeData={this.changeData}
            staticRouteChangeTimeMin={staticRouteChangeTimeMin}
            staticRouteChangeTimeMax={staticRouteChangeTimeMax}
            customer={list[k]}
            selectedAddress={list[k]}
            routeAssigned={list[k].route_assigned}
            routeProps={this.state.deliveryData.map(x => ({
              name: x.name,
              id: x.id,
            }))}
          >
            <div>
              {`Customer Address #${String(list[k].id).padStart(4, '0')}`}
              {list[k].verified ?
                <span style={{ float: 'right' }}>
                  <Checked style={{ color: '#25c238' }} />
                </span>
                : <span style={{ float: 'right', color: '#f50057' }}>
                  NOT VERIFIED <IconButton style={{ fontSize: '0.8em' }}><EditIcon /></IconButton>
                </span>}
            </div>
            <div style={{ fontSize: '1.2em', fontWeight: '400', paddingTop: '1em' }}>
              {list[k].name}
            </div>
            <br />
            {list[k].address}
          </StyledCard>
        </ColMod>
        {(k + 1) < list.length ?
          <ColMod lg={6}>
            <StyledCard
              changeData={this.changeData}
              staticRouteChangeTimeMin={staticRouteChangeTimeMin}
              staticRouteChangeTimeMax={staticRouteChangeTimeMax}
              customer={list[k + 1]}
              selectedAddress={list[k + 1]}
              routeAssigned={list[k + 1].route_assigned}
              routeProps={this.state.deliveryData.map(x => ({ name: x.name, id: x.id }))}
            >
              <div>
                {`Customer Address #${String(list[k + 1].id).padStart(4, '0')}`}
                {list[k + 1].verified ?
                  <span style={{ float: 'right' }}>
                    <Checked style={{ color: '#25c238' }} />
                  </span>
                  : <span style={{ float: 'right', color: '#f50057' }}>
                    NOT VERIFIED&nbsp;
                    <IconButton style={{ fontSize: '0.8em' }}>
                      <EditIcon />
                    </IconButton>
                  </span>}
              </div>
              <div style={{ fontSize: '1.2em', fontWeight: '400', paddingTop: '1em' }}>
                {list[k + 1].name}
              </div>
              <br />
              {list[k + 1].address}
            </StyledCard>
          </ColMod>
          : null
        }
      </Row>);
    }
    return (
      <Grid>
        {l}
      </Grid>
    );
  }


  renderDragList = () => {
    const { useContainer, staticRouteLineItem, selectedStaticRoute } = this.state;
    return (
      <div
        className="list"
        ref={(el) => {
          if (el) this.container = el;
        }}
        style={{
          overflow: useContainer ? 'auto' : '',
          height: useContainer ? '200px' : '',
          border: useContainer ? '1px solid gray' : '',
        }}
      >
        {staticRouteLineItem.map((item, index) => (
          <StyledCard
            changeData={this.changeData}
            staticRouteChangeTimeMin={staticRouteChangeTimeMin}
            staticRouteChangeTimeMax={staticRouteChangeTimeMax}
            customer={{ id: item.addressId }}
            onReload={() => this.handleClick(null, selectedStaticRoute)}
            routeAssigned={this.state.selectedStaticRoute.name}
            routeProps={this.state.deliveryData.map(x => ({
              name: x.name,
              id: x.id,
            }))}
          >
            <Paper key={index} elevation={0} style={{ padding: '10px', marginBottom: '5px' }}>
              <div>
                <Typography variant="body2">
                  &emsp;&emsp;&emsp;
                  Customer Address #{String(item.addressId).padStart(4, '0')}
                </Typography>
              </div>
              <div>
                <Typography style={{ fontWeight: '400', fontSize: '1.44em' }} variant="body2">
                  <span
                    style={{ color: '#f50057' }}
                    onMouseEnter={() => {
                      this.setState(prevState => ({
                        editableStaticRouteLineItem: [
                          ...prevState.editableStaticRouteLineItem.slice(0, index),
                          1,
                          ...prevState.editableStaticRouteLineItem.slice(index + 1),
                        ],
                      }));
                    }}
                    onMouseLeave={() => {
                      this.setState(prevState => ({
                        editableStaticRouteLineItem: [
                          ...prevState.editableStaticRouteLineItem.slice(0, index),
                          0,
                          ...prevState.editableStaticRouteLineItem.slice(index + 1),
                        ],
                      }));
                    }}
                  >
                    {this.state.editableStaticRouteLineItem[index] ?
                      <TextField
                        defaultValue={index + 1}
                        style={{ width: '1.5em' }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            this.onListChange(index, event.target.value);
                          }
                        }} />
                      : <span>{index + 1}</span>
                    }
                  </span>
                  &emsp;
                  {item.customerName}
                </Typography>
              </div>

              <div>
                <Typography variant="body2">
                  &emsp;&emsp;&emsp;
                  {item.address}
                </Typography>
              </div>
            </Paper>
          </StyledCard>
        ))}
        {/* <DraggableList
            itemKey={i => i.planet.name}
            template={PlanetItem}
            list={this.state.staticRouteLineItem.map((planet, index) => ({planet, index}))}
            onMoveEnd={(newList, movedItem, oldIndex, newIndex) => this._onListChange(newList, movedItem, oldIndex, newIndex)}
            container={()=>useContainer ? this.container : document.body}
          /> */}
      </div>
    );
  }

  handleCloseRouteAddressModal = () => {
    this.setState({
      openAddressesModal: false
    });
  };

  render() {
    const { value, openAddressesModal, selectedStaticRoute, loading, patchData, disableButton } = this.state;
    return (
      <div>
        <NavBar />
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} tip="Loading...">
          <div style={{ height: '4em', backgroundColor: '#EEEEEE' }}>
            <div style={{ width: window.innerWidth * 0.8, float: "left" }}>
              <Tabs
                value={this.state.value}
                onChange={this.handleTabChange}
                fullWidth
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Static Routes" />
                <Tab label="Un-assigned Addresses" />
              </Tabs>
            </div>
            <div style={{ float: "right", marginTop: "10px", marginRight: "10px" }}>
              <Button variant="outlined" color="secondary" onClick={this.openAddNewRoute}>
                New Route
              </Button>
            </div>
          </div>
          <div style={{ margin: '1em' }}>
            {value === 0 && <div>
              <Grid fluid>
                <Row>
                  <ColMod md={12}>
                    <Typography style={{ marginBottom: '1em' }} color="secondary" variant="title" >Static Routes</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ flex: 2 }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label="Search Routes"
                          name="staticRoutesQuery"
                          fullWidth
                          value={this.state.filters.staticRoutesQuery}
                          onChange={this.handleFilterChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                            ),
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div style={{ flex: 2, marginLeft: '25px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Warehouse</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '400px' }}
                            value={this.state.filters.warehouseId ? this.state.filters.warehouseId : ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'warehouseId',
                              id: 'select-destination',
                            }}
                          >
                            {this.state.warehouseList.map((warehouse, index) => (<MenuItem key={index} value={warehouse.id}> {warehouse.name} </MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ flex: 1, marginLeft: '25px', marginTop: '15px' }}>
                        <Button color="primary" variant="contained" onClick={this.fetchStaticRoutesByParams}>
                          Search
                        </Button>
                      </div>
                      <div style={{ flex: 1, marginLeft: '25px', marginTop: '15px' }}>
                        <Button color="secondary" variant="outlined" onClick={this.onClear}>
                          Clear All
                        </Button>
                      </div>
                    </div>
                  </ColMod>
                  <ColMod md={12}>
                    {this.renderTable()}
                  </ColMod>
                  {/* <ColMod lg={4}>
                    {this.state.selectedStaticRoute ?
                      <Typography color="secondary" variant="title" >{this.state.selectedStaticRoute.name}</Typography>
                  : null}
                    {this.state.selectedStaticRoute ?
                  <Typography style={{ marginBottom: '3em', fontSize: '0.66em' }} variant="title" >Hover on card index to reorder the address</Typography>
              : null}
                {this.renderDragList()}
                  </ColMod> */}
                </Row>
              </Grid>
            </div>}
            {value === 1 &&
              <div>
                <Typography color="secondary" variant="title" style={{ margin: '0 360px', padding: '0 8px' }}>Un-assigned Addresses</Typography>
                <div style={{ margin: '0 360px', marginBottom: '10px', padding: '0 8px', marginTop: '5px' }}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Search Addresses"
                    fullWidth
                    name="unassignedAddressQuery"
                    value={this.state.filters.unassignedAddressQuery}
                    onChange={this.handleFilterChange}
                    onKeyPress={(event) => { if (event.key === 'Enter') { this.fetchUnassignedAddresses(); } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* Icon arrow_back Prev On Click Previous Unassigned addresses */}
                {/* Icon arrow_forward Next On Click Next Unassigned addresses */}
                <Grid>
                  <Row>
                    <span style={{ color: 'grey' }}><Typography onClick={() => { if (this.state.unassignedLinks.prev) { this.fetchPaginatedUnassignedAddress(this.state.unassignedLinks.prev.href); } }} color={this.state.unassignedLinks.prev ? 'secondary' : 'inherit'} variant="body1" style={{ cursor: this.state.unassignedLinks.prev ? 'pointer' : 'initial' }}><ArrowBack style={{ fontSize: '20', verticalAlign: 'top', marginLeft: '16' }} />&nbsp;Prev</Typography></span>&emsp;
                    <span style={{ color: 'grey' }}><Typography onClick={() => { if (this.state.unassignedLinks.next) { this.fetchPaginatedUnassignedAddress(this.state.unassignedLinks.next.href); } }} color={this.state.unassignedLinks.next ? 'secondary' : 'inherit'} variant="body1" style={{ cursor: this.state.unassignedLinks.next ? 'pointer' : 'initial' }}><ArrowForward style={{ fontSize: '20', verticalAlign: 'top' }} />&nbsp;Next</Typography></span>
                  </Row>
                </Grid>
                {this.renderAddress()}
              </div>}
          </div>
        </Spin>
        <UserSelection userType={this.state.userType} openSearchBox={this.state.openUserSelector} handleClose={this.handleClose} selectUser={this.state.openAddNewRoute ? this.selectNewUser : this.selectUser} />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openAddNewRoute}
          onClose={this.handleNewRouteClose}
          onBackdropClick={this.handleNewRouteClose}
        >
          <div style={{
            background: 'white',
            width: '70%',
            height: '650px',
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleNewRouteClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="subheading" color="primary">
                      {this.state.postData.id ? 'Edit Route' : 'Create New Route'}
                    </Typography>
                  </Row>
                  <Row>
                    <Typography variant="caption" color="error">
                      * Required
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={6} style={{ padding: 0 }}>
                      <TextField
                        margin="dense"
                        label="Name"
                        value={this.state.postData.name ? this.state.postData.name : ''}
                        name="name"
                        required
                        error={this.state.error.name}
                        fullWidth
                        onChange={this.handleNewChange}
                        autoComplete="off"
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        label="Delivery Boy"
                        value={this.state.postData.defaultDeliveryBoyName ? this.state.postData.defaultDeliveryBoyName : ''}
                        name="defaultDeliveryBoy"
                        fullWidth
                        required
                        error={this.state.error.defaultDeliveryBoyName}
                        onChange={this.handleNewChange}
                        onClick={this.openUserSelector('DeliveryBoy')}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                  </Row>

                  <Row>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        label="Segregation Boy"
                        value={this.state.postData.defaultSegregationBoyName ? this.state.postData.defaultSegregationBoyName : ''}
                        name="defaultSegregationBoy"
                        fullWidth
                        error={this.state.error.defaultSegregationBoyName}
                        onChange={this.handleNewChange}
                        onClick={this.openUserSelector('SegregationBoy')}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        label="Supervisor"
                        // id="input-with-icon-textfield"
                        value={this.state.postData.defaultSupervisorName ? this.state.postData.defaultSupervisorName : ''}
                        name="defaultSupervisor"
                        fullWidth
                        required
                        error={this.state.error.defaultSupervisorName}
                        onChange={this.handleNewChange}
                        onClick={this.openUserSelector('Supervisor')}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <InputLabel style={{ fontSize: '0.8em' }} htmlFor="select-destination">Warehouse *</InputLabel>
                      <MaterialSelect
                        value={this.state.postData.warehouseId}
                        onChange={this.handleNewChange}
                        error={this.state.error.warehouseId}
                        inputProps={{
                          name: 'warehouseId',
                          id: 'select-destination',
                        }}
                        fullWidth
                      >
                        {this.state.warehouseList.map((warehouse, index) => (
                          <MenuItem
                            key={index}
                            value={warehouse.id}
                          >
                            {warehouse.name}
                          </MenuItem>
                        ))}
                      </MaterialSelect>
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        label="Hub"
                        value={this.state.postData.hubName ? this.state.postData.hubName : ''}
                        name="hubName"
                        fullWidth
                        required
                        disabled={!this.state.postData.warehouseId}
                        error={this.state.error.hubName}
                        onChange={this.handleNewChange}
                        onClick={this.openUserSelector('Hub')}
                        helperText={!this.state.postData.warehouseId && 'Please select warehouse first..'}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          )
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod md={6}>
                      <Typography variant="caption" > Status </Typography>
                      <RadioGroup onChange={this.handleNewChange} name="active" value={(this.state.postData.active + '')} row>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Active"
                          disabled={!this.state.isRouteManager && this.state.postData.id}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Inactive"
                          disabled={!this.state.isRouteManager && this.state.postData.id}
                        />
                      </RadioGroup>
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button style={{ marginRight: '2em' }} color="secondary" onClick={this.handleNewRouteClose}>
                Cancel
              </Button>
              <Button
                onClick={() => { this.createNewStaticRoute() }}
                variant="contained"
                color="secondary"
                disabled={disableButton}
              >
                {this.state.postData.id ? 'Save' : 'Create'}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={openAddressesModal}
          onBackdropClick={this.handleCloseRouteAddressModal}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RouteAddresses
            selectedRoute={selectedStaticRoute}
            onClose={this.handleCloseRouteAddressModal}
          />
        </Modal>
        <DeactivateDialog
          open={this.state.openDeactivateDialog}
          onCancel={this.cancelDeactivate}
          onConfirm={this.confirmDeactivate}
        />
      </div>
    );
  }
}

class StyledCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: 0,
      routeAssigned: '',
      openRouteSelector: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.routeAssigned !== this.state.routeAssigned) {
        this.setState({
          routeAssigned: nextProps.routeAssigned,
        });
      }
    }
  }

  // handleDeliveryChange = event => {
  //   // this.props.changeData(this.props.customer.id, event.target.value)
  //   let routeAssigned = event.target.value;
  //   this.setState({
  //     routeAssigned: routeAssigned
  //   },() => {
  //     apiCall.sendRequest('put', `/api/v1/static-routes/${this.state.routeAssigned}/${this.props.customer.id}`)
  //       .then((response) => {
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         if(error) {
  //           console.error(error);
  //         }
  //       })
  //   })
  // }

  onMouseOver = () => this.setState({ shadow: 1 })

  onMouseOut = () => this.setState({ shadow: 0 })

  openRouteSelector = () => {
    const { staticRouteChangeTimeMin, staticRouteChangeTimeMax } = this.props;
    if (isStaticRouteChangeAllowed(staticRouteChangeTimeMin, staticRouteChangeTimeMax)) {
      this.setState({
        openRouteSelector: true,
      });
    } else {
      showStaticRouteChangeErrorDialog(staticRouteChangeTimeMin, staticRouteChangeTimeMax);
    }
  }

  handleRouteSelectorClose = () => {
    this.setState({
      openRouteSelector: false,
    });
  }

  selectStaticRoute = (route) => {
    this.setState({
      routeAssigned: route.name,
    }, () => {
      const { onReload } = this.props;
      this.handleRouteSelectorClose();
      apiCall.sendRequest('put', `/api/v1/static-routes/${route.id}/${this.props.customer.id}`)
        .then((response) => {
          console.log(response.data);
          onReload();
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
        });
    });
  }

  render() {
    const { selectedAddress } = this.props;
    const { openRouteSelector } = this.state;
    return (
      <div style={{ marginBottom: '10px' }}>
        <div
          onMouseEnter={this.onMouseOver}
          onMouseLeave={this.onMouseOut}
          className={this.state.shadow ? 'item2-hover' : 'item2'}
        >
          {this.props.children}
          {(this.state.shadow != 0 || this.props.route_assigned) &&

            <div style={{ float: 'right' }}>
              <TextField
                margin="dense"
                id="input-with-icon-textfield"
                value={this.state.routeAssigned ? this.state.routeAssigned : this.props.routeAssigned}
                name="Route"
                fullWidth
                // onChange={() => { }}
                onClick={this.openRouteSelector}
                helperText="Route"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search color="secondary" onClick={this.openRouteSelector} />
                      {/* {this.props.route_assigned || this.state.routeAssigned ?
                      <CloseIcon color="secondary" onClick={this.unassignStaticRouteLineItem}/>
                      : null} */}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          }
        </div>
        {openRouteSelector &&
          <StaticRouteSelection
            openSearchBox={openRouteSelector}
            handleClose={this.handleRouteSelectorClose}
            selectStaticRoute={this.selectStaticRoute}
            selectedAddress={selectedAddress}
            key={Date.now()}
          />
        }
      </div>
    );
  }
}


export default (App);
