import { parseMessage, filterRegex } from '../middleware/rulesValidator';
import apiCall, { getEnumsPath, uomUrl, getWareHouse, ticketTypesAndSubTypes, staticRoutesPath, getTaxType } from '../api/NetworkHandler';

export const TEXT_ONLY = 'TEXT_ONLY';
export const NUMBERS_ONLY = 'NUMBERS_ONLY';
export const STRICT_TEXT_ONLY = 'STRICT_TEXT_ONLY';
export const GET_ALL_ENUM = 'GET_ALL_ENUM';
export const GET_ALL_UOM = 'GET_ALL_UOM';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_ALL_WAREHOUSES = 'GET_ALL_WAREHOUSES';
export const GET_TICKET_TYPE_SUBTYPE_MAPPING = 'GET_TICKET_TYPE_SUBTYPE_MAPPING';
export const SET_STATIC_ROUTES = 'SET_STATIC_ROUTES';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_TAXTYPE = 'SET_TAXTYPE';

/**
 * Warning Only Text
 * @param {*} value from onlyTextCheck
 */

export const textCheck = value => ({
  type: TEXT_ONLY,
  payload: value,
});

export const onlyTextCheck = event => (dispatch) => {
  const getText = event.target.value;
  if (getText.length === 0) {
    dispatch(textCheck(parseMessage('nullError', true)));
  } else if (!filterRegex.String.test(getText)) {
    dispatch(textCheck(parseMessage('stringError', true)));
  } else {
    dispatch(textCheck(''));
  }
  return null;
};


/**
 * Warning Only Number
 * @param {*} value from onlyNumberCheck
 */

export const checkNumbers = value => ({
  type: NUMBERS_ONLY,
  payload: value,
});


export const onlyNumbersCheck = event => (dispatch) => {
  const getNumbers = event.target.value;
  if (getNumbers.length === 0) {
    dispatch(checkNumbers(parseMessage('nullError', true)));
  } else if (!filterRegex.Number.test(getNumbers)) {
    dispatch(checkNumbers(parseMessage('numberError', true)));
  } else {
    dispatch(checkNumbers(''));
  }
  return null;
};


/**
 * Strict Text Only
 * @param {*} value from strictTextCheck
 */

export const getStrictText = value => ({
  type: STRICT_TEXT_ONLY,
  payload: value,
});


export const strictTextCheck = event => (dispatch, getState) => {
  const getText = event.target.value;
  const { reducerCheck: { strictText } } = getState();

  if (getText.length === 0) {
    dispatch(getStrictText({ strictText: '' }));
  } else if (filterRegex.String.test(getText)) {
    dispatch(getStrictText({ strictText: getText }));
  } else if (!filterRegex.String.test(getText)) {
    dispatch(getStrictText({ strictText }));
  }
};

/**
 * Fetch all enums
 */

export const getAllEnums = () => (dispatch) => {
  apiCall.sendRequest('get', getEnumsPath)
    .then((response) => {
      dispatch({
        type: GET_ALL_ENUM,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAllUOM = () => (dispatch) => {
  apiCall.sendRequest('get', uomUrl)
    .then((response) => {
      dispatch({
        type: GET_ALL_UOM,
        payload: response.data._embedded.uoms,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loginUser = userData => (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: userData,
  });
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
  });
};

export const fetchWarehouses = () => (dispatch) => {
  apiCall.sendRequest('get', getWareHouse)
    .then((response) => {
      const { data: { _embedded } } = response;
      dispatch({
        type: GET_ALL_WAREHOUSES,
        payload: _embedded.warehouse,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getTicketTypeSubtypeMapping = () => (dispatch) => {
  apiCall.sendRequest('get', ticketTypesAndSubTypes)
    .then((response) => {
      const { data: { data = {} } } = response;
      dispatch({ type: GET_TICKET_TYPE_SUBTYPE_MAPPING, payload: data });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchStaticRoutes = () => (dispatch) => {
  apiCall.sendRequest('get', staticRoutesPath)
    .then((response) => {
      const { data: { _embedded = {} } } = response;
      const staticRoutes = {};
      _embedded['static-routes'].map((route) => {
        staticRoutes[route.id] = route.name;
        return route;
      });
      dispatch({ type: 'SET_STATIC_ROUTES', payload: staticRoutes });
    });
};

export const fetchCurrency = () => (dispatch) => {
  apiCall.sendRequest('get', '/api/v1/application-configs/app/scmapp/currency_code')
    .then((response) => {
      const { data: { data: { currency_code = "INR" } } } = response;
      dispatch({ type: 'SET_CURRENCY', payload: currency_code });
    }).catch(error => {
      dispatch({ type: 'SET_CURRENCY', payload: "INR" });
    });
};

export const fetchTaxType = () => (dispatch) => {
  apiCall.sendRequest('get', getTaxType)
    .then((response) => {
      const { data: { data: { taxType = 'gst' } } } = response;
      dispatch({ type: 'SET_TAXTYPE', payload: taxType });
    }).catch(error => {
      dispatch({ type: 'SET_TAXTYPE', payload: 'gst' });
    });
};
