import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Button, Select } from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import Constants from '../../../utils/Constant';
import apiCall, { allCitiesPath, searchAllLocalities, getFetchAllBuildings, searchLocalityByName } from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import debounce from 'lodash/debounce';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  searchContainer: {
    flex: 7,
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchField: {
    width: 200,
    marginRight: 40,
  },
  buttonField: {
    flex: 1,
    lineHeight: 'normal',
    marginBottom: 20,
  },
  or: {
    flex: 1,
  },
  clear: {
    flex: 1,
    color: Colors.primary,
    marginLeft: '30px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

let domKey = false;

var versionDict = {
  'buidingVersion': 0,
}
class AddressSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      cities: [],
      localities: [],
      buildings: [],
    };
  }

  // componentWillMount() {
  //   document.addEventListener('keypress', this.handleKeyPress, false);
  // }


  componentDidMount() {
    this.fetchCityList();
  }

  // componentWillUnmount() {
  //   document.removeEventListener('keypress', this.handleKeyPress, false);
  // }

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }

  fetchCityList = async () => {
    try {
      const response = await apiCall.sendRequest('get', allCitiesPath);
      const { data: { _embedded, page = {} } } = response;
      this.setState({
        cities: _embedded.cities,
      });
    } catch (e) {
      console.log('ERROR: ', e);
    }
  }

  handleBuildingSearch = debounce(async (searchString) => {
    this.handleChange(searchString, 'building');
    try {
      versionDict.buidingVersion += 1;
      let prevBuildingVersion = versionDict.buidingVersion;
      const { filters: { locality = '' } } = this.state;
      const url = getFetchAllBuildings(searchString, locality);
      const response = await apiCall.sendRequest('get', url);
      const { data: { _embedded: { buildings = [] } = {} } = {} } = response;
      if (versionDict.buidingVersion === prevBuildingVersion) {
        this.setState({
          buildings: buildings.slice(0, 10),
        });
      }
    } catch (e) {
      console.log('ERROR: ', e);
    }
  }, 500)

  handleLocaltiySearch = debounce(async (searchString) => {
    this.handleChange(searchString, 'locality');
    try {
      const { filters: { city = '' } } = this.state;
       let url = searchAllLocalities(city,searchString);
      if(city === ''){
        url = searchLocalityByName(searchString);
      }
      const response = await apiCall.sendRequest('get', url);
      const { data: { _embedded: { localities = [] } = {} } = {} } = response;
      this.setState({
        localities: localities,
      });
    } catch (e) {
      console.log('ERROR: ', e);
    }
  }, 750)

  handleClear = () => {
    const { title, onButtonClick } = this.props;
    const { filters } = this.state;
    let newfilters = {};
    if (title === Constants.CUSTOMER_ADDRESSES) {
      newfilters = {
        customerId: filters.customerId,
      };
    }
    onButtonClick('clear', newfilters);
    this.setState({
      filters: newfilters,
    }, () => {
      domKey = !domKey;
      onButtonClick('search', 0);
    });
  }

  isValidDateRange = (value, key) => {
    const { filters: { fromDate, toDate } } = this.state;
    const isEmpty = value === '';
    const endDateValidation = (key === 'toDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate))));
    const fromDateValidation = (key === 'fromDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate))));
    return isEmpty || endDateValidation || fromDateValidation;
  }

  handleChange = (value, key) => {
    let { filters } = this.state;
    const { onFilterChange } = this.props;
    const isNotDateField = (key !== 'toDate') && (key !== 'fromDate');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      filters = {
        ...filters,
        [key]: value,
      };
      onFilterChange(key, value);
    } else {
      filters = {
        ...filters,
        [key]: value,
      };
      onFilterChange(key, value);
    }
    this.setState({
      filters,
    });
  }

  render() {
    const {
      title,
      onButtonClick,
      addressStatuses = {},
      customerStatuses,
      dateErrorText,
    } = this.props;

    const { ASSIGNED, NON_DELIVERABLE, ...unassignedStatuses } = addressStatuses;

    const {
      filters, cities, localities, buildings,
    } = this.state;
    const {
      customerId,
      query,
      city,
      fromDate,
      toDate,
      locality = '',
      building = '',
      status = '',
      customerStatus = '',
    } = filters;
    return (
      <div style={styles.contentContainer} key={domKey}>
        <div style={styles.searchContainer}>
          {
            (title === Constants.NEW_CUSTOMER || title === Constants.UNASSIGNED) &&
            <div style={styles.searchField}>
              <TextField
                id="date"
                style={{ width: '100%' }}
                label="From Date (Delivery)"
                required
                type="date"
                value={fromDate}
                defaultValue={fromDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.handleChange(e.target.value, 'fromDate')}
              />
              <small style={{ color: 'red' }}>{dateErrorText ? dateErrorText : ''}</small>
            </div>
          }
          {
            (title === Constants.NEW_CUSTOMER || title === Constants.UNASSIGNED) &&
            <div style={styles.searchField}>
              <TextField
                id="date"
                fullWidth
                label="To Date (Delivery)"
                required
                type="date"
                value={toDate}
                min={fromDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.handleChange(e.target.value, 'toDate')}
              />
            </div>
          }
          {
            !(title === Constants.NEW_CUSTOMER || title === Constants.UNASSIGNED) &&
            <div style={styles.searchField}>
              <TextField
                label="Quick Search"
                fullWidth
                value={query}
                onChange={e => this.handleChange(e.target.value, 'query')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          }
          {/* {
            !(title === Constants.NEW_CUSTOMER || title === Constants.UNASSIGNED) &&
              <div style={styles.searchField}>
                <TextField
                  type="text"
                  fullWidth
                  placeholder=""
                  label="Customer Id"
                  name="customerId"
                  value={customerId}
                  disabled={title === Constants.CUSTOMER_ADDRESSES}
                  onChange={e => this.handleChange(e.target.value, 'customerId')}
                />
              </div>
          } */}
          <div style={styles.searchField}>
            <Select
              style={{ marginTop: 16 }}
              native
              fullWidth
              label="City"
              value={city}
              onChange={e => this.handleChange(e.target.value, 'city')}
            >
              <option value="">Select City</option>
              {cities.map(cityObj => (
                <option value={cityObj.name}>{cityObj.name}</option>
              ))}
            </Select>
          </div>
          <div style={styles.searchField}>
            <SearchWithSuggestions
              searchQuery={locality}
              style={{ marginTop: 16 }}
              label="Locality"
              suggestions={localities}
              onChange={this.handleLocaltiySearch}
              onSelect={selected => this.handleChange(selected.name, 'locality')}
            />
          </div>
          <div style={styles.searchField}>
            <SearchWithSuggestions
              searchQuery={building}
              style={{ marginTop: 16 }}
              label="Apartment"
              suggestions={buildings}
              onChange={this.handleBuildingSearch}
              onSelect={selected => this.handleChange(selected.name, 'building')}
            />
          </div>
          {title === Constants.ADDRESSES &&
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="Status"
                value={status}
                onChange={e => this.handleChange(e.target.value, 'status')}
              >
                <option value="">Select Status</option>
                {Object.keys(addressStatuses).map(addressStatus => (
                  <option value={addressStatus}>{addressStatus}</option>
                ))}
              </Select>
            </div>
          }
          {
            (title === Constants.NEW_CUSTOMER) &&
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="Customer Status"
                value={customerStatus}
                onChange={e => this.handleChange(e.target.value, 'customerStatus')}
              >
                <option value=""> Customer Type</option>
                {Object.keys(customerStatuses).map(cs => (
                  <option value={cs}>{cs}</option>
                ))}
              </Select>
            </div>
          }
          {title === Constants.UNASSIGNED &&
            <div style={styles.searchField}>
              <Select
                style={{ marginTop: 16 }}
                native
                fullWidth
                label="Status"
                value={status}
                onChange={e => this.handleChange(e.target.value, 'status')}
              >
                <option value="">Select Status</option>
                {Object.keys(unassignedStatuses).map(status => (
                  <option value={status}>{status}</option>
                ))}
              </Select>
            </div>
          }
        </div>
        <div style={styles.buttonContainer}>
          <div style={{ flex: 1 }}>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onButtonClick('search', filters)}
              >
                Search
              </Button>
            </div>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleClear}
              >
                Clear All
              </Button>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            {title === Constants.CUSTOMER_ADDRESSES && customerId && customerId !== '' &&
              <div style={styles.buttonField}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onButtonClick('create_new')}
                >
                  Create New
                </Button>
              </div>
            }
            {
              (title === Constants.NEW_CUSTOMER || title === Constants.UNASSIGNED) &&
              <div style={styles.buttonField}>
                <Button
                  onClick={() => onButtonClick('download', null)}
                  download
                  variant="contained"
                  color="secondary"
                >
                  Download
                </Button>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

AddressSearch.propTypes = {
  title: PropTypes.string,
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.func,
  addressStatuses: PropTypes.instanceOf(Object),
  customerStatuses: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { addressStatuses, customerStatuses } = Status;
  return {
    addressStatuses,
    customerStatuses,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null,
)(AddressSearch));
