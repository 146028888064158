import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import InputDir from '../InputDir';
import CustomPlan from './CustomPlan';
import styles from './styles';
import moment from 'moment';

class EditPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCustomPlan: false,
      dateText: '',
    };
  }

  onValChange = (e) => {
    const { name, value } = e.target;
    let today = new Date;
    if (name === "startDate" && value < moment(today).format("YYYY-MM-DD")) {
      this.setState({
        dateText: 'Past date not allowed'
      });
      this.props.onChange({ [name]: moment(today).format("YYYY-MM-DD") }, this.props.newItem);
      return;
    } else {
      this.setState({
        dateText: ''
      });
      this.props.onChange({ [name]: value }, this.props.newItem);
    }
    this.props.onChange({ [name]: value }, this.props.newItem);
  };

  onPlanChange = (e) => {
    const { schedules, newItem } = this.props;
    const { value } = e.target;
    const scheduleData = schedules.find(el => el.name === value);
    this.props.onChange({ scheduleData }, newItem);
  };

  onChangeCustomPlan = (e) => {
    const { checked } = e.target;
    this.setState({
      openCustomPlan: checked,
    });
  };

  onCreateCustomPlan = (obj) => {
    const { startDate } = this.props.data;
    if (startDate) {
      this.props.onChange({ ['startDate']: startDate }, this.props.newItem);
    } else {
      this.props.onChange({ ['startDate']: moment().format("YYYY-MM-DD") }, this.props.newItem);
    }
    const { onCreateCustomPlan, newItem } = this.props;
    onCreateCustomPlan(obj, newItem);
    this.setState({
      openCustomPlan: false,
    });
  };

  getSchedules = (name) => {
    const { schedules } = this.props;
    const scheduleData = schedules.find(el => el.name === name);
    return scheduleData ? scheduleData.name : '';
  };

  uniqueSchedules = () => {
    const { schedules } = this.props;
    const uniqueSchedules = [...new Map(schedules.map(item => [item.name, item])).values()];
    return uniqueSchedules;
  };

  formatSchedules = data =>
    data.map(el => ({
      text: el.name,
      value: el.name,
    }));

  render() {
    const {
      data, schedules, updateEdit, closeEdit, newItem,
    } = this.props;
    const {
      quantity, pauseFrom, pauseTill, planName, startDate,
    } = data;
    const { openCustomPlan, dateText } = this.state;
    let today = new Date;
    return (
      <div style={styles.editPaln}>
        <div style={styles.epRow}>
          <div style={styles.epLeft}>
            <div style={styles.epRow}>
              <InputDir
                label="Quantity"
                type="number"
                name="quantity"
                value={quantity}
                style={styles.epInputWrap}
                inputStyle={styles.epInput}
                onChange={this.onValChange}
              />
              <InputDir
                label="Plan Name"
                type="select"
                name="planName"
                options={this.formatSchedules(this.uniqueSchedules())}
                value={this.getSchedules(planName)}
                style={styles.epInputWrap}
                inputStyle={styles.epInput}
                onChange={this.onPlanChange}
                disabled={openCustomPlan}
              />
              <InputDir
                label="Plan Start Date"
                type="date"
                name="startDate"
                style={styles.epInputWrap}
                inputStyle={styles.epInput}
                value={startDate ? startDate : moment(today).format("YYYY-MM-DD")}
                onChange={this.onValChange}
                dateText={dateText}
              />
            </div>
            <div style={styles.epCol}>
              <InputDir
                label="CUSTOM PLAN"
                type="checkbox"
                name="customPlan"
                value={openCustomPlan}
                onChange={this.onChangeCustomPlan}
              />
              {openCustomPlan && <CustomPlan onDone={this.onCreateCustomPlan} />}
            </div>
          </div>
          {!newItem && (
            <div style={styles.epRight}>
              {/* <h4 style={styles.epHeader}>Pause Item</h4> */}
              <div style={styles.epRow}>
                <InputDir
                  label="Pause Date (From)"
                  type="date"
                  name="pauseFrom"
                  value={pauseFrom}
                  style={styles.epInputWrap}
                  inputStyle={styles.epInput}
                  onChange={this.onValChange}
                />
                <InputDir
                  label="Pause Date (Till)"
                  type="date"
                  name="pauseTill"
                  value={pauseTill}
                  style={styles.epInputWrap}
                  inputStyle={styles.epInput}
                  onChange={this.onValChange}
                />
              </div>
            </div>
          )}
        </div>
        <div style={styles.epAction}>
          <Button
            style={styles.btn}
            onClick={() => closeEdit(newItem)}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            style={styles.btn}
            // disabled={updateEdit}
            onClick={updateEdit}
            color="primary"
            variant="contained"
            size="small"
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

EditPlan.propTypes = {
  onChange: PropTypes.func,
  schedules: PropTypes.instanceOf(Array),
  onCreateCustomPlan: PropTypes.func,
  updateEdit: PropTypes.func,
  closeEdit: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  newItem: PropTypes.bool,
};

export default EditPlan;
