import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  InputAdornment,
  TextField,
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
  MenuItem,
  Switch,
  // MenuList,
  // Paper,
} from '@material-ui/core/';
import { Search } from '@material-ui/icons';
import { map } from 'lodash';

class InputDir extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     showAC: false,
  //   };
  // }

  // showAutoComplete = () => {
  //   if (!this.state.showAC) this.state({ showAC: true });
  // };

  // hideAutoComplete = () => {
  //   if (this.state.showAC) this.state({ showAC: false });
  // };

  builder = () => {
    const {
      type,
      name,
      label,
      value,
      options,
      disabled,
      id,
      inputStyle,
      onChange,
      onClick,
      dateText,
      helperText,
      error,
    } = this.props;

    if (type === 'text') {
      return (
        <FormControl style={inputStyle}>
          <TextField
            fullWidth
            label={label}
            name={name}
            disabled={disabled}
            value={value || ''}
            onChange={onChange}
            autoComplete="off"
          />
        </FormControl>
      );
    }
    if (type === 'number') {
      return (
        <FormControl style={inputStyle}>
          <TextField
            fullWidth
            type="number"
            label={label}
            name={name}
            disabled={disabled}
            value={value || ''}
            onChange={onChange}
            autoComplete="off"
            helperText={helperText}
            error={error}
          />
        </FormControl>
      );
    }
    if (type === 'search') {
      return (
        <FormControl style={inputStyle}>
          <TextField
            autoComplete="off"
            fullWidth
            label={label}
            name={name}
            disabled={disabled}
            value={value || ''}
            onClick={onClick}
            onChange={onChange}
            helperText={helperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {!disabled && <Search color="secondary" />}
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      );
    }
    if (type === 'date') {
      return (
        <FormControl style={inputStyle}>
          <TextField
            fullWidth
            autoComplete="off"
            type="date"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={dateText ? dateText : null}
          />
        </FormControl>
      );
    }
    if (type === 'select') {
      return (
        <FormControl style={inputStyle}>
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Select id={id} name={name} onChange={onChange} disabled={disabled} value={value || ''}>
            {map(options, el => (
              <MenuItem value={el.value}>{el.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    // if (type === 'autoSuggest') {
    //   return (
    //     <FormControl style={inputStyle}>
    //       <TextField
    //         fullWidth
    //         label={label}
    //         name={name}
    //         defaultValue={value}
    //         onChange={onChange}
    //         disabled={disabled}
    //         autoComplete={null}
    //         onKeyDown={this.showAutoComplete}
    //         onKeyUp={this.hideAutoComplete}
    //         onBlur={this.hideAutoComplete}
    //       />
    //       {this.state.showAC && (
    //         <Paper>
    //           <MenuList>
    //             {map(options, el => (
    //               <MenuItem value={el.value}>{el.text}</MenuItem>
    //             ))}
    //           </MenuList>
    //         </Paper>
    //       )}
    //     </FormControl>
    //   );
    // }
    if (type === 'checkbox') {
      return (
        <FormControlLabel
          label={label}
          style={inputStyle}
          control={
            <Checkbox
              disabled={disabled}
              name={name}
              checked={value}
              value={value}
              onChange={onChange}
              color="secondary"
            />
          }
        />
      );
    }
    if (type === 'switch') {
      return (
        <FormControlLabel
          label={label}
          style={inputStyle}
          control={
            <Switch
              disabled={disabled}
              name={name}
              checked={value}
              value={value}
              onChange={onChange}
              color="secondary"
            />
          }
        />
      );
    }
    return null;
  };

  render() {
    return <div style={this.props.style}>{this.builder()}</div>;
  }
}

InputDir.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  inputStyle: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
};

export default InputDir;
