import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import apiCall, { updateProductPropertiesPath } from '../../../api/NetworkHandler';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import isEmpty from 'lodash/isEmpty';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'costPrice', numeric: false, disablePadding: true, label: 'Buying Price',
  },
  {
    id: 'unitPrice', numeric: false, disablePadding: true, label: 'MRP',
  },
  {
    id: 'sellingPrice', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: "Actions",
  }
]

class ProductItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: {},
      patchData: []
    }
  }

  getActions = (product) => {
    const { editing } = this.state;
    return (
      !editing[product.id] ?
        <Button color="secondary" onClick={() => { this.onEditProductItem(product) }}>Edit</Button> :
        <Button color="secondary" onClick={this.saveproductItems}>Save</Button>
    )
  }

  saveproductItems = async () => {
    const { products, onRefresh, onEdit } = this.props;
    const { editing } = this.state;
    const product = products.filter(product => product.id == Object.keys(editing)[0])[0];

    const patchData = [];
    product.productItemList.forEach(productItem => {
      const patchProduct = {};
      patchProduct.id = productItem.productItem.id;
      patchProduct.name = productItem.productItem.name;
      patchProduct.costPrice = productItem.productItem.costPrice;
      patchProduct.unitPrice = productItem.productItem.unitPrice;
      patchProduct.sellingPrice = productItem.productItem.sellingPrice;

      patchProduct.vendorProductItemList = productItem.vendorProductItemMappingDTOs.map((vendorItem) => {
        return {
          id: vendorItem.productItem.id,
          name: vendorItem.productItem.name,
          sellingPrice: vendorItem.productItem.sellingPrice
        }
      })
      patchData.push(patchProduct);
    })

    try {
      await apiCall.sendRequest("patch", updateProductPropertiesPath, patchData);
      onRefresh();
      onEdit();
      this.setState({
        editing: {}
      })
    } catch (error) {

    }
  }

  onEditProductItem = (product) => {
    const { editing } = this.state;
    if (!isEmpty(editing)) {
      const { onRefresh } = this.props;
      onRefresh();
    }
    this.setState({
      editing: {
        [product.id]: true,
      }
    })
  }

  canDisplayCell = (displayCell = {}, productItemId) => {
    if (!displayCell.hasOwnProperty(productItemId)) {
      displayCell[productItemId] = true;
      return true;
    }
    return false;
  }

  getAdditionalRows = (dataIndex) => {
    const { products, onPriceChange, onVendorPriceChange } = this.props;
    const { editing } = this.state;
    const product = products[dataIndex];
    const vendorProductMapping = {};

    product.productItemList.forEach(productItem => {
      productItem.vendorProductItemMappingDTOs.forEach(vendorItem => {
        const { id } = vendorItem.productItem;
        if (!vendorProductMapping.hasOwnProperty(id)) {
          vendorProductMapping[id] = [];
        }
        vendorProductMapping[id].push(productItem.productItem.id);
      })
    })

    return product.productItemList.map((productItem, index) => {
      const displayCell = {};
      return (
        <React.Fragment key={index}>
          <TableRow>
            <TableCell component="td" scope="row">
              {productItem.productItem.name}
            </TableCell>
            <TableCell component="td" scope="row" padding="none">
              <TextField
                type="number"
                name="costPrice"
                value={productItem.productItem.costPrice || ''}
                onChange={(e) => { onPriceChange(e, dataIndex, index) }}
                disabled={!editing[product.id]}
              />
            </TableCell>
            <TableCell component="td" scope="row" padding="none">
              <TextField
                type="number"
                name="unitPrice"
                value={productItem.productItem.unitPrice}
                onChange={(e) => { onPriceChange(e, dataIndex, index) }}
                disabled={!editing[product.id]}
              />
            </TableCell>
            <TableCell component="td" scope="row" padding="none">
              <TextField
                type="number"
                name="sellingPrice"
                value={productItem.productItem.sellingPrice}
                onChange={(e) => { onPriceChange(e, dataIndex, index) }}
                disabled={!editing[product.id]}
              />
            </TableCell>
          </TableRow>
          {productItem.vendorProductItemMappingDTOs.map((vendorItem, vendorIndex) => {
            const { id } = vendorItem.productItem;
            const lastIndex = vendorProductMapping[id].length - 1;
            let vendorProductLength = 0;
            for (const key in vendorProductMapping) {
              const lastIndex = vendorProductMapping[key].length - 1;
              if (vendorProductMapping[key][lastIndex] == productItem.productItem.id) {
                vendorProductLength++;
              }
            }
            return (
              (vendorProductMapping[id][lastIndex] == productItem.productItem.id) &&
              <TableRow key={vendorIndex}>
                {this.canDisplayCell(displayCell, productItem.productItem.id) &&
                  <TableCell
                    rowSpan={vendorProductLength}
                    component="td"
                    scope="row"
                    style={vendorProductLength > 1 ? { verticalAlign: 'top', paddingTop: '15px' } : {}}
                  >
                    Vendors
                  </TableCell>}
                <TableCell component="td" scope="row" padding="none">
                  {vendorItem.catalog.vendor.name}
                </TableCell>
                <TableCell component="td" scope="row" padding="none">
                  <TextField
                    type="number"
                    key={vendorIndex}
                    name="vendorProductItemList"
                    value={vendorItem.productItem.sellingPrice || ''}
                    onChange={(e) => { onVendorPriceChange(e, dataIndex, index, vendorIndex) }}
                    disabled={!editing[product.id]}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        {(vendorItem.productItem.unitMeasure != 0 ? (vendorItem.productItem.unitMeasure == 1 ? '/' : vendorItem.productItem.unitMeasure) : '')
                          //currently getting uom object null for some vendor product item to handle this temporary assigning uom code as emty string
                          + (vendorItem.productItem.uom ? vendorItem.productItem.uom.code : "")}
                      </InputAdornment>,
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </React.Fragment>
      )
    })
  }

  render() {
    const {
      products,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      handleChangeRowsPerPage,
      handleChangePage,
      onRequestSort,
      searchTerm,
      isLoading,
      isNoData,
    } = this.props;
    return (
      <PaginatedTable
        columnData={columnData}
        data={products}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        showAdditionalRow={true}
        getAdditionalRows={this.getAdditionalRows}
        searchTerm={searchTerm}
        isLoading={isLoading}
        isNoData={isNoData}
      />
    )
  }
}
ProductItemsList.propTypes = {
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
};

ProductItemsList.defaultProps = {
  searchTerm: '',
  isLoading: false,
  isNoData: false,
};

export default ProductItemsList;
