import React from 'react';
import moment from 'moment';
import { Row } from 'react-flexbox-grid';
import { TextField, Button } from '@material-ui/core';
import CustomerDelivery from '../Component/CustomerDelivery';
import apiCall, { upcomingDeliveriesByDate, productSearchPath } from '../../../api/NetworkHandler';
import { ColMod } from '../../../components/lib';
import ProductSelection from '../../../components/ProductSelection';


class DeliveryContainer extends React.Component {
  constructor(props) {
    super(props);
    const { customerId } = this.props;
    this.state = {
      showProduct: false,
      customerId,
      addressId: '1234',
      deliveries: [],
      deliveryDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      message: '',
    };
  }

  componentDidMount() {
    const { deliveryDate } = this.state;
    this.fetchAllDeliveries(deliveryDate);
  }

  onSubmitDeliveryChanges = async (index, isRemoved) => {
    const {
      customerId, addressId, deliveryDate, deliveries,
    } = this.state;
    const { productItemId, quantity } = deliveries[index];
    const planData = { productItemId, quantity: isRemoved ? 0 : quantity };
    const url = upcomingDeliveriesByDate(deliveryDate, customerId, addressId);
    const response = await apiCall.sendRequest('post', url, [planData]);
    if (response) {
      this.fetchAllDeliveries(deliveryDate);
    }
  }

  fetchAllDeliveries = async (date) => {
    const { customerId, addressId } = this.state;
    const url = upcomingDeliveriesByDate(date, customerId, addressId);
    const response = await apiCall.sendRequest('get', url);
    if (response && response.data) {
      const { data } = response;
      this.setState({
        deliveries: data,
      });
    }
  }

  handleDeliverDate = (date) => {
    const diff = moment(date).diff(moment(), 'days') <= 10 && moment().isBefore(date);
    if (diff) {
      this.setState({
        deliveryDate: date,
        message: '',
      }, () => {
        this.fetchAllDeliveries(date);
      });
    } else {
      this.setState({
        message: 'Please select a future date till 10 days from today',
      });
    }
  }

  handleQuantityChange = (index, key, value) => {
    const { deliveries } = this.state;
    const modifiedData = deliveries.map((item, i) => {
      if (i === index) {
        const newItem = item;
        newItem[key] = parseInt(value, 10);
        return newItem;
      }
      return item;
    });
    this.setState({
      deliveries: modifiedData,
    });
  }

  handleClose = () => {
    this.setState({
      showProduct: false,
    });
  }

  selectProduct = (product) => {
    const {
      id, name, unitMeasure, uom = {}, sellingPrice, offerPrice
    } = product;
    const { deliveries } = this.state;
    const formattedItem = {
      productItemId: id,
      quantity: 1,
      product: {
        name,
        productItems: [
          {
            sellingPrice,
            offerPrice,
            unitMeasure,
            uom,
          },
        ],
      },
    };
    this.setState({
      deliveries: [...deliveries, formattedItem],
      showProduct: false,
    });
  }

  handleOpen = () => {
    this.setState({
      showProduct: true,
    });
  }

  render() {
    const {
      deliveries, deliveryDate, showProduct, message,
    } = this.state;
    const { customerId } = this.props;
    const maxDate = moment().add(10, 'days');
    return (
      <div style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <Row>
            <ColMod lg={8}>
              <h2>Upcoming Deliveries</h2>
            </ColMod>
            <ColMod lg={4}>
              <TextField
                id="date"
                type="date"
                name="selectDate"
                onChange={e => this.handleDeliverDate(e.target.value)}
                value={deliveryDate}
                maxDate={new Date()}
                style={{ margin: '0.83em', border: 0 }}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </ColMod>
          </Row>
          <div style={{ color: '#f44336' }}>{message}</div>
        </div>
        <div style={{ flex: 1 }}>
          <CustomerDelivery
            deliveries={deliveries}
            onChange={this.handleQuantityChange}
            onSave={this.onSubmitDeliveryChanges}
          />
          <ProductSelection
            showOfferPopup={showProduct}
            handleClose={this.handleClose}
            selectProduct={this.selectProduct}
            searchUrl={productSearchPath}
            customerId={customerId}
          />
          <div style={{ margin: '20px 0px' }}>
            <Button color="primary" onClick={this.handleOpen}>+ Add Item</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryContainer;
