import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button } from '@material-ui/core';
import { Close, KeyboardArrowRight } from '@material-ui/icons/';
import './styles.scss';

class TaskBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="TaskBlock block">
        <h2>Notifications</h2>
        <Paper className="TaskContent">
          <div className="TaskItem">
            <div className="TasKDescription">
              <div className="TaskType info">INFO</div>
              <div className="TaskNote">Nandini 500ml stock is expiring soon</div>
            </div>
            <div className="TaskAction">
              <Button color="primary" variant="outlined" size="small" className="btn">
                <KeyboardArrowRight className="icon" />
              </Button>

              <Button color="primary" variant="outlined" size="small" className="btn">
                <Close className="icon" />
              </Button>
            </div>
          </div>
          <div className="TaskItem">
            <div className="TasKDescription">
              <div className="TaskType warning">WARNING</div>
              <div className="TaskNote">Nandini 500ml stock is expiring soon</div>
            </div>
            <div className="TaskAction">
              <Button color="primary" variant="outlined" size="small" className="btn">
                <KeyboardArrowRight className="icon" />
              </Button>

              <Button color="primary" variant="outlined" size="small" className="btn">
                <Close className="icon" />
              </Button>
            </div>
          </div>
          <div className="TaskItem">
            <div className="TasKDescription">
              <div className="TaskType critical">CRITICAL</div>
              <div className="TaskNote">Nandini 500ml stock is expiring soon</div>
            </div>
            <div className="TaskAction">
              <Button color="primary" variant="outlined" size="small" className="btn">
                <KeyboardArrowRight className="icon" />
              </Button>

              <Button color="primary" variant="outlined" size="small" className="btn">
                <Close className="icon" />
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

TaskBlock.propTypes = {};

export default TaskBlock;
