import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableCell, TableBody, TextField } from '@material-ui/core/';
import TableHeader from '../../Commons/components/TableHeader';
import Lozenge from '@atlaskit/lozenge';
import { fetchMaxExtraAllowed } from '../containers/GrnUtils';
import { isEmpty } from "lodash";

const columnData = [
  {
    id: 'vendorProductItemId',
    numeric: false,
    disablePadding: true,
    label: 'Product Item ID',
  },
  {
    id: 'productName',
    numeric: false,
    disablePadding: true,
    label: 'Product Name',
  },
  {
    id: 'hsnCode',
    numeric: false,
    disablePadding: true,
    label: 'HSN Code',
  },
  {
    id: 'packaging',
    numeric: false,
    disablePadding: true,
    label: 'Packaging',
  },
  {
    id: 'receivedQuantity',
    numeric: false,
    disablePadding: true,
    label: 'Received Quantity',
  },
  {
    id: 'rejectedQuantity',
    numeric: false,
    disablePadding: true,
    label: 'Rejected Quantity',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: true,
    label: 'Ordered Quantity',
  },
  {
    id: 'rejectionReason',
    numeric: false,
    disablePadding: true,
    label: 'Rejection Reason',
  },
  {
    id: 'purchaseItemType',
    numeric: false,
    disablePadding: true,
    label: 'Purchase Item Type',
  },
  {
    id: 'unitPrice',
    numeric: false,
    disablePadding: true,
    label: 'Unit Price',
  },
  {
    id: 'lineItemReceivedPrice',
    numeric: false,
    disablePadding: true,
    label: 'Received Price',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class POItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receivedQuantityText: '',
      maxExtraAllowed: null,
      vendorProductId: null,
      rejectedQuantityText: '',
      invalidRejectedQuantity: false,
      invalidReceivedQuantity: false,
    };
  }

  componentDidMount() {
    this.getMaxExtraAllowed();
  }

  getMaxExtraAllowed = () => {
    fetchMaxExtraAllowed((err, res) => {
      if (err) {
        this.setState({
          maxExtraAllowed: null
        });
      }

      if (res) {
        this.setState({
          maxExtraAllowed: res
        });
      }
    })
  }

  calculateAvailableQuantity = () => {
    const { getReceivedQuantity, items = [], poOrderedQuantity } = this.props;
    let getOrderedQuantity = [];
    var quantityExists = items.filter((obj) => {
      return obj.hasOwnProperty("quantity");
    }).length > 0;
    if (quantityExists) {
      items.forEach((item) => {
        if (item && item.length != 0) {
          getOrderedQuantity.push({
            vendorProductItemId: item.vendorProductItemId,
            quantity: Math.round(item.quantity * (1.0 + (this.state.maxExtraAllowed / 100)).toFixed(1)),
          });
        }
      });
    }
    else {
      poOrderedQuantity.forEach((item) => {
        if (item && item.length != 0) {
          getOrderedQuantity.push({
            vendorProductItemId: item.vendorProductItemId,
            quantity: Math.round(item.quantity * (1.0 + (this.state.maxExtraAllowed / 100)).toFixed(1)),
          });
        }
      })
    }
    let mergedArray = [...getReceivedQuantity, ...getOrderedQuantity]
    var holder = {};
    mergedArray.forEach((d) => {
      if (holder.hasOwnProperty(d.vendorProductItemId)) {
        holder[d.vendorProductItemId] = d.quantity - holder[d.vendorProductItemId];
      } else {
        holder[d.vendorProductItemId] = d.quantity;
      }
    });
    var uniqueQuantity = [];
    for (var prop in holder) {
      uniqueQuantity.push({ vendorProductItemId: prop, quantity: holder[prop] });
    }
    return uniqueQuantity;
  }

  handleChange = (event, index, lineItem) => {
    const { name, value } = event.target;
    const { items, onChange } = this.props;

    let numPattern = new RegExp(/[^0-9\s]/g);
    if (['receivedQuantity', 'rejectedQuantity'].includes(name) && (numPattern.test(value) || value < 0)) return;

    let availableQuantity = this.calculateAvailableQuantity();

    let availableQuantityObj = availableQuantity.find((item) => {
      if (item.vendorProductItemId == items[index]['vendorProductItemId']) {
        return item;
      }
    });

    this.setState({
      vendorProductId: items[index]['vendorProductItemId'],
    });

    if (name === 'receivedQuantity') {
      let calculatedValue = parseInt(lineItem.rejectedQuantity) + parseInt(value);
      if (calculatedValue > availableQuantityObj.quantity) {
        let message = (availableQuantityObj.quantity - lineItem.rejectedQuantity) > 0 ? `Max ${availableQuantityObj.quantity - lineItem.rejectedQuantity}` : `Max reached`;
        this.setState({
          receivedQuantityText: message,
          invalidReceivedQuantity: true,
        });
      } else {
        this.setState({
          receivedQuantityText: '',
          invalidReceivedQuantity: false,
        });
      }
    }

    if (name === 'rejectedQuantity') {
      let calculatedValue = parseInt(lineItem.receivedQuantity) + parseInt(value);
      if (calculatedValue > availableQuantityObj.quantity) {
        let message = (availableQuantityObj.quantity - lineItem.receivedQuantity) > 0 ? `Max ${availableQuantityObj.quantity - lineItem.receivedQuantity}` : `Max reached`;
        this.setState({
          rejectedQuantityText: message,
          invalidRejectedQuantity: true,
        });
      } else {
        this.setState({
          rejectedQuantityText: '',
          invalidRejectedQuantity: false,
        });
      }
    }

    if (name === 'unitPrice') {
      const costPrice = items[index]['costPrice'];
      if (value > costPrice) return;
    }

    items[index][name] = value;
    onChange(items);
  }

  lineItemDisableStatus = (lineItem) => {
    const quantitySummary = this.calculateAvailableQuantity();
    if (quantitySummary.length > 0) {
      const targetObj = quantitySummary.find(el => el.vendorProductItemId === lineItem.vendorProductItemId);
      if (!isEmpty(targetObj) && targetObj.quantity === 0) {
        return true;
      }
      return false;
    }
  };

  render() {
    const { items = [], viewOnly = false, requiredQuantity, taxType, classes } = this.props;
    const modifiedColumnData = taxType !== 'gst' ? columnData.filter(col => col.id !== 'hsnCode') : columnData;
    const { rejectedQuantityText, invalidRejectedQuantity, invalidReceivedQuantity, receivedQuantityText, vendorProductId } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle">
            <TableHeader columns={modifiedColumnData} />
            <TableBody>
              {items.map((lineItem, index) => {
                const disableLineItem = this.lineItemDisableStatus(lineItem);
                return (
                  <TableRow
                    hover
                    key={index}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    style={{ background: disableLineItem ? '#D4D8DB' : 'FFF' }}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        id="vendorProductItemId"
                        style={{ width: 100 }}
                        type="number"
                        name="vendorProductItemId"
                        disabled
                        onChange={e => this.handleChange(e, index)}
                        value={lineItem.vendorProductItemId.split("-")[0]}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 370 }}
                        type="text"
                        name="productName"
                        disabled
                        value={lineItem.productName}
                      />
                    </TableCell>
                    {taxType === 'gst' &&
                      <TableCell component="th" scope="row" padding="none">
                        <TextField
                          style={{ width: 100 }}
                          type="text"
                          name="hsnCode"
                          disabled
                          value={lineItem.hsnCode}
                        />
                      </TableCell>}
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 100 }}
                        type="text"
                        name="packaging"
                        disabled
                        value={lineItem.packaging}
                        autoComplete="off"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 90 }}
                        type="number"
                        name="receivedQuantity"
                        disabled={viewOnly || disableLineItem}
                        onChange={e => this.handleChange(e, index, lineItem)}
                        value={lineItem.receivedQuantity}
                        error={vendorProductId === lineItem.vendorProductItemId ? invalidReceivedQuantity : false}
                        helperText={vendorProductId === lineItem.vendorProductItemId ? receivedQuantityText : ''}
                        autoComplete='off'
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 90 }}
                        type="number"
                        name="rejectedQuantity"
                        disabled={viewOnly || disableLineItem}
                        onChange={e => this.handleChange(e, index, lineItem)}
                        value={lineItem.rejectedQuantity}
                        error={vendorProductId === lineItem.vendorProductItemId ? invalidRejectedQuantity : false}
                        helperText={vendorProductId === lineItem.vendorProductItemId ? rejectedQuantityText : ''}
                        autoComplete='off'
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 90 }}
                        type="number"
                        name="quantity"
                        disabled
                        onChange={e => this.handleChange(e, index)}
                        value={lineItem.quantity || requiredQuantity[lineItem.vendorProductItemId]}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 220 }}
                        type="text"
                        name="rejectionReason"
                        disabled={viewOnly || lineItem.rejectedQuantity <= 0 || disableLineItem}
                        onChange={e => this.handleChange(e, index)}
                        value={lineItem.rejectionReason}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      <Lozenge appearance={lineItem.purchaseItemType === 'PAID' ? 'success' : 'removed'} isBold>{lineItem.purchaseItemType}</Lozenge>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 90 }}
                        type="number"
                        name="unitPrice"
                        disabled={viewOnly || lineItem.purchaseOrderLineItemId == null || disableLineItem}
                        value={lineItem.unitPrice}
                        onChange={e => this.handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <TextField
                        style={{ width: 90 }}
                        type="number"
                        name="lineItemReceivedPrice"
                        disabled
                        onChange={e => this.handleChange(e, index)}
                        value={lineItem.lineItemReceivedPrice.toFixed(2)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

POItemList.propTypes = {
  items: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
  requiredQuantity: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  taxType: state.taxType
});

export default connect(mapStateToProps, null)(withStyles(styles)(POItemList));
