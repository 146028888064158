import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import apiCall from '../../../../api/NetworkHandler';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 4,
  },
  clear: {
    flex: 1,
    color: '#f50057',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class SearchBar extends React.Component {
  state = {
    filters: {
      query: '',
      poNumber: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      status: '',
    },

  }

  clearAll = () => {
    this.setState({
      filters: {
        query: '',
        poNumber: '',
        startDate: null,
        endDate: null,
        status: '',
      },
    }, () => {
      this.onSearch();
    });
  }

  render() {
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
              Product Offers
          </Typography>
        </div>
        <div style={styles.searchBar}>
          <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                fullWidth
                name="query"
                onChange={this.onChangeFilter}
                value={this.state.filters.query}
                onKeyPress={this.onKeyPress}
                InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
              />
            </div>
            <div style={styles.clear}>
              <Button color="secondary" variant="contained" onClick={this.onSearch}>
                    Search
              </Button>
            </div>
            <div style={styles.clear}>
              <Button color="secondary" onClick={this.clearAll}>
                    Clear All
              </Button>
            </div>
          </div>
          <div style={styles.filterContainer}>
            <div style={{ flex: 2 }}>
              <TextField
                id="date"
                type="date"
                name="startDate"
                onChange={this.onChangeFilter}
                value={this.state.filters.startDate ? this.state.filters.startDate : ''}
                InputLabelProps={{
                      shrink: true,
                    }}
              />
              <FormHelperText>Start Date</FormHelperText>
            </div>
            <div style={{ flex: 2 }}>
              <TextField
                id="date"
                type="date"
                name="endDate"
                onChange={this.onChangeFilter}
                value={this.state.filters.endDate ? this.state.filters.endDate : ''}
                InputLabelProps={{
                      shrink: true,
                    }}
              />
              <FormHelperText>End Date</FormHelperText>
            </div>
            <div style={{ flex: 1 }}>
              <Select
                name="status"
                onChange={this.onChangeFilter}
                value={this.state.filters.status ? this.state.filters.status : ''}
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </Select>
              <FormHelperText>Status</FormHelperText>
            </div>
          </div>
        </div>
        <div style={styles.add}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
            this.props.openAddOffer(true);
          }}
          >
          Create New Offer
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  POStatuses: state.Status.postatuses,
});

export default withRouter(connect(mapStateToProps, null)(SearchBar));
