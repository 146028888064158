import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import apiCall, {
  purchaseOrderItemAnalytics,
} from '../../../api/NetworkHandler';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

class StockAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsData: {
        stockAvailable: '-',
        dumpedStock: '-',
        predictedQuantity: '-',
        poQuantity: '-',
        grnQuantity: '-',
        customerOrderedQuantity: '-',
      }
    }
  }

  componentDidMount() {
    const { vendorProductItemId, warehouseId } = this.props;
    this.fetchProductItemStockAnalytics(warehouseId, vendorProductItemId);
  }

  componentDidUpdate(prevProps) {
    const { warehouseId: previousWarehouseId } = prevProps;
    const { warehouseId, vendorProductItemId } = this.props;
    if (previousWarehouseId !== warehouseId) {
      this.fetchProductItemStockAnalytics(warehouseId, vendorProductItemId);
    }
  }

  fetchProductItemStockAnalytics = async (warehouseId, vendorProductItemId) => {
    try {
      const response = await apiCall.sendRequest('get', purchaseOrderItemAnalytics(warehouseId, vendorProductItemId));
      const { data: { data } } = response;
      this.setState({ analyticsData: data });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { analyticsData } = this.state;
    const {
      stockAvailable,
      dumpedStock,
      predictedQuantity,
      poQuantity,
      grnQuantity,
      customerOrderedQuantity,
    } = analyticsData;
    const { productName } = this.props;
    return (
      <Grid style={{ width: '100%' }}>
        <Row>
          <Col>
            <Typography variant="title">Stock Analytics {productName ? ` - ${productName}` : ''}</Typography>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Available Stock</TableCell>
                    <TableCell>Yesterday's Dumped Stock</TableCell>
                    <TableCell>Predicted Quantity</TableCell>
                    <TableCell>Yesterday's PO Quantity</TableCell>
                    <TableCell>Yesterday's GRN Quantity</TableCell>
                    <TableCell>Yesterday's Customer Order Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{productName ? stockAvailable || 0 : '-'}</TableCell>
                    <TableCell>{productName ? dumpedStock || 0 : '-'}</TableCell>
                    <TableCell>{productName ? predictedQuantity || 0 : '-'}</TableCell>
                    <TableCell>{productName ? poQuantity || 0 : '-'}</TableCell>
                    <TableCell>{productName ? grnQuantity || 0 : '-'}</TableCell>
                    <TableCell>{productName ? customerOrderedQuantity || 0 : '-'}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Paper>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default StockAnalytics;