import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 2,
    marginLeft: 3,
  },
  clear: {
    flex: 1,
    color: '#f50057',
    marginLeft: '30px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default class GenericSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: '',
      query: '',
    };
  }

  componentWillMount() {
    const { customerId } = this.props;
    this.setState({
      customerId,
    });
  }

  handleCustomerId = (customerId) => {
    const { handleCustomerId } = this.props;
    this.setState({
      customerId,
      query: '',
    });
    handleCustomerId(customerId);
  }

  handleQueryChange = (event) => {
    const { value } = event.target;
    const { handleQueryChange } = this.props;
    this.setState({
      query: value,
      customerId: '',
    });
    handleQueryChange(value);
  }

  render() {
    const {
      onSearch,
      title,
      onOpenAdd,
      buttonName,
    } = this.props;
    const { customerId, query } = this.state;
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
            {title}
          </Typography>
        </div>
        <div style={styles.searchBar}>
          <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                value={query}
                onChange={this.handleQueryChange}
                onKeyPress={onSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={styles.filterContainer}>
              <div>
                <TextField
                  id="customerId"
                  type="number"
                  label="Customer Id"
                  name="customerId"
                  value={customerId}
                  onChange={e => this.handleCustomerId(e.target.value)}
                />

              </div>
            </div>
            <div style={styles.clear}>
              <Button
                color="secondary"
                variant="contained"
                onClick={onSearch}
              >
                      Search
              </Button>
            </div>
          </div>

        </div>
        <div style={styles.add}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              onOpenAdd(null);
            }}
          >
            {buttonName || `New ${title}`}
          </Button>
        </div>
      </div>
    );
  }
}

GenericSearchBar.propTypes = {
  customerId: PropTypes.string,
  handleCustomerId: PropTypes.func,
  handleQueryChange: PropTypes.func,
  title: PropTypes.string,
  onSearch: PropTypes.func,
  onOpenAdd: PropTypes.func,
  buttonName: PropTypes.string,
};
