import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    TableBody,
    TablePagination,
    Typography,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Grid,
    Tooltip,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import Lozenge from '@atlaskit/lozenge';

import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';


const OfferColumnData = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Overlay ID',
    },
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Overlay Title',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description',
    },
    {
        id: 'timeUpdated',
        numeric: false,
        disablePadding: true,
        label: 'Updated On',
    },
    {
        id: 'filterType',
        numeric: false,
        disablePadding: true,
        label: 'Filter Type',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: 'Action',
    },
];

const styles = {
    div: {
        display: 'flex',
        flexDirection: 'row wrap',
        width: '100%',
    },
    paperLeft: {
        flex: 5,
        height: '100%',
        marginLeft: 5,
        textAlign: 'center',
        padding: 2,
    },
    paperRight: {
        flex: 2,
        background: '#f9f9f9',
        padding: '20px',
        overflow: 'scroll',
    },
    link: {
        color: Colors.primary,
        textDecoration: 'none',
        textTransform: 'uppercase',
        display: 'inline-block',
        padding: 10,
    },
    tableCell: {
        textAlign: 'center',
    },
    tableData: {
        borderBottom: 'solid 1px #e0e0e0',
        height: '30px',
        padding: 5,
        fontSize: 15,
    },
    titleRow: {
        borderBottom: 'solid 1px #e0e0e0',
        height: '25px',
        padding: 5,
        fontSize: 16,
    },
};

class AudienceSetList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedData: {},
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            hideSidePanel: false,
        };
    }

    handleClick = (audienceSetData) => {
        this.setState({
            selectedData: audienceSetData,
            hideSidePanel: false,
        });
    };

    handleChangePage = (event, page) => {
        const { onPageChange } = this.props;
        onPageChange(page);
    };

    handleChangeRowsPerPage = (event) => {
        const { onRowsPerPageChange } = this.props;
        const { value } = event.target;
        onRowsPerPageChange(value);
    };

    renderSelectedAudienceData = (selectedData) => {
        return (
            <div>
                <Typography variant="title" color="inherit" style={styles.tableData}>
                    <b>Audience Set Details</b>
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Id : </b> {selectedData.id}
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Title : </b> {selectedData.title}
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Description : </b> {selectedData.description}
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Filter Type : </b> {selectedData.filterType}
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Created On : </b> {moment(selectedData.timeCreated).format('lll')}
                </Typography>
                <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Updated On : </b> {moment(selectedData.timeUpdated).format('lll')}
                </Typography>
            </div>
        );
    };

    handleHideRightPanel = () => {
        this.setState({
            hideSidePanel: true,
        });
    };

    onChangeStatus = async (item) => {
        item.active = !item.active
        const response = await apiCall.sendRequest('put', updateOverlaysById(item.id), item);

    }

    render() {
        const {
            audienceSetData, totalElements = 0, rowsPerPage = 20, page = 0,
        } = this.props;
        const { selectedData = {}, rowsPerPageOptions, hideSidePanel } = this.state;
        const isSelected = audienceSetData.some(el => el.id === selectedData.id);

        return (
            <Grid container>
                <div style={{ width: (Object.keys(selectedData).length > 0 && !hideSidePanel) ? '75%' : '98%', float: 'left', height: window.innerHeight * 0.65, overflowY: 'scroll', paddingLeft: '10px' }}>
                    <Table>
                        <TableHeader columns={OfferColumnData} cellStyle={styles.tableCell} />
                        <TableBody>
                            {audienceSetData.map(item => (
                                <TableRow
                                    hover
                                    onClick={() => this.handleClick(item)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={item.id}
                                    selected={!isEmpty(audienceSetData) && audienceSetData.id === item.id}
                                >
                                    <TableCell component="th" scope="row" padding="default">{item.id}</TableCell>
                                    <TableCell component="th" scope="row" padding="none">{item.title}</TableCell>
                                    <TableCell component="th" scope="row" padding="none">{item.description}</TableCell>
                                    <TableCell component="th" scope="row" padding="none" >{moment(item.timeUpdated).format('lll')}</TableCell>
                                    <TableCell component="th" scope="row" padding="none">{item.filterType}</TableCell>
                                    <TableCell component="th" scope="row" padding="none">
                                        <Lozenge appearance={item.active ? "success" : "removed"}>{item.active ? "Active" : "Inactive"}</Lozenge>
                                    </TableCell>
                                    <Tooltip title="Toggle Status">
                                        <TableCell component="th" scope="row" padding="none">
                                            <div>

                                                <Switch
                                                    id="status"
                                                    checked={item.active}
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    onChange={() => this.props.onChangeStatus(item)}
                                                    key={item.id}
                                                />


                                            </div>
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {(audienceSetData && audienceSetData.length > 0) &&
                        <TablePagination
                            component="div"
                            count={totalElements}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                            onChangePage={this.handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                </div>
                {(selectedData && isSelected && !hideSidePanel) &&
                    <div style={{ width: '25%', float: 'right', height: '700px', overflowY: 'scroll', paddingRight: '10px', paddingLeft: '5px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2" color="secondary" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                                Audience Set Details
                            </Typography>
                            <IconButton>
                                <CloseIcon onClick={this.handleHideRightPanel} />
                            </IconButton>
                        </div>
                        <div style={{ border: '1px solid black', borderRadius: '10px' }}>
                            {this.renderSelectedAudienceData(selectedData)}
                        </div>
                    </div>

                }
            </Grid>
        );
    }
}


AudienceSetList.propTypes = {
    totalElements: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    audienceSetData: PropTypes.instanceOf(Array),
    onChangeStatus: PropTypes.func,
};

export default AudienceSetList;

