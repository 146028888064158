import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputLabel,
} from '@material-ui/core';
import BASE_URL from '../../../api/config';
import TableHeader from '../../Commons/components/TableHeader';
import SearchWithSuggestions from "../../Commons/components/SearchWithSuggestions";
import apiCall, { findPackaging } from '../../../api/NetworkHandler';
import { Modal } from 'antd';
import GstSelection from '../../../components/GstSelection';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Lozenge from '@atlaskit/lozenge';


const columnData = [
  {
    id: 'vendorProductItemId', numeric: false, disablePadding: true, label: 'Product Item ID',
  },
  {
    id: 'product', numeric: false, disablePadding: true, label: 'Product Item Name',
  },
  {
    id: 'hsnCode', numeric: false, disablePadding: true, label: 'HSN Code',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'sellingPrice', numeric: false, disablePadding: true, label: 'Cost Price (Exclusive Tax)',
  },
  {
    id: 'unitPrice', numeric: false, disablePadding: true, label: 'MRP',
  },
  {
    id: 'tax', numeric: false, disablePadding: true, label: 'Tax Rate (%)',
  },
  {
    id: 'total_price', numeric: false, disablePadding: true, label: 'Total Price',
  },
  {
    id: 'purchaseItemType', numeric: false, disablePadding: true, label: 'Purchase Item Type',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [],
      rowSelected: false,
      packagingList: [],
      openPackageSelector: false,
      linePackaging: null,
      lineIndex: null,
      selectedKey: null,
      selectedIndex: null,
      openGstSelector: false,
      selectedVendorProductItemId: null,
    };
  }

  componentWillMount() {
    const { items } = this.props;
    this.setState({
      data: items,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { items = [] } = nextProps;
    return {
      data: items,
    };
  }

  handleChange = (event, index, fieldName) => {
    const { onChange } = this.props;
    const { data: modfiedItems } = this.state;
    if (fieldName) {
      modfiedItems[index].packaging = event.name;
      this.setState({ data: modfiedItems });
      onChange(modfiedItems);
    } else {
      const { name, value } = event.target;
      const mrp = modfiedItems[index].unitPrice;
      const tax = modfiedItems[index].tax;
      const marginType = modfiedItems[index].marginType;
      const marginPercent = modfiedItems[index].marginPercentage;
      if (["quantity", "unitPrice", "sellingPrice"].includes(name) && value < 0) return;
      if (name === 'uomId') {
        modfiedItems[index].uom = `${BASE_URL.BASE_URL}/api/v1/uoms/${value}`;
      }
      
      modfiedItems[index][name] = value;
      this.setState({ data: modfiedItems });
      onChange(modfiedItems);
    }
  }

  getAllPackagingList = async (query = '') => {
    try {
      const response = await apiCall.sendRequest('get', findPackaging(query));
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      const { packaging = [] } = embedded;
      this.setState({
        packagingList: packaging,
      });
    } catch (error) {
      this.setState({
        packagingList: [],
      });
    }
  }

  handlePackagingSearch = (query = '') => {
    this.setState({
      inputData: query,
    });
    this.getAllPackagingList(query);
  };

  handleOpenPackageSelector = (linePackaging, lineIndex) => {
    this.setState({
      openPackageSelector: true,
      linePackaging: linePackaging,
      lineIndex: lineIndex,
    })
  };

  handleClose = () => {
    this.setState({
      openPackageSelector: false,
      lineIndex: null,
      linePackaging: null,
    });
  };

  removeItem = (index) => {
    const { onChange, onRowClick } = this.props;
    const { data: modItems } = this.state;
    const isSelected = this.isSelected(modItems[index].vendorProductItemId);
    modItems.splice(index, 1);
    if (isSelected) {
      if (modItems.length) {
        this.onRowClick(modItems[0])
      } else {
        onRowClick();
        this.setState({
          rowSelected: false,
          selected: null
        })
      }
    }
    this.setState({ data: modItems });
    onChange(modItems);
  }

  isSelected = id => this.state.selected === id

  onRowClick = (selected) => {
    const { onRowClick } = this.props;
    this.setState({ selected: selected.vendorProductItemId });
    onRowClick(selected);
  }

  setSelectedRow = () => {
    const { data = [], rowSelected } = this.state;

    if (!rowSelected && data.length) {
      this.setState({
        rowSelected: true,
      })
      this.onRowClick(data[0]);
    }
    if (rowSelected && !data.length) {
      this.setState({
        rowSelected: false,
        selected: null
      })
    }
  }

  openGstSelector = (event, index) => {
    const { name } = event.target;
    const { poStatus = 'NEW', editable = true } = this.props;
    this.setState({
      openGstSelector: poStatus === "NEW" && editable ? true : false,
      selectedKey: name,
      selectedIndex: index,
    });
  }

  handleGstSelectorClose = () => {
    this.setState({
      openGstSelector: false,
    });
  }

  selectGst = (gstData) => {
    const { onChange } = this.props;
    const { data: modfiedItems, selectedIndex, selectedKey } = this.state;
    let calculateTax = gstData.igst ? gstData.igst : (gstData.sgst + gstData.cgst);
    modfiedItems[selectedIndex][selectedKey] = gstData.hsnCode;
    modfiedItems[selectedIndex]["tax"] = calculateTax;
    this.setState({ 
      data: modfiedItems,
      openGstSelector: false,
    });
    onChange(modfiedItems);
  }


  render() {
    const {
      classes, UOM = [], Packaging, editable = true, hideCol = [], expandRow, getExpandedComponent, poStatus = 'NEW', taxType, emptyQuantityText,
    } = this.props;
    const {
      data = [], packagingList = [], openPackageSelector, lineIndex, linePackaging, openGstSelector, selectedVendorProductItemId
    } = this.state;
    const modifiedColumnData = taxType !== 'gst' ? columnData.filter(col => col.id !== 'hsnCode') : columnData;
    const prColumnData = modifiedColumnData.filter(n => !hideCol.includes(n.id));
    this.setSelectedRow();
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {hideCol.length === null ? <TableHeader columns={modifiedColumnData} /> : <TableHeader columns={prColumnData} />}
            <TableBody>
              {data.map((n, index) => {
                const isSelected = n.vendorProductItemId ? this.isSelected(n.vendorProductItemId) : false;
                const isPriceValidated = parseFloat((n.sellingPrice * (1 + 0.01 * n.tax)).toFixed(2)) > parseFloat(n.unitPrice);
                const calculateMaxCost = (n.unitPrice / (1 + (n.tax / 100))).toFixed(2);
                return (
                  <React.Fragment>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    onClick={() => { this.onRowClick(n) }}
                  >
                    { !hideCol.includes("vendorProductItemId") &&
                      <TableCell style={{ color: '#f50057', width: '100px' }} component="th" scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            style={{ width: '90px' }}
                            value={n.vendorProductItemId}
                            name="vendorProductItemId"
                            disabled
                          />
                        </div>
                    </TableCell>}
                    { !hideCol.includes("productName") &&
                    <TableCell style={{ color: '#f50057', width: '550px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 5 }}>
                        <TextField
                          style={{ width: '95%' }}
                          value={n.productName}
                          name="productName"
                          disabled={!editable}
                        />
                      </div>
                    </TableCell>}
                    { (!hideCol.includes("hsnCode") && taxType === 'gst') &&
                    <TableCell style={{ width: '200px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="text"
                          style={{ width: '190px' }}
                          disabled
                          value={n.hsnCode}
                          name="hsnCode"
                          // onClick={e => this.openGstSelector(e, index)}
                          autoComplete="off"
                        />
                      </div>
                    </TableCell>}
                    { !hideCol.includes("quantity") &&
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          style={{ width: '50px' }}
                          disabled={!editable}
                          value={n.quantity}
                          name="quantity"
                          error={!n.quantity}
                          helperText={!n.quantity && "*required"}
                          onChange={e => this.handleChange(e, index)}
                        />
                      </div>
                    </TableCell>}
                    { !hideCol.includes("sellingPrice") &&
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          name="sellingPrice"
                          style={{ width: '80px' }}
                          value={n.sellingPrice}
                          error={n.sellingPrice < 0 || isPriceValidated}
                          helperText={n.sellingPrice < 0 && "*required" || isPriceValidated && `Max ${calculateMaxCost} allowed`}
                          onChange={e => this.handleChange(e, index)}
                          disabled={n.purchaseItemType === 'SAMPLE'}
                        />
                      </div>
                    </TableCell>}
                    { !hideCol.includes("unitPrice") &&
                    <TableCell style={{ width: '70px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          name="unitPrice"
                          style={{ width: '80px' }}
                          disabled={!editable}
                          value={n.unitPrice}
                          error={!n.unitPrice}
                          helperText={!n.unitPrice && "*required"}
                          onChange={e => this.handleChange(e, index)}
                        />
                      </div>
                    </TableCell>}
                    { !hideCol.includes("tax") &&
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          name="tax"
                          style={{ width: '80px' }}
                          disabled
                          value={n.tax}
                          onChange={e => this.handleChange(e, index)}
                        />
                      </div>
                    </TableCell>}
                    { !hideCol.includes("total_price") &&
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 2 }}>
                        <TextField
                          type="number"
                          name="total_price"
                          style={{ width: '80px' }}
                          value={(n.quantity * n.sellingPrice * (1 + 0.01 * n.tax)).toFixed(2)}
                          disabled
                        />
                      </div>
                    </TableCell>}
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="default">
                      <div style={{ flex: 2 }}>
                        <Lozenge appearance={n.purchaseItemType === 'PAID' ? 'success' : 'removed'} isBold>{n.purchaseItemType}</Lozenge>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: '100px' }} component="th" scope="row" padding="none">
                      <IconButton>
                        <DeleteIcon color="action" variant="outlined" disabled={!editable} onClick={(e) => { e.stopPropagation(); this.removeItem(index)}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                    {expandRow && isSelected &&
                      <TableRow>
                        <TableCell colSpan={9}>
                          {getExpandedComponent()}
                        </TableCell>
                      </TableRow>
                    }
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          <Modal
            title="Packaging Selector"
            visible={openPackageSelector}
            onOk={this.handleClose}
            onCancel={this.handleClose}
            cancelText="Close"
           >
            <div style={{ width: '50%', margin: '0 auto', height: '100px' }}>
              <InputLabel style={{ fontSize: '1em' }}> Search packaging </InputLabel>
              <SearchWithSuggestions
                searchQuery={linePackaging}
                suggestions={packagingList}
                onChange={this.handlePackagingSearch}
                onSelect={e => this.handleChange(e, lineIndex, 'packaging')}
              /> 
            </div>
          </Modal>
          <GstSelection openSearchBox={openGstSelector} handleClose={this.handleGstSelectorClose} selectGst={this.selectGst} />
        </div>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.instanceOf(Object),
  UOM: PropTypes.instanceOf(Array),
  items: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  onRowClick: PropTypes.func,
  expandRow: PropTypes.bool,
  getExpandedComponent: PropTypes.func,
};

EnhancedTable.defaultProps = {
  onRowClick: () => { },
  expandRow: false,
  getExpandedComponent: () => { },
}

const mapStateToProps = state => ({
  UOM: state.UOM,
  Packaging: state.Packaging,
  taxType: state.taxType,
});

export default connect(mapStateToProps, null)(withStyles(styles)(EnhancedTable));
