import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
// import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import styles from './styles';

class Actions extends Component {
  render() {
    const {
      isUpdateDisabled = false, onUpdate, onDelete, isPlans, onEdit, isDeleteAllowed, viewHistory, showDeletedSchedules,
      isCustomerPaused = false, categoryPause = false } = this.props;

    return (
      <div style={styles.actionBox}>
        {!isCustomerPaused && !categoryPause && (
          <React.Fragment>
            {!isPlans && (
              <Button
                disabled={isUpdateDisabled}
                onClick={() => onUpdate()}
                color="primary"
                variant="outlined"
                size="small"
                style={styles.actionBtn}
              >
                UPDATE
              </Button>
            )}
            {isPlans && !showDeletedSchedules && (
              <Tooltip title="Edit" aria-label="Edit">
                <Button
                  onClick={() => onEdit()}
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={styles.actionBtn}
                >
                  <EditIcon style={styles.actionIcon} />
                </Button>
              </Tooltip>
            )}
            {!showDeletedSchedules && (
              <Tooltip title="Delete" aria-label="Delete">
                <div>
                  <Button
                    onClick={() => onDelete()}
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={styles.actionBtn}
                    disabled={isDeleteAllowed}
                  >
                    <DeleteIcon style={styles.actionIcon} />
                  </Button>
                </div>
              </Tooltip>
            )}
          </React.Fragment>
        )}
        {/* {isPlans && ( */}
          <Tooltip title="History" aria-label="History">
            <div>
              <Button
                onClick={() => viewHistory()}
                color="primary"
                variant="outlined"
                size="small"
                style={styles.actionBtn}
              // disabled={isDeleteAllowed}
              >
                <HistoryIcon style={styles.actionIcon} />
              </Button>
            </div>
          </Tooltip>
        {/* )} */}
      </div>
    );
  }
}

Actions.propTypes = {
  isUpdateDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  isPlans: PropTypes.bool,
  onEdit: PropTypes.func,
  viewHistory: PropTypes.func,
  showDeletedSchedules: PropTypes.func,
  isCustomerPaused: PropTypes.bool,
  categoryPause: PropTypes.bool,
};

export default Actions;
