import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import BeenhereIcon from 'material-ui-icons/Beenhere';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import NavBar from './NavBar.js';
import StockLedgerTable from './StockLedgerTable';
import StockLedgerSearchBar from './StockLedgerSearchBar';
import apiCall from "../api/NetworkHandler";

import { ColMod, ListMod, DisplayMod1, CodeStyleMod, DividerMod, PaperMod } from './lib';

const styles = {
  div:{
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft:{
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10
  },
  paperRight:{
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  }
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      links: {},
			searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchAllStockLedgers();
  }

  fetchAllStockLedgers = () => {
    apiCall.sendRequest('get', '/api/v1/stock-ledger')
      .then((response) => {
        this.setState({
          data: response.data._embedded["stock-ledger"],
          links: response.data._links,
					searchedResult: false,
        });
      });
  }

  fetchPaginatedSL = (url) => {
		apiCall.sendRequest('get', url)
      .then(({ data }) => {
        this.setState({
					data: data._embedded['stock-ledger'],
					links: data._links,
					searchedResult: false,
        });
      })
      .catch(err => {
        console.log(err);
      });
	}
  
  onSearch = (filter) => {
		const queryParam = [];
    const query = filter.query ? `query=${filter.query}` : '';
    if (query) { queryParam.push(query); }
    const warehouseId = filter.warehouseId ? `id=${filter.warehouseId}` : '';
    if (warehouseId) { queryParam.push(warehouseId); }
    const startDate = filter.startDate ? `startDate=${filter.startDate}` : '';
    if (startDate) { queryParam.push(startDate); }
    const endDate = filter.endDate ? `endDate=${filter.endDate}` : '';
    if (endDate) { queryParam.push(endDate); }
		let url = `/api/v1/stock-ledger/search/findStockLedgers?${  queryParam.join('&')}`;
		
		if (queryParam.length === 0) {
      url = '/api/v1/stock-ledger';
    }
    apiCall.sendRequest('get', url)
      .then(res => {
        this.setState({
          data: res.data._embedded["stock-ledger"],
          links: res.data._links,
					searchedResult: true,
        });
      })
      .catch(err => {
        console.log(err);
      });
	}

  render() {
    return (    
      <div>
        <NavBar />
        <StockLedgerSearchBar onSearch={this.onSearch} />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <StockLedgerTable data={this.state.data} links={this.state.links} fetchPaginatedSL={this.fetchPaginatedSL} searchedResult={this.state.searchedResult}/>
          </div>
				</div>
      </div>
    );
  }
}


export default Dashboard;
