import configDev from './config.dev';
import configProd from './config.prod';
import configDevUae from './config.dev-uae';
import configProdUae from './config.prod-uae';
import { getLatestVersion } from '../utils/LatestVersion';

var config = configProd;
if (process.env.NODE_ENV == 'development') config = configDev;
else if (process.env.NODE_ENV == 'dev-uae') config = configDevUae;
else if (process.env.NODE_ENV == 'prod-uae') config = configProdUae;


// env is pulled from config.js in root dir.
export default config;

//inittally setting version from back-end API
export const version = getLatestVersion();
