import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const placeholder = {
  color: 'blue',
};

const theme = createMuiTheme({
  spacing: {
    unit: 8,
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: '#fff1f4',
        },
        '&$hover:hover': {
          backgroundColor: '#fff1f4',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #f50057',
        },
      },
      input: {
        '&::-webkit-input-placeholder': placeholder,
        '&::-moz-placeholder': placeholder, // Firefox 19+
        '&:-ms-input-placeholder': placeholder, // IE 11
        '&::-ms-input-placeholder': placeholder, // Edge
      },
    },
    MuiBackdrop: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        color: 'white',
      },
    },
  },
});

class Layout extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
