import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@atlaskit/select';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ENTITY_LIST } from '../../../constants';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';

const styles = {
  searchBar: {
    background: Colors.grey[100],
    paddingTop: '10px',
    paddingBottom: '20px',
  },
  paddingDiv: {
    paddingTop: '10px',
  },
  helperText: {
    color: 'red',
    marginTop: '-10px',
  },
};

const actionOptions = [
  { label: 'All', value: '' },
  { label: 'Inserted', value: 'INSERTED' },
  { label: 'Updated', value: 'UPDATED' },
  { label: 'Deleted', value: 'DELETED' },
];

let entities = [];
Object.keys(ENTITY_LIST).map((key) => {
  let data = ENTITY_LIST[key].map(entity => ({
    label: entity,
    value: entity,
    dbName: key === 'scm' ? 'scm' : 'app'
  }))
  entities = entities.concat(data);
});
entities.unshift({
  label: 'All SCM',
  value: 'All SCM',
  dbName: 'scm',
}, {
  label: 'All Customer',
  value: 'All Customer',
  dbName: 'app',
});

function AuditSearch(props) {
  const {
    startDate,
    endDate,
    modifiedBy,
    action,
    entity,
    onChangeFilter,
    onClearFilters,
    users,
    entityKey,
    entityText,
    classes,
    onSearchHandler,
  } = props;

  return (


    <Grid className={classes.searchBar} fluid>
      <Row md={12} middle="md">
        <Col md={12}>
          <Typography variant="title" color="inherit">
            Audit Report
          </Typography>
        </Col>
        <Col md={12}>
          <Row md={12} between="md" middle="md" style={styles.paddingDiv}>
            <Col md={2}>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                fullWidth
                onChange={onChangeFilter}
                value={startDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
            <Col md={2}>
              <TextField
                type="date"
                label="End Date"
                name="endDate"
                fullWidth
                onChange={onChangeFilter}
                value={endDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
            <Col md={2}>
              <React.Fragment>
                <Typography variant="caption"> Modified By </Typography>
                <Select
                  value={modifiedBy}
                  options={users}
                  onChange={(value) => { onChangeFilter({ target: { name: 'modifiedBy', value } }); }}
                />
              </React.Fragment>
            </Col>
            <Col md={2}>
              <React.Fragment>
                <Typography variant="caption"> Action </Typography>
                <Select
                  value={action}
                  options={actionOptions}
                  onChange={(value) => { onChangeFilter({ target: { name: 'action', value } }); }}
                />
              </React.Fragment>
            </Col>
            <Col md={3}>
              <React.Fragment>
                <Typography variant="caption"> Entity </Typography>
                <Select
                  value={entity}
                  options={entities}
                  onChange={(value) => { onChangeFilter({ target: { name: 'entity', value } }); }}
                />
              </React.Fragment>
            </Col>
            <Col md={12}>
              <Row md={12} middle="md" style={styles.paddingDiv}>
                <Col md={2} >
                  <TextField
                    label="Entity Id"
                    fullWidth
                    value={entityKey || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => { onChangeFilter({ target: { name: 'entityKey', value: event.target.value } }); }}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    label="Entity Text"
                    fullWidth
                    value={entityText || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => { onChangeFilter({ target: { name: 'entityText', value: event.target.value } }); }}
                  />
                </Col>
                <ColMod md={1}>
                  <Button color="secondary" variant="contained" onClick={onSearchHandler}>Search</Button>
                </ColMod>
                <ColMod md={1}>
                  <Button color="secondary" variant="contained" onClick={onClearFilters}>Clear</Button>
                </ColMod>
              </Row>
            </Col>
            <Col md={12}>
              <Row md={12} middle="md" style={styles.paddingDiv}>
                <FormHelperText id="name-error-text" style={styles.helperText} ><span>*</span>Please select Start Date and End Date and any of one the field to search with Entity Text</FormHelperText>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
}

AuditSearch.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  modifiedBy: PropTypes.string,
  action: PropTypes.string,
  entityKey: PropTypes.string,
  entityText: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onSearchHandler: PropTypes.func,
};

AuditSearch.defaultProps = {
  startDate: '',
  endDate: '',
  modifiedBy: '',
  action: '',
  entityKey: '',
  entityText: '',
  onSearchHandler: () => { },
};

export default withStyles(styles)(AuditSearch);
