import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import apiCall, { getOverlaysByFilters, updateOverlaysById } from '../../../api/NetworkHandler';
import AudienceSetSearch from '../components/AudienceSetSearch';
import AudienceSetList from '../components/AudienceSetList';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';


const Constants = {
  title: 'Audience Set',
};

var versionDict = {
  'getOverlayRequestId': 0,
};

class AudienceSetContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audienceSetData: [],
      filters: {
        query: '',
        filterType: 'CUSTOMER',
      },
      totalElements: 0,
      currentPage: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount = () => {
    this.fetchParams();
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    });
    this.fetchOverlaysByFilters(filters, page);
  };

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({
      filters: getFilterParams
    }, () => this.fetchOverlaysByFilters());
  }

  fetchOverlaysByFilters = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    try {
      versionDict.getOverlayRequestId += 1;
      let prevRequestId = versionDict.getOverlayRequestId;
      const url = getOverlaysByFilters(filters, page, size);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data: { content = [], totalElements = 0 } } } = res;
      if (versionDict.getOverlayRequestId == prevRequestId) {
        this.setState({
          audienceSetData: content,
          totalElements,
        });
      }
    } catch (e) {
      this.setState({
        audienceSetData: [],
        totalElements: 0,
      });
    }
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.fetchOverlaysByFilters() })
  };

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.fetchOverlaysByFilters() })
  };

  onChangeStatus = async (item) => {
    try {
      item.active = !item.active
      await apiCall.sendRequest('put', updateOverlaysById(item.id), item);
    } catch (error) {
      console.error(error);
    }
    this.fetchOverlaysByFilters()
  }

  render() {
    const { overlayFilterTypes } = this.props;
    const {
      audienceSetData, filters, totalElements, currentPage, rowsPerPage,
    } = this.state;

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <AudienceSetSearch overlayFilterTypes={overlayFilterTypes} />
        </SearchBar>
        <AudienceSetList
          audienceSetData={audienceSetData}
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={this.onPageChange}
          onRowsPerPageChange={this.onRowsPerPageChange}
          onChangeStatus={this.onChangeStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  overlayFilterTypes: state.Status.overlayFilterTypes,
});

export default connect(mapStateToProps, null)(withRouter(AudienceSetContainers));
