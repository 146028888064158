import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InputAdornment,
  FormControl,
  Input,
  Button,
  Tooltip
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Popconfirm, Input as AntdInput } from 'antd';
import utils from '../../../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;

class CatalogSearch extends Component {

  handleChange = (event) => {
    const { onFilterChange } = this.props;
    const { name, value } = event.target;
    onFilterChange(name, value);
  };

  render() {
    const { filters = {}, onSearch, onCreate, onPublishAll, disablePublishAll, onDownloadMOQStats, disableMOQStats } = this.props;
    const { query } = filters;
    const isPublishAllVisible = canView(['ADMIN', 'CATALOG_MANAGER']);
    return (
      <div style={{ padding: '0.5em 2em' }}>
        <Grid container>
          <Grid item md={isPublishAllVisible ? 4 : 6}>
            <FormControl color="secondary" style={{ width: '100%' }}>
              <Input
                value={query}
                color="secondary"
                label="Search"
                fullWidth
                name="query"
                onChange={this.handleChange}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onSearch();
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                autoComplete='off'
              />
            </FormControl>
          </Grid>
          {isPublishAllVisible &&
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <Popconfirm
                title="Are you sure to process all the catalog?"
                onConfirm={onPublishAll}
                onCancel={() => { }}
                okText="Yes"
                cancelText="No"
              >
                <Button color="secondary" variant="contained" disabled={disablePublishAll}> Process All </Button>
              </Popconfirm>
            </Grid>
          }
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <Button color="primary" variant="outlined" onClick={() => onCreate(true)}>
              Create New Catalog
            </Button>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <Tooltip
              title="Download MOQ Stats"
            >
              <Button color="primary" variant="contained" onClick={onDownloadMOQStats} disabled={disableMOQStats}> MOQ Stats </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CatalogSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
  onUpload: PropTypes.func,
};

export default CatalogSearch;
