import React, { Component } from 'react';
import moment from 'moment';
import NavBar from '../components/NavBar';
import SearchBar from '../modules/Commons/containers/SearchBar';
import DetailedReportsSearch from '../modules/DeliveryBoys/components/DRSearch';
import apiCall, { fetchDeliveryBoysDetailedReports, fetchDeliveryBoysDRByPhone } from '../api/NetworkHandler';
import DRList from '../modules/DeliveryBoys/components/DRList';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';
import debounce from 'lodash/debounce';

const defaultDate = moment()
  .format('YYYY-MM-DD');

const defaultStartDate = moment()
  .subtract(7, 'days')
  .format('YYYY-MM-DD');

  var versionDict = { 
    'drList' :0,
  }

class DeliveryDetailedReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        query: '',
        startDate: defaultStartDate,
        endDate: defaultDate,
        prId: '',
        status: null,
      },
      detailedReportsList: [],
      totalElements: 0,
      currentPage: 0,
    };
  }

  componentDidMount() {
    // this.fetchWarehouses();
    this.fetchDrList();
  }

  fetchWarehouses = async () => {
    try {
      const response = await API.sendRequest('get', getWareHouse);
      const {
        data: { _embedded = {} },
      } = response;
      this.setState({
        warehouses: _embedded.warehouse,
      });
    } catch (e) {
      this.setState({ warehouses: [] });
    }
  };

  fetchDrList = async (page = 0) => {
    versionDict.drList += 1;
    let prevDrlistVersion = versionDict.drList;
    const { filters } = this.state;
    const { startDate, endDate, query } = filters;
    if (query) {
      const response = await apiCall.sendRequest('get', fetchDeliveryBoysDRByPhone(query));
      if (response.data && versionDict.drList === prevDrlistVersion) {
        const { data: { content = [], totalElements = 0 } = {}, data = {} } = response.data;
        this.setState({
          detailedReportsList: data,
          totalElements,
        });
      }
    } else {
      const response = await apiCall.sendRequest('get', fetchDeliveryBoysDetailedReports(startDate, endDate));
      if (response.data && versionDict.drList === prevDrlistVersion) {
        const { data: { content = [], totalElements = 0 } = {}, data = {} } = response.data;
        this.setState({
          detailedReportsList: content,
          totalElements,
        });
      }
    }
  };

  handleFilterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  handleChange = debounce((filters, refresh = false) => {
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.fetchDrList();
        }
      },
    );
  },750);

  handleSearch = debounce((page = 0) => {
    this.setState({
      currentPage: page,
    });
    this.fetchDrList(page);
  },500);

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.handleSearch();
        break;
      case 'clear':
        this.clearAll();
        break;
      default:
        break;
    }
  };

  clearAll = () => {
    this.setState(
      {
        filters: {
          query: '',
          startDate: defaultStartDate,
          endDate: defaultDate,
          prId: '',
        },
      },
      () => {
        this.handleSearch();
      },
    );
  };

  render() {
    const {
      filters, detailedReportsList = [], warehouses, totalElements,
      currentPage = 0,
    } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title="Delivery Boys Detailed Reports"
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <DetailedReportsSearch warehouses={warehouses} />
        </SearchBar>
        <div>
          <DRList
            data={detailedReportsList}
            totalElements={totalElements}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={currentPage}
            onPageChange={this.handleSearch}
          />
        </div>
      </div>
    );
  }
}

export default DeliveryDetailedReports;
