import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import RegionSelector from '../../../components/Region/Selector';
import apiCall, {
  productCatalogsPath
} from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';

class CatalogForm extends Component {
  constructor(props) {
    super();
    this.state = {
      catalog: {
        name: '',
        regions: null
      },
      selectedRegion: null,
      error: {
        name: false,
        regions: false
      },
      errorMessage: '',
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const { catalog, error } = prevState;
      return {
        catalog: {
          ...catalog,
          [name]: value
        },
        error: {
          ...error,
          [name]: !value,
        }
      }
    })
  }

  onRegionChange = (region) => {
    this.setState({ selectedRegion: region });
    let regions = [];
    if (region) {
      regions.push(region);
    } else {
      regions = null;
    }
    this.handleInputChange({ target: { name: "regions", value: regions } });
  }

  isFormValid = () => {
    const { error, catalog } = this.state;
    let isFormValid = true;
    for (const key in error) {
      if (!catalog[key]) {
        error[key] = true;
        isFormValid = false;
      } else {
        error[key] = false;
      }
    }
    if (!isFormValid) {
      this.setState({ error });
    }
    return isFormValid;
  };

  handleSave = async () => {
    if (!this.isFormValid()) {
      return;
    }
    const { catalog } = this.state;
    const { onClose, onRefresh } = this.props;
    catalog.status = "NEW";
    try {
      await apiCall.sendRequest('post', productCatalogsPath, catalog);
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }
  };

  render() {
    const {
      catalog,
      selectedRegion,
      error,
      errorMessage,
    } = this.state;
    const {
      name,
    } = catalog;

    const { onClose } = this.props;

    return (
      <Grid style={{width: window.innerWidth * 0.4}}>
        <Row between="lg" middle="lg">
          <ColMod>
            <Row>
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose(); }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  New Catalog
                </Typography>
                <Typography variant="caption" color="error">
                  *Required Field
                </Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <ColMod>
              <Button onClick={() => { onClose(); }} style={{ marginRight: '2em', color: 'black' }}>
                Cancel
                </Button>
              <Button onClick={this.handleSave} variant="contained" color="secondary">
                Save
                </Button>
            </ColMod>
          </ColMod>
        </Row>
        {errorMessage && <Row>
          <ColMod lg={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row middle="lg">
          <ColMod lg={6}>
            <TextField
              fullWidth
              value={name || ''}
              label="Name"
              name="name"
              onChange={this.handleInputChange}
              required
              error={error.name}
            />
          </ColMod>
          <ColMod lg={6}>
            <InputLabel style={{ fontSize: "0.8rem" }} required error={error.regions}>Region</InputLabel>
            <RegionSelector
              selected={selectedRegion}
              onSelect={this.onRegionChange}
            />
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default CatalogForm;
