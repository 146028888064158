import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@atlaskit/select';
import SectionMessage from '@atlaskit/section-message';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import apiCall, { updateDump, updatePhysicalCheck, getLastFiveLedgerEntries, updateStockByTransactionType } from '../../../api/NetworkHandler';
import { debounce } from 'lodash';

const innerWidth = window.innerWidth;
const containerWidth = innerWidth < 600 ? innerWidth * 0.7 : innerWidth * 0.5;

const styles = {
  container: {
    width: containerWidth,
  },
  arrow: {
    cursor: "pointer",
  },
  label: {
    marginBottom: '1em',
  },
  error: {
    color: 'red',
  }
}

const transactionTypes = [
  { label: "Physical Check", value: "Physical Check" },
  { label: "Dump", value: "Dump" },
  { label: "Closing Stock", value: "Closing Stock" },
  {label: "Internal Transfer", value: "Internal Transfer"},
  {label: "Return to Vendor", value: "Return to Vendor"}
]

const numPattern = new RegExp(/[^0-9\s]/g);

class StockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockData: {
        quantity: '',
        remarks: ''
      },
      warehouseName: '',
      vendorProductItemName: '',
      uom: {},
      selectedTransactionType: null,
      remarks: '',
      quantity: '',
      error: {},
      errorMessage: '',
      lastFiveEntries: [],
      disableButton: false,
    }
  }

  componentDidMount() {
    const { stock: { warehouseId, vendorProductItemId, warehouseName, vendorProductItemName, uom } } = this.props;

    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        warehouseName,
        vendorProductItemName,
        uom,
        stockData: {
          ...stockData,
          warehouseId,
          vendorProductItemId
        }
      }
    }, () => { this.fetchLastFiveEntries() })
  }

  fetchLastFiveEntries = async () => {
    const { stockData: { vendorProductItemId, warehouseId} } = this.state;
    try {
      const response = await apiCall.sendRequest('get', getLastFiveLedgerEntries(vendorProductItemId, warehouseId));
      const { data: { _embedded: { "vendor-stock-ledger": lastFiveEntries } } } = response;
      this.setState({ lastFiveEntries });
    } catch (error) {
      console.log(error);
    }
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  isPropertyValid = (property, value) => {
    if (property == "quantity" && value == 0 && this.state.selectedTransactionType.value === "Dump") {
      this.setError(property, true);
      this.setError("dumpQuantity", true);
      return false;
    }
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    this.setError("dumpQuantity", false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;

    const { selectedTransactionType, stockData } = this.state;

    if (!selectedTransactionType) {
      isFormValid = false;
      this.setError("transaction", true);
    } else {
      this.setError("transaction", false);
    }

    const validEntityTypes = ["quantity", "remarks"];

    for (const property in stockData) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, stockData[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  getTransactionType = (selectedTransactionType) => {
    if (Object.keys(selectedTransactionType).length == 0) return '';
    switch (selectedTransactionType.value) {
      case "Dump": return 'with-damage';
      case "Internal Transfer": return 'internal-transfer';
      case "Return to Vendor": return 'return-to-vendor';
      default: return 'adjust-stock';
    }
  }

  toggleButton = (state) => {
    this.setState({
      disableButton: state,
    });
  }

  onSave = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }
    this.toggleButton(true);
    let { selectedTransactionType, stockData } = this.state;
    const transactionType = this.getTransactionType(selectedTransactionType);

    // const url = selectedTransactionType.value == "Dump" ? updateDump : updatePhysicalCheck;

    try {
      const { onClose, onRefresh } = this.props;
      if (selectedTransactionType.value === 'Closing Stock') {
        stockData.remarks = `${stockData.remarks} - (Closing Stock)`;
      }
      await apiCall.sendRequest('post', updateStockByTransactionType(transactionType), stockData);
      onClose();
      onRefresh();
      this.toggleButton(false);
    } catch (error) {
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
      this.toggleButton(false);
    }
  }, 500);

  onWareouseChange = (selectedWarehouse) => {
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        selectedWarehouse,
        stockData: {
          ...stockData,
          warehouseId: selectedWarehouse.id,
        }
      }
    })
  }

  onProductItemChange = (selectedItem) => {
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        selectedItem,
        stockData: {
          ...stockData,
          vendorProductItemId: selectedItem.value,
        }
      }
    })
  }

  onTransactionChange = (selectedTransactionType) => {
    this.setState({ selectedTransactionType });
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'quantity' && (value < 0 || numPattern.test(value))) {
      return;
    } else {
      this.setState(prevState => {
        const { stockData } = prevState;
  
        return {
          stockData: {
            ...stockData,
            [name]: value,
          }
        }
      })
    }
  }

  canAcceptDecimalValue() {
    const { uom: { code } } = this.state;
    const uomWithDecimal = ["G", "GM", "KG"];
    if (uomWithDecimal.includes(code.toUpperCase())) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, onClose, warehouseName, stock } = this.props;
    const {
      vendorProductItemName,
      uom,
      selectedTransactionType,
      stockData: { quantity, remarks },
      error,
      errorMessage,
      lastFiveEntries,
      disableButton,
    } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md" bottom="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Update Stock</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
            <Button variant="contained" color="secondary" onClick={this.onSave} disabled={disableButton}>Save</Button>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row xs={12}>
          <ColMod xs={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod md={6}>
            <Typography variant="caption"> Warehouse </Typography>
            <TextField
              value={warehouseName || ''}
              fullWidth
              inputProps={{ readonly: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> Vendor Product Item </Typography>
            <TextField
              value={vendorProductItemName || ''}
              fullWidth
              inputProps={{ readonly: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> UOM </Typography>
            <TextField
              value={uom.code || ''}
              fullWidth
              inputProps={{ readonly: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> Unit Measure </Typography>
            <TextField
              value={stock.unitMeasure || ''}
              fullWidth
              inputProps={{ readonly: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              type="number"
              label="Quantity"
              name="quantity"
              value={quantity || ''}
              onChange={this.onInputChange}
              fullWidth
              required
              helperText={error.dumpQuantity && "0 dump not allowed"}
              error={error.quantity}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption" color={error.transaction ? 'error' : 'default'}> Transaction Type * </Typography>
            <Select
              options={transactionTypes}
              selected={selectedTransactionType}
              onChange={this.onTransactionChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              label="Remarks"
              name="remarks"
              value={remarks || ''}
              onChange={this.onInputChange}
              inputProps={{ maxLength: 255 }}
              fullWidth
              required
              error={error.remarks}
            />
          </ColMod>
        </Row>
        <Row xs={12}>
          <ColMod xs={12}>
            <Typography variant="subheading">
              Last Five Entries
            </Typography>
          </ColMod>
          <ColMod xs={12}>
            <Paper style={{ overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lastFiveEntries.map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.vendorStockTransactionType}
                        </TableCell>
                        <TableCell>{Math.abs(row.quantity)}</TableCell>
                        <TableCell>{row.remarks}</TableCell>
                        <TableCell>{row.createdBy}</TableCell>
                        <TableCell style={{ minWidth: '150px' }}>{moment(row.timeCreated).format('DD-MM-YYYY hh:mm:ss a')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(StockForm);