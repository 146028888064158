import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiCall, { updatePincodePrefix } from '../../../api/NetworkHandler';
import moment from 'moment';
import {
  TableBody,
  Typography,
  Modal,
  TextField,
  TableRow,
  TableCell,
  Button,
  Table,
  Grid,
  Chip
} from '@material-ui/core/';
import TableHeader from '../../Commons/components/TableHeader';
import CloseIcon from '@material-ui/icons/Close';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import AddIcon from '@material-ui/icons/Add';
import { debounce } from "lodash";


const columnData = [
    {
        id: 'city', numeric: false, disablePadding: true, label: 'City',
    },
    {
        id: 'pincodePrefix', numeric: false, disablePadding: true, label: 'Pincode Prefix',
    },
    {
        id: 'updatedAt', numeric: false, disablePadding: true, label: 'Last Updated',
    },
    {
        id: 'actions', numeric: false, disablePadding: true, label: 'Action',
    }
];

const styles = {
  tableCell: {
    textAlign: 'center',
  },
};

class PincodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      openModal: false,
      pincodePrefixText: "",
      pincodePrefixList: [],
      invalidPincode: false,
    };
  }

  handleOpenModal = (selectedData) => {
    this.setState({
      selectedData,
      openModal: true,
      pincodePrefixList: selectedData.pincodePrefix,
      disableButton: false,
    });
  };

  handleCloseModal = () => {
    const { onRefresh } = this.props;
    this.setState({
      openModal: false,
      pincodePrefixList: [],
      invalidPincode: false,
    }, () => onRefresh());
  };

  handleChangePincodePrefix = (event) => {
    const { value } = event.target;
    if (value < 0) return;
    if (value.length < 3) {
        this.setState({
            pincodePrefixText: value,
            invalidPincode: false,
        });
    } else {
        this.setState({
            invalidPincode: true,
        });
    }
  };

  handlePincodePrefixAdd = () => {
    const { pincodePrefixList, pincodePrefixText } = this.state;
    if (pincodePrefixText.length == 2) {
      this.setState({
        pincodePrefixList: [...pincodePrefixList, pincodePrefixText],
        pincodePrefixText: "",
        invalidPincode: false,
      });
    }
  };

  handlePincodePrefixDelete = (pincode) => {
    const { pincodePrefixList } = this.state;
    this.setState({
        pincodePrefixList: pincodePrefixList.filter(el => el != pincode),
    });
  };

  toggleButtonState = (state) => {
    this.setState({
      disableButton: state,
    });
  };

  updatePincodePrefix = debounce( async () => {
    const { selectedData, pincodePrefixList } = this.state;
    const postData = {city: selectedData.city, pincodePrefix: pincodePrefixList};
    this.toggleButtonState(true);
    try {
      await apiCall.sendRequest('post', updatePincodePrefix, postData);
      this.handleCloseModal();
      this.toggleButtonState(false);
    } catch (err) {
      this.handleCloseModal();
      this.toggleButtonState(false);
    }
  }, 750);

  render() {
    const {
      pincodePrefixData
    } = this.props;
    const { openModal, selectedData, pincodePrefixList, pincodePrefixText, invalidPincode, disableButton } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={12} style={{ paddingLeft: '15px' }}>
          <Table>
            <TableHeader columns={columnData} cellStyle={styles.tableCell} />
            <TableBody>
              {
                pincodePrefixData.map(item => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={item.id}
                  >
                    <TableCell component="th" scope="row" padding="none">{item.city}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{item.pincodePrefix.join(", ")}</TableCell>
                    <TableCell component="th" scope="row" padding="none" >{moment().format('lll')}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                        <Button size="small" color="primary" variant="contained" onClick={() => this.handleOpenModal(item)}> Edit </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModal}
          onClose={this.handleCloseModal}
          disableBackdropClick
        >
          <div style={{
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'white',
                width: '35%',
                margin: '0 auto',
                height: '40%',
                overflowX: 'auto',
                overflowY: 'auto',
                borderRadius: '5px',
              }}
          >
            <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", top: 0, width: "100%" }}>
              <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: "50px", paddingTop: "10px" }}>
                  <Typography variant="title" color="inherit"> Pincode Prefix Set </Typography>
              </div>
              <Button style={{ float: 'right' }} onClick={this.handleCloseModal} color="secondary" aria-label="close">
                  <CloseIcon />
              </Button>
            </div>
            <Grid style={{ padding: '2em', marginTop: window.innerHeight * 0.05 }}>
              <Row>
                <ColMod custompadding="true" bumphigh="true" xs md lg={15}>
                  <Row>
                    <ColMod custompadding="true" bumphigh="true" lg={6}>
                      <TextField
                        label="City"
                        fullWidth
                        value={selectedData.city}
                        InputProps={{ readOnly: true }}
                      />
                    </ColMod>
                    <ColMod custompadding="true" bumphigh="true" lg={6}>
                      <Typography variant="caption"> Pincode Prefix </Typography>
                      <div>
                        <TextField
                            onChange={this.handleChangePincodePrefix}
                            type="number"
                            value={pincodePrefixText}
                            style={{ marginRight: "10px", width: window.innerWidth * 0.07 }}
                            error={invalidPincode}
                            helperText={invalidPincode ? "Max two digit" : "Enter first two digit of pincode"}
                        />
                        <Button onClick={this.handlePincodePrefixAdd} variant="fab" mini color="primary" aria-label="add">
                            <AddIcon />
                        </Button>
                      </div>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod custompadding="true" bumphigh="true" lg={12}>
                        {pincodePrefixList.length > 0 && 
                            <div>
                                {pincodePrefixList.map((e, index) => (
                                    <Chip
                                        key={index}
                                        label={e}
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginRight: '10px', marginBottom: '10px' }}
                                        onDelete={() => { this.handlePincodePrefixDelete(e) }}
                                    />
                                ))}
                            </div>
                        }
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ position: "fixed", bottom: 15, marginLeft: window.innerWidth * 0.27 }}>
              <Button variant="contained" color="secondary" onClick={this.updatePincodePrefix} disabled={disableButton}> Update </Button>
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}


PincodeList.propTypes = {
  pincodePrefixData: PropTypes.instanceOf(Array),
};

export default PincodeList;
