import apiCall, { getMaxExtraAllowed } from '../../../api/NetworkHandler';



const getLatestGRNQuantitySummary = (data, resultType) => {
  let aggregatedItemsByQuantity = [];
  data.forEach(item => {
    const { grnLineItems = [], status } = item;
    if (resultType.includes(status)) {
      if (grnLineItems.length > 0) {
        grnLineItems.forEach(n => {
          aggregatedItemsByQuantity.push({
            vendorProductItemId: n.purchaseOrderLineItemId ? `${n.vendorProductItemId}-${n.purchaseOrderLineItemId}` : `${n.vendorProductItemId}-${n.unitPrice}-${n.purchaseItemType}`,
            receivedQuantity: n.receivedQuantity,
            rejectedQuantity: n.rejectedQuantity,
          })
        })
      }
    }
  });

  var quantityHolder = {};
  aggregatedItemsByQuantity.forEach(d => {
    if (quantityHolder.hasOwnProperty(d.vendorProductItemId)) {
      quantityHolder[d.vendorProductItemId] = quantityHolder[d.vendorProductItemId] + (d.receivedQuantity + d.rejectedQuantity);
    } else {
      quantityHolder[d.vendorProductItemId] = (d.receivedQuantity + d.rejectedQuantity);
    }
  });
  var quantitySum = [];
  for (var prop in quantityHolder) {
    quantitySum.push({ vendorProductItemId: prop, quantity: quantityHolder[prop] });
  }

  return quantitySum;
};

const fetchMaxExtraAllowed = async (callback) => {
  try {
    const response = await apiCall.sendRequest('get', getMaxExtraAllowed);
    const { data: { data: { maxExtraAllowed = null } = {} } = {} } = response;
    callback(null, maxExtraAllowed);
  } catch (err) {
    callback(err, null)
  }
}

export {
  getLatestGRNQuantitySummary,
  fetchMaxExtraAllowed,
};