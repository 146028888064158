import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

export default function CatalogProductSearch(props) {
  const { query, onChange, addNewProduct } = props;

  return (
    <Grid fluid>
      <Row middle='lg'>
        <ColMod lg={2} zerobottom='true'>
          <Typography variant='title'>
            Products
          </Typography>
        </ColMod>
        <ColMod lg={10}>
          <Row>
            <ColMod lg={5} zerobottom='true'>
              <FormControl color="secondary" style={{ width: '100%' }}>
                <Input
                  value={query || ''}
                  color="secondary"
                  label="Search"
                  fullWidth
                  name="query"
                  onChange={onChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </ColMod>
            <ColMod lg={3} zerobottom='true'>
              <Button color='secondary' onClick={addNewProduct}>
                <AddIcon />
                Add new Product
              </Button>
            </ColMod>
          </Row>
        </ColMod>
      </Row>
    </Grid>
  )
}
