import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import BannerList from '../components/BannerList';
import NavBar from '../../../components/NavBar';
import {
  Typography,
  Drawer
} from '@material-ui/core';
import BannerForm from '../components/BannerForm';
import ProgressBar from '../../../components/States/ProgressBar';
import apiCall, { fetchWidgetsByWidgetType, findAllBannersByFilters } from '../../../api/NetworkHandler';
import BannerSearch from '../components/BannerSearch';
import Colors from '../../../utils/Colors';
import { debounce, isEmpty, orderBy } from 'lodash';

const styles = {
  container: {
    margin: 0,
  },
  searchBar: {
    margin: "0",
    background: Colors.grey[100],
  },
  mainContainer: {
    backgroundColor: Colors.grey[100],
    paddingBottom: 10
  },
  title: {
    height: '2em',
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '0.8em',
  },
}

var versionDict = {
  "bannerWidgetRequestId": 0,
  "allBannerRequestId": 0,
};

class BannerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBannerForm: false,
      banner: {},
      banners: [],
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      orderBy: 'sortId',
      count: 0,
      searchTerm: '',
      type: '',
      status: 'ALL',
      region: null,
      isLoading: false,
      isNoData: false,
      bannerWidgets: [],
      selectedWidget: null,
      selectedDisplayOption: null,
    }
  }

  componentDidMount() {
    this.searchBanners();
    this.getBannerWidgets();
  }

  searchBanners = debounce( async () => {
    this.toggleProgressBar();
    const {
      page,
      rowsPerPage: size,
      orderBy: orderByData,
      order,
      searchTerm,
      region,
      type,
      status,
      selectedWidget,
      selectedDisplayOption
    } = this.state;

    const regionId = region ? region.value : '';
    const parentId = isEmpty(selectedWidget) ? '' : selectedWidget.value;
    const screenType = isEmpty(selectedDisplayOption) ? '' : selectedDisplayOption.value;

    try {
      versionDict.allBannerRequestId += 1;
      let prevRequestId = versionDict.allBannerRequestId;
      const response = await apiCall.sendRequest('get', findAllBannersByFilters(page, size, searchTerm, regionId, status, type, screenType, parentId));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      let banners = orderBy(content, orderByData, order);
      const isNoData = !!(banners && banners.length);
      if (prevRequestId == versionDict.allBannerRequestId) {
        this.setState({
          banners,
          count: totalElements,
          isNoData: !isNoData
        });
      }
      this.toggleProgressBar();
    } catch (error) {
      this.toggleProgressBar();
      this.setState({
        isNoData: true
      });
    }
  }, 750);

  getBannerWidgets = async () => {
    try {
      versionDict.bannerWidgetRequestId += 1;
      let prevRequestId = versionDict.bannerWidgetRequestId;
      const response = await apiCall.sendRequest('get', fetchWidgetsByWidgetType('BANNER'));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.bannerWidgetRequestId && data.length > 0) {
        this.setState({
          bannerWidgets: data.map(el => ({ label: el.title, value: el.id }))
        });
      }
    } catch (err) {
      this.setState({
        bannerWidgets: [],
      });
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.searchBanners() });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.searchBanners() });
  }

  onRequestSort = (property) => {
    if (!["name", "entityType", "timeUpdated", "sortId"].includes(property)) {
      return;
    }
    const { banners, order } = this.state;
    const newOrder = order == 'asc' ? 'desc' : 'asc';
    let sortedData;
    if (property == "timeUpdated") {
      sortedData = banners.sort((bannerA, bannerB) => {
        if (order == "asc") {
          return new Date(bannerA.timeUpdated) - new Date(bannerB.timeUpdated);
        } else {
          return new Date(bannerB.timeUpdated) - new Date(bannerA.timeUpdated);
        }
      });
    } else {
      sortedData = orderBy(banners, property, newOrder);
    }
    this.setState({
      banners: sortedData,
      order: newOrder,
      orderBy: property,
    })
  }

  closeBannerForm = () => {
    this.setState({ openBannerForm: false, banner: {} });
  }

  onEdit = (banner) => {
    this.setState({
      openBannerForm: true,
      banner: banner,
    });
  }

  onSearchChange = debounce(() => {
    this.searchBanners()
  }, 400)

  onChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchTerm: value
    });
    this.onSearchChange();
  }

  onFilterChange = (event) => {
    let { name, value } = event.target;
    if (name === 'type' && value == 'all') {
      value = "";
    }
    this.setState({
      [name]: value
    }, () => { this.searchBanners() });
  }

  onRegionChange = (region) => {
    this.setState({
      region
    }, () => { this.searchBanners() });
  }

  handleWidgetChange = (selectedWidget) => {
    this.setState({
      selectedWidget,
    }, () => { this.searchBanners() });
  }

  addNewBanner = () => {
    this.setState({
      openBannerForm: true,
      banner: {},
    });
  }

  toggleProgressBar = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading });
  }

  onClear = () => {
    this.setState({
      searchTerm: '',
      type: '',
      status: '',
      region: null,
      selectedWidget: null,
    }, () => { this.searchBanners() })
  }

  handleDisplayOptionChange = (selectedDisplayOption) => {
    this.setState({
      selectedDisplayOption,
    }, () => this.searchBanners());
  };

  render() {
    const { classes } = this.props;
    const {
      openBannerForm,
      banner,
      banners,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      searchTerm,
      type,
      status,
      region,
      isLoading,
      isNoData,
      bannerWidgets,
      selectedWidget,
      selectedDisplayOption
    } = this.state;

    return (
      <React.Fragment>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <div style={styles.mainContainer}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">Banners</Typography>
          </div>
          <BannerSearch
            searchTerm={searchTerm}
            onChange={this.onChange}
            onFilterChange={this.onFilterChange}
            type={type}
            status={status}
            region={region}
            onRegionChange={this.onRegionChange}
            onAddNew={this.addNewBanner}
            clear={this.onClear}
            bannerWidgets={bannerWidgets}
            selectedWidget={selectedWidget}
            onChangeWidget={this.handleWidgetChange}
            selectedDisplayOption={selectedDisplayOption}
            onDisplayOptionChange={this.handleDisplayOptionChange}
          />
        </div>
        <Grid className={classes.container} fluid>
          <Row lg={12}>
            <Col lg={12}>
              <BannerList
                onEdit={this.onEdit}
                banners={banners}
                rowsPerPage={rowsPerPage}
                page={page}
                order={order}
                orderBy={orderBy}
                count={count}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
                onRequestSort={this.onRequestSort}
                searchTerm={searchTerm}
                isNoData={isNoData}
                isLoading = {isLoading}
              />
            </Col>
          </Row>
        </Grid>
        <Drawer anchor="right" open={openBannerForm} onClose={this.closeBannerForm}>
          <BannerForm
            banner={banner}
            onClose={this.closeBannerForm}
            onRefresh={this.searchBanners}
          />
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(BannerContainer);