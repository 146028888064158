import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import ReportingTable from '../components/ReportingTable';
import Colors from '../../../utils/Colors';
import API, { agentsReportPath, agentsAcitivityDownloadPath } from '../../../api/NetworkHandler';
import AgentReportSearch from '../components/AgentReportSearch';

const data1 = [
  {
    agentName: 'Agent 1',
    agentId: 1,
    ticketCount: 10,
    workingHours: 8,
    agentStatus: 'ONLINE',
  },
  {
    agentName: 'Agent 2',
    agentId: 2,
    ticketCount: 8,
    workingHours: 8,
    agentStatus: 'OFFLINE',
  },
  {
    agentName: 'Agent 3',
    agentId: 3,
    ticketCount: 11,
    workingHours: 8,
    agentStatus: 'AWAY',
  },
];

class TicketReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentsReport: [],
      filters: {
        fromDate: '',
        toDate: '',
      },
    };
  }

  componentDidMount() {
    this.fetchReport();
  }

  fetchReport = async () => {
    const response = await API.sendRequest('get', agentsReportPath);
    const { data: { data = [] } } = response;
    this.setState({
      agentsReport: data,
    });
  }


  handleDownload = async () => {
    // agentsAcitivityDownloadPath
    await API.downloadFile(agentsAcitivityDownloadPath, 'AGENTS_REPORT.csv');
  }

  handleChange = (filters, refresh = false) => {
    this.setState({ filters }, () => {
      if (refresh) {
        this.fetchReport();
      }
    });
  };

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.fetchReport(params);
        break;
      case 'download':
        this.handleDownload();
        break;
      default:
        break;
    }
  };


  render() {
    const { filters = {}, agentsReport = [] } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title="Reporting"
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <AgentReportSearch />
        </SearchBar>
        <Grid container>
          <Grid item xs={8}>
            <ReportingTable data={agentsReport} />
          </Grid>
          <Grid item xs={4} style={{ background: Colors.lightGrey }}>
            <h4 style={{ padding: 10 }}>Statistics</h4>
          </Grid>
        </Grid>
      </div>
    );
  }
}


export default TicketReporting;
