import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import PerformanceBlock from '../../components/PerformanceBlock';
import AnalysisBlock from '../../components/AnalysisBlock';
import TaskBlock from '../../components/TaskBlock';
import './styles.scss';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="category">
        <div className="categoryList">
          <h2>Categories</h2>
          <ul>
            <li className="active">Dairy</li>
            <li>Bakery</li>
            <li>FnV</li>
            <li>EnM</li>
            <li>Grocery</li>
            <li>Breavarages</li>
            <li>Religious Needs</li>
          </ul>
        </div>
        <div className="categoryContent">
          <h1>Dairy</h1>
          <Tabs className="categoryTab">
            <Tab label="Overview" />
            <Tab label="All Items" />
          </Tabs>
          <div className="categoryOverview">
            <PerformanceBlock />
            <TaskBlock />
            <AnalysisBlock />
          </div>
          <div className="categoryItems">{/* Selectable Items Table */}</div>
        </div>
      </div>
    );
  }
}

Category.propTypes = {};

export default Category;
