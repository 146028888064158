import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Button,
  Typography,
} from '@material-ui/core';
import Colors from '../../../utils/Colors';
import TableHeader from '../../Commons/components/TableHeader';
import apiCall from '../../../api/NetworkHandler';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';

const columnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'GRN No',
  },
  {
    id: 'poNumber',
    numeric: false,
    disablePadding: true,
    label: 'PO Number',
  },
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: 'Vendor Name',
  },
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: true,
    label: 'Warehouse',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 15,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: 10,
  },
  tableCell: {
    textAlign: 'left',
  },
  tableData: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '20px',
    padding: 5,
  },
};

class GRNList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      errorMessage: '',
    };
  }

  componentDidMount() {
    let currLocation = this.props.history.location.pathname;
    reactLocalStorage.set('curLocation', currLocation)
  }

  handleClick = (grnList) => {
    this.setState({
      selectedData: grnList,
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;
    onRowsPerPageChange(value);
  }

  addGRNToStock = async (id) => {
    try {
      await apiCall.sendRequest('post', `/api/v1/stock-ledger?grn_id=${id}`);
      this.setState({ errorMessage: '' });
      window.location.reload(false);
    } catch (e) {
      console.error(e);
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }


  render() {
    const {
      grnList, rowsPerPage, page = 0, rowsPerPageOptions, totalElements, 
    } = this.props;
    const { selectedData = {}, curLocation } = this.state;
    return (
      <div>

        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <Table aria-labelledby="tableTitle">
              <TableHeader columns={columnData} cellStyle={styles.tableCell} />
              <TableBody key={page}>
                {
                  grnList.map(lineItem => (
                    <TableRow
                      hover
                      onClick={() => this.handleClick(lineItem)}
                      tabIndex={-1}
                      key={lineItem.id}
                      selected={!isEmpty(selectedData) && selectedData.id === lineItem.id}
                    >
                      <TableCell style={{ ...styles.tableCell, color: Colors.primary }} component="th" scope="row" padding="none">
                        {lineItem.id}
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        {lineItem.poNumber}
                      </TableCell>
                      <TableCell style={{ ...styles.tableCell, color: Colors.primary }} component="th" scope="row" padding="none">
                        {lineItem.vendorName}
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        {lineItem.warehouseName}
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        {lineItem.status}
                      </TableCell>
                      <TableCell style={{ color: Colors.primary }} component="th" scope="row" padding="none">
                        <Link style={{ textDecoration: 'none' }} to={`/po/${lineItem.poNumber}/grn/${lineItem.id}`}>
                          {lineItem.status === 'RECEIVED' ? 
                          <Button size="small" variant="contained" color="secondary">
                            Edit
                          </Button> :
                          <Button size="small" variant="contained" color="primary" >
                            View
                          </Button>}
                        </Link>
                      </TableCell>
                      {lineItem.status === 'RECEIVED' &&
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <Button size="small" color="secondary" variant="contained" onClick={() => this.addGRNToStock(lineItem.id)}>
                          Add to Stock
                        </Button>
                      </TableCell>}
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
              'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
              'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangeRowsPerPage={this.handleChangeRowPerPage}
            />
          </div>

          <div style={styles.paperRight}>
            { Object.keys(selectedData).length
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={styles.tableData} >
                    <b>GRN Details</b>
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>GRN No : </b> {selectedData.id}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Warehouse Name : </b> {selectedData.warehouseName}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> PO Number : </b> {selectedData.poNumber}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Status : </b> {selectedData.status}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Active : </b> {selectedData.active}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> InvoiceNumber : </b> {selectedData.invoiceNumber}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> TimeCreated: </b> {moment(selectedData.timeCreated).format("LLL")}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> TimeUpdated : </b> {moment(selectedData.timeUpdated).format("LLL")}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Total Received Price : </b> {selectedData.totaReceivedPrice ? selectedData.totaReceivedPrice.toFixed(2) : null}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Total Received Quantity : </b> {selectedData.totalReceivedQuantity}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Total Rejected Price : </b> {selectedData.totalRejectedPrice ? selectedData.totalRejectedPrice.toFixed(2) : null}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Total Rejected Quantity : </b> {selectedData.totalRejectedQuantity}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Vendor Name : </b> {selectedData.vendorName}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Version Id : </b> {selectedData.versionId}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> WareHouse Id : </b> {selectedData.warehouseId}
                  </Typography>
                </div>
              : <div />}
          </div>
        </div>
      </div>
    );
  }
}

GRNList.propTypes = {
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  grnList: PropTypes.instanceOf(Array),
};

export default withRouter(GRNList);
