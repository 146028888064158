import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerformanceBlock from '../../components/PerformanceBlock';
import AnalysisBlock from '../../components/AnalysisBlock';
import TaskBlock from '../../components/TaskBlock';
import ItemInfo from '../../components/ItemInfo';
import './styles.scss';

class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="productItem">
        <ItemInfo />
        <PerformanceBlock />
        <TaskBlock />
        <AnalysisBlock />
      </div>
    );
  }
}

ProductItem.propTypes = {};

export default ProductItem;
