import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default function PaginatedTableHeader(props) {
  const {
    order,
    orderBy,
    columns,
    onRequestSort
  } = props;
  
  return (
    <TableHead>
      {<TableRow>
        {columns.map(column => (
          <TableCell
            key={column.id}
            numeric={column.numeric}
            padding={column.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === column.id ? order : false}
            style={{ color: '#f50057', fontSize: '14px' }}
          >
            <Tooltip
              title="Sort"
              placement={column.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={() => { onRequestSort(column.id) }}
              >
                {column.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>}
    </TableHead>
  )
}

PaginatedTableHeader.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

PaginatedTableHeader.defaultProps = {
  order: 'asc',
  orderBy: '',
  columns: [],
  onRequestSort: () => { },
}
