import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Clear, Search, Style } from '@material-ui/icons';
import './styles.scss';

const Styles = {
  fullWidth: {
    width: '100%',
  },
};

class HeadBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="headBar">
        <div className="searchBar">
          <FormControl style={Styles.fullWidth}>
            <TextField
              fullWidth
              label="Search for Product Items"
              onChange={this.props.onSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <Search />{' '}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <Clear onClick={this.props.onSearchClear} />{' '}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <div className="wareHouseSelection">
          <FormControl style={Styles.fullWidth}>
            <InputLabel htmlFor="warehouse">Select Warehouse</InputLabel>
            <Select id="warehouse">
              <MenuItem>BANGALORE</MenuItem>
              <MenuItem>PUNE</MenuItem>
              <MenuItem>HYDERABAD</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
}

HeadBar.propTypes = {};

export default HeadBar;
