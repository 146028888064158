import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import TableHeader from '../../Commons/components/TableHeader';
import moment from 'moment';
import CustomerOrderTable from './CustomerOrderTable';
import COSearchBar from './COSearchBar';
import SearchBar from '../../Commons/containers/SearchBar';
import apiCall, {
  customerOrderByFiltersPath,
  customerOrdersPath,
  getIsPackaged,
  findCustomerOrdersByFilters,
  setCustomerOrderPackageStatus,
  fetchPackagingDetailsByOrderId,
} from '../../../api/NetworkHandler';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'lodash/debounce';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

const style = {
  cellStyle: {
    textAlign: 'center',
  },
};

const columnData = [
  {
    id: 'productName', numeric: false, disablePadding: true, label: 'Product',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Ordered Quantity',
  },
  {
    id: 'deliveredQuantity', numeric: false, disablePadding: true, label: 'Delivered Quantity',
  },
];

var versionDict = {
  'initialRequestId': 0,
  'initalStateId': 0,
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      co: [],
      links: {},
      selectedCO: {},
      currentPage: 0,
      totalElements: 0,
      rowsPerPage: 25,
      filters: {
        query: '',
        id: '',
        customerId: '',
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        status: '',
        productItemId: '',
        priority: '',
        warehouseId: null,
        packageStatus: '',
        hubId: null,
      },
      searchedResult: false,
      hideRightPaper: false,
      isOrderPackaged: false,
      customerOrders: [],
    };
  }

  componentDidMount() {
    this.fetchPackageStatus();
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.fetchCustomerOrders(getFilterParams);
    }

  fetchPackageStatus = async () => {
    try {
      const response = await apiCall.sendRequest('get', getIsPackaged);
      const { data: { data = {} } } = response;
      const { isPackaged = false } = data;
      this.setState({ isOrderPackaged: isPackaged });
    } catch (err) {
      this.setState({ isOrderPackaged: false });
    }
  }

  //adapt the JSON response from fetchCustomerOrders
  getCustomerOrdersFromCustomerOrdersContent = (customerOrdersContent) => {
    let customerOrders = [];
    customerOrdersContent.map(item => {
      customerOrders.push({...item.customerOrder, hubId: item.hubId, hubName: item.hubName, warehouseId: item.warehouseId, warehouseName: item.warehouseName})
    });
    return customerOrders;
  }

  fetchCustomerOrders = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    try {
      const response = await apiCall.sendRequest('get', findCustomerOrdersByFilters(filters, page, size));
      const { data: { data = {} } } = response;
      const { content = [], number = 0, totalElements = 0 } = data;
      const customerOrders = this.getCustomerOrdersFromCustomerOrdersContent(content);
      this.setState({
        customerOrders,
        currentPage: number,
        totalElements,
      });
    } catch (err) {
      this.setState({
        customerOrders: [],
        currentPage: 0,
        totalElements: 0,
      });
    }
  }

  getPackagingDetailsByOrderId = async () => {
    const { selectedCO = {}, isOrderPackaged } = this.state;
    const { id = null } = selectedCO;
    if (!isOrderPackaged) return;
    try {
        const response = await apiCall.sendRequest('get', fetchPackagingDetailsByOrderId(id));
        const { data: { data = [] } } = response;
        this.getCOLineItemByPackaging(data);
    } catch (err) {
      console.log(err.response);
    }
  }

  onChangeFilter = (filters = {}, refresh) => {
    const { currentPage } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.fetchCustomerOrders(currentPage);
      }
    });
  }

  getCustomerOrder = (data) => {
    this.setState({
      selectedCO: data,
      hideRightPaper: false,
    }, () => this.getPackagingDetailsByOrderId());
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.fetchCustomerOrders();
        this.setState({ selectedCO: {} });
        break;
      default: break;
    }
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.fetchCustomerOrders() });
  }

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.fetchCustomerOrders() });
  }

  appendAddressToSelectedOrder = async (order) => {
    const { customerAddressId = null } = order;
    let customerAddress = '';
    try {
      const response = await apiCall.sendRequest('get', `/api/v1/customer-address/${customerAddressId}`);
      const { data = {} } = response;
      const { fullAddress = '' } = data;
      customerAddress = fullAddress;
    } catch (err) {
      customerAddress = '';
    }
    return {...order, customerAddress}
  }

  prepareLabelPrint = (selectedOrder) => {
    return (
      `<div style="width: 188.97px; height: 113.38px; border: 1px solid black; border-collapse: collapse;" rules="all">
        <p style="font-size: 7px;  line-height: 1px; text-align: center; margin-top: 4px; margin-bottom: 0; padding: 0"> FRESH TO HOME FOODS FACTORY L.L.C </p>
        <div>
          <img src='../../../../public/images/FTHDailyIcon.png' style="width:30px; height:30px;">
          <p style="font-size: 12px;  line-height: 1px; float: right; margin-right: 5px"> ${moment(selectedOrder.deliveryDate).format("DD/MM/YYYY")} </p>
        </div>
        <p style="font-size: 16px; line-height: 1px; text-align: center; margin-top: 2px"> Order# <b> ${selectedOrder.id} </b> </p>
        <hr style="margin-top: -0.4em;" />
        <div style="font-size: 10px; margin-left: 5px; text-align: left; word-wrap: break-word;">
          <p><b>${selectedOrder.customerName || ''}</b><br/>${selectedOrder.customerAddress}</p>
        </div>
      </div>`
    );
  }
  
  displayLabel = async (selectedOrder) => {
    let modifiedSelectedOrder = await this.appendAddressToSelectedOrder(selectedOrder);
    let content = this.prepareLabelPrint(modifiedSelectedOrder);
    let orderHtml = '<html><head><title></title></head><body onLoad="window.print();window.close();">' + content + '</body></html>';
    let winPrint = window.open("", "", 200, 120);
    winPrint.document.write(orderHtml);
    winPrint.document.close();
    winPrint.focus();
  }

  handleHideSidePanel = () => {
    this.setState({ hideRightPaper: true });
  }

  getCOLineItemByPackaging = (packagingData) => {
    let distinctPackaging = [];
    packagingData.map(el => {
      if (el.primaryPackageName) {
        let obj = distinctPackaging.find(elm => elm.packageName == el.primaryPackageName);
        if (obj) {
          obj.packageItem.push(el);
        } else {
          distinctPackaging.push({packageItem: [{...el}], packageName: el.primaryPackageName});
        }
      }
      if (el.secondaryPackageName) {
        let obj = distinctPackaging.find(elm => elm.packageName == el.secondaryPackageName);
        if (obj) {
          obj.packageItem.push(el);
        } else {
          distinctPackaging.push({packageItem: [{...el}], packageName: el.secondaryPackageName});
        }
      }
    });
    this.setState({
      distinctPackagingLineItem: distinctPackaging,
    });
  }

  render() {
    const {
      filters = {},
      totalElements,
      currentPage = 0,
      links,
      selectedCO = {},
      hideRightPaper,
      isOrderPackaged,
      rowsPerPage,
      customerOrders,
      distinctPackagingLineItem,
    } = this.state;
    const { timeCreated, timeUpdated, customerOrderLineItems = [] } = selectedCO;
    const newDistinctPackagingLineItem = !distinctPackagingLineItem ? [] : distinctPackagingLineItem;
    return (
      <div>
        <SearchBar
          title="Customer Orders"
          filters={filters}
          onChange={this.onChangeFilter}
          onButtonClick={this.handleClick}
        >
          <COSearchBar isOrderPackaged={isOrderPackaged}/>
        </SearchBar>
        <div>
          <div style={{ width: (Object.keys(selectedCO).length > 0 && !hideRightPaper) ? '70%' : '100%', float: 'left', height: '700px', overflowY: 'scroll', paddingLeft: '10px' }}>
            <CustomerOrderTable
              data={customerOrders}
              links={links}
              fetchPaginatedCOS={this.fetchCustomerOrders}
              getCustomerOrder={this.getCustomerOrder}
              totalElements={totalElements}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onPageChange={this.onPageChange}
              onRowsPerPageChange={this.onRowsPerPageChange}
              isOrderPackaged={isOrderPackaged}
            />
          </div>
          {(Object.keys(selectedCO).length > 0 && !hideRightPaper) &&
            <div style={{ width: '30%', float: 'right', height: '700px', overflowY: 'scroll', paddingRight: '10px', paddingLeft: '10px' }}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton>
                    <CloseIcon onClick={this.handleHideSidePanel} />
                  </IconButton>
                </div>
                <div style={{ borderBottom: '1px solid #000000', paddingBottom: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', height: '30px' }}>
                    <p>Customer Id: <b>{selectedCO.customerId}</b></p>
                    <p>Order Number: <b>{selectedCO.id}</b></p>
                    {!isOrderPackaged && <Button size="small" variant="outlined" color="primary" onClick={() => this.displayLabel(selectedCO)} style={{ paddingRight: '15px' }}> Print Label </Button>}
                  </div>
                  <p style={{ fontSize: '16px', marginBottom: 0, paddingBottom: 0 }}>Customer Name: <b>{selectedCO.customerName}</b></p>
                </div>
                {isOrderPackaged && <div>
                  {customerOrderLineItems.length > 0 &&
                    newDistinctPackagingLineItem.map((el, index) => {
                      return (
                        <Collapse accordion>
                          <Panel header={`Package Name: ${el.packageName}`} key={index} style={{ backgroundColor: '#DCDCDC' }}>
                            <div>
                              <Table aria-labelledby="tableTitle">
                                <TableHeader 
                                  columns={columnData}
                                  cellStyle={style.cellStyle}
                                />
                                {el.packageItem.map(n => (
                                  <TableBody>
                                      <TableRow
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={n.id}
                                      >
                                        <TableCell component="th" scope="row" padding="none">{n.productItemName}</TableCell>
                                        <TableCell component="th" scope="row" padding="none">{n.packageStatus}</TableCell>
                                        <TableCell component="th" scope="row" padding="default">{n.quantity}</TableCell>
                                        <TableCell component="th" scope="row" padding="default">{n.deliveredQuantity}</TableCell>
                                      </TableRow>
                                  </TableBody>))}
                              </Table>
                            </div>
                          </Panel>
                        </Collapse>
                    )})
                  }
                </div>}
                {!isOrderPackaged && <div>
                  {customerOrderLineItems.length > 0 &&
                    <Table aria-labelledby="tableTitle">
                      <TableHeader 
                        columns={columnData}
                        cellStyle={style.cellStyle}
                      />
                      {customerOrderLineItems.map(n => (
                        <TableBody>
                            <TableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={n.id}
                            >
                              <TableCell component="th" scope="row" padding="none">{n.productName}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{n.status}</TableCell>
                              <TableCell component="th" scope="row" padding="default">{n.quantity}</TableCell>
                              <TableCell component="th" scope="row" padding="default">{n.deliveredQuantity}</TableCell>
                            </TableRow>
                        </TableBody>))}
                    </Table>
                  }
                </div>}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}


export default withRouter(Dashboard);