import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  container: {
    width: "100%",
    height: "100%"
  },
  input: {
    display: "none"
  },
  inputDiv: {
    border: "1px dotted #d3d3d3"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    height: "100%",
    fontSize: "20px",
    cursor: "pointer"
  },
  info: {
    fontSize: "15px"
  },
  height100: {
    height: "100%"
  }
};

const UploadSingleImageFile = props => {
  const {
    classes,
    uploadFileHandler,
    label,
    progressBarStatus,
    error,
    loading,
  } = props;

  return (
    <div className={classes.container}>
      {/* when image url is null */}
      <div className={`${classes.height100} ${classes.inputDiv}`}>
        {!loading && (
          <label className={`${classes.center} ${classes.label}`}>
            {label}
            <input
              type="file"
              accept="image/*"
              onChange={uploadFileHandler}
              className={classes.input}
            />
          </label>
        )}

        {/* uploading starts when file is uploaded and status will be greater than 0 */}
        {loading > 0 && (
          <div style={{ padding: "100px 10px" }}>
            <LinearProgress
              // variant="determinate"
              color={error ? "secondary" : "primary"}
              // value={progressBarStatus}
              top="20"
            />

            {/* if error while uploading display error else show uploading.. */}
            {error ? (
              <div className={classes.info}>{error}</div>
            ) : ( loading && (<div className={classes.info}>uploading...</div>))}
          </div>
        )}
      </div>
    </div>
  );
};

UploadSingleImageFile.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(UploadSingleImageFile);
