import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { getFetchAllWarehouses } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', getFetchAllWarehouses(searchTerm))
  const { data: { _embedded } } = response;
  const { warehouse: warehouses } = _embedded;

  return warehouses.map(warehouse => {
    return {
      ...warehouse,
      value: warehouse.id,
      label: warehouse.name,
    }
  })
}

function WarehouseSelector(props) {
  const { selected, onSelect, isClearable } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
    />
  )
}

WarehouseSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
};

WarehouseSelector.defaultProps = {
  selected: null,
  isClearable: true,
};

export default WarehouseSelector;
