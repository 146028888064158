import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TableBody, TableRow, TableCell, Button, Typography, Grid, TablePagination, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import Search from '@material-ui/icons/Search';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const columnData = [
  {
    id: 'referralDate',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'productCatalogName',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Boy Phone Number',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Boy Name',
  },
  {
    id: 'hubName',
    numeric: false,
    disablePadding: false,
    label: 'Hub Name',
  },
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: false,
    label: 'Warehouse',
  },
  {
    id: 'customerId',
    numeric: false,
    disablePadding: false,
    label: 'CustomerId',
  },
  {
    id: 'customerSpend',
    numeric: false,
    disablePadding: false,
    label: 'Customer Spend',
  },
  {
    id: 'customerRecharge',
    numeric: false,
    disablePadding: false,
    label: 'Customer Recharge',
  },
  {
    id: 'referralStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];
const styles = {
  cellStyle: {
    textAlign: 'center',
    padding: '0px 10px',
  },
  headerStyle: {
    textAlign: 'center',
    padding: '0px 10px',
    color: 'rgb(245, 0, 87)',
    fontSize: '14px',
  },
};

class EnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                style={styles.headerStyle}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

class DRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      data: [],
      rowSelected: {},
      itemsList: [],
      searchQuery: '',
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    return {
      data,
    };
  }

  handleClick = (item) => {
    this.setState({
      rowSelected: item,
      itemsList: item.purchaseRequisitionLineItems,
      searchQuery: '',
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  onInputChange = (event) => {
    this.setState({
      searchQuery: event.target.value,
    }, () => this.filterList());
  }

  filterList() {
    let items = this.state.rowSelected.purchaseRequisitionLineItems;
    const q = this.state.searchQuery;

    items = items.filter(item =>
      item.productName.toLowerCase().indexOf(q) !== -1);
    this.setState({ itemsList: items });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  render() {
    const {
      totalElements, rowsPerPage, page = 0, rowsPerPageOptions,
    } = this.props;
    const { rowSelected = {}, order, orderBy, data = [] } = this.state;
    return (
      <Grid container>
        <Grid item md={10} sm={12} style={{ overflow: 'scroll', margin: '0 auto' }}>
          <Table>
            {/* <TableHeader columns={columnData} cellStyle={styles.cellStyle} /> */}
            <EnhancedTableHead
              numSelected={rowSelected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              cellStyle={styles.cellStyle}
            />
            <TableBody>
              {data && data.map(item => (
                <TableRow
                  hover
                  onClick={() => this.handleClick(item)}
                  role="checkbox"
                  aria-checked={rowSelected.referralDate === item.referralDate}
                  tabIndex={-1}
                  key={item.referralDate}
                  selected={rowSelected && rowSelected.referralDate === item.referralDate}
                >
                  <TableCell style={styles.cellStyle}>{moment(item.referralDate).format('ddd, DD MMM YYYY')}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.mobile}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.name}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.hubName}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.warehouseName}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.customerId}</TableCell>
                  <TableCell style={styles.cellStyle}>{Math.round((item.customerSpend + Number.EPSILON) * 100) / 100}</TableCell>
                  <TableCell style={styles.cellStyle}>{Math.round((item.customerRecharge + Number.EPSILON) * 100) / 100}</TableCell>
                  <TableCell style={styles.cellStyle}>{item.referralStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data && data.length > 0 ?
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
              : null
          }
        </Grid>
      </Grid>
    );
  }
}

DRList.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default DRList;
