import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import apiCall, {
  allProductItemsPath,
  getVendors,
  customerTypeDownloadPath,
  getWareHouse,
  getWarehouseHubs,
} from '../../../api/NetworkHandler';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
};


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      products: [],
      warehouses: [],
      vendors: [],
      hubs: [],
    };
  }

  componentDidMount() {
    apiCall.sendRequest('get', getVendors)
      .then((res) => {
        const { data: { _embedded = {} } } = res;
        this.setState({
          vendors: _embedded.vendors,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.fetchWarehouseList();
    this.getAllProducts();
  }

  onChangeFilter = (event) => {
    const { name, value } = event.target;
    const { onFilterChange } = this.props;
    if (name === 'warehouseId') {
      this.fetchHubs(value);
    }
    const isNotDateField = (name !== 'startDate') && (name !== 'endDate');
    if (isNotDateField || this.isValidDateRange(value, name)) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }));
      onFilterChange(name, value);
    }
  }

  fetchHubs = async (warehouseId) => {
    try {
      const response = await apiCall.sendRequest('get', getWarehouseHubs(warehouseId));
      const { data: { data = [] } } = response;
      this.setState({ hubs: data });
    } catch (err) {
      this.setState({ hubs: [] });
    }
  }

  onKeyPress = (event) => {
    const { onChangeFilter } = this.props;
    if (event.key) {
      onChangeFilter(this.state.filters);
    }
  }

  onSearch = () => {
    const { onChangeFilter, onButtonClick } = this.props;
    const { filters } = this.state;
    // onChangeFilter(filters);
    onButtonClick('search', 0);
  }

  getAllProducts = async (query = '') => {
    const url = allProductItemsPath(query);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = [] } } = response;
      this.setState({
        products: data,
      });
    } catch (e) {
      this.setState({
        products: [],
      });
    }
  }

  fetchWarehouseList = async () => {
    const response = await apiCall.sendRequest('get', getWareHouse);
    const { data: { _embedded: { warehouse = [] } = {} } = {} } = response;
    this.setState({
      warehouses: warehouse,
    });
  }

  isValidDateRange = (value, key) => {
    const { filters: { endDate, startDate } } = this.state;
    const isEmpty = value === '';
    const endDateValidation = (key === 'startDate' && (endDate === '' || moment(value).isSameOrBefore(moment(endDate))));
    const startDateValidation = (key === 'endDate' && (startDate === '' || moment(value).isSameOrAfter(moment(startDate))));
    return isEmpty || endDateValidation || startDateValidation;
  }


  downloadCustomerOrders = () => {
    const { filters } = this.state;
    const { endDate, startDate, priority } = filters;
    const url = customerTypeDownloadPath(startDate, endDate, priority);
    apiCall.downloadFile(url, `${priority}_Customer_Orders_${startDate}_${endDate}.csv`);
  }


  clearAll = () => {
    const { onButtonClick } = this.props;
    this.setState({
      filters: {
        query: '',
        id: '',
        customerId: null,
        startDate: '',
        endDate: '',
        status: '',
        packageStatus: '',
        warehouseId: null,
        hubId: null,
      },
    }, () => {
      onButtonClick('clear', {});
    });
  }

  handleProductSelection = (product) => {
    const { id } = product;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        productItemId: id,
      },
    }));
  }

  handleProductSearch = (query) => {
    this.getAllProducts(query);
  }

  renderVendors = () => this.state.vendors.map(vendor =>
    <MenuItem key={vendor.id} value={vendor}>{vendor.name}</MenuItem>)

  render() {
    const {
      products, filters, warehouses = [], hubs = [],
    } = this.state;
    const { COStatuses, orderPriority, packageStatuses, isOrderPackaged } = this.props;
    const newPackageStatuses = !packageStatuses ? {} : packageStatuses;
    return (
      <div style={styles.container}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Quick Search (By Name)"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={filters.query}
                  onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  type="date"
                  label="Delivery From Date"
                  name="startDate"
                  onChange={this.onChangeFilter}
                  value={filters.startDate ? filters.startDate : ''}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  type="date"
                  label="Delivery To Date"
                  name="endDate"
                  onChange={this.onChangeFilter}
                  value={filters.endDate ? filters.endDate : ''}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="cId"
                  label="Customer Id"
                  fullWidth
                  type="number"
                  name="customerId"
                  onChange={this.onChangeFilter}
                  value={filters.customerId ? filters.customerId : ''}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="coNum"
                  fullWidth
                  label="Order Number"
                  type="number"
                  name="id"
                  onChange={this.onChangeFilter}
                  value={filters.id ? filters.id : ''}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              {isOrderPackaged &&
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Package Status</FormHelperText>
                <Select
                  name="packageStatus"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={filters.packageStatus || ''}
                >
                  {Object.keys(newPackageStatuses).map((status, index) => (<MenuItem key={index} value={status}>{packageStatuses[status]}</MenuItem>))}
                </Select>
              </Grid>}
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Warehouse</FormHelperText>
                <Select
                  name="warehouseId"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={(+filters.warehouseId) || ''}
                >
                  {warehouses.map(warehouse => (<MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.name}</MenuItem>))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Hub</FormHelperText>
                {!filters.warehouseId ?
                  <TextField
                    fullWidth
                    disabled
                    value="Please select warehouse first"
                  /> :
                  <Select
                    name="hubId"
                    fullWidth
                    value={+filters.hubId||''}
                    onChange={this.onChangeFilter}
                  >
                    {hubs.map(hub => (<MenuItem key={hub.id} value={hub.id}>{hub.name}</MenuItem>))}
                  </Select>}
              </Grid>
              {/* <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Product</FormHelperText>
                <SearchWithSuggestions
                  suggestions={products}
                  onChange={this.handleProductSearch}
                  onSelect={this.handleProductSelection}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Status</FormHelperText>
                <Select
                  name="status"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={filters.status ? filters.status : ''}
                >
                  {Object.keys(COStatuses).map(costatus => (
                    <MenuItem value={costatus}>{COStatuses[costatus]}</MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Priority</FormHelperText>
                <Select
                  name="priority"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={filters.priority ? filters.priority : ''}
                >
                  {Object.keys(orderPriority).map(coPriority => (
                    <MenuItem value={coPriority}>{orderPriority[coPriority]}</MenuItem>
                    ))}
                </Select>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button color="secondary" variant="contained" onClick={this.onSearch}>
                    Search
                </Button>
              </Grid>
              <Grid item xs={6} md={6} style={styles.rightAlign}>
                <Button
                  onClick={this.downloadCustomerOrders}
                  download
                  variant="contained"
                  color="secondary"
                >
                    Download
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button color="secondary" variant="outlined" onClick={this.clearAll}>
                    Clear All
                </Button>
              </Grid>
              {/* <Grid item xs={6} md={8} style={styles.rightAlign}>
                <Link to="/new-co" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="secondary">Create New Order</Button>
                </Link>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SearchBar.propTypes = {
  COStatuses: PropTypes.instanceOf(Object),
  orderPriority: PropTypes.instanceOf(Object),
  onChangeFilter: PropTypes.func,
  filters: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => ({
  COStatuses: state.Status.costatuses,
  orderPriority: state.Status.orderPriority,
  warehouse: state.information.warehouses,
  packageStatuses: state.Status.coPackageStatuses,
});

export default connect(mapStateToProps, null)(SearchBar);
