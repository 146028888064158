import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  TextField,
  Select,
  Grid,
  FormHelperText,
  Drawer,
  InputAdornment,
  MenuItem
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import CreateAudienceSet from './CreateAudienceSet';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
  buttonAlignment: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
};

let clearFilters = false;

class AudienceSetSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      products: [],
      searchQuery: '',
      openAudienceSetDrawer: false,
    };
  }

  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {};
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
      searchQuery: '',
    }, () => {
      clearFilters = !clearFilters;
      onButtonClick('search', 0);
    });
  }

  handleChange = (value, key) => {
    const { onFilterChange } = this.props;
    this.setState(prevState => ({
    filters: {
        ...prevState.filters,
        [key]: value,
    },
    }));
    onFilterChange(key, value);
  }

  handleOpenCreateAudienceSet = () => {
    this.setState({
        openAudienceSetDrawer: true,
    });
  }

  handleCloseCreateAudienceSet = () => {
    this.setState({ openAudienceSetDrawer: false });
  }

  render() {
    const { onButtonClick, overlayFilterTypes } = this.props;

    const {
      filters, openAudienceSetDrawer, 
    } = this.state;

    const {
      query = '',
      filterType,
    } = filters;

    return (
      <div style={styles.container} key={clearFilters}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Quick Search (By Title)"
                  fullWidth
                  name="query"
                  onChange={e => this.handleChange(e.target.value, 'query')}
                  value={query.replace(/%20/g, " ")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText> Filter Type </FormHelperText>
                <Select
                    fullWidth
                    label="Filter Type"
                    value={filterType}
                    onChange={e => this.handleChange(e.target.value, 'filterType')}
                >
                    {Object.keys(overlayFilterTypes || {}).filter(el => el === 'CUSTOMER').map((type, index) => (<MenuItem key={index} value={type}>{overlayFilterTypes[type]}</MenuItem>))}
                </Select>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search', 0)}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleClear}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6} md={6} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                <Button color="primary" variant="outlined" onClick={this.handleOpenCreateAudienceSet}> Create Audience Set </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Drawer anchor="right" open={openAudienceSetDrawer} onClose={this.handleCloseCreateAudienceSet} disableBackdropClick>
            <CreateAudienceSet
                onClose={this.handleCloseCreateAudienceSet}
                onButtonClick={onButtonClick}
                overlayFilterTypes={overlayFilterTypes}
            />
        </Drawer>
      </div>
    );
  }
}

AudienceSetSearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  overlayFilterTypes: PropTypes.instanceOf(Object),
};


export default withRouter(AudienceSetSearch);
