import React from 'react';
import PackagingComponent from "../component/PackagingComponent";

class PackagingContainer extends React.Component {
  render() {
    return (
      <div>
        <PackagingComponent />
      </div>
    );
  }
}


export default (PackagingContainer);

