import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Paper, Typography, Button } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import Colors from '../../../utils/Colors';

const styles = {
  orderCard: {
    padding: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  orderlinkButton: {
    padding: 0,
    margin: 0,
    textTransform: 'none',
    textDecoration: 'underline',
    minHeight: 'auto',
  },
};

const OrderCard = (props) => {
  const {
    order,
    selected = false,
    onClick,
    onOrderClick,
    // displayLabel,
  } = props;

  let border = 'none';
  if (selected) {
    border = `3px solid ${Colors.grey[400]}`;
  }

  return (
    <Paper
      elevation={1}
      style={{ ...styles.orderCard, border }}
      onClick={onClick}
    >
      <div>
        <Typography variant="body1">
          <div style={{
                color: Colors.default,
                fontWeight: 600,
                background: Colors.yellow[100],
                width: 70,
                paddingLeft: 5,
              }}
          >
            {order.priority}
          </div>
          <div>
            <Typography variant="body1" style={{ float: 'left' }}>
              <b>Created On:</b> {moment(order.timeCreated).format('lll')}
            </Typography>
            <Typography variant="body1" style={{ float: 'Right' }}>
              <b>Updated On:</b> {moment(order.timeUpdated).format('lll')}
            </Typography>
          </div>
          <br />
          <b>Customer</b> #{ String(order.customerID).padStart(5, '0')}
          <span style={{ marginLeft: window.innerWidth * 0.08 }}>
              {
                onOrderClick ?
                <Button variant="flat" color="primary" onClick={() => onOrderClick(order)} style={styles.orderlinkButton}>
                  {(`Order #${String(order.customerOrder).padStart(6, '0')}`)}
                </Button>
                :
                <Link to={`/edit-co/${order.customerOrder}`}>
                  Order #{String(order.customerOrder).padStart(6, '0')}
                </Link>

              }
          </span>
          <Typography style={{ float: 'right' }} variant="body1">
            <Link to={`/delivery-routes?deliveryDate=${order.deliveryDate}&routeName=${order.deliveryRouteName}`}>
              {(`${String(order.deliveryRouteName)}`)}
            </Link>
          </Typography>
        </Typography>
        <div>
          <Typography style={{ float: 'left' }} variant="body1">
            <b>Delivery Boy:</b> {(`${String(order.deliveryBoyName)} ${order.deliveryBoyMobile ? `( ${String(order.deliveryBoyMobile || '')} )` : ''} `)}
          </Typography>
          <Typography style={{ float: 'right' }} variant="body1">
            <b>Supervisor:</b> {(`${String(order.supervisorName)} ${order.supervisorMobile ? `( ${String(order.supervisorMobile || '')} )` : ''} `)}
          </Typography>
        </div>
        <div>
          <Typography variant="body1" style={{ float: 'right', clear: 'both' }}>
            Package Status:
            &nbsp;
            <b style={{
              color: 'white',
              fontSize: '16px',
              backgroundColor: ['INCOMPLETE', 'NOT_PACKED'].includes(order.packageStatus) ? 'rgb(245, 0, 8)' : 'rgb(39, 163, 5)',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}>
              {order.packageStatus}
            </b>
        </Typography>
        </div>
      </div>
      <div>
        <Typography variant="body2">
          {order.customerName }
          <span style={{ marginRight: window.innerWidth * 0.23, float: 'right', fontStyle: 'italic' }}>
            {order.status}
          </span>
          <Typography variant="body2" />
        </Typography>
      </div>

      <div>
        <Typography variant="body1">
          {order.address}
        </Typography>
        {/* <Button style={{ float: 'right' }} color="secondary" variant="outlined" onClick={displayLabel}> Print Label </Button> */}
      </div>
    </Paper>);
};

OrderCard.propTypes = {
  order: PropTypes.instanceOf(Object),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onOrderClick: PropTypes.func,
};

export default OrderCard;
