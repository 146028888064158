import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import apiCall, { getProductOffersList, getAllOffers } from '../../../api/NetworkHandler';
import OfferCampaignSearch from '../components/OfferCampaignSearch';
import OfferCampaignList from '../components/OfferCampaignList';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import { ROWS_PER_PAGE } from '../../../constants';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';


const Constants = {
  title: 'Offer Campaign',
};

var versionDict = {
  'getProductRequestId': 0,
};

class OfferCampaignContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersData: [],
      filters: {
        startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endTime: moment().format('YYYY-MM-DD'),
        query: '',
        status: true,
        offerType: 'PRODUCT_OFFER',
      },
      totalElements: 0,
      currentPage: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount= () => {
    this.fetchParams();
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    }, () => this.fetchProductOfferList());
  };

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({
      filters: getFilterParams
    }, () => this.fetchProductOfferList());
    // this.fetchProductOfferList(getFilterParams);
  }

  fetchProductOfferList = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    try {
      versionDict.getProductRequestId += 1;
      let prevRequestId = versionDict.getProductRequestId;
      const url = getProductOffersList(filters, page, size);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data: { content = [], totalElements = 0 } } } = res;
      if (versionDict.getProductRequestId == prevRequestId) {
        this.setState({
          offersData: content,
          totalElements,
        });
      }
    } catch (e) {
      this.setState({
        offersData: [],
        totalElements: 0,
      });
    }
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.fetchProductOfferList() })
  };

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.fetchProductOfferList() })
  };

  render() {
    const {
      offersData, filters, totalElements, currentPage, rowsPerPage,
    } = this.state;
    const { productItemId = '' } = filters;

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <OfferCampaignSearch queryId={productItemId} />
        </SearchBar>
        <OfferCampaignList
          offersData={offersData}
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={this.onPageChange}
          onRowsPerPageChange={this.onRowsPerPageChange}
        />
      </div>
    );
  }
}

export default withRouter(OfferCampaignContainers);
