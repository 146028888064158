import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  DialogActions,
  Modal,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import { Grid, Row } from 'react-flexbox-grid';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { ColMod } from '../../../components/lib';
import Upload from './Upload';
import API, {
  productItemPath,
  productItemsPath,
  vendorCatalogPath,
  vendorProductMappingPath,
  findPackaging,
  getVendorProductItemsByCatalog,
  fetchMOQStatsByProductItemId,
  updateMOQ,
} from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import ProductItemMapping from '../../Commons/components/ProductItemMapping';
import { Button } from '../../../ui';
import SectionMessage from '@atlaskit/section-message';
import SearchWithSuggestions from "../../Commons/components/SearchWithSuggestions";
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import Select from '@atlaskit/select';
import { InputNumber } from 'antd';



const STATUSES = [{ value: 'ACTIVE', text: 'Active' }, { value: 'INACTIVE', text: 'Inactive' }];

var versionDict = {
  "moqRequestId": 0
}

class ProductItemForm extends Component {
  constructor(props) {
    super(props);
    const { productItem = {} } = this.props;
    this.state = {
      productItem,
      packagingList: [],
      productId: null,
      vendorMapping: [],
      vendorCatalogs: [],
      vendorProductItems: [],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      invalidName: false,
      invalidUomId: false,
      invalidCutoffTime: false,
      invalidUnitPrice: false,
      invalidCostPrice: false,
      invalidSellingPrice: false,
      invalidTax: false,
      invalidDiscount: false,
      invalidUnitMeasure: false,
      sellingPriceMessage: '',
      invalidPackaging: false,
      productItemCutOffTimeMin: '',
      productItemCutOffTimeMax: '',
      openMOQStats: false,
      MOQStats: [],
      selectedMOQ: {},
      successMessage: "",
      invalidMOQ: false,
      invalidProductItemType: false,
    };
  }

  componentDidMount() {
    const { packaging } = this.state.productItem;
    this.getAllPackagingList(packaging);
    this.setTimeState();
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      productItem, productId, vendorMapping, productName
    } = nextProps;
    const { unitMeasure, uom = {}, packaging } = productItem;
    return {
      productItem: {
        unitMeasure: unitMeasure || '1',
        ...productItem,
        // name: `${productName}-${unitMeasure || ''}${uom.code || ''}`,
        name: `${productName}-${unitMeasure || ''}${uom.code || ''}${' '}${packaging || ''}`,
        // productItemType: '',
      },
      vendorMapping,
      productId,
    };
  }

  setTimeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { productItemCutOffTimeMin, productItemCutOffTimeMax } = appConfig;
    this.setState({
      productItemCutOffTimeMin,
      productItemCutOffTimeMax,
    });
  }

  getMOQStatsByProductItemId = async (productItemId) => {
    try {
      versionDict.moqRequestId += 1;
      let prevRequestId = versionDict.moqRequestId;
      const response = await API.sendRequest("get", fetchMOQStatsByProductItemId(productItemId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.moqRequestId) {
        this.setState({
          MOQStats: data.map(el => { return { label: el.deliveryDate, value: el } }),
        });
      }
    } catch (err) {
      this.setState({
        MOQStats: []
      });
    }
  };

  handleChange = (key, event) => {
    const { onProductItemChange, UOM } = this.props;
    let { value } = event.target;
    if (value < 0) return;
    let { productItem, productItemCutOffTimeMin, productItemCutOffTimeMax } = this.state;
    let { unitPrice } = productItem;
    if (key === 'active') {
      value = value === 'ACTIVE';
    }
    if (key === 'uom') {
      value = UOM.find(item => item.id === value) || {};
    }
    if (key === 'cutOffTime') {
      if (value < productItemCutOffTimeMin) {
        return;
      }
      if (value > productItemCutOffTimeMax) {
        return;
      }
    }
    if (key === 'unitPrice') {
      productItem = {
        ...productItem,
        sellingPrice: value,
        discount: 0,
      }
      onProductItemChange(productItem);
    }
    if (key === 'sellingPrice') {
      if (value > parseFloat(unitPrice)) {
        this.setState({
          sellingPriceMessage: "more than Unit Price not allowed",
          invalidPrice: true
        });
        productItem = {
          ...productItem,
          sellingPrice: unitPrice,
          discount: 0,
        }
        onProductItemChange(productItem);
        return;
      } else {
        this.setState({
          sellingPriceMessage: '',
          invalidPrice: false,
        });
        productItem = {
          ...productItem,
          sellingPrice: value,
          discount: ((unitPrice - value) / unitPrice * 100).toFixed(2)
        }
        onProductItemChange(productItem);
      }
    }
    if (key === 'discount') {
      if (value > 100) return;
      else {
        productItem = {
          ...productItem,
          sellingPrice: ((100 - value) / 100 * unitPrice).toFixed(2),
          discount: value,
        }
        onProductItemChange(productItem);
      }
    }
    productItem[key] = value;
    onProductItemChange(productItem);
  }

  getAllPackagingList = async (query = '') => {
    try {
      const response = await API.sendRequest('get', findPackaging(query));
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      const { packaging = [] } = embedded;
      this.setState({
        packagingList: packaging,
      });
    } catch (error) {
      this.setState({
        packagingList: [],
      });
    }
  }

  handlePackagingSearch = query => {
    const { onProductItemChange } = this.props;
    let { productItem } = this.state;
    this.setState({
      inputData: query,
    });
    productItem = {
      ...productItem,
      packaging: this.validatePackaging(query) ? query : '',
    }
    onProductItemChange(productItem);
    this.getAllPackagingList(query);
  };

  handlePackagingSelection = item => {
    const { name } = item;
    const { onProductItemChange } = this.props;
    let { productItem } = this.state;
    productItem = {
      ...productItem,
      packaging: name
    }
    onProductItemChange(productItem);
  };

  clearImage = (image) => {
    if (image.id) {
      const { productItem: { productItemImages = [] } } = this.state;
      const index = productItemImages.findIndex(img => img.position === image.position);
      productItemImages.splice(index, 1);
      let { productItem } = this.state;
      productItem = {
        ...productItem,
        productItemImages,
      };

      this.setState({ productItem });
    } else {
      const { urls } = this.state;
      const index = urls.findIndex(img => img.position === image.position);
      urls[index].imageUrl = null;
      this.setState({
        urls,
      });
    }
  }

  addProductItemAttributes = () => {
    let { productItem } = this.state;
    const { onProductItemChange } = this.props;
    let { productItemAttributes } = productItem;
    productItemAttributes = [...productItemAttributes, { name: '', value: '' }];
    productItem = {
      ...productItem,
      productItemAttributes,
    };
    onProductItemChange(productItem);
  }

  removeProductItemAttributes = (i) => {
    const { productItem } = this.state;
    const { productItemAttributes } = productItem;
    productItemAttributes.splice(i, 1);
    this.setState({ productItem: { ...productItem, productItemAttributes } });
  }

  handleAttrChange = (event) => {
    const { name, value } = event.target;
    const { onProductItemChange } = this.props;
    let { productItem = {} } = this.state;
    // let { productItemAttributes = [{ name: 'PLU_NO', value: '' }] } = productItem;
    // if (productItemAttributes.length < 1) {
    //   productItemAttributes = [...productItemAttributes, { [field]: value }];
    // } else {
    //   productItemAttributes[i][field] = value;
    // }
    productItem = {
      ...productItem,
      productItemAttributes: [{ name: name, value: value }]
    };
    onProductItemChange(productItem);
  }

  updateImageUrls = () => {
    const { urls: prevUrls = [], productItem } = this.state;
    const { productItemImages = [] } = productItem;
    const urls = prevUrls.map((url) => {
      const image = productItemImages.find(img => img.position === url.position);
      if (image && !url.imageUrl) return image;
      return {
        ...url,
        productItem: productItem.id ? `${BASE_URL.BASE_URL}/api/v1/staging-products/${productItem.id}` : null,
      };
    });
    return urls;
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  fetchProductCatalog = async (query = '') => {
    const response = await API.sendRequest('get', vendorCatalogPath(query));
    const { _embedded: embedded } = response.data;
    this.setState({
      vendorCatalogs: embedded['vendor-catalogs'],
    });
  }

  fetchProductItems = async (catalogId, query) => {
    const response = await API.sendRequest('get', getVendorProductItemsByCatalog(catalogId, query));
    const { _embedded: embedded } = response.data;
    this.setState({
      vendorProductItems: embedded['vendor-product-items'],
    });
  }

  // fetchProductItems = async (productId, catalogId, query) => {
  //   const response = await API.sendRequest('get', findProductItemsByCatalogProductIdAndName(productId, catalogId, query));
  //   const { _embedded: embedded } = response.data;
  //   console.log(embedded);
  //   this.setState({
  //     vendorProductItems: embedded['vendor-product-items'],
  //   });
  // }

  updatingProductMapping = async (id) => {
    const { vendorMapping } = this.state;
    const url = vendorProductMappingPath(id);
    const postData = vendorMapping.filter(item => !isEmpty(item));
    await API.sendRequest('post', url, postData);
  }

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      productItem: {},
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      invalidName: false,
      invalidUomId: false,
      invalidCutoffTime: false,
      invalidUnitPrice: false,
      invalidCostPrice: false,
      invalidSellingPrice: false,
      invalidTax: false,
      invalidDiscount: false,
      invalidUnitMeasure: false,
      errorMessage: '',
      mappingText: '',
      invalidPrice: false,
      sellingPriceMessage: '',
      invalidPackaging: false,
      invalidProductItemType:false,
    });
    onClose();
  }

  validatePackaging = (query) => {
    const { packagingList = [] } = this.state;
    let invalidPackagingQuery = false;
    packagingList.forEach(item => {
      if (item.name === query) {
        invalidPackagingQuery = true;
      }
    });
    return invalidPackagingQuery;
  }

  validateForm = () => {
    const {
      name,
      cutOffTime,
      uom,
      sellingPrice,
      unitPrice,
      costPrice,
      tax,
      discount,
      unitMeasure,
      packaging,
      isPublished,
      productItemType,
    } = this.state.productItem;
    // const { vendorMapping } = this.state;
    let isFormValid = true;

    // if(vendorMapping.length == 0) {
    //   isFormValid = false;
    //   this.setState({ 
    //     mappingText: 'Atleast One Product Mapping Required',
    //    });
    // } else {
    //   isFormValid = true
    //   this.setState({
    //     mappingText: '',
    //   })
    // }
    if (!name) {
      isFormValid = false;
      this.setState({ invalidName: true });
    } else {
      this.setState({ invalidName: false });
    }
    if (!cutOffTime) {
      isFormValid = false;
      this.setState({ invalidCutoffTime: true });
    } else {
      this.setState({ invalidCutoffTime: false });
    }
    if (!uom) {
      isFormValid = false;
      this.setState({ invalidUomId: true });
    } else {
      this.setState({ invalidUomId: false });
    }
    if (!sellingPrice) {
      isFormValid = false;
      this.setState({ invalidSellingPrice: true });
    } else {
      this.setState({ invalidSellingPrice: false });
    }
    if (!unitPrice) {
      isFormValid = false;
      this.setState({ invalidUnitPrice: true });
    } else {
      this.setState({ invalidUnitPrice: false });
    }
    if (!costPrice) {
      isFormValid = false;
      this.setState({ invalidCostPrice: true });
    } else {
      this.setState({ invalidCostPrice: false });
    }
    if ((typeof tax == 'string' && !tax) || typeof tax == 'undefined') {
      isFormValid = false;
      this.setState({ invalidTax: true });
    } else {
      this.setState({ invalidTax: false });
    }
    if ((typeof discount == 'string' && !discount) || typeof discount == 'undefined') {
      isFormValid = false;
      this.setState({ invalidDiscount: true });
    } else {
      this.setState({ invalidDiscount: false });
    }

    if (!Number(unitMeasure)) {
      isFormValid = false;
      this.setState({ invalidUnitMeasure: true });
    } else {
      this.setState({ invalidUnitMeasure: false });
    }
    if (!isPublished) {
      if (!packaging) {
        isFormValid = false;
        this.setState({ invalidPackaging: true });
      } else {
        this.setState({ invalidPackaging: false });
      }
    }
    if (!productItemType) {
      isFormValid = false;
      this.setState({ invalidProductItemType: true });
    } else {
      this.setState({ invalidProductItemType: false });
    }
    return isFormValid;
  }

  priceValidationOnProductItemType = () => {
    const { productItem } = this.state;
    const { sellingPrice, productItemType } = productItem;
    let isInvalid = false;
    if (productItemType === 'FREEBIE' && sellingPrice > 0) {
      this.setState({
        errorMessage: "Selling price should be 0 in FREEBIE product",
        invalidSellingPrice: true,
      });
      isInvalid = true;
    }
    else if (["SIMPLE", "HIDDEN"].includes(productItemType) && sellingPrice <= 0) {
      this.setState({
        errorMessage: "Selling price should be more than 0 in SIMPLE/HIDDEN product",
        invalidSellingPrice: true,
      });
      isInvalid = true;
    }
    return isInvalid;
  };

  handleSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    if (this.priceValidationOnProductItemType()) {
      return;
    }
    const { onRefresh } = this.props;
    let { productItem } = this.state;
    const { uom } = productItem;
    const { productId } = this.state;
    const urls = this.updateImageUrls();
    productItem.productItemImages = urls.filter((url) => {
      if (url.imageUrl) return true;
      return false;
    });
    const timeString = `2019-01-01T${productItem.cutOffTime}`;
    const formattedCutOffTime = moment(timeString).format('HH:mm:ss');
    productItem.productId = productId;
    productItem.cutOffTime = formattedCutOffTime;
    try {
      let itemId = productItem.id;
      if (productItem.id) {
        productItem = {
          ...productItem,
          uom: uom.id ? `${BASE_URL.BASE_URL}/api/v1/uoms/${uom.id}` : null,
          stagingProduct: `${BASE_URL.BASE_URL}/api/v1/staging-products/${productId}`,
        };
        await API.sendRequest('patch', productItemPath(productItem.id), productItem);
      } else {
        productItem = {
          ...productItem,
          uom: uom.id ? `${BASE_URL.BASE_URL}/api/v1/uoms/${uom.id}` : null,
          stagingProduct: `${BASE_URL.BASE_URL}/api/v1/staging-products/${productId}`,
        };
        const response = await API.sendRequest('post', productItemsPath, productItem);
        const { data } = response;
        itemId = data.id;
      }
      this.updatingProductMapping(itemId);
      onRefresh();
      this.handleCancel();
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }

  openMOQStatsModal = () => {
    const { productItem: { id } = {} } = this.state;
    id && this.getMOQStatsByProductItemId(id);
    this.setState({
      openMOQStats: true,
    });
  };

  closeMOQStatsModal = () => {
    this.setState({
      openMOQStats: false,
      selectedMOQ: {},
    });
  };

  handleSelectDate = (selectedValue) => {
    this.setState({
      selectedMOQ: selectedValue.value,
      successMessage: "",
    });
  };

  handleMOQChange = (value) => {
    const { selectedMOQ } = this.state;
    let newValue = parseInt(value);
    if (value !== newValue) {
      this.setState({
        invalidMOQ: true
      });
      return;
    } else {
      this.setState({
        selectedMOQ: {
          ...selectedMOQ,
          moq: value,
        },
        invalidMOQ: false
      });
    }
  };

  updateMOQ = async () => {
    const { selectedMOQ } = this.state;
    const postData = { deliveryDate: selectedMOQ.deliveryDate, productItemId: selectedMOQ.productItemId, moq: selectedMOQ.moq }
    try {
      await API.sendRequest("post", updateMOQ, postData);
      this.setState({
        successMessage: "MOQ is successfully updated!"
      });
    } catch (err) {
      this.setState({
        successMessage: "",
      });
    }
  };

  render() {
    const breakline = '\u000A';
    const {
      productItem = {},
      vendorMapping,
      vendorCatalogs,
      vendorProductItems,
      invalidName,
      invalidUomId,
      invalidCutoffTime,
      invalidUnitPrice,
      invalidCostPrice,
      invalidSellingPrice,
      invalidTax,
      invalidDiscount,
      invalidUnitMeasure,
      invalidPackaging,
      errorMessage,
      // mappingText,
      invalidPrice,
      sellingPriceMessage,
      packagingList,
      productItemCutOffTimeMin,
      productItemCutOffTimeMax,
      openMOQStats,
      MOQStats,
      selectedMOQ,
      successMessage,
      invalidMOQ,
      invalidProductItemType,
    } = this.state;
    const {
      active,
      costPrice,
      cutOffTime,
      description,
      discount,
      name,
      packaging,
      sellingPrice,
      sizeHeight,
      sizeLength,
      sizeWidth,
      tags,
      tax,
      maxDailyOrderQuantity,
      unitMeasure,
      uom = {},
      unitPrice,
      cgst,
      weightInGrams,
      productItemAttributes = [],
      sortSequence,
      isPublished = false,
      productItemType = '',
      barcode,
    } = productItem;
    const { id: uomId } = uom;
    const selectedStatus = active ? 'ACTIVE' : 'INACTIVE';
    const {
      UOM, open = false, onMappingChange,
    } = this.props;
    let modProductItemAttributes = productItemAttributes;
    if (productItemAttributes.length === 0) {
      modProductItemAttributes = [...productItemAttributes, { name: '', value: '' }];
    }
    const urls = this.updateImageUrls();
    const invalidCost = parseFloat(sellingPrice) < parseFloat(costPrice);

    return (
      <Dialog
        open={open}
        onClose={this.handleCancel}
        maxWidth="70%"
      >
        <div style={{ width: '100%' }}>
          <Button style={{ float: 'right', width: '20px', height: '20px' }} onClick={this.handleCancel} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
        </div>
        <DialogTitle>
          <Typography style={{ marginLeft: '0.5em' }} variant="title">{productItem.id ? 'Edit' : 'Add'} Product Item
            <span style={{ fontSize: 14, color: 'black' }}> {productItem.name ? `(${productItem.name})` : ''}</span>
          </Typography>
          <Typography style={{ marginLeft: '0.5em' }} color="error" variant="caption">* required</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid>
            {errorMessage && <Row>
              <ColMod lg={12}>
                <SectionMessage appearance="error">
                  {errorMessage}
                </SectionMessage>
              </ColMod>
            </Row>}
            <Row lg={12}>
              <ColMod custompadding lg={10}>
                <TextField
                  label="Name"
                  required
                  error={invalidName}
                  value={name}
                  inputProps={{ readOnly: true }}
                  helperText="Note: Name will be auto-set from Unit Measure, UOM and Packaging"
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={2}>
                <InputLabel style={{ fontSize: '0.9em' }} htmlFor="status-type">Status</InputLabel>
                <MuiSelect
                  value={selectedStatus}
                  onChange={e => this.handleChange('active', e)}
                  inputProps={{ name: 'active', id: 'status-type' }}
                  fullWidth
                >
                  {
                    STATUSES.map(x => (<MenuItem key={x.value} value={x.value}>{x.text}</MenuItem>))
                  }
                </MuiSelect>
              </ColMod>
            </Row>
            <Row>
              <ColMod custompadding lg={10}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={e => this.handleChange('description', e)}
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={2}>
                <TextField
                  label="Sort Sequence"
                  type="number"
                  value={sortSequence}
                  onChange={e => this.handleChange('sortSequence', e)}
                  fullWidth
                />
              </ColMod>
            </Row>
            <Row>
              <div style={{ width: '100%' }}>
                <Typography style={{ marginTop: '1em', marginBottom: '0.2em', marginLeft: '1em' }} variant="body2">Product Properties</Typography>
              </div>
              <ColMod custompadding lg={4}>
                <TextField
                  label="Unit Measure"
                  type="number"
                  value={unitMeasure}
                  onChange={e => this.handleChange('unitMeasure', e)}
                  fullWidth
                  error={invalidUnitMeasure}
                  required
                  disabled={isPublished}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </ColMod>
              <ColMod custompadding lg={4}>
                <InputLabel style={{ fontSize: '0.7em', color: invalidUomId ? 'red' : '' }} htmlFor="uom-id">UOM ID<sup style={{ fontSize: '1.2em' }}>*</sup></InputLabel>
                <MuiSelect
                  value={uomId || ''}
                  onChange={e => this.handleChange('uom', e)}
                  inputProps={{
                    name: 'uom',
                    id: 'uom-id',
                  }}
                  fullWidth
                  disabled={isPublished}
                >
                  {UOM.map(x => (<MenuItem key={x.id} value={x.id}>{x.code}</MenuItem>))}
                </MuiSelect>
              </ColMod>
              <ColMod lg={4} custompadding>
                <InputLabel style={{ fontSize: '0.9em', color: invalidPackaging ? 'red' : '' }}> Packaging <sup>*</sup></InputLabel>
                <div>
                  {!isPublished ?
                    <SearchWithSuggestions
                      searchQuery={packaging || ''}
                      suggestions={packagingList}
                      onChange={this.handlePackagingSearch}
                      onSelect={this.handlePackagingSelection}
                    /> :
                    <TextField
                      value={packaging}
                      fullWidth
                      disabled
                    />}
                </div>
              </ColMod>
            </Row>
            <Row>
              <ColMod custompadding lg={3}>
                <TextField
                  label="Width(cms)"
                  type="number"
                  value={sizeWidth}
                  onChange={e => this.handleChange('sizeWidth', e)}
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={3}>
                <TextField
                  label="Height(cms)"
                  type="number"
                  value={sizeHeight}
                  onChange={e => this.handleChange('sizeHeight', e)}
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={3}>
                <TextField
                  label="Length(cms)"
                  type="number"
                  value={sizeLength}
                  onChange={e => this.handleChange('sizeLength', e)}
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={3}>
                <TextField
                  label="Weight(gms)"
                  type="number"
                  value={weightInGrams}
                  onChange={e => this.handleChange('weightInGrams', e)}
                  fullWidth
                />
              </ColMod>
            </Row>
            <Row>
              <ColMod custompadding lg={3}>
                <TextField
                  fullWidth
                  label="Barcode/EAN"
                  type="number"
                  value={barcode || ''}
                  onChange={e => this.handleChange('barcode', e)}
                />
              </ColMod>
              <ColMod custompadding lg={3}>
                <InputLabel style={{ fontSize: '0.7em', color: invalidProductItemType ? 'red' : '' }} htmlFor="status-type"> Product Item Type <sup style={{ fontSize: '1.2em' }}>*</sup></InputLabel>
                <MuiSelect
                  value={productItemType}
                  onChange={e => this.handleChange('productItemType', e)}
                  inputProps={{ name: 'productItemType', id: 'productItemType-type' }}
                  fullWidth
                  disabled={isPublished}
                >
                  {["SIMPLE", "SAMPLE", "FREEBIE", "HIDDEN"].map((x, index) => (<MenuItem key={index} value={x}>{x}</MenuItem>))}
                </MuiSelect>
              </ColMod>
              <ColMod custompadding lg={3}>
                <TextField
                  label="Tags"
                  value={tags}
                  onChange={e => this.handleChange('tags', e)}
                  fullWidth
                />
              </ColMod>
              <ColMod custompadding lg={3}>
                <TextField
                  fullWidth
                  id="datetime-local"
                  label="Cutoff Time"
                  type="time"
                  required
                  error={invalidCutoffTime}
                  helperText={`Please select cut-off time between ${productItemCutOffTimeMin} to ${productItemCutOffTimeMax}`}
                  value={cutOffTime}
                  onChange={e => this.handleChange('cutOffTime', e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </ColMod>
            </Row>
            <Row>
              <div style={{ width: '100%' }}>
                <Typography style={{ marginTop: '1em', marginBottom: '0.2em', marginLeft: '1em' }} variant="body2">Price,Tax and Discount & Item Pictures</Typography>
              </div>
              <ColMod custompadding="true" zerobottom="true" lg={6}>
                <div style={{ padding: '0.5em' }}>
                  <TextField
                    label="Unit Price"
                    type="number"
                    value={unitPrice || ''}
                    onChange={e => this.handleChange('unitPrice', e)}
                    required
                    error={invalidUnitPrice}
                  />
                  &emsp; &emsp;
                  <TextField
                    label="Selling Price"
                    type="number"
                    value={sellingPrice || ''}
                    onChange={e => this.handleChange('sellingPrice', e)}
                    required
                    error={invalidSellingPrice || invalidPrice}
                    helperText={sellingPriceMessage}
                    InputLabelProps={{ shrink: parseFloat(sellingPrice) >= 0 || parseFloat(sellingPrice) < 0 ? true : false }}
                  />
                  {/* <small style={{ color: 'red' }}>{sellingPriceMessage}</small> */}
                </div>
                <div style={{ padding: '0.5em' }}>
                  <TextField
                    label="Cost Price"
                    type="number"
                    value={costPrice || ''}
                    onChange={e => this.handleChange('costPrice', e)}
                    required
                    error={invalidCostPrice || invalidCost}
                    helperText={invalidCost && "more than selling price not allowed"}
                  />
                  &emsp; &emsp;
                  <TextField
                    label="Discount(%)"
                    type="number"
                    value={discount}
                    onChange={e => this.handleChange('discount', e)}
                    required
                    error={invalidDiscount}
                    InputLabelProps={{ shrink: parseFloat(discount) >= 0 || parseFloat(discount) < 0 ? true : false }}
                  />
                </div>
                <div style={{ padding: '0.5em' }}>
                  <TextField
                    label="Tax(%)"
                    type="number"
                    value={tax}
                    onChange={e => this.handleChange('tax', e)}
                    required
                    error={invalidTax}
                  />
                  &emsp; &emsp;
                  <TextField
                    label="Max Order Quantity"
                    type="number"
                    value={maxDailyOrderQuantity || ''}
                    // onChange={e => this.handleChange('maxDailyOrderQuantity', e)}
                    onClick={this.openMOQStatsModal}
                    disabled
                  />
                </div>
              </ColMod>
              <ColMod custompadding lg={6}>
                <div style={{ marginTop: '10px' }}>
                  {
                    urls.map((url, i) => (
                      <Upload
                        key={i}
                        url={url.imageUrl}
                        side={url.position}
                        handleImageUpload={
                          (imageUrl, position) => this.handleImageUpload(imageUrl, position)
                        }
                        clearImage={() => this.clearImage(url)}
                      />
                    ))
                  }
                </div>
              </ColMod>
            </Row>
            <Row>
              <ColMod custompadding="true" zerobottom="true" lg={6}>
                <Typography style={{ marginTop: '0.4em', marginLeft: '0.2em' }} variant="body2">Product Attributes</Typography>
                {modProductItemAttributes.map((attr, i) => (
                  <div key={i} style={{ paddingTop: '0.7em' }}>
                    <TextField
                      style={{ marginLeft: '0.4em' }}
                      value={attr.value}
                      name="PLU_NO"
                      onChange={this.handleAttrChange}
                      label="PLU_NO"
                    />
                    &emsp; &emsp;
                    <Button onClick={this.openMOQStatsModal} variant="contained" color="primary" disabled={!!(!productItem.id)} > MOQ stats </Button>
                  </div>
                ))}
              </ColMod>
              <ColMod custompadding="true" zerobottom="true" lg={12}>
                <div style={{ marginBottom: '15px' }}>
                  <Typography style={{ marginTop: '0.4em', marginLeft: '0.2em' }} variant="body2">
                    Vendor Product Item Mapping
                    {/* <span style={{ color: 'red', marginLeft: '50px' }}>{mappingText}</span> */}
                  </Typography>
                </div>
                <ProductItemMapping
                  data={vendorMapping}
                  catalogs={vendorCatalogs}
                  productItems={vendorProductItems}
                  productId={this.state.productId}
                  productName={this.props.productName}
                  onCatalogSearch={this.fetchProductCatalog}
                  onProductItemSearch={this.fetchProductItems}
                  onChange={onMappingChange}
                  UOM={UOM}
                  productItemData={productItem}
                />
              </ColMod>
            </Row>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} style={{ marginRight: '2em' }} color="secondary" variant="outlined" > Cancel </Button>
          <Button onClick={this.handleSave} variant="contained" color="primary" > Save </Button>
        </DialogActions>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openMOQStats}
          onClose={this.closeMOQStatsModal}
        >
          <div style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            width: '40%',
            margin: '0 auto',
            // height: '95%',
            minHeight: '50vh',
          overflowX: 'auto',
          overflowY: 'auto',
          borderRadius: '5px',
          }}
          >
          <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", top: 0, width: "100%" }}>
            <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: "50px", paddingTop: "10px" }}>
              <Typography variant="title" color="inherit"> MOQ Stats </Typography>
              <Typography variant="body2" color="primary" style={{ paddingLeft: '20px' }}> {successMessage || ""} </Typography>
            </div>
            <Button style={{ float: 'right' }} onClick={this.closeMOQStatsModal} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </div>
          <Grid style={{ padding: '2em', marginTop: window.innerHeight * 0.05 }}>
            <Row>
              <ColMod custompadding="true" bumphigh="true" xs md lg={15}>
                <Row>
                  <ColMod custompadding="true" bumphigh="true" lg={12}>
                    <Typography variant="caption" style={{ color: "#7753A4" }}> Select a delivery date: </Typography>
                    <Select
                      className="checkbox-select"
                      classNamePrefix="select"
                      options={MOQStats}
                      placeholder="Choose a date"
                      onChange={this.handleSelectDate}
                    />
                    <Typography variant="caption" style={{ color: "red" }}> Update the changed data before changing the date. </Typography>
                  </ColMod>
                </Row>
                {!isEmpty(selectedMOQ) && <Row>
                  <ColMod custompadding="true" bumphigh="true" lg={12}>
                    <Paper>
                      <div style={{ padding: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Product Item ID: </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.productItemId} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Cut-off Time: </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.cutOffTime} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Last Updated On: </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {moment(selectedMOQ.lastUpdatedTime).format("lll")} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Avg. Qty. Per Customer: </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.avgQuantityPerCustomer} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Successfully Orderd Created Qty. (S1): </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.successfullyOrderedQty} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Qty Accepted, but order yet to be created (S2): </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.qtyOrderedPostOrderCron} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Total Qty. (S1 + S2): </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}> {selectedMOQ.successfullyOrderedQty + selectedMOQ.qtyOrderedPostOrderCron} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> Present MOQ: </Typography>
                          <Typography variant="body2" color="primary" style={{ width: "50%", textAlign: "left" }}>
                            {selectedMOQ.moq === -1 ? `Unlimited and cannot be overridden by System MOQ.` : selectedMOQ.moq === null ? `Unlimited, but can be overridden by System MOQ.` : selectedMOQ.moq} </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }}>
                          <Typography variant="body2" color="inherit" style={{ width: "70%", textAlign: "right", paddingRight: "20px" }}> MOQ: </Typography>
                          <div style={{ width: "50%", textAlign: "left" }}>
                            <InputNumber
                              value={selectedMOQ.moq}
                              onChange={this.handleMOQChange}
                              style={{ width: "70%" }}
                            />
                            <Typography variant="caption" style={{ color: "red" }}> {invalidMOQ ? 'Invalid MOQ' : ''} </Typography>
                          </div>
                        </div>
                        <div>
                          <span style={{ color: "indigo" }}>Note: MOQ must be between 1 to any positive value. Use:<br />
                            -1 = Unlimited and cannot be overridden by System MOQ.<br />
                            0 = MOQ set to 0<br />
                            empty = Unlimited, but can be overridden by System MOQ.</span>
                        </div>
                      </div>
                    </Paper>
                  </ColMod>
                </Row>}
              </ColMod>
            </Row>
          </Grid>
          <div style={{ position: "fixed", bottom: 15, marginLeft: "83%" }}>
            <Button variant="contained" color="secondary" onClick={this.updateMOQ} disabled={isEmpty(selectedMOQ) || invalidMOQ}> Update </Button>
          </div>
          </div>
        </Modal>
      </Dialog >
    );
  }
}
ProductItemForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onMappingChange: PropTypes.func,
  onProductItemChange: PropTypes.func,
  productItem: PropTypes.instanceOf(Object),
  UOM: PropTypes.instanceOf(Object),
};

export default ProductItemForm;
