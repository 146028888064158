import React, { Component, Fragment } from 'react';
import ProgressBar from '../../../components/States/ProgressBar';
import SearchBar from '../../Commons/components/SearchBar';
import NavBar from '../../../components/NavBar';
import CatalogSearch from '../components/CatalogSearch';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import apiCall from '../../../api/NetworkHandler';
import { Button, Drawer } from '@material-ui/core';
import debounce from 'lodash/debounce';
import Lozenge from '@atlaskit/lozenge';
import NewCatalogForm from '../components/NewCatalogForm';
import sortBy from 'lodash/sortBy';
import paginateData from '../../../utils/PaginateData';

const columnData = [
  {
    id: 'vendorName', numeric: false, disablePadding: true, label: 'Vendor Name',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Catalog Name',
  },
  {
    id: 'comments', numeric: false, disablePadding: true, label: 'Comments',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Last Updated',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class VendorCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressBar: false,
      data: [],
      tableData: [],
      rowsPerPage: 10,
      page: 0,
      count: 0,
      searchTerm: '',
      searchData: [],
      order: 'asc',
      orderBy: 'vendorName',
      openNewCatalogForm: false,
      vendorCatalog: {},
      vendorOptions: [],
      vendorFilterId: '',
      isNoData: false,
    }
  }

  componentDidMount() {
    this.fetchVendorCatalogs();
    this.fetchVendors();
  }

  fetchVendors = () => {
    apiCall.sendRequest('get', `api/v1/vendors/search/findVendors?query=`)
      .then((response) => {
        let vendorOptions = response.data._embedded.vendors.map(vendor => {
          return {
            ...vendor,
            label: vendor.name,
            value: vendor.id,
          }
        })
        vendorOptions = sortBy(vendorOptions, ['name']);
        this.setState({
          vendorOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onFilterChange = (event) => {
    const { value } = event.target;
    this.setState({ vendorFilterId: value }, () => { this.fetchVendorCatalogsByVendorId() });
  }

  fetchVendorCatalogsByVendorId = async () => {
    const { vendorFilterId: id, orderBy } = this.state;
    if (id == 'all') {
      this.fetchVendorCatalogs();
    } else {
      const response = await apiCall.sendRequest('get', `/api/v1/vendor-catalogs/search/findAllByVendorId?id=${id}`);
      const { data: { _embedded } } = response;
      let vendorCatalogs = _embedded['vendor-catalogs'];
      vendorCatalogs = sortBy(vendorCatalogs, orderBy);
      this.setState({
        searchData: vendorCatalogs,
        count: vendorCatalogs.length,
      }, () => { this.paginateSearchedData(); });
    }
  }

  actions = (catalog) => {
    return (
      <Button color="secondary" onClick={() => { this.onEdit(catalog) }}>
        Edit
      </Button>
    )
  }

  onEdit = (catalog) => {
    const { history = {} } = this.props;
    const { id, vendorCatalogVendorID: vendorId } = catalog;

    history.push(`/edit-catalog/${id}/${vendorId}`);
  }

  getStatusComponent = (status) => {
    const appearance = status == 'NEW' ? 'new' : 'success';
    return (
      <Lozenge appearance={appearance}>{status}</Lozenge>
    )
  }

  showOrHideProgressBar = (showBar) => {
    this.setState({
      showProgressBar: showBar,
    })
  }

  fetchOrSearchData = () => {
    const { searchTerm } = this.state;
    if (!searchTerm) {
      this.fetchVendorCatalogs();
    }
    else {
      this.paginateSearchedData();
    }
  }

  handleChangeRowsPerPage = (event) => {
    const perPage = event.target.value;

    this.setState({
      rowsPerPage: perPage
    }, () => { this.fetchOrSearchData(); });
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => { this.fetchOrSearchData(); });
  }

  handleSearchChange = (event) => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm, page: 0 }, () => {
      if (!searchTerm) {
        this.fetchVendorCatalogs();
      } else {
        this.searchVendorCatalogs();
      }
    });
  }

  searchVendorCatalogs = debounce(async () => {
    const { searchTerm, orderBy } = this.state;

    const response = await apiCall.sendRequest('get', `/api/v1/vendor-catalogs/search/findVendorCatalogs?query=${searchTerm}`);
    const { data: { _embedded } } = response;
    let searchedCatalogs = _embedded['vendor-catalogs'];
    searchedCatalogs = sortBy(searchedCatalogs, orderBy);
    this.setState({
      searchData: searchedCatalogs,
      count: searchedCatalogs.length,
    }, () => { this.paginateSearchedData(); });
  }, 400);

  fetchVendorCatalogs = async () => {
    const { rowsPerPage, page, orderBy = 'name' } = this.state;

    this.showOrHideProgressBar(true);
    try {
      const response = await apiCall.sendRequest('get', `/api/v1/vendor-catalogs?page=${page}&size=${rowsPerPage}`);
      const { data: { _embedded, page: pageData } } = response;
      let vendorCatalogs = _embedded['vendor-catalogs'];
      vendorCatalogs = sortBy(vendorCatalogs, orderBy);
      const isNoData = !!(vendorCatalogs && vendorCatalogs.length);
      this.setState({
        data: vendorCatalogs,
        count: pageData.totalElements,
        isNoData: !isNoData,
      });
      this.showOrHideProgressBar(false);
    } catch (error) {
      this.showOrHideProgressBar(false);
      this.setState({ isNoData: true });
    }
  }

  paginateSearchedData = () => {
    const { searchData, page, rowsPerPage } = this.state;
    const paginatedCatalogs = paginateData(searchData, page, rowsPerPage);
    this.setState({
      data: paginatedCatalogs,
    });
  }

  onClearSearch = () => {
    this.setState({
      searchTerm: '',
      searchData: [],
      page: 0,
      vendorFilterId: '',
    }, () => { this.fetchOrSearchData() });
  }

  onRequestSort = (property) => {
    if (['actions', 'status'].includes(property)) {
      return;
    }
    const { data, order: dataOrder, orderBy: dataOrderBy } = this.state;
    const orderBy = property;
    let order = 'desc';

    if (dataOrderBy === property && dataOrder === 'desc') {
      order = 'asc';
    }

    let sortedData = order === 'desc' ?
      data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)) :
      data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

    this.setState({
      tableData: sortedData,
      order,
      orderBy,
    })
  }

  getCustomComponents = () => {
    const { data } = this.state;
    const status = {};
    data.forEach((catalog, index) => {
      status[index] = this.getStatusComponent(catalog.status);
    })
    return {
      status
    }
  }

  onClose = () => {
    this.setState({
      openNewCatalogForm: false,
    })
  }

  openNewCatalogForm = () => {
    this.setState({
      openNewCatalogForm: true,
    })
  }

  render() {
    const {
      showProgressBar,
      data,
      rowsPerPage,
      page,
      count,
      searchTerm,
      order,
      orderBy,
      openNewCatalogForm,
      vendorOptions,
      vendorFilterId,
      isNoData,
    } = this.state;

    return (
      <div>
        <NavBar />
        <ProgressBar isLoading={showProgressBar} />
        <SearchBar title="Vendor Catalog">
          <CatalogSearch
            onChange={this.handleSearchChange}
            value={searchTerm}
            onClearSearch={this.onClearSearch}
            addNewCatalog={this.openNewCatalogForm}
            vendorOptions={vendorOptions}
            vendorFilterId={vendorFilterId}
            onFilterChange={this.onFilterChange}
          />
        </SearchBar>
        <PaginatedTable
          columnData={columnData}
          data={data}
          count={count}
          actions={this.actions}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleChangePage={this.handleChangePage}
          onRequestSort={this.onRequestSort}
          order={order}
          orderBy={orderBy}
          customComponents={this.getCustomComponents()}
          searchTerm={searchTerm}
          isLoading={showProgressBar}
          isNoData={isNoData}
        />
        <Drawer anchor="right" open={openNewCatalogForm} onClose={() => { this.onClose() }}>
          <NewCatalogForm
            onClose={this.onClose}
            onrefresh={this.fetchVendorCatalogs}
          />
        </Drawer>
      </div>
    )
  }
}

export default VendorCatalog;
