import * as ActionType from '../actions';

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOGIN_USER:
      return action.payload;
    case ActionType.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};


export default user;

