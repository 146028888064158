import { Grid, Paper, Tab, Tabs, AppBar, Modal, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import API, {
  customerProfileById,
  findTicketById,
  findTicketsbyCustomerId,
  deliveryRouteOrdersPath,
  ticketAuditPath,
  ticketHistoryPath,
  ticketCommentPath,
  postCommentPath,
  customerAddressesPath,
  customerOrderByFiltersPath,
  getTransactionHistory,
  ticketCallHistoryPath,
  customerOrderById,
  editCustomerOrdersPath,
  emailsByTicketId,
  agentList,
  sendEmail,
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import MessageBoard from '../../DeliveryRoutes/components/MessageBoard';
import OrderCard from '../../DeliveryRoutes/components/OrderCard';
import CallTicket from '../components/CallTicket';
import CustomerDetail from '../components/CustomerDetail';
import TicketList from '../components/TicketList';
import TicketNotes from '../components/TicketNotes';
import TicketHistory from '../components/TicketHistory';
import SelectedItemDetail from '../../Commons/components/SelectedItemDetail';
import AddressList from '../../Customers/Component/AddressList';
import AgentStatus from '../components/AgentStatus';
import TransactionList from '../../Customers/Component/TransactionList';
import TableList from '../../Commons/components/TableList';
import EmailHistory from '../components/EmailHistory';
import CustomerOrderItemTable from '../../CustomerOrders/components/CustomerOrderItemTable';
import Disposition from '../components/Disposition';

const styles = {
  paper: {
    padding: 20,
    marginBottom: 10,
  },
  tabStyle: {
    minWidth: 72,
    background: 'navajowhite',
  },
  modal: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    padding: 20,
  },
  noData: {
    textAlign: 'center',
    color: '#e6e6e6',
    marginTop: 30,
  },
};

const orderColumnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Order Number',
  },
  {
    id: 'delivery_date',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Date',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'Priority',
  },
];

class TicketDetail extends Component {
  constructor(props) {
    super(props);
    const { match: { params = {} } = {} } = this.props;
    this.state = {
      tab: 0,
      ticketId: params.id,
      ticket: {},
      callDetails: [],
      emails: [],
      comments: [],
      deliveryRouteOrder: {},
      customerOrder: {},
      customerProfile: {},
      customerTickets: [],
      customerAddresses: [],
      customerOrders: [],
      customerTransactions: [],
      isLineItemVisible: false,
      agents: [],
    };
  }

  componentDidMount() {
    this.fetchTicket();
    this.getAllAgents();
  }

  componentWillUnmount() {
    console.log('tikcet detail ====> offline the user');
  }

  onClose = () => {
    this.setState({
      isLineItemVisible: false,
    });
  };

  onSaveCustomerOrder = async () => {
    const { customerOrder } = this.state;
    try {
      await API.sendRequest('post', editCustomerOrdersPath, customerOrder);
    } catch (err) {
      console.log(err);
    }
    this.onClose();
    this.fetchDeliveryRouteOrder(customerOrder.id);
  };

  onSubmitDisposition = () => {
    const { ticket } = this.state;
    this.fetchTicketHistory(ticket);
    this.setState({ tab: 2 });
  };

  getAllAgents = async () => {
    const response = await API.sendRequest('get', agentList);
    const { data: { data = [] } = {} } = response;
    this.setState({
      agents: data,
    });
  };

  fetchTicketHistory = async (ticket) => {
    const { id } = ticket;
    const response = await API.sendRequest('get', ticketHistoryPath(id));
    const { data } = response;
    this.setState({
      auditInfoes: data.data,
    });
  };

  fetchTicketNotes = async () => {
    const { ticketId } = this.state;
    const response = await API.sendRequest('get', ticketCommentPath(ticketId));
    const { data: { _embedded: embedded = {} } = {} } = response;
    this.setState({
      comments: embedded['ticket-comments'],
    });
  };

  fetchCustomerProfile = async (customerId) => {
    try {
      const response = await API.sendRequest('get', customerProfileById(customerId));
      const {
        data: { data: customerProfileData },
      } = response;
      this.setState({
        customerProfile: customerProfileData,
      });
    } catch (e) {
      // error handling
    }
  };

  fetchCustomerOrder = async (orderId) => {
    try {
      if (orderId) {
        const response = await API.sendRequest('get', customerOrderById(orderId));
        const { data } = response;
        this.setState({
          customerOrder: data,
        });
      }
    } catch (e) {
      // error handling
    }
  };

  fetchDeliveryRouteOrder = async (orderId) => {
    try {
      if (orderId) {
        const response = await API.sendRequest('get', deliveryRouteOrdersPath({ orderId }, 0));
        const {
          data: { _embedded: orderData },
        } = response;
        const [deliveryRouteOrder] = orderData['delivery-route-order'];
        this.setState({
          deliveryRouteOrder,
        });
      }
    } catch (e) {
      // error handling
    }
  };

  fetchCustomerTickets = async (customerId) => {
    try {
      const response = await API.sendRequest('get', findTicketsbyCustomerId(customerId));
      const { data: { _embedded: { tickets } } = {} } = response;
      this.setState({
        customerTickets: tickets,
      });
    } catch (e) {
      // error handling
    }
  };

  fetchCallHistory = async () => {
    const { ticketId } = this.state;
    try {
      const response = await API.sendRequest('get', ticketCallHistoryPath(ticketId));
      const {
        data: { _embedded },
      } = response;
      this.setState({
        callDetails: _embedded['call-history'],
      });
    } catch (e) {
      // error handling
    }
  };

  fetchEmailHistory = async () => {
    const { ticketId } = this.state;
    try {
      // call email history here
      const response = await API.sendRequest('get', emailsByTicketId(ticketId));
      const {
        data: { _embedded },
      } = response;
      this.setState({
        emails: _embedded['ticket-emails'],
      });
    } catch (e) {
      // error handling
    }
  };

  fetchCustomerAddresses = async (customerId) => {
    try {
      const response = await API.sendRequest('get', customerAddressesPath(customerId));
      const { data = [] } = response;
      this.setState({
        customerAddresses: data,
      });
    } catch (e) {
      // error handling
    }
  };

  fetchCustomerOldOrders = async (customerId) => {
    try {
      const queryString = `customerId=${customerId}&size=5`;
      const response = await API.sendRequest('get', customerOrderByFiltersPath(queryString));
      const {
        data: { _embedded = [] },
      } = response;
      this.setState({
        customerOrders: _embedded['customer-orders'],
      });
    } catch (e) {
      // error handling
    }
  };

  fetchCustomerTransactions = async (customerId) => {
    // getTransactionHistory
    try {
      const response = await API.sendRequest('get', getTransactionHistory(customerId));
      const {
        data: { data = [] },
      } = response;
      this.setState({
        customerTransactions: data,
      });
    } catch (e) {
      // error handling
    }
  };

  fetchTicket = async () => {
    const { ticketId } = this.state;
    const response = await API.sendRequest('get', findTicketById(ticketId));
    const { data: ticket } = response;
    const { customerId, customerOrderId } = ticket;
    this.fetchCustomerProfile(customerId);
    this.fetchDeliveryRouteOrder(customerOrderId);
    this.fetchCustomerOrder(customerOrderId);
    this.fetchCustomerTickets(customerId);
    this.fetchCallHistory();
    this.fetchEmailHistory();
    this.fetchTicketHistory(ticket);
    this.fetchTicketNotes();
    this.fetchCustomerAddresses(customerId);
    this.fetchCustomerOldOrders(customerId);
    this.fetchCustomerTransactions(customerId);
    this.setState({ ticket });
  };

  handleChange = (event, value) => {
    this.setState({
      tab: value,
    });
  };

  handleCOLItemChange = (items) => {
    const { customerOrder } = this.state;
    this.setState({
      customerOrder: {
        ...customerOrder,
        customerOrderLineItems: Object.values(items),
      },
    });
  };

  handleCOLItemRemove = (i) => {
    const { items } = this.state;
    const newItems = items.filter(item => item.productItemId !== i.productItemId);
    this.setState({
      items: newItems,
    });
  };

  handleNote = async (note) => {
    const { ticketId } = this.state;
    const data = {
      ticketId,
      comment: note,
    };
    await API.sendRequest('post', postCommentPath, data);
    this.fetchTicketNotes();
  };

  handleOrderClick = (order) => {
    this.setState({
      isLineItemVisible: true,
    });
  };

  handleEmailChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSendEmail = () => {
    const { emailSubject, emailContent } = this.state;
    // email_body=Alliwell&email_id=<ReceiverEmail>&subject=AAAAA
    // await API.sendRequest('post', sendEmail, data);
  };

  renderEmptyData = data => <h2 style={styles.noData}>No {data} data found.</h2>;

  render() {
    const {
      tab = 0,
      ticket = {},
      callDetails,
      comments = [],
      deliveryRouteOrder = {},
      customerProfile = {},
      customerTickets = [],
      ticketId,
      auditInfoes = {},
      customerAddresses = [],
      customerOrders = [],
      customerTransactions = [],
      customerOrder = {},
      isLineItemVisible,
      emails,
      emailSubject,
      emailContent,
      agents,
    } = this.state;
    console.log('ticket--->', ticket);
    console.log('call history--->', callDetails);
    console.log('email history--->', emails);
    console.log('comments--->', comments);
    console.log('delivery order--->', deliveryRouteOrder);
    console.log('customer order-->', customerOrder);
    console.log('profile--->', customerProfile);
    console.log('tickets--->', customerTickets);
    console.log('customer Address--->', customerAddresses);
    console.log('customer orders--->', customerOrders);
    console.log('customer txn--->', customerTransactions);
    const { customerOrderLineItems = [] } = customerOrder;
    return (
      <div>
        <style>
          {`
          th {
            font-weight:600 !important;
          }
          .MuiTableCell-root-202{
            border-bottom:0;
          }
          .MuiTab-root-219{
            min-width:72px;
          }
          .MuiTab-root-238{
            min-width:72px;
          }
          .MuiTableCell-root-221{
            border-bottom:0;
          }
          .email:focus{
            outline:none;
          }
          `}
        </style>
        <NavBar />
        <AgentStatus />
        <div style={{ width: '98%', margin: '10px auto' }}>
          <Grid container>
            <Grid item xs={12}>
              <Paper elevation={1} square style={styles.paper}>
                <Grid container>
                  <Grid item md={6}>
                    <b>
                      Ticket #{ticketId.padStart(4, '0')} {ticket.title} -{ticket.ticketType}
                    </b>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Paper elevation={1} square style={{ ...styles.paper, padding: 0 }}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={tab}
                    onChange={this.handleChange}
                    scrollButtons="auto"
                    variant="scrollable"
                    style={{ background: 'navajowhite' }}
                  >
                    <Tab label="Email" style={styles.tabStyle} />
                    <Tab label="Call" style={styles.tabStyle} />
                    <Tab label="History" style={styles.tabStyle} />
                    <Tab label="Info" style={styles.tabStyle} />
                    <Tab label="Notes" style={styles.tabStyle} />
                    <Tab label="Old Tickets" style={styles.tabStyle} />
                    <Tab label="Disposition" style={styles.tabStyle} />
                    {/* <Tab label="Last Orders" style={styles.tabStyle} /> */}
                    {/* <Tab label="Addresses" style={styles.tabStyle} /> */}
                    {/* <Tab label="Transactions" style={styles.tabStyle} /> */}
                  </Tabs>
                </AppBar>
                <div style={{ padding: '10px 20px', height: 300, overflow: 'scroll' }}>
                  {tab === 0 && (
                    <Grid>
                      <Grid item xs={12} style={{ height: 200, overflow: 'auto' }}>
                        {emails.length > 0
                          ? emails.map(emailItem => <EmailHistory email={emailItem} />)
                          : this.renderEmptyData('Emails')}
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Grid container>
                          <Grid
                            item
                            style={{
                              flex: 1,
                              marginRight: 10,
                              overflow: 'hidden',
                              border: '1px solid #999',
                            }}
                          >
                            <textarea
                              className="email"
                              style={{
                                width: '100%',
                                height: 20,
                                resize: 'none',
                                border: '0px solid #999',
                                fontSize: 15,
                                fontWeight: '600',
                              }}
                              placeholder="Subject"
                              value={emailSubject}
                              onChange={this.handleEmailChange}
                              name="emailSubject"
                            />
                            <textarea
                              style={{
                                width: '100%',
                                height: 40,
                                resize: 'none',
                                border: '0px solid #999',
                              }}
                              className="email"
                              value={emailContent}
                              placeholder="Write message here"
                              onChange={this.handleEmailChange}
                              name="emailContent"
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ height: '96%' }}
                              onClick={emailContent ? this.handleSendEmail : null}
                            >
                              SEND
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {tab === 1 && (
                    <div>
                      {callDetails.length > 0
                        ? callDetails.map(call => <CallTicket callDetail={call} />)
                        : this.renderEmptyData('Call Recordings')}
                    </div>
                  )}
                  {tab === 2 && <TicketHistory data={auditInfoes} />}
                  {tab === 3 && <SelectedItemDetail item={ticket} />}
                  {tab === 4 && <TicketNotes notes={comments} onComment={this.handleNote} />}
                  {tab === 5 && <TicketList data={customerTickets} fullTable />}
                  {tab === 6 && (
                    <Disposition
                      users={this.props.user}
                      agents={agents}
                      ticketId={ticketId}
                      ticket={ticket}
                      ticketDispositionTypes={this.props.ticketDispositionTypes}
                      onSubmit={this.onSubmitDisposition}
                    />
                  )}
                  {tab === 7 && (
                    <TableList
                      data={customerOrders}
                      columns={orderColumnData}
                      keys={['id', 'deliveryDate', 'totalPrice', 'priority']}
                    />
                  )}
                  {tab === 8 && <AddressList addresses={customerAddresses} fullTable />}
                  {tab === 9 && <TransactionList data={customerTransactions} />}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingLeft: 10 }}>
              <CustomerDetail data={customerProfile} />
            </Grid>
          </Grid>
          {!isEmpty(deliveryRouteOrder) && (
            <Grid container spacing={16} style={{ marginTop: 10 }}>
              <Grid item xs md={6}>
                <OrderCard
                  order={deliveryRouteOrder}
                  selected
                  onOrderClick={this.handleOrderClick}
                />
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={isLineItemVisible}
                  onClose={this.handleClose}
                >
                  <div style={styles.modal}>
                    <h3>Cusomer Order Items</h3>
                    {customerOrderLineItems.length > 0 && (
                      <CustomerOrderItemTable
                        items={customerOrderLineItems}
                        removeItem={this.handleCOLItemRemove}
                        onChange={this.handleCOLItemChange}
                      />
                    )}
                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={this.onClose}
                        style={{ marginRight: 10 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.onSaveCustomerOrder}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Modal>
              </Grid>
              <Grid item xs md={6}>
                <MessageBoard order={deliveryRouteOrder} />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

TicketDetail.propTypes = {
  match: PropTypes.instanceOf(Object),
  ticketDispositionTypes: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status, User } = state;

  return {
    user: User,
    ticketDispositionTypes: Status.ticketDispositionTypes,
  };
};

export default connect(
  mapStateToProps,
  null,
)(TicketDetail);
