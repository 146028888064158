import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import apiCall, { addressListByFilters } from '../../../api/NetworkHandler';
import AddressList from './AddressList';
import AddressSearch from './AddressSearch';
import Constants from '../../../utils/Constant';
import SearchBar from '../../Commons/containers/SearchBar';
import { ROWS_PER_PAGE } from '../../../constants';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';

class CustomerAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerAddresses: [],
      totalAddresses: 0,
      filters: {},
      currentPage: 0,
      rowsPerPage: 25,
      isOneCustomer: false,
    };
  }

  componentDidMount= () => {
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.onSearch(getFilterParams);
    }

  componentWillMount() {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { customerId } = params;
    if (customerId) {
      this.setState({
        filters: {
          customerId,
        },
        isOneCustomer: true,
      });
    }
  }

  onSearch = (page = 0, size) => {
    const { filters, rowsPerPage } = this.state;
    this.setState({
      currentPage: page,
      customerAddresses: [],
    });
    if (filters && !isEmpty(filters)) {
      this.getAddressesByFilter(page);
    } else {
      this.getAllAddresses(page, rowsPerPage);
    }
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    this.setState({ rowsPerPage }, () => { this.onSearch() });
  };

  getAddressesByFilter = async (page = 0) => {
    const { filters } = this.state;
    const url = addressListByFilters(filters, page);
    try {
      const response = await apiCall.sendRequest('get', url);
      const {
        data: { _embedded: embedded, page: newPage },
      } = response;
      const addresses = embedded['customer-address'];
      this.setState({
        customerAddresses: addresses,
        totalAddresses: newPage.totalElements,
      });
    } catch (e) {
      this.getAllAddresses();
    }
  };

  getAllAddresses = (page = 0, size) => {
    const allAddressURL = `api/v1/customer-address?page=${page}&size=${size}`;
    apiCall.sendRequest('get', allAddressURL).then((response) => {
      const { data } = response;
      const { _embedded, page: newPage = {} } = data;
      if (_embedded) {
        const customerAddresses = _embedded['customer-address'];
        this.setState({
          customerAddresses,
          totalAddresses: newPage.totalElements,
          rowsPerPage: newPage.size,
        });
      }
    });
  };

  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  handleNewAddress = () => {
    const {
      filters: { customerId },
    } = this.state;
    const { history = {} } = this.props;
    history.push(`/new-customer-address/${customerId}`);
  };

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      case 'create_new':
        this.handleNewAddress();
        break;
      default:
        break;
    }
  };

  render() {
    const {
      customerAddresses, totalAddresses, filters, rowsPerPage, currentPage, isOneCustomer,
    } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title={isOneCustomer ? Constants.CUSTOMER_ADDRESSES : Constants.ADDRESSES}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <AddressSearch
            title={isOneCustomer ? Constants.CUSTOMER_ADDRESSES : Constants.ADDRESSES}
            onSearch={this.onSearch}
            onClick={this.handleNewAddress}
          />
        </SearchBar>
        <AddressList
          page={currentPage}
          addresses={customerAddresses}
          reloadAddresses={this.onSearch}
          onPageChange={this.onSearch}
          totalElements={totalAddresses}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}

        />
      </div>
    );
  }
}

CustomerAddress.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default withRouter(CustomerAddress);
