export default {
  orderInfoCard: {
    borderBottom: '1px solid #ddd',
    padding: '16px',
    // display: 'flex',
  },
  hover: {
    background: '#fafafa',
  },
  ongoing: {
    background: 'lavenderblush',
  },
  highlight: {
    background: "#ECEEF0"
  },
  orderIdbox: {
    width: '100px',
  },
  deliveryDate: {
    width: '100px',
  },
  orderId: {
    background: 'beige',
    fontSize: '13px',
    display: 'inline-block',
    border: '1px solid #ccc',
    padding: '2px 5px',
    margin: '0',
  },
  CRITICAL: {
    background: 'red',
    color: 'white',
    borderColor: 'red',
  },
  NEW: {
    background: 'green',
    color: 'white',
    borderColor: 'green',
  },
  priority: {
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
    margin: '0 0 2px',
    fontSize: '10px',
  },
  orderStatus: {
    marginTop: '5px',
    fontSize: '13px',
  },

  orderItems: {
    minWidth: 'calc(100% - 340px)',
    padding: '0 6px',
    width: '100%',
    marginTop: "10px"
  },
  cartItem: {
    display: 'flex',
    margin: '5px 0',
    fontSize: '12px',
  },
  light: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 5px',
  },
  items: {
    category: { width: "10%", boxSizing: 'border-box', margin: '0 2px' },
    productName: { width: '25%', boxSizing: 'border-box', margin: '0 2px' },
    status: { width: '10%', boxSizing: 'border-box', margin: '0 2px' },
    timeUpdated: { width: '10%', boxSizing: 'border-box', margin: '0 2px' },
    quantity: { width: '7%', boxSizing: 'border-box', margin: '0 2px' },
    unitPrice: { width: '7%', boxSizing: 'border-box', margin: '0 2px' },
    refund: { width: '15%', boxSizing: 'border-box', margin: '0 2px', display: 'flex', justifyContent: 'center' },
    productItemType: { width: "7%", boxSizing: 'border-box', margin: '0 2px' },
    complaintReason: { width: "9%", boxSizing: 'border-box', margin: '0 2px' },
  },
  newItems: {
    category: { width: "10%", boxSizing: 'border-box', margin: '0 2px' },
    productName: { width: '25%', boxSizing: 'border-box', margin: '0 2px' },
    status: { width: '10%', boxSizing: 'border-box', margin: '0 2px' },
    quantity: { width: '10%', boxSizing: 'border-box', margin: '0 2px' },
    unitPrice: { width: '10%', boxSizing: 'border-box', margin: '0 2px' },
    refund: { width: '15%', boxSizing: 'border-box', margin: '0 2px', display: 'flex', justifyContent: 'center' },
    productItemType: { width: "10%", boxSizing: 'border-box', margin: '0 2px' },
    complaintReason: { width: "10%", boxSizing: 'border-box', margin: '0 2px' },
  },
  orderActions: {
    width: '128px',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    width: '128px',
    height: 'fit-content',
  },
  totalPriceMain: {
    display: 'flex',
    fontWeight: '500',
    margin: '2px 0',
    fontSize: '16px',
  },
  totalPriceItems: {
    title: { width: '46%', boxSizing: 'border-box', margin: '0 2px' },
    amount: { width: '10%', boxSizing: 'border-box', margin: '0 2px', color: 'green' },
  },
  actionButton: {
    border: "none", 
    borderRadius: "5px", 
    padding: "0 5px", 
    cursor: "pointer" ,
    textDecoration: "underline",
    color: "blue",
    background: "transparent",
  }
};
