import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import InputDir from '../InputDir';
import ItemText from '../ItemText';
import styles from './styles';
import moment from 'moment';
import utils from '../../../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;

// const CS_STATUS = {
//   ACTIVE: 'Active',
//   INACTIVE: 'In-active',
// };

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtnDisabled: true,
    };
  }

  onChangeHandler = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    const { editableCustomerInfo, onProfileEdit } = this.props;
    if (
      // name === 'ringBell' ||
      name === 'vegetarian' ||
      name === 'married' ||
      name === 'dndEnabled' ||
      name === 'dndConsent' ||
      name === 'critical'
    ) {
      value = !editableCustomerInfo[name];
    }
    if (name === 'dndConsent' && editableCustomerInfo[name] === null) value = false;
    this.setState({ updateBtnDisabled: false });
    onProfileEdit({ [name]: value });
  };

  getLineItemsConfig = () => [
    {
      label: 'Customer Name',
      type: 'text',
      name: 'name',
    },
    {
      label: 'Email',
      type: 'text',
      name: 'email',
    },
    {
      label: 'Gender',
      type: 'select',
      name: 'gender',
      options: this.formatConsts(this.props.genderTypes),
    },
    {
      label: 'Date of Birth',
      type: 'date',
      name: 'dob',
    },
    {
      label: 'Number of Family Members',
      type: 'number',
      name: 'noOfFamilyMembers',
    },
    {
      label: 'Registration Date',
      type: 'text',
      name: 'timeCreated',
      disabled: true,
    },
    {
      name: 'vegetarian',
      label: 'Vegetarian',
      type: 'checkbox',
    },
    {
      name: 'married',
      label: 'Married',
      type: 'checkbox',
    },
    // {
    //   name: 'dndEnabled',
    //   label: 'Stop App Notifications',
    //   type: 'checkbox',
    // },
    {
      name: 'dndConsent',
      label: 'DND Consent',
      type: 'checkbox',
      disabled: canView(['CSR_ADMIN', 'CC_MANAGER']) ? false : true,
    },
    {
      name: 'critical',
      label: 'Critical',
      type: 'checkbox',
    },
    // {
    //   name: 'ringBell',
    //   label: 'Ring Bell',
    //   type: 'switch',
    // },
    {
      label: 'Profile Status',
      type: 'select',
      name: 'profileStatus',
      options: this.formatConsts(this.props.customerProfileStatuses),
      disabled: !canView(["CSR_ADMIN"])
    },
  ];

  formatConsts = (obj) => {
    const arr = Object.keys(obj);
    return arr.map(el => ({
      text: obj[el],
      value: el,
    }));
  };

  updateProfileChange = () => {
    const { editableCustomerInfo } = this.props;
    this.props.updateProfileChange(editableCustomerInfo);
    this.setState({
      updateBtnDisabled: true,
    });
  };

  render() {
    const { editableCustomerInfo } = this.props;
    const { referredBy } = editableCustomerInfo;
    const { updateBtnDisabled } = this.state;
    return (
      <div>
        <Typography style={styles.title}>EDIT CUSTOMER DETAILS</Typography>

        <div style={styles.editContent}>
          {this.getLineItemsConfig().map(el => (
            <InputDir
              {...el}
              onChange={this.onChangeHandler}
              style={styles.inputDir}
              // value={editableCustomerInfo[el.name]}
              value={el.name === 'timeCreated' ? moment(editableCustomerInfo.timeCreated).format('DD-MM-YYYY') : editableCustomerInfo[el.name]
                || (el.name === 'dndConsent' && editableCustomerInfo[el.name] === null ? true : editableCustomerInfo[el.name])}
              inputStyle={styles.inputStyle}
            />
          ))}
          {referredBy && <ItemText name="Referred By" value={referredBy} style={styles.refer} />}
        </div>

        <div style={styles.action}>
          {/* <Button
            style={styles.btn}
            onClick={cancelDTAction}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button> */}
          <Button
            style={styles.btn}
            disabled={updateBtnDisabled}
            onClick={this.updateProfileChange}
            color="primary"
            variant="contained"
            size="small"
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

EditProfile.propTypes = {
  editableCustomerInfo: PropTypes.instanceOf(Object),
  onProfileEdit: PropTypes.func,
  genderTypes: PropTypes.instanceOf(Object),
  updateProfileChange: PropTypes.func,
  cancelDTAction: PropTypes.func,
};

export default EditProfile;
