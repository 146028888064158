import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import apiCall, { postProductToProductWidget } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import RegionSelector from '../../../components/Region/Selector';
import ProductWidgetSelector from '../../../components/ProductWidget/Selector';
import ProductItemSelector from '../../../components/ProductItem/Selector';
import moment from 'moment';
import { debounce } from 'lodash';
import ProgressBar from '../../../components/States/ProgressBar';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  }
}

class WidgetProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
      selectedRegion: null,
      selectedProductWidget: null,
      postDeal: {
        productItem: null,
        sortId: '',
        active: true,
      },
      patchDeal: {},
      errorMessage: '',
      invalidSortId: false,
      invalidProductItem: false,
      invalidProductWidget: false,
      invalidRegion: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    const { deal } = this.props;
    if (deal) {
      this.setState({
        postDeal: deal,
      })
    }
  }

  onNewProductSelect = (selectedProduct) => {
    this.setState(prevState => {
      const { postDeal } = prevState;

      return {
        selectedProduct,
        postDeal: {
          ...postDeal,
          productItem: selectedProduct ? selectedProduct.value : null,
        }
      }
    });
  }

  onProductWidgetChange = (selectedProductWidget) => {
    this.setState({ selectedProductWidget: selectedProductWidget });
  }

  onRegionChange = (selectedRegion) => {
    this.setState({
        selectedRegion: null,
        selectedProduct: null,
    }, () => { this.setState({ selectedRegion }) })
  }


  onChange = (event) => {
    const { name, value } = event.target;

    if (name === "sortId" && value < 0) {
      return;
    }
    this.setState(prevState => {
      const { postDeal} = prevState;

      return {
        postDeal: {
          ...postDeal,
          [name]: value,
        }
      }
    })
  }

  getFormatedDateTime = (dateTime) => {
    if(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  validateForm = () => {
    let isFormValid = true;
    const { postDeal, selectedProductWidget, selectedRegion } = this.state;
    const { productItem, sortId } = postDeal;
    const { deal } = this.props;

    if ((typeof sortId == 'string' && !sortId) || typeof sortId == 'undefined') {
      isFormValid = false;
      this.setState({ invalidSortId: true });
    } else {
      this.setState({ invalidSortId: false });
    }

    if (deal) return isFormValid;

    if (!productItem) {
      isFormValid = false;
      this.setState({ invalidProductItem: true });
    } else {
      this.setState({ invalidProductItem: false });
    }

    if (!selectedProductWidget) {
      isFormValid = false;
      this.setState({ invalidProductWidget: true });
    } else {
      this.setState({ invalidProductWidget: false });
    }

    if(!selectedRegion) {
      isFormValid = false;
      this.setState({ invalidRegion: true });
    } else {
      this.setState({ invalidRegion: false });
    }

    return isFormValid;
  }

  onSave = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({ isLoading: true });
    const { postDeal, selectedProductWidget } = this.state;
    const { onClose, onRefresh, productWidget } = this.props;
    const widgetId = selectedProductWidget ? selectedProductWidget.id : productWidget.value;
    let postData = {
      sortId: postDeal.sortId,
      active: postDeal.active,   
    };

    if('productItem' in postDeal) {
      delete Object.assign(postData, {['id']: postDeal['productItem'] })['productItem'];
    } else  {
      postData = {
        ...postData,
        id: postDeal.id
      }
    }

    try {
      await apiCall.sendRequest("post", postProductToProductWidget(widgetId), postData);
      this.setState({ isLoading: false });
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message, isLoading: false });
    }
  }, 300);

  render() {
    const { classes, onClose } = this.props;
    const { 
      postDeal,
      selectedProduct,
      errorMessage,
      selectedRegion,
      selectedProductWidget,
      invalidSortId,
      invalidProductItem,
      invalidProductWidget,
      invalidRegion,
      isLoading,
    } = this.state;
    return (
      <Grid className={classes.container} fluid>
        <ProgressBar isLoading={isLoading} />
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{postDeal.id ? "Edit Product" : "Add New Product"}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary" variant="outlined">Cancel</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" onClick={this.onSave}>{postDeal.id ? "Save" : "Add"}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> * Required Fields </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            <SectionMessage appearance="error">{errorMessage}</SectionMessage>
          </ColMod>
        </Row>}
        <Row md={12}>
        {!postDeal.id && <ColMod md={6}>
            <Typography variant="caption" color={invalidProductWidget ? 'error' : 'default'}> Product Widget * </Typography>
            <ProductWidgetSelector
              onSelect={this.onProductWidgetChange}
              selected={selectedProductWidget}
            />
          </ColMod>}
          {!postDeal.id && <ColMod md={6}>
            <Typography variant="caption" color={invalidRegion ? 'error' : 'default'}> Region * </Typography>
            <RegionSelector
              onSelect={this.onRegionChange}
              selected={selectedRegion}
            />
          </ColMod>}
          {!postDeal.id && <ColMod md={12}>
            <Typography variant="caption" color={invalidProductItem ? 'error' : 'default'}> Product Item * </Typography>
            {!selectedRegion && <TextField
              value="Please select region first"
              fullWidth
              disabled
            />}
            {selectedRegion && <ProductItemSelector
              selected={selectedProduct}
              onSelect={this.onNewProductSelect}
              regionId={selectedRegion ? selectedRegion.value : null}
            />}
          </ColMod>}
          <ColMod md={6}>
            <TextField
              type="number"
              label="Sort Id"
              name="sortId"
              value={postDeal.sortId}
              onChange={this.onChange}
              error={invalidSortId}
              required
              fullWidth
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> Status </Typography>
            <RadioGroup onChange={this.onChange} name="active" value={(postDeal.active + '') || 'true'} row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(WidgetProductForm);
