import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress'

export default function ProgressBar(props) {
  const { isLoading, color = "secondary" } = props;
  
  return (
    isLoading ? <LinearProgress color={color} /> : <div style={{ minHeight: '5px' }}></div>
  )
}

ProgressBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

ProgressBar.defaultProps = {
  isLoading: false,
}
