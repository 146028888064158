import React, { Component } from 'react';
import NavBar from '../../../components/NavBar';
import moment from 'moment';
import { saveAs } from 'file-saver/FileSaver';
import ProgressBar from '../../../components/States/ProgressBar';
import VendorProductItemSearch from '../components/VendorProductItemSearch';
import StockForm from '../components/StockForm';
import {
  Drawer,
  Typography,
  Button,
  Modal
} from '@material-ui/core';
import WarehouseSelector from '../../../components/Warehouse/Selector';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import LedgerStockList from '../components/LedgerStockList';
import { sortBy } from 'lodash';
import apiCall, { searchVendorStockLedger, downloadVendorStockInfo, uploadVendorStockInfo } from '../../../api/NetworkHandler';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';



var versionDict = {
  "getStockRequestId": 0,
  "postStockRequestId": 0,
};
class VendorStockLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockList: [],
      openLedgerForm: false,
      selectedWarehouse: null,
      isWarehouseSelected: false,
      searchTerm: '',
      editingStock: null,
      rowsPerPage: 10,
      page: 0,
      count: 0,
      isNoData: false,
      loading: false,
      disableButton: false,
    }
  }

  fetchStockData = async () => {
    const { selectedWarehouse: { id }, searchTerm, page, rowsPerPage } = this.state;
    this.toggleProgressBar(true, true);

    try {
      versionDict.getStockRequestId += 1;
      let prevRequestId = versionDict.getStockRequestId;
      const response = await apiCall.sendRequest('get', searchVendorStockLedger(id, searchTerm, page, rowsPerPage));
      const { data: { data: { content, totalElements } } } = response;
      const sortedData = sortBy(content, "vendorProductItemName");
      const isNoData = !!(sortedData && sortedData.length);
      if (versionDict.getStockRequestId == prevRequestId) {
        this.setState({
          stockList: sortedData,
          count: totalElements,
          isNoData: !isNoData
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        stockList: [],
        count: 0,
        isNoData: true
      })
    }
    this.toggleProgressBar(false, false)
  };

  closeLedgerForm = () => {
    this.setState({ openLedgerForm: false })
  }

  selectWarehouse = () => {
    this.setState(prevState => {
      const { selectedWarehouse } = prevState;

      return {
        isWarehouseSelected: selectedWarehouse ? true : false,
      }
    }, () => {
      const { selectedWarehouse } = this.state;
      if (selectedWarehouse) {
        this.fetchStockData();
      }
    })
  }

  onchangeWarehouse = (selectedWarehouse) => {
    this.setState({ selectedWarehouse });
  }

  onSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchTerm: value,
      page: 0,
    });
  }

  clearSearch = () => {
    this.setState({
      searchTerm: '',
      page: 0,
    }, () => { this.fetchStockData() });
  }

  onUpdateStock = (stock) => {
    this.setState({
      openLedgerForm: true,
      editingStock: stock,
    })
  }

  toggleProgressBar = (loadingState, buttonState) => {
    this.setState({
      loading: loadingState,
      disableButton: buttonState,
    });
  }

  handleDownloadStockInfo = async () => {
    const { selectedWarehouse } = this.state;
    const { id = null } = selectedWarehouse;
    let filename = `Vendor_Stock_Ledger-${id}-${moment(new Date()).format("DD-MM-YYYY")}.csv`;
    this.toggleProgressBar(true, true);
    try {
      const response = await apiCall.sendRequest('get', downloadVendorStockInfo(id), null, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
      this.toggleProgressBar(false, false);
    } catch (err) {
      console.log(err);
      this.toggleProgressBar(false, false);
    }
  }

  uploadStockInfo = async (selectedFile) => {
    const { selectedWarehouse } = this.state;
    const { id = null } = selectedWarehouse;
    this.toggleProgressBar(true, true);

    try {
      versionDict.postStockRequestId += 1;
      let prevRequestId = versionDict.postStockRequestId;
      const formData = new FormData();
      formData.append('file', selectedFile);
      await apiCall.sendRequest('post', uploadVendorStockInfo(id), formData);
      if (versionDict.postStockRequestId == prevRequestId) {
        this.toggleProgressBar(false, false);
        this.fetchStockData();
      }
    } catch (e) {
      // error handling
      this.toggleProgressBar(false, false);
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchStockData() });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.fetchStockData() });
  }

  render() {
    const {
      openLedgerForm,
      stockList,
      selectedWarehouse,
      isWarehouseSelected,
      searchTerm,
      editingStock,
      rowsPerPage,
      page,
      count,
      isNoData,
      loading,
      disableButton,
    } = this.state;
    return (
      <div>
        <NavBar />
        {isWarehouseSelected &&
          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
            <React.Fragment>
              <VendorProductItemSearch
                selectedWarehouse={selectedWarehouse}
                searchTerm={searchTerm}
                onChange={this.onSearchChange}
                clearSearch={this.clearSearch}
                handleDownload={this.handleDownloadStockInfo}
                handleUpload={this.uploadStockInfo}
                loading={loading}
                disableButton={disableButton}
                onClickSearch={this.fetchStockData}
              />
              <LedgerStockList
                stockList={stockList}
                updateStock={this.onUpdateStock}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
                searchTerm={searchTerm}
                isNoData={isNoData}
              />
              <Drawer anchor="right" open={openLedgerForm} onClose={this.closeLedgerForm}>
                <StockForm
                  onClose={this.closeLedgerForm}
                  onRefresh={this.fetchStockData}
                  stock={editingStock}
                  warehouseName={selectedWarehouse.name || ''}
                />
              </Drawer>
            </React.Fragment>
          </Spin>
        }

        <Modal open={!isWarehouseSelected} style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Grid fluid style={{
            background: 'white',
            width: window.innerWidth < 575 ? window.innerWidth * 0.9 : window.innerWidth * 0.3,
            margin: '2em'
          }}>
            <Row xs={12}>
              <ColMod xs={12}>
                <Typography variant="title">
                  Select Warehouse
                </Typography>
              </ColMod>
              <ColMod xs={12}>
                <WarehouseSelector
                  onSelect={this.onchangeWarehouse}
                  selected={selectedWarehouse}
                />
              </ColMod>
            </Row>
            <Row xs={12} end="xs">
              <ColMod xs={5}>
                <Button color="secondary" variant="contained" onClick={this.selectWarehouse}>
                  Select
                </Button>
              </ColMod>
            </Row>
          </Grid>
        </Modal>
      </div>
    )
  }
}

export default VendorStockLedger;