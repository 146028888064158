import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall, {
  searchAllDeliveryBoys, searchAllCities,
  searchUSers, findHubPath,
  searchAllLocalities,getFetchAllBuildings,
  searchLocalityByName,
} from "../api/NetworkHandler";
import debounce from 'lodash/debounce';

var versionDict = {
  "searchUser": 0,
  "searchDeliveryBoys": 0,
  "searchHubs": 0,
  "searchBuildings": 0,
  "searchLocalities": 0,
  "searchCity": 0,

};

class UserSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
      userType: '',
      userValue: '',
    };
  }

  componentWillMount() {
    const { userType, userValue } = this.props;
    this.setState({
      userType,
      userValue,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.userType !== this.state.userType) {
        this.setState({
          userType: nextProps.userType,
          userValue: nextProps.userValue,
          searchText: nextProps.userValue,
          suggestions: [],
        });
      }
    }
  }

  setEmpty = () => {
    this.props.selectUser({});
  }

  searchUsers = debounce(async () => {
    try {
      versionDict.searchUser += 1;
      let PrevUserVersion = versionDict.searchUser;
      const response = await apiCall.sendRequest('get', searchUSers(this.state.searchText));
      const { data: { _embedded: { users = [] } = {} } = {} } = response;
      if (PrevUserVersion == versionDict.searchUser) {
        this.setState({
          suggestions: users,
        });
      }
    }

    catch (error) {
      console.error(error);
    }
  }, 750)

  searchDeliveryBoys = debounce(async () => {
    versionDict.searchDeliveryBoys += 1;
    let prevDeliveryBoy = versionDict.searchDeliveryBoys;
    try {
      const response = await apiCall.sendRequest('get', searchAllDeliveryBoys(this.state.searchText));
      const { data: { _embedded = {} } = {} } = response;
      if (prevDeliveryBoy === versionDict.searchDeliveryBoys && _embedded['delivery-boys']) {
        this.setState({
          suggestions: _embedded['delivery-boys'],
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 750);

  searchHubs = debounce(async () => {
    try {
      versionDict.searchHubs += 1;
      let prevHub = versionDict.searchHubs;
      const response = await apiCall.sendRequest('get', findHubPath(this.state.searchText));
      const { data: { _embedded: { hub = [] } = {} } = {} } = response;
      if (prevHub === versionDict.searchHubs) {
        this.setState({
          suggestions: hub,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 750)

  searchBuildings = debounce(async () => {
    try {
      versionDict.searchBuildings += 1;
      let prevBuildingSearch = versionDict.searchBuildings;
      const { searchText } = this.state;
      const { address = {} } = this.props;
      const { cityName, localityName } = address;
      const response = await apiCall.sendRequest('get', getFetchAllBuildings('',searchText))
      const { data: { _embedded: { buildings = [] } = {} } = {} } = response
      if (prevBuildingSearch === versionDict.searchBuildings) {
        this.setState({
          suggestions: buildings,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 750)

  searchLocalities = debounce(async () => {
    try {
      versionDict.searchLocalities += 1;
      let prevSearchLocality = versionDict.searchLocalities;
      const { searchText } = this.state;
      const { address = {} } = this.props;
      const { cityName = ''} = address; 
      let url = searchAllLocalities(cityName,searchText);
      if(cityName === ''){
        url = searchLocalityByName(searchText);
      }
      const response = await apiCall.sendRequest('get', url);
      const { data: { _embedded: { localities = [] } = {} } = {} } = response
      if (versionDict.searchLocalities === prevSearchLocality) {
        this.setState({
          suggestions: localities,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 750)

  searchCities = debounce(async () => {
    versionDict.searchCity += 1;
    let prevSearchcity = versionDict.searchCity;
    try {
      const response = await apiCall.sendRequest('get', searchAllCities(this.state.searchText))
      const { data: { _embedded: { cities = [] } = {} } = {} } = response;
      if (prevSearchcity === versionDict.searchCity) {
        this.setState({
          suggestions: cities,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 500);

  search = () => {
    switch (this.state.userType) {
      case 'DeliveryBoy': this.searchDeliveryBoys(); break;
      case 'Hub': this.searchHubs(); break;
      case 'Building': this.searchBuildings(); break;
      case 'Locality': this.searchLocalities(); break;
      case 'City': this.searchCities(); break;
      case 'User':
      case 'SegregationBoy':
      case 'Supervisor':
      case 'Picker':
      default: this.searchUsers(); break;
    }
  }

  handleSearch = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      this.search();
    });
  }

  render() {
    const { searchText = '', userType } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.openSearchBox}
        onClose={this.props.handleClose}
        onBackdropClick={this.props.handleClose}
      >
        <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '70%',
          height: '85%',
        }}
        >
          <Button style={{ float: 'right' }} onClick={this.props.handleClose} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <ColMod zerobottom="true" lg={6}>
                    <Typography variant="body2" color="secondary">
                      {`Search ${userType}`}
                    </Typography>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod custompadding="true" lg={6}>
                    <TextField
                      value={searchText}
                      type="text"
                      onChange={event => this.handleSearch(event)}
                      label="Name"
                    />
                  </ColMod>
                  <ColMod zerobottom="true" lg={3}>
                    <Button color="secondary" variant="contained" onClick={this.search}>Search</Button>
                  </ColMod>
                  <ColMod zerobottom="true" lg={3}>
                    <Button color="secondary" onClick={this.setEmpty}>Set Empty</Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowX: 'auto', overflowY: 'auto', height: '450px' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {(this.state.userType !== 'Locality' && this.state.userType !== 'City') && <TableCell>Username</TableCell>}
                            <TableCell>Name</TableCell>
                            {(this.state.userType !== 'Locality' && this.state.userType !== 'City') && <TableCell>Mobile</TableCell>}
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.suggestions.map((suggestion, index) => (
                            <TableRow key={index}>
                              {(this.state.userType !== 'Locality' && this.state.userType !== 'City') && <TableCell>{suggestion.email}</TableCell>}
                              <TableCell>{suggestion.name}</TableCell>
                              {(this.state.userType !== 'Locality' && this.state.userType !== 'City') && <TableCell>{suggestion.mobile}</TableCell>}
                              <TableCell><Button variant="contained" color="secondary" onClick={() => { this.props.selectUser(suggestion, userType); }}>Select</Button></TableCell>
                            </TableRow>))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div>
      </Modal>
    );
  }
}

UserSelection.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
};

export default UserSelection;
