import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { isEmpty } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-picky/dist/picky.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import SmsTemplate from './SmsTemplate';
import apiCall, { sendSmsUrl, notificationsTemplatesPath, getWareHouse, routesByHubIdPath } from '../../../api/NetworkHandler';
import ProductSelection from '../../../components/ProductSelection';
import { styles } from './styles';
import HubSelector from '../../../components/Hub/Selector';
import { isValid } from 'redux-form';

const notifications = [
  {
    label: 'All',
    value: 'allNotification',
  },
  {
    label: 'SNS',
    value: 'snsNotification',
  },
  {
    label: 'SMS',
    value: 'smsNotification',
  },
  {
    label: 'EMAIL',
    value: 'emailNotification',
  }];

class SendSmsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouse: {},
      hub: {},
      route: {},
      warehouseList: [],
      routeList: [],
      message: '',
      deliveryStatus: '',
      selecteAttributes: [],
      templates: {},
      attributes: {},
      selectedTemplate: '',
      showOffer: false,
      showDate: false,
      showTime: false,
      productName: '',
      index: 0,
      selectDate: moment(new Date()).format('YYYY-MM-DD'),
      selectTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      allNotification: false,
      snsNotification: false,
      smsNotification: true,
      emailNotification: false,
      customerIds: '',
      selectedHub: null,
      warehouseText: '',
      hubText: '',
      routeText: '',
      orderStatusText: '',
      deliveryStatusText: '',
      customerIdText: '',
      notificationText: '',
      templateText: '',
      invalidHub: false,
    };
    this.selectOption = this.selectOption.bind(this);
  }

  componentDidMount() {
    this.fetchAllWarehouses();
    this.fetchAllTemplates();
  }

  setSmsTemplates = (data) => {
    this.setState({
      templates: data.templateMessageMap,
      attributes: data.templateAttributes,
    });
  }

  handleRouteChange = (event) => {
    const { value } = event.target;
    const { routeList = [] } = this.state;
    const [route] = routeList.filter(item => item.id === value);
    this.setState({
      route,
    });
  };

  handleHubChange = (hub) => {
    this.setState(
      {
        selectedHub: hub,
        route: {},
      },
      this.fetchAllRoutes,
    );
  };

  clearData = () => {
    this.setState({
      warehouse: {},
      hub: {},
      route: {},
      routeList: [],
      message: '',
      deliveryStatus: '',
      customerIds: '',
      selectedHub: null,
      selectedTemplate: ''
    });
  };

  updateMessage = (event) => {
    const { target: { value } } = event;
    this.setState({
      message: value,
    });
  };

  updateStatus = (event) => {
    const { value } = event.target;
    this.setState({
      deliveryStatus: value,
    });
  }

  handleProductSearchPopup = (value) => {
    this.setState({
      showOffer: value,
    });
  }

  handleProductSuggest = (productSearch) => {
    const { name } = productSearch;
    this.setState({
      productName: name,
      showOffer: false,
    }, () => {
      const { productName } = this.state;
      this.changeSmsContent('#PH_PRODUCT_NAME#', productName);
      this.handleFieldSelection();
    });
  }

  handleDateSelect = (date) => {
    this.changeSmsContent('#PH_DATE#', moment(date).format('ll'));
    this.setState({
      selectDate: date,
      showDate: false,
    });
    this.handleFieldSelection();
  }

  handleTimeSelect = (date) => {
    this.changeSmsContent('#PH_TIME#', date);
    this.setState({
      selectTime: date,
      showTime: false,
    });
    this.handleFieldSelection();
  }


  changeSmsContent = (text, replaceWith) => {
    const { message } = this.state;
    const res = message.replace(text, replaceWith);
    this.setState({
      message: res,
    });
  }

  handleFieldSelection = () => {
    const { selecteAttributes, index } = this.state;
    const currentAttribute = selecteAttributes[index];
    if (index === selecteAttributes.length) {
      return;
    }

    switch (currentAttribute) {
      case '#PH_PRODUCT_NAME#':
        this.setState({
          showOffer: true,
          index: index + 1,
        });
        break;
      case '#PH_DATE#':
        this.setState({
          showDate: true,
          index: index + 1,
        });
        break;
      case '#PH_TIME#':
        this.setState({
          showTime: true,
          index: index + 1,
        });
        break;
      default: break;
    }
  }

  handleTemplate = (event) => {
    const { templates, attributes } = this.state;
    const { target: { value } } = event;
    this.setState({
      selectedTemplate: value,
      message: templates[value],
      selecteAttributes: attributes[value],
      index: 0,
    }, () => {
      if (attributes[value] && attributes[value].length > 0) {
        this.handleFieldSelection();
      }
    });
  }

  selectOption(value) {
    this.setState({ value });
  }

  fetchAllTemplates = async () => {
    const response = await apiCall.sendRequest('get', notificationsTemplatesPath);
    const { data } = response;
    this.setState({
      templates: data.templateMessageMap,
      attributes: data.templateAttributes,
    });
  }

  fetchAllWarehouses = () => {
    apiCall.sendRequest('get', getWareHouse).then((response) => {
      const { data } = response;
      const { _embedded } = data;
      this.setState({
        warehouseList: _embedded.warehouse,
        hub: {},
      });
    });
  };


  fetchAllRoutes = () => {
    const { selectedHub } = this.state;
    const id = selectedHub ? selectedHub.id : null;
    if(id){
      apiCall
        .sendRequest('get', routesByHubIdPath(id))
        .then((response) => {
          const { data } = response;
          const { _embedded } = data;
          this.setState({
            routeList: _embedded['static-routes'],
          });
        });
    }
  };

  handleWarehouseChange = (event) => {
    const { target: { value } } = event;
    const { warehouseList = [] } = this.state;
    const [warehouse] = warehouseList.filter(item => item.id === value);
    this.setState(
      {
        warehouse: {},
        hub: {},
        route: {},
        selectedHub: null
      }, () => {
        this.setState({ warehouse })
      }
    );
  };

  validateForm = () => {
    const {
      warehouse,
      selectedHub,
      route,
      message,
      deliveryStatus,
      selectedTemplate,
      customerIds,
      allNotification,
      snsNotification,
      smsNotification,
      emailNotification,
    } = this.state;
    let isFormValid = true;

    if (!Object.keys(warehouse).length) {
      isFormValid = false;
      this.setState({ warehouseText: '*required' });
    } else {
      this.setState({ warehouseText: '' });
    }

    if (!selectedHub) {
      isFormValid = false;
      this.setState({ hubText: '*required', invalidHub: true });
    } else {
      this.setState({ hubText: '', invalidHub: false });
    }

    if (!deliveryStatus) {
      isFormValid = false;
      this.setState({ deliveryStatusText: '*required' });
    } else {
      this.setState({ deliveryStatusText: '' });
    }

    if (!selectedTemplate) {
      isFormValid = false;
      this.setState({ templateText: '*required' });
    } else {
      this.setState({ templateText: '' });
    }

    // if (!message) {
    //   isFormValid = false;
    //   this.setState({ messageText: '*required' });
    // } else {
    //   this.setState({ messageText: '' });
    // }

    if (!customerIds) {
      isFormValid = false;
      this.setState({ customerIdText: '*required' });
    } else {
      this.setState({ customerIdText: '' });
    }

    if (!allNotification && !snsNotification && !smsNotification && !emailNotification) {
      isFormValid = false;
      this.setState({ notificationText: 'Please select atleast one notification channel' });
    } else {
      this.setState({ notificationText: '' });
    }

    return isFormValid;
  }

  sendMessage = () => {
    if (!this.validateForm()) {
      return;
    }
    const {
      warehouse,
      hub,
      route,
      message,
      deliveryStatus,
      customerIds,
      allNotification,
      snsNotification,
      smsNotification,
      emailNotification,
    } = this.state;

    let formttedData =
    {
      warehouseId: '',
      hubId: '',
      routeId: '',
      message,
      deliveryStatus,
      customerIds,
      allNotification,
      snsNotification,
      smsNotification,
      emailNotification,
    };
    if (warehouse && !isEmpty(warehouse)) {
      formttedData = {
        ...formttedData,
        warehouseId: warehouse.id,
      };
    }
    if (hub && !isEmpty(hub)) {
      formttedData = {
        ...formttedData,
        hubId: hub.id,
      };
    }
    if (route && !isEmpty(route)) {
      formttedData = {
        ...formttedData,
        routeId: route.id,
      };
    }
    this.sendDataToBackend(formttedData);
  }

  sendDataToBackend = (data) => {
    apiCall
      .sendRequest('post', sendSmsUrl, data)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          this.clearData();
        }
      });
  }

  handleChannels = (key, value) => {
    if (key === 'allNotification') {
      const notification = !(value === 'true');
      this.setState({
        allNotification: notification,
        smsNotification: notification,
        snsNotification: notification,
        emailNotification: notification,
      });
    } else {
      this.setState({
        [key]: !(value === 'true'),
      });
    }
  }

  handleCustomerIds = (event) => {
    const { value } = event.target;
    this.setState({
      customerIds: value,
    });
  }

  renderText = (name, fieldName, txtName, selectorName) => (
    <TextField
      margin="dense"
      id="input-with-icon-textfield"
      value={name}
      name={fieldName}
      fullWidth
      onChange={() => {}}
      helperText={txtName}
      InputProps={{
          endAdornment: (
            <InputAdornment position="start" onClick={() => this.openUserSelector({ selectorName })}>
              <Search color="secondary" />
            </InputAdornment>
          ),
        }}
      style={{ marginLeft: 40 }}
    />
  )

  renderField = (id, inputPropName, list, fieldName, errorText, onChangeFunction) =>
    (
      <div style={{ marginLeft: 40, marginTop: 5 }}>
        <MaterialSelect
          value={id}
          onChange={onChangeFunction}
          inputProps={{
            name: inputPropName,
            id: 'select-destination',
          }}
          style={{ minWidth: 300 }}
          fullWidth
        >
          {list.map(listvalue => (
            <MenuItem key={listvalue.id} value={listvalue.id}>
              {listvalue.name}
            </MenuItem>
          ))}
        </MaterialSelect>
        <InputLabel style={{ fontSize: '0.8em' }} htmlFor="select-destination">{fieldName}</InputLabel>
        <small style={{ color: 'red', marginLeft: '5px' }}>{errorText}</small>
      </div>
    )

  renderDeliveryStatuses = () => {
    const { deliveryStatuses } = this.props;
    return (
      <div style={{ marginLeft: 40, marginTop: 5 }}>
        <MaterialSelect
          style={{ minWidth: 300 }}
          value={this.state.deliveryStatus}
          onChange={this.updateStatus}
        >
          {
            Object.keys(deliveryStatuses).map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))
          }
        </MaterialSelect>
        <InputLabel style={{ fontSize: '0.8em', display: 'block', marginTop: 5 }} htmlFor="select-destination">
          Order Status
          <small style={{ color: 'red' , fontSize: '11px', marginLeft: '5px'}}>{this.state.deliveryStatusText}</small>
        </InputLabel>
      </div>
    );
  };

  renderHubView = () => {
    const { warehouse: { id  }, selectedHub, invalidHub, hubText } = this.state;
    return (
      <div style={{ marginLeft: 40, marginTop: 5, minWidth: '300px' }}>
        {id &&
        <div> 
          <HubSelector
            warehouseId={id}
            onSelect={this.handleHubChange}
            selected={selectedHub}
            helperText="Hub"
          />
          <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">Hub</InputLabel>
          <small style={{ color: 'red', marginLeft: '5px' }}>{hubText}</small>
        </div>}
        {!id &&
        <div> 
          <TextField 
            value="select warehouse first"
            error={invalidHub}
            helperText='Hub'
            disabled
          />
        </div>}
      </div>
    )
  };

renderWarehouseView = () => {
  const {
    warehouse: { id: warehouseId } = {},
    warehouseList = [],
  } = this.state;
  return this.renderField(warehouseId, 'warehouseId', warehouseList, 'Warehouse', this.state.warehouseText, this.handleWarehouseChange);
};

renderRouterView = () => {
  const {
    selectedHub = {},
    route: { id: routeId, name: routeName } = {},
    routeList = [],
  } = this.state;
  return (selectedHub && selectedHub.id) && routeList.length ? (
    this.renderField(routeId, 'routeId', routeList, 'Route', this.state.routeText, this.handleRouteChange)) : (
      <div>
        <TextField
          margin="dense"
          id="input-with-icon-textfield"
          value={routeName || ''}
          name="routeName"
          fullWidth
          onChange={() => {}}
          helperText="Route"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" onClick={this.openRouteSelector}>
                <Search color="secondary" />
              </InputAdornment>
            ),
          }}
          style={{ marginLeft: 40 }}
        />
      </div>
  );
};

  renderChannels = () => (
    <div style={styles.checkbox}>
      {
          notifications.map(notification => (
            <div>
              <Checkbox
                checked={this.state[notification.value]}
                onChange={(e) => { this.handleChannels(notification.value, e.target.value); }}
                value={this.state[notification.value]}
              />
              <span>{notification.label}</span>
            </div>
          ))
        }
    </div>)

  render() {
    const {
      message, templates, customerIds,
      selectedTemplate, showOffer, showDate, showTime,
    } = this.state;
    return (
      <div>
        <div style={styles.wrapper}>
          <Typography variant="title" color="inherit">
            Send Notification (SMS/SNS/EMAIL)
          </Typography>
          <div style={styles.container}>
            <div style={styles.topContainer}>
              {this.renderWarehouseView()}
              {this.renderHubView()}
              {this.renderRouterView()}
            </div>
            {/** SECOND ROW */}
            <div style={styles.middleLeftContainer}>
              {this.renderDeliveryStatuses()}
              <SmsTemplate
                data={templates}
                value={selectedTemplate}
                updateStatus={this.handleTemplate}
                updateTemplates={this.setSmsTemplates}
                templateText={this.state.templateText}
              />
              {
                showDate &&
                <div style={{ marginLeft: 40 }}>
                  <TextField
                    id="date"
                    type="date"
                    name="selectDate"
                    onChange={e => this.handleDateSelect(e.target.value)}
                    value={this.state.selectDate ? this.state.selectDate : ''}
                    InputLabelProps={{
                        shrink: true,
                      }}
                  />
                  <FormHelperText>Select Date</FormHelperText>
                </div>
              }
              {
                showTime &&
                <div style={{ marginLeft: 40 }}>
                  <TextField
                    id="date"
                    type="local-datetime"
                    name="selectTime"
                    onChange={e => this.handleTimeSelect(e.target.value)}
                    value={this.state.selectTime ? this.state.selectTime : ''}
                    InputLabelProps={{
                        shrink: true,
                      }}
                  />
                  <FormHelperText>Select Date</FormHelperText>
                </div>
              }
              <div>
                <ProductSelection
                  showOfferPopup={showOffer}
                  handleClose={this.handleProductSearchPopup}
                  selectProduct={this.handleProductSuggest}
                />
              </div>
            </div>
            {/** THIRD ROW */}
            <div style={styles.middleRightContainer}>
              <div style={{ marginTop: 28 }}>
                <FormHelperText>Customer Id's<small style={{ color: 'red', marginLeft: '5px', fontSize: '11px' }}>{this.state.customerIdText}</small></FormHelperText>
                <textarea
                  value={customerIds}
                  style={styles.message}
                  cols={50}
                  maxLength="500"
                  placeholder="Add customer Id's separated by comma"
                  onChange={this.handleCustomerIds}
                />
              </div>
              <div>
                {this.renderChannels()}
                <textarea
                  value={message}
                  style={styles.message}
                  cols={60}
                  maxLength="500"
                  placeholder="Please write a sms about your favorite customers..."
                  onChange={this.updateMessage}
                />
                <div style={{ textAlign: 'right' }}>{`${message.length}/400`}</div>
              </div>
            </div>
            <div>
              <small style={{ color: 'red' }}>{this.state.notificationText}</small>
            </div>
            <div style={{ width: '100%' }}>
              <div style={styles.bottomContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ float: 'right', marginTop: '10px', marginRight: '30px' }}
                  onClick={this.clearData}
                >
                    Clear All
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ float: 'right', marginTop: '10px' }}
                  onClick={this.sendMessage}
                >
                    Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SendSmsComponent.propTypes = {
  deliveryStatuses: PropTypes.object,
};
SendSmsComponent.defaultProps = {
  deliveryStatuses: {},
};

const mapStateToProps = (state) => {
  const { User, Status } = state;
  const { deliveryStatuses, complaintReasons } = Status;
  return {
    User,
    deliveryStatuses,
    complaintReasons,
  };
};


export default connect(
  mapStateToProps,
  null,
)(SendSmsComponent);
