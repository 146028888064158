import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Lozenge from '@atlaskit/lozenge';


const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Referral Name',
  },
  {
    id: "referralCode", numeric: false, disablePadding: true, label: 'Referral Code',
  },
  {
    id: 'validFrom', numeric: false, disablePadding: true, label: 'Start Time',
  },
  {
    id: 'validTill', numeric: false, disablePadding: true, label: 'End Time',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'couponCode', numeric: false, disablePadding: true, label: 'Default Coupon',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  }
]

class ReferralList extends Component {

  getStatusColor = (status) => {
    switch(status) {
      case 'ONGOING': return 'success';
      case 'UPCOMING': return 'inprogress';
      default: return 'removed';
    }
  };

  getCustomComponents = () => {
    const customComponents = {
      validFrom: {},
      validTill: {},
      couponAmount: {},
      status: {},
    };
    const { referrals } = this.props;

    referrals.map((el, index) => {
      customComponents['validFrom'][index] = moment(el.validFrom).format("lll");
      customComponents['validTill'][index] = moment(el.validTill).format("lll");
      customComponents['couponAmount'][index] = el.couponAmount || 'NA';
      customComponents['status'][index] = <Lozenge appearance={this.getStatusColor(el.status)}>{el.status}</Lozenge>;
    })
    return customComponents;
  }

  getActions = (el) => (
    <React.Fragment>
      <Button color="primary" variant="outlined" onClick={() => { this.props.onEdit(el) }}>Edit</Button>
    </React.Fragment>
  )

  render() {
    const {
      referrals,
      rowsPerPage,
      page,
      count,
      isNoData,
      onChangePage,
      onChangeRowsPerPage
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={referrals}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        isNoData={isNoData}
        customComponents={this.getCustomComponents()}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

ReferralList.propTypes = {
  referrals: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}

export default ReferralList;
