import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core/';

class TableHeader extends Component {
    createSortHandler = property => (event) => {
      const { onRequestSort } = this.props;
      onRequestSort(event, property);
    };

    render() {
      const {
        order,
        orderBy,
        columns = [],
        cellStyle = {},
      } = this.props;

      return (
        <TableHead>
          {/* <TableRow>
            {columns.map(column => (
              <TableCell
                style={{ ...cellStyle, ...column.style }}
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.label}
              </TableCell>
              ))}
          </TableRow> */}
          
            {/* // Table Header with Sorting */}
          {<TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                style={{ color: '#f50057', fontSize: '14px' }}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              ), this)}
          </TableRow>}
        </TableHead>
      );
    }
}

TableHeader.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  columns: PropTypes.instanceOf(Array),
  onRequestSort: PropTypes.func,
  cellStyle: PropTypes.instanceOf(Object),
};

export default TableHeader;
