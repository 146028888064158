export const handleSort = (property, dataOrder, dataOrderBy, products) => {
  if (['actions', 'active'].includes(property)) {
    return;
  }
  const orderBy = property;
  let order = 'desc';

  if (dataOrderBy === property && dataOrder === 'desc') {
    order = 'asc';
  }
  let data;
  if (orderBy == 'category') {
    data =
      order === 'desc'
        ? products.sort((a, b) => (b[orderBy].name < a[orderBy].name ? -1 : 1))
        : products.sort((a, b) => (a[orderBy].name < b[orderBy].name ? -1 : 1));
  } else {
    data =
      order === 'desc'
        ? products.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : products.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
  }
  return { data, order, orderBy };
}