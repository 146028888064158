import React, { Component } from 'react';
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core/';
import PropTypes from 'prop-types';

class SelectedItemDetail extends Component {
    renderDetails = (selectedItem) => {
      const keys = Object.keys(selectedItem);
      if (!keys.length) {
        return null;
      }
      return keys.map(item => item !== 'deviceId' && item !== '_links' &&
          (
          <TableRow style={{ height: 30 }}>
            <TableCell style={{ padding: 0, fontWeight: 500 }}>{item.split(/(?=[A-Z])/).map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(' ')}</TableCell>
            <TableCell style={{ padding: 5 }}>:</TableCell>
            <TableCell style={{ padding: 0 }}>{selectedItem[item]}</TableCell>
          </TableRow>
          ));
    }

    render() {
      const { item = {} } = this.props;
      return (
        <div style={{ paddingLeft: '10px' }}>
          <Table>
            <TableBody>
              {this.renderDetails(item)}
            </TableBody>
          </Table>
        </div>
      );
    }
}

SelectedItemDetail.propTypes = {
  item: PropTypes.instanceOf(Object),
};

export default SelectedItemDetail;
