import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchBrandsByName } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchBrandsByName(searchTerm));
  const { data: { _embedded } } = response;
  const { brands } = _embedded;
  
  return brands.map(brand => {
    return {
      label: brand.name,
      value: brand.id,
    }
  })
}

function BrandSelector(props) {
  const { selected, onSelect } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
    />
  )
}

BrandSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
};

BrandSelector.defaultProps = {
  selected: null
};

export default BrandSelector;
