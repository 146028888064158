import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getAllEnums, getAllUOM, fetchWarehouses, getTicketTypeSubtypeMapping, fetchStaticRoutes, fetchCurrency, fetchTaxType } from '../actions/index';
import Login from './Login/Login';
import SetPassword from './Login/SetPassword';
import App from './App';
import Home from './Home';
import PurchaseOrder from '../modules/Purchase/containers/PurchaseOrder';
import customComponent from './custom-components';
import EditVendor from './EditVendor';
import NewPo from '../modules/Purchase/containers/CreatePo';
import EditPo from '../modules/Purchase/containers/EditPo';
import ProductCatalog from './ProductCatalog';
import EditCatalog from '../modules/VendorCatalog/containers/EditCatalog';
import EditProductCatalog from '../modules/ProductCatalog/containers/EditProductCatalog';
import CatalogApproval from './CatalogApproval';
import CatalogView from '../modules/ProductCatalog/containers/CatalogView';
import PurchaseOrderView from '../modules/Purchase/containers/PurchaseOrderView';
import StaticRoutesView from '../modules/DeliveryRoutes/containers/StaticRoutesView';
import DeliveryRoutesView from '../modules/DeliveryRoutes/containers/DeliveryRoutesView';
import DeliveryRouteOrders from '../modules/DeliveryRoutes/containers/DeliveryRouteOrders';
import CustomerComplaints from '../modules/CustomerComplaints/containers/CustomerComplaints';
import CustomerOrder from '../modules/CustomerOrders/containers/CustomerOrder';
import CreateCo from '../modules/CustomerOrders/containers/CreateCo';
import EditCo from '../modules/CustomerOrders/containers/EditCo';
import Users from './Users';
import DeliveryBoys from './DeliveryBoys';
import DeliveryDetailedReports from './DeliveryDR';
import DeliveryConsolidatedReports from './DeliveryCR';
import Warehouses from './Warehouses';
import HubsContainer from './HubsContainer';
import StockAdjustment from './StockAdjustment';
import StockInventory from './StockInventory';
import StockLedger from './StockLedger';
import Region from './Region';
import SurveyContainers from '../modules/SurveyDashboard/containers/SurveyContainers';
import OrderStatusContainer from '../modules/OrderStatus/containers/OrderStatusContainer';
import PincodeContainer from '../modules/Pincode/containers/PincodeContainer';
import Category from './Category';
import Brand from './Brand';
import Quantity from './Quantity/container/QuantityContainer';
import CustomerAddressesContainer from '../modules/Customers/Container/CustomerAddressContainer';
import PurchaseIndentContainer from '../modules/Purchase/containers/PurchaseIndentContainer';
import BuildingsContainer from '../modules/Buildings/containers/BuildingsContainer';
import LocalitiesContainer from '../modules/Localities/LocalitiesContainer';
import ProductOfferContainer from '../modules/Offers/ProductOffers/containers/ProductOffer';
import SendSms from '../modules/SendSms/SendSms';
import CustomerContainer from '../modules/Customers/Container/CustomerContainer';
import PlanContainer from '../modules/CustomerPlan/Container/PlanContainer';
import CreateCustomerPlan from '../modules/CustomerPlan/Component/CreateCustomerPlan';
import ViewPlans from '../modules/CustomerPlan/Component/ViewPlans';
import EditAddress from '../modules/Customers/Component/EditAddressComponent';
import CustomerComponent from '../modules/Customers/Component/CustomerComponent';
import TransactionHistory from '../modules/Customers/Component/TransactionHistory';
import Banners from '../modules/Offers/Banners/containers/BannerOffer';
// import Coupons from '../modules/Offers/Coupons/containers/Coupons';
import NewUACustomerContainer from '../modules/Customers/Container/NewCustomerContainer';
import UnAssignedCustomerContainer from '../modules/Customers/Container/UnAssignedCustomerContainer';
import EditGRNContainer from '../modules/Purchase/containers/EditGRNContainer';
import AddGRNContainer from '../modules/Purchase/containers/AddGRNContainer';
import PRContainer from '../modules/Purchase/containers/PRContainer';
import ViewPRContainer from '../modules/Purchase/containers/ViewPRContainer';
import NonAvailabilityContainer from '../modules/NonAvailability/container/NonAvailabilityContainer';
import Investment from '../modules/Investment/containers/Investment';
import HubSegregationContainer from '../modules/Segregation/container/HubSegregationContainer';
import RouteSegregationContainer from '../modules/Segregation/container/RouteSegregationContainer';
import GRNContainer from '../modules/GRN/container/GRNContainer';
import MonitoringDashboard from '../modules/Monitoring/containers/MatrixDashboard';
import ProductMonitor from '../modules/Monitoring/containers/ProductMonitor';
import NaMonitoring from '../modules/Monitoring/containers/NaMonitoring';
import AppLink from '../modules/AppLinks/containers/AppLink';
import ManageSegregation from '../modules/Segregation/container/ManageSegregation';
import ProductOffersContainer from '../modules/Offer/Container/ProductOfferContainer';
import EditOfferContainer from '../modules/Offer/Component/EditOfferContainer';
import OfferCampaignContainers from '../modules/DiscountOffers/containers/OfferCampaignContainers';
import EditOfferCampaign from '../modules/DiscountOffers/components/EditOfferCampaign';
import AssignSegregator from '../modules/Segregation/container/AssignSegregator';
import CsAssist from '../modules/CsAssist';
import AttendanceContainer from '../modules/Attendance/containers/AttendanceContainer';
import TicketContainer from '../modules/Tickets/containers/TicketContainer';
import TicketDetail from '../modules/Tickets/containers/TicketDetail';
import TicketReporting from '../modules/Tickets/containers/TicketReporting';
import CRMContainer from '../modules/Tickets/CRMContainer';
import Inventory from '../modules/Inventory';
import AttendanceSummaryContainer from '../modules/AttendanceSummary/containers/AttendanceSummaryContainer';
import VendorCatalog from '../modules/VendorCatalog/containers/VendorCatalogView';
import ProductsView from '../modules/Products/containers/ProductsView';
import BannerContainer from '../modules/BannerManagement/containers/BannerContainer';
import DealOfTheDayContainer from '../modules/DealOfTheDay/containers/DealOfTheDayContainer';
import ProductWidgetsContainer from '../modules/ProductWidgets/containers/ProductWidgetsContainer';
import AppWidgetContainers from '../modules/AppWidgets/containers/AppWidgetContainers';
import PriceListContainer from '../modules/PriceList/containers/PriceListContainer';
import VendorStockLedger from '../modules/VendorProductItemsStock/containers/VendorStockLedger';
import MonthlyInvoices from '../modules/CsAssist/MonthlyInvoices';
import LocalityRouteMappingContainer from '../modules/LocalityRouteMapping/containers/LocalityRouteMappingContainer';
import AuditReportContainer from '../modules/AuditReport/containers/AuditReportContainer';
import PackagingContainer from './Packaging/container/PackagingContainer';
import AudienceSetContainers from "../modules/Audience/containers/AudienceSetContainers";
import ReferralContainer from '../modules/Referral/containers/ReferralContainer';
import TOTMarginContainer from '../modules/TOTMargin/containers/TOTMarginContainer';
import UserGroupContainer from '../modules/UserGroups/containers/UserGroupContainer';
import GroupContainer from '../modules/Groups/containers/GroupContainer';
import HomeOfferContainer from '../modules/HomeOffers/containers/HomeOfferContainer';
import SegregatorContainer from '../modules/Segregator/containers/SegregatorContainer';

/**
 * @param exact in order for not being followed with bellow routes
 */

class Root extends Component {
  componentDidMount() {
    const {
      getAllEnums: fetchEnums, getAllUOM: fetchUOM, fetchWarehouses: getWarehouses, fetchTicketTypeMappings,
      fetchAllStaticRoutes, fetchCurrency, fetchTaxType,
    } = this.props;
    fetchEnums();
    fetchUOM();
    getWarehouses();
    fetchTicketTypeMappings();
    fetchAllStaticRoutes();
    fetchCurrency();
    fetchTaxType();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/set-password" component={SetPassword} />
          <Route path="/vendors" component={App} />
          <Route path="/new-vendor" component={customComponent} />
          <Route path="/edit-vendor/:id" component={EditVendor} />
          <Route path="/purchase-order" component={PurchaseOrder} />
          <Route path="/purchase-indent" component={PurchaseIndentContainer} />
          <Route path="/new-po" component={NewPo} />
          <Route path="/edit-po/:id/view" component={EditPo} />
          <Route path="/edit-po/:id" component={EditPo} />
          <Route path="/new-catalog/:vendorId" component={EditCatalog} />
          <Route path="/edit-catalog/:id/:vendorId" component={EditCatalog} />
          <Route path="/catalog/:id" component={ProductCatalog} />
          <Route path="/catalog-view" component={CatalogView} />
          <Route path="/edit-product-catalog/:id" component={EditProductCatalog} />
          <Route exact path="/po/:id" component={PurchaseOrderView} />
          <Route path="/static-routes" component={StaticRoutesView} />
          <Route path="/delivery-routes" component={DeliveryRoutesView} />
          <Route path="/delivery-route-orders" component={DeliveryRouteOrders} />
          <Route path="/customer-complaints" component={CustomerComplaints} />
          <Route path="/customer-complaints-by-cc" component={CustomerComplaints} />
          <Route path="/customer-complaints-by-hub-route" component={CustomerComplaints} />
          <Route path="/customer-order" component={CustomerOrder} />
          <Route path="/new-co" component={CreateCo} />
          <Route path="/edit-co/:id/view" component={EditCo} />
          <Route path="/edit-co/:id" component={EditCo} />
          <Route path="/users" component={Users} />
          <Route path="/user-groups" component={UserGroupContainer} />
          <Route path="/groups" component={GroupContainer} />
          <Route path="/segregators" component={SegregatorContainer} />
          <Route path="/delivery-boys" component={DeliveryBoys} />
          <Route path="/delivery-boys-detailed-report" component={DeliveryDetailedReports} />
          <Route path="/delivery-boys-consolidated-report" component={DeliveryConsolidatedReports} />
          <Route path="/warehouses" component={Warehouses} />
          <Route path="/hubs" component={HubsContainer} />
          <Route path="/catalog-approval" component={CatalogApproval} />
          <Route path="/stock-adjustment" component={StockAdjustment} />
          <Route path="/stock-inventory" component={StockInventory} />
          <Route path="/stock-ledger" component={StockLedger} />
          <Route path="/region" component={Region} />
          <Route path="/survey" component={SurveyContainers} />
          <Route path="/orders" component={OrderStatusContainer} />
          <Route path="/pincode" component={PincodeContainer} />
          <Route path="/category" component={Category} />
          <Route path="/brand" component={Brand} />
          <Route path="/quantity" component={Quantity} />
          <Route path="/customer-info" component={CustomerContainer} />
          <Route path="/customer-plans/:customerId" component={PlanContainer} />
          <Route path="/customer-addresses/:customerId" component={CustomerAddressesContainer} />
          <Route path="/buildings" component={BuildingsContainer} />
          <Route path="/localities" component={LocalitiesContainer} />
          <Route path="/create-customer-plan/:customerId" component={CreateCustomerPlan} />
          <Route path="/view-customer-plan/:customerId/:addressId" component={ViewPlans} />
          <Route path="/product-offers" component={ProductOfferContainer} />
          <Route path="/send-sms" component={SendSms} />
          <Route path="/edit-customer-address/:addressId" component={EditAddress} />
          <Route path="/new-customer-address/:customerId" component={EditAddress} />
          <Route path="/customer-addresses" component={CustomerAddressesContainer} />
          <Route path="/transaction-history/:customerId" component={TransactionHistory} />
          <Route path="/customer-edit/:customerId" component={CustomerComponent} />
          <Route path="/banners" component={Banners} />
          {/* <Route path="/coupons" component={Coupons} /> */}
          <Route path="/new-customers" component={NewUACustomerContainer} />
          <Route path="/unassigned-customers" component={UnAssignedCustomerContainer} />
          <Route path="/po/:poId/grn/:id" component={EditGRNContainer} />
          <Route path="/po/:poId/grn" component={AddGRNContainer} />
          <Route exact path="/purchase-requisition" component={PRContainer} />
          <Route path="/purchase-requisition/:id" component={ViewPRContainer} />
          <Route path="/non-availability" component={NonAvailabilityContainer} />
          <Route path="/investment" component={Investment} />
          <Route path="/hub-segregation" component={HubSegregationContainer} />
          <Route path="/route-segregation" component={RouteSegregationContainer} />
          <Route path="/grn" component={GRNContainer} />
          <Route path="/monitoring" exact component={MonitoringDashboard} />
          {/* <Route path="/product-monitoring/:produtItemId" component={ProductMonitor} /> */}
          <Route path="/na-monitoring" exact component={NaMonitoring} />
          <Route path="/apps" exact component={AppLink} />
          <Route path="/segregation-management/:name" exact component={ManageSegregation} />
          <Route path="/offers" component={ProductOffersContainer} />
          <Route exact path="/edit-offers" component={EditOfferContainer} />
          <Route path="/edit-offers/:itemId" component={EditOfferContainer} />
          <Route path="/offer-campaign" component={OfferCampaignContainers} />
          <Route path="/edit-offer-campaign/:id" component={EditOfferCampaign} />
          <Route path="/assign/segregator" component={AssignSegregator} />
          <Route path={['/cs-assist', '/cs-assist/:type/:id']} exact component={CsAssist} />
          <Route path={['/monthly-invoices', '/monthly-invoices/:type/:id']} exact component={MonthlyInvoices} />
          {/* <Route path="/cs-helper/:type/:id" exact render={(props)=><CsAssist {...props} inFullScreen />} /> */}
          <Route path="/attendance" component={AttendanceContainer} />
          <Route path="/attendance-summary" component={AttendanceSummaryContainer} />
          <Route exact path="/tickets" component={TicketContainer} />
          <Route path="/tickets/:id" component={TicketDetail} />
          <Route path="/ticket-reporting" component={TicketReporting} />
          <Route
            path={['/inventory', '/inventory/:page', '/inventory/item/:itemId']}
            exact
            component={Inventory}
          />

          <Route path="/vendor-catalog-view" component={VendorCatalog} />
          <Route path="/products" component={ProductsView} />
          <Route path="/banner-management" component={BannerContainer} />
          <Route path="/deal-of-the-day-products" component={DealOfTheDayContainer} />
          <Route path="/product-widgets" component={ProductWidgetsContainer} />
          <Route path="/app-widgets" component={AppWidgetContainers} />
          <Route path="/home-offers" component={HomeOfferContainer} />
          <Route path="/price-list" component={PriceListContainer} />
          <Route path="/vendor-stock-ledger" component={VendorStockLedger} />
          <Route path="/default-route-mapping" component={LocalityRouteMappingContainer} />
          <Route path="/audit-report" component={AuditReportContainer} />
          <Route path="/audience-set" component={AudienceSetContainers} />
          <Route path="/referral" component={ReferralContainer} />
          <Route path="/packaging" component={PackagingContainer} />
          <Route path="/tot-margin" component={TOTMarginContainer} />
        </Switch>
      </Router>
    );
  }
}

Root.propTypes = {
  getAllEnums: PropTypes.func,
  getAllUOM: PropTypes.func,
  fetchWarehouses: PropTypes.func,
  fetchTicketTypeMappings: PropTypes.func,
  fetchAllStaticRoutes: PropTypes.func,
  fetchTaxType: PropTypes.func,
};

Root.defaultProps = {
  getAllEnums: () => { },
  getAllUOM: () => { },
  fetchWarehouses: () => { },
  fetchTicketTypeMappings: () => { },
  fetchAllStaticRoutes: () => { },
  fetchTaxType: () => { },
};

const mapDispatchToProps = dispatch => ({
  getAllEnums: bindActionCreators(getAllEnums, dispatch),
  getAllUOM: bindActionCreators(getAllUOM, dispatch),
  fetchWarehouses: bindActionCreators(fetchWarehouses, dispatch),
  fetchTicketTypeMappings: bindActionCreators(getTicketTypeSubtypeMapping, dispatch),
  fetchAllStaticRoutes: bindActionCreators(fetchStaticRoutes, dispatch),
  fetchCurrency: bindActionCreators(fetchCurrency, dispatch),
  fetchTaxType: bindActionCreators(fetchTaxType, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(Root);
