import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { KeyboardArrowRight } from '@material-ui/icons/';
import cx from 'classnames';
import './styles.scss';

class NavSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  render() {
    const { currentPageName } = this.props;
    const { hover } = this.state;
    const navStyle = hover ? cx('navSideBar', 'open') : 'navSideBar';

    return (
      <div className={navStyle} onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
        {!hover && <KeyboardArrowRight className="navIcon" />}
        {hover && (
          <ul className="nav">
            <li>
              <a
                href="/inventory"
                alt
                className={currentPageName === 'dashboard' ? 'active' : null}
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="/inventory/adjustments"
                alt
                className={currentPageName === 'adjustments' ? 'active' : null}
              >
                Items Adjustment
              </a>
            </li>
            <li>
              <a
                href="/inventory/categories"
                alt
                className={currentPageName === 'categories' ? 'active' : null}
              >
                Category
              </a>
            </li>
            <li>
              <a
                href="/inventory/analytics"
                alt
                className={currentPageName === 'analytics' ? 'active' : null}
              >
                Analytics
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

NavSideBar.propTypes = {};

export default NavSideBar;
