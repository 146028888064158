import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';
import WarehouseTable from './WarehouseTable';
import WarehouseSearchBar from './WarehouseSearchBar';
import apiCall, { getWareHouse, getFetchAllRegionsPath, getFetchAllWarehouses, regionIdPath, getWareHouseById } from "../api/NetworkHandler";
import { ColMod } from './lib';
import Toast from "../utils/Toast";
import isEmpty from "lodash/isEmpty";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddWarehouse: false,
      selectedWarehouse: {},
      warehouse: [],
      links: {},
      error: {},
      warehouseRegions: [],
      regions: [],
      filters: {
        query: '',
      },
      searchedResult: false,
      patchData: {},
    };
  }

  componentDidMount() {
    this.fetchAllWarehouse();
    this.fetchAllRegions();
  }

  onSearch = (event) => {
    if (event.key === 'Enter') {
      this.fetchAllWarehouse();
    }
  }


getWarehouseDetails = () => {
  const regionUrl = this.state.selectedWarehouse._links.regions.href.split('/').slice(3).join('/');
  apiCall.sendRequest('get', regionUrl)
    .then((response) => {
      const wregions = response.data._embedded.regions;
      this.setState({
        warehouseRegions: this.state.regions.filter((region) => {
          for (let i = 0; i < wregions.length; i++) {
            if (wregions[i].id === region.id) {
              return true;
            }
          }
        }),
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

fetchPaginatedWarehouse = (url) => {
  apiCall.sendRequest('get', url)
    .then(({ data }) => {
      this.setState({
        warehouse: data._embedded.warehouse,
        links: data._links,
        searchedResult: false,
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

fetchAllRegions = () => {
  apiCall.sendRequest('get', getFetchAllRegionsPath(''))
    .then(({ data }) => {
      this.setState({
        regions: data._embedded.regions,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}


fetchAllWarehouse = () => {
  if (this.state.filters.query) {
    apiCall.sendRequest('get', getFetchAllWarehouses(this.state.filters.query))
      .then(({ data }) => {
        this.setState({
          warehouse: data._embedded.warehouse,
          links: data._links,
          searchedResult: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    apiCall.sendRequest('get', getWareHouse)
      .then(({ data }) => {
        this.setState({
          warehouse: data._embedded.warehouse,
          links: data._links,
          searchedResult: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

handleQueryChange = (event) => {
  const {value} = event.target;
  this.setState(prevState => ({
    filters: {
      ...prevState.filters,
      query: value,
    },
  }));
}


handleClick = (selectedWarehouse) => {
  this.setState({
    selectedWarehouse,
    selectedName: selectedWarehouse.name,
    selectedParentCategory: selectedWarehouse.parentId ? selectedWarehouse.parentId : '',
  }, () => {
    this.getWarehouseDetails();
  });
}

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

handleChange = (event) => {
  const {name} = event.target;
  const {value} = event.target;
  if (name === 'active') {
    this.setState(prevState => ({
      selectedWarehouse: {
        ...prevState.selectedWarehouse,
        [name]: value === 'ACTIVE',
      },
      patchData: {
        ...prevState.patchData,
        [name]: value  === 'ACTIVE',
      }
    }));
  } else if (name === 'region') {

  } else {
    this.setState(prevState => ({
      selectedWarehouse: {
        ...prevState.selectedWarehouse,
        [name]: value,
      },
      patchData: {
        ...prevState.patchData,
        [name]: value,
      },
    }));
  }
}

openAddWarehouse = (warehouse) => {
  if (!warehouse) this.setState({ selectedWarehouse: {}, warehouseRegions: [] })
  this.setState({
    openAddWarehouse: true,
    isEdit: !!warehouse,
  });
}

handleClose = () => {
  this.setState({
    openAddWarehouse: false,
    isEdit: false,
    selectedWarehouse: {},
    error: {},
    warehouseRegions: [],
  });
}

  isPropertyValid = (key, value) => {
    // Is empty does not word for numbers so if condition
    if (typeof value === 'number') {
      if (value === 0) {
        this.setError(key, true);
        return false;
      }
    } else {
      if (isEmpty(value)) {
        this.setError(key, true);
        return false;
      }
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const warehouse = {};
    warehouse.name = this.state.selectedWarehouse.name;
    warehouse.address = this.state.selectedWarehouse.address;
    let isValid = true;

    const validateEntities = ['name', 'address'];

    for(const property in warehouse) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, warehouse[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

postWarehouse = () => {
  if (!this.isFormValid()) {
    return;
  }
  const postData = { ...this.state.selectedWarehouse };
  const warehouseId = postData.id;
  if(!warehouseId) {
    postData.regions = this.state.warehouseRegions.map(region => regionIdPath(region.id));
    apiCall.sendRequest('post', getWareHouse, postData)
      .then((response) => {
        this.fetchAllWarehouse();
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    const { patchData } = this.state;
    patchData.regions = this.state.warehouseRegions.map(region => regionIdPath(region.id));
    apiCall.sendRequest('patch', getWareHouseById(warehouseId), patchData)
      .then((response) => {
        this.fetchAllWarehouse();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  this.handleClose();
}

renderRegions = () =>
  (this.state.warehouseRegions ? this.state.warehouseRegions.map(region => (<div>
    <div style={{ display: 'flex' }}>
      <Typography variant="body1" color="inherit" style={{ flex: 1 }}>
        <b>{region.name}</b>
      </Typography>
    </div>
                                                                            </div>)) : <div />)

render() {
  return (
    <div>
      <NavBar />
      <WarehouseSearchBar onSearch={this.onSearch} openAddWarehouse={this.openAddWarehouse} query={this.state.filters.query} handleQueryChange={this.handleQueryChange} onSearch={this.onSearch} />
      <div style={styles.div}>
        <div style={styles.paperLeft}>
          <WarehouseTable warehouse={this.state.warehouse} links={this.state.links} fetchPaginatedWarehouse={this.fetchPaginatedWarehouse} searchedResult={this.state.searchedResult} openAddWarehouse={this.openAddWarehouse} handleClick={this.handleClick} />
        </div>
        <div style={styles.paperRight}>
          { Object.keys(this.state.selectedWarehouse).length
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={{ borderBottom: 'solid 1px #e0e0e0', height: '40px' }}>
                  Warehouse Details
                  </Typography>
                  <Typography variant="body1" color="secondary" style={{ marginTop: '10px' }}>
                    {this.state.selectedWarehouse.name}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  Lat-Long: {(this.state.selectedWarehouse.lat ? `${this.state.selectedWarehouse.lat}, ` : '') + (this.state.selectedWarehouse.lng ? this.state.selectedWarehouse.lng : '')}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  Address: {this.state.selectedWarehouse.address ? this.state.selectedWarehouse.address : null}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  Regions
                  </Typography>
                  {this.renderRegions()}
                </div>
              : <div />}
        </div>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.openAddWarehouse}
        onClose={this.handleClose}
      >
        <div style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            width: '70%',
            marginLeft: '15%',
            height: '80%',
          }}
        >
          <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <Typography variant="body2" color="secondary">
                    {this.state.isEdit ? 'Update Warehouse' : 'Create Warehouse'}
                  </Typography>
                </Row>
                <Row>
                <Typography variant="caption" color="error">
                  * Required
                </Typography>
                </Row>
                <Row>
                  <ColMod lg={6}>
                    <TextField
                      label="Name"
                      fullWidth
                      name="name"
                      required
                      error={this.state.error.name}
                      value={this.state.selectedWarehouse.name ? this.state.selectedWarehouse.name : ''}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                  <ColMod lg={6}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Status</InputLabel>
                    <MaterialSelect
                      value={this.state.selectedWarehouse.active ? 'ACTIVE' : (this.state.selectedWarehouse.active === false ? 'INACTIVE' : 'ACTIVE')}
                      onChange={this.handleChange}
                      inputProps={{
                          name: 'active',
                          id: 'select-status',
                        }}
                      fullWidth
                    >
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="INACTIVE">Inactive</MenuItem>
                    </MaterialSelect>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={4}>
                    <TextField
                      label="Latitude"
                      fullWidth
                      name="lat"
                      value={this.state.selectedWarehouse.lat ? this.state.selectedWarehouse.lat : ''}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="Longitude"
                      fullWidth
                      name="lng"
                      value={this.state.selectedWarehouse.lng ? this.state.selectedWarehouse.lng : ''}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="Phone"
                      fullWidth
                      name="phoneNumber"
                      value={this.state.selectedWarehouse.phoneNumber ? this.state.selectedWarehouse.phoneNumber : ''}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Regions</InputLabel>
                    <MaterialSelect
                      multiple
                      value={this.state.warehouseRegions}
                      onChange={(event) => {
                          const {value} = event.target;
                          this.setState({
                            warehouseRegions: value,
                          });
                        }}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={selected => (
                        <div>
                          {selected.map(value => <Chip style={{ padding: 0 }} key={value.id} label={value.name} />)}
                        </div>
                        )}
                      fullWidth
                    >
                      {this.state.regions.map((region, index) => (
                        <MenuItem
                          key={index}
                          value={region}
                        >
                          {region.name}
                        </MenuItem>
                        ))}
                    </MaterialSelect>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <TextField
                      label="Address"
                      fullWidth
                      name="address"
                      required
                      error={this.state.error.address}
                      value={this.state.selectedWarehouse.address ? this.state.selectedWarehouse.address : ''}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
          <div style={{ float: 'right', paddingRight: '2em' }}>
            <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.postWarehouse();
              }}
              variant="contained"
              color="secondary"
            >
              {this.state.isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
}


export default Dashboard;
