import BASE_URL from "../api/config";

const data = {
id: 1,
timeCreated: "2018-05-25T18:19:35.000+0000",
timeUpdated: "2018-05-25T18:19:35.000+0000",
active: true,
name: "Testing",
website: "http://testing.com",
email: null,
mobile: null,
workPhone: null,
fax: null,
cin: null,
gstin: null,
pan: null,
bankName: null,
bankAddress: null,
bankAccountNo: null,
bankIfscCode: null,
versionId: 0,
_links: {
self: {
href: `{BASE_URL.BASE_URL}/api/v1/vendors/1`
},
vendor: {
href: `{BASE_URL.BASE_URL}/api/v1/vendors/1`
},
vendorCatalogs: {
href: `{BASE_URL.BASE_URL}/api/v1/vendors/1/vendor-catalogs`
},
vendorLocations: {
href: `{BASE_URL.BASE_URL}/api/v1/vendors/1/vendor-locations`
}
}
};
export default data;