import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@material-ui/core';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
};

class AgentReportSearch extends Component {
  onChangeFilter = (event) => {
    const { onFilterChange } = this.props;
    const { name, value } = event.target;
    onFilterChange(name, value);
  };
  render() {
    const { filters = {}, onButtonClick } = this.props;
    const { fromDate, toDate } = filters;
    return (
            <div style={styles.container}>
            <Grid container spacing={16}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={16}>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="fromDate"
                      fullWidth
                      type="date"
                      label="From Date"
                      name="fromDate"
                      onChange={this.onChangeFilter}
                      value={fromDate || ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="toDate"
                      fullWidth
                      type="date"
                      label="To Date"
                      name="toDate"
                      onChange={this.onChangeFilter}
                      value={toDate || ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={32}>
                  <Grid item xs={6} md={6}>
                    <Button color="secondary" variant="contained" onClick={() => onButtonClick('search', 0)}>
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button color="secondary" variant="contained" onClick={() => onButtonClick('download', 0)}>
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
    );
  }
}

AgentReportSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
};

export default AgentReportSearch;
