import React, { Component } from 'react';
import moment from 'moment';
import { Grid, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import {
  Button,
  Select,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Typography,
  Modal,
  TextField,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import MaterialSelect from '@material-ui/core/Select';
import apiCall, { fetchDeliveryBoys, fetchHubByIdPath, fetchUserByIdPath } from '../../../api/NetworkHandler';
import { ColMod } from '../../../components/lib';
import UploadFile from '../../../modules/Commons/components/UploadFile';
import UserSelection from '../../../components/UserSelection';


const styles = {
  popUp: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    height: '90%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};


class DeliveryBoyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idCardImage: null,
      photo: null,
    };
  }


  componentWillReceiveProps(nextProps) {
    const { selectedDeliveryBoy = {} } = nextProps;
    if (nextProps) {
      if (selectedDeliveryBoy !== this.state.selectedDeliveryBoy
      ) {
        this.setState({
          selectedDeliveryBoy,
        });
      }
    }
    this.openAddDeliveryBoy(nextProps);
  }

onChangeValue = (event, id) => {
  const { selectedDeliveryBoy = {} } = this.state;
  const { value } = event.target;
  if (id === 'active') {
    selectedDeliveryBoy[id] = value === 'ACTIVE';
    this.setState({ selectedDeliveryBoy });
  } else if (id === 'tshirt' || id === 'rainCoat' || id === 'tarpaulinBag' || id === 'idCardProvided') {
    const { checked } = event.target;
    selectedDeliveryBoy[id] = checked;
    this.setState({
      selectedDeliveryBoy,
    });
  } else {
    selectedDeliveryBoy[id] = value;
    this.setState({
      selectedDeliveryBoy,
    });
  }
};

onClose = () => {
  this.setState({
    openUserSelector: false,
    userType: '',
  });
}

openUserSelector = userType => () => {
  this.setState({
    openUserSelector: true,
    userType,
  });
}
handleImageUpload = (imageObj) => {
  const [idCardImageURL] = Object.values(imageObj);
  this.setState({
    idCardImage: idCardImageURL,
  });
}

handlePhotoUpload = (photoObj) => {
  const [fePhotoURL] = Object.values(photoObj);
  this.setState({
    photo: fePhotoURL,
  });
}

openAddDeliveryBoy = (nextProps) => {
  const { selectedDeliveryBoy = {} } = nextProps;
  if (selectedDeliveryBoy) {
    this.setState({
      idCardImage: selectedDeliveryBoy.idCardImageURL,
      photo: selectedDeliveryBoy.photo,
    });
  }
  this.setState({
    selectedDeliveryBoy: selectedDeliveryBoy || {},
  });
  this.fetchHubById(nextProps);
  this.fetchUserById(nextProps);
}

fetchHubById= (nextProps) => {
  const { selectedDeliveryBoy = {} } = nextProps;
  const { hubId = '' } = selectedDeliveryBoy;
  if (hubId) {
    apiCall.sendRequest('get', fetchHubByIdPath(hubId))
      .then((response) => {
        const { data } = response;
        this.setState(prevState => ({
          selectedDeliveryBoy: {
            ...prevState.selectedDeliveryBoy,
            hubName: data.name,
          },
        }));
      });
  }
}


fetchUserById = (nextProps) => {
  const { selectedDeliveryBoy = {} } = nextProps;
  const { referredBy = '' } = selectedDeliveryBoy;
  if (referredBy) {
    apiCall.sendRequest('get', fetchUserByIdPath(referredBy))
      .then((response) => {
        const { data } = response;
        this.setState(prevState => ({
          selectedDeliveryBoy: {
            ...prevState.selectedDeliveryBoy,
            referredByName: data.name,
          },
        }));
      });
  }
}

postUser = () => {
  const {
    idCardImage, photo,
  } = this.state;
  const postData = { ...this.state.selectedDeliveryBoy };
  postData.idCardImageURL = idCardImage;
  postData.photo = photo;
  const newData = { ...postData };
  apiCall.sendRequest('post', fetchDeliveryBoys, newData)
    .then((response) => {
    })
    .catch((error) => {
      console.error(error);
    });
  this.onClose();
}


selectUser = (deliveryBoy) => {
  const { userType } = this.state;
  switch (userType) {
    case 'User':
      this.setState(prevState => ({
        selectedDeliveryBoy: {
          ...prevState.selectedDeliveryBoy,
          referredBy: deliveryBoy.id,
          referredByName: deliveryBoy.name,
        },
      }));
      break;
    case 'Hub':
      this.setState(prevState => ({
        selectedDeliveryBoy: {
          ...prevState.selectedDeliveryBoy,
          hubId: deliveryBoy.id,
          hubName: deliveryBoy.name,
        },
      }));
      break;
    default:
      break;
  }
  this.onClose();
}


render() {
  const {
    openUserSelector, userType, selectedDeliveryBoy, idCardImage, photo,
  } = this.state;
  const {
    deliveryBoyIdCardType, maritalStatuses, openAddDeliveryBoy, handleClose,
  } = this.props;


  const {
    idCard,
    tshirt = false,
    rainCoat = false,
    tarpaulinBag = false,
    idCardProvided = false,
    name,
    active,
    address,
    mobile,
    pancard,
    email,
    drivingLicence,
    latitude,
    longitude,
    alternatePhone,
    reasonForHiring,
    reasonForLeaving,
    bankAccountNo,
    bankAddress,
    bankIfscCode,
    bankName,
    idCardNumber,
    fatherName,
    motherName,
    maritalStatus,
    dob = null,
    personalEmailId,
    bloodGroup,
    code,
    doj = null,
    dol = null,
    hubName,
    referredByName,
    otherOrganisations,
  } = selectedDeliveryBoy || {};

  const images = [];
  if (idCardImage) {
    images.push({
      name: selectedDeliveryBoy.idCard,
      url: idCardImage,
      editable: false,
    });
  }

  const fePhoto = [];
  if (photo) {
    fePhoto.push({
      name: selectedDeliveryBoy.name,
      url: photo,
      editable: false,
    });
  }

  if (isEmpty(selectedDeliveryBoy)) {
    return null;
  }


  return (
      <div>
        <UserSelection
            userType={userType}
            openSearchBox={openUserSelector}
            handleClose={this.onClose}
            selectUser={this.selectUser}
            selectHubUser={this.selectHubUser}
          />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openAddDeliveryBoy}
          handleClose={handleClose}
        >
          <div style={styles.popUp}
          >
          <Button style={{ float: 'right' }} onClick={handleClose} color="secondary" aria-label="close">
                <CloseIcon />
            </Button>
            <Grid style={{ padding: '1em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      {'Update Delivery Boy'}
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Name (As Aadhar)"
                        fullWidth
                        name="name"
                        value={name || ''}
                        onChange={event => this.onChangeValue(event, 'name')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Email"
                        fullWidth
                        name="email"
                        value={email || ''}
                        onChange={event => this.onChangeValue(event, 'email')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Status</InputLabel>
                  <MaterialSelect
                    value={active ? 'ACTIVE' : 'INACTIVE'}
                    inputProps={{
                      name: 'selectedStatus',
                      id: 'select-status',
                    }}
                    onChange={event => this.onChangeValue(event, 'active')}
                    fullWidth
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </MaterialSelect>
                    </ColMod>
                  </Row>
                   <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Mobile"
                        fullWidth
                        name="mobile"
                        value={mobile || ''}
                        onChange={event => this.onChangeValue(event, 'mobile')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Address"
                        fullWidth
                        name="address"
                        value={address || ''}
                        onChange={event => this.onChangeValue(event, 'address')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Company ID Card Number"
                        fullWidth
                        name="code"
                        value={code || ''}
                        onChange={event => this.onChangeValue(event, 'code')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                        <TextField
                        id="date"
                        type="date"
                        fullWidth
                        label="Date of Joining"
                        name="doj"
                        value={moment(doj).format('YYYY-MM-DD')}
                        onChange={event => this.onChangeValue(event, 'doj')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        />
                    </ColMod>
                      <ColMod lg={4}>
                          <TextField
                            label="Hub Name"
                            fullWidth
                            name="hubId"
                            value={hubName || '' }
                            onChange={event => this.onChangeValue(event, 'hubId')}
                            onClick={this.openUserSelector('Hub')}
                              InputProps={{
                              readOnly: true,
                              endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                        }}
                      />
                      </ColMod>
                    <ColMod lg={4}>
                        <TextField
                        label="Other Company Status"
                        multiline
                        rows="2"
                        fullWidth
                        name="otherOrganisations"
                        value={otherOrganisations || ''}
                        onChange={event => this.onChangeValue(event, 'otherOrganisations')}
                        />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={4}>
                      <TextField
                        label="Reason For Hiring"
                        fullWidth
                        name="reasonForHiring"
                        value={reasonForHiring || ''}
                        onChange={event => this.onChangeValue(event, 'reasonForHiring')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Reason for Leaving"
                        name={reasonForLeaving}
                        fullWidth
                        value={reasonForLeaving || ''}
                        onChange={event => this.onChangeValue(event, 'reasonForLeaving')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                          <TextField
                            label="ReferredBy Name"
                            fullWidth
                            name="referredBy"
                            value={referredByName || '' }
                            onChange={event => this.handleChange(event, 'referredBy')}
                            onClick={this.openUserSelector('User')}
                              helperText="Referred"
                              InputProps={{
                              readOnly: true,
                              endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={3}>
                        <TextField
                        id="date"
                        type="date"
                        fullWidth
                        label="Date of Leaving"
                        name="dol"
                        value={moment(dol).format('YYYY-MM-DD') || ''}
                        onChange={event => this.onChangeValue(event, 'dol')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        />
                    </ColMod>
                  <ColMod lg={3}>
                  <TextField
                    label="Alternate Mobile Number"
                    fullWidth
                    name="alternatePhone"
                    value={alternatePhone || ''}
                    onChange={event => this.onChangeValue(event, 'alternatePhone')}
                  />
                </ColMod>
                    <ColMod lg={3}>
                      <TextField
                        label="Latitude"
                        fullWidth
                        name="latitude"
                        value={latitude || ''}
                        onChange={event => this.onChangeValue(event, 'latitude')}
                      />
                    </ColMod>
                    <ColMod lg={3}>
                      <TextField
                        label="Longitude"
                        fullWidth
                        name="longitude"
                        value={longitude || ''}
                        onChange={event => this.onChangeValue(event, 'longitude')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={3}>
                  <Select
                  style={{ marginTop: 16 }}
                  native
                  fullWidth
                  name="idCard"
                  value={idCard || ''}
                  onChange={event => this.onChangeValue(event, 'idCard')}
                >
                  <option value="">Select Id Type</option>
                  {Object.keys(deliveryBoyIdCardType).map(type => (
                    <option value={type}>{deliveryBoyIdCardType[type]}</option>
                ))}
                </Select>
                <FormHelperText>Select ID Type</FormHelperText>
            </ColMod>
            <ColMod lg={3}>
                      <TextField
                        label="Enter Id Number"
                        name="idCardNumber"
                        fullWidth
                        value={idCardNumber || ''}
                        onChange={event => this.onChangeValue(event, 'idCardNumber')}
                      />
                    </ColMod>
                    <ColMod lg={3} style={{ border: '1px solid' }}>
                    <UploadFile
                      title="Upload Id Card"
                      files={images}
                      onUpload={this.handleImageUpload}
                      multiple={false}
                    />
                      </ColMod>
                      <ColMod lg={3} style={{ border: '1px solid' }}>
                    <UploadFile
                      title="Upload FE Photo"
                      files={fePhoto}
                      onUpload={this.handlePhotoUpload}
                      multiple={false}
                    />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tshirt}
                          value={tshirt}
                          onChange={event => this.onChangeValue(event, 'tshirt')}
                          color="secondary"
                        />
                          }
                      label="T-Shirt"
                    />
                  </FormGroup>
                </ColMod>
                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rainCoat}
                          value={rainCoat}
                          onChange={event => this.onChangeValue(event, 'rainCoat')}
                          color="secondary"
                        />
                          }
                      label="Rain Coat"
                    />
                  </FormGroup>
                </ColMod>
                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tarpaulinBag}
                          value={tarpaulinBag}
                          onChange={event => this.onChangeValue(event, 'tarpaulinBag')}
                          color="secondary"
                        />
                          }
                      label="Tarpaulin Bag"
                    />
                  </FormGroup>
                </ColMod>
                <ColMod lg={3}>
                    <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                                checked={idCardProvided}
                                value={idCardProvided}
                                onChange={event => this.onChangeValue(event, 'idCardProvided')}
                                color="secondary"
                            />
                          }
                        label="IdCard"
                        />
                    </FormGroup>
                 </ColMod>
              </Row>
                  <Row>
                    <Typography variant="body2" color="secondary">
                        Payment Details
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Bank Name"
                        fullWidth
                        name="bankName"
                        value={bankName || ''}
                        onChange={event => this.onChangeValue(event, 'bankName')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Account Number"
                        fullWidth
                        name="bankAccountNo"
                        value={bankAccountNo || ''}
                        onChange={event => this.onChangeValue(event, 'bankAccountNo')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="IFSC Code"
                        fullWidth
                        name="bankIfscCode"
                        value={bankIfscCode || ''}
                        onChange={event => this.onChangeValue(event, 'bankIfscCode')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={8}>
                      <TextField
                        label="Address"
                        name={bankAddress}
                        fullWidth
                        value={bankAddress || ''}
                        onChange={event => this.onChangeValue(event, 'bankAddress')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="PAN"
                        name="pancard"
                        fullWidth
                        value={pancard || ''}
                        onChange={event => this.onChangeValue(event, 'pancard')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <Typography variant="body2" color="secondary">
                          FE Family Details
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                          <TextField
                              label = "name"
                              name="name"
                              fullWidth
                              value={name || ''}
                              onChange={event => this.onChangeValue(event, 'name')}
                          />
                    </ColMod>
                    <ColMod lg={4}>
                          <TextField
                              label = "Father Name"
                              name="fatherName"
                              fullWidth
                              value={fatherName || ''}
                              onChange={event => this.onChangeValue(event, 'fatherName')}
                          />
                    </ColMod>
                    <ColMod lg={4}>
                          <TextField
                              label = "Mother Name"
                              name="motherName"
                              fullWidth
                              value={motherName || ''}
                              onChange={event => this.onChangeValue(event, 'motherName')}
                          />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={4}>
                          <TextField
                              label = "Personal Email Id"
                              name="personalEmailId"
                              fullWidth
                              value={personalEmailId || ''}
                              onChange={event => this.onChangeValue(event, 'personalEmailId')}
                          />
                    </ColMod>
                    <ColMod lg={4}>
                              <TextField
                                id="date"
                                type="date"
                                label="Date Of Birth"
                                name="dob"
                                fullWidth
                                value={moment(dob).format('YYYY-MM-DD')}
                                onChange={event => this.onChangeValue(event, 'dob')}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                  />
                    </ColMod>
                    <ColMod lg={4}>
                          <TextField
                              label = "Blood Group"
                              name="bloodGroup"
                              fullWidth
                              value={bloodGroup || ''}
                              onChange={event => this.onChangeValue(event, 'bloodGroup')}
                          />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={4}>
                      <TextField
                        label="Driving License"
                        fullWidth
                        name="drivingLicence"
                        value={drivingLicence || ''}
                        onChange={event => this.onChangeValue(event, 'drivingLicence')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <Select
                        style={{ marginTop: 16 }}
                        native
                        fullWidth
                        name="maritalStatus"
                        value={maritalStatus || ''}
                        onChange={event => this.onChangeValue(event, 'maritalStatus')}
                      >
                        <option value="">Select Marital Status</option>
                        {Object.keys(maritalStatuses).map(status => (
                          <option value={status}>{maritalStatuses[status]}</option>
                      ))}
                </Select>
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button onClick={handleClose} style={{ marginRight: '2em' }} color="secondary" >
              Cancel
              </Button>
              <Button
                onClick={() => {
                this.postUser();
                handleClose();
              }}
                variant="contained"
                color="secondary"
              >
                { 'Update' }
              </Button>
            </div>
          </div>
        </Modal>
      </div>
  );
}
}


DeliveryBoyProfile.propTypes = {
  deliveryBoyIdCardType: PropTypes.instanceOf(Object),
  maritalStatuses: PropTypes.instanceOf(Object),
  selectedDeliveryBoy: PropTypes.instanceOf(Object),
  openAddDeliveryBoy: PropTypes.bool,
  handleClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { deliveryBoyIdCardType, maritalStatuses } = Status;
  return {
    deliveryBoyIdCardType,
    maritalStatuses,
  };
};


export default withRouter(connect(mapStateToProps, null)(DeliveryBoyProfile));

