import BASE_URL from "../api/config";

const data = {
_embedded: {
vendors: [
{
id: 1,
timeCreated: "2018-05-25T18:19:35.000+0000",
timeUpdated: "2018-05-25T18:19:35.000+0000",
active: true,
name: "Testing",
website: "http://testing.com",
email: null,
mobile: null,
workPhone: null,
fax: null,
cin: null,
gstin: null,
pan: null,
bankName: null,
bankAddress: null,
bankAccountNo: null,
bankIfscCode: null,
versionId: 0,
_links: {
self: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/1`
},
vendor: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/1`
},
vendorCatalogs: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/1/vendor-catalogs`
},
vendorLocations: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/1/vendor-locations`
}
}
},
{
id: 2,
timeCreated: "2018-05-30T19:03:51.000+0000",
timeUpdated: "2018-05-30T19:03:51.000+0000",
active: true,
name: "Testing-1",
website: "http://testing.com",
email: null,
mobile: null,
workPhone: null,
fax: null,
cin: null,
gstin: null,
pan: null,
bankName: null,
bankAddress: null,
bankAccountNo: null,
bankIfscCode: null,
versionId: 0,
_links: {
self: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/2`
},
vendor: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/2`
},
vendorCatalogs: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/2/vendor-catalogs`
},
vendorLocations: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/2/vendor-locations`
}
}
},
{
id: 3,
timeCreated: "2018-06-02T17:05:02.000+0000",
timeUpdated: "2018-06-03T01:26:08.000+0000",
active: true,
name: "Vendor Testing - 4",
website: "http://testing.com",
email: null,
mobile: null,
workPhone: null,
fax: null,
cin: null,
gstin: null,
pan: null,
bankName: null,
bankAddress: null,
bankAccountNo: null,
bankIfscCode: null,
versionId: 2,
_links: {
self: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/3`
},
vendor: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/3`
},
vendorCatalogs: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/3/vendor-catalogs`
},
vendorLocations: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/3/vendor-locations`
}
}
},
{
id: 4,
timeCreated: "2018-06-03T01:26:08.000+0000",
timeUpdated: "2018-06-03T01:26:08.000+0000",
active: true,
name: "Vendor Testing",
website: "http://testing.com",
email: null,
mobile: null,
workPhone: null,
fax: null,
cin: null,
gstin: null,
pan: null,
bankName: null,
bankAddress: null,
bankAccountNo: null,
bankIfscCode: null,
versionId: 0,
_links: {
self: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/4`
},
vendor: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/4`
},
vendorCatalogs: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/4/vendor-catalogs`
},
vendorLocations: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors/4/vendor-locations`
}
}
}
]
},
_links: {
self: {
href: `${BASE_URL.BASE_URL}/api/v1/vendors{?page,size,sort}`,
templated: true
},
profile: {
href: `${BASE_URL.BASE_URL}/api/v1/profile/vendors`
}
},
page: {
size: 20,
totalElements: 4,
totalPages: 1,
number: 0
}
};

export default data;