import React from "react";
import ProductImageItem from "./ProductImageItem";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import CONFIG from '../../../api/config';

const DEFAULT_IMAGE_LIST = [
  {
    position: "FRONT",
    uploadProgressStatus: 0
  },
  {
    position: "BACK",
    uploadProgressStatus: 0
  },
  {
    position: "LEFT_SIDE",
    uploadProgressStatus: 0
  },
  {
    position: "RIGHT_SIDE",
    uploadProgressStatus: 0
  }
];

const SINGLE_IMAGE = [
  {
    position: "FRONT",
    uploadProgressStatus: 0
  },
]

const styles = {
  container: {
    border: "1px solid #8080803d",
    padding: "10px",
    maxWidth: "300px",
    borderRadius: "2px",
    backgroundColor: "#f5f5f585"
  },
  imageTitle: {
    display: "flex",
    fontSize: "24px",
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  singleImageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }
};

class ProductImageManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productImages: props.singleImage ? SINGLE_IMAGE : DEFAULT_IMAGE_LIST,
      loading: false,
    };
  }

  setImageDisplayName = (props) => {
    if (this.props.singleImage) {
      this.setState(prevState => {
        const { productImages } = prevState;
        productImages[0].position = props.images[0].name || "FRONT";
        productImages[0].imageUrl = props.images[0].imageUrl || null;
        return {
          productImages,
        }
      })
    }
  }

  componentDidMount() {
    this.setImageDisplayName(this.props);
    const { productImages } = this.state;
    productImages.map(item => {
      this.props.images.map(data => {
        if (data.position === item.position) {
          item.imageUrl = data.imageUrl;
          item.id = data.id;
        }
      });
      return item;
    });
    this.setState({ productImages });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setImageDisplayName(nextProps);
    const { productImages } = this.state;
    productImages.map(item => {
      nextProps.images.map(data => {
        if (data.position === item.position) {
          item.imageUrl = data.imageUrl;
          item.id = data.id;
        }
      });
      return item;
    });
    this.setState({ productImages });
  }

  uploadFileHandler = index => event => {
    const [image] = event.target.files;
    const { productImages } = this.state;
    const { parentId = '' } = this.props;
    const formData = new FormData();
    formData.append("images", image);
    // var interval;

    // productImages[index].uploadProgressStatus = 0.01;

    const config = {
      onUploadProgress: progressEvent => {
        // interval = setInterval(() => {
        //   productImages[index].uploadProgressStatus += 10;
        //   productImages[index].uploadProgressStatus < 100 &&
        //     this.setState({ productImages });
        // }, 100);
      },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    const api = axios.create({
      baseURL: `${CONFIG.BASE_URL}`,
    });

    this.setState({
      loading: true,
    });

    const uploadUrl = parentId === 'survey' ?  '/api/v1/images?imageType=SURVEY' : '/api/v1/images';

    api.post(uploadUrl, formData, config)
      .then(response => {
        productImages[index].imageUrl =
          response.data[Object.keys(response.data)];
        // productImages[index].uploadProgressStatus = 100;
        // clearInterval(interval);
        this.props.onImageUpload(productImages[index].imageUrl);
        this.setState({ productImages, loading: false });
        // productImages[index].uploadProgressStatus = 0;
      })
      .catch(response => {
        productImages[index].error = "Something went wrong! Please try again.";
        this.setState({ productImages });
        setTimeout(() => {
          productImages[index].error = "";
          // productImages[index].uploadProgressStatus = 0;
          this.setState({ productImages, loading: false });
        }, 3000);
      });
  };

  deleteFileHandler = index => {
    const { productImages } = this.state;
    productImages[index].imageUrl = null;
    // productImages[index].uploadProgressStatus = 0;
    this.setState({ productImages });
    this.props.onDelete();
  };

  render() {
    const { classes, singleImage } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.imageTitle}>{singleImage ? "Image" : "Images"}</div>
        <div className={singleImage ? classes.singleImageContainer : classes.imageContainer}>
          {this.state.productImages.map((item, i) => (
            <ProductImageItem
              productInfo={item}
              index={i}
              loading={loading}
              {...this}
              {...this.state}
              key={i}
            />
          ))}
        </div>
      </div>
    );
  }
}

ProductImageManager.propTypes = {
  classes: PropTypes.object,

  /**
   * array of objects containing info of image
   * [{ position: 'POSITION', imageUrl: 'url' }...]
   * In case of Single Image [{ position: 'FRONT', imageUrl: 'url', name: "name to be displayed" }]
   */
  images: PropTypes.arrayOf(PropTypes.object),

  /**
   * Triggered when image uploaded and get new image url in parameter
   */
  onImageUpload: PropTypes.func,

  /**
   * true if uploading single image
   */
  singleImage: PropTypes.bool,

  /**
   * Invoked when image deleted
   */
  onDelete: PropTypes.func
};

ProductImageManager.defaultProps = {
  singleImage: false,
  images: [],
};

export default withStyles(styles)(ProductImageManager);
