import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PRODUCT_TIME_FORMAT } from '../../../constants'

export default function TimeUpdated(props) {
  const { timeUpdated } = props;
  return (
    <p>{moment(timeUpdated).format(PRODUCT_TIME_FORMAT)}</p>
  )
}

TimeUpdated.propTypes = {
  timeUpdated: PropTypes.string.isRequired,
}