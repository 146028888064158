import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  TextField,
  Button,
  Typography,
  FormHelperText,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import apiCall, { createOverlays } from '../../../api/NetworkHandler';
import { isEmpty, debounce, capitalize } from 'lodash';
import { ArrowBack } from 'material-ui-icons';
import CSVReader from 'react-csv-reader';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
};

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
        .toLowerCase()
        .replace(/\W/g, '')
};

let COMPONENT_TITLE = 'Create Audience Set';
const re = /^[0-9\b]+$/;

class CreateAudienceSet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audienceSetData: {
                title: '',
                description: '',
                filterType: 'CUSTOMER',
                value: '',
            },
            disableButton: false,
            error: {},
            startSliceValue: 0,
            endSliceValue: 6,
        };
    }

    setError = (key, value) => {
        this.setState((prevState) => {
        const { error } = prevState;

        return {
            error: {
                ...error,
                [key]: value,
            },
        };
        });
    };

    isPropertyValid = (key, value) => {
        if (isEmpty(value)) {
            this.setError(key, true);
            return false;
        }
        this.setError(key, false);
        return true;
    };

    isFormValid = () => {
        const audienceSet = {};
        audienceSet.title = this.state.audienceSetData.title;
        audienceSet.description = this.state.audienceSetData.description;
        audienceSet.filterType = this.state.audienceSetData.filterType;
        audienceSet.value = this.state.audienceSetData.value;
        let isValid = true;

        const validateEntities = ['title', 'description', 'filterType', 'value'];

        for (const property in audienceSet) {
            if (validateEntities.includes(property) && !this.isPropertyValid(property, audienceSet[property])) {
                isValid = false;
            }
        }
        return isValid;
    };

    handleCreateAudienceSet = debounce( async () => {
        if (!this.isFormValid()) {
            return;
        }
        this.toggleCreateButton(true);
        const { onClose, onButtonClick } = this.props;
        const { audienceSetData } = this.state;
        const { filterType, value } = audienceSetData;
        if (['BUILDING', 'LOCALITY', 'PINCODE', 'CITY'].includes(filterType)) {
            audienceSetData.value = value.split(",").filter(el => el);
        }
        try {
            await apiCall.sendRequest('post', createOverlays, audienceSetData);
            onClose();
            onButtonClick('search', 0);
            this.toggleCreateButton(false);
        } catch (err) {
            console.log(err.response);
            this.toggleCreateButton(false);
            onClose();
        }
    }, 500);

    toggleCreateButton = (state) => {
        this.setState({
            disableButton: state,
        });
    }

    handleChange = (eventValue, key) => {
        const { audienceSetData } = this.state;
        if (key === 'filterType') {
            this.setState({
                audienceSetData: {
                    ...audienceSetData,
                    [key]: eventValue,
                    value: null
                }
            });
        } else {
            this.setState({
                audienceSetData: {
                    ...audienceSetData,
                    [key]: eventValue,
                }
            });
        }
    }

    handleUploadFile = (data, fileInfo) => {
        if (fileInfo.type !== "text/csv") {
            message.warn("Please upload csv file..");
        }
        const customerIds = data.map(el => el.customer_id);
        const { audienceSetData } = this.state;
        this.setState({
            audienceSetData: {
                ...audienceSetData,
                value: customerIds,
            }
        });

        document.getElementById("fileControl").value = '';
    };

    handleUploadError = (err) => {
        console.log(err);
    };

    renderAudienceSetCustomerList = () => {
        const { audienceSetData: { value } = {}, startSliceValue, endSliceValue } = this.state;
        const customerIdGroups = value.map((e, i) => { 
            return i % 4 === 0 ? value.slice(i, i + 4) : null; 
        }).filter(el => el);
        const initialCustomerIds = customerIdGroups.slice(startSliceValue, endSliceValue);
        return (
            <Paper style={{ height: '350px', overflowY: 'scroll' }}>
                <Table>
                    <TableBody>
                        {initialCustomerIds.map((el, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{el[0]}</TableCell>
                                    <TableCell component="th" scope="row">{el[1]}</TableCell>
                                    <TableCell component="th" scope="row">{el[2]}</TableCell>
                                    <TableCell component="th" scope="row">{el[3]}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton disabled={startSliceValue <= 0}>
                        <NavigateBeforeIcon onClick={this.handleChangePageBefore} />
                    </IconButton>
                    <IconButton>
                        <NavigateNextIcon onClick={this.handleChangePageNext} />
                    </IconButton>
                </div>
            </Paper>
        );
    };

    handleChangePageBefore = () => {
        const { startSliceValue, endSliceValue } = this.state;
        this.setState({
            startSliceValue: startSliceValue - 6,
            endSliceValue: endSliceValue - 6,
        });
    };

    handleChangePageNext = () => {
        const { startSliceValue, endSliceValue } = this.state;
        this.setState({
            startSliceValue: startSliceValue + 6,
            endSliceValue: endSliceValue + 6,
        });
    };

  render() {
    const { onClose, overlayFilterTypes } = this.props;

    const { audienceSetData, disableButton } = this.state;

    const {
      id,
      title,
      description,
      filterType,
      value,
    } = audienceSetData;

    const newValue = filterType === 'CUSTOMER' && !value ? [] : value;

    return (
      <div>
        <div style={styles.div}>
            <Grid style={{ padding: '2em', width: '40vw' }} fluid>
                <Row md={12} between="md">
                    <ColMod>
                        <Row>
                            <Col>
                                <ArrowBack onClick={onClose} color="secondary" style={{ fontSize: '1.6em', cursor: 'pointer' }}/>
                            </Col>
                            <Col>
                                <Typography variant="title"> {COMPONENT_TITLE} </Typography>
                            </Col>
                        </Row>
                    </ColMod>
                    <ColMod>
                        <Row md={12}>
                            <Col md={12}>
                                <Button onClick={onClose} variant="outlined" color="secondary" style={{ marginRight: '10px' }}>Cancel</Button>
                                <Button type="submit" variant="contained" color="primary" onClick={this.handleCreateAudienceSet} disabled={disableButton}> Create </Button>
                            </Col>
                        </Row>
                    </ColMod>
                </Row>
                <Row lg={12}>
                    <ColMod zerobottom lg={12}>
                        <TextField
                            label="Title"
                            name="title"
                            fullWidth
                            required
                            error={this.state.error.title}
                            value={title}
                            onChange={e => this.handleChange(e.target.value, 'title')}
                            disabled={id}
                        />
                    </ColMod>
                </Row>
                <Row lg={12}>
                    <ColMod zerobottom lg={12}>
                        <TextField
                            label="Description"
                            name="description"
                            required
                            fullWidth
                            error={this.state.error.description}
                            value={description}
                            onChange={e => this.handleChange(e.target.value, 'description')}
                        />
                    </ColMod>
                </Row>
                <Row lg={12}>
                    <ColMod lg={6}>
                        <FormHelperText> Filter Type<sup>*</sup> </FormHelperText>
                        <Select
                            fullWidth
                            label="Filter Type"
                            value={filterType}
                            onChange={e => this.handleChange(e.target.value, 'filterType')}
                        >
                            {Object.keys(overlayFilterTypes || {}).filter(el => el === 'CUSTOMER').map((type, index) => (<MenuItem key={index} value={type}>{overlayFilterTypes[type]}</MenuItem>))}
                        </Select>
                    </ColMod>
                    {filterType === 'CUSTOMER_FROM' &&
                        <ColMod zerobottom lg={6}>
                            <TextField
                                type="date"
                                label="Customer From Date"
                                name="value"
                                error={this.state.error.customerFromDate}
                                fullWidth
                                required
                                value={value || ''}
                                onChange={e => this.handleChange(e.target.value, 'value')}
                                InputLabelProps={{ shrink: true }}
                            />
                        </ColMod>
                    }
                    {['BUILDING', 'LOCALITY', 'PINCODE', 'CITY'].includes(filterType) &&
                        <ColMod zerobottom lg={6}>
                            <TextField
                                name="value"
                                error={this.state.error.customerFromDate}
                                fullWidth
                                required
                                value={value || ''}
                                onChange={e => this.handleChange(e.target.value, 'value')}
                                label={capitalize(filterType)}
                                multiline
                                rowsMax={4}
                                variant="outlined"
                            />
                        </ColMod>
                    }
                    {filterType === 'CUSTOMER' &&
                        <ColMod zerobottom lg={6}>
                            <div>
                                <CSVReader
                                    cssClass="csv-reader-input"
                                    label="Select CSV to upload"
                                    accept=".csv, text/csv"
                                    onFileLoaded={this.handleUploadFile}
                                    onError={this.handleUploadError}
                                    parserOptions={papaparseOptions}
                                    inputId="fileControl"
                                    inputStyle={{color: 'transparent', outlineStyle: 'none'}}
                                />
                            </div>
                        </ColMod>
                    }
                    {(newValue.length === 0) && <span style={{ color: 'red', paddingLeft: '20px' }}>**Please upload csv file</span>}
                </Row>
                {(newValue.length > 0) &&
                    <Row lg={12}>
                        <ColMod lg={12}>
                            <div style={{ border: '1px solid black', borderRadius: '5px', height: '30px', display: 'flex', flexDirection: 'row' }}>
                                <p style={{ paddingTop: '3px', paddingLeft: '20px' }}><b>No Of Customer ID: &nbsp;&nbsp;</b></p>
                                <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>{value.length}</p>
                            </div>
                            {this.renderAudienceSetCustomerList()}
                        </ColMod>
                    </Row>
                }
            </Grid>
        </div>
      </div>
    );
  }
}


CreateAudienceSet.propTypes = {
    onClose: PropTypes.func,
    onButtonClick: PropTypes.func,
    overlayFilterTypes: PropTypes.instanceOf(Object),
  };
  
export default CreateAudienceSet;