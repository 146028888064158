import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import apiCall, { getVendorLocationByName } from "../../../api/NetworkHandler";
import { debounce, isEmpty } from 'lodash';
import Lozenge from '@atlaskit/lozenge';
import RegionSelector from '../../../components/Region/Selector';

const styles = {
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    width: '55%',
    height: '85%',
    transition: 'width 2s',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    paddingLeft: window.innerWidth * 0.04,
    paddingTop: '10px'
  }
}

var versionDict = {
  'locationRequestId': 0,
}

class VendorLocationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
      selectedData: {},
      selectedRegion: null,
    };
  }

  componentDidMount() {
    const { regions } = this.props;
    if (regions.length > 0) {
      this.setState({
        selectedRegion: regions.map(el => {return {label: el.name, value: el.id}})[0]
      }, () => this.fetchLocationData());
    }
  }

  fetchLocationData = debounce(async () => {
    const { selectedRegion, searchText } = this.state;
    const regionId = isEmpty(selectedRegion) ? '' : selectedRegion.value;
    try {
      versionDict.locationRequestId += 1;
      let prevRequestId = versionDict.locationRequestId;
      const response = await apiCall.sendRequest('get', getVendorLocationByName(searchText, regionId));
      const { data: { _embedded: embedded = {} } = {} } = response;
      if (versionDict.locationRequestId == prevRequestId) {
        this.setState({
          suggestions: embedded['vendor-locations']
        });
      }
    } catch (error) {
      this.setState({
        suggestions: []
      });
    }
  }, 750);

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({
      searchText: searchTerm
    }, () => this.fetchLocationData());
  }

  handleClearSearch = () => {
    this.setState({
      searchText: '',
      suggestions: [],
      selectedRegion: null,
    }, () => this.fetchLocationData());
  }

  handleSelect = (data) => {
    const { onSelect } = this.props;
    const { handleClose } = this.props;
    onSelect(data);
    this.setEmptyState();
    handleClose();
  }

  setEmptyState = () => {
    this.setState({
      suggestions: [],
      searchText: '',
    });
  }

  handleClose = () => {
    const { handleClose } = this.props;
    this.setEmptyState();
    handleClose();
  }

  handleRegionChange = (selectedRegion) => {
    this.setState({
      selectedRegion,
    }, () => this.fetchLocationData());
  }

  render() {
    const { suggestions, searchText, selectedRegion } = this.state;
    const { openSearchBox, parentComponent, helperText } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openSearchBox}
        onClose={this.handleClose}
      >
        <div style={styles.container}>
          <div style={styles.header}>
            <Typography variant="title" style={styles.title}> {parentComponent} </Typography>
            <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </div>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={12}>
                <Row>
                  <ColMod lg={6}>
                    <TextField
                      label="Search"
                      value={searchText}
                      onChange={this.handleInputChange}
                      placeholder="type to search"
                      helperText={helperText}
                      fullWidth
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <Typography variant="caption"> Region </Typography>
                    <RegionSelector selected={selectedRegion} onSelect={this.handleRegionChange} name="region" />
                  </ColMod>
                  <ColMod lg={2}>
                    <Button variant="contained" color="secondary" onClick={this.handleClearSearch}> Clear </Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowY: 'auto', overflowX: 'auto', height: window.innerHeight / 2.35 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row" padding="none">Location ID</TableCell>
                            <TableCell component="th" scope="row" padding="none">Name</TableCell>
                            <TableCell component="th" scope="row" padding="none">Status</TableCell>
                            <TableCell component="th" scope="row" padding="none">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suggestions.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" padding="none">{data.id}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{data.name}</TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Lozenge appearance={data.active ? "success" : "removed"}>{data.active ? "Active" : "Inactive"}</Lozenge>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Button size="small" variant="contained" color="secondary" onClick={() => this.handleSelect(data)}> Select </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div>
      </Modal>
    );
  }
}

VendorLocationSelector.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectGst: PropTypes.func.isRequired,
};

export default VendorLocationSelector;
