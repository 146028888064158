import React from 'react';
import PropTypes from 'prop-types';
import SearchAndSelect from '../components/Core/SearchAndSelect';
import apiCall, { searchVendorsProductsPathByWarehouse } from '../api/NetworkHandler';

const onLoad = async (searchTerm, warehouseId) => {
  const response = await apiCall.sendRequest('get', searchVendorsProductsPathByWarehouse(searchTerm, warehouseId));
  const { data: { data: { content = [] } = {} } = {} } = response;

  return content.map(item => ({
    label: item.name,
    value: item.id,
  }));
};

function VendorProductItemSelector(props) {
  const { selected, onSelect, warehouseId } = props;

  return (
    <SearchAndSelect
      onLoad={searchTerm => onLoad(searchTerm, warehouseId)}
      selected={selected}
      onSelect={onSelect}
    />
  );
}

VendorProductItemSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.instanceOf(Object),
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,

};

VendorProductItemSelector.defaultProps = {
  selected: null,
};

export default VendorProductItemSelector;
