import React, { Component } from 'react';
import { Paper, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import Colors from '../../../utils/Colors';

const data = [
  {
    hubName: 'HUB1',
    requiredQuantity: 100,
    recievedQuantity: 80,
    shortageQuantity: 20,
    routes: [{
      name: 'ROUTE1',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE2',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE3',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE4',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }],
  },
  {
    hubName: 'HUB3',
    requiredQuantity: 100,
    recievedQuantity: 80,
    shortageQuantity: 20,
    routes: [{
      name: 'ROUTE1',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE2',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE3',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }],
  },
  {
    hubName: 'HUB2',
    requiredQuantity: 100,
    recievedQuantity: 80,
    shortageQuantity: 20,
    routes: [{
      name: 'ROUTE1',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE2',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }, {
      name: 'ROUTE3',
      requiredQuantity: 100,
      recievedQuantity: 80,
      shortageQuantity: 20,
    }],
  },
];

const styles = {
  cell: {
    borderRight: '1px solid #CCC',
    borderBottom: '1px solid #CCC',
    padding: '10px 5px',
    textAlign: 'center',
  },
  cellHead: {
    borderRight: '1px solid #CCC',
    borderBottom: '1px solid #CCC',
    padding: '10px 5px',
    textAlign: 'center',
    // fontWeight: 600,
    color: Colors.white,
    background: Colors.primary,
  },
  icon: {
    fontSize: 20,
    verticalAlign: 'middle',
    border: '1px solid #696464',
    color: '#696464',
  },
};

class ProductMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHub: '',
    };
  }

  renderRoutes = items => items.map(item => (
    <Grid container>
      <Grid item style={styles.cell} xs={3}>{item.name}</Grid>
      <Grid item style={styles.cell} xs={3}>{item.requiredQuantity}</Grid>
      <Grid item style={styles.cell} xs={3}>{item.recievedQuantity}</Grid>
      <Grid item style={styles.cell} xs={3}>{item.shortageQuantity}</Grid>
    </Grid>
  ))

  render() {
    const { selectedHub = '' } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar title="Nandini Shubham-500ML" />
        <br />
        <Grid container>
          <Grid item xs={12} md={10} style={{ margin: '0px auto' }}>
            <Paper square elevation={1}>
              <Grid container>
                <Grid item style={styles.cellHead} xs={3}>Hub/Route Name</Grid>
                <Grid item style={styles.cellHead} xs={3}>Required Qty</Grid>
                <Grid item style={styles.cellHead} xs={3}>Recieved Qty</Grid>
                <Grid item style={styles.cellHead} xs={3}>NA</Grid>
              </Grid>
            </Paper>
            {
                  data.map((item, index) => (
                    <Paper square elevation={1} style={{ margin: index === selectedHub ? '10px 0px' : 0 }}>
                      <Grid
                        container
                        style={{ background: '#e2e2e2' }}
                        onClick={() => { this.setState({ selectedHub: selectedHub === index ? '' : index }); }}
                      >
                        <Grid item style={{ ...styles.cell, display: 'flex' }} xs={3}>
                          <div style={{ flex: 1 }}>{item.hubName}</div>
                          {
                            selectedHub === index ?
                              <ExpandLessIcon
                                onClick={() => { this.setState({ selectedHub: '' }); }}
                                style={styles.icon}
                              /> :
                              <ExpandMoreIcon
                                onClick={() => { this.setState({ selectedHub: index }); }}
                                style={styles.icon}
                              />}
                        </Grid>
                        <Grid item style={styles.cell} xs={3}>{item.requiredQuantity}</Grid>
                        <Grid item style={styles.cell} xs={3}>{item.recievedQuantity}</Grid>
                        <Grid item style={styles.cell} xs={3}>{item.shortageQuantity}</Grid>
                      </Grid>
                      <Grid container>
                        {
                          index === selectedHub &&
                          <Grid item xs={12}>
                            {this.renderRoutes(item.routes)}
                          </Grid>
                        }
                      </Grid>
                    </Paper>
                  ))
              }
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ProductMonitor;
