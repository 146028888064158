import React, { Component } from 'react';
import Upload from '../../ProductCatalog/components/Upload';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';

export default class ImageContainer extends Component {
  render() {
    const {
      urls,
      handleImageUpload,
      clearImage
    } = this.props;
    return (
      <Row>
        {urls.map((url, index) => (
          <ColMod key={index} lg={3} md={3} sm={3}>
            <Upload
              url={url.imageUrl ? url.imageUrl : null}
              side={url.position}
              handleImageUpload={(imageUrl, position) => handleImageUpload(imageUrl, position)}
              clearImage={(position) => { clearImage(position); }}
            />
          </ColMod>
        ))}
      </Row>
    )
  }
}
