import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { InputLabel, MenuItem } from '@material-ui/core';
import MaterialSelect from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';
import CategoryTable from './CategoryTable';
import CategorySearchBar from './CategorySearchBar';
import apiCall, { postCategoryPath, allCategoriesPath, getFetchAllCategories, fetchAllZoneIds } from '../api/NetworkHandler';
import CategorySelection from './CategorySelection';
import { ColMod } from './lib';
import ProductImageManager from './Core/ProductImageManager/ProductImageManager';
import isEmpty from 'lodash/isEmpty';
import Toast from '../utils/Toast';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  fullScreen: {
    flex: 1,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: null,
      categories: [],
      zones: [],
      links: {},
      selectedCategory: {},
      openAddCategory: false,
      isEdit: false,
      selectedName: '',
      selectedSortId: '',
      selectedZoneId: '',
      selectedDescription: '',
      selectedParentCategoryName: '',
      imageUrl: '',
      name: '',
      filters: {
        query: '',
      },
      error: {},
      searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchAllCategories();
    this.fetchAllZones();
  }


onSearch = () => {
  this.fetchAllCategories();
};

fetchAllCategories = () => {
  if (this.state.filters.query) {
    apiCall.sendRequest('get', getFetchAllCategories(this.state.filters.query))
      .then((response) => {
        this.setState({
          categories: response.data._embedded.categories,
          links: response.data._links,
          searchedResult: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    apiCall.sendRequest('get', allCategoriesPath)
      .then((response) => {
        this.setState({
          categories: response.data._embedded.categories,
          links: response.data._links,
          searchedResult: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

fetchPaginatedCategories = (url) => {
  apiCall.sendRequest('get', url)
    .then((response) => {
      this.setState({
        categories: response.data._embedded.categories,
        links: response.data._links,
        searchedResult: false,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

handleQueryChange = (event) => {
  const query = event.target.value;
  this.setState(prevState => ({
    filters: {
      ...prevState.filters,
      query,
    },
  }));
};

clearSearch = () => {
  this.setState({
    filters: {
      query: '',
    },
  }, () => {
    this.onSearch();
  });
};

handleClick = (selectedCategory) => {
  this.setState({
    selectedCategory,
    selectedName: selectedCategory.name,
    selectedParentCategory: selectedCategory.parentId ? selectedCategory.parentId : '',
  });
};

handleChange = (event) => {
  const { name } = event.target;
  const { value } = event.target;
  this.setState({
    [name]: value,
  });
};

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

openAddCategory = (category) => {
  if (category) {
    this.setState({
      selectedName: category.name,
      selectedSortId: category.sortId,
      selectedZoneId: category.zoneId,
      selectedParentCategory: category.parentId ? category.parentId : '',
      selectedDescription: category.description,
      selectedParentCategoryName: category.parentName,
      imageUrl: category.imageUrl,
      name: category.name,
    });
  }
  this.setState({
    openAddCategory: true,
    isEdit: !!category,
  });
};

handleClose = () => {
  this.setState({
    openAddCategory: false,
    isEdit: false,
    selectedName: '',
    selectedParentCategory: '',
    selectedParentCategoryName: '',
    selectedDescription: '',
    imageUrl: '',
    name: '',
    selectedSortId: '',
    selectedZoneId: '',
    selectedCategory: {},
    error: {},
  });
};

postCategory = () => {
  if (!this.isFormValid()) {
    return;
  }
  const postData = { ...this.state.selectedCategory };
  postData.name = this.state.selectedName;
  postData.imageUrl = this.state.imageUrl;
  postData.description = this.state.selectedDescription;
  postData.sortId = this.state.selectedSortId;
  postData.zoneId = this.state.selectedZoneId;
  postData.parent = this.state.selectedParentCategory ? postCategoryPath(this.state.selectedParentCategory) : '';
  apiCall.sendRequest('post', allCategoriesPath, postData)
    .then((response) => {
      this.fetchAllCategories();
    })
    .catch((error) => {
      console.error(error);
    });
  this.handleClose();
};

  isFormValid = () => {
    const selectedCategory = {};
    selectedCategory.selectedName = this.state.selectedName;
    selectedCategory.selectedSortId = this.state.selectedSortId;
    selectedCategory.selectedZoneId = this.state.selectedZoneId;
    let isValid = true;

    const validateEntities = ['selectedName', 'selectedSortId', 'selectedZoneId'];

    for(const property in selectedCategory) {
      if (validateEntities.includes(property) && !this.isPropertyValid(property, selectedCategory[property])) {
        isValid = false;
      }
    }
    if (!this.state.imageUrl) {
      Toast.show('Image is Mandatory');
      isValid = false;
    }
    return isValid;
  };

  isPropertyValid = (key, value) => {
    // Is empty does not word for numbers so if condition
    if (typeof value === 'number') {
      if (value === 0) {
        this.setError(key, true);
        return false;
      }
    } else {
      if (isEmpty(value)) {
        this.setError(key, true);
        return false;
      }
    }
    this.setError(key, false);
    return true;
  };

openCategorySelector = () => {
  this.setState({
    openCategorySelector: true,
  });
};

  handleCategorySelectorClose = () => {
    this.setState({
      openCategorySelector: false,
    });
  };

  selectCategory = (category) => {
    this.setState({
      selectedParentCategory: category.id,
      selectedParentCategoryName: category.name,
      selectedZoneId: category.zoneId,
      openCategorySelector: false,
    });
  };

  fetchAllZones = () => {
    apiCall.sendRequest('get', fetchAllZoneIds)
      .then((response) => {
        this.setState({
          zones: response.data._embedded.zones,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onImageUpload = (imageUrl) => {
    this.setState({
      imageUrl,
    });
  };

  onDeleteImage = () => {
    this.setState({
      imageUrl: null,
    });
  };

  render() {
    return (
      <div>
        <NavBar />
        <CategorySearchBar openAddCategory={this.openAddCategory} handleQueryChange={this.handleQueryChange} onSearch={this.onSearch} clearSearch={this.clearSearch} searchQuery={this.state.filters.query} />
        <div style={styles.div}>
          {/* <div style={styles.paperLeft}> */}
          <div style={styles.fullScreen}>
            <CategoryTable categories={this.state.categories} zones={this.state.zones} openAddCategory={this.openAddCategory} handleClick={this.handleClick} links={this.state.links} fetchPaginatedCategories={this.fetchPaginatedCategories} searchedResult={this.state.searchedResult} />
          </div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openAddCategory}
            onClose={this.handleClose}
            onBackdropClick={this.handleClose}
          >
            <div style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'white',
              width: '70%',
              marginLeft: '15%',
              height: '80%',
              overflow: 'auto',
            }}
            >
              <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <Row>
                      <Typography variant="body2" color="secondary">
                        {this.state.isEdit ? 'Update Category' : 'Create Category'}
                      </Typography>
                    </Row>
                      <Row>
                      <Typography variant="body2" color="error">
                        * Required
                      </Typography>
                      </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                          label="Name"
                          fullWidth
                          name = "selectedName"
                          required
                          error={this.state.error.selectedName}
                          value={this.state.selectedName}
                          onChange={(event) => {
                            const { value } = event.target;
                            this.setState({
                              selectedName: value,
                            });
                          }}
                        />
                      </ColMod>
                      <ColMod lg={6}>
                        <TextField
                          margin="dense"
                          id="input-with-icon-textfield"
                          value={this.state.selectedParentCategoryName}
                          name="Category"
                          fullWidth
                          onChange={() => { }}
                          onClick={this.openCategorySelector}
                          helperText="Parent Category"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
  <InputAdornment position="start" >
    <Search color="secondary" />
  </InputAdornment>
                            ),
                          }}
                        />
                      </ColMod>
                    </Row>
                    <Row>
                      <ColMod lg={12}>
                        <TextField
                          label="Description"
                          fullWidth
                          value={this.state.selectedDescription}
                          onChange={(event) => {
                            const { value } = event.target;
                            this.setState({
                              selectedDescription: value,
                            });
                          }}
                        />
                      </ColMod>
                    </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                            label="Sort Id"
                            fullWidth
                            type="number"
                            name = "selectedSortId"
                            required
                            value={this.state.selectedSortId}
                            error={this.state.error.selectedName}
                            onChange={(event) => {
                              const { value } = event.target;
                              this.setState({
                                selectedSortId: value,
                              });
                            }}
                        />
                      </ColMod>
                      <ColMod lg={6}>
                        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-zoneId">Zone*</InputLabel>
                        <MaterialSelect
                            value={this.state.selectedZoneId}
                            required
                            name = "selectedSortId"
                            error={this.state.error.selectedName}
                            onChange={(event) => {
                              const { value } = event.target;
                              this.setState({
                                selectedZoneId: value,
                              });
                            }}
                            fullWidth
                        >
                          {this.state.zones.map(zone =>
                              <MenuItem key={zone.id} value={zone.id} >{zone.name}</MenuItem>)}
                        </MaterialSelect>
                      </ColMod>
                    </Row>

                  </ColMod>
                </Row>

                <Row md={12}>
                  <ColMod md={12}>
                    <ProductImageManager
                        images={[{
                          imageUrl: this.state.imageUrl,
                          position: 'FRONT',
                          name: this.state.name,
                        }]}
                        singleImage
                        onImageUpload={this.onImageUpload}
                        onDelete={this.onDeleteImage}
                    />
                  </ColMod>
                </Row>
              </Grid>
              <div style={{ float: 'right', paddingRight: '2em' }}>
                <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.postCategory();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {this.state.isEdit ? 'Update' : 'Create'}
                </Button>
              </div>
              <CategorySelection openSearchBox={this.state.openCategorySelector} handleClose={this.handleCategorySelectorClose} selectCategory={this.selectCategory} />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}


export default Dashboard;
