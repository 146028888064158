import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import apiCall, { getSurveyListByParams, updateGlobalConfig } from '../../../api/NetworkHandler';
import SurveySearch from '../components/SurveySearch';
import SurveyList from '../components/SurveyList';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';


const Constants = {
  title: 'Survey',
};

var versionDict = {
  'surveyRequestId': 0,
};

class SurveyContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyData: [],
      filters: {
        question: '',
        active: '',
        type: '',
      },
      totalElements: 0,
      currentPage: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount= () => {
    this.fetchParams();
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    });
    this.fetchSurveyList(filters, page);
  };

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({
      filters: getFilterParams
    }, () => this.fetchSurveyList());
  }

  fetchSurveyList = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    try {
      versionDict.surveyRequestId += 1;
      let prevRequestId = versionDict.surveyRequestId;
      const response = await apiCall.sendRequest('get', getSurveyListByParams(filters, page, size));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (versionDict.surveyRequestId == prevRequestId) {
        this.setState({
          surveyData: content,
          totalElements,
        });
      }
    } catch (e) {
      this.setState({
        surveyData: [],
        totalElements: 0,
      });
    }
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.fetchSurveyList() })
  };

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.fetchSurveyList() })
  };

  updateGlobalCoolOffTime = async (globalTtlValue) => {
    try {
      await apiCall.sendRequest('post', updateGlobalConfig(globalTtlValue));
    } catch (err) {
      console.error(err.response);
    }
  };

  render() {
    const { surveyTypes, surveyConditionTypes } = this.props;
    const {
      surveyData, filters, totalElements, currentPage, rowsPerPage,
    } = this.state;
    const { productItemId = '' } = filters;
    const newSurveyTypes = (typeof surveyTypes === 'undefined' || Object.keys(surveyTypes).length === 0) ? {} : surveyTypes;

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <SurveySearch
            queryId={productItemId}
            surveyTypes={newSurveyTypes}
            surveyConditionTypes={surveyConditionTypes}
            onRefresh={this.fetchSurveyList}
            onUpdateGlobalCoolOffTime={this.updateGlobalCoolOffTime}
          />
        </SearchBar>
        <SurveyList
          surveyData={surveyData}
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={this.onPageChange}
          onRowsPerPageChange={this.onRowsPerPageChange}
          surveyConditionTypes={surveyConditionTypes}
          surveyTypes={surveyTypes}
          onRefresh={this.fetchSurveyList}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
    surveyTypes: state.Status.surveyTypes,
    surveyConditionTypes: state.Status.surveyConditionTypes,
});
  
export default connect(mapStateToProps, null)(withRouter(SurveyContainers));
