
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import apiCall, { fetchDeliveryBoyById, fetchUserByIdPath } from '../../../api/NetworkHandler';
import DeliveryBoyProfile from '../components/DeliveryBoyProfile';
import UserProfile from '../components/UserProfile';

const attendaceDetails = [
  {
    id: 'empCode',
    numeric: false,
    label: 'E Code',
  },
  {
    id: 'empName',
    numeric: false,
    label: 'E Name',
  },
  {
    id: 'department',
    numeric: false,
    label: 'Department',
  },
  {
    id: 'doj',
    numeric: false,
    label: 'DOJ',
  },
];
const attendaceSummary = [
  {
    id: 'present',
    numeric: false,
    label: 'Present Days',
  },
  {
    id: 'absent',
    numeric: false,
    label: 'Absent Days',
  },
  {
    id: 'leftDate',
    numeric: false,
    label: 'Left Date',
  },
  {
    id: 'remarks',
    numeric: false,
    label: 'Remark',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 3,
    height: '100%',
    textAlign: 'center',
    border: '1px solid #D3D3D3',
    boxShadow: '3px 3px 3px #D3D3D3',
  },
  paperRight: {
    flex: 6,
    textAlign: 'center',
    overflow: 'scroll',
  },
  tableCell: {
    textAlign: 'center',
    border: '1px solid #C2C2C0',
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    backgroundColor: '#F5DCE1',
    textAlign: 'center',
    border: '1px solid #C2C2C0',
    padding: 10,
  },
};

const EMPLOYEE_CODE = ['employeeCode'];
const DETAILS = ['employeeName', 'department'];
const DATE_OF_JOINING = ['dateOfJoining'];

class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryBoys: [],
      users: [],
    };
  }

  componentDidMount() {
    this.handleDeliveryBoy();
    this.handleUser();
  }

   getLabel = (user, day) => {
     const { filters: { month, year } } = this.props;
     const { presentOn = [] } = user;
     if (moment(user.dateOfLeaving).format('YYYY-MM-DD') <= moment(`${day} ${month} ${year}`).format('YYYY-MM-DD')) {
       return <span style={{ color: Colors.blue, fontWeight: 'bold', width: 100 }}>L</span>;
     } else if (presentOn.indexOf(moment(`${day} ${month} ${year}`).format('YYYY-MM-DD')) !== -1) {
       return <span style={{ color: Colors.green, fontWeight: 'bold' }}>P</span>;
     } else if (moment(`${day} ${month} ${year}`).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
       return <span> </span>;
     }
     return <span style={{ color: Colors.red }}>A</span>;
   }

   handleChangePage = (event, page) => {
     const { onPageChange } = this.props;
     onPageChange(page);
   };

   handleDeliveryBoy = async (item) => {
     try {
       const { employeeCode = '' } = item;
       if (employeeCode) {
         const url = fetchDeliveryBoyById(employeeCode);
         const res = await apiCall.sendRequest('get', url);
         const { data = { } } = res;
         this.setState({
           openAddDeliveryBoy: true,
           deliveryBoys: data,
           item,
         });
       }
     } catch (e) {
       console.log('error');
     }
   }

   handleUser = async (item) => {
     try {
       const { employeeCode = '' } = item;
       if (employeeCode) {
         const url = fetchUserByIdPath(employeeCode);
         const res = await apiCall.sendRequest('get', url);
         const { data = {} } = res;
         this.setState({
           openAddUser: true,
           users: data,
           item,
         });
       }
     } catch (e) {
       console.log('error');
     }
   }

   handleClose = () => {
     this.setState({
       openAddDeliveryBoy: false,
       openAddUser: false,
     });
   }

  renderUserProfile = (item) => {
    const { designation = '' } = item;
    switch (designation) {
      case 'DELIVERY_BOY':
        this.handleDeliveryBoy(item);
        break;
      case 'SCM_USER':
        this.handleUser(item);
        break;
      default: break;
    }
  }


  renderUserAttendance = (user, days) => {
    const summaries = ['presentDays', 'absentDays'];
    const dateOfLeaving = ['dateOfLeaving', 'remarks'];
    return (
      <TableRow hover >
        {
           days.map(day => (
            <TableCell style={styles.tableCell}>
                {this.getLabel(user, day)}
            </TableCell>
          ))
        }
        {
          summaries.map(summary => (
            <TableCell style={styles.tableCell}>
            {user[summary]}
            </TableCell>
          ))
        }
        {
          dateOfLeaving.map(leaving => (
            <TableCell style={styles.tableCell}>
               {user[leaving] ? moment(user[leaving]).format('ll') : ''}
            </TableCell>
          ))
        }
      </TableRow>
    );
  }


  render() {
    const {
      attendaceList = [], totalElements = 0, totalDays, rowsPerPage = 20, page = 0, filters,
    } = this.props;
    const { month, year } = filters;
    const {
      deliveryBoys, users, openAddDeliveryBoy, openAddUser, item,
    } = this.state;
    const { designation } = item || {};

    const days = [];
    let attendanceColumns = [];
    for (let i = 0; i < totalDays; i += 1) {
      days.push(i + 1);
      attendanceColumns.push({
        id: i,
        numeric: false,
        label: i + 1,
      });
    }

    attendanceColumns = [...attendanceColumns, ...attendaceSummary];
    return (
      <div>
        { designation === 'DELIVERY_BOY' &&
         <DeliveryBoyProfile
            openAddDeliveryBoy={openAddDeliveryBoy}
            handleClose={this.handleClose}
            selectedDeliveryBoy={deliveryBoys}
            deliveryItem={item}
        />
        }
        { designation === 'SCM_USER' &&
        <UserProfile
          openAddUser={openAddUser}
          handleClose={this.handleClose}
          selectedUser={users}
          userItem={item}
        />
      }
        <div style={styles.div}>
        <div style={styles.paperLeft}>
          <h3 style={styles.titleCell}>Employee Details</h3>
          <Table aria-labelledby="tableTitle">
              <TableHeader columns={attendaceDetails} cellStyle={styles.header} />
                <TableBody cellStyle={styles.tableCell}>
                {
                  attendaceList.map(item => (
                    <TableRow hover >
                    {
                      EMPLOYEE_CODE.map(employee => (
                        <TableCell style={styles.tableCell}>
                          <Button color="secondary" size="small" onClick={() => { this.renderUserProfile(item); }}>{item[employee]}</Button>
                        </TableCell>
                      ))
                    }
                    {
                      DETAILS.map(detail => (
                        <TableCell style={styles.tableCell}>
                          {item[detail]}
                        </TableCell>
                      ))
                    }
                    {
                      DATE_OF_JOINING.map(doj => (
                        <TableCell style={styles.tableCell}>
                          {item[doj] ? moment(item[doj]).format('ll') : ''}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  ))
                }
              </TableBody>
          </Table>
               </div>
        <div style={styles.paperRight}>
          <h3> {month} - { year} Attendance Summary </h3>
          <Table aria-labelledby="tableTitle">
            <TableHeader columns={attendanceColumns} cellStyle={styles.header} />
            <TableBody cellStyle={styles.tableCell}>
             { attendaceList.map(user => (
                this.renderUserAttendance(user, days)
              ))
              }
            </TableBody>
          </Table>
        </div>
      </div>
      {attendaceList && attendaceList.length > 0 ?
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                rowsPerPage={rowsPerPage}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
              />
              : null
          }
      </div>
    );
  }
}

AttendanceList.propTypes = {
  attendaceList: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  totalDays: PropTypes.number,
  totalElements: PropTypes.number,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
};

export default AttendanceList;
