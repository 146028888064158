import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import apiCall, { getCustomerIdPath, getAddressesByQuerypath } from '../../../api/NetworkHandler';


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 4,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  searchField: {
    flex: 4,
    padding: 10,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: '#f50057',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class PlanSearchBar extends React.Component {
  constructor(props) {
    const { customerId = '', page = 0 } = props;
    super(props);
    this.state = {
      query: '',
      customerId,
      page,
    };
  }


  componentDidMount() {
    const { customerId } = this.state;
    if (customerId) {
      this.getAddressesByCustomerId(customerId);
    } else {
      this.getAllAddresses();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { page: prevPage } = prevProps;
    const { page: currentPage } = this.props;
    if (prevPage !== currentPage) {
      this.onSearch();
    }
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  onSearch = () => {
    const { query = '', customerId } = this.state;

    if (customerId && customerId !== '') {
      this.getAddressesByCustomerId(customerId);
    } else if (query && query !== '') {
      this.getAddressesByQuery(query);
    } else {
      this.getAllAddresses();
    }
  }

  // renderVendors = () => this.state.vendors.map(vendor => <MenuItem key={vendor.id} value={vendor}>{vendor.name}</MenuItem>)

  onChangeOfQuery = (event) => {
    const { target = {} } = event;
    const { value = '' } = target;
    this.setState(prevState => ({
      query: value,
    }));
  }

  onCustomerIDChange = (event) => {
    const { target = {} } = event;
    const { name = '', value = '' } = target;
    this.setState(prevState => ({
      customerId: value,
    }));
  }

  getAddressesByQuery = (query) => {
    const addressByQueryURL = getAddressesByQuerypath(query);
    this.getData(addressByQueryURL, true);
  }

  getAllAddresses = () => {
    const { page = 0 } = this.props;
    const allAddressURL = `api/v1/customer-address?page=${page}&size=20`;
    this.getData(allAddressURL, false);
  }

  getAddressesByCustomerId = (customerId) => {
    const { onResultsRetrieved } = this.props;
    const URL = getCustomerIdPath(customerId);
    apiCall.sendRequest('get', URL)
      .then((response) => {
        const { data } = response;
        onResultsRetrieved(data);
      });
  }

  getData = (url, searchResult) => {
    const { onResultsRetrieved } = this.props;
    apiCall.sendRequest('get', url)
      .then((response) => {
        const { data } = response;
        const { _embedded = {}, page = {} } = data;
        const { totalElements = 0 } = page;
        const customerAddresses = _embedded['customer-address'];
        onResultsRetrieved(customerAddresses, totalElements, searchResult);
      });
  }

  clearAll = () => {
    const { clearAll } = this.props;
    this.setState({
      query: '',
      customerId: '',
    }, () => {
      clearAll();
    });
  }

  render() {
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
              Customer Plans
          </Typography>
        </div>
        <div style={styles.searchBar}>
          <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                fullWidth
                name="query"
                onChange={this.onChangeOfQuery}
                value={this.state.query}
                onKeyPress={this.onKeyPress}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
              />
            </div>
            <div style={styles.searchField}>
              <TextField
                id="cId"
                type="number"
                name="cId"
                label="Customer Id"
                onChange={this.onCustomerIDChange}
                onKeyPress={this.onKeyPress}
                value={this.state.customerId}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={styles.clear}>
              <Button color="secondary" variant="contained" onClick={this.onSearch}>
                    Search
              </Button>
            </div>
            <div style={styles.clear}>
              <Button color="secondary" onClick={this.clearAll}>
                    Clear All
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  COStatuses: state.Status.costatuses,
});

export default connect(mapStateToProps, null)(PlanSearchBar);
