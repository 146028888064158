import React from 'react';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver/FileSaver';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer
} from '@material-ui/core';
import apiCall, {
  findProductCatalogsPath,
  catalogPriceList,
  catalogPubilshPath,
  catalogImmediatePubilshPath,
  publishAllCatalog,
  getProductCatalogByFilter,
  downloadMOQStats
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import TableHeader from '../../Commons/components/TableHeader';
import SearchBar from '../../Commons/containers/SearchBar';
import CatalogSearch from '../components/CatalogSearch';
import CreateCatalogForm from '../components/CatalogForm'
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import utils from '../../../utils/queryBuilder';
import { message, Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce, filter } from 'lodash';
import moment from 'moment';

const canView = utils.isCurrentUserAuthorizedToView;


const columnData = [
  {
    id: 'Catalog', numeric: false, disablePadding: true, label: 'Catalog Name',
  },
  {
    id: 'Version', numeric: false, disablePadding: true, label: 'Version',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
  {
    id: 'PriceListDownload', numeric: false, disablePadding: true, label: 'Update Product Items',
  },
  {
    id: 'Status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'Publish Action', numeric: false, disablePadding: true, label: 'Publish Action',
  },
];

var versionDict = {
  "catalogRequestId": 0
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadPopup: false,
      page: 0,
      count: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      data: [],
      selectedFile: null,
      selectedProductCatalog: {},

      filters: {
        query: '',
      },
      openCreateCatalogForm: false,
      errorMessage: '',
      disablePublishAll: false,
      loading: false,
      disableMOQStats: false
    };
    this.inputCSVFileRef = []; // React.createRef();
  }

  componentDidMount() {
    this.fetchAllCatalogs();
  }

  onSearch = () => {
    this.fetchAllCatalogs();
  }

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState
    });
  };

  fetchAllCatalogs = debounce(async () => {
    const { filters, page, rowsPerPage: size } = this.state;
    this.toggleLoading(true);
    try {
      versionDict.catalogRequestId += 1;
      let prevRequestId = versionDict.catalogRequestId;
      const response = await apiCall.sendRequest('get', getProductCatalogByFilter(filters, page, size));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (prevRequestId === versionDict.catalogRequestId) {
        this.setState({
          data: content,
          count: totalElements,
        });
      }
    } catch (err) {
      this.setState({
        data: [],
        count: 0
      });
    }
    this.toggleLoading(false);
  }, 50)

  handleQueryChange = (event) => {
    const query = event.target.value;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query,
      },
    }));
  }

  handleChange = (filters, refresh = false) => {
    const { page } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.fetchAllCatalogs();
      }
    });
  }


  editCatalog = (id) => {
    const { history = {} } = this.props;
    history.push(`/edit-product-catalog/${id}`);
  }

  downloadPriceList = (id, filename) => {
    apiCall.sendRequest('get', catalogPriceList(id), null, { Accept: 'text/csv' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, filename);
      })
      .catch((e) => {
        // error handling
        const { message } = e.response.data;
        this.setState({ errorMessage: message });
      });
  }

  updateSelectedFile = (event, id) => {

    this.setState({
      selectedFile: event.target.files[0],
    }, () => {
      this.uploadPriceList(id);
      this.inputCSVFileRef[id].value = '';
    });
  }

  uploadPriceList = async (id) => {
    try {
      const formData = new FormData();
      formData.append('file', this.state.selectedFile);
      await apiCall.sendRequest('post', `/api/v1/product-catalogs/${id}/upload-prices`, formData);
      this.setState({ errorMessage: '' });
      const { page } = this.state;
      this.fetchAllCatalogs();
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }

  changeCatalogStatus = async (id, status) => {
    await apiCall.sendRequest('patch', `/api/v1/product-catalogs/${id}`, { status });
    this.fetchAllCatalogs();
  }

  updateCatalog = async (id, name, value) => {
    await apiCall.sendRequest('patch', `/api/v1/product-catalogs/${id}`, { [name]: value });
    this.fetchAllCatalogs();
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => this.fetchAllCatalogs());
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({
      rowsPerPage
    }, () => this.fetchAllCatalogs());
  };

  handleCreateCatalog = (open = false) => {
    this.setState({ openCreateCatalogForm: open })
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    this.setState({
      selectedFile: file,
    });
  }

  handleBulkUpload = async () => {
    const { selectedProductCatalog, selectedFile } = this.state;
    const { id: productcatalogId, name: productCatalogName } = selectedProductCatalog;
    const regions = selectedProductCatalog.regions[0].id;

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('name', productCatalogName);
      formData.append('regions', regions);
      await apiCall.sendRequest('put', `/api/v1/product-catalogs/${productcatalogId}/upload`, formData);
      this.setState({
        open: false,
      });
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }

  handlePublish = async (id) => {
    try {
      await apiCall.sendRequest('post', catalogPubilshPath(id));
      const { page } = this.state;
      this.fetchAllCatalogs();
      this.setState({ errorMessage: '' });
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }

  handleImmediatePublish = async (id) => {
    try {
      await apiCall.sendRequest('post', catalogImmediatePubilshPath(id));
      const { page } = this.state;
      this.setState({
        errorMessage: '',
      });
      message.info("Please refresh after some time to get updated catalog..!");
      this.fetchAllCatalogs();
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
  }

  togglePublishAll = (buttonState) => {
    this.setState({
      disablePublishAll: buttonState
    });
  };

  handlePublishAll = debounce(async () => {
    this.togglePublishAll(true);
    try {
      await apiCall.sendRequest('post', publishAllCatalog);
    } catch (err) {

    }
    this.togglePublishAll(false);
  }, 500);

  toggleDownloadMOQButton = (buttonState) => {
    this.setState({
      disableMOQStats: buttonState,
    });
  };

  handleDownloadMOQStats = debounce(async () => {
    this.toggleLoading(true);
    this.toggleDownloadMOQButton(true);
    try {
      const response = await apiCall.sendRequest('get', downloadMOQStats, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `MOQ_Stats_${moment().format("YYYY-MM-DD")}`);
    } catch (err) {
      
    }
    this.toggleLoading(false);
    this.toggleDownloadMOQButton(false);
  }, 500);


  render() {
    const {
      data,
      page,
      count,
      rowsPerPage,
      uploadPopup,
      filters,
      rowsPerPageOptions,
      openCreateCatalogForm,
      errorMessage,
      disablePublishAll,
      loading,
      disableMOQStats
    } = this.state;
    return (
      <div>
        <div>
          <NavBar />
          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} tip="Loading...">
            <SearchBar title="Product Catalog" filters={filters} onChange={this.handleChange}>
              <CatalogSearch
                filters={filters}
                onChange={this.handleQueryChange}
                onSearch={this.onSearch}
                onCreate={this.handleCreateCatalog}
                onPublishAll={this.handlePublishAll}
                disablePublishAll={disablePublishAll}
                onDownloadMOQStats={this.handleDownloadMOQStats}
                disableMOQStats={disableMOQStats}
              />
            </SearchBar>
            <Drawer anchor="right" open={openCreateCatalogForm} onClose={() => { this.handleCreateCatalog() }}>
              <CreateCatalogForm
                onClose={this.handleCreateCatalog}
                onRefresh={this.fetchAllCatalogs}
              />
            </Drawer>

            <Table aria-labelledby="tableTitle" style={{ width: '85%', marginLeft: '10%' }}>
              <TableHeader columns={columnData} />
              <TableBody>
                {data.map(n => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={n.id}
                  >
                    <TableCell style={{ color: Colors.default }} component="th" scope="row" padding="none">{n.name}</TableCell>

                    <TableCell component="th" scope="row" padding="none">{n.catalogVersion}</TableCell>
                    <TableCell style={{ color: Colors.primary }} component="th" scope="row" padding="none">
                      <span>
                        <Button variant="outlined" color="primary" onClick={() => this.editCatalog(n.id)}> EDIT </Button>
                      </span>
                      {(n.status === 'INACTIVE' &&
                        <span>&emsp;|&emsp;
                          <Popconfirm
                            title="Are you sure to activate this catalog?"
                            onConfirm={() => this.changeCatalogStatus(n.id, 'EDITING')}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button variant="outlined" color="primary"> ACTIVATE </Button>
                          </Popconfirm>
                        </span>)}
                      {(n.status !== 'INACTIVE' && n.status !== 'NEW' &&
                        <span>&emsp;|&emsp;
                          <Popconfirm
                            title="Are you sure to deactivate this catalog?"
                            onConfirm={() => this.changeCatalogStatus(n.id, 'INACTIVE')}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button variant="outlined" color="inherit"> DEACTIVATE </Button>
                          </Popconfirm>
                        </span>)}
                    </TableCell>
                    <TableCell style={{ color: Colors.primary }} component="th" scope="row" padding="none">
                      <span>
                        <Button variant="raised" color="default" onClick={() => this.downloadPriceList(n.id, "catalog-" + n.name + ".csv")}> Download </Button>
                      </span>&emsp; | &emsp;
                      <span>
                        <input ref={ref => this.inputCSVFileRef[n.id] = ref} type="file" accept=".csv" style={{ display: 'none' }} onChange={(e) => { this.updateSelectedFile(e, n.id); }} />
                        <Button variant="outlined" color="primary" onClick={() => this.inputCSVFileRef[n.id].click()}>
                          Upload
                        </Button>
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Typography color={n.status === "PUBLISH_FAILED" ? "error" : "inherit"} >
                        {n.status}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {n.status === 'NEW' || n.status === 'EDITING' ?
                        <Popconfirm
                          title="Are you sure to publish this catalog?"
                          onConfirm={() => this.handlePublish(n.id)}
                          onCancel={() => { }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button variant="contained" color="primary"> Publish </Button>
                        </Popconfirm>
                        :
                        <Button variant="contained" color="primary" disabled> Publish </Button>
                      }
                      {canView(["ADMIN", "CATALOG_MANAGER"]) && <span>
                        &emsp; | &emsp;
                        <Popconfirm
                          title="Are you sure to process this catalog?"
                          onConfirm={() => this.handleImmediatePublish(n.id)}
                          onCancel={() => { }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button variant="contained" color="secondary" disabled={n.status === "PUBLISHED"}> Process </Button>
                        </Popconfirm>
                      </span>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Spin>
        </div>
        <Dialog
          open={this.state.open}
          onClose={() => { this.setState({ open: false }); }}
        >
          <DialogTitle><Typography variant="title" color="secondary"> Upload Catalog </Typography></DialogTitle>
          <DialogContent style={{ marginTop: '2em' }}>
            <div>
              <input type="file" onChange={this.handleFileUpload} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ open: false }); }} color="secondary">
              Cancel
            </Button>

            <Button variant="contained" onClick={() => { this.handleBulkUpload(); }} color="secondary">
              Upload
            </Button>

          </DialogActions>
        </Dialog>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    );
  }
}

export default withRouter(App);

