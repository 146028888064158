import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  InputAdornment,
  Typography,
  Grid
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RegionSelector from '../../../components/Region/Selector';
import withStyles from '@material-ui/core/styles/withStyles';
import { MenuItem } from 'material-ui';
import Select from '@material-ui/core/Select';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingBottom: 6,
  },
  title: {
    height: '2em',
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '0.8em',
  },
}

function HomeOfferSearch(props) {

  const {
    onChange,
    searchTerm,
    selectedRegion,
    onRegionChange,
    onAddNew,
    clear,
    onSearch,
    selectedStatus,
    handleChangeStatus,
  } = props;

  return (
    <div style={styles.container}>
      <Grid container spacing={16}>
        <Grid item xs={12} md={2} >
          <div style={styles.title}>
            <Typography variant="title" color="inherit">Home Offers</Typography>
          </div>
        </Grid>

        <Grid item xs={12} md={8} >
          <Grid container spacing={16}>
            <Grid item xs={4} md={4} >
              <TextField
                color="secondary"
                value={searchTerm || ''}
                label="Search (by title)"
                onChange={onChange}
                fullWidth
                InputProps={{ startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>) }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Typography variant="caption"> Region </Typography>
              <RegionSelector selected={selectedRegion} onSelect={onRegionChange} isClearable={false} name="region" />
            </Grid>
            <Grid item xs={2} md={2}>
              <Typography variant="caption"> Status </Typography>
              <Select
                name='active'
                value={selectedStatus}
                onChange={handleChangeStatus}
                fullWidth
              >
                <MenuItem value='All' key='All'>All</MenuItem>
                <MenuItem value={true} key={'Active'}>Active</MenuItem>
                <MenuItem value={false} key={'Inactive'}>Inactive</MenuItem>
              </Select></Grid>
            <Grid item xs={2} md={2} style={{ textAlign: "right" }}>
              <Button color="inherit" variant="outlined" onClick={onSearch}>Search</Button>
            </Grid>
            <Grid item xs={2} md={2} style={{ textAlign: "left" }}>
              <Button color="secondary" variant="outlined" onClick={clear}>Clear</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2} >
          <Grid container spacing={32}>

            <Grid item xs={8} md={12} style={{ textAlign: "right" }}>
              <Button color="primary" variant="outlined"
                onClick={() => { onAddNew(null) }}
              ><AddIcon /> Create Home Offer</Button>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}

HomeOfferSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  selectedRegion: PropTypes.object,
  onRegionChange: PropTypes.func.isRequired,
  onAddNew: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string,
  handleChangeStatus: PropTypes.func.isRequired,
}

HomeOfferSearch.defaultProps = {
  searchTerm: '',
  selectedStatus: 'All',
}

export default withStyles(styles)(HomeOfferSearch);