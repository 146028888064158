import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import configureStore from './src/store/configureStore';
import Root from './src/containers/Root';
import './public/styles/base.scss';
import Layout from './src/layout/Layout';
import * as Sentry from '@sentry/browser';
import Constant from './src/utils/Constant';
import ForceRefresh from './src/components/ForceRefresh/ForceRefresh';

// eslint-disable-next-line
import 'typeface-roboto';

// enabled in production
if (!Constant.isDev) {
  Sentry.init({ dsn: 'https://814702ad8ef841f08de472c8ad43725e@sentry.freshtohome.com/6' });
}

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <div>
        <ToastContainer
          position="top-right"
          type="default"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
        <Layout>
          <React.Fragment>
            <ForceRefresh />
            <Root />
          </React.Fragment>
        </Layout>
      </div>
    </PersistGate>
  </Provider>,

  document.getElementById('root'),
);

export { store };
