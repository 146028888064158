import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Modal, Table as AntdTable, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const totMarginColumns = [
  {title: "Vendor Product [ID]", dataIndex: "id", width: 80},
  {title: "Item Name", dataIndex: "name", width: 220, ellipsis: true},
  {title: "Max Allowed CP", dataIndex: "expectedPrice", width: 80},
  {title: "Remarks", dataIndex: "remarks", width: 120}
];

function PoTotMarginModal(props) {
  const { open, onSavePO, onCloseTotMarginModal, modalButtonText, data, getCollapseHeaderTitle, onDownloadMissingItem } = props;

  return (
    <Modal
      title="TOT Margin Error Logs"
      visible={open}
      onOk={onSavePO}
      cancelText="Close"
      onCancel={onCloseTotMarginModal}
      okText={modalButtonText}
      width={window.innerWidth * 0.8}
    >
      <div style={{ maxHeight: window.innerHeight * 0.6, overflow: "auto" }}>
        <Collapse>
          {Object.keys(data).map((el, index) => {
            const totMarginColumnData = data[el].length > 0 ? data[el].map(el => {return {...el, key: el.id}}) : [];
            return (
              <Panel header={getCollapseHeaderTitle(el)} key={index}>
                {(el === 'missing' && data['missing'].length > 0) && <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: '10px' }}>
                  <span style={{ paddingRight: '10px', color: 'red' }}>**Please download this csv file and forward it to procurement head to update TOT margin.</span>
                  <Button type="primary" icon={<DownloadOutlined />} size="small" onClick={onDownloadMissingItem} >Download</Button>
                </div>}
                <div>
                  <AntdTable columns={totMarginColumns} dataSource={totMarginColumnData} size="small" sticky />
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </Modal>
  );
}

PoTotMarginModal.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  // selected: PropTypes.instanceOf(Object),
  /**
   * Triggered on option selection
   */
  // onSelect: PropTypes.func.isRequired,

};

PoTotMarginModal.defaultProps = {
  // selected: null,
};

export default PoTotMarginModal;
