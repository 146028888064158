import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Select, FormHelperText, MenuItem, TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';
import apiCall, { getWareHouse, routesByHubIdPath } from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import HubSelector from '../../../components/Hub/Selector';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  searchContainer: {
    flex: 7,
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchField: {
    width: 200,
    marginRight: 40,
  },
  buttonField: {
    flex: 1,
    lineHeight: 'normal',
    marginBottom: 20,
  },
  or: {
    flex: 1,
  },
  clear: {
    flex: 1,
    color: Colors.primary,
    marginLeft: '30px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginTop: 4,
  },
};

let domKey = false;
class SegregationSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      routeList: [],
      warehouses: [],
      selectedHub: null,
    };
  }

  componentWillMount() {
    document.addEventListener('keypress', this.handleKeyPress, false);
  }

  componentDidMount() {
    this.fetchWarehouses();
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress, false);
  }

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }

  fetchWarehouses = async () => {
    try {
      const response = await apiCall.sendRequest('get', getWareHouse);
      const { data: { _embedded = {} } } = response;
      this.setState({
        warehouses: _embedded.warehouse,
      });
    } catch (e) {
      this.setState({
        warehouses: [],
      });
    }
  }

  handleRoutesSearch = async () => {
    try {
      const { filters: { hubId = '' } } = this.state;
      if (hubId && hubId !== '') {
        const response = await apiCall.sendRequest('get', routesByHubIdPath(hubId));
        const { data: { _embedded = {} } } = response;
        this.setState({
          routeList: _embedded['static-routes'],
        });
      }
    } catch (e) {
      this.setState({
        routeList: [],
      });
    }
  }

  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {
      startDate: '',
      endDate: '',
      hubId: '',
      routeId: '',
      warehouseId: '',
      status: '',
    };
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
      selectedHub: null,
    }, () => {
      domKey = !domKey;
      onButtonClick('search', 0);
    });
  }

  handleChange = (value, key) => {
    let { filters, selectedHub } = this.state;
    const { onFilterChange } = this.props;
    filters = {
      ...filters,
      [key]: value,
    };
    onFilterChange(key, value);
    if(key == 'warehouseId'){
      selectedHub = null;
      filters.warehouseId = null;
    }
    this.setState({
      filters,
      selectedHub
    },()=>{
      if(key == 'warehouseId'){
        this.setState({
          filters: {
            ...filters,
            [key]: value,
          }
        })
      }
    });
  }

  onHubChange = (hub) => {
    this.setState({
      selectedHub: hub
    }, () => { this.handleChange(hub ? hub.id : null, 'hubId') })
  }

  render() {
    const {
      onButtonClick,
      management = false,
      hub = false,
      route = false,
      segregationStatuses = {},
    } = this.props;

    const {
      HUB_STARTED, HUB_COMPLETED, HUB_NEW, ...routeStatus
    } = segregationStatuses;

    const {
      ROUTE_COMPLETED, ROUTE_STARTED, ROUTE_NEW, ...hubStatus
    } = segregationStatuses;

    const {
      filters, warehouses, routeList, selectedHub
    } = this.state;

    const {
      startDate,
      routeId = '',
      warehouseId,
      status = '',
      deliveryDate,
    } = filters;

    let routeName = '';
    if (routeId && routeId !== '' && routeName === '') {
      const routeData = routeList.find(rt => rt.id === (+routeId));
      routeName = routeData ? routeData.name : '';
    }
    return (
      <div style={styles.contentContainer} key={domKey}>
        <div style={styles.searchContainer}>
          {!management &&
            <div style={styles.searchField}>
              <TextField
                id="date"
                fullWidth
                label="Delivery Date"
                type="date"
                value={startDate}
                defaultValue={startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.handleChange(e.target.value, 'startDate')}
              />
            </div>}
          {
            management &&
            <div style={styles.searchField}>
              <TextField
                id="deliveryDate"
                fullWidth
                label="Delivery Date"
                type="date"
                value={deliveryDate || ''}
                defaultValue={deliveryDate}
                onChange={e => this.handleChange(e.target.value, 'deliveryDate')}
              />
            </div>
          }
          {!management &&
          <div style={styles.searchField}>
            <FormHelperText style={styles.label}>WareHouse</FormHelperText>
            <Select
              name="warehouseId"
              fullWidth
              onChange={e => this.handleChange(e.target.value, 'warehouseId')}
              value={(+warehouseId) || ''}
            >
              {warehouses.map(warehouse => (
                <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                      ))}

            </Select>
          </div>}

          {!management &&
            <div style={styles.searchField}>
              <div style={{ width: '100%' }}>
                <FormHelperText style={styles.label}>Hub</FormHelperText>
                {warehouseId  && <HubSelector
                  warehouseId={warehouseId}
                  onSelect={this.onHubChange}
                  selected={selectedHub}
                />}
                {!warehouseId && 
                <TextField 
                  value="Select warehouse first"
                  disabled
                />}
              </div>
            </div>
          }
          {!management && !hub &&
            <div style={styles.searchField}>
              <div style={{ marginTop: 16, width: '100%' }}>
                <SearchWithSuggestions
                  key={routeName}
                  searchQuery={routeName}
                  placeholder="Search by Route"
                  suggestions={routeList}
                  onChange={this.handleRoutesSearch}
                  onSelect={selected => this.handleChange(selected.id, 'routeId')}
                />
              </div>
            </div>
          }
          {!management && !route &&
          <div style={styles.searchField}>
            <Select
              style={{ marginTop: 16 }}
              native
              fullWidth
              label="Status"
              value={status}
              onChange={e => this.handleChange(e.target.value, 'status')}
            >
              <option value="">Select Status</option>
              {Object.keys(hubStatus).map(segregation => (
                <option value={segregation}>{segregation}</option>
                  ))}
            </Select>
          </div>
        }
        {!management && !hub &&
          <div style={styles.searchField}>
            <Select
              style={{ marginTop: 16 }}
              native
              fullWidth
              label="Status"
              value={status}
              onChange={e => this.handleChange(e.target.value, 'status')}
            >
              <option value="">Select Status</option>
              {Object.keys(routeStatus).map(segregation => (
                <option value={segregation}>{segregation}</option>
                  ))}
            </Select>
          </div>
        }
        </div>
        <div style={styles.buttonContainer}>
          <div style={{ flex: 1 }}>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onButtonClick('search', 0)}
              >
                      Search
              </Button>
            </div>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleClear}
              >
                      Clear All
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SegregationSearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.func,
  management: PropTypes.bool,
  hub: PropTypes.bool,
  route: PropTypes.bool,
  segregationStatuses: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { segregationStatuses } = Status;
  return {
    segregationStatuses,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null,
)(SegregationSearch));

