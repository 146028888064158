import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Button } from '@material-ui/core';
import RegionFilter from './ProductRegionFilter';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import CategoryFilter from './ProductCategoryFilter';
import StatusFilter from './ProductStatusFilter';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';

class ProductSearch extends Component {

  render() {
    const {
      searchQuery, onSearch, regions,
      regionId, onChangeFilter, onChange,
      clearSearch, categories, onChangeCategory,
      categoryId, status, onChangeStatus
    } = this.props;
    return (
        <Grid fluid style={{width: '100%'}}>
          <Row lg={12}>
            <ColMod lg={3}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                fullWidth
                value={searchQuery}
                onChange={onChange}
                onKeyPress={(event) => { if (event.key === "Enter") { onSearch() } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                helperText="Search for Brand, Product and Category"
              />
            </ColMod>
            <ColMod lg={3}>
              <RegionFilter
                regions={regions}
                regionId={regionId}
                onChangeFilter={onChangeFilter}
              />
            </ColMod>
            <ColMod lg={2}>
              <CategoryFilter
                categories={categories}
                categoryId={categoryId}
                onChangeCategory={onChangeCategory}
              />
            </ColMod>
            <ColMod lg={2}>
              <StatusFilter
                status={status}
                onChangeStatus={onChangeStatus}
              />
            </ColMod>
            <ColMod lg={2}>
              <Button color="secondary" onClick={clearSearch} style={{ marginTop: '15px' }}>
                Clear All
            </Button>
            </ColMod>
          </Row>
        </Grid>
    );
  }
}

ProductSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ProductSearch;
