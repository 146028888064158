import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Typography,
  Paper,
} from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';
import HistoryIcon from '@material-ui/icons/History';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';



const styles = {
  card: {
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid",
    borderRadius: "10px"
  },
  reasonText: {
    marginBottom: "0",
    border: "1px solid",
    fontFamily: "serif",
    fontSize: "12px",
    padding: "2px",
    borderRadius: "5px",
    background: "antiquewhite"
  },
  generalFont: {
    fontSize: "13px"
  },
  priceText: {
    fontWeight: "bold",
    color: "darkgreen"
  },
  deliveryData: {
    marginBottom: "5px",
    borderBottom: "2px solid",
    paddingBottom: "5px",
    borderRadius: "5px"
  },
  sectionTitle: {
    backgroundColor: "dimgrey",
    color: "white",
    textAlign: "center",
    padding: "0 10px",
    borderRadius: "2px"
  },
  paperLeftDiv: {
    float: "left",
    textAlign: "right",
    width: "50%"
  },
  paperRightDiv: {
    textAlign: "left"
  },
  offerButton: {
    border: "none",
    borderRadius: "5px",
    padding: "0 5px",
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue",
    background: "transparent",
  }
}


class LineItemSummary extends Component {
  constructor(props) {
    super(props);
  }

  handleClickProductItemHistory = () => {
    console.log("Product item id clicked!!");
    //TODO: Need to implement later.
  };

  renderDeliveryBoyComlaints = (generalFontForTitle, generalFontForValue) => {
    const { deliveryData = {} } = this.props;
    const { deliveryLogs = [], deliveryRoute = {} } = deliveryData;
    console.log(deliveryRoute);
    const { deliveryBoyName, deliveryBoyMobile, supervisorName, supervisorMobile } = deliveryRoute;
    if (deliveryLogs.length > 0) {
      return deliveryLogs.map((el, index) => {
        return (
          <div style={index !== (deliveryData.length - 1) ? styles.deliveryData : {}}>
            <div style={styles.paperLeftDiv}>
              <p style={generalFontForTitle}>Reason: &nbsp;</p>
              <p style={generalFontForTitle}>Reported On: &nbsp;</p>
              <p style={generalFontForTitle}>Reported Qty: &nbsp;</p>
              {el.imageUrl && <p style={generalFontForTitle}>Faulty Image: &nbsp;</p>}
            </div>
            <div style={styles.paperRightDiv}>
              <p style={generalFontForValue}>
                <Tag color="error">{el.remarks}</Tag>
              </p>
              <p style={generalFontForValue}>{moment(el.timeCreated).format('lll')}</p>
              <p style={generalFontForValue}>{el.quantity}</p>
              {el.imageUrl &&
                <p style={generalFontForValue}>
                  <a href={el.imageUrl}>Click to open</a>
                </p>
              }
            </div>
          </div>
        );
      });
    } else {
      return (
        <div>
          <div style={styles.paperLeftDiv}>
            <p style={generalFontForTitle}>Delivered By: &nbsp;</p>
            <p style={generalFontForTitle}>Delivery Boy Mobile: &nbsp;</p>
            <p style={generalFontForTitle}>Supervisor Name: &nbsp;</p>
            <p style={generalFontForTitle}>Supervisor Mobile: &nbsp;</p>
          </div>
          <div style={styles.paperRightDiv}>
            <p style={generalFontForValue}>{deliveryBoyName}</p>
            <p style={generalFontForValue}>{deliveryBoyMobile}</p>
            <p style={generalFontForValue}>{supervisorName}</p>
            <p style={generalFontForValue}>{supervisorMobile}</p>
          </div>
        </div>
      );
    }
  };

  renderProductItemType = (productItemType) => {
    switch (productItemType) {
      case "SAMPLE": return <Lozenge appearance="moved" isBold><span style={{ color: "white" }}>{productItemType}</span></Lozenge>;
      case "FREEBIE": return <Lozenge appearance="removed" isBold>{productItemType}</Lozenge>;
      default: return <Lozenge appearance="success" isBold>PAID</Lozenge>;
    };
  };

  renderDeliveryStatus = (status) => {
    switch (status) {
      case "DELIVERED": return <Tag icon={<CheckCircleOutlined />} color="#81C784">{status}</Tag>;
      case "NOT_DELIVERED": return <Tag icon={<CloseCircleOutlined />} color="error">{status}</Tag>;
      default: return <Tag icon={<SyncOutlined spin />} color="processing">{status}</Tag>;
    }
  };

  render() {
    const {
      data = {},
      open,
      currency,
      refundData,
      openRefundForm,
      deliveryData,
      onClose,
      selectedOrderId,
      onOpenOfferData
    } = this.props;
    const {
      productItemId,
      productItemType,
      productName,
      timeCreated,
      timeUpdated,
      packaging,
      category,
      unitMeasure,
      uom = {},
      mrp,
      discount,
      unitPrice,
      tax,
      offerCode,
      quantity,
      deliveredQuantity,
      status,
      comment,
      complaintReason,
      id
    } = data;

    const formattedComment = JSON.parse(comment);
    const generalFontForTitle = { ...styles.generalFont, marginBottom: 0, paddingLeft: "5px", fontWeight: "bold" };
    const generalFontForValue = { ...styles.generalFont, marginBottom: 0, paddingLeft: "5px" };

    return (
      <div style={{ width: window.innerWidth * 0.25, float: "left", height: window.innerHeight * 0.65, paddingRight: "10px", overflow: "auto" }}>
        <button style={{ float: "right", border: "none", borderRadius: "15px", backgroundColor: "#FA8A66" }} onClick={onClose}>X</button>
        <Paper style={{ padding: "10px", marginBottom: "5px", backgroundColor: "#F1F1EE" }}>
          <Typography variant="body2" color="primary">
            {productName}
          </Typography>
          <span style={{ display: 'flex' }}>
            <b style={styles.generalFont}>Product Item ID: &nbsp;</b>
            <p style={{ ...styles.generalFont, marginBottom: 0, paddingLeft: "5px", ...styles.priceText, color: "#E91E63" }}>{productItemId}</p>
            {/* <Button onClick={this.handleClickProductItemHistory} color="primary" style={{ padding: 0, minHeight: 0 }}>
              <HistoryIcon style={{ fontSize: "16px" }} />
            </Button> */}
          </span>
          {/* <small style={{ paddingLeft: "5px" }}>** Click to see product item history.</small> */}
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Basic Info</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px" }}>
          <div style={styles.paperLeftDiv}>
            <p style={generalFontForTitle}>Order ID: &nbsp;</p>
            <p style={generalFontForTitle}>Created On: &nbsp;</p>
            <p style={generalFontForTitle}>Last Updated On: &nbsp;</p>
            <p style={generalFontForTitle}>Packaging: &nbsp;</p>
            <p style={generalFontForTitle}>Category: &nbsp;</p>
            <p style={generalFontForTitle}>Unit Measure: &nbsp;</p>
            <p style={generalFontForTitle}>UOM Code: &nbsp;</p>
          </div>
          <div style={styles.paperRightDiv}>
            <p style={generalFontForValue}>{selectedOrderId}</p>
            <p style={generalFontForValue}>{moment(timeCreated).format('lll')}</p>
            <p style={generalFontForValue}>{moment(timeUpdated).format('lll')}</p>
            <p style={generalFontForValue}>{packaging}</p>
            <p style={generalFontForValue}>{category}</p>
            <p style={generalFontForValue}>{unitMeasure}</p>
            <p style={generalFontForValue}>{uom.code}</p>
          </div>
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Tax & Pricing Info</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px", backgroundColor: "#F1F1EE" }}>
          <div style={styles.paperLeftDiv}>
            <p style={generalFontForTitle}>MRP: &nbsp;</p>
            <p style={generalFontForTitle}>Discount: &nbsp;</p>
            <p style={generalFontForTitle}>Selling Price: &nbsp;</p>
            <p style={generalFontForTitle}>Tax: &nbsp;</p>
            <p style={generalFontForTitle}>Offer Code: &nbsp;</p>
            <p style={generalFontForTitle}>Item Type: &nbsp;</p>
          </div>
          <div style={styles.paperRightDiv}>
            <p style={{ ...generalFontForValue, ...styles.priceText, color: "salmon" }}>{currency} {mrp.toFixed(2)}</p>
            <p style={{ ...generalFontForValue, ...styles.priceText }}>{discount} %</p>
            <p style={{ ...generalFontForValue, ...styles.priceText }}>{currency} {unitPrice.toFixed(2)}</p>
            <p style={{ ...generalFontForValue, ...styles.priceText, color: "saddlebrown" }}>{tax} %</p>
            <p style={generalFontForValue}>
              {offerCode ?
                <button style={styles.offerButton} onClick={() => onOpenOfferData(id)}>
                  {offerCode}
                </button>
                :
                'No offer applied for this item'}
            </p>
            <div style={{ height: "15px" }}>
              {this.renderProductItemType(productItemType)}
            </div>
          </div>
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Quantity & Status</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px" }}>
          <div style={styles.paperLeftDiv}>
            <p style={generalFontForTitle}>Ordered: &nbsp;</p>
            <p style={generalFontForTitle}>Delivered: &nbsp;</p>
            <p style={generalFontForTitle}>Status: &nbsp;</p>
          </div>
          <div style={styles.paperRightDiv}>
            <p style={generalFontForValue}>{quantity}</p>
            <p style={generalFontForValue}>{deliveredQuantity}</p>
            <div>
              {this.renderDeliveryStatus(status)}
            </div>
          </div>
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Customer Complaints</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px", backgroundColor: "#F1F1EE" }}>
          {formattedComment ?
            <div>
              <div style={styles.paperLeftDiv}>
                <p style={generalFontForTitle}>Ticket ID: &nbsp;</p>
                <p style={generalFontForTitle}>Reason: &nbsp;</p>
                <p style={generalFontForTitle}>Raised On: &nbsp;</p>
              </div>
              <div style={styles.paperRightDiv}>
                <p style={generalFontForValue}>{formattedComment.ticketId}</p>
                <p style={generalFontForValue}>
                  <Tag color="error">{formattedComment.orderItemComplaint.complaintReason}</Tag>
                </p>
                <p style={generalFontForValue}>{moment(formattedComment.timeCreated).format('lll')}</p>
              </div>
            </div>
            :
            <div style={{ color: "#117CF2", textAlign: "center" }}>No complaint raised by the customer for this item.</div>
          }
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Delivery Info</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px" }}>
          {!isEmpty(deliveryData) ?
            this.renderDeliveryBoyComlaints(generalFontForTitle, generalFontForValue)
            :
            <div style={{ color: 'red', textAlign: "center" }}>This item is not yet delivered!</div>
          }
        </Paper>

        <Typography variant="caption" style={styles.sectionTitle}>Refund Status</Typography>
        <Paper style={{ padding: "10px", marginBottom: "5px", backgroundColor: "#F1F1EE" }}>
          {refundData.length > 0 ?
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ display: 'flex' }}>
                <b style={styles.generalFont}>Refund Reason: &nbsp;</b>
                <p style={{ ...styles.generalFont, marginBottom: 0, paddingLeft: "5px" }}>{complaintReason}</p>
              </span>
              <Button onClick={() => { openRefundForm(data) }} color="primary" style={{ padding: 0, minHeight: 0 }}>
                <Typography variant="caption" color="primary"> Refund Status </Typography>
              </Button>
            </div>
            :
            <div style={{ color: "#117CF2", textAlign: "center" }}>No refund initiated for this item.</div>
          }
        </Paper>
      </div>
    );
  }
}

LineItemSummary.propTypes = {
  data: PropTypes.object
};

export default LineItemSummary;
