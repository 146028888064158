import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function DeactivateDialog(props) {
  const { open, onCancel, onConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <DialogTitle id="alert-dialog-title">Deactivate Route?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All orders on this route will be cancelled due to this.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
          </Button>
        <Button onClick={onConfirm} color="secondary" variant="contained" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeactivateDialog;
