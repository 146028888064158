import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Dialog, DialogTitle,
  Button,
  DialogContent, DialogContentText,
} from '@material-ui/core';

class ConfirmationPopup extends Component {
  render() {
    const {
      open = false,
      onCancel,
      onSend,
      title = '',
      message = '',
      rightButton = 'Send',
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
                  Cancel
          </Button>
          <Button onClick={onSend} color="secondary" autoFocus>
            {rightButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationPopup.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  rightButton: PropTypes.string,
};

export default ConfirmationPopup;
