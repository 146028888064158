import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@material-ui/core';
import moment from 'moment';

class TicketNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({
      comment: value,
    });
  }

  handleNotes = () => {
    const { onComment } = this.props;
    const { comment } = this.state;
    onComment(comment);
    this.setState({
      comment: null,
    });
  }

  render() {
    const { notes = [] } = this.props;
    const { comment } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} style={{ height: 244, overflow: 'auto' }}>
        {
          notes.map(note => (
              <Grid
                key={note.id}
                container
                style={{ background: '#fdf4f4', padding: 10, marginBottom: 10 }}
              >
                <Grid item xs={12}>{note.comment}</Grid>
                <Grid item xs={12} style={{ textAlign: 'right', fontSize: 12 }}>
                  {moment(note.timeUpdated).format('lll')}
                </Grid>
              </Grid>
            ))
        }
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Grid container>
            <Grid item style={{ flex: 1, marginRight: 10 }}>
              <textarea
                style={{
                    width: '100%',
                    height: 30,
                    resize: 'none',
                    borderRadius: 3,
                    border: '1px solid #999',
                }}
                value={comment}
                onChange={this.handleChange}
                name="comment"
                />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={comment ? this.handleNotes : null}
              >POST
              </Button>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

TicketNotes.propTypes = {
  notes: PropTypes.instanceOf(Array),
  onComment: PropTypes.func,
};

export default TicketNotes;
