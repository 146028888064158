import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button, Tooltip } from '@material-ui/core';
import moment from 'moment';

import HistoryIcon from '@material-ui/icons/History';


const columnData = [
  {
    id: 'vendorProductItemId', numeric: false, disablePadding: true, label: 'Vendor Product Item ID',
  },
  {
    id: "name", numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'marginPercentage', numeric: false, disablePadding: true, label: 'Margin Percentage',
  },
  {
    id: 'marginType', numeric: false, disablePadding: true, label: 'Margin Type',
  },
  {
    id: 'updatedBy', numeric: false, disablePadding: true, label: 'Updated By',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Last Updated at',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  }
]

class TOTMarginList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      timeUpdated: {},
      marginPercentage: {}
    };
    const { totMarginData } = this.props;

    totMarginData.map((el, index) => {
      customComponents['timeUpdated'][index] = moment(el.timeUpdated).format('lll');
      customComponents['marginPercentage'][index] = `${el.marginPercentage.toFixed(2)} %`;
    })
    return customComponents;
  }
  
  getActions = (el) => (
    <React.Fragment>
      <Button color="primary" variant="outlined" onClick={() => { this.props.onEdit(el) }}>Edit</Button>

      <Tooltip title="History">
        <Button color="primary" onClick={() => this.props.viewHistory(el)} ><HistoryIcon /></Button>
      </Tooltip>
    </React.Fragment>
  )
 


  render() {
    const {
      totMarginData,
      rowsPerPage,
      page,
      count,
      isNoData,
      onChangePage,
      onChangeRowsPerPage
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={totMarginData}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        isNoData={isNoData}
        customComponents={this.getCustomComponents()}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

TOTMarginList.propTypes = {
  totMarginData: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}

export default TOTMarginList;
