import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Button from '@material-ui/core/Button';
import Lozenge from '@atlaskit/lozenge';

const columnDataAll = [
  {
    id: 'locality', numeric: false, disablePadding: true, label: 'Locality',
  },
  {
    id: "subLocality", numeric: false, disablePadding: true, label: 'Sub Locality',
  },
  {
    id: "pincode", numeric: false, disablePadding: true, label: 'Pincode',
  },
  {
    id: "staticRouteName", numeric: false, disablePadding: true, label: 'Static Route',
  },
  {
    id: "active", numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: "actions", numeric: false, disablePadding: true, label: 'Actions',
  }
]

class DefaultRouteMappingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnData: columnDataAll,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { showPincode } = nextProps;
    if (!showPincode) {
      const columnData = columnDataAll.filter(column => column.id != "pincode");
      return {
        columnData
      }
    } else {
      return {
        columnData: columnDataAll
      }
    }
  }

  getCustomComponents = () => {
    const customComponents = {
      active: {}
    };
    const { routeMappings } = this.props;

    routeMappings.map((routeMapping, index) => {
      customComponents['active'][index] = <Lozenge key={index} appearance={routeMapping.active ? "success" : "removed"}> {routeMapping.active ? "Active" : "Inactive"} </Lozenge>
    })
    return customComponents;
  }

  getActions = (routeMapping) => (
    <React.Fragment>
      <Button color="secondary" onClick={() => { this.props.onEdit(routeMapping) }}>Edit</Button>
      <Button color="secondary" variant="contained" onClick={() => { this.props.onDelete(routeMapping.id) }}>Delete</Button>
    </React.Fragment>
  )

  render() {
    const {
      routeMappings,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      handleChangePage,
      handleChangeRowsPerPage
    } = this.props;

    const { columnData } = this.state;

    return (
      <PaginatedTable
        columnData={columnData}
        data={routeMappings}
        count={count}
        actions={this.getActions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        page={page}
        order={order}
        orderBy={orderBy}
        customComponents={this.getCustomComponents()}
      />
    )
  }
}

DefaultRouteMappingList.propTypes = {
  routeMappings: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired
}

DefaultRouteMappingList.defaultProps = {
  order: 'acs',
  orderBy: '',
}

export default DefaultRouteMappingList;
