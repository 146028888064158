import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Typography, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import ItemText from '../ItemText';
import styles from './styles';

class TrackOrder extends Component {
  getSteps = () => {
    const { currentDeliveryRouteList } = this.props;
    if (isEmpty(currentDeliveryRouteList)) {
      return [];
    }
    return currentDeliveryRouteList.map((el, i) => ({
      number: i,
      name: el.customerAddress.customerName,
      mobile: el.customerAddress.mobile,
      orderId: el.customerOrder.id,
      address: el.deliveryRouteOrder.address,
      status: el.deliveryRouteOrder.status,
      time: moment(el.deliveryRouteOrder.timeUpdated).format('lll'),
    }));
  };

  getTimeLineItem = (el) => {
    const { ongoingOrder } = this.props;
    const myOrder = ongoingOrder.id === el.orderId;
    const labelStyle = { ...styles.status, ...styles[el.status] };
    const constentStyle = myOrder ? { ...styles.csItem } : { ...styles.csItemContent };
    return (
      <Step key={el.number} active>
        <StepLabel>
          <div style={styles.small}>{el.orderId}</div>
          <div style={labelStyle}>{el.status}</div>
          <div style={styles.small}>{el.time}</div>
        </StepLabel>
        <StepContent>
          {myOrder && (
            <div style={constentStyle}>
              <div>{el.name}</div>
              <div>{el.mobile}</div>
              <div>{el.address}</div>
            </div>
          )}
          {!myOrder && <div style={constentStyle}>{el.address}</div>}
        </StepContent>
      </Step>
    );
  };

  render() {
    const { currentDeliveryRouteInfo } = this.props;
    const steps = !isEmpty(currentDeliveryRouteInfo) ? this.getSteps() : [];
    const {
      name,
      deliveryBoyName,
      deliveryBoyMobile,
      supervisorName,
      supervisorMobile,
    } = currentDeliveryRouteInfo;
    return (
      <div>
        <Typography style={styles.title}>TRACK DELIVERY BOY</Typography>
        {!isEmpty(steps) && (
          <div>
            <div style={styles.info}>
              <ItemText name="Delivery Route" value={name} style={styles.infoItem} />
              {deliveryBoyName && (
                <ItemText
                  name="Delivery Boy"
                  value={`${deliveryBoyName} (${deliveryBoyMobile})`}
                  style={styles.infoItem}
                />
              )}
              {supervisorName && (
                <ItemText
                  name="Supervisor"
                  value={`${supervisorName} (${supervisorMobile})`}
                  style={styles.infoItem}
                />
              )}
            </div>
            <div>
              <Stepper orientation="vertical">{steps.map(el => this.getTimeLineItem(el))}</Stepper>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TrackOrder.propTypes = {
  currentDeliveryRouteList: PropTypes.instanceOf(Array),
  ongoingOrder: PropTypes.instanceOf(Object),
  currentDeliveryRouteInfo: PropTypes.instanceOf(Object),
};

export default TrackOrder;
