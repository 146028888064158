import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {}, ref, ...other
  } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        style: { textColor: 'black' },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {suggestion.name}
      </div>
    </MenuItem>
  );
}


const classes = {
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
};

class SearchWithSuggestions extends React.Component {
  constructor(props) {
    super(props);
    const { suggestions = [], searchQuery = '' } = this.props;
    this.state = {
      query: searchQuery,
      suggestions,
    };
  }

  componentDidMount = () => {
    const { suggestions } = this.props;
    this.setState({
      suggestions,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { suggestions } = nextProps;
    return {
      ...prevState,
      suggestions,
    };
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    const { onChange } = this.props;
    this.setState({
      query: newValue,
    });
    onChange(newValue);
  };

  handleSelection = (suggestion) => {
    const { onSelect } = this.props;
    onSelect(suggestion);
    return suggestion.name;
  }

  render() {
    const { suggestions, query } = this.state;
    const { placeholder = '', label = '', required = false } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: (e) => { console.log(e); },
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.handleSelection,
      renderSuggestion,
    };
    return (
      <div>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder,
            value: query,
            onChange: this.handleChange,
            label,
            required
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={({ containerProps, children }) => (
            <div>
              <style>
                {`
                  .suggestionList{
                     min-width:300px;
                     max-height:300px;
                     overflow-y: scroll;
                  }
                `}
              </style>
              <Paper {...containerProps} square className="suggestionList">
                {children}
              </Paper>
            </div>
          )}
        />
      </div>
    );
  }
}

SearchWithSuggestions.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.string,
  suggestions: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

export default SearchWithSuggestions;
