import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';

const styles = {
  paper: {
    marginBottom: 10,
  },
  tableCell: {
    borderBottom: 0,
  },
};

class CustomerDetail extends Component {
  render() {
    const { data: customerProfile } = this.props;
    return (
      <Paper elevation={1} square style={styles.paper}>
        <div style={{ padding: '15px 20px', fontWeight: 600, background: 'navajowhite' }}>Customer Details</div>
        <div style={{ padding: '10px 20px', height: 300, overflow: 'scroll' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={styles.tableCell} padding="none" component="th">Phone</TableCell>
                <TableCell style={styles.tableCell} padding="none">
                  {customerProfile.mobile}
                  <Button variant="contained" color="secondary" disabled size="small" style={{ marginLeft: 5 }}>Call</Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableCell} padding="none" component="th">Email</TableCell>
                <TableCell style={styles.tableCell} padding="none">{customerProfile.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableCell} padding="none" component="th">Name</TableCell>
                <TableCell style={styles.tableCell} padding="none">{customerProfile.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableCell} padding="none" component="th">CustomerID</TableCell>
                <TableCell style={styles.tableCell} padding="none">{customerProfile.customerId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={styles.tableCell} padding="none" component="th">Aditional Phone</TableCell>
                <TableCell style={styles.tableCell} padding="none" />
              </TableRow>
            </TableBody>
          </Table>
          <div style={{ textAlign: 'center' }}>
              <a
              className="MuiButtonBase-root-57 MuiButton-root-74 MuiButton-text-76 MuiButton-textSecondary-78 MuiButton-outlined-82 MuiButton-outlinedSecondary-84"
              target="_blank" rel="noopener noreferrer"
              href={`/cs-assist/customer/${customerProfile.customerId}`}>
              View Details</a>
          </div>
        </div>
      </Paper>
    );
  }
}

CustomerDetail.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default CustomerDetail;
