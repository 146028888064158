import React, { Component } from 'react';
import apiCall, { getAppConfig, getCustomerAppConfig } from '../../src/api/NetworkHandler';


export const getApplicationConfigs = async () => {
    let appConfig = {};
    try {
        const response = await apiCall.sendRequest('get', getAppConfig);
        const { data: { data = {} } = {} } = response;
        return data;
    } catch (err) {
        return {};
    }
}

export const getCustomerApplicationConfigs = async () => {
    try {
        const response = await apiCall.sendRequest('get', getCustomerAppConfig);
        const { data: { data = {} } = {} } = response;
        return data;
    } catch (err) {
        return {};
    }
}