export default {
  selectedItemBox: {
    margin: '32px',
  },
  itemInfo: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  subHeader: {
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  selectedItem: {
    margin: '16px 0',
    display: 'flex',
  },
  itemImage: {
    marginRight: '32px',
  },
  itemDesc: {
    flexGrow: 1,
  },
  itemName: {
    fontSize: '1em',
  },
  itemDetails: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  itemDetailsOfferPrice: {
    color: 'rgba(0, 0, 0, 0.54)',
    textDecorationLine: 'line-through'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btn: {
    width: '128px',
    marginLeft: '10px',
  },
};
