import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/FileDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Colors from '../../../utils/Colors';
import API, { uploadFilePath } from '../../../api/NetworkHandler';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { warning} = Modal;

const fileImage = require('../../../../public/images/file.png');
const csvImage = require('../../../../public/images/csv.png');
const pdfImage = require('../../../../public/images/pdf.png');

const styles = {
  container: {
    display: 'flex',
  },
  uploadContainer: {
    margin: 5,
    background: Colors.grey[100],
    border: '1px dashed #999',
    height: 98,
  },
  fileInput: { height: 100, width: 100, opacity: 0 },
  uploadImage: {
    width: 100,
    height: 60,
    margin: 10,
    marginTop: '-80px',
    textAlign: 'center',
  },
  image: { margin: 5 },
  icons: {
    fontSize: 18,
    color: Colors.primary,
    background: Colors.white,
    boxShadow: `1px 1px 1px 0px ${Colors.grey[400]}`,
    cursor: 'pointer',
  },
};

class UploadFile extends Component {
    fileExtension = (fileUrl) => {
      const fileSegments = fileUrl.split('.');
      return fileSegments[fileSegments.length - 1].toLowerCase();
    }

    handleChange = async (event) => {
      const { onUpload, validExtensions: extns , grn} = this.props;
      if(!grn.invoiceNumber){
        warning({
          title: 'Please add invoice number before uploading any receipt.',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: 'Ok',
          onOk: () => { },
        });
        return
      }
      const { files } = event.target;
      const error = [];
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        const extn = this.fileExtension(files[i].name);
        if (extns && extns.length > 0 && (extns.indexOf(extn) === -1)) {
          error.push({
            file: files[i].name,
            message: 'not a valid extension',
          });
        } else {
          formData.append('files', files[i]);
        }
      }
      try {
        const response = await API.sendRequest('post', uploadFilePath, formData);
        const { data } = response;
        onUpload(data, error);
      } catch (e) {
        onUpload({}, error);
      }
    }

    handleRemove = (index) => {
      const { onDelete } = this.props;
      onDelete(index);
    }

    renderFilePreview = (file) => {
      const { name, url } = file;
      let imagePreview = '';
      switch (this.fileExtension(url)) {
        case 'csv':
          imagePreview = csvImage;
          break;
        case 'pdf':
          imagePreview = pdfImage;
          break;
        case 'jpeg':
        case 'png':
        case 'jpg':
          imagePreview = url;
          break;
        default:
          imagePreview = fileImage;
      }
      return <img src={imagePreview} alt={name} title={name} height="100" style={styles.image} />;
    }

    render() {
      const {
        title = 'Upload',
        files = [],
        multiLine = true, onChange, viewOnly = false,
        multiple = true,
        noAction = false, 
        ...rest
      } = this.props;
      const flexWrap = multiLine ? 'wrap' : 'no-wrap';
      return (
        <div style={{ ...styles.container, flexWrap }}>
          {
            files.length > 0 && files.map((image, index) => (
              <div style={{ height: 130, textAlign: 'right' }}>
                {this.renderFilePreview(image)}
                <div style={{ position: 'relative', bottom: 107, right: 8 }}>
                  <a href={image.url}>
                    <DownloadIcon style={{ ...styles.icons, marginRight: 2 }} />
                  </a>
                  { image.editable && !noAction && <CloseIcon
                    style={styles.icons}
                    onClick={() => this.handleRemove(index)}
                  />}
                </div>
                <div style={{
                      marginTop: '-30px',
                      textAlign: 'center',
                }}
                >
                  <input
                    type="text"
                    name="name"
                    value={image.name}
                    disabled={!image.editable || noAction} 
                    style={{ height: '15px', width: '91%' }}
                    onChange={e => onChange(index, 'name', e.target.value)}
                  />
                </div>
              </div>
            ))
        }
          { (multiple || files.length === 0) && !viewOnly &&
          <div style={styles.uploadContainer}>
            <input
              type="file"
              name="files"
              multiple
              style={styles.fileInput}
              onChange={this.handleChange}
              {...rest}
              disabled={noAction}
            />
            <div style={styles.uploadImage}>
              <CloudUploadIcon fontSize="large" />
              <div>{title}</div>
            </div>
          </div>}
        </div>
      );
    }
}

UploadFile.propTypes = {
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  files: PropTypes.instanceOf(Array),
  multiLine: PropTypes.bool,
  viewOnly: PropTypes.bool,
  noAction: PropTypes.bool,
  multiple: PropTypes.bool,
  title: PropTypes.string,
};

export default UploadFile;
