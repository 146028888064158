import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


class Adjustments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="adjustments">
        Adjustments
      </div>
    );
  }
}

Adjustments.propTypes = {};

export default Adjustments;
