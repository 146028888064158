import React from 'react';
import moment from 'moment';
import { Button, Select, FormHelperText, MenuItem, TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';
import apiCall, { getWareHouse } from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';


const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
  },
  searchContainer: {
    flex: 7,
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchField: {
    width: 200,
    marginRight: 40,
  },
  buttonField: {
    flex: 1,
    lineHeight: 'normal',
    marginBottom: 20,
  },
  or: {
    flex: 1,
  },
  clear: {
    flex: 1,
    color: Colors.primary,
    marginLeft: '30px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginTop: 4,
  },
};

let clearFilters = false;
class GRNSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      warehouses: [],
    };
  }


  componentDidMount() {
    this.fetchWarehouses();
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress, false);
  }

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }

  fetchWarehouses = async () => {
    try {
      const response = await apiCall.sendRequest('get', getWareHouse);
      const { data: { _embedded = {} } } = response;
      this.setState({
        warehouses: _embedded.warehouse,
      });
    } catch (e) {
      this.setState({
        warehouses: [],
      });
    }
  }

  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {};
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
    }, () => {
      clearFilters = !clearFilters;
      onButtonClick('search', 0);
    });
  }

  isValidDateRange = (value, key) => {
    const { filters: { startDate, endDate } } = this.state;
    const isEmpty = value === '';
    const endDateValidation = (key === 'endDate' && (startDate === '' || moment(value).isSameOrAfter(moment(startDate))));
    const startDateValidation = (key === 'startDate' && (endDate === '' || moment(value).isSameOrBefore(moment(endDate))));
    return isEmpty || endDateValidation || startDateValidation;
  }

  handleChange = (value, key) => {
    let { filters } = this.state;
    const { onFilterChange } = this.props;
    const isNotDateField = (key !== 'endDate') && (key !== 'startDate');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      filters = {
        ...filters,
        [key]: value,
      };
      onFilterChange(key, value);
    }
    this.setState({
      filters,
    });
  }

  render() {
    const {
      onButtonClick, grnStatuses,
    } = this.props;
    const {
      filters, warehouses,
    } = this.state;
    const {
      startDate,
      endDate,
      warehouseId = '',
      status = '',
    } = filters;
    return (
      <div style={styles.contentContainer} key={clearFilters}>
        <div style={styles.searchContainer}>
          <div style={styles.searchField}>
            <TextField
              id="date"
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              defaultValue={startDate}
              InputLabelProps={{
                  shrink: true,
                }}
              onChange={e => this.handleChange(e.target.value, 'startDate')}
            />
          </div>

          <div style={styles.searchField}>
            <TextField
              id="date"
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              min={startDate}
              defaultValue={endDate}
              InputLabelProps={{
                  shrink: true,
                }}
              onChange={e => this.handleChange(e.target.value, 'endDate')}
            />
          </div>

          <div style={styles.searchField}>
            <FormHelperText style={styles.label}>WareHouse Id</FormHelperText>
            <Select
              name="warehouseId"
              fullWidth
              onChange={e => this.handleChange(e.target.value, 'warehouseId')}
              value={(+warehouseId) || ''}
            >
              {warehouses.map(warehouse => (
                <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                      ))}

            </Select>
          </div>
          <div style={styles.searchField}>
            <FormHelperText style={styles.label}> Status </FormHelperText>
            <Select
              // style={{ marginTop: 16 }}
              native
              fullWidth
              label="Status"
              value={status}
              onChange={e => this.handleChange(e.target.value, 'status')}
            >
              <option value="">Select Status</option>
              {Object.keys(grnStatuses).map(grnStatus => (
                <option key={grnStatus} value={grnStatus}>
                  {grnStatus}
                </option>
                  ))}
            </Select>
          </div>
        </div>
        <div style={styles.buttonContainer}>
          <div style={{ flex: 1 }}>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onButtonClick('search', 0)}
              >
                      Search
              </Button>
            </div>
            <div style={styles.buttonField}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleClear}
              >
                      Clear All
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GRNSearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.func,
  grnStatuses: PropTypes.instanceOf(Array),
};

export default GRNSearch;
