import React from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import apiCall, {
  createTermsConditions
} from "../../../api/NetworkHandler";

const styles = {
  termsAndConditionText: {
    width: "100%",
    minHeight: `${window.innerHeight - 250 + 'px'}`,
    maxHeight:`${window.innerHeight - 220 + 'px'}`,
    marginTop:'30px'
  },
  bootstrapRoot: {
    padding: 0
  },
  bootstrapInput: {
    borderRadius: 4,
    border: "1px solid #d3d3d3",
    fontSize: 15,
    padding: "10px 12px",
    width: "95%",
    minHeight: `${window.innerHeight - 250 + 'px'}`,
    maxHeight:`${window.innerHeight - 220 + 'px'}`,
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.25)',
    },
  },
  flex:{
    display:'flex',
    alignItems:'center'
  },
  flexsb:{
    justifyContent:'space-between'
  },
  buttonSave:{
    marginLeft:'10px'
  },
  notes:{
    fontSize:'14px',
    color:'#c90b0bbf',
    marginTop:'28px'
  }
};

class TermsAndConditionsEditCreatePo extends React.Component {
  state = {
    termsAndConditionEdit: ""
  };

  componentDidMount = () => {
    this.setState({ termsAndConditionEdit: this.props.value });
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.open === false && nextProps.open === true) {
      this.setState({ termsAndConditionEdit: nextProps.value });
    }
  };

  changeInputHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  onSaveTermsConditionsHandler = () =>{
    const data = {
      vendorLocationId: this.props.vendorlocationId,
      termsAndConditions : this.state.termsAndConditionEdit
    }
    if(this.state.termsAndConditionEdit){
      apiCall
        .sendRequest('post',createTermsConditions,data)
        .then(res => {
          this.props.updateTermsAndConditions(res.data.termsAndConditions)
        })
        .catch(err => console.log(err))
    }
  }


  render() {
    const { classes, onClose } = this.props;
    const { termsAndConditionEdit } = this.state;
    return (
      <div>
        <div className={`${classes.flex} ${classes.flexsb}`}>
          <div className={classes.flex}> 
            <ArrowBack onClick = {onClose} style={{cursor:'pointer'}}/>
            <Typography variant="h1" component="h3">
              Vendor Terms & Conditions
            </Typography>
          </div>
          <div>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={classes.buttonSave}
              variant="contained"
              onClick={this.onSaveTermsConditionsHandler}
              color="secondary"
              autoFocus
            >
              Save
            </Button>
          </div>
        </div>
        
          <TextField
            name="termsAndConditionEdit"
            onChange={this.changeInputHandler}
            className={classes.termsAndConditionText}
            multiline={true}
            value={termsAndConditionEdit}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.bootstrapRoot,
                input: classes.bootstrapInput
              }
            }}
          />
          
        <div className={classes.notes}>Variables: %DELIVERY_DATE%, %CITY%
                                       <br/>
                                       eg: 
                                       <br/>Jurisdiction %CITY% 
                                       <br/>Goods should be delivered on or before %DELIVERY_DATE% 3 am</div>

      </div>
    );
  }
}

TermsAndConditionsEditCreatePo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TermsAndConditionsEditCreatePo);
