import React, { Component } from 'react';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import PRSearch from '../components/PRSearch';
import API, { prListByFiltersPath, prByPrId, getWareHouse } from '../../../api/NetworkHandler';
import PRList from '../components/PRList';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { withRouter } from 'react-router-dom';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';
import  debounce  from 'lodash/debounce';

const defaultDate = moment()
  .add(1, 'days')
  .format('YYYY-MM-DD');

var versionDict = {
  'prList': 0,
}

class PRContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        query: '',
        startDate: defaultDate,
        endDate: defaultDate,
        prId: '',
        status: null,
      },
      warehouses: [],
      totalElements: 0,
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.fetchWarehouses();
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.fetchPrList(getFilterParams);
  }

  fetchWarehouses = async () => {
    try {
      const response = await API.sendRequest('get', getWareHouse);
      const {
        data: { _embedded = {} },
      } = response;
      this.setState({
        warehouses: _embedded.warehouse,
      });
    } catch (e) {
      this.setState({ warehouses: [] });
    }
  };

  fetchPrList = async (page = 0) => {
    versionDict.prList += 1;
    let prevPrList = versionDict.prList;
    const { filters } = this.state;
    const { prId, ...rest } = filters;
    let responseType = 0;
    let url = prListByFiltersPath(rest, page);
    if (filters.prId && filters.prId !== '') {
      url = prByPrId(prId);
      responseType = 1;
    }
    try {
      const response = await API.sendRequest('get', url);
      const { data: { content = [], totalElements = 0 } = {}, data = {} } = response;
      if (prevPrList === versionDict.prList) {
        this.setState({
          purchaseRequisitions: responseType === 1 ? [data] : content,
          totalElements: responseType === 1 ? 1 : totalElements,
        });
      }
    } catch (e) {
      this.setState({ purchaseRequisitions: [] });
    }
  };

  handleFilterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  handleChange = (filters, refresh = false) => {
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.handleSearch();
        }
      },
    );
  };

  handleSearch = debounce((page = 0) => {
    this.setState({
      currentPage: page,
    }, () => this.fetchPrList(page));
  }, 500);

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.handleSearch();
        break;
      default:
        break;
    }
  };

  clearAll = () => {
    this.setState(
      {
        filters: {
          query: '',
          startDate: defaultDate,
          endDate: defaultDate,
          prId: '',
        },
      },
      () => {
        this.handleSearch();
      },
    );
  };

  render() {
    const {
      filters, purchaseRequisitions = [], warehouses, totalElements,
      currentPage = 0,
    } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title="Purchase Requisitions"
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <PRSearch warehouses={warehouses} />
        </SearchBar>
        <div>
          <PRList
            data={purchaseRequisitions}
            totalElements={totalElements}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={currentPage}
            onPageChange={this.handleSearch}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(PRContainer);
