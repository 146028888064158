import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, InputAdornment } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ImageContainer from './ImageContainer';
import Grid from 'react-flexbox-grid/lib/components/Grid';
import Row from 'react-flexbox-grid/lib/components/Row';
import { ColMod } from '../../../components/lib';
import BASE_URL from '../../../api/config';
import apiCall, { vendorProductMappingPath, vendorCatalogPath, findVendorProductItemsByCatalog, findPackaging } from '../../../api/NetworkHandler';
import VendorProductItemMapping from '../../Commons/components/VendorProductItemMapping';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ProgressBar from '../../../components/States/ProgressBar';
import SectionMessage from '@atlaskit/section-message';
import SearchWithSuggestions from "../../Commons/components/SearchWithSuggestions";


class ProductItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subProduct: {},
      packagingList: [],
      vendorMapping: [],
      vendorCatalogs: [],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      errorMessage: '',
      isSubmitInProcess: false,
    }
  }

  componentDidMount() {
    this.fetchVendorProductMapping();
    const { subProduct = {}, vendorProductName, UOM } = this.props;
    const { uomId, unitMeasure = '', packaging = '' } = subProduct;
    const uom = UOM.find(uom => uom.id == uomId) || {};
    subProduct.name = `${vendorProductName}-${unitMeasure || ''}${uom.code || ''} ${packaging || ''}`;
    this.setState({ subProduct });
    this.getAllPackagingList(this.state.subProduct.packaging);
    subProduct.unitMeasure = unitMeasure || '1';
    this.setState({ subProduct })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { subProduct } = nextProps;
    const { subProduct: prevProduct } = prevState;
    return {
      subProduct: {
        ...subProduct,
        ...prevProduct,
      },
    };
  }

  handleInputChange = property => (event) => {
    const value = event.target.value;
    this.setState(prevState => {
      const subProduct = { ...prevState.subProduct };
      subProduct[property] = value;
      const { uomId, unitMeasure = '' } = subProduct;
      const { vendorProductName, UOM } = this.props;
      const uom = UOM.find(uom => uom.id == uomId) || {};
      subProduct.name = `${vendorProductName}-${unitMeasure || ''}${uom.code || ''}`;
      return {
        subProduct,
      }
    })
  }

  getAllPackagingList = async (query = '') => {
    try {
      const response = await apiCall.sendRequest('get', findPackaging(query));
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      const { packaging = [] } = embedded;
      this.setState({
        packagingList: packaging,
      });
    } catch (error) {
      this.setState({
        packagingList: [],
      });
    }
  }

  handlePackagingSearch = query => {
    const { subProduct } = this.state;
    this.setState({
      inputData: query,
      subProduct: {
        ...subProduct,
        packaging: this.validatePackaging(query)  ? query : '',
      }
    });
    this.getAllPackagingList(query);
  };


  handlePackagingSelection = item => {
    const { name } = item;
    const { subProduct } = this.state;
    this.setState({
      subProduct: {
        ...subProduct,
        packaging: name
      }
    });
  };

  addProductItemAttributes = () => {
    this.setState(prevState => {
      const subProduct = { ...prevState.subProduct };
      const { vendorProductItemAttributes } = subProduct;
      vendorProductItemAttributes.push({ name: '', value: '' });
      return {
        subProduct
      }
    })
  }

  removeProductItemAttributes = (i) => {
    this.setState(prevState => {
      const subProduct = { ...prevState.subProduct };
      const { vendorProductItemAttributes } = subProduct;
      vendorProductItemAttributes.splice(i, 1);
      return {
        subProduct,
      }
    })
  }

  handleAttrChange = (event, i, field) => {
    const { value } = event.target;

    this.setState(prevState => {
      const subProduct = prevState.subProduct;
      const { vendorProductItemAttributes = [{ name: '', value: '' }] } = subProduct;
      vendorProductItemAttributes[i][field] = value;
      subProduct.vendorProductItemAttributes = vendorProductItemAttributes;
      return {
        subProduct,
      }
    })

  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  updateImageUrls = () => {
    const { urls: prevUrls = [], subProduct } = this.state;
    const { vendorProductItemImages = [] } = subProduct;
    const urls = prevUrls.map((url) => {
      const image = vendorProductItemImages.find(img => img.position === url.position);
      if (image && !url.imageUrl) return image;
      return {
        ...url,
        productItem: subProduct.id ? `${BASE_URL.BASE_URL}/api/v1/vendor-product-items/${subProduct.id}` : null,
      };
    });
    return urls;
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  fetchVendorProductMapping = async () => {
    const id = this.state.subProduct.id;
    if (id) {
      const url = vendorProductMappingPath(id);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data = [] } = {} } = res;
      this.setState({ vendorMapping: data });
    }
  }

  fetchProductCatalog = async (query = '') => {
    const response = await apiCall.sendRequest('get', vendorCatalogPath(query));
    const { _embedded: embedded } = response.data;

    const catalogOptions = embedded['vendor-catalogs'].map(vendorCatalog => {
      return {
        ...vendorCatalog,
        value: vendorCatalog.id,
        label: vendorCatalog.name,
      }
    })
    return {
      options: catalogOptions
    }
  }

  fetchProductItems = async (catalogId, query) => {
    try {
      if (catalogId) {
        const response = await apiCall.sendRequest('get', findVendorProductItemsByCatalog(catalogId, query));
        const { _embedded: embedded } = response.data;

        const vendorProductItems = embedded['vendor-product-items'].map(ProductItem => {
          return {
            ...ProductItem,
            value: ProductItem.id,
            label: ProductItem.name,
          }
        })
        return {
          options: vendorProductItems
        }
      }else{
        return new Promise.resolve({
          options: [],
        })
      }
    } catch (error) {
      return {
        options: []
      }
    }
  }

  onMappingChange = mapping => {
    this.setState({ vendorMapping: mapping })
  }

  validatePackaging = (query) => {
    const { packagingList = [] } = this.state;
    let invalidPackagingQuery = false;
    packagingList.forEach(item => {
      if (item.name === query) {
        invalidPackagingQuery = true;
      }
    });
    return invalidPackagingQuery;
  }

  validateForm = () => {
    const { subProduct, inputData } = this.state;
    let isFormValid = true;
    const {
      name,
      unitMeasure,
      uomId,
      packaging,
      cutOffTime,
      tax,
      discount,
    } = subProduct;

    if (!name) {
      isFormValid = false;
      this.setState({
        invalidName: true,
      })
    } else {
      this.setState({
        invalidName: false,
      })
    }
    if (!Number(unitMeasure)) {
      isFormValid = false;
      this.setState({
        invalidUnitMeasure: true,
        unitMeasureText: 'Unit Measure field is required'
      })
    } else {
      this.setState({
        invalidUnitMeasure: false,
        unitMeasureText: ''
      })
    }
    if (!uomId) {
      isFormValid = false;
      this.setState({
        invalidUomId: true,
        uomIdText: 'UOM ID field is required'
      })
    } else {
      this.setState({
        invalidUomId: false,
        uomIdText: ''
      })
    }
    if (!packaging) {
      isFormValid = false;
      this.setState({
        invalidPackaging: true,
        packagingText: 'Packaging field is required'
      })
    } else {
      this.setState({
        invalidPackaging: false,
        packagingText: ''
      })
    }
    // if (!cutOffTime) {
    //   isFormValid = false;
    //   this.setState({
    //     invalidCutOffTime: true,
    //     cutOffTimeText: 'Cutoff Time field is required'
    //   })
    // } else {
    //   this.setState({
    //     invalidCutOffTime: false,
    //     cutOffTimeText: ''
    //   })
    // }
    if ((typeof tax == 'string' && !tax) || typeof tax == 'undefined') {
      isFormValid = false;
      this.setState({
        invalidTax: true,
        taxText: 'Tax field is required'
      })
    } else {
      this.setState({
        invalidTax: false,
        taxText: ''
      })
    }
    if ((typeof discount == 'string' && !discount) || typeof discount == 'undefined') {
      isFormValid = false;
      this.setState({
        invalidDiscount: true,
        discountText: 'Discount field is required'
      })
    } else {
      this.setState({
        invalidDiscount: false,
        discountText: ''
      })
    }
    return isFormValid;
  }

  handleSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ isSubmitInProcess: true });
    const { onRefresh, onClose, vendorProductId } = this.props;
    let { subProduct } = this.state;
    const urls = this.updateImageUrls();
    subProduct.vendorProductItemImages = urls.filter((url) => {
      if (url.imageUrl) return true;
      return false;
    });
    // const timeString = `2019-01-01T${subProduct.cutOffTime}`;
    // const formattedCutOffTime = moment(timeString).format('HH:mm:ss');
    subProduct.vendorProductId = vendorProductId;
    // subProduct.cutOffTime = formattedCutOffTime;
    try {
      let itemId = subProduct.id;
      if (subProduct.id) {
        subProduct = {
          ...subProduct,
          uom: subProduct.uomId ? `${BASE_URL.BASE_URL}/api/v1/uoms/${subProduct.uomId}` : null,
          vendorProduct: `${BASE_URL.BASE_URL}/api/v1/vendor-product-items/${vendorProductId}`,
        };
        await apiCall.sendRequest('patch', `api/v1/vendor-product-items/${itemId}`, subProduct);
        onRefresh();
      } else {
        subProduct = {
          ...subProduct,
          uom: subProduct.uomId ? `${BASE_URL.BASE_URL}/api/v1/uoms/${subProduct.uomId}` : null,
          vendorProduct: `${BASE_URL.BASE_URL}/api/v1/vendor-product-items/${vendorProductId}`,
        };
        const response = await apiCall.sendRequest('post', `api/v1/vendor-product-items/`, subProduct);
        const { data } = response;
        itemId = data.id;
      }
      this.updatingProductMapping(itemId);
      this.setState({ isSubmitInProcess: false });
      onClose();
    } catch (e) {
      // error handling
      const { message } = e.response.data;
      this.setState({
        errorMessage: message,
        isSubmitInProcess: false,
      });
    }
  }

  updatingProductMapping = async (id) => {
    const { vendorMapping } = this.state;
    const url = vendorProductMappingPath(id);
    const postData = vendorMapping.filter(item => !isEmpty(item));
    await apiCall.sendRequest('post', url, postData);
  }

  render() {
    const {
      subProduct,
      vendorMapping,
      invalidName,
      invalidUnitMeasure,
      invalidPackaging,
      invalidCutOffTime,
      invalidTax,
      invalidDiscount,
      unitMeasureText,
      uomIdText,
      packagingText,
      cutOffTimeText,
      taxText,
      discountText,
      errorMessage,
      isSubmitInProcess,
      packagingList,
    } = this.state;

    const { onClose, UOM, isNew = false } = this.props;

    const {
      name = '',
      active = '',
      description = '',
      unitMeasure = '',
      uomId = null,
      packaging = '',
      sizeWidth = null,
      sizeHeight = null,
      sizeLength = null,
      weightInGrams = null,
      tags = '',
      cutOffTime = '',
      unitPrice = null,
      sellingPrice = null,
      tax = null,
      discount = null,
      vendorProductItemAttributes: attributes = [],
      barcode,
    } = subProduct;

    if (attributes.length == 0) {
      attributes.push({ name: '', value: '' })
    }
    const urls = this.updateImageUrls();

    return (
      <Grid style={{ width: '50vw' }} fluid>
        <ProgressBar isLoading={isSubmitInProcess} />
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose() }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  Product Item Form
                </Typography>
                <Typography variant="caption" color='error'>
                  * Required
                </Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose() }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={() => { this.handleSave() }} variant="contained" color="secondary">
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        {errorMessage && <Row>
          <ColMod lg={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Basic Details
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Name"
                value={name}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                error={invalidName}
                required
                helperText="Note: Name will be auto-set from Unit Measure and UOM"
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <InputLabel style={{ fontSize: '0.7em' }}>Status</InputLabel>
              <RadioGroup onChange={this.handleInputChange('active')} value={(active + '') || 'true'} style={{ flexDirection: 'row' }}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={12} custompadding='true' bumphigh='true'>
              <TextField
                value={description || ''}
                onChange={this.handleInputChange('description')}
                label="Description"
                fullWidth
                multiline={true}
              />
            </ColMod>
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Product Properties
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Unit Measure"
                type = "number"
                value={unitMeasure}
                onChange={this.handleInputChange('unitMeasure')}
                fullWidth
                error={invalidUnitMeasure}
                required
                helperText={unitMeasureText}
                InputProps={{
                  inputProps: { min: 1, readOnly: !isNew },
                }}
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <InputLabel style={{ fontSize: '0.7em', color: uomIdText ? 'red' : '' }} htmlFor="uom-id">UOM ID<sup>*</sup></InputLabel>
              <Select
                error={uomIdText}
                required
                value={uomId || ''}
                onChange={this.handleInputChange('uomId')}
                inputProps={{
                  name: 'uomId',
                  id: 'uom-id',
                  readOnly: !isNew,
                }}
                fullWidth
              >
                {UOM.map(x => (<MenuItem key={x.id} value={x.id}>{x.code}</MenuItem>))}
              </Select>
              <small style={{ color: 'red' }}> {uomIdText} </small>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true'>
              <InputLabel style={{ fontSize: '0.9em', color: invalidPackaging ? 'red' : '' }}> Packaging <sup>*</sup></InputLabel>
              <div>
                {isNew ?
                <SearchWithSuggestions
                  searchQuery={packaging || ''}
                  suggestions={packagingList}
                  onChange={this.handlePackagingSearch}
                  onSelect={this.handlePackagingSelection}
                /> :
                <TextField
                  value={packaging}
                  fullWidth
                  inputProps={{ readOnly: true }}
                /> }
              </div>
              <small style={{ color: 'red', fontSize: '0.8em' }}> {packagingText} </small>
            </ColMod>
            <ColMod custompadding lg={6}>
              <TextField
                fullWidth
                label="Barcode/EAN"
                type="number"
                value={barcode || ''}
                onChange={this.handleInputChange('barcode')}
              />
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6}>
              <TextField
                label="tags"
                value={tags || ''}
                onChange={this.handleInputChange('tags')}
                fullWidth
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Width(Cm)"
                value={sizeWidth || ''}
                onChange={this.handleInputChange('sizeWidth')}
                fullWidth
              />
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Height(Cm)"
                value={sizeHeight || ''}
                onChange={this.handleInputChange('sizeHeight')}
                fullWidth
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Length(Cm)"
                value={sizeLength || ''}
                onChange={this.handleInputChange('sizeLength')}
                fullWidth
              />
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Weigth(Gm)"
                value={weightInGrams || ''}
                onChange={this.handleInputChange('weightInGrams')}
                fullWidth
              />
            </ColMod>
          </Row>
          {/* <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Cutoff Time"
                type="time"
                value={cutOffTime || ''}
                onChange={this.handleInputChange('cutOffTime')}
                fullWidth
                error={invalidCutOffTime}
                required
                helperText={cutOffTimeText}
                InputLabelProps={{ shrink: true }}
              />
            </ColMod>
          </Row> */}
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Price,Tax and Discount
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Unit Price"
                value={unitPrice || ''}
                onChange={this.handleInputChange('unitPrice')}
                fullWidth
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Selling Price"
                value={sellingPrice || ''}
                onChange={this.handleInputChange('sellingPrice')}
                fullWidth
              />
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Tax(%)"
                value={tax}
                onChange={this.handleInputChange('tax')}
                fullWidth
                error={invalidTax}
                required
                helperText={taxText}
              />
            </ColMod>
            <ColMod lg={6} custompadding='true' bumphigh='true'>
              <TextField
                label="Discount(%)"
                value={discount}
                onChange={this.handleInputChange('discount')}
                fullWidth
                error={invalidDiscount}
                required
                helperText={discountText}
              />
            </ColMod>
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Product Attributes
              </Typography>
            </ColMod>
          </Row>
          <Row>
            {attributes.map((x, i) => (
              <ColMod lg={12} key={i}>
                <TextField
                  value={x.name}
                  onChange={(event) => { this.handleAttrChange(event, i, 'name'); }}
                  label="Name"
                />
                <TextField
                  style={{ marginLeft: '0.4em' }}
                  value={x.value}
                  onChange={(event) => { this.handleAttrChange(event, i, 'value'); }}
                  label="Value"
                />
                {(
                  attributes.length === (i + 1) ?
                    <AddIcon color="secondary" style={{ fontSize: '1.6em' }} onClick={this.addProductItemAttributes} /> :
                    <CloseIcon color="secondary" style={{ fontSize: '1.6em' }} onClick={() => this.removeProductItemAttributes(i)} />
                )}
              </ColMod>
            ))}
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Files
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={8} md={12}>
              <ImageContainer
                urls={urls}
                handleImageUpload={this.handleImageUpload}
                clearImage={this.clearImage}
              />
            </ColMod>
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Vendor Product Item Mapping
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={12}>
              <VendorProductItemMapping
                data={vendorMapping}
                onChange={this.onMappingChange}
                catalogOptions={this.fetchProductCatalog}
                productItemOptions={this.fetchProductItems}
              />
            </ColMod>
          </Row>
        </ColMod>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  UOM: state.UOM,
});

export default connect(mapStateToProps, null)(ProductItemForm);
