import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchProductItemByRegion } from '../../api/NetworkHandler';

function ProductItemSelector(props) {
  const { selected, onSelect, regionId } = props;

  const onLoad = async (searchTerm) => {
    const response = await apiCall.sendRequest('get', searchProductItemByRegion(searchTerm, regionId ? regionId : ''));
    const { data } = response;
    
    return data.map(productItem => {
      return {
        label: productItem.name,
        value: productItem.id,
        price: productItem.unitPrice,
        sellingPrice: !productItem.offerPrice ? productItem.sellingPrice : productItem.offerPrice,
      }
    })
  }
  

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
    />
  )
}

ProductItemSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Products from perticular region, if regionId not passed will get productItems from all regions
   */
  regionId: PropTypes.number,
};

ProductItemSelector.defaultProps = {
  selected: null,
  regionId: null,
};

export default ProductItemSelector;
