import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import NavBar from './NavBar';
import HubSearchBar from './HubSearchBar';
import apiCall, { getWareHouse, createHubIdPath } from '../api/NetworkHandler';
import UserSelection from './UserSelection';
import { ColMod } from './lib';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';
import isEmpty from "lodash/isEmpty";
import { FormControl, Select } from '@material-ui/core';

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'warehouseName', numeric: false, disablePadding: true, label: 'Warehouse Name',
  },
  {
    id: 'inchargeName', numeric: false, disablePadding: true, label: 'Incharge Name',
  },
  {
    id: 'clManagerName', numeric: false, disablePadding: true, label: 'CL Manager Name',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

class HubEnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.label}
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

class HubsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddHub: false,
      selectedHub: {},
      hub: [],
      links: {},
      warehouseList: [],
      regions: [],
      filters: {
        query: '',
      },
      error: {},
      searchedResult: false,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      openUserSelector: false,
      postData: {},
      userType: '',
    };
  }

  componentDidMount() {
    this.fetchAllHubs();
    this.fetchAllWarehouses();
  }

  fetchAllHubs = () => {
    if (this.state.filters.query) {
      apiCall
        .sendRequest('get', `/api/v1/hub/search/findHub?query=${this.state.filters.query}`)
        .then(({ data }) => {
          this.setState({
            hub: data._embedded.hub,
            links: data._links,
            searchedResult: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      apiCall
        // .sendRequest('get', '/api/v1/hub')
        .sendRequest('get', createHubIdPath)
        .then(({ data }) => {
          this.setState({
            hub: data._embedded.hub,
            links: data._links,
            searchedResult: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  fetchPaginatedHub = (url) => {
    apiCall
      .sendRequest('get', url)
      .then(({ data }) => {
        this.setState({
          hub: data._embedded.hub,
          links: data._links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  fetchAllWarehouses = () => {
    apiCall.sendRequest('get', getWareHouse)
      .then((response) => {
        this.setState({
          warehouseList: response.data._embedded.warehouse,
        });
      });
  }

  handleQueryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value,
      },
    }));
  };

  onSearch = (event) => {
    if (event.key === 'Enter') {
      console.log(event.key);
      this.fetchAllHubs();
    }
  };

  handleClick = (selectedHub) => {
    this.setState(
      {
        selectedHub,
        selectedName: selectedHub.name,
        selectedParentCategory: selectedHub.parentId ? selectedHub.parentId : '',
      },
      () => {
        // this.getWarehouseDetails();
      },
    );
  };

  handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    if (name === 'warehouseId') {
      let warehouse;
      [warehouse] = this.state.warehouseList.filter(item => item.id === value);
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          warehouseId: warehouse.id,
          warehouseName: warehouse.name,
        },
      }));
    } else {
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          [name]: value,
        },
      }));
    }
  }

  openAddHub = (hub) => {
    this.setState({
      openAddHub: true,
      isEdit: !!hub,
    });
  };

  editHub = (n) => {
    this.setState({
      postData: n,
    }, () => {
      this.openAddHub(n);
    });
  }

  handleNewHubClose = () => {
    this.setState({
      openAddHub: false,
      isEdit: false,
      selectedHub: {},
      warehouseRegions: [],
      postData: {},
      error: {},
    });
  };

  openUserSelector = (userType, index) => () => {
    this.setState({
      openUserSelector: true,
      selectorIndex: index,
      userType,
    });
  }

  handleClose = () => {
    this.setState({
      openUserSelector: false,
      selectorIndex: null,
      userType: '',
    });
  }

  selectNewUser = (user, userType) => {
    if (userType === 'Supervisor') {
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          incharge: user.id,
          inchargeName: user.name,
        },
      }), this.handleClose());
    } else {
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          clManagerId: user.id,
          clManagerName: user.name,
        },
      }), this.handleClose());
    }
  }

  selectUser = (user) => {
    this.setState(prevState => ({
      hub: [
        ...prevState.hub.slice(0, prevState.selectorIndex),
        {
          ...prevState.hub[prevState.selectorIndex],
          incharge: user.id,
          inchargeName: user.name,
        },
        ...prevState.hub.slice(prevState.selectorIndex + 1),
      ],
    }), this.handleClose());
  }

  handleChangePage = (event, page) => {
    const { links: { prev = {}, next = {} } = {} } = this.state;
    const { href: prevHref } = prev;
    const { href: nextHref } = next;
    if (this.state.searchedResult) {
      this.setState({ page });
    } else {
      let url = '';
      if (this.state.page < page) {
        url = nextHref;
      } else {
        url = prevHref;
      }
      if (url) {
        this.fetchPaginatedHub(url);
      }
    }
  };

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    // Is empty does not word for numbers so if condition
    if (typeof value === 'number') {
      if (value === 0) {
        this.setError(key, true);
        return false;
      }
    } else {
      if (isEmpty(value)) {
        this.setError(key, true);
        return false;
      }
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const { postData = {} } = this.state;
    const { name, inchargeName, clManagerName, warehouseId, latitude, longitude } = postData;
    const hub = {};
    hub.name = name;
    hub.inchargeName = inchargeName;
    hub.clManagerName = clManagerName;
    hub.warehouseId = warehouseId;
    hub.latitude = latitude;
    hub.longitude = longitude;
    let isValid = true;

    const validateEntities = ['name', 'warehouseId', 'inchargeName', 'clManagerName', 'latitude', 'longitude'];

    for(const property in hub) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, hub[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

  createNewHub = () => {
    if (!this.isFormValid()) {
      return;
    }
    const postData = { ...this.state.postData };
    if (postData.id) {
      apiCall.sendRequest('patch', `/api/v1/hub/${postData.id}`, postData)
        .then((response) => {
          console.log(response.data);
          this.fetchAllHubs();
          this.handleNewHubClose();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // apiCall.sendRequest('post', '/api/v1/hub', postData)
      apiCall.sendRequest('post', createHubIdPath, postData)
        .then((response) => {
          console.log(response.data);
          this.fetchAllHubs();
          this.handleNewHubClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  deleteHub = (id) => {
    apiCall.sendRequest('delete', `api/v1/hub/${id}`)
      .then((response) => {
        console.log(response && response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { rowsPerPage, page, hub, rowsPerPageOptions, postData = {}, selectedHub = {}, userType } = this.state;
    const {
      name = 'default', warehouseId = -1, warehouseName = '', incharge = -1, inchargeName = '', clManagerName = ''
    } = selectedHub;
    return (
      <div>
        <NavBar />
        <HubSearchBar
          onSearch={this.onSearch}
          openAddHub={this.openAddHub}
          query={this.state.filters.query}
          handleQueryChange={this.handleQueryChange}
        />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
              <HubEnhancedTableHead />
              <TableBody>
                {this.state.hub.map(n => (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(n)}
                    tabIndex={-1}
                    key={n.id}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    selected={this.state.selectedHub && this.state.selectedHub.id === n.id}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      {n.name}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.warehouseName}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.inchargeName}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.clManagerName}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <span>
                        <Button variant="outlined" size="small" color="secondary" onClick={(event) => { event.stopPropagation(); this.editHub(n); }}>EDIT</Button>
                      </span>
                      {/* &emsp;|&emsp;
                      <span>
                        <Button size="small" color="secondary" disabled onClick={(event) => { event.stopPropagation(); this.deleteHub(n.id); }}>DELETE</Button>
                      </span> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {(this.state.searchedResult) ?
              <TablePagination
                component="div"
                count={hub.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
              : <TablePagination
                component="div"
                count={hub.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  disabled: !this.state.links.prev,
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  disabled: !this.state.links.next,
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            }
          </div>
          <div style={styles.paperRight}>
            {Object.keys(this.state.selectedHub).length
              ?
              <div>
                <Typography variant="body2" color="inherit" style={{ borderBottom: 'solid 1px #e0e0e0', height: '40px' }}>
                  Hub Details
                  </Typography>
                <Typography variant="body1" color="secondary" style={{ marginTop: '10px' }}>
                  {name}
                </Typography>
                <Typography variant="body1" color="inherit">
                  Warehouse Id: {warehouseId}
                </Typography>
                <Typography variant="body1" color="inherit">
                  Warehouse Name: {warehouseName}
                </Typography>
                <Typography variant="body1" color="inherit">
                  Incharge Id: {incharge}
                </Typography>
                <Typography variant="body1" color="inherit">
                  Incharge Name: {inchargeName}
                </Typography>
                <Typography variant="body1" color="inherit">
                  CL Manager Name: {clManagerName}
                </Typography>
              </div>
              : <div />}
          </div>
        </div>
        <UserSelection userType={userType} openSearchBox={this.state.openUserSelector} handleClose={this.handleClose} selectUser={this.state.openAddHub ? this.selectNewUser : this.selectUser} />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openAddHub}
          onClose={this.handleNewHubClose}
        >
          <div style={{
            background: 'white',
            width: '70%',
            height: '60%',
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleNewHubClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="subheading" color="default">
                      {this.state.isEdit ? 'Update Hub' : 'Create Hub'}
                    </Typography>
                  </Row>
                  <Row>
                  <Typography variant="caption" color="error">
                    *Required
                  </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        value={postData.name || ''}
                        name="name"
                        fullWidth
                        label="Hub Name"
                        required
                        error={this.state.error.name}
                        onChange={(event) => { this.handleChange(event); }}
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        value={postData.inchargeName || ''}
                        name="inchargeName"
                        fullWidth
                        required
                        error={this.state.error.inchargeName}
                        onChange={(event) => { this.handleChange(event); }}
                        onClick={this.openUserSelector('Supervisor')}
                        label="Incharge Name"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        value={postData.clManagerName || ''}
                        name="clManagerName"
                        fullWidth
                        required
                        error={this.state.error.clManagerName}
                        onChange={(event) => { this.handleChange(event); }}
                        onClick={this.openUserSelector('CL Manager')}
                        label="CL Manager Name"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <FormControl variant="filled">
                        <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Warehouse *</InputLabel>
                        <Select
                          style={{ marginBottom: '2px', width: '400px' }}
                          value={postData.warehouseId || ''}
                          error={this.state.error.warehouseId}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'warehouseId',
                            id: 'select-destination',
                          }}
                          >
                          {this.state.warehouseList.map((warehouse, index) => (<MenuItem key={index} value={warehouse.id}> {warehouse.name} </MenuItem>))}
                        </Select>
                      </FormControl>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <TextField
                          margin="dense"
                          value={postData.latitude || ''}
                          name="latitude"
                          type="number"
                          fullWidth
                          label="Latitude"
                          required
                          error={this.state.error.latitude}
                          onChange={(event) => { this.handleChange(event); }}
                        />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                          margin="dense"
                          value={postData.longitude || ''}
                          name="longitude"
                          type="number"
                          fullWidth
                          label="Longitude"
                          required
                          error={this.state.error.longitude}
                          onChange={(event) => { this.handleChange(event); }}
                        />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button style={{ marginRight: '2em' }} color="secondary" onClick={this.handleNewHubClose}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.createNewHub();
                }}
                variant="contained"
                color="secondary"
              >
                {this.state.isEdit ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default HubsComponent;
