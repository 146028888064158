export default {
  ongoing: {
    marginBottom: '32px',
  },
  upcoming: {
    marginBottom: '32px',
  },
  title: {
    fontSize: '1em',
  },
  load: {
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0 0',
  },
  btn: {
    width: '250px',
  },
};
