import { FormControl, FormControlLabel, Radio, RadioGroup, Modal, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Colors from '../../../utils/Colors';
import API, { agentById, agentStatusById } from '../../../api/NetworkHandler';

const styles = {
  container: {
    position: 'absolute',
    right: 0,
    background: Colors.white,
    boxShadow: `1px 1px 2px 0px ${Colors.lightPrimary}`,
    paddingLeft: 10,
  },
  modal: {
    width: '30%',
    minWidth: 200,
    background: '#FFF',
    margin: '20% auto',
    outline: 'none',
    borderRadius: 4,
    padding: 20,
    textAlign: 'center',
  },
};
class AgentStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentStatus: '',
    };
  }

  componentDidMount() {
    this.getAgentStatus();
  }


  getAgentStatus = async () => {
    const { user: { id } = {} } = this.props;
    const res = await API.sendRequest('get', agentById(id));
    const { data } = res;
    this.setState({
      agentStatus: data.status,
    });
  }

  upateAgentStatus = async (value) => {
    const { user: { id } = {} } = this.props;
    await API.sendRequest('put', agentStatusById(id, value));
    this.getAgentStatus();
  }

  render() {
    const { user } = this.props;
    const { agentStatus } = this.state;
    const showPopup = (!agentStatus || agentStatus === 'OFFLINE') && user.role !== 'A';
    return (
      <div>
        <FormControl
          component="fieldset"
          style={styles.container}
        >
          <RadioGroup
            name="status"
            value={agentStatus}
            onChange={e => this.upateAgentStatus(e.target.value)}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value="ONLINE" control={<Radio />} label="Online" />
            <FormControlLabel value="AWAY" control={<Radio />} label="Away" />
            <FormControlLabel value="OFFLINE" control={<Radio />} label="Offline" />
          </RadioGroup>
        </FormControl>
        <Modal
          open={showPopup}
          onClose={this.handleClose}
        >
          <div style={styles.modal}>
            <div style={{ textAlign: 'left' }}> Please go online to start working on tickets.</div>
            <br />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              style={{ marginRight: 10 }}
              onClick={() => this.upateAgentStatus('ONLINE')}
            >Go Online
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
            ><a href="/" style={{ textDecoration: 'none', color: Colors.white }}>Main Page</a>
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

AgentStatus.propTypes = {
  user: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    user: User,
  };
};

export default connect(mapStateToProps, null)(AgentStatus);
