import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
const columnData = [
  {
    id: 'product', numeric: false, disablePadding: true, label: 'Product',
  },
  {
    id: 'unit', numeric: false, disablePadding: true, label: 'Unit Measure',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'price', numeric: false, disablePadding: true, label: 'Unit Price',
  },
  {
    id: 'tax', numeric: false, disablePadding: true, label: 'Tax',
  },
  {
    id: 'discount', numeric: false, disablePadding: true, label: 'Discount',
  },
  {
    id: 'total_price', numeric: false, disablePadding: true, label: 'Total Price',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Item Status',
  },
  {
    id: 'deliveredQuantity', numeric: false, disablePadding: true, label: 'Delivered Quantity',
  },
  {
    id: 'complaintReason', numeric: false, disablePadding: true, label: 'Complaint Reason',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Time Updated',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [],
    };
  }

  componentWillMount() {
    const { items } = this.props;
    this.setState({
      data: items,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { items = [] } = nextProps;
    return {
      data: items,
    };
  }
 
  handleChange = (event, selectedItem, key, index) => {
    const { value } = event.target;
    const { onChange } = this.props;
    const { data } = this.state;
    const { isAdded = false, quantity } = selectedItem; 
    if (["status", "complaintReason"].includes(key)) {
      if (key === 'status' && value === 'NOT_DELIVERED') {
        selectedItem = {
          ...selectedItem,
          deliveredQuantity: 0,
          [key]: value,
        };
      } else {
        selectedItem = {
          ...selectedItem,
          [key]: value,
        };
      }
      data[index] = selectedItem;
      onChange(data);
    } else {
      if (value < 0) return;
      if (quantity > 0 && value > quantity) return;
      selectedItem = {
        ...selectedItem,
        [key]: value,
      };
      data[index] = selectedItem;
      onChange(data);
    }
  };

  isSelected = id => this.state.selected === id;

  getLineItemStatusChangePermission = (productItemId) => {
    const { initialData } = this.props;
    let isDisable = false;
    initialData.customerOrderLineItems.forEach(el => {
      if (el.productItemId == productItemId && el.status === "DELIVERED") {
        isDisable = true;
      }
    });
    return isDisable;
  };

  render() {
    const { classes, crErrorMessage, statusErrorMessage, pathname, isEditingAllowed, removeItem } = this.props;
    const { data } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader columns={columnData}/>
            <TableBody>
              {data.map((n, i) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <React.Fragment key={n.productItemId}>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      selected={isSelected}
                    >
                      <TableCell style={{ color: Colors.primary, width: window.innerWidth * 0.2 }} scope="row" padding="none">
                        <div style={{ flex: 4 }}>
                          <TextField
                            value={n.productName}
                            style={{ width: window.innerWidth * 0.19 }}
                            disabled={!n.isAdded}
                          />
                        </div>
                      </TableCell>
                      <TableCell style={{ color: Colors.primary }} scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '50px' }}
                            value={n.unitMeasure}
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '50px' }}
                            value={n.quantity ? n.quantity : 0}
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '70px' }}
                            value={n.unitPrice ? n.unitPrice : 0}
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '70px' }}
                            value={n.tax}
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '50px' }}
                            value={n.discount}
                            disabled
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            style={{ width: '80px' }}
                            value={((n.deliveredQuantity) * n.unitPrice).toFixed(2)}
                            disabled={['TEMPORARILY_UNAVAILABLE','OUT_OF_STOCK'].includes(n.complaintReason)}
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>
                        <Tooltip title={this.props.COLineStatuses[n.status]}>
                          <Select
                            onChange={e => this.handleChange(e, n, 'status', i)}
                            style={{ width: "120px" }}
                            value={n.status ? n.status : 'none'}
                            disabled={(pathname.split('/').length === 4) || (!isEditingAllowed && this.getLineItemStatusChangePermission(n.productItemId)) || n.isAdded}
                          >
                          { Object.keys(this.props.COLineStatuses).filter(n => ['NOT_DELIVERED', 'DELIVERED', 'PENDING'].includes(n)).map((status, i) => (<MenuItem key={i} value={status}>{this.props.COLineStatuses[status]}</MenuItem>)) }
                          </Select>
                          </Tooltip>
                        </div>
                        <small style={{ color: 'red' }}>{n.quantity != n.deliveredQuantity && !n.status ? statusErrorMessage : null}</small>
                      </TableCell>
                      
                      <TableCell scope="row" padding="none" component="th" style={{ width: "80px" }}>
                        <div style={{ flex: 1 }}>
                          <TextField
                            type="number"
                            id="delivered-quantity"
                            name="deliveredQuantity"
                            style={{ width: '70px' }}
                            value={n.deliveredQuantity}
                            onChange={e => this.handleChange(e, n, 'deliveredQuantity', i)}
                            error={n.isAdded && n.deliveredQuantity < 1}
                            helperText={(n.isAdded && n.deliveredQuantity < 1) && "minimum 1"}
                            disabled={(n.status === 'NOT_DELIVERED' ? true : false) || (pathname.split('/').length === 4) || (!isEditingAllowed && this.getLineItemStatusChangePermission(n.productItemId))}
                          />
                        </div>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 2 }}>
                        <Tooltip title={this.props.complaintReasons[n.complaintReason]}>   
                          <Select
                            onChange={e => this.handleChange(e, n, 'complaintReason', i)}
                            value={n.complaintReason ? n.complaintReason : 'none'}
                            disabled={(pathname.split('/').length === 4)||['TEMPORARILY_UNAVAILABLE','OUT_OF_STOCK'].includes(n.complaintReason)}
                            error={n.isAdded}
                            style={{ width: '150px' }}
                          >
                            <MenuItem value={null}>
                              <em>None</em>
                            </MenuItem>
                            { Object.keys(this.props.complaintReasons)
                            .filter(key => (!['TEMPORARILY_UNAVAILABLE','OUT_OF_STOCK'].includes(key)
                            || ['TEMPORARILY_UNAVAILABLE','OUT_OF_STOCK'].includes(n.complaintReason))
                            ).map((x, i) => (<MenuItem key={i} value={x}>{this.props.complaintReasons[x]}</MenuItem>)) }
                          </Select>
                          </Tooltip>
                           </div>
                        <Typography color="error" variant="caption">{n.quantity != n.deliveredQuantity && (!n.complaintReason || n.complaintReason == 'none') ? crErrorMessage : ''}</Typography>
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        <div style={{ flex: 1 }}>{moment(n.timeUpdated).format('lll')}</div>
                      </TableCell>
                      <TableCell style={{ width: '50px' }} component="th" scope="row" padding="none">
                        <IconButton disabled={!n.isAdded} onClick={(e) => { e.stopPropagation(); removeItem(n)}}>
                          <DeleteIcon color={n.isAdded ? "secondary" : "action"} variant="outlined" />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  UOM: state.UOM,
  COLineStatuses: state.Status.colineStatuses,
  deliveryRemarks: state.Status.deliveryRemarks,
  complaintReasons: state.Status.complaintReasons,
});

export default connect(mapStateToProps, null)(withStyles(styles)(EnhancedTable));

