import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import BeenhereIcon from 'material-ui-icons/Beenhere';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import apiCall, { getInventoryProductItems } from "../api/NetworkHandler";
import StockInventorySearchBar from './StockInventorySearchBar';
import StockInventoryTable from './StockInventoryTable';
import NavBar from './NavBar.js';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { ColMod, ListMod, DisplayMod1, CodeStyleMod, DividerMod, PaperMod } from './lib';
import WarehouseSelector from './Warehouse/Selector';
import Drawer from '@material-ui/core/Drawer';
import UpdateStockForm from './StockLedger/UpdateStockForm';

const styles = {
  div:{
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft:{
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10
  },
  paperRight:{
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  }
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      rowsPerPage: 10,
      totalElements: 0,
      filter: {},
      isWarehouseSelected: false,
      selectedWarehouse: null,
      searchTerm: '',
      openStockForm: false,
      stockInventory: null,
      refreshItems: {}
    };
  }

  fetchProductItems = async () => {
    const { page, rowsPerPage, searchTerm, selectedWarehouse: { value: warehouseId } } = this.state;
    try {
      const response = await apiCall.sendRequest('get', getInventoryProductItems(warehouseId, searchTerm, page, rowsPerPage));
      const { data: { data: { content, totalElements } } } = response;
      const productItems = sortBy(content, "productItemName");
      const refreshItems = productItems.reduce((refreshItemIds, productItem) => {
        refreshItemIds[productItem.productItemId] = true;
        return refreshItemIds;
      }, {})

      this.setState({
        refreshItems: {}
      }, () => {
        this.setState({
          data: productItems,
          totalElements,
          refreshItems,
        })
      })
    } catch (error) {
      console.log(error);
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchProductItems() });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.fetchProductItems() });
  }

  onchangeWarehouse = (selectedWarehouse) => {
    this.setState({ selectedWarehouse });
  }

  selectWarehouse = () => {
    this.setState(prevState => {
      const { selectedWarehouse } = prevState;

      return {
        isWarehouseSelected: selectedWarehouse ? true : false,
      }
    }, async () => {
      const { selectedWarehouse } = this.state;
      if (selectedWarehouse) {
        this.fetchProductItems();
      }
    })
  }

  onChangeSearch = (event) => {
    const { value: searchTerm } = event.target;
    this.setState({ searchTerm, page: 0 });
    this.onSearch();
  }

  onClearSearch = () => {
    this.setState({
      searchTerm: '',
      page: 0,
    }, () => { this.fetchProductItems(); });
  }

  onSearch = debounce(() => {
    this.fetchProductItems();
  }, 1000)

  onUpdateStock = (stockInventory) => {
    this.setState({
      openStockForm: true,
      stockInventory
    })
  }

  closeStockForm = () => {
    this.setState({
      openStockForm: false,
      stockInventory: null,
    });
  }

  onRefresh = (productItemId = null) => {
    const refreshItemIds = { ...this.state.refreshItems };
    if (productItemId) {
      this.setState(prevState => {
        const { refreshItems } = prevState;

        return {
          refreshItems: {
            ...refreshItems,
            [productItemId]: false,
          }
        }
      }, () => {
        this.setState({ refreshItems: refreshItemIds })
      })
    } else {
      this.setState({
        refreshItems: {},
      }, () => {
        this.setState({ refreshItems: refreshItemIds })
      })
    }
  }

  render() {
    const { isWarehouseSelected, selectedWarehouse, searchTerm, rowsPerPage, openStockForm, stockInventory, refreshItems } = this.state;
    return (
      <div>
        <NavBar />
        {isWarehouseSelected && <React.Fragment>
          <StockInventorySearchBar
            searchTerm={searchTerm}
            onChangeSearch={this.onChangeSearch}
            onClearSearch={this.onClearSearch}
            warehouse={selectedWarehouse ? selectedWarehouse.label : ''}
            onRefresh={this.onRefresh}
          />
          <div style={styles.div}>
            <div style={styles.paperLeft}>
              <StockInventoryTable
                data={this.state.data}
                page={this.state.page}
                totalElements={this.state.totalElements}
                handleChangePage={this.handleChangePage}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                onUpdateStock={this.onUpdateStock}
                refreshItems={refreshItems}
                warehouseId={selectedWarehouse.value}
              />
            </div>
          </div>
          <Drawer anchor="right" open={openStockForm} onClose={this.closeStockForm}>
            <UpdateStockForm
              onClose={this.closeStockForm}
              onRefresh={this.onRefresh}
              stockInventory={stockInventory}
              warehouseName={selectedWarehouse.label || ''}
            />
          </Drawer>
        </React.Fragment>}
        <Modal open={!isWarehouseSelected} style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Grid fluid style={{
            background: 'white',
            width: window.innerWidth < 575 ? window.innerWidth * 0.9 : window.innerWidth * 0.3,
            margin: '2em'
          }}>
            <Row xs={12}>
              <ColMod xs={12}>
                <Typography variant="title">
                  Select Warehouse
                </Typography>
              </ColMod>
              <ColMod xs={12}>
                <WarehouseSelector
                  onSelect={this.onchangeWarehouse}
                  selected={selectedWarehouse}
                />
              </ColMod>
            </Row>
            <Row xs={12} end="xs">
              <ColMod xs={5}>
                <Button color="secondary" variant="contained" onClick={this.selectWarehouse}>
                  Select
                </Button>
              </ColMod>
            </Row>
          </Grid>
        </Modal>
      </div>
    );
  }
}


export default Dashboard;
