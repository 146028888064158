import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 2,
  },
  calendar: {
    flex: 1,
    textAlign: 'center',
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 5,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: '#f50057',
  },
};

function HeaderSearchBar(props) {
  const { query, handleQueryChange, onSearch } = props;
  return (
    <div style={styles.div}>
      <div style={styles.title}>
        <Typography variant="title" color="inherit">
          Hub
        </Typography>
      </div>
      <div style={styles.searchBar}>
        <div style={styles.searchContainer}>
          <div style={styles.searchField}>
            <TextField
              id="input-with-icon-textfield"
              label="Quick Search"
              fullWidth
              value={query}
              onChange={handleQueryChange}
              onKeyPress={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div style={styles.add}>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => {
            props.openAddHub(null);
          }}
        >
          New Hub
        </Button>
      </div>
    </div>
  );
}

export default HeaderSearchBar;
