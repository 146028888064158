import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PaginatedTableHeader from './PaginatedTableHeader';
import { Grid } from 'react-flexbox-grid';
import moment from 'moment';
import NoData from '../../../components/States/NoData';

const getTableDataFromFetchedData = (data, columnData, customComponents) => {
  // customComponents = { propertyName: ReactComponent } propertyName must match one of the column name

  const columns = columnData.reduce((columns, columnObj) => {
    columns.push(columnObj.id);
    return columns;
  }, []);

  return data && data.reduce((tableData, dataObj, index) => {
    const tableDataObj = {};
    columns.forEach((column) => {
      if (!customComponents.hasOwnProperty(column)) {
        tableDataObj[column] = dataObj[column];
      } else {
        tableDataObj[column] = customComponents[column][index];
      }
    });
    tableData.push(tableDataObj);
    return tableData;
  }, []);
};

export default function PaginatedTable(props) {
  const {
    data,
    columnData,
    actions,
    count,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    onRequestSort,
    order,
    orderBy,
    customComponents,
    showAdditionalRow,
    getAdditionalRows,
    onClickRow,
    handleClickRow,
    selectedindex,
    searchTerm,
    isLoading,
    isNoData,
  } = props;

  const tableData = getTableDataFromFetchedData(data, columnData, customComponents);

  return (
    <Grid fluid>
      {data && data.length > 0 && <div>
        <Table>
          <PaginatedTableHeader
            columns={columnData}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {
              tableData && tableData.map((dataObject, dataIndex) => {
                return (
                  <React.Fragment key={dataIndex}>
                    <TableRow onClick={() => onClickRow && handleClickRow(dataObject, dataIndex)} selected={selectedindex === dataIndex}>
                      {Object.keys(dataObject).map((key, index) => {
                        if (key == 'actions') {
                          return null;
                        }
                        return (
                          <TableCell key={index} component="td" scope="row" padding="none">
                            {key == 'timeUpdated' ?
                              moment(dataObject[key]).format('DD-MM-YYYY hh:mm a')
                              : dataObject[key]}
                          </TableCell>
                        )
                      })}
                      {actions && <TableCell component="td" scope="row" padding="none">{actions(data[dataIndex])}</TableCell>}
                    </TableRow>
                    {showAdditionalRow && getAdditionalRows(dataIndex)}
                  </React.Fragment>
                )
              })
            }
          </TableBody>
        </Table>
        {!isNoData && 
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
        />}
      </div>}
      { data && data.length === 0 && !isLoading && (searchTerm || isNoData) && <NoData searchTerm={ searchTerm } />}
    </Grid>
  );
}

PaginatedTable.propTypes = {
  data: PropTypes.array,
  columnData: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  actions: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  customComponents: PropTypes.object,
  showAdditionalRow: PropTypes.bool,
  getAdditionalRows: PropTypes.func,
  onClickRow: PropTypes.bool,
  selectedindex: PropTypes.number,
  handleClickRow: PropTypes.func,
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
};

PaginatedTable.defaultProps = {
  data: [],
  columnData: [],
  count: 0,
  rowsPerPage: 25,
  page: 0,
  handleChangePage: () => { },
  handleChangeRowsPerPage: () => { },
  onRequestSort: () => { },
  order: 'asc',
  orderBy: '',
  customComponents: {},
  showAdditionalRow: false,
  onClickRow: false,
  selectedindex: null,
  handleClickRow: () => {},
  searchTerm: '',
  isLoading: false,
  isNoData: false,
};
