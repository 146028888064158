import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import TableBuilder from '../TableBuilder';
import styles from './styles';

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      label,
      query,
      onSearchChange,
      onClearSearch,
      data,
      onSelectRow,
      tableConfig,
      onKeyPress,
      emptyResult,
      errText,
    } = this.props;
    return (
      <div>
        <TextField
          fullWidth
          label={label}
          style={styles.searchBar}
          value={query}
          onChange={onSearchChange}
          onKeyPress={onKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {' '}
                <Search />{' '}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start" onClick={onClearSearch}>
                {' '}
                <Clear />{' '}
              </InputAdornment>
            ),
          }}
        />
        {emptyResult && <span style={{ fontSize: '16px', color: 'red', width: '50%', margin: '0 auto' }}>Sorry, we can't find anything for this region...</span>}
        <span style={{ fontSize: '13px', color: 'red', width: '50%', margin: '0 auto' }}>{errText || ''}</span>
        <TableBuilder
          config={tableConfig}
          data={data}
          onRowClick={onSelectRow}
          pagination={false}
        />
      </div>
    );
  }
}

QuickSearch.propTypes = {
  label: PropTypes.string,
  query: PropTypes.string,
  onSearchChange: PropTypes.func,
  onClearSearch: PropTypes.func,
  data: PropTypes.instanceOf(Array),
  onSelectRow: PropTypes.func,
  onKeyPress: PropTypes.func,
  tableConfig: PropTypes.instanceOf(Object),
};

export default QuickSearch;
