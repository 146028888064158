import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { uniqWith, isEqual, isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { ColMod } from '../../../components/lib';
import CustomerPlanItemTable from './CustomerPlanItemTable';
import NavBar from '../../../components/NavBar';
import CreateSchedule from './CreateSchedule';
import apiCall,
{
  getPlanItemsPath,
  customerAddressesPath,
  savePlansPath,
  getCategoryPath,
  schedulesPath,
  editPlanItem,
  customerAccountPath,
  customerProfileById,
} from '../../../api/NetworkHandler';
import PlanDateHelper from '../../../utils/PlanDate';
import DeliveryContainer from '../../Customers/Container/DeliveryContainer';


const COMPONENT_TITLE = 'Customer Plans And Deliveries';
const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    background: '#f0f0f0',
    height: '56px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
};


const EMPTY_ITEM = {
  categoryId: null,
  productName: '', // not to send
  productItemId: 0,
  scheduleId: '',
  quantity: 1,
  scheduleType: '',
  startDate: moment().format('YYYY-MM-DD'),
  status: 'ACTIVE',
  planName: '', // not to send
  unitPrice: 0, // not to send
  newEntry: true,
  unitMeasure: '',
  code: '',
};

const defaultPlans = {
  ALTERNATE_DAYS: 'Alternate Days',
  DAILY: 'Daily',
  WEEKDAYS: 'Weekdays',
  WEEKENDS: 'Weekends',
};


class CreateCustomerPlan extends Component {
  constructor(props) {
    super(props);
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { customerId } = params;
    this.state = {
      items: [],
      customerAddress: {},
      customerId,
      customerSchedules: [],
      createPlanPopup: false,
      selectedItemIndex: '',
      addressId: '',
      isCustomerPaused: false,
      profile: {},
    };
  }


  componentDidMount() {
    this.getPlanItemsAndSchedules();
    this.getCustomerAccountInfo();
    this.getCustomerProfile();
  }

  onAddItem = () => {
    const { items } = this.state;
    const itemFind = items.find(product => product.productItemId === 0);
    if (!itemFind) {
      items.push(EMPTY_ITEM);
      this.setState({
        items,
      });
    }
  }


  onProductSelection = async (selectedItem) => {
    const { items } = this.state;
    const { name } = selectedItem;
    const productName = name.substring(0, name.lastIndexOf('-'));
    try {
      const category = await apiCall.sendRequest('get', getCategoryPath(encodeURIComponent(productName)));
      const { data: { data: categoryId } } = category;
      if (items.length > 0) {
        const lastIndex = items.length - 1;
        const lastItem = items[items.length - 1];
        items[lastIndex] = {
          ...lastItem,
          categoryId,
          unitPrice: selectedItem.unitPrice,
          productItemId: selectedItem.id,
          productName: selectedItem.name,
          cutOffTime: selectedItem.cutOffTime,
        };
        this.setState({
          items,
        });
      }
    } catch (e) {
      console.log('ERROR : ', e);
    }
  }

  onCreateSchedule = (itemIndex) => {
    this.setState({
      createPlanPopup: true,
      selectedItemIndex: itemIndex,
    });
  }


  onModify = (product) => {
    const {
      quantity, unitMeasure, code, scheduleId, scheduleType, planItemId, pauseFrom = null, pauseTill = null, startDate,
    } = product;
    const body = {
      quantity,
      scheduleId,
      scheduleType,
      id: planItemId,
      pauseFrom,
      pauseTill,
      startDate,
      unitMeasure,
      code,
    };

    const { customerId, addressId } = this.state;
    apiCall.sendRequest('post', editPlanItem(scheduleId, customerId, planItemId, addressId), body)
      .then((response) => {
        console.info(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onDelete = (product) => {
    const {
      scheduleId, planItemId,
    } = product;
    const { customerId, addressId } = this.state;
    apiCall.sendRequest('delete', editPlanItem(scheduleId, customerId, planItemId, addressId))
      .then((response) => {
        console.info(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

   // Checks if pre-defined plan is registered in DB
   getDefaultSchedules = customerSchedules => Object.keys(defaultPlans).map((planName) => {
     const existingPlanIndex = customerSchedules.findIndex((eachUserPlan) => {
       const { name = '' } = eachUserPlan;
       return name.toUpperCase() === planName.replace('_', ' ');
     });
     if (existingPlanIndex === -1) {
       return { scheduleType: planName, name: defaultPlans[planName] };
     }
     return customerSchedules[existingPlanIndex];
   });


  getCustomerSchedules = async (customerId) => {
    const response = await apiCall.sendRequest('get', schedulesPath(customerId));
    let { data: { data: customerSchedules } } = response;
    const defaultSchedules = this.getDefaultSchedules(customerSchedules);
    customerSchedules = [...customerSchedules, ...defaultSchedules];
    this.setState({
      customerSchedules: uniqWith(customerSchedules, isEqual),
    });
  }

  getFormattedAddress = (address) => {
    const {
      cityName = '',
      buildingName = '',
      blockName = '',
      floor = '',
      fullAddress = '',
      houseNumber = '',
      landmark = '',
      localityName = '',
      pincode = '',
      streetDetails = '',
      id: addressId,
    } = address;
    if (fullAddress === null || isEmpty(fullAddress)) {
      return `${houseNumber ? `${houseNumber},` : ''} ${floor ? `${floor} floor, ` : ''}${
        buildingName ? `${buildingName},` : ''
      } ${blockName ? `${blockName},` : ''}${streetDetails ? `${streetDetails}, ` : ''}${
        landmark ? `Landmark: ${landmark},` : ''
      }${localityName ? `${localityName},` : ''} ${cityName || ''}, ${pincode || ''}`;
    }
    return fullAddress;
  };


  getPlanItems = async () => {
    const { customerId, customerAddress } = this.state;
    const { id: addressId } = customerAddress;
    const url = getPlanItemsPath(customerId, addressId);
    const response = await apiCall.sendRequest('get', url);
    if (response && response.data) {
      const { data: { data } } = response;
      this.setState({
        items: this.formatThePlanData(data),
      });
    }
  }

  getPlanItemsAndSchedules = () => {
    const { customerId } = this.state;
    this.fetchCustomerAddressAndPlanItems(customerId);
    this.getCustomerSchedules(customerId);
  }

  getCustomerProfile = async () => {
    const { customerId } = this.state;
    const url = customerProfileById(customerId);
    const response = await apiCall.sendRequest('get', url);
    const { data: { data = {} } } = response;
    this.setState({
      profile: data,
    });
  };


  getCustomerAccountInfo = async () => {
    const { customerId } = this.state;
    const url = customerAccountPath(customerId);
    try {
      const response = await apiCall.sendRequest('get', url);
      if (response && response.data) {
        const { data: { data = {} } } = response;
        const customerStatus = this.customerPauseStatus(data);
        this.setState({
          isCustomerPaused: customerStatus,
        });
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  customerPauseStatus = (customerStatus) => {
    const { status } = customerStatus;
    const { pauseFrom, pauseTill } = customerStatus;
    if (status === 'AUTO_PAUSED') {
      return true;
    }
    if (pauseFrom && pauseTill) {
      if (moment().isBetween(pauseFrom, pauseTill, null, '[]')) return true;
    }
    return false;
  };

  createCustomerSchedule = async (scheduleData) => {
    const { customerId, selectedItemIndex } = this.state;
    const url = schedulesPath(customerId);
    const response = await apiCall.sendRequest('post', url, scheduleData);
    const { data: { data } } = response;
    const [schedule] = data;
    this.handleItemChange(selectedItemIndex, 'frequency', schedule);
    this.getCustomerSchedules(customerId);
    this.setState({
      createPlanPopup: false,
    });
  }

formatThePlanData = (categoriesData = []) => {
  let formattedItems = [];
  categoriesData.forEach((eachCateogry) => {
    const {
      category = {}, items, pauseFrom: categoryPauseFrom, pauseTo, paused,
    } = eachCateogry;
    items.forEach((eachItemInCategory) => {
      const { plan, product } = eachItemInCategory;
      const {
        planItem: {
          quantity, startDate, status, id: planItemId, schedule = {}, pauseFrom, pauseTill,
        }, scheduleType,
      } = plan;
      const { id: scheduleId, customFrequency, daysOfWeek } = schedule;
      const { productItems, name: productName } = product;
      const [productItem] = productItems;
      const { uom, cutOffTime } = productItem;
      formattedItems = [
        ...formattedItems,
        {
          planName: plan.name, // plan -> name
          productItemId: productItem.id, // product -> productItems -> id
          productName: `${productName}-${productItem.unitMeasure}${uom.code}`, // product ->  name
          categoryId: category.id, // category->id
          scheduleId, // plan -> id
          quantity, // plan -> planItem -> quantity
          scheduleType, // plan -> scheduleType
          startDate, // plan -> planItem -> startDate
          status, // plan -> planItem -> status
          unitPrice: productItem.sellingPrice, //  product -> productItems -> sellingPrice
          planItemId,
          cutOffTime,
          customFrequency,
          daysOfWeek,
          pauseFrom,
          pauseTill,
          categoryPauseFrom,
          categoryPauseTill: pauseTo,
          categoryPause: paused,
          unitMeasure: productItem.unitMeasure,
          code: uom.code,
        },
      ];
    });
  });
  return formattedItems;
}

  fetchCustomerAddressAndPlanItems = (customerId) => {
    apiCall.sendRequest('get', customerAddressesPath(customerId))
      .then((response) => {
        const defaultAddressOfCustomer = response.data.find(address => address.default === true);
        const { id: addressId } = defaultAddressOfCustomer;
        this.setState({
          customerAddress: defaultAddressOfCustomer, addressId,
        }, () => {
          this.getPlanItems();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeItem = (index, product) => {
    const { items } = this.state;
    items.splice(index, 1);
    this.setState({
      items,
    }, () => this.onDelete(product));
  }

  handleItemChange = (itemIndex, key, value) => {
    const { items } = this.state;
    if (key === 'frequency') {
      const { id = '', scheduleType, name } = value;
      const productInfo = items[itemIndex];
      const { cutOffTime, customFrequency, daysOfWeek } = productInfo;
      const currentDate = moment().format('YYYY-MM-DD');
      const startDate = PlanDateHelper.getPlanDateOfProductFor(currentDate, {
        scheduleType, customFrequency, daysOfWeek,
      }, cutOffTime, true);
      items[itemIndex].scheduleId = id;
      items[itemIndex].scheduleType = scheduleType;
      items[itemIndex].planName = name;
      items[itemIndex].startDate = moment(startDate).format('YYYY-MM-DD');
    } else {
      items[itemIndex][key] = value;
    }
    this.setState({
      items,
    });
  }

  handleSave = (product) => {
    const { customerId, customerAddress } = this.state;
    const { id: addressId } = customerAddress;
    const url = savePlansPath(customerId, addressId);
    const {
      unitPrice, productName, planName, ...rest
    } = product;
    const planItems = [rest];
    apiCall.sendRequest('post', url, planItems)
      .then((response) => {
        this.getPlanItemsAndSchedules();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  renderSubTile = title => (
    <h2>{title}</h2>
  )

  render() {
    const {
      customerAddress,
      items,
      customerSchedules,
      createPlanPopup,
      isCustomerPaused,
      customerId,
      profile,
    } = this.state;
    const randomKey = +new Date();
    return (
      <div>
        <NavBar />
        <div style={styles.div}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">
              {COMPONENT_TITLE }
            </Typography>
          </div>
        </div>
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary" >
            <Button color="secondary" onClick={() => { this.props.history.goBack(); }}>Back</Button>
          </Typography>
        </div>
        <Grid>
          <ColMod xs md lg={18}>
            <Row>
              <ColMod lg={12}>
                <Row>
                  <ColMod lg={4}>
                    <TextField
                      label="Customer Id"
                      InputLabelProps={{
                          shrink: true,
                        }}

                      name="customerId"
                      value={this.state.customerId}
                      fullWidth
                      disabled
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="Name"
                      InputLabelProps={{
                          shrink: true,
                        }}

                      name="name"
                      value={profile.name}
                      fullWidth
                      disabled
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="Mobile"
                      InputLabelProps={{
                          shrink: true,
                        }}

                      name="mobile"
                      value={profile.mobile}
                      fullWidth
                      disabled
                    />
                  </ColMod>
                </Row>
                <Row>
                  <DeliveryContainer
                    customerId={customerId}
                  />
                </Row>
                <Row>
                  {this.renderSubTile('Plans')}
                </Row>
                <Row>
                  <div style={{ flex: 1 }}>
                    <CustomerPlanItemTable
                      key={randomKey}
                      schedules={customerSchedules}
                      items={items}
                      removeItem={this.removeItem}
                      onFieldChange={this.handleItemChange}
                      onSelect={this.onProductSelection}
                      onCreateSchedule={this.onCreateSchedule}
                      onModify={this.onModify}
                      handleSave={this.handleSave}
                      isCustomerPaused={isCustomerPaused}
                      customerId={customerId}
                    />
                  </div>
                </Row>
                <Row>
                  <div>
                    <Button onClick={this.onAddItem} color="primary">
                    + Add Plan
                    </Button>
                  </div>
                </Row>
              </ColMod>
            </Row>
          </ColMod>
        </Grid>
        <CreateSchedule
          visible={createPlanPopup}
          onClose={() => { this.setState({ createPlanPopup: false }); }}
          onSubmit={this.createCustomerSchedule}
        />
      </div>
    );
  }
}

CreateCustomerPlan.propTypes = {
  match: PropTypes.object.isRequired,
};

CreateCustomerPlan.defaultProps = {
  // match: {},
};

export default CreateCustomerPlan;
