import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import TableHeader from '../../Commons/components/TableHeader';
import apiCall, {
  purchaseOrderPdfPath,
  getVendorLocationById,
  sendEmailToVendorByPoId,
  getCopiedPODetailsById,
  purchaseOrderCsvPath,
  getWareHouseById
} from '../../../api/NetworkHandler';
import Colors from '../../../utils/Colors';
import { saveAs } from 'file-saver/FileSaver';
import { Modal, Popconfirm, Radio } from 'antd';
import PrintIcon from '@material-ui/icons/Print';
import utils from '../../../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;



const columnData = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'PO Number',
  },
  {
    id: 'vendorLocationName', numeric: false, disablePadding: true, label: 'Vendor Location Name',
  },
  {
    id: 'companyName', numeric: false, disablePadding: true, label: 'Company',
  },
  {
    id: 'totalQuantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'timeCreated', numeric: false, disablePadding: true, label: 'Created Date',
  },
  {
    id: 'receivableDate', numeric: false, disablePadding: true, label: 'Receivable Date',
  },
  {
    id: 'taxRateType', numeric: false, disablePadding: true, label: 'Tax Rate Type',
  },
  {
    id: 'totalPrice', numeric: false, disablePadding: true, label: 'Amount',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const style = {
  cellStyle: {
    textAlign: 'left',
  },
};

var versionDict = {
  "warehouseRequestId": 0,
};

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'desc',
      orderBy: 'id',
      selected: [],
      openEmailDisplay: false,
      vendorName: '',
      vendorEmailId: '',
      poId: null,
      emailResponseMessage: '',
      isLoading: false,
      buttonClicked: false,
      openPrintOption: false,
      selectedOrder: {},
      printType: 'pdf',
      selectedPOCityCode: '',
    };
  }

  handleRequestSort = (event, property) => {
    let { data } = this.props;
    if (['actions', 'status'].includes(property)) {
      return;
    }
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    data =
      order === 'desc'
        ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ order, orderBy });
  };


  handleClick = (event, data) => {
    const { getPurchaseOrder } = this.props;
    this.setState({ selected: data.id });
    getPurchaseOrder(data);
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;

    onRowsPerPageChange(value);
  };

  handlePOPdfDownload = async (orderId, cityName) => {
    let filename = `PO-${cityName.substring(0, 3).toUpperCase()}${orderId}.pdf`;
    const url = purchaseOrderPdfPath(orderId, true);
    try {
      await apiCall.downloadFile(url, filename);
    } catch (e) {
      // error handling
    }
  }

  handlePOCsvDownload = async (orderId, cityName) => {
    let filename = `PO-${cityName.substring(0, 3).toUpperCase()}${orderId}.csv`;
    try {
      const response = await apiCall.sendRequest('get', purchaseOrderCsvPath(orderId), null, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
    } catch (e) {
      // error handling
    }
  }

  handleChangePrintOption = (event) => {
    const { value } = event.target;
    this.setState({
      printType: value,
    });
  }

  handlePrintPO = () => {
    const { printType, selectedOrder, selectedPOCityCode } = this.state;
    const { id: orderId } = selectedOrder;
    if (printType === 'pdf') {
      this.handlePOPdfDownload(orderId, selectedPOCityCode);
      this.handleClosePrintOption();
    } else {
      this.handlePOCsvDownload(orderId, selectedPOCityCode);
      this.handleClosePrintOption();
    }
  }

  fetchVendorLocationDetailsByLocationId = async (warehouseId) => {
    try {
      versionDict.warehouseRequestId += 1;
      let prevRequestId = versionDict.warehouseRequestId;
      const response = await apiCall.sendRequest('get', getWareHouseById(warehouseId));
      const { data: { code = '' } = {} } = response;
      if (prevRequestId == versionDict.warehouseRequestId) {
        this.setState({
          selectedPOCityCode: code || '',
        });
      }
    } catch (err) {
      this.setState({
        selectedPOCityCode: '',
      });
    }
  };

  handleOpenPrintOptions = (selectedOrder) => {
    this.setState({
      openPrintOption: true,
      selectedOrder,
    }, () => this.fetchVendorLocationDetailsByLocationId(selectedOrder.warehouseId));
  }

  handleClosePrintOption = () => {
    this.setState({
      openPrintOption: false,
      selectedOrder: {},
    });
  }

  getVendorEmail = async (locationId) => {
    try {
      const response = await apiCall.sendRequest("get", getVendorLocationById(locationId));
      const { data } = response;
      const { email, name } = data;
      this.setState({
        vendorName: name,
        vendorEmailId: email
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleEmailSend = async () => {
    this.setState({ buttonClicked: true })
    const { poId } = this.state;
    try {
      this.setState({ isLoading: true });
      const response = await apiCall.sendRequest("post", sendEmailToVendorByPoId(poId));
      const { data } = response;
      const { message } = data;
      this.setState({
        emailResponseMessage: message,
      });
      this.setState({ isLoading: false });
      this.handleClose();
    } catch (error) {
      let errorMessage = error.response.data.message;
      this.setState({ isLoading: false, emailResponseMessage: errorMessage });
    }
  }

  openEmailSend = (locationId, poId) => {
    this.getVendorEmail(locationId);
    this.setState({
      openEmailDisplay: true,
      poId: poId,
    });
  }

  handleClose = () => {
    this.setState({
      openEmailDisplay: false,
    });
    if (this.state.buttonClicked) {
      window.location.reload(false);
    }
  }

  isSelected = id => this.state.selected === id

  getEmailStatus = (status, isEmailSent, isEmailEnabled) => {
    let buttonEnable = false;
    if (status === 'ORDERED' && !isEmailSent) {
      buttonEnable = true;
    }
    else if (status === 'CANCELLED' && !isEmailSent && isEmailEnabled) {
      buttonEnable = true;
    }
    else {
      buttonEnable;
    }
    return buttonEnable;
  }

  getVendorEmails = (email) => {
    if (!email) return [];
    else return email.split(',');
  }

  handleOpenSelectedPO = (poId) => {
    const { history } = this.props;
    history.push(`/edit-po/${poId}`);
  }

  fetchCopiedPODetails = async (poId) => {
    try {
      const response = await apiCall.sendRequest('post', getCopiedPODetailsById(poId));
      const { data: { data: { id = null } = {} } = {} } = response;
      this.handleOpenSelectedPO(id);
    } catch (err) {
      console.log(err.response);
    }
  }

  handleConfirmCopy = (poId) => {
    this.fetchCopiedPODetails(poId);
  }

  handlePrintPOPdf = async (poId) => {
    let filename = `PO-${poId}.pdf`;
    const url = purchaseOrderPdfPath(poId, true);
    try {
      await apiCall.downloadFile(url, filename);
    } catch (e) {
      // error handling
    }
  };

  render() {
    const {
      classes, totalElements, page, data, rowsPerPage, rowsPerPageOptions, taxType
    } = this.props;
    const { order, orderBy } = this.state;
    const { openEmailDisplay, vendorEmailId, vendorName, emailResponseMessage, isLoading, openPrintOption, printType } = this.state;
    const vendorEmails = this.getVendorEmails(vendorEmailId);
    const newColumnData = taxType === 'gst' ? columnData : columnData.filter(el => el.id !== 'taxRateType')
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader
              columns={newColumnData}
              cellStyle={style.cellStyle}
              onRequestSort={this.handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {data.map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell style={{ ...style.cellStyle, color: Colors.default }} component="th" scope="row" padding="none">
                      <Link to={`/edit-po/${n.id}`} style={{ textDecoration: "underline", color: "royalblue" }}>
                        {n.id}
                      </Link>
                    </TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.vendorLocationName}</TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.companyName}</TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.totalQuantity}</TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{moment(n.timeCreated).format('LL')}</TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.receivableDate ? moment(n.receivableDate).format('LL') : ''}</TableCell>
                    {taxType === 'gst' && <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.taxRateType.split("_").join(" ")}</TableCell>}
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.totalPrice ? n.totalPrice.toFixed(2) : 0.0}</TableCell>
                    <TableCell style={style.cellStyle} component="th" scope="row" padding="none">{n.status}</TableCell>
                    <TableCell style={{ ...style.cellStyle, color: Colors.primary }} component="th" scope="row" padding="none">
                      {n.status === 'NEW' ?
                        <Button style={{ marginRight: '6px' }} size="small" variant="contained" color="secondary" onClick={() => { this.props.history.push(`/edit-po/${n.id}`); }}>
                          Edit
                        </Button>
                        :
                        <Button style={{ marginRight: '6px' }} size="small" variant="contained" color="primary" onClick={() => { this.props.history.push(`/edit-po/${n.id}/view`); }}>
                          View
                        </Button>
                      }
                      <Button style={{ marginRight: '6px', padding: "4px 8px" }} variant="outlined" size="small" color="secondary" onClick={() => this.handleOpenPrintOptions(n)}>
                        <PrintIcon />
                      </Button>
                      {/* {canView(["ADMIN"]) && <Button style={{ marginRight: '6px' }} variant="outlined" size="small" color="primary" onClick={() => this.handlePrintPOPdf(n.id)}> Print pdf </Button>} */}
                      <Button style={{ marginRight: '6px' }} disabled={!this.getEmailStatus(n.status, n.emailSent, n.emailEnabled)} variant="outlined" size="small" color="primary" onClick={() => { this.openEmailSend(n.vendorLocationId, n.id) }}>
                        Send Email
                      </Button>
                      <Button style={{ marginRight: '6px' }} size="small" color="secondary" variant="outlined" disabled={n.status === 'NEW'}>
                        <a href={`/po/${n.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          GRN
                        </a>
                      </Button>
                      <Popconfirm
                        title="Are you sure to copy this Purchase Order?"
                        onConfirm={() => this.handleConfirmCopy(n.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button variant="outlined" size="small" color="primary" disabled={n.status === 'NEW'}> Copy PO </Button>
                      </Popconfirm>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
          <Modal
            title="Send Email"
            centered
            visible={openEmailDisplay}
            onOk={this.handleEmailSend}
            onCancel={this.handleClose}
            okText="Send Email"
            confirmLoading={isLoading}
            okButtonProps={{ disabled: !vendorEmailId || isLoading }}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div>
              <span style={{ fontWeight: 'bold' }}>Vendor Name: </span><br />
              <span style={{ marginLeft: '50px' }}>{vendorName}</span><br />
              <span style={{ fontWeight: 'bold' }}>Vendor Email: </span>{vendorEmailId ? vendorEmails.map(email => (<div><span style={{ marginLeft: '50px' }}>{email}</span><br /></div>)) : 'No email found'}<br />
              {!vendorEmailId && <span style={{ color: 'red' }}> Please update vendor email in Vendor Location </span>}
            </div>
          </Modal>
          <Modal
            title="Select Print Format"
            centered
            visible={openPrintOption}
            onOk={this.handlePrintPO}
            onCancel={this.handleClosePrintOption}
            okText="Print"
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <Radio.Group onChange={this.handleChangePrintOption} value={printType}>
                <Radio value={'pdf'}>pdf</Radio>
                <Radio value={'csv'}>csv</Radio>
              </Radio.Group>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.instanceOf(),
  onPageChange: PropTypes.func,
  totalElements: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  data: PropTypes.instanceOf(Array),
  getPurchaseOrder: PropTypes.func,
  history: PropTypes.instanceOf(Object),
};

export default withRouter(withStyles(styles)(EnhancedTable));
