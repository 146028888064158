import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import Drawer from '@material-ui/core/Drawer';
import apiCall, { getActiveAppWidgetsByFilters, reorderAppWidgets, updateAppWidget } from '../../../api/NetworkHandler';
import AppWidgetList from '../components/AppWidgetList';
import AppWidgetForm from '../components/AppWidgetForm';
import AppWidgetSearch from '../components/AppWidgetSearch';
import AppWidgetManageOrderSequenceModal from '../components/AppWidgetManageOrderSequenceModal';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';
import { Modal } from 'antd';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';

const styles = {
    container: {
        margin: 0,
    },

    arrow: {
        cursor: "pointer",
    },
    error: {
        color: "red",
    }
};

var versionDict = {
    "widgetRequestId": 0,
    "allWidgetRequestId": 0,
}

class AppWidgetContainers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widgetForm: false,
            widgets: [],
            allWidgets: [],
            currentPage: 0,
            rowsPerPage: 10,
            totalElements: 0,
            order: 'asc',
            orderBy: 'sortId',
            isLoading: false,
            filters: {
                query: '',
            },
            openManageOrder: false,
            selectedWidget: {},
            isEdit: false,
        };
    }

    componentDidMount = () => {
        this.fetchParams();
        this.fetchAllAppWidgets();
    };

    onSearch = () => {
        this.setState({
            currentPage: 0,
        }, () => this.fetchAppWidgets());
    };

    fetchParams = () => {
        const { filters } = this.state;
        const { location } = this.props;
        const { search } = location;
        const getFilterParams = getParamsSearchQuery(search, filters);
        this.setState({
            filters: getFilterParams
        }, () => this.fetchAppWidgets());
    };

    fetchAppWidgets = async () => {
        const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
        try {
            versionDict.widgetRequestId += 1;
            let prevRequestId = versionDict.widgetRequestId;
            const url = getActiveAppWidgetsByFilters(filters, page, size);
            const res = await apiCall.sendRequest('get', url);
            const { data: { data: { content = [], totalElements = 0 } } } = res;
            if (versionDict.widgetRequestId == prevRequestId) {
                this.setState({
                    widgets: content,
                    totalElements,
                });
            }
        } catch (e) {
            this.setState({
                widgets: [],
                totalElements: 0,
            });
        }
    };

    fetchAllAppWidgets = async () => {
        try {
            versionDict.allWidgetRequestId += 1;
            let prevRequestId = versionDict.allWidgetRequestId;
            const url = getActiveAppWidgetsByFilters({ filters: { query: '' } }, 0, 100);
            const res = await apiCall.sendRequest('get', url);
            const { data: { data: { content = [] } } } = res;
            if (versionDict.allWidgetRequestId == prevRequestId) {
                this.setState({
                    allWidgets: content,
                });
            }
        } catch (e) {
            this.setState({
                allWidgets: [],
            });
        }
    };

    handleSaveReorder = async (reorderedWidgets) => {
        let modifiedReorderedWidgets = reorderedWidgets.map((el, index) => { return { order: index, widgetId: el.id } });
        try {
            await apiCall.sendRequest('post', reorderAppWidgets, modifiedReorderedWidgets);
            this.handleCloseManageOrder();
            this.onSearch(0);
        } catch (error) {
            this.handleCloseManageOrder();
            this.onSearch(0);
        }
    };

    addNewWidgets = () => {
        this.setState({
            widgetForm: true,
        });
    };

    onClose = () => {
        this.setState({
            widgetForm: false,
        });
    };

    handleOpenManageOrder = () => {
        this.setState({
            openManageOrder: true,
        });
    };

    handleCloseManageOrder = () => {
        this.setState({
            openManageOrder: false,
        });
    };

    toggleProgressBar = () => {
        const { isLoading } = this.state;
        this.setState({ isLoading: !isLoading })
    };

    handleQueryChange = (event) => {
        const { value } = event.target;
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                query: value,
            },
        }));
    };

    handleClearSearch = () => {
        this.setState({
            filters: {}
        }, () => this.fetchAppWidgets());
    };

    onPageChange = (page) => {
        this.setState({
            currentPage: page
        }, () => { this.fetchAppWidgets() })
    };

    onRowsPerPageChange = (size) => {
        this.setState({
            rowsPerPage: size,
            currentPage: 0,
        }, () => { this.fetchAppWidgets() })
    };

    handleTitleChange = (event) => {
        const { value } = event.target;
        const { selectedWidget } = this.state;
        this.setState({
            selectedWidget: { ...selectedWidget, title: value }
        })
    }

    onSaveTitle = debounce(async () => {
        try {
            this.setState({ isLoading: true });
            const { selectedWidget } = this.state;
            await apiCall.sendRequest('put', updateAppWidget, selectedWidget);
            this.onCloseEdit();
            this.fetchAppWidgets();
            this.setState({ isLoading: false })
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false })

        }

    }, 500);

    onEditWidget = (widget) => {
        this.setState({ selectedWidget: widget, isEdit: true, })

    }

    onCloseEdit = () => {
        this.setState({
            selectedWidget: {},
            isEdit: false,
        })

    }

    render() {
        const { classes } = this.props;
        const {
            widgetForm,
            widgets,
            rowsPerPage,
            currentPage,
            order,
            orderBy,
            totalElements,
            isLoading,
            filters,
            openManageOrder,
            allWidgets,
            isEdit = false,
            selectedWidget,
        } = this.state;
        const { title = '' } = selectedWidget;
        const isTitleLengthInvalid = title.length > 50;
        return (
            <React.Fragment>
                <NavBar />
                <ProgressBar isLoading={isLoading} />
                <AppWidgetSearch
                    onCreateAppWidgets={this.addNewWidgets}
                    handleQueryChange={this.handleQueryChange}
                    query={filters.query}
                    onSearch={this.onSearch}
                    onReorder={this.handleOpenManageOrder}
                    onClearSearch={this.handleClearSearch}
                />
                <Grid className={classes.container} fluid>
                    <Row lg={12}>
                        <Col lg={12}>
                            <AppWidgetList
                                widgets={widgets}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                count={totalElements}
                                onEdit={this.handleOpenManageOrder}
                                onPageChange={this.onPageChange}
                                onRowsPerPageChange={this.onRowsPerPageChange}
                                order={order}
                                orderBy={orderBy}
                                onEditWidget={this.onEditWidget}
                            />
                        </Col>
                    </Row>
                </Grid>
                <Drawer anchor="right" open={widgetForm} onClose={this.onClose}>
                    <AppWidgetForm
                        onClose={this.onClose}
                        onRefresh={this.fetchAppWidgets}
                        totalElements={totalElements}
                    />
                </Drawer>
                <AppWidgetManageOrderSequenceModal
                    open={openManageOrder}
                    onClose={this.handleCloseManageOrder}
                    widgets={allWidgets}
                    onReorder={this.handleSaveReorder}
                />
                <Modal
                    title="Edit App Widget"
                    centered
                    visible={isEdit}
                    onOk={this.onSaveTitle}
                    onCancel={this.onCloseEdit}
                    okText="Save"
                    okButtonProps={{ disabled: isTitleLengthInvalid || isLoading }}
                >
                    <div>
                        <TextField
                            type="text"
                            label="Widget Title"
                            name="title"
                            value={title}
                            onChange={this.handleTitleChange}
                            helperText={isTitleLengthInvalid ? 'Max length is 50' : ''}
                            error={isTitleLengthInvalid ? true : false}
                            fullWidth
                            autoComplete="off"
                        />
                    </div>
                </Modal>
            </React.Fragment>
        );
    };
};

export default withStyles(styles)(AppWidgetContainers);
