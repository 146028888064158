import React from 'react';
import Async from '@atlaskit/select/AsyncSelect';
import PropTypes from "prop-types";
import sortBy from 'lodash/sortBy';

/**
 * Search and select is core component from which its features can be inherited.
 */
const SearchAndSelect = (props) => {
  const { selected, onLoad, onSelect, isMulti, isClearable, disabled, params } = props;

  const onSearch = async (searchTerm) => {
    const response = await onLoad(searchTerm, params);
    return sortBy(response, 'label');
  };

  return (
    <Async
      value={selected}
      loadOptions={onSearch}
      onChange={onSelect}
      defaultOptions
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  )
}


SearchAndSelect.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,

  /**
   * Function that does API calls to search. And should return as 
   * [{value: String, label: String} ...]
   */
  onLoad: PropTypes.func.isRequired,

  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,

   /**
   * Multi Select
   */
  isMulti: PropTypes.bool,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
  /**
   * Select box can be disabled or not
   */
  disabled: PropTypes.bool,
};

SearchAndSelect.defaultProps = {
  selected: null,
  isMulti: false,
  isClearable: true,
  disabled: false,
};


export default SearchAndSelect;