import React from 'react';
import HubsComponent from '../components/HubsComponent';

class HubsContainer extends React.Component {
  render() {
    return (
      <div>
        <HubsComponent />
      </div>
    );
  }
}

export default HubsContainer;
