import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ItemText from '../ItemText';
import styles from './styles';
import Lozenge from '@atlaskit/lozenge';
import moment from 'moment';
import { Modal ,Tooltip} from 'antd';
import apiCall, { getDeliverableAddressStatus,getHistory } from './../../../api/NetworkHandler';
import HistoryContainer from '../../../modules/History/containers/HistoryContainer';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const MAIN_INFO = [
  {
    text: 'Customer Id',
    param: 'customerId',
  },
  {
    text: 'Name',
    param: 'name',
  },
  {
    text: 'Mobile No',
    param: 'mobile',
  },
  {
    text: 'Account Status',
    param: 'status',
  },
  {
    text: 'Profile Status',
    param: 'profileStatus',
  },
  {
    text: 'Email',
    param: 'email',
  },
  {
    text: 'Registration Date',
    param: 'timeCreated',
  },
];

class CustomerInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAppPopup: false,
      deliverableStatus: '',
      deliverableText: '',
      data: [],
      showHistory: false,
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    }
  }
  componentDidMount() {
    const { info } = this.props
    const { customerId } = info;
    customerId && this.getDeliverableStatus(customerId);
  }


  getDeliverableStatus = async (customerId) => {
    try {
      const response = await apiCall.sendRequest("get", getDeliverableAddressStatus(customerId));
      const { data } = response.data;
      const { status = '', remarks = '' } = data;
      this.setState({
        deliverableStatus: status,
        deliverableText: remarks,
      })
    } catch (err) {
      console.log(err);
      this.setState({
        deliverableStatus: '',
        deliverableText: 'No address found',
      })
    }
  };

  getDeliverable = () => {
    let notDeliverable = false;
    if (!this.state.deliverableStatus || this.state.deliverableStatus !== 'DELIVERABLE') {
      notDeliverable = true;
    }
    return <Lozenge appearance={notDeliverable ? 'removed' : 'success'} >{this.state.deliverableText}</Lozenge>;
  }


  getPlanStatus = () => {
    const { accountStatus } = this.props;
    let message = ''
    if (Object.keys(accountStatus).length === 0) {
      return
    } else {
      if (!accountStatus.pauseFrom && !accountStatus.pauseTill) {
        message = 'Active';
      }
      else if (!(moment().isBefore(accountStatus.pauseFrom) || moment().isAfter(accountStatus.pauseTill))) {
        message = 'Paused'
      } else {
        message = 'Active'
      }
    }
    return <Lozenge appearance={message === 'Active' ? 'success' : 'removed'} isBold>{message}</Lozenge>;
  }

  getReferralDetails = () => {
    const { info = {} } = this.props;
    if (typeof info === 'undefined' || Object.keys(info).length === 0) return;
    if (info.referredByEntity === 'Customer') {
      if (info.referredBy) {
        return <Link to={`/cs-assist/customer/${info.referredBy}`} style={{ textDecoration: "underline", color: "indigo", }}> {`Referred By: ${info.referredBy}`} </Link>;
      } else {
        return <Typography variant="caption" color="inherit"> NO REFERRER AVAILABLE </Typography>
      }
    }
    else if (['ExternalReferrer', 'FTH'].includes(info.referredByEntity)) {
      if (info.referralCodeOfReferredBy) {
        return <Typography variant="body1" style={{ paddingLeft: '10px' }}> <mark style={{ backgroundColor: '#ccc', color: 'indigo' }}>{info.referralCodeOfReferredBy}</mark> </Typography>
      } else {
        return <Typography variant="caption" color="inherit"> NO CODE AVAILABLE </Typography>
      }
    }
  };

  getItemTextByParam = (param = '', data = {}) => {
    switch (param) {
      case 'timeCreated': return moment(data.timeCreated).format('LL');
      case 'status': return <Lozenge appearance={data.status === 'ACTIVE' ? 'success' : 'removed'} isBold>{data.status}</Lozenge>;
      case 'profileStatus': return <Lozenge appearance={data.profileStatus === 'ACTIVE' ? 'success' : 'removed'} isBold>{data.profileStatus}</Lozenge>;
      default: return data[param];
    }
  };

  renderMainInfo = () => {
    const { info = {}, showExtras } = this.props;
    if (!isEmpty(info)) {
      return (
        <React.Fragment>
          {
            MAIN_INFO.map((el) => {
              if (info[el.param]) {
                return <ItemText name={el.text} value={this.getItemTextByParam(el.param, info)} style={el.param === 'email' ? styles.mainInfoPrimaryItem : styles.mainInfoPrimaryItemSmall} />;
              }
              return null;
            })
          }
          {showExtras &&
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ItemText name="Deliverable" value={this.getDeliverable()} style={{ ...styles.mainInfoSecondaryItem, marginLeft: '10px' }} />
              <ItemText name="Plan Status" value={this.getPlanStatus()} style={styles.mainInfoSecondaryItem} />
              <ItemText name="Referral Info" value={this.getReferralDetails()} style={styles.mainInfoSecondaryItem} />
            </div>
          }
        </React.Fragment>
      )
    }
    return null;
  };

  handleOpenDeviceInfo = () => {
    this.setState({ visibleAppPopup: true });
  };

  handleOk = () => {
    this.setState({ visibleAppPopup: false });
  };
  handleCloseHistory = () => {
    this.setState({
      showHistory: false,
    });
  };
  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.viewHistory() });
  };
  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ page:0 ,rowsPerPage}, () => { this.viewHistory() });
  };
  resetHistoryParams = () => {
    this.setState({
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    }, () => {
      this.viewHistory()
    });
  }
  viewHistory = () => {
    const { info } = this.props
    const { customerId } = info;
    const { page, rowsPerPage } = this.state;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": 'AccountHistory',
        "params": JSON.stringify(
          {
            'customerId': customerId,
            'limit': rowsPerPage,
            'page': page,
          }
        )
      })
    )
      .then((response) => {
        let { data } = response.data;
        // data = JSON.parse(data);
        const { AccountHistory, TotalElements } = data;
        this.setState({
          data: AccountHistory,
          showHistory: true,
          count: TotalElements,
        });
      })
      .catch((error) => {
        console.error(error);
      });


  }

  render() {
    const { onClickHandler, info, showExtras, onAutoPauseCheck } = this.props;
    const {
      androidAppVersion = null,
      androidLastActiveTimestamp = null,
      iosAppVersion = null,
      iosLastActiveTimestamp = null
    } = info;
    const {showHistory,count,rowsPerPage,rowsPerPageOptions,data,page } = this.state;
    if (isEmpty(info)) {
      return (
        <Card style={styles.card}>
          <CardContent style={styles.content}>LOADING INFO...</CardContent>
        </Card>
      );
    }
    return (
      <div>
        <Card style={styles.card}>
          <CardContent style={styles.content}>
            <div style={styles.mainInfo}>{this.renderMainInfo()}</div>
          </CardContent>
        </Card>
        <Card style={styles.card}>
          <CardActions style={styles.actions}>
            <Button
              onClick={() => onClickHandler('editAddress')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Edit Address
            </Button>
            <Button
              onClick={() => onClickHandler('editProfile')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Edit Profile
            </Button>
            <Button
              onClick={() => onClickHandler('plans')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Plans
            </Button>
            <Button
              onClick={() => onClickHandler('transactions')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Payments
            </Button>
            <Button
              onClick={this.handleOpenDeviceInfo}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              App Info
            </Button>
            <Button
              onClick={onAutoPauseCheck}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Auto Pause Status
            </Button>
            <Button
              onClick={() => onClickHandler('changeMobile')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Change Mobile
            </Button>
            <Button
              onClick={() => onClickHandler('changeEmail')}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Change Email
            </Button>
            <Button
              onClick={() => this.resetHistoryParams()}
              style={styles.btn}
              color="primary"
              variant="outlined"
              size="small"
            >
              Account History
            </Button>
          </CardActions>
        </Card>
        <Modal
          title="App Information"
          visible={this.state.visibleAppPopup}
          onOk={this.handleOk}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={false}
        >
          <span style={{ fontWeight: 'bold' }}>Android: </span>< br />
          <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;App Version: </span> {androidAppVersion || 'No data available'}< br />
          <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Active Time: </span> {androidLastActiveTimestamp ? moment(androidLastActiveTimestamp).format('LLL') : 'No data available'}< br />
          <span style={{ fontWeight: 'bold' }}>iOS: </span>< br />
          <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;App Version: </span> {iosAppVersion || 'No data available'}< br />
          <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Active Time: </span> {iosLastActiveTimestamp ? moment(iosLastActiveTimestamp).format('LLL') : 'No data available'}< br />
        </Modal>

        <HistoryContainer
          openHistory={showHistory}
          handleClose={this.handleCloseHistory}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={this.handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          data={data}
          title='Account History'
        />
      </div>
    );
  }
}

CustomerInfoCard.propTypes = {
  info: PropTypes.instanceOf(Object),
  onClickHandler: PropTypes.func,
  showExtras: PropTypes.bool,
};

export default CustomerInfoCard;
