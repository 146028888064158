export default {
  title: {
    fontSize: '1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  action: {
    display: 'flex',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  btn: {
    width: '128px',
    marginLeft: '10px',
  },
  editContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputDir: {
    minWidth: '27%',
    margin: '16px 3%',
  },
  inputStyle: {
    width: '100%',
  },
  refer: {
    margin: '16px 3%',
  },
};
