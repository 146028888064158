import React from 'react';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Select,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import apiCall, { allProductItemsPath, productItemPath } from '../../../api/NetworkHandler';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
  buttonAlignment: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
};

let clearFilters = false;
class OfferSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      products: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    const { queryId } = this.props;
    if (queryId) {
      this.getProductById(queryId);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { queryId } = this.props;
    if (queryId !== prevProps.queryId && queryId) {
      this.getProductById(queryId);
    }
  }

  getProductById = async (productItemId) => {
    const url = productItemPath(productItemId);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data = {} } = response;
      const { name = '' } = data;
      this.setState({
        products: [data],
        searchQuery: name,
      });
    } catch (e) {
      this.setState({
        products: [],
        searchQuery: '',
      });
    }
  }

  getAllProducts = async (query = '') => {
    const url = allProductItemsPath(query);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = [] } } = response;
      this.setState({
        products: data,
      });
    } catch (e) {
      this.setState({
        products: [],
      });
    }
  }

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }


  handleProductSearch = (query) => {
    this.setState({ searchQuery: query });
    this.getAllProducts(query);
  }

  handleProductSelection = (product) => {
    const { id } = product;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        productItemId: id,
      },
    }));
  }

  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {};
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
      searchQuery: '',
    }, () => {
      clearFilters = !clearFilters;
      onButtonClick('search', 0);
    });
  }


  isValidDateRange = (value, key) => {
    const { filters: { startDate, endDate } } = this.state;
    const isEmpty = value === '';
    const endDateValidation = (key === 'endDate' && (startDate === '' || moment(value).isSameOrAfter(moment(startDate))));
    const startDateValidation = (key === 'startDate' && (endDate === '' || moment(value).isSameOrBefore(moment(endDate))));
    return isEmpty || endDateValidation || startDateValidation;
  }

  handleChange = (value, key) => {
    let { filters } = this.state;
    const { onFilterChange } = this.props;
    const isNotDateField = (key !== 'endDate') && (key !== 'startDate');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      filters = {
        ...filters,
        [key]: value,
      };
      onFilterChange(key, value);
    }
    this.setState({
      filters,
    });
  }

  render() {
    const {
      onButtonClick, offerTypes = {}, // onUpload,
    } = this.props;
    const {
      RECHARGE_OFFER = '', REFERRAL_OFFER = '', HAPPY_HOUR_OFFER = '', ...offerSelection
    } = offerTypes;

    const {
      filters, products, searchQuery = '',
    } = this.state;

    const {
      startDate,
      endDate,
      offerType = '',
    } = filters;

    return (
      <div style={styles.container} key={clearFilters}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  label="Start Date"
                  type="date"
                  value={startDate}
                  defaultValue={startDate}
                  InputLabelProps={{
                  shrink: true,
                }}
                  onChange={e => this.handleChange(e.target.value, 'startDate')}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  label="End Date"
                  type="date"
                  value={endDate}
                  min={startDate}
                  defaultValue={endDate}
                  InputLabelProps={{
                  shrink: true,
                }}
                  onChange={e => this.handleChange(e.target.value, 'endDate')}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Select
                  style={{ marginTop: 16 }}
                  native
                  fullWidth
                  label="Offer Type"
                  value={offerType}
                  onChange={e => this.handleChange(e.target.value, 'offerType')}
                >
                  <option value="">Offers Type</option>
                  {Object.keys(offerSelection).map(type => (
                    <option value={type}>{type}</option>
                ))}
                </Select>
              </Grid>

              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Product</FormHelperText>
                <SearchWithSuggestions
                  suggestions={products}
                  searchQuery={searchQuery}
                  onChange={this.handleProductSearch}
                  onSelect={(product) => { this.handleChange(product.id, 'productItemId'); }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search', 0)}
                >
                      Search
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleClear}
                >
                      Clear All
                </Button>
              </Grid>
              <Grid item xs={6} md={6} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                <Button color="secondary" variant="contained">
                  <Link to="/edit-offers" style={{ textDecoration: 'none', color: 'inherit' }}> Create New Offer </Link>
                </Button>

              </Grid>
              {/* <Grid item xs={8} md={8}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onUpload(true)}
                >
                      Upload Offers
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

OfferSearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.func,
  offerTypes: PropTypes.instanceOf(Object),
  queryId: PropTypes.string,
  // onUpload: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { offerTypes } = Status;
  return {
    offerTypes,
  };
};


export default withRouter(connect(mapStateToProps, null)(OfferSearch));
