import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, capitalize } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableBody, TablePagination, Typography } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import Lozenge from '@atlaskit/lozenge';
import { getOfferStatus } from './OfferUtility';

const OfferColumnData = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Campaign Title',
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start Date (Delivery)',
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End Date (Delivery)',
  },
  {
    id: 'paybackType',
    numeric: false,
    disablePadding: true,
    label: 'Offer Type',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: 10,
  },
  tableCell: {
    textAlign: 'center',
  },
//   titleCell: {
//     textAlign: 'left',
//   },
  tableData: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '30px',
    padding: 5,
    fontSize: 15,
  },
  titleRow: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '25px',
    padding: 5,
    // paddingBottom: 20,
    fontSize: 16,
  },
};

class OfferCampaignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  handleClick = (offersData) => {
    this.setState({
      selectedData: offersData,
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;

    onRowsPerPageChange(value);
  }

  getPaybackType = (type) => {
    if (!type) return "Discount";
    if (type === "PRICE_DISCOUNT") {
      return "Percent Discount";
    } else {
      return capitalize(type)
    }
  };

  render() {
    const {
      offersData, totalElements = 0, rowsPerPage = 20, page = 0,
    } = this.props;
    const { selectedData = {}, rowsPerPageOptions } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={9} style={{ paddingLeft: '15px' }}>
          <Table>
            <TableHeader columns={OfferColumnData} cellStyle={styles.tableCell} />
            <TableBody>
              {
                offersData.map(item => (
                  <TableRow
                    hover
                    onClick={() => this.handleClick(item)}
                    role="checkbox"
                    tabIndex={-1}
                    key={item.id}
                    selected={!isEmpty(offersData) && offersData.id === item.id}
                  >
                    <TableCell component="th" scope="row" padding="none">{item.title}</TableCell>
                    <TableCell component="th" scope="row" padding="none" >{moment(item.startTime).format('ll')}</TableCell>
                    <TableCell component="th" scope="row" padding="none" >{moment(item.endTime).format('ll')}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{this.getPaybackType(item.paybackType)}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        <Lozenge appearance={getOfferStatus(item.startTime, item.endTime).color}>{getOfferStatus(item.startTime, item.endTime).status}</Lozenge>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {getOfferStatus(item.startTime, item.endTime).status === 'completed' ? 
                        <Button size="small" color="primary" variant="contained">
                          <Link to={{ pathname: `/edit-offer-campaign/${item.id}` }} style={{ textDecoration: 'none', color: 'inherit' }}> View </Link>
                        </Button>
                      :
                        <Button size="small" color="secondary" variant="outlined">
                          <Link to={{ pathname: `/edit-offer-campaign/${item.id}` }} style={{ textDecoration: 'none', color: 'inherit' }}> Edit </Link>
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          {(offersData && offersData.length > 0) &&
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          }

        </Grid>
        <Grid item xs={12} md={3}>
          <div style={styles.paperRight}>
            { Object.keys(selectedData).length
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={styles.tableData}>
                    <b>Offer Details</b>
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Id : </b> {selectedData.id}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Title : </b> {selectedData.title}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Offer Type : </b> {selectedData.offerType}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Payback Type : </b> {selectedData.paybackType}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Start Time : </b> {moment(selectedData.startTime).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> End Time : </b> {moment(selectedData.endTime).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Description : </b> {selectedData.description}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Time Created: </b> {moment(selectedData.timeCreated).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Time Updated : </b> {moment(selectedData.timeUpdated).format('lll')}
                  </Typography>
                </div>
              : <div />}
          </div>

        </Grid>
      </Grid>
    );
  }
}


OfferCampaignList.propTypes = {
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  offersData: PropTypes.instanceOf(Array),
};

export default OfferCampaignList;

