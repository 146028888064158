import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall, { getAllBrandsPath } from "../api/NetworkHandler";

class BrandSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
    };
  }

searchBrands = () => {
// Change API
  const { searchText = '' } = this.state;
  apiCall.sendRequest('get', getAllBrandsPath(searchText))
    .then((response) => {
      // console.log(response.data);
      const { data = {} } = response;
      const { _embedded = {} } = data;
      const { brands } = _embedded;
      this.setState({
        suggestions: brands,
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

search = () => {
  this.searchBrands();
}

render() {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={this.props.openSearchBox}
      onClose={() => { this.props.handleClose(); this.setState({ suggestions: [], searchText: '' }); }}
      onBackdropClick={() => { this.props.handleClose(); this.setState({ suggestions: [], searchText: '' }); }}
    >
      <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '70%',
          height: '85%',
          }}
      >
        <Button style={{ float: 'right' }} onClick={() => { this.props.handleClose(); this.setState({ suggestions: [], searchText: '' }); }} color="secondary" aria-label="close">
          <CloseIcon />
        </Button>
        <Grid style={{ padding: '2em' }}>
          <Row>
            <ColMod xs md lg={15}>
              <Row>
                <Typography variant="body2" color="secondary">
Search Brands
                </Typography>
              </Row>
              <Row>
                <ColMod lg={6}>
                  <TextField
                    value={this.state.searchText}
                    onChange={(event) => { this.setState({ searchText: event.target.value }); }}
                    placeholder="Name"
                  />
                </ColMod>
                <ColMod lg={6}>
                  <Button color="secondary" variant="contained" onClick={this.search}>
Search
                  </Button>
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={12}>
                  <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '320px' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Brand</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.suggestions.map((suggestion, index) => (
                          <TableRow key={index}>
                            <TableCell>{suggestion.name}</TableCell>
                            <TableCell><Button variant="contained" color="secondary" onClick={() => { this.props.selectBrand(suggestion); this.setState({ suggestions: [], searchText: '' }); }}>Select</Button></TableCell>
                          </TableRow>
))}
                      </TableBody>
                    </Table>
                  </div>
                </ColMod>
              </Row>
            </ColMod>
          </Row>
        </Grid>
      </div>
    </Modal>
  );
}
}

BrandSelection.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectBrand: PropTypes.func.isRequired,
};

export default BrandSelection;
