import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@atlaskit/select';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';

const styles = {
  searchBar: {
    background: Colors.grey[100],
    // paddingTop: '5px',
    // paddingBottom: '15px',
  },
  paddingDiv: {
    paddingTop: '5px',
  },
  helperText: {
    color: 'red',
    marginTop: '-10px',
  },
};

const actionOptions = [
  { label: 'All', value: '' },
  { label: 'Inserted', value: 'INSERTED' },
  { label: 'Updated', value: 'UPDATED' },
  { label: 'Deleted', value: 'DELETED' },
];



function PlanSearch(props) {
  const {
    startDate,
    endDate,
    // modifiedBy,
    action,
    // entity,
    onChangeFilter,
    onClearFilters,
    // users,
    // entityKey,
    // entityText,
    classes,
    onSearchHandler,
  } = props;

  return (


    <Grid className={classes.searchBar} fluid>
      <Row md={12} middle="md">
       
        <Col md={12}>
          <Row md={12} between="md" middle="md" style={styles.paddingDiv}>
            <Col md={2}>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                fullWidth
                onChange={onChangeFilter}
                value={startDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
            <Col md={2}>
              <TextField
                type="date"
                label="End Date"
                name="endDate"
                fullWidth
                onChange={onChangeFilter}
                value={endDate || ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Col>
            {/* <Col md={2}>
              <React.Fragment>
                <Typography variant="caption"> Modified By </Typography>
                <Select
                  value={modifiedBy}
                  options={users}
                  onChange={(value) => { onChangeFilter({ target: { name: 'modifiedBy', value } }); }}
                />
              </React.Fragment>
            </Col> */}
            <Col md={2}>
              <React.Fragment>
                <Typography variant="caption"> Action </Typography>
                <Select
                  value={action}
                  options={actionOptions}
                  onChange={(value) => { onChangeFilter({ target: { name: 'action', value } }); }}
                />
              </React.Fragment>
            </Col>
            <ColMod md={1}>
                  <Button color="secondary" variant="contained" onClick={onSearchHandler}>Search</Button>
                </ColMod>
                <ColMod md={1}>
                  <Button color="secondary" variant="contained" onClick={onClearFilters}>Clear</Button>
                </ColMod>
         
            {/* <Col md={12}>
              <Row md={12} middle="md" style={styles.paddingDiv}>
                <FormHelperText id="name-error-text" style={styles.helperText} ><span>*</span>Please select Start Date and End Date and any of one the field to search with Entity Text</FormHelperText>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Grid>
  );
}

PlanSearch.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
//   modifiedBy: PropTypes.string,
  action: PropTypes.string,
//   entityKey: PropTypes.string,
//   entityText: PropTypes.string,
  onChangeFilter: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onSearchHandler: PropTypes.func,
};

PlanSearch.defaultProps = {
  startDate: '',
  endDate: '',
//   modifiedBy: '',
  action: '',
//   entityKey: '',
//   entityText: '',
  onSearchHandler: () => { },
};

export default withStyles(styles)(PlanSearch);
