// CS [ASSIST] DASHBOARD
export const QUICK_SEARCH_LOADING = 'QUICK_SEARCH_LOADING';
export const QUICK_SEARCH_SUCCESS = 'QUICK_SEARCH_SUCCESS';
export const QUICK_SEARCH_FAILURE = 'QUICK_SEARCH_FAILURE';
export const CLEAR_QUICK_SEARCH = 'CLEAR_QUICK_SEARCH';
export const SELECT_QUICK_SEARCH_ITEM = 'SELECT_QUICK_SEARCH_ITEM';
export const FETCH_CS_INFO_SUCCESS = 'FETCH_CS_INFO_SUCCESS';
export const FETCH_CS_INFO_FAILURE = 'FETCH_CS_INFO_FAILURE';
export const FETCH_CS_INVOICE_SUCCESS = 'FETCH_CS_INVOICE_SUCCESS';
export const FETCH_CS_INVOICE_FAILURE = 'FETCH_CS_INVOICE_FAILURE';
export const FETCH_CS_MORE_INVOICE_SUCCESS = 'FETCH_CS_MORE_INVOICE_SUCCESS';
export const FETCH_CS_MORE_INVOICE_FAILURE = 'FETCH_CS_MORE_INVOICE_FAILURE';
export const FETCH_CS_ORDERS_SUCCESS = 'FETCH_CS_ORDERS_SUCCESS';
export const FETCH_CS_ORDERS_FAILURE = 'FETCH_CS_ORDERS_FAILURE';
export const FETCH_CS_MORE_ORDERS_SUCCESS = 'FETCH_CS_MORE_ORDERS_SUCCESS';
export const FETCH_CS_MORE_ORDERS_FAILURE = 'FETCH_CS_MORE_ORDERS_FAILURE';
export const FETCH_CS_ADDRESSES_SUCCESS = 'FETCH_CS_ADDRESSES_SUCCESS';
export const FETCH_CS_ADDRESSES_FAILURE = 'FETCH_CS_ADDRESSES_FAILURE';
export const DT_EDIT_ORDER = 'DT_EDIT_ORDER';
export const ORDER_DETAILS_CHANGE = 'ORDER_DETAILS_CHANGE';
export const ADD_NEW_LINE_ITEM = 'ADD_NEW_LINE_ITEM';
export const UPDATE_ORDER_DETAILS_SUCCESS = 'UPDATE_ORDER_DETAILS_SUCCESS';
export const UPDATE_ORDER_DETAILS_FAILURE = 'UPDATE_ORDER_DETAILS_FAILURE';
export const CHANGE_SELECTED_ADDRESS = 'CHANGE_SELECTED_ADDRESS';
export const ADDRESS_EDIT = 'ADDRESS_EDIT';
export const NEW_ADDRESS_EDIT = 'NEW_ADDRESS_EDIT';
export const ADDRESS_SET_DEFAULT_SUCCESS = 'ADDRESS_SET_DEFAULT_SUCCESS';
export const ADDRESS_SET_DEFAULT_FAILURE = 'ADDRESS_SET_DEFAULT_FAILURE';
export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';
export const ADD_NEW_ADDRESS_FAILURE = 'ADD_NEW_ADDRESS_FAILURE';
export const SET_ROUTE_SUCCESS = 'SET_ROUTE_SUCCESS';
export const SET_ROUTE_FAILURE = 'SET_ROUTE_FAILURE';
export const UPDATE_ADDRESS_CHANGE_SUCCESS = 'UPDATE_ADDRESS_CHANGE_SUCCESS';
export const UPDATE_ADDRESS_CHANGE_FAILURE = 'UPDATE_ADDRESS_CHANGE_FAILURE';
export const PROFILE_EDIT = 'PROFILE_EDIT';
export const UPDATE_PROFILE_CHANGE_SUCCESS = 'UPDATE_PROFILE_CHANGE_SUCCESS';
export const UPDATE_PROFILE_CHANGE_FAILURE = 'UPDATE_PROFILE_CHANGE_FAILURE';
export const FETCH_TRANSACTION_HISTORY_SUCCESS = 'FETCH_TRANSACTION_HISTORY_SUCCESS';
export const FETCH_TRANSACTION_HISTORY_FAILURE = 'FETCH_TRANSACTION_HISTORY_FAILURE';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_FAILURE = 'FETCH_WALLET_FAILURE';
export const FETCH_TOTAL_WALLET_SUCCESS = 'FETCH_TOTAL_WALLET_SUCCESS';
export const FETCH_TOTAL_WALLET_FAILURE = 'FETCH_TOTAL_WALLET_FAILURE';
export const NEXT_DELIVERY_ITEMS_SUCCESS = 'NEXT_DELIVERY_ITEMS_SUCCESS';
export const NEXT_DELIVERY_ITEMS_FAILURE = 'NEXT_DELIVERY_ITEMS_FAILURE';
export const GET_CS_PLANS_SUCCESS = 'GET_CS_PLANS_SUCCESS';
export const GET_CS_PLANS_FAILURE = 'GET_CS_PLANS_FAILURE';
export const GET_CS_PLAN_ITEMS_SUCCESS = 'GET_CS_PLAN_ITEMS_SUCCESS';
export const GET_CS_PLAN_ITEMS_FAILURE = 'GET_CS_PLAN_ITEMS_FAILURE';
export const UPDATE_CS_PLAN = 'UPDATE_CS_PLAN';
export const ADD_NEW_UPCOMING_ITEM = 'ADD_NEW_UPCOMING_ITEM';
export const GET_ROUTE_ORDER_FAILURE = 'GET_ROUTE_ORDER_FAILURE';
export const GET_DELIVERY_ROUTE_DETAILS_FAILURE = 'GET_DELIVERY_ROUTE_DETAILS_FAILURE';
export const FETCH_DELIVERY_ROUTE_LIST_FAILURE = 'FETCH_DELIVERY_ROUTE_LIST_FAILURE';
export const FETCH_DELIVERY_ROUTE_LIST_SUCCESS = 'FETCH_DELIVERY_ROUTE_LIST_SUCCESS';
export const FETCH_INITIATED_TRANSACTION_SUCCESS = 'FETCH_INITIATED_TRANSACTION_SUCCESS';
export const FETCH_INITIATED_TRANSACTION_FAILURE = 'FETCH_INITIATED_TRANSACTION_FAILURE';
export const FETCH_REFUND_TRANSACTION = 'FETCH_REFUND_TRANSACTION';
export const FETCH_REFUND_AMOUNT_SUCCESS = 'FETCH_REFUND_AMOUNT_SUCCESS';
export const FETCH_REFUND_AMOUNT_FAILURE = 'FETCH_REFUND_AMOUNT_FAILURE';
export const PROCESS_REFUND_SUCCESS = 'PROCESS_REFUND_SUCCESS';
export const PROCESS_REFUND_FAILURE = 'PROCESS_REFUND_FAILURE';
export const CHANGE_REFUND_AMOUNT = 'CHANGE_REFUND_AMOUNT';
export const EDIT_PLANNED_ITEM_SUCCESS = 'EDIT_PLANNED_ITEM_SUCCESS';
export const EDIT_PLANNED_ITEM_FAILURE = 'EDIT_PLANNED_ITEM_FAILURE';
export const NEW_CUSTOM_PLAN_SUCCESS = 'NEW_CUSTOM_PLAN_SUCCESS';
export const NEW_CUSTOM_PLAN_FAILURE = 'NEW_CUSTOM_PLAN_FAILURE';
export const CS_PLAN_ITEM_DELETE_SUCCESS = 'CS_PLAN_ITEM_DELETE_SUCCESS';
export const CS_PLAN_ITEM_DELETE_FAILURE = 'CS_PLAN_ITEM_DELETE_FAILURE';
export const CS_PLAN_ITEM_ADD_SUCCESS = 'CS_PLAN_ITEM_ADD_SUCCESS';
export const CS_PLAN_ITEM_ADD_FAILURE = 'CS_PLAN_ITEM_ADD_FAILURE';
export const ADD_NEW_SCHEDULED_ITEM = 'ADD_NEW_SCHEDULED_ITEM';
export const ADD_PLANNED_ITEM_SUCCESS = 'ADD_PLANNED_ITEM_SUCCESS';
export const ADD_PLANNED_ITEM_FAILURE = 'ADD_PLANNED_ITEM_FAILURE';
export const CANCEL_NEW_SCHEDULED_ITEM = 'CANCEL_NEW_SCHEDULED_ITEM';
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 200];
export const ROWS_PER_PAGE = 25;
export const PRODUCT_TIME_FORMAT = 'DD-MM-YYYY hh:mm:ss a';
export const SEARCH_AND_SELECT_OPTIONS_LIMIT = 15;
export const BANNER_ENTITIES = ["CATEGORY", "BRAND", "PRODUCT", "OTHERS"];

// move later
export const FETCH_ALL_WAREHOUSES_SUCCESS = 'FETCH_ALL_WAREHOUSES_SUCCESS';
export const FETCH_ALL_WAREHOUSES_FAILURE = 'FETCH_ALL_WAREHOUSES_FAILURE';


// Deeplinking url
export const DEEPLINKING_URLS = [
	// { url: '/ca/pd', type: 'productdescription', text: 'Product Description' },
	// { url: '/ca/pl', type: 'productListing', text: 'Product Listing' },
	{ url: '/ca/pymts', type: 'payment', text: 'Payment' },
	{ url: '/ca/dlvry', type: 'delivery', text: 'Delivery' },
	{ url: '/ca/sb', type: 'subscriptions', text: 'Subscriptions' },
	{ url: '/ca/profile', type: 'profile', text: 'Profile' },
	{ url: '/offers', type: 'offers', text: 'Offers' },
	// Account
	{ url: '/ca/account', type: 'account', text: 'Account' },
	{ url: '/ca/wallet', type: 'wallet', text: 'Wallet' },
	{ url: '/ca/th', type: 'transactionhistory', text: 'Transactionhistory' },
	{ url: '/ca/offers', type: 'accountoffers', text: 'Account Offers' },
	{ url: '/ca/support', type: 'support', text: 'Support' },
	{ url: '/ca/rf', type: 'referfriend', text: 'Refer Friend' },
	{ url: '/ca/coupons', type: 'coupons', text: 'Coupons' },
	{ url: '/ca/rus', type: 'rateus', text: 'Rate Us' },
	{ url: '/ca/aboutus', type: 'aboutus', text: 'About Us' },
	{ url: '/ca/ep', type: 'editprofile', text: 'Edit Profile' },
	{ url: '/ca/addaddress', type: 'addaddress', text: 'Add Address' },
	{ url: '/ca/refer', type: 'referrals', text: 'Referrals' },
	{ url: 'ca/ts', type: 'transactionstatus', text: 'Transaction Status' },
	// Home
	{ url: '/ca/search', type: 'search', text: 'Search' },
	{ url: '/ca/cart', type: 'cart', text: 'Cart' },
	{ url: '/ca/store', type: 'store', text: 'Store' },
	// Plan
	{ url: '/ca/sc', type: 'showcategories', text: 'Show Categories' },
];



export const ENTITY_LIST_SCM = [
	"AgentActivity",
	"AgentState",
	"Building",
	"CustomerAddress",
	"CustomerInvoice",
	"CustomerInvoiceLineItem",
	"CustomerOrder",
	"CustomerOrderLineItem",
	"DeliveryBoy",
	"DeliveryOrderChat",
	"DeliveryRoute",
	"DeliveryRouteOrder",
	"DeliveryRoutePickList",
	"EmployeeInvestmentDetails",
	"GRN",
	"GRNLineItem",
	"GRNReceipt",
	"Hub",
	"Offer",
	"OrderPriceLogs",
	"Product",
	"ProductCatalog",
	"ProductItem",
	"ProductOffer",
	"PurchaseOrder",
	"PurchaseOrderLineItem",
	"RouteDeliveryInfo",
	"StagingProduct",
	"StaticRoute",
	"StaticRouteLineItem",
	"StockLedger",
	"Ticket",
	"TicketComment",
	"Vendor",
	"VendorCatalog",
	"VendorLocation",
	"VendorProduct",
	"VendorProductItem",
	"Warehouse",
]
export const ENTITY_LIST_APP = [
	"Cart",
	"CartItem",
	"Coupon",
	"CouponRestriction",
	"Customer",
	"CustomerAccount",
	"CustomerCoupon",
	"CustomerDevice",
	"CustomerOrderRefund",
	"CustomerOtp",
	"CustomerPayment",
	"CustomerProfile",
	"CustomerReview",
	"CustomRechargeOffer",
	"ExternalReferrer",
	"FTHReferralCode",
	"GetOnceOrder",
	"HappyHourOrder",
	"PaymentTransaction",
	"PlanItem",
	"ProductChanges",
	"Schedule"
]
export const ENTITY_LIST = { 'scm': ENTITY_LIST_SCM, 'app': ENTITY_LIST_APP }
export const INDIA_STATE_LIST = [
	"Andhra Pradesh",
	"Arunachal Pradesh",
	"Assam",
	"Bihar",
	"Chhattisgarh",
	"Goa",
	"Gujarat",
	"Haryana",
	"Himachal Pradesh",
	"Jammu and Kashmir",
	"Jharkhand",
	"Karnataka",
	"Kerala",
	"Madhya Pradesh",
	"Maharashtra",
	"Manipur",
	"Meghalaya",
	"Mizoram",
	"Nagaland",
	"Odisha",
	"Punjab",
	"Rajasthan",
	"Sikkim",
	"Tamil Nadu",
	"Telangana",
	"Tripura",
	"Uttarakhand",
	"Uttar Pradesh",
	"West Bengal",
	"Andaman and Nicobar Islands",
	"Chandigarh",
	"Dadra and Nagar Haveli",
	"Daman and Diu",
	"Delhi",
	"Lakshadweep",
	"Puducherry"
]

export const UAE_CITIES_LIST = [
	"Dubai",
	"Abu Dhabi",
	"Sharjah"
]

