import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core/';

const styles = {
  selfChat: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  selfMessageContainer: {
    minWidth: '80%',
    maxWidth: '80%',
    width: '80%',
    backgroundColor: '#FFF4F8',
    padding: '10px',
    marginTop: '10px',
    marginLeft: '17%',
  },
  otherMessageContainer: {
    minWidth: '80%',
    maxWidth: '80%',
    width: '80%',
    backgroundColor: '#F0F0F0',
    padding: '10px',
    marginTop: '10px',
  },
  userChat: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
};

class OrderChat extends Component {
  render() {
    const { user, chat } = this.props;
    return (
      <div>
        { chat.userId === user.id ?
          <div style={styles.selfMessageContainer}>
            <div style={styles.selfChat}>
              <Typography variant="body2" color="secondary" style={{ maxWidth: '80%', minWidth: '80%' }}>
                {chat.message}
              </Typography>
              <Typography variant="body1" style={{ alignSelf: 'center' }}>
                {moment(chat.timeCreated).format('hh:mm A')}
              </Typography>
            </div>
          </div>
          :
          <div style={styles.otherMessageContainer}>
            <div style={styles.userChat}>
              <div style={{ flexDirection: 'column', maxWidth: '70%', minWidth: '70%' }}>
                <Typography variant="body2" color="secondary">
                  {chat.userName}
                </Typography>
                <Typography variant="body2">
                  {chat.message}
                </Typography>
              </div>
              <Typography variant="body1" style={{ alignSelf: 'center' }}>
                {moment(chat.timeCreated).format('lll')}
              </Typography>
            </div>
          </div>
        }
      </div>
    );
  }
}

OrderChat.propTypes = {
  user: PropTypes.instanceOf(Object),
  chat: PropTypes.instanceOf(Object),
};

export default OrderChat;
