import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TableBody, TableRow, TableCell, Button, Typography, Grid, TablePagination, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import Search from '@material-ui/icons/Search';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const columnData = [
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Month-Year',
  },
  {
    id: 'referralCount',
    numeric: false,
    disablePadding: false,
    label: 'Referral Count',
  },
  {
    id: 'eligibleForCashback',
    numeric: false,
    disablePadding: false,
    label: 'Eligible for Cashback',
  },
  {
    id: 'nonEligibleForCashback',
    numeric: false,
    disablePadding: false,
    label: 'Not Eligible for Cashback',
  },
];

const styles = {
  cellStyle: {
    textAlign: 'center',
    padding: '0px 10px',
  },
  headerStyle: {
    textAlign: 'center',
    padding: '0px 10px',
    color: 'rgb(245, 0, 87)',
    fontSize: '14px',
  },
  error: {
    padding: 20,
    textAlign: 'center',
    fontSize: 20,
    color: Colors.grey[400],
  },
};

class EnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                style={styles.headerStyle}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

class DRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      data: [],
      rowSelected: {},
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    return {
      data,
    };
  }

  handleClick = (item) => {
    this.setState({
      rowSelected: item,
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  render() {
    const {
      totalElements, rowsPerPage, page = 0, rowsPerPageOptions, phoneNumber
    } = this.props;
    const { rowSelected = {}, order, orderBy, data: { monthlyAnalytics = [], name = '', email = '' } = {} } = this.state;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container style={{ justifyContent: 'flex-start', margin: '20px' }} spacing={16}>
            {name && name !== '' ?
              <Grid item >
                <Typography variant="body2" color="secondary">
                  Delivery Boy Name:&emsp;
             <Typography style={{ display: 'inline' }} variant="body2">
                    {name}
                  </Typography>
                </Typography>
              </Grid> : null
            }
            {email && email !== '' ?
              <Grid item>
                <Typography variant="body2" color="secondary">
                  Delivery Boy email:&emsp;
                <Typography style={{ display: 'inline' }} variant="body2">
                    {email}
                  </Typography>
                </Typography>
              </Grid> : null
          }
          </Grid>
          <Grid item md={10} sm={12} style={{ overflow: 'scroll', margin: '0 auto' }}>
            <Table>
              <EnhancedTableHead
                numSelected={rowSelected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={monthlyAnalytics.length}
                cellStyle={styles.cellStyle}
              />
              <TableBody>
                {monthlyAnalytics && monthlyAnalytics.length > 0 && monthlyAnalytics.map(item => (
                  <TableRow
                    hover
                    onClick={() => this.handleClick(item)}
                    role="checkbox"
                    aria-checked={rowSelected.referralDate === item.referralDate}
                    tabIndex={-1}
                    key={item.referralDate}
                    selected={rowSelected && rowSelected.referralDate === item.referralDate}
                  >
                    <TableCell style={styles.cellStyle}>{moment(item.date).format('MMM YYYY')}</TableCell>
                    <TableCell style={styles.cellStyle}>{item.referralCount}</TableCell>
                    <TableCell style={styles.cellStyle}>{item.eligibleForCashback}</TableCell>
                    <TableCell style={styles.cellStyle}>{item.nonEligibleForCashback}</TableCell>
                  </TableRow>
                ))} { monthlyAnalytics && monthlyAnalytics.length === 0 &&
                  <TableCell colSpan={columnData.length} style={styles.error} >No data</TableCell>
                }
              </TableBody>
            </Table>
            {monthlyAnalytics && monthlyAnalytics.length > 0 ?
              <TablePagination
                component="div"
                count={totalElements}
                page={page}
                rowsPerPage={rowsPerPage}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
              : null
            }
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

DRList.propTypes = {
  data: PropTypes.instanceOf(Array),
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default DRList;
