export default {
    searchBar : {
        display: 'flex',
        padding: '5px 0 10px',
    },
    header: {
        fontWeight: '400',
        fontSize: '20px',
        textTransform: 'uppercase',
        padding: '16px 0 12px',
    },
    wname: {
        padding: '2px 5px',
        border: '1px solid #ccc',
        background: 'beige',
    },
    content: {
        display: 'flex',
    },
    mainContent: {
        margin: '0 auto',
        width: '50%',
        padding: '16px 32px',
    },
    actionContent: {
        width: '50%',
        background: '#f0f0f0',
        padding: '16px 32px',
        position: 'relative',
    },
    actionBox: {
        height: 'calc(100vh - 32px)',
        background: '#f0f0f0',
        position: 'sticky',
        top: '0px',
    },
    loading: {
        justifyContent: 'center',
        minHeight: '250px',
        display: 'flex',
        alignItems: 'center',
    },
    closeAssignBox: {
        position: 'absolute',
        top: '0',
        right: '-16px',
        cursor: 'pointer',
    },
    assignNew: {
        display: 'flex',
        margin: '10px 0',
    },
    assignObj: {
        padding: '2px 15px',
        border: '1px solid #ccc',
        background: 'antiquewhite',
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
        height: '35px',
    },
    assigned: {
        background: '#3f51b5',
        color: 'white',
        padding: '2px 5px',
        display: 'flex',
        alignItems: 'center',
        height: '37px',
    },
    btn: {
        margin: '0 5px',
    }
};
