import React, { Component } from 'react';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import SegregationSearch from '../component/SegregationSearch';
import RouteSegregationList from '../component/RouteSegregationList';
import apiCall, { routeSegregationListByFilters } from '../../../api/NetworkHandler';
import { ROWS_PER_PAGE } from '../../../constants';

const Constants = {
  title: 'Route Segregation',
};
class RouteSegregationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segregationList: [],
      filters: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        hubId: '',
        routeId: '',
        warehouseId: '',
        status: '',
      },
      totalElements: 0,
      currentPage: 0,
    };
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    });
    this.getSegregationData(filters, page);
  };

  getSegregationData = async (filters = {}, page = 0) => {
    try {
      const filterWithEndDate = {
        ...filters,
        endDate: filters.startDate,
      };
      const url = routeSegregationListByFilters(filterWithEndDate, page);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data = [], totalElements = 0 } } = res;
      this.setState({
        segregationList: data,
        totalElements,
      });
    } catch (e) {
      // error handling
    }
  }


  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.onSearch(currentPage);
      }
    });
  };

  render() {
    const {
      segregationList, filters, totalElements, currentPage,
    } = this.state;

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <SegregationSearch route />
        </SearchBar>
        <RouteSegregationList
          segregationList={segregationList}
          totalElements={totalElements}
          rowsPerPage={ROWS_PER_PAGE}
          page={currentPage}
          onPageChange={this.onSearch}
        />
      </div>
    );
  }
}

export default RouteSegregationContainer;
