import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, debounce } from 'lodash';
import DetailModal from '../DetailModal';
import QuickSearch from '../QuickSearch';
import apiCall, { getAllSubLocalities } from '../../../api/NetworkHandler';

var versionDict = {
  "subLocalityRequestId": 0,
};
class SubLocalitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      data: [],
      loadingData: false,
    };
  }

  componentDidMount() {
    this.fetchSubLocalities();
  };

  onSubLocalitySearch = (e) => {
    this.setState({
      query: e.target.value,
      data: [],
    }, () => this.fetchSubLocalities());
  };

  onSubLocalitySearchClear = () => {
    this.setState({
      query: '',
      data: [],
    });
  };

  onSelectData = (selectedSubLocality) => {
    const { obj } = this.props;
    const { cityName } = obj;
    this.props.onSelectLineItem({ subLocality: {...selectedSubLocality, city: cityName} });
  };

  getTableConfig = () => ({
    table: [
      {
        label: 'Sub Locality Name',
        pick: 'name',
      },
      {
        label: 'Locality Name',
        pick: 'locality',
      },
      {
        label: 'Pincode',
        pick: 'pincode',
      },
    ],
  });

  fetchSubLocalities = debounce( async () => {
    const { obj } = this.props;
    const { regionId, pincode, cityName, localityName } = obj;
    const { query } = this.state;
    this.setState({
      loadingData: true,
      noData: false,
    });
    try {
      versionDict.subLocalityRequestId += 1;
      let prevRequestId = versionDict.subLocalityRequestId;
      const response = await apiCall.sendRequest('get', getAllSubLocalities(regionId, pincode, cityName, localityName, query));
      const { data: { data = [] } = {} } = response
      if (versionDict.subLocalityRequestId == prevRequestId) {
        this.setState({
          data: data.map(el => { return {...el, pincode: pincode, locality: localityName} }),
          loadingData: false,
          noData: isEmpty(data),
        });
      }
    } catch(err) {
      this.setState({
        data: [],
        loadingData: false,
        noData: false
      });
    }
  }, 750);

  handleSelection = (e) => {
    const { obj } = this.props;
    const { pincode, cityName, localityName } = obj;
    if (e.key === 'Enter' && this.state.noData) {
      this.props.onSelectLineItem({
        subLocality: { name: this.state.query, pincode, city: cityName, locality: localityName },
      });
    }
  };

  render() {
    const { onClose } = this.props;
    const {
      query, data, noData, loadingData,
    } = this.state;
    return (
      <DetailModal title="Find Sub Locality" unMountComponent={onClose}>
        <QuickSearch
          label="Type sublocality name"
          query={query}
          onSearchChange={this.onSubLocalitySearch}
          onClearSearch={this.onSubLocalitySearchClear}
          data={data}
          loadingData={loadingData}
          onSelectRow={this.onSelectData}
          tableConfig={this.getTableConfig()}
          onKeyPress={this.handleSelection}
        />
        {noData && <div>Press Enter to add this as sublocality</div>}
      </DetailModal>
    );
  }
}

SubLocalitySearch.propTypes = {
  cityName: PropTypes.string,
  onClose: PropTypes.func,
  onSelectLineItem: PropTypes.func,
};

export default SubLocalitySearch;
