import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core';
import apiCall, {
  searchAllDeliveryBoys,
  searchAllCities,
  customerProfileByFilters,
  searchUSers,
  findHubPath,
  searchAllLocalities,
} from '../../../api/NetworkHandler';


import debounce from 'lodash/debounce';
const styles = {
  modal: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#ffffff',
    width: '70%',
    padding: 30,
  },
};

var versionDict = {
  'userVersion': 0,
  'customerVersion': 0,
  'deliveryBoyVersion': 0,
  'hubVersion': 0,
  'buildingVersion': 0,
  'localityVersion': 0,
  'cityVersion': 0,
}


class EntitySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
    };
  }

  setEmpty = () => {
    this.props.onSelect({});
  }

  searchUsers = debounce(async () => {
    try {
      versionDict.userVersion += 1;
      let PrevUserVersion = versionDict.userVersion;
      const response = await apiCall.sendRequest('get', searchUSers(this.state.searchText));
      const { data: { _embedded: { users = [] } = {} } = {} } = response;
      if (PrevUserVersion === versionDict.userVersion) {
        this.setState({
          suggestions: users,
        });
      }
    } catch (e) {
      this.setState({
        suggestions: [],
      });
    }
  }, 750)

  searchDeliveryBoys = debounce(async () => {
    try {
      versionDict.deliveryBoyVersion += 1;
      let prevDeliveryBoyVersion = versionDict.deliveryBoyVersion;
      const response = await apiCall.sendRequest('get', searchAllDeliveryBoys(this.state.searchText))

      const { data: { _embedded: embedded = {} } = {} } = response;
      if (prevDeliveryBoyVersion === versionDict.deliveryBoyVersion) {
        this.setState({
          suggestions: embedded['delivery-boys'],
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 500)

  searchHubs = debounce(async () => {
    try {
      versionDict.hubVersion += 1;
      let prevHubVersion = versionDict.hubVersion;
      const response = await apiCall.sendRequest('get', findHubPath(this.state.searchText))
      const { data: { _embedded: { hub = [] } = {} } = {} } = response;
      if (versionDict.hubVersion === prevHubVersion) {
        this.setState({
          suggestions: hub,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 750);

  searchBuildings = debounce(async () => {
    versionDict.buildingVersion += 1;
    let prevBuildingVersion = versionDict.buildingVersion;
    const { searchText } = this.state;
    const { address = {} } = this.props;
    const { cityName, localityName } = address;
    try {
      const response = await apiCall.sendRequest('get', getBuildingsWithFilter(searchText, cityName, localityName))
      const { data: { _embedded: { buildings = [] } = {} } = {} } = response;
      if (versionDict.buildingVersion === prevBuildingVersion) {
        this.setState({
          suggestions: buildings,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 500)

  searchLocalities = debounce(async () => {
    try {
      versionDict.localityVersion += 1;
      let prevlocalityVersion = versionDict.localityVersion;
      const { searchText } = this.state;
      const { address = {} } = this.props;
      const { cityName = '' } = address;
      const response = await apiCall.sendRequest('get', searchAllLocalities(cityName, searchText));
      const { data: { _embedded: { localities = [] } = {} } = {} } = response;
      if (versionDict.localityVersion === prevlocalityVersion) {
        this.setState({
          suggestions: localities,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 500)

  searchCities = debounce(async () => {
    try {
      versionDict.cityVersion += 0;
      let prevCityVersion = versionDict.cityVersion;
      const response = await apiCall.sendRequest('get', searchAllCities(this.state.searchText))
      const { data: { _embedded: { cities = [] } = {} } = {} } = response;
      if (versionDict.cityVersion === prevCityVersion) {
        this.setState({
          suggestions: cities,
        });
      }
    }
    catch (error) {
      console.error(error);
    }
  }, 500)

  searchCustomers = async () => {
    const { searchText } = this.state;
    const url = customerProfileByFilters(searchText);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = [] } } = response;
      this.setState({
        suggestions: data,
      });
    } catch (e) {
      this.setState({
        suggestions: [],
      });
    }
  }

  search = () => {
    const { entityType } = this.props;
    switch (entityType) {
      case 'DeliveryBoy': this.searchDeliveryBoys(); break;
      case 'Hub': this.searchHubs(); break;
      case 'Building': this.searchBuildings(); break;
      case 'Locality': this.searchLocalities(); break;
      case 'City': this.searchCities(); break;
      case 'Customer': this.searchCustomers(); break;
      default: this.searchUsers(); break;
    }
  }

  handleSearch = (event) => {
    this.setState({ searchText: event.target.value }, () => {
      this.search();
    });
  }
  render() {
    const { entityType, open, handleClose } = this.props;
    const { suggestions = [] } = this.state;
    return (
      <Modal
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <div style={styles.modal}>
          <Button style={{ float: 'right' }} onClick={handleClose} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs md={12}>
              <Typography variant="body2" color="secondary">
                {`Search ${entityType}`}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                value={this.state.searchText}
                onChange={event => this.handleSearch(event)}
                placeholder="Name"
              />
            </Grid>
            <Grid item md={3}>
              <Button color="secondary" variant="contained" onClick={this.search}>
                Search
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button color="secondary" onClick={this.setEmpty}>
                Set Empty
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '320px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Username</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suggestions.map(suggestion => (
                      <TableRow key={suggestion.id}>
                        <TableCell>{suggestion.email}</TableCell>
                        <TableCell>{suggestion.name}</TableCell>
                        <TableCell>{suggestion.mobile}</TableCell>
                        <TableCell><Button variant="contained" color="secondary" onClick={() => { this.props.onSelect(suggestion); }}>Select</Button></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

EntitySelection.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  entityType: PropTypes.string,
};

export default EntitySelection;
