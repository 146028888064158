import React from "react";
import PropTypes from 'prop-types';
import noConnectionImage from "../../images/No_Connection.png";
import { Empty } from 'antd';


const styles = {
  container: {
    margin: '48px auto',
    textAlign: 'center',
    maxWidth: '464px',
  },
  image: {
    display: 'block',
    height: 'auto',
    margin: '0 auto 24px',
    maxHeight: '400px',
    maxWidth: '160px',
    width: 'auto',
  },
  header: {
    fontSize: '1.42em',
    lineHeight: '1.2',
    color: '#172B4D',
    fontWeight: '500',
    marginTop: '0',
    marginBottom: '16px',
  },
  para: {
    color: '#172B4D',
    marginTop: '0',
    marginBottom: '24px',
  },
};

export default function Nodata(props) {
  const { searchTerm } = props;
  const data = {
	header: 'No data!',
	description: searchTerm ? 'Please try searching for something else.' : '',
	imageUrl: noConnectionImage,
  };

  return (
    <div style={styles.container}>
        {/* <img alt="" src={data.imageUrl} style={styles.image} /> */}
        <Empty />
  	    {/* <h4 style={styles.header}>{data.header}</h4> */}
        <p style={styles.para}>{data.description}</p>
    </div>
  );
}

Nodata.propTypes = {
	searchTerm: PropTypes.string,
}
  
Nodata.defaultProps = {
	searchTerm: '',
}
  
