import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core/';
import Search from '@material-ui/icons/Search';
import moment from 'moment';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
};
class DeliveryRouteSearch extends Component {
  static getDerivedStateFromProps(nextProps, nextState) {
    return {
      ...nextProps,
    };
  }

  onChangeFilter = (event) => {
    const { name, value } = event.target;
    const isNotDateField = name !== 'toDate' && name !== 'fromDate';
    const { onFilterChange, onWarehouseSelection, onHubSelection } = this.props;
    if (isNotDateField || this.isValidDateRange(name, value)) {
      if(name === 'warehouseId'){
        onWarehouseSelection(value);
      }
      if(name === 'hubId'){
        onHubSelection(value);
      }
      onFilterChange(name, value);
    }
  };

  isValidDateRange = (key, value) => {
    const {
      filters: { fromDate, toDate },
    } = this.props;
    const isEmpty = value === '';
    const isNotFutureDate = moment().isSameOrAfter(moment(value));
    const endDateValidation =
      key === 'toDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate)));
    const fromDateValidation =
      key === 'fromDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate)));
    return isEmpty || (isNotFutureDate && (endDateValidation || fromDateValidation));
  };

  clearAll = () => {
    const { onClearAll } = this.props;
    onClearAll();
  };

  render() {
    const {
      deliveryStatuses,
      customerComplaint = false,
      orderPriority,
      onButtonClick,
      filters = {},
      warehouses,
      hubs,
      routes,
      handleDownload
    } = this.props;
    const {
      query = '',
      fromDate,
      toDate,
      customerId,
      orderId,
      status,
      priority,
      warehouseId,
      editable = true,
      hubId,
      routeName,
    } = filters;
    const disabled = !editable || editable === 'false';
    return (
      <div style={styles.container}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Quick Search"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={query}
                  onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  type="date"
                  disabled={disabled}
                  label="From Date (Delivery)"
                  name="fromDate"
                  onChange={this.onChangeFilter}
                  value={fromDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  type="date"
                  label="To Date (Delivery)"
                  name="toDate"
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={toDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="customerId"
                  label="Customer Id"
                  fullWidth
                  type="number"
                  name="customerId"
                  onChange={this.onChangeFilter}
                  value={customerId || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="orderId"
                  fullWidth
                  label="Order Number"
                  type="number"
                  name="orderId"
                  onChange={this.onChangeFilter}
                  value={orderId || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {!customerComplaint && (
                <Grid item xs={6} md={3}>
                  <FormHelperText style={styles.label}>Status</FormHelperText>
                  <Select
                    name="status"
                    fullWidth
                    onChange={this.onChangeFilter}
                    value={status || ''}
                  >
                    {Object.keys(deliveryStatuses).map(costatus => (
                      <MenuItem value={costatus}>{deliveryStatuses[costatus]}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Priority</FormHelperText>
                <Select
                  name="priority"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={priority || ''}
                >
                  {Object.keys(orderPriority).map(coPriority => (
                    <MenuItem value={coPriority}>{orderPriority[coPriority]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Warehouse</FormHelperText>
                <Select
                  name="warehouseId"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={(+warehouseId) || ''}
                >
                  {warehouses.map(warehouse => (
                    <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Hub</FormHelperText>
                  <Select
                    name="hubId"
                    fullWidth
                    value={+hubId||''}
                    onChange={this.onChangeFilter}
                  >
                    {
                    hubs.map(hub => (
                      <MenuItem key={hub.id} value={hub.id}>{hub.name}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Route</FormHelperText>
                  <Select
                    name="routeName"
                    fullWidth
                    value={routeName||''}
                    onChange={this.onChangeFilter}
                  >
                    {
                    routes.map(route => (
                      <MenuItem key={route.id} value={route.name}>{route.name}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button color="secondary" variant="contained" onClick={() => onButtonClick('search')}>
                  Search
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button color="secondary" variant="outlined" onClick={() => onButtonClick('clear')}>
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button color="secondary" variant="contained" onClick={handleDownload}>
                  Download
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DeliveryRouteSearch.propTypes = {
  deliveryStatuses: PropTypes.instanceOf(Object),
  orderPriority: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  onClearAll: PropTypes.func,
  customerComplaint: PropTypes.bool,
  onButtonClick: PropTypes.func,
  warehouses: PropTypes.instanceOf(Array),
  handleDownload: PropTypes.func,
};

export default DeliveryRouteSearch;
