import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Colors from '../../../utils/Colors';

const colors = ['#000000', '#01a417', '#fa9917', '#f75f96'];
const styles = {
  number: (i, lastIndex) => ({
    fontSize: 40,
    borderRight: lastIndex ? 0 : '1px solid #000000',
    width: '98%',
    textAlign: 'center',
    lineHeight: 0.8,
    color: colors[i % 4],
  }),
  numberTitle: {
    textAlign: 'center',
  },
};

const labels = ['PR', 'PO', 'GRN', 'Hub', 'Route', 'NA'];

class NaMonitorCard extends Component {
  renderLabels = () => (
    <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
      <Grid md={2} style={{ marginBottom: 20 }}>
        <Typography>
          <div style={{ ...styles.numberTitle, textAlign: 'left' }}>Product Name</div>
        </Typography>
      </Grid>
      <Grid md={10}>
        <Grid container>
          {
          labels.map(label => (
            <Grid md={2}>
              <div style={styles.numberTitle}>{label}</div>
            </Grid>
          ))
          }
        </Grid>
      </Grid>
    </Grid>
  )
  render() {
    const {
      data = [],
      labelPosition = 'bottom',
      showDetailsButton = false,
      date,
      warehouseId,
    } = this.props;
    return (
      <Paper elevation={1} style={{ padding: 30, borderRadius: 0 }}>
        <Grid container spacing={8}>
          <Grid item md={6}>Product Quantity</Grid>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            {showDetailsButton &&
            <Typography>
              <a
                style={{ color: Colors.primary, textDecoration: 'none' }}
                href={`/na-monitoring?date=${date}&warehouse=${warehouseId}`}
              >Details
              </a>
            </Typography>}
          </Grid>
        </Grid>
        {labelPosition === 'top' && this.renderLabels()}
        {
          data.map((item) => {
            const {
              productItemId,
              productName,
              grnQuantity,
              poQuantity,
              prQuantity, hubQuantity,
              routeQuantity,
              shortageQuantity,
              } = item;
            return (
              <Grid container style={{ marginTop: 20, marginBottom: 20, padding: '10px 0px' }}>
                <Grid md={2}>
                  <Typography>
                    <a style={{ color: Colors.primary, textDecoration: 'none' }} href>{productName}</a>
                  </Typography>
                </Grid>
                <Grid md={10}>
                  <Grid container>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(0, false)}>{prQuantity || 0}</div>
                      </a>
                    </Grid>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(1, false)}>{poQuantity || 0}</div>
                      </a>
                    </Grid>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(2, false)}>{grnQuantity || 0}</div>
                      </a>
                    </Grid>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(3, false)}>{hubQuantity || 0}</div>
                      </a>
                    </Grid>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(4, false)}>{routeQuantity || 0}</div>
                      </a>
                    </Grid>
                    <Grid md={2}>
                      <a href style={{ textDecoration: 'none' }}>
                        <div style={styles.number(5, true)}>{shortageQuantity || 0}</div>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        }
        {labelPosition === 'bottom' && this.renderLabels()}
      </Paper>
    );
  }
}

NaMonitorCard.propTypes = {
  data: PropTypes.instanceOf(Array),
  labelPosition: PropTypes.string,
  showDetailsButton: PropTypes.bool,
  date: PropTypes.string,
  warehouseId: PropTypes.number,
};

// eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export default NaMonitorCard;
