import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchCategoriesByName } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchCategoriesByName(searchTerm))
  const { data: { _embedded } } = response;
  const { categories } = _embedded;
  
  return categories.map(category => {
    return {
      value: category.id,
      label: category.name,
    }
  })
}

function CategorySelector(props) {
  const { selected, onSelect, isMulti } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isMulti={isMulti}
    />
  )
}

CategorySelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
   /**
   * Multiselector
   */
  isMulti: PropTypes.bool,
};

CategorySelector.defaultProps = {
  selected: null,
  isMulti: false,
};

export default CategorySelector;
