import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../../components/NavBar';
import PackagingTable from './PackagingTable';
import PackagingSearchBar from './PackagingSearchBar';
import apiCall, { getPackaging, findPackaging } from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import debounce from 'lodash/debounce';
import { ColMod } from '../../../components/lib';
import { size } from 'lodash';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  fullScreen: {
    flex: 1,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
};


class PackagingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: null,
      packaging: [],
      links: {},
      openAddPackaging: false,
      selectedPackaging: {},
      selectedName: '',
      selectedDescription: '',
      isEdit: false,
      filters: {
        query: '',
      },
      searchedResult: false,
      currentPage: 0,
      rowsPerPage: 25,
      totalElements: 0,
      disableButton: false,
    };
  }

  componentDidMount() {
    this.fetchAllPackaging();
  }

  fetchAllPackaging = (page = 0) => {
    if (this.state.filters.query) {
      apiCall.sendRequest('get', findPackaging(this.state.filters.query, page))
        .then((response) => {
          this.setState({
            packaging: response.data._embedded.packaging,
            links: response.data._links,
            searchedResult: true,
            currentPage: response.data.page ? response.data.page.number:0,
            totalElements: response.data.page.totalElements
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('get', getPackaging())
        .then((response) => {
          this.setState({
            packaging: response.data._embedded.packaging,
            links: response.data._links,
            searchedResult: false,
            currentPage: response.data.page.number,
            totalElements: response.data.page.totalElements
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  fetchPaginatedPackaging = () => {
    const { currentPage: page, rowsPerPage: size } = this.state;

    apiCall.sendRequest('get', getPackaging(page, size))
      .then((response) => {
        this.setState({
          packaging: response.data._embedded.packaging,
          links: response.data._links,
          searchedResult: false,
          // grnList: [],
          currentPage: response.data.page.number,
          totalElements: response.data.page.totalElements
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

	handleQueryChange = (event) => {
	  const query = event.target.value;
	  this.setState(prevState => ({
	    filters: {
	      ...prevState.filters,
	      query,
	    },
	  }));
	}

  clearSearch = () => {
    this.setState({
      filters: {
        query: '',
      },
    }, () => {
      this.onSearch();
    });
  }

  onSearch = () => {
    this.fetchAllPackaging();
  }

  handleClick = (selectedPackaging) => {
    this.setState({
      selectedPackaging,
      selectedName: selectedPackaging.name,
      selectedDescription: selectedPackaging.description ? selectedPackaging.description : null,
    });
  }

	openAddPackaging = (packaging) => {
	  if (packaging) {
	    this.setState({
	      selectedName: packaging.name,
	      selectedDescription: packaging.description,
	    });
	  } else {
      this.setState({
        selectedName: null,
        selectedDescription: null,
        selectedPackaging: {},
      });
    }
	  this.setState({
	    openAddPackaging: true,
	    isEdit: !!packaging,
	  });
  }
  
  deletePackaging = (id) => {
	  if (id) {
      apiCall
      .sendRequest('delete', `/api/v1/packaging/${id}`)
      .then(() => {
          this.fetchAllPackaging();
      })
      .catch((err) => {
          console.log(err);            
      });
    }
  }

	handleClose = () => {
	  this.setState({
	    openAddPackaging: false,
      isEdit: false,
      nameMessage: '',
      invalidName: false,
      descriptionMessage: '',
      invalidDescription: false,
	  });
  }
  
  validateForm = () => {
    const { selectedName, selectedDescription } = this.state;
    let isFormValid = true;
    if (!selectedName) {
      this.setState({
        nameMessage: 'Name required',
        invalidName: true
      });
      isFormValid = false
    } else {
      this.setState({
        nameMessage: '',
        invalidName: false
      });
    }
    return isFormValid;    
  }

  postPackaging = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ disableButton: true });
    const postData = { ...this.state.selectedPackaging };
    postData.name = this.state.selectedName;
    postData.description = this.state.selectedDescription;
    try {
      await apiCall.sendRequest('post', '/api/v1/packaging', postData);
      this.fetchAllPackaging();
        this.handleClose();
    } catch (error) {
      console.log(error.response);
    }
    this.setState({ disableButton: false });
  }, 300);

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.fetchPaginatedPackaging() })
  }

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.fetchPaginatedPackaging() })
  }

  render() {
    const { invalidName, nameMessage, disableButton, selectedPackaging = {} } = this.state;
    const { id = null } = selectedPackaging;
    return (
      <div>
        <NavBar />
        <PackagingSearchBar openAddPackaging={this.openAddPackaging} handleQueryChange={this.handleQueryChange} onSearch={this.onSearch} clearSearch={this.clearSearch} searchQuery={this.state.filters.query} />
        <div style={styles.div}>
          <div style={styles.fullScreen}>
            <PackagingTable 
              packaging={this.state.packaging}
              openAddPackaging={this.openAddPackaging} 
              handleClick={this.handleClick} 
              links={this.state.links} 
              fetchPaginatedPackaging={this.fetchPaginatedPackaging} 
              searchedResult={this.state.searchedResult} 
              clearData={this.clearData} 
              deletePackaging={this.deletePackaging}
              page={this.state.currentPage}
              rowsPerPage={this.state.rowsPerPage}
              onPageChange={this.onPageChange}
              onRowsPerPageChange={this.onRowsPerPageChange}
              totalElements={this.state.totalElements}
            />
          </div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openAddPackaging}
            onClose={this.handleClose}
          >
            <div style={{
							position: 'relative',
							top: '50%',
							transform: 'translateY(-50%)',
							background: 'white',
							width: '70%',
							marginLeft: '15%',
							height: '70%',
						}}
            >
              <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <Row>
                      <Typography variant="body2" color="inherit">
                        {this.state.isEdit ? 'Update Packaging' : 'Create Packaging'}
                      </Typography>
                    </Row>
                    <Row>
                      <Typography variant="caption" color="secondary">
                        * Required
                      </Typography>
                    </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                          label="Name"
                          fullWidth
                          required
                          error={invalidName}
                          helperText={nameMessage}
                          value={this.state.selectedName}
                          onChange={(event) => {
														const {value} = event.target;
														this.setState({
															selectedName: value,
														});
                          }}
                          disabled={id}
                        />
                      </ColMod>
                    </Row>
                    <Row>
                      <ColMod lg={12}>
                        <TextField
                          label="Description"
                          fullWidth
                          value={this.state.selectedDescription}
                          onChange={(event) => {
														const {value} = event.target;
														this.setState({
															selectedDescription: value,
														});
													}}
                        />
                      </ColMod>
                    </Row>
                  </ColMod>
                </Row>
              </Grid>
              <div style={{ float: 'right', paddingRight: '2em' }}>
                <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
								Cancel
                </Button>
                <Button
                  onClick={() => {
                  this.postPackaging();
								}}
                  variant="contained"
                  color="secondary"
                  disabled={disableButton}
                >
                  {this.state.isEdit ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default PackagingComponent;
