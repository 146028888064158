import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, InfoWindow } from 'react-google-maps';


class MapRoute extends Component {
  render() {
    const {
      isMarkerShown, onMarkerClick, infoWindow, data, center,
    } = this.props;
    return (
            <div>
            <GoogleMap
                defaultZoom={15}
                center={center}
            >
                {
                    isMarkerShown &&
                    data.map((crd, i) => (
                        <Marker
                            label={crd.label}
                            labelStyle={{ color: 'white' }}
                            onClick={() => onMarkerClick(i)}
                            icon={crd.icon}
                            position={{ lat: parseFloat(crd.lat), lng: parseFloat(crd.lng) }}
                        >
                        {
                            infoWindow === i &&
                            <InfoWindow>
                                <p>{i + 1}
                                <br />Time: {moment.utc(crd.timestamp).local().format('HH:mm:ss')}
                                <br /> distance: {crd.distance / 1000} km
                                <br /> Travel Time: {crd.travelTime.toFixed(2)} mins
                                <br /> Time Spend : {moment(crd.timeLeave).diff(moment(crd.timeReached), 'minutes')} minutes
                                </p>
                            </InfoWindow>
                        }
                    </Marker>
                    ))
                }
                <Polyline
                    path={data}
                    geodesic
                    options={{
                        strokeColor: 'red',
                        strokeOpacity: 0.75,
                        strokeWeight: 2,
                    }}/>
            </GoogleMap>
            </div>
    );
  }
}

MapRoute.propTypes = {
  isMarkerShown: PropTypes.bool,
  onMarkerClick: PropTypes.func,
  infoWindow: PropTypes.number,
  data: PropTypes.instanceOf(Array),
  center: PropTypes.instanceOf(Object),
};

export default withScriptjs(withGoogleMap(MapRoute));
