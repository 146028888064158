import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import TableHeader from '../../Commons/components/TableHeader';

const columnData = [
  {
    id: 'Operation',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'modifiedBy',
    numeric: false,
    disablePadding: false,
    label: 'Updated By',
  },
  {
    id: 'timeUpdated',
    numeric: false,
    disablePadding: false,
    label: 'Time Updated',
  },
  {
    id: 'assignedTo',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
  },
];

const styles = {
  cellStyle: {
    textAlign: 'center',
    padding: '0px 10px',
  },
};

class TicketHistory extends Component {
  render() {
    const { data } = this.props;

    return (
      <div>
        {!isEmpty(data) && (
          <Table style={{ width: '100%' }}>
            <TableHeader columns={columnData} cellStyle={styles.cellStyle} />
            <TableBody>
              {data.map(audit => (
                <TableRow>
                  <TableCell style={styles.cellStyle}>{audit.action}</TableCell>
                  <TableCell style={styles.cellStyle}>{audit.updatedUserEmail}</TableCell>
                  <TableCell style={styles.cellStyle}>
                    {moment(audit.timeUpdated).format('lll')}
                  </TableCell>
                  <TableCell style={styles.cellStyle}>
                    {audit.assignedUser ? audit.assignedUser.email || '_' : '_'}
                  </TableCell>
                  <TableCell sstyle={styles.cellStyle}>{audit.comments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

TicketHistory.propTypes = {
  data: PropTypes.instanceOf(Array),
};

export default TicketHistory;
