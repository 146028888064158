import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, get } from 'lodash';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#2a2a2a',
    position: 'sticky',
    color: 'white',
    top: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const FooterStyle = {
  position: 'sticky',
  background: 'white',
  bottom: 0,
};

const styles = {
  selected: {
    background: '#dfdfdf',
  },
};

const LIMIT_OPTIONS = [20, 50, 100];

class TableBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: (props.urlValues && props.urlValues.limit) || props.limit || 20,
      page: (props.urlValues && props.urlValues.page) || props.page || 0,
    };
  }

  // componentDidUpdate() {
  //   const { updateMe=false } = this.props;
  //   if(updateMe){
  //     this.setState({ ...this.changeStateValues() });
  //   }
  // }

  onChangePage = (e, val) => {
    const pageObj = { page: val };
    this.setState(pageObj, this.updateParent.bind(null, pageObj));
  };

  onChangeLimit = (e) => {
    const limitObj = { limit: e.target.value };
    this.setState(limitObj, this.updateParent.bind(null, limitObj));
  };

  changeStateValues = () => {
    const { urlValues = {} } = this.props;
    const changedObject = Object.assign(
      {},
      { limit: urlValues.limit || 10 },
      { page: urlValues.page || 0 },
    );
    return Object.assign({}, { ...this.state }, { ...changedObject });
  };

  updateParent = (obj) => {
    const { pagination = true, onChange = null, updateUrl = null } = this.props;
    if (pagination) {
      // this.props.updateUrl && updateUrl(obj);
      onChange(obj);
    }
  };

  renderHeader = () => {
    const { config } = this.props;
    const { table = [] } = config;
    return (
      <TableRow>
        {table.map(cell => (
          <StyledTableCell style={cell.style} align="right">
            {cell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  };

  renderRow = (el) => {
    const { config, onRowClick = null, selectedRow = {} } = this.props;
    const clickEvent = onRowClick ? { onClick: onRowClick.bind(null, el) } : null;
    const { table = [] } = config;
    return (
      <StyledTableRow
        {...clickEvent}
        style={!isEmpty(selectedRow) && selectedRow.id === el.id ? styles.selected : null}
      >
        {table.map(cell => this.renderCell(cell, el))}
      </StyledTableRow>
    );
  };

  renderCell = (cell, data) => {
    const { actions = null } = this.props;
    if (cell.pick) {
      return this.renderConfig(cell, data);
    } else if (actions) {
      return (
        <TableCell style={cell.style}>
          {actions.map(el => (
            <Button onClick={() => el.event(data)} color="primary" variant="outlined">
              {el.name}
            </Button>
          ))}
        </TableCell>
      );
    }
    return null;
  };

  renderConfig = (cell, data) => {
    if (cell.check) {
      return <TableCell style={cell.style}>{cell.check(get(data, cell.pick))}</TableCell>;
    }
    if (cell.image) {
      return (
        <TableCell style={cell.style}>
          <img height="32" width="32" src={get(data, cell.pick)} alt="" />
        </TableCell>
      );
    }
    return <TableCell style={cell.style}>{get(data, cell.pick)}</TableCell>;
  };

  render() {
    const {
      data = [], total, loading, pagination = true,
    } = this.props;
    const { page, limit } = this.state;
    return (
      <Paper style={{ overflow: 'auto' }}>
        {loading && <div>LOADING...</div>}
        {!loading && !isEmpty(data) && (
          <Table>
            <TableHead>{this.renderHeader()}</TableHead>
            <TableBody>{data.map(el => this.renderRow(el))}</TableBody>
            {pagination && total > 20 && (
              <TableFooter style={FooterStyle}>
                <TablePagination
                  rowsPerPageOptions={LIMIT_OPTIONS}
                  count={total}
                  rowsPerPage={limit}
                  page={page}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeLimit}
                />
              </TableFooter>
            )}
            {!loading && isEmpty(data) && <div>NO DATA AVAILABLE</div>}
          </Table>
        )}
      </Paper>
    );
  }
}

TableBuilder.propTypes = {
  urlValues: PropTypes.instanceOf(Object),
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  updateMe: PropTypes.bool,
  pagination: PropTypes.bool,
  onChange: PropTypes.func,
  updateUrl: PropTypes.func,
  config: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func,
  selectedRow: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Array),
};

export default TableBuilder;
