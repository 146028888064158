import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import Drawer from '@material-ui/core/Drawer';
import apiCall, { getExternalReferrerByFilters } from '../../../api/NetworkHandler';
import ReferralList from '../components/ReferralList';
import CreateReferral from '../components/CreateReferral';
import ReferralSearch from '../components/ReferralSearch';

const styles = {
  container: {
    margin: 0,
  },
};

var versionDict = {
  "referralRequestId": 0
};

const ReferralStatuses = {
  'ALL': 'All',
  'ONGOING': 'Ongoing',
  'UPCOMING': 'Upcoming',
  'COMPLETED': 'Completed'
};

class ReferralContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralForm: false,
      referrals: [],
      page: 0,
      rowsPerPage: 25,
      count: 0,
      isLoading: false,
      selectedReferral: null,
      filters: {
        name: '',
        referralCode: '',
        status: 'ALL',
      },
    };
  };

  componentDidMount() {
    this.fetchAllReferral();
  };

  fetchAllReferral = async () => {
    const { filters, page, rowsPerPage: size } = this.state;
    this.toggleProgressBar(true);
    try {
       versionDict.referralRequestId += 1;
       let prevRequestId = versionDict.referralRequestId;
       const response = await apiCall.sendRequest('get', getExternalReferrerByFilters(filters, page, size));
       const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
       if (prevRequestId == versionDict.referralRequestId) {
         this.setState({
           referrals: content,
           count: totalElements
         });
       }
    } catch (error) {
      console.log('error', error);
      this.setState({
        referrals: [],
        count: 0,
      });
    }
    this.toggleProgressBar(false);
  };

  handleSearch = () => {
    this.fetchAllReferral();
  };

  handleOpenReferralForm = () => {
    this.setState({
      referralForm: true,
      selectedReferral: {},
    });
  };

  handleCloseReferralForm = () => {
    this.setState({
      referralForm: false,
      selectedReferral: null,
    });
  };

  handleEditReferral = (selectedReferral) => {
    this.setState({
      referralForm: true,
      selectedReferral,
    })
  };

  toggleProgressBar = (state) => {
    this.setState({
        isLoading: state,
    });
  };

  handleQueryChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  };

  handleClearFilters = () => {
    this.setState({
      filters: {
        name: '',
        referralCode: '',
        status: 'ALL'
      }
    });
  };

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => this.handleSearch());
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({
      rowsPerPage
    }, () => this.handleSearch());
  };

  render() {
    const { classes } = this.props;
    const {
      referralForm,
      referrals,
      rowsPerPage,
      page,
      count,
      isLoading,
      selectedReferral,
      filters
    } = this.state;

    const isNoData = referrals.length === 0;

    return (
      <React.Fragment>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <ReferralSearch
          onGenerateReferral={this.handleOpenReferralForm}
          onSearch={this.handleSearch}
          onChange={this.handleQueryChange}
          filters={filters}
          onClear={this.handleClearFilters}
          referralStatuses={ReferralStatuses}
        />
        <Grid className={classes.container} fluid>
          <Row lg={12}>
            <Col lg={12}>
              <ReferralList
                referrals={referrals}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                onEdit={this.handleEditReferral}
                isNoData={isNoData}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Col>
          </Row>
        </Grid>
        <Drawer anchor="right" open={referralForm} onClose={this.handleCloseReferralForm}>
          <CreateReferral
            onClose={this.handleCloseReferralForm}
            onRefresh={this.fetchAllReferral}
            selectedReferral={selectedReferral}
          />
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ReferralContainer);
