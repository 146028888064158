import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Lozenge from '@atlaskit/lozenge';
import { Switch as AntdSwitch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Product Item',
  },
  {
    id: "sortId", numeric: false, disablePadding: true, label: 'Sort Id',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: "actions", numeric: false, disablePadding: true, label: 'Actions',
  }
]

class WidgetProductList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      name: {},
      updatedAt: {},
      status: {}
    };
    const { deals } = this.props;

    deals.map((deal, index) => {
      customComponents['name'][index] = deal.name;
      customComponents['status'][index] = <Lozenge appearance={deal.active ? "success" : "removed"}>{deal.active ? "Active" : "Inactive"}</Lozenge>;
    })
    return customComponents;
  }

  getActions = (deal) => (
    <React.Fragment>
      <AntdSwitch
        key={deal.id}
        onChange={(e) => this.props.onStatusChange(e, deal)}
        checked={deal.active}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </React.Fragment>
  )

  render() {
    const {
      deals,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      onRequestSort,
      isNoData,
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={deals}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
        customComponents={this.getCustomComponents()}
        isNoData={isNoData}
      />
    )
  }
}

WidgetProductList.propTypes = {
  deals: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

WidgetProductList.defaultProps = {
  order: 'acs',
  orderBy: '',
}

export default WidgetProductList;
