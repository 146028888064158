import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { map, get, isEmpty, find } from 'lodash';
import InputDir from '../InputDir';
import Actions from './Actions';
import styles from './styles';
import moment from 'moment';
import HistoryContainer from '../../History/containers/HistoryContainer';
import apiCall, { getHistory } from '../../../api/NetworkHandler';

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      showHistory: false,
      count: 0,
    };
  }

  onChangeFilter = (e) => {
    this.props.onChangeFilter(e.target.value);
  };

  onChangeUpcomingItemsConfig = (e, id) => {
    const { name } = e.target;
    const { data } = this.props;
    let { value } = e.target;
    if (name === 'quantity' && value < 0) {
      value = 0;
    }
    this.props.onUpcomingDetailsChange({
      upComingOrder: data.map((el) => {
        if (el.productItemId === id) {
          return Object.assign({}, el, { [name]: value }, { shouldUpdate: true });
        }
        return el;
      }),
    });
  };

  onUpdateItem = (id, isDelete = false) => {
    const {
      data, defaultAddress, customerId, filter,
    } = this.props;
    const reqData = find(data, el => el.productItemId === id);
    const reqObject = [
      {
        productItemId: reqData.productItemId,
        quantity: isDelete ? 0 : reqData.quantity,
      },
    ];
    this.props.updateUpcomingItems(customerId, defaultAddress.id, filter, reqObject);
  };

  geItemsConfig = () => {
    const { upStyle } = styles;
    return [
      {
        text: 'Item',
        pick: 'product.name',
        style: upStyle.productName,
      },
      {
        text: 'Measure',
        pick: 'product.productItems[0].unitMeasure',
        style: upStyle.measure,
      },
      {
        text: 'Unit',
        pick: 'product.productItems[0].uom.code',
        style: upStyle.unit,
      },
      {
        text: 'Unit Price',
        pick: 'product.productItems[0].unitPrice',
        style: upStyle.unitPrice,
      },
      {
        text: 'Quantity',
        pick: 'quantity',
        componet: true,
        componetType: 'input',
        props: {
          type: 'number',
          name: 'quantity',
          inputStyle: styles.inputItems,
        },
        style: upStyle.quantity,
      },
      {
        componet: true,
        componetType: 'action',
        style: upStyle.actions,
      },
    ];
  };

  getFormattedData = (data) => {
    if (isEmpty(data)) return;
    data.product.productItems[0]["unitPrice"] = data.product.productItems[0].offerPrice ? data.product.productItems[0].offerPrice : data.product.productItems[0].sellingPrice;
    return data;
  };

  checkQuantity = (obj) => {
    if (isEmpty(obj)) return;

    if (obj.quantity < 1) return true;
    else return false;
  };
  setPlanItemId = (productId) => {
    this.setState({
      count: 0,
    }, () => {
      this.onViewHistory(productId)
    });
  }
  onViewHistory = async (productId) => {
    const { customerId, filter } = this.props;
    try {
      const response = await apiCall.sendRequest('post', getHistory(), (
        {
          "methodName": 'PlanAndGetOnceHistory',
          "params": JSON.stringify({
            "customerId": customerId,
            "productId": productId,
            "deliveryDate": filter,

          })
        })
      )
      const { data: { data } } = response;
      const { totalquantity, history } = data;
      this.setState({
        history: history,
        showHistory: true,
        count: totalquantity,
      });
    }
    catch (error) {
      console.error(error);
      this.setState({
        history: [],
        showHistory: false,
        count: 0,
      });
    }

  }
  handleCloseHistory = () => {
    this.setState({
      showHistory: false,
    });
  };

  renderRow = (header = false, data = {}, isEditAllowed = false) => {
    const formattedData = this.getFormattedData(data);
    const config = this.geItemsConfig();
    const isQuantityEmpty = this.checkQuantity(data);
    const cartItemStyle = data.newItem
      ? { ...styles.cartItem, ...styles.newCartItem }
      : styles.cartItem;
    return (
      <div style={header ? { ...styles.cartItem, ...styles.light } : cartItemStyle}>
        {config.map((el) => {
          if (header) {
            return <div style={{ ...styles.itemStyle, ...el.style }}>{el.text}</div>;
          }
          if (!header) {
            if (el.componet && el.componetType === 'input') {
              return (
                <InputDir
                  {...el.props}
                  style={{ ...styles.itemStyle, ...el.style }}
                  onChange={e => this.onChangeUpcomingItemsConfig(e, data.productItemId)}
                  value={get(data, el.pick)}
                  disabled={!isEditAllowed}
                />
              );
            }
            if (el.componet && el.componetType === 'action') {
              return (
                <Actions
                  isUpdateDisabled={!data.shouldUpdate || isQuantityEmpty}
                  onUpdate={() => this.onUpdateItem(data.productItemId)}
                  onDelete={() => this.onUpdateItem(data.productItemId, true)}
                  isDeleteAllowed={!isEditAllowed}
                  viewHistory={() => this.setPlanItemId(data.productItemId)}
                />
              );
            }
            if (!el.componet) {
              return (
                <div style={{ ...styles.itemStyle, ...el.style }}>{get(formattedData, el.pick) || '_'}</div>
              );
            }
          }
          return null;
        })}
      </div>
    );
  };

  renderTable = (d, isEditAllowed) => (
    <div style={styles.orderItems}>
      {this.renderRow(true)}
      {map(d, el => this.renderRow(false, el, isEditAllowed))}
    </div>
  );

  calculateTotalPrice = (data) => {
    let totalPrice = 0;
    data.forEach(el => {
      let unitPrice = el.product.productItems[0].offerPrice ? el.product.productItems[0].offerPrice : el.product.productItems[0].sellingPrice;
      totalPrice += (el.quantity * unitPrice)
    });
    return totalPrice;
  };

  render() {
    const { filter, data, openModal } = this.props;
    const { showHistory, count, history } = this.state;
    const totalUpcomingOrderValue = this.calculateTotalPrice(data);
    const isEditAllowed = !moment(filter).isSameOrBefore(moment(), "days");
    return (
      <div style={styles.editContent}>
        <div style={styles.upcomingFilter}>
          <span style={styles.errorTitle}>{!isEditAllowed && "**This order is not editable, please choose tomorrow's date onwards"}</span>
          <InputDir
            label="Delivery Date"
            name="date"
            type="date"
            value={filter}
            onChange={this.onChangeFilter}
          // style={styles.upcomingDate}
          />
        </div>
        {isEmpty(data) && <div style={styles.empty}>No Upcoming Deliveries</div>}
        <div style={styles.content}>
          {!isEmpty(data) && this.renderTable(data, isEditAllowed)}
          {!isEmpty(data) && <div style={styles.orderTotal.div}>
            <span style={styles.orderTotal.upcomingTitle}>Total Amount</span>
            <span style={styles.orderTotal.upcomingValue}>{(totalUpcomingOrderValue).toFixed(2)}</span>
          </div>}
          <Button
            style={styles.addNewBtn}
            onClick={() => openModal('productSearch', 'upcoming')}
            color="primary"
            variant="outlined"
            size="small"
            disabled={!isEditAllowed}
          >
            + Add New Item
          </Button>
        </div>
        <HistoryContainer
          openHistory={showHistory}
          handleClose={this.handleCloseHistory}
          data={history}
          title='Get Once History'
          count={1}
          parent="getOnce"
        />
      </div>
    );
  }
}

Upcoming.propTypes = {
  filter: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  customerId: PropTypes.string,
  openModal: PropTypes.func,
  defaultAddress: PropTypes.instanceOf(Object),
  onUpcomingDetailsChange: PropTypes.func,
  updateUpcomingItems: PropTypes.func,
  undoUpcomingItems: PropTypes.func,
  onChangeFilter: PropTypes.func,
};

export default Upcoming;
