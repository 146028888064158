import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
    TextField,
    Typography,
    InputAdornment,
    Button,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  },
};

function AppWidgetSearch(props) {
    const {
        classes,
        onCreateAppWidgets,
        query,
        handleQueryChange,
        onSearch,
        onReorder,
        onClearSearch
    } = props;

    return (
        <Grid fluid className={classes.container}>
            <Row md={12} middle="md">
                <Col md={2}>
                    <Typography variant="title" color="inherit">
                        App Widgets
                    </Typography>
                </Col>
                <Col md={10}>
                    <Row md={12} middle="md">
                        <Col md={12}>
                            <Row md={12} bottom="md">
                                <ColMod md={4}>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Search widgets (By title)"
                                        fullWidth
                                        value={query || ''}
                                        onChange={handleQueryChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="off"
                                    />
                                </ColMod>
                                <ColMod md={1}>
                                    <Button color="inherit" variant="outlined" onClick={onSearch}>
                                        Search
                                    </Button>
                                </ColMod>
                                <ColMod md={1}>
                                    <Button color="secondary" variant="outlined" onClick={onClearSearch}>
                                        Clear
                                    </Button>
                                </ColMod>
                                <ColMod md={2}>
                                    {' '}
                                </ColMod>
                                <ColMod md={2}>
                                    <Button color="primary" variant="contained" onClick={onReorder}> Re-order </Button>
                                </ColMod>
                                <ColMod md={2}>
                                    <Button color="primary" variant="outlined" onClick={onCreateAppWidgets}> New App Widget </Button>
                                </ColMod>
                            </Row>
                        </Col>  
                    </Row>
                </Col>
            </Row>
        </Grid>
    )
}

AppWidgetSearch.propTypes = {
  onCreateAppWidgets: PropTypes.func,
  query: PropTypes.string,
  handleQueryChange: PropTypes.func.isRequired,
  OnSearch: PropTypes.func.isRequired,
};

AppWidgetSearch.defaultProps = {
  query: '',
};

export default withStyles(styles)(AppWidgetSearch);
