import React from 'react';
import NavBar from '../../components/NavBar';
import SendSms from './components/SendSmsComponent';

class SendSmsContainer extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <SendSms />
      </div>
    );
  }
}

export default SendSmsContainer;
