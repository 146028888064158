import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { Grid, Row } from 'react-flexbox-grid';
import cx from 'classnames';
import { getPreciseDistance } from 'geolib';
import { ColMod } from '../../../components/lib';
import NavBar from '../../../components/NavBar';
import apiCall, { fullDeliveryRouteOrderPath, routeTravelledPath, getWareHouse, getWarehouseHubs } from '../../../api/NetworkHandler';
import UserSelection from '../../../components/UserSelection';
import utils from '../../../utils/queryBuilder';
import QuantityPicker from '../../../components/QuantityPicker';
import TableHeader from '../../Commons/components/TableHeader';
import MapRoute from '../components/MapRoute';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HubSelector from '../../../components/Hub/Selector';
import { FormHelperText } from '@material-ui/core/';



const canView = utils.isCurrentUserAuthorizedToView;
const statuses = ['Delivered', 'OnRoute', 'PartiallyDelivered'];
const TIME_SPEND = 600;
const DISTANCE_DIFF_IN_METERS = 50;
const mapStop = require('../../../../public/images/mapCircle2.png');

const columnData2 = [
  {
    id: 'routes', numeric: false, disablePadding: true, label: 'Routes',
  },
  {
    id: 'deliveryBoy', numeric: false, disablePadding: true, label: 'Delivery Boy',
  },
  {
    id: 'supervisor', numeric: false, disablePadding: true, label: 'Supervisor',
  },
  {
    id: 'hubName', numeric: false, disablePadding: true, label: 'Hub Name',
  },
  {
    id: 'warehouseName', numeric: false, disablePadding: true, label: 'Warehouse',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const columnData3 = [
  {
    id: 'routes', numeric: false, disablePadding: true, label: 'Routes',
  },
  {
    id: 'segregated', numeric: false, disablePadding: true, label: 'Segregated by',
  },
  {
    id: 'supervisor', numeric: false, disablePadding: true, label: 'Supervisor',
  },
  {
    id: 'hub', numeric: false, disablePadding: true, label: 'Hub Name',
  },
  {
    id: 'warehouseName', numeric: false, disablePadding: true, label: 'Warehouse',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const columnData4 = [
  {
    id: 'hub', numeric: false, disablePadding: true, label: 'Hub Name',
  },
  {
    id: 'warehouseName', numeric: false, disablePadding: true, label: 'Warehouse',
  },
];

const columnData5 = [
  {
    id: 'item_name', numeric: false, disablePadding: true, label: 'Item Name',
  },
  {
    id: 'packaging', numeric: false, disablePadding: true, label: 'Packaging',
  },
  {
    id: 'unit_measure', numeric: false, disablePadding: true, label: 'Unit Measure',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'reason', numeric: false, disablePadding: false, label: 'Reason',
  },
  {
    id: 'actions', numeric: false, disablePadding: false, label: 'Actions',
  },
];

const columnData6 = [
  {
    id: 'item_name', numeric: false, disablePadding: true, label: 'Item Name',
  },
  {
    id: 'packaging', numeric: false, disablePadding: true, label: 'Packaging',
  },
  {
    id: 'unit_measure', numeric: false, disablePadding: true, label: 'Unit Measure',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'reason', numeric: false, disablePadding: false, label: 'Reason',
  },
  {
    id: 'actions', numeric: false, disablePadding: false, label: 'Actions',
  },
];

class App extends React.Component {
  static propTypes ={
  }

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      useContainer: false,
      list: [],
      links: {},
      deliveryData: [],
      deliveryData2: [],
      deliveryRoutes: [],
      selectedDeliveryRoute: null,
      selectedDeliveryRoutePickList: null,
      selectedHub: null,
      deliveryRouteOrders: [],
      editableDeliveryRouteOrders: [],
      deliveryRoutePickList: [],
      segregationPickList: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      selectIndex: 0,
      postData: {},
      openEditDeliveryRoute: false,
      openEditSegregationPickList: false,
      uniquePickers: [],
      datePicker: moment().format('YYYY-MM-DD'),
      status: '',
      getUrlRouteName: '',
      filters: {
        deliveryRoutesQuery: '',
        deliveryRoutesOrderQuery: '',
        segregationPickListQuery: '',
        segregationItemsQuery: '',
        deliveryPickListQuery: '',
        deliveryPickListItemsQuery: '',
        warehouseId: null,
        hubId: null,
      },
      selectedRouteCoords: [],
      infoWindow: null,
      warehouseList: [],
      hubs: [],
    };
  }


  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const getUrlRouteName = query.get('routeName');
    const deliveryDate = query.get('deliveryDate') || moment().format('YYYY-MM-DD');
    if (getUrlRouteName && getUrlRouteName !== '') {
      const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
      const queryString = `query=${getUrlRouteName}&date=${moment(deliveryDate).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}`;
      apiCall.sendRequest('get', `api/v1/delivery-routes/search/findDeliveryRoutes?${queryString}`)
        .then((response) => {
          const { data: { _embedded: embedded = {} } } = response;
          const deliveryRoutes = embedded['delivery-routes'];
          this.handleDeliveryRouteClick(null, deliveryRoutes[0]);
          this.setState({
            deliveryRoutes,
            getUrlRouteName,
            datePicker: moment(deliveryDate).format('YYYY-MM-DD'),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.getDeliveryRoutes();
    }
    this.fetchAllWarehouse();
  }

  _toggleContainer() {
    this.setState({ useContainer: !this.state.useContainer });
  }

  updateDeliveryRouteSequenceAddresses = (sequence, id) => {
    apiCall.sendRequest('patch', `api/v1/delivery-route-order/${id}`, { sequence })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  _onDeliveryRouteOrderListChange = (oldIndex, newIndex) => {
    const newList = this.state.deliveryRouteOrders.map(item => ({ ...item }));
    const tempVar = newList[oldIndex];
    newList.splice(oldIndex, 1);
    newList.splice(newIndex, 0, tempVar);

    if (oldIndex < newIndex) {
      for (let i = oldIndex; i <= newIndex; i++) {
        newList[i].sequence = this.state.deliveryRouteOrders[i].sequence;
        this.updateDeliveryRouteSequenceAddresses(newList[i].sequence, newList[i].id);
      }
    } else {
      for (let i = newIndex; i <= oldIndex; i++) {
        newList[i].sequence = this.state.deliveryRouteOrders[i].sequence;
        this.updateDeliveryRouteSequenceAddresses(newList[i].sequence, newList[i].id);
      }
    }
    this.setState({
      deliveryRouteOrders: newList,
    });
  }

  handleMarkerClick = (index) => {
    this.setState({ infoWindow: index });
  }

  handleStatusChange = (event, value, index) => {
    const val = event.target.value;
    const { selectedDeliveryRoute } = this.state;
    apiCall.sendRequest('patch', `api/v1/delivery-route-order/${value.id}`, { status: val })
      .then((response) => {
        this.setState(prevState => ({
          deliveryRouteOrders: [
            ...prevState.deliveryRouteOrders.slice(0, index),
            {
              ...prevState.deliveryRouteOrders[index],
              status: val,
            },
            ...prevState.deliveryRouteOrders.slice(index + 1),
          ],
        }));
        this.handleDeliveryRouteClick(event, selectedDeliveryRoute);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderMapView = () => {
    const { selectedRouteCoords, infoWindow } = this.state;
    let cordsToRender = [];
    let lastCords = {};
    let totalDistance = 0;
    let lastDistanceInMeters = 0;
    let travelTime = 0;
    let timeReached = '';
    let timeLeave = '';
    let label = 2;
    selectedRouteCoords.map((cords, i) => {
      const { latitude: lat, longitude: lng, timestamp } = cords;
      if (i === 0) {
        lastCords = cords;
        timeReached = timestamp;
        timeLeave = timeReached;
        return null;
      }
      const { latitude: prevLat, longitude: prevLng, timestamp: prevTimestamp } = lastCords;
      const distanceInMeters = getPreciseDistance(
        { latitude: prevLat, longitude: prevLng },
        { latitude: lat, longitude: lng }, 1,
      );
      if (distanceInMeters > DISTANCE_DIFF_IN_METERS || (i === (selectedRouteCoords.length - 1))) {
        const timeSpend = moment(timeLeave).diff(moment(timeReached), 'seconds');
        cordsToRender.push({
          id: lastCords.id,
          lat: prevLat,
          lng: prevLng,
          timestamp: prevTimestamp,
          distance: lastDistanceInMeters,
          travelTime: travelTime / 60,
          timeReached,
          timeLeave,
          timeSpend,
          icon: timeSpend > TIME_SPEND ? null : mapStop,
          label: timeSpend > TIME_SPEND ? `${label}` : null,
        });
        if (cordsToRender.length === 1) {
          cordsToRender[0].icon = null;
          cordsToRender[0].label = `${1}`;
        }
        if (timeSpend > TIME_SPEND && cordsToRender.length > 1) label += 1;
        lastCords = cords;
        timeReached = timestamp;
        travelTime = moment(timestamp).diff(moment(timeLeave), 'seconds');
        lastDistanceInMeters = distanceInMeters;
      }
      timeLeave = timestamp;
      if (i > 0) {
        const lastPoint = selectedRouteCoords[i - 1];
        const distanceFromLastPoint = getPreciseDistance(
          { latitude: lastPoint.latitude, longitude: lastPoint.longitude },
          { latitude: lat, longitude: lng }, 1,
        );
        totalDistance += distanceFromLastPoint;
      }
      if (i === (selectedRouteCoords.length - 1)) {
        travelTime = moment(timestamp).diff(moment(prevTimestamp), 'seconds');
        cordsToRender.push({
          lat,
          lng,
          timestamp,
          distance: lastDistanceInMeters,
          travelTime: travelTime / 60,
          timeReached,
          timeLeave: timestamp,
          label: `${label}`,
        });
      }
      return cords;
    });
    cordsToRender = cordsToRender.map((c, i) => ({
      ...c,
      lat: parseFloat(c.lat),
      lng: parseFloat(c.lng),
    }));
    let center = { lat: 13.00818, lng: 77.5268797 };
    let totalTime = 0;
    if (cordsToRender.length > 0) {
      const { timestamp: startTime = '' } = cordsToRender[0];
      const { timestamp: endTime = '' } = cordsToRender[cordsToRender.length - 1];
      const [firstCord = {}] = cordsToRender;
      center = { lat: firstCord.lat, lng: firstCord.lng };
      totalTime = moment(endTime).diff(moment(startTime), 'minutes');
    }
    return (
      <Paper elevation={1} style={{ width: '100%' }}>
        <p style={{ paddingLeft: 10 }}>
          <b>Total Time:</b> {totalTime > 60 ? `${parseInt(totalTime / 60, 10)} hrs ${totalTime % 60}` : totalTime } mins
          | <b>Total Distance:</b> {totalDistance / 1000} kms
        </p>
        <MapRoute
        isMarkerShown
        infoWindow={infoWindow}
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAKQvG2v5odEuBoH2i_ujPiKzwoOoHtkfQ&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        onMarkerClick={this.handleMarkerClick}
        data={cordsToRender}
        center={center}
      />
    </Paper>
    );
  }

  renderDeliveryRouteDragList =() => {
    const { useContainer } = this.state;
    return (
      <div>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span style={{ fontSize: 20, fontWeight: '600' }}>Delivery Map </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: 0 }}>
          {this.renderMapView()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <span style={{ fontSize: 20, fontWeight: '600' }}>Orders </span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: 0 }}>
      {(this.state.deliveryRouteOrders.length == 0 || typeof this.state.deliveryRouteOrders === 'undefined') &&
        <div style={{ height: '50px', marginLeft: '32%' }}>
          <span style={{ color: 'red', fontSize: '20px' }}>{this.state.deliveryRouteErrorText}</span>
        </div>}
      <div
        className="list"
        ref={(el) => {
            if (el) this._container = el;
          }}
        style={{
            overflow: useContainer ? 'auto' : '',
            height: useContainer ? '200px' : '',
            border: useContainer ? '1px solid gray' : '',
          }}
      >
        {this.state.deliveryRouteOrders.map((orders, index) => (
          <Paper key={index} elevation={1} style={{ padding: '10px', marginBottom: '10px' }}>
            <div style={{ display: 'flex' }}>
              <Typography variant="body2" style={{ flex: 2 }}>
                  Customer Address #{ String(orders.addressId).padStart(4, '0')}
              </Typography>
              <span style={{ flex: 1 }}>
                <Link to={`/edit-co/${orders.customerOrder}`}>
                  {(`Order #${String(orders.customerOrder).padStart(6, '0')}`)}
                </Link>
              </span>
              <div style={{ flex: 1, textAlign: 'right' }}>
                {
                  orders.priority &&
                  <span style={{
                  padding: 1,
                  display: 'inline-block',
                  background: 'yellow',
                  fontSize: '15px',
                }}
                  >{orders.priority}
                  </span>
              }
              </div>
            </div>
            <div>
              <Typography variant="body2">
                  Time Updated : { moment(orders.timeUpdated).format('lll')}
              </Typography>
            </div>

            <div>
              <Typography style={{ fontWeight: '400', fontSize: '1.44em' }} variant="body2">
                <span
                  style={{ color: '#f50057' }}
                  onMouseEnter={() => {
                      this.setState(prevState => ({
                          editableDeliveryRouteOrders: [
                            ...prevState.editableDeliveryRouteOrders.slice(0, index),
                            1,
                            ...prevState.editableDeliveryRouteOrders.slice(index + 1),
                          ],
                        }));
                    }}
                  onMouseLeave={() => {
                      this.setState(prevState => ({
                          editableDeliveryRouteOrders: [
                            ...prevState.editableDeliveryRouteOrders.slice(0, index),
                            0,
                            ...prevState.editableDeliveryRouteOrders.slice(index + 1),
                          ],
                        }));
                    }}
                >
                  {this.state.editableDeliveryRouteOrders[index] ?
                    <TextField defaultValue={index + 1} style={{ width: '1.5em' }} onKeyPress={(event) => { if (event.key === 'Enter') { this._onDeliveryRouteOrderListChange(index, event.target.value - 1); } }} />
                    : <span color="secondary">{index + 1}</span>
                  }
                </span>
                {' '}
                {orders.customerName }
                <span style={{ float: 'right' }}>
                  <MaterialSelect
                    value={orders.status}
                    onChange={(event) => { this.handleStatusChange(event, orders, index); }}
                    input={<Input name="age" id="age-helper" />}
                  >
                    {Object.keys(this.props.DeliveryStatuses).map(x => (
                      <MenuItem value={x}>
                        {this.props.DeliveryStatuses[x]}
                      </MenuItem>
                      ))}
                  </MaterialSelect>
                </span>
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                {orders.address}
              </Typography>
            </div>
          </Paper>
          ))}
      </div>
      </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }

  handleInputChange = prop => (event) => {
    const index = prop.split('$')[1];
    if (prop.split('$')[0] == 'delivery') {
      this.setState({ d_boys: this.state.deliveryData[index].d_boy_options });
    }
  }

  dListClick = val => (event) => {
    const temp = this.state.deliveryData;
    temp[this.state.selectIndex].selected_d_boy = val;
    this.setState({ d_boys: [], deliveryData: temp });
  }

  handleDelivery2Change = (event) => {
    const temp = this.state.deliveryData2;
    temp[this.state.selectIndex].assigned_boy = event.target.value;
    this.setState({ deliveryData2: temp });
  }

  handleSegChange = (event) => {
    const temp = this.state.deliveryData;
    temp[this.state.selectIndex].selected_s_boy = event.target.value;
    this.setState({ deliveryData: temp });
  }

  changeData = (id, value) => {
    const temp = this.state.list;
    const idx = temp.map(x => x.id).indexOf(id);
    temp[idx].route_assigned = value;
    this.setState({
      list: temp,
    });
  }

  getCoordinatesOfRoute = async (routeId) => {
    const response = await apiCall.sendRequest('get', routeTravelledPath(routeId));
    const { data: { data = [] } } = response;
    this.setState({
      selectedRouteCoords: data,
    });
  }

  handleDeliveryRouteClick = (event, n) => {
    this.getCoordinatesOfRoute(n.id);
    this.setState({
      selectedDeliveryRoute: n,
      routeSelected: n.id,
    }, async () => {
      const { id } = n;
      const { status } = this.state;
      try {
        if (id) {
          const url = fullDeliveryRouteOrderPath(id, status === 'all' ? '' : status);
          const response = await apiCall.sendRequest('get', url);
          const { data: { data = {} } } = response;
          const { deliveryRouteOrders } = data;
          const modifiedRouteOrders = deliveryRouteOrders.map((routeOrder) => {
            const { deliveryRouteOrder, customerOrder } = routeOrder;
            return {
              ...deliveryRouteOrder,
              priority: customerOrder.priority,
            };
          });

          modifiedRouteOrders.sort((a, b) =>
            (moment(a.timeUpdated).isBefore(b.timeUpdated) ? -1 : 1));

          this.setState({
            deliveryRouteOrders: modifiedRouteOrders,
            editableDeliveryRouteOrders: new Array(modifiedRouteOrders.length).fill(0),
          });
        } else {
          this.setState({
            deliveryRouteOrders: [],
            editableDeliveryRouteOrders: [],
          });
        }
      } catch (e) {
        this.setState({
          deliveryRouteOrders: [],
          editableDeliveryRouteOrders: [],
          deliveryRouteErrorText: e.response.data.message,
        });
      }
    });
  }

  fetchSegregationPickList = () => {
    const { selectedDeliveryRoutePickList = {} } = this.state;
    apiCall.sendRequest('get', `/api/v1/delivery-routes/${selectedDeliveryRoutePickList.id}/deliveryRoutePickLists`)
      .then((response) => {
        if (response.data && response.data._embedded['delivery-route-pick-list']) {
          this.setState({
            segregationPickList: response.data._embedded['delivery-route-pick-list'],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSegregationPickupListClick = (event, n) => {
    this.setState({
      selectedDeliveryRoutePickList: n,
      segregationSelected: n.id,
    }, () => {
      this.fetchSegregationPickList();
    });
  }

  fetchDeliveryRoutePickList = () => {
    const { datePicker, selectedHub } = this.state;
    apiCall.sendRequest('get', `/api/v1/delivery-routes/get-hub-items-by-date?date=${moment(datePicker).format('YYYYMMDD')}&hub=${selectedHub.id}`)
      .then((response) => {
        if (response.data && response.data.list) {
          this.setState({
            deliveryRoutePickList: [
              ...response.data.list,
            ],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleDeliveryRoutePickupListClick = (event, n) => {
    this.setState({
      selectedHub: n,
      hubSelected: n.id,
    }, this.fetchDeliveryRoutePickList);
  }

  editDeliveryRoute = deliveryRoute => () => {
    this.setState({
      postData: deliveryRoute,
    }, () => {
      this.openEditDeliveryRoute();
    });
  }

  editSegregationPickList = segregationPickList => () => {
    this.setState({
      postData: segregationPickList,
    }, () => {
      this.openEditSegregationPickList();
    });
  }

  handleChangePage = (event, page) => {
    let url = '';
    if (this.state.page < page) {
      url = this.state.links.next.href;
    } else {
      url = this.state.links.prev.href;
    }
    this.fetchPaginatedDeliveryRoute(url);
  }

  handleChangeDeliveryRowsPerPage = (event) => {
    const { page, filters, datePicker } = this.state;
    const { deliveryRoutesQuery } = filters;
    const { value } = event.target;
    let url = '';
    if (value) {
      this.setState({ rowsPerPage: value });
      url = `api/v1/delivery-routes/search/findDeliveryRoutes?query=${deliveryRoutesQuery}&date=${datePicker}&size=${value}&page=${page}`;
    } else {
      url = `api/v1/delivery-routes/search/findDeliveryRoutes?query=${deliveryRoutesQuery}&date=${datePicker}&size=20&page=${page}`;
    }
    this.fetchPaginatedDeliveryRoute(url);
  }

  handleChangeSegragationRowsPerPage = (event) => {
    const { page, filters, datePicker } = this.state;
    const { segregationPickListQuery } = filters;
    const { value } = event.target;
    let url = '';
    if (value) {
      this.setState({ rowsPerPage: value });
      url = `api/v1/delivery-routes/search/findDeliveryRoutes?query=${segregationPickListQuery}&date=${datePicker}&size=${value}&page=${page}`;
    } else {
      url = `api/v1/delivery-routes/search/findDeliveryRoutes?query=${segregationPickListQuery}&date=${datePicker}&size=20&page=${page}`;
    }
    this.fetchPaginatedDeliveryRoute(url);
  }

  isRouteSelected = id => this.state.routeSelected === id;

  renderDeliveryRouteTable=() => {
    const { rowsPerPage, page, rowsPerPageOptions } = this.state;
    return (
      <div>
        <div>
          <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
            <TableHeader columns={columnData2} />
            <TableBody>
              {this.state.deliveryRoutes.map((n, i) => (
                <TableRow
                  hover
                  onClick={event => this.handleDeliveryRouteClick(event, n)}
                  tabIndex={-1}
                  key={n.id}
                  role="checkbox"
                  aria-checked={this.isRouteSelected(n.id)}
                  selected={this.isRouteSelected(n.id)}
                >
                  <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.deliveryBoyName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.supervisorName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.hubName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.warehouseName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Typography color="secondary">
                      <span>
                        <Button variant="outlined" color="secondary" onClick={this.editDeliveryRoute(n)}>
                            EDIT
                        </Button>
                      </span>
                    </Typography>
                  </TableCell>
                </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={this.state.deliveryRoutes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            disabled: !this.state.links.prev,
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            disabled: !this.state.links.next,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeDeliveryRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    );
  }

  isSegregationSelected = id => this.state.segregationSelected === id;

  renderSegregationTable=() => {
    const { rowsPerPage, page, rowsPerPageOptions } = this.state;
    return (
      <div>
        <div>
          <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
            <TableHeader columns={columnData3} />
            <TableBody>
              {this.state.deliveryRoutes.map((n, i) => (
                <TableRow
                  hover
                  onClick={event => this.handleSegregationPickupListClick(event, n)}
                  tabIndex={-1}
                  key={n.id}
                  role="checkbox"
                  aria-checked={this.isSegregationSelected(n.id)}
                  selected={this.isSegregationSelected(n.id)}
                >
                  <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.segregationBoyName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.supervisorName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.hubName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.warehouseName}</TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Typography color="secondary">
                      <span>
                        <Button variant="outlined" color="secondary" onClick={this.editSegregationPickList(n)}>
                            EDIT
                        </Button>
                      </span>
                    </Typography>
                  </TableCell>
                </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={this.state.deliveryRoutes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            disabled: !this.state.links.prev,
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            disabled: !this.state.links.next,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeSegragationRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    );
  }

  verifySegregationItem = (segregationItem, index) => () => {
    const postData = { ...segregationItem };
    if (postData.id) {
      apiCall.sendRequest('post', `/api/v1/stock-ledger/removeOnVerify?delivery_route_pick_list_id=${postData.id}`)
        .then((response) => {
          apiCall.sendRequest('get', `api/v1/delivery-route-pick-list/${postData.id}`)
            .then((response) => {
              this.setState(prevState => ({
                segregationPickList: [
                  ...prevState.segregationPickList.slice(0, index),
                  response.data,
                  ...prevState.segregationPickList.slice(index + 1),
                ],
              }));
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  updateSegregationPickListItem = (n) => {
    if (n.id) {
      n.status = 'VERIFIED';
      n.qtyOut = n.qtyIn;
      apiCall.sendRequest('patch', `api/v1/delivery-route-pick-list/${n.id}`, n)
        .then((response) => {
          this.fetchSegregationPickList();
        });
    }
  }

  renderSegregationItemTable=() => {
    const { rowsPerPage, page } = this.state;
    const { StockUnavailabilityReasons } = this.props;
    const reasonsArray = Object.keys(StockUnavailabilityReasons);
    return (
      <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
        <TableHeader columns={columnData5} />
        <TableBody>
          {this.state.segregationPickList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => (
            <TableRow
              hover
              tabIndex={-1}
              key={n.id}
            >
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.productName}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.packaging}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.unitMeasure}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">
                <QuantityPicker
                  counter={n.qtyIn}
                  onIncrement={this.onSegregationItemQuantityIncrement}
                  onDecrement={this.onSegregationItemQuantityDecrement}
                  sku={n.sku}
                />
              </TableCell>
              {
                <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">
                  {
                    <MaterialSelect
                      value={n.reasonIn}
                      onChange={event => this.handlePickListStatusChange(event, n.sku)}
                      inputProps={{
                          name: 'reasonIn',
                          id: 'select-destination',
                        }}
                      style={{ minWidth: 100, marginLeft: 10 }}
                      fullWidth
                    >
                      {reasonsArray.map((reason, index) => (<MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>))}
                    </MaterialSelect>
                    }
                </TableCell>
                }
              <TableCell component="th" scope="row" padding="none">
                <Button size="small" color="secondary" variant="contained" style={{ marginLeft: 20 }} onClick={() => this.updateSegregationPickListItem(n)}>
                    Submit
                </Button>
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  isHubSelected = id => this.state.hubSelected === id;

  renderDeliveryPickListTable=() => {
    const { rowsPerPage, page } = this.state;
    return (
      <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
        <TableHeader columns={columnData4} />
        <TableBody>
          {this.state.uniquePickers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => (
            <TableRow
              hover
              onClick={event => this.handleDeliveryRoutePickupListClick(event, n)}
              tabIndex={-1}
              key={n.id}
              role="checkbox"
              aria-checked={this.isHubSelected(n.id)}
              selected={this.isHubSelected(n.id)}
            >
              <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
              <TableCell component="th" scope="row" padding="none">{n.warehouseName}</TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  onItemQuantityIncrement = (sku) => {
    const { deliveryRoutePickList } = this.state;
    deliveryRoutePickList.forEach((item) => {
      if (item.sku === sku && item.qtyIn < item.quantity) {
        item.qtyIn += 1;
      }
    });
    this.setState({
      deliveryRoutePickList,
    });
  }

  onItemQuantityDecrement = (sku) => {
    const { deliveryRoutePickList } = this.state;
    deliveryRoutePickList.forEach((item) => {
      if (item.sku === sku && item.qtyIn > 0) {
        item.qtyIn -= 1;
      }
    });
    this.setState({
      deliveryRoutePickList,
    });
  }

  onSegregationItemQuantityIncrement = (sku) => {
    const { segregationPickList } = this.state;
    segregationPickList.forEach((item) => {
      if (item.sku === sku && item.qtyIn < item.quantity) {
        item.qtyIn += 1;
      }
    });
    this.setState({
      segregationPickList,
    });
  }

  onSegregationItemQuantityDecrement = (sku) => {
    const { segregationPickList } = this.state;
    segregationPickList.forEach((item) => {
      if (item.sku === sku && item.qtyIn > 0) {
        item.qtyIn -= 1;
      }
    });
    this.setState({
      segregationPickList,
    });
  }

  handleReasonChange = (event, sku) => {
    const { value } = event.target;
    const { deliveryRoutePickList } = this.state;
    deliveryRoutePickList.forEach((item) => {
      if (item.sku === sku) {
        item.reasonIn = value;
      }
    });
    this.setState({
      deliveryRoutePickList,
    });
  }

  handlePickListStatusChange = (event, sku) => {
    const { value } = event.target;
    const { segregationPickList } = this.state;
    segregationPickList.forEach((item) => {
      if (item.sku === sku) {
        item.reasonIn = value;
      }
    });
    this.setState({
      segregationPickList,
    });
  }

  updateDeliveryItem = (n) => {
    const { datePicker, selectedHub } = this.state;
    apiCall.sendRequest('put', `/api/v1/delivery-routes/mark-picklist/hub/${selectedHub.hubId}?date=${moment(datePicker).format('YYYYMMDD')}`, n)
      .then((response) => {
        this.fetchDeliveryRoutePickList();
      });
  }

  renderDeliveryPickListItemTable=() => {
    const { rowsPerPage, page, deliveryRoutePickList = [] } = this.state;
    const { StockUnavailabilityReasons } = this.props;
    const reasonsArray = Object.keys(StockUnavailabilityReasons);
    return (
      <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
        <TableHeader columns={columnData6} />
        <TableBody>
          {deliveryRoutePickList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => (
            <TableRow
              hover
              tabIndex={-1}
              key={n.sku}
            >
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.productName}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.packaging}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">{n.unitMeasure}</TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">
                <QuantityPicker
                  counter={n.qtyIn}
                  onIncrement={this.onItemQuantityIncrement}
                  onDecrement={this.onItemQuantityDecrement}
                  sku={n.sku}
                />
              </TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">
                <MaterialSelect
                  value={n.reasonIn}
                  onChange={event => this.handleReasonChange(event, n.sku)}
                  inputProps={{
                      name: 'reasonIn',
                      id: 'select-destination',
                    }}
                  style={{ minWidth: 100, marginLeft: 10 }}
                  fullWidth
                >
                  {reasonsArray.map((reason, index) => (
                    <MenuItem key={index} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </MaterialSelect>
              </TableCell>
              <TableCell style={{ color: '#000000' }} component="th" scope="row" padding="none">
                <Button size="small" color="secondary" variant="contained" style={{ marginLeft: 20 }} onClick={() => this.updateDeliveryItem(n)}>
                    Submit
                </Button>
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  getDeliveryRoutes = () => {
    const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
    apiCall.sendRequest('get', `/api/v1/delivery-routes/search/findDeliveryRoutes?query=&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}&size=20&page=0`)
      .then((response) => {
        let links = { ...response.data._links };
        links = {
          ...links,
          next: {
            href: `/api/v1/delivery-routes/search/findDeliveryRoutes?query=&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}&size=20&page=1`,
          },
        };
        if (response.data && response.data._embedded && response.data._embedded['delivery-routes']) {
          this.setState({
            deliveryRoutes: response.data._embedded['delivery-routes'],
            links,
          });
        }
      });
  }

  fetchPaginatedDeliveryRoute = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        let links = { ...response.data._links };
        const page = +url.slice(url.length - 1);
        if (page === 0) {
          links = {
            ...links,
            next: {
              href: `${url.slice(0, url.length - 1)}${page + 1}`,
            },
          };
        } else if (response.data._embedded['delivery-routes'].length < 20) {
          links = {
            ...links,
            prev: {
              href: `${url.slice(0, url.length - 1)}${page - 1}`,
            },
          };
        } else {
          links = {
            ...links,
            next: {
              href: `${url.slice(0, url.length - 1)}${page + 1}`,
            },
            prev: {
              href: `${url.slice(0, url.length - 1)}${page - 1}`,
            },
          };
        }
        if (response.data && response.data._embedded && response.data._embedded['delivery-routes']) {
          this.setState({
            deliveryRoutes: response.data._embedded['delivery-routes'],
            links,
          });
        }
      });
  }

  getDeliveryPickUpList = () => {
    const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
    apiCall.sendRequest('get', `/api/v1/delivery-routes/search/findDeliveryRoutes?query=&size=500&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}`)
      .then((response) => {
        const deliveryRoutes = response.data._embedded['delivery-routes'];
        const uniqueHubs = [];
        const uniquePickers = deliveryRoutes.filter(({ hubId }) => {
          if (!hubId || uniqueHubs.includes(hubId)) {
            return false;
          }
          uniqueHubs.push(hubId);
          return true;
        });
        this.setState({
          uniquePickers,
        }, () => {
          // console.log(this.state.uniquePickers);
        });
      });
  }

  openEditDeliveryRoute = () => {
    this.setState({
      openEditDeliveryRoute: true,
    });
  }

handleEditDeliveryRouteClose = () => {
  this.setState({
    openEditDeliveryRoute: false,
    postData: {},
  });
}

  openEditSegregationPickList = () => {
    this.setState({
      openEditSegregationPickList: true,
    });
  }

  handleEditSegregationPickListClose = () => {
    this.setState({
      openEditSegregationPickList: false,
      postData: {},
    });
  }

handleNewChange = (event) => {
  const { name, value } = event.target;
  this.setState(prevState => ({
    postData: {
      ...prevState.postData,
      [name]: value,
    },
  }));
}

  openUserSelector = (userType, index) => () => {
    this.setState({
      openUserSelector: true,
      userType,
    });
  }

  handleClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  }

selectUser = (user) => {
  switch (this.state.userType) {
    case 'User':
      break;
    case 'DeliveryBoy':
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          deliveredBy: user.id,
          deliveryBoyName: user.name,
          deliveryBoyMobile: user.mobile,
        },
      }));
      break;
    case 'SegregationBoy':
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          segregatedBy: user.id,
          segregationBoyName: user.name,
        },
      }));
      break;
    case 'Supervisor':
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          supervisor: user.id,
          supervisorName: user.name,
          supervisorMobile: user.mobile,
        },
      }));
      break;
    case 'Hub':
      this.setState(prevState => ({
        postData: {
          ...prevState.postData,
          hubId: user.id,
          hubName: user.name,
        },
      }));
      break;
    default:
      break;
  }
  this.handleClose();
}

  updateDeliveryRoute = () => {
    const postData = { ...this.state.postData };
    if (postData.id) {
      apiCall.sendRequest('patch', `api/v1/delivery-routes/${postData.id}`, postData)
        .then((response) => {
          this.handleEditDeliveryRouteClose();
          this.getDeliveryRoutes();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  updateSegregationPickList = () => {
    const postData = { ...this.state.postData };
    if (postData.id) {
      apiCall.sendRequest('patch', `api/v1/delivery-routes/${postData.id}`, postData)
        .then((response) => {
          this.handleEditSegregationPickListClose();
          this.getDeliveryRoutes();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  searchDeliveryRoutes = (event) => {
    const { getUrlRouteName } = this.state;
    if (event.key === 'Enter') {
      const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
      const query = `query=${this.state.filters.deliveryRoutesQuery}&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}`;
      apiCall.sendRequest('get', `api/v1/delivery-routes/search/findDeliveryRoutes?${query}`)
        .then((response) => {
          this.setState({
            deliveryRoutes: response.data._embedded['delivery-routes'],
            getUrlRouteName,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  searchSegregationPickList = (event) => {
    if (event.key === 'Enter') {
      const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
      const query = `query=${this.state.filters.segregationPickListQuery}&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}`;
      apiCall.sendRequest('get', `api/v1/delivery-routes/search/findDeliveryRoutes?${query}`)
        .then((response) => {
          this.setState({
            deliveryRoutes: response.data._embedded['delivery-routes'],
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  searchDeliveryPickList = (event) => {
    if (event.key === 'Enter') {
      const additionalQuery = utils.deliveryRouteRoleQueryBuilder(this.props.User);
      const query = `query=${this.state.filters.deliveryPickListQuery}&date=${moment(this.state.datePicker).format('YYYY-MM-DD')}${additionalQuery ? (`&${additionalQuery}`) : ''}`;
      apiCall.sendRequest('get', `api/v1/delivery-routes/search/findDeliveryRoutes?${query}&size=500`)
        .then((response) => {
          const deliveryRoutes = response.data._embedded['delivery-routes'];
          const uniqueHubs = [];
          const uniquePickers = deliveryRoutes.filter(({ hubId }) => {
            if (!hubId || uniqueHubs.includes(hubId)) {
              return false;
            }
            uniqueHubs.push(hubId);
            return true;
          });
          this.setState({
            uniquePickers,
          }, () => {
            console.log(this.state.uniquePickers);
          });
        });
    }
  }

  handleQueryChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      getUrlRouteName: value,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => {
      console.log(this.state.filters);
    });
  }

  handleTabChange = (event, value) => {
    this.setState({
      value,
    }, () => {
      switch (this.state.value) {
        case 0: this.getDeliveryRoutes(); break;
        case 1: this.getDeliveryRoutes(); break;
        // case 2: this.getDeliveryPickUpList(); break;
        case 2: this.handleHubSearch(); break;
        default: break;
      }
    });
    this.handleClear();
  }

  handleStatusFilter = (event, value) => {
    const { selectedDeliveryRoute } = this.state;
    this.setState({
      status: event.target.value,
    }, () => {
      this.handleDeliveryRouteClick(event, selectedDeliveryRoute);
    });
  }

  fetchAllWarehouse = () => {
    apiCall.sendRequest('get', getWareHouse)
      .then((response) => {
        const { data: { _embedded } } = response;
  
        this.setState({
          warehouseList: _embedded.warehouse,
        });
      });
  }

  fetchHubs = async (warehouseId) => {
    const response = await apiCall.sendRequest('get', getWarehouseHubs(warehouseId));
    const { data: { data } } = response;
    this.setState({ hubs: data });
  }

  handleFilterChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    if (name === 'hubId') {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }));
      this.fetchHubs(value);
    }
  }

  handleSearch = async () => {
    const { filters: { warehouseId, hubId, deliveryRoutesQuery, segregationPickListQuery, deliveryPickListQuery }, datePicker, value } = this.state;
    let query = '';
    if (value == 0) query = deliveryRoutesQuery;
    if (value == 1) query = segregationPickListQuery;
    let url = `/api/v1/delivery-routes/search/findDeliveryRoutes?query=${query}&size=500&date=${datePicker}&warehouseId=${warehouseId || ''}&hubId=${hubId || ''}`
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      this.setState({
        deliveryRoutes: embedded['delivery-routes'],
        // getUrlRouteName,
      });
    } catch (error) {
      console.log(error.response);
    }
  }

  handleHubSearch = async () => {
    const { filters: { warehouseId, deliveryPickListQuery } } = this.state;
    if (!warehouseId) { 
      this.setState({ 
        warehouseText: 'Please select warehouse first to see the data...',
        uniquePickers: [],
        selectedHub: null,
      });
      return;
    } else {
      this.setState({ warehouseText: '' });
    }
    let url = `/api/v1/warehouses/${warehouseId}/hubs?query=${deliveryPickListQuery || ''}&size=500`;
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data = [] } = response.data;
      this.setState({
        uniquePickers: data,
      });
    } catch (error) {
      console.log(error.response);
    }
  }

  handleClear = () => {
    this.setState({
      filters: {
        deliveryRoutesQuery: '',
        deliveryRoutesOrderQuery: '',
        segregationPickListQuery: '',
        segregationItemsQuery: '',
        deliveryPickListQuery: '',
        deliveryPickListItemsQuery: '',
        warehouseId: null,
        hubId: null,
      },
    }, () => this.handleSearch());
  }

  render() {
    const { 
      value, 
      status, 
      warehouseList, 
      filters: { 
        warehouseId, 
        hubId, 
        deliveryRoutesQuery, 
        deliveryPickListQuery, 
        segregationPickListQuery 
      }, 
      hubs,
      warehouseText,
      selectedHub,
    } = this.state;

    return (
      <div>
        <NavBar />
        <div style={{ height: '7em', backgroundColor: '#EEEEEE', lineHeight: '6em' }}>
          <span style={{
                height: '2em',
                verticalAlign: 'top',
                paddingLeft: '2em',
                display: 'inline-block',
                lineHeight: 'normal',
                paddingTop: '1em',
              }}
          >
            Delivery Routes
          </span>
          <span style={{
                float: 'right',
                display: 'flex',
                verticalAlign: 'top',
                paddingRight: '2em',
                lineHeight: 'normal',
                paddingTop: '1.4em',
              }}
          >
            {/* { (value === 0 && this.state.selectedDeliveryRoute) &&
              <div style={{
                  flex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 10,
                  minWidth: 100,
                }}
              >
                <span style={{
                  fontSize: '0.75rem',
                  color: 'rgba(0, 0, 0, 0.54)',
                  marginBottom: 2,
                  }}
                >
                Order Status
                </span>
                <MaterialSelect
                  name="status"
                  onChange={e => this.handleStatusFilter(e, value)}
                  value={status}
                >
                  <MenuItem value="">All</MenuItem>
                  {Object.keys(this.props.DeliveryStatuses).map((postatus, index) => (
                    <MenuItem value={postatus}>{this.props.DeliveryStatuses[postatus]}</MenuItem>
                    ))}
                </MaterialSelect>
              </div>
              } */}
            <TextField
              fullWidth
              style={{ flex: 3 }}
              id="datetime-local"
              label="Current Date"
              type="date"
              value={this.state.datePicker}
              onChange={(event) => {
                this.setState({
                  datePicker: moment(event.target.value).format('YYYY-MM-DD'),
                  selectedDeliveryRoute: null,
                  selectedDeliveryRoutePickList: null,
                  selectedHub: null,
                }, () => {
                  this.handleTabChange(event, value);
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </span>
          <span style={{ position: 'absolute', marginLeft: '-8.8em' }}>
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              fullWidth
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab label="Delivery Routes" />
              <Tab label="Segregation Pick-List" disabled={!canView(['ADMIN', 'DELIVERY_MANAGER', 'SEGREGATION_BOY', 'SUPERVISOR'])} />
              <Tab label="Delivery Hubs" disabled={!canView(['ADMIN', 'DELIVERY_MANAGER', 'PICKER', 'SUPERVISOR'])} />
            </Tabs>
          </span>
        </div>
        <div style={{ margin: '1em' }}>
          {value === 0 &&
            <div>
              <Grid style={{ width: '90%' }}>
                <Row>
                  <ColMod lg={this.state.selectedDeliveryRoute ? 6 : 10}>
                    <Typography style={{ marginBottom: '1em' }} color="secondary" variant="title" >Delivery Routes</Typography>
                    <div style={{ marginBottom: '10px' }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="Search Route"
                        fullWidth
                        name="deliveryRoutesQuery"
                        value={deliveryRoutesQuery}
                        onChange={this.handleQueryChange}
                        // onKeyPress={this.searchDeliveryRoutes}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Warehouse</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '400px' }}
                            value={warehouseId || ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'warehouseId',
                              id: 'select-destination',
                            }}
                            >
                            {warehouseList.map((warehouse, index) => (<MenuItem key={index} value={warehouse.id}> {warehouse.name} </MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Hub</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '210px' }}
                            value={hubId || ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'hubId',
                              id: 'select-destination',
                            }}
                            disabled={!warehouseId}
                            >
                            {hubs.map(hub => (<MenuItem key={hub.id} value={hub.id}>{hub.name}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <Button variant="outlined" size="small" color="primary" onClick={this.handleSearch}>Search</Button>
                      </div>
                      <div style={{ marginBottom: '10px', marginLeft: '30px' }}>
                        <Button variant="outlined" size="small" color="secondary" onClick={this.handleClear}>Clear All</Button>
                      </div>
                    </div>
                    {this.renderDeliveryRouteTable()}
                  </ColMod>
                  <ColMod lg={6}>
                    {this.state.selectedDeliveryRoute ?
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ marginBottom: '1em' }} color="secondary" variant="title" >{this.state.selectedDeliveryRoute.name}
                          
                          <span style={{ float: 'right', color: '#696969', marginLeft: '20px' }}>
                            <b style={{ fontSize: '1.6em', color: 'orangered' }}>
                              {this.state.deliveryRouteOrders.filter(x => ['NEW', 'ON_ROUTE'].indexOf(x.status) !== -1).length }
                            </b> Pending &emsp;&emsp;
                            <b style={{ fontSize: '1.6em', color: '#25c238' }}>
                              {this.state.deliveryRouteOrders.filter(x => ['NEW', 'ON_ROUTE'].indexOf(x.status) === -1).length }
                            </b> Completed
                          </span>
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', minWidth: 150, marginBottom: '10px' }}>
                          <span style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.54)', marginBottom: 2 }}> Order Status </span>
                          <MaterialSelect name="status" onChange={e => this.handleStatusFilter(e, value)} value={status}>
                            <MenuItem value="all">All</MenuItem>
                              {Object.keys(this.props.DeliveryStatuses).map((postatus, index) => (<MenuItem key={index} value={postatus}>{this.props.DeliveryStatuses[postatus]}</MenuItem>))}
                          </MaterialSelect>
                        </div>
                      </div>
                     : null}
                    {this.state.selectedDeliveryRoute ? this.renderDeliveryRouteDragList() : null}
                  </ColMod>
                </Row>
              </Grid>
            </div>}
          {value === 1 &&
            <div>
              <Grid style={{ width: '90%' }}>
                <Row>
                  <ColMod lg={this.state.selectedDeliveryRoutePickList ? 6 : 10}>
                    <Typography style={{ marginBottom: '1em' }} color="secondary" variant="title" >Segregation Pick-List</Typography>
                    <div style={{ marginBottom: '10px' }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label="Search Segregation Pick List"
                        fullWidth
                        name="segregationPickListQuery"
                        value={segregationPickListQuery}
                        onChange={this.handleQueryChange}
                        // onKeyPress={this.searchSegregationPickList}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Warehouse</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '400px' }}
                            value={warehouseId || ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'warehouseId',
                              id: 'select-destination',
                            }}
                            >
                            {warehouseList.map((warehouse, index) => (<MenuItem key={index} value={warehouse.id}> {warehouse.name} </MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Hub</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '210px' }}
                            value={hubId || ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'hubId',
                              id: 'select-destination',
                            }}
                            disabled={!warehouseId}
                            >
                            {hubs.map(hub => (<MenuItem key={hub.id} value={hub.id}>{hub.name}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <Button variant="outlined" size="small" color="primary" onClick={this.handleSearch}>Search</Button>
                      </div>
                      <div style={{ marginBottom: '10px', marginLeft: '30px' }}>
                        <Button variant="outlined" size="small" color="secondary" onClick={this.handleClear}>Clear All</Button>
                      </div>
                    </div>
                    {this.renderSegregationTable()}
                  </ColMod>
                  <ColMod lg={6}>
                    {this.state.selectedDeliveryRoutePickList ?
                      <Typography color="secondary" variant="title" >{`${this.state.selectedDeliveryRoutePickList.name} Items`}</Typography>
                     : null}
                    {this.state.selectedDeliveryRoutePickList ? this.renderSegregationItemTable() : null}
                  </ColMod>
                </Row>
              </Grid>
            </div>
            }
          {value === 2 &&
            <div>
              <Grid style={{ width: '90%' }}>
                <Row>
                  <ColMod lg={this.state.selectedHub ? 6 : 10}>
                    <Typography style={{ marginBottom: '1em' }} color="secondary" variant="title" >Delivery Hubs</Typography>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <FormControl variant="filled">
                          <InputLabel style={{ fontSize: '1em' }} htmlFor="select-destination">Warehouse</InputLabel>
                          <Select
                            style={{ marginBottom: '2px', width: '350px' }}
                            value={warehouseId || ''}
                            onChange={this.handleFilterChange}
                            inputProps={{
                              name: 'warehouseId',
                              id: 'select-destination',
                            }}
                            >
                            {warehouseList.map((warehouse, index) => (<MenuItem key={index} value={warehouse.id}> {warehouse.name} </MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                      <TextField
                        id="input-with-icon-textfield"
                        label="Search Hubs"
                        style={{ width: '250px' }}
                        name="deliveryPickListQuery"
                        value={deliveryPickListQuery}
                        onChange={this.handleQueryChange}
                        disabled={!warehouseId}
                        placeholder={warehouseId ? '' : 'select warehouse first'}
                        // onKeyPress={this.searchDeliveryPickList}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <Button variant="outlined" size="small" color="primary" onClick={this.handleHubSearch}>Search</Button>
                      </div>
                      <div style={{ marginBottom: '10px', marginLeft: '30px' }}>
                        <Button variant="outlined" size="small" color="secondary" onClick={this.handleClear}>Clear All</Button>
                      </div>
                    </div>
                    {this.renderDeliveryPickListTable()}
                    { warehouseText && <div style={{ color: 'red', textAlign: 'center', marginTop: '50px', fontSize: 'large' }}>{warehouseText}</div> }
                  </ColMod>
                  <ColMod lg={6}>
                    {this.state.selectedHub ?
                      <Typography color="secondary" variant="title" >{`${selectedHub.name.charAt(0).toUpperCase() + selectedHub.name.slice(1)} Items`}</Typography>
                  : null}
                    {this.state.selectedHub ? this.renderDeliveryPickListItemTable() : null}
                  </ColMod>
                </Row>
              </Grid>
            </div>
          }
        </div>
        <UserSelection userType={this.state.userType} openSearchBox={this.state.openUserSelector} handleClose={this.handleClose} selectUser={this.state.openAddNewRoute ? this.selectNewUser : this.selectUser} />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openEditDeliveryRoute}
          onClose={this.handleEditDeliveryRouteClose}
          onBackdropClick={this.handleEditDeliveryRouteClose}
        >
          <div style={{
                background: 'white',
                width: '70%',
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleEditDeliveryRouteClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <div style={{ background: 'white' }}>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <Row>
                      <Typography variant="body2" color="secondary">
                        Edit Delivery Route
                      </Typography>
                    </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                          margin="dense"
                          id="input-with-icon-textfield"
                          value={this.state.postData.deliveryBoyName ? this.state.postData.deliveryBoyName : ''}
                          name="deliveredBy"
                          fullWidth
                          onChange={(event) => { this.handleNewChange(event); }}
                          helperText="Delivery Boy"
                          onClick={this.openUserSelector('DeliveryBoy')}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start" >
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                          }}
                        />
                      </ColMod>
                      <ColMod lg={6}>
                        <TextField
                          margin="dense"
                          id="input-with-icon-textfield"
                          value={this.state.postData.supervisorName ? this.state.postData.supervisorName : ''}
                          name="superviser"
                          fullWidth
                          onChange={(event) => { this.handleNewChange(event); }}
                          onClick={this.openUserSelector('Supervisor')}
                          helperText="Supervisor"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start" >
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                          }}
                        />
                      </ColMod>
                    </Row>
                  </ColMod>
                </Row>
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div style={{ float: 'right', paddingRight: '2em' }}>
                    <Button style={{ marginRight: '2em' }} color="secondary" onClick={this.handleEditDeliveryRouteClose}>
                  Cancel
                    </Button>
                    <Button
                      onClick={() => this.updateDeliveryRoute()}
                      variant="contained"
                      color="secondary"
                    >Update
                    </Button>
                  </div>
                </Row>
              </Grid>
            </div>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openEditSegregationPickList}
          onClose={this.handleEditSegregationPickListClose}
          onBackdropClick={this.handleEditSegregationPickListClose}
        >
          <div style={{
              background: 'white',
              width: '70%',
              height: '50%',
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleEditSegregationPickListClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                        Edit Segregation Pick List
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        id="input-with-icon-textfield"
                        value={this.state.postData.segregationBoyName ? this.state.postData.segregationBoyName : ''}
                        name="segregatedBy"
                        fullWidth
                        onChange={(event) => { this.handleNewChange(event); }}
                        onClick={this.openUserSelector('SegregationBoy')}
                        helperText="Segregation Boy"
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                            }}
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        id="input-with-icon-textfield"
                        value={this.state.postData.supervisorName ? this.state.postData.supervisorName : ''}
                        name="supervisor"
                        fullWidth
                        onChange={(event) => { this.handleNewChange(event); }}
                        onClick={this.openUserSelector('Supervisor')}
                        helperText="Supervisor"
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start" >
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                            }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={12}>
                      <TextField
                        margin="dense"
                        id="input-with-icon-textfield"
                        value={this.state.postData.hubName ? this.state.postData.hubName : ''}
                        name="hubName"
                        fullWidth
                        onChange={(event) => { this.handleNewChange(event); }}
                        onClick={this.openUserSelector('Hub')}
                        helperText="Hub"
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                            }}
                      />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button style={{ marginRight: '2em' }} color="secondary" onClick={this.handleEditSegregationPickListClose}>
							Cancel
              </Button>
              <Button
                onClick={() => {
								// this.createStockAdjustment();
								this.updateSegregationPickList();
							}}
                variant="contained"
                color="secondary"
              >
							Update
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

class PlanetItem extends React.Component {
  state = {
    value: 0,
  };

  getDragHeight() {
    return null;
  }

  isOrder = (item) => {
    if (item.status) {
      return true;
    }
    return false;
  }

  handleStatusChange = async (event, value) => {
    const val = event.target.value;
    apiCall.sendRequest('patch', `api/v1/delivery-route-order/${value.id}`, { status: val })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  render() {
    const { item, itemSelected, dragHandle } = this.props;
    const { planet, deliveryStatus, index } = item;
    const { value } = this.state;
    const scale = itemSelected * 0.05 + 1;
    const shadow = itemSelected * 15 + 1;
    const dragged = itemSelected !== 0;
    console.log(deliveryStatus);
    return (
      <div
        className={cx('item', { dragged })}
        style={{
          transform: `scale(${scale})`,
          boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
        }}
      >
        {dragHandle(<div className="dragHandle" />)}
        <div>
          <Typography variant="body2">
            &emsp;&emsp;&emsp;
            Customer Address #{ String(planet.addressId).padStart(4, '0')}
            <span style={{ marginLeft: window.innerWidth * 0.08 }}>
              {this.isOrder(planet) &&
                (`Order #${String(planet.customerOrder).padStart(6, '0')}`)}
            </span>
          </Typography>
        </div>
        <div>
          <Typography style={{ fontWeight: '400', fontSize: '1.44em' }} variant="body2">
            <span style={{ color: '#f50057' }}>
              { (index + 1) }
            </span> &emsp; {planet.customerName }
            {this.isOrder(planet) &&
            <span style={{ float: 'right' }}>
              <MaterialSelect
                value={planet.status}
                onChange={(event) => { this.handleStatusChange(event, planet); }}
                input={<Input name="age" id="age-helper" />}
              >
                {Object.keys(deliveryStatus).map(x => (
                  <MenuItem value={x}>
                    {deliveryStatus[x]}
                  </MenuItem>
              ))}
              </MaterialSelect>
            </span>
          }
          </Typography>
        </div>

        <div>
          <Typography variant="body2">  &emsp;&emsp;&emsp; {planet.address}
            { this.isOrder(planet) &&
            <span style={{ float: 'right', marginTop: '0.3em', color: planet.order_status == statuses[0] ? '#25c238' : 'orangered' }}>
              {planet.status}
            </span>
          }
          </Typography>
        </div>
      </div>
    );
  }
}

class StyledCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shadow: 0,
      routeAssigned: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.route_assigned !== this.state.routeAssigned) {
        this.setState({
          routeAssigned: nextProps.route_assigned,
        });
      }
    }
  }

  handleDeliveryChange = (event) => {
    const routeAssigned = event.target.value;
    this.setState({
      routeAssigned,
    }, () => {
      apiCall.sendRequest('put', `/api/v1/static-routes/${this.state.routeAssigned}/${this.props.customer.id}`)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
        });
    });
  }

  onMouseOver = () => this.setState({ shadow: 1 })

  onMouseOut = () => this.setState({ shadow: 0 })

  render() {
    return (
      <div
        onMouseEnter={this.onMouseOver}
        onMouseLeave={this.onMouseOut}
        className={this.state.shadow ? 'item2-hover' : 'item2'}
      >
        {this.props.children}
        {(this.state.shadow != 0 || this.props.route_assigned) &&

        <div style={{ float: 'right' }}>
          <InputLabel style={{ paddingRight: '1em' }}>Route</InputLabel>
          <MaterialSelect
            value={this.state.routeAssigned}
            onChange={this.handleDeliveryChange}
            input={<Input name="age" id="age-helper" />}
          >
            {this.props.routeProps.map(x => (<MenuItem value={x.id}>{x.name}</MenuItem>))}
          </MaterialSelect>
        </div>
           }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  DeliveryStatuses: state.Status.deliveryStatuses,
  StockUnavailabilityReasons: state.Status.stockUnavailabilityReasons,
  User: state.User,
});

export default connect(mapStateToProps, null)(App);
