import { store } from '../../index';

export default {
  deliveryRouteRoleQueryBuilder: (user) => {
    const { roles } = user;
    let queryArr = roles.map((role) => {
      let query = '';
      switch (role.name) {
        case 'PICKER':
          query = `picker=${user.id}`;
          break;
        case 'SUPERVISOR':
          query = `supervisor=${user.id}`;
          break;
        case 'SEGREGATION_BOY':
          query = `segregatedBy=${user.id}`;
          break;
        default:
          break;
      }
      return query;
    });
    queryArr = queryArr.filter(query => query);
    return queryArr.join('&');
  },
  isCurrentUserAuthorizedToView: (role) => {
    const state = store.getState();
    const roles = state.User && state.User.roles ? state.User.roles : [];
    const isRolePresent = roles.find(eachRole => role.indexOf(eachRole.name) !== -1);
    return !!isRolePresent;
  },
};
