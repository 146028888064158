import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarIcon from '@material-ui/icons/AccountCircle';
import CirlceIcon from '@material-ui/icons/Brightness1';
import {
  List, ListItem, ListItemText, ListItemSecondaryAction, Paper,
} from '@material-ui/core';
import Colors from '../../../utils/Colors';

const styles = {
  container: {
    position: 'fixed',
    right: 10,
    bottom: 0,
    // border: '1px solid #000',
    background: Colors.white,
    width: 250,
  },
  header: {
    padding: 10,
  },
  listStyle: {
    overflow: 'scroll',
    maxHeight: 300,
  },
};

class AgentWithStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: false,
    };
  }

  toggleList = () => {
    const { list } = this.state;
    this.setState({
      list: !list,
    });
  }

  render() {
    const { list } = this.state;
    const { data } = this.props;
    let num = 0;
    data.forEach((item) => {
      if (item.status === 'ONLINE') num += 1;
    });
    return (
      <Paper style={styles.container} square>
        <Paper elevation={1} style={styles.header} square onClick={this.toggleList}>
          <CirlceIcon style={{ fontSize: 12, color: 'green' }} />
          {' '}
Online (
          {num}
)
        </Paper>
        {list
        && (
        <List dense style={styles.listStyle}>
          {data.map(agent => (
            <ListItem key={agent.id} button>
              <AvatarIcon />
              <ListItemText primary={agent.name} />
              <ListItemSecondaryAction>
                <CirlceIcon style={{ fontSize: 10, color: agent.status === 'ONLINE' ? 'green' : 'grey' }} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        )}
      </Paper>
    );
  }
}

AgentWithStatus.propTypes = {
  data: PropTypes.instanceOf(Array)
};

export default AgentWithStatus;
