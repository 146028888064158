import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
};

class DRSearch extends Component {
  onKeyPress = () => {};

  onChangeFilter = (event) => {
    const { onFilterChange, onButtonClick } = this.props;
    const { name, value } = event.target;
    onFilterChange(name, value);
    onButtonClick('search');
  };

  render() {
    const { filters, onButtonClick, warehouses = [] } = this.props;
    return (
      <div style={styles.container} >
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Search by Delivery Boy Phone Number"
                  type="number"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={filters.query ? filters.query : ''}
                  onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="startDate"
                  fullWidth
                  type="date"
                  label="Start Date"
                  name="startDate"
                  onChange={this.onChangeFilter}
                  value={filters.startDate ? filters.startDate : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="endDate"
                  fullWidth
                  type="date"
                  label="End Date"
                  name="endDate"
                  onChange={this.onChangeFilter}
                  value={filters.endDate ? filters.endDate : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Warehouse</FormHelperText>
                <Select
                  name="warehouseId"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={filters.warehouseId ? parseInt(filters.warehouseId, 10) : ''}
                >
                  {warehouses.map(warehouse => (
                    <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                  ))}
                </Select>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search')}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button color="secondary" variant="outlined" onClick={() => onButtonClick('clear')}>
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

DRSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  warehouses: PropTypes.instanceOf(Array),
  onButtonClick: PropTypes.func,
};

export default DRSearch;
