import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@atlaskit/select';
import SectionMessage from '@atlaskit/section-message';
import ProductImageManager from '../../../components/Core/ProductImageManager/ProductImageManager';
import apiCall, { processFaultyProductRefund, getFullProductItemDataByProductItemId } from '../../../api/NetworkHandler';
import ProductItemSelector from '../../../components/ProductItem/Selector';
import ProgressBar from '../../../components/States/ProgressBar';
import utils from '../../../utils/queryBuilder';
import { capitalize, isEmpty, orderBy, debounce } from "lodash";

const canView = utils.isCurrentUserAuthorizedToView;

const innerWidth = window.innerWidth;
const containerWidth = innerWidth < 600 ? innerWidth * 0.7 : innerWidth * 0.4;

const styles = {
  container: {
    width: containerWidth,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: 'red',
  }
}

// const refundTypes = [
//   { label: "Full", value: "FULL_REFUND" },
//   { label: "Partial", value: "PARTIAL_REFUND" }
// ]

const fullRefundReasons = [
  { label: "Damaged", value: "DAMAGED_PRODUCT" },
  { label: "Wrong Product Sent", value: "WRONG_PRODUCT" },
  { label: "Wrong Product Size", value: "WRONG_PRODUCT_SIZE" },
  { label: "Price Mismatch", value: "PRICE_MISMATCH" },
  { label: "Not Delivered", value: "NOT_DELIVERED" },
  { label: "Other", value: "OTHERS" }
]

const partialRefundReasons = [
  { label: "Partially Damaged", value: "PARTIAL_DAMAGED_PRODUCT" },
  { label: "Partially Delivered", value: "PARTIALLY_DELIVERED" },
  { label: "Wrong Quantity", value: "WRONG_QUANTITY" },
  { label: "Other", value: "OTHERS" }
]

const percentageOptions = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 }
]

const fullRefundPercentage = [
  { label: "100", value: 100 }
]

var keyCounter = {
  'selectedType': 0,
}

var versionDict = {
  "fullItemRequestId": 0
}

class RefundForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refundData: {
        customerId: '',
        orderId: '',
        orderLineItemId: '',
        refundType: '',
        complaintReason: '',
        amountPercentage: null,
        remark: '',
        defectItemImageUrl: '',
        refundQuantity: '',
        productMRP: '',
        amount: null,
        selectedProductName: null,
        selectedProductId: null,
      },
      selectedType: null,
      selectedReason: null,
      selectedAmountPercentage: null,
      error: {},
      errorMessage: '',
      reRender: false,
      amount: '',
      fullDefaultPercentage: { label: "100", value: 100 },
      partialDefaultPercentage: { label: "0", value: 0 },
      refundError: '',
      selectedProduct: null,
      loading: false,
      refundTypes: [],
      selectedItemFullData: {},
      disableButton: false,
    }
  }

  componentDidMount() {
    const { orderItem: { orderId, id, customerId } } = this.props;

    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        refundData: {
          ...refundData,
          customerId,
          orderId: orderId,
          orderLineItemId: id,
        },
      }
    });
  }

  componentWillUnmount() {
    keyCounter.selectedType = 0;
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    });
  }

  isPropertyValid = (property, value) => {
    const { selectedItemFullData } = this.state;
    const { orderItem: { discount } = {} } = this.props;
    const minAllowedPrice = discount === 100 ? 0 : selectedItemFullData.costPrice * (1 + (selectedItemFullData.tax / 100));
    if (property == "remark") {
      value = value.trim();
    }
    else if (property === 'productMRP' && parseFloat(value) < minAllowedPrice.toFixed(2)) {
      this.setError(property, true);
      return false;
    }
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;
    const { refundData } = this.state;
    const { complaintReason } = refundData;
    let validEntityTypes = ["refundType", "complaintReason", "amountPercentage", "remark", "refundQuantity", "productMRP", "selectedProductName"];
    if (["PRICING_ISSUE", "WRONG_MRP"].includes(complaintReason)) {
      validEntityTypes = ["refundType", "complaintReason", "remark", "refundQuantity", "productMRP"];
    }
    else if (['WRONG_PRODUCT', 'WRONG_PRODUCT_SIZE'].includes(complaintReason)) {
      validEntityTypes = ["refundType", "complaintReason", "remark", "refundQuantity", "selectedProductName"];
    } 
    else {
      validEntityTypes = ["refundType", "complaintReason", "amountPercentage", "remark", "refundQuantity"];
    }
    for (const property in refundData) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, refundData[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  onSave = debounce( async () => {
    this.setState({ error: {} });
    if (!this.validateForm()) {
      return;
    }

    this.setState({
      loading: true,
      disableButton: true
    });

    try {
      const { refundData } = this.state;
      const { onClose } = this.props;
      refundData.remark = refundData.remark.trim();
      await apiCall.sendRequest('post', processFaultyProductRefund, refundData);
      this.setState({ loading: false });
      onClose('buttonClicked');
    } catch (error) {
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({
        errorMessage,
        loading: false,
        disableButton: false
      });
    }
  }, 500);

  onTypeChange = (selectedType) => {
    this.setState(prevState => {
      const { refundData, fullDefaultPercentage, partialDefaultPercentage, refundQuantity } = prevState;

      // const amountPercentage = (!isEmpty(selectedType) && selectedType.value == "PARTIAL_REFUND") ? partialDefaultPercentage.value : fullDefaultPercentage.value;

      return {
        refundData: {
          ...refundData,
          refundType: !isEmpty(selectedType) ? selectedType.value : '',
          amountPercentage: null,
          refundQuantity,
          // amount: this.getAmountFromPercentage(amountPercentage),
          amount: null,
        },
        selectedType,
        selectedAmountPercentage: null,
        reRender: true,
      }
    }, () => { this.setState({ reRender: false }) })
  }

  getRefundTypesByRefundReason = (reason) => {
    const { customerOrderComplaintReasons } = this.props;
    let types = []
    Object.keys(customerOrderComplaintReasons).forEach(el => {
      if (el === reason) return types = customerOrderComplaintReasons[el].map(el => {return {label: capitalize(el.split("_")[0]), value: el}})
    });
    return types;
  };

  fetchFullProductItemData = async (productItemId) => {
    try {
      versionDict.fullItemRequestId += 1;
      let prevRequestId = versionDict.fullItemRequestId;
      const response = await apiCall.sendRequest('get', getFullProductItemDataByProductItemId(productItemId));
      const { data: { data = {} } = {} } = response;
      if (prevRequestId == versionDict.fullItemRequestId) {
        this.setState({
          selectedItemFullData: data,
        });
      }
    } catch (err) {
      this.setState({
        selectedItemFullData: {},
      });
    }
  };

  onReasonChange = (selectedReason) => {
    keyCounter.selectedType += 1;
    const { refundData: { refundQuantity, amountPercentage, productMRP }, selectedProduct } = this.state;
    const { orderItem: { productItemId } = {} } = this.props;
    selectedReason.value === 'PRICING_ISSUE' && this.fetchFullProductItemData(productItemId);
    const refundTypes = this.getRefundTypesByRefundReason(selectedReason.value);
    this.onTypeChange(refundTypes.length === 1 ? refundTypes[0] : null);
    let priceOnRefundQuantity = this.getProductPrice(selectedReason, productMRP, selectedProduct, amountPercentage);
    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        selectedReason,
        refundTypes,
        refundData: {
          ...refundData,
          complaintReason: selectedReason.value,
          amount: this.getAmountFromPercentage(priceOnRefundQuantity, refundQuantity, selectedReason.value),
        },
      }
    });
  }

  onAmountPercentageChange = (selectedAmountPercentage) => {
    const { refundData: { refundQuantity } } = this.state;
    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        refundData: {
          ...refundData,
          amountPercentage: selectedAmountPercentage.value,
          amount: this.getAmountFromPercentage(selectedAmountPercentage.value, refundQuantity),
        },
        selectedAmountPercentage,
      }
    })
  }

  onRefundQuantityChange = (event) => {
    const { orderItem: { deliveredQuantity } } = this.props;
    const { refundData: { refundQuantity, amountPercentage, productMRP }, selectedReason, selectedProduct } = this.state;
    const { name, value } = event.target;
    if (name === 'refundQuantity' && (value > deliveredQuantity || value < 0)) {
      this.setState({ refundError: value > deliveredQuantity ? `max quantity ${deliveredQuantity}` : `min quantity 0` });
      return;
    } else {
      this.setState({ refundError: '' }); //clearing error message
    }
    let priceOnRefundQuantity = 0;
    if (selectedReason) {
      priceOnRefundQuantity = this.getProductPrice(selectedReason, productMRP, selectedProduct, amountPercentage);
    }
    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        refundData: {
          ...refundData,
          refundQuantity: value,
          amount: this.getAmountFromPercentage(priceOnRefundQuantity, value),
        },
      }
    });
  }

  onProductMRPChange = (event) => {
    const { refundData: { refundQuantity } } = this.state;
    const { name, value } = event.target;
    if (name === "productMRP" && value < 0) {
      return;
    }
    this.setState(prevState => {
      const { refundData } = prevState;
    
      return {
        refundData: {
          ...refundData,
          [name]: value,
          amount: this.getAmountFromPercentage(value, refundQuantity),
        },
      }
    });
  }

  onNewProductSelect = (selectedProduct) => {
    console.log(selectedProduct);
    const { refundData: { amountPercentage, refundQuantity }, selectedReason } = this.state;
    // const priceOnRefundQuantity = selectedReason ? selectedReason.value === "WRONG_PRODUCT" ? selectedProduct.price : amountPercentage : null;
    let priceOnRefundQuantity = 0;
    if (selectedProduct) {
      if (selectedReason) {
        if (['WRONG_PRODUCT', 'WRONG_PRODUCT_SIZE'].includes(selectedReason.value)) {
          priceOnRefundQuantity = selectedProduct.sellingPrice;
        } else {
          priceOnRefundQuantity = amountPercentage;
        }
      }
      this.setState(prevState => {
        const { refundData } = prevState;

        return {
          selectedProduct,
          refundData: {
            ...refundData,
            selectedProductName: selectedProduct.label,
            selectedProductId: selectedProduct.value,
            amount: this.getAmountFromPercentage(priceOnRefundQuantity, refundQuantity),
          },
          // amount: this.getAmountFromPercentage(priceOnRefundQuantity),
        }
      });
    } else {
      this.setState(prevState => {
        const { refundData } = prevState;

        return {
          selectedProduct: null,
          refundData: {
            ...refundData,
            selectedProductName: null,
            selectedProductId: null,
          },
        }
      });
    }
  }

  onInputChange = (event) => {
    let { name, value } = event.target;
    this.setState(prevState => {
      const { refundData } = prevState;
    
      return {
        refundData: {
          ...refundData,
          [name]: value,
        }
      }
    })
  }

  onImageUpload = (imageUrl) => {
    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        refundData: {
          ...refundData,
          defectItemImageUrl: imageUrl,
        }
      }
    })
  }

  onDeleteImage = () => {
    this.setState(prevState => {
      const { refundData } = prevState;

      return {
        refundData: {
          ...refundData,
          defectItemImageUrl: '',
        }
      }
    })
  }

  getProductPrice = (selectedReason, productMRP, selectedProduct, amountPercentage) => {
    let priceOnRefundQuantity = 0;
    if (["PRICING_ISSUE", "WRONG_MRP"].includes(selectedReason.value)) {
      priceOnRefundQuantity = productMRP;
    }
    else if (['WRONG_PRODUCT', 'WRONG_PRODUCT_SIZE'].includes(selectedReason.value)) {
      if (selectedProduct) {
        priceOnRefundQuantity = selectedProduct.sellingPrice;
      }
    }
    else {
      priceOnRefundQuantity = amountPercentage;
    }
    return priceOnRefundQuantity;
  }

  getAmountFromPercentage = (amountValue, quantity, reason) => {
    const { orderItem: { unitPrice } } = this.props;
    const { selectedReason } = this.state;
    if (!(selectedReason || reason))  return;
    let reasonValue = null;
    if (reason) {
      reasonValue = reason;
    } else {
      reasonValue = selectedReason.value;
    }
    switch (reasonValue) {
      case "WRONG_MRP": 
      case "PRICING_ISSUE": 
      case "WRONG_PRODUCT" : 
      case "WRONG_PRODUCT_SIZE" : 
        return ((unitPrice - amountValue) * quantity);
      default: return (unitPrice * quantity * amountValue) / 100; // amountValue indicates number of pieces in case of Packaged item and percentage in case of others
    }
  }

  getDisplayAmount = () => {
    const { refundData: { amount }, selectedType } = this.state;
    if (amount && selectedType) {
      return amount.toFixed(2);
    }
  }

  getAmountMessage = () => {
    const { refundData: { amount } } = this.state;
    if (!amount) return;
    let message = `Rs.${amount.toFixed(2) * -1} debit from customer`;
    if (amount) {
      if (amount >= 0) {
        message = `Rs.${amount.toFixed(2)} will be credited to customer`;
      }
      return message;
    }
    return '';
  }

  getPercentage = () => {
    const { orderItem } = this.props;
    let uomIdList = [5, 6, 8, 10, 15, 19, 20, 21, 22, 29, 30, 61, 62, 64, 65, 66];
    let percentageObjectArray = [];
    if (Object.keys(orderItem).length != 0) {
      if (uomIdList.includes(orderItem.uom.id)) {
        let calculateUnitPercentage = 100 / orderItem.unitMeasure
        Array.from({length: orderItem.unitMeasure -1}, (v, k) => {
          let label = k + 1;
          let value = (k + 1) * calculateUnitPercentage;
          percentageObjectArray.push({label, value});
        });
        return percentageObjectArray;
      } 
      return percentageOptions;
    }
  }

  getPercentageLabel = () => {
    const { orderItem } = this.props;
    let uomIdList = [5, 6, 8, 10, 15, 19, 20, 21, 22, 29, 30, 61, 62, 64, 65, 66];
    let label = 'Amount Percentage (%) *';
    if (Object.keys(orderItem).length != 0) {
      return uomIdList.includes(orderItem.uom.id) ? 'Piece Per Unit *' : label;
    }
    return label;
  }

  getFullRefundQunatity = () => {
    const { orderItem } = this.props;
    const { fullDefaultPercentage } = this.state;
    let uomIdList = [5, 6, 8, 10, 15, 19, 20, 21, 22, 29, 30, 61, 62, 64, 65, 66];
    if (Object.keys(orderItem).length != 0) {
      return uomIdList.includes(orderItem.uom.id) ? {label : orderItem.unitMeasure, value: orderItem.unitMeasure} : fullDefaultPercentage;
    }
  }

  getFullRefundValue = () => {
    const { orderItem } = this.props;
    let uomIdList = [5, 6, 8, 10, 15, 19, 20, 21, 22, 29, 30, 61, 62, 64, 65, 66];
    if (Object.keys(orderItem).length != 0) {
      return uomIdList.includes(orderItem.uom.id) ? [{label : orderItem.unitMeasure, value: 100}] : fullRefundPercentage;
    }
  }

  getPriceMismatchedReason = () => {
    const { selectedReason } = this.state;
    if (selectedReason) {
      if (["PRICING_ISSUE", "WRONG_MRP"].includes(selectedReason.value)) {
        return true;
      }
      return false;
    }
    return false;
  }

  getWrongProductReason = () => {
    const { selectedReason } = this.state;
    if (selectedReason) {
      if (['WRONG_PRODUCT', 'WRONG_PRODUCT_SIZE'].includes(selectedReason.value)) {
        return true;
      }
      return false;
    }
    return false;
  }

  getRefundTypeByPermission = (refundTypes) => {
    if (canView(["CC_MANAGER_RESTRICTED"])) {
      return refundTypes.filter(el => el.label !== 'Partial');
    } else {
      return refundTypes;
    }
  };

  
  getComplaintReasons = () => {
    const { customerOrderComplaintReasons } = this.props;
    let reasons = [];
    Object.keys(customerOrderComplaintReasons).map(el => {
      reasons = [...reasons, {label: el.split("_").map(el => capitalize(el)).join(" "), value: el}]
    });
    return orderBy(reasons, 'label', 'asc');
  };

  render() {
    const { classes, onClose, customerDefaultAddress, orderItem: { productName, discount } } = this.props;
    const {
      errorMessage,
      error,
      refundData: {
        remark,
        defectItemImageUrl,
        refundQuantity,
        productMRP,
      },
      selectedReason,
      selectedType,
      selectedAmountPercentage,
      reRender,
      fullDefaultPercentage,
      partialDefaultPercentage,
      selectedProduct,
      loading,
      refundTypes,
      selectedItemFullData,
      disableButton
    } = this.state;
    const conditionalRefundTypes = this.getRefundTypeByPermission(refundTypes);
    const newComplaintReasons = this.getComplaintReasons();
    const minAllowedPrice = discount === 100 ? 0 : selectedItemFullData.costPrice * (1 + (selectedItemFullData.tax / 100));
    const isPriceInvalid = (!isEmpty(selectedReason) && selectedReason.value === 'PRICING_ISSUE') ? (parseFloat(productMRP) < minAllowedPrice.toFixed(2)) : false;
    return (
      <Grid className={classes.container} fluid>
        <ProgressBar isLoading={loading}/>
        <Row md={12} between="md" bottom="md">
          <ColMod zerobottom="true">
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Faulty Product Refund</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod zerobottom="true">
            <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
            <Button variant="contained" color="secondary" onClick={this.onSave} disabled={disableButton}>Save</Button>
          </ColMod>
        </Row>
        <Row>
          <ColMod zerobottom="true">
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row xs={12}>
          <ColMod xs={12} zerobottom="true">
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod md={12} zerobottom="true">
            <Typography variant="caption"> Ordered Product Item </Typography>
            <TextField
              value={productName || ''}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption" color={error.complaintReason ? 'error' : 'default'}> Complaint Reason * </Typography>
            <Select
              options={newComplaintReasons}
              value={selectedReason}
              onChange={this.onReasonChange}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption" color={error.refundType ? 'error' : 'default'}> Refund Type * </Typography>
            <Select
              options={conditionalRefundTypes}
              value={selectedType}
              onChange={this.onTypeChange}
              key={keyCounter.selectedType}
            />
          </ColMod>
          {this.getWrongProductReason() && 
          <ColMod md={6}>
            <Typography variant="caption" color={error.selectedProductName ? 'error' : 'default'}> Delivered Product Item * </Typography>
            <ProductItemSelector
              selected={selectedProduct}
              onSelect={this.onNewProductSelect}
              regionId={customerDefaultAddress ? customerDefaultAddress.regionId : null}
            />
          </ColMod>}
          <ColMod md={6}>
            {!reRender &&
            <TextField
              label="Refund Quantity"
              name="refundQuantity"
              type="number"
              value={refundQuantity}
              fullWidth
              required
              onChange={this.onRefundQuantityChange}
              error={error.refundQuantity}
            />}
            <small style={{ color: 'red' }}>{this.state.refundError}</small>
          </ColMod>
          {this.getPriceMismatchedReason() &&
            <ColMod md={6}>
              <TextField
                label={(!isEmpty(selectedReason) && selectedReason.value === "WRONG_MRP") ? "Product MRP" : "Received Price"}
                name="productMRP"
                type="number"
                value={productMRP}
                fullWidth
                required
                onChange={this.onProductMRPChange}
                error={error.productMRP || isPriceInvalid}
                helperText={(!isEmpty(selectedReason) && selectedReason.value === 'PRICING_ISSUE') ? `Min value ${minAllowedPrice.toFixed(2)}` : ''}
              />
            </ColMod>}
          {(!this.getPriceMismatchedReason() && !this.getWrongProductReason()) &&
            <ColMod md={6}>
              <Typography variant="caption" color={error.amountPercentage ? 'error' : 'default'}> {this.getPercentageLabel()} </Typography>
              {!reRender && <Select
                options={selectedType ? (selectedType.value == "PARTIAL_REFUND") ? this.getPercentage() : this.getFullRefundValue() : []}
                value={selectedAmountPercentage}
                defaultValue={selectedType ? (selectedType.value == "PARTIAL_REFUND") ? partialDefaultPercentage : this.getFullRefundQunatity() : []}
                onChange={this.onAmountPercentageChange}
              />}
            </ColMod>}
          <ColMod md={6}>
            <TextField
              label="Amount"
              name="amount"
              value={this.getDisplayAmount() || ''}
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <small style={{ color: 'red' }}>{this.getAmountMessage()}</small>
          </ColMod>
          <ColMod md={12}>
            <TextField
              label="Remarks"
              name="remark"
              value={remark || ''}
              onChange={this.onInputChange}
              multiline
              rows={2}
              inputProps={{ maxLength: 500 }}
              fullWidth
              required
              error={error.remark}
            />
          </ColMod>
          <ColMod md={12}>
            <ProductImageManager
              images={[{
                imageUrl: defectItemImageUrl,
                position: "FRONT",
                name: defectItemImageUrl ? productName : 'Upload Image'
              }]}
              singleImage={true}
              onImageUpload={this.onImageUpload}
              onDelete={this.onDeleteImage}
            />
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(RefundForm);

