import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import apiCall, { getProductOffersList } from '../../../api/NetworkHandler';
import OfferSearch from '../Component/OfferSearch';
import OfferList from '../Component/OfferList';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import { ROWS_PER_PAGE } from '../../../constants';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';
// import UploadOffer from '../Component/UploadOffer';


const Constants = {
  title: 'Offers',
};

class ProductOffersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offersData: [],
      // uploadPopup: false,
      filters: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      totalElements: 0,
      currentPage: 0,
    };
  }

  componentDidMount= () => {
    this.fetchParams();
  }

  onSearch = (page = 0) => {
    const { filters } = this.state;
    this.setState({
      currentPage: page,
    });
    this.fetchProductOfferList(filters, page);
  };

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.fetchProductOfferList(getFilterParams);
  }

  fetchProductOfferList = async (filters = {}, page = 0) => {
    try {
      const url = getProductOffersList(filters, page);
      const res = await apiCall.sendRequest('get', url);
      const { data: { data: { content = [], totalElements = 0 } } } = res;
      this.setState({
        offersData: content,
        totalElements,
      });
    } catch (e) {
      // error handling
    }
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      default:
        break;
    }
  };


  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  // handleUploadOffer = (value) => {
  //   this.setState({
  //     uploadPopup: value,
  //   });
  // }

  // handleUpload = async (offer) => {
  //   const { name, file } = offer;
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     await apiCall.sendRequest('post', uploadOfferCSV, formData);
  //     this.handleUploadOffer(false);
  //   } catch (e) {
  //     console.log('error');
  //   }
  // }


  render() {
    const {
      offersData, filters, totalElements, currentPage, // uploadPopup
    } = this.state;
    const { productItemId = '' } = filters; 

    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <OfferSearch queryId={productItemId} />
        </SearchBar>
        {/* <UploadOffer
          open={uploadPopup}
          onClose={() => this.handleUploadOffer(false)}
          onUpload={this.handleUpload}
        /> */}
        <OfferList
          offersData={offersData}
          totalElements={totalElements}
          rowsPerPage={ROWS_PER_PAGE}
          page={currentPage}
          onPageChange={this.onSearch}
        />
      </div>
    );
  }
}

export default withRouter(ProductOffersContainer);
