import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TaskBlock from '../../components/TaskBlock';
import PerformanceBlock from '../../components/PerformanceBlock';
import AnalysisBlock from '../../components/AnalysisBlock';
import './styles.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="dashboard">
        <PerformanceBlock />
        <TaskBlock />
        <AnalysisBlock />
      </div>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
