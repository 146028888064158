import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { Button, Checkbox, TextField } from '@material-ui/core';
import moment from 'moment';

const weekDays = [
  {
    label: 'M',
    value: 'MONDAY',
  },
  {
    label: 'T',
    value: 'TUESDAY',
  },
  {
    label: 'W',
    value: 'WEDNESDAY',
  },
  {
    label: 'T',
    value: 'THURSDAY',
  },
  {
    label: 'F',
    value: 'FRIDAY',
  },
  {
    label: 'S',
    value: 'SATURDAY',
  },
  {
    label: 'S',
    value: 'SUNDAY',
  },
];

class CreateSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planType: 0,
      numberOfDays: 0,
      selectedDays: [],
    };
  }

handleChange = (event) => {
  const { value } = event.target;
  this.setState({
    planType: parseInt(value, 10),
  });
}

handleNoOfDays = (days) => {
  this.setState({
    numberOfDays: days,
  });
}

handleDaySelection = (day) => {
  let { selectedDays } = this.state;
  if (selectedDays.includes(day)) {
    selectedDays = selectedDays.filter(item => item !== day);
  } else {
    selectedDays.push(day);
  }
  this.setState({
    selectedDays,
  });
}

createSchedule = () => {
  const { planType, numberOfDays, selectedDays } = this.state;
  const { onSubmit } = this.props;
  const data = [{
    name: '',
    scheduleType: 'CUSTOM',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    paused: false,
    planItems: [],
    daysOfWeek: planType === 1 ? selectedDays : [],
    customFrequency: planType === 0 ? numberOfDays : '',
    status: 'ACTIVE',
  }];
  onSubmit(data);
}

render() {
  const { visible, onClose } = this.props;
  const { numberOfDays, planType } = this.state;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={visible}
      onClose={onClose}
      onBackdropClick={onClose}
    >
      <div style={{
            position: 'relative',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            width: '40%',
            minWidth: 300,
            minHeight: 300,
            flex: 1,
            padding: 30,
        }}
      >
        <div style={{ textAlign: 'center', marginTop: 20 }}> CREATE PLAN</div>
        <div>
          <div style={{ flexDirection: 'row' }}>
            <Radio
              checked={planType === 0}
              onChange={this.handleChange}
              value={0}
              name="planType"
            />
            <span>Every</span>
            <TextField
              value={numberOfDays}
              style={{
                width: 50,
                textAlign: 'center',
                marginLeft: 10,
                marginRight: 10,
                }}
              disabled={planType !== 0}
              onChange={e => this.handleNoOfDays(e.target.value)}
            />
            <span>Days</span>
          </div>
          <Radio
            checked={planType === 1}
            onChange={this.handleChange}
            value={1}
            name="planType"
          />
          <span>Days of Week</span>
          <div style={{ flexDirection: 'row' }} >
            {
                weekDays.map(day => (
                  <span>
                    <Checkbox
                      disabled={planType !== 1}
                      onChange={() => this.handleDaySelection(day.value)}
                      value={day.value}
                    />
                    <span>{day.label}</span>
                  </span>
                ))
            }
          </div>
        </div>
        <div style={{ marginTop: 50, textAlign: 'center' }}><Button variant="contained" color="primary" onClick={this.createSchedule}> Save Plan</Button></div>
      </div>
    </Modal>
  );
}
}

CreateSchedule.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateSchedule.defaultProps = {
  visible: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default CreateSchedule;
