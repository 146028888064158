import React from 'react';
import moment from 'moment';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText';
import SearchSelect from './SearchSelect';


import { ColMod } from '../../../../components/lib';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

class OfferDetail extends React.Component {
  constructor(props) {
    super(props);
    const { offer } = this.props;
    this.state = {
      openAddOffer: false,
      filters: {
        query: '',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      offer: {
        ...offer,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { offer } = nextProps;
    if (offer) {
      this.setState({
        offer: {
          ...offer,
        },
      });
    }
  }

  onChangeValue = (event, label) => {
    const { offer } = this.state;
    this.setState({
      offer: {
        ...offer,
        [label]: event.target.value,
      },

    });
  };

  createNewOffer = () => {
    console.log('NEW OFFER', this.state.offer);
  }

  handleNewOfferClose = () => {
    this.setState({
      openAddOffer: false,
      isEdit: false,
      postData: {},
    });
  };

  openUserSelector = (userType, index) => () => {
    this.setState({
      openUserSelector: true,
      selectorIndex: index,
      userType,
    });
  };

  render() {
    const { showOfferPopup, handlePopup } = this.props;
    const {
      quantity, OfferDescription, OfferTitle, cashBackAmount, PercentCashBackAmount, percentCashBack, noOfDays,
    } = this.state.offer;
    console.log('STATE', this.state);
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showOfferPopup}
        onClose={() => handlePopup(false)}
      >
        <div style={{
            background: 'white',
            width: '70%',
            height: '70%',
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Button style={{ float: 'right' }} onClick={() => handlePopup(false)} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <Typography variant="body2" color="secondary">Create/Update Offer
                  </Typography>
                </Row>
                <Row>
                  <ColMod lg={4}>
                    <SearchSelect />
                  </ColMod>

                  <ColMod lg={4}>
                    <TextField
                      label="Quantity"
                      fullWidth
                      value={quantity}
                      onChange={event => this.onChangeValue(event, 'quantity')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Status</InputLabel>
                    <MaterialSelect
                      value="ACTIVE"
                      inputProps={{
                          name: 'selectedStatus',
                          id: 'select-status',
                        }}
                      onChange={event => this.onChangeValue(event, 'active')}
                      fullWidth
                    >
                      <MenuItem value="ACTIVE">Active</MenuItem>
                      <MenuItem value="INACTIVE">Inactive</MenuItem>
                    </MaterialSelect>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={4}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Offer Type</InputLabel>
                    <MaterialSelect
                      value="Select Offer Type"
                      inputProps={{
                          name: 'selectedStatus',
                          id: 'select-status',
                        }}
                      onChange={event => this.onChangeValue(event, 'active')}
                      fullWidth
                    >
                      <MenuItem value="CashBackGetOnce">CashBackGetOnce</MenuItem>
                      <MenuItem value="CashBackSubscribe">CashBackSubscribe</MenuItem>
                      <MenuItem value="BuyXGetYFree">BuyXGetYFree</MenuItem>
                      <MenuItem value="PriceOfferOff">PriceOfferOff%</MenuItem>
                    </MaterialSelect>
                  </ColMod>

                  <ColMod lg={4}>
                    <TextField
                      label="Offer Title"
                      fullWidth
                      value={OfferTitle}
                      onChange={event => this.onChangeValue(event, 'OfferTitle')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="CashBack Amount"
                      fullWidth
                      value={cashBackAmount}
                      onChange={event => this.onChangeValue(event, 'cashBackAmount')}
                    />
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={4}>
                    <TextField
                      label="Offer Description"
                      fullWidth
                      value={OfferDescription}
                      onChange={event => this.onChangeValue(event, 'OfferDescription')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="noOfDays"
                      fullWidth
                      value={noOfDays}
                      onChange={event => this.onChangeValue(event, 'noOfDays')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="percentCashBack"
                      fullWidth
                      value={percentCashBack}
                      onChange={event => this.onChangeValue(event, 'percentCashBack')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      label="%CashBackAmount"
                      fullWidth
                      value={PercentCashBackAmount}
                      onChange={event => this.onChangeValue(event, 'PercentCashBackAmount')}
                    />
                  </ColMod>
                  <ColMod lg={4}>
                    <TextField
                      id="date"
                      type="date"
                      name="startDate"
                      onChange={this.onChangeFilter}
                      value={this.state.filters.startDate ? this.state.filters.startDate : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormHelperText>Offer Start Date</FormHelperText>
                  </ColMod>
                  <ColMod lg={4}>

                    <TextField
                      id="date"
                      type="date"
                      name="endDate"
                      onChange={this.onChangeFilter}
                      value={this.state.filters.endDate ? this.state.filters.endDate : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormHelperText>Offer End Date</FormHelperText>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
          <div style={{ float: 'right', paddingRight: '2em' }}>
            <Button onClick={() => handlePopup(false)} style={{ marginRight: '2em' }} color="secondary">
                Cancel
            </Button>
            <Button
              onClick={() => {
                  this.createNewOffer();
                }}
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default OfferDetail;
