import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import API, {
  findTicketsByFilter, agentById,
  agentStatusById, agentList, ticketsPath,
  ticketReportDownloadPath,
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import AgentStatus from '../components/AgentStatus';
import CreateTicket from '../components/CreateTicket';
import TicketList from '../components/TicketList';
import TicketSearch from '../components/TicketSearch';
import AgentWithStatus from '../components/AgentListWithStatus';
import utils from '../../../utils/queryBuilder';
import { ROWS_PER_PAGE } from '../../../constants';

const canView = utils.isCurrentUserAuthorizedToView;

const title = 'Tickets';

class TicketContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        query: '',
        customerId: '',
        mobile: '',
      },
      currentPage: 0,
      isAddTicketOpen: false,
      selectedTicket: {},
      agentStatus: '',
      agents: [],
    };
  }

  componentDidMount() {
    this.getAllAgents();
    this.getAgentStatus();
    this.onSearch(0);
  }

  componentWillUnmount() {
    console.log('ticket container ---> offline the user');
  }


  onSearch = async (page = 0) => {
    // search listing api for tickets
    const { filters = {} } = this.state;
    try {
      const response = await API.sendRequest('get', findTicketsByFilter(filters, page));
      const { data: { content, page: pagable = {} } = {} } = response;
      const { totalElements = 0 } = pagable;
      this.setState({
        tickets: content,
        currentPage: page,
        totalElements,
      });
    } catch (e) {
      // error handling
    }
  };

  getAgentStatus = async () => {
    const { user: { id } = {} } = this.props;
    const res = await API.sendRequest('get', agentById(id));
    const { data } = res;
    this.setState({
      agentStatus: data.status,
    });
  }

  getAllAgents = async () => {
    const response = await API.sendRequest('get', agentList);
    const { data: { data = [] } = {} } = response;
    this.setState({
      agents: data,
    });
  }


  handleAddTicket = (isOpen, ticket = {}) => {
    this.setState({
      isAddTicketOpen: isOpen,
      selectedTicket: ticket,
    });
  }

  handleSaveTicket = async (data) => {
    await API.sendRequest('post', ticketsPath, data);
    this.handleAddTicket(false, {});
    this.onSearch(0);
  }

  upateAgentStatus = async (value) => {
    const { user: { id } = {} } = this.props;
    await API.sendRequest('put', agentStatusById(id, value));
    this.getAgentStatus();
  }

  handleDownload = async () => {
    const { filters } = this.state;
    await API.downloadFile(ticketReportDownloadPath(filters), 'Ticket_Report.csv');
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.onSearch(params);
        break;
      case 'add_ticket':
        this.handleAddTicket(true);
        break;
      case 'download':
        this.handleDownload();
        break;
      default:
        break;
    }
  };

  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState({ filters }, () => {
      if (refresh) {
        this.onSearch(currentPage);
      }
    });
  };

  handleTicketChange = (ticket) => {
    this.setState({
      selectedTicket: ticket,
    });
  }

  render() {
    const {
      filters,
      tickets = [],
      totalElements = 0,
      currentPage = 0,
      isAddTicketOpen,
      selectedTicket,
      agents = [],
      agentStatus = 'start',
    } = this.state;
    const { user, ticketStatus } = this.props;
    const showPopup = (!agentStatus || agentStatus === 'OFFLINE') && user.role !== 'A';
    const availableAgents = agents.filter(ag => ag.status === 'ONLINE');
    return (
      <div>
        <NavBar />
        {
          canView(['CC_AGENT']) &&
          <AgentStatus
              showPopup={showPopup}
              onChange={this.upateAgentStatus}
          />
        }
        <SearchBar
          title={title}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <TicketSearch
            agentStatus={agentStatus}
            agents={agents}
            ticketStatuses={ticketStatus}
            />
        </SearchBar>
        <CreateTicket
          isVisible={isAddTicketOpen}
          ticket={selectedTicket}
          onClose={() => this.handleAddTicket(false)}
          onChange={this.handleTicketChange}
          agents={availableAgents}
          onSave={this.handleSaveTicket}
        />
        <TicketList
          data={tickets}
          totalElements={totalElements}
          rowsPerPage={ROWS_PER_PAGE}
          page={currentPage}
          onPageChange={this.onSearch}
          onEditClick={this.handleAddTicket}
          fullTable={false}
        />
        {canView(['CC_MANAGER', 'ADMIN']) && <AgentWithStatus data={agents} />}
      </div>
    );
  }
}

TicketContainer.propTypes = {
  user: PropTypes.instanceOf(Object),
  ticketStatus: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { User, Status } = state;
  const { ticketStatus } = Status;
  return {
    user: User,
    ticketStatus,
  };
};

export default connect(mapStateToProps, null)(TicketContainer);
