import React, { Component } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { version } from '../../api/config';
import { getLatestVersion } from '../../utils/LatestVersion';
import moment from 'moment';

export default class ForceRefresh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVersion: '',
      latestVersion: '',
      doRefresh: false,
      timeToRefresh: null,
    };
  }

  componentDidMount() {
    this.getCurrentVersion();
    this.fetchVersionInterval();
  }

  getCurrentVersion = () => {
    version.then((data) => {
      // initially set current version and latest version same
      this.setState({ currentVersion: data.version, latestVersion: data.version });
    });
  }

  fetchVersionInterval = () => {
    // check for the version every 5 minutes
    this.interval = window.setInterval(async () => {
      const response = await getLatestVersion();
      // check if response has a version before updating timer
      if (response.version) {
        const latestVersion = response.version;
        // set the latest version
        this.setState({
          latestVersion,
        }, () => {
          if (this.isVersionDifferent()) {
            // if latest version is different than current version then start timer for 5 minutes
            this.startTimer();
          }
        });
      }
    }, 5 * 60 * 1000);
  }

  // check if latest version is different from current version or not.
  isVersionDifferent = () => {
    const { currentVersion, latestVersion } = this.state;
    const isVersionDifferent = currentVersion !== latestVersion;

    return isVersionDifferent;
  }

  startTimer = () => {
    const start = moment('5:00', 'm:ss');
    let seconds = start.minutes() * 60;
    this.timeInterval = setInterval(() => {
      const timeToRefresh = start.subtract(1, 'second').format('m:ss');
      this.setState({ timeToRefresh });
      seconds--;
      if (seconds === 0) {
        // after 5 minutes if user dosn't refresh the app force refresh the app.
        this.refresh();
      }
    }, 1000);
  }

  refresh = () => {
    clearInterval(this.timeInterval);
    clearInterval(this.interval);
    window.location.reload();
  }

  render() {
    const { timeToRefresh } = this.state;
    return (
      this.isVersionDifferent() ? <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <SectionMessage appearance="warning" css={{ display: 'flex' }}>
          New version available, this window will will auto refresh in {timeToRefresh} minutes. Please save your work OR &nbsp;
           <span style={{ color: 'blue', cursor: 'pointer' }} onClick={this.refresh}>Refresh Now</span>
        </SectionMessage>
      </div> : null
    );
  }
}
