import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ToolTip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import globalStyle from '../../../components/lib/style';
import PlanHistoryJsonSearch from './PlanHistoryJsonSearch';

const columnData = [
    {
        id: 'ID', numeric: false, disablePadding: true, label: 'ID',
      },
  {
    id: 'TIME_CREATED', numeric: false, disablePadding: true, label: 'When',
  },
  {
    id: 'ACTION', numeric: false, disablePadding: true, label: 'Action',
  },
  {
    id: 'modifiedBy', numeric: false, disablePadding: true, label: 'Modified By',
  },
];

const getCustomComponents = (data) => {
  const customComponents = {
    modifiedBy: {},
    TIME_CREATED: {},
  };

  data && data.map((auditEntry, index) => {
   
    customComponents['TIME_CREATED'][index] = <ToolTip title={moment(auditEntry.TIME_CREATED).format(PRODUCT_TIME_FORMAT)}><span>{moment(auditEntry.TIME_CREATED).fromNow()}</span></ToolTip>;
   
    if(auditEntry.MODIFIED_BY){
        customComponents['modifiedBy'][index] = auditEntry.MODIFIED_BY.Name;
    } else customComponents['modifiedBy'][index]='';
  })
  return customComponents;
}; 

const styles = {
  paperRight: {
    ...globalStyle.paperRight,
  },
  auditTable: {
    ...globalStyle.paperLeft,
  },
  search: {
    marginLeft: '10px',
  },
  rightPane: {
    padding: '0px',
    overflow: 'hidden',
  },
};

function PlanList(props) {
  const {
    data,
    // rowsPerPage,
    // page,
    // order,
    // orderBy,
    count,
    // handleChangeRowsPerPage,
    // handleChangePage,
    // onRequestSort,
    onClickRow,
    handleClickRow,
    selectedData,
    selectedindex,
    height,
    onCloseHandler,
  } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={selectedData.ENTITY_INFO ? 7 : 12 } style={{ ...styles.auditTable, height: `calc(100vh - ${height + `px`})` }}>
        <PaginatedTable
          columnData={columnData}
         data={data}
           count={count}
        //   rowsPerPage={rowsPerPage}
        //   page={page}
        //   handleChangeRowsPerPage={handleChangeRowsPerPage}
        //   handleChangePage={handleChangePage}
        //   onRequestSort={onRequestSort}
        //   order={order}
        //   orderBy={orderBy}
          customComponents={getCustomComponents(data)}
           onClickRow={onClickRow}
          handleClickRow={handleClickRow}
          selectedindex={selectedindex}
          isNoData={true}
        />
      </Grid>
      {selectedData && selectedData.ENTITY_INFO &&
        <Grid item xs={12} md={5} className="test">
          <div style={{ ...styles.paperRight, height: `calc(100vh - ${height + 'px'})`, ...styles.rightPane }}>
            <PlanHistoryJsonSearch data={selectedData.ENTITY_INFO} height={height} onCloseHandler={onCloseHandler}/>
          </div>
        </Grid>
      } 
    </Grid>
  );
}

PlanList.propTypes = {
   data: PropTypes.array,
//   rowsPerPage: PropTypes.number.isRequired,
//   page: PropTypes.number.isRequired,
//   order: PropTypes.string,
//   orderBy: PropTypes.string,
   count: PropTypes.number.isRequired,
//   handleChangeRowsPerPage: PropTypes.func.isRequired,
//   handleChangePage: PropTypes.func.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
   onClickRow: PropTypes.bool,
   handleClickRow: PropTypes.func,
   selectedData: PropTypes.object,
   selectedindex: PropTypes.number,
   height: PropTypes.number,
  onCloseHandler: PropTypes.func,
};

PlanList.defaultProps = {
  order: 'asc',
  orderBy: '',
  onClickRow: false,
  handleClickRow: () => {},
  height: 0,
  onCloseHandler: () => {},
};

export default PlanList;
