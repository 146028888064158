import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Typography, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchAndSelect from '../../../components/SearchAndSelect';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';

class VendoProductItemMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappings: [],
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { data } = nextProps;
    return {
      mappings: data,
    };
  }

  handleAddIcon = () => {
    const { onChange } = this.props;
    let { mappings = [] } = this.state;
    mappings = [...mappings, {}];
    onChange(mappings);
  }

  handleCloseIcon = (i) => {
    const { onChange } = this.props;
    const { mappings = [] } = this.state;
    mappings.splice(i, 1);
    onChange(mappings);
  }

  handleChange = (value, i, key) => {
    if(!value){
      value = {};
    }
    const { onChange } = this.props;
    const { mappings = [] } = this.state;
    if (mappings.length === 0) {
      mappings.push({ [key]: value });
    } else {
      mappings[i][key] = value;
    }
    if (key === 'catalog') {
      mappings[i].productItem = {};
    }
    onChange(mappings);
  }

  render() {
    const {
      catalogOptions,
      productItemOptions,
      title,
    } = this.props;
    let { mappings = [] } = this.state;
    if (mappings.length === 0) { mappings = [{ catalog: {}, productItem: {} }]; }
    return (
      <div>
        <Typography style={{ marginTop: '0.4em', marginLeft: '0.2em' }} color="secondary" variant="body2">{title}</Typography>
        {mappings.map(({ catalog = {}, productItem = {} }, i) => (
          <div style={{ paddingTop: '0.7em' }} key={i}>

            <Row lg={12} middle='lg'>
              <ColMod lg={5}>
                <InputLabel style={{ fontSize: '0.7em' }}>Vendor</InputLabel>
                <SearchAndSelect
                  value={catalog.id}
                  loadOptions={catalogOptions}
                  onChange={(option) => { this.handleChange(option, i, 'catalog') }}
                />
              </ColMod>
              <ColMod lg={5}>
              <InputLabel style={{ fontSize: '0.7em' }}>Product Item</InputLabel>
                <SearchAndSelect
                  value={productItem.id}
                  loadOptions={query => productItemOptions(catalog.id, query) }
                  onChange={(option) => { this.handleChange(option, i, 'productItem') }}
                />
              </ColMod>
              <ColMod lg={2}>
                {(mappings.length === (i + 1)
                  ? <AddIcon
                    color="secondary"
                    style={{ fontSize: '1.6em' }}
                    onClick={this.handleAddIcon}
                  />
                  : <CloseIcon
                    color="secondary"
                    style={{ fontSize: '1.6em' }}
                    onClick={() => this.handleCloseIcon(i)}
                  />
                )}
              </ColMod>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

VendoProductItemMapping.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  productItemOptions: PropTypes.func,
  catalogOptions: PropTypes.func,
};

export default VendoProductItemMapping;
