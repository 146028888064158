export default {
  modal: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: 'white',
    padding: '16px',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
  },
  cross: {
    fontSize: '1em',
    width: '1.5em',
    height: '1.5em',
    marginRight: '16px',
  },
  title: {
    fontSize: '1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
  },
};
