import React from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Button from '@material-ui/core/Button';

const columnData = [
  { id: 'vendorProductItemId', numeric: false, disablePadding: true, label: 'Product Item ID' },
  { id: 'vendorProductItemName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'uom', numeric: false, disablePadding: true, label: 'Unit Measure (UOM)' },
  { id: 'stockType', numeric: false, disablePadding: true, label: 'Stock Type' },
  { id: 'quantity', numeric: false, disablePadding: true, label: 'Current Stock' },
  { id: 'actions', numeric: false, disablePadding: true, label: 'Actions' },
]


function LedgerStockList(props) {
  const {
    stockList,
    rowsPerPage,
    page,
    count,
    handleChangeRowsPerPage,
    handleChangePage,
    updateStock,
    searchTerm,
    isNoData,
  } = props;

  const getActions = (stock) => (
    <Button variant="outlined" color="primary" onClick={() => { updateStock(stock) }}>Update Stock</Button>
  )

  const getCustomComponents = () => {
    const customComponents = {
      uom: {},
    };

    stockList.map((stock, index) => {
      customComponents['uom'][index] = `${stock.unitMeasure} ${stock.uom.code}`;
    })
    return customComponents;
  }

  return (
    <PaginatedTable
      data={stockList}
      columnData={columnData}
      actions={getActions}
      customComponents={getCustomComponents()}
      count={stockList.length}
      rowsPerPage={rowsPerPage}
      page={page}
      orderBy="vendorProductItemName"
      count={count}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      searchTerm={searchTerm}
      isNoData={isNoData}
    />
  )
}

LedgerStockList.propTypes = {
  isNoData: PropTypes.bool,
  searchTerm: PropTypes.string,
};

LedgerStockList.defaultProps = {
  isNoData: false,
  searchTerm: '',
};

export default LedgerStockList;