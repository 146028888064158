import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, debounce } from 'lodash';
import { Typography } from '@material-ui/core';
import DetailModal from '../DetailModal';
import QuickSearch from '../QuickSearch';
import apiCall, { searchStaticRoutesByCustomerAddressId } from '../../../api/NetworkHandler';

var requestId = 0;
class RouteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      routes: [],
      loadingData: false,
      enableAutomaticRouting: false,
      errMessage: '',
    };
  }

  componentDidMount() {
    this.fetchRoutes();
  }

  onRouteSearch = (e) => {
    this.setState({
      query: e.target.value,
      routes: [],
    }, () => this.fetchRoutes());
  };

  onRouteSearchClear = () => {
    this.setState({
      query: '',
      routes: [],
      errMessage: '',
    });
  };

  onSelectData = (obj) => {
    this.props.onSelectLineItem({ route: obj });
  };

  getTableConfig = () => ({
    table: [
      {
        label: 'Route Id',
        pick: 'id',
      },
      {
        label: 'Route Name',
        pick: 'name',
      },
      {
        label: 'Hub Name',
        pick: 'hubName',
      },
      {
        label: 'Warehouse Name',
        pick: 'warehouseName',
      },
    ],
  });

  fetchRoutes = debounce( async () => {
    const { query, enableAutomaticRouting } = this.state;
    const { selectedAddress = {} } = this.props;
    const { id = null } = selectedAddress;
    this.setState({
      loadingData: true,
      // data: [],
    });
    try {
      requestId += 1;
      let prevRequestId = requestId;
      const response = await apiCall.sendRequest('get', searchStaticRoutesByCustomerAddressId(id, query, enableAutomaticRouting));
      const { data: { data = [] } } = response;
      if (requestId == prevRequestId) {
        this.setState({
          routes: data,
          loadingData: false,
          errMessage: '',
        });
      }
    } catch (err) {
      this.setState({
        routes: [],
        loadingData: false,
        errMessage: err.response.data.message,
      });
    }
  }, 750);

  handleAutoAssignedRoute = () => {
    const { enableAutomaticRouting } = this.state;
    this.setState({
      enableAutomaticRouting: !enableAutomaticRouting,
      query: '',
    }, () => this.fetchRoutes());
  }

  render() {
    const { onClose, selectedAddress, isNew = false } = this.props;
    const { query, loadingData, enableAutomaticRouting, routes, errMessage } = this.state;
    const modifiedData = routes.filter(el => el.active !== false);
    return (
      <DetailModal
        title="Find Route"
        unMountComponent={onClose}
        handleAutoAssignedRoute={this.handleAutoAssignedRoute}
        isRouteSearch={true}
        enableAutomaticRouting={enableAutomaticRouting}
      >
        {!isNew &&
          <div style={{ display: 'flex', justifyContent: 'initial', paddingLeft: '10px' }}>
            <div style={{ paddingRight: '10px' }}>
              <Typography variant="body2" color="secondary">
                Customer Address:
              </Typography>
            </div>
            <Typography variant="body2" color="primary">
              {selectedAddress.fullAddress.split(",").filter(el => el).join(", ")}
            </Typography>
          </div>
        }
        <QuickSearch
          label="Type Route name"
          query={query}
          errText={errMessage}
          onSearchChange={this.onRouteSearch}
          onClearSearch={this.onRouteSearchClear}
          data={modifiedData}
          loadingData={loadingData}
          onSelectRow={this.onSelectData}
          tableConfig={this.getTableConfig()}
        />
      </DetailModal>
    );
  }
}

RouteSearch.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectLineItem: PropTypes.func,
};

export default RouteSearch;
