import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import apiCall, { getWareHouse } from "../api/NetworkHandler";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 2,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 5,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: '#f50057',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class StockAdjustmentSearchBar extends React.Component {
  state = {
    filters: {
      query: '',
      warehouseId: '',
      startDate: '',
      endDate: '',
    },
    warehouse: [],
  }

  componentDidMount() {
    apiCall.sendRequest('get', getWareHouse)
      .then((res) => {
        this.setState({
          warehouse: res.data._embedded.warehouse,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangeFilter = (event) => {
    const {name} = event.target;
    const {value} = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  }

  onSearch = () => {
    this.props.onSearch(this.state.filters);
  }

  onClearSearch = () => {
    this.setState({
      filters: {
        query: '',
        warehouseId: '',
        startDate: '',
        endDate: '',
        status: '',
      },
    }, () => {
      this.props.onSearch(this.state.filters);
    });
  }

  renderWarehouse = () => this.state.warehouse.map(warehouse => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.name}</MenuItem>)


  render() {
    console.log(this.state);
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
              Stock Ledger
          </Typography>
        </div>
        <div style={styles.searchBar}>
          <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                fullWidth
                name="query"
                onChange={this.onChangeFilter}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
              />
            </div>
            <div style={styles.clear}>
              <Button variant="contained" color="secondary" onClick={this.onSearch}>
                    Search
              </Button>
            </div>
            <div style={styles.clear}>
              <Button color="secondary" onClick={this.onClearSearch}>
                    Clear All
              </Button>
            </div>
          </div>
          <div style={styles.filterContainer}>
            <div style={{ flex: 1 }}>
              <Select
                name="warehouseId"
                onChange={this.onChangeFilter}
                value={this.state.filters.warehouseId}
              >
                {this.renderWarehouse()}
              </Select>
              <FormHelperText>Warehouse</FormHelperText>
            </div>
            <div style={{ flex: 2 }}>
              <TextField
                id="date"
                type="date"
                name="startDate"
                onChange={this.onChangeFilter}
                value={this.state.filters.startDate}
                InputLabelProps={{
                      shrink: true,
                    }}
              />
              <FormHelperText>Start Date</FormHelperText>
            </div>
            <div style={{ flex: 2 }}>
              <TextField
                id="date"
                type="date"
                name="endDate"
                onChange={this.onChangeFilter}
                value={this.state.filters.endDate}
                InputLabelProps={{
                      shrink: true,
                    }}
              />
              <FormHelperText>End Date</FormHelperText>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StockAdjustmentSearchBar;
