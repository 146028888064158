import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
    TextField,
    Button,
    Typography,
    Tooltip,
    Modal,
    Chip,
    Avatar,
    Select,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import apiCall, { updateSurveyById, createNewSurvey, updateOverlaysById } from '../../../api/NetworkHandler';
import { isEmpty, debounce } from 'lodash';
import { ArrowBack } from 'material-ui-icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Edit from "@material-ui/icons/Create";
import ChoiceSelctor from './ChoiceSelector';
import { Input as AntdInput, Switch } from "antd";
import SectionMessage from '@atlaskit/section-message';
import SurveyQuestionSelector from "./SurveyQuestionSelector";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import AudienceSetSelector from '../../Audience/components/AudienceSetSelector';
import ProgressBar from '../../../components/States/ProgressBar';


const styles = {
    container: {
        width: window.innerWidth * 0.4,
    },
    arrow: {
        cursor: "pointer",
    },
    label: {
        marginBottom: '1em',
    },
    error: {
        color: 'red',
    },
    editIcon: {
        color: "#f50057",
        width: "18px",
        height: "18px",
        marginLeft: "3px",
        cursor: "pointer"
    },
    deleteIcon: {
        color: "#808080",
        width: "18px",
        height: "18px",
        marginLeft: "10px",
        cursor: "pointer"
    },
    colPadding: {
        padding: "10px"
    }
};

var versionDict = {
    "selectedOverlayRequestId": 0
};


class EditSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            surveyData: {},
            error: {},
            disableButton: false,
            openChoiceSelector: false,
            choiceErrorText: '',
            openEditCondition: false,
            selectedCondition: [],
            tempCondition: '',
            tempConditionValue: null,
            choicePosition: '',
            invalidCondition: false,
            invalidConditionValue: false,
            isChildMounted: false,
            conditionError: false,
            selectedQuestion: null,
            selectedOverlay: null,
            loading: false
        };
    }

    componentDidMount() {
        const { selectedSurvey } = this.props;
        if (selectedSurvey.overlayId) this.getOverlayById(selectedSurvey.overlayId);
        this.setState({
            surveyData: {
                ...selectedSurvey
            },
            selectedQuestion: selectedSurvey.question ? { label: selectedSurvey.question, value: selectedSurvey.question } : null,
        });
    };

    getOverlayById = async (overlayId) => {
        try {
            versionDict.selectedOverlayRequestId += 1;
            let prevRequestId = versionDict.selectedOverlayRequestId;
            const response = await apiCall.sendRequest('get', updateOverlaysById(overlayId));
            const { data: { data = {} } = {} } = response;
            if (prevRequestId == versionDict.selectedOverlayRequestId) {
                this.setState({
                    selectedOverlay: { ...data, label: data.title, value: data.id },
                });
            }
        } catch (err) {
            this.setState({
                selectedOverlay: null,
            });
        }
    }

    setError = (key, value) => {
        this.setState((prevState) => {
            const { error } = prevState;

            return {
                error: {
                    ...error,
                    [key]: value,
                },
            };
        });
    };

    isPropertyValid = (key, value) => {
        if (!value) {
            this.setError(key, true);
            return false;
        }
        this.setError(key, false);
        return true;
    };

    isFormValid = () => {
        const { surveyData } = this.state;
        const {
            ttl,
            question,
            type,
            firstChoice,
            secondChoice,
            conditions
        } = surveyData;
        const survey = {};
        survey.ttl = ttl;
        survey.question = question;
        survey.type = type;
        survey.firstChoice = firstChoice;
        survey.secondChoice = secondChoice;
        survey.conditions = conditions;

        let isValid = true;

        const validateEntities = ['ttl', 'question', 'type', 'firstChoice', 'secondChoice', 'conditions'];

        for (const property in survey) {
            // eslint-disable-next-line max-len
            if (validateEntities.includes(property) && !this.isPropertyValid(property, survey[property])) {
                isValid = false;
            }
        }
        return isValid;
    };

    preConditionCheck = (conditions) => {
        let copiedCondition = { ...JSON.parse(conditions) };
        const isAppOpenConditionPresent = copiedCondition.hasOwnProperty('OPENED_APP_IN_LAST_N_DAYS');
        return isAppOpenConditionPresent;
    };

    toggleLoading = (loadingState, buttonState) => {
        this.setState({
            loading: loadingState,
            disableButton: buttonState,
        });
    };

    handleSaveSurvey = debounce(async () => {
        if (!this.isFormValid()) {
            return;
        }
        this.toggleLoading(true, true);
        const { onRefresh } = this.props;
        const { surveyData } = this.state;
        const { id = null, conditions } = surveyData;
        if (this.preConditionCheck(conditions)) {
            this.setState({
                conditionError: false,
            });
            delete surveyData.answerOptions;
            try {
                if (id) {
                    await apiCall.sendRequest('put', updateSurveyById(surveyData.id), surveyData);
                } else {
                    await apiCall.sendRequest('post', createNewSurvey, surveyData);
                }
                this.handleClose();
                onRefresh();
            } catch (err) {
                console.log(err.response);
            }
        } else {
            this.setState({
                conditionError: true,
            });
        }
        this.toggleLoading(false, false);
    }, 500);

    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (["ttl", "priority"].includes(name) && value < 0) return;
        const { surveyData } = this.state;
        this.setState({
            surveyData: {
                ...surveyData,
                [name]: value,
            }
        });
    };

    handleQuestionSelect = (selectedQuestion) => {
        const { surveyData } = this.state;
        this.setState({
            selectedQuestion,
            surveyData: {
                ...surveyData,
                question: isEmpty(selectedQuestion) ? null : selectedQuestion.value,
            }
        });
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    handleOptionDelete = (position) => {
        const { surveyData } = this.state;
        this.setState({
            surveyData: {
                ...surveyData,
                [position]: null,
            }
        });
    };

    handleAddOption = (position) => {
        this.setState({
            openChoiceSelector: true,
            choicePosition: position,
            choiceErrorText: '',
            isChildMounted: true,
        });
    };

    handleCloseChoiceSelector = () => {
        this.setState({
            openChoiceSelector: false,
            isChildMounted: false,
        });
    };

    handleSelectChoice = (choice) => {
        const { surveyData, choicePosition } = this.state;
        const { answerOptions = [], firstChoice, secondChoice, thirdChoice, fourthChoice, fifthChoice, } = surveyData;
        let choiceArray = [firstChoice, secondChoice, thirdChoice, fourthChoice, fifthChoice];
        if (choiceArray.includes(choice.id)) {
            this.setState({
                choiceErrorText: 'Duplicate choice not allowed'
            });
            this.handleCloseChoiceSelector();
            return;
        } else {
            let updatedAnswerOptions = [...answerOptions];
            updatedAnswerOptions.push(choice);
            this.setState({
                surveyData: {
                    ...surveyData,
                    answerOptions: updatedAnswerOptions,
                    [choicePosition]: choice.id,
                },
                choiceErrorText: '',
            });
            this.handleCloseChoiceSelector();
        }
    };

    handleEditConditions = (selectedCondition) => {
        this.setState({
            selectedCondition,
            tempCondition: selectedCondition[0],
            tempConditionValue: selectedCondition[1]
        }, () => this.handleOpenEditCondition());
    };

    handleOpenEditCondition = () => {
        this.setState({
            openEditCondition: true,
        });
    };

    handleCloseEditCondition = () => {
        this.setState({
            openEditCondition: false,
            selectedCondition: [],
            tempCondition: '',
            tempConditionValue: null,
        });
    };

    handleDeleteConditions = (selectedCondition) => {
        let prop = selectedCondition[0];
        const { surveyData } = this.state;
        const { conditions = {} } = surveyData;
        let copiedCondition = { ...JSON.parse(conditions) };
        const newConditions = Object.keys(copiedCondition).reduce((object, key) => {
            if (key !== prop) {
                object[key] = copiedCondition[key]
            }
            return object
        }, {});
        this.handleInputChange({ target: { name: 'conditions', value: JSON.stringify(newConditions) } });
    };

    handleChangeCondition = (event) => {
        const { name, value } = event.target;
        if (name === 'conditions') {
            this.setState({
                tempCondition: value,
            });
        } else {
            this.setState({
                tempConditionValue: value,
            });
        }
    };

    validateConditionForm = () => {
        const { tempCondition, tempConditionValue } = this.state;
        let isValid = true;

        if (!tempCondition) {
            this.setState({
                invalidCondition: true,
            });
            isValid = false;
        } else {
            this.setState({
                invalidCondition: false,
            });
        }

        if (!tempConditionValue) {
            this.setState({
                invalidConditionValue: true,
            });
            isValid = false;
        } else {
            this.setState({
                invalidConditionValue: false
            });
        }

        return isValid;
    }

    handleSelectCondition = () => {
        if (!this.validateConditionForm()) {
            return;
        }
        const { surveyData, tempCondition, tempConditionValue } = this.state;
        const { conditions = {} } = surveyData;
        let tempObj = { [tempCondition]: tempConditionValue };
        let newConditions = (!conditions || Object.keys(conditions).length == 0) ? {} : { ...JSON.parse(conditions) };
        if (Object.keys(newConditions).length == 0) {
            Object.assign(newConditions, tempObj);
        } else {
            Object.assign(newConditions, tempObj);
        }
        this.handleInputChange({ target: { name: 'conditions', value: JSON.stringify(newConditions) } });
        this.handleCloseEditCondition();
    };

    findChoiceDataByChoiceId = (choiceId) => {
        if (!choiceId) return {};
        const { surveyData = {} } = this.state;
        const { answerOptions = [] } = surveyData;
        let choiceObj = answerOptions.find(el => el.id == choiceId);
        return typeof choiceObj === 'undefined' ? {} : choiceObj;
    }

    handleOverlaySelection = (selectedOverlay) => {
        const { surveyData } = this.state;
        this.setState({
            selectedOverlay,
            surveyData: {
                ...surveyData,
                overlayId: isEmpty(selectedOverlay) ? null : selectedOverlay.value,
            }
        });
    };

    render() {
        const { parentComponentId, classes, surveyConditionTypes, surveyTypes } = this.props;
        const {
            surveyData,
            openChoiceSelector,
            choiceErrorText,
            openEditCondition,
            selectedCondition,
            tempCondition,
            tempConditionValue,
            error,
            invalidCondition,
            invalidConditionValue,
            isChildMounted,
            conditionError,
            selectedQuestion,
            selectedOverlay,
            loading,
            disableButton
        } = this.state;
        const {
            id = null,
            priority,
            type,
            active,
            conditions,
            value,
            firstChoice,
            secondChoice,
            thirdChoice,
            fourthChoice,
            fifthChoice,
            ttl
        } = surveyData;
        const newConditions = (!conditions || typeof conditions === 'undefined' || Object.keys(conditions).length === 0) ? {} : JSON.parse(conditions);
        const firstChoiceData = this.findChoiceDataByChoiceId(firstChoice);
        const secondChoiceData = this.findChoiceDataByChoiceId(secondChoice);
        const thirdChoiceData = this.findChoiceDataByChoiceId(thirdChoice);
        const fourthChoiceData = this.findChoiceDataByChoiceId(fourthChoice);
        const fifthChoiceData = this.findChoiceDataByChoiceId(fifthChoice);
        const errorText = (error.firstChoice || error.secondChoice) ? 'Please select first and second choice' : choiceErrorText;

        return (
            <Grid className={classes.container} fluid>
                <ProgressBar isLoading={loading} />
                <Row md={12} between="md">
                    <ColMod>
                        <Row>
                            <Col>
                                <Tooltip title="Back">
                                    <ArrowBack onClick={this.handleClose} className={classes.arrow} color="secondary" />
                                </Tooltip>
                            </Col>
                            &nbsp;&nbsp;
                            <Col>
                                <Typography variant="title">{parentComponentId}</Typography>
                            </Col>
                        </Row>
                    </ColMod>
                    <ColMod>
                        <Row md={12}>
                            <Col md={12}>
                                <Button onClick={this.handleClose} color="secondary" variant="outlined">Cancel</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button type="submit" variant="contained" color="primary" onClick={this.handleSaveSurvey} disabled={disableButton}>Save</Button>
                            </Col>
                        </Row>
                    </ColMod>
                </Row>
                {conditionError &&
                    <Row md={12}>
                        <Col md={12} style={styles.colPadding}>
                            <SectionMessage appearance="error">
                                Please add <i style={{ color: "blue" }}>Opened the app in last n days</i> condition to save the survey.
                            </SectionMessage>
                        </Col>
                    </Row>
                }
                <Row md={12}>
                    <Col md={6} style={styles.colPadding}>
                        <Typography variant="body2" color="inherit">
                            Status
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <small style={{ color: 'red' }}>Inactive</small>
                            &nbsp;
                            <Switch
                                name="status"
                                checked={active}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                onChange={value => this.handleInputChange({ target: { name: 'active', value } })}
                            />
                            &nbsp;
                            <small style={{ color: '#1E90FF' }}>Active</small>
                        </Typography>
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={6} style={styles.colPadding}>
                        <Typography variant="caption"> Survey Priority </Typography>
                        <AntdInput
                            type="number"
                            value={priority || ''}
                            name="priority"
                            onChange={this.handleInputChange}
                            placeholder="Enter survey priority.."
                        />
                    </Col>
                    <Col md={6} style={styles.colPadding}>
                        <Typography variant="caption" color={error.ttl ? "error" : "default"}> Cool Off Period (Individual) <sup>*</sup> </Typography>
                        <AntdInput
                            type="number"
                            value={ttl || ''}
                            name="ttl"
                            onChange={this.handleInputChange}
                            placeholder="Enter individual cool off time.."
                            addonAfter="Days"
                        />
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12}>
                        <Typography variant="caption"> Audience Set </Typography>
                        <AudienceSetSelector selected={selectedOverlay} onSelect={this.handleOverlaySelection} />
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12} style={styles.colPadding}>
                        <Typography variant="caption" color={error.question ? "error" : "default"}> Question <sup>*</sup> </Typography>
                        <SurveyQuestionSelector selected={selectedQuestion} onSelect={this.handleQuestionSelect} />
                    </Col>
                </Row>
                <Row md={12} bottom="md">
                    <Col md={12} style={styles.colPadding}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ paddingLeft: '10px' }}>First Choice</span>
                                <Chip
                                    key={Date.now()}
                                    label={firstChoiceData.choice}
                                    avatar={<Avatar alt="choice" src={firstChoiceData.imageUrl} />}
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => this.handleAddOption('firstChoice')}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                                <span style={{ paddingLeft: '10px' }}>Second Choice</span>
                                <Chip
                                    key={Date.now()}
                                    label={secondChoiceData.choice}
                                    avatar={<Avatar alt="choice" src={secondChoiceData.imageUrl} />}
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => this.handleAddOption('secondChoice')}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                                <span style={{ paddingLeft: '10px' }}>Third Choice</span>
                                <Chip
                                    key={Date.now()}
                                    label={thirdChoiceData.choice}
                                    avatar={<Avatar alt="choice" src={thirdChoiceData.imageUrl} />}
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => this.handleAddOption('thirdChoice')}
                                    onDelete={() => { this.handleOptionDelete('thirdChoice') }}
                                    deleteIcon={thirdChoice ? <CancelIcon /> : <div />}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ paddingLeft: '10px' }}>Fourth Choice</span>
                                <Chip
                                    key={Date.now()}
                                    label={fourthChoiceData.choice}
                                    avatar={<Avatar alt="choice" src={fourthChoiceData.imageUrl} />}
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => this.handleAddOption('fourthChoice')}
                                    onDelete={() => { this.handleOptionDelete('fourthChoice') }}
                                    deleteIcon={fourthChoice ? <CancelIcon /> : <div />}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                                <span style={{ paddingLeft: '10px' }}>Fifth Choice</span>
                                <Chip
                                    key={Date.now()}
                                    label={fifthChoiceData.choice}
                                    avatar={<Avatar alt="choice" src={fifthChoiceData.imageUrl} />}
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => this.handleAddOption('fifthChoice')}
                                    onDelete={() => { this.handleOptionDelete('fifthChoice') }}
                                    deleteIcon={fifthChoice ? <CancelIcon /> : <div />}
                                />
                            </div>
                            <span style={{ color: 'red', paddingLeft: '15px', paddingTop: '25px' }}>{errorText}</span>
                        </div>
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={4} style={styles.colPadding}>
                        <Typography variant="caption"> Type <sup>*</sup> </Typography>
                        <Select
                            value={type || 'all'}
                            fullWidth
                            name="type"
                            onChange={this.handleInputChange}
                            disabled={!!id}
                            error={error.type}
                        >
                            {Object.keys(surveyTypes).map((type, index) => (<MenuItem key={index} value={type}>{surveyTypes[type]}</MenuItem>))}
                        </Select>
                    </Col>
                    <Col md={8} style={styles.colPadding}>
                        <TextField
                            label="Value"
                            type="text"
                            value={value || ''}
                            name="value"
                            onChange={this.handleInputChange}
                            fullWidth
                            disabled={!!id}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
                <Row md={12}>
                    <Col md={12} style={{ padding: "20px 10px" }}>
                        <div>
                            <Tooltip title="Add">
                                <Button variant="outlined" color="primary" onClick={this.handleOpenEditCondition}> + Add New Condition </Button>
                            </Tooltip>
                            {error.conditions && <small style={{ color: "red", paddingLeft: "10px" }}>Please add atleast one condition.</small>}
                        </div>
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Condition Type</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(newConditions).map((el, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">{el[0]}</TableCell>
                                                <TableCell component="th" scope="row">{el[1]}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Tooltip title="Edit">
                                                        <Edit
                                                            style={styles.editIcon}
                                                            onClick={() => this.handleEditConditions(el)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <DeleteIcon
                                                            style={styles.deleteIcon}
                                                            onClick={() => this.handleDeleteConditions(el)}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Col>
                </Row>
                <ChoiceSelctor isChildMounted={isChildMounted} openSearchBox={openChoiceSelector} handleClose={this.handleCloseChoiceSelector} selectChoice={this.handleSelectChoice} key={Date.now()} />
                <Modal
                    open={openEditCondition}
                    onClose={this.handleCloseEditCondition}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Paper style={{
                        background: 'white',
                        width: '40%',
                        height: '30%',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: "5px"
                    }}>
                        <div style={{ height: window.innerHeight * 0.23 }}>
                            <div style={{ height: '40px', width: '100%', backgroundColor: 'lightgray', borderRadius: '5px' }}>
                                <Typography variant="title" style={{ textAlign: 'center', paddingTop: '10px' }}> Edit/Add Conditions </Typography>
                            </div>
                            <Row md={12}>
                                <ColMod md={9}>
                                    <Typography variant="caption" color={invalidCondition ? "error" : "default"}> Conditions <sup>*</sup> </Typography>
                                    <Select
                                        value={tempCondition}
                                        fullWidth
                                        onChange={this.handleChangeCondition}
                                        name="conditions"
                                        disabled={selectedCondition.length > 0}
                                        native
                                    >
                                        <option value="">select condition</option>
                                        {Object.keys(surveyConditionTypes).map((condition, index) => (<option key={index} value={condition}>{surveyConditionTypes[condition]}</option>))}
                                    </Select>
                                </ColMod>
                                <ColMod md={3}>
                                    <TextField
                                        label="Value"
                                        type="text"
                                        value={tempConditionValue}
                                        name="value"
                                        onChange={this.handleChangeCondition}
                                        fullWidth
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        autoComplete="off"
                                        error={invalidConditionValue}
                                    />
                                </ColMod>
                            </Row>
                            <Row md={12}>
                                <ColMod md={12}>
                                    <span style={{ color: "red" }}>**Note: </span>
                                    <i>Opened the app in last n days</i>
                                    <span> - This condition is madatory.</span>
                                </ColMod>
                            </Row>
                        </div>
                        <div style={{ float: 'right', paddingRight: '2em' }}>
                            <Button onClick={this.handleCloseEditCondition} style={{ marginRight: '2em' }} color="secondary" variant="outlined"> Cancel </Button>
                            <Button variant="contained" color="primary" onClick={this.handleSelectCondition}> Ok </Button>
                        </div>
                    </Paper>
                </Modal>
            </Grid>
        )
    }
}

export default (withStyles(styles)(EditSurvey));
