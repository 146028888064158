export default {
  actionContainer: {
    display: 'flex',
    borderBottom: '1px solid rgb(221, 221, 221)',
    padding: '16px 24px',
  },
  actionLeft: {
    width: '60%',
  },
  actionRight: {
    width: '40%',
  },
  title: {
    fontSize: '1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoItem: {
    marginRight: '36px',
  },
  tabBar: {
    background: 'white',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  refundBtn: {
    marginLeft: '20px',
  },
};
