import React, { Component } from 'react';
import PropTypes from "prop-types";
import apiCall, { getHubsPath } from '../../api/NetworkHandler';
import SearchAndSelect from '../Core/SearchAndSelect';

class HubSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubs: [],
      warehouseId: "",
    }
  }

  fetchHubs = async (searchTerm) => {
    const { warehouseId } = this.props;
    if (!warehouseId) {
      return [];
    }
    const response = await apiCall.sendRequest('get', getHubsPath(warehouseId) + `?query=${searchTerm}`);
    const { data: { data } } = response;
    const hubs = data.map(hub => {
      return {
        ...hub,
        label: hub.name,
        value: hub.id,
      }
    })

    return hubs;
  }

  render() {
    const { onSelect, selected } = this.props;
    return (
      <SearchAndSelect
        onLoad={this.fetchHubs}
        selected={selected}
        onSelect={onSelect}
      />
    )
  }
}


HubSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Products from perticular region, if regionId not passed will get productItems from all regions
   */
  warehouseId: PropTypes.number.isRequired,
};

HubSelector.defaultProps = {
  selected: null,
};

export default HubSelector;
