import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import moment from 'moment';
import Cookies from 'universal-cookie';
import NavBar from '../../../components/NavBar';
import apiCall, {
  getWareHouse,
  routesByHubIdPath,
  productCatalogsPath,
  allHubsPurchaseIndentCsv,
} from '../../../api/NetworkHandler';
import UserSelection from '../../../components/UserSelection';
import StaticRouteSelection from '../../../components/StaticRouteSelection';
import BrandSelection from '../../../components/BrandSelection';
import CONFIG from '../../../api/config';
import CatalogSelector from '../../../components/Catalog/Selector';
import { isEmpty } from 'lodash';


const styles = {
  wrapper: {
    marginTop: 50,
    marginLeft: 50,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    display: 'flex',
  },
  textField: {
    marginLeft: 20,
    marginRight: 20,
  },
  datePicker: {
    marginTop: 20,
    display: 'flex',
  },
  bottomContainer: {
    marginTop: 20,
    display: 'flex',
  },
  downloadButton: {
    marginLeft: 20,
  },
};

const cookies = new Cookies();

class PurchaseIndentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouse: {},
      hub: {},
      route: {},
      brand: {},
      catalog: {},
      userType: '',
      warehouseList: [],
      hubList: [],
      routeList: [],
      openUserSelector: false,
      openRouteSelector: false,
      openBrandSelector: false,
      date: moment().format('YYYY-MM-DD'),
      productCatalogs: [],
      reRenderCatalog: false,
    };
  }

  componentDidMount() {
    this.fetchAllWarehouses();
    this.fetchPrductCatalogs();
  }

  setDate = (event) => {
    this.setState({
      date: event.target.value,
    });
  };

  getPickList = () => {
    const {
      warehouse, hub, route,
    } = this.state;
    if (route && route.id) {
      return this.downloadPickListByRoute(route.id);
    } if (hub && hub.id) {
      return this.downloadPickListByHub(hub.id);
    } if (warehouse && warehouse.id) {
      return this.downloadPickListByWarehouse(warehouse.id);
    }
  };

  getWarehouseUrl = () => {
    const { warehouse } = this.state;
    if (warehouse && warehouse.id) {
      this.downloadPickListByWarehouse(warehouse.id);
    }
  };


  fetchAllHubs = () => {
    const { warehouse: { name } = {} } = this.state;
    apiCall.sendRequest('get', `/api/v1/hub/search/findHub?query=${name}`).then((response) => {
      const { data: { _embedded = {} } } = response;
      this.setState({
        hubList: _embedded.hub,
        route: {},
      });
    });
  };

  fetchAllRoutes = () => {
    const { hub: { id } = {} } = this.state;
    apiCall
      .sendRequest('get', routesByHubIdPath(id))
      .then((response) => {
        const { data: { _embedded = {} } } = response;
        this.setState({
          routeList: _embedded['static-routes'],
        });
      });
  };

  handleWarehouseChange = (event) => {
    const { value } = event.target;
    const { warehouseList = [], reRenderCatalog } = this.state;
    const [warehouse] = warehouseList.filter(item => item.id === value);
    this.setState(
      {
        warehouse,
        hub: {},
        route: {},
        reRenderCatalog: !reRenderCatalog,
        catalog: {}
      },
      this.fetchAllHubs,
    );
  };

  handleHubChange = (event) => {
    const { value } = event.target;
    const { hubList = [] } = this.state;
    const [hub] = hubList.filter(item => item.id === value);
    this.setState(
      {
        hub,
        route: {},
      },
      this.fetchAllRoutes,
    );
  };

  handleRouteChange = (event) => {
    const { value } = event.target;
    const { routeList = [] } = this.state;
    const [route] = routeList.filter(item => item.id === value);
    this.setState({
      route,
    });
  };

  handleCatalogChange = (catalog) => {
    if (catalog) {
      catalog.id = catalog.value;
    } else {
      catalog = {}
    }
    this.setState({
      catalog,
    });
  }

  openUserSelector = (userType) => {
    this.setState({
      openUserSelector: true,
      userType,
    });
  };

  handleClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  };

  selectUser = (hub) => {
    this.setState(
      {
        openUserSelector: false,
        hub,
        route: {},
      },
      this.fetchAllRoutes,
    );
  };

  fetchAllWarehouses = () => {
    apiCall.sendRequest('get', getWareHouse).then((response) => {
      const { _embedded: { warehouse } } = response.data;
      this.setState({
        warehouseList: warehouse,
        hub: {},
      });
    });
  };

  fetchPrductCatalogs = async () => {
    try {
      const response = await apiCall.sendRequest('get', productCatalogsPath);
      const { data: { _embedded } } = response;
      this.setState({
        productCatalogs: _embedded['product-catalogs'],
      });
    } catch (e) {
      console.log('ERROR: ', e);
    }
  }

  handleRouteSelectorClose = () => {
    this.setState({
      openRouteSelector: false,
    });
  };

  selectStaticRoute = (route) => {
    this.setState({
      openRouteSelector: false,
      route,
    });
  };

  openRouteSelector = () => {
    this.setState({
      openRouteSelector: true,
    });
  };

  downloadPickListByRoute = (routeId) => {
    const { date, brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    return `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-static-route/${routeId}/pdf?date=${date}${brandString}${catalogString}`;
  };

  downloadPickListByHub = (hubId) => {
    const { date, brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    return `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-hub/${hubId}/pdf?date=${date}${brandString}${catalogString}`;
  };

  downloadPickListByWarehouse = (warehouseId) => {
    const { date, brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    const url = `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-warehouse/${warehouseId}/pdf?date=${date}${brandString}${catalogString}`;
    return url;
  };


  downloadCSVPickListByRoute = (routeId, date) => {
    const { brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    let dateString = '';
    if (date) {
      dateString = `&date=${date}`;
    }
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    return `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-static-route/${routeId}/csv?${dateString}${brandString}${catalogString}`;
  };

  downloadCSVPickListByHub = (hubId, date) => {
    const { brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    let dateString = '';
    if (date) {
      dateString = `&date=${date}`;
    }
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    return `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-hub/${hubId}/csv?${dateString}${brandString}${catalogString}`;
  };

  downloadCSVPickListByWarehouse = (warehouseId, date) => {
    const { brand: { id: brandId } = {}, catalog: { id: catalogId } = {} } = this.state;
    let brandString = '';
    let catalogString = '';
    let dateString = '';
    if (date) {
      dateString = `&date=${date}`;
    }
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    const url = `${
      CONFIG.BASE_URL
    }/api/v1/delivery-route-pick-list/by-warehouse/${warehouseId}/csv?${dateString}${brandString}${catalogString}`;
    return url;
  };

  downloadAllHubsZip = () => {
    const {
      date,
      hub = {},
      warehouse = {}, brand: { id: brandId } = {}, catalog: { id: catalogId } = {},
    } = this.state;
    const { id: warehouseId } = warehouse;
    const { id: hubId } = hub;
    const filters = {
      warehouseId,
      brandId,
      catalogId,
      hubId,
      date,
    };
    if (warehouseId) {
      const url = `${CONFIG.BASE_URL}${allHubsPurchaseIndentCsv(filters)}`;
      this.handleDownload(url, `${warehouse.name}-${date}.csv`);
    }
  }


  handleBrandSelectorClose = () => {
    this.setState({
      openBrandSelector: false,
    });
  };

  selectBrand = (brand) => {
    this.setState({
      brand,
      openBrandSelector: false,
    });
  };

  openBrandSearch = () => {
    this.setState({
      openBrandSelector: true,
    });
  };


  clearData = () => {
    this.setState({
      warehouse: {},
      hub: {},
      route: {},
      brand: {},
      hubList: [],
      routeList: [],
      catalog: {},
      date: '',
    });    
  };

  downloadAll = () => {
    const {
      date, brand = {}, hub = {}, warehouse = {},
      catalog: { id: catalogId } = {},
    } = this.state;
    const { id: hubId } = hub;
    const { id: warehouseId } = warehouse;
    const { id: brandId } = brand;
    let brandString = '';
    let catalogString = '';
    if (brandId) {
      brandString = `&brandId=${brandId}`;
    }
    if (catalogId) {
      catalogString = `&catalogId=${catalogId}`;
    }
    let url = null;
    if (hubId) {
      url = `${CONFIG.BASE_URL}/api/v1/delivery-route-pick-list/by-hub/${hubId}/pdf/all-routes?date=${date}${brandString}${catalogString}`;
    } else if (warehouseId) {
      url = `${CONFIG.BASE_URL}/api/v1/delivery-route-pick-list/by-warehouse/${warehouseId}/pdf/all-hubs?date=${date}${brandString}${catalogString}`;
    }
    return url;
  }

  handleDownloadIndent = () => {
    // download indent
    const {
      warehouse, hub, route, date,
    } = this.state;
    if (route && route.id) {
      return this.downloadCSVPickListByRoute(route.id, date);
    } if (hub && hub.id) {
      return this.downloadCSVPickListByHub(hub.id, date);
    } if (warehouse && warehouse.id) {
      return this.downloadCSVPickListByWarehouse(warehouse.id, date);
    }
  }

  handleDownloadIndentForToday = () => {
    // download indent
    const {
      warehouse, hub, route,
    } = this.state;
    if (route && route.id) {
      return this.downloadCSVPickListByRoute(route.id, null);
    } if (hub && hub.id) {
      return this.downloadCSVPickListByHub(hub.id, null);
    } if (warehouse && warehouse.id) {
      return this.downloadCSVPickListByWarehouse(warehouse.id, null);
    }
  }

  handleDownload = (url, filename) => {
    apiCall.downloadFile(url, filename);
  }


  renderHubView = () => {
    const {
      warehouse: { id } = {},
      hub: { id: hubId, name: hubName } = {},
      hubList = [],
    } = this.state;
    return id && hubList.length ? (
      <div style={{ marginLeft: 40 }}>
        <MaterialSelect
          value={hubId}
          onChange={this.handleHubChange}
          inputProps={{
            name: 'hubId',
            id: 'select-destination',
          }}
          style={{ minWidth: 150 }}
          fullWidth
        >
          {hubList.map(hub => (
            <MenuItem key={hub.id} value={hub.id}>
              {hub.name}
            </MenuItem>
          ))}
        </MaterialSelect>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">
          Hub
        </InputLabel>
      </div>
    ) : (
      <TextField
        margin="dense"
        id="input-with-icon-textfield"
        value={hubName || ''}
        name="hubName"
        fullWidth
        onChange={() => {}}
        onClick={() => this.openUserSelector('Hub')}
        helperText="Hub"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <Search color="secondary" />
            </InputAdornment>
          ),
        }}
        style={{ marginLeft: 40 }}
      />
    );
  };

  renderRouterView = () => {
    const {
      hub: { id } = {},
      route: { id: routeId, name: routeName } = {},
      routeList = [],
    } = this.state;
    return id && routeList.length ? (
      <div style={{ marginLeft: 40 }}>
        <MaterialSelect
          value={routeId}
          onChange={this.handleRouteChange}
          inputProps={{
            name: 'routeId',
            id: 'select-destination',
          }}
          style={{ minWidth: 150 }}
          fullWidth
        >
          {routeList.map(route => (
            <MenuItem key={route.id} value={route.id}>
              {route.name}
            </MenuItem>
          ))}
        </MaterialSelect>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">
          Route
        </InputLabel>
      </div>
    ) : (
      <TextField
        margin="dense"
        id="input-with-icon-textfield"
        value={routeName || ''}
        name="routeName"
        fullWidth
        onChange={() => {}}
        onClick={this.openRouteSelector}
        helperText="Route"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <Search color="secondary" />
            </InputAdornment>
          ),
        }}
        style={{ marginLeft: 40 }}
      />
    );
  };

  renderProductCatalogs = () => {
    const { catalog, warehouse, reRenderCatalog } = this.state;
    return (
      <div style={{ marginRight: 20, marginTop: 5, minWidth: 250 }}>
        <CatalogSelector 
          onSelect={this.handleCatalogChange}
          selected={catalog.id ? catalog : null}
          warehouseId={!isEmpty(warehouse) ? warehouse.id : ''}
          key={reRenderCatalog}
        />
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">
            Product Catalog
        </InputLabel>
      </div>
    );
  }


  render() {
    const {
      warehouse: { id: warehouseId } = {},
      brand: { name: brandName } = {},
      userType,
      openUserSelector,
      openRouteSelector,
      openBrandSelector,
      warehouseList = [],
    } = this.state;
    return (
      <div>
        <NavBar />
        <UserSelection
          userType={userType}
          openSearchBox={openUserSelector}
          handleClose={this.handleClose}
          selectUser={this.selectUser}
        />
        <StaticRouteSelection
          openSearchBox={openRouteSelector}
          handleClose={this.handleRouteSelectorClose}
          selectStaticRoute={this.selectStaticRoute}
        />
        <BrandSelection
          openSearchBox={openBrandSelector}
          handleClose={this.handleBrandSelectorClose}
          selectBrand={this.selectBrand}
        />
        <div style={styles.wrapper}>
          <Typography variant="title" color="inherit">
            Purchase List
          </Typography>
          <div style={styles.container}>
            <div style={styles.topContainer}>
              <div>
                <MaterialSelect
                  value={warehouseId}
                  onChange={this.handleWarehouseChange}
                  inputProps={{
                    name: 'warehouseId',
                    id: 'select-destination',
                  }}
                  style={{ minWidth: 100, maxWidth: 150, width: 150 }}
                  fullWidth
                >
                  {warehouseList.map(warehouse => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </MaterialSelect>
                <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">
                  Warehouse
                </InputLabel>
              </div>
              {this.renderHubView()}
              {this.renderRouterView()}
              <TextField
                margin="dense"
                id="input-with-icon-textfield"
                value={brandName || ''}
                name="brandName"
                fullWidth
                onChange={() => {}}
                onClick={this.openBrandSearch}
                helperText="Brand"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search color="secondary" />
                    </InputAdornment>
                  ),
                }}
                style={{ marginLeft: 40 }}
              />
            </div>
            <div style={styles.datePicker}>
              {this.renderProductCatalogs()}
              <TextField
                id="date"
                label="Select Date"
                type="date"
                value={this.state.date}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.setDate}
              />
            </div>
            <div>
              <Button size="small" variant="contained" color="secondary" onClick={this.clearData}>
                Clear All
              </Button>
            </div>
            <div style={styles.bottomContainer}>
              <div style={{ color: 'white' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleDownload(this.getPickList(), 'PurchaseIndent.pdf')}
                >Download
                </Button>
              </div>
              <div style={{
                  marginLeft: 5,
                  color: 'white',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleDownload(this.downloadAll(), 'PurchaseIndent.pdf')}
                >Download All
                </Button>
              </div>
              <div style={{
                  marginLeft: 5,
                  color: 'white',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleDownload(this.handleDownloadIndent(), 'PurchaseIndent.csv')}
                > Download Indent
                </Button>
              </div>
              <div style={{
                  marginLeft: 5,
                  color: 'white',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.downloadAllHubsZip}
                > Download All (CSV)
                </Button>
              </div>
            </div>
            <div style={styles.bottomContainer}>
              <div style={{
                  marginLeft: 5,
                  color: 'white',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleDownload(this.handleDownloadIndentForToday(), 'PurchaseIndentForToday.csv')}
                >Download Indent for Next Deliveries
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PurchaseIndentComponent;
