import React, { Component } from 'react'
import PropTypes from 'prop-types';
import apiCall, { getProductItemQuantity } from "../api/NetworkHandler";

class StockInventoryQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: ''
    }
  }

  componentDidMount() {
    this.fetchQuantity();
  }

  fetchQuantity = async () => {
    const { warehouseId, productItemId } = this.props;
    try {
      const response = await apiCall.sendRequest('get', getProductItemQuantity(warehouseId, productItemId));
      const { data: { data } } = response;
      const productItem = data[0];
      this.setState({ quantity: productItem ? productItem.quantity : 0 })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { quantity } = this.state;
    return (
      <React.Fragment>
        {quantity}
      </React.Fragment>
    )
  }
}

StockInventoryQuantity.propTypes = {
  productItemId: PropTypes.number.isRequired,
};

export default StockInventoryQuantity;