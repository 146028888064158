import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Paper } from '@material-ui/core/';
import styles from './styles';

class CustomerList extends Component {
  render() {
    const { data, query, searchItemClicked, path } = this.props;
    if (!isEmpty(data)) {
      return (
        <div>
          <h4 style={styles.header}>
            Search: <span style={styles.q}>{query}</span>
          </h4>
          {data.map(el => (
            <Paper style={styles.content} key={el.customerId}>
              <Link
                to={`/${path}/customer/${el.customerId}`}
                style={styles.link}
                onClick={searchItemClicked}
              >
                <div style={styles.item}>
                  <div style={styles.id}>{el.customerId}</div>
                  <div>
                    <div style={styles.name}>{el.name}</div>
                    <div style={styles.info}>
                      <span style={styles.infoItem}>{el.mobile}</span>
                      <span style={styles.infoItem}>{el.email}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Paper>
          ))}
        </div>
      );
    }
    return (
      <div>
        <h4 style={styles.header}>
          Sorry, no results found matching your search for: <span style={styles.errorQ}>{query}</span>
        </h4>
      </div>
    );
  }
}

CustomerList.propTypes = {
  data: PropTypes.instanceOf(Array),
  query: PropTypes.string,
  searchItemClicked: PropTypes.func,
  path: PropTypes.string,
};

export default CustomerList;
