import moment from 'moment';


export const getOfferStatus = (startTime, endTime) => {
    const today = new Date();
    const calculatedStartTime = getOfferItemStartDate();
    const tomorrow = moment(calculatedStartTime).add(1, 'days').format("YYYY-MM-DD");
    let status = 'ongoing';
    let color = 'success';
    if (moment(endTime).isSameOrBefore(calculatedStartTime, 'days')) {
        status = 'completed';
        color = 'removed';
    }
    else if (moment(startTime).isAfter(tomorrow, 'days')) {
        status = 'upcoming';
        color = 'inprogress';
    }
    return { status, color };
};

export const getOfferItemStartDate = () => {
    let currDate = moment().format("YYYY-MM-DD");
    let currTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let maxAllowedTime = moment('06:30:00', "HH:mm:ss");
    
    if (moment(currTime).isBefore(maxAllowedTime)) {
        return moment(currDate).subtract(1, 'days').format("YYYY-MM-DDT00:00:00");
    } else {
        return moment(currDate).format("YYYY-MM-DDT00:00:00");
    }
};