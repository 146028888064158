import apiCall, { getVendorLocationById } from '../../../api/NetworkHandler';


const calculateMaxCostFromMarginPercent = (mrp, tax, marginType, marginPercent, sellingPrice) => {
  if (marginType === 'REGULAR') {
    // const mrpExclusiveOfTax = mrp/(1 + (tax/100));
    // const calculatedSellingPrice = mrpExclusiveOfTax - (mrpExclusiveOfTax * (marginPercent / 100));
    const calculatedSellingPrice = (mrp * (1 - (marginPercent / 100))) / (1 + (tax / 100));
    return calculatedSellingPrice.toFixed(2);
  }
  return sellingPrice.toFixed(2);
};

const fetchVendorLocationDataByLocationId = async (locationId, callback) => {
  try {
    const response = await apiCall.sendRequest('get', getVendorLocationById(locationId));
    const { data } = response;
    callback(data, null);
  } catch (err) {
    callback(null, err);
  }
};


export {
  calculateMaxCostFromMarginPercent,
  fetchVendorLocationDataByLocationId
};
