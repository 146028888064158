import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../../../components/Core/SearchAndSelect';
import apiCall, { getSurveyListByParams } from '../../../api/NetworkHandler';

function SurveyQuestionSelector(props) {
    const { selected, onSelect } = props;

    const onSurveyQuestionLoad = async (searchTerm) => {
        const filters = { question: searchTerm };
        const response = await apiCall.sendRequest('get', getSurveyListByParams(filters));
        const { data: { data: { content = [] } = {} } = {} } = response;
        if (content.length > 0) {
            return content.map(el => {
                return {
                    label: el.question,
                    value: el.question,
                }
            });
        } else {
            return [{
                label: searchTerm,
                value: searchTerm,
            }]
        }
    };

    return (
        <SearchAndSelect
            onLoad={onSurveyQuestionLoad}
            selected={selected}
            onSelect={onSelect}
        />
    );
}

SurveyQuestionSelector.propTypes = {
    /**
     * value should be an object {value: String, label: String}
     */
    selected: PropTypes.instanceOf(Object),
    /**
     * Triggered on option selection
     */
    onSelect: PropTypes.func.isRequired,
};

SurveyQuestionSelector.defaultProps = {
    selected: null
};

export default SurveyQuestionSelector;
