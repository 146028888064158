import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import BASE_URL from '../../../api/config';
import Typography from '@material-ui/core/Typography';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import apiCall, { getAllDealOfTheDay, updateDealOfTheDay } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import RegionSelector from '../../../components/Region/Selector';
import ProductItemSelector from '../../../components/ProductItem/Selector';
import moment from 'moment';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  }
}

class NewDealOfTheDayProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
      selectedRegion: null,
      postDeal: {
        productItem: null,
        sortId: '',
        active: true,
        startTime: null,
        endTime: null,
      },
      patchDeal: {},
      error: {},
      errorMessage: '',
    }
  }

  componentDidMount() {
    const { deal } = this.props;
    if (deal) {
      this.setState({
        postDeal: deal,
      })
    }
  }

  onNewProductSelect = (selectedProduct) => {
    this.setState(prevState => {
      const { postDeal } = prevState;

      return {
        selectedProduct,
        postDeal: {
          ...postDeal,
          productItem: selectedProduct ? `${BASE_URL.BASE_URL}/api/v1/product-item/${selectedProduct.value}` : null,
        }
      }
    })
  }

  onRegionChange = (selectedRegion) => {
    this.setState(prevState => {
      const { postDeal, patchDeal } = prevState;

      return {
        selectedRegion: null,
        selectedProduct: null,
        postDeal: {
          ...postDeal,
          region: selectedRegion ? `${BASE_URL.BASE_URL}/api/v1/regions/${selectedRegion.value}` : null,
        },
        patchDeal: {
          ...patchDeal,
          region: selectedRegion ? `${BASE_URL.BASE_URL}/api/v1/regions/${selectedRegion.value}` : null,
        }
      }
    }, () => { this.setState({ selectedRegion }) })
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => {
      const { postDeal, patchDeal } = prevState;

      return {
        postDeal: {
          ...postDeal,
          [name]: value,
        },
        patchDeal: {
          ...patchDeal,
          [name]: value,
        }
      }
    })
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  isPropertyValid = (property, value) => {
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;
    const { postDeal } = this.state;

    const validateProperties = ["sortId"];

    if (!this.props.deal) {
      validateProperties.push("region", "productItem");
    }

    validateProperties.forEach(property => {
      if (!this.isPropertyValid(property, postDeal[property])) {
        isFormValid = false;
      }
    })

    return isFormValid;
  }

  getFormatedDateTime = (dateTime) => {
    if(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  onSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { postDeal, patchDeal } = this.state;
    const { onClose, onRefresh } = this.props;

    try {
      if (!postDeal.id) {
        postDeal.startTime = this.getFormatedDateTime(postDeal.startTime);
        postDeal.endTime = this.getFormatedDateTime(postDeal.endTime);
        await apiCall.sendRequest("post", getAllDealOfTheDay, postDeal);
      } else {
        patchDeal.startTime = this.getFormatedDateTime(postDeal.startTime);
        patchDeal.endTime = this.getFormatedDateTime(postDeal.endTime);
        await apiCall.sendRequest("patch", updateDealOfTheDay(postDeal.productItem.id), patchDeal);
      }
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }
  }

  render() {
    const { classes, onClose } = this.props;
    const { postDeal, selectedProduct, error, errorMessage, selectedRegion } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{postDeal.id ? "Edit Deal" : "Add New Product"}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
                <Button type="submit" variant="contained" color="secondary" onClick={this.onSave}>{postDeal.id ? "Save" : "Add"}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            <SectionMessage appearance="error">{errorMessage}</SectionMessage>
          </ColMod>
        </Row>}
        <Row md={12}>
          {!postDeal.id && <ColMod md={6}>
            <Typography variant="caption" className={error.region ? classes.error : null}> Region * </Typography>
            <RegionSelector
              onSelect={this.onRegionChange}
              selected={selectedRegion}
            />
          </ColMod>}
          <ColMod md={6}>
            <TextField
              type="number"
              label="Sort Id"
              name="sortId"
              value={postDeal.sortId || ''}
              onChange={this.onChange}
              error={error.sortId}
              required
              fullWidth
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> Status </Typography>
            <RadioGroup onChange={this.onChange} name="active" value={(postDeal.active + '') || 'true'} row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </ColMod>
          {!postDeal.id && <ColMod md={6}>
            <Typography variant="caption" className={error.productItem ? classes.error : null}> Product Item * </Typography>
            {!selectedRegion && <TextField
              value="Please select region first"
              fullWidth
              disabled
            />}
            {selectedRegion && <ProductItemSelector
              selected={selectedProduct}
              onSelect={this.onNewProductSelect}
              regionId={selectedRegion ? selectedRegion.value : null}
            />}
          </ColMod>}
          <ColMod md={6}>
            <TextField
              type="datetime-local"
              label="Start Time"
              name="startTime"
              value={postDeal.startTime ? moment(postDeal.startTime).format("YYYY-MM-DDTHH:mm") : ''}
              onChange={this.onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              type="datetime-local"
              label="End Time"
              name="endTime"
              value={postDeal.endTime ? moment(postDeal.endTime).format("YYYY-MM-DDTHH:mm") : ''}
              onChange={this.onChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(NewDealOfTheDayProduct);
