import React, { Component } from 'react';
import {
  Typography,
  Button,
  Tooltip
} from '@material-ui/core';
import NavBar from '../../../components/NavBar';
import apiCall, { getAllUserGroups, createUserGroups, updateUserGroups } from '../../../api/NetworkHandler';
import { debounce, isEmpty, trim } from 'lodash';
import { Input as AntdInput, Spin, Modal } from 'antd';
import GroupList from '../components/GroupList';
import { LoadingOutlined } from '@ant-design/icons';
import CreateIcon from '@material-ui/icons/Create';
import GroupAssociation from '../components/GroupAssociation';
import utils from '../../../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;


const { Search } = AntdInput;

const styles = {
  tableConatiner: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: "row",
    width: '100%',
    background: '#f0f0f0',
    height: window.innerHeight * 0.1,
    alignItems: 'center'
  },
  title: {
    marginLeft: '30px'
  },
  searchBar: {
    width: window.innerWidth * 0.5,
    marginLeft: '10%'
  },
  createButton: {
    marginLeft: "10%"
  },
};

var versionDict = {
  "userRequestId": 0,
};

class GroupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGroups: [],
      filters: {
        query: '',
      },
      count: 0,
      page: 0,
      selectedUserGroup: {},
      rowsPerPage: 10,
      isNoData: false,
      loading: false,
      openUserGroupModal: false,
      groupData: {
        name: ''
      },
      openAssociateModal: false
    };
  }

  componentDidMount() {
    this.fetchAllUserRoles();
  }

  onSearch = (searchText) => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: searchText,
      },
    }), () => {
      this.fetchAllUserRoles();
    });
  };

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState,
    });
  };

  fetchAllUserRoles = async () => {
    const { filters: { query = '' }, page, rowsPerPage: size } = this.state;
    this.toggleLoading(true);
    try {
      versionDict.userRequestId += 1;
      let prevRequestId = versionDict.userRequestId;
      const response = await apiCall.sendRequest('get', getAllUserGroups(query, page, size));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (prevRequestId === versionDict.userRequestId) {
        this.setState({
          allGroups: content,
          count: totalElements,
          isNoData: !!(content.length == 0),
        });
      }
    } catch (err) {
      this.setState({
        allGroups: [],
        count: 0,
        isNoData: true
      });
    }
    this.toggleLoading(false);
  };

  handleSaveUserGroup = debounce(async () => {
    const { groupData, selectedUserGroup } = this.state;
    groupData.name = trim(groupData.name);
    try {
      if (isEmpty(selectedUserGroup)) {
        await apiCall.sendRequest('post', createUserGroups, groupData);
      } else {
        await apiCall.sendRequest('put', updateUserGroups(selectedUserGroup.id), groupData);
      }
      this.handleCloseUserGroup();
      this.fetchAllUserRoles();
    } catch (err) {

    }
  }, 500);

  handleChangeGroupName = (event) => {
    const { name, value } = event.target;
    const { groupData } = this.state;
    this.setState({
      groupData: {
        ...groupData,
        [name]: value
      }
    });
  };

  renderUserGroupCreationModal = () => {
    const { openUserGroupModal, groupData: { name = '' }, selectedUserGroup } = this.state;
    return (
      <Modal
        title={`${isEmpty(selectedUserGroup) ? 'Create' : 'Edit'} User Group`}
        centered
        visible={openUserGroupModal}
        onOk={this.handleSaveUserGroup}
        onCancel={this.handleCloseUserGroup}
        okText="Save"
        okButtonProps={{ disabled: !name }}
      >
        <div>
          <Typography variant="caption">Group Name</Typography>
          <AntdInput
            placeholder="type group name..."
            onChange={this.handleChangeGroupName}
            name="name"
            value={name}
            allowClear
            autoComplete='off'
          />
          {!name && <Typography variant="caption" color="error">Please enter group name to create</Typography>}
        </div>
      </Modal>
    );
  };

  handleOpenUserGroup = () => {
    this.setState({
      openUserGroupModal: true
    });
  };

  handleCloseUserGroup = () => {
    const { groupData } = this.state;
    this.setState({
      openUserGroupModal: false,
      selectedUserGroup: {},
      groupData: {
        ...groupData,
        name: ''
      }
    });
  };

  renderSearchBar = () => {
    return (
      <div style={styles.searchContainer}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">SCM Groups</Typography>
        </div>
        <div style={styles.searchBar}>
          <Typography variant="caption">Search Group</Typography>
          <Search
            placeholder="search by group name"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={this.onSearch}
          />
        </div>
        {canView(['ADMIN']) &&
          <div style={styles.createButton}>
            <Tooltip title="Create Group">
              <Button onClick={this.handleOpenUserGroup} variant="fab" mini color="secondary" aria-label="add">
                <CreateIcon />
              </Button>
            </Tooltip>
          </div>}
      </div>
    );
  };

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => { this.fetchAllUserRoles() });
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    const { page = 0 } = this.state;
    this.setState({
      rowsPerPage
    }, () => { this.fetchAllUserRoles() });
  };

  handleEditUserGroup = (selectedUserGroup) => {
    const { groupData } = this.state;
    this.setState({
      selectedUserGroup,
      groupData: {
        ...groupData,
        name: selectedUserGroup.name
      },
    }, () => this.handleOpenUserGroup());
  };

  handleAssociate = (selectedUserGroup) => {
    this.setState({
      selectedUserGroup
    }, () => this.toggleOpenAssociateModal(true));
  };

  toggleOpenAssociateModal = (modalState) => {
    this.setState({
      openAssociateModal: modalState
    });
  };

  render() {
    const {
      allGroups,
      count,
      page,
      rowsPerPage,
      isNoData,
      loading,
      openAssociateModal,
      selectedUserGroup
    } = this.state;

    return (
      <div>
        <NavBar />
        {this.renderSearchBar()}
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
          <div style={styles.tableConatiner}>
            <div style={styles.paperLeft}>
              <GroupList
                data={allGroups}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                onEditGroupName={this.handleEditUserGroup}
                onAssociate={this.handleAssociate}
                isNoData={isNoData}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
          {this.renderUserGroupCreationModal()}
          {openAssociateModal &&
            <GroupAssociation
              open={openAssociateModal}
              onClose={() => this.toggleOpenAssociateModal(false)}
              data={selectedUserGroup}
            />
          }
        </Spin>
      </div>
    );
  }
}


export default GroupContainer;
