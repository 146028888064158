import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';

const columnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Order ID',
  },
  {
    id: 'gateway',
    numeric: false,
    disablePadding: true,
    label: 'PAYMENT MODE',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Transaction Date',
  },
  {
    id: 'transactionId',
    numeric: false,
    disablePadding: true,
    label: 'Transaction Id',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: true,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: true,
    label: 'Additional Notes',
  },

];

class TransationList extends Component {
  render() {
    const { data = [] } = this.props;
    return (
      <Table
        aria-labelledby="tableTitle"
      >
        <TableHeader columns={columnData} />
        <TableBody>
          {data.map((eachRow) => {
              const {
                gateway,
                status,
                notes,
                amount,
                id: orderId,
                gatewayTransactionTime,
                transactionId,
              } = eachRow;
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <TableCell scope="row">
                    <div>{orderId}</div>
                  </TableCell>
                  <TableCell style={{ color: Colors.primary }} scope="row" >
                    {gateway}
                  </TableCell>
                  <TableCell style={{ color: Colors.primary }} scope="row" >
                    {gatewayTransactionTime}
                  </TableCell>
                  <TableCell scope="row" >
                    {transactionId}
                  </TableCell>
                  <TableCell scope="row">
                    <div>{amount}</div>
                  </TableCell>
                  <TableCell scope="row">
                    <div>{status}</div>
                  </TableCell>
                  <TableCell scope="row">
                    <div>{notes}</div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  }
}

TransationList.propTypes = {
  data: PropTypes.instanceOf(Array),
};

export default TransationList;
