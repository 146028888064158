import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Typography } from '@material-ui/core';

const MyMapComponent = withScriptjs(withGoogleMap(props =>
  (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
  )));

class DeliveryOrderImage extends Component {
  render() {
    const { imageUrl, latitude, longitude } = this.props;
    const showMap = false;
    return (
      <div style={{ display: 'flex', paddingTop: 80 }}>
        <div style={{ flex: 1, margin: 10 }}>
          <Typography variant="display1" gutterBottom headlineMapping={{ display1: 'h6' }}>
            <span>
                Images
            </span>
          </Typography>
          <img src={imageUrl} alt="deliveryImage" />
        </div>
        {
            showMap &&
            <div style={{ flex: 1, margin: 10 }}>
              <Typography variant="display1" gutterBottom headlineMapping={{ display1: 'h6' }}>
                <span>
                Location
                </span>
              </Typography>
              <MyMapComponent
                isMarkerShown
                lat={latitude}
                lng={longitude}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAKQvG2v5odEuBoH2i_ujPiKzwoOoHtkfQ&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '400px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
              />
            </div>}
      </div>
    );
  }
}

DeliveryOrderImage.propTypes = {
  imageUrl: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default DeliveryOrderImage;
