import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { Paper } from '@material-ui/core';
import './styles.scss';

class AnalysisBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="analysisOverview block">
        <h2>Analysis</h2>
        <Paper className="analysisContent">
          <div className="chartBox">
            <Chart
              chartType="PieChart"
              data={[['Age', 'Weight'], ['a', 12], ['b', 5.5]]}
              options={pieOptions}
              graph_id="PieChart"
              width="100%"
              height="400px"
              legend_toggle
            />
          </div>
          <div className="chartBox">
            <Chart
              width="600px"
              height="400px"
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ['x', 'Dairy', 'FnV'],
                [0, 0, 0],
                [1, 10, 8],
                [2, 23, 13],
                [3, 17, 19],
                [4, 18, 12],
                [5, 9, 34],
                [6, 11, 10],
                [7, 27, 12],
                [8, 33, 15],
                [9, 40, 18],
                [10, 32, 20],
                [11, 35, 22],
              ]}
              options={{
                hAxis: {
                  title: 'Time',
                },
                vAxis: {
                  title: 'Stock',
                },
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

AnalysisBlock.propTypes = {};

export default AnalysisBlock;

const pieOptions = {
  title: '',
  pieHole: 0.6,
  slices: [
    {
      color: '#2BB673',
    },
    {
      color: '#d91e48',
    },
    {
      color: '#007fad',
    },
    {
      color: '#e9a227',
    },
  ],
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: {
      color: '233238',
      fontSize: 14,
    },
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    left: 0,
    top: 0,
    width: '100%',
    height: '80%',
  },
  fontName: 'Roboto',
};
