import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import parallel from 'async-await-parallel';
import {
    Typography,
    Button,
    Paper,
    InputAdornment,
    TextField
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import NavBar from '../../../../components/NavBar';
import DWTable from '../../../Commons/components/DwTable';
import apiCall, {
    searchAllDeliveryBoys,
    fetchDeliveryBoys,
    createHubIdPath,
    findHubPath,
    hubSegregator,
    searchHubSegregator,
 } from "../../../../api/NetworkHandler";
import styles from './styles';

const HUB_TABLE_CONFIG = {
    table: [
        {
            label: "Hub Name",
            pick: "name",
        },
        {
            label: "WareHouse",
            pick: "warehouseName",
        },
        {
            label: "Incharge Name",
            pick: "inchargeName",
        }
    ]
};
const BOYS_TABLE_CONFIG = {
    table: [
        {
            label: "Name",
            pick: "name",
        },
        {
            label: "Mobile",
            pick: "mobile",
        },
        {
            label: "Status",
            pick: "active",
            check: (v)=>{
                return v? "ACTIVE": "INACTIVE";
            }
        },
        {
            label: "Action",
        }
    ]
};

class AssignSegregator extends Component {
  constructor(props) {
    super(props);
    this.state = {
        hubData: [],
        hubTotal: 0,
        loadingHubs: false,
        hubCurrentPage: 0,
        hubPageLimit: 20,
        hubQuery: '',
        hubSearchData: [],
        slelectedHub: {},
        hubMappingData: {},
        boysData: [],
        loadingboys: false,
        boysQuery: '',
        boysSearchData: [],
        slelectedBoy: {}, 
        boyAssigned: false,
    };
  };

  componentDidMount() {
    this.fetchHubs();
  };

  onHubSearch = (e) => {
    this.setState({hubQuery: e.target.value}, this.fetchHubs);
  };

  onHubSearchClear = (e) => {
    this.setState({hubQuery: ''});
  };

  onBoysSearch = (e) => {
    this.setState({boysQuery: e.target.value}, this.fetchBoys);
  };

  onBoysSearchClear = () => {
    this.setState({boysQuery: ''});
  }

  onHubPaginationChange = (f) => {
    if(f.page){
        this.setState({
            hubCurrentPage: f.page,
        }, this.fetchHubs);    
    }
    if(f.limit){
        this.setState({
            hubCurrentPage: 0,
            hubPageLimit: f.limit,
        }, this.fetchHubs); 
    }
  };

  onSelectHub = (obj) => {
    this.setState({slelectedHub: obj}, this.fetchBoys);
  };

  onSelectBoy = (obj) => {
    this.setState({slelectedBoy: obj});
  };

  onRemoveBoy = (obj) => {
    const { hubMappingData } = this.state;
    const mapId = hubMappingData.filter(el=>el.segregatorId===obj.id)[0].id;
    apiCall
        .sendRequest('delete', `${hubSegregator}/${mapId}`)
        .then(() => {
            this.fetchBoys();
        })
        .catch((err) => {
            console.log(err);            
        });
  };

  getHubPath = () => {
    const { hubCurrentPage, hubPageLimit } = this.state;
    return `${createHubIdPath}?page=${hubCurrentPage}&size=${hubPageLimit}`;
  };

  getSegTableActions = () => {
      return !isEmpty(this.state.boysQuery) ? 
                [
                    {
                        name: "Select",
                        event: this.onSelectBoy,
                    },
                ] : 
                [
                    {
                        name: "Remove",
                        event: this.onRemoveBoy,
                    },
                ];
  };

  endBoySelection = () => {
    this.setState({boyAssigned: true});
    setTimeout(() => {
        this.setState({
            boyAssigned: false,
            slelectedBoy: {},
            boysQuery: '',
        }, this.fetchBoys);
    }, 1000);
  };

  assignBoySelection = () => {
    const { slelectedBoy, slelectedHub } = this.state;
    const params = {
        hubId: slelectedHub.id,
        segregatorId: slelectedBoy.id,
    };
    apiCall
        .sendRequest('post', hubSegregator, params)
        .then(() => {
            this.endBoySelection();
        })
        .catch((err) => {
          console.log(err);            
        });
  };

  cancelBoySelection = () => {
    this.setState({slelectedBoy: {}});
  };

  clearHubSelection = () => {
    this.setState({
    slelectedHub: {},
    boysData: [],
    loadingboys: false,
    boysQuery: '',
    boysSearchData: [],
    slelectedBoy: {}, 
    boyAssigned: false,
    hubMappingData: {},
    });
  };

  fetchBoys = () => {
    const { boysQuery, slelectedHub } = this.state;
    this.setState({loadingboys: true});
    if(!isEmpty(boysQuery)) {
      apiCall
        .sendRequest('get', searchAllDeliveryBoys(boysQuery))
        .then(({ data }) => {
            const { _embedded } = data;
            let boys = _embedded["delivery-boys"];
            if (boys.length > 10) boys = boys.slice(0, 10);
            this.setState({
                boysSearchData: boys,
                boysQuery,
                loadingboys: false,
            });
        })
        .catch(() => {
            this.setState({
                boysSearchData: [],
                boysQuery,
                loadingboys: false,
            });
        });
    } else {
        const { id } = slelectedHub;
        apiCall
            .sendRequest('get', searchHubSegregator(id))
            .then(({ data }) => {
                this.mapCreateBoys(data);
            })
            .catch((err) => {
                console.log(err);            
            });
    }
  };

  mapCreateBoys = async (obj) => {
    const self = this;
    const { _embedded } = obj;
    const mapping = _embedded["hub-segregator"];
    const boysArr = [];
    if(!isEmpty(mapping)){
        mapping.map(el=>{
            if(!boysArr.includes(el.segregatorId)){
                boysArr.push(el.segregatorId);
            }
            return null;
        });
        parallel(boysArr.map(el => {
            return () => { 
                return apiCall.sendRequest('get', `${fetchDeliveryBoys}/${el}`);               
            };
        }, boysArr.length))
        .then((data) => {
            self.setState({
                boysData: data.map(el=>el.data),
                loadingboys: false,
                hubMappingData: mapping,
            });
        })
        .catch(err => {
            console.log(err);
        });
    }else{
        this.setState({
            boysData: [],
            loadingboys: false,
            hubMappingData: [],
        });
    }
  };

  fetchHubs = () => {
    const { hubQuery } = this.state;
    this.setState({loadingHubs: true});
    if(!isEmpty(hubQuery)) {
      apiCall
        .sendRequest('get', findHubPath(hubQuery))
        .then(({ data }) => {
            const { _embedded } = data;
            this.setState({
                hubSearchData: _embedded.hub,
                hubQuery,
                loadingHubs: false,
            });
        })
        .catch((err) => {
            this.setState({
                hubSearchData: [],
                hubQuery,
                loadingHubs: false,
            });
        });
    } else {
        apiCall
        .sendRequest('get', this.getHubPath())
        .then(({ data }) => {
          const { _embedded } = data;
          this.setState({
            hubData: _embedded.hub,
            hubTotal: data.page.totalElements,
            loadingHubs: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  renderAssignedSegTable = () => {
    const { boysData, loadingboys, boysQuery, boysSearchData, slelectedBoy } = this.state;  
    if(loadingboys){
        return(
            <Paper style={styles.loading}>
                LOADING SEGREGATORS...
            </Paper>
        );
    }
    if(!isEmpty(boysQuery)) {
        if(!isEmpty(boysSearchData)) {
            return(
                <DWTable
                    config={BOYS_TABLE_CONFIG}
                    data={boysSearchData}
                    selectedRow={slelectedBoy}
                    actions={this.getSegTableActions()}
                    pagination={false}
                />
            );
        } 
        if(isEmpty(boysSearchData)){
            return(
                <Paper style={styles.loading}>
                    NO SEARCH RESULTS 
                </Paper>
            ); 
        }
    }else{
        if(!isEmpty(boysData)) {
            return(
                    <DWTable
                        config={BOYS_TABLE_CONFIG}
                        data={boysData}
                        actions={this.getSegTableActions()}
                        onChange={this.onHubPaginationChange}
                        pagination={false}
                    />
            );
        }
        if(isEmpty(boysData)) {
            return(
                <Paper style={styles.loading}>
                    NO ASSIGNED SEGREGATORS 
                </Paper>
            );  
        }; 
    }
    return null;
  };

  renderHubTable = () => {
    const { hubData, hubTotal, loadingHubs, hubQuery, hubSearchData, slelectedHub } = this.state;  
    if(loadingHubs){
        return(
            <Paper style={styles.loading}>
                LOADING HUBS...
            </Paper>
        );
    }
    if(!isEmpty(hubQuery)) {
        if(!isEmpty(hubSearchData))
            return(
                <DWTable
                    config={HUB_TABLE_CONFIG}
                    data={hubSearchData}
                    onRowClick={this.onSelectHub}
                    selectedRow={slelectedHub}
                    pagination={false}
                />
            );
        if(isEmpty(hubSearchData))
            return(
                <Paper style={styles.loading}>
                    NO SEARCH RESULTS 
                </Paper>
            ); 
    }else{
        if(!isEmpty(hubData))
            return(
                <DWTable
                    config={HUB_TABLE_CONFIG}
                    data={hubData}
                    onChange={this.onHubPaginationChange}
                    onRowClick={this.onSelectHub}
                    selectedRow={slelectedHub}
                    total={hubTotal}
                    pagination={false}
                />
            );
        if(isEmpty(hubData))
            return(
                <Paper style={styles.loading}>
                    NO HUBS FOUND
                </Paper>
            );   
    };
    return null;
  };

  render() { 
    const { slelectedHub={}, boysQuery, hubQuery, slelectedBoy, boyAssigned } = this.state;
    const { warehouseName, name} = slelectedHub;
    return (
      <div>
        <NavBar />
        <div style={styles.content}>
            <div style={styles.mainContent}>
                <Typography style={styles.header}>
                    Assign Segregators
                </Typography> 
                <TextField 
                    label="Search For Hubs" 
                    style={styles.searchBar} 
                    value={hubQuery} 
                    onChange={this.onHubSearch} 
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <Search /> </InputAdornment> ), 
                    endAdornment:( <InputAdornment position="start" onClick={this.onHubSearchClear}> <Clear /> </InputAdornment> ) }} 
                />
                {this.renderHubTable()}
            </div>
            {!isEmpty(slelectedHub) &&
                <div style={styles.actionContent}>
                    <div style={styles.actionBox}>
                        <Clear style={styles.closeAssignBox} onClick={this.clearHubSelection} />
                        <Typography style={styles.header}>
                            HUB: <b style={styles.wname}>{name} <small>({warehouseName})</small></b>
                        </Typography> 
                        {isEmpty(slelectedBoy) &&
                            <div style={styles.assignSearch}>
                                <TextField 
                                    label="Search For Segregators To Assign" 
                                    style={styles.searchBar} 
                                    value={boysQuery} 
                                    onChange={this.onBoysSearch} 
                                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <Search /> </InputAdornment> ), 
                                    endAdornment:( <InputAdornment position="start" onClick={this.onBoysSearchClear}> <Clear /> </InputAdornment> ) }} 
                                />
                            </div>
                        }
                        {!isEmpty(slelectedBoy) &&
                            <div style={styles.assignNew}>
                                <div style={styles.assignObj}>
                                    {slelectedBoy.name}
                                </div>
                                {boyAssigned && 
                                    <div style={styles.assigned}>ASSIGNING...</div>
                                }
                                {!boyAssigned &&
                                    <Button onClick={this.cancelBoySelection} style={styles.btn} color="primary" variant="outlined">Cancel</Button>
                                }
                                {!boyAssigned &&
                                    <Button onClick={this.assignBoySelection} style={styles.btn} color="primary" variant="contained">Assign</Button>
                                }
                            </div>
                        }
                        <div style={styles.assignedSeg}>
                            <Typography style={styles.header}>
                                {!isEmpty(boysQuery) ? "Select Segregators": "Assigned Segregators"}
                            </Typography>
                            {this.renderAssignedSegTable()}
                        </div>
                    </div>
                </div>        
            }
        </div> 
      </div>
    );
  };
}

export default AssignSegregator;

