import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { MenuOutlined } from '@ant-design/icons';


class SortableList extends Component {
    constructor(props) {
        super(props);
        const { widgets: items } = this.props;
        this.state = {
            items,
            isDragStarted: false,
        };
    }
    
    handleDrop = ({ removedIndex, addedIndex }) => {
        const { items } = this.state;
        const { handleReorder } = this.props;
        let reorderedItems = arrayMove(items, removedIndex, addedIndex);
        this.setState({
            items: reorderedItems,
        }, () => handleReorder(reorderedItems));
    };

    handleDragStart = () => {
        this.setState({
            isDragStarted: true,
        });
    };

    handleDragEnd = () => {
        this.setState({
            isDragStarted: false,
        });
    };

    render() {
        const { items, isDragStarted } = this.state;
        return (
            <List component="nav">
                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={this.handleDrop} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
                    {items.map(({ id, title }, index) => (
                        <Draggable key={id}>
                            <ListItem style={{ backgroundColor: !isDragStarted ? "rgb(215,215,215)" : "rgb(255,255,255)"}}>
                                <ListItemText primary={title} />
                                <ListItemSecondaryAction>
                                    <ListItemIcon className="drag-handle">
                                        {/* <DragHandleIcon /> */}
                                        <MenuOutlined style={{ cursor: 'grab', color: '#000' }} />
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Draggable>
                    ))}
                </Container>
            </List>
        );
    };
};


class AppWidgetManageOrderSequenceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    };

    handleItemReorder = (items) => {
        this.setState({
            items,
            errorText: '',
        });
    };

    handleClickReorder = () => {
        const { items } = this.state;
        const { onReorder } = this.props;
        if (items.length <= 0) {
            this.setState({
                errorText: 'Please reorder list item to proceed'
            });
            return;
        } else {
            this.setState({
                errorText: '',
            });
            onReorder(items);
        }
    };

    render() {
        const { onClose, open, widgets } = this.props;
        const { errorText } = this.state;
        return (
            <Modal
                open={open}
                onClose={onClose}
            >
                <div style={{
                        background: 'white',
                        width: window.innerWidth * 0.65,
                        marginLeft: window.innerHeight * 0.32,
                        height: window.innerHeight * 0.85,
                        marginTop: window.innerHeight * 0.09,
                        marginBottom: window.innerHeight * 0.3,
                        padding: '10px',
                        overflow: 'scroll',
                    }}
                >
                    <Button style={{ float: 'right' }} size="small" onClick={onClose} color="secondary" aria-label="close" variant="outlined">
                        <CloseIcon />
                    </Button>
                    <div>
                        <p style={{ fontSize: '18px', fontWeight: 'bold', paddingTop: '0px', textAlign: 'center' }}> Manage Widget Order Sequence </p>
                    </div>
                    <div style={{ overflowX: 'auto', height: window.innerHeight * 0.7 }}>
                        <SortableList widgets={widgets} handleReorder={this.handleItemReorder} />
                    </div>
                    &nbsp;&nbsp;
                    <span style={{ float: 'left', paddingLeft: '20px', color: 'red' }}>{errorText}</span>
                    <Button style={{ float: 'right', paddingTop: '10px' }} onClick={this.handleClickReorder} color="primary" aria-label="close" variant="raised">
                        Save Order
                    </Button>
                </div>
            </Modal>
        );
    };
};


AppWidgetManageOrderSequenceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AppWidgetManageOrderSequenceModal;
