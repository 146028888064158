import React from 'react';
import PropTypes from "prop-types";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';

function DialogBox(props) {
  const { open, onClose, dialogTitle, dialogMessage, onLeftButtonClick, leftButtonVisible = false, leftButtonText, leftButtonDisable = false, extraMessage = '' } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { minWidth: window.innerWidth * 0.4 } }}
    >
      <DialogTitle style={{ background: 'lightgray', padding: '15px 10px 5px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {dialogTitle}
          {leftButtonVisible && 
            <Button onClick={onLeftButtonClick} color="primary" variant="outlined" autoFocus disabled={leftButtonDisable}>
              {leftButtonText}
            </Button>
          }
        </div>
        {extraMessage && <Typography variant="caption" color="error" style={{ textAlign: 'right' }}>{extraMessage}</Typography>}
      </DialogTitle>
      <DialogContent style={{ padding: '5px 10px 10px' }}>
        <DialogContentText>
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogBox.propTypes = {
  /**
   * dialog will open if true
   */
  open: PropTypes.bool,

  /**
   * Function that handles close dialog
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Title of the dialog
   */
  dialogTitle: PropTypes.string.isRequired,

  /**
   * Message of the dialog
   */
  dialogMessage: PropTypes.string.isRequired,
};

DialogBox.defaultProps = {
  open: false,
  dialogTitle: '',
  dialogMessage: '',
};

export default DialogBox;
