import React from 'react';
import LocalitiesComponent from './LocalitiesComponent';

class LocalitiesContainer extends React.Component {
  render() {
    return (
      <div>
        <LocalitiesComponent />
      </div>
    );
  }
}

export default LocalitiesContainer;
