import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Button,
    TextField,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Avatar
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import apiCall, { searchChoicesByQuery } from "../../../api/NetworkHandler";
import debounce from 'lodash/debounce';
import ChoiceForm from './ChoiceForm';
import Lozenge from '@atlaskit/lozenge';

const styles = {
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    width: '55%',
    height: '85%',
    transition: 'width 2s',
    overflow: 'auto',
  }
}

var versionDict = {
  'choiceRequestId': 0,
}

class ChoiceSelection extends Component {
  constructor(props) {
    super(props);
    const { isChildMounted } = this.props;
    this.state = {
      searchText: '',
      choicesData: [],
      editChoiceData: false,
      selectedData: {},
      isChildMounted,
    };
  }

  componentDidMount() {
    const { isChildMounted } = this.state;
    isChildMounted && this.fetchChoicesData('');
  }

  componentWillUnmount() {
    this.setState({
        isChildMounted: false,
    });
  }

  fetchChoicesData = debounce( async (searchTerm) => {
    try {
      versionDict.choiceRequestId += 1;
      let prevRequestId = versionDict.choiceRequestId;
      const response = await apiCall.sendRequest('get', searchChoicesByQuery(searchTerm));
      const { data: { _embedded: embedded } } = response;
      if (versionDict.choiceRequestId == prevRequestId) {
        this.setState({
            choicesData: embedded['survey-choice']
        });
      }
    } catch (error) {
      this.setState({
          choicesData: []
        });
    }
  }, 750);

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchText: searchTerm });
    this.fetchChoicesData(searchTerm);
  }

  handleClearSearch = () => {
    this.setState({
        searchText: '',
        choicesData: []
    }, () => this.fetchChoicesData(''));
  }

  onEditData = (data) => {
    this.setState({
      editChoiceData: true,
      selectedData: data,
    });
  }

  handleEditCancel = () => {
    this.setState({
      editChoiceData: false,
      selectedData: {},
    }, () => this.fetchChoicesData(''));
  }

  handleAddChoice = () => {
    this.setState({
      editChoiceData: true,
    });
  }

  onclose = () => {
    this.setState({
      choicesData: [],
      searchText: '',
      editChoiceData: false,
    });
  }

  handleSave = () => {
    const { searchText } = this.state;
    this.fetchChoicesData(searchText);
    this.setState({
      editChoiceData: false,
      selectedData: {}
    });
  }

  handleSelectChoice = (choice) => {
    const { selectChoice } = this.props;
    const { handleClose } = this.props;
    selectChoice(choice);
    this.setState({
        choicesData: [],
        searchText: ''
    }, () => handleClose());
  }

  render() {
    const { editChoiceData, selectedData, choicesData } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.openSearchBox}
        onClose={() => { this.props.handleClose(); this.setState({ suggestions: [], searchText: '' }); }}
        disableBackdropClick
      >
        {!editChoiceData ? <div style={styles.container}>
          <Button style={{ float: 'right' }} onClick={() => { this.props.handleClose(); this.onclose() }} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={12}>
                <Row>
                  <ColMod lg={10}>
                    <Typography variant="title">
                      Search Choices
                    </Typography>
                  </ColMod>
                  <ColMod lg={2}>
                    <Button variant="contained" color="secondary" onClick={this.handleAddChoice}>
                      Add
                    </Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={10}>
                    <TextField
                      value={this.state.searchText}
                      onChange={this.handleInputChange}
                      placeholder="Search choices"
                      helperText="Search Choices by name (ex. happy, sad, angry etc.)"
                      fullWidth
                    />
                  </ColMod>
                  <ColMod lg={2}>
                    <Button variant="contained" color="secondary" onClick={this.handleClearSearch}>
                      Clear
                    </Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowY: 'auto', overflowX: 'auto', height: window.innerHeight / 2.35 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row" padding="none">Choice</TableCell>
                            <TableCell component="th" scope="row" padding="none">Icon</TableCell>
                            <TableCell component="th" scope="row" padding="none">Status</TableCell>
                            <TableCell component="th" scope="row" padding="none">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {choicesData.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" padding="none">{data.choice}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{<Avatar alt="choice" src={data.imageUrl} />}</TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Lozenge appearance={data.active ? "success" : "removed"}>{data.active ? "Active" : "Inactive"}</Lozenge>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Button size="small" variant="contained" color="secondary" onClick={() => this.handleSelectChoice(data)}>Select</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button size="small" variant="outlined" color="primary" onClick={() => { this.onEditData(data) }}>Edit</Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div> :
          <div style={styles.container}>
            <ChoiceForm
              choicesData={selectedData}
              handleCancel={this.handleEditCancel}
              onSave={this.handleSave}
            />
          </div>
        }
      </Modal>
    );
  }
}

ChoiceSelection.propTypes = {
  openSearchBox: PropTypes.bool,
  handleClose: PropTypes.func,
  selectGst: PropTypes.func,
};

export default ChoiceSelection;
