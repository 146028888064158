import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import UserSelection from '../../../components/UserSelection';
import { ColMod } from '../../../components/lib';

class EditCustomerComponent extends Component {
  constructor(props) {
    super(props);
    const { address = {} } = props;
    this.state = {
      address,
      openUserSelector: false,
    };
  }

  onChangeValue = (event, id) => {
    const { address } = this.state;
    const val = event.target.value;
    if (id === 'active') {
      address[id] = val === 'ACTIVE';
      this.setState({ address });
    } else if (id === 'ringBell' || id === 'critical' || id === 'dndEnabled' || id === 'dndConsent') {
      address[id] = event.target.checked;
      this.setState({ address });
    } else {
      address[id] = event.target.value;
      this.setState({ address });
    }
  };

  setSelectedData = (data) => {
    const { address, userType } = this.state;
    const { name } = data;
    if (userType === 'Building') {
      address.buildingName = name;
      this.setState({ address, openUserSelector: false, userType: '' });
    } else if (userType === 'Locality') {
      address.localityName = name;
      this.setState({ address, openUserSelector: false, userType: '' });
    } else if (userType === 'City') {
      address.cityName = name;
      this.setState({ address, openUserSelector: false, userType: '' });
    }
  };

  openUserSelector = userType => () => {
    this.setState({
      openUserSelector: true,
      userType,
    });
  };

  handleSelectorClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  };

  generateAddress = () => {
    const { address } = this.state;
    const {
      floor,
      houseNumber,
      buildingName,
      landmark,
      blockName,
      streetDetails,
      subLocalityName,
      localityName,
      cityName,
      pincode,
    } = address;
    return `${floor ? `${floor},` : ''}${houseNumber ? ` ${houseNumber},` : ''}
            ${buildingName ? ` ${buildingName},` : ''}${landmark ? ` ${landmark},` : ''}
            ${blockName ? ` ${blockName},` : ''}${streetDetails ? ` ${streetDetails},` : ''}
            ${subLocalityName ? ` ${subLocalityName},` : ''}${
      localityName ? ` ${localityName},` : ''
    }
            ${cityName ? ` ${cityName},` : ''}${pincode ? ` ${pincode}` : ''}`;
  };

  render() {
    const { onClose, onSubmitDetails } = this.props;
    const { address, openUserSelector = false, userType } = this.state;
    const {
      customerId,
      name,
      active,
      mobile,
      email,
      gender,
      dob,
      vegetarian,
      married,
      noOfFamilyMembers,
      critical = false,
      ringBell = false,
      dndEnabled = false,
      referredBy = null,
      dndConsent = null,
    } = address;
    return (
      <div
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          background: 'white',
          width: '70%',
          marginLeft: '15%',
          height: '90%',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <UserSelection
          userType={userType}
          openSearchBox={openUserSelector}
          handleClose={this.handleSelectorClose}
          selectUser={this.setSelectedData}
        />
        <Button style={{ float: 'right' }} onClick={onClose} color="secondary" aria-label="close">
          <CloseIcon />
        </Button>
        <Grid style={{ padding: '2em' }}>
          <Row>
            <ColMod xs md lg={15}>
              <Row>
                <Typography variant="body2" color="secondary">
                  {'Update User'}
                </Typography>
              </Row>
              <Row>
                <ColMod lg={4}>
                  <TextField label="Customer Id" fullWidth value={customerId || ''} disabled />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Customer Name"
                    fullWidth
                    value={name || ''}
                    onChange={event => this.onChangeValue(event, 'name')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">
                    Status
                  </InputLabel>
                  <MaterialSelect
                    value={active ? 'ACTIVE' : 'INACTIVE'}
                    inputProps={{
                      name: 'selectedStatus',
                      id: 'select-status',
                    }}
                    onChange={event => this.onChangeValue(event, 'active')}
                    fullWidth
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </MaterialSelect>
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={4}>
                  <TextField
                    label="Mobile"
                    fullWidth
                    value={mobile || ''}
                    onChange={event => this.onChangeValue(event, 'mobile')}
                    disabled
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={email || ''}
                    onChange={event => this.onChangeValue(event, 'email')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Gender"
                    fullWidth
                    value={gender || ''}
                    onChange={event => this.onChangeValue(event, 'gender')}
                  />
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={4}>
                  <TextField
                    label="DOB"
                    fullWidth
                    value={dob || ''}
                    onChange={event => this.onChangeValue(event, 'dob')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Vegetarian"
                    fullWidth
                    value={vegetarian || ''}
                    onChange={event => this.onChangeValue(event, 'vegetarian')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Married"
                    fullWidth
                    value={married || ''}
                    onChange={event => this.onChangeValue(event, 'married')}
                  />
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={3}>
                  <TextField
                    label="NoOfFamilyMembers"
                    fullWidth
                    value={noOfFamilyMembers || ''}
                    onChange={event => this.onChangeValue(event, 'noOfFamilyMembers')}
                  />
                </ColMod>

              </Row>
              <Row>
                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ringBell}
                          value={ringBell}
                          onChange={event => this.onChangeValue(event, 'ringBell')}
                          color="primary"
                        />
                          }
                      label="Ring Bell"
                    />
                  </FormGroup>

                </ColMod>

                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={critical}
                          value={critical}
                          onChange={event => this.onChangeValue(event, 'critical')}
                          color="secondary"
                        />
                          }
                      label="Critical"
                    />
                  </FormGroup>
                </ColMod>
                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dndEnabled}
                          value={dndEnabled}
                          onChange={event => this.onChangeValue(event, 'dndEnabled')}
                          color="secondary"
                        />
                          }
                      label="STOP APP NOTIFICATIONS"
                    />
                  </FormGroup>
                </ColMod>
                <ColMod lg={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={dndConsent}
                          value={dndConsent}
                          onChange={event => this.onChangeValue(event, 'dndConsent')}
                          color="secondary"
                        />
                          }
                      label="DND CONSENT"
                    />
                  </FormGroup>
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={3}>
                  <Typography variant="body2" color="secondary">
                    Referred By :
                  </Typography>
                </ColMod>
                <ColMod lg={9}>
                  <Typography variant="body2" color="primary">
                    {referredBy || ''}
                  </Typography>
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={3}>
                  <Typography variant="body2" color="secondary">
                    Full Customer Info:
                  </Typography>
                </ColMod>
                <ColMod lg={9}>
                  <Typography variant="body2" color="primary">
                    {this.generateAddress()}
                  </Typography>
                </ColMod>

              </Row>
            </ColMod>
          </Row>
        </Grid>
        <div style={{ float: 'right', paddingRight: '2em' }}>
          <Button onClick={onClose} style={{ marginRight: '2em' }} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              address.fullAddress = this.generateAddress();
              onSubmitDetails(address);
            }}
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default EditCustomerComponent;
