import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CatalogSelector from '../../../components/Catalog/Selector';
import apiCall, { catalogPubilshPath } from '../../../api/NetworkHandler';

const styles = {
  searchBar: {
    background: Colors.grey[100],
  },
}

function CatalogProductItemSearch(props) {
  const { classes, searchTerm, onChange, onSelectCatalog, selectedCatalog, onEdit } = props;

  const handlePublish = async () => {
    try {
      await apiCall.sendRequest('post', catalogPubilshPath(selectedCatalog.value));
      onEdit();
    } catch (e) {

    }
  }

  return (
    <Grid className={classes.searchBar} fluid>
      <Row md={12} middle="md">
        <Col md={2}>
          <Typography variant="title" color="inherit">
            Products
          </Typography>
        </Col>
        <ColMod md={10}>
          <Row md={12} between="md" middle="md">
            <Col md={10}>
              <Row md={12}>
                <Col md={3}>
                  <Input
                    color="secondary"
                    value={searchTerm || ''}
                    label="Search"
                    onChange={onChange}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Col>
                <Col md={3}>
                  <CatalogSelector
                    selected={selectedCatalog}
                    onSelect={onSelectCatalog}
                    isClearable={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              {selectedCatalog && (selectedCatalog.status === 'NEW' || selectedCatalog.status === 'EDITING') ?
                <Button variant="contained" color="secondary" onClick={() => { handlePublish() }}>
                  PUBLISH
                </Button> :
                <Typography color="secondary" >
                  {selectedCatalog && selectedCatalog.status}
                </Typography>}
            </Col>
          </Row>
        </ColMod>
      </Row>
    </Grid>

  )
}

export default withStyles(styles)(CatalogProductItemSearch);