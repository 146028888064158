import React from 'react';
import MainComponent from '../components/StockAdjustment';

class App extends React.Component {
  render() {
    return (
      <div>
        <MainComponent />
      </div>
    );
  }
}


export default (App);

