import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import {
  InputAdornment,
  Typography,
  Modal,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Input,
  Chip,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';
import UserTable from './UserTable';
import UserSearchBar from './UserSearchBar';
import apiCall, { getUsers, getAllUsers, getRoles, fetchUserByIdPath } from '../api/NetworkHandler';
import BASE_URL from '../api/config';
import UserSelection from './UserSelection';
import { ColMod } from './lib';
import SectionMessage from '@atlaskit/section-message';
import isEmpty from 'lodash/isEmpty';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      links: {},
      roles: [],
      departments: [],
      openAddUser: false,
      selectedUser: {},
      selectedRoles: [],
      openUserSelector: false,
      filters: {
        query: '',
        role: '',
      },
      searchedResult: false,
      error: {},
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.fetchAllUsers();
    this.fetchAllRoles();
    this.fetchAllDepartments();
  }


  onSearch = (query, role) => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query,
        role: role.id ? role.id : '',
      },
    }), () => {
      this.fetchAllUsers();
    });
  }

  onClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  }

  fetchAllUsers = () => {
    const { filters: { query, role } } = this.state;
    if (query || role) {
      apiCall.sendRequest('get', getAllUsers(query, role))
        .then((response) => {
          const { data: { _embedded: embedded, _links: links } } = response;
          this.setState({
            users: embedded.users,
            links,
            searchedResult: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('get', getUsers)
        .then((response) => {
          const { data: { _embedded: embedded, _links: links } } = response;
          this.setState({
            users: embedded.users,
            links,
            searchedResult: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  fetchPaginatedUser = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        const { data: { _embedded: embedded = {}, _links: links = {} } } = response;
        this.setState({
          users: embedded.users,
          links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchAllRoles = () => {
    apiCall.sendRequest('get', getRoles)
      .then((response) => {
        const { data: { _embedded: embedded } } = response;
        this.setState({
          roles: embedded.roles,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchAllDepartments = () => {
    apiCall.sendRequest('get', 'api/v1/departments')
      .then((response) => {
        const { data: { _embedded: embedded } } = response;
        this.setState({
          departments: embedded.departments,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleClick = (user) => {
    const { roles } = this.state;
    if (user) {
      const { _links: links = {} } = user;
      const href = links.roles.href.split('/').slice(3).join('/');
      apiCall.sendRequest('get', href)
        .then((response) => {
          const { data: { _embedded: embedded } } = response;
          const userRoles = embedded.roles;
          this.setState({
            selectedRoles: roles.filter((role) => {
              for (let i = 0; i < userRoles.length; i += 1) {
                if (userRoles[i].id === role.id) {
                  return true;
                }
              }
            }),
          });
        });
      this.setState({
        selectedUser: user,
      });
    }
  }

  handleDepartment = (user) => {
    if (user) {
      const { _links: links } = user;
      const href = links.departments.href.split('/').slice(3).join('/');
      apiCall.sendRequest('get', href)
        .then((response) => {
          const { data: { _embedded: embedded } } = response;
          const { departments } = embedded;
          const userDepartment = departments[0].id;
          this.setState({
            selectedDepartment: userDepartment,
          });
        });
      this.setState({
        selectedUser: user,
      });
    }
  }

  openAddUser = (user) => {
    this.setState({
      selectedUser: user || { 
      name: '',
      mobile: '',
      email: '',
    },
      openAddUser: true,
      isEdit: !!user,
    });
    if (!user) {
      this.setState({
        selectedRoles: [],
        selectedDepartment: [],
      });
    }
    this.fetchUserById(user);
  }

openUserSelector = userType => () => {
  this.setState({
    openUserSelector: true,
    userType,
  });
}

  handleClose = () => {
    this.setState({
      selectedRoles: [],
      selectedDepartment: [],
      openAddUser: false,
      isEdit: false,
      error: {},
      errorMessage: '',
    });
  }

  setError = (key, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error:{
          ...error,
          [key]: value,
        }
      }
    })
  }

  isPropertyValid = (key, value) => {
    if(key == 'departments'){
      const { selectedDepartment } = this.state;
      const isArr = Array.isArray(selectedDepartment);
      if((isArr && isEmpty(selectedDepartment)) || !selectedDepartment){
        value = '';
      }
    }
    if(isEmpty(value)){
      this.setError(key, true);
      return false;
    }
    this.setError(key, false);
    return true;
  }

  isFormValid = () => {
    const { selectedUser } = this.state;
    let isValid = true;

    const validateEntities = ['name', 'email', 'mobile', 'roles', 'departments'];

    for(const property in selectedUser){
      if(validateEntities.includes(property) && !this.isPropertyValid(property, selectedUser[property])){
        isValid = false;
      }
    }

    return isValid;
  }

  postUser = () => {
    if(!this.isFormValid()){
      return;
    }
    const { selectedUser } = this.state;
    const postData = { ...selectedUser };
    apiCall.sendRequest('post', getUsers, postData)
      .then((response) => {
        this.fetchAllUsers();
        this.handleClose();
      })
      .catch((error) => {
        const { response: { data } } = error;
        let errorMessage = '';
        if(!data.message){
          errorMessage = data;
        }else{
          errorMessage = data.message;
        }
        this.setState({ errorMessage });
      });
  }

  fetchUserById = (user) => {
    const { supervisorId } = user || {};
    if (supervisorId) {
      apiCall.sendRequest('get', fetchUserByIdPath(supervisorId))
        .then((response) => {
          const { data } = response;
          this.setState(prevState => ({
            selectedUser: {
              ...prevState.selectedUser,
              supervisorName: data.name,
            },
          }));
        });
    }
  }

  addOrUpdateUser = () => {
    const { selectedRoles, selectedDepartment } = this.state;
    if (selectedRoles.length || selectedDepartment) {
      this.setState(prevState => ({
        selectedUser: {
          ...prevState.selectedUser,
          roles: prevState.selectedRoles.map(role => `${BASE_URL.BASE_URL}/api/v1/roles/${role.id}`),
          departments: [`${BASE_URL.BASE_URL}/api/v1/departments/${selectedDepartment}`],
        },
      }), () => {
        this.postUser();
      });
    } else {
      this.postUser();
    }
  }

  handleChange = (event, id) => {
    const { selectedUser } = this.state;
    const { value } = event.target;
    if (id === 'active') {
      selectedUser[id] = value === 'ACTIVE';
      this.setState({ selectedUser });
    } else {
      selectedUser[id] = value;
      this.setState({
        selectedUser,
      });
    }
  };


  selectUser = (user) => {
    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        supervisorId: user.id,
        supervisorName: user.name,
        supervisorMobile: user.mobile,
      },
    }));
    this.onClose();
  }

  renderRoles = () => (this.state.selectedRoles ? this.state.selectedRoles.map(role => (<div>
    <div style={{ display: 'flex' }}>
      <Typography variant="body1" color="inherit" style={{ flex: 1 }}>
        {role.name}
      </Typography>
    </div>
  </div>)) : <div />)

  render() {
    const {
      selectedUser, roles, users, links, searchedResult,
      userType, openUserSelector, openAddUser,
      isEdit, selectedRoles, selectedDepartment, departments,
      errorMessage, error
    } = this.state;

    const {
      name,
      active,
      address,
      email,
      mobile,
      pan,
      latitude,
      longitude,
      supervisorName,
      workPhone,
      bankAccountNo,
      bankAddress,
      bankIfscCode,
      bankName,
    } = selectedUser;

    return (
      <div>
        <NavBar />
        <UserSearchBar
              openAddUser={this.openAddUser}
              roles={roles}
              onSearch={this.onSearch}
              />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <UserTable
              users={users}
              links={links}
              fetchPaginatedUser={this.fetchPaginatedUser}
              searchedResult={searchedResult}
              openAddUser={this.openAddUser}
              handleClick={this.handleClick}
              handleDepartment={this.handleDepartment}
               />
          </div>
          <div style={styles.paperRight}>
            { selectedUser && selectedUser.name
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={{ borderBottom: 'solid 1px #e0e0e0', height: '40px' }}>
                  User Details
                  </Typography>
                  <Typography variant="body1" color="secondary" style={{ marginTop: '10px' }}>
                    {selectedUser.name}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  Address
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {selectedUser.address}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  Roles
                  </Typography>
                  {this.renderRoles()}
                </div>
              : <div />}
          </div>
        </div>
        <UserSelection
            userType={userType}
            openSearchBox={openUserSelector}
            handleClose={this.onClose}
            selectUser={this.selectUser}
          />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openAddUser}
          onClose={this.handleClose}
        >
          <div style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            width: '70%',
            marginLeft: '15%',
            height: '90%',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              {errorMessage && <Row sm={12}>
                <ColMod sm={12}>
                  <SectionMessage appearance="error">
                    {errorMessage}
                  </SectionMessage>
                </ColMod>
              </Row>}
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      {isEdit ? 'Update User' : 'Create User'}
                    </Typography>
                  </Row>
                  <Row>
                    <Typography variant="body2" color="error">
                      {'* Required'}
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Name"
                        fullWidth
                        name="name"
                        value={name || ''}
                        onChange={event => this.handleChange(event, 'name')}
                        required
                        error={error.name}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Email"
                        fullWidth
                        name="email"
                        value={email || ''}
                        onChange={event => this.handleChange(event, 'email')}
                        required
                        error={error.email}
                        helperText="OTP will be mailed to set password"

                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">Status</InputLabel>
                      <MaterialSelect
                    value={active ? 'ACTIVE' : 'INACTIVE'}
                    inputProps={{
                      name: 'selectedStatus',
                      id: 'select-status',
                    }}
                    onChange={event => this.handleChange(event, 'active')}
                    fullWidth
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Work Phone"
                        fullWidth
                        name="workPhone"
                        value={workPhone || ''}
                        onChange={event => this.handleChange(event, 'workPhone')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Mobile"
                        fullWidth
                        name="mobile"
                        value={mobile || ''}
                        onChange={event => this.handleChange(event, 'mobile')}
                        required
                        error={error.mobile}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em', color: error.roles ? 'red' : '' }} htmlFor="select-status">Roles *</InputLabel>
                      <MaterialSelect
                        multiple
                        fullWidth
                        value={selectedRoles}
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState({
                            selectedRoles: value,
                          });
                        }}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                          <div>
                            {selected.map(value => <Chip style={{ padding: 0 }} key={value.id} label={value.name} />)}
                          </div>
                        )}
                        error={error.roles}
                      >
                        {roles.map(role => (
                          <MenuItem
                            key={role}
                            value={role}
                          >
                            {role.name}
                          </MenuItem>
                        ))
                        }
                      </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Address"
                        fullWidth
                        name="address"
                        value={address || ''}
                        onChange={event => this.handleChange(event, 'address')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Latitude"
                        fullWidth
                        name="latitude"
                        value={latitude || ''}
                        onChange={event => this.handleChange(event, 'latitude')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Longitude"
                        fullWidth
                        name="longitude"
                        value={longitude || ''}
                        onChange={event => this.handleChange(event, 'longitude')}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                  <ColMod lg={4}>
                          <TextField
                            label="Supervisor Name"
                            fullWidth
                            name="supervisorId"
                            value={supervisorName || '' }
                            onChange={event => this.handleChange(event, 'supervisorId')}
                            onClick={this.openUserSelector('Supervisor')}
                              helperText="Supervisor"
                              InputProps={{
                              readOnly: true,
                              endAdornment: (
                              <InputAdornment position="start">
                                <Search color="secondary" />
                              </InputAdornment>
                              ),
                        }}
                      />
                      </ColMod>
                      <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em', color: error.departments ? 'red' : '' }} htmlFor="select-status">Department *</InputLabel>
                      <MaterialSelect
                        fullWidth
                        value={selectedDepartment}
                        onChange={(event) => {
                          const { value } = event.target;
                          this.setState({
                            selectedDepartment: value,
                          });
                        }}
                        error={error.departments}
                      >
                        {departments.map(department => (
                          <MenuItem
                            key={department}
                            value={department.id}
                          >
                            {department.name}
                          </MenuItem>
                        ))
                        }
                      </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <Typography variant="body2" color="secondary">
                        Payment Details
                    </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                    <TextField
                        label="Bank Name"
                        fullWidth
                        name="bankName"
                        value={bankName || ''}
                        onChange={event => this.handleChange(event, 'bankName')}
                        />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="Account Number"
                        fullWidth
                        name="bankAccountNo"
                        value={bankAccountNo || ''}
                        onChange={event => this.handleChange(event, 'bankAccountNo')}
                        />
                    </ColMod>
                    <ColMod lg={4}>
                    <TextField
                        label="IFSC Code"
                        fullWidth
                        name="bankIfscCode"
                        value={bankIfscCode || ''}
                        onChange={event => this.handleChange(event, 'bankIfscCode')}
                        />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={12}>
                    <TextField
                        label="Address"
                        fullWidth
                        name="bankAddress"
                        value={bankAddress || ''}
                        onChange={event => this.handleChange(event, 'bankAddress')}
                        />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={12}>
                    <TextField
                        label="PAN#"
                        fullWidth
                        name="pan"
                        value={pan || ''}
                        onChange={event => this.handleChange(event, 'pan')}
                        />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
              Cancel
              </Button>
              <Button
                onClick={() => {
                this.addOrUpdateUser();
                this.fetchUserById();
              }}
                variant="contained"
                color="secondary"
              >
                {isEdit ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


export default Dashboard;
