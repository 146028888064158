import React, { Component } from "react";
import NavBar from "../../../components/NavBar";
import HomeOfferSearch from "../components/HomeOfferSearch";
import HomeOfferList from "../components/HomeOfferList";
import HomeOfferForm from "../components/HomeOfferForm";
import Colors from '../../../utils/Colors';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import apiCall, { getHomeOffers, getFetchAllRegionsPath } from "./../../../api/NetworkHandler";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';

const styles = {
  container: {
    margin: 0,
  },
  searchBar: {
    margin: "0",
    background: Colors.grey[100],
  },
  mainContainer: {
    backgroundColor: Colors.grey[100],
    paddingBottom: 10
  },
  title: {
    height: '2em',
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '0.8em',
  },
}
const versionDict = {
  "fetchOfferId": 0,
}
class HomeOfferContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      homeOffers: [],
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isNoData: true,
      loading: false,
      disableButton: false,
      searchTerm: '',
      order: 'desc',
      orderByData: 'order',
      openHomeOfferForm: false,
      selectedRegion: null,
      selectedOffer: null,
      selectedStatus: 'All',
    }
  }

  componentDidMount() {
    this.fetchHomeOffers();
    this.searchRegion();
  }

  searchRegion = async () => {
    // Change API
    try {
      const response = await apiCall.sendRequest('get', getFetchAllRegionsPath(''))
      const { data: { _embedded: { regions = [] } } } = response;
      this.setState({
        regions: regions
      });
    } catch (error) {
      console.error(error);
    }
  }

  toggleLoading = (loadingState) => {
    this.setState({ loading: loadingState });
  }

  fetchHomeOffers = async () => {

    this.toggleLoading(true);
    try {
      versionDict.fetchOfferId += 1;
      let prevFetchOfferId = versionDict.fetchOfferId;

      const { page, rowsPerPage, order, orderByData, searchTerm, selectedRegion, selectedStatus } = this.state;
      let active = selectedStatus;
      if (selectedStatus === 'All') active = ""
      let regionId = '';
      if (selectedRegion) regionId = selectedRegion.id;
      const response = await apiCall.sendRequest('get', getHomeOffers(encodeURIComponent(searchTerm), regionId, active, page, rowsPerPage));
      const { data: { data: { content = [], totalElements = 0 } = {} } } = response;
      let offers = orderBy(content, orderByData, order);

      if (versionDict.fetchOfferId === prevFetchOfferId) {
        this.setState({
          homeOffers: offers,
          count: totalElements,
          isNoData: totalElements > 0 ? false : true,
        });
      }

    } catch (error) {
      this.setState({
        homeOffers: [],
        isNoData: true,
        count: 0,
      })
    }

    this.toggleLoading(false);

  }

  onClear = () => {
    this.setState({
      searchTerm: '',
      selectedRegion: null,
      selectedStatus: 'All',
      page: 0,
    }, () => {
      this.fetchHomeOffers();
    });
  }

  onChangeSearchTerm = (event) => {
    this.setState({
      searchTerm: event.target.value,
    })
  }

  onSearch = () => {
    this.setState({
      page: 0
    }, () => {
      this.fetchHomeOffers();
    })
  };

  onRegionChange = (selectedRegion = {}) => {
    this.setState({
      selectedRegion,
      page: 0,
    }, () => {
      this.fetchHomeOffers();
    })
  }

  onRequestSort = (property) => {
    if (property !== 'order') return;

    this.setState(prevState => {
      const { order, homeOffers, orderByData } = prevState;
      let sortOrder = 'asc'
      if (order === 'asc') sortOrder = 'desc';
      let offers = orderBy(homeOffers, orderByData, sortOrder);
      return {
        ...prevState,
        order: sortOrder,
        homeOffers: offers,
      }

    })
  }

  onPageChange = (event, page) => {
    this.setState({ page }, () => {
      this.fetchHomeOffers();
    })
  }

  onChangeRowsPerPage = (event) => {
    const { value } = event.target;
    this.setState({ page: 0, rowsPerPage: value }, () => {
      this.fetchHomeOffers();
    })
  }

  closeHomeOfferForm = () => {
    this.setState({ openHomeOfferForm: false, selectedOffer: null });
  }

  openHomeOfferForm = (selectedOffer) => {
    this.setState({ selectedOffer, openHomeOfferForm: true });
  }

  handleChangeStatus = (event) => {
    console.log(event.target.value);
    this.setState({ selectedStatus: event.target.value, page: 0 }, () => {
      this.fetchHomeOffers();
    });
  }

  render() {

    const { classes } = this.props;
    const {
      regions = [],
      homeOffers,
      loading,
      page,
      searchTerm = '',
      selectedRegion,
      count,
      rowsPerPage,
      order,
      orderByData,
      openHomeOfferForm,
      selectedOffer,
      isNoData,
      selectedStatus,
    } = this.state;
    return (
      <React.Fragment>
        <NavBar />
        <div style={styles.mainContainer}>

          <HomeOfferSearch
            onRegionChange={this.onRegionChange}
            onChange={this.onChangeSearchTerm}
            clear={this.onClear}
            searchTerm={searchTerm}
            selectedRegion={selectedRegion}
            onAddNew={this.openHomeOfferForm}
            onSearch={this.onSearch}
            selectedStatus={selectedStatus}
            handleChangeStatus={this.handleChangeStatus}

          />
        </div>
        <Grid className={classes.container} fluid>
          <Row lg={12}>
            <Col lg={12}>
              <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
                <HomeOfferList
                  data={homeOffers}
                  page={page}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  order={order}
                  orderBy={orderByData}
                  onRequestSort={this.onRequestSort}
                  onChangePage={this.onPageChange}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  onEdit={this.openHomeOfferForm}
                  regions={regions}
                  isNoData={isNoData}

                />
              </Spin>
            </Col>
          </Row>
        </Grid>
        <Drawer anchor="right" open={openHomeOfferForm} >
          <HomeOfferForm
            onClose={this.closeHomeOfferForm}
            regions={regions}
            selectedOffer={selectedOffer}
            onRefresh={this.fetchHomeOffers}
            toggleLoading={this.toggleLoading} />
        </Drawer>
      </React.Fragment>


    )
  }

}

export default withStyles(styles)(HomeOfferContainer);