import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@atlaskit/select';
import SectionMessage from '@atlaskit/section-message';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import apiCall, { stockDamagePath, stockReturnPath, stockAdjustPath, getlastFiveStockledgerEntries, stockExtrasPath, stockDumpPath } from '../../api/NetworkHandler';
import HubSelector from '../Hub/Selector';

const innerWidth = window.innerWidth;
const containerWidth = innerWidth < 600 ? innerWidth * 0.7 : innerWidth * 0.5;

const styles = {
  container: {
    width: containerWidth,
  },
  arrow: {
    cursor: "pointer",
  },
  label: {
    marginBottom: '1em',
  },
  error: {
    color: 'red',
  }
}

const transactionTypes = [
  { label: "Damaged", value: "DAMAGED" },
  { label: "Return", value: "RETURN" },
  { label: "Physical Check", value: "PHYSICAL_CHECK" },
  { label: "Extra Sent", value: "HubExtrasSent" },
  { label: "Dump", value: "Dump" }
]

class UpdateStockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockData: {
        stockUpdatingQty: '',
        comment: '',
        hubId: '',
      },
      warehouseName: '',
      productName: '',
      uomCode: '',
      selectedTransactionType: null,
      error: {},
      errorMessage: '',
      lastFiveEntries: [],
      selectedHub: null,
      createByFlag: false,
    }
  }

  componentDidMount() {
    const { stockInventory: { productItemId, productItemName, uom, warehouseId }, warehouseName } = this.props;

    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        warehouseName,
        productName: productItemName,
        stockData: {
          ...stockData,
          warehouseId,
          productItemId
        },
        uomCode: uom.code,
      }
    }, () => { this.fetchLastFiveEntries() })
  }

  fetchLastFiveEntries = async () => {
    const { stockData: { productItemId, warehouseId } } = this.state;
    try {
      const response = await apiCall.sendRequest('get', getlastFiveStockledgerEntries(warehouseId, productItemId));
      const { data: { data } } = response;
      data.forEach( item => {
        if(item.createdBy) {
          this.setState({ createByFlag: true });
        }
      });
      this.setState({ lastFiveEntries: data });
    } catch (error) {
      console.log(error);
    }
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  isPropertyValid = (property, value) => {
    if (property == "stockUpdatingQty") {
      value = parseFloat(value)
    }
    if(property == "comment"){
      value = value.trim();
    }
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let isFormValid = true;

    const { selectedTransactionType, stockData } = this.state;

    if (!selectedTransactionType) {
      isFormValid = false;
      this.setError("transaction", true);
    } else {
      this.setError("transaction", false);
    }

    const validEntityTypes = ["stockUpdatingQty"];

    if (selectedTransactionType) {
      validEntityTypes.push("comment")

      if(selectedTransactionType.value !== 'PHYSICAL_CHECK' && selectedTransactionType.value !== 'Dump') {
        validEntityTypes.push("hubId");
      }
    }

    for (const property in stockData) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, stockData[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  getPostUrl = () => {
    const { selectedTransactionType: { value } } = this.state;

    switch (value) {
      case 'DAMAGED':
        return stockDamagePath;
      case 'RETURN':
        return stockReturnPath;
      case 'PHYSICAL_CHECK':
        return stockAdjustPath;
      case 'HubExtrasSent':
        return stockExtrasPath;
      case 'Dump':
        return stockDumpPath;
    }
  }

  onSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { stockData } = this.state;

    const url = this.getPostUrl();

    try {
      const { onClose, onRefresh, stockInventory: { productItemId } } = this.props;
      await apiCall.sendRequest('post', url, stockData);
      onClose();
      onRefresh(productItemId);
    } catch (error) {
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  onWareouseChange = (selectedWarehouse) => {
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        selectedWarehouse,
        stockData: {
          ...stockData,
          warehouseId: selectedWarehouse.id,
        }
      }
    })
  }

  onProductItemChange = (selectedItem) => {
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        selectedItem,
        stockData: {
          ...stockData,
          vendorProductItemId: selectedItem.value,
        }
      }
    })
  }

  onTransactionChange = (selectedTransactionType) => {
    this.setState({selectedTransactionType});
    if(selectedTransactionType.value === 'PHYSICAL_CHECK' && selectedTransactionType.value !== 'Dump') {
      this.setState(prevState => {
        const { stockData } = prevState;
  
        return {
          stockData: {
            ...stockData,
            hubId: '',
          },
          selectedHub: null
        }
      });
    }
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        stockData: {
          ...stockData,
          [name]: (name == "stockUpdatingQty" && !this.canAcceptDecimalValue()) ? parseInt(value) : value,
        }
      }
    })
  }

  canAcceptDecimalValue() {
    const { uomCode } = this.state;
    const uomWithDecimal = ["G", "GM", "KG"];
    if (uomWithDecimal.includes(uomCode.toUpperCase())) {
      return true;
    }
    return false;
  }

  onHubChange = (selectedHub) => {
    this.setState(prevState => {
      const { stockData } = prevState;

      return {
        stockData: {
          ...stockData,
          hubId: selectedHub ? selectedHub.value : '',
        },
        selectedHub
      }
    })
  }

  render() {
    const { classes, onClose } = this.props;
    const {
      productName,
      uomCode,
      selectedTransactionType,
      stockData: { stockUpdatingQty, comment, warehouseId },
      error,
      errorMessage,
      lastFiveEntries,
      warehouseName,
      selectedHub,
      createByFlag,
    } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md" bottom="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Update Stock</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
            <Button variant="contained" color="secondary" onClick={this.onSave}>Save</Button>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row xs={12}>
          <ColMod xs={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod md={6}>
            <Typography variant="caption"> Warehouse </Typography>
            <TextField
              value={warehouseName || ''}
              fullWidth
              disabled
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> Product Item </Typography>
            <TextField
              value={productName || ''}
              fullWidth
              disabled
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption"> UOM </Typography>
            <TextField
              value={uomCode}
              fullWidth
              disabled
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption" color={error.transaction ? 'error' : 'default'}> Transaction Type * </Typography>
            <Select
              options={transactionTypes}
              selected={selectedTransactionType}
              onChange={this.onTransactionChange}
            />
          </ColMod>
          {selectedTransactionType && selectedTransactionType.value != "PHYSICAL_CHECK" && selectedTransactionType.value != "Dump" &&
            <ColMod md={6}>
              <Typography variant="caption" color={error.hubId ? 'error' : 'default'}> Hub * </Typography>
              {warehouseId && <HubSelector
                warehouseId={warehouseId}
                selected={selectedHub}
                onSelect={this.onHubChange}
              />}
            </ColMod>}
          <ColMod md={6}>
            <TextField
              type="number"
              label="Quantity"
              name="stockUpdatingQty"
              value={stockUpdatingQty || ''}
              onChange={this.onInputChange}
              fullWidth
              required
              error={error.stockUpdatingQty}
            />
          </ColMod>
          <ColMod md={12}>
            <TextField
              label="Remarks"
              name="comment"
              value={comment || ''}
              onChange={this.onInputChange}
              inputProps={{ maxLength: 255 }}
              fullWidth
              required
              error={error.comment}
            />
          </ColMod>
        </Row>
        <Row xs={12}>
          <ColMod xs={12}>
            <Typography variant="subheading">
              Last Entries
            </Typography>
          </ColMod>
          <ColMod xs={12}>
            <Paper style={{ overflow: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Stock Source</TableCell>
                    {createByFlag && <TableCell>Created By</TableCell>}
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lastFiveEntries.map(row => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell style={{ minWidth: '110px' }}>
                          {row.transactionType}
                        </TableCell>
                        <TableCell>{Math.abs(row.quantity)}</TableCell>
                        <TableCell>{row.comment || "-"}</TableCell>
                        <TableCell>{row.stockSource}</TableCell>
                        {createByFlag && <TableCell style={{ minWidth: '150px' }}>{row.createdBy || "-"}</TableCell>}                        
                        <TableCell style={{ minWidth: '150px' }}>{moment(row.timeCreated).format('DD-MM-YYYY hh:mm:ss a')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(UpdateStockForm);