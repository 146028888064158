import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducers from '../reducers';

const logger = createLogger({
  // ...options
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['User'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export default function configureStore() {
  /* eslint-disable no-underscore-dangle */
  const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(logger, thunk),
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
