import React, { Component } from 'react'
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import ProductSearch from '../components/ProductSearch';
import apiCall, {
  findProductsWithVendorDetails,
  productCatalogById,
  getProductCatalogByFilter
} from '../../../api/NetworkHandler';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import ProductsList from '../components/ProductsList';
import { convertUom } from '../../../utils/UomConverter';

const convertUomList = ["kg", "g", "l", "ml"];

class CatalogProductItemsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      selectedCatalog: null,
      products: [],
      rowsPerPage: 10,
      page: 0,
      order: 'asc',
      orderBy: 'name',
      count: 0,
      isLoading: false,
      isNoData: false,
    }
  }

  componentDidMount() {
    this.setDefaultCatalog();
  }

  setDefaultCatalog = async () => {
    const filters = { query: '' };
    const response = await apiCall.sendRequest("get", getProductCatalogByFilter(filters, 0));
    const { data: { data: { content = [] } = {} } = {} } = response;
    const productCatalogs = sortBy(content, 'name');
    this.setState({
      selectedCatalog: {
        value: productCatalogs[0].id,
        label: productCatalogs[0].name,
        status: productCatalogs[0].status
      }
    }, () => { this.fetchCatalogProductItems() })
  }

  fetchCatalogProductItems = async () => {
    const { selectedCatalog, searchTerm, page, rowsPerPage: size } = this.state;
    const { value: catalogId } = selectedCatalog;
    this.setLoadingIndicator(true);
    try{
      const response = await apiCall.sendRequest("get", findProductsWithVendorDetails(catalogId, searchTerm, size, page));
      const { data: { data: { products, page: pageInfo } } } = response;
      this.setLoadingIndicator(false);
      let isNoData =  !!(products && products.length);
      this.setState({
        products,
        count: pageInfo.totalElements,
        isNoData: !isNoData,
      });
    }
    catch(err){
      this.setLoadingIndicator(false);
      this.setState({isNoData:true});
    }
  }

  onSearchChange = debounce(() => {
    this.fetchCatalogProductItems();
  }, 400)

  onChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchTerm: value,
      page: 0
    });
    this.onSearchChange();
  }

  onSelectCatalog = async (selectedCatalog) => {
    const response = await apiCall.sendRequest("get", productCatalogById(selectedCatalog.value));
    const { data: { status } } = response;

    this.setState({
      selectedCatalog: {
        ...selectedCatalog,
        status
      },
      page: 0
    }, () => { this.fetchCatalogProductItems() })
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({
      rowsPerPage
    }, () => { this.fetchCatalogProductItems() })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => { this.fetchCatalogProductItems() })
  }

  setConvertedPrices = (productItem, sourceUom, sourceUnitMeasure, value) => {
    productItem.vendorProductItemMappingDTOs.forEach(vendor => {
      const targetUom = vendor.productItem.uom.code;
      const targetUnitMeasure = vendor.productItem.unitMeasure;
      const sellingPrice = convertUom(sourceUom, targetUom, sourceUnitMeasure, targetUnitMeasure, value);
      vendor.productItem.sellingPrice = sellingPrice;
    });
  }

  onPriceChange = (event, productIndex, productItemIndex) => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const { products } = prevState;
      const productItem = products[productIndex].productItemList[productItemIndex];
      const sourceUom = productItem.productItem.uom.code;
      if (name == "costPrice" && convertUomList.includes(sourceUom.toLowerCase())) {
        const sourceUnitMeasure = productItem.productItem.unitMeasure;
        this.setConvertedPrices(productItem, sourceUom, sourceUnitMeasure, value);
      }
      productItem.productItem[name] = value;

      return {
        products
      }
    })
  }

  onVendorPriceChange = (event, productIndex, productItemIndex, vendorIndex) => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const { products } = prevState;
      const productItem = products[productIndex].productItemList[productItemIndex];
      const vendorProductItem = productItem.vendorProductItemMappingDTOs[vendorIndex];

      // source uom and unit measure i.e vendorProductItem
      const sourceUom = vendorProductItem.productItem.uom ? vendorProductItem.productItem.uom.code : "";
      if (convertUomList.includes(sourceUom.toLowerCase())) {
        const sourceUnitMeasure = vendorProductItem.productItem.unitMeasure;

        this.setConvertedPrices(productItem, sourceUom, sourceUnitMeasure, value);

        // target Uom and unit measure i.e productItem
        const productItemUom = productItem.productItem.uom.code;
        const productItemUnitMeasure = productItem.productItem.unitMeasure;

        const costPrice = convertUom(sourceUom, productItemUom, sourceUnitMeasure, productItemUnitMeasure, value);

        productItem.productItem.costPrice = costPrice;
      } else {
        vendorProductItem.productItem.sellingPrice = value;
      }

      return {
        products
      }
    })
  }

  onProductEdit = async () => {
    const { selectedCatalog } = this.state;

    const response = await apiCall.sendRequest("get", productCatalogById(selectedCatalog.value));
    const { data: { status } } = response;

    this.setState({
      selectedCatalog: {
        ...selectedCatalog,
        status
      }
    })
  }

  setLoadingIndicator = (value) => {
    this.setState({ isLoading: value });
  }

  render() {
    const {
      searchTerm,
      selectedCatalog,
      products,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      isLoading,
      isNoData,
    } = this.state;

    return (
      <div>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <ProductSearch
          searchTerm={searchTerm}
          onChange={this.onChange}
          onSelectCatalog={this.onSelectCatalog}
          selectedCatalog={selectedCatalog}
          onEdit={this.onProductEdit}
        />
        <ProductsList
          products={products}
          rowsPerPage={rowsPerPage}
          page={page}
          order={order}
          orderBy={orderBy}
          count={count}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleChangePage={this.handleChangePage}
          onRequestSort={this.onRequestSort}
          onPriceChange={this.onPriceChange}
          onVendorPriceChange={this.onVendorPriceChange}
          onRefresh={this.fetchCatalogProductItems}
          onEdit={this.onProductEdit}
          searchTerm={searchTerm}
          isLoading={isLoading}
          isNoData={isNoData}
        />
      </div>
    )
  }
}

export default CatalogProductItemsContainer;