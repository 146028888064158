import React, { Component } from 'react';
import {
  Typography,
  Modal,
  Button,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import apiCall, {
  customerProfileById,
  allCustomerProfile,
  customerProfileByFilters,
  customersPath,
  staticRoute,
  editSubmitAddress,
  elseEditSubmitAddress,
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import GenericSearchBar from './GenericSearchBar';
import StaticRouteSelection from '../../../components/StaticRouteSelection';
import EditCustomerComponent from './EditCustomerComponent';
import TransactionHistory from './TransactionHistory';
import TableHeader from '../../Commons/components/TableHeader';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import PlanHistory from '../../CsAssist/Plans/PlanHistory';

const columnData = [
  {
    id: 'customerId',
    numeric: false,
    disablePadding: true,
    label: 'Customer ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'mobile',
    numeric: false,
    disablePadding: true,
    label: 'Mobile',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'timeCreated',
    numeric: false,
    disablePadding: true,
    label: 'Registration Date',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const styles = {

  wrapper: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    height: '300',
    color: 'white',
  },

  calendar: {
    flex: 1,
  },
};
const cookies = new Cookies();

class CustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUA: {},
      customers: [],
      openRouteSelector: false,
      openEditAddress: false,
      openTransactions: false,
      filters: {
        unassignedAddressQuery: '',
        cId: '',
      },
      links: {},
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      postData: {},
      customerId: '',
      totalElements: 0,
    };
  }

  componentDidMount() {
    this.searchCustomers();
    this.getCustomers(0);
  }


  onSearch = () => {
    const { customerId } = this.state;
    if (customerId !== '') {
      this.searchCustomerById();
    } else {
      this.searchCustomers();
    }
  };


  getNextCustomers = (number) => {
    const { page } = this.state;
    this.getCustomers(page + number);
  }

  getCustomers = (page) => {
    apiCall
      .sendRequest('get', customersPath(page, 100))
      .then((response) => {
        const { data = {} } = response;
        const { _embedded, page: pagination = {} } = data;
        let { customers = [] } = _embedded;
        const { totalElements, size: rowsPerPage } = pagination;
        customers = customers.map((eachCustomer) => {
          const { id: customerId } = eachCustomer;
          return { ...eachCustomer, customerId };
        });
        this.setState({ customers, rowsPerPage, totalElements });
      });
  }

  searchCustomerById = async () => {
    const { customerId } = this.state;
    const response = await apiCall.sendRequest('get', customerProfileById(customerId));
    const { data: { data } } = response;
    this.setState({
      customers: [data],
    });
  }

  searchCustomers = () => {
    const { filters: { unassignedAddressQuery } = {} } = this.state;
    const url = unassignedAddressQuery ?
      customerProfileByFilters(unassignedAddressQuery)
      :
      allCustomerProfile;
    apiCall
      .sendRequest('get', url)
      .then((response) => {
        const { data = [] } = response;
        if (data) {
          const tempUnassignedAddressData = data.data;
          this.setState(prevState => ({
            customers: [...tempUnassignedAddressData],
            links: response.data._links,
            selectedUA: {},
          }));
        }
      });
  };

  fetchPaginatedAddresses = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        const { data = [] } = response;
        const tempUnassignedAddressData = data.data;
        this.setState({
          customers: [...tempUnassignedAddressData],
          links: response.data._links,
          selectedUA: {},
          searchedResult: false,
        });
      });
  }

  handleClick = (selectedUA) => {
    this.setState({
      selectedUA,
    });
  };


  handleQueryChange = (value) => {
    this.setState(prevState => ({
      customerId: '',
      filters: {
        ...prevState.filters,
        unassignedAddressQuery: value,
      },
    }));
  };

  openAddAddress = (address) => {
    this.setState({
      openEditAddress: true,
    });
  };

  editAddress = (n) => {
    this.setState({
      postData: { ...n },
    }, () => {
      this.openAddAddress(n);
    });
  }

  openRouteSelector = (n) => {
    this.setState({
      openRouteSelector: true,
      selectedUA: n,
    });
  }

  handleRouteSelectorClose = () => {
    this.setState({
      openRouteSelector: false,
    });
  }

  selectStaticRoute = (route = {}) => {
    this.handleRouteSelectorClose();
    apiCall.sendRequest('put', staticRoute)
      .then((response) => {
        this.searchCustomers();
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
  }

  handleChangePage = (event, page) => {
    const { links: { prev = {}, next = {} } = {} } = this.state;
    const { href: prevHref } = prev;
    const { href: nextHref } = next;
    if (this.state.searchedResult) {
      this.setState({ page });
    } else {
      let url = '';
      if (this.state.page < page) {
        url = nextHref;
      } else {
        url = prevHref;
      }
      if (url) {
        this.fetchPaginatedAddresses(url);
      }
    }
  };

  handleEditAddressClose = () => {
    this.setState({
      openEditAddress: false,
    });
  }

  handleSubmitAddress = (address) => {
    if (address && address.customerId) {
      const { customerId } = address;
      apiCall.sendRequest('post', editSubmitAddress(customerId), address)
        .then((response) => {
          this.searchCustomers();
          this.handleEditAddressClose();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('post', elseEditSubmitAddress, address)

        .then((response) => {
          this.searchCustomers();
          this.handleEditAddressClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }


  handleCustomerIdChange = (customerId) => {
    const { filters } = this.state;
    this.setState({
      customerId,
      filters: {
        ...filters,
        unassignedAddressQuery: '',
      },
    });
  }


  renderAddressDetails = () => {
    const { selectedUA } = this.state;
    const addressKeys = Object.keys(selectedUA);
    if (!addressKeys.length) {
      return null;
    }
    return addressKeys.map(item => item !== 'deviceId' && item !== '_links' &&
    <Typography variant="body1" color="inherit">
        {`${item} : ${selectedUA[item]}`}
    </Typography>);
  }


  render() {
    const {
      customers,
      customerId,
      selectedUA,
      rowsPerPage, page, filters: { unassignedAddressQuery = '' } = {},
      openEditAddress, postData, openTransactions,
      totalElements,
      rowsPerPageOptions,
    } = this.state;
    return (
      <div>
        <NavBar />
        <GenericSearchBar
          customerId={customerId}
          onSearch={this.onSearch}
          query={unassignedAddressQuery}
          onOpenAdd={this.editAddress}
          handleQueryChange={this.handleQueryChange}
          handleCustomerId={this.handleCustomerIdChange}
          title="Customer Profile"
          buttonName="Create New"
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openEditAddress}
          onClose={this.handleEditAddressClose}
        >
          <EditCustomerComponent
            address={postData}
            onClose={this.handleEditAddressClose}
            onSubmitDetails={this.handleSubmitAddress}
          />
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openTransactions}
          onClose={() => {
            this.setState({ openTransactions: false });
          }}
        >
          <TransactionHistory
            customerId={customerId}
            address={postData}
            onClose={() => {
            this.setState({ openTransactions: false });
          }}
          />
        </Modal>
        {/* <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openPlanHistory}
          onClose={this.handleEditAddressClose}
        >
          <PlanHistory
            selctedPlan={postData}
            onClose={this.handleEditAddressClose}
          />
        </Modal> */}
        <StaticRouteSelection
          openSearchBox={this.state.openRouteSelector}
          handleClose={this.handleRouteSelectorClose}
          selectStaticRoute={this.selectStaticRoute}
        />
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary" >
            <Button color="secondary" onClick={() => { this.props.history.goBack(); }}>Back</Button>
          </Typography>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.container}>
            <Table style={{ marginRight: '2.5%' }} aria-labelledby="tableTitle">
              <TableHeader columns={columnData} />
              <TableBody>
                {customers.map(n => (
                  <TableRow
                    hover
                    onClick={() => this.handleClick(n)}
                    tabIndex={-1}
                    key={n.customerId}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    selected={selectedUA && selectedUA.id === n.id}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" >
                      {n.customerId}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <div>
                        {n.name}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.mobile}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <div>
                        {n.email}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <div>
                        {moment(n.timeCreated).format('ll')}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <span>
                        <Button size="small" color="secondary" onClick={() => this.editAddress(n)}>Edit</Button>
                      </span>&emsp;|&emsp;
                      <span>
                        <Button size="small" color="secondary">
                          <Link to={`/transaction-history/${n.customerId}`} style={{ textDecoration: 'none', color: 'inherit' }} >Transactions</Link>
                        </Button>
                      </span>&emsp;|&emsp;
                      <span>
                        <Button size="small" color="secondary">
                          <Link to={`/customer-addresses/${n.customerId}`} style={{ textDecoration: 'none', color: 'inherit' }} >Addresses</Link>
                        </Button>

                      </span>&emsp;|&emsp;
                      <span>
                        <Button size="small" color="secondary">
                          <Link to={`/create-customer-plan/${n.customerId}`} style={{ textDecoration: 'none', color: 'inherit' }} >Plans</Link>
                        </Button>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {(this.state.searchedResult) ?
              <TablePagination
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
                nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            : <TablePagination
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
              nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          }
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerComponent;
