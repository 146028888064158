import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import apiCall, { getDummyRouteById } from '../../../api/NetworkHandler';

function DeleteDialog(props) {
  const { open, onClose, dummyRouteId, onRefresh } = props;

  const deleteDummyRouteMapping = async () => {
    try {
      await apiCall.sendRequest("delete", getDummyRouteById(dummyRouteId));
      onClose();
      onRefresh();
    } catch (error) {

    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">Delete Route Mapping?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this Dummy route mapping?
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
          </Button>
        <Button onClick={deleteDummyRouteMapping} color="secondary" variant="contained" autoFocus>
          Confirm
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog;
