import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../../../components/Core/SearchAndSelect';
import apiCall, { getOverlaysByFilters } from '../../../api/NetworkHandler';

const onOverlayLoad = async (searchTerm) => {
    const filters = { query: searchTerm, filterType: '' };
    const response = await apiCall.sendRequest('get', getOverlaysByFilters(filters));
    const { data: { data: { content = [] } = {} } = {} } = response;
    const overlays = content.filter(el => ['CUSTOMER', 'CUSTOMER_FROM'].includes(el.filterType));
    
    return overlays.map(overlay => {
      return {
        label: overlay.title,
        value: overlay.id,
      }
    });
}

function AudienceSetSelector(props) {
  const { selected, onSelect } = props;

  return (
    <SearchAndSelect
      onLoad={onOverlayLoad}
      selected={selected}
      onSelect={onSelect}
    />
  )
}

AudienceSetSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.instanceOf(Object),
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
};

AudienceSetSelector.defaultProps = {
  selected: null
};

export default AudienceSetSelector;
