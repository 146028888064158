import React, { Component } from "react";
import { Row } from "react-flexbox-grid";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  InputLabel,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Edit from "@material-ui/icons/Create";
import moment from "moment";
import { ColMod } from "../../../components/lib";
import PurchaseOrderItemTable from "./PurchaseOrderItemTable";
import NavBar from "../../../components/NavBar";
import SaveBar from "./SavePurchaseOrder";
import TermsAndConditionsEditCreatePo from "../components/TermsAndConditionEditCreatePo";
import VendorProductSelection from './VendorProductSelection';
import Drawer from '@material-ui/core/Drawer';
import Toast from '../../../utils/Toast';
import apiCall, {
  getOrganization,
  purchaseOrderUrl,
  getTermsConditions,
  fetchVendorCatalogByLocationId,
  downloadPOTemplateByParams,
  uploadPOcsvByParams,
  validateTotMargin,
  downloadTotMarginCSV,
  getOrganizationLocationsByWarehouseId,
} from "../../../api/NetworkHandler";
import ProgressBar from '../../../components/States/ProgressBar';
import StockAnalytics from "./StockAnalytics";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { saveAs } from 'file-saver/FileSaver';
import { Button as AntdButton, Modal } from 'antd';
import { DownloadOutlined, CloudUploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { debounce, isEmpty } from 'lodash';
import PoTotMarginModal from './PoTotMarginModal';
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import VendorLocationSelector from './VendorLocationSelector';

const { confirm } = Modal;

const styles = {
  termConditionsContainer: {
    marginTop: '12px'
  },
  bootstrapRoot: {
    padding: 0
  },
  bootstrapInput: {
    borderRadius: 4,
    border: "1px solid #d3d3d3",
    fontSize: 15,
    padding: "10px 12px",
    width: "100%",
  },
  editIcon: {
    color: "#f50057",
    width: "18px",
    height: "18px",
    marginLeft: "3px",
    cursor: "pointer"
  },
  termsAndConditionDiv: {
    width: '100%'
  },
  drawerPaper: {
    width: '50%',
    maxWidth: '550px',
    padding: '24px'
  }
};

var versionDict = {
  "catalogRequestId": 0,
  "vendorLocationRequestId": 0,
  "termsAndConditionRequestId": 0,
  "orgRequestId": 0,
  "orgLocRequestId": 0,
}

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    const { history: { location: { state = {} } = {} } = {} } = this.props;
    const { warehouse: selectedShipTo, rateType: taxRateType } = state;
    this.inputCSVFileRef = [];
    this._currentButtonClicked = '';
    this.state = {
      otherCharges: 0,
      orgs: [],
      orgLocations: [],
      warehouses: [],
      items: [],
      data: {},
      selectedBillTo: {},
      selectedShipTo,
      selectedCompany: {},
      receivableDate: moment().format("YYYY-MM-DD"),
      openTermsAndConditions: false,
      invalidDate: false,
      invalidVendorLocation: false,
      termsAndConditions: "",
      openVendorProductSearchBox: false,
      poErrorMessage: '',
      isLoading: false,
      selectedProductItemId: null,
      selectedProductName: null,
      dateText: '',
      nameText: '',
      hideSidePanel: false,
      emptyItemsText: '',
      emptyQuantityText: '',
      vendorCatalog: [],
      disableButton: false,
      vendorLocations: [],
      selectedLocation: null,
      openTotMarginModal: false,
      totMarginData: {},
      shouldAllowToSavePO: false,
      modalButtonText: '',
      isPOSaveAllowed: false,
      disableMarginValidation: true,
      openLocationSelector: false,
      taxRateType,
    };
  }

  componentDidMount() {
    this.fetchOrganizations();

    let today = new Date(), day = today.getDate(), month = today.getMonth() + 1, year = today.getFullYear();
    if (day < 10) { day = '0' + day }
    if (month < 10) { month = '0' + month }
    today = `${year}-${month}-${day}`;
    document.getElementById("date").setAttribute("min", today);
    document.getElementById("date").setAttribute("value", today);

    this.setMarginValidationState();
  };

  fetchOrganizations = async () => {
    try {
      versionDict.orgRequestId += 1;
      let prevRequestId = versionDict.orgRequestId;
      const response = await apiCall.sendRequest('get', getOrganization);
      const { data: { _embedded: { organization = [] } = {} } = {} } = response;
      if (prevRequestId === versionDict.orgRequestId) {
        this.setState({
          orgs: organization,
          selectedCompany: organization[0]
        }, () => this.fetchOrganizationLocations(organization[0].id));
      }
    } catch (err) {
      this.setState({
        orgs: [],
        selectedCompany: {}
      });
    }
  };

  fetchOrganizationLocations = async (organizationId) => {
    const { history: { location: { state = {} } = {} } = {} } = this.props;
    const { warehouse } = state;
    try {
      versionDict.orgLocRequestId += 1;
      let prevRequestId = versionDict.orgLocRequestId;
      const response = await apiCall.sendRequest('get', getOrganizationLocationsByWarehouseId(organizationId, warehouse.id));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId === versionDict.orgLocRequestId) {
        this.setState({
          orgLocations: data,
          selectedBillTo: data[0]
        });
      }
    } catch (err) {
      this.setState({
        orgLocations: [],
        selectedBillTo: {}
      });
    }
  };

  setMarginValidationState = async () => {
    const appConfig = await getApplicationConfigs();
    const { disableMarginValidation = true } = appConfig;
    this.setState({
      disableMarginValidation
    });
  };

  onChangeItem = (product) => {
    this.setState({
      items: product,
    });
  };

  validateForm = () => {
    const { receivableDate, selectedLocation } = this.state;
    let isFormvalid = true;
    var getReceivableDate = moment(receivableDate).format("YYYY-MM-DD");
    var getToday = moment().format("YYYY-MM-DD");

    if (moment(getReceivableDate).isBefore(getToday) && getReceivableDate != getToday) {
      isFormvalid = false;
      this.setState({
        invalidDate: true,
        dateText: '* Past dates are not allowed'
      })
    } else {
      this.setState({
        invalidDate: false,
        dateText: ''
      })
    }
    if (isEmpty(selectedLocation)) {
      isFormvalid = false;
      this.setState({
        invalidVendorLocation: true,
        nameText: '* Please select vendor location'
      })
    } else {
      this.setState({
        invalidVendorLocation: false,
        nameText: ''
      })
    }
    return isFormvalid;
  }

  validateItems = () => {
    const { items } = this.state;
    const isAllQuantityValid = items.every(item => item.quantity > 0);
    const isAllUnitPriceValid = items.every(item => item.unitPrice > 0);
    const isAllSellingPriceValid = items.every(item => item.purchaseItemType === 'PAID' ? item.sellingPrice > 0 : item.sellingPrice == 0);
    const isSellingPriceValid = items.some(el => el.purchaseItemType === 'PAID' ? parseFloat((el.sellingPrice * (1 + 0.01 * el.tax)).toFixed(2)) > parseFloat(el.unitPrice) : false);
    // const isAllSellingPriceValid = items.every(item => item.sellingPrice > 0);
    // const isSellingPriceValid = items.some(el => parseFloat((el.sellingPrice * (1 + 0.01 * el.tax)).toFixed(2)) > parseFloat(el.unitPrice));
    return { isAllQuantityValid, isAllUnitPriceValid, isAllSellingPriceValid, isSellingPriceValid };
  }

  validatePo = () => {
    let isPoValid = true;
    const { items } = this.state;

    if (items.length == 0) {
      this.toggleItemsTextState("Please add at least one item");
      isPoValid = false;
    } else {
      this.toggleItemsTextState('');
    }

    const isAllQuantityValid = this.validateItems().isAllQuantityValid;
    const isAllUnitPriceValid = this.validateItems().isAllUnitPriceValid;
    const isAllSellingPriceValid = this.validateItems().isAllSellingPriceValid;
    const isSellingPriceValid = this.validateItems().isSellingPriceValid;

    if (!isAllQuantityValid || !isAllUnitPriceValid || !isAllSellingPriceValid || isSellingPriceValid) {
      this.toggleQuantityTextState("Please enter quantity for all items");
      isPoValid = false;
    } else {
      this.toggleQuantityTextState('');
    }

    return isPoValid;
  }

  toggleItemsTextState = (state) => {
    this.setState({
      emptyItemsText: state,
    });
  }

  toggleQuantityTextState = (state) => {
    this.setState({
      emptyQuantityText: state,
    });
  }

  toggleLoading = (state) => {
    this.setState({
      isLoading: state,
    });
  };

  onSave = debounce(async (status) => {
    const { data } = this.state;
    const { history } = this.props;
    this.toggleLoading(true);

    try {
      await apiCall.sendRequest('post', purchaseOrderUrl, data);
      this.toggleLoading(false);
      this.handleCloseTotMarginModal();
      history.goBack();
    } catch (err) {
      console.log(err.response);
      this.handleCloseTotMarginModal();
    }
  }, 500);

  validateTotMarginData = (marginData, buttonClicked) => {
    const { should = [], missing = [], must = [] } = marginData;
    if (buttonClicked === 'NEW') {
      if (should.length == 0 && missing.length == 0 && must.length == 0) {
        this.onSave(buttonClicked);
        return;
      }
      this.setState({
        modalButtonText: 'Save & Exit',
        isPOSaveAllowed: true,
      }, () => this.handleOpenTotMarginModal());
    } else {
      if (should.length > 0 && missing.length == 0 && must.length == 0) {
        this.setState({
          modalButtonText: 'Submit & Exit',
          isPOSaveAllowed: true
        }, () => this.handleOpenTotMarginModal())
      }
      else if (should.length == 0 && missing.length == 0 && must.length == 0) {
        this.onSave(buttonClicked);
      }
      else {
        this.setState({
          modalButtonText: 'Ok',
          isPOSaveAllowed: false
        }, () => this.handleOpenTotMarginModal())
      }
    }
  };

  validateTotMarginForPOLineItems = async (status) => {
    const { data } = this.state;
    try {
      const response = await apiCall.sendRequest('post', validateTotMargin, data);
      const { data: { data: totMarginData = {} } = {} } = response;
      this.setState({
        totMarginData,
      });

      this.validateTotMarginData(totMarginData, status);
    } catch (err) {
      console.error(err.response);
      this.setState({
        totMarginData: {}
      });
      return false;
    }
  };

  preparePostData = (status) => {
    const data = {};
    const { receivableDate, selectedLocation, selectedBillTo, selectedCompany, selectedShipTo, items, taxRateType } = this.state;
    const { User } = this.props;

    data.requester = User.email || "";
    data.vendorName = selectedLocation.name;
    data.vendorAddress = selectedLocation.fullAddress || "";
    data.companyName = selectedCompany.name || "";
    data.companyLocation = selectedBillTo.name || "";
    data.companyAddress = selectedCompany.organizationLocations[0].fullAddress || "";
    data.billTo = selectedBillTo.fullAddress || "";
    data.shipTo = selectedShipTo.fullAddress || "";
    data.status = status;
    data.warehouseId = selectedShipTo.id || "";
    data.additionalCharges = parseInt(this.state.otherCharges, 10);
    data.purchaseOrderLineItems = items;
    data.totalQuantity = items.reduce((prev, next) => prev + next.quantity, 0);
    data.totalPrice = items.reduce((prev, next) => prev + next.lineItemTotalPrice, 0);
    data.totalLineItemPrice = items.reduce((prev, next) => prev + next.lineItemTotalPrice, 0);
    data.receivableDate = receivableDate;
    data.deliveryDate = moment().add(1, "days").format("YYYY-MM-DD");
    data.billToLocationId = selectedBillTo.id;
    data.vendorLocationId = selectedLocation.id;
    data.source = "CC";
    data.taxRateType = taxRateType;
    this.setState({
      data,
    });
  };

  validatePOLineItemBeforeSave = debounce(async (status) => {
    this._currentButtonClicked = status;
    const { disableMarginValidation } = this.state;
    if (!this.validateForm()) {
      return;
    }
    if (!this.validatePo()) {
      return;
    }
    this.preparePostData(status);
    if (disableMarginValidation) {
      this.onSave(status);
    } else {
      this.validateTotMarginForPOLineItems(status);
    }
  }, 500);

  handleSavePO = () => {
    const { isPOSaveAllowed } = this.state;
    if (isPOSaveAllowed) {
      this.onSave(this._currentButtonClicked);
    } else {
      this.handleCloseTotMarginModal();
      return;
    }
  };

  onAddItem = () => {
    const items = [...this.state.items];
    items.push({
      productName: "",
      sku: null,
      packaging: "",
      unitPrice: 0,
      sellingPrice: 0,
      category: "",
      unitMeasure: 0,
      tax: 0,
      discount: 0,
      quantity: 0,
      comment: "NA",
      lineItemTotalPrice: 0
    });
    this.setState({
      items
    });
  };

  openVendorProductSearch = () => {
    this.setState({
      openVendorProductSearchBox: true,
    });
  }

  handleVendorProductClose = () => {
    this.setState({
      openVendorProductSearchBox: false,
    });
  }

  removeItem = i => {
    const { items } = this.state;
    const newItems = items.filter(
      item => item.productItemId !== i.productItemId
    );

    this.setState({
      items: newItems
    });
  };

  addOtherCharges = event => {
    const { data } = this.state;
    let { value } = event.target;
    if (isNaN(value)) {
      value = data.additionalCharges;
    }
    this.setState({
      otherCharges: value,
      data: {
        ...data,
        additionalCharges: value
      }
    });
  };

  onChangeVendorLocation = (selectedLocation) => {
    const { id, name } = selectedLocation;
    this.setState({
      selectedLocation,
      locationId: id,
      vendorName: name,
      invalidVendorLocation: false,
      nameText: '',
      items: []
    }, () => {
      this.getVendorCatalogByLocationId(id);
      this.handleFetchTermsAndCondition(id);
    });
  };

  showConfirm = (selectedLocation) => {
    confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content: 'Changing vendor will remove all the line item. Do you want to remove?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => { this.onChangeVendorLocation(selectedLocation) },
      onCancel: () => { },
    });
  }

  handleVendorLocationSelection = (selectedLocation) => {
    const { items } = this.state;
    if (!isEmpty(selectedLocation)) {
      if (items.length > 0) {
        this.showConfirm(selectedLocation);
      } else {
        this.onChangeVendorLocation(selectedLocation);
      }
    } else {
      this.setState({
        selectedLocation: null,
        vendorCatalog: [],
        termsAndConditions: ''
      });
    }
  };

  getVendorCatalogByLocationId = async (locationId) => {
    try {
      versionDict.catalogRequestId += 1;
      let prevRequestId = versionDict.catalogRequestId;
      const response = await apiCall.sendRequest('get', fetchVendorCatalogByLocationId(locationId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.catalogRequestId) {
        this.setState({
          vendorCatalog: data,
        });
      }
    } catch (err) {
      this.setState({
        vendorCatalog: []
      });
    }
  };

  handleFetchTermsAndCondition = async (vendorLocationId) => {
    try {
      versionDict.termsAndConditionRequestId += 1;
      let prevRequestId = versionDict.termsAndConditionRequestId;
      const response = await apiCall.sendRequest('get', getTermsConditions(vendorLocationId));
      const { data: { termsAndConditions = '' } = {} } = response;
      if (prevRequestId == versionDict.termsAndConditionRequestId) {
        this.setState({
          termsAndConditions,
        });
      }
    } catch (err) {
      this.setState({
        termsAndConditions: ''
      });
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  addExtraCharge = event => {
    if (event.target.value === 1) {
      this.setState({
        extraCharge: true
      });
    } else {
      const { data } = this.state;
      this.setState({
        extraCharge: false,
        otherCharges: 0,
        data: {
          ...data,
          additionalCharges: 0
        }
      });
    }
  };

  openTermsAndConditionsModel = () => {
    const { openTermsAndConditions } = this.state;
    if (this.state.locationId) {
      this.setState({ openTermsAndConditions: !openTermsAndConditions });
    }
    else {
      Toast.error('Please select vendor')
    }
  };

  updateTermsAndConditions = (value) => {
    this.setState({ termsAndConditions: value })
    this.openTermsAndConditionsModel()
  }

  renderOrgs = () =>
    this.state.orgs.map(org => (
      <MenuItem key={org.id} value={org}>
        {org.name}
      </MenuItem>
    ));

  renderOrgLocations = () =>
    this.state.orgLocations.map(location => (
      <MenuItem key={location.id} value={location}>
        {location.name}
      </MenuItem>
    ));

  renderWarehouses = () =>
    this.state.warehouses.map(warehouse => (
      <MenuItem key={warehouse.id} value={warehouse}>
        {warehouse.name}
      </MenuItem>
    ));

  onPOItemClick = (productItem = {}) => {
    const { vendorProductItemId = null, productName = '' } = productItem;
    this.setState({
      selectedProductItemId: vendorProductItemId,
      selectedProductName: productName
    })
  }

  getAnalyticsComponent = () => {
    const { selectedProductItemId, selectedShipTo, selectedProductName } = this.state;
    return (
      <StockAnalytics
        vendorProductItemId={selectedProductItemId}
        warehouseId={selectedShipTo.id}
        productName={selectedProductName}
      />
    )
  }

  toggleSidePanel = () => {
    const { hideSidePanel } = this.state;
    this.setState({
      hideSidePanel: !hideSidePanel,
    });
  }

  toggleProgressBar = (state) => {
    this.setState({
      disableButton: state,
    });
  }

  handleDownloadCSV = async () => {
    const { selectedCompany, selectedBillTo, selectedShipTo, receivableDate, selectedLocation, taxRateType } = this.state;
    const { name = '', id } = selectedLocation;
    let filename = `PO-${name || 'NEW'}${taxRateType ? `-${taxRateType}` : ''}.csv`;
    this.toggleProgressBar(true);

    try {
      const response = await apiCall.sendRequest('get', downloadPOTemplateByParams(selectedShipTo.id, id, selectedCompany.name, selectedBillTo.id, receivableDate, '', taxRateType), null, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
      this.toggleProgressBar(false);
    } catch (err) {
      console.log(err);
      this.toggleProgressBar(false);
    }
  }

  handleUploadPOCSV = (event, id) => {
    let selectedFile = event.target.files[0];
    this.uploadPOcsv(selectedFile);
    this.inputCSVFileRef[id].value = '';
  }

  uploadPOcsv = async (selectedFile) => {
    const { selectedBillTo, selectedShipTo, receivableDate, selectedLocation, taxRateType } = this.state;
    this.toggleProgressBar(true);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const response = await apiCall.sendRequest('post', uploadPOcsvByParams(selectedShipTo.id, selectedLocation.id, selectedBillTo.id, receivableDate, '', taxRateType), formData);
      const { data: { data: { id = null } = {} } = {} } = response;
      this.toggleProgressBar(false);
      this.handleOpenEditPOById(id);
    } catch (e) {
      // error handling
      this.toggleProgressBar(false);
    }
  }

  handleOpenEditPOById = (poId) => {
    const { history } = this.props;
    history.push({
      pathname: `/edit-po/${poId}`,
      state: 'csv-uploaded',
    });
  }

  csvDownloadUploadValidation = (data) => {
    const { selectedLocation, selectedCompany, selectedBillTo, selectedShipTo } = data;
    if (isEmpty(selectedLocation) || isEmpty(selectedCompany) || isEmpty(selectedBillTo) || isEmpty(selectedShipTo)) {
      return true
    } else {
      return false
    }
  }

  handleOpenTotMarginModal = () => {
    this.setState({
      openTotMarginModal: true,
    });
  };

  handleCloseTotMarginModal = () => {
    this.setState({
      openTotMarginModal: false,
    });
  };

  getCollapseHeaderTitle = (errorType) => {
    switch (errorType) {
      case "should": return 'Warning';
      case "must": return 'Error';
      default: return 'Intervention By Procurement Head';
    }
  };

  handleDownloadMissingTotMargin = debounce(async () => {
    const { totMarginData, selectedShipTo } = this.state;
    const { missing = [] } = totMarginData;
    const missingIds = missing.map(el => el.id);
    const url = downloadTotMarginCSV(selectedShipTo.id);
    const filename = `TOT-Margin-${moment().format("DD-MM-YYYY_HH:mm")}.csv`;
    try {
      const response = await apiCall.sendRequest('post', url, missingIds, { Accept: 'text/csv' });
      const { data = {} } = response;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
    } catch (err) {
      console.log(err.response);
    }
  }, 500);

  handleOpenLocationSelector = () => {
    this.setState({
      openLocationSelector: true,
    });
  };

  handleCloseLocationSelector = () => {
    this.setState({
      openLocationSelector: false,
    });
  };

  render() {
    const {
      receivableDate,
      openTermsAndConditions,
      termsAndConditions,
      locationId,
      selectedCompany,
      selectedBillTo,
      selectedShipTo,
      items,
      extraCharge,
      otherCharges,
      data,
      isLoading,
      dateText,
      nameText,
      hideSidePanel,
      emptyItemsText,
      emptyQuantityText,
      vendorCatalog,
      disableButton,
      selectedLocation,
      openTotMarginModal,
      modalButtonText,
      totMarginData,
      disableMarginValidation,
      invalidVendorLocation,
      openLocationSelector,
      openVendorProductSearchBox
    } = this.state;

    const { User, classes, history } = this.props;
    const innerHeightPercent = 0.57 - ((window.innerHeight - 640) * 0.00065);
    const { id = null } = selectedShipTo;
    const calculateZoom = (75 + ((window.innerHeight - 640) / 12.48)).toFixed(2);
    document.body.style.zoom = `${calculateZoom}%`;
    const isButtonDisable = this.csvDownloadUploadValidation({ selectedLocation, selectedCompany, selectedBillTo, selectedShipTo });
    const { location: { state: { rateType = '', warehouse = {} } } = {} } = history;

    return (
      <div>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <SaveBar onSave={this.validatePOLineItemBeforeSave} isLoading={isLoading} taxRateType={rateType} />
        <div style={{ width: '95%', margin: '10px auto' }}>
          <div style={{
            height: hideSidePanel ? window.innerHeight * 0.06 : window.innerHeight * innerHeightPercent,
            border: '2px solid #AAB7B8',
            borderRadius: '20px',
            padding: !hideSidePanel && '0px 10px 10px 10px',
          }}
          >
            {!hideSidePanel && <Row>
              <ColMod lg={9} custompadding="true">
                <Row>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <TextField
                      label="Requester"
                      value={User.email}
                      fullWidth
                      disabled
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
                    <TextField
                      id="input-with-icon-textfield"
                      label="Vendor Location"
                      fullWidth
                      required
                      name="vendorLocation"
                      onClick={this.handleOpenLocationSelector}
                      value={!isEmpty(selectedLocation) ? selectedLocation.name : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                    />
                    {invalidVendorLocation && <small style={{ color: 'red' }}>{nameText}</small>}
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1, margin: "10px" }}>
                    <InputLabel htmlFor="name-readonly" style={{ fontSize: '1em' }}>Company Name</InputLabel>
                    <Select
                      name="selectedCompany"
                      value={selectedCompany}
                      fullWidth
                      onChange={this.handleChange}
                      disabled
                    >
                      {this.renderOrgs()}
                    </Select>
                  </div>
                  <div style={{ flex: 1, margin: "10px" }}>
                    <InputLabel htmlFor="name-readonly" style={{ fontSize: '1em' }}>Bill To</InputLabel>
                    <Select
                      name="selectedBillTo"
                      value={selectedBillTo}
                      fullWidth
                      onChange={this.handleChange}
                    >
                      {this.renderOrgLocations()}
                    </Select>
                  </div>
                </Row>
                <Row>
                  <div style={{ flex: 1, margin: "10px" }}>
                    <InputLabel htmlFor="name-readonly" style={{ fontSize: '1em' }}>Ship To</InputLabel>
                    {/* <Select
                      name="selectedShipTo"
                      value={selectedShipTo}
                      fullWidth
                      onChange={this.handleChange}
                    >
                      {this.renderWarehouses()}
                    </Select> */}
                    <TextField
                      fullWidth
                      name="selectedShipTo"
                      type="text"
                      value={selectedShipTo.name}
                      disabled
                    />
                  </div>
                  <div style={{ flex: 1, margin: "10px" }}>
                    <InputLabel htmlFor="name-readonly" style={{ fontSize: '1em' }}>Receivable Date</InputLabel>
                    <TextField
                      id="date"
                      fullWidth
                      name="receivableDate"
                      type="date"
                      value={receivableDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handleChange}
                    />
                    <small style={{ color: 'red' }}>{dateText}</small>
                  </div>
                </Row>
              </ColMod>
              <ColMod lg={3} custompadding="true">
                <div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ paddingTop: '10px' }}>
                        <Typography variant="body2" color="secondary" >
                          Vendor Info:
                        </Typography>
                      </div>
                      <IconButton size="small" color="primary" style={{ backgroundColor: '#CCC' }}>
                        <KeyboardArrowUpIcon onClick={this.toggleSidePanel} />
                      </IconButton>
                    </div>
                    {Object.keys(selectedLocation || {}).length > 0 &&
                      <div style={{ marginBottom: '10px' }}>
                        <Typography variant="body2" color="primary">
                          {selectedLocation.name}
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          {selectedLocation.fullAddress}
                        </Typography>
                      </div>}
                  </div>
                  {selectedCompany && selectedBillTo ? (
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                      <Typography variant="body2" color="secondary">
                        Company Info: &nbsp;&nbsp;
                      </Typography>
                      <Typography variant="body2" color="primary">
                        {selectedCompany.name}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectedBillTo ? (
                    <div style={{ marginBottom: '10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" color="secondary">
                          Bill To: &nbsp;&nbsp;
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {selectedBillTo.name}
                        </Typography>
                      </div>
                      <Typography variant="body1" color="inherit">
                        {selectedBillTo.fullAddress}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {selectedShipTo ? (
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="body2" color="secondary">
                          Ship To: &nbsp;&nbsp;
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {selectedShipTo.name}
                        </Typography>
                      </div>
                      <Typography variant="body1" color="inherit">
                        {selectedShipTo.fullAddress}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </ColMod>
            </Row>}
            {hideSidePanel && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ marginTop: '10px' }}><b>Requester:&nbsp;&nbsp;</b>{User.email}</p>
              <p style={{ marginTop: '10px' }}><b>Vendor Name:&nbsp;&nbsp;</b>{selectedLocation.name || ''}</p>
              <p style={{ marginTop: '10px' }}><b>Company Name:&nbsp;&nbsp;</b>{selectedCompany.name}</p>
              <IconButton size="small" color="primary" style={{ backgroundColor: '#CCC' }}>
                <KeyboardArrowDownIcon onClick={this.toggleSidePanel} />
              </IconButton>
            </div>}
          </div>
          <Row>
            <ColMod lg={12}>
              <Row>
                <div style={{ flex: 1 }}>
                  {/* {emptyQuantityText && <Typography color="primary">{emptyQuantityText}</Typography>} */}
                  <PurchaseOrderItemTable
                    items={items}
                    hideCol={[]}
                    removeItem={this.removeItem}
                    onChange={this.onChangeItem}
                    emptyQuantityText={emptyQuantityText}
                  // onRowClick={this.onPOItemClick}
                  // expandRow
                  // getExpandedComponent={this.getAnalyticsComponent}
                  />
                </div>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'initial' }}>
                  <Button disabled={isEmpty(selectedLocation)} color="primary" variant="outlined" onClick={this.openVendorProductSearch} style={{ border: emptyItemsText && '5px solid red' }}>
                    Add Item
                  </Button>
                  <Typography color="default" style={{ marginTop: '10px', marginLeft: '20px', width: '180px', marginRight: '20px' }}>{emptyItemsText}</Typography>
                  <AntdButton icon={<DownloadOutlined />} size="large" onClick={this.handleDownloadCSV} disabled={disableButton || isButtonDisable}>
                    Download
                  </AntdButton>
                  <div style={{ width: '20px' }}></div>
                  <div>
                    <input ref={ref => this.inputCSVFileRef[id] = ref} type="file" accept=".csv" style={{ display: 'none' }} onChange={(e) => this.handleUploadPOCSV(e, id)} />
                    <AntdButton type="primary" icon={<CloudUploadOutlined />} size="large" onClick={() => this.inputCSVFileRef[id].click()} disabled={disableButton || isButtonDisable}>
                      Upload{' '}
                    </AntdButton>
                  </div>
                </div>
              </Row>
              <Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    flex: 1
                  }}
                >
                  <Typography variant="body1" color="secondary">
                    Extra charges/ discounts
                  </Typography>
                </div>
              </Row>
              <Row
                style={{
                  borderBottom: "solid 1px grey",
                  paddingBottom: "30px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    flex: 1
                  }}
                >
                  {extraCharge ? (
                    <TextField
                      value={otherCharges}
                      onChange={this.addOtherCharges}
                      style={{ marginLeft: "10px", width: "50px" }}
                    />
                  ) : (
                    <TextField
                      value={0}
                      style={{ marginLeft: "10px", width: "50px" }}
                      disabled
                    />
                  )}
                  <Select
                    value={extraCharge ? 1 : "none"}
                    onChange={this.addExtraCharge}
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Other Charges</MenuItem>
                  </Select>
                </div>
              </Row>
              <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    flex: 1
                  }}
                >
                  <Typography
                    variant="title"
                    color="primary"
                    style={{ marginLeft: "20px" }}
                  >
                    {data ? (items.reduce((prev, next) => prev + (next.quantity * next.sellingPrice * (1 + 0.01 * next.tax)), 0) + (data.additionalCharges ? parseFloat(data.additionalCharges) : 0)).toFixed(2) : 0}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    Total Order Amount
                  </Typography>
                </div>
              </Row>
              <Row style={{ marginBottom: "10px", marginTop: "20px" }}>
                <div style={{ flex: '1' }}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="body1" color="default">
                      Vendor Terms & Conditions
                    </Typography>
                    <Edit
                      style={styles.editIcon}
                      onClick={this.openTermsAndConditionsModel}
                    />
                  </div>
                  <div className={classes.termConditionsContainer} onClick={this.openTermsAndConditionsModel}>
                    <TextField
                      className={classes.termsAndConditionDiv}
                      multiline={true}
                      rows={4}
                      rowsMax={28}
                      value={termsAndConditions}
                      disabled
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.bootstrapRoot,
                          input: classes.bootstrapInput
                        }
                      }}
                    />
                  </div>

                  <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    anchor="right"
                    open={openTermsAndConditions}
                    onClose={this.openTermsAndConditionsModel}
                  >
                    <TermsAndConditionsEditCreatePo
                      open={openTermsAndConditions}
                      value={termsAndConditions}
                      onClose={this.openTermsAndConditionsModel}
                      vendorlocationId={locationId ? locationId.id : 0}
                      updateTermsAndConditions={this.updateTermsAndConditions}
                    />
                  </Drawer>
                </div>
              </Row>
            </ColMod>
          </Row>
        </div>
        <VendorProductSelection
          open={openVendorProductSearchBox}
          handleClose={this.handleVendorProductClose}
          removeItem={this.removeItem}
          onChange={this.onChangeItem}
          items={items}
          vendorCatalog={vendorCatalog}
          taxRateType={rateType}
          warehouseId={selectedShipTo.id}
          disableMarginValidation={disableMarginValidation}
        />
        <PoTotMarginModal
          open={openTotMarginModal}
          onSavePO={this.handleSavePO}
          onCloseTotMarginModal={this.handleCloseTotMarginModal}
          modalButtonText={modalButtonText}
          data={totMarginData}
          getCollapseHeaderTitle={this.getCollapseHeaderTitle}
          onDownloadMissingItem={this.handleDownloadMissingTotMargin}
        />
        <VendorLocationSelector
          open={openLocationSelector}
          onClose={this.handleCloseLocationSelector}
          onSelect={this.handleVendorLocationSelection}
          warehouseId={warehouse.id}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  User: state.User
});

DashboardComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(withStyles(styles)(withRouter(DashboardComponent)));
