import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {  Table, 
          TableBody, 
          TableCell,
          TableHead,
          TablePagination,
          TableRow,
          TableSortLabel,
          Button, 
          Tooltip, 
        } from '@material-ui/core';
import apiCall from "../api/NetworkHandler";
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

const columnData = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Vendor ID' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Vendor Name' },
  { id: 'web', numeric: false, disablePadding: true, label: 'Website' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'phone', numeric: false, disablePadding: true, label: 'Work Phone' },
  { id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile' },
  { id: 'action', numeric: false, disablePadding: true, label: 'Actions' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
    if (this.props.links.next || this.props.links.prev) {
      this.setState({
        page: this.props.data.length ? Math.floor((this.props.data.length-1)/20) : 0,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data !== this.state.data) {
        this.setState({
          data: nextProps.data,
        });
        if (nextProps.links.next || nextProps.links.prev) {
          this.setState({
            page: nextProps.data.length ? Math.floor((nextProps.data.length-1)/20) : 0,
          });
        }
      }
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, vendor) => {
    const {href} = vendor._links.vendor;
    apiCall.sendRequest('get', href.split('/').slice(3).join('/'))
      .then(res => {
        this.setState({ selected: vendor.id });
        this.props.getVendorData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChangePage = (event, page) => {
    if (this.props.searchedResult) {
      this.setState({page});
    } else {
      let url = "";
      if (this.state.page < page) {
        url = this.props.links.next.href;
      } else {
        url = this.props.links.prev.href;
      }
      this.props.fetchPaginatedVendor(url);
    }
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getEmail = (email) => {
    if (email && email.split(',').length > 1) {
      return `${email.split(',')[0]}...`
    }
    return email;
  }

  isSelected = id => this.state.selected === id

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, rowsPerPageOptions } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      {n.id}
                    </TableCell>
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">{n.name}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.website}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{this.getEmail(n.email)}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.workPhone}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.mobile}</TableCell>
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      <Link to={`/edit-vendor/${n.id}`} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" color="secondary" size="small">
                          Edit
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {(this.props.searchedResult) ? 
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
          : <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              disabled: !this.props.links.prev,
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              disabled: !this.props.links.next,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        }
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);