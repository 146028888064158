import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import NavBar from '../../../components/NavBar';
import BuildingSearchBar from '../../../components/BuildingSearchBar';
import apiCall, { getFetchAllBuildings, getBuildingPath, getCreateNewBuildingPath } from '../../../api/NetworkHandler';
import UserSelection from '../../../components/UserSelection';
import TableHeader from '../../Commons/components/TableHeader';
import { ColMod } from '../../../components/lib';
import SelectedItemDetail from '../../Commons/components/SelectedItemDetail';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import isEmpty from "lodash/isEmpty";
import Toast from "../../../utils/Toast";

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'street',
    numeric: false,
    disablePadding: true,
    label: 'Street',
  },
  {
    id: 'landmark',
    numeric: false,
    disablePadding: true,
    label: 'Landmark',
  },
  {
    id: 'locality',
    numeric: false,
    disablePadding: true,
    label: 'Locality',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: true,
    label: 'Active',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

const suggestions = [
  { label: 'Afghanistan' },
  { label: 'Aland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

class BuildingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddBuilding: false,
      selectedBuilding: {},
      building: [],
      links: {},
      filters: {
        query: '',
      },
      searchedResult: false,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      openUserSelector: false,
      postData: {},
      error: {},
    };
  }

  componentDidMount() {
    this.fetchAllBuildings();
  }


  onSearch = (event) => {
    if (event.key === 'Enter') {
      this.fetchAllBuildings();
    }
  };

  onChangeValue = (event, id) => {
    const { postData } = this.state;
    const val = event.target.value;
    if (id === 'active') {
      postData[id] = val === 'ACTIVE';
      this.setState({ postData });
    } else {
      postData[id] = event.target.value;
      this.setState({ postData });
    }
  };

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };


  setSelectedData = (data) => {
    const { postData, userType } = this.state;
    if (userType === 'Locality') {
      const { name } = data;
      postData.locality = name;
      this.setState({ postData, openUserSelector: false, userType: '' });
    } else if (userType === 'City') {
      const { name } = data;
      postData.city = name;
      this.setState({ postData, openUserSelector: false, userType: '' });
    }
  };

  isPropertyValid = (key, value) => {
    if (isEmpty(value)) {
      this.setError(key, true);
      return false;
    }
    this.setError(key, false);
    return true;
  };


  fetchAllBuildings = () => {
    const { filters: { query = '' } = {} } = this.state;
    if (query) {
      apiCall
        .sendRequest('get', getFetchAllBuildings(query))
        .then(({ data }) => {
          const { _embedded = {}, _links: links } = data;
          const { buildings } = _embedded;
          this.setState({
            building: buildings,
            links,
            searchedResult: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      apiCall
        .sendRequest('get', getBuildingPath)
        .then(({ data }) => {
          const { _embedded = {}, _links: links } = data;
          const { buildings } = _embedded;
          this.setState({
            links,
            building: buildings,
            searchedResult: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  isFormValid = () => {
    const building = {};
    building.city = this.state.postData.city;
    building.locality = this.state.postData.locality;
    building.name = this.state.postData.name;
    let isValid = true;

    const validateEntities = ['city', 'locality', 'name'];

    for (const property in building) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, building[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

  fetchPaginatedBuilding = (url) => {
    apiCall
      .sendRequest('get', url)
      .then(({ data }) => {
        const { _embedded = {}, _links: links } = data;
        const { buildings } = _embedded;
        this.setState({
          building: buildings,
          links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleQueryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value,
      },
    }));
  };

  handleClick = (selectedBuilding) => {
    this.setState({
      selectedBuilding,
    });
  };

  openAddBuilding = (building) => {
    this.setState({
      openAddBuilding: true,
    });
  };

  editBuilding = (n) => {
    this.setState(
      {
        postData: { ...n },
      },
      () => {
        this.openAddBuilding(n);
      },
    );
  };

  handleNewBuildingClose = () => {
    this.setState({
      openAddBuilding: false,
      selectedBuilding: {},
      error: {},
      postData: {},
    });
  };

  openUserSelector = (userType, userValue) => () => {
    this.setState({
      openUserSelector: true,
      userType,
      userValue,
    });
  };

  handleClose = () => {
    this.setState({
      openUserSelector: false,
      error: {},
      userType: '',
    });
  };

  handleChangePage = (event, page) => {
    const { links: { prev = {}, next = {} } = {}, searchedResult } = this.state;
    const { href: prevHref } = prev;
    const { href: nextHref } = next;
    if (searchedResult) {
      this.setState({ page });
    } else {
      let url = '';
      if (this.state.page < page) {
        url = nextHref;
      } else {
        url = prevHref;
      }
      if (url) {
        this.fetchPaginatedBuilding(url);
      }
    }
  };

  createNewBuilding = () => {
    if (!this.isFormValid()) {
      return;
    }
    const { postData } = this.state;
    if (postData.id) {
      apiCall.sendRequest('patch', getCreateNewBuildingPath(postData.id), postData)
        .then((response) => {
          this.fetchAllBuildings();
          this.handleNewBuildingClose();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('post', getBuildingPath, postData)
        .then((response) => {
          this.fetchAllBuildings();
          this.handleNewBuildingClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const {
      rowsPerPage,
      rowsPerPageOptions,
      page,
      building,
      selectedBuilding,
      searchedResult, postData, userType, userValue, openUserSelector,
    } = this.state;
    const {
      active, city, locality, name, street, pincode, landmark,
    } = postData;
    return (
      <div>
        <NavBar />
        <BuildingSearchBar
          onSearch={this.onSearch}
          openAddBuilding={this.openAddBuilding}
          query={this.state.filters.query}
          handleQueryChange={this.handleQueryChange}
        />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
              <TableHeader columns={columnData} />
              <TableBody>
                {building.map(n => (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(n)}
                    tabIndex={-1}
                    key={n.id}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    selected={selectedBuilding && selectedBuilding.id === n.id}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      {n.name}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.street}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.landmark}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.locality}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.city}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div>
                        {n.active ? 'true' : 'false'}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <span>
                        <Button size="small" color="secondary" onClick={(event) => { event.stopPropagation(); this.editBuilding(n); }}>EDIT</Button>
                      </span>
                    </TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
            {(searchedResult) ?
              <TablePagination
                component="div"
                count={building.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
              : <TablePagination
                component="div"
                count={building.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                    disabled: !this.state.links.prev,
                  }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                    disabled: !this.state.links.next,
                  }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            }
          </div>
          <div style={styles.paperRight}>
            { Object.keys(selectedBuilding).length
              ?
                <SelectedItemDetail item={selectedBuilding} />
              : <div />}
          </div>
        </div>
        <UserSelection
          userType={userType}
          userValue={userValue}
          openSearchBox={openUserSelector}
          handleClose={this.handleClose}
          selectUser={this.setSelectedData}
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openAddBuilding}
          onClose={this.handleNewBuildingClose}
        >
          <div style={{
            background: 'white',
            width: '70%',
            height: '60%',
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleNewBuildingClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      Create/Update Building
                    </Typography>
                  </Row>
                  <Row>
                  <Typography variant="caption" color="error">
                    *Required Field
                  </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Name"
                        fullWidth
                        required
                        error={this.state.error.name}
                        value={name || ''}
                        onChange={event => this.onChangeValue(event, 'name')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Street"
                        fullWidth
                        value={street || ''}
                        onChange={event => this.onChangeValue(event, 'street')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-status">
                        Status
                      </InputLabel>
                      <MaterialSelect
                        value={active ? 'ACTIVE' : 'INACTIVE'}
                        inputProps={{
                          name: 'selectedStatus',
                          id: 'select-status',
                        }}
                        onChange={event => this.onChangeValue(event, 'active')}
                        fullWidth
                      >
                        <MenuItem value="ACTIVE">Active</MenuItem>
                        <MenuItem value="INACTIVE">Inactive</MenuItem>
                      </MaterialSelect>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Locality"
                        fullWidth
                        value={locality || ''}
                        required
                        error={this.state.error.locality}
                        onChange={event => this.onChangeValue(event, 'locality')}
                        // onChange={event => {}}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={this.openUserSelector('Locality', locality)}
                            >
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="Landmark"
                        fullWidth
                        value={landmark || ''}
                        onChange={event => this.onChangeValue(event, 'landmark')}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <TextField
                        label="City"
                        fullWidth
                        required
                        error={this.state.error.city}
                        value={city || ''}
                        onChange={event => this.onChangeValue(event, 'city')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              onClick={this.openUserSelector('City', city)}
                            >
                              <Search color="secondary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod lg={4}>
                      <TextField
                        label="Pincode"
                        fullWidth
                        value={pincode || ''}
                        onChange={event => this.onChangeValue(event, 'pincode')}
                      />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button onClick={this.handleNewBuildingClose} style={{ marginRight: '2em' }} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.createNewBuilding();
                }}
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default BuildingsComponent;
