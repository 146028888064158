import axios from 'axios';
import config from '../api/config';

export const getLatestVersion = async () => {
  try {
    const response = await axios.get(config.BASE_URL + '/api/v1/application-configs/app/scmapp');
    const { data: { data: version } } = response;
    return version;
  } catch (error) {
    return "Error in fetching version";
  }
}