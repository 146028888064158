import React from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import apiCall, { cancelPoPath } from '../../../api/NetworkHandler';
import { Modal, Switch, notification, Space } from 'antd';


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 2,
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'row'
  },
  searchBar: {
    flex: 4,
  },
  calendar: {
    flex: 1,
    textAlign: 'center',
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 5,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: '#f50057',
  },
  taxRateType: {
    backgroundColor: 'gold',
    marginLeft: '10px',
    fontWeight: 'bold',
    height: '30px',
    borderRadius: '10px',
    padding: '5px'
  }
};

class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      poId: '',
      visible: false,
      confirmLoading: false,
      cancelError: '',
      sendCancellationEmail: false,
      isEmailSent: false,
    };
  }

  componentWillMount() {
    const { match: { params } } = this.props;
    this.setState({ poId: params.id });
  }

  handleBack() {
    const { comingFrom = '' } = this.props;
    if (comingFrom === 'csv-uploaded') {
      this.props.history.go(-2);
    } else {
      this.props.history.goBack()
    }
  }
 
  savePo = () => {
    this.props.onSave('NEW');
  }

  submitPo = () => {
    this.props.onSave('ORDERED');
  }

  cancelPo = () => {
    this.props.onSave('CANCELLED');
  }

  openNotificationWithIcon = type => {
    notification[type]({
      description: this.state.successMessage,
      top: '100px'
    });
  };

  getMailStatus = () => {
    const { data } = this.props;
    if (!data) return;
    if (!data.emailSent) return false;
    if (data.emailSent) return true;
  }

  handleOpenPopUp = () => {
    this.setState({
      visible: true,
      isEmailSent: this.props.data ? this.props.data.emailSent : null,
      sendCancellationEmail: this.getMailStatus(),
      cancelError: null,
    });
  }

  handleOk = async () => {
    const { poId, sendCancellationEmail } = this.state;
    const { onChangeData } = this.props;
    this.setState({ confirmLoading: true })
    try {
      const response = await apiCall.sendRequest('put', cancelPoPath(poId, sendCancellationEmail), {});
      this.setState({ 
        confirmLoading: false, 
        visible: false,
        data: response.data.data,
        successMessage: response.data.message
      });
      onChangeData(this.state.data);
      this.openNotificationWithIcon('info');
    } catch (error) {
      this.setState({ 
        confirmLoading: false, 
        cancelError: error.response.data.message,
        visible: false
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      cancelError: null,
    });
  };

  handleToggle = () => {
    this.setState({
      sendCancellationEmail: !this.state.sendCancellationEmail,
    });
  }

  render() {
    const { status = 'NEW', isLoading = false, data, taxRateType } = this.props;
    const { visible, confirmLoading, cancelError, sendCancellationEmail, isEmailSent } = this.state;
    const path = this.props.location.pathname.split('/');
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
            { this.props.header ? this.props.header : 'Create Purchase Order' }
          </Typography>
          {taxRateType && <span style={styles.taxRateType}>{taxRateType}</span>}
        </div>
        <div style={styles.searchBar} />
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary">
            <Button color="primary" variant="outlined" onClick={() => {this.handleBack()}}>Back</Button>
          </Typography>
        </div>
        <div style={styles.add}>
          <Button onClick={this.savePo} variant="contained" size="large" color="primary" disabled={(path.length === 4 && path[3] === 'view') || isLoading}>Save</Button>
        </div>
        <div style={styles.add}>
          <Button onClick={this.submitPo} variant="outlined" size="large" color="primary" disabled={(path.length === 4 && path[3] === 'view') || isLoading}>Submit</Button>
        </div>
        {this.props.header === 'Edit Purchase Order' &&
        <div style={styles.add}>
          <Button onClick={this.handleOpenPopUp} variant="outlined" size="medium" color="secondary" disabled={['CLOSED', 'CANCELLED', 'RECEIVING'].includes(status)}>Cancel Order</Button>
        </div>}
        <Modal
          title="Cancel Purchase Order"
          centered
          visible={visible}
          confirmLoading={confirmLoading}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={sendCancellationEmail ? 'Cancel and Send Email' : 'Cancel Order'}
          cancelText="Close"
        >
          {cancelError ? <p style={{ color: 'red' }}>{cancelError}</p> : 
          <div>
            <p>Do you want to cancel the order ?</p>
            {typeof isEmailSent === 'undefined' && <p>If you don't want to send email, toggle the switch <span style={{ paddingLeft: '5px' }}><Switch size="small" onChange={this.handleToggle}/></span></p>}
          </div>}
        </Modal>
      </div>
    );
  }
}

export default withRouter(SearchBar);
