import React, { Component } from 'react'
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Create";
import apiCall, { getProductCatalogVendors, getProductCatalogVendor, deleteVendorLocationMapping } from '../../../api/NetworkHandler';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Drawer from '@material-ui/core/Drawer';
import CatalogVendorForm from './CatalogVendorForm';
import { Tooltip, IconButton } from '@material-ui/core';
import VendorLocationSelector from "./VendorLocationSelector";
import { Popconfirm } from 'antd';

const columnData = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'Vendor ID',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Vendor Name',
  },
  {
    id: 'companyName', numeric: false, disablePadding: true, label: 'Company Name',
  },
  {
    id: 'contactNumber', numeric: false, disablePadding: true, label: 'Contact Number',
  },
  {
    id: 'email', numeric: false, disablePadding: true, label: 'Email',
  },
  {
    id: 'address', numeric: false, disablePadding: true, label: 'Address',
  },
  {
    id: "actions", numeric: false, disablePadding: true, label: 'Actions',
  }
]

class CatalogVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      openVendorForm: false,
      editingVendor: {},
    }
  }

  componentDidMount() {
    this.fetchVendors();
  }

  fetchVendors = async () => {
    const { catalogId } = this.props;
    try {
      const response = await apiCall.sendRequest("get", getProductCatalogVendors(catalogId));
      const { data } = response;
      this.setState({ vendors: data })
    } catch (error) {

    }
  }

  handleSelect = async (selectedData) => {
    const { productCatalog } = this.props;
    const { id } = productCatalog;
    try {
      await apiCall.sendRequest("post", getProductCatalogVendor(id), selectedData);
      this.fetchVendors();
    } catch (error) {
      console.log(error.response);
    }
  }

  handleDeleteLocationMapping = async (vendor) => {
    const { productCatalog } = this.props;
    const { vendorCatalogId } = productCatalog;
    try {
      await apiCall.sendRequest('delete', deleteVendorLocationMapping(vendorCatalogId, vendor.id));
      this.fetchVendors();
    } catch (err) {
      console.log(err.response);
    }
  }

  getActions = (vendor) => {
    return (
      <div>
        <Tooltip title="Edit">
          <IconButton>
            <Edit color="primary" onClick={() => { this.handleVendorFormOpen(vendor) }}></Edit>
          </IconButton>
        </Tooltip>
        <Popconfirm
          title="Are you sure you want to delete vendor location mapping?"
          onConfirm={() => this.handleDeleteLocationMapping(vendor)}
          okText="Yes"
          cancelText="No"
        >
          <IconButton>
            <DeleteIcon color="default"></DeleteIcon>
          </IconButton>
        </Popconfirm>
      </div>
    )
  }

  handleVendorFormOpen = (vendor = {}) => {
    const { vendors } = this.state;
    const index = vendors.findIndex(item => item.id === vendor.id);
    this.setState({
      openVendorForm: true,
      editingVendor: vendors[index],
    });
  }

  handleVendorFormClose = () => {
    this.setState({ openVendorForm: false, editingVendor: {} })
  }

  getCustomComponents = () => {
    const customComponents = {
      address: {},
      contactNumber: {},
    };
    const { vendors } = this.state;

    vendors.map((vendor, index) => {
      const { fullAddress: address, contact, contactMobile } = vendor;
      const addressLength = address.length;
      const displayAddress = addressLength > 30 ? address.substr(0, 30) + '...' : address;
      customComponents['address'][index] = <Tooltip title={address}><span>{displayAddress}</span></Tooltip>;
      customComponents['contactNumber'][index] = contact ? `${contactMobile ? `${contact} (${contactMobile})` : ''}` : contactMobile;
    })
    return customComponents;
  }

  getModifiedVendors = () => {
    const { vendors = [] } = this.state;
    let modifiedVendor = [];
    vendors.map(item => (
      modifiedVendor.push({ ...item, email: this.getEmail(item.email) })
    ));
    return modifiedVendor;
  }

  getEmail = (email) => {
    if (email && email.split(',').length > 1) {
      return `${email.split(',')[0]}...`
    }
    return email;
  }

  handleOpenVendorLocationSelector = () => {
    this.setState({
      openVendorLocationSelector: true,
    });
  }

  handleCloseVendorLocationSelector = () => {
    this.setState({
      openVendorLocationSelector: false,
    });
  }

  render() {
    const { vendors, openVendorForm, editingVendor, openVendorLocationSelector } = this.state;
    const modifiedVendor = this.getModifiedVendors();
    const { productCatalog, isLoading } = this.props;
    const { regions = [] } = productCatalog;
    return (
      <Grid>
        <Row between="md" middle="md">
          <ColMod>
            <Typography variant="title">Vendor Location</Typography>
          </ColMod>
          {/* <ColMod>
            <Button variant="outlined" color="primary" onClick={this.handleOpenVendorLocationSelector}> Location Mapping </Button>
          </ColMod> */}
          <ColMod>
            <Button variant="outlined" color="primary" onClick={this.handleOpenVendorLocationSelector} disabled={isLoading}> Associate Vendor Location </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="outlined" color="primary" onClick={() => { this.handleVendorFormOpen() }}><AddIcon /> Add Vendor Location </Button>
          </ColMod>
        </Row>
        <Row>
          <ColMod sm={12}>
            <PaginatedTable
              columnData={columnData}
              data={modifiedVendor}
              count={vendors.length}
              actions={this.getActions}
              customComponents={this.getCustomComponents()}
            />
          </ColMod>
        </Row>
        <Drawer anchor="right" open={openVendorForm} onClose={() => { this.handleVendorFormClose() }}>
          <CatalogVendorForm
            vendor={editingVendor}
            productCatalog={productCatalog}
            onClose={this.handleVendorFormClose}
            onRefresh={this.fetchVendors}
          />
        </Drawer>
        {openVendorLocationSelector &&
          <VendorLocationSelector
            openSearchBox={openVendorLocationSelector}
            handleClose={this.handleCloseVendorLocationSelector}
            parentComponent="Search Vendor Location"
            helperText="Search Location by name"
            onSelect={this.handleSelect}
            regions={regions}
            key={Date.now()}
          />
        }
      </Grid>
    )
  }
}

export default CatalogVendors;
