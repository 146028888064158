export const validateEmails = (commaSeparatedEmails) => {
    if(!commaSeparatedEmails) {
      return false;
    }
  
    const separateEmails = commaSeparatedEmails.split(",");
    const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const areAllEmailsValid = separateEmails.every(email => {
      return emailTester.test(email.trim());
    })
    if(!areAllEmailsValid){
      return false;
    }else {
      return true;
    }
  }