import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { filter } from 'lodash';
import moment from 'moment';
import OrderInfo from '../OrderInfo';
import InputDir from '../InputDir';
import ItemText from '../ItemText';
import utils from '../../../utils/queryBuilder';
import styles from './styles';

const CanView = utils.isCurrentUserAuthorizedToView;
class OrderDetails extends Component {
  constructor(props) {
    super(props);
    const selectedAddress =
      filter(props.customerAddresses, el => el.id === props.order.customerAddressId)[0] || {};
    this.state = {
      selectedAddress,
      updateBtnDisabled: true,
    };
  }

  onChangeEditOrderConfig = (e) => {
    const { name, value } = e.target;
    if (name === 'orderStatus') {
      this.setState({ updateBtnDisabled: false });
      this.props.onOrderDetailsChange({ orderStatus: value });
    }
  };

  onChangeLineItemsConfig = (e, id) => {
    const { name } = e.target;
    const { orderLineItems } = this.props;
    let { value } = e.target;
    if (name === 'deliveredQuantity' && value < 0) {
      value = 0;
    }
    this.setState({ updateBtnDisabled: false });
    this.props.onOrderDetailsChange({
      selectedOrderLineItems: orderLineItems.map((el) => {
        if (el.id === id) {
          return Object.assign({}, el, { [name]: value });
        }
        return el;
      }),
    });
  };

  getLineOptions = (name) => {
    const { coLineStatusList, complaintReasons } = this.props;
    if (name === 'complaintReason') {
      return [{ text: '--NONE--', value: null }, ...this.formatConsts(complaintReasons)];
    }
    if (name === 'status') {
      return this.formatConsts(coLineStatusList);
    }
    return null;
  };

  getEditOrderConfig = () => {
    const { coStatusList, orderStatus } = this.props;
    return [
      {
        name: 'orderStatus',
        label: 'Order Status',
        type: 'select',
        options: this.formatConsts(coStatusList),
        value: orderStatus,
        onChange: this.onChangeEditOrderConfig,
      },
    ];
  };

  getLineItemsConfig = () => [
    {
      text: 'Item',
      param: 'productName',
      style: styles.items.productName,
    },
    {
      text: 'Time Updated',
      param: 'timeUpdated',
      style: styles.items.timeUpdated,
    },
    {
      text: 'Unit Price',
      param: 'unitPrice',
      style: styles.items.unitPrice,
    },
    {
      text: 'Ordered Quantity',
      param: 'quantity',
      style: styles.items.unitPrice,
    },
    {
      text: 'Quantity Delivered',
      componet: true,
      style: styles.items.quantity,
      param: 'deliveredQuantity',
      props: {
        type: 'number',
        name: 'deliveredQuantity',
        inputStyle: styles.inputItems,
      },
    },
    {
      text: 'Status',
      componet: true,
      style: styles.items.status,
      param: 'status',
      props: {
        type: 'select',
        name: 'status',
        options: this.getLineOptions('status'),
        inputStyle: styles.inputItems,
      },
    },
    {
      text: 'Complaint Reason',
      componet: true,
      style: styles.items.reason,
      param: 'complaintReason',
      props: {
        type: 'select',
        name: 'complaintReason',
        options: this.getLineOptions('complaintReason'),
        inputStyle: styles.inputItems,
      },
    },
  ];

  formatConsts = (obj) => {
    const arr = Object.keys(obj);
    return arr.map(el => ({
      text: obj[el],
      value: el,
    }));
  };

  updateOrder = () => {
    const { order, orderStatus, orderLineItems } = this.props;
    this.props.updateOrderDetails({
      ...order,
      customerOrderLineItems: orderLineItems,
      status: orderStatus,
    });
  };

  renderItems = data => data.map(el => this.renderRow(false, el));

  renderRow = (header = false, data = {}) => {
    const lineItemsConfig = this.getLineItemsConfig();
    const cartItemStyle = data.newItem
      ? { ...styles.cartItem, ...styles.newCartItem }
      : styles.cartItem;
    return (
      <div style={header ? { ...styles.cartItem, ...styles.light } : cartItemStyle}>
        {lineItemsConfig.map((el) => {
          if (header) {
            return <div style={{ ...styles.itemStyle, ...el.style }}>{el.text}</div>;
          }
          if (!header) {
            if (el.componet) {
              return (
                <InputDir
                  {...el.props}
                  style={{ ...styles.itemStyle, ...el.style }}
                  onChange={e => this.onChangeLineItemsConfig(e, data.id)}
                  value={data[el.param]}
                />
              );
            }
            if (!el.componet) {
              return (
                <div style={{ ...styles.itemStyle, ...el.style }}>
                  {el.param.includes('time')
                    ? moment(data[el.param]).format('lll')
                    : data[el.param] || '_'}
                </div>
              );
            }
          }
          return null;
        })}
      </div>
    );
  };

  renderTable = d => (
      <div style={styles.orderItems}>
        {this.renderRow(true)}
        {this.renderItems(d)}
      </div>
  );

  render() {
    const {
      order, openModal, orderLineItems, cancelDTAction, customerInfo,
    } = this.props;
    const { selectedAddress, updateBtnDisabled } = this.state;
    const editOrderConfig = this.getEditOrderConfig();
    const disableUpdateBtn = updateBtnDisabled;
    return (
      <div>
        <Typography color="textSecondary" style={styles.title}>
          EDIT ORDER
        </Typography>

        <OrderInfo order={order} isViewOnly />

        <div style={styles.editFields}>
          <div style={styles.editFieldsLeft}>
            {
              <ItemText
                name="Contact Person"
                value={selectedAddress.customerName || customerInfo.name || '_'}
                style={styles.staticCs}
              />
            }
            {
              <ItemText
                name="Contact No"
                value={selectedAddress.mobile || customerInfo.mobile || '_'}
                style={styles.staticCs}
              />
            }
            {
              <ItemText
                name="Delivery Address"
                value={selectedAddress.fullAddress || '_'}
                style={styles.staticCs}
              />
            }
          </div>
          {editOrderConfig.map(el => (
            <InputDir {...el} style={styles.editCs} inputStyle={styles.editCsFields} />
          ))}
        </div>

        <div style={styles.editItems}>{this.renderTable(orderLineItems)}</div>
        {/* {CanView(['CC_AGENT', 'ADMIN', 'CC_MANAGER']) &&
          <Button
            style={styles.addNewBtn}
            onClick={() => openModal('productSearch', 'order')}
            color="primary"
            variant="outlined"
            size="small"
          >
            + Add New Item
          </Button>
        } */}

        {CanView(['CC_AGENT', 'ADMIN', 'CC_MANAGER']) &&
          <div style={styles.action}>
            <Button
              style={styles.btn}
              onClick={cancelDTAction}
              color="primary"
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
            <Button
              style={styles.btn}
              disabled={disableUpdateBtn}
              onClick={this.updateOrder}
              color="primary"
              variant="contained"
              size="small"
            >
              Update
            </Button>
          </div>
        }
      </div>
    );
  }
}

OrderDetails.propTypes = {
  customerAddresses: PropTypes.instanceOf(Array),
  customerInfo: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  orderStatus: PropTypes.string,
  orderLineItems: PropTypes.instanceOf(Array),
  coLineStatusList: PropTypes.instanceOf(Object),
  complaintReasons: PropTypes.instanceOf(Object),
  openModal: PropTypes.func,
  cancelDTAction: PropTypes.func,
  updateOrderDetails: PropTypes.func,
  coStatusList: PropTypes.instanceOf(Object),
  onOrderDetailsChange: PropTypes.func,
};

export default OrderDetails;
