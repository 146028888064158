import React, { Component } from 'react';
import {  Button, 
          Table, 
          TableBody, 
          TableCell, 
          Typography, 
          TableRow 
        } from '@material-ui/core';
import { Link } from 'react-router-dom';
import apiCall, { customerPlansForAddressPath } from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import TableHeader from '../../Commons/components/TableHeader';


const COMPONENT_TITLE = 'View Plan';

const columnData = [
  {
    id: 'categoryName',
    numeric: false,
    disablePadding: true,
    label: 'Category',
  },
  {
    id: 'productName',
    numeric: false,
    disablePadding: true,
    label: 'Product',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: true,
    label: 'Quantity',
  },
  {
    id: 'scheduleType',
    numeric: false,
    disablePadding: true,
    label: 'Schedule Type',
  },
  // {
  //   id: 'scheduleName',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Schedule Name',
  // },
  {
    id: 'pauseFrom',
    numeric: false,
    disablePadding: true,
    label: 'Pause From',
  },
  {
    id: 'pauseTo',
    numeric: false,
    disablePadding: true,
    label: 'Pause To',
  },
  {
    id: 'productStartDate',
    numeric: false,
    disablePadding: true,
    label: 'Start Date',
  },
  {
    id: 'productStatus',
    numeric: false,
    disablePadding: true,
    label: 'Product Status',
  },
  {
    id: 'variant',
    numeric: false,
    disablePadding: true,
    label: 'Variant',
  },

  {
    id: 'modify',
    numeric: false,
    disablePadding: true,
    label: 'Modify',
  },

];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    background: '#f0f0f0',
    height: '56px',
    alignItems: 'center',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    maxWidth: 400,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
};

class ViewPlans extends Component {
  constructor(props) {
    super(props);
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { customerId, addressId } = params;
    this.state = {
      customerId,
      addressId,
      planItems: [],
    };
  }

  componentDidMount = () => {
    this.getPlans();
  }

  getPlans = () => {
    const { customerId, addressId } = this.state;
    apiCall.sendRequest('get', customerPlansForAddressPath(customerId, addressId))
      .then((response) => {
        const { data = {} } = response;
        const { data: categories = [] } = data;
        let planItems = [];
        categories.forEach((eachCategory) => {
          const { items = [], paused: isCategoryPaused = false } = eachCategory;
          const products = items.map((eachItem) => {
            const { plan = {}, product = {} } = eachItem;
            const {
              productItems = [], category, name: productName, id: productId,
            } = product;
            const [productItem = {}] = productItems;
            const { unitMeasure, uom = {} } = productItem;
            const { code } = uom;
            const variant = `${unitMeasure} ${code}`;
            const { planItem = {} } = plan;
            const {
              startDate: productStartDate, status: productStatus, schedule = {}, pauseFrom = null, pauseTo = null, quantity = 1,
            } = planItem;
            const { name: scheduleName, scheduleType } = schedule;
            const { name: categoryName, imageUrl: categoryImageurl } = category;
            return {
              productName,
              productId,
              productItem,
              categoryName,
              categoryImageurl,
              isCategoryPaused,
              productStatus,
              productStartDate,
              scheduleName,
              scheduleType,
              pauseFrom,
              pauseTo,
              quantity,
              variant,
            };
          });
          planItems = [...planItems, ...products];
        });
        this.setState({ planItems });
      });
  }

  render() {
    const { planItems = [] } = this.state;
    const { customerId } = this.state;

    return (
      <div>
        <NavBar />
        <div style={styles.div}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">
              {COMPONENT_TITLE }
            </Typography>
          </div>
        </div>
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary" >
            <Button color="secondary" onClick={() => { this.props.history.goBack(); }}>Back</Button>
          </Typography>
        </div>
        <div style={styles.paperLeft}>
          <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
          <TableHeader columns={columnData}/>
            <TableBody>
              {planItems.map(item => (
                <TableRow
                  hover
                  onClick={() => this.handleClick(n)}
                  tabIndex={-1}
                  key={item.productId}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                    <div>
                      {item.categoryName}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {item.productName}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.quantity}
                    </div>
                  </TableCell>


                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.scheduleType}
                    </div>
                  </TableCell>
                  {/* <TableCell component="th" scope="row" padding="none">
                      <div>
                        {item.scheduleName}
                      </div>
                    </TableCell> */}
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.pauseFrom}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.pauseTo}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.productStartDate}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.productStatus}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <div>
                      {item.variant}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <span>
                      <Button size="small" color="secondary">
                        <Link to={{ pathname: `/create-customer-plan/${customerId}` }} style={{ color: 'inherit' }} onClick={this.onClickButton1}>Modify
                        </Link>
                      </Button>
                    </span>
                  </TableCell>


                </TableRow>
            ))}
            </TableBody>
          </Table>
        </div>
      </div>

    );
  }
}

export default ViewPlans;
