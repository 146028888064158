import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import apiCall, { vendorProductMappingPath, productItemsByProductId } from '../../../api/NetworkHandler';
import TableHeader from '../../Commons/components/TableHeader';
import ProductItemForm from '../../ProductCatalog/components/ProductItemForm';
import TimeUpdated from '../components/TimeUpdated';
import ProductStatus from '../components/ProductStatus';
import { handleSort } from '../../../utils/TableSort';
import orderBy from 'lodash/orderBy';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const productsColumnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'brandName', numeric: false, disablePadding: true, label: 'Brand',
  },
  {
    id: 'category', numeric: false, disablePadding: true, label: 'Category',
  },
  {
    id: 'tags', numeric: false, disablePadding: true, label: 'Tags',
  },
  {
    id: 'active', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Last Updated At',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const subProductsColumnData = [
  {
    id: 'Name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'Unit_Price', numeric: false, disablePadding: true, label: 'Unit Price',
  },
  {
    id: 'Selling_Price', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  {
    id: 'Discount', numeric: false, disablePadding: true, label: 'Discount(%)',
  },
  {
    id: 'CGST', numeric: false, disablePadding: true, label: 'CGST',
  },
  {
    id: 'Images', numeric: false, disablePadding: true, label: 'Images',
  },
  {
    id: 'active', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  productItemsContainer: {
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    height: '80%',
    marginTop: window.innerHeight * 0.09,
    marginBottom: window.innerHeight * 0.3,
    padding: '10px',
    overflow: 'scroll',
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selected: [],
      selectedProductItem: {},
      page: 0,
      rowsPerPage: 10,
      subProductsPage: 0,
      subProductsRowsPerPage: 25,
      rowsPerPageOprions: ROWS_PER_PAGE_OPTIONS,
      itemTable: [],
      editItem: false,
      updateItem: false,
      productId: null,
      productItemList: [],
      product_item_id: null,
      product_item_version_id: null,
      new_product_attrs: [{ name: '', value: '' }],
      vendorMapping: [],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      order: 'asc',
      orderBy: 'name',
    };
  }

  handleClick = (event, data) => {
    this.setState({ selected: data.v_id });
  }

  handleChangePage = (event, subProductsPage) => {
    this.setState({ subProductsPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ subProductsRowsPerPage: event.target.value });
  }

  fetchVendorProductMapping = async (id) => {
    const url = vendorProductMappingPath(id);
    const res = await apiCall.sendRequest('get', url);
    const { data: { data = [] } = {} } = res;
    this.setState({ vendorMapping: data });
  }

  isSelected = id => this.state.selected === id

  showItemDialog(n) {
    this.setState(prevState => ({
      selectedProductItem: n,
      itemTable: [],
      updateItem: true,
      adding: false,
      product_item_id: n.id,
      product_item_sku: n.sku,
      product_item_version_id: n.versionId,
      new_product_attrs: n.productItemAttributes.length ? n.productItemAttributes : [{ name: '', value: '' }],
      urls: [
        ...prevState.urls.map((url) => {
          const obj = n.productItemImages.find(imgs => imgs.position === url.position);
          return {
            ...url,
            imageUrl: obj ? obj.imageUrl : null,
            id: obj ? obj.id : null,
            versionId: obj ? obj.versionId : null,
          };
        }),
      ],
    }));
    this.fetchVendorProductMapping(n.id);
  }

  fetchProductItemList = () => {
    const { productId } = this.state;
    if (productId) {
      apiCall.sendRequest('get', productItemsByProductId(productId))
        .then((response) => {
          const { data: { data = {} } } = response;
          this.setState({
            productItemList: data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { order, orderBy: orderByKey } = this.state;
    if (nextProps) {
      const { data } = nextProps;
      const sortedData = orderBy(data, [orderByKey], [order]);
      this.setState({
        data: sortedData,
      });
    }
  }

  handleRequestSort = (event, property) => {
    const { data, order, orderBy } = this.state;
    const sortedData = handleSort(property, order, orderBy, data);
    this.setState(sortedData);
  }

  renderImages = (imgArr = []) => {
    if (imgArr) {
      return imgArr.map(x =>
        <img alt="imageUrl" style={{ width: '60px', height: 'auto' }} src={x.imageUrl} />)
    }
  }

  renderList = () => {
    const { classes, currency, taxType } = this.props;

    const {
      subProductsPage, subProductsRowsPerPage, productItemList,
    } = this.state;

    const newSubProductsColumnData = taxType !== 'gst' ? subProductsColumnData.filter(col => col.id !== 'CGST') : subProductsColumnData;

    return (
      <Modal
        open={this.state.editItem}
        onClose={() => { this.setState({}); }}
      >
        <div className={classes.productItemsContainer}>
          <Button style={{ float: 'right' }} onClick={() => { this.setState({ editItem: false, productItemList: [] }); }} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Typography style={{ marginTop: '2em', marginLeft: window.innerWidth * 0.03 }} color="secondary" variant="title">View/Edit Product Items</Typography>
          <Table style={{ width: '90%', marginLeft: '5%', marginTop: '2%' }} className={classes.table} aria-labelledby="tableTitle">
            <TableHeader columns={newSubProductsColumnData} />
            <TableBody>
              {productItemList.slice(subProductsPage * subProductsRowsPerPage, subProductsPage * subProductsRowsPerPage + subProductsRowsPerPage).map((n, i) => (
                <TableRow
                  tabIndex={-1}
                  key={i}
                >
                  <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{currency} {n.unitPrice}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{currency} {n.sellingPrice}</TableCell>
                  <TableCell component="th" scope="row" padding="none">{n.discount}%</TableCell>
                  {taxType === 'gst' && <TableCell component="th" scope="row" padding="none">{n.cgst}%</TableCell>}
                  <TableCell component="th" scope="row" padding="none">{this.renderImages(n.productItemImages)}</TableCell>
                  <TableCell component="th" scope="row" padding="none"><ProductStatus isActive={n.active} /></TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Typography color="secondary" style={{ fontSize: '0.9em', cursor: 'pointer' }}>
                      <span onClick={() => { this.showItemDialog(n); }}>EDIT</span>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            style={{ marginRight: '3em' }}
            component="div"
            count={this.state.itemTable.length}
            rowsPerPage={subProductsRowsPerPage}
            page={subProductsPage}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const {
      classes, UOM,
      handleChangeRowsPerPage,
      handleChangePage,
      rowsPerPage, count, page
    } = this.props;

    const {
      selectedProductItem = {},
      productId,
      updateItem,
      vendorMapping = [],
      rowsPerPageOprions,
      order,
      orderBy,
      data,
      productName
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          {this.renderList()}
          <ProductItemForm
            UOM={UOM}
            vendorMapping={vendorMapping}
            open={updateItem}
            productItem={selectedProductItem}
            productId={productId}
            onClose={() => this.setState({ selectedProductItem: {}, updateItem: false })}
            onProductItemChange={productItem => this.setState({ selectedProductItem: productItem })}
            onMappingChange={mapping => this.setState({ vendorMapping: mapping })}
            onRefresh={this.fetchProductItemList}
            productName={productName}
          />
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              columns={productsColumnData} />
            <TableBody>
              {data.map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell component="th" scope="row" padding="none" style={{ maxWidth: 200 }}>{n.name}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.brandName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.categoryName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.productTags.map(tag => tag.tag).join(", ")}</TableCell>
                    <TableCell component="th" scope="row" padding="none"><ProductStatus isActive={n.active} /></TableCell>
                    <TableCell component="th" scope="row" padding="none"><TimeUpdated timeUpdated={n.timeUpdated} /></TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Typography color="secondary" style={{ fontSize: '0.9em' }}>
                        {/* eslint-disable */}
                        <span onClick={() => { this.props.editHandler(n); }} style={{ cursor: 'pointer' }}>
                          EDIT&emsp;
                        </span>
                        |
                        <span
                          onClick={() => {
                            this.setState({
                              updateItem: true,
                              adding: true,
                              productId: n.id,
                              selectedProductItem: {},
                              vendorMapping: [],
                              productName: n.name,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          &emsp;ADD ITEM&emsp;
                        </span>
                        |
                        <span onClick={() => { this.setState({ editItem: true, adding: false, productId: n.id, productName: n.name }, () => { this.fetchProductItemList(); }); }} style={{ cursor: 'pointer' }}>
                          &emsp;VIEW/EDIT ITEM
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOprions}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.array.isRequired,
  editHandler: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  UOM: state.UOM,
  currency: state.currency,
  taxType: state.taxType,
});

export default connect(mapStateToProps, null)(withStyles(styles)(EnhancedTable));
