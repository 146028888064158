import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../actions/inventoryActions';
import NavBar from '../../components/NavBar';
import './styles.scss';
import NavSideBar from './components/NavSideBar';
import HeadBar from './components/HeadBar';
import Dashboard from './pages/Dashboard';
import SearchList from './pages/SearchList';
import Adjustments from './pages/Adjustments';
import Category from './pages/Category';
import ProductItem from './pages/ProductItem';
import ItemAutoComplete from './components/ItemAutoComplete';

const PAGE_CONST = {
  dashboard: Dashboard,
  search: SearchList,
  adjustments: Adjustments,
  categories: Category,
  item: ProductItem,
};

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: Dashboard,
      currentPageName: 'dashboard',
      isSearching: false,
    };
  }

  componentDidMount() {
    const { page = null, itemId = null } = this.props.match.params;
    this.pageSelector(page, itemId);
  }

  onSearchChange = () => {
    this.setState({
      isSearching: true,
    });
  };

  onSearchClear = () => {
    this.setState({
      isSearching: false,
    });
  };

  onSearchItemClick = () => {};

  pageSelector = (page, itemId) => {
    if (page) {
      this.setState({ currentPage: PAGE_CONST[page], currentPageName: page });
    }
    if (itemId) {
      this.setState({ currentPage: PAGE_CONST.item, currentPageName: 'item' });
    }
    if (!itemId && !page) {
      this.setState({ currentPage: PAGE_CONST.dashboard, currentPageName: 'dashboard' });
    }
    // else loading screen
  };

  render() {
    const { currentPage, currentPageName, isSearching } = this.state;
    return (
      <div className="inventoryContainer">
        {/* <NavBar /> */}
        <div className="inventoryBox">
          <NavSideBar currentPageName={currentPageName} />
          <div className="contentArea">
            <HeadBar onSearchChange={this.onSearchChange} onSearchClear={this.onSearchClear} />
            {isSearching && <ItemAutoComplete onClick={this.onSearchItemClick} />}
            <div className="mainContent">
              {React.createElement(currentPage, {
                ...this.props, // other props
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { warehousesList = [], selectedWarehouse = {} } = Inventory;
  return {
    warehousesList,
    selectedWarehouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getAllWarehouses } = actions;
  return {
    getAllWarehouses: bindActionCreators(getAllWarehouses, dispatch),
  };
};

Inventory.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inventory);
