import React, { Component } from 'react';
import NavBar from '../../../components/NavBar';
import apiCall, { getSegregators, createSegregator, updateSegregator } from '../../../api/NetworkHandler';
import { Input as AntdInput, Spin } from 'antd';
import SegregatorList from '../components/SegregatorList';
import { LoadingOutlined } from '@ant-design/icons';
import SegregatorSearch from '../components/SegregatorSearch';
import SegregatorForm from '../components/SegregatorForm';
import Drawer from '@material-ui/core/Drawer';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { debounce } from "lodash";

const styles = {
  tableConatiner: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: window.innerHeight * 0.1,
    alignItems: 'center'
  },
  title: {
    marginLeft: '30px'
  },
  searchBar: {
    width: window.innerWidth * 0.7,
    marginLeft: '10%'
  }
};

var versionDict = {
  "createRequestId": 0,
  "updateRequestId": 0,
};


class SegregatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segregators: [],
      filters: {
        query: '',
        role: ''
      },
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isNoData: false,
      loading: false,
      segregatorForm: false,
      selectedSegregator: null,
      disableButton: false,
      departments: [],
    };
  }

  componentDidMount() {
    this.fetchSegregators();
    this.fetchAllDepartments();
  }

  onSearch = () => {
    this.setState({ page: 0 }, () => {
      this.fetchSegregators()
    });
  }

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState,
    });
  };

  fetchSegregators = async () => {
    this.toggleLoading(true);
    try {
      const { filters: { query, role }, page, rowsPerPage } = this.state;
      const response = await apiCall.sendRequest('get', getSegregators(query, role, page, rowsPerPage));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;

      this.setState({
        segregators: content,
        count: totalElements,
        isNoData: totalElements > 0 ? false : true,
      });
    } catch (err) {
      this.setState({
        segregators: [],
        count: 0,
        isNoData: true
      });
    }
    this.toggleLoading(false);
  };

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => { this.fetchSegregators() });
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({
      rowsPerPage
    }, () => { this.fetchSegregators() });
  };

  handleEditUser = (selectedSegregator) => {
    this.setState({
      selectedSegregator,
    });
  };

  onClose = () => {
    this.setState({
      segregatorForm: false,
      selectedSegregator: null,
    });
  };

  addNewSegregator = (selectedSegregator) => {
    this.setState({
      segregatorForm: true,
      selectedSegregator,
    });
  }

  onClearSearch = () => {
    this.setState({
      filters: {
        query: '',
        role: ''
      },
    }, () => { this.fetchSegregators() });
  }

  handleQueryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value,
      },
    }));
  };

  handleRoleChange = (selectedRole) => {
    if (selectedRole === 'All') selectedRole = "";
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        role: selectedRole,
      },
      page: 0,
    }));
    this.fetchSegregators();
  }

  onSave = debounce(async (segregator) => {
    this.toggleLoading(true);
    this.setState({ disableButton: true });
    try {
      versionDict.updateRequestId += 1;
      let prevRequestId = versionDict.updateRequestId;
      if (prevRequestId === versionDict.updateRequestId) {
        await apiCall.sendRequest('put', updateSegregator(segregator.id), segregator);
        this.onClose();
        this.fetchSegregators();
      }

    } catch (err) {
      console.error(err);
    }
    this.toggleLoading(false);
    this.setState({ disableButton: false });
  }, 500);

  onCreateSegregator = debounce(async (segregator) => {
    this.toggleLoading(true);
    this.setState({ disableButton: true });
    try {
      versionDict.createRequestId += 1;
      let prevRequestId = versionDict.createRequestId;
      if (prevRequestId === versionDict.createRequestId) {
        await apiCall.sendRequest('post', createSegregator, segregator);
        this.onClose();
        this.fetchSegregators();
      }

    } catch (err) {
      console.error(err);
    }
    this.toggleLoading(false);
    this.setState({ disableButton: false });
  }, 500);

  fetchAllDepartments = async () => {
    try {
      const response = await apiCall.sendRequest('get', 'api/v1/departments');
      const { data: { _embedded: embedded } } = response;
      this.setState({
        departments: embedded.departments,
      });
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      segregators,
      count,
      page,
      rowsPerPage,
      isNoData,
      loading,
      selectedSegregator,
      filters,
      departments,
      disableButton,
    } = this.state;

    const { segregatorRoles } = this.props;

    return (
      <div>
        <NavBar />
        < SegregatorSearch
          openAddSegregator={this.addNewSegregator}
          onClearSearch={this.onClearSearch}
          query={filters.query}
          onSearch={this.onSearch}
          handleQueryChange={this.handleQueryChange}
          handleRoleChange={this.handleRoleChange}
          segregatorRoles={segregatorRoles}

        />
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
          <div style={styles.tableConatiner}>
            <div style={styles.paperLeft}>
              <SegregatorList
                data={segregators}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                onEdit={this.handleEditUser}
                isNoData={isNoData}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                openAddSegregator={this.addNewSegregator}

              />
            </div>
          </div>
        </Spin>

        <Drawer anchor="right" open={this.state.segregatorForm} onClose={this.onClose} >
          <SegregatorForm
            onClose={this.onClose}
            selectedSegregator={selectedSegregator}
            onSave={this.onSave}
            onCreateSegregator={this.onCreateSegregator}
            segregatorRoles={segregatorRoles}
            departments={departments}
            disableButton={disableButton}

          />
        </Drawer >


      </div >
    );
  }
}

SegregatorContainer.propTypes = {
  segregatorRoles: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const { segregatorRoles } = Status;
  return {
    segregatorRoles,
  };
};

export default connect(mapStateToProps, null)(withRouter(SegregatorContainer));
