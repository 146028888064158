import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import NavBar from '../../../components/NavBar';
import SearchBar from '../../Commons/containers/SearchBar';
import SegregationSearch from '../component/SegregationSearch';
import TableList from '../../Commons/components/TableList';
import API, {
  getSegregationLogsForHubsPath,
  getSegregationLogsForRoutesPath,
  unlockUserFromRoutePath,
  unlockUserFromZonePath,
} from '../../../api/NetworkHandler';
import SelectedItemDetail from '../../Commons/components/SelectedItemDetail';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
};

const titles = {
  hub: 'Hub',
  route: 'Route',
};

let columnData = [
  {
    id: 'segregatedBY',
    numeric: false,
    disablePadding: false,
    label: 'Segregated By',
  },
  {
    id: 'startedAt',
    numeric: false,
    disablePadding: false,
    label: 'Started At',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const hubcolumnData = [
  {
    id: 'hubName',
    numeric: false,
    disablePadding: false,
    label: 'Hub',
  },
  {
    id: 'zoneName',
    numeric: false,
    disablePadding: false,
    label: 'Zone',
  },
  {
    id: 'slotName',
    numeric: false,
    disablePadding: false,
    label: 'Slot',
  },
];


const routecolumnData = [
  {
    id: 'routeName',
    numeric: false,
    disablePadding: false,
    label: 'Route',
  }
]; 

class ManageSegregation extends Component {
  constructor(props) {
    super(props);
    const { match: { params = {} } = {} } = this.props;
    const { name } = params;
    this.state = {
      entity: name,
      logs: [],
      filters: {
        deliveryDate: moment().format('YYYY-MM-DD'),
      },
    };
    if(name==='route'){
      columnData = [...routecolumnData, ...columnData];
    }else{
      columnData = [...hubcolumnData, ...columnData];
    }
  }

  // componentDidMount() {
  //   this.fetchLogs();
  // }

  fetchLogs = () => {
    const { entity } = this.state;
    if (entity === 'hub') {
      this.fetchHubSegregationLogs();
    } else {
      this.fetchRouteSegregationLogs();
    }
  }

  fetchHubSegregationLogs = async () => {
    const { filters: { deliveryDate } } = this.state;
    const url = getSegregationLogsForHubsPath(deliveryDate);
    const response = await API.sendRequest('get', url);
    const { data: { data = [] } } = response;
    this.setState({
      logs: data,
    });
  }

  fetchRouteSegregationLogs = async () => {
    const { filters: { deliveryDate } } = this.state;
    const url = getSegregationLogsForRoutesPath(deliveryDate);
    const response = await API.sendRequest('get', url);
    const { data: { data = [] } } = response;
    this.setState({
      logs: data,
    });
  }

  unlockRoute = async (routeId) => {
    const { filters: { deliveryDate } } = this.state;
    await API.sendRequest('post', unlockUserFromRoutePath(deliveryDate, routeId));
    window.location.reload();
  }

  unlockHub = async (hubId, zoneId) => {
    const { filters: { deliveryDate } } = this.state;
    await API.sendRequest('post', unlockUserFromZonePath(deliveryDate, hubId, zoneId), {});
    window.location.reload();
  }

  handleUnlock = (index) => {
    const { logs, entity } = this.state;
    const { hubId, zoneId, routeId } = logs[index];
    if (entity === 'hub') {
      this.unlockHub(hubId, zoneId);
    } else {
      this.unlockRoute(routeId);
    }
  }


  handleSelect = (row) => {
    this.setState({
      selectedData: row,
    });
  };

  handleChange = (filters, refresh = false) => {
    // const { currentPage } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.fetchLogs();
      }
    });
  };

  handleClick = (name, params = null) => {
    switch (name) {
      case 'unlock':
        this.handleUnlock(params);
        break;
      case 'select':
        this.handleSelect(params);
        break;
      case 'search':
        this.fetchLogs();
        break;
      default: break;
    }
  }

  render() {
    const {
      filters = {}, entity, logs, selectedData = {},
    } = this.state;
    let keys = ['hubName', 'zoneName', 'slotName', 'segregatedBy', 'startedAt'];

    if (entity === 'route') {
      keys = ['routeName', 'segregatedBy', 'startedAt'];
    }
    return (
      <div>
        <NavBar />
        <SearchBar
          title={`Manage ${titles[entity]} Segregation`}
          filters={filters}
          onButtonClick={this.handleClick}
          onChange={this.handleChange}
        >
          <SegregationSearch management />
        </SearchBar>
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <TableList
              data={logs}
              columns={columnData}
              keys={keys}
              actions={['unlock']}
              handleClick={this.handleClick}
              errorMessage={`No ${entity} found with lock`}
            />
          </div>
          <div style={styles.paperRight}>
            { (!isEmpty(selectedData) && Object.keys(selectedData).length > 0)
              ?
                <SelectedItemDetail item={selectedData} />
              : <div />}
          </div>
        </div>
      </div>
    );
  }
}

ManageSegregation.propTypes = {
  match: PropTypes.instanceOf(Object),
};

export default ManageSegregation;
