import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import apiCall, { getWareHouse } from "../api/NetworkHandler";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '116px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  searchBar: {
    flex: 3,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchField: {
    flex: 3,
  },
  clear: {
    flex: 1,
    marginLeft: '10px',
    color: '#f50057',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class StockAdjustmentSearchBar extends React.Component {

  render() {
    const { searchTerm, onChangeSearch, onClearSearch, warehouse, onRefresh } = this.props;
    return (
      <div style={styles.div}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
              Stock Inventory
          </Typography>
        </div>
        <div style={styles.searchBar}>
          <div style={styles.searchContainer}>
            <div style={styles.searchField}>
              <TextField
                id="input-with-icon-textfield"
                label="Quick Search"
                fullWidth
                name="query"
                onChange={onChangeSearch}
                value={searchTerm}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
              />
            </div>
            <div style={styles.clear}>
              <TextField 
                label="Warehouse"
                value={warehouse}
                disabled
              />
            </div>
            <div style={styles.clear}>
              <Button color="secondary" onClick={onClearSearch}>
                    Clear
              </Button>
            </div>
            <div style={styles.clear}>
              <Button color="secondary" variant="contained" onClick={()=>{onRefresh(null)}}>
                    Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StockAdjustmentSearchBar;
