import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { onlyTextCheck, onlyNumbersCheck, strictTextCheck } from '../../../actions/index';
import apiCall from '../../../api/NetworkHandler';
import ManualComponent from '../components/EditCo';
import NavBar from '../../../components/NavBar';

class customComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { id } = params;
    if (id) {
      apiCall.sendRequest('get', `/api/v1/customer-orders/${id}`)
        .then((response) => {
          console.log(response.data);
          this.setState({
            data: response.data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp) {
      const { id } = nextProps.match.params;
      if (id) {
        apiCall.sendRequest('get', `/api/v1/customer-orders/${id}`)
          .then((response) => {
            this.setState({
              data: response.data,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  render() {
    const {
      reducerCheck,
      validatorText,
      validatorNumbers,
      validatorStrictText,
    } = this.props;

    return (
      <div>
        <NavBar />
        <ManualComponent
          TextAction={validatorText}
          TextIsError={reducerCheck.errorStatusText}
          TextErrorMessage={reducerCheck.errorMessageText}
          NumberAction={validatorNumbers}
          NumberMessage={reducerCheck.errorMessageNumbers}
          NumberIsError={reducerCheck.errorStatusNumber}
          StrictTextAction={validatorStrictText}
          valueStrictText={reducerCheck.strictText}
          data={this.state.data}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reducerCheck: state.reducerCheck,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    validatorText: onlyTextCheck,
    validatorNumbers: onlyNumbersCheck,
    validatorStrictText: strictTextCheck,
  }, dispatch);
}


export default withRouter(connect(mapStateToProps, matchDispatchToProps)(customComponent));

