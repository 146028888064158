import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function StatusFilter(props) {
  const { status, onChangeStatus } = props;
  return (
    <div>
      <FormHelperText style={{ marginTop: '4px' }}>Status</FormHelperText>
      <Select
        name="status"
        label="status"
        fullWidth
        onChange={onChangeStatus}
        value={status || 'all'}
      >
        <MenuItem value='all'>All</MenuItem>
        <MenuItem value="true">Active</MenuItem>
        <MenuItem value="false">Inactive</MenuItem>
      </Select>
    </div>
  )
}

StatusFilter.propTypes = {
  onChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

StatusFilter.defaultProps = {
  onChangeStatus: () => {},
  status: '',
}
