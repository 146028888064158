export default {
  title: {
    fontSize: '1em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  action: {
    display: 'flex',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  cartItem: {
    display: 'flex',
    fontWeight: '500',
    margin: '2px 0 4px',
    borderBottom: '1px solid rgb(221, 221, 221)',
    height: '32px',
  },
  newCartItem: {
    background: 'beige',
  },
  light: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 16px',
    fontSize: '0.875em',
    borderBottom: 'none',
  },
  itemStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.875em',
  },
  upcomingDate: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '16px 0',
  },
  upcomingFilter: {
    display: "flex",
    justifyContent: "space-between",
  },
  errorTitle: {
    paddingTop: "20px",
    color: "red"
  },
  upStyle: {
    productName: { minWidth: '45%', maxWidth: '45%' },
    measure: { minWidth: '10%', maxWidth: '10%' },
    unit: { minWidth: '7%', maxWidth: '7%' },
    unitPrice: { minWidth: '10%', maxWidth: '10%' },
    quantity: { minWidth: '10%', maxWidth: '10%' },
    actions: { minWidth: '12%', maxWidth: '12%' },
  },
  items: {
    productName: { minWidth: '26%', maxWidth: '26%' },
    measure: { minWidth: '6%', maxWidth: '6%' },
    code: { minWidth: '5%', maxWidth: '5%' },
    unitPrice: { minWidth: '7%', maxWidth: '7%' },
    quantity: { minWidth: '7%', maxWidth: '7%' },
    plan: { minWidth: '10%', maxWidth: '10%' },
    date: { minWidth: '9%', maxWidth: '9%' },
    actions: { minWidth: '12%', maxWidth: '12%' },
  },
  itemEditing: {
    background: '#fafafa',
    padding: '5px 0',
    marginBottom: '5px',
  },
  newItemEditing: {
    background: 'beige',
    padding: '5px 0',
    marginBottom: '5px',
  },
  newItem: {
    fontSize: '0.875em',
    fontWeight: '500',
    padding: '5px 0 0 5px',
  },
  editContent: {
    marginBottom: '64px',
  },
  content: {
    margin: '0 0 16px',
  },
  btn: {
    width: '128px',
    marginLeft: '10px',
  },
  addNewBtn: {
    width: '128px',
    marginTop: '16px',
  },
  itemPaused: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  empty: {
    margin: '32px 0',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.75em',
    textTransform: 'uppercase',
  },
  warning: {
    margin: '32px 0',
    color: '#f50057',
  },
  actionBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  actionBtn: {
    height: '32px',
    minWidth: '40px',
    margin: '0 5px',
  },
  actionIcon: {
    fontSize: '20px',
  },
  editPaln: {
    padding: '2px 10px 24px',
  },
  epRow: {
    display: 'flex',
    width: '100%',
  },
  epInputWrap: {
    minWidth: '20%',
    margin: '16px 5%',
  },
  epInput: {
    width: '100%',
  },
  epLeft: {
    width: '60%',
  },
  epRight: {
    width: '40%',
  },
  epHeader: {
    fontSize: '0.83em',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '10px 0 0',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  epCol: {
    margin: '2px 5%',
  },
  epAction: {
    display: 'flex',
    padding: '16px 5%',
    justifyContent: 'flex-end',
  },
  orderTotal: {
    div: {
      display: 'flex',
      fontWeight: '500',
      margin: '2px 0',
      fontSize: '16px',
    },
    upcomingTitle: { minWidth: '62%', maxWidth: '62%' },
    upcomingValue: { minWidth: '10%', maxWidth: '10%', color: "#388E3C", fontSize: "18px" },
    planTitle: { minWidth: '37%', maxWidth: '37%' },
    planValue: { minWidth: '7%', maxWidth: '7%', color: "#388E3C", fontSize: "18px" },
  }
};
