import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
};

class CRSearch extends Component {

  onChangeFilter = (event) => {
    const { onFilterChange } = this.props;
    const { name, value } = event.target;
    onFilterChange(name, value);
  };

  render() {
    const { filters, onButtonClick } = this.props;
    return (
      <div style={styles.container} >
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={6}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Search By Phone Number"
                  type="number"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={filters.query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search')}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CRSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default CRSearch;
