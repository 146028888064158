import React from 'react'
import PropTypes from 'prop-types';
import Lozenge from '@atlaskit/lozenge';

export default function ProductStatus(props) {
  const { isActive } = props;
  return (
    <Lozenge appearance={isActive ? "success" : "removed"}>{isActive ? "ACTIVE" : "INACTIVE"}</Lozenge>
  )
}

ProductStatus.prototypes = {
  isActive: PropTypes.bool.isRequired,
}
