import React, { Component } from 'react';
import { capitalize, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import apiCall, { fetchProductWidgets, getAllParentCategories, getCategoriesByParentId } from '../api/NetworkHandler';
import {
  Button,
  Typography,
  Grid,
  Modal,
  IconButton,
  Paper,
  Tooltip,
  Snackbar
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../components/lib';
import { Input } from 'antd';
import { CopyFilled } from '@ant-design/icons';
import Select, { CheckboxSelect } from '@atlaskit/select';
import ProductSelector from '../components/Product/Selector';
import CatalogSelector from '../components/Catalog/Selector';
import BrandSelector from '../components/Brand/Selector';
import WidgetSelector from '../modules/BannerManagement/components/WidgetSelector';
import copy from "copy-to-clipboard";



var prefix = "/ca/"

var versionDict = {
    "categoryRequestId": 0,
    "brandRequestId": 0,
    "productWidgetRequestId": 0,
};

var DEEPLINK_TYPES = [
  {label: "Brand", value: "brand"},
  {label: "Category", value: "category"},
  {label: "Payment", value: "pymts"},
  {label: "Coupons", value: "coupons"},
  {label: "Product", value: "pd"},
  {label: "Product Widget", value: "productWidget"},
  {label: "Cart", value: "cart"},
  {label: "Store", value: "store"},
  {label: "Refer Friend", value: "rf"}
];

class DeeplinkUrlGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedType: {label: "Payments", value: "pymts"},
          urlValue: "",
          categoryList: [],
          selectedCategory: null,
          deeplinkUrl: "",
          selectedBrand: null,
          title: "",
          selectedCategories: [],
          selectedSubCategory: [],
          subCategoryList: [],
          selectedCatalog: null,
          visibleProduct: false,
          selectedProduct: null,
          subCategoryStore: [],
          allSelectedCategories: [],
          openSnackbar: false,
          productWidgets: [],
          selectedWidget: null,
          errorText: '',
        };
    }
  
    handleChangeValue = (event) => {
      const { value } = event.target;
      this.setState({
        urlValue: value
      });
    };

    handleChangeTitle = (event) => {
      const { value } = event.target;
      this.setState({
        title: value
      });
    };
  
    handleModalClose = () => {
      const { onClose } = this.props;
      this.setState({
        selectedType: {label: "Payments", value: "pymts"},
        urlValue: "",
        selectedCategory: null,
        deeplinkUrl: "",
        selectedBrand: null,
        title: "",
        selectedCategories: [],
        selectedSubCategory: [],
        subCategoryList: [],
        selectedCatalog: null,
        visibleProduct: false,
        selectedProduct: null,
        subCategoryStore: [],
        allSelectedCategories: [],
        openSnackbar: false,
        productWidgets: [],
        selectedWidget: null,
        errorText: '',
      });
      onClose();
    }

    handleSelectType = (selectedType) => {
      this.setState({
        selectedType,
        selectedCategory: null,
        visibleProduct: false,
        selectedProduct: null,
        selectedCatalog: null,
        title: "",
        urlValue: "",
        selectedCategories: [],
        subCategoryStore: [],
        errorText: "",
        deeplinkUrl: "",
      });
      selectedType.value === "category" && this.fetchAllParentCategories();
      selectedType.value === "productWidget" && this.getProductWidgets();
    };

    fetchAllParentCategories = async () => {
      try {
        versionDict.categoryRequestId += 1;
        let prevRequestId = versionDict.categoryRequestId;
        const response = await apiCall.sendRequest('get', getAllParentCategories);
        const { data: { _embedded: { categories = [] } = {} } = {} } = response;
        if (prevRequestId == versionDict.categoryRequestId) {
          this.setState({
            categoryList: categories.map(el => { return {label: el.name, value: el.id}}),
          });
        }
      } catch (err) {
        this.setState({
          categoryList: [],
        });
      }
    };

    getProductWidgets = async () => {
      try {
        versionDict.productWidgetRequestId += 1;
        let prevRequestId = versionDict.productWidgetRequestId;
        const response = await apiCall.sendRequest('get', fetchProductWidgets);
        const { data: { data = [] } = {} } = response;
        if (prevRequestId == versionDict.productWidgetRequestId && data.length > 0) {
          this.setState({
            productWidgets: data.map(el => ({ label: el.title, value: el.id }))
          });
        }
      } catch (err) {
        this.setState({
          productWidgets: [],
        });
      }
    };
  
    handleSelectCategory = (selectedCategories) => {
      this.setState({
        title: "",
        selectedCategories,
      }, () => this.createSubCategoryArray(selectedCategories || []));
    };

    createSubCategoryArray = (selectedCategories) => {
      const { allSelectedCategories } = this.state;
      let subCategory = []
      if (selectedCategories.length > 0) {
        selectedCategories.forEach(el => {
          let existingCategory = allSelectedCategories.find(elm => elm.parentId == el.value);
          subCategory.push({parentId: el.value, category: existingCategory ? existingCategory.category : []})
        });
      }
      this.setState({
        allSelectedCategories: subCategory,
      });
    };

    fetchSubCategories = async (parentId) => {
      const { subCategoryStore } = this.state;
      if (subCategoryStore.length > 0 && subCategoryStore.some(el => el.parentId === parentId)) {
        return;
      }
      
      try {
        const response = await apiCall.sendRequest('get', getCategoriesByParentId(parentId));
        const { data: { _embedded: { categories = [] } = {} } = {} } = response;
        const newSubCategories = categories.map(el => { return {label: el.name, value: el.id}});
        this.setState({
          subCategoryStore: [...subCategoryStore, {parentId, category: newSubCategories}]
        });
      } catch (err) {
        this.setState({
          subCategoryStore: [...subCategoryStore]
        });
      }
    };

    handleSelectSubCategory = (selectedSubCategory, parentId) => {
      this.setState({
        selectedSubCategory,
      }, () => this.combineSubCategories(selectedSubCategory, parentId));
    };

    combineSubCategories = (subCategory, parentId) => {
      const { allSelectedCategories } = this.state;
      const matchedCategoryIndex = allSelectedCategories.findIndex(el => el.parentId == parentId);
      allSelectedCategories[matchedCategoryIndex] = {parentId, category: subCategory || []};
      this.setState({
        allSelectedCategories,
      });
    };

    handleSelectBrand = (selectedBrand) => {
      this.setState({
        selectedBrand,
        title: !isEmpty(selectedBrand) ? selectedBrand.label : "",
        deeplinkUrl: "",
      });
    };

    handleCatalogSelect = (selectedCatalog) => {
      this.setState({
        selectedCatalog,
        visibleProduct: true,
        selectedProduct: null,
        deeplinkUrl: "",
      });
    };

    handleSelectProduct = (selectedProduct) => {
      this.setState({
        selectedProduct,
      });
    };

    handleSelectProductWidget = (selectedWidget) => {
      this.setState({
        selectedWidget,
        title: !isEmpty(selectedWidget) ? selectedWidget.label : "",
        deeplinkUrl: "",
      });
    };

    setErrorText = (state) => {
      this.setState({
        errorText: state,
      });
    };

    validateData = () => {
      const { selectedType, selectedBrand, selectedCategories, selectedProduct, urlValue, selectedWidget, selectedCatalog, title } = this.state;

      let isValid = true;
      if (selectedType.value === "category" && isEmpty(selectedCategories)) {
        isValid = false;
        this.setErrorText("Please select category");
      }
      else if (selectedType.value === "brand" && (isEmpty(selectedBrand) || !title)) {
        isValid = false;
        this.setErrorText("Please select brand");
      }
      else if (selectedType.value === "pd" && isEmpty(selectedCatalog)) {
        isValid = false;
        this.setErrorText("Please select catalog");
        if (isEmpty(selectedProduct)) {
          isValid = false;
        } 
      }
      else if (selectedType.value === "productWidget" && (isEmpty(selectedWidget) || !title)) {
        isValid = false;
        this.setErrorText("Please select widget");
      }
      else if (["pymts", "coupons"].includes(selectedType.value) && !urlValue) {
        isValid = false;
        this.setErrorText("Please enter some value");
      }
      else {
        this.setErrorText("");
      }
      return isValid;
    };

    generateDeeplink = () => {
      const { selectedType, selectedBrand, title, selectedProduct, urlValue, allSelectedCategories, selectedWidget } = this.state;
      if (!this.validateData()) {
        return;
      }
      let deeplink = `${prefix}`;
      const encodedTitle = encodeURIComponent(title.trim());

      if (selectedType.value === "category") {
        let allCategories = this.getAllCategories(allSelectedCategories);
        deeplink = `${deeplink}pl?cid=${(allCategories || []).join(",")}&title=${encodedTitle || ''}`;
        this.setLinkUrlState(deeplink);
      }
      else if (selectedType.value === "brand") {
        deeplink = `${deeplink}pl?bid=${selectedBrand.value}&title=${encodedTitle || ''}`;
        this.setLinkUrlState(deeplink);
      }
      else if (selectedType.value === "pd") {
        deeplink = `${deeplink}pd?pid=${selectedProduct.value}`;
        this.setLinkUrlState(deeplink);
      }
      else if (selectedType.value === "pymts") {
        deeplink = `${deeplink}${selectedType.value}?amt=${urlValue}`;
        this.setLinkUrlState(deeplink);
      }
      else if (selectedType.value === "coupons") {
        deeplink = `${deeplink}${selectedType.value}?coupon=${urlValue}`;
        this.setLinkUrlState(deeplink);
      }
      else if (selectedType.value === "productWidget") {
        deeplink = `${deeplink}pl?cid=0&sid=${selectedWidget.value}&title=${encodedTitle || ''}`;
        this.setLinkUrlState(deeplink);
      }
      else {
        deeplink = `${deeplink}${selectedType.value}`;
        this.setLinkUrlState(deeplink);
      }
    };

    getAllCategories = (allSelectedCategories) => {
      let allCategoryIds = [];
      allSelectedCategories.forEach(el => {
        if (el.category.length > 0) {
          allCategoryIds.push(el.category.map(elm => elm.value).join(","));
        } else {
          allCategoryIds.push(el.parentId);
        }
      });
      return allCategoryIds;
    };

    setLinkUrlState = (url) => {
      this.setState({
        deeplinkUrl: url
      });
    };

    copyTextToClipboard = () => {
      const { parentComponent = "", onCopyUrl } = this.props;
      const { deeplinkUrl } = this.state;
      // navigator.clipboard.writeText(deeplinkUrl);
      copy(deeplinkUrl);
      this.setState({
        openSnackbar: true
      });
      if (parentComponent === "banner" || parentComponent === "home-offer") {
        onCopyUrl(deeplinkUrl);
        this.handleModalClose();
      }
    };

    handleClose = () => {
      this.setState({
        openSnackbar: false
      });
    };

    getInputLabelByType = () => {
      const { selectedType } = this.state;
      if (["category", "brand"].includes(selectedType.value)) return capitalize(selectedType.label);
      else if (selectedType.value === "pymts") return "Amount";
      else if (selectedType.value === "coupons") return "Coupon Code";
      else if (selectedType.value === "pd") return "Catalog";
      else if (selectedType.value === "productWidget") return "Product Widget";
      else return;
    };

    getSubCategoriesByParentId = (subCategoryStore, parentId) => {
      if (subCategoryStore.length == 0) return [];
      let categories = subCategoryStore.find(el => el.parentId == parentId);
      return categories ? categories.category : [];
    };


    render() {
      const { open = false } = this.props;
      const {
        selectedType,
        urlValue,
        categoryList,
        deeplinkUrl,
        title,
        selectedCategories,
        selectedCatalog,
        visibleProduct,
        selectedProduct,
        selectedBrand,
        subCategoryStore,
        openSnackbar,
        productWidgets,
        selectedWidget,
        errorText,
      } = this.state;
      return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={this.handleModalClose}
            disableBackdropClick
        >
          <div style={{
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'white',
              width: '60%',
              height: '90%',
              borderRadius: '5px',
          }}
          >
            <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", width: "100%", top: 0 }}>
                <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: window.innerWidth * 0.02, paddingTop: "10px" }}>
                  <Typography variant="title" color="inherit"> Deeplink URL Generator </Typography>
                </div>
                <Button style={{ float: 'right' }} onClick={this.handleModalClose} color="secondary" aria-label="close">
                    <CloseIcon />
                </Button>
            </div>
            <Grid style={{ padding: '1em', background: '#FFFFFF', marginTop: window.innerHeight * 0.05, overflowX: "auto", height: "90%", position: "absolute", width: "100%" }}>
                <Row>
                  <ColMod custompadding="true" bumphigh="true" xs md lg={16}>
                    <Row>
                      <ColMod custompadding="true" bumphigh="true" lg={6}>
                        <Typography variant="caption"> Type </Typography>
                        <Select
                          className="checkbox-select"
                          classNamePrefix="select"
                          options={DEEPLINK_TYPES}
                          placeholder="Choose a type"
                          onChange={this.handleSelectType}
                          value={selectedType}
                        />
                      </ColMod>
                      <ColMod custompadding="true" bumphigh="true" lg={6}>
                        <Typography variant="caption"> {this.getInputLabelByType()} </Typography>
                        {["pymts", "coupons"].includes(selectedType.value) && <Input type={selectedType.value === "pymts" ? "number" : "text"} size="large" value={urlValue} onChange={this.handleChangeValue} />}
                        {selectedType.value === "category" &&
                          <CheckboxSelect
                            className="checkbox-select"
                            classNamePrefix="select"
                            options={categoryList}
                            placeholder="Choose a category"
                            onChange={this.handleSelectCategory}
                          />
                        }
                        {selectedType.value === "brand" &&
                          <BrandSelector selected={selectedBrand} onSelect={this.handleSelectBrand} />
                        }
                        {selectedType.value === "pd" &&
                          <CatalogSelector selected={selectedCatalog} onSelect={this.handleCatalogSelect} />
                        }
                        {selectedType.value === "productWidget" &&
                          <WidgetSelector options={productWidgets} selected={selectedWidget} onWidgetChange={this.handleSelectProductWidget}/>
                        }
                        <Typography variant="caption" color="error"> {errorText} </Typography>
                      </ColMod>
                    </Row>
                    {(selectedCategories && selectedCategories.length > 0) && 
                      selectedCategories.map(el => {
                        return (
                          <Row key={el.value}> 
                            <ColMod custompadding="true" bumphigh="true" lg={12}>
                              <Typography variant="caption"> {el.label} </Typography>
                              <CheckboxSelect
                                className="checkbox-select"
                                classNamePrefix="select"
                                options={this.getSubCategoriesByParentId(subCategoryStore, el.value)}
                                placeholder="Choose sub category"
                                onChange={(e) => this.handleSelectSubCategory(e, el.value)}
                                onMenuOpen={() => this.fetchSubCategories(el.value)}
                              />
                            </ColMod> 
                          </Row>
                        )
                      })
                    }
                    {visibleProduct &&
                      <Row>
                        <ColMod custompadding="true" bumphigh="true" lg={12}>
                          <Typography variant="caption"> Product </Typography>
                          <ProductSelector
                            selected={selectedProduct}
                            onSelect={this.handleSelectProduct}
                            categoryId={!isEmpty(selectedCatalog) ? selectedCatalog.value : null}
                            key={Date.now()}
                          />
                          <Typography variant="caption" color="error"> {isEmpty(selectedProduct) && "Please select product"} </Typography>
                        </ColMod> 
                      </Row>
                    }
                    {["category", "brand", "productWidget"].includes(selectedType.value) && <Row>
                      <ColMod custompadding="true" bumphigh="true" lg={12}>
                        <Typography variant="caption"> Title </Typography>
                        <Input size="large" value={title} onChange={this.handleChangeTitle} required />
                        {!title && <Typography variant="caption" color="error"> Please enter title </Typography>}
                      </ColMod> 
                    </Row>}
                    <Row>
                      <ColMod custompadding="true" bumphigh="true" lg={2}>
                          <Button color="primary" variant="contained" onClick={this.generateDeeplink} > Generate </Button>
                      </ColMod> 
                    </Row>
                    <Row>
                      <span style={{ paddingLeft: "20px" }}>Your deeplink url will appear here.</span>
                      <ColMod custompadding="true" bumphigh="true" lg={12}>
                        <Paper style={{ minHeight: window.innerHeight * 0.07 }}>
                          <div style={{ float: "right" }}>
                            <Tooltip title="Copy to clipboard">
                              <div>
                                <IconButton onClick={this.copyTextToClipboard} style={{ height: "35px", width: "35px" }} disabled={!deeplinkUrl}>
                                  <CopyFilled style={{ fontSize: "18px", color: deeplinkUrl ? "#08c" : "lightgray" }} />
                                </IconButton>
                              </div>
                            </Tooltip>
                          </div>
                          <span style={{ paddingLeft: "5px", wordBreak: "break-word" }}>{deeplinkUrl}</span>
                        </Paper>
                      </ColMod> 
                    </Row>
                  </ColMod>
                </Row>
            </Grid>
            <Snackbar
              open={openSnackbar}
              onClose={this.handleClose}
              message="Deeplink url copied to clipboard"
              style={{ marginBottom: "20px" }}
            />
          </div>
        </Modal>
      );
    }
}
  
DeeplinkUrlGenerator.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    offerData: PropTypes.instanceOf(Object)
};

export default (DeeplinkUrlGenerator);
