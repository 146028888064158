import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../../Core/SearchAndSelect';
import apiCall, { searchDummyStaticRoutes } from '../../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchDummyStaticRoutes(searchTerm))
  const { data: { _embedded } } = response;
  const { "static-routes": routes } = _embedded;

  return routes.map(route => {
    return {
      ...route,
      value: route.id,
      label: route.name,
    }
  })
}

function DummyRouteSelector(props) {
  const { selected, onSelect, isClearable } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
    />
  )
}

DummyRouteSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
};

DummyRouteSelector.defaultProps = {
  selected: null,
  isClearable: true,
};

export default DummyRouteSelector;
