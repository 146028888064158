import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { TableBody, TablePagination, Typography } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const OfferColumnData = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Title',
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start Time',
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End Time',
  },
  {
    id: 'paybackType',
    numeric: false,
    disablePadding: true,
    label: 'Pay Type',
  },
  {
    id: 'paybackSubType',
    numeric: false,
    disablePadding: true,
    label: 'Pay SubType',
  },
  {
    id: 'paybackValue',
    numeric: false,
    disablePadding: true,
    label: 'Pay Value',
  },
  {
    id: 'offerType',
    numeric: false,
    disablePadding: true,
    label: 'Offer Type',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: 10,
  },
  tableCell: {
    textAlign: 'center',
  },
  titleCell: {
    textAlign: 'left',
    paddingLeft: 30,
  },
  tableData: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '20px',
    padding: 7,
    fontSize: 12,
  },
  titleRow: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '20px',
    padding: 7,
    paddingBottom: 20,
    fontSize: 12,
  },
};

class OfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  handleClick = (offersData) => {
    this.setState({
      selectedData: offersData,
    });
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  render() {
    const {
      offersData, totalElements = 0, rowsPerPage = 20, page = 0,
    } = this.props;
    const { selectedData = {}, rowsPerPageOptions } = this.state;


    return (
      <Grid container>
        <Grid item xs={12} md={9}>
          <Table>
            <TableHeader columns={OfferColumnData} cellStyle={styles.tableCell} />
            <TableBody>
              {
                offersData.map(item => (
                  <TableRow
                    hover
                    onClick={() => this.handleClick(item)}
                    role="checkbox"
                    tabIndex={-1}
                    key={item.id}
                    selected={!isEmpty(offersData) && offersData.id === item.id}
                  >
                    <TableCell style={styles.titleCell} component="th" scope="row" padding="none">{item.title}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none" >{moment(item.startTime).format('ll')}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none" >{moment(item.endTime).format('ll')}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none">{item.paybackType}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none">{item.paybackSubType}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none">{item.paybackValue}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none">{item.offerType}</TableCell>
                    <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                      <Button size="small" color="secondary" variant="contained">
                        <Link to={{ pathname: `/edit-offers/${item.id}` }} style={{ textDecoration: 'none', color: 'inherit' }}> Edit </Link>
                      </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          {offersData && offersData.length > 0 ?
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
              nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
                  : null
              }

        </Grid>
        <Grid item xs={12} md={3}>
          <div style={styles.paperRight}>
            { Object.keys(selectedData).length
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={styles.tableData}>
                    <b>Offer Details</b>
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b>Id : </b> {selectedData.id}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.titleRow}>
                    <b>Title : </b> {selectedData.title}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Offer Type : </b> {selectedData.offerType}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Start Time : </b> {moment(selectedData.startTime).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> End Time : </b> {moment(selectedData.endTime).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Description : </b> {selectedData.description}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> TimeCreated: </b> {moment(selectedData.timeCreated).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> TimeUpdated : </b> {moment(selectedData.timeUpdated).format('lll')}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Limit Per User : </b> {selectedData.limitPerUser}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Applied For : </b> {selectedData.appliedFor}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Payback Value : </b> {selectedData.paybackValue}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Max Payback Amount : </b> {selectedData.maxPaybackAmount}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> PayBack SubType : </b> {selectedData.payBackSubType}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Payback Type : </b> {selectedData.paybackType}
                  </Typography>
                  { selectedData.offerType === 'PRODUCT_OFFER' &&
                  <div>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Product Item Id : </b> {selectedData.productOffers[0].productItemId}
                  </Typography>
                  </div>
                  }
                  { selectedData.offerType === 'PRODUCT_OFFER' &&
                  <div>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Price : </b> {selectedData.productOffers[0].price}
                  </Typography>
                  </div>
                  }
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Short Description : </b> {selectedData.shortDescription}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Frequency : </b> {selectedData.frequency}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Offer Subtype : </b> {selectedData.offerSubtype}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Subtype Ids : </b> {selectedData.subtypeIds}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={styles.tableData}>
                    <b> Overlay Id : </b> {selectedData.overlayId}
                  </Typography>
                </div>
              : <div />}
          </div>

        </Grid>
      </Grid>
    );
  }
}


OfferList.propTypes = {
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  offersData: PropTypes.instanceOf(Array),
};

export default OfferList;

