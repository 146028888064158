import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { isEmpty, orderBy } from 'lodash';
import Upcoming from './Upcoming';
import Scheduled from './Scheduled';
import styles from './styles';
import moment from 'moment';
import apiCall, { getHistory } from './../../../api/NetworkHandler';

const defaultPlans = {
  // ALTERNATE_DAYS: 'Alternate Days',
  DAILY: 'Daily',
  WEEKDAYS: 'Weekdays',
  WEEKENDS: 'Weekends',
  GET_ONCE: 'GetOnce', // remove if needed
};
class Plans extends Component {


  onUpdatePlannedItems = (body, isNewItem = false) => {
    const { customerId, defaultAddress } = this.props;
    if (isNewItem) {
      this.props.addPlannedItem({ paramBody: body, customerId, addressId: defaultAddress.id });
    } else {
      this.props.editPlannedItem({ paramBody: body, customerId, addressId: defaultAddress.id });
    }
  };

  onDeletePlannedItem = (body) => {
    const { customerId, defaultAddress } = this.props;
    this.props.deletePlannedItem({ paramBody: body, customerId, addressId: defaultAddress.id });
  };

  getDefaultSchedules = customerSchedules =>
    Object.keys(defaultPlans).map((planName) => {
      const existingPlanIndex = customerSchedules.findIndex((eachUserPlan) => {
        const { name = '' } = eachUserPlan;
        return name.toUpperCase() === planName.replace('_', ' ');
      });
      if (existingPlanIndex === -1) {
        return { scheduleType: planName, name: defaultPlans[planName] };
      }
      return customerSchedules[existingPlanIndex];
    });

  getDefaultPlans = () => {
    let newPlans = []
    Object.keys(defaultPlans).map(plan => {
      newPlans.push({ scheduleType: plan, name: defaultPlans[plan] })
    });
    return newPlans;
  }

  formatPlanItems = (customerPlanItems) => {
    let formattedItems = [];
    customerPlanItems.forEach((eachCateogry) => {
      const {
        category = {}, items, pauseFrom: categoryPauseFrom, pauseTo, paused,
      } = eachCateogry;
      items.forEach((eachItemInCategory) => {
        const { plan, product } = eachItemInCategory;
        const {
          planItem: {
            quantity,
            startDate,
            status,
            id: planItemId,
            schedule = {},
            pauseFrom,
            pauseTill,
          },
          scheduleType,
        } = plan;
        const { id: scheduleId, customFrequency, daysOfWeek } = schedule;
        const { productItems, name: productName } = product;
        const [productItem] = productItems;
        const { uom, cutOffTime } = productItem;
        formattedItems = [
          ...formattedItems,
          {
            planName: plan.name,
            productItemId: productItem.id,
            productName: `${productName}-${productItem.unitMeasure}${uom.code}`,
            categoryId: category.id,
            scheduleId,
            quantity,
            scheduleType,
            startDate,
            status,
            unitPrice: productItem.offerPrice ? productItem.offerPrice : productItem.sellingPrice,
            planItemId,
            cutOffTime,
            customFrequency,
            daysOfWeek,
            pauseFrom,
            pauseTill,
            categoryPauseFrom,
            categoryPauseTill: pauseTo,
            categoryPause: paused,
            unitMeasure: productItem.unitMeasure,
            code: uom.code,
          },
        ];
      });
    });
    return formattedItems;
  };


  getCancelledPlans = () => {
    const { openModal,
      customerId,
      customerInfo } = this.props;
    openModal('deletedPlans', 'schedule',
      {
        'customerId': customerId,
        'customerInfo': customerInfo,
        'openModal': openModal
      })
  }

  render() {
    const {
      upComingOrderDate,
      upComingOrder,
      onChangeUpcomingDate,
      onUpcomingDetailsChange,
      undoUpcomingItems,
      defaultAddress,
      updateUpcomingItems,
      customerPlanItems,
      customerPlans,
      openModal,
      customerId,
      customerInfo,
      onCreateCustomPlan,
      newScheduledItem,
      cancelNewScheduledItem,
    } = this.props;
    let customerSchedules = [];
    let scheduledItems = [];

    const defaultSchedules = !isEmpty(customerPlans) ? this.getDefaultSchedules(customerPlans) : this.getDefaultPlans();
    customerSchedules = [...customerPlans, ...defaultSchedules];
    scheduledItems = this.formatPlanItems(customerPlanItems);
    const sortedUpcomingOrder = orderBy(upComingOrder, item => item.product.name, 'asc');
    return (
      <div>
        <Typography style={styles.title}>UPCOMING DELIVERIES</Typography>
        <Upcoming
          customerId={customerId}
          filter={upComingOrderDate}
          data={sortedUpcomingOrder}
          onChangeFilter={onChangeUpcomingDate}
          openModal={openModal}
          onUpcomingDetailsChange={onUpcomingDetailsChange}
          undoUpcomingItems={undoUpcomingItems}
          defaultAddress={defaultAddress}
          updateUpcomingItems={updateUpcomingItems}
        />
        <Typography style={styles.title}>CUSTOMER PLANS</Typography>
        <Scheduled
          customerId={customerId}
          schedules={customerSchedules}
          items={scheduledItems}
          customerInfo={customerInfo}
          onUpdateItems={this.onUpdatePlannedItems}
          onDeleteItem={this.onDeletePlannedItem}
          onCreateCustomPlan={onCreateCustomPlan}
          openModal={openModal}
          newScheduledItem={newScheduledItem}
          cancelNewScheduledItem={cancelNewScheduledItem}
          getCancelledPlans={this.getCancelledPlans}
          showDeletedSchedules={false}
        />


      </div>
    );
  }
}

Plans.propTypes = {
  upComingOrderDate: PropTypes.string,
  upComingOrder: PropTypes.instanceOf(Array),
  onChangeUpcomingDate: PropTypes.func,
  onUpcomingDetailsChange: PropTypes.func,
  undoUpcomingItems: PropTypes.func,
  defaultAddress: PropTypes.instanceOf(Object),
  updateUpcomingItems: PropTypes.func,
  customerPlanItems: PropTypes.instanceOf(Array),
  customerPlans: PropTypes.instanceOf(Array),
  openModal: PropTypes.func,
  customerId: PropTypes.string,
  customerInfo: PropTypes.instanceOf(Object),
  addPlannedItem: PropTypes.func,
  editPlannedItem: PropTypes.func,
  deletePlannedItem: PropTypes.func,
  onCreateCustomPlan: PropTypes.func,
  newScheduledItem: PropTypes.instanceOf(Object),
  cancelNewScheduledItem: PropTypes.func,
};

export default Plans;
