import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FileUpload from '@material-ui/icons/FileUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import apiCall from "../api/NetworkHandler";
import ProductCatalogTable from './ProductCatalogTable';
import NavBar from './NavBar';

const VENDORLIST = ['Dodla', 'Nestle', 'Tirumala', 'Amul', 'Heritage', 'Nandini', 'Vrindavan'];


class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      po: null,
      searchText: '',
      open: false,
      c_name: '',
      selectedVendor: '',
      vendorList: [],
      selectedCities: [],
      selectedFile: null,
      vendor: {},
    };
  }

  componentDidMount() {
    this.setState({
      vendor: this.props.vendor,
    }, () => {
      console.log(this.state.vendor);
    });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp) {
      if (nextProp.vendor !== this.state.vendor) {
        this.setState({
          vendor: nextProp.vendor,
        }, () => {
          console.log(this.state.vendor);
        });
      }
    }
  }

  handleVendorChange = (event) => {
    this.setState({
      selectedVendor: event.target.value,
      vendorList: VENDORLIST.filter(q => (q.toLowerCase().includes(event.target.value.toLowerCase()))).slice(0, 1),
    });
  }

  getPurchaseOrder = (data) => {
    this.setState({
      po: data,
    });
  }

  handleRegionChange = (event) => {
    this.setState({ selectedCities: event.target.value });
  }

  handleInputChange = prop => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    this.setState({
      selectedFile: file,
    });
  }

  uploadCatalogFile = () => {
    const formData = new FormData();
    formData.append('file', this.state.selectedFile);
    formData.append('name', this.state.c_name);
    formData.append('locations', this.state.selectedCities.join(','));

    apiCall.sendRequest('post', `/api/v1/vendors/${this.state.vendor.id}/vendor-catalogs/upload`, formData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    this.setState({
      open: false,
    });
  }


  render() {
    const { match: { params } } = this.props;
    return (
      <div>
        <NavBar />
        <div style={{ height: '6em', backgroundColor: '#EEEEEE', lineHeight: '6em' }}>
          <span style={{
 height: '2em', verticalAlign: 'middle', paddingLeft: '2em', display: 'inline-block', lineHeight: 'normal',
 }}
          >
             Vendors Products Catalog
          </span>
          {this.props.showFilter &&
            <span style={{ marginLeft: '5em' }}>
              <FormControl color="secondary" style={{ width: '25em' }}>
                <Input
                  value={this.props.searchQuery}
                  color="secondary"
                  placeholder="Search"
                  onChange={this.props.handleQueryChange}
                  onKeyPress={(event) => { if (event.key === 'Enter') { this.props.onSearch(); } }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </span>}
          {params.id !== 'product' ?
            <span style={{ float: 'right', paddingRight: '2em' }}>
              <Button onClick={() => { this.setState({ open: true }); }} size="small" style={{ paddingRight: '2em' }} color="secondary">
                <FileUpload />
                IMPORT CATALOG
              </Button>
              <Button variant="contained" color="secondary" >
                <Link to={`/new-catalog/${params.id}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                  NEW VENDOR CATALOG
                </Link>
              </Button>
            </span>
            : null}
        </div>
        <div style={{ width: '80%', marginLeft: '10%' }}>
          <ProductCatalogTable data={this.props.data} vendorId={params.id} fetchAllDetails={this.props.fetchAllDetails} />
        </div>
        <Dialog
          open={this.state.open}
          onClose={() => { this.setState({ open: false }); }}
        >
          <DialogTitle><Typography variant="title" color="secondary"> Import Vendor Catalog Zip</Typography></DialogTitle>
          <DialogContent style={{ marginTop: '2em' }}>
            <div>
              <input type="file" onChange={this.handleFileUpload} />
              <TextField
                style={{ marginTop: '1.8em' }}
                label="Catalog Name"
                value={this.state.c_name}
                onChange={this.handleInputChange('c_name')}
                fullWidth
              />
              <TextField
                style={{ marginTop: '1.8em' }}
                label="Vendor"
                fullWidth
                value={this.state.vendor.name ? this.state.vendor.name : ''}
                disabled={!!this.state.vendor.name}
                onChange={this.handleVendorChange.bind(this)}
              />
              {this.state.vendorList.length != 0 &&
              <div style={{ position: 'absolute', zIndex: 3000, width: '90%' }}>
                <List style={{ border: '2px', borderStyle: 'solid', borderColor: '#EEEEEE' }} component="nav">
                  {this.state.vendorList.map(x => (<ListItem onClick={() => { this.setState({ selectedVendor: x, vendorList: [] }); }} button><ListItemText primary={x} /></ListItem>))}
                </List>
              </div>
              }
              { this.state.vendorList.length == 0 &&
              <div style={{ marginTop: '1.8em' }}>
                <InputLabel style={{ fontSize: '0.7em' }}>Location</InputLabel>{/* abc */}
                <Select
                  multiple
                  value={this.state.selectedCities}
                  onChange={this.handleRegionChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div>
                      {selected.map((value, index) => {
                        let name = '';
                        if (this.state.vendor.vendorLocations) {
                          name = this.state.vendor.vendorLocations.find(vendorLocation => value === vendorLocation.id).name;
                        }
                        return (<Chip key={index} style={{ padding: 0 }} label={name} />);
                      })
                    }
                    </div>
                )}
                  fullWidth
                >
                  {this.state.vendor && this.state.vendor.vendorLocations &&
                this.state.vendor.vendorLocations.map(name => (
                  <MenuItem
                    key={name.id}
                    value={name.id}
                    style={{
                      fontWeight:
                        this.state.selectedCities.indexOf(name.id) === -1
                          ? '200'
                          : '400',
                    }}
                  >
                    {name.name}
                  </MenuItem>
                ))}
                </Select>
              </div>}
              <div style={{ marginTop: this.state.vendorList.length == 0 ? '1.8em' : '4.2em', marginBottom: '2.1em' }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ open: false }); }} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" onClick={() => { this.uploadCatalogFile(); }} color="secondary">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default withRouter(Products);
