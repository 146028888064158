import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchRegionsByName } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchRegionsByName(searchTerm))
  const { data: { _embedded } } = response;
  const { regions } = _embedded;

  return regions.map(region => {
    return {
      ...region,
      value: region.id,
      label: region.name,
    }
  })
}

function RegionSelector(props) {
  const { selected, onSelect, isClearable, disabled } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
      disabled={disabled}
    />
  )
}

RegionSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
  /**
   * Select box can be disabled or not
   */
  disabled: PropTypes.bool,
};

RegionSelector.defaultProps = {
  selected: null,
  isClearable: true,
  disabled: false,
};

export default RegionSelector;
