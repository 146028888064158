import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableBody,
  Typography,
  Modal,
  TextField,
  TableRow,
  TableCell,
  Button,
  Table,
  Grid
} from '@material-ui/core/';
import TableHeader from '../../Commons/components/TableHeader';
import Lozenge from '@atlaskit/lozenge';
import CloseIcon from '@material-ui/icons/Close';
import { Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import apiCall, { updateOrderAPIStatus } from '../../../api/NetworkHandler';
import { debounce } from "lodash";


const columnData = [
    {
        id: 'city', numeric: false, disablePadding: true, label: 'City',
    },
    {
        id: 'errMsg', numeric: false, disablePadding: true, label: 'Message',
    },
    {
        id: 'enabled', numeric: false, disablePadding: true, label: 'Status',
    },
    {
        id: 'updatedAt', numeric: false, disablePadding: true, label: 'Last Updated',
    },
    {
        id: 'actions', numeric: false, disablePadding: true, label: 'Action',
    }
];

const styles = {
  tableCell: {
    textAlign: 'center',
  },
};

class OrderStatusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      openModal: false,
      disableButton: false,
    };
  }

  handleOpenModal = (selectedData) => {
    this.setState({
      selectedData,
      openModal: true,
    });
  };

  handleCloseModal = () => {
    const { onRefresh } = this.props;
    this.setState({
      openModal: false,
    }, () => onRefresh());
  };

  toggleStatus = (event) => {
    const { selectedData } = this.state;
    this.setState({
      selectedData: {
        ...selectedData,
        enabled: event
      }
    });
  };

  handleChangeMessage = (event) => {
    const { value } = event.target;
    const { selectedData } = this.state;
    this.setState({
      selectedData: {
        ...selectedData,
        errMsg: value,
      }
    });
  };

  toggleButtonState = (state) => {
    this.setState({
      disableButton: state,
    });
  };

  updateOrderStatus = debounce( async () => {
    const { selectedData } = this.state;
    this.toggleButtonState(true);
    try {
      await apiCall.sendRequest('post', updateOrderAPIStatus, selectedData);
      this.handleCloseModal();
      this.toggleButtonState(false);
    } catch (err) {
      this.handleCloseModal();
      this.toggleButtonState(false);
    }
  }, 750);

  render() {
    const { OrderStatusData } = this.props;
    const { openModal, selectedData, disableButton } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={12} style={{ paddingLeft: '15px' }}>
          <Table>
            <TableHeader columns={columnData} cellStyle={styles.tableCell} />
            <TableBody>
              {
                OrderStatusData.map(item => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={item.id}
                  >
                    <TableCell component="th" scope="row" padding="none">{item.city}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{item.errMsg}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                        <Lozenge appearance={item.enabled ? "inprogress" : "removed"}>{item.enabled ? "Enabled" : "Disabled"}</Lozenge>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none" >{moment().format('ll')}</TableCell>
                    <TableCell component="th" scope="row" padding="none">
                        <Button size="small" color="primary" variant="contained" onClick={() => this.handleOpenModal(item)}> Edit </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModal}
          onClose={this.handleCloseModal}
          disableBackdropClick
        >
          <div style={{
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'white',
                width: '35%',
                margin: '0 auto',
                height: '40%',
                overflowX: 'auto',
                overflowY: 'auto',
                borderRadius: '5px',
              }}
          >
            <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", top: 0, width: "100%" }}>
              <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: "50px", paddingTop: "10px" }}>
                  <Typography variant="title" color="inherit"> Order Status </Typography>
              </div>
              <Button style={{ float: 'right' }} onClick={this.handleCloseModal} color="secondary" aria-label="close">
                  <CloseIcon />
              </Button>
            </div>
            <Grid style={{ padding: '2em', marginTop: window.innerHeight * 0.05 }}>
              <Row>
                <ColMod custompadding="true" bumphigh="true" xs md lg={15}>
                  <Row>
                    <ColMod custompadding="true" bumphigh="true" lg={6}>
                      <TextField
                        label="City"
                        fullWidth
                        value={selectedData.city}
                        InputProps={{ readOnly: true }}
                      />
                    </ColMod>
                    <ColMod custompadding="true" bumphigh="true" lg={6}>
                      <Typography variant="caption"> Status </Typography>
                      <div>
                        <small style={{ color: "red" }}>DISABLE</small>
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={selectedData.enabled}
                          onChange={this.toggleStatus}
                          style={{ margin: "10px" }}
                        />
                        <small style={{ color: "green" }}>ENABLE</small>
                      </div>
                    </ColMod>
                  </Row>
                  <Row>
                    <ColMod custompadding="true" bumphigh="true" lg={12}>
                      <Typography variant="caption"> Message </Typography>
                      <textarea rows="4" cols="50" maxlength="250" style={{ width: "100%" }} onChange={this.handleChangeMessage}>
                        {selectedData.errMsg}
                      </textarea>
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ position: "fixed", bottom: 15, marginLeft: "83%" }}>
              <Button variant="contained" color="secondary" onClick={this.updateOrderStatus} disabled={disableButton}> Update </Button>
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}


OrderStatusList.propTypes = {
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  OrderStatusData: PropTypes.instanceOf(Array),
};

export default OrderStatusList;

