const o = {};

const FilterStore = {
  saveFilters: (filters, page) => {
    o[page] = filters;
  },
  getFilters: page => o[page],
  saveLatestMessage: (message) => {
    o.latestMessage = message;
  },
  getLatestMessage: () => o.latestMessage,
};

export default FilterStore;
