import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Button, TextField, Select, MenuItem } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import { ArrowBack } from '@material-ui/icons';
import SectionMessage from '@atlaskit/section-message';
import WarehouseSelector from '../../../components/Warehouse/Selector';
import { saveAs } from 'file-saver/FileSaver';
import apiCall, { getWarehouseHubs, routesByHubIdPath, deliveryRouteOrdersCsv } from '../../../api/NetworkHandler';
import moment from 'moment';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: 'red',
  }
}

class DownloadReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWarehouse: null,
      downloadData: {
        warehouseId: null,
        deliveryDate: moment(new Date()).format("YYYY-MM-DD"),
        hubId: '',
        routeName: '',
      },
      hubs: [],
      routes: [],
      error: {},
      errorMessage: '',
    }
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    });
  }

  isPropertyValid = (property, value) => {
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  isFormValid = () => {
    const { downloadData } = this.state;
    let isFormValid = true;
    const validateEntities = ['warehouseId', 'deliveryDate'];

    for (const property in downloadData) {
      if (validateEntities.includes(property)) {
        if (!this.isPropertyValid(property, downloadData[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  downloadOrders = async () => {
    if (!this.isFormValid()) {
      return;
    }
    const { downloadData } = this.state;
    const { onClose } = this.props;
    const { warehouseId, deliveryDate, hubId, routeName } = downloadData;
    const fromDate = deliveryDate;
    const toDate = deliveryDate;
    const url = deliveryRouteOrdersCsv(warehouseId, fromDate, toDate, hubId, routeName);

    try {
      const response = await apiCall.sendRequest('get', url, null, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'delivery_route_orders.csv');
      onClose();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }
  }

  onWarehouseChange = (warehouse) => {
    const { downloadData } = this.state;
    this.setState({
      selectedWarehouse: warehouse,
      downloadData: {
        ...downloadData,
        warehouseId: warehouse ? warehouse.id : null,
      }
    }, () => { this.fetchHubs() });
  }

  onInputChange = (event) => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const { downloadData } = prevState;

      if (name == "hubId") {
        downloadData.routeName = '';
      }

      return {
        downloadData: {
          ...downloadData,
          [name]: value,
        }
      }
    }, () => {
      if (name == "hubId") {
        this.fetchRoutes();
      }
    })
  }

  fetchHubs = async () => {
    const { selectedWarehouse: { id } } = this.state;
    const response = await apiCall.sendRequest('get', getWarehouseHubs(id));
    const { data: { data } } = response;
    this.setState({ hubs: data });
  }

  fetchRoutes = async () => {
    const { downloadData: { hubId } } = this.state;
    const response = await apiCall.sendRequest('get', routesByHubIdPath(hubId));
    const { data: { _embedded = {} } = {} } = response;
    this.setState({ routes: _embedded['static-routes'] });
  }

  render() {
    const { classes, onClose } = this.props;
    const { selectedWarehouse, downloadData, hubs, routes, error, errorMessage } = this.state;
    const {
      deliveryDate,
      hubId,
      routeName
    } = downloadData;
    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Download Orders</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
                <Button type="submit" variant="contained" color="secondary" onClick={this.downloadOrders}>Download</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod custompadding="true" bumphigh="true">
            <Typography color="error" variant="caption">* Required</Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            {errorMessage && <SectionMessage appearance="error">{errorMessage}</SectionMessage>}
          </ColMod>
        </Row>}
        <Row md={12}>
          <ColMod md={6}>
            <Typography className={error.warehouseId ? classes.error : null} variant="caption"> Warehouse *</Typography>
            <WarehouseSelector
              selected={selectedWarehouse}
              onSelect={this.onWarehouseChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              type="date"
              label="Delivery Date"
              name="deliveryDate"
              value={deliveryDate || ''}
              onChange={this.onInputChange}
              fullWidth
              required
              error={error.deliveryDate}
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption">Hub</Typography>
            <Select
              name="hubId"
              fullWidth
              value={hubId || ''}
              onChange={this.onInputChange}
            >
              {
                hubs.map(hub => (
                  <MenuItem key={hub.id} value={hub.id}>{hub.name}</MenuItem>
                ))
              }
            </Select>
          </ColMod>
          <ColMod md={6}>
            <Typography variant="caption">Route</Typography>
            <Select
              name="routeName"
              fullWidth
              value={routeName || ''}
              onChange={this.onInputChange}
            >
              {
                routes.map(route => (
                  <MenuItem key={route.id} value={route.name}>{route.name}</MenuItem>
                ))
              }
            </Select>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(DownloadReportForm);
