import React from 'react';
import PurchaseOrder from '../components/PurchaseOrder';

class App extends React.Component {
  static propTypes ={

  }

  render() {
    return (
      <div>
        <PurchaseOrder />
      </div>
    );
  }
}

export default (App);

