import React, { Component } from 'react';
import { Button, TextField, InputLabel, Grid } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';
import API, { disposeTicket } from '../../../api/NetworkHandler';

class Disposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tstatus: '',
      asignee: null,
      comments: '',
      isDisabled: true,
    };
  }

  setRemark = (event) => {
    this.setState(
      {
        comments: event.target.value,
      },
      () => this.validate(),
    );
  };

  getAgent = (name) => {
    const { agents } = this.props;
    const agent = agents.find(cc => cc.name === name);
    return agent || '';
  };

  handleAssignee = (asignee) => {
    if (asignee === null) {
      this.setState({
        asignee: null,
      });
    } else {
      this.setState({ asignee: asignee.value });
    }
  };

  handleClick = async () => {
    const { users, ticketId } = this.props;
    const { comments, asignee, tstatus } = this.state;

    const disposition = {
      assignee: asignee,
      action: tstatus,
      ticketId: Number(ticketId),
      comments,
      updatedBy: users.id,
      updatedUserEmail: users.email,
    };
    try {
      await API.sendRequest('post', disposeTicket, disposition);
      this.setState({
        tstatus: '',
        asignee: null,
        comments: '',
        isDisabled: false,
      });
      this.props.onSubmit();
    } catch (err) {
      console.log(err);
    }
  };

  handleToMe = () => {
    const { users } = this.props;
    const agent = this.getAgent(users.name);
    this.setState({
      asignee: agent.id,
    });
  };

  handleStatus = (tstatus) => {
    if (tstatus === null) {
      this.setState(
        {
          tstatus: null,
        },
        () => this.validate(),
      );
    } else {
      this.setState(
        {
          tstatus: tstatus.value,
        },
        () => this.validate(),
      );
    }
  };

  formatAgents = data =>
    data.map(el => ({
      label: el.name,
      value: el.id,
    }));

  validate = () => {
    const { tstatus, comments } = this.state;
    if (tstatus !== '' && comments !== '') {
      this.setState({
        isDisabled: false,
      });
    }
  };
  render() {
    const {
      tstatus = '', asignee, isDisabled, comments,
    } = this.state;
    const { ticketDispositionTypes = {}, agents = {} } = this.props;
    return (
      <div>
        <InputLabel htmlFor="status-type">Status</InputLabel>
        <Grid item xs={5}>
          <Select
            options={Object.keys(ticketDispositionTypes).map(key => ({
              label: key,
              value: key,
            }))}
            value={tstatus || ''}
            onChange={this.handleStatus}
          />
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={5}>
            <InputLabel htmlFor="assigned-to">Assignee</InputLabel>
            <Select
              options={this.formatAgents(agents)}
              placeholder={'Select an Asignee' || asignee}
              value={asignee}
              onChange={this.handleAssignee}
            />
          </Grid>
          <Grid item md={2}>
            <Button
              style={{ background: '#ffffff', color: '#3273a8', marginTop: 20 }}
              onClick={this.handleToMe}
            >
              Assign to me
            </Button>
          </Grid>
        </Grid>
        <br />
        <TextField
          id="comment-box"
          label="Remarks"
          multiline
          fullWidth
          rows="2"
          margin="normal"
          variant="outlined"
          value={comments}
          onChange={this.setRemark}
        />
        <br />
        <Button
          style={{ float: 'right' }}
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          onClick={this.handleClick}
        >
          Save
        </Button>
      </div>
    );
  }
}

Disposition.propTypes = {
  agents: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Object),
  ticketId: PropTypes.string,
  ticketDispositionTypes: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
};

export default Disposition;
