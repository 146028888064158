import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableRow, TableCell, TableBody, Button, TablePagination } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import SelectedItemDetail from '../../Commons/components/SelectedItemDetail';


const columnData = [
  {
    id: 'customerName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'ticketCategory',
    numeric: false,
    disablePadding: false,
    label: 'Reason',
  },
  {
    id: 'source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Upated Time',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];
const styles = {
  cellStyle: {
    textAlign: 'center',
    padding: '0px 10px',
  },
};
class TicketList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: {},
    };
  }

  handleClick = (item) => {
    this.setState({
      rowSelected: item,
    });
  };
  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };


  render() {
    const {
      data, fullTable = false, onEditClick, totalElements, rowsPerPage, page,
    } = this.props;
    const { rowSelected } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} md={fullTable ? 12 : 8} style={{ overflow: 'scroll' }}>
          <Table>
            <TableHeader columns={columnData} cellStyle={styles.cellStyle} />
            <TableBody>
              {
                  data.map(ticket => (
                    <TableRow
                      hover
                      onClick={() => this.handleClick(ticket)}
                      tabIndex={-1}
                      key={ticket.id}
                      selected={rowSelected && rowSelected.id === ticket.id}
                    >
                      <TableCell style={styles.cellStyle}>{ticket.customerName}</TableCell>
                      <TableCell style={styles.cellStyle}>{ticket.ticketType}</TableCell>
                      <TableCell style={styles.cellStyle}>{ticket.source}</TableCell>
                      <TableCell style={styles.cellStyle}>{ticket.status}</TableCell>
                      <TableCell style={styles.cellStyle}>{moment(ticket.timeUpdated).format('lll')}</TableCell>
                      <TableCell style={styles.cellStyle}>
                        <Button variant="contained" color="secondary">
                          <a style={{ color: 'white', textDecoration: 'none' }} href={`/tickets/${ticket.id}`}>View</a>
                        </Button>
                        {onEditClick && <Button
                          variant="flat"
                          color="secondary"
                          onClick={() => onEditClick(true, ticket)}
                        >
                          Edit
                        </Button>}
                      </TableCell>
                    </TableRow>
                  ))
                }
            </TableBody>
          </Table>
          {/* {data && data.length > 0 ?
            <TablePagination
              component="div"
              count={totalElements}
              page={page}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
            />
              : null
          } */}
        </Grid>
        {!fullTable
        && (
        <Grid item xs={12} md={4} style={{ padding: '1em 1em', background: Colors.lightGrey, minHeight: 400 }}>
          { (!isEmpty(rowSelected) && Object.keys(rowSelected).length > 0)
            ? <SelectedItemDetail item={rowSelected} />
            : <div />}
        </Grid>
        )}
      </Grid>
    );
  }
}

TicketList.propTypes = {
  data: PropTypes.instanceOf(Array),
  fullTable: PropTypes.bool,
  onEditClick: PropTypes.func,
  totalElements: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default TicketList;
