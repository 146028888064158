import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';
import BrandTable from './BrandTable';
import BrandSearchBar from './BrandSearchBar';
import apiCall, { getAllBrandsPath, allBrandsPath, allBrandsPathImages } from '../api/NetworkHandler';
import BASE_URL from '../api/config';

import { ColMod } from './lib';
import isEmpty from "lodash/isEmpty";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  fullScreen: {
	  flex: 1,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: null,
      brands: [],
      links: {},
      openAddBrand: false,
      selectedBrand: {},
      selectedName: '',
      selectedDescription: '',
      imageUrl: null,
      isEdit: false,
      filters: {
        query: '',
      },
      error: {},
      searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchAllBrands();
  }


  onSearch = () => {
    this.fetchAllBrands();
  };

  fetchAllBrands = () => {
    if (this.state.filters.query) {
      // TODO Add search api
      apiCall.sendRequest('get', getAllBrandsPath(this.state.filters.query))
        .then((response) => {
          this.setState({
            brands: response.data._embedded.brands,
            links: response.data._links,
            searchedResult: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('get', allBrandsPath)
        .then((response) => {
          this.setState({
            brands: response.data._embedded.brands,
            links: response.data._links,
            searchedResult: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  fetchPaginatedBrands = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        this.setState({
          brands: response.data._embedded.brands,
          links: response.data._links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

handleQueryChange = (event) => {
  const query = event.target.value;
  this.setState(prevState => ({
    filters: {
      ...prevState.filters,
      query,
    },
  }));
}

  isFormValid = () => {
    if (isEmpty(this.state.selectedName)) {
      this.setError('selectedName', true);
      return false;
    }
    this.setError('selectedName', false);
    return true;
  };

  clearSearch = () => {
    this.setState({
      filters: {
        query: '',
      },
    }, () => {
      this.onSearch();
    });
  }

  handleClick = (selectedBrand) => {
    this.setState({
      selectedBrand,
      selectedName: selectedBrand.name,
      selectedDescription: selectedBrand.description ? selectedBrand.description : null,
      imageUrl: selectedBrand.logo,
    });
  }

    openAddBrand = (brand) => {
      if (brand) {
        this.setState({
          selectedName: brand.name,
          selectedDescription: brand.description,
          imageUrl: brand.logo,
        });
      }
      console.log('Called');
      this.setState({
        openAddBrand: true,
        isEdit: !!brand,
      });
    }

    handleClose = () => {
      this.setState({
        openAddBrand: false,
        error: {},
        isEdit: false,
      });
    }

  handleImageUpload = (imageUrl) => {
    this.setState({
      imageUrl,
    });
  }

  clearImage = () => {
    this.setState({
      imageUrl: null,
    });
  };

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  postBrand = () => {
    if (!this.isFormValid()) {
      return;
    }
    const postData = { ...this.state.selectedBrand };
    postData.name = this.state.selectedName;
    postData.description = this.state.selectedDescription;
    postData.logo = this.state.imageUrl;
    apiCall.sendRequest('post', allBrandsPath, postData)
      .then((response) => {
        this.handleClose();
        this.fetchAllBrands();
      })
      .catch((error) => {
        console.error(error);
        this.handleClose();
      });
  }

  render() {
    return (
      <div>
        <NavBar />
        <BrandSearchBar openAddBrand={this.openAddBrand} handleQueryChange={this.handleQueryChange} onSearch={this.onSearch} clearSearch={this.clearSearch} searchQuery={this.state.filters.query} />
        <div style={styles.div}>
          <div style={styles.fullScreen}>
            <BrandTable brands={this.state.brands} openAddBrand={this.openAddBrand} handleClick={this.handleClick} links={this.state.links} fetchPaginatedBrands={this.fetchPaginatedBrands} searchedResult={this.state.searchedResult} clearData={this.clearData} />
          </div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openAddBrand}
            onClose={this.handleClose}
          >
            <div style={{
                  position: 'relative',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'white',
                  width: '70%',
                  marginLeft: '15%',
                  height: '70%',
                }}
            >
              <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <Row>
                      <Typography variant="body2" color="secondary">
                        {this.state.isEdit ? 'Update Brand' : 'Create Brand'}
                      </Typography>
                    </Row>
                    <Row>
                    <Typography variant="caption" color="error">
                      *Required Field
                    </Typography>
                    </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                          label="Name"
                          fullWidth
                          value={this.state.selectedName}
                          required
                          error={this.state.error.selectedName}
                          onChange={(event) => {
                              const {value} = event.target;
                              this.setState({
                                selectedName: value,
                              });
                            }}
                        />
                      </ColMod>
                      <ColMod lg={6}>
                        <Upload url={this.state.imageUrl ? this.state.imageUrl : null} side="LOGO" handleImageUpload={imageUrl => this.handleImageUpload(imageUrl)} clearImage={() => { this.clearImage(); }} />
                      </ColMod>
                    </Row>
                    <Row>
                      <ColMod lg={12}>
                        <TextField
                          label="Description"
                          fullWidth
                          value={this.state.selectedDescription}
                          onChange={(event) => {
                              const {value} = event.target;
                              this.setState({
                                selectedDescription: value,
                              });
                            }}
                        />
                      </ColMod>
                    </Row>
                  </ColMod>
                </Row>
              </Grid>
              <div style={{ float: 'right', paddingRight: '2em' }}>
                <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.postBrand();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {this.state.isEdit ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const image = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(image),
    });
    const formData = new FormData();
    formData.append('images', image);
    apiCall.sendRequest('post', allBrandsPathImages, formData)
      .then((response) => {
        console.log(response.data);
        this.props.handleImageUpload(Object.values(response.data)[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (this.state.file || this.props.url) {
      return (
        <div style={{
 height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px',
}}
        >
          <img style={{ height: '5em', width: 'auto' }} src={this.props.url || this.state.file} />
          <CloseIcon
            color="secondary"
            style={{
 fontSize: '1.6em', position: 'absolute', top: '5px', right: '5px', cursor: 'pointer',
 }}
            onClick={() => { this.props.clearImage(this.props.side); this.setState({ file: null }); }}
          />
        </div>
      );
    }
    const textDisplay = 'LOGO';
    return (
      <div style={{
height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px',
}}
      >
        <label style={{ lineHeight: '5em' }}>{textDisplay}
          <input accept="image/*" type="file" style={{ visibility: 'hidden' }} onChange={this.handleChange} />
        </label>
      </div>
    );
  }
}


export default Dashboard;
