import { isEmpty, filter, find } from 'lodash';
import moment from 'moment';
import * as type from '../constants';

const INITIAL_STATE = {
  q: '',
  noCsList: false,
  csList: [],
  customerId: null,
  customerInfo: {},
  editableCustomerInfo: {},
  customerInfoFailed: false,
  allOrders: [],
  ongoingOrder: {},
  upComingOrder: [],
  upComingOrderDate: moment()
    .add(1, 'days')
    .format('YYYY-MM-DD'),
  customerPlans: [],
  customerPlanItems: [],
  customerHasNoOrders: false,
  currentOrdersPage: 0,
  hasMoreOrders: true,
  customerAddresses: [],
  selectedAddress: {},
  defaultAddress: {},
  newAddress: {},
  transactionDetails: [],
  initiatedTransactions: [],
  refundTxns: false,
  walletBalance: null,
  refundAmount: null,
  maxRefundAmount: null,
  currentDeliveryRouteInfo: {},
  currentDeliveryRouteList: [],
  orderId: null,
  orderStatus: null,
  selectedOrder: {},
  selectedOrderLineItems: [],
  newScheduledItem: {},
  customerInvoice: [],
  currentInvoicePage: 0,
  hasMoreInvoices: true,
  totalWalletBalance: null,
};

const csReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.QUICK_SEARCH_LOADING:
      return {
        ...state,
        q: action.payload,
      };
    case type.QUICK_SEARCH_SUCCESS:
      return {
        ...state,
        csList: action.payload,
        noCsList: false,
      };
    case type.QUICK_SEARCH_FAILURE:
      return {
        ...state,
        noCsList: true,
      };
    case type.CLEAR_QUICK_SEARCH:
      return {
        ...state,
        q: '',
        csList: [],
        noCsList: false,
      };
    case type.SELECT_QUICK_SEARCH_ITEM:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case type.FETCH_CS_INVOICE_SUCCESS:
      return {
        ...state,
        customerInvoice: action.payload.invoiceList,
        currentInvoicePage: action.payload.currentInvoicePage,
        hasMoreInvoices: (action.payload.currentInvoicePage + 1) < action.payload.totalInvoicePages,
      };
    case type.FETCH_CS_INVOICE_FAILURE:
      return {
        ...state,
        customerInvoice: {},
      };
    case type.FETCH_CS_MORE_INVOICE_SUCCESS:
      return {
        ...state,
        customerInvoice: [...state.customerInvoice, ...action.payload.invoiceList],
        currentInvoicePage: action.payload.currentInvoicePage,
        hasMoreInvoices: (action.payload.currentInvoicePage + 1) < action.payload.totalInvoicePages,
      };
    case type.FETCH_CS_MORE_INVOICE_FAILURE:
      return {
        ...state,
        hasMoreInvoices: false,
      };
    case type.FETCH_CS_INFO_SUCCESS:
      return {
        ...state,
        q: '',
        noCsList: false,
        csList: [],
        upComingOrderDate: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        customerPlans: [],
        customerPlanItems: [],
        customerHasNoOrders: false,
        currentOrdersPage: 0,
        hasMoreOrders: true,
        transactionDetails: [],
        initiatedTransactions: [],
        refundTxns: false,
        walletBalance: null,
        refundAmount: null,
        maxRefundAmount: null,
        currentDeliveryRouteInfo: {},
        currentDeliveryRouteList: [],
        orderId: null,
        orderStatus: null,
        selectedOrder: {},
        selectedOrderLineItems: [],
        customerInfo: action.payload,
        editableCustomerInfo: action.payload,
        customerId: action.payload.customerId,
        customerInfoFailed: false,
        newScheduledItem: {},
      };
    case type.FETCH_CS_INFO_FAILURE:
      return {
        ...state,
        customerInfo: {},
        editableCustomerInfo: {},
        customerId: null,
        customerInfoFailed: true,
      };
    case type.FETCH_CS_ORDERS_SUCCESS:
      return {
        ...state,
        allOrders: action.payload.csOrders,
        currentOrdersPage: action.payload.page,
        customerHasNoOrders: isEmpty(action.payload.csOrders),
        ongoingOrder: filter(action.payload.csOrders, el => el.status === 'IN_DELIVERY')[0] || {},
      };
    case type.FETCH_CS_ORDERS_FAILURE:
      return {
        ...state,
        allOrders: [],
        currentOrdersPage: 0,
        customerHasNoOrders: true,
        ongoingOrder: {},
      };
    case type.FETCH_CS_MORE_ORDERS_SUCCESS:
      return {
        ...state,
        allOrders: [...state.allOrders, ...action.payload.csOrders],
        currentOrdersPage: action.payload.page,
        hasMoreOrders: !isEmpty(action.payload.csOrders),
      };
    case type.FETCH_CS_MORE_ORDERS_FAILURE:
      return {
        ...state,
        hasMoreOrders: false,
      };
    case type.FETCH_CS_ADDRESSES_SUCCESS:
      return {
        ...state,
        customerAddresses: action.payload,
        selectedAddress: find(action.payload, el => el.isDefault),
        defaultAddress: find(action.payload, el => el.isDefault),
      };
    case type.NEXT_DELIVERY_ITEMS_SUCCESS:
      return {
        ...state,
        upComingOrder: action.payload.data,
        upComingOrderDate: action.payload.date,
      };
    case type.GET_CS_PLANS_SUCCESS:
      return {
        ...state,
        customerPlans: action.payload,
      };
    case type.GET_CS_PLAN_ITEMS_SUCCESS:
      return {
        ...state,
        customerPlanItems: action.payload,
      };
    case type.UPDATE_CS_PLAN:
      return {
        ...state,
        ...action.payload,
      };
    case type.ADD_NEW_UPCOMING_ITEM:
      return {
        ...state,
        upComingOrder: [...state.upComingOrder, { ...action.payload }],
      };
    case type.ADD_NEW_SCHEDULED_ITEM:
      return {
        ...state,
        newScheduledItem: action.payload,
      };
    case type.CANCEL_NEW_SCHEDULED_ITEM:
      return {
        ...state,
        newScheduledItem: {},
      };
    case type.ADD_PLANNED_ITEM_SUCCESS:
    case type.ADD_PLANNED_ITEM_FAILURE:
      return {
        ...state,
        newScheduledItem: {},
      };
    case type.DT_EDIT_ORDER:
      return {
        ...state,
        selectedOrder: action.payload,
        orderId: action.payload.id,
        orderStatus: action.payload.status,
        selectedOrderLineItems: action.payload.customerOrderLineItems,
      };
    case type.ORDER_DETAILS_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case type.ADD_NEW_LINE_ITEM:
      return {
        ...state,
        selectedOrderLineItems: [
          ...state.selectedOrderLineItems,
          {
            ...action.payload,
            name: action.payload.productName,
          },
        ],
      };
    case type.UPDATE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderId: null,
        orderStatus: null,
        selectedOrder: {},
        selectedOrderLineItems: [],
      };
    case type.UPDATE_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderId: null,
        orderStatus: null,
        selectedOrder: {},
        selectedOrderLineItems: [],
      };
    case type.CHANGE_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case type.ADDRESS_EDIT:
      return {
        ...state,
        selectedAddress: { ...state.selectedAddress, ...action.payload },
      };
    case type.NEW_ADDRESS_EDIT:
      return {
        ...state,
        newAddress: { ...state.newAddress, ...action.payload },
      };
    case type.PROFILE_EDIT:
      return {
        ...state,
        editableCustomerInfo: { ...state.editableCustomerInfo, ...action.payload },
      };
    case type.FETCH_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionDetails: action.payload,
      };
    case type.FETCH_INITIATED_TRANSACTION_SUCCESS:
      return {
        ...state,
        initiatedTransactions: action.payload,
      };
    case type.FETCH_REFUND_TRANSACTION:
      return {
        ...state,
        refundTxns: action.payload,
      };
    case type.FETCH_WALLET_SUCCESS:
      return {
        ...state,
        walletBalance: action.payload,
      };
    case type.FETCH_TOTAL_WALLET_SUCCESS:
      return {
        ...state,
        totalWalletBalance: action.payload,
      };
    case type.FETCH_REFUND_AMOUNT_SUCCESS:
      return {
        ...state,
        refundAmount: action.payload,
        maxRefundAmount: action.payload,
      };
    case type.CHANGE_REFUND_AMOUNT:
      return {
        ...state,
        refundAmount:
          action.payload > state.maxRefundAmount ? state.maxRefundAmount : action.payload,
      };
    case type.FETCH_DELIVERY_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        currentDeliveryRouteInfo: action.payload.deliveryRoute,
        currentDeliveryRouteList: action.payload.deliveryRouteOrders,
      };
    default:
      return state;
  }
};

export default csReducer;
