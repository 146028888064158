import React from 'react';
import Async from '@atlaskit/select/AsyncSelect';
import sortBy from 'lodash/sortBy';

// loadOptions function should return a promise which resolves an array [{value: '', label: ''}]
export default function SearchAndSelect(props) {
  const { value, loadOptions, onChange } = props;

  const onSearchChange = async (searchTerm) => {
    const response = await loadOptions(searchTerm);
    const options = sortBy(response, 'label');
    return options;
  }
  //value should be an object {value: value, label: 'label'}
  return (
    <Async
      value={value}
      loadOptions={onSearchChange}
      onChange={onChange}
      defaultOptions
      isClearable={true}
    />
  )
}
