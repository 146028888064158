import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Drawer
} from '@material-ui/core';
import moment from 'moment';
import Edit from "@material-ui/icons/Create";
import PurchaseOrderItemTable from './PurchaseOrderItemTable';
import NavBar from '../../../components/NavBar';
import SaveBar from './SavePurchaseOrder';
import apiCall, {
  purchaseOrderByIdPath,
  getTermsConditions,
  fetchVendorCatalogByLocationId,
  downloadPOTemplateByParams,
  uploadPOcsvByParams,
  validateTotMargin,
  getTotMarginByVendorProductItemIds,
  downloadTotMarginCSV,
  getOrganizationLocationsById
} from '../../../api/NetworkHandler';
import TermsAndConditionsEditCreatePo from "../components/TermsAndConditionEditCreatePo";
import Toast from '../../../utils/Toast';
import VendorProductSelection from './VendorProductSelection';
import ProgressBar from '../../../components/States/ProgressBar';
import StockAnalytics from './StockAnalytics';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { saveAs } from 'file-saver/FileSaver';
import { Button as AntdButton, message, Modal } from 'antd';
import { DownloadOutlined, CloudUploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { debounce, isEmpty } from 'lodash';
import PoTotMarginModal from './PoTotMarginModal';
import { calculateMaxCostFromMarginPercent, fetchVendorLocationDataByLocationId } from './Utils';
import { getApplicationConfigs } from '../../../utils/getAppConfig';


const { info } = Modal;

const styles = {
  termConditionsContainer: {
    marginTop: '12px'
  },
  bootstrapRoot: {
    padding: 0
  },
  bootstrapInput: {
    borderRadius: 4,
    border: "1px solid #d3d3d3",
    fontSize: 15,
    padding: "10px 12px",
    width: "100%",
  },
  editIcon: {
    color: "#f50057",
    width: "18px",
    height: "18px",
    marginLeft: "3px",
    cursor: "pointer"
  },
  termsAndConditionDiv: {
    width: '100%'
  },
  drawerPaper: {
    width: '50%',
    maxWidth: '550px',
    padding: '24px'
  }
};

var versionDict = {
  "catalogRequestId": 0,
  "getPORequestId": 0,
  "vendorDetailsRequestId": 0,
  "billToRequestId": 0
};

class EditPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.inputCSVFileRef = [];
    this._currentButtonClicked = '';
    this.state = {
      otherCharges: 0,
      data: {},
      type: 'requester',
      openTermsAndConditions: false,
      termsAndConditions: "",
      openVendorProductSearchBox: false,
      poErrorMessage: '',
      isLoading: false,
      selectedProductItemId: null,
      dateText: '',
      hideSidePanel: false,
      emptyItemsText: '',
      emptyQuantityText: '',
      vendorCatalog: [],
      disableButton: false,
      vendorData: {},
      openTotMarginModal: false,
      totMarginData: {},
      shouldAllowToSavePO: false,
      modalButtonText: '',
      isPOSaveAllowed: false,
      disableMarginValidation: true,
      billToData: {},
      vendorLocationName: ''
    };
  }

  componentDidMount() {
    let today = new Date(), day = today.getDate(), month = today.getMonth() + 1, year = today.getFullYear();
    if (day < 10) { day = '0' + day }
    if (month < 10) { month = '0' + month }
    today = `${year}-${month}-${day}`;
    document.getElementById("date").setAttribute("min", today);
    document.getElementById("date").setAttribute("value", today);

    this.setMarginValidationState();
  };

  setMarginValidationState = async () => {
    const appConfig = await getApplicationConfigs();
    const { disableMarginValidation = true } = appConfig;
    this.setState({
      disableMarginValidation
    });
  };

  getBillToData = async (billToLocationId) => {
    try {
      versionDict.billToRequestId += 1;
      let prevRequestId = versionDict.billToRequestId;
      const response = await apiCall.sendRequest('get', getOrganizationLocationsById(billToLocationId));
      const { data = {} } = response;
      if (prevRequestId === versionDict.billToRequestId) {
        this.setState({
          billToData: data,
        });
      }
    } catch (err) {
      this.setState({
        billToData: {}
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data,
        otherCharges: nextProps.data.additionalCharges,
        extraCharge: nextProps.data.additionalCharges ? true : false,
      }, () => {
        const { data = {} } = nextProps;
        const { vendorLocationId, billToLocationId, warehouseId, purchaseOrderLineItems } = data;
        this.getVendorCatalogByLocationId(vendorLocationId);
        this.getTermsConditionsByLocationId(vendorLocationId);
        this.getBillToData(billToLocationId);
        this.getVendorLocationData(vendorLocationId);

        if (!this.state.disableMarginValidation && nextProps.data.status === 'NEW') {
          const vendorProductItemIds = purchaseOrderLineItems.map(el => el.vendorProductItemId);
          this.fetchTotMarginByVendorProductItemIds(vendorProductItemIds, warehouseId, data);
        }
      });
    }
  }

  getVendorLocationData = (locationId) => {
    fetchVendorLocationDataByLocationId(locationId, (res, err) => {
      if (res) {
        this.setState({
          vendorLocationName: res.name
        });
      }
      if (err) {
        this.setState({
          vendorLocationName: 'NA'
        });
      }
    })
  };

  getTermsConditionsByLocationId = async (vendorLocationId) => {
    try {
      const response = await apiCall.sendRequest('get', getTermsConditions(vendorLocationId));
      const { data: { termsAndConditions = '' } = {} } = response;
      this.setState({
        termsAndConditions,
      });
    } catch (err) {
      this.setState({
        termsAndConditions: ''
      });
    }
  };

  showUpdateInfo = () => {
    info({
      title: 'Update Info',
      icon: <ExclamationCircleOutlined />,
      content: 'We have upated cost price according to the margin percentage. Please save the changes',
      okText: 'Ok',
      onOk: () => { },
    });
  }

  setUpdatePoDataWithTotMargin = (poData, marginData) => {
    const { purchaseOrderLineItems = [] } = poData;
    const { disableMarginValidation = true } = this.state;

    const newPurchaseOrderLineItems = []
    purchaseOrderLineItems.forEach(el => {
      const marginItem = marginData.find(elm => elm.vendorProductItemId == el.vendorProductItemId);
      if (marginItem) {
        const maxCostPrice = disableMarginValidation ? el.sellingPrice : calculateMaxCostFromMarginPercent(el.unitPrice, el.tax, marginItem.marginType, marginItem.marginPercentage, el.sellingPrice);
        newPurchaseOrderLineItems.push({ ...el, marginType: marginItem.marginType, marginPercentage: marginItem.marginPercentage, sellingPrice: el.sellingPrice < maxCostPrice ? el.sellingPrice : maxCostPrice });
      } else {
        newPurchaseOrderLineItems.push({ ...el, marginType: '', marginPercentage: null })
      }
    });
    this.setState({
      data: {
        ...poData,
        purchaseOrderLineItems: newPurchaseOrderLineItems
      }
    });
    const results = purchaseOrderLineItems.filter(({ sellingPrice: originalSellingPrice }) => !newPurchaseOrderLineItems.some(({ sellingPrice: modifiedSellingPrice }) => originalSellingPrice == modifiedSellingPrice));
    console.log(results);
    if (results.length > 0) {
      this.showUpdateInfo();
    }
  };

  fetchTotMarginByVendorProductItemIds = async (vendorProductItemIds = [], warehouseId, poData) => {
    try {
      const ids = vendorProductItemIds.filter(el => el).join(",");
      const response = await apiCall.sendRequest('get', getTotMarginByVendorProductItemIds(ids, warehouseId));
      const { data: { data = [] } = {} } = response;
      this.setUpdatePoDataWithTotMargin(poData, data);
    } catch (err) {

    }
  };

  fetchPurchaseOrderDataById = async (poId) => {
    try {
      versionDict.getPORequestId += 1;
      let prevRequestId = versionDict.getPORequestId;
      const response = await apiCall.sendRequest('get', purchaseOrderByIdPath(poId));
      const { data = {} } = response;
      if (prevRequestId == versionDict.getPORequestId) {
        this.setState({
          data,
        });
      }
    } catch (err) {
      console.log(err.response);
    }
  }

  getVendorCatalogByLocationId = async (locationId) => {
    try {
      versionDict.catalogRequestId += 1;
      let prevRequestId = versionDict.catalogRequestId;
      const response = await apiCall.sendRequest('get', fetchVendorCatalogByLocationId(locationId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.catalogRequestId) {
        this.setState({
          vendorCatalog: data,
        });
      }
    } catch (err) {
      this.setState({
        vendorCatalog: []
      });
    }
  }

  validateItems = () => {
    const { data: { purchaseOrderLineItems: items } } = this.state;
    const isAllQuantityValid = items.every(item => item.quantity > 0);
    const isAllUnitPriceValid = items.every(item => item.unitPrice > 0);
    const isAllSellingPriceValid = items.every(item => item.purchaseItemType === 'PAID' ? item.sellingPrice > 0 : item.sellingPrice == 0);
    const isSellingPriceInvalid = items.some(el => el.purchaseItemType === 'PAID' ? parseFloat((el.sellingPrice * (1 + 0.01 * el.tax)).toFixed(2)) > parseFloat(el.unitPrice) : false);
    return { isAllQuantityValid, isAllUnitPriceValid, isAllSellingPriceValid, isSellingPriceInvalid };
  }

  validatePo = () => {
    let isPoValid = true;
    const { data: { purchaseOrderLineItems: items } } = this.state;
    if (items.length == 0) {
      this.toggleItemsTextState("Please add at least one item");
      return false;
    }
    const isAllQuantityValid = this.validateItems().isAllQuantityValid;
    const isAllUnitPriceValid = this.validateItems().isAllUnitPriceValid;
    const isAllSellingPriceValid = this.validateItems().isAllSellingPriceValid;
    const isSellingPriceInvalid = this.validateItems().isSellingPriceInvalid;

    if (!isAllQuantityValid) {
      this.toggleQuantityTextState("Please enter quantity for all items");
      return false;
    }

    if (!isAllUnitPriceValid) {
      this.toggleQuantityTextState("Please enter correct MRP for all items");
      return false;
    }

    if (!isAllSellingPriceValid || isSellingPriceInvalid) {
      this.toggleQuantityTextState("Please enter correct Cost Price for all items");
      return false;
    }

    this.toggleItemsTextState('');
    this.toggleQuantityTextState('');
    return isPoValid;
  }

  toggleItemsTextState = (state) => {
    this.setState({
      emptyItemsText: state,
    });
  }

  toggleQuantityTextState = (state) => {
    this.setState({
      emptyQuantityText: state,
    });
  }

  validateTotMarginData = (marginData, buttonClicked) => {
    const { should = [], missing = [], must = [] } = marginData;
    if (buttonClicked === 'NEW') {
      if (should.length == 0 && missing.length == 0 && must.length == 0) {
        this.onSave(buttonClicked);
        return;
      }
      this.setState({
        modalButtonText: 'Save',
        isPOSaveAllowed: true,
      }, () => this.handleOpenTotMarginModal());
    } else {
      if (should.length > 0 && missing.length == 0 && must.length == 0) {
        this.setState({
          modalButtonText: 'Submit & Exit',
          isPOSaveAllowed: true
        }, () => this.handleOpenTotMarginModal())
      }
      else if (should.length == 0 && missing.length == 0 && must.length == 0) {
        this.onSave(buttonClicked);
      }
      else {
        this.setState({
          modalButtonText: 'Ok',
          isPOSaveAllowed: false
        }, () => this.handleOpenTotMarginModal())
      }
    }
  };

  validateTotMarginForPOLineItems = async (status) => {
    const { data } = this.state;
    try {
      const response = await apiCall.sendRequest('post', validateTotMargin, data);
      const { data: { data: totMarginData = {} } = {} } = response;
      this.setState({
        totMarginData,
      });

      this.validateTotMarginData(totMarginData, status);
    } catch (err) {
      console.error(err.response);
      this.setState({
        totMarginData: {}
      });
      return false;
    }
  };

  validatePOLineItemBeforeSave = debounce(async (status) => {
    this._currentButtonClicked = status;
    const { disableMarginValidation } = this.state;
    if (!this.validatePo()) {
      return;
    }
    if (disableMarginValidation) {
      this.onSave(status);
    } else {
      this.validateTotMarginForPOLineItems(status);
    }
  }, 500);

  onSave = async (status) => {
    this.setState({
      isLoading: true
    });
    const { history } = this.props;
    const { location: { state = '' } = {} } = history;
    const { data } = this.state;
    data.status = this._currentButtonClicked;
    try {
      await apiCall.sendRequest('put', purchaseOrderByIdPath(data.id), data);
      this.setState({
        isLoading: false,
        openTotMarginModal: false,
      });
      if (state === 'csv-uploaded') {
        history.go(-2);
      } else {
        if (this._currentButtonClicked === 'ORDERED') {
          history.goBack()
        } else {
          this.fetchPurchaseOrderDataById(data.id);
        }
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        openTotMarginModal: false,
      });
    }
  };

  handleSavePO = () => {
    const { isPOSaveAllowed } = this.state;
    if (isPOSaveAllowed) {
      this.onSave(this._currentButtonClicked);
    } else {
      this.handleCloseTotMarginModal();
      return;
    }
  };

  onAddItem = () => {
    let { data: { purchaseOrderLineItems: items = [] } } = this.state;
    items = [
      ...items,
      {
        productName: '',
        vendorProductItemId: '',
        sku: null,
        packaging: '',
        unitPrice: 0,
        category: '',
        unitMeasure: 0,
        tax: 0,
        discount: 0,
        quantity: 0,
        comment: 'NA',
        lineItemTotalPrice: 0,
      },
    ];
    this.onChangeItem(items);
  }


  onChangeItem = (items) => {
    const { data = {} } = this.state;
    this.setState({
      data: {
        ...data,
        purchaseOrderLineItems: items,
      },
    });
  }

  handleFormChanges = (event) => {
    const { data = [] } = this.state;
    var today = moment().format("YYYY-MM-DD");
    const { name } = event.target;
    let { value } = event.target;
    if (name === 'billTo') {
      const { locationId } = event.currentTarget.dataset;
      data.billToLocationId = locationId;
    }
    if (name == 'shipTo') {
      const { warehouseId } = event.currentTarget.dataset;
      data.warehouseId = warehouseId;
    }
    if (name === 'receivableDate' && value < today) {
      this.setState({ dateText: 'Past dates are not allowed' })
      return;
    } else {
      this.setState({ dateText: '' })
    }
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  }

  addExtraCharge = (event) => {
    if (event.target.value === 1) {
      this.setState({
        extraCharge: true,
      });
    } else {
      const { data } = this.state;
      this.setState({
        extraCharge: false,
        otherCharges: 0,
        data: {
          ...data,
          additionalCharges: 0,
        }
      });
    }
  }

  removeItem = (i) => {
    const { data: { purchaseOrderLineItems: items = [] } } = this.state;
    const newItems = items.filter(item => item.productItemId !== i.productItemId);
    this.onChangeItem(newItems);
  }

  addOtherCharges = (event) => {
    const { data } = this.state;
    let { value } = event.target;
    if (isNaN(value)) {
      value = data.additionalCharges;
    }
    this.setState({
      otherCharges: value,
      data: {
        ...data,
        additionalCharges: value,
      }
    });
  }

  formatAddressForPost = (data) => {
    const addressLine = (data.house && data.floor && data.block ? `#${data.house}, Floor: ${data.floor}, ${data.block},` : '') + (data.building && data.street ? `${data.building}, ${data.street},` : '') + (data.subLocality && data.locality && data.landmark ? `${data.subLocality}, ${data.locality}, ${data.landmark},` : '') + (data.city && data.pincode && data.state && data.country ? `${data.city}-${data.pincode}, ${data.state}, ${data.country}` : '');
    return addressLine;
  }

  formatAddress = (data) => {
    const addressLine1 = data.house && data.floor && data.block ? `#${data.house}, Floor: ${data.floor}, ${data.block},` : '';
    const addressLine2 = data.building && data.street ? `${data.building}, ${data.street},` : '';
    const addressLine3 = data.subLocality && data.locality && data.landmark ? `${data.subLocality}, ${data.locality}, ${data.landmark},` : '';
    const addressLine4 = data.city && data.pincode && data.state && data.country ? `${data.city}-${data.pincode}, ${data.state}, ${data.country}` : '';
    return (
      <div>
        {addressLine1}<br />{addressLine2}<br />{addressLine3}<br />{addressLine4}
      </div>
    );
  }

  openVendorProductSearch = () => {
    this.setState({
      openVendorProductSearchBox: true,
    });
  }

  handleVendorProductClose = () => {
    this.setState({
      openVendorProductSearchBox: false,
    });
  }

  openTermsAndConditionsModel = () => {
    const { openTermsAndConditions } = this.state;
    if (this.state.data.vendorLocationId) {
      this.setState({ openTermsAndConditions: !openTermsAndConditions });
    }
    else {
      Toast.error('Please select vendor')
    }
  };

  updateTermsAndConditions = (value) => {
    this.setState({ termsAndConditions: value })
    this.openTermsAndConditionsModel()
  }

  onPOItemClick = (productItem = {}) => {
    const { vendorProductItemId = null, productName = '' } = productItem;
    this.setState({
      selectedProductItemId: vendorProductItemId,
      selectedProductName: productName
    })
  }

  getAnalyticsComponent = () => {
    const { selectedProductItemId, selectedProductName, data = {} } = this.state;
    const { warehouseId } = data;
    return (
      <StockAnalytics
        vendorProductItemId={selectedProductItemId}
        warehouseId={warehouseId}
        productName={selectedProductName}
      />
    )
  }

  onChangeData = (updatedData) => {
    this.setState({
      data: updatedData
    })
  }

  toggleSidePanel = () => {
    const { hideSidePanel } = this.state;
    this.setState({
      hideSidePanel: !hideSidePanel,
    });
  }

  toggleProgressBar = (state) => {
    this.setState({
      disableButton: state,
    });
  }

  handleDownloadCSV = async () => {
    const { data = {} } = this.state;
    const { warehouseId, vendorLocationId, companyName, billToLocationId, receivableDate, id, taxRateType } = data;
    if (!warehouseId || !vendorLocationId || !companyName || !billToLocationId || !receivableDate) {
      message.warn("Please enter vendor details !");
      return;
    }
    let filename = `PO-${id}${taxRateType ? `-${taxRateType}` : ''}.csv`;
    this.toggleProgressBar(true);

    try {
      const response = await apiCall.sendRequest('get', downloadPOTemplateByParams(warehouseId, vendorLocationId, companyName, billToLocationId, receivableDate, id, taxRateType || ''), null, { Accept: 'text/csv' });
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
      this.toggleProgressBar(false);
    } catch (err) {
      console.log(err);
      this.toggleProgressBar(false);
    }
  }

  handleUploadPOCSV = (event, id) => {
    let selectedFile = event.target.files[0];
    this.uploadPOcsv(selectedFile);
    this.inputCSVFileRef[id].value = '';
  }

  uploadPOcsv = async (selectedFile) => {
    const { data = {} } = this.state;
    const { warehouseId, vendorLocationId, billToLocationId, receivableDate, id, taxRateType } = data;
    this.toggleProgressBar(true);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      await apiCall.sendRequest('post', uploadPOcsvByParams(warehouseId, vendorLocationId, billToLocationId, receivableDate, id, taxRateType || ''), formData);
      this.toggleProgressBar(false);
      this.fetchPurchaseOrderDataById(id);
    } catch (e) {
      // error handling
      this.toggleProgressBar(false);
    }
  }

  handleOpenTotMarginModal = () => {
    this.setState({
      openTotMarginModal: true,
    });
  };

  handleCloseTotMarginModal = () => {
    this.setState({
      openTotMarginModal: false,
    });
  };

  getCollapseHeaderTitle = (errorType) => {
    switch (errorType) {
      case "should": return 'Warning';
      case "must": return 'Error';
      default: return 'Intervention By Procurement Head';
    }
  };

  handleDownloadMissingTotMargin = debounce(async () => {
    const { totMarginData, data } = this.state;
    const { missing = [] } = totMarginData;
    const missingIds = missing.map(el => el.id);
    const url = downloadTotMarginCSV(data.warehouseId);
    const filename = `TOT-Margin-${moment().format("DD-MM-YYYY_HH:mm")}.csv`;
    try {
      const response = await apiCall.sendRequest('post', url, missingIds, { Accept: 'text/csv' });
      const { data = {} } = response;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
    } catch (err) {
      console.log(err.response);
    }
  }, 500);

  getShipTo = (warehouseId) => {
    const { warehouses = [] } = this.props;
    if (warehouses.length > 0) {
      const warehouse = warehouses.find(el => el.id == warehouseId);
      if (warehouse) return warehouse.name;
      else return ''
    }
    else return '';
  };

  render() {
    const { classes, history } = this.props
    const {
      data = {},
      openTermsAndConditions,
      termsAndConditions,
      extraCharge,
      otherCharges,
      isLoading,
      dateText,
      hideSidePanel,
      emptyItemsText,
      emptyQuantityText,
      vendorCatalog,
      disableButton,
      openTotMarginModal,
      totMarginData,
      modalButtonText,
      disableMarginValidation,
      billToData,
      vendorLocationName
    } = this.state;

    const {
      id = '',
      vendorName = '',
      vendorAddress = '',
      shipTo,
      billTo,
      companyName,
      requester,
      status,
      purchaseOrderLineItems = [],
      receivableDate = moment().format('YYYY-MM-DD'),
      warehouseId,
      taxRateType = '',
    } = data;

    const today = moment().format('YYYY-MM-DD')
    const validateDate = moment(receivableDate).isBefore(today, 'day');
    const innerHeightPercent = 0.5 - ((window.innerHeight - 640) * 0.0005);
    const calculateZoom = (75 + ((window.innerHeight - 640) / 12.48)).toFixed(2);
    document.body.style.zoom = `${calculateZoom}%`;
    const isButtonDisable = status !== 'NEW';
    const { location: { state = '' } = {} } = history;

    const shipToLocationName = this.getShipTo(warehouseId);

    return (
      <div>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <SaveBar
          status={status}
          onSave={this.validatePOLineItemBeforeSave}
          header="Edit Purchase Order"
          parentComponent="purchase-order"
          isLoading={isLoading}
          poId={id}
          onChangeData={this.onChangeData}
          data={data}
          comingFrom={state}
          taxRateType={taxRateType || ''}
        />
        {/* PO Detail Form */}
        <div style={{ width: '95%', margin: '10px auto' }}>
          <div style={{
            height: hideSidePanel ? window.innerHeight * 0.06 : window.innerHeight * innerHeightPercent,
            border: '2px solid #AAB7B8',
            borderRadius: '20px',
            padding: !hideSidePanel && '10px'
          }}
          >
            {!hideSidePanel && <Grid container spacing={32}>
              <Grid item xs={12} md={9} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={16}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Purchase Order Number"
                      InputLabelProps={{ shrink: true }}
                      value={id}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Requestor"
                      value={requester}
                      fullWidth
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Vendor Location Name"
                      name="vendorLocationName"
                      value={vendorLocationName}
                      fullWidth
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Vendor Location</Typography>
                    <TextField
                      name="vendorAddress"
                      value={vendorAddress}
                      fullWidth
                      disabled
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Company Name</Typography>
                    <TextField
                      name="companyName"
                      value={companyName}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Bill To</Typography>
                    <TextField
                      name="billTo"
                      value={!isEmpty(billToData) ? billToData.name : ''}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Ship To</Typography>
                    <TextField
                      name="shipTo"
                      value={shipToLocationName}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Receivable Date</Typography>
                    <TextField
                      id="date"
                      fullWidth
                      type="date"
                      value={receivableDate}
                      disabled={validateDate}
                      name="receivableDate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handleFormChanges}
                    />
                    <small style={{ color: 'red' }}>{dateText}</small>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant="body2" color="secondary" >
                        Vendor Info
                      </Typography>
                      <Typography variant="body2" color="primary">
                        {vendorName}
                      </Typography>
                      <Typography variant="body1" color="inherit">
                        {vendorAddress}
                      </Typography>
                    </div>
                    <IconButton size="small" color="primary" style={{ backgroundColor: '#CCC' }}>
                      <KeyboardArrowUpIcon onClick={this.toggleSidePanel} />
                    </IconButton>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <Typography variant="body2" color="secondary">
                      Company Name: &nbsp;&nbsp;
                    </Typography>
                    <Typography variant="body2" color="primary">
                      {companyName}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" color="secondary" style={{ marginTop: '10px' }}>
                      Bill To
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      {billTo}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" color="secondary" style={{ marginTop: '10px' }}>
                      Ship To
                    </Typography>
                    <Typography variant="body1" color="inherit">
                      {shipTo}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>}
            {hideSidePanel && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ marginTop: '10px' }}><b>Purchase Order Number:&nbsp;&nbsp;</b>{id}</p>
              <p style={{ marginTop: '10px' }}><b>Requester:&nbsp;&nbsp;</b>{requester}</p>
              <p style={{ marginTop: '10px' }}><b>Vendor Name:&nbsp;&nbsp;</b>{vendorName}</p>
              <p style={{ marginTop: '10px' }}><b>Company Name:&nbsp;&nbsp;</b>{companyName}</p>
              <IconButton size="small" color="primary" style={{ backgroundColor: '#CCC' }}>
                <KeyboardArrowDownIcon onClick={this.toggleSidePanel} />
              </IconButton>
            </div>}
          </div>
          <Grid container spacing={32}>
            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12}>
                {/* {emptyQuantityText && <Typography color="primary">{emptyQuantityText}</Typography>} */}
                <PurchaseOrderItemTable
                  poId={id}
                  hideCol={[]}
                  items={purchaseOrderLineItems}
                  removeItem={this.removeItem}
                  onChange={this.onChangeItem}
                  poStatus={status}
                  emptyQuantityText={emptyQuantityText}
                // onRowClick={this.onPOItemClick}
                // expandRow
                // getExpandedComponent={this.getAnalyticsComponent}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'initial' }}>
                  <Button color="primary" variant="outlined" onClick={this.openVendorProductSearch} style={{ border: emptyItemsText && '5px solid red' }}>
                    Add Item
                  </Button>
                  <Typography color="default" style={{ marginTop: '10px', marginLeft: '20px', width: '180px', marginRight: '20px' }}>{emptyItemsText}</Typography>
                  <AntdButton icon={<DownloadOutlined />} size="large" onClick={this.handleDownloadCSV} disabled={isButtonDisable}>
                    Download
                  </AntdButton>
                  <div style={{ width: '20px' }}></div>
                  <div>
                    <input ref={ref => this.inputCSVFileRef[id] = ref} type="file" accept=".csv" style={{ display: 'none' }} onChange={(e) => this.handleUploadPOCSV(e, id)} />
                    <AntdButton type="primary" icon={<CloudUploadOutlined />} size="large" onClick={() => this.inputCSVFileRef[id].click()} disabled={disableButton || isButtonDisable}>
                      Upload{' '}
                    </AntdButton>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}>
                  <Typography variant="body1" color="secondary">
                    Extra charges/ discounts
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}>
                  {extraCharge ? <TextField value={otherCharges} onChange={this.addOtherCharges} style={{ marginLeft: '10px', width: '50px' }} /> : <TextField value={0} style={{ marginLeft: '10px', width: '50px' }} disabled />}
                  <Select
                    value={extraCharge ? 1 : 'none'}
                    onChange={this.addExtraCharge}
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Other Charges</MenuItem>
                  </Select>
                </div>
              </Grid>
              <div style={{ height: '10px' }}></div>
              <Grid item xs={12} md={12} style={{ borderTop: '1px solid' }}>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1, marginTop: '10px' }}>
                  <Typography variant="title" color="primary" style={{ marginLeft: '20px' }}>
                    {data ? (purchaseOrderLineItems.reduce((prev, next) => prev + (next.quantity * next.sellingPrice * (1 + 0.01 * next.tax)), 0) + (data.additionalCharges ? parseFloat(data.additionalCharges) : 0)).toFixed(2) : 0}
                  </Typography>{/* <Button color="primary" variant="outlined" onClick={this.openVendorProductSearch} style={{ border: emptyItemsText && '5px solid red' }}>
                    + Add Item
                  </Button> */}
                  <Typography variant="body2" color="inherit">
                    Total Order Amount
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                <div style={{ flex: '1' }}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="body1" color="default">
                      Vendor Terms & Conditions
                    </Typography>
                    <Edit
                      style={styles.editIcon}
                      onClick={this.openTermsAndConditionsModel}
                    />
                  </div>
                  <div className={classes.termConditionsContainer} onClick={this.openTermsAndConditionsModel}>
                    <TextField
                      className={classes.termsAndConditionDiv}
                      multiline={true}
                      rows={0}
                      rowsMax={28}
                      value={termsAndConditions}
                      disabled
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.bootstrapRoot,
                          input: classes.bootstrapInput
                        }
                      }}
                    />
                  </div>

                  <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    anchor="right"
                    open={openTermsAndConditions}
                    onClose={this.openTermsAndConditionsModel}
                  >
                    <TermsAndConditionsEditCreatePo
                      open={openTermsAndConditions}
                      value={termsAndConditions}
                      onClose={this.openTermsAndConditionsModel}
                      vendorlocationId={data.vendorLocationId}
                      updateTermsAndConditions={this.updateTermsAndConditions}
                    />
                  </Drawer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <VendorProductSelection
          open={this.state.openVendorProductSearchBox}
          handleClose={this.handleVendorProductClose}
          poId={id}
          items={purchaseOrderLineItems}
          removeItem={this.removeItem}
          onChange={this.onChangeItem}
          vendorCatalog={vendorCatalog}
          taxRateType={taxRateType || ''}
          warehouseId={warehouseId}
          disableMarginValidation={disableMarginValidation}
        />
        <PoTotMarginModal
          open={openTotMarginModal}
          onSavePO={this.handleSavePO}
          onCloseTotMarginModal={this.handleCloseTotMarginModal}
          modalButtonText={modalButtonText}
          data={totMarginData}
          getCollapseHeaderTitle={this.getCollapseHeaderTitle}
          onDownloadMissingItem={this.handleDownloadMissingTotMargin}
        />
      </div>
    );
  }
}

EditPurchaseOrder.propTypes = {
  classes: PropTypes.object
};


const mapStateToProps = state => ({
  warehouses: state.information.warehouses,
});

export default connect(mapStateToProps, null)(withStyles(styles)(withRouter(EditPurchaseOrder)));
