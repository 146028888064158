const colors = {
  primary: '#f50057',
  lightPrimary: '#cc6388',
  lightGrey: '#f9f9f9',
  lightGrey1: '#EEEEEE',
  white: '#FFFFFF',
  grey: {
    100: '#EEEEEE',
    200: '#DDDDDD',
    300: '#CCCCCC',
    400: '#888888',
  },
  yellow: {
    100: '#ffdb29',
  },
  blue: '#000080',
  green: '#008000',
  red: '#FF0000',
};

export default{
  ...colors,
};

