import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Paper } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons/';
import './styles.scss';

class PerformanceBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="performanceBlock block">
        <h2>Stock Performance</h2>
        <Paper className="performanceContentWrap">
          <div className="performanceHeader">
            <AppBar position="static">
              <div className="left">
                <Tabs>
                  <Tab label="Today" />
                  <Tab label="Week" />
                  <Tab label="Month" />
                  <Tab label="Custom" />
                </Tabs>
              </div>
              {/* <div className="right">
                  <FormControl>
                    <InputLabel htmlFor="compare">Compared To</InputLabel>
                    <Select id="compare">
                      <MenuItem>Yesterday</MenuItem>
                      <MenuItem>Day Before</MenuItem>
                      <MenuItem>A month Before</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
            </AppBar>
          </div>
          <div className="performanceContent">
            <div className="performanceItem">
              <div className="chartTitle">Stocks Total</div>
              <div className="chartTotal">
                456
                <div className="indicator down">
                  <ArrowDownward className="indicatorIcon down" />
                  14%
                </div>
              </div>
              <div className="chartCompare">
                <span>386</span>
                <span className="chart-compare-label" translate="">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
            <div className="performanceItem">
              <div className="chartTitle">Stock Available</div>
              <div className="chartTotal">
                456
                <div className="indicator down">
                  <ArrowDownward className="indicatorIcon down" />
                  14%
                </div>
              </div>
              <div className="chartCompare">
                <span>386</span>
                <span className="chart-compare-label" translate="">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
            <div className="performanceItem">
              <div className="chartTitle">Stock Non-Available</div>
              <div className="chartTotal">
                46
                <div className="indicator up">
                  <ArrowUpward className="indicatorIcon up" />
                  14%
                </div>
              </div>
              <div className="chartCompare">
                <span>76</span>
                <span className="chart-compare-label">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
            <div className="performanceItem">
              <div className="chartTitle">Stock in PO</div>
              <div className="chartTotal">
                456
                <div className="indicator down">
                  <ArrowDownward className="indicatorIcon down" />
                  14%
                </div>
              </div>
              <div className="chartCompare">
                <span>386</span>
                <span className="chart-compare-label">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
            <div className="performanceItem">
              <div className="chartTitle">Stocks Expiring</div>
              <div className="chartTotal">
                456
                <div className="indicator up">
                  <ArrowUpward className="indicatorIcon up" />
                  12%
                </div>
              </div>
              <div className="chartCompare">
                <span>386</span>
                <span className="chart-compare-label">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
            <div className="performanceItem">
              <div className="chartTitle">Stocks Required</div>
              <div className="chartTotal">
                456
                <div className="indicator down">
                  <ArrowDownward className="indicatorIcon down" />
                  14%
                </div>
              </div>
              <div className="chartCompare">
                <span>386</span>
                <span className="chart-compare-label">
                  <span>Yesterday</span>
                </span>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

PerformanceBlock.propTypes = {};

export default PerformanceBlock;
