import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button'
import { Grid, Row, Col } from 'react-flexbox-grid';
import apiCall from '../../../api/NetworkHandler';
import EditForm from '../components/EditCatalogForm';
import CatalogProductList from '../components/CatalogProductList';
import ProgressBar from '../../../components/States/ProgressBar';
import ArrorBack from '@material-ui/icons/ArrowBack';
import sortBy from 'lodash/sortBy';
import BASE_URL from '../../../api/config';

class EditCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      vendorCatalog: {},
      isLoading: false,
      query: '',
      vendorOptions: [],
      vendorId: null,
    }
  }

  componentDidMount() {
    this.fetchVendorDetails();
    this.fetchVendors();
  }

  fetchVendorDetails = async () => {
    const { match: { params } } = this.props;
    const response = await apiCall.sendRequest('get', `/api/v1/vendor-catalogs/${params.id}`);
    this.setState({
      vendorCatalog: response.data,
    })
  }

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  }

  getVendorOptions = (value) => {
    this.setState({ query: value }, () => { this.fetchVendors() })
  }

  fetchVendors = () => {
    const { query } = this.state;
    apiCall.sendRequest('get', `api/v1/vendors/search/findVendors?query=${query}`)
      .then((response) => {
        let vendorOptions = response.data._embedded.vendors.map(vendor => {
          return {
            ...vendor,
            label: vendor.name,
            value: vendor.id,
          }
        })
        vendorOptions = sortBy(vendorOptions, ['name']);
        this.setState({
          vendorOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  selectVendor = (vendor) => {
    if (!vendor) {
      vendor = {};
    }

    this.setState(prevState => {
      const vendorCatalog = prevState.vendorCatalog;
      vendorCatalog.vendor = `${BASE_URL.BASE_URL}/api/v1/vendors/${vendor.id}`;
      vendorCatalog.vendorCatalogVendorID = vendor.id;
      return {
        vendorCatalog,
      }
    });
  }

  handleInputChange = property => (event) => {
    const value = event.target.value;
    this.setState(prevState => {
      const { vendorCatalog } = prevState;
      vendorCatalog[property] = value;
      return {
        vendorCatalog
      }
    })
  }

  validateForm = () => {
    const { vendorCatalog } = this.state;
    if(!vendorCatalog.vendorCatalogVendorID){
      return false;
    }
    return true;
  }

  handleSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { vendorCatalog } = this.state;
    const { match: { params }, history } = this.props;
    try {
      await apiCall.sendRequest('patch', `/api/v1/vendor-catalogs/${params.id}`, vendorCatalog);
      history.goBack();
    } catch (error) {

    }
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  toggleProgressBar = (isLoading) => {
    this.setState({
      isLoading
    })
  }

  render() {
    const {
      tab,
      vendorCatalog,
      isLoading,
      vendorOptions
    } = this.state;

    const { catalogStatuses, history } = this.props;
    return (
      <div>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <Grid fluid style={{ background: '#f0f0f0' }}>
          <Row middle='lg' between='lg'>
            <Col lg={5}>
              <Row lg={12} middle='lg'>
                <Col lg={10}>
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    fullWidth
                  >
                    <Tab label="Catalog Details" />
                    <Tab label="Products" />
                  </Tabs>
                </Col>
              </Row>
            </Col>
            <Col lg={3}>
              <Button variant='contained' color='secondary' onClick={this.handleCancel}>
                <ArrorBack /> Go Back To Vendor Catalogs
                </Button>
            </Col>
          </Row>
        </Grid>
        {tab == 0 &&
          <EditForm
            vendorCatalog={vendorCatalog}
            catalogStatuses={catalogStatuses}
            handleInputChange={this.handleInputChange}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            vendorOptions={vendorOptions}
            getVendorOptions={this.getVendorOptions}
            selectVendor={this.selectVendor}
          />}
        {tab == 1 &&
          <CatalogProductList
            toggleProgressBar={this.toggleProgressBar}
            {...this.props}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  catalogStatuses: state.Status.catalogStatuses,
});

export default withRouter(connect(mapStateToProps, null)(EditCatalog));
