import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import './styles.scss';

class ItemAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="itemAutoComplete">
        <ul>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
          <li>
            <Paper className="item">
              <div>Item Name</div>
              <div>Item Category</div>
            </Paper>
          </li>
        </ul>
      </div>
    );
  }
}

ItemAutoComplete.propTypes = {};

export default ItemAutoComplete;
