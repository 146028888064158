import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class ItemInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="itemInfo">
        <div className="image">
          <img
            src="https://rukminim1.flixcart.com/image/832/832/jh6l2fk0/milk/m/s/h/200-tetrapack-toned-nandini-original-imaf59b5yhmzs2rn.jpeg"
            alt=""
          />
        </div>
        <div className="itemDescription">
          <h1>Nandini Goodlife</h1>
          <div className="itemCategory">Dairy</div>
        </div>
      </div>
    );
  }
}

ItemInfo.propTypes = {};

export default ItemInfo;
