import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { ColMod } from '../../../components/lib';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

export default function CatalogSearch(props) {
  const { value, onChange, onClearSearch, addNewCatalog, vendorOptions, vendorFilterId, onFilterChange } = props;

  return (
    <Row between="lg" middle="lg">
      <ColMod lg={4}>
        <TextField
          id="input-with-icon-textfield"
          color="secondary"
          value={value}
          label="Quick Search"
          onChange={onChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </ColMod>
      <ColMod lg={2}>
        <Typography variant="caption"> Vendors </Typography>
        <Select 
          fullWidth
          value={vendorFilterId || 'all'}
          onChange={onFilterChange}
        >
          <MenuItem value='all'>All</MenuItem>
          {vendorOptions.map((vendorOption, index) => (
            <MenuItem key={index} value={vendorOption.value}>{vendorOption.label}</MenuItem>
          ))}
        </Select>
      </ColMod>
      <ColMod lg={3}>
        <Button variant="outlined" color="secondary" onClick={onClearSearch}>
          Clear
        </Button>
      </ColMod>
      <ColMod lg={3}>
        <Button color="secondary" variant="contained" onClick={addNewCatalog}>
          Create New Catalog
        </Button>
      </ColMod>
    </Row>
  )
}

CatalogSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
}

CatalogSearch.defaultProps = {
  value: '',
  onChange: () => { },
  onClearSearch: () => { },
}
