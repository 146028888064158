import React, { Component } from 'react';
import {
  Button,
  Typography,
  Grid,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  TablePagination,
  ExpansionPanelDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
} from '@material-ui/core/';
import { Row } from 'react-flexbox-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ColMod } from '../../../components/lib';
import apiCall, {
  getTransactionHistory,
  transactionHistoryPath,
  refundProcessPath,
  refundAmmoutPath,
  initiatedTransactions,
  transactionHistoryDownloadPath,
  ordersAfterDatePath,
} from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import utils from '../../../utils/queryBuilder';
import Colors from '../../../utils/Colors';
import TableHeader from '../../Commons/components/TableHeader';

const canView = utils.isCurrentUserAuthorizedToView;

const COMPONENT_TITLE = 'Transaction History';

const columnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Order ID',
  },
  {
    id: 'gateway',
    numeric: false,
    disablePadding: true,
    label: 'PAYMENT MODE',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Transaction Date',
  },
  {
    id: 'transactionId',
    numeric: false,
    disablePadding: true,
    label: 'Transaction Id',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: true,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: true,
    label: 'Additional Notes',
  },

];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F2F2F2',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
    padding: '15px 0px',
  },
  actionContainer: {
    display: 'flex',
    padding: 10,
    flexDirection: 'row',
    background: '#F2F2F2',
  },
  actionCard: {
    padding: 10,
    flex: 1,
    background: Colors.white,
  },
  cardTitle: {
    fontSize: '1.17em',
    fontWeight: 500,
  },
  customerId: { fontSize: '18px', fontWeight: 300, color: '#999' },
};

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { customerId } = params;
    this.state = {
      transactionsHistory: [],
      walletBalance: 0,
      customerId,
      refundAmount: 0,
      maxRefundAmount: 0,
      initiatedTxn: [],
      totalInitiatedTxn: 0,
      initPage: 0,
      fromDate: moment().subtract('days', 15).format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      formatType: 'csv',
      refundTxns: false,
      lastOrders: [],
      netRefundDate: moment().add(4, 'days').format('ll'),
    };
    this.refundClicked = false;
  }

  componentDidMount = () => {
    const { customerId } = this.state;
    apiCall
      .sendRequest('get', getTransactionHistory(customerId), null)
      .then((response) => {
        const { data = {} } = response;
        const { data: transactionsHistory = [] } = data;
        this.setState({ transactionsHistory });
      });
    apiCall
      .sendRequest('get', transactionHistoryPath(customerId), null)
      .then((response) => {
        const { data: { data } } = response;
        this.setState({ walletBalance: data });
      });
    this.fetchLastOrders();
    this.getRefundAmount();
    this.getInitiatedTransactions();
  }

  getRefundAmount = async () => {
    const { customerId } = this.state;
    const url = refundAmmoutPath(customerId);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = 0 } } = response;
      this.setState({
        refundAmount: data,
        maxRefundAmount: data,
      });
    } catch (e) {
      console.log('ERROR : ', e);
    }
  }

  getInitiatedTransactions = async (page) => {
    const { customerId } = this.state;
    const url = initiatedTransactions(customerId, page);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = {} } } = response;
      const { totalElements = [], paymentTransaction = [], refundTxns } = data;
      this.setState({
        initiatedTxn: paymentTransaction,
        totalInitiatedTxn: totalElements,
        refundTxns,
      });
    } catch (e) {
      console.log('ERROR : ', e);
    }
  }

  fetchLastOrders = async () => {
    const { customerId } = this.state;
    try {
      const date3DaysBefore = moment().subtract(3, 'days').format('YYYY-MM-DD');
      const response = await apiCall.sendRequest('get', ordersAfterDatePath(customerId, date3DaysBefore));
      const { data = [] } = response;
      let { netRefundDate } = this.state;
      if (data.length > 0) {
        const [lastOrder] = data.reverse();
        netRefundDate = moment(lastOrder.deliveryDate).add(4, 'days').format('ll');
      }
      this.setState({
        lastOrders: data,
        netRefundDate,
      });
    } catch (e) {
      // error handle
    }
  };

  handleRefundProcess = async () => {
    const { customerId: customerID, refundAmount } = this.state;
    if (!this.refundClicked) {
      this.refundClicked = true;
      const url = refundProcessPath(customerID);
      try {
        const response = await apiCall.sendRequest('post', url, { refundAmount, customerID });
        if (response && response.data) {
          window.location.reload();
        }
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  handleDownload = () => {
    const {
      fromDate,
      toDate,
      customerId,
    } = this.state;
    const url = transactionHistoryDownloadPath(customerId, fromDate, toDate);
    apiCall.downloadFile(url, `Transaction_History_${customerId}.csv`);
  }

  isValidDateRange = (value, key) => {
    const { fromDate, toDate } = this.state;
    const isEmpty = value === '';
    const isNotFutureDate = moment().isSameOrAfter(moment(value));
    const endDateValidation = (key === 'toDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate))));
    const fromDateValidation = (key === 'fromDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate))));
    return isEmpty || (isNotFutureDate && (endDateValidation || fromDateValidation));
  }

  handleChange = (value, key) => {
    const isNotDateField = (key !== 'toDate') && (key !== 'fromDate');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      this.setState({
        [key]: value,
      });
    }
  }

  handleRefundAmount = (value) => {
    const { maxRefundAmount } = this.state;
    const refund = parseInt(value, 10) || 0;
    if (maxRefundAmount >= refund) {
      this.setState({
        refundAmount: refund,
      });
    }
  }

  handleChangePage = (e, page, pageType) => {
    this.setState({
      [pageType]: page,
    });
    if (pageType === 'initPage') {
      this.getInitiatedTransactions(page);
    }
  }

  renderInitiatedTransactions = () => {
    const { initiatedTxn = [], totalInitiatedTxn, initPage } = this.state;
    return (
      <div style={{ flex: 1 }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell>Payment Mode</TableCell>
              <TableCell>Transaction Id</TableCell>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              initiatedTxn.length > 0 && initiatedTxn.map(txn => (
                <TableRow>
                  <TableCell>{txn.id}</TableCell>
                  <TableCell style={{ color: '#f50057' }}>{txn.paymentGateway}</TableCell>
                  <TableCell>{txn.transactionId}</TableCell>
                  <TableCell>{txn.transactionType}</TableCell>
                  <TableCell>{txn.amount}</TableCell>
                  <TableCell style={{ color: '#f50057' }}>{txn.timeCreated}</TableCell>
                  <TableCell>{txn.status}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        {
          initiatedTxn.length > 0 &&
          <TablePagination
            component="div"
            count={totalInitiatedTxn}
            page={initPage}
            rowsPerPage={20}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={(e, page) => this.handleChangePage(e, page, 'initPage')}
          />
        }
      </div>
    );
  }

  renderCompletedTransactions = () => {
    const { transactionsHistory } = this.state;
    return (
      <Table
        aria-labelledby="tableTitle"
      >
        <TableHeader columns={columnData} />
        <TableBody>
          {transactionsHistory.map((eachRow) => {
            const {
              gateway,
              status,
              notes,
              amount,
              id: orderId,
              gatewayTransactionTime,
              transactionId,
            } = eachRow;
            return (
              <TableRow
                hover
                tabIndex={-1}
                lg={4}
                md={6}
                sm={6}
                xs={6}
              >
                <TableCell component="th" scope="row" padding="none">
                  <div>{orderId}</div>
                </TableCell>
                <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none" >
                  {gateway}
                </TableCell>
                <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none" >
                  {gatewayTransactionTime}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" >
                  {transactionId}
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <div>{amount}</div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <div>{status}</div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <div>{notes}</div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { onClose } = this.props;
    const {
      walletBalance,
      refundAmount = 0,
      maxRefundAmount,
      totalInitiatedTxn = 0,
      initiatedTxn = [],
      fromDate,
      toDate,
      formatType,
      customerId,
      refundTxns,
      lastOrders,
      netRefundDate,
    } = this.state;
    return (
      <div>
        <NavBar />
        <div style={styles.div}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">
              {COMPONENT_TITLE} <span style={styles.customerId}>(Customer Id : {customerId})</span>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', paddingLeft: 30, background: '#F2F2F2' }}>
          <div>
            <Typography variant="body2" color="secondary">
              <Button color="secondary" variant="outlined" onClick={() => { this.props.history.goBack(); }}>Back</Button>
            </Typography>
          </div>
          <div>
            <Typography variant="body2" color="secondary">
              <Button color="secondary" onClick={() => { window.location.reload(); }}>
                <RefreshIcon />
              </Button>
            </Typography>
          </div>
        </div>
        <div style={styles.actionContainer}>
          <Grid
            spacing={12}
            style={{ ...styles.actionCard, marginRight: 5 }}
          >
            <Row>
              <ColMod xs md lg={12}>
                <div style={styles.cardTitle}>Wallet Management</div>
              </ColMod>
            </Row>
            <Row>
              <ColMod xs md lg={12}>
                <Row>
                  <ColMod lg={6}>
                    <Typography variant="body2" align="left" color="inherit">
                      {' '}
                      {`Wallet Balance : ${walletBalance}`}{' '}
                    </Typography>
                  </ColMod>
                  {maxRefundAmount > 0 && !refundTxns &&
                    <ColMod lg={3}>
                      <TextField
                        label="Refundable Amount"
                        value={refundAmount}
                        onChange={e => this.handleRefundAmount(e.target.value)}
                        disabled={!canView(['ADMIN'])}
                      />
                    </ColMod>
                  }
                  {refundAmount > 0 && !refundTxns &&
                    <ColMod lg={3}>
                      {canView(['ADMIN']) &&
                        <Typography variant="body2" color="secondary">
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={refundAmount <= 0 || lastOrders.length > 0}
                            onClick={() => { this.handleRefundProcess(); }}>Refund</Button>
                        </Typography>
                      }
                      {lastOrders.length > 0 &&
                        <p style={{ fontSize: 10, color: Colors.primary }}>Customer is having orders in last 3 days. Next Refund on <b>{netRefundDate}</b>.</p>
                      }
                    </ColMod>
                  }
                </Row>
              </ColMod>
            </Row>
          </Grid>
          <Grid
            spacing={12}
            style={{ ...styles.actionCard, marginLeft: 5 }}
          >
            <Row>
              <ColMod xs md lg={12}>
                <div style={styles.cardTitle}>Transactions Statement Download</div>
              </ColMod>
            </Row>
            <Row style={{ alignItems: 'flex-end', width: '100%' }}>
              <ColMod md={4}>
                <TextField
                  id="date"
                  label="From Date"
                  type="date"
                  value={fromDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e.target.value, 'fromDate')}
                />
              </ColMod>
              <ColMod md={4}>
                <TextField
                  id="date"
                  label="To Date"
                  type="date"
                  value={toDate}
                  // min={fromDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e.target.value, 'toDate')}
                />
              </ColMod>
              <ColMod md={2}>
                <Select
                  style={{ marginTop: 16 }}
                  native
                  label="Document Format"
                  value={formatType}
                  onChange={e => this.handleChange(e.target.value, 'formatType')}
                >
                  <option value="csv">CSV</option>
                </Select>
              </ColMod>
              <ColMod md={2}>
                <Button
                  download
                  variant="contained"
                  color="secondary"
                  onClick={this.handleDownload}
                >
                  Download
                </Button>
              </ColMod>
            </Row>
          </Grid>
        </div>
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            {
              canView(['ADMIN']) && initiatedTxn.length > 0 &&
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <h3>Initiated Transactions ({totalInitiatedTxn})</h3>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {this.renderInitiatedTransactions()}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            }
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <h3>Completed Transactions</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {this.renderCompletedTransactions()}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
      </div>
    );
  }
}

TransactionHistory.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TransactionHistory;
