import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import SearchBar from './SearchBar';
import * as actions from '../../actions/csActions';
import NavBar from '../../components/NavBar';
import CustomerInfoCard from './CustomerInfoCard';
import DetailPanel from './DetailPanel';
import CustomerList from './CustomerList';
import InvoiceList from './InvoiceList';
import styles from './styles';

class MonthlyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchPage: true,
      detailHasComponent: false,
      paramType: null,
      paramId: null,
    };
  }

  componentDidMount() {
    this.updateComponentState();
  }

  componentDidUpdate() {
    const { type = null, id = null } = this.props.match.params;
    const { paramType, paramId, isSearchPage } = this.state;
    if (!type && !isSearchPage) {
      this.resetState();
    }
    if (type !== paramType || id !== paramId) {
      this.getData(type, id);
    }
  }

  getData = (type, id) => {
    const { getCustomerInfo, getCustomerInvoices } = this.props;
    this.setState({
      isSearchPage: false,
      paramId: id,
      paramType: type,
      detailHasComponent: false,
    });
    if (type === 'customer') {
      getCustomerInfo(id);
      getCustomerInvoices(id);
    }
  };

  showMoreInvoices = () => {
    const { currentInvoicePage, customerInfo, getCustomerInvoices } = this.props;
    const newPage = currentInvoicePage + 1;
    getCustomerInvoices(customerInfo.customerId, newPage);
  };

  resetState = () => {
    this.setState({
      isSearchPage: true,
      detailHasComponent: false,
      paramType: null,
      paramId: null,
    });
  };

  searchItemClicked = () => {
    this.unMountFromDetail();
    this.props.quickSearchItemSelect();
  };

  updateComponentState = () => {
    const { type = null, id = null } = this.props.match.params;
    if (type && id) {
      this.getData(type, id);
    }
  };

  unMountFromDetail = () => {
    this.setState({
      detailHasComponent: false,
    });
  };

  render() {
    const {
      history,
      q,
      csList,
      noCsList,
      customerId,
      customerInfo,
      customerInvoice,
      customerInfoFailed,
      inFullScreen,
      quickSearch,
      clearQuickSearch,
      currency,
      hasMoreInvoices,
    } = this.props;
    const {
      isSearchPage,
      detailHasComponent,
    } = this.state;

    return (
      <div style={styles.csAssitBox}>
        {!inFullScreen && <NavBar />}
        {!inFullScreen && (
          <SearchBar
            history={history}
            q={q}
            customerId={customerId}
            quickSearch={quickSearch}
            clearQuickSearch={clearQuickSearch}
            path="monthly-invoices"
          />
        )}
        {!isEmpty(q) && (
          <div style={styles.autoCompleteBox}>
            <CustomerList data={csList} query={q} searchItemClicked={this.searchItemClicked} path="monthly-invoices" />
          </div>
        )}
        {!isEmpty(q) && noCsList && (
          <div style={styles.emptyPage}>No customers found with the search term.</div>
        )}
        {isSearchPage && isEmpty(q) && (
          <div style={styles.emptyPage}>
            Please Search for Customer Mobile Number, Name, or Email to start.
          </div>
        )}
        {!isSearchPage && !customerInfoFailed && (
          <div style={{ ...styles.contentBox, display: !isEmpty(q) ? 'none' : 'block' }}>
            <CustomerInfoCard info={customerInfo} showExtras={false} />
            { !isEmpty(customerInvoice) && (
            <DetailPanel unMountFromDetail={this.unMountFromDetail} showCross={detailHasComponent}>
              <InvoiceList
                invoices={customerInvoice}
                customerId={customerInfo.customerId}
                currency={currency}
                hasMoreInvoices={hasMoreInvoices}
                showMoreInvoices={this.showMoreInvoices}
              />
            </DetailPanel>
            )}
          </div>
        )}
        {!isSearchPage && isEmpty(customerInvoice) && <div style={styles.emptyPage}>No Customer invoices found!</div>}
        {customerInfoFailed && <div style={styles.emptyPage}>No Customer records found!</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { csReducer, currency } = state;
  const {
    q,
    csList,
    noCsList,
    customerId,
    customerInfo,
    customerInfoFailed,
    customerInvoice,
    currentInvoicePage,
    hasMoreInvoices,
  } = csReducer;
  return {
    q,
    csList,
    noCsList,
    customerId,
    customerInfo,
    customerInfoFailed,
    customerInvoice,
    currency,
    currentInvoicePage,
    hasMoreInvoices,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    quickSearch,
    clearQuickSearch,
    quickSearchItemSelect,
    getCustomerInfo,
    getCustomerInvoices,
  } = actions;
  return {
    getCustomerInfo: bindActionCreators(getCustomerInfo, dispatch),
    getCustomerInvoices: bindActionCreators(getCustomerInvoices, dispatch),
    quickSearch: bindActionCreators(quickSearch, dispatch),
    clearQuickSearch: bindActionCreators(clearQuickSearch, dispatch),
    quickSearchItemSelect: bindActionCreators(quickSearchItemSelect, dispatch),
  };
};

MonthlyInvoices.propTypes = {
  q: PropTypes.string,
  csList: PropTypes.instanceOf(Array),
  noCsList: PropTypes.bool,
  customerId: PropTypes.string,
  customerInfo: PropTypes.instanceOf(Object),
  customerInvoice: PropTypes.instanceOf(Array),
  currentInvoicePage: PropTypes.number,
  hasMoreInvoices: PropTypes.bool,
  currency: PropTypes.string,
  customerInfoFailed: PropTypes.bool,
  getCustomerInfo: PropTypes.func,
  getCustomerInvoices: PropTypes.func,
  quickSearch: PropTypes.func,
  clearQuickSearch: PropTypes.func,
  quickSearchItemSelect: PropTypes.func,
  inFullScreen: PropTypes.bool,
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonthlyInvoices);
