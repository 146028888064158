import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import Lozenge from '@atlaskit/lozenge';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import moment from 'moment';
import ToolTip from '@material-ui/core/Tooltip';
import { Button } from "@material-ui/core";

const columnData = [
  {
    id: 'title', numeric: false, disablePadding: true, label: 'Widget Title',
  },
  {
    id: "order", numeric: false, disablePadding: true, label: 'Sort Sequence',
  },
  {
    id: 'updatedAt', numeric: false, disablePadding: true, label: 'Last Updated At',
  },
  {
    id: "widgetType", numeric: false, disablePadding: true, label: 'Layout Type',
  },
  {
    id: "widgetDataType", numeric: false, disablePadding: true, label: 'Widget Data Type',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  }
];

class AppWidgetList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      updatedAt: {},
      status: {},
    };
    const { widgets } = this.props;

    widgets.map((widget, index) => {
      customComponents['status'][index] = <Lozenge appearance={widget.active ? "success" : "removed"}>{widget.active ? "Active" : "Inactive"}</Lozenge>;
      customComponents['updatedAt'][index] = <ToolTip title={moment(widget.timeUpdated).format(PRODUCT_TIME_FORMAT)}><span>{moment(widget.timeUpdated).fromNow()}</span></ToolTip>;
    })
    return customComponents;
  }
  
  getActions = (el) => {
    return <Button size="small" color="primary" variant="outlined" onClick={() => { this.props.onEditWidget(el) }}> Edit </Button>
  }

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;
    onRowsPerPageChange(value);
  };

  render() {
    const {
      widgets,
      rowsPerPage,
      page,
      count,
      order,
      orderBy,
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        actions={this.getActions}
        data={widgets}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        order={order}
        orderBy={orderBy}
        customComponents={this.getCustomComponents()}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        handleChangePage={this.handleChangePage}
      />
    )
  }
}

AppWidgetList.propTypes = {
  widgets: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  onEditWidget: PropTypes.func,
}

AppWidgetList.defaultProps = {
  order: 'acs',
  orderBy: '',
}

export default AppWidgetList;
