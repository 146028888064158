import React, { Component } from 'react';
import moment from 'moment';
import { TextField, Button, Grid, TableCell, Table, TableRow } from '@material-ui/core';
import NavBar from '../../../components/NavBar';
import UploadFile from '../../Commons/components/UploadFile';
import API, { getAllUsers, markAttendance, userAttendance } from '../../../api/NetworkHandler';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin:'auto',
    marginTop: 60,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth:450,
  },
};

class AttendanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
      coords: {},
      selectedUser: {},
      attendanceStart: false,
      attendanceEndTime: null,
      attendanceStartTime: null
    };
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { coords } = position;
        const { latitude, longitude } = coords;
        this.setState({
          coords: {
            latitude,
            longitude,
          },
        });
        // alert(`location-->${latitude},${longitude}`);
      }, (err) => {
        alert(`errr-->${err.message}`);
      },
      { enableHighAccuracy: true, maximumAge: 10000 },
    );
  }

  getUserAttendance = async (mobile) => {
    try {
      const response = await API.sendRequest('get', userAttendance(mobile));
      const { data: { data } } = response;
      this.setState({
        attendanceStart: !!data,
        imageUrl: data && data.attendanceProof ? data.attendanceProof : '',
        attendanceStartTime: data && data.attendanceStartTime ? data.attendanceStartTime : null,
        attendanceEndTime: data && data.attendanceEndTime ? data.attendanceEndTime : null,
      });
    } catch (e) {
      // error
    }
  }

  handleImageUpload = (imageObj) => {
    const [imageUrl] = Object.values(imageObj);
    this.setState({
      imageUrl,
    });
  }

  handleImageDelete = () => {
    this.setState({
      imageUrl: null,
    });
  }

  findUserByMobile = async (mobile) => {
    try {
      const response = await API.sendRequest('get', getAllUsers(mobile, ''));
      const { data: { _embedded: embedded = {} } = {} } = response;
      const { users = [] } = embedded;
      const [selectedUser = {}] = users;
      this.setState({
        selectedUser,
      });
    } catch (e) {
      // error
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      selectedUser: {},
    });
    if (name === 'userMobile' && value.length > 9) {
      this.findUserByMobile(value);
      this.getUserAttendance(value);
    }
  }

  handleMarkAttendance = async () => {
    const {
      coords, userMobile, imageUrl, attendanceStart,
    } = this.state;
    const { latitude, longitude } = coords;
    const attendanceObj = {
      attendanceDate: moment().format('YYYY-MM-DD'),
      attendanceStart: !attendanceStart,
      attendanceProof: imageUrl,
      attendanceProofLatitude: latitude,
      attendanceProofLongitude: longitude,
      mobile: userMobile,
    };
    await API.sendRequest('post', markAttendance, attendanceObj);
  }

  render() {
    const {
      imageUrl, userMobile, selectedUser, attendanceStart,
      attendanceStartTime,
      attendanceEndTime
    } = this.state;
    const images = [];
    if (imageUrl && imageUrl !== '') {
      images.push({
        name: userMobile,
        url: imageUrl,
        editable: true,
      });
    }
    return (
            <div>
                <NavBar />
                <Grid container>
                  <Grid item xs={12}>
                    <div style={styles.container}>
                    <UploadFile
                        title="Take Picture"
                        files={images}
                        onUpload={this.handleImageUpload}
                        onDelete={this.handleImageDelete}
                        multiple={false}
                        accept="image/*"
                        capture="user"
                    />
                    <TextField
                      placeholder="Mobile No"
                      style={{ color: '#000000', marginTop: 30 }}
                      name="userMobile"
                      value={userMobile}
                      onChange={this.handleChange}
                    />
                    <br />
                    <div>
                    {selectedUser.name ? selectedUser.name : null}
                    </div>
                    {!attendanceEndTime ?
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.handleMarkAttendance}
                      style={{ marginTop: 30 }}>
                      {attendanceStart ? 'End' : 'Start'} Attendance
                    </Button>
                    :
                    <Table>
                      <TableRow>
                        <TableCell>START TIME</TableCell>
                        <TableCell>{attendanceStartTime}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>END TIME</TableCell>
                        <TableCell>{attendanceEndTime}</TableCell>
                      </TableRow>
                    </Table>
                    }
                    </div>
                  </Grid>
                </Grid>
            </div>
    );
  }
}

export default AttendanceContainer;
