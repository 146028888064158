import React from 'react';
import Colors from '../../../utils/Colors';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid, Row, Col } from 'react-flexbox-grid';

const styles = {
  mainContainer: {
    backgroundColor: Colors.grey[100],
  },
}

export default function SearchBar(props) {
  const { title, children } = props;
  return (
    <div style={styles.mainContainer}>
      <Grid fluid>
        <Row lg={12} middle="sm">
          <Col lg={2}>
            <Typography variant="title" color="inherit">
              {title}
            </Typography>
          </Col>
          <Col lg={10} style={{ width: '150px' }}>
            {children && React.cloneElement(children)}
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

SearchBar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};