import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  InputAdornment,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row } from 'react-flexbox-grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ColMod } from '../../../components/lib';
import BrandSelection from '../../../components/BrandSelection';
import CategorySelection from '../../../components/CategorySelection';
import Upload from "./Upload";
import BASE_URL from '../../../api/config';
import apiCall from '../../../api/NetworkHandler';
import { Button } from '@material-ui/core';
import GstSelection from '../../../components/GstSelection';
import TagMultiSelector from '../../../components/Tag/MultiSelector';
import SectionMessage from '@atlaskit/section-message';
import { stringFormatting, removeSpaceFromString } from '../../../utils/stringFormatting';

const STATUSES = [{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }];

const styles = {
  root:{
    width:'35px',
    height:'31px'
  },
  label:{
    marginRight:'50px',
    marginLeft: '-7px'
  }
};
const productFlag =[{value:'organic',label:'Organic'},{value:"gourmet",label:'Gourmet'},{value:'breakfast',label:'Breakfast'},{value:'care',label:'Care'},{value:'onOffer',label:'Offer'}]

class ProductForm extends Component {
  constructor(props) {
    super(props);
    const { product, catalogId } = this.props;
    this.state = {
      product,
      catalogId,
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      openBrandSelector: false,
      openCategorySelector: false,
      openGstSelector: false,
      errorMessage: "",
      error: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { product, catalogId } = nextProps;
    const { product: prevProduct } = prevState;
    return {
      product: {
        ...product,
        ...prevProduct,
      },
      catalogId,
    };
  }

  updateImageUrls = () => {
    const { urls: prevUrls = [], product } = this.state;
    const { stagingProductImages = [] } = product;
    const urls = prevUrls.map((url) => {
      const image = stagingProductImages.find(img => img.position === url.position);
      if (image && !url.imageUrl) return image;
      return {
        ...url,
        stagingProduct: product.id ? `${BASE_URL.BASE_URL}/api/v1/staging-products/${product.id}` : null,
      };
    });
    return urls;
  }

  handleInputChange = prop => (event) => {
    const { product } = this.state;
    const { value } = event.target;
    product[prop] = value;
    this.setState({ product });
  }

  handleInputCheckboxChange = name => event =>{
    const {product} = this.state
    product[name] = event.target.checked
    this.setState({ product })
  }

  openBrandSelector = () => {
    this.setState({
      openBrandSelector: true,
    });
  }

  openCategorySelector = () => {
    this.setState({
      openCategorySelector: true,
    });
  }

  openGstSelector = () => {
    this.setState({
      openGstSelector: true,
    });
  }

  handleBrandSelectorClose = () => {
    this.setState({
      openBrandSelector: false,
    });
  }

  handleCategorySelectorClose = () => {
    this.setState({
      openCategorySelector: false,
    });
  }

  handleGstSelectorClose = () => {
    this.setState({
      openGstSelector: false,
    });
  }

  selectBrand = (brand) => {
    let { product } = this.state;
    product = {
      ...product,
      brandId: brand.id,
      brandName: brand.name,
    };
    this.setState({
      product,
      openBrandSelector: false,
    });
  }

  selectCategory = (category) => {
    let { product } = this.state;
    product = {
      ...product,
      categoryId: category.id,
      categoryName: category.name,
      // category: category,
      zoneId: category.zoneId
    };
    this.setState({
      product,
      openCategorySelector: false,
    });
  }

  selectGst = (gstData) => {
    this.setState(prevState => {
      const product = prevState.product;
      product.gstRateId = gstData.id;
      product.hsnCode = gstData.hsnCode;
      const openGstSelector = false;
      return {
        product,
        openGstSelector
      }
    })
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    });
  }

  isPropertyValid = (property, value) => {
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  validateForm = () => {
    let { product } = this.state;
    let isFormValid = true;

    const validateProperties = ["name", "description", "brandId", "categoryId"];

    validateProperties.forEach((property) => {
      if(!this.isPropertyValid(property, product[property])) {
        isFormValid = false;
      }
    })

    return isFormValid;
  }

  saveProduct = async () => {
    if(!this.validateForm()){
      return;
    }
    const { product, catalogId } = this.state;
    const { onClose, onRefresh, onClear } = this.props;
    const { stagingProductAttributes = [], name, title } = product;
    const urls = this.updateImageUrls();
    const productPostData = { ...product };
    productPostData.name = stringFormatting(name);
    productPostData.title = stringFormatting(title);
    productPostData.brand = `${BASE_URL.BASE_URL}/api/v1/brands/${product.brandId}`;
    productPostData.category = `${BASE_URL.BASE_URL}/api/v1/categories/${product.categoryId}`;
    productPostData.stagingProductAttributes =
        stagingProductAttributes.filter(attr => attr.name || attr.value).map(attr => ({
          ...attr,
        }));
    productPostData.stagingProductImages = urls.filter((url) => { if (url.imageUrl) return true; });
    productPostData.productCatalog = `${BASE_URL.BASE_URL}/api/v1/product-catalogs/${catalogId}`;
    try {
      if (product.id) {
        await apiCall.sendRequest('patch', `/api/v1/staging-products/${productPostData.id}`, productPostData);
      } else {
        await apiCall.sendRequest('post', '/api/v1/staging-products', productPostData);
      }
      onClose();
      onRefresh();
      onClear();
      this.clearState();
    } catch (e) {
      // error handling
      const { data } = e.response;
      const { message } = data;
      if (message) {
        this.setState({ errorMessage: message });
      } else if (data && (typeof data == "string")) {
        this.setState({ errorMessage: data });
      }
    }
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  handleAttrChange=(event, i, field) => {
    const { product } = this.state;
    const { value } = event.target;
    const { stagingProductAttributes = [] } = product;
    const tempObj = stagingProductAttributes[i] || {};
    if (field === 'value') {
      tempObj.value = value;
    } else {
      tempObj.name = value;
    }
    stagingProductAttributes[i] = tempObj;
    this.setState({
      product: {
        ...product,
        stagingProductAttributes,
      },
    });
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  clearState = () => {
    const { onClear } = this.props;
    this.setState({
      product: {},
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      error: {},
      errorMessage: "",
    });
    onClear();
  }

  onTagSelect = (tags) => {
    let productTags = [];
    if (tags) {
      productTags = tags.map(tag => {
        return {
          tagId: tag.value,
          tag: tag.label,
        }
      })
    }

    this.setState((prevState) => {
      const { product } = prevState;

      return {
        selectedTags: tags,
        product: {
          ...product,
          newProductTags: productTags,
        }
      }
    })
  }

  getSelectedTags = () => {
    const { product = {} } = this.state;
    const { newProductTags = [] } = product;
    const selectedTags = newProductTags.map((tag) => {
      return {
        value: tag.tagId,
        label: tag.tag,
      }
    })
    return selectedTags;
  }

  render() {
    const { product = {}, errorMessage, error } = this.state;
    const {
      id,
      name,
      title,
      description,
      brandName,
      categoryName,
      stagingProductAttributes = [],
      active,
      identifier,
      hsnCode,
      isPublished = false,
      zoneId,
    } = product;
    const { open, onClose, classes, zones, taxType } = this.props;
    const urls = this.updateImageUrls();
    let attributes = [{ name: '', value: '' }];
    if (stagingProductAttributes.length > 0) attributes = stagingProductAttributes;
    return (
      <Dialog
        open={open}
        onClose={()=>{ onClose()}}
        maxWidth="70%"
        onBackdropClick={this.clearState}
      >
        <div style={{ width: '100%' }}>
          <Button
            style={{ float: 'right', height: '20px', width: '20px' }}
            onClick={() => { onClose()}}
            color="secondary"
            aria-label="close"
          >
            <CloseIcon />
          </Button>
        </div>
        <DialogTitle style={{ marginLeft: '1em' }}>
          <Typography variant="title" color="secondary">
            {id ? 'Edit' : 'New'} Product
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid>
            {errorMessage && <Row>
              <ColMod lg={12}>
                <SectionMessage appearance="error">
                  {errorMessage}
                </SectionMessage>
              </ColMod>
            </Row>}
            <Row>
              <ColMod custompadding="true" bumphigh="true">
                <Typography color="error" variant="caption">* required</Typography>
              </ColMod>
            </Row>
            {/* {errorMessage && <Row>
              <ColMod lg={12}>
                <SectionMessage appearance="error">{errorMessage}</SectionMessage>
              </ColMod>
            </Row>} */}
            <Row>
              <ColMod lg={6}>
                <TextField
                  label="Identifier"
                  value={identifier || ''}
                  fullWidth
                  disabled
                />
              </ColMod>
              <ColMod lg={6}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={this.handleInputChange('name')}
                  fullWidth
                  error={error.name}
                  required
                  disabled={isPublished}
                />
              </ColMod>
              <ColMod lg={taxType === 'gst' ? 6 : 12}>
                <TextField
                  value={title}
                  onChange={this.handleInputChange('title')}
                  label="Display Name"
                  error={error.title}
                  required
                  fullWidth
                />
              </ColMod>
              {taxType === 'gst' &&
              <ColMod lg={6}>
                <TextField
                  value={hsnCode || ''}
                  name="gstData"
                  fullWidth
                  onChange={() => { }}
                  label="HSN Code"
                  onClick={this.openGstSelector}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </ColMod>}
            </Row>
            <Row>
              <ColMod lg={12}>
                <TextField
                  value={description}
                  onChange={this.handleInputChange('description')}
                  label="Description"
                  fullWidth
                  error={error.description}
                  required
                />
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={6}>
                <TextField
                  margin="dense"
                  // id="input-with-icon-textfield"
                  value={brandName}
                  name="Brand"
                  fullWidth
                  onChange={() => { }}
                  onClick={this.openBrandSelector}
                  helperText="Brand *"
                  error={error.brandId}
                  InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                />
              </ColMod>
              <ColMod lg={6}>
                <TextField
                  margin="dense"
                  // id="input-with-icon-textfield"
                  value={categoryName}
                  name="Category"
                  fullWidth
                  onChange={() => { }}
                  onClick={this.openCategorySelector}
                  helperText="Category *"
                  error={error.categoryId}
                  InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search color="secondary" />
                        </InputAdornment>
                      ),
                    }}
                />
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={6}>
                <InputLabel style={{ fontSize: '1em' }}>Tags</InputLabel>
                <TagMultiSelector
                  selected={this.getSelectedTags()}
                  onSelect={this.onTagSelect}
                />
              </ColMod>
              <ColMod lg={6}>
                <InputLabel style={{ fontSize: '1em' }} htmlFor="category-type">Status</InputLabel>
                <Select
                  value={active}
                  onChange={this.handleInputChange('active')}
                  inputProps={{
                        name: 'new_status',
                        id: 'category-type',
                    }}
                  fullWidth
                >
                  { STATUSES.map((x, i) => (<MenuItem key={i} value={x.id}>{x.name}</MenuItem>)) }
                </Select>
              </ColMod>
              <ColMod lg={6}>
                <FormControl variant="filled">
                  <InputLabel style={{ fontSize: '1.1em' }} htmlFor="select-destination">Product Zone</InputLabel>
                  <Select
                    style={{ width: '250px' }}
                    value={zoneId || ''}
                    onChange={this.handleInputChange('zoneId')}
                    inputProps={{
                      name: 'zoneId',
                      id: 'select-destination',
                    }}
                    >
                    {zones.map((zone, index) => (<MenuItem key={index} value={zone.id}> {zone.name} </MenuItem>))}
                  </Select>
                </FormControl>
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={12}>
                {
                  productFlag.map((item,key) => (
                    <FormControlLabel
                      key={key}
                      className = {classes.label}
                      control={
                        <Checkbox
                          checked={product[item.value]}
                          onChange={this.handleInputCheckboxChange(item.value)}
                          value={item.value}
                          classes={{
                            root: classes.root,
                          }}
                        />
                      }
                      label={item.label}
                    />
                  ))
                }
              </ColMod>
            </Row>
            <Row>
              <ColMod lg={6}>
                <Typography style={{ marginTop: '0.4em', marginLeft: '0.2em' }} color="secondary" variant="body2">Product Attributes</Typography>
                {
                    attributes.map((x, i) => (
                      <div style={{ paddingTop: '0.7em' }}>
                        <TextField
                          value={attributes[i].name}
                          onFocus={() => { this.setState({ tracer: i, valueSelect: false }); }}
                          onChange={(event) => { this.handleAttrChange(event, i, 'name'); }}
                          label="Name"
                        />
                        <TextField
                          style={{ marginLeft: '0.4em' }}
                          value={attributes[i].value}
                          onFocus={() => { this.setState({ tracer: i, valueSelect: true }); }}
                          onChange={(event) => { this.handleAttrChange(event, i, 'value'); }}
                          label="Value"
                        />
                        {(
                            attributes.length === (i + 1) ?
                              <AddIcon
                                color="secondary"
                                style={{ fontSize: '1.6em' }}
                                onClick={() => {
                                    this.setState({
                                        product: {
                                            ...product,
                                            stagingProductAttributes: [
                                                ...attributes,
                                                { name: '', value: '' },
                                            ],
                                        },
                                    });
                                }}
                              />
                         :
                              <CloseIcon
                                color="secondary"
                                style={{ fontSize: '1.6em' }}
                                onClick={() => {
                                    const d = attributes.slice(0); d.splice(i, 1);
                                    this.setState({
                                        product: {
                                            ...product,
                                            stagingProductAttributes: attributes,
                                        },
                                    });
                                }}
                              />
                        )}
                      </div>))

                }
                {}
              </ColMod>
              <ColMod lg={6}>
                <Typography style={{ marginTop: '0.7em', marginBottom: '1em' }} color="secondary" variant="body2">Product Images</Typography>
                {
                    urls.map((url, index) => (
                      <Upload
                        key={index}
                        url={url.imageUrl ? url.imageUrl : null}
                        side={url.position}
                        handleImageUpload={(imageUrl, position) => this.handleImageUpload(imageUrl, position)}
                        clearImage={(position) => { this.clearImage(position); }}
                      />
                    ))
                }
              </ColMod>
            </Row>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose()}} style={{ marginRight: '2em' }} color="secondary">
                Cancel
          </Button>
          <Button onClick={this.saveProduct} variant="contained" color="secondary">
          {id ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
        <BrandSelection openSearchBox={this.state.openBrandSelector} handleClose={this.handleBrandSelectorClose} selectBrand={this.selectBrand} />
        <CategorySelection openSearchBox={this.state.openCategorySelector} handleClose={this.handleCategorySelectorClose} selectCategory={this.selectCategory} />
        <GstSelection openSearchBox={this.state.openGstSelector} handleClose={this.handleGstSelectorClose} selectGst={this.selectGst} />
      </Dialog>
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.instanceOf(Object),
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  taxType: state.taxType
});

export default connect(mapStateToProps, null)(withStyles(styles)(ProductForm));
