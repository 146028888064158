import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { searchAllCities } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', searchAllCities(searchTerm));
  const { data: { _embedded } } = response;
  const { cities } = _embedded;

  return cities.map(city => {
    return {
      ...city,
      value: city.id,
      label: city.name,
    }
  })
}

function CitySelector(props) {
  const { selected, onSelect, isClearable } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
    />
  )
}

CitySelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
};

CitySelector.defaultProps = {
  selected: null,
  isClearable: true,
};

export default CitySelector;
