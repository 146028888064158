import React, { Component } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Snackbar
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavBar from '../../../components/NavBar';
import apiCall, {
  grnReceiptsById,
  grnById,
  grnPath,
  purchaseOrderById,
  fetchAllGrnByPoId
} from '../../../api/NetworkHandler';
import UploadFile from '../../Commons/components/UploadFile';
import Title from '../../Commons/components/Title';
import POItemList from '../components/POItemList';
import Colors from '../../../utils/Colors';
import GrnTitleBar from '../components/GrnTitleBar';
import config from '../../../api/config';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isEqual, cloneDeep, debounce } from "lodash";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getLatestGRNQuantitySummary, fetchMaxExtraAllowed } from './GrnUtils';

const { confirm } = Modal;

const styles = {
  paper: {
    border: '1px solid #f2f2f2',
    padding: 10,
    margin: '5px auto',
    width: '98%',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
};

var versionDict = {
  "grnRequestId": 0
};

class EditGRNContainer extends Component {
  constructor(props) {
    super(props);
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { id, poId } = params;
    this.state = {
      grnId: id,
      grn: {},
      receipts: [],
      error: '',
      poId,
      orderedQuantity: [],
      poOrderedQuantity: [],
      data: [],
      disableUpdate: false,
      disableSubmitReceipt: false,
      initialGrn: {},
      initialReceipts: [],
      poReceivableDate: '',
      maxExtraAllowed: null,
      poStatus: '',
      openSnackbar: false,
      renderLineItem: false
    };
  };

  componentDidMount() {
    this.fetchGrnDetails();
    this.fetchGrnReceipts();
    this.fetchGRNS();
    this.getMaxExtraAllowed();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchGRNS = async () => {
    const { poId } = this.state;
    let result = false;
    try {
      versionDict.grnRequestId += 1;
      let prevRequestId = versionDict.grnRequestId;
      const response = await apiCall.sendRequest('get', fetchAllGrnByPoId(poId));
      const { data: { _embedded: { grn = [] } = {} } = {} } = response;
      if (prevRequestId === versionDict.grnRequestId) {
        this.setState({
          data: grn
        });
      }
      return result;
    } catch (err) {
      this.setState({
        data: []
      });
    }
  };

  getMaxExtraAllowed = () => {
    fetchMaxExtraAllowed((err, res) => {
      if (err) {
        this.setState({
          maxExtraAllowed: null
        });
      }

      if (res) {
        this.setState({
          maxExtraAllowed: res
        });
      }
    })
  }

  toggleRenderLineItems = (state) => {
    this.setState({
      renderLineItem: state
    });
  };

  fetchGrnDetails = async () => {
    const { grnId } = this.state;
    this.toggleRenderLineItems(false);
    try {
      const response = await apiCall.sendRequest('get', grnById(grnId));
      const { data = {} } = response;
      const shouldAddPOLineItemId = data.grnLineItems.every(el => el.purchaseOrderLineItemId !== null);
      this.setState({
        grn: data,
      }, () => {
        this.fetchPurchaseOrderDetails(shouldAddPOLineItemId);
      });
    } catch (e) {
      // error handling
    }
  }

  prepareGrnDataFromPO = (po, shouldAddPOLineItemId) => {
    const { grn = {} } = this.state;
    const { grnLineItems = [] } = grn;
    const { purchaseOrderLineItems = [], receivableDate, status } = po;

    const newGrnLineItems = grnLineItems.map(el => {
      if (el.purchaseOrderLineItemId) {
        const matchedPoLineItem = purchaseOrderLineItems.find(elm => elm.id === el.purchaseOrderLineItemId);
        return {
          ...el,
          vendorProductItemId: `${el.vendorProductItemId}-${el.purchaseOrderLineItemId}`,
          costPrice: matchedPoLineItem.sellingPrice
        };
      } else {
        return {
          ...el,
          vendorProductItemId: `${el.vendorProductItemId}-${el.unitPrice}-${el.purchaseItemType}`
        }
      }
    });

    let purchaseOrderItems = {};
    let poOrderedQuantity = [];
    purchaseOrderLineItems.forEach((item) => {
      const newVendorProductItemId = shouldAddPOLineItemId ? `${item.vendorProductItemId}-${item.id}` : `${item.vendorProductItemId}-${item.sellingPrice}-${item.purchaseItemType}`;
      purchaseOrderItems = {
        ...purchaseOrderItems,
        [newVendorProductItemId]: item.quantity,
      };
      poOrderedQuantity.push({
        vendorProductItemId: newVendorProductItemId,
        quantity: item.quantity,
      })
    });

    this.setState({
      orderedQuantity: purchaseOrderItems,
      poOrderedQuantity,
      poReceivableDate: receivableDate,
      poStatus: status,
      grn: {
        ...grn,
        grnLineItems: newGrnLineItems
      },
      initialGrn: cloneDeep(newGrnLineItems),
    }, () => this.toggleRenderLineItems(true));
  }


  fetchPurchaseOrderDetails = async (shouldAddPOLineItemId) => {
    const { poId } = this.state;
    try {
      const purchaseOrderItemsResponse = await apiCall.sendRequest('get', purchaseOrderById(poId));
      const { data = {} } = purchaseOrderItemsResponse;
      this.prepareGrnDataFromPO(data, shouldAddPOLineItemId);
    } catch (e) {
      console.log(e);
    }
  }

  fetchGrnReceipts = async () => {
    const { grnId } = this.state;
    try {
      const response = await apiCall.sendRequest('get', grnReceiptsById(grnId));
      const { data: { data = [] } } = response;
      this.setState({
        receipts: data,
        initialReceipts: cloneDeep(data),
      });
    } catch (e) {
      // error handling
    }
  }

  goBack = () => {
    const { history } = this.props;
    let prevLocation = reactLocalStorage.get('curLocation')
    if (prevLocation === '/grn') {
      history.push(`/grn`);
    } else {
      history.push(`/po/${this.state.poId}`);
    }
  }

  postGrnReceipts = debounce(async () => {
    this.setState({ disableSubmitReceipt: true });
    const { grnId, receipts } = this.state;
    let postData = [];
    receipts.forEach((receipt) => {
      if (!receipt.id) {
        postData = [
          ...postData,
          {
            active: true,
            id: receipt.id || null,
            grnId: receipt.grnId || grnId,
            name: receipt.name,
            receiptUrl: receipt.receiptUrl,
          },
        ];
      }
    });
    try {
      await apiCall.sendRequest('post', grnReceiptsById(grnId), postData);
      this.fetchGrnReceipts();
      this.goBack();
    } catch (e) {
      // error handling
    }
    this.setState({ disableSubmitReceipt: false });
  }, 300);

  preparePostData = (grnData) => {
    const { grnLineItems = [] } = grnData;
    const newGrnLineItems = grnLineItems.map(el => { return { ...el, vendorProductItemId: el.vendorProductItemId.split("-")[0] } });
    return { ...grnData, grnLineItems: newGrnLineItems }
  };

  toggleSnackBar = (snackBarState) => {
    this.setState({
      openSnackbar: snackBarState
    });
  };

  updateGrn = debounce(async () => {
    const { grn } = this.state;
    grn.warehouse = `${config.BASE_URL}/api/v1/warehouse/${grn.warehouseId}`;
    if (grn.status !== 'STOCKUPDATED' && !this.validateGRNLineItems()) {
      this.toggleSnackBar(true);
      return;
    }

    this.setState({ disableUpdate: true });
    // let modifiedGRN = this.getModifiedGRN(grn);
    // grn.grnLineItems = modifiedGRN;
    const newGrnData = this.preparePostData(grn);

    try {
      await apiCall.sendRequest('post', grnPath, newGrnData);
      this.setState({ disableUpdate: false });
      this.goBack();
    } catch (e) {
      // error handling
      this.setState({ disableUpdate: false });
    }
  }, 500);

  validateGRNLineItems = () => {
    const { grn = {}, maxExtraAllowed, poOrderedQuantity, data } = this.state;
    const { grnLineItems = [] } = grn;
    let isValid = true;
    let maxAllowedOrderedQuantityObj = poOrderedQuantity.map(el => {
      return { ...el, quantity: Math.round(el.quantity * (1.0 + (maxExtraAllowed / 100)).toFixed(1)) }
    });
    let stockUpdatedGrnReceivedQuantity = getLatestGRNQuantitySummary(data, ['STOCKUPDATED']);
    grnLineItems.forEach(lineItem => {
      let curMaxAllowedLineItem = maxAllowedOrderedQuantityObj.find(el => el.vendorProductItemId == lineItem.vendorProductItemId);
      if (stockUpdatedGrnReceivedQuantity.length > 0) {
        let curStockUpdatedLineItem = stockUpdatedGrnReceivedQuantity.find(el => el.vendorProductItemId == lineItem.vendorProductItemId);
        if (curStockUpdatedLineItem) {
          if (parseInt(lineItem.receivedQuantity) > (parseInt(curMaxAllowedLineItem.quantity) - parseInt(curStockUpdatedLineItem.quantity))) {
            isValid = false;
          }
        }
      } else {
        if (lineItem.receivedQuantity > curMaxAllowedLineItem.quantity) {
          isValid = false
        }
      }
    });

    return isValid
  }

  getModifiedGRN = (grn) => {
    let modifiedGRN = { ...grn }
    let { grnLineItems = [] } = modifiedGRN;
    let modifiedGRNLineItems = grnLineItems.filter(el => !(el.receivedQuantity == 0 && el.rejectedQuantity == 0));
    return modifiedGRNLineItems;
  }

  handleFileUpload = (data = {}, error = []) => {
    let { receipts = [] } = this.state;
    const { grn = {} } = this.state;

    Object.keys(data).forEach((key) => {
      receipts = [
        ...receipts,
        {
          name: `PO${grn.poNumber}_GRN${grn.id}_${receipts.length}${key.substr(key.lastIndexOf('.'), key.length)}`,
          receiptUrl: data[key],
        },
      ];
    });
    this.setState({
      receipts,
      error: error.length > 0 ? 'invalid file format' : null,
    });
  }

  handleRemoveFile = (index) => {
    const { receipts } = this.state;
    receipts.splice(index, 1);
    this.setState({
      receipts,
    });
  }

  handleChange = (items) => {
    const { grn } = this.state;
    grn.grnLineItems = items;
    this.setState({
      grn,
    });
  }


  handleFileChange = (index, key, value) => {
    const { receipts } = this.state;
    receipts[index][key] = value;
    this.setState({
      receipts,
    });
  }

  showConfirm = () => {
    const { initialGrn, grn, initialReceipts, receipts } = this.state;
    let isGrnModified = isEqual(initialGrn, grn);
    let isReceiptModified = isEqual(initialReceipts, receipts);
    confirm({
      title: 'Do you want to save changes?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Save & Exit',
      cancelText: 'Exit',
      onOk: () => {
        !isGrnModified ? this.updateGrn() : null;
        !isReceiptModified ? this.postGrnReceipts() : null;
      },
      onCancel: () => {
        this.goBack();
      },
    });
  }

  handleGrnChange = (event) => {
    const { name, value } = event.target;
    const { grn, poReceivableDate } = this.state;
    let today = new Date;
    if (name === "deliveryDate" && !moment(value).isBetween(poReceivableDate, today, undefined, '[)')) {
      return;
    }
    this.setState({
      grn: {
        ...grn,
        [name]: value,
      }
    });
  }

  render() {
    const {
      receipts = [],
      grn = {},
      error = '',
      orderedQuantity,
      poOrderedQuantity,
      disableUpdate,
      disableSubmitReceipt,
      initialGrn,
      initialReceipts,
      poReceivableDate,
      poStatus,
      data,
      openSnackbar,
      renderLineItem
    } = this.state;

    const { grnLineItems = [], comment = '', totalReceivedPrice } = grn;
    const images = receipts.map(({ name, receiptUrl, id }) => ({
      name,
      url: receiptUrl,
      editable: !id,
    }));
    const viewOnly = grn.status === 'STOCKUPDATED';
    let isValid = grnLineItems.some(el => el.receivedQuantity > 0 || el.rejectedQuantity > 0);
    let isGrnModified = isEqual(initialGrn, grn);
    let isReceiptModified = isEqual(initialReceipts, receipts);
    const isUpdateAllowed = grn.status === 'STOCKUPDATED' && isGrnModified;
    return (
      <div>
        <NavBar />
        <GrnTitleBar title="Edit GRN" grn={grn} onChange={this.handleGrnChange} viewOnly={viewOnly} poReceivableDate={poReceivableDate} />
        <Grid container style={{ margin: '0 auto' }}>
          <Paper
            elevation={0}
            style={styles.paper}
          >
            <div style={styles.div}>
              <Title title="GRN Line Items" />
              <Typography variant="body2" color="secondary">
                <Button
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={!(isGrnModified && isReceiptModified) ? this.showConfirm : this.goBack}
                >
                  Back
                </Button>
              </Typography>
            </div>
            {renderLineItem &&
              <POItemList
                viewOnly={viewOnly}
                items={grnLineItems}
                requiredQuantity={orderedQuantity}
                onChange={this.handleChange}
                getReceivedQuantity={getLatestGRNQuantitySummary(data, ['STOCKUPDATED'])}
                poOrderedQuantity={poOrderedQuantity}
              />
            }
            <div style={{ borderTop: '1px solid', marginTop: '30px' }}>
              <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1, marginTop: '10px' }}>
                <Typography variant="title" color="primary" style={{ marginLeft: '20px' }}>{totalReceivedPrice ? totalReceivedPrice.toFixed(2) : 0}</Typography>
                <Typography variant="body2" color="inherit">
                  Total Received Amount
                </Typography>
              </div>
            </div>
            <div style={{ margin: '30px 0px' }}>
              <Grid container>
                <Grid item md={6}>
                  <TextField
                    label="Comment"
                    fullWidth
                    name="comment"
                    value={comment}
                    onChange={(e) => {
                      this.setState({
                        grn: {
                          ...grn,
                          comment: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} style={{ textAlign: 'right' }}>
                  <Button
                    id="update-btn"
                    variant="contained"
                    color="secondary"
                    onClick={this.updateGrn}
                    disabled={isUpdateAllowed || disableUpdate || !isValid}
                    style={{ marginRight: 10 }}
                  >Update GRN
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Paper elevation={0} style={styles.paper}>
            <Title title="GRN Receipts" />
            {error &&
              <span style={{ fontSize: 12, color: Colors.primary, padding: '0em 2em' }}>
                *few unsupported files were not uploaded
              </span>}
            <div style={{ marginTop: 10, marginBottom: 10, padding: '0em 2em' }}>
              <UploadFile
                files={images}
                onChange={this.handleFileChange}
                onUpload={this.handleFileUpload}
                onDelete={this.handleRemoveFile}
                grn={grn}
              />
            </div>
            <div style={{ textAlign: 'right', margin: '30px 0px' }}>
              <Button variant="contained" disabled={disableSubmitReceipt} color="secondary" onClick={this.postGrnReceipts}>Submit Receipts</Button>
            </div>
          </Paper>
        </Grid>
        <Snackbar
          open={openSnackbar}
          onClose={() => this.toggleSnackBar(false)}
          message="Invalid Received Quantity Entered"
          style={{ marginBottom: "20px" }}
        />
      </div>
    );
  }
}

EditGRNContainer.propTypes = {
  match: PropTypes.instanceOf(Object),
};

export default withRouter(EditGRNContainer);