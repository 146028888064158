import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from 'react-flexbox-grid/lib/components/Grid';
import Row from 'react-flexbox-grid/lib/components/Row';
import { ColMod } from '../../../components/lib';
import BASE_URL from '../../../api/config';
import apiCall, { createVendorProductById, findPackaging } from '../../../api/NetworkHandler';
import moment from 'moment';
import { connect } from 'react-redux';
import ProgressBar from '../../../components/States/ProgressBar';
import SectionMessage from '@atlaskit/section-message';
import SearchWithSuggestions from "../../Commons/components/SearchWithSuggestions";
import debounce from 'lodash/debounce';

class CreateProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productItem: {},
      packagingList: [],
      errorMessage: '',
      isSubmitInProcess: false,
      inputData: '',
      disableSaveButton: false,
    }
  };

  componentDidMount() {
    const { productItemData = {} } = this.props;
    const { unitPrice, costPrice, packaging } = productItemData;
    const { productItem } = this.state;
    this.setState({
      productItem: {
        ...productItem,
        unitPrice: unitPrice,
        sellingPrice: costPrice,
        packaging: packaging,
        discount: 0,
        tax: 0,
      },
      inputData: packaging,
    });
    this.getAllPackagingList(packaging);
  }

  handleInputChange = property => (event) => {
    const { value } = event.target;
    let { productItem } = this.state;
    let { unitPrice } = productItem;
    if (property === 'unitMeasure' && value < 0) {
      return;
    }
    if (property === 'unitPrice') {
      if (value < 0) {
        return;
      }
      this.setState({
        productItem: {
          ...productItem,
          sellingPrice: value,
          tax: 0,
        }
      });
    }
    if (property === 'sellingPrice') {
      if (value < 0) {
        return;
      }
      else if (value > unitPrice) {
        this.setState({
          invalidSellingPrice: true,
          sellingPriceText: 'More than MRP not allowed',
        });
        return;
      } else {
        this.setState({
          invalidSellingPrice: false,
          sellingPriceText: '',
        });
      } 
    }
    if (property === 'tax' && value < 0) {
      return;
    }
    this.setState(prevState => {
      const productItem = { ...prevState.productItem };
      productItem[property] = value;
      const { uomId, unitMeasure = '' } = productItem;
      const { productName, UOM } = this.props;
      const uom = UOM.find(uom => uom.id == uomId) || {};
      productItem.name = `${productName}-${unitMeasure || ''}${uom.code || ''}`;
      return {
        productItem,
      }
    });
  }

  getAllPackagingList = async (query = '') => {
    try {
      const response = await apiCall.sendRequest('get', findPackaging(query));
      const { data = {} } = response;
      const { _embedded: embedded = {} } = data;
      const { packaging = [] } = embedded;
      this.setState({
        packagingList: packaging,
      });
    } catch (error) {
      this.setState({
        packagingList: [],
      });
    }
  }

  handlePackagingSearch = query => {
    const { productItem } = this.state;
    this.setState({
      inputData: query,
      productItem: {
        ...productItem,
        packaging: this.validatePackaging(query) ? query : '',
      }
    });
    this.getAllPackagingList(query);
  };


  handlePackagingSelection = item => {
    const { name } = item;
    const { productItem } = this.state;
    this.setState({
      productItem: {
        ...productItem,
        packaging: name
      }
    });
  };

  validatePackaging = (query) => {
    const { packagingList = [] } = this.state;
    let invalidPackagingQuery = false;
    packagingList.forEach(item => {
      if (item.name === query) {
        invalidPackagingQuery = true;
      }
    });
    return invalidPackagingQuery;
  }


  validateForm = () => {
    const { productItem } = this.state;
    let isFormValid = true;
    const {
      name,
      unitMeasure,
      uomId,
      unitPrice,
      sellingPrice,
      tax,
      packaging,
    } = productItem;

    if (!name) {
      isFormValid = false;
      this.setState({
        invalidName: true,
      });
    } else {
      this.setState({
        invalidName: false,
      });
    }
    if (!unitMeasure || unitMeasure < 1) {
      isFormValid = false;
      this.setState({
        invalidUnitMeasure: true,
        unitMeasureText: unitMeasure < 1 ? 'Less than 1 not allowed' : 'Unit Measure field is required'
      });
    } else {
      this.setState({
        invalidUnitMeasure: false,
        unitMeasureText: ''
      });
    }
    if (!uomId) {
      isFormValid = false;
      this.setState({
        invalidUomId: true,
        uomIdText: 'UOM ID field is required'
      });
    } else {
      this.setState({
        invalidUomId: false,
        uomIdText: ''
      });
    }
    if (!packaging) {
      isFormValid = false;
      this.setState({
        invalidPackaging: true,
        packagingText: 'Packaging field is required'
      });
    } else {
      this.setState({
        invalidPackaging: false,
        packagingText: ''
      });
    }
    if (!unitPrice) {
      isFormValid = false;
      this.setState({
        invalidUnitPrice: true,
        unitPriceText: 'Unit Price field is required'
      });
    } else {
      this.setState({
        invalidUnitPrice: false,
        unitPriceText: ''
      });
    }
    if (!sellingPrice) {
      isFormValid = false;
      this.setState({
        invalidSellingPrice: true,
        sellingPriceText: 'Selling Price field is required'
      });
    } else {
      this.setState({
        invalidSellingPrice: false,
        sellingPriceText: ''
      });
    }
    if ((typeof tax == 'string' && !tax) || typeof tax == 'undefined') {
      isFormValid = false;
      this.setState({
        invalidTax: true,
        taxText: 'Tax field is required'
      });
    } else {
      this.setState({
        invalidTax: false,
        taxText: ''
      });
    }
    return isFormValid;
  }

  handleSave = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({ isSubmitInProcess: true, disableSaveButton: true });
    const { productItem = {} } = this.state;
    const { productId = null, productItemData = {}, onClose } = this.props;
    // const { cutOffTime } = productItemData;
    const { uomId = null } = productItem;
    // const timeString = `2019-01-01T${cutOffTime}`;
    // const formattedCutOffTime = moment(timeString).format('HH:mm:ss');
    // productItem.cutOffTime = formattedCutOffTime;
    try {
      const response = await apiCall.sendRequest('post', createVendorProductById(productId, uomId), productItem);
      this.setState({ isSubmitInProcess: false });
      onClose();
    } catch (error) {
      console.log(error.response);
      this.setState({ isSubmitInProcess: false });
    }
    this.setState({ disableSaveButton: false });
  }, 300);

  render() {
    const {
      productItem,
      invalidUnitMeasure,
      invalidPackaging,
      invalidUnitPrice,
      invalidSellingPrice,
      invalidTax,
      unitMeasureText,
      uomIdText,
      sellingPriceText,
      packagingText,
      unitPriceText,
      taxText,
      errorMessage,
      isSubmitInProcess,
      packagingList,
      disableSaveButton,
    } = this.state;

    const { onClose, UOM, productName, productItemData = {} } = this.props;

    const {
      name = `${productName}-`,
      unitMeasure = '',
      tax = null,
      uomId = null,
      unitPrice = null,
      sellingPrice = null,
    } = productItem;

    return (
      <Grid style={{ width: '50vw' }} fluid>
        <ProgressBar isLoading={isSubmitInProcess} />
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose() }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  Create Vendor Product Item
                </Typography>
                <Typography variant="caption" color='error'>
                  * Required
                </Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose() }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={this.handleSave} variant="contained" color="secondary" disabled={disableSaveButton}>
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        {errorMessage && 
          <Row>
            <ColMod lg={12}>
              <SectionMessage appearance="error">
                {errorMessage}
              </SectionMessage>
            </ColMod>
          </Row>}
        <ColMod lg={18}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Basic Details
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={10}>
              <TextField
                label="Name"
                value={name}
                InputProps={{ readOnly: true }}
                fullWidth
                helperText="Note: Name will be auto-set from Unit Measure and UOM"
              />
            </ColMod>
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Product Properties
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding>
              <TextField
                label="Unit Measure"
                type="number"
                value={unitMeasure || ''}
                onChange={this.handleInputChange('unitMeasure')}
                fullWidth
                error={invalidUnitMeasure}
                required
                helperText={unitMeasureText}
                // InputProps={{ inputProps: { min: 1 } }}
                InputLabelProps={{ shrink: true }}
              />
            </ColMod>
            <ColMod lg={6} custompadding bumphigh>
              <InputLabel style={{ fontSize: '0.7em', color: uomIdText ? 'red' : '' }} htmlFor="uom-id">UOM ID<sup>*</sup></InputLabel>
              <Select
                error={uomIdText}
                required
                value={uomId || ''}
                onChange={this.handleInputChange('uomId')}
                inputProps={{
                  name: 'uomId',
                  id: 'uom-id',
                }}
                fullWidth
              >
                {UOM.map(x => (<MenuItem key={x.id} value={x.id}>{x.code}</MenuItem>))}
              </Select>
              <small style={{ color: 'red' }}> {uomIdText} </small>
            </ColMod>
            <ColMod lg={6} custompadding bumphigh>
              <InputLabel style={{ fontSize: '0.9em', color: invalidPackaging ? 'red' : '' }}> Packaging <sup>*</sup></InputLabel>
              <SearchWithSuggestions
                searchQuery={productItemData.packaging || ''}
                suggestions={packagingList}
                onChange={this.handlePackagingSearch}
                onSelect={this.handlePackagingSelection}
              /> 
              <small style={{ color: 'red', fontSize: '0.8em' }}> {packagingText} </small>
            </ColMod>
            {/* <ColMod lg={6} custompadding bumphigh>
              <TextField
                label="Cutoff Time"
                type="time"
                value={productItemData.cutOffTime}
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </ColMod> */}
          </Row>
        </ColMod>
        <ColMod lg={12}>
          <Row>
            <ColMod lg={12}>
              <Typography variant='title'>
                Product Price Details
              </Typography>
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding bumphigh>
              <TextField
                label="MRP"
                type="number"
                value={unitPrice}
                defaultValue={productItemData.unitPrice}
                onChange={this.handleInputChange('unitPrice')}
                fullWidth
                required
                error={invalidUnitPrice}
                helperText={unitPriceText}
                InputLabelProps={{ shrink: true }}
              />
            </ColMod>
            <ColMod lg={6} custompadding bumphigh>
              <TextField
                label="Buying Price (Exclusive of Tax)"
                type="number"
                value={sellingPrice}
                defaultValue={productItemData.costPrice}
                onChange={this.handleInputChange('sellingPrice')}
                fullWidth
                required
                error={invalidSellingPrice}
                helperText={sellingPriceText}
                InputLabelProps={{ shrink: true }}
              />
            </ColMod>
          </Row>
          <Row>
            <ColMod lg={6} custompadding bumphigh>
              <TextField
                label="Tax(%)"
                type="number"
                value={tax}
                defaultValue={0}
                onChange={this.handleInputChange('tax')}
                fullWidth
                error={invalidTax}
                required
                helperText={taxText}
                InputLabelProps={{ shrink: true }}
              />
            </ColMod>
          </Row>
        </ColMod>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  UOM: state.UOM,
});

export default connect(mapStateToProps, null)(CreateProductForm);
