import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, Row } from 'react-flexbox-grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ColMod } from '../components/lib';
import NavBar from '../components/NavBar';
import apiCall from "../api/NetworkHandler";

const tableData = [
  {
    v_id: 'VID6761', v_name: 'Nandini', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6762', v_name: 'Heritage', location: 'Bengaluru,Pune', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6763', v_name: 'Amul', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6764', v_name: 'Nestle', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6765', v_name: 'Vrindavan', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6766', v_name: 'Thirumala', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
  {
    v_id: 'VID6767', v_name: 'Dodla', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'SUBMITTED',
  },
];

const columnData = [
  {
    id: 'VendorName', numeric: false, disablePadding: true, label: 'Vendor name',
  },
  {
    id: 'Catalog', numeric: false, disablePadding: true, label: 'Catalog',
  },
  {
    id: 'Status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.label}
            </TableCell>
            ), this)}
        </TableRow>
      </TableHead>
    );
  }
}


class App extends React.Component {
  static propTypes ={
  }

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      page: 0,
      rowsPerPage: 10,
      data: [],
    };
  }

  componentDidMount() {
    this.fetchAllPendingCatalogs();
  }

  fetchAllPendingCatalogs = () => {
    apiCall.sendRequest('get', '/api/v1/vendor-catalogs/search/by-status?status=PENDING')
      .then((response) => {
        this.setState({
          data: response.data._embedded['vendor-catalogs'],
        });
      });
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    const text = event.target.value.toLowerCase();
    const tempdata = tableData.filter(x => (x.v_name.toLowerCase().includes(text) || x.v_id.toLowerCase().includes(text) || x.c_name.toLowerCase().includes(text) || x.location.toLowerCase().includes(text) || x.status.toLowerCase().includes(text)));
    this.setState({
      data: tempdata,
    });
  }

  rejectProductCatalog = (id) => {
    apiCall.sendRequest('patch', `/api/v1/vendor-catalogs/${id}`, { status: 'REJECTED' })
      .then((response) => {
        console.log(response.data);
        this.fetchAllPendingCatalogs();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createProductCatalog = (id) => {
    apiCall.sendRequest('post', `/api/v1/vendor-catalogs/${id}/publish`, {})
      .then((response) => {
        console.log(response.data);
        this.props.history.push('/catalog-view');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeCatalog(q) {
    console.log('$DEL$', q);
  }

  render() {
    const {
      value, data, page, rowsPerPage,
    } = this.state;
    return (
      <div>
        <NavBar />
        <div style={{ height: '6em', backgroundColor: '#EEEEEE', lineHeight: '6em' }}>
          <span style={{
height: '2em', verticalAlign: 'middle', paddingLeft: '2em', display: 'inline-block', lineHeight: 'normal',
 }}
          >
                 Vendors Catalog Approval
          </span>
          {/* <span style={{marginLeft: '5em'}}>
                <FormControl color='secondary' style={{width: '25em'}}>
                  <Input
                    value={this.state.searchText}
                    color="secondary"
                    placeholder="Search"
                    onChange={this.handleChange('searchText')}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </span> */}
        </div>
        <Table aria-labelledby="tableTitle" style={{ width: '80%', marginLeft: '10%', marginTop: '4%' }}>
          <EnhancedTableHead />
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => (
              <TableRow
                hover
                onClick={event => console.log(n.v_id)}
                tabIndex={-1}
                key={n.id}
              >
                <TableCell component="th" scope="row" padding="none">{n.vendorName}</TableCell>
                <TableCell component="th" scope="row" padding="none">{n.name}</TableCell>
                <TableCell component="th" scope="row" padding="none">{n.status}</TableCell>
                <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                  <Button color="secondary" onClick={() => { this.rejectProductCatalog(n.id); }}>
                        REJECT
                  </Button>&emsp;|&emsp;
                  <Button onClick={() => { this.createProductCatalog(n.id); }} variant="contained" color="secondary">
                        Create Product Catalog
                  </Button>&emsp;|
                  <IconButton onClick={() => { this.removeCatalog(n); }}>
                    <RemoveIcon color="secondary" />
                  </IconButton>
                </TableCell>
              </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}


export default withRouter(App);

