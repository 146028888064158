import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons/';
import styles from './styles';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

class DetailModal extends Component {
  render() {
    const { children, title, unMountComponent, handleAutoAssignedRoute, isRouteSearch = false, enableAutomaticRouting } = this.props;
    return (
      <div style={styles.modal}>
        <div style={styles.header}>
          <ArrowBack style={styles.cross} onClick={unMountComponent} />
          <Typography style={styles.title}>{title}</Typography>
          {isRouteSearch &&
            <Typography variant="body2" color="inherit" style={{ marginLeft: window.innerWidth * 0.6 }}>
              System Suggested Route Only
              &nbsp;&nbsp;&nbsp;&nbsp;
              <small style={{ color: 'red' }}>NO</small>
              &nbsp;
              <Switch
                checked={enableAutomaticRouting}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={handleAutoAssignedRoute}
              />
              &nbsp;
              <small style={{ color: '#1E90FF' }}>YES</small>
            </Typography>
          }
        </div>
        <CardContent style={styles.content}>{children}</CardContent>
      </div>
    );
  }
}

DetailModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  unMountComponent: PropTypes.func,
};

export default DetailModal;
