import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import NavBar from './NavBar';
import RegionTable from './RegionTable';
import RegionSearchBar from './RegionSearchBar';
import apiCall, { getFetchAllRegionsPath } from "../api/NetworkHandler";
import BASE_URL from "../api/config";
import RegionSelection from './RegionSelection';

import { ColMod } from './lib';
import isEmpty from "lodash/isEmpty";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  fullScreen: {
    flex: 1,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      links: {},
      openAddRegion: false,
      isEdit: false,
      selectedName: '',
      selectedParentRegion: '',
      selectedParentRegionName: '',
      selectedRegion: {},
      error: {},
      openRegionSelector: false,
      filters: {
        query: '',
      },
      searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchAllRegions();
  }


onSearch = (query) => {
  this.setState(prevState => ({
    filters: {
      ...prevState.filters,
      query,
    },
  }), () => {
    console.log(this.state.filters.query);
    this.fetchAllRegions();
  });
}

fetchAllRegions = () => {
  if (this.state.filters.query) {
    // TODO Add search api
    apiCall.sendRequest('get', getFetchAllRegionsPath(this.state.filters.query))
      .then(({ data }) => {
        this.setState({
          regions: data._embedded.regions,
          links: data._links,
          searchedResult: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    apiCall.sendRequest('get', 'api/v1/regions')
      .then((response) => {
        this.setState({
          regions: response.data._embedded.regions,
          links: response.data._links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

fetchPaginatedRegions = (url) => {
  apiCall.sendRequest('get', url)
    .then((response) => {
      this.setState({
        regions: response.data._embedded.regions,
        links: response.data._links,
        searchedResult: false,
      });
    })
    .catch((error) => {
      console.error(error);
    });
}

handleClick = (selectedRegion) => {
  this.setState({
    selectedRegion,
    selectedName: selectedRegion.name,
    selectedParentRegion: selectedRegion.parentId ? selectedRegion.parentId : '',
  });
}

handleChange = (event) => {
  const {name} = event.target;
  const {value} = event.target;
  this.setState({
    [name]: value,
  });
}

openAddRegion = (region) => {
  if (region) {
    this.setState({
      selectedName: region.name,
      selectedParentRegionName: region.parentName ? region.parentName : '',
    });
  }
  this.setState({
    openAddRegion: true,
    isEdit: !!region,
  });
}

handleClose = () => {
  this.setState({
    openAddRegion: false,
    selectedName: '',
    selectedParentRegion: '',
    isEdit: false,
    error: {},
  });
}

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    // Is empty does not word for numbers so if condition
    if (typeof value === 'number') {
      if (value === 0) {
        this.setError(key, true);
        return false;
      }
    } else {
      if (isEmpty(value)) {
        this.setError(key, true);
        return false;
      }
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const region = {};
    region.selectedName = this.state.selectedName;
    let isValid = true;

    const validateEntities = ['selectedName'];

    for(const property in region) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, region[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

postRegion = () => {
  if (!this.isFormValid()) {
    return;
  }
  const postData = { ...this.state.selectedRegion };
  postData.name = this.state.selectedName;
  postData.parent = `${BASE_URL.BASE_URL}/api/v1/regions/${this.state.selectedParentRegion}`;
  console.log(postData);
  apiCall.sendRequest('post', '/api/v1/regions', postData)
    .then((response) => {
      console.log(response.data);
      this.fetchAllRegions();
    })
    .catch((error) => {
      console.error(error);
    });

  this.handleClose();
}

openRegionSelector = () => {
  this.setState({
    openRegionSelector: true,
  });
}

handleRegionSelectorClose = () => {
  this.setState({
    openRegionSelector: false,
  });
}

selectRegion = (region) => {
  this.setState({
    selectedParentRegion: region.id,
    selectedParentRegionName: region.name,
    openRegionSelector: false,
  });
}

render() {
  return (
    <div>
      <NavBar />
      <RegionSearchBar openAddRegion={this.openAddRegion} onSearch={this.onSearch} />
      <div style={styles.div}>
        <div style={styles.fullScreen}>
          <RegionTable regions={this.state.regions} openAddRegion={this.openAddRegion} handleClick={this.handleClick} links={this.state.links} fetchPaginatedRegions={this.fetchPaginatedRegions} searchedResult={this.state.searchedResult} />
        </div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openAddRegion}
          onClose={this.handleClose}
          onBackdropClick={this.handleClose}
        >
          <div style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            width: '70%',
            marginLeft: '15%',
            height: '80%',
            }}
          >
            <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
            <Grid style={{ padding: '2em' }}>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <Typography variant="body2" color="secondary">
                      {this.state.isEdit ? 'Update Region' : 'Create Region'}
                    </Typography>
                  </Row>
                  <Row>
                  <Typography variant="body2" color="error">
                    * Required
                  </Typography>
                  </Row>
                  <Row>
                    <ColMod lg={6}>
                      <TextField
                        label="Name"
                        fullWidth
                        required
                        error={this.state.error.selectedName}
                        value={this.state.selectedName}
                        onChange={(event) => {
												const {value} = event.target;
												this.setState({
												selectedName: value,
												});
												}}
                      />
                    </ColMod>
                    <ColMod lg={6}>
                      <TextField
                        margin="dense"
                        id="input-with-icon-textfield"
                        value={this.state.selectedParentRegionName}
                        name="Region"
                        fullWidth
                        onChange={(e) => { this.setState({ selectedParentRegionName: e.target.value }) }}
                        helperText="Parent Region"
                        onClick={this.openRegionSelector}
                        InputProps={{
                        readOnly: true,
												endAdornment: (
																	<InputAdornment position="start">
																		<Search color="secondary" />
												</InputAdornment>
												),
												}}
                      />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
            <div style={{ float: 'right', paddingRight: '2em' }}>
              <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
Cancel
              </Button>
              <Button
                onClick={() => {
									this.postRegion();
							}}
                variant="contained"
                color="secondary"
              >
                {this.state.isEdit ? 'Update' : 'Create'}
              </Button>
            </div>
            <RegionSelection openSearchBox={this.state.openRegionSelector} handleClose={this.handleRegionSelectorClose} selectRegion={this.selectRegion} />
          </div>
        </Modal>
      </div>
    </div>
  );
}
}


export default Dashboard;
