import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row } from 'react-flexbox-grid';
import {
    Typography,
    Select,
    MenuItem,
    Button
} from '@material-ui/core/';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import Colors from '../../../utils/Colors';
import { Input } from 'antd';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isEmpty } from 'lodash';
import { HomeOutlined } from '@ant-design/icons';

const styles = {
  container: {
    margin: 0,
    background: Colors.grey[100],
  },
};


class TOTMarginSearch extends Component {
    constructor(props) {
        super(props)
    
        this.inputCSVFileRef = [];
    }

    handleUploadTotMargin = (event) => {
        const { onUploadTotMargin } = this.props;
        onUploadTotMargin(event.target.files[0]);
        this.inputCSVFileRef["fileUpload"].value = '';
    };
    

    render() {
        const {
            classes,
            onSearch,
            onClear,
            onChange,
            onCreateTOTMargin,
            filters,
            marginTypes,
            onDownloadTotMargin,
            disableButton,
            selectedWarehouse
        } = this.props;
        const { name, marginType } = filters;
        const warehouseName = isEmpty(selectedWarehouse) ? '' : selectedWarehouse.name;
        return (
            <div>
                <Grid fluid className={classes.container}>
                    <Row md={12} middle="md" style={{ justifyContent: 'space-between' }}>
                        <Typography variant="title" color="inherit" style={{ padding: "10px" }}> TOT (Terms of Trade) Margin </Typography>
                        <Typography variant="caption" color="inherit" style={{ padding: "10px" }}><HomeOutlined /> {warehouseName}</Typography>
                    </Row>
                    <Row md={12} middle="md">
                        <ColMod custompadding="true" md={2}>
                            <Typography variant="caption"> Search TOT Margin (By Item Name) </Typography>
                            <Input
                                placeholder="type to search product"
                                allowClear
                                value={name}
                                size="large"
                                name="name"
                                onChange={onChange}
                                autoComplete="off"
                            />
                        </ColMod>
                        <ColMod custompadding="true" md={2}>
                            <Typography variant="caption"> Margin Type </Typography>
                            <Select
                                value={marginType}
                                fullWidth
                                name="marginType"
                                onChange={onChange}
                            >
                                <MenuItem value="ALL">All</MenuItem>
                                {Object.keys(marginTypes).map((type, index) => (<MenuItem key={index} value={type}>{marginTypes[type]}</MenuItem>)) }
                            </Select>
                        </ColMod>
                        <ColMod custompadding="true" md={1}>
                            <Button color="inherit" variant="contained" onClick={onSearch}> Search </Button>
                        </ColMod>
                        <ColMod custompadding="true" md={1}>
                            <Button color="secondary" variant="outlined" onClick={onClear}> Clear </Button>
                        </ColMod>
                        <ColMod custompadding="true" md={2}>
                            <Button color="primary" variant="outlined" onClick={onCreateTOTMargin}> Create TOT Margin </Button>
                        </ColMod>
                        <ColMod custompadding="true" md={2}>
                            <Button color="primary" variant="outlined" onClick={onDownloadTotMargin} disabled={disableButton}> <CloudDownloadIcon /> &nbsp;&nbsp; Download </Button>
                        </ColMod>
                        <ColMod custompadding="true" md={2}>
                            <input ref={ref => this.inputCSVFileRef['fileUpload'] = ref} type="file" accept=".csv" style={{ display: 'none' }} onChange={this.handleUploadTotMargin} />
                            <Button color="secondary" variant="outlined" onClick={() => this.inputCSVFileRef['fileUpload'].click()} disabled={disableButton}> <CloudUploadIcon /> &nbsp;&nbsp; Upload </Button>
                        </ColMod>
                    </Row>
                </Grid>
            </div>
        )
    }
}

TOTMarginSearch.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onCreateTOTMargin: PropTypes.func,
  onClear: PropTypes.func,
  filters: PropTypes.instanceOf(Object)
};

TOTMarginSearch.defaultProps = {
  filters: {},
};

export default withStyles(styles)(TOTMarginSearch);
