import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import NavBar from '../../../components/NavBar';
import PurchaseOrderTable from './PurchaseOrderTable';
import POSearchBar from './POSearchBar';
import apiCall, { searchPurchaseOrderPath, fetchAllGrnByPoId, getVendorLocationById } from '../../../api/NetworkHandler';
import SearchBar from '../../Commons/containers/SearchBar';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';
import { withRouter } from 'react-router-dom';
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import { isEmpty } from "lodash";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: window.innerHeight * 0.7,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '20px',
    paddingLeft: '10px',
    overflow: "auto"
  },
};

var versionDict = {
  "grnRequestId": 0,
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      po: [],
      links: {},
      selectedPo: {},
      currentPage: 0,
      totalElements: 0,
      filters: {
        query: '',
        poNumber: '',
        startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        status: '',
        vendorName: '',
        taxRateType: '',
      },
      rowsPerPage: 10,
      taxType: '',
      grns: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    setTimeout(this.fetchParams, 1000);
    this.setTaxTypeState();
  }

  setTaxTypeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { taxType, poPermittedUsers } = appConfig;
    this.setState({
      taxType,
    });
  };

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.searchPurchaseOrders(getFilterParams);
  }

  onChangeFilter = (filters = {}, refresh) => {
    const { currentPage } = this.state;
    this.setState({
      filters,
    }, () => {
      if (refresh) {
        this.searchPurchaseOrders(currentPage);
      }
    });
  }

  fetchAllGrns = async (poId) => {
    try {
      versionDict.grnRequestId += 1;
      let prevRequestId = versionDict.grnRequestId;
      const response = await apiCall.sendRequest('get', fetchAllGrnByPoId(poId));
      const { data: { _embedded: { grn = [] } = {} } = {} } = response;
      if (prevRequestId == versionDict.grnRequestId) {
        this.setState({
          grns: grn
        });
      }
    } catch (err) {
      this.setState({
        grns: []
      });
    }
  };

  getPurchaseOrder = (data) => {
    this.setState({
      selectedPo: data,
    }, () => this.fetchAllGrns(data.id));
  }

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.searchPurchaseOrders();
        break;
      default: break;
    }
  }

  getVendorLocationData = async (locationId) => {
    try {
      const response = await apiCall.sendRequest('get', getVendorLocationById(locationId));
      const { data } = response;
      return data;
    } catch (err) {
      return {};
    }
  };

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState,
    });
  };

  searchPurchaseOrders = async () => {
    const { filters = {}, currentPage: page, rowsPerPage: size } = this.state;
    filters.taxRateType = filters.taxRateType === "All" ? '' : filters.taxRateType;
    this.toggleLoading(true);
    try {
      const url = searchPurchaseOrderPath(filters, page, size);
      const res = await apiCall.sendRequest('get', url);
      const { data: { _embedded: embedded = {}, page: { totalElements = 0 } = {} } } = res;
      const purchaseOrders = embedded['purchase-orders'];
      let newPurchaseOrders = [];
      for (const el of purchaseOrders) {
        const { vendorLocationId } = el;
        const vendorLocationData = await this.getVendorLocationData(vendorLocationId);
        newPurchaseOrders.push({ ...el, vendorLocationName: !isEmpty(vendorLocationData) ? vendorLocationData.name : 'NA' });
      }
      this.setState({
        po: newPurchaseOrders,
        currentPage: 0,
        totalElements,
      });
    } catch (e) {
      this.setState({
        po: [],
        currentPage: 0,
        totalElements: 0
      });
    }
    this.toggleLoading(false);
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => { this.searchPurchaseOrders() })
  }

  onRowsPerPageChange = (size) => {
    this.setState({
      rowsPerPage: size,
      currentPage: 0,
    }, () => { this.searchPurchaseOrders() })
  }

  render() {
    const { filters = {}, totalElements, currentPage = 0, po, rowsPerPage, taxType, selectedPo, grns, loading } = this.state;
    const { POStatuses = {}, warehouses = [] } = this.props;
    const {
      id,
      timeCreated,
      requester,
      receivableDate,
      totalLineItemPrice,
      timeUpdated,
      taxRateType,
      shipTo,
      billTo,
      vendorAddress,
      vendorName,
      companyName,
      companyAddress
    } = selectedPo;
    return (
      <div>
        <NavBar />
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} tip="Loading...">
          <SearchBar
            title="Purchase Orders"
            filters={filters}
            onChange={this.onChangeFilter}
            onButtonClick={this.handleClick}
          >
            <POSearchBar POStatuses={POStatuses} warehouses={warehouses} taxType={taxType} />
          </SearchBar>
          <div style={styles.div}>
            <div style={styles.paperLeft}>
              <PurchaseOrderTable
                data={po}
                links={this.state.links}
                onPageChange={this.onPageChange}
                onRowsPerPageChange={this.onRowsPerPageChange}
                selectedPo={this.state.selectedPo}
                getPurchaseOrder={this.getPurchaseOrder}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                totalElements={totalElements}
                taxType={taxType}
              />
            </div>
            <div style={styles.paperRight}>
              {!isEmpty(selectedPo)
                ?
                <div>
                  <Typography variant="body2" color="secondary" style={{ borderBottom: 'solid 1px #e0e0e0', marginBottom: '10px' }}> PO Details </Typography>
                  <Typography variant="body1" color="inherit"> <b>PO No:</b> {id} </Typography>
                  <Typography variant="body1" color="inherit"> <b>PO Requester:</b> {requester} </Typography>
                  <Typography variant="body1" color="inherit"> <b>Receivable Date:</b> {moment(receivableDate).format('ll')} </Typography>
                  <Typography variant="body1" color="inherit"> <b>Tax Rate Type:</b> {taxRateType} </Typography>
                  <Typography variant="body1" color="inherit"> <b>Total PO Amount:</b> {totalLineItemPrice.toFixed(2)} </Typography>
                  <Typography variant="body1" color="inherit"> <b>Created on:</b> {moment(timeCreated).format('lll')} </Typography>
                  <Typography variant="body1" color="inherit"> <b>Last Updated on:</b> {moment(timeUpdated).format('lll')} </Typography>

                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px', borderBottom: 'solid 1px #e0e0e0' }}> GRN Details</Typography>
                  {grns.length > 0 ?
                    <div>
                      {grns.map(el => {
                        return (
                          <div style={{ borderBottom: 'solid 1px #e0e0e0', marginBottom: '10px' }} key={el.id}>
                            <Typography variant="body1" color="inherit"> <b>GRN No:</b> {el.id} </Typography>
                            <Typography variant="body1" color="inherit"> <b>Invoice No:</b> {el.invoiceNumber} </Typography>
                            <Typography variant="body1" color="inherit"> <b>Stock Status:</b> {el.status} </Typography>
                            <Typography variant="body1" color="inherit"> <b>Created On:</b> {moment(el.createdOn).format('lll')} </Typography>
                            <Typography variant="body1" color="inherit"> <b>Total Amount:</b> {el.totalReceivedPrice.toFixed(2)} </Typography>
                          </div>
                        )
                      })}
                    </div>
                    :
                    <Typography variant="body1" color="inherit"> No GRN available! </Typography>
                  }
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px', borderBottom: 'solid 1px #e0e0e0' }}>
                    Vendor Info
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    <b>{vendorName}</b>
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {vendorAddress}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px', borderBottom: 'solid 1px #e0e0e0' }}>
                    Company Info
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    <b>{companyName}</b>
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {companyAddress}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px', borderBottom: 'solid 1px #e0e0e0' }}>
                    Ship To
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {shipTo}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px', borderBottom: 'solid 1px #e0e0e0' }}>
                    Bill To
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {billTo}
                  </Typography>
                </div>
                : <div />}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}


Dashboard.propTypes = {
  POStatuses: PropTypes.instanceOf(Object),
  warehouses: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => ({
  POStatuses: state.Status.postatuses,
  warehouses: state.information.warehouses,
});

export default connect(mapStateToProps, null)(withRouter(Dashboard));

