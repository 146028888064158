import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Grid, Row, Col } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { debounce, isEmpty } from 'lodash';
import { ColMod } from '../../../components/lib';
import apiCall, {
  vendorCatalogPath,
  fetchVendorProductItemsByCatalogAndRateType,
  getVendorProductDetailsByCatalogIdAndVendorProductId,
  getTotMarginByVendorProductItemId
} from '../../../api/NetworkHandler';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import { getApplicationConfigs } from "../../../utils/getAppConfig";
import { Input } from 'antd';
import ProgressBar from '../../../components/States/ProgressBar';
import Lozenge from '@atlaskit/lozenge';
import { calculateMaxCostFromMarginPercent } from './Utils';

class ProductSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      catalogId: '',
      suggestions: [],
      vendorProductList: [],
      showSelectedProducts: false,
      taxType: '',
      selectedPurchaseItemType: 'PAID',
      loading: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setTaxTypeState();
  }

  setTaxTypeState = async () => {
    const appConfig = await getApplicationConfigs();
    const { taxType } = appConfig;
    this.setState({
      taxType
    });
  };

  static getDerivedStateFromProps(nextProps) {
    const { items } = nextProps;
    return {
      vendorProductList: items,
    };
  }

handleSearch = (searchText) => {
  console.log(searchText);
  this.setState({ searchText, suggestions: [] }, () => {
    if (searchText.length > 2) {
      this.searchProducts();
    }
  });
}

getVendorProductDetailsByCatalogAndProductId = async (catalogId, vendorProductId) => {
  try {
    const response = await apiCall.sendRequest('get', getVendorProductDetailsByCatalogIdAndVendorProductId(catalogId, vendorProductId));
    const { data: { data = {} } = {} } = response;
    if (!isEmpty(data)) {
      return data;
    }
    else return { category: {}, gstRate: {} };
  } catch (err) {
    return { category: {}, gstRate: {} };
  }
};

getTotMarginInfoByVendorProductItemId = async (vendorProductItemId, warehouseId) => {
  try {
    const response = await apiCall.sendRequest('get', getTotMarginByVendorProductItemId(vendorProductItemId, warehouseId));
    const { data: { data = {} } = {} } = response;
    if (!isEmpty(data)) {
      return data;
    }
    return {};
  } catch (err) {
    console.error(err.response);
    return {};
  }
};

selectProduct = async (product) => {
  const { vendorProductList = [], catalogId, selectedPurchaseItemType } = this.state;
  const { onChange, warehouseId, disableMarginValidation = true } = this.props;
  let item = {};
  let productDetails = {};
  let totMarginDetails = {};
  if (product) {
    productDetails = await this.getVendorProductDetailsByCatalogAndProductId(catalogId, product.vendorProductId);
    if (!disableMarginValidation) totMarginDetails = await this.getTotMarginInfoByVendorProductItemId(product.id, warehouseId);
  }
  const { category = {}, gstRate = {} } = productDetails;
  let calculatedTax = product.tax;
  let hsnCode = null;
  let categoryId = null;
  let categoryName = '';
  let marginType = '';
  let marginPercent = null;
  if (!isEmpty(totMarginDetails)) {
    marginType = totMarginDetails.marginType;
    marginPercent = totMarginDetails.marginPercentage;
    this.setState({
      errorMessage: ''
    });
  }

  if (!isEmpty(gstRate)) {
    calculatedTax = gstRate.igst ? gstRate.igst : (gstRate.sgst + gstRate.cgst);
    hsnCode = gstRate.hsnCode;
  }

  if (!isEmpty(category)) {
    categoryId = category.id;
    categoryName = category.name;
  }

  if (product && product.vendorProductItemId) {
    item = product;
  } else {
    const calculatedSellingPrice = disableMarginValidation ? product.sellingPrice : calculateMaxCostFromMarginPercent(product.unitPrice, product.tax, marginType, marginPercent, product.sellingPrice);
    item = {
      active: product.active,
      //TODO: Not Required discount: selectedPurchaseItemType === 'PAID' ? product.discount : 100,
      lineItemTotalPrice: 0,
      packaging: product.packaging,
      vendorProductItemId: product.id,
      productName: product.name,
      quantity: 0,
      sku: product.sku,
      tax: calculatedTax,
      unitMeasure: product.unitMeasure,
      unitPrice: product.unitPrice,
      sellingPrice: selectedPurchaseItemType === 'PAID' ? calculatedSellingPrice : 0,
      uomId: product.uomId,
      hsnCode,
      categoryId,
      category: categoryName,
      purchaseItemType: selectedPurchaseItemType,
      marginType,
      marginPercentage: marginPercent
    };
  }

  let index = 0;
  let modItems = [];

  if (vendorProductList.length > 0) {
    index = vendorProductList.findIndex(a => a.vendorProductItemId === item.vendorProductItemId && a.purchaseItemType === item.purchaseItemType);

    if (index > -1) {
      modItems = [...vendorProductList];
      modItems.splice(index, 1);
      onChange(modItems);
    } else {
      modItems = [...vendorProductList, item];
      onChange(modItems);
    }
  } else if (vendorProductList.length === 0) {
    modItems.push(item);
    onChange(modItems);
  }
}

handleCatalogSearch =async (query) => {
  const res = await apiCall.sendRequest('get', vendorCatalogPath(query));
  const { data: { _embedded: embedded = {} } = '' } = res;
  this.setState({
    catalogs: embedded['vendor-catalogs'],
    suggestions: [],
  });
}

handleCatalogSelection = async (catalog) => {
  this.setState({
    catalogId: catalog.id,
  });
}

handleCatalogChange = (event) => {
  const { value } = event.target;
  this.setState({
    catalogId: value
  });
}

toggleProgress = (state) => {
  this.setState({
      loading: state,
  });
};

searchProducts = debounce(async () => {
  const { searchUrl, customerId = '', taxRateType } = this.props;
  const { searchText, catalogId, taxType } = this.state;
  this.toggleProgress(true);
  let url = fetchVendorProductItemsByCatalogAndRateType(catalogId, searchText, taxRateType);
  if (searchUrl) {
    url = searchUrl(customerId, searchText);
  }
  try {
    const response = await apiCall.sendRequest('get', url);
    if (searchUrl) {
      const { data } = response.data;
      this.setState({ suggestions: data });
    } else {
      const { data: { data = [] } = {} } = response;
      this.setState({
        suggestions: data,
      });
    }
  } catch (err) {
    this.setState({
      suggestions: [],
    });
  }
  this.toggleProgress(false);
}, 750);

handleModalClose = (flag) => {
  this.setState({
    showSelectedProducts: false,
    suggestions: [],
    catalogId: '',
    searchText: ''
  });
  this.props.handleClose(flag);
}

togglePurchaseItemType = (event) => {
  const { name, value } = event.target;
  this.setState({
    selectedPurchaseItemType: value,
  });
};

render() {
  const { open = false, UOM = [], vendorCatalog = [] } = this.props;
  const {
    searchText, suggestions = [], catalogs = [], vendorProductList = [], showSelectedProducts, catalogId, selectedPurchaseItemType, loading, errorMessage
  } = this.state;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={() => this.handleModalClose(false)}
      disableBackdropClick
    >
      <div style={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        width: '70%',
        height: '80%',
      }}
      >
        <ProgressBar isLoading={loading}/>
        <Button style={{ float: 'right' }} onClick={() => this.handleModalClose(false)} color="secondary" aria-label="close">
          <CloseIcon />
        </Button>
        <Grid style={{ padding: '2em', background: '#FFFFFF' }}>
          <Row>
            <ColMod xs md lg={12} custompadding="true" zerobottom="true">
              <Row>
                <Col lg={4}>
                  <Typography variant="caption"> Vendor Catalog </Typography>
                  {vendorCatalog.length == 0 &&
                    <SearchWithSuggestions
                      suggestions={catalogs}
                      onChange={this.handleCatalogSearch}
                      onSelect={this.handleCatalogSelection}
                    />
                  }
                  {vendorCatalog.length > 0 &&
                    <Select
                      name="catalogId"
                      value={catalogId}
                      fullWidth
                      onChange={this.handleCatalogChange}
                    >
                      {vendorCatalog.map(location => <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)}
                    </Select>
                  }
                </Col>
                <Col lg={6}>
                  <Typography variant="caption"> Search Product (By Name) </Typography>
                  <Input
                    placeholder="type to search product"
                    allowClear
                    value={searchText}
                    size="large"
                    onChange={(e) => this.handleSearch(e.target.value)}
                  />
                </Col>
                <Col lg={2} style={{ textAlign: "right", marginTop: "15px" }}>
                  <Button color="inherit" variant="contained" onClick={this.searchProducts}>Search</Button>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      checked={showSelectedProducts}
                      value={showSelectedProducts}
                      onChange={() => this.setState({ showSelectedProducts: !showSelectedProducts })}
                      color="secondary"
                    />
                    <Typography style={{ paddingTop: '10px' }} color="secondary" variant="body2"> Show Selected Products </Typography>
                  </div>
                </Col>
                <Col lg={8}>
                  <RadioGroup onChange={this.togglePurchaseItemType} name="purchaseItemType" value={selectedPurchaseItemType} row>
                    <FormControlLabel
                      value="PAID"
                      control={<Radio />}
                      label="Paid"
                      disabled={showSelectedProducts}
                    />
                    <FormControlLabel
                      value="SAMPLE"
                      control={<Radio />}
                      label="Sample"
                      disabled={showSelectedProducts}
                    />
                  </RadioGroup>
                </Col>
              </Row>
              {errorMessage && 
                <Row>
                  <Col lg={12} style={{ textAlign: "center" }}>
                    <Typography variant="caption" color="error">{errorMessage}</Typography>
                  </Col>
                </Row>
              }
              <Row>
                <Col lg={12}>
                  <div style={{ overflowX: 'hidden', overflowY: 'auto', height: window.innerHeight * 0.57 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ padding: '0px', width: '50px', textAlign: 'center' }}>Select</TableCell>
                          <TableCell>Product</TableCell>
                          {showSelectedProducts && <TableCell>Purchase Item Type</TableCell>}
                          <TableCell>Unit Measure</TableCell>
                          <TableCell>Buying Price</TableCell>
                          <TableCell>Tax</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          { showSelectedProducts &&
                            vendorProductList.map(item => (
                              <TableRow key={item.id}>
                                <TableCell style={{ padding: '0px' }}>
                                  <Checkbox
                                    checked={!isEmpty(vendorProductList.find(a => a.vendorProductItemId === item.vendorProductItemId))}
                                    value={!isEmpty(vendorProductList.find(a => a.vendorProductItemId === item.vendorProductItemId))}
                                    onChange={event => this.selectProduct(item, event)}
                                    color="secondary"
                                  />
                                </TableCell>
                                <TableCell>{item.productName}</TableCell>
                                <TableCell>
                                  <Lozenge appearance={item.purchaseItemType === 'PAID' ? 'success' : 'removed'} isBold>{item.purchaseItemType}</Lozenge>
                                </TableCell>
                                <TableCell>
                                  {UOM.map((x) => {
                                    if (x.id === item.uomId) {
                                      return `${item.unitMeasure} ${x.code}`;
                                    }
                                    return '';
                                })}</TableCell>
                                <TableCell>{item.sellingPrice}</TableCell>
                                <TableCell>{item.tax}</TableCell>
                              </TableRow>
                          ))}
                          { !showSelectedProducts &&
                          suggestions.map(suggestion => (
                            <TableRow key={suggestion.id}>
                              <TableCell style={{ padding: '0px' }}>
                                <Checkbox
                                  checked={!isEmpty(vendorProductList.find(a => a.vendorProductItemId === suggestion.id && a.purchaseItemType === selectedPurchaseItemType))}
                                  value={!isEmpty(vendorProductList.find(a => a.vendorProductItemId === suggestion.id && a.purchaseItemType === selectedPurchaseItemType))}
                                  onChange={event => this.selectProduct(suggestion, event)}
                                  color="secondary"
                                />
                              </TableCell>
                              <TableCell>{suggestion.name}</TableCell>
                              <TableCell>
                                  {UOM.map((x) => {
                                    if (x.id === suggestion.uomId) {
                                      return `${suggestion.unitMeasure} ${x.code}`;
                                    }
                                    return '';
                                })}</TableCell>
                              <TableCell>{suggestion.sellingPrice}</TableCell>
                              <TableCell>{suggestion.tax}</TableCell>
                            </TableRow>
                        ))
                        }
                      </TableBody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </ColMod>
          </Row>
        </Grid>
      </div>
    </Modal>
  );
}
}

ProductSelection.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  searchUrl: PropTypes.func,
  customerId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  UOM: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => ({
  UOM: state.UOM,
});

export default connect(mapStateToProps, null)(ProductSelection);

