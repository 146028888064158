export const convertUom = (sourceUom, targetUom, sourceUnitMeasure, targetUnitMeasure, value) => {
  const source = sourceUom.toLowerCase();
  const target = targetUom.toLowerCase();
  if (source == target) {
    return (targetUnitMeasure * value) / sourceUnitMeasure;
  }

  switch (source) {
    case "kg":
      if (target == "g") {
        return ((value * targetUnitMeasure) / (sourceUnitMeasure * 1000));
      }
    case "g":
      if (target == "kg") {
        return ((value * targetUnitMeasure * 1000) / sourceUnitMeasure);
      }
    case "l":
      if (target == "ml") {
        return ((value * targetUnitMeasure) / (sourceUnitMeasure * 1000));
      }
    case "ml":
      if (target == "l") {
        return ((value * targetUnitMeasure * 1000) / sourceUnitMeasure);
      }
  }
}