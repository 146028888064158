import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button, Tooltip } from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';
import moment from 'moment';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';


const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Group Name',
  },
  {
    id: 'timeCreated', numeric: false, disablePadding: true, label: 'Create On',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Last Updated On',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCustomComponents = () => {
    const customComponents = {
      status: {},
      timeCreated: {},
      timeUpdated: {}
    };
    const { data } = this.props;

    data.map((el, index) => {
      customComponents['status'][index] = <Lozenge appearance={el.active ? "success" : "removed"}>{el.active ? 'Active' : 'Inactive'}</Lozenge>;
      customComponents['timeCreated'][index] = moment(el.timeCreated).format("lll");
      customComponents['timeUpdated'][index] = moment(el.timeUpdated).format("lll");
    })
    return customComponents;
  }

  getActions = (el) => (
    <React.Fragment>
      <Tooltip title="Edit Group">
        <Button color="primary" variant="outlined" size="small" onClick={() => { this.props.onEditGroupName(el) }} style={{ padding: "0px 5px", minWidth: "45px" }}>
          <CreateIcon />
        </Button>
      </Tooltip>
      &nbsp;&nbsp;
      <Tooltip title="Associate User Info">
        <Button color="primary" variant="outlined" size="small" onClick={() => { this.props.onAssociate(el) }} style={{ padding: "0px 5px", minWidth: "45px" }}>
          <SettingsIcon />
        </Button>
      </Tooltip>
    </React.Fragment>
  )



  render() {
    const {
      data,
      rowsPerPage,
      page,
      count,
      isNoData,
      onChangePage,
      onChangeRowsPerPage
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={data}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        isNoData={isNoData}
        customComponents={this.getCustomComponents()}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

GroupList.propTypes = {
  data: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}

export default GroupList;
