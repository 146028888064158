import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import NavBar from '../../../components/NavBar';
import BASE_URL from '../../../api/config';
import apiCall, {
  alldeliveryOrderChats,
  getWareHouse,
  complaintReasonPath,
  resolveOrderPath,
  deliveryRouteOrdersPath,
  searchDeliverOrderChats,
  deliveryOrderChats,
} from '../../../api/NetworkHandler';
import FilterStore from '../../../store/TempStore';
import OrderCard from '../../DeliveryRoutes/components/OrderCard';
import SearchBar from '../../Commons/containers/SearchBar';
import CustomerComplaintSearch from '../components/CustomerComplaintSearch';
import DeliveryOrderImage from '../../Commons/components/DeliveryOrderImage';

const pageTypes = ['GENERAL', 'CC_EXECUTIVE', 'HUB_ROUTE'];


const notificationOn = {
  position: 'absolute',
  left: '47%',
};
const paper = {
  padding: 8,
  textAlign: 'left',
  color: '#000',
  margin: '5px',
  borderBottom: '3px solid rgb(187, 118, 115)',
};

class CustomerComplaints extends Component {
  constructor(props) {
    super(props);
    const page = 'CustomerComplaints';
    const { match: { path = '' } = {} } = this.props;
    let filtersLocal = {
      query: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      status: '',
      orderId: '',
      customerId: '',
      drawer: false,
    };
    if (FilterStore.getFilters(page)) {
      filtersLocal = FilterStore.getFilters(page);
    }
    let [pageUser] = pageTypes;
    if (path.indexOf('customer-complaints-by-cc') !== -1) {
      [, pageUser] = pageTypes;
    }
    if (path.indexOf('customer-complaints-by-hub-route') !== -1) {
      [,, pageUser] = pageTypes;
    }
    this.state = {
      page: 'CustomerComplaints',
      deliveryRouteOrders: [],
      selectedDeliveryRouteOrder: {},
      deliveryOrderChats: [],
      message: '',
      filters: {
        ...filtersLocal,
        warehouseId: '',
        hubId: '',
        routeName: '',
      },
      currentPage: 0,
      chatMessages: [],
      showNotification: false,
      warehouseList: [],
      complaintReasons: [],
      pageUser,
    };
  }

  componentDidMount() {
    this.fetchAllDeliveryRouteOrders();
    this.interval = setInterval(() => this.getLatestChats(), 10000);
    this.fetchAllWarehouse();
  }

  componentWillUnmount() {
    FilterStore.saveFilters(this.state.filters, this.state.page);
    clearInterval(this.interval);
  }

  getLatestChats = async () => {
    const { User: { id: ccUserId } } = this.props;
    const { currentPage, filters = {}, pageUser } = this.state;
    let filtersToSend = filters;
    if (pageUser === pageTypes[1]) {
      filtersToSend = {
        ...filtersToSend,
        ccUserId,
        status: 'CUSTOMER_COMPLAINT',
      };
    }
    try {
      const response = await apiCall.sendRequest('get', alldeliveryOrderChats(filtersToSend, currentPage, 100));
      const { data } = response;
      const oldMessage = FilterStore.getLatestMessage();
      const latestMessage = data.data && data.data.length > 0 ? data.data[0] : null;
      this.setState({
        chatMessages: latestMessage ? data.data : [],
      });
      if (oldMessage && latestMessage) {
        if (oldMessage.customerOrder !== latestMessage.customerOrder || oldMessage.message !== latestMessage.message) {
          FilterStore.saveLatestMessage(data.data[0]);
          this.setState({
            showNotification: true,
          });
        }
      } else if (data.data.length > 0) {
        FilterStore.saveLatestMessage(data.data[0]);
        this.setState({
          showNotification: true,
        });
      }
    } catch (e) {
      console.error(error);
    }
  }

  getComplaintResons = async () => {
    const { selectedDeliveryRouteOrder = {} } = this.state;
    const { customerOrder } = selectedDeliveryRouteOrder;
    const path = complaintReasonPath(customerOrder);
    try {
      const response = await apiCall.sendRequest('get', path);
      const { data = [] } = response;
      this.setState({
        complaintReasons: data,
      });
    } catch (e) {
      console.log('error ===>', e);
    }
  }

  fetchAllWarehouse = () => {
    apiCall.sendRequest('get', getWareHouse)
      .then((response) => {
        const { data: { _embedded } } = response;
        this.setState({
          warehouseList: _embedded.warehouse,
        });
      });
  }

  fetchAllDeliveryRouteOrders = async (currentPage = 0) => {
    const { filters = {}, pageUser } = this.state;
    const {
      query, fromDate, toDate,
      status, customerId,
      orderId, warehouseId,
      hubId, routeName,
    } = filters;
    const { User: { id: ccUserId } } = this.props;

    let filtersToSend = {
      query,
      fromDate,
      toDate,
      status,
      orderId,
      customerId,
    };
    if (pageUser === pageTypes[0]) {
      filtersToSend = {
        ...filtersToSend,
        status: 'CUSTOMER_COMPLAINT',
      };
    }
    if (pageUser === pageTypes[1]) {
      filtersToSend = {
        ...filtersToSend,
        ccUserId,
        status,
      };
    }
    if (pageUser === pageTypes[2]) {
      filtersToSend = {
        ...filtersToSend,
        warehouseId,
        hubId,
        routeName,
        status: 'CUSTOMER_COMPLAINT',
      };
    }

    const urlPath = deliveryRouteOrdersPath(filtersToSend, currentPage);
    this.setState({
      currentPage,
    });

    const orderResponse = await apiCall.sendRequest('get', urlPath);
    const { data = {} } = orderResponse;
    const { _embedded: embedded } = data;
    this.setState({
      deliveryRouteOrders: embedded['delivery-route-order'],
      selectedDeliveryRouteOrder: embedded['delivery-route-order'].length > 0 ? embedded['delivery-route-order'][0] : {},
    }, () => {
      this.getComplaintResons();
    });
  };

  fetchAllDeliveryOrderChats = (id) => {
    apiCall.sendRequest('get', searchDeliverOrderChats(id))
      .then((response) => {
        const { data: { _embedded } } = response;
        this.setState({
          deliveryOrderChats: _embedded['delivery-order-chats'],
        });
      });
  };

  searchDeliveryRouteOrders = () => {
    this.setState({
      selectedDeliveryRouteOrder: {},
    });
    this.fetchAllDeliveryRouteOrders();
  };

  selectDeliveryRouteOrder = (order) => {
    this.setState(
      {
        selectedDeliveryRouteOrder: order,
      },
      () => {
        this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id);
        this.getComplaintResons();
      },
    );
  };

updateStatus = (value, name) => {
  const postData = {};
  postData[name] = value;
  apiCall.sendRequest('patch', `api/v1/delivery-route-order/${this.state.selectedDeliveryRouteOrder.id}`, postData)
    .then((response) => {
      const index = this.state.deliveryRouteOrders.findIndex(order => order.id === this.state.selectedDeliveryRouteOrder.id);
      this.setState(prevState => ({
        deliveryRouteOrders: [
          ...prevState.deliveryRouteOrders.slice(0, index),
          {
            ...prevState.deliveryRouteOrders[index],
            [name]: value,
          },
          ...prevState.deliveryRouteOrders.slice(index + 1),
        ],
        selectedDeliveryRouteOrder: response.data,
      }));
    })
    .catch((error) => {
      console.error(error);
    });
  this.getComplaintResons();
}

  updateMessage = (event) => {
    const { target: { value } } = event;
    this.setState({
      message: value,
    });
  };

  sendMessage = async () => {
    const { User = {} } = this.props;
    const { selectedDeliveryRouteOrder = {} } = this.state;
    const postData = {
      message: this.state.message,
      deliveryRouteOrder: `${BASE_URL.BASE_URL}/api/v1/delivery-route-order/${selectedDeliveryRouteOrder.id}`,
      userId: User.id,
      userName: User.name,
    };
    await apiCall.sendRequest('post', deliveryOrderChats, postData);
    this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id);
    this.setState({
      message: '',
    });
  }

  toggleDrawer = () => {
    this.setState({
      drawer: !this.state.drawer,
      showNotification: false,
    });
  };

  openOrder = (order) => {
    const { filters } = this.state;
    filters.orderId = order.customerOrder;
    filters.date = moment(order.deliveryDate).format('YYYY-MM-DD');
    this.setState(
      {
        filters,
      },
      () => {
        this.searchDeliveryRouteOrders();
      },
    );
  };

  handleResolve = async () => {
    const { selectedDeliveryRouteOrder = {} } = this.state;
    const { customerOrder, complaintReason } = selectedDeliveryRouteOrder;
    const path = resolveOrderPath(customerOrder);
    await apiCall.sendRequest('put', path, { remarks: complaintReason });
    this.fetchAllDeliveryRouteOrders();
  }

  handleChange = (filters, refresh = false) => {
    this.setState({ filters }, () => {
      if (refresh) {
        this.fetchAllDeliveryRouteOrders();
      }
    });
  };

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.searchDeliveryRouteOrders(params);
        break;
      default:
        break;
    }
  };


  render() {
    const {
      chatMessages,
      deliveryRouteOrders,
      showNotification,
      warehouseList,
      complaintReasons = [],
      selectedDeliveryRouteOrder = {},
      selectedDeliveryRouteOrder: { complaintReason },
      filters = {},
      currentPage = 0,
      pageUser = 'GENERAL',
    } = this.state;
    const {
      deliveryImageUrl,
      latitude,
      longitude,
    } = selectedDeliveryRouteOrder;
    const { reasonMapping = {}, deliveryStatuses = {} } = this.props;
    return (
      <div>
        <style>
          {`
            .chat .col{
              padding: 5px !important;
              font-size: 14px;
              overflow: hidden;
            }
            .name{
              font-weight:600
            }
            .message{
              font-size:13px;
            }
            .chat .col .orderNo, .time{
              text-align:right;
              color:#999;
            }
            .userName{
              color:#999;
            }
            .notificationOn{
              background: #f50057;
              width: 15px;
              height: 14px;
              top: -5px;
              right: -5px;
            }
            .notificationOff{
              background: transparent;
            }
            #orderCards::-webkit-scrollbar { width: 0 !important }
            #orderCards { 
              max-height:100vh;
              overflow: scroll;
              scrollbar-width: none; 
            }
          `}
        </style>
        <div>
          <NavBar />
          <SearchBar
            title="Customer Complaints"
            filters={filters}
            onChange={this.handleChange}
            onButtonClick={this.handleClick}
          >
            <CustomerComplaintSearch
              warehouses={warehouseList}
              pageUser={pageUser}
              deliveryStatuses={deliveryStatuses}
            />
          </SearchBar>
          <div style={{ margin: '1em' }}>
            <Grid fluid>
              <Row>
                <span style={{ color: 'grey' }}>
                  <Typography
                    onClick={() => (
                    currentPage > 0 ? this.fetchAllDeliveryRouteOrders(currentPage - 1) : null)
                }
                    color={currentPage > 0 ? 'secondary' : 'inherit'}
                    variant="body1"
                    style={{ cursor: currentPage > 0 ? 'pointer' : 'initial' }}
                  >
                    <ArrowBack style={{ fontSize: '20', verticalAlign: 'top', marginLeft: '16' }} />&nbsp;Prev
                  </Typography>
                </span>&emsp;
                &emsp;
                <span style={{ color: 'grey' }}>
                  <Typography
                    onClick={() => (
                      deliveryRouteOrders.length > 19 ?
                        this.fetchAllDeliveryRouteOrders(currentPage + 1) : null)
                    }
                    color={deliveryRouteOrders.length > 19 ? 'secondary' : 'inherit'}
                    variant="body1"
                    style={{ cursor: deliveryRouteOrders.length > 19 ? 'pointer' : 'initial' }}
                  >
                    <ArrowForward style={{ fontSize: '20', verticalAlign: 'top' }} />&nbsp;Next
                  </Typography>
                </span>
                <Badge
                  style={notificationOn}
                  badgeContent=""
                  classes={{ badge: showNotification ? 'notificationOn' : 'notificationOff' }}
                  onClick={this.toggleDrawer}
                >
                  <MailIcon />
                </Badge>
                <Drawer
                  anchor="right"
                  open={this.state.drawer}
                  onClose={this.toggleDrawer}
                >
                  <div style={{ width: 400, background: '#f2f2f2' }}>
                    {
                      chatMessages && chatMessages.length > 0 ?
                        chatMessages.map((message, index) => (
                          <Paper className="chat" style={paper}>
                            <Grid fluid onClick={() => this.openOrder(message)}>
                              <Row >
                                <ColMod lg={6} >
                                  <span className="name">{message.customerName}</span>
                                </ColMod>
                                <ColMod lg={6}>
                                  <div className="orderNo">OrderNo: {message.customerOrder}</div>
                                </ColMod>
                                <ColMod lg={12}>
                                  <div className="message">{message.message}</div>
                                </ColMod>
                                <ColMod lg={6}>
                                  <div className="userName">{message.userName}</div>
                                </ColMod>
                                <ColMod lg={6}>
                                  <div className="time">{moment(message.timeUpdated).fromNow()}</div>
                                </ColMod>
                              </Row>
                            </Grid>
                          </Paper>
                        ))
                        : null
                    }

                  </div>
                </Drawer>
              </Row>
              <Row>
                <ColMod lg={6} id="orderCards">
                  {deliveryRouteOrders && deliveryRouteOrders.length > 0 ?
                    this.state.deliveryRouteOrders.map((order, index) => (
                      <OrderCard
                        key={order.id}
                        selected={selectedDeliveryRouteOrder.id === order.id}
                        order={order}
                        onClick={() => this.selectDeliveryRouteOrder(order)}
                      />
                    )) :
                    <Row>
                      <ColMod md={12}>
                        <p style={{ color: '#999', fontSize: 18 }}>There is no customer complaints on any order for the day.</p>
                      </ColMod>
                    </Row>}
                </ColMod>
                <ColMod lg={6}>
                  {Object.keys(this.state.selectedDeliveryRouteOrder).length ? (
                    <div>
                      <Typography
                        variant="display1"
                        gutterBottom
                        headlineMapping={{ display1: 'h6' }}
                      >
                        <span>Message Board</span>
                        <span style={{ float: 'right' }}>
                          <Refresh
                            color="secondary"
                            onClick={() =>
                              this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id)
                            }
                          />
                        </span>
                      </Typography>
                      <Typography variant="title" gutterBottom>
                        {this.state.selectedDeliveryRouteOrder.customerName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="secondary"
                        style={{
                          paddingBottom: '20px',
                          marginBottom: '20px',
                          borderBottom: '3px solid #eeeeee',
                        }}
                      >
                        <span>
                          Order #
                          {String(this.state.selectedDeliveryRouteOrder.customerOrder).padStart(6, '0')}
                        </span>
                        {
                          this.state.selectedDeliveryRouteOrder && this.state.selectedDeliveryRouteOrder.status === 'CUSTOMER_COMPLAINT' ?
                            <span style={{ marginLeft: '10%', marginRight: '10%' }}>
                              <span style={{ margin: '0px 5px' }}>Reason: </span>
                              <Select
                                value={complaintReason || 'Select Reason'}
                                onChange={e => this.updateStatus(e.target.value, 'complaintReason')}
                              >
                                {
                              complaintReasons.map((status, index) => (
                                <MenuItem key={index} value={status}>
                                  {reasonMapping[status]}
                                </MenuItem>
                              ))
                            }
                              </Select>
                            </span>
                          : null
                        }
                        <span style={{ float: 'right' }}>
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={!complaintReason || complaintReason === ''}
                            onClick={this.handleResolve}
                          > Resolve
                          </Button>
                        </span>
                      </Typography>
                      {this.state.deliveryOrderChats.map((chat, index) => (
                        <div key={index}>
                          {chat.userId === this.props.User.id ? (
                            <div
                              style={{
                                minWidth: '80%',
                                maxWidth: '80%',
                                width: '80%',
                                backgroundColor: '#FFF4F8',
                                padding: '10px',
                                marginTop: '10px',
                                marginLeft: '17%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignContent: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="secondary"
                                  style={{ maxWidth: '80%', minWidth: '80%' }}
                                >
                                  {chat.message}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ alignSelf: 'center' }}
                                >
                                  {moment(chat.timeCreated).format('hh:mm A')}
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                  minWidth: '80%',
                                  maxWidth: '80%',
                                  width: '80%',
                                  backgroundColor: '#F0F0F0',
                                  padding: '10px',
                                  marginTop: '10px',
                                }}
                            >
                              <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    justifyContent: 'space-between',
                                  }}
                              >
                                <div
                                  style={{
                                      flexDirection: 'column',
                                      maxWidth: '70%',
                                      minWidth: '70%',
                                    }}
                                >
                                  <Typography variant="body2" color="secondary">
                                    {chat.userName}
                                  </Typography>
                                  <Typography variant="body2">
                                    {chat.message}
                                  </Typography>
                                </div>
                                <Typography
                                  variant="body1"

                                >
                                  {moment(chat.timeCreated).format('lll')}
                                </Typography>
                              </div>
                            </div>
                            )}
                        </div>
                      ))}
                      <div>
                        <textarea
                          value={this.state.message}
                          style={{
                            resize: 'none',
                            width: '97%',
                            height: '75px',
                            marginTop: '10px',
                            padding: '10px',
                            borderRadius: '5px',
                            borderColor: '#bbbbbb',
                            fontSize: '100%',
                          }}
                          placeholder="Enter a message"
                          onChange={this.updateMessage}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.sendMessage();
                            }
                          }}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          style={{ float: 'right', marginTop: '10px' }}
                          onClick={this.sendMessage}
                        >
                          Post
                        </Button>
                      </div>
                      {
                    deliveryImageUrl &&
                    <DeliveryOrderImage
                      latitude={+latitude}
                      longitude={+longitude}
                      imageUrl={deliveryImageUrl}
                    />
                  }
                    </div>
                  ) : null}
                </ColMod>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

CustomerComplaints.propTypes = {
  User: PropTypes.instanceOf(Object),
  reasonMapping: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  deliveryStatuses: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  User: state.User,
  deliveryStatuses: state.Status.deliveryStatuses,
  reasonMapping: state.Status.complaintReasons,
});

export default connect(
  mapStateToProps,
  null,
)(CustomerComplaints);
