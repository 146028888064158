import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button, Chip } from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';


const columnData = [
  {
    id: 'userName', numeric: false, disablePadding: true, label: 'Username (Email ID)',
  },
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class UserGroupList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      userName: {},
      status: {}
    };
    const { data } = this.props;

    data.map((el, index) => {
      customComponents['userName'][index] = <Chip label={el.email} variant="outlined" />;
      customComponents['status'][index] = <Lozenge appearance={el.active ? "success" : "removed"}>{el.active ? 'Active' : 'Inactive'}</Lozenge>;
    })
    return customComponents;
  }

  getActions = (el) => (
    <React.Fragment>
      <Button color="primary" variant="outlined" onClick={() => { this.props.onEdit(el) }}>Edit</Button>
    </React.Fragment>
  )



  render() {
    const {
      data,
      rowsPerPage,
      page,
      count,
      isNoData,
      onChangePage,
      onChangeRowsPerPage
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={data}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        isNoData={isNoData}
        customComponents={this.getCustomComponents()}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

UserGroupList.propTypes = {
  data: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}

export default UserGroupList;
