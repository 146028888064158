import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Typography,
  Button,
  InputAdornment,
  Tab,
  Tabs
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import { Grid, Row } from 'react-flexbox-grid';
import apiCall, { productCatalogById, productsByCatalogIdPath, regionsPath, fetchAllZoneIds } from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import { ColMod } from '../../../components/lib';
import ProductCatalogProductList from '../components/ProductCatalogProductList';
import ProductForm from '../components/ProductForm';
import CatalogVendors from '../components/CatalogVendors';
import RegionSelector from '../../../components/Region/Selector';
import { debounce, isEmpty } from 'lodash';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: '30px',
  },
  nav: {
    flex: 3,
  },
  action: {
    flex: 1,
    textAlign: 'center',
    marginRight: '30px',
  },
};

var versionDict = {
  'zoneRequestId': 0,
  'productRequestId': 0,
  'catalogRequestId': 0,
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      newProdDialog: false,
      productData: [],
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      productCatalog: {},
      filters: {
        query: '',
      },
      selectedProduct: {},
      selectedRegion: null,
      zones: [],
      isLoading: false,
      count: 0,
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const { value } = this.state;
    this.fetchCatalogDetails();
    value == 1 && this.fetchAllProducts();
    this.fetchAllZones();
  }

  fetchAllZones = async () => {
    try {
      versionDict.zoneRequestId += 1;
      let prevRequestId = versionDict.zoneRequestId;
      const response = await apiCall.sendRequest('get', fetchAllZoneIds);
      const { data = {} } = response;
      const { _embedded: embedded } = data;
      if (versionDict.zoneRequestId == prevRequestId) {
        this.setState({
          zones: embedded['zones']
        });
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  onChangeSearch = (event) => {
    const query = event.target.value;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query,
      },
    }));
  }

  toggleProgressBar = (state) => {
    this.setState({
      isLoading: state,
    });
  }

  fetchAllProducts = debounce(async () => {
    this.toggleProgressBar(true);
    const { match: { params } } = this.props;
    const { filters, page, rowsPerPage } = this.state;
    filters.catalogId = params.id;
    filters.query = filters.query || ' ';
    try {
      versionDict.productRequestId += 1;
      let prevRequestId = versionDict.productRequestId;
      const response = await apiCall.sendRequest('get', productsByCatalogIdPath(filters, page, rowsPerPage));
      const { data: { data: { content = [], totalElements = 0 } = {} } = {} } = response;
      if (versionDict.productRequestId == prevRequestId) {
        this.setState({
          productData: content,
          count: totalElements,
        });
      }
    } catch (error) {
      this.setState({
        productData: [],
        count: 0
      });
    }
    this.toggleProgressBar(false);
  }, 50);

  fetchCatalogDetails = async () => {
    const { match: { params } } = this.props;
    versionDict.catalogRequestId += 1;
    let prevRequestId = versionDict.catalogRequestId;
    const response = await apiCall.sendRequest('get', productCatalogById(params.id));
    const { data } = response;
    if (data && versionDict.catalogRequestId == prevRequestId) {
      const selectedRegion = data.regions[0] ?
        {
          label: data.regions[0].name,
          value: data.regions[0].id
        } : null;
      this.setState({
        productCatalog: data,
        selectedRegion
      });
    }
  }

  handleRegionChange = (event) => {
    this.setState({ selectedCities: event.target.value });
  }

  handleInputChange = prop => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  handleChange = (event, value) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleTabChange = (event, value) => {
    const { productData = [] } = this.state;
    this.setState({ value });
    (isEmpty(productData) && value == 1) && this.fetchAllProducts();
  }

  editHandler = (x) => {
    this.setState(prevState => ({
      newProdDialog: true,
      adding: false,
      selectedProduct: x,
      productId: x.id,
      productVersionId: x.versionId,
      new_product_attrs: x.stagingProductAttributes.length ? x.stagingProductAttributes : [{ name: '', value: '' }],
      urls: [
        ...prevState.urls.map((url) => {
          const obj = x.stagingProductImages.find(imgs => imgs.position === url.position);
          return {
            ...url,
            imageUrl: obj ? obj.imageUrl : null,
            id: obj ? obj.id : null,
            versionId: obj ? obj.versionId : null,
          };
        }),
      ],
    }));
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  updateCalatog = (status) => {
    apiCall.sendRequest('patch', `/api/v1/product-catalogs/${this.state.productCatalog.id}`, { status })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  saveCatalog = () => {
    const { productCatalog = {} } = this.state;
    const { history = {} } = this.props;
    apiCall.sendRequest('patch', `/api/v1/product-catalogs/${this.state.productCatalog.id}`, productCatalog)
      .then((response) => {
        history.goBack();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  searchProducts = () => {
    this.fetchAllProducts();
  }

  onRegionChange = (region) => {
    const regions = [];
    if (region) regions.push(region);
    this.setState(prevState => {
      const { productCatalog } = prevState;
      productCatalog.regions = regions;

      return {
        productCatalog,
        selectedRegion: region
      }
    })
  }

  handlePopupClose = (popup) => {
    this.setState({
      [popup]: false,
      selectedProduct: {},
    });
  }

  handleClear = () => {
    this.setState({
      selectedProduct: {},
    });
    // this.fetchAllProducts();
  }

  renderForm = () => {
    const { productCatalog = {}, selectedRegion = {} } = this.state;
    return (
      <Grid style={{ marginLeft: 0 }}>
        <Row>
          <ColMod lg={4}>
            <TextField
              label="Name"
              fullWidth
              name="name"
              value={productCatalog.name}
              inputProps={{ readOnly: true }}
            />
          </ColMod>
          <ColMod lg={4}>
            <TextField
              label="Status"
              disabled
              style={{ border: 0 }}
              value={productCatalog.status}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={4}>
            <RegionSelector
              selected={selectedRegion}
              onSelect={this.onRegionChange}
              disabled
            />
          </ColMod>
        </Row>
      </Grid>
    );
  }

  handleChangePage = (page) => {
    this.setState({
      page
    }, () => { this.fetchAllProducts() });
  }

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({
      rowsPerPage
    }, () => { this.fetchAllProducts() });
  }

  renderProductTable = () => {
    const { productData, productCatalog, page, rowsPerPage, count } = this.state;
    return (
      <ProductCatalogProductList
        editHandler={this.editHandler}
        data={productData}
        productCatalogId={productCatalog.id}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
        onPageChange={this.handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
      />
    );
  }

  render() {
    const {
      value, productCatalog, newProdDialog, selectedProduct, zones, productData, isLoading,
    } = this.state;
    return (
      <div>
        <NavBar />
        <ProductForm
          open={newProdDialog}
          catalogId={productCatalog.id}
          product={selectedProduct}
          zones={zones}
          onRefresh={this.fetchAllProducts}
          onClear={this.handleClear}
          onClose={() => this.handlePopupClose('newProdDialog')}
          key={selectedProduct.id || ''}
        />
        <div style={styles.div}>
          <span style={styles.title}>
            {value === 0 ? 'Edit Product Catalog' : productCatalog.name}
          </span>
          <span style={styles.nav}>
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              fullWidth
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab label="Catalog Details" />
              <Tab label="Products" />
              <Tab label="Vendor Location" />
            </Tabs>
          </span>
          <span style={styles.action}>
            <Typography variant="title" style={{ display: 'inherit' }}>
              <Button color="primary" variant="outlined" onClick={() => { this.props.history.goBack(); }}>
                Back
              </Button>
            </Typography>
          </span>
        </div>
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />} tip="Loading...">
          <div style={{ margin: '4em' }}>
            {value === 0 &&
              <div>
                <Typography variant="title" style={{ marginLeft: '1.4em' }}>Catalog Details</Typography>
                {!isEmpty(productCatalog) && this.renderForm()}
              </div>}
            {value === 1 &&
              <div>
                <Typography variant="title" color="secondary" style={{ marginBottom: '25px' }}>
                  Products
                  <span style={{ float: 'right', fontSize: '0.em', fontWeight: '400' }}>
                    <Button onClick={() => { this.setState({ newProdDialog: true, adding: true }); }} variant="contained" size="small">
                      <AddIcon />
                      Add New Product
                    </Button>
                  </span>
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Quick Search"
                    fullWidth
                    name="query"
                    onChange={this.onChangeSearch}
                    onKeyPress={(event) => { if (event.key === 'Enter') { this.searchProducts(); } }}
                    disabled={isLoading}
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button variant="contained" color="secondary" style={{ marginTop: '5px' }} onClick={this.searchProducts} disabled={isLoading}>
                    Search
                  </Button>
                </div>
                {this.renderProductTable()}
              </div>
            }
            {
              value == 2 &&
              <CatalogVendors
                catalogId={productCatalog.id}
                productCatalog={productCatalog}
                isLoading={isLoading}
              />
            }
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  catalogStatuses: state.Status.catalogStatuses,
});

export default withRouter(connect(mapStateToProps, null)(App));

