import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  Grid
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RegionSelector from '../../../components/Region/Selector';
import withStyles from '@material-ui/core/styles/withStyles';
import WidgetSelector from './WidgetSelector';
import DisplayOptionsSelector from './DisplayOptionsSelector';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
}

function BannerSearch(props) {
  const {
    onChange,
    searchTerm,
    classes,
    onFilterChange,
    type,
    status,
    region,
    onRegionChange,
    onAddNew,
    clear,
    bannerTypes,
    onChangeWidget,
    bannerWidgets,
    selectedWidget,
    bannerStatus,
    selectedDisplayOption,
    bannerDisplayOptions,
    onDisplayOptionChange
  } = props;

  return (
    <div style={styles.container}>
      <Grid container spacing={16}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={16}>
            <Grid item xs={6} md={3}>
              <TextField
                color="secondary"
                value={searchTerm || ''}
                label="Quick search (by name)"
                onChange={onChange}
                fullWidth
                InputProps={{startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>)}}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption"> Region </Typography>
              <RegionSelector selected={region} onSelect={onRegionChange} name="region" />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption"> Type </Typography>
              <Select
                value={type || 'all'}
                fullWidth
                name="type"
                onChange={onFilterChange}
              >
                <MenuItem value="all">All</MenuItem>
                {Object.keys(bannerTypes || {}).map((type, index) => (<MenuItem key={index} value={type}>{bannerTypes[type]}</MenuItem>)) }
              </Select>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption"> Status </Typography>
              <Select
                value={status}
                fullWidth
                name="status"
                onChange={onFilterChange}
              >
                {Object.keys(bannerStatus || {}).map((status, index) => (<MenuItem key={index} value={status}>{bannerStatus[status]}</MenuItem>)) }
              </Select>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption"> Banner Set </Typography>
              <WidgetSelector options={bannerWidgets} selected={selectedWidget} onWidgetChange={onChangeWidget}/>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption"> Display Option </Typography>
              <DisplayOptionsSelector
                selected={selectedDisplayOption}
                options={bannerDisplayOptions || []}
                onDisplayOptionChange={onDisplayOptionChange}
                isMulti={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={32}>
            <Grid item xs={4} md={4} style={{ textAlign: "right" }}>
              <Button color="secondary" variant="outlined" onClick={clear}>Clear</Button>
            </Grid>
            <Grid item xs={8} md={8} style={{ textAlign: "right" }}>
              <Button color="secondary" variant="contained" onClick={onAddNew}><AddIcon /> Create Banner</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

BannerSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  status: PropTypes.string,
  region: PropTypes.object,
  onRegionChange: PropTypes.func.isRequired,
  onAddNew: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
}

BannerSearch.defaultProps = {
  searchTerm: '',
  type: '',
  status: '',
  regions: {}
}

const mapStateToProps = state => ({
  bannerTypes: state.Status.bannerTypes,
  bannerStatus: state.Status.bannerStatus,
  bannerDisplayOptions: state.Status.bannerScreenTypes,
});

export default connect(mapStateToProps, null)(withStyles(styles)(BannerSearch));