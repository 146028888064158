import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ToolTip from '@material-ui/core/Tooltip';
import capitalize from 'lodash/capitalize';
import Grid from '@material-ui/core/Grid';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import globalStyle from '../../../components/lib/style';
import AuditJsonSearch from './AuditJsonSearch';

const columnData = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'ID',
  },
  {
    id: 'timeCreated', numeric: false, disablePadding: true, label: 'When',
  },
  {
    id: 'date', numeric: false, disablePadding: true, label: 'Date',
  },
  {
    id: 'entity', numeric: false, disablePadding: true, label: 'Entity',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Action',
  },
  {
    id: 'modifiedBy', numeric: false, disablePadding: true, label: 'Modified By',
  },
  {
    id: 'entityKey', numeric: false, disablePadding: true, label: 'Entity Id',
  },
];

const getCustomComponents = (data) => {
  const customComponents = {
    action: {},
    timeCreated: {},
    date:{},
  };

  data && data.map((auditEntry, index) => {
    customComponents['timeCreated'][index] = <ToolTip title={moment(auditEntry.timeCreated).format(PRODUCT_TIME_FORMAT)}><span>{moment(auditEntry.timeCreated).fromNow()}</span></ToolTip>;
    customComponents['action'][index] = capitalize(auditEntry.action);
    customComponents['date'][index] = moment(auditEntry.timeCreated).format(PRODUCT_TIME_FORMAT);
   
  })
  return customComponents;
};

const styles = {
  paperRight: {
    ...globalStyle.paperRight,
  },
  auditTable: {
    ...globalStyle.paperLeft,
  },
  search: {
    marginLeft: '10px',
  },
  rightPane: {
    padding: '0px',
    overflow: 'hidden',
  },
};

function AuditList(props) {
  const {
    data,
    rowsPerPage,
    page,
    order,
    orderBy,
    count,
    handleChangeRowsPerPage,
    handleChangePage,
    onRequestSort,
    onClickRow,
    handleClickRow,
    selectedData,
    selectedindex,
    height,
    onCloseHandler,
  } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={selectedData.entityInfo ? 7 : 12 } style={{ ...styles.auditTable, height: `calc(100vh - ${height + `px`})` }}>
        <PaginatedTable
          columnData={columnData}
          data={data}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          onRequestSort={onRequestSort}
          order={order}
          orderBy={orderBy}
          customComponents={getCustomComponents(data)}
          onClickRow={onClickRow}
          handleClickRow={handleClickRow}
          selectedindex={selectedindex}
        />
      </Grid>
      {selectedData.entityInfo &&
        <Grid item xs={12} md={5} className="test">
          <div style={{ ...styles.paperRight, height: `calc(100vh - ${height + 'px'})`, ...styles.rightPane }}>
            <AuditJsonSearch data={JSON.parse(selectedData.entityInfo)} height={height} onCloseHandler={onCloseHandler}/>
          </div>
        </Grid>
      }
    </Grid>
  );
}

AuditList.propTypes = {
  data: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onClickRow: PropTypes.bool,
  handleClickRow: PropTypes.func,
  selectedData: PropTypes.object,
  selectedindex: PropTypes.number,
  height: PropTypes.number,
  onCloseHandler: PropTypes.func,
};

AuditList.defaultProps = {
  order: 'asc',
  orderBy: '',
  onClickRow: false,
  handleClickRow: () => {},
  height: 0,
  onCloseHandler: () => {},
};

export default AuditList;
