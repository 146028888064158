import React from 'react';
import { withRouter } from 'react-router-dom';
import apiCall, { getVendors } from "../api/NetworkHandler";

import MainComponent from '../components/ProductList';

const propData = [
  {
    v_id: 'VID6761', v_name: 'Nandini', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'ACTIVE',
  },
  {
    v_id: 'VID6762', v_name: 'Heritage', location: 'Bengaluru,Pune', c_name: 'Catalog Name', edit: true, status: 'PUBLISHED',
  },
  {
    v_id: 'VID6763', v_name: 'Amul', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'PUBLISHED',
  },
  {
    v_id: 'VID6764', v_name: 'Nestle', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'PENDING',
  },
  {
    v_id: 'VID6765', v_name: 'Vrindavan', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'PUBLISHED',
  },
  {
    v_id: 'VID6766', v_name: 'Thirumala', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'PENDING',
  },
  {
    v_id: 'VID6767', v_name: 'Dodla', location: 'Bengaluru', c_name: 'Catalog Name', edit: true, status: 'ACTIVE',
  },
];

class App extends React.Component {
  static propTypes ={
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      vendor: {},
      filters: {
        query: '',
      },
      showFilter: true,
    };
  }

  componentDidMount() {
    this.fetchAllDetails();
  }

  handleQueryChange = (event) => {
    const query = event.target.value;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query,
      },
    }));
  }

  onSearch = () => {
    this.fetchAllVendorCatalogs();
  }

  fetchAllVendorCatalogs = () => {
    const { match: { params } } = this.props;
    if (params.id !== 'product') {
      apiCall.sendRequest('get', `/api/v1/vendor-catalogs/search/findByVendorId?vendorId=${params.id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data && response.data._embedded && response.data._embedded['vendor-catalogs']) {
            this.setState({
              data: response.data._embedded['vendor-catalogs'],
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('get', `/api/v1/vendor-catalogs/search/findVendorCatalogs?query=${this.state.filters.query}`)
        .then((response) => {
          console.log(response.data);
          if (response.data && response.data._embedded && response.data._embedded['vendor-catalogs']) {
            this.setState({
              data: response.data._embedded['vendor-catalogs'],
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  fetchAllDetails = () => {
    const { match: { params } } = this.props;
    if (params.id !== 'product') {
      this.fetchAllVendorCatalogs();
      this.setState({
        showFilter: false,
      });
      apiCall.sendRequest('get', `/api/v1/vendors/${params.id}`)
        .then((response) => {
          if (response.data) {
            this.setState({
              vendor: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.fetchAllVendorCatalogs();
      apiCall.sendRequest('get', getVendors)
        .then((response) => {
          if (response.data) {
            this.setState({
              vendor: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div>
        <MainComponent data={this.state.data} vendor={this.state.vendor} showFilter={this.state.showFilter} searchQuery={this.state.filters.query} handleQueryChange={this.handleQueryChange} onSearch={this.onSearch} fetchAllDetails={this.fetchAllDetails} />
      </div>
    );
  }
}


export default withRouter(App);

