import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { Button, Checkbox, TextField } from '@material-ui/core';
import moment from 'moment';

const weekDays = [
  {
    label: 'M',
    value: 'MONDAY',
  },
  {
    label: 'T',
    value: 'TUESDAY',
  },
  {
    label: 'W',
    value: 'WEDNESDAY',
  },
  {
    label: 'T',
    value: 'THURSDAY',
  },
  {
    label: 'F',
    value: 'FRIDAY',
  },
  {
    label: 'S',
    value: 'SATURDAY',
  },
  {
    label: 'S',
    value: 'SUNDAY',
  },
];

class CreateSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planType: 0,
      numberOfDays: 0,
      selectedDays: [],
    };
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({
      planType: parseInt(value, 10),
    });
  };

  handleNoOfDays = (days) => {
    this.setState({
      numberOfDays: days,
    });
  };

  handleDaySelection = (day) => {
    let { selectedDays } = this.state;
    if (selectedDays.includes(day)) {
      selectedDays = selectedDays.filter(item => item !== day);
    } else {
      selectedDays.push(day);
    }
    this.setState({
      selectedDays,
    });
  };

  createSchedule = () => {
    const { planType, numberOfDays, selectedDays } = this.state;
    const { onDone } = this.props;
    const data = [
      {
        name: '',
        scheduleType: 'CUSTOM',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        paused: false,
        planItems: [],
        daysOfWeek: planType === 1 ? selectedDays : [],
        customFrequency: planType === 0 ? numberOfDays : '',
        status: 'ACTIVE',
      },
    ];
    onDone(data);
  };

  render() {
    const { numberOfDays, planType } = this.state;
    return (
      <div>
        <div>
          <div style={{ flexDirection: 'row' }}>
            <Radio
              checked={planType === 0}
              onChange={this.handleChange}
              value={0}
              name="planType"
            />
            <span>Every</span>
            <TextField
              value={numberOfDays}
              style={{
                width: 50,
                textAlign: 'center',
                marginLeft: 10,
                marginRight: 10,
              }}
              disabled={planType !== 0}
              onChange={e => this.handleNoOfDays(e.target.value)}
            />
            <span>Days</span>
          </div>
          <Radio checked={planType === 1} onChange={this.handleChange} value={1} name="planType" />
          <span>Days of Week</span>
          <div style={{ flexDirection: 'row' }}>
            {weekDays.map(day => (
              <span>
                <Checkbox
                  disabled={planType !== 1}
                  onChange={() => this.handleDaySelection(day.value)}
                  value={day.value}
                />
                <span>{day.label}</span>
              </span>
            ))}
          </div>
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={this.createSchedule}>
            Done
          </Button>
        </div>
      </div>
    );
  }
}

CreateSchedule.propTypes = {
  onDone: PropTypes.func,
};
export default CreateSchedule;
