import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducerCheck } from './validator';
import Status from './statusReducer';
import UOM from './uomReducer';
import User from './userReducer';
import information from './informationReducer';
import csReducer from './csReducer';
import currencyreducer from './currencyReducer';
import taxTypeReducer from './taxTypeReducer';

const allReducers = combineReducers({
  reducerCheck,
  form: formReducer,
  Status,
  UOM,
  User,
  information,
  csReducer,
  currency: currencyreducer,
  taxType: taxTypeReducer,
});

export default allReducers;
