import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-flexbox-grid/lib';

import globalStyle from './style';

function ColMod(props) {
  return (
    <Col {...props} className="col" style={ props.custompadding ? (props.bumphigh ? globalStyle.customCol3 : globalStyle.customCol) : (props.zerobottom ? globalStyle.customCol2 : globalStyle.col)}>
      {props.children}
    </Col>
  );
}

ColMod.propTypes = {
  children: PropTypes.node,
};

export { ColMod };
