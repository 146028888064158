import React, { Component } from 'react';
import {
  Typography,
} from '@material-ui/core';
import NavBar from '../../../components/NavBar';
import apiCall, { getAllUsers, getPaginatedUsers } from '../../../api/NetworkHandler';
import { debounce } from 'lodash';
import { Input as AntdInput, Spin } from 'antd';
import UserGroupList from '../components/UserGroupList';
import { LoadingOutlined } from '@ant-design/icons';
import UserGroupSelector from '../components/UserGroupSelector';

const { Search } = AntdInput;

const styles = {
  tableConatiner: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: window.innerHeight * 0.1,
    alignItems: 'center'
  },
  title: {
    marginLeft: '30px'
  },
  searchBar: {
    width: window.innerWidth * 0.5,
    marginLeft: '10%'
  }
};

var versionDict = {
  "userRequestId": 0,
  "filteredUserRequestId": 0
};


class UserGroupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filters: {
        query: '',
        role: ''
      },
      count: 0,
      page: 0,
      selectedUser: {},
      rowsPerPage: 10,
      isNoData: false,
      loading: false,
      openUserXPathSelector: false,
    };
  }

  componentDidMount() {
    const { page = 0, rowsPerPage = 10 } = this.state;
    this.fetchAllUserRoles(page, rowsPerPage);
  }

  onSearch = (searchText) => {
    if (searchText) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          query: searchText,
        },
      }), () => {
        this.fetchUsersByFilter();
      });
    } else {
      this.fetchAllUserRoles();
    }
  };


  fetchUsersByFilter = debounce(async () => {
    const { filters: { query, role } } = this.state;
    this.toggleLoading(true);
    try {
      versionDict.filteredUserRequestId += 1;
      let prevRequestId = versionDict.filteredUserRequestId;
      const response = await apiCall.sendRequest('get', getAllUsers(query, role));
      const { data: { _embedded: { users = [] } = {}, _links: { links = [] } = {} } = {} } = response;
      if (prevRequestId === versionDict.filteredUserRequestId) {
        this.setState({
          users,
          isNoData: false,
          links,
        });
      }
    } catch (err) {
      this.setState({
        users: [],
        isNoData: true,
        links: []
      });
    }
    this.toggleLoading(false);
  }, 750);

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState,
    });
  };

  fetchAllUserRoles = debounce(async (page, size) => {
    this.toggleLoading(true);
    try {
      versionDict.userRequestId += 1;
      let prevRequestId = versionDict.userRequestId;
      const response = await apiCall.sendRequest('get', getPaginatedUsers(page, size));
      const { data: { _embedded: { users = [] } = {}, page: { totalElements } = {} } = {} } = response;
      if (prevRequestId === versionDict.userRequestId) {
        this.setState({
          users,
          count: totalElements,
          isNoData: false,
        });
      }
    } catch (err) {
      this.setState({
        users: [],
        count: 0,
        isNoData: true
      });
    }
    this.toggleLoading(false);
  }, 500);


  renderSearchBar = () => {
    return (
      <div style={styles.searchContainer}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">User Groups</Typography>
        </div>
        <div style={styles.searchBar}>
          <Typography variant="caption">Search User</Typography>
          <Search
            placeholder="search by user"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={this.onSearch}
          />
        </div>
      </div>
    );
  };

  handleChangePage = (event, page) => {
    const { rowsPerPage = 10 } = this.state;
    this.setState({
      page
    }, () => { this.fetchAllUserRoles(page, rowsPerPage) });
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    const { page = 0 } = this.state;
    this.setState({
      rowsPerPage
    }, () => { this.fetchAllUserRoles(page, rowsPerPage) });
  };

  handleEditUserRoles = (selectedUser) => {
    this.setState({
      selectedUser,
      openUserXPathSelector: true,
    });
  };

  handleCloseUserXPathSelector = () => {
    this.setState({
      openUserXPathSelector: false,
    });
  };

  render() {
    const {
      users,
      count,
      page,
      rowsPerPage,
      isNoData,
      loading,
      openUserXPathSelector,
      selectedUser
    } = this.state;

    return (
      <div>
        <NavBar />
        {this.renderSearchBar()}
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..">
          <div style={styles.tableConatiner}>
            <div style={styles.paperLeft}>
              <UserGroupList
                data={users}
                rowsPerPage={rowsPerPage}
                page={page}
                count={count}
                onEdit={this.handleEditUserRoles}
                isNoData={isNoData}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Spin>
        {openUserXPathSelector &&
          <UserGroupSelector
            open={openUserXPathSelector}
            onClose={this.handleCloseUserXPathSelector}
            data={selectedUser}
          />
        }
      </div>
    );
  }
}


export default UserGroupContainer;
