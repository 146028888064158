import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall, { searchGstData, searchGstDataByQuery } from "../api/NetworkHandler";
import debounce from 'lodash/debounce';
import GstDataForm from './GstDataForm';

const styles = {
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    width: '70%',
    height: '85%',
    transition: 'width 2s',
    overflow: 'auto',
  }
}

var versionDict = {
  'gstRequestId': 0,
}

class BrandSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      gstData: [],
      editGstData: false,
      selectedData: {},
    };
  }

  // fetchGstData = debounce(async (searchTerm) => {
  //   const response = await apiCall.sendRequest('get', searchGstData(searchTerm));
  //   const { data: { _embedded: { gst } } } = response;
  //   this.setState({ gstData: gst });
  // }, 300)

  fetchGstData = debounce( async (searchTerm) => {
    let { gstRequestId = 0 } = versionDict;
    try {
      gstRequestId += 1;
      let prevRequestId = gstRequestId;
      const response = await apiCall.sendRequest('get', searchGstDataByQuery(searchTerm));
      const { data: { _embedded: { gst } } } = response;
      if (gstRequestId == prevRequestId) {
        this.setState({ gstData: gst });
      }
    } catch (error) {
      this.setState({ gstData: [] });
    }
  }, 750);

  handleInputChange = (event) => {
    const searchTerm = event.target.value;
    this.setState({ searchText: searchTerm });
    this.fetchGstData(searchTerm);
  }

  handleClearSearch = () => {
    this.setState({ searchText: '', gstData: [] });
  }

  onEditData = (data) => {
    this.setState({
      editGstData: true,
      selectedData: data,
    })
  }

  handleEditCancel = () => {
    this.setState({
      editGstData: false,
      selectedData: {},
    })
  }

  handleAddGstData = () => {
    this.setState({
      editGstData: true,
    })
  }

  onclose = () => {
    this.setState({
      gstData: [],
      searchText: '',
      editGstData: false,
    });
  }

  handleSave = () => {
    const { searchText } = this.state;
    this.fetchGstData(searchText);
    this.setState({
      editGstData: false,
      selectedData: {}
    });
  }

  render() {
    const { editGstData, selectedData } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.openSearchBox}
        onClose={() => { this.props.handleClose(); this.setState({ suggestions: [], searchText: '' }); }}
        onBackdropClick={() => { this.props.handleClose(); this.onclose(); }}
      >
        {!editGstData ? <div style={styles.container}>
          <Button style={{ float: 'right' }} onClick={() => { this.props.handleClose(); this.onclose() }} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={12}>
                <Row>
                  <ColMod lg={10}>
                    <Typography variant="title">
                      Search HSN
                    </Typography>
                  </ColMod>
                  <ColMod lg={2}>
                    <Button variant="contained" color="primary" onClick={this.handleAddGstData}>
                      Add
                    </Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={10}>
                    <TextField
                      value={this.state.searchText}
                      onChange={this.handleInputChange}
                      placeholder="Search for HSN Data"
                      helperText="Search HSN by category name or HSN code (ex. milk, non-milk, fish, meat etc.)"
                      fullWidth
                    />
                  </ColMod>
                  <ColMod lg={2}>
                    <Button variant="outlined" color="secondary" onClick={this.handleClearSearch}>
                      Clear
                    </Button>
                  </ColMod>
                </Row>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowY: 'auto', overflowX: 'auto', height: window.innerHeight / 2.35 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row" padding="none">Name</TableCell>
                            <TableCell component="th" scope="row" padding="none">HSN Code</TableCell>
                            <TableCell component="th" scope="row" padding="none">CGST</TableCell>
                            <TableCell component="th" scope="row" padding="none">SGST</TableCell>
                            {/* <TableCell>IGST</TableCell> */}
                            <TableCell component="th" scope="row" padding="none">Description</TableCell>
                            <TableCell component="th" scope="row" padding="none">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.gstData.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" padding="none">{data.name}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{data.hsnCode}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{data.cgst}</TableCell>
                              <TableCell component="th" scope="row" padding="none">{data.sgst}</TableCell>
                              {/* <TableCell>{data.igst}</TableCell> */}
                              <TableCell style={{ maxWidth: '300px' }} component="th" scope="row" padding="none">{data.description}</TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Button size="small" variant="contained" color="secondary" onClick={() => { this.props.selectGst(data); this.setState({ gstData: [], searchText: '' }); }}>Select</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button size="small" variant="outlined" color="primary" onClick={() => { this.onEditData(data) }}>Edit</Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div> :
          <div style={styles.container}>
            <GstDataForm
              gstData={selectedData}
              handleCancel={this.handleEditCancel}
              handleSave={this.handleSave}
            />
          </div>
        }
      </Modal>
    );
  }
}

BrandSelection.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectGst: PropTypes.func.isRequired,
};

export default BrandSelection;
