import React, { Component } from 'react';
import NavBar from '../../../../components/NavBar';
import FormComponent from '../../../Commons/components/FormComponent';

class BannerOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
    };
  }

  render() {
    const { banners = [] } = this.state;
    return (
      <div>
        <NavBar />
        <FormComponent />
      </div>
    );
  }
}

export default BannerOffer;
