import React, { Component } from 'react';
import NewCustomerComponent from '../Component/NewCustomerComponent';
import apiCall, { getNewCustomers } from '../../../api/NetworkHandler';

class CustomerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCustomersData: [],
      dateErrorText: '',
    };
  }

  fetchCustomers = async (filters, page = 0) => {
    this.setState({
      newCustomersData: [],
    });
    try {
      const response = await apiCall.sendRequest('get', getNewCustomers(filters, page));
      const { data: { data = {} } } = response;
      const { content = [], totalPages = 1, totalElements = 0 } = data;
      this.setState({ newCustomersData: content, totalPages, totalElements, dateErrorText: '' });
    }
    catch (error) {
      this.setState({
        newCustomersData: [],
        dateErrorText: error.response.data.message,
      });
    }
  };

  render() {
    const { newCustomersData = [], totalPages, totalElements, dateErrorText } = this.state;
    return (
      <div>
        <NewCustomerComponent
          data={newCustomersData}
          totalElements={totalElements}
          totalPages={totalPages}
          updateData={this.fetchCustomers}
          dateErrorText={dateErrorText}
          {...this.props}
        />
      </div>
    );
  }
}

export default CustomerContainer;
