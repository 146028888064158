import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core/';
import Colors from '../../../utils/Colors';

const styles = {
  mainContainer: { backgroundColor: Colors.grey[100], paddingBottom: 10 },
  title: {
    height: '2em',
    verticalAlign: 'top',
    paddingLeft: '2em',
    lineHeight: 'normal',
    paddingTop: '0.8em',
  },
};

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  componentWillMount() {
    const { location = {}, filters = {}, onChange = () => {} } = this.props;
    const { search } = location;
    const queryParams = this.getQueryParams(search);
    const modFilters = {
      ...filters,
      ...queryParams,
    };
    this.setState({
      filters: modFilters,
    });
    this.generateQueryString(modFilters);
  }

  getQueryParams = (queryString) => {
    let filters = {};
    if (queryString && queryString !== '') {
      let qString = queryString;
      if (queryString.includes('?') && queryString.indexOf('?') === 0) {
        qString = queryString.substring(1, queryString.length);
      }
      const params = qString.split('&');
      params.forEach((param) => {
        const [key, value] = param.split('=');
        filters = {
          ...filters,
          [key]: value,
        };
      });
    }
    return filters;
  };

  generateQueryString = (params) => {
    let qString = '';
    const { history = {}, location = {} } = this.props;
    Object.keys(params).forEach((key) => {
      if (params[key] && params[key] !== '') {
        qString += `${key}=${params[key]}&`;
      }
    });
    history.push({
      pathname: location.pathname,
      search: isEmpty(params) ? '' : `?${qString.substring(0, qString.length - 1)}`,
    });
    return qString.substring(0, qString.length - 1);
  };

  handleQueryParams = (name, value) => {
    const { onChange = () => {} } = this.props;
    let { filters = {} } = this.props;
    if (filters[name]) filters[name] = value;
    else filters = { ...filters, [name]: value };
    this.setState({ filters });
    onChange(filters, false);
  };

  handleSearch = () => {
    const { filters } = this.state;
    this.generateQueryString(filters);
  };

  handleClearFilter = (filters = {}) => {
    const { onChange = () => {} } = this.props;
    this.setState({
      filters,
    });
    this.generateQueryString(filters);
    onChange(filters);
  };

  handleClick = (buttonName, data = null) => {
    const { onButtonClick = () => {} } = this.props;
    switch (buttonName) {
      case 'search':
        this.handleSearch();
        onButtonClick(buttonName, data);
        break;
      case 'clear':
        this.handleClearFilter(data || {});
        break;
      default:
        onButtonClick(buttonName, data);
    }
  };

  render() {
    const { title, children } = this.props;
    const { filters } = this.state;
    return (
      <div style={styles.mainContainer}>
        <div style={styles.title}>
          <Typography variant="title" color="inherit">
            {title}
          </Typography>
        </div>
        {children && React.cloneElement(children, {
          filters,
          onFilterChange: this.handleQueryParams,
          onButtonClick: this.handleClick,
        })}
      </div>
    );
  }
}

SearchBar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  location: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  filters: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default withRouter(SearchBar);
