import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import BeenhereIcon from 'material-ui-icons/Beenhere';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import apiCall, { getWareHouse } from "../api/NetworkHandler";
import ProductSelection from './ProductSelection';
import StockAdjustmentSearchBar from './StockAdjustmentSearchBar';
import StockAdjustmentTable from './StockAdjustmentTable';
import NavBar from './NavBar.js';
import BASE_URL from "../api/config";

import { ColMod, ListMod, DisplayMod1, CodeStyleMod, DividerMod, PaperMod } from './lib';
import isEmpty from "lodash/isEmpty";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStockAdjustment: null,
      openStockAdjustment: false,
      postData: {
        active: true,
        productItemId: null,
        productName: '',
        sku: '',
        packaging: '',
        unitPrice: 0,
        category: '',
        unitMeasure: 0,
        tax: 0,
        discount: 0,
        quantity: 0,
        state: 'ADDED',
        reason: '',
        expiryDate: '',
        warehouse: '',
        destinationWarehouse: '',
        uom: '',
      },
      openSearchBox: false,
      selectedSuggestion: {},
      currProductItemId: null,
      warehouseList: [],
      warehouse: '',
      destinationWarehouse: '',
      data: [],
      error: {},
      links: {},
      searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchAllStockAdjustments();
    this.fetchAllWarehouse();
  }

	fetchAllStockAdjustments = () => {
	  apiCall.sendRequest('get', '/api/v1/stock-adjustment')
	    .then(({ data }) => {
	      this.setState({
	        data: data._embedded['stock-adjustment'],
	        links: data._links,
	        searchedResult: false,
	      });
	    })
	    .catch((err) => {
	      console.log(err);
	    });
	}

fetchPaginatedSA = (url) => {
  apiCall.sendRequest('get', url)
    .then(({ data }) => {
      this.setState({
        data: data._embedded['stock-adjustment'],
        links: data._links,
        searchedResult: false,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

fetchAllWarehouse = () => {
  apiCall.sendRequest('get', getWareHouse)
    .then((response) => {
      this.setState({
        warehouseList: response.data._embedded.warehouse,
      });
    });
}

openStockAdjustment = () => {
  console.log('Called');
  this.setState({
    openStockAdjustment: true,
  });
}

handleClose = () => {
  this.setState({
    openStockAdjustment: false,
    error: {},
  });
}

handleChange = (event) => {
  const {name} = event.target;
  const {value} = event.target;
  this.setState(prevState => ({
    postData: {
      ...prevState.postData,
      [name]: value,
    },
  }));
}

handleWarehouseLinkChange = (event) => {
  const {name} = event.target;
  const {value} = event.target;
  this.setState(prevState => ({
    postData: {
      ...prevState.postData,
      [name]: `${BASE_URL.BASE_URL}/api/v1/warehouse/${value}`,
    },
    [name]: value,
  }));
}

openSearch = () => {
  this.setState({
    openSearchBox: true,
  });
}

  handleCloseProductSelection = () => {
    this.setState({
      openSearchBox: false,
      searchText: '',
    });
  }

  selectProduct = (product) => {
    this.setState(prevState => ({
      postData: {
        ...prevState.postData,
        discount: product.discount,
        packaging: product.packaging,
        productItemId: product.id,
        productName: product.name,
        sku: product.sku,
        tax: product.tax,
        unitMeasure: product.unitMeasure,
        unitPrice: product.unitPrice,
        category: product.category,
        uom: product.uomId ? `${BASE_URL.BASE_URL}/api/v1/uoms/${product.uomId}` : '',
      },
    }));
    this.handleCloseProductSelection();
  }


  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    if (isEmpty(value)) {
      this.setError(key, true);
      return false;
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const stockAdjustment = {};
    stockAdjustment.productName = this.state.postData.productName;
    stockAdjustment.id = this.state.postData.warehouse;
    let isValid = true;

    const validateEntities = ['id', 'productName'];

    for (const property in stockAdjustment) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, stockAdjustment[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

createStockAdjustment = () => {
  if (!this.isFormValid()) {
    return;
  }
  const postData = { ...this.state.postData };
  if (postData.state !== 'MOVED') {
    delete postData.destinationWarehouse;
  }
  apiCall.sendRequest('post', '/api/v1/stock-adjustment', postData)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  this.handleClose();
}

onSearch = (filter) => {
  const queryParam = [];
  const query = filter.query ? `query=${filter.query}` : 'query=';
  if (query) { queryParam.push(query); }
  const warehouseId = filter.warehouseId ? `id=${filter.warehouseId}` : '';
  if (warehouseId) { queryParam.push(warehouseId); }
  const startDate = filter.startDate ? `startDate=${filter.startDate}` : '';
  if (startDate) { queryParam.push(startDate); }
  const endDate = filter.endDate ? `endDate=${filter.endDate}` : '';
  if (endDate) { queryParam.push(endDate); }
  const status = filter.status ? `status=${filter.status}` : '';
  if (status) { queryParam.push(status); }
  let url = `/api/v1/stock-adjustment/search/findStockAdjustments?${queryParam.join('&')}`;

  if (queryParam.length === 0) {
    url = '/api/v1/stock-adjustment';
  }
  apiCall.sendRequest('get', url)
    .then((res) => {
      console.log(res.data);
      this.setState({
        data: res.data._embedded['stock-adjustment'],
        links: res.data._links,
        searchedResult: true,
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

selectStockAdjustment = (data) => {
  this.setState({
    selectedStockAdjustment: data,
  });
}

render() {
  return (
    <div>
      <NavBar />
      <StockAdjustmentSearchBar onSearch={this.onSearch} openStockAdjustment={this.openStockAdjustment} />
      <div style={styles.div}>
        <div style={styles.paperLeft}>
          <StockAdjustmentTable data={this.state.data} links={this.state.links} fetchPaginatedSA={this.fetchPaginatedSA} searchedResult={this.state.searchedResult} selectStockAdjustment={this.selectStockAdjustment} />
        </div>
        <div style={styles.paperRight}>
          { this.state.selectedStockAdjustment
              ?
                <div>
                  <Typography variant="body2" color="secondary">
                  Stock Adjustment Details
                  </Typography>
                  <Typography variant="body1" color="secondary" style={{ marginTop: '10px', borderBottom: 'solid 1px #e0e0e0', paddingBottom: '10px' }}>
                  Reason
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {this.state.selectedStockAdjustment.reason}
                  </Typography>

                </div>
              : <div />}
        </div>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.openStockAdjustment}
        onClose={this.handleClose}
      >
        <div style={{
background: 'white',
width: '70%',
height: '60%',
position: 'relative',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
}}
        >
          <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <Typography variant="body2" color="secondary">
											Create Stock Adjustment
                  </Typography>
                </Row>
                <Row>
                  <Typography variant="caption" color="error">
                    * Required
                  </Typography>
                </Row>
                <Row>
                  <ColMod lg={6} style={{ padding: 0 }}>
                    <TextField
                      value={this.state.postData.productName}
                      required
                      error={this.state.error.productName}
                      onChange={() => { /* this.handleChange.bind(this, n, 'productName') */ }}
                      InputProps={{
													endAdornment: (
  <InputAdornment position="start" onClick={() => { this.openSearch(); }}>
    <Search />
  </InputAdornment>
													),
												}}
                      fullWidth
                    />
                  </ColMod>
                  <ColMod lg={6}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-warehouse">Warehouse</InputLabel>
                    <MaterialSelect
                      value={this.state.warehouse}
                      required
                      error={this.state.error.id}
                      onChange={this.handleWarehouseLinkChange}
                      inputProps={{
													name: 'warehouse',
													id: 'select-warehouse',
												}}
                      fullWidth
                    >
                      {this.state.warehouseList.map((warehouse, index) => (
                        <MenuItem
                          key={index}
                          value={warehouse.id}
                        >
                          {warehouse.name}
                        </MenuItem>
												))}
                    </MaterialSelect>
                  </ColMod>
                </Row>

                <Row>
                  <ColMod lg={this.state.postData.state !== 'MOVED' ? 12 : 6}>
                    <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-state">State</InputLabel>
                    <MaterialSelect
                      value={this.state.postData.state}
                      onChange={this.handleChange}
                      inputProps={{
													name: 'state',
													id: 'select-state',
												}}
                      fullWidth
                    >
                      {Object.keys(this.props.AdjustmentStates).map((status, index) => (
                        <MenuItem
                          key={index}
                          value={status}
                        >
                          {this.props.AdjustmentStates[status]}
                        </MenuItem>
												))}
                    </MaterialSelect>
                  </ColMod>
                  {this.state.postData.state === 'MOVED' ?
                    <ColMod lg={6}>
                      <InputLabel style={{ fontSize: '0.7em' }} htmlFor="select-destination">Destination (if moved only)</InputLabel>
                      <MaterialSelect
                        value={this.state.destinationWarehouse}
                        onChange={this.handleWarehouseLinkChange}
                        inputProps={{
														name: 'destinationWarehouse',
														id: 'select-destination',
													}}
                        fullWidth
                      >
                        {this.state.warehouseList.map((warehouse, index) => (
                          <MenuItem
                            key={index}
                            value={warehouse.id}
                          >
                            {warehouse.name}
                          </MenuItem>
													))}
                      </MaterialSelect>
                    </ColMod>
											: null}
                </Row>

                <Row>
                  <ColMod lg={6}>
                    <TextField
                      label="Quantity"
                      fullWidth
                      name="quantity"
                      value={this.state.postData.quantity}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                  <ColMod lg={6}>
                    <TextField
                      label="Reason"
                      fullWidth
                      name="reason"
                      value={this.state.postData.reason}
                      onChange={this.handleChange}
                    />
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
          <div style={{ float: 'right', paddingRight: '2em' }}>
            <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
							Cancel
            </Button>
            <Button
              onClick={() => {
								this.createStockAdjustment();
							}}
              variant="contained"
              color="secondary"
            >
							Create
            </Button>
          </div>
        </div>
      </Modal>
      <ProductSelection showOfferPopup={this.state.openSearchBox} handleClose={this.handleCloseProductSelection} selectProduct={this.selectProduct} />
    </div>
	  );
}
}

const mapStateToProps = state => ({
  AdjustmentStates: state.Status.adjustmentStates,
});

export default connect(mapStateToProps, null)(Dashboard);
