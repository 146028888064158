import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, FormHelperText, Select, MenuItem, Button } from '@material-ui/core';
import moment from 'moment';
import API, { routesByHubIdPath } from '../../../api/NetworkHandler';
import HubSelector from '../../../components/Hub/Selector';

const pageTypes = ['GENERAL', 'CC_EXECUTIVE', 'HUB_ROUTE'];

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
};
class CustomerComplaintSearch extends Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      selectedHub: null,
    };
  }

  onClear =() => {
    const { onButtonClick } = this.props;
    this.setState({ filters: {}, selectedHub: null });
    onButtonClick('clear');
  }

  isValidDateRange = (value, key) => {
    const { filters: { fromDate, toDate } } = this.state;
    const isEmpty = value === '';
    const isNotFutureDate = moment().isSameOrAfter(moment(value));
    const endDateValidation = (key === 'toDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate))));
    const fromDateValidation = (key === 'fromDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate))));
    return isEmpty || (isNotFutureDate && (endDateValidation || fromDateValidation));
  }

  handleQueryChange = (value, key) => {
    let { filters, selectedHub } = this.state;
    const { onFilterChange } = this.props;
    const isNotDateField = (key !== 'toDate') && (key !== 'fromDate');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      filters = {
        ...filters,
        [key]: value,
      };
      if (key === 'warehouseId') {
        filters.hubId = '';
        selectedHub = null;
        filters.warehouseId = null;
      }
      if (key === 'hubId') {
        this.fetchRoutes(value);
      }
      onFilterChange(key, value);
    }
    this.setState({
      filters,
      selectedHub
    },()=>{
      if(key == 'warehouseId'){
        this.setState({
          filters: {
            ...filters,
            [key]: value,
          }
        })
      }
    });
  }

  fetchRoutes = async (hubId) => {
    const response = await API.sendRequest('get', routesByHubIdPath(hubId));
    const { data: { _embedded = {} } = {} } = response;
    this.setState({ routes: _embedded['static-routes'] });
  }

  onHubChange = (hub) => {
    this.setState({ 
      selectedHub: hub
     }, () => { 
       this.handleQueryChange(hub ? hub.id : null, 'hubId');
      });
  }

  render() {
    const { filters = {}, selectedHub, routes = [] } = this.state;
    const {
      warehouses, onButtonClick, pageUser, deliveryStatuses,
    } = this.props;
    const {
      fromDate,
      toDate,
      orderId,
      customerId,
      warehouseId,
      routeName,
      status,
    } = filters;
    return (
      <div style={styles.container}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  type="date"
                  label="Start Date"
                  name="fromDate"
                  fullWidth
                  value={fromDate || ''}
                  onChange={e => this.handleQueryChange(e.target.value, 'fromDate')}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  type="date"
                  fullWidth
                  label="End Date"
                  name="toDate"
                  value={toDate || ''}
                  onChange={e => this.handleQueryChange(e.target.value, 'toDate')}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              {
                pageUser === pageTypes[1] &&
                <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Status</FormHelperText>
                  <Select
                    name="status"
                    fullWidth
                    onChange={e => this.handleQueryChange(e.target.value, 'status')}
                    value={status || ''}
                  >
                    {Object.keys(deliveryStatuses).map(costatus => (
                      <MenuItem value={costatus}>{deliveryStatuses[costatus]}</MenuItem>
                    ))}
                  </Select>
                </Grid>}
              <Grid item xs={6} md={3}>
                <TextField
                  type="number"
                  fullWidth
                  label="Order Number"
                  name="orderId"
                  value={orderId || ''}
                  onChange={e => this.handleQueryChange(e.target.value, 'orderId')}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  type="number"
                  name="customerId"
                  fullWidth
                  label="Customer Id"
                  value={customerId || ''}
                  onChange={e => this.handleQueryChange(e.target.value, 'customerId')}
                  InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              {
                pageUser === pageTypes[2] &&
                <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Warehouse</FormHelperText>
                  <Select
                    name="warehouseId"
                    fullWidth
                    value={warehouseId}
                    onChange={e => this.handleQueryChange(e.target.value, 'warehouseId')}
                  >
                    {
                    warehouses.map(warehouse => (
                      <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.name}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>}
              {
                pageUser === pageTypes[2] &&
                <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Hub</FormHelperText>
                  {warehouseId && <HubSelector
                    warehouseId={warehouseId}
                    onSelect={this.onHubChange}
                    selected={selectedHub}
                  />}
                  {!warehouseId && 
                  <TextField
                    value="Select warehouse first"
                    disabled
                  />}
                </Grid>}
              {
                pageUser === pageTypes[2] &&
                <Grid item xs={6} md={3}>
                  <FormHelperText style={{ marginTop: 4 }}>Route</FormHelperText>
                  <Select
                    name="routeName"
                    fullWidth
                    value={routeName}
                    onChange={e => this.handleQueryChange(e.target.value, 'routeName')}
                  >
                    {
                    routes.map(route => (
                      <MenuItem key={route.id} value={route.name}>{route.name}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('search')}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button color="secondary" variant="outlined" onClick={() => this.onClear('clear')}>
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CustomerComplaintSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  warehouses: PropTypes.instanceOf(Array),
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  pageUser: PropTypes.string,
  deliveryStatuses: PropTypes.instanceOf(Object),
};

export default CustomerComplaintSearch;
