export default {
  ongoing: {
    marginBottom: '32px',
  },
  upcoming: {
    marginBottom: '32px',
  },
  title: {
    fontSize: '1em',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '20px',
  },
  load: {
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0 0',
  },
  btn: {
    width: '250px',
  },
  card: {
    display: 'flex',
    borderRadius: '0',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  content: {
    display: 'flex',
    minWidth: '90%',
    padding: '16px',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  mainInfo: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mainInfoItem: {
    flexGrow: 1,
    minWidth: '180px',
  },
};
