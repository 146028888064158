import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import apiCall, { generateExternalReferralCode, getCouponsByCouponType } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import ProgressBar from '../../../components/States/ProgressBar';
import utils from '../../../utils/queryBuilder';
import Select from '@atlaskit/select';


const canView = utils.isCurrentUserAuthorizedToView;

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
};

var versionDict = {
  "couponRequestId": 0
};

class CreateReferral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postReferral: {
        name: '',
        referralCode: '',
        validFrom: moment().format("YYYY-MM-DD"),
        validTill: moment().add(1, "days").format("YYYY-MM-DD"),
        couponType: 'OTHER',
        couponAmount: '',
        couponId: null
      },
      errorMessage: '',
      isLoading: false,
      invalidName: false,
      invalidReferralCode: false,
      invalidCouponAmount: false,
      disableButton: false,
      coupons: [],
      selectedCoupon: null
    }
  }

  componentDidMount() {
    this.fetchDefaultCoupons();
    const { selectedReferral } = this.props;
    if (!isEmpty(selectedReferral)) {
      this.setState({
        postReferral: selectedReferral,
      });
    }
  }

  fetchDefaultCoupons = async () => {
    const { selectedReferral } = this.props;
    try {
      versionDict.couponRequestId += 1;
      let prevRequestId = versionDict.couponRequestId;
      const response = await apiCall.sendRequest('get', getCouponsByCouponType('REFERRAL_CODE'));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId === versionDict.couponRequestId) {
        const couponObj = data.find(el => el.id == selectedReferral.couponId);
        this.setState({
          coupons: data.map(el => {return {label: el.couponCode, value: el.id}}),
          selectedCoupon: !isEmpty(selectedReferral) ? {label: couponObj.couponCode, value: couponObj.id} : null
        });
      }
    } catch (err) {
      this.setState({
        coupons: []
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { postReferral } = this.state;
    const { validFrom } = postReferral;
    if (name === "validTill" && moment(value).isBefore(validFrom, "days")) {
      return;
    }
    else if (name === "couponAmount" && value < 0) return;
    else if (name === "referralCode" && value.length > 10) return;
    this.setState({
      postReferral: {
        ...postReferral,
        [name]: value,
      }
    });
  };

  handleSelectCoupon = (selectedCoupon) => {
    this.setState({
      selectedCoupon,
    });
  };

  handleChangeCouponType = (event) => {
    const { checked } = event.target;
    const { postReferral } = this.state;
    const { couponAmount } = postReferral;
    this.setState({
      postReferral: {
        ...postReferral,
        couponType: checked ? "CASHBACK" : "OTHER",
        couponAmount: checked ? couponAmount : null,
      }
    });
  };

  validateForm = () => {
    let isFormValid = true;
    const { postReferral } = this.state;
    const { name, referralCode, couponType, couponAmount } = postReferral;

    if (!name) {
      isFormValid = false;
      this.setState({ invalidName: true });
    } else {
      this.setState({ invalidName: false });
    }

    if (!referralCode) {
      isFormValid = false;
      this.setState({ invalidReferralCode: true });
    } else {
      this.setState({ invalidReferralCode: false });
    }

    if (couponType === "CASHBACK" && (!couponAmount || couponAmount == 0)) {
      isFormValid = false;
      this.setState({ invalidCouponAmount: true });
    } else {
      this.setState({ invalidCouponAmount: false });
    }

    return isFormValid;
  }

  toggleProgressBar = (loadingState, buttonState) => {
    this.setState({
      isLoading: loadingState,
      disableButton: buttonState
    });
  };

  onSave = debounce(async () => {
    if (!this.validateForm()) {
      return;
    }

    this.toggleProgressBar(true, true);
    const { postReferral, selectedCoupon } = this.state;
    const { onClose, onRefresh } = this.props;
    postReferral.validFrom = moment(postReferral.validFrom).format("YYYY-MM-DD 00:00:00");
    postReferral.validTill = moment(postReferral.validTill).format("YYYY-MM-DD 23:59:59");
    if (!isEmpty(selectedCoupon)) {
      postReferral.couponId = selectedCoupon.value;
    }

    try {
      await apiCall.sendRequest("post", generateExternalReferralCode, postReferral);
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
      onClose();
      onRefresh();
    }
    this.toggleProgressBar(false, false)
  }, 500);

  render() {
    const { classes, onClose } = this.props;
    const {
      postReferral,
      errorMessage,
      isLoading,
      invalidName,
      invalidReferralCode,
      disableButton,
      coupons,
      selectedCoupon
    } = this.state;
    const { id, name, referralCode, validFrom, validTill } = postReferral;

    return (
      <Grid className={classes.container} fluid>
        <ProgressBar isLoading={isLoading} />
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{postReferral.id ? "Edit Referral" : "Add New Referral"}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary" variant="outlined" >Cancel</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" onClick={this.onSave} disabled={disableButton}>{id ? "Save" : "Add"}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> * required fields </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            <SectionMessage appearance="error">{errorMessage}</SectionMessage>
          </ColMod>
        </Row>}
        <Row md={12}>
          <ColMod custompadding="true" md={12}>
            <TextField
              type="text"
              label="Referral Name"
              name="name"
              value={name || ''}
              onChange={this.handleChange}
              required
              fullWidth
              InputLabelProps={{ shrink: !!name }}
              autoComplete="off"
              error={invalidName}
              helperText={invalidName && "* required"}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod custompadding="true" md={6}>
            <TextField
              type="text"
              label="Referral Code"
              name="referralCode"
              value={referralCode || ''}
              onChange={this.handleChange}
              required
              fullWidth
              InputLabelProps={{ shrink: !!referralCode }}
              autoComplete="off"
              error={invalidReferralCode}
              helperText={invalidReferralCode ? "* required" : "Code must be between 10 character"}
              disabled={!!id}
            />
          </ColMod>
          <ColMod custompadding="true" md={6}>
            <Typography variant="caption"> Default Coupon </Typography>
            <Select
              className="checkbox-select"
              classNamePrefix="select"
              options={coupons}
              placeholder="Choose a coupon"
              onChange={this.handleSelectCoupon}
              value={selectedCoupon}
              isDisabled={!!id}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod custompadding="true" md={6}>
            <TextField
              type="date"
              label="Start Date"
              name="validFrom"
              value={moment(validFrom).format("YYYY-MM-DD")}
              onChange={this.handleChange}
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
          <ColMod custompadding="true" md={6}>
            <TextField
              type="date"
              label="End Date"
              name="validTill"
              value={moment(validTill).format("YYYY-MM-DD")}
              onChange={this.handleChange}
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

export default withStyles(styles)(CreateReferral);
