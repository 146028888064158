import axios from 'axios';
import Cookies from 'universal-cookie';
import CONFIG from './config';
import Toast from '../utils/Toast';
import { SEARCH_AND_SELECT_OPTIONS_LIMIT } from '../constants';
import { logoutUser } from '../actions';

const api = axios.create({
  baseURL: `${CONFIG.BASE_URL}`,
});

const generateUrlWithQueryParams = (url, params) => {
  let urlPath = url;
  Object.keys(params).forEach((key) => {
    if (params[key] && params[key] !== '') {
      urlPath = `${urlPath + key}=${encodeURIComponent(params[key])}&`;
    }
  });
  return urlPath;
};

// Setting up base URL
const APP_URL = `${CONFIG.APP_URL}/api/v1`;
const APP_URL_V2 = `${CONFIG.APP_URL}/api/v2`;
const SCM_URL = CONFIG.BASE_URL;


/** PATHS */
export const alldeliveryOrderChats =
  ({ fromDate: startDate, toDate: endDate, ...filters }, page = 0, size = 20) => {
    const url = `api/v1/delivery-routes/delivery-chats/by-date?page=${page}&size=${size}&`;
    return generateUrlWithQueryParams(url, { startDate, endDate, ...filters });
  };
export const productItemsPath = 'api/v1/product-items';
export const getTaxType = '/api/v1/application-configs/app/scmapp/taxType';
export const getMaxExtraAllowed = '/api/v1/application-configs/app/scmapp/maxExtraAllowed';
export const getPincodeAbsent = '/api/v1/application-configs/app/scmapp/pincodeAbsent';
export const getIsPackaged = '/api/v1/application-configs/app/scmapp/isPackaged';
export const getAppConfig = '/api/v1/application-configs/app/scmapp';
export const getCustomerAppConfig = '/api/v1/application-configs/app/customerapp';
export const getOrderAPIStatus = '/api/v1/application-configs/order-api-status';
export const updateOrderAPIStatus = '/api/v1/application-configs/update-order-api-status';
export const getValidPincodesPrefixes = '/api/v1/application-configs/get-valid-pincodes';
export const updatePincodePrefix = '/api/v1/application-configs/update-valid-pincodes';
export const productItemPath = id => `/api/v1/product-items/${id}`;
export const findHubPath = name => `/api/v1/hub/search/findHub?query=${name}`;
export const getHubsPath = wareehouseId => `/api/v1/warehouses/${wareehouseId}/hubs`;
export const customerAddressesPath = customerId => `/api/v1/customer-address/get-addresses?customerId=${customerId}`;
export const getDeletedCustomerAddresses = customerId => `/api/v1/customer-address/get-deleted-addresses?customerId=${customerId}`;
export const setDefaultAddress = (customerId, addressId) => `/api/v1/customers/${customerId}/customer-address/${addressId}/set-default`;
export const getPlanItemsPath = (customerId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/plan-items/by-category`;
export const searchProductsPath = searchText => `/api/v1/product-items/search/by-name?name=${searchText}`;
export const searchProductsByRegionId = (searchText, regionId) => `/api/v1/products/by-location/${regionId}?search=${searchText}&cached=false`;
export const searchVendorsProductsPath = searchText => `/api/v1/vendor-product-items/search/by-name?name=${searchText}`;
export const savePlansPath = (customerId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/confirm-subscriptions`;
export const getCategoryPath = productName => `/api/v1/products/getCategory?productName=${productName}`;
export const schedulesPath = customerId => `${APP_URL}/customers/${customerId}/schedules`;
export const customerOrderByFiltersPath = queryString => `/api/v1/customer-orders/search/findCOs?${queryString}`;
export const customerOrdersPath = '/api/v1/customer-orders?sort=id,desc';
export const findCustomerOrdersByFilters = (filters, page = 0, size = 25) => {
  const url = `/api/v1/customer-orders/co-with-warehouse-hub-details?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const setCustomerOrderPackageStatus = customerOrderId => `/api/v1/customers-order-metadata/${customerOrderId}`;
export const allCustomerProfile = `${APP_URL}/customers/customer-profile`;
export const customerProfileById = customerId => `${APP_URL}/customers/${customerId}/profile`;
export const customerInvoiceById = (customerId, page = 0) => `${SCM_URL}/api/v1/invoice/customer/${customerId}/monthly-invoice?page=${page}&maxLimit=6`;
export const customerProfileByFilters = (query, fetchAll = false) => {
  return fetchAll ?
    `${APP_URL}/customers/customer-profile/search/by-filters?query=${query}&size=100` :
    `${APP_URL}/customers/customer-profile/search/by-filters?query=${query}`;
};
export const allProductsPath = queryString => `api/v1/products?${queryString}`;
export const allProductItemsPath = queryString => `api/v1/product-items/allProductItems?query=${queryString}`;
export const allVendorItemsPath = queryString => `/api/v1/vendors/search/by-name?name=${queryString}`;
export const fetchVendorLocationsByQuery = (queryString, warehouseId = '') => `api/v1/vendors/vendor-locations/by-name?query=${queryString}&warehouseId=${warehouseId}`;
export const routesByHubIdPath = (hubId, page = 0, size = 100) => `/api/v1/static-routes/search/findByHubId?hubId=${hubId}&page=${page}&size=${size}`;
export const notificationsTypesPath = '/api/v1/notifications/get-notification-types';
export const notificationsTemplatesPath = '/api/v1/notifications/get-notification-templates';
export const sendSmsUrl = '/api/v1/notifications/send-notification';
export const customersPath = (page, size) => `${APP_URL}/customers?page=${page}&size=${size}`;
export const getCustomerIdPath = customerId => `/api/v1/customer-address/get-addresses?customerId=${customerId}`;
export const getAddressesByQuerypath = query => `/api/v1/customer-address/search/findCustomerAddresses?query=${query}`;
export const getAddressesStatusURL = status => `/api/v1/customer-address/by-status?status=${status}`;
export const getDeliverableAddressStatus = customerId => `/api/v1/customer-address/customers/${customerId}/is-deliverable-info`;
export const customerPlansForAddressPath = (customerId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/plan-items/by-category`;
export const editPlanItem = (scheduleId, customerId, planItemId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/plan-items/${planItemId}`;
export const editPlanItem1 = (customerId, planItemId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/plan-items/${planItemId}`;
export const customerAccountPath = customerId => `${APP_URL}/customers/${customerId}/account`;
export const productSearchPath = (customerId, query) => `/api/v1/product-items/allProductItemsForRegion?customerId=${customerId}&query=${query}`;
export const getFetchAllBuildings = (query, locality = '') => `/api/v1/buildings/search/findBuildingsByLocalityAndPinCode?name=${query}&locality=${locality}&size=20`;
export const getBuildingsWithFilter = (query = '', city = '', locality = '') => `/api/v1/buildings/search/findBuildingsByLocalityAndPinCode?name=${query}&city=${city}&locality=${locality}&size=20`;
export const staticRoute = (id, addressId) => `/api/v1/static-routes/${id}/${addressId}`;
export const staticRoutesPath = '/api/v1/static-routes?size=1000';
export const createStaticRoutes = '/api/v1/static-routes';
export const editSubmitAddress = customerId => `${APP_URL}/customers/${customerId}/profile`;
export const elseEditSubmitAddress = '/api/v1/customers/customer-profile';
export const searchStaticRoutes = text => `/api/v1/static-routes/search/findStaticRoutes?query=${text}`;
export const pageableStaticRoutesByFilter = (warehouseId = '', query = '', page = 0, size = 10) => `/api/v1/static-routes/by-warehouse?warehouseId=${warehouseId}&query=${query}&page=${page}&size=${size}`;
export const searchStaticRoutesByCustomerAddressId = (addressId, text, autoRouting) => `/api/v1/static-routes/by-customer-address/${addressId}?query=${text}&enableAutomaticRouting=${autoRouting}`;
export const getTransactionHistory = customerId => `${APP_URL}/customers/${customerId}/payments?size=1000`;
export const getSubmitAddressPath = id => `/api/v1/customer-address/${id}`;
export const getAddressWithCustomerIdPath = '/api/v1/customer-address';
export const getAllBrandsPath = text => `/api/v1/brands/search/findBrands?query=${text}`;
export const allBrandsPath = 'api/v1/brands';
export const allBrandsPathImages = '/api/v1/images';
export const getBuildingPath = '/api/v1/buildings';
export const getCreateNewBuildingPath = id => `/api/v1/buildings/${id}`;
export const findProductCatalogsPath = (queryText = '', page = 0, size = 10, catalogStatus = '', isActive = true) => `/api/v1/product-catalogs/search/findProductCatalogs?query=${queryText}&page=${page}&size=${size}&catalogStatus=${catalogStatus}&isActive=${isActive}&sort=name`;
export const getProductCatalogByFilter = (filters, page = 0, size = 10) => {
  const url = `/api/v1/product-catalogs/search/findProductCatalogs?page=${page}&size=${size}&sort=name&`;
  return generateUrlWithQueryParams(url, filters);
};
export const productCatalogsPath = '/api/v1/product-catalogs';
export const productCatalogById = id => `/api/v1/product-catalogs/${id}`;
export const getProductItemList = query => `/api/v1/vendor-products/${query}/vendor-product-items`;
export const updateCatalogPath = query => `/api/v1/vendor-catalogs/${query}`;
export const vendorCatalogPath = (query = '') => `api/v1/vendor-catalogs/search/findVendorCatalogs?query=${query}`;
export const findPackaging = (query = '') => `/api/v1/packaging/search/findByName?query=${query}`;
export const postProductItemData = '/api/v1/vendor-product-items';
export const CatalogProductPath = text => `/api/v1/vendor-product-items/${text}`;
// export const findPackaging = (query = '') => `/api/v1/packaging/search/findByName?query=${query}`;
export const vendorProductsPath = (text, productId) => `/api/v1/vendor-products/${text || productId}`;
export const uomCatalogProductsPath = uomId => `/api/v1/uoms/${uomId}`;
export const postCategoryPath = text => `/api/v1/categories/${text}`;
export const getFetchAllCategories = query => `/api/v1/categories/search/findCategories?query=${query}`;
export const allCategoriesPath = '/api/v1/categories';
export const getAllParentCategories = '/api/v1/categories/search/findParentCategories';
export const getCategoriesByParentId = (parentId) => `/api/v1/categories/search/by-parent?parentId=${parentId}`;
export const fetchPaginatedCategories = (page = 0, size = 100) => `/api/v1/categories?page=${page}&size=${size}`;
export const getUsers = '/api/v1/users';
export const getVendors = '/api/v1/vendors';
export const getOrganization = '/api/v1/organization';
export const getOrganizationLocations = 'api/v1/organization-location';
export const getOrganizationLocationsById = (orgLocId) => `/api/v1/organization-location/${orgLocId}`;
export const getOrganizationLocationsByWarehouseId = (organizationId, warehouseId) => `/api/v1/organization/${organizationId}/organization-locations/by-warehouse?warehouseId=${warehouseId}`;
export const getWareHouse = '/api/v1/warehouse';
export const getWareHouseById = warehouseId => `/api/v1/warehouse/${warehouseId}`;
export const fetchVendorCatalogByLocationId = locationId => `/api/v1/vendor-catalog/by-locationId/${locationId}?query=`;
export const downloadPOTemplateByParams = (warehouseId, locationId, companyName, billTo, receivableDate, poId = '', taxRateType = '') => `/api/v1/purchase-orders/${warehouseId}/download-po-template?vendorLocationId=${locationId}&companyName=${companyName}&billTo=${billTo}&date=${receivableDate}&id=${poId}&taxRateType=${taxRateType}`;
export const uploadPOcsvByParams = (warehouseId, locationId, billTo, receivableDate, poId = '', taxRateType = '') => `/api/v1/purchase-orders/${warehouseId}/upload-csv?vendorLocationId=${locationId}&billTo=${billTo}&date=${receivableDate}&id=${poId}&taxRateType=${taxRateType}`;
//api for terms and conditions for create and edit po
export const getTermsConditions = vendorLocationId => `/api/v1/tc/${vendorLocationId}`
export const createTermsConditions = `api/v1/tc`

export const regionsPath = '/api/v1/regions';
export const getRegionsByWarehouse = (warehouseId) => `/api/v1/warehouse/${warehouseId}/regions`;
export const getFetchAllWarehouses = query => `/api/v1/warehouse/search/findWarehouse?query=${query}`;
export const regionIdPath = id => `/api/v1/regions/${id}`;
export const getFetchAllRegionsPath = query => `/api/v1/regions/search/findRegions?query=${query}`;
export const upcomingDeliveriesByDate = (date, customerId, addressId) => `${APP_URL}/customers/${customerId}/customer-addresses/${addressId}/upcoming-deliveries?date=${date}`;
export const locationsByOrganizationIdPath = id => `/api/v1/organization/${id}/organization-locations`;
export const vendorsLocationIdPath = vendorId => `/api/v1/vendors/${vendorId}`;
export const ordersByCustomerId = (cid, page = 0) => `/api/v1/customer-orders/all-orders-details?page=${page}&customerId=${cid}`;

export const getStatusCountByDate = (filters) => {
  const url = '/api/v1/delivery-route-order/get-status-count-by-date?';
  return generateUrlWithQueryParams(url, filters);
};
export const getEnumsPath = '/api/v1/enums-metadata';
export const getAppEnums = `${APP_URL}/metadata`;
export const searchAllDeliveryBoys = query => `/api/v1/delivery-boys/search/by-query?query=${query}`;
export const fetchDeliveryBoys = '/api/v1/delivery-boys';
export const fetchDeliveryBoysDetailedReports = (startDate, endDate, warehouse) => `/api/delivery-boy-referral/reports/within-date-range?endDate=${endDate}&startDate=${startDate}`;
export const fetchDeliveryBoysDRByPhone = mobile => `/api/delivery-boy-referral/reports/by-mobile?mobile=${mobile}`;
export const fetchDeliveryBoysCRByPhone = mobile => `/api/delivery-boy-referral/reports/monthly-analytics?mobile=${mobile}`;
export const transactionHistoryPath = customerId => `${APP_URL}/customers/${customerId}/wallet-balance`;
export const getTotalWalletBalance = customerId => `${APP_URL_V2}/customers/${customerId}/wallet-balance`;
export const purchaseOrderUrl = '/api/v1/purchase-orders';
export const purchaseOrderByIdPath = id => `/api/v1/purchase-orders/${id}`;
export const getCopiedPODetailsById = id => `/api/v1/purchase-orders/${id}/copy-purchase-order`;
export const searchPurchaseOrderPath = (filters, page = 0, size = 10) => {
  const url = `/api/v1/purchase-orders/search/findPOs?page=${page}&size=${size}&sort=id,desc&`;
  return generateUrlWithQueryParams(url, filters);
};
export const getCustomerPlansPath = customerId => `${APP_URL}/migration/${customerId}/getPlans`;
export const allCitiesPath = '/api/v1/cities';
export const searchAllCities = query => `/api/v1/cities/search/findCities?name=${query}`;
export const searchLocalityByName = (searchString) => `api/v1/localities/search/findLocalities?name=${searchString}`;
export const searchAllLocalities = (city, searchString) => `api/v1/localities/search/findLocalities?city=${city}&name=${searchString}`;
export const getAllLocalities = (regionId, pincode, cityName, searchString) => `/api/v1/customer-address/get-localities?regionId=${regionId}&pincode=${pincode}&city=${cityName}&query=${searchString}`;
export const getAllSubLocalities = (regionId, pincode, cityName, localityName, searchString) => `/api/v1/customer-address/get-sub-localities?regionId=${regionId}&pincode=${pincode}&city=${cityName}&locality=${localityName}&query=${searchString}`;
export const validatePincodeByCity = (regionId, city, pincode) => `/api/v1/customer-address/is-pincode-valid?regionId=${regionId}&city=${city}&pincode=${pincode}`;
export const uomUrl = '/api/v1/uoms?size=500';
export const getuomByProductItemId = (productItemId) => `/api/v1/product-items/${productItemId}/uom`;
export const editCustomerOrdersPath = '/api/v1/customer-orders';
export const fetchCustomerAddressByAddressId = customerAddressId => `/api/v1/customer-address/${customerAddressId}`;
export const fetchRouteDetailsByCustomerOrderId = customerOrderId => `/api/v1/customer-orders/co-with-route-details/${customerOrderId}`;
export const fetchPackagingDetailsByOrderId = customerOrderId => `/api/v1/oli-packaging/packaging-details/${customerOrderId}`;
export const updatePackagingDetailsByOrderId = customerOrderId => `/api/v1/oli-packaging/update-packaging-status/${customerOrderId}`;
export const resolveOrderPath = orderId => `/api/v1/delivery-route-order/update-status/${orderId}`;
export const complaintReasonPath = orderId => `/api/v1/complaint-reasons/${orderId}`;
export const createHubIdPath = '/api/v1/hub';
export const createNewCityPath = id => `/api/v1/cities/${id}`;
export const customerTypeDownloadPath = (startDate, endDate, priority) => `${SCM_URL}/api/v1/customer-orders/customers/filter/csv?startDate=${startDate}&endDate=${endDate}&priority=${priority}`;
export const gstPath = `/api/v1/gst`;
export const getGstPathById = gstId => `/api/v1/gst/${gstId}`;
export const searchGstData = searchTerm => `/api/v1/gst?search=${searchTerm}`;
export const searchGstDataByQuery = searchTerm => `/api/v1/gst/search/findByName?query=${searchTerm || ''}`;
export const findVendors = (searchTerm) => `/api/v1/vendors/search/findVendors?query=${searchTerm}`;
export const getVendorLocationById = (locationId) => `/api/v1/vendor-locations/${locationId}`;
export const getRegionByLocationId = (locationId) => `/api/v1/vendor-locations/${locationId}/region`;
export const sendEmailToVendorByPoId = poId => `/api/v1/purchase-orders/${poId}/send-as-email`;
export const getRegionProducts = (locationId, size, page, searchQuery, categoryIds, status) => `/api/v1/staging-products/by-location/${locationId}?size=${size}&page=${page}&search=${searchQuery}&categoryIds=${categoryIds}&isActive=${status}`;
export const productItemsByProductId = productId => `api/v1/staging-products/${productId}/product-items`;
export const searchCategoriesByName = (name = '', page = 0, size = SEARCH_AND_SELECT_OPTIONS_LIMIT) => `/api/v1/categories/search/by-name?name=${name}&page=${page}&size=${size}`;
export const searchBrandsByName = (name = '', page = 0, size = SEARCH_AND_SELECT_OPTIONS_LIMIT) => `/api/v1/brands/search/by-name?name=${name}&page=${page}&size=${size}`;
export const searchProductsByName = (name = '', page = 0, size = SEARCH_AND_SELECT_OPTIONS_LIMIT) => `/api/v1/products/search/by-name?name=${name}&page=${page}&size=${size}`;
export const searchRegionsByName = (name = '', page = 0, size = SEARCH_AND_SELECT_OPTIONS_LIMIT) => `/api/v1/regions/search/by-name?name=${name}&page=${page}&size=${size}`;
export const getAllBanners = (page, size) => `/api/v1/banners?page=${page}&size=${size}`;
export const getBannerById = (id) => `api/v1/banners/${id}`;
export const bannersPath = `api/v1/banners`;
export const getProductById = (id) => `/api/v1/products/${id}`;
export const getBrandById = (id) => `/api/v1/brands/${id}`;
export const getCategoryById = (id) => `/api/v1/categories/${id}`;
export const fetchWidgetsByWidgetType = (widgetDataType = 'BANNER') => `/api/v1/app/get-app-widget?widgetDataType=${widgetDataType}`;
export const fetchProductWidgets = '/api/v1/product-widgets';
export const getWidgetsByWidgetId = widgetId => `/api/v1/app-widgets/${widgetId}`;
export const bannerSearchPath = (name) => `/api/v1/banners/search/findAllBanners?name=${name}`
export const findAllBanners = (page, size, name, bannerType, active, regionId) => {
  let path = `/api/v1/banners/search/findAllBanners?page=${page}&size=${size}&name=${name}&bannerType=${bannerType}&active=${active}`;
  if (regionId) {
    return path + `&regions=${regionId}`;
  } else {
    return path;
  }
}
export const findAllBannersByFilters = (page = 0, size = 10, name = '', regionId = '', status = '', bannerType = '', screenType = '', parentId = '') => `/api/v1/banners/all?page=${page}&size=${size}&name=${name}&regions=${regionId}&status=${status}&bannerType=${bannerType}&screenType=${screenType}&parentId=${parentId}`;
export const getAllDealOfTheDay = `/api/v1/deal-of-day`;
export const updateDealOfTheDay = (productItemId) => `/api/v1/deal-of-day/update/${productItemId}`;
export const searchProductItemByRegion = (searchTerm, regionId) => {
  let path = `/api/v1/product-item/by-region?query=${searchTerm}`;
  if (regionId) {
    return path + `&regionId=${regionId}`;
  } else {
    return path;
  }
}
export const searchTagsByName = (searchText) => `/api/v1/tags/search/by-tag?query=${searchText}`;
export const findRegionsByBrandId = brandId => `/api/v1/regions/search/findRegionsByBrandId?brandId=${brandId}`;
export const findProductCatalogs = (query = '') => `/api/v1/product-catalogs/search/findProductCatalogs?query=${query}&page=0`;
export const findActiveProductsWithCatalogId = (catalogId = '', query = '', size = 100) => `api/v1/products/search/findActiveProductsWithCatalogId?catalogId=${catalogId}&query=${query}&size=${size}`;
export const getCatalogById = id => `api/v1/products/${id}/productCatalog/`;
export const findProductsWithVendorDetails = (catalogId, query, size, page) => `/api/v1/products/with-vendor-details?catalogId=${catalogId}&query=${query}&size=${size}&page=${page}`;
export const updateProductPropertiesPath = `/api/v1/product-items/update-properties`;
export const getProductCatalogVendors = (productCatalogId, warehouseId = '') => `/api/v1/product-catalog/${productCatalogId}/vendors?warehouseId=${warehouseId}`;
export const getProductCatalogVendor = (productCatalogId) => `/api/v1/product-catalog/${productCatalogId}/vendor`;
export const getVendorLocationByName = (locationName, regionId) => `/api/v1/vendor-locations/search/by-name?name=${locationName}&page=0&size=100&regionId=${regionId}`;
export const deleteVendorLocationMapping = (vendorCatalogId, locationId) => `/api/v1/vendor-catalog/${vendorCatalogId}/vendor-location-mapping/${locationId}`;
export const deliveryRouteOrdersCsv = (warehouseId, fromDate, toDate, hubId = '', routeName = '') => `/api/v1/delivery-route-order/get-delivery-route-orders/csv?warehouseId=${warehouseId}&fromDate=${fromDate}&toDate=${toDate}&hubId=${hubId}&routeName=${routeName}`;
export const getWarehouseHubs = (warehouseId, page = 0, size = 100) => `api/v1/warehouses/${warehouseId}/hubs?page=${page}&size=${size}`;
export const resendOpt = `/api/v1/users/resend-otp`;
export const validatePassword = `/api/v1/users/validate-password`;
export const resetPassword = (userId) => `/api/v1/users/${userId}/reset-password`;
export const staticRouteAddressesPath = (staticRouteId) => `/api/v1/static-routes/${staticRouteId}/staticRouteLineItems`;
export const updateLineItems = (routeId) => `/api/v1/static-routes/${routeId}/update-lineitems-sequence`;
export const searchVendorStockLedger = (warehouseId, name, page, size) => `/api/v1/vendor-stock-ledger?warehouseId=${warehouseId}&name=${name}&page=${page}&size=${size}`;
export const downloadVendorStockInfo = warehouseId => `${SCM_URL}/api/v1/vendor-stock-ledger/${warehouseId}/download-stock-info`;
export const uploadVendorStockInfo = warehouseId => `${SCM_URL}/api/v1/vendor-stock-ledger/${warehouseId}/upload-stock-info`;
// export const updateDump = `/api/v1/vendor-stock-ledger/with-damage`;
// export const updatePhysicalCheck = `/api/v1/vendor-stock-ledger/adjust-stock`;
export const updateStockByTransactionType = (transactionType) => `/api/v1/vendor-stock-ledger/${transactionType}`;
export const getLastFiveLedgerEntries = (vendorProductItemId, warehouseId) => `/api/v1/vendor-stock-ledger/search/findByVendorProductItemIdWithLimits?vendorProductItemId=${vendorProductItemId}&warehouseId=${warehouseId}&page=0&size=5`;
export const getDummyRouteMappings = (page, size) => `/api/v1/locality-default-route?page=${page}&size=${size}`;
export const searchDummyRouteMappings = (page, size, queryParams) => `/api/v1/locality-default-route/with-filters?page=${page}&size=${size}${queryParams}`;
export const searchDummyStaticRoutes = (searchTerm) => `/api/v1/static-routes/search/findDummyStaticRoutes?query=${searchTerm}`;
export const postDummyRoutePath = `/api/v1/locality-default-route`;
export const getDummyRouteById = (id) => `/api/v1/locality-default-route/${id}`;
export const getStockLedgerInventory = (warehouseId, page, size, query, withDetails) => `/api/v1/stock-ledger/stock-inventory?warehouseId=${warehouseId}&page=${page}&size=${size}&name=${query}&withDetails=${withDetails}`;
export const stockDamagePath = `/api/v1/stock-ledger/with-damage`;
export const stockReturnPath = `/api/v1/stock-ledger/item-return`;
export const stockAdjustPath = `/api/v1/stock-ledger/adjust-stock`;
export const stockExtrasPath = `/api/v1/stock-ledger/extras-sent`;
export const stockDumpPath = `/api/v1/stock-ledger/with-dump`;
export const getlastFiveStockledgerEntries = (warehouseId, productItemId) => `/api/v1/stock-ledger/last-transactions?warehouseId=${warehouseId}&productItemId=${productItemId}`;
export const getProductWidgetsList = `/api/v1/product-widgets`;
export const getProductListForWidgets = (widgetId, regionId = '') => `/api/v1/product-widget/${widgetId}/product-items?regionId=${regionId}`;
export const postProductToProductWidget = (widgetId) => `/api/v1/product-widget/${widgetId}/product-item`;
export const downloadProductItemCsvByWidgetId = (widgetId) => `/api/v1/product-widget/${widgetId}/download`;
export const validateProductWidgetItems = '/api/v1/product-widget/validate-items';
export const associateProductItemsByWidgetId = (widgetId) => `/api/v1/product-widget/${widgetId}/associate`;
export const getActiveAppWidgetsByFilters = (filters = {}, page = 0, size = 10) => {
  const url = `/api/v1/app-widgets/all-active?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const createAppWidgets = '/api/v1/app-widgets';
export const reorderAppWidgets = '/api/v1/app-widgets/reorder';
export const updateAppWidget = `/api/v1/app-widgets`;
export const findAuditEntries = (filters, page = 0, size = 10) => {
  const {
    startDate,
    endDate,
    modifiedBy,
    action,
    entity,
    entityKey,
    entityText: entityInfo,
    dbName,
  } = filters;

  let path = `/api/v1/audit-info/search/findEntries?startDate=${startDate}&endDate=${endDate}&action=${action}&page=${page}&size=${size}&entityInfo=${entityInfo}&entityKey=${entityKey}&dbName=${dbName}`;

  if (modifiedBy) {
    path += `&modifiedBy=${modifiedBy}`;
  }
  if (entity) {
    path += `&entity=${entity}`;
  }
  return path;
};

// Non Availability APIs
export const naCustomerOrdersPath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/list`;
export const naGeneratePath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/generate`;
export const naListProductsItemsPath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/products-items`;
export const naReGeneratePath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/re-generate`;
export const naResetPath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/reset`;
export const naSendNotificationsPath = (warehouseId, deliveryDate) => `/api/v1/na/warehouse/${warehouseId}/${deliveryDate}/send-notifications`;

export const allOffersPath = 'api/v1/offers/';
export const offerByIdPath = id => `api/v1/offers/${id}`;
export const offerProductsByIdPath = (id, regionId = '', query = '', page = 0, size = 25) => `api/v1/offers/${id}/products?page=${page}&size=${size}&query=${query}&regionId=${regionId}`;
export const validateOfferProductsByOfferId = id => `/api/v1/offers/${id}/validate-products`;
export const getOfferById = itemId => `/api/v1/offers/offers/all?id=${itemId}`;
export const uploadOfferCSV = '/api/v1/offers/upload';

export const getProductOffersList = (filters = {}, page = 0, size = 10) => {
  const url = `api/v1/offers/offers/all?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};

export const getSurveyListByParams = (filters = {}, page = 0, size = 10) => {
  const url = `/api/v1/survey-master-data?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};

export const getOrSetSurveyDataById = surveyId => `/api/v1/survey-master-data/${surveyId}`;
export const searchChoicesByQuery = (queryText) => `/api/v1/survey-choice/search/findByChoice?name=${queryText}`;
export const getChoiceIcons = '/api/v1/survey-master-data/icon-images';
export const createChoice = '/api/v1/survey-choice';
export const updateSurveyById = (surveyId) => `/api/v1/survey-master-data/${surveyId}`;
export const createNewSurvey = '/api/v1/survey-master-data';
export const updateGlobalConfig = ttl => `/api/v1/application-configs/survey/update-global-configs?ttl=${ttl}`;

export const getOverlaysByFilters = (filters = {}, page = 0, size = 100) => {
  const url = `/api/v1/overlays/filter?page=${page}&size=${size}&sort=timeUpdated,desc&`;
  return generateUrlWithQueryParams(url, filters);
};
export const createOverlays = '/api/v1/overlays';
export const updateOverlaysById = id => `/api/v1/overlays/${id}`;

export const getNewCustomers = ({
  fromDate, toDate, city, locality, building, query, customerId, customerStatus,
}, page) => {
  let path = `/api/v1/customers/new?sort.sorted=true&page=${page}&size=50&fromDate=${fromDate || ''}&toDate=${toDate || ''}&sort=timeUpdated,desc&`;
  if (city) path = `${path}city=${city}&`;
  if (building) path = `${path}building=${building}&`;
  if (locality) path = `${path}locality=${locality}&`;
  if (query) path = `${path}query=${query}&`;
  if (customerId) path = `${path}customerId=${customerId}`;
  if (customerStatus) path = `${path}customerStatus=${customerStatus}`;
  return path;
};

export const getUnAssignedCustomer = ({
  fromDate, toDate, city, locality, building, query, customerId, status,
}, page) => {
  let path = `/api/v1/customer-orders/customers/unassigned?sort.sorted=true&page=${page}&size=50&fromDate=${fromDate || ''}&toDate=${toDate || ''}&sort=timeUpdated,desc&`;
  if (city) path = `${path}city=${city}&`;
  if (building) path = `${path}building=${building}&`;
  if (locality) path = `${path}locality=${locality}&`;
  if (query) path = `${path}query=${query}&`;
  if (customerId) path = `${path}customerId=${customerId}`;
  if (status) path = `${path}status=${status}`;
  return path;
};
export const getNewCustomerCSV = ({
  fromDate, toDate, city, locality, building, query, customerId, customerStatus,
}) => {
  const url = `${SCM_URL}/api/v1/customers/new/csv?`;
  return generateUrlWithQueryParams(url, {
    fromDate, toDate, city, locality, building, query, customerId, customerStatus,
  });
};
export const getUnAssignedCustomersCSV = (filters) => {
  const url = `${SCM_URL}/api/v1/customer-orders/customers/unassigned/csv?`;
  return generateUrlWithQueryParams(url, filters);
};
export const refundProcessPath = customerId => `${APP_URL}/payment-refund/${customerId}`;
export const refundAmmoutPath = customerId => `${APP_URL}/customers/${customerId}/refund-amount`;
export const addressListByFilters = ({
  city, locality, building, query, customerId, status,
}, page = 0) => {
  const url = `/api/v1/customer-address/search/findCustomerAddresses?page=${page}&`;
  return generateUrlWithQueryParams(url, {
    city, locality, building, query, customerId, status,
  });
};

export const hubSegregationListByFilters = (filters = {}, page = 0) => {
  const url = `api/v1/ps/hub-segregation-by-status?page=${page}&`;
  return generateUrlWithQueryParams(url, filters);
};

export const routeSegregationListByFilters = (filters = {}, page = 0) => {
  const url = `/api/v1/ps/route-segregation-by-status?page=${page}&`;
  return generateUrlWithQueryParams(url, filters);
};

export const routeIdPathUrl = (deliveryDate, routeId) => `api/v1/ps/${deliveryDate}/routes/${routeId}/all-products`;
export const hubIdPathUrl = (deliveryDate, hubId) => `api/v1/ps/${deliveryDate}/hubs/${hubId}/all-products`;

export const grnListByFilters = (filters = {}, page = 0) => {
  const url = `/api/v1/grn/search/findGRNs?page=${page}&size=20&`;
  return generateUrlWithQueryParams(url, filters);
};

export const getGrnWithFilters = (filters = {}, page = 0, size = 25) => {
  const url = `/api/v1/grn/search/getGRNWithFilters?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};

export const initiatedTransactions = (customerId, page = 0, size = 20) => `${APP_URL}/initiated-payment-transactions/${customerId}?page=${page}&size=${size}`;
export const transactionHistoryDownloadPath = (customerId, fromDate, toDate) => `${APP_URL}/customers/${customerId}/wallet-ledger/csv?from=${fromDate}&to=${toDate}`;
export const customerInvoiceDownloadPath = (customerId, invoiceId) => `${SCM_URL}/api/v1/invoice/${invoiceId}/customer/${customerId}/download`;
export const updateInitiatedTransactionStatus = (customerId, transcationId) => `${APP_URL}/customers/${customerId}/check-payment-status?transactionId=${transcationId}`;
export const getPercentageCashbackEnableStatusByCustomerId = (customerId) => `${APP_URL}/customers/${customerId}/is-percentage-cashback-enabled`
export const fullDeliveryRouteOrderPath = (id, status) => `/api/v1/delivery-routes/${id}/full?status=${status}`;

export const customerOrderHistoryPath = ({
  customerId, fromDate, toDate, status,
}, page = 0) => {
  const url = `/api/v1/customer-orders/all-orders-details?page=${page}&customerId=${customerId}&`;
  return generateUrlWithQueryParams(url, {
    customerId, fromDate, toDate, status,
  });
};
export const deliveryRouteOrdersPath = (filters, page) => {
  const url = `/api/v1/delivery-route-order/search/findDeliveryRouteOrders?page=${page}&size=20&`;
  return generateUrlWithQueryParams(url, filters);
};
export const fetchPackageStatusesByCustomerOrders = '/api/v1/customer-orders/package-status';
export const findDeliveryRouteOrder = (routeName, date) => `/api/v1/delivery-routes/search/findDeliveryRoutes?query=${routeName}&date=${date}`;
export const getDeliveryRouteDetailsById = id => `/api/v1/delivery-routes/${id}/full`;
export const searchDeliverOrderChats = id => `/api/v1/delivery-order-chats/search/findByDeliveryRouteOrderId?id=${id}`;
export const deliveryOrderChats = 'api/v1/delivery-order-chats';
export const catalogPriceList = id => `${SCM_URL}/api/v1/product-catalogs/${id}/download-prices`;
export const uploadCatalogPath = '/api/v1/product-catalogs/upload';
export const productsByCatalogIdPath = (filters, page = 0, size = 25) => {
  const url = `/api/v1/staging-products/search/findProductsWithCatalogId?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const grnReceiptsById = id => `/api/v1/grn/${id}/receipts`;
export const grnPath = '/api/v1/grn';
export const printGRN = (id, useCarbonService = false) => `${SCM_URL}/api/v1/grn/as-pdf?id=${id}&useCarbonService=${useCarbonService}`;
export const grnById = grnId => `/api/v1/grn/${grnId}`;
export const fetchAllGrnByPoId = poId => `/api/v1/grn/search/findByPoNumber?poNumber=${poId}`;
export const updateStockByGrnId = grnId => `/api/v1/stock-ledger?grn_id=${grnId}`;
export const uploadFilePath = '/api/v1/upload-files';
export const purchaseOrderById = id => `/api/v1/purchase-orders/${id}`;
export const prListByFiltersPath = (filters, page = 0, size = 25) => {
  const url = `/api/v1/purchase-requisitions/filter?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const attendanceSummaryURL = (filters, page = 0) => {
  const url = `api/v1/userAttendanceDetails/getAttendanceForMonth?sort.sorted=true&page=${page}&size=20&sort=department&employeeCode,desc&`;
  return generateUrlWithQueryParams(url, filters);
};
export const attendanceDownloadCSV = (filters = {}) => {
  const url = `${SCM_URL}/api/v1/userAttendanceDetails/getAttendanceForMonth/csv/?`;
  return generateUrlWithQueryParams(url, filters);
};
export const prByPrId = id => `/api/v1/purchase-requisitions/${id}`;
export const generatePObyPR = (id, vendorId, taxRateType = '') => `/api/v1/purchase-orders/generate-purchase-orders?purchaseRequisitionId=${id}&vendorLocationId=${vendorId}&taxRateType=${taxRateType}`;
export const purchaseOrderByPrId = id => `/api/v1/purchase-requisitions/${id}/purchaseOrders`;
export const availableVendorForPRPath = id => `/api/v1/purchase-requisitions/${id}/vendors`;
export const purchaseOrderPdfPath = (id, useCarbonService = false) => `${SCM_URL}/api/v1/purchase-orders/${id}/generate-pdf?useCarbonService=${useCarbonService}`;
export const purchaseOrderCsvPath = id => `${SCM_URL}/api/v1/purchase-orders/${id}/generate-csv`;
export const cancelPoPath = (id, sendCancellationEmail) => `/api/v1/purchase-orders/${id}/cancel?sendCancellationEmail=${sendCancellationEmail}`;
export const purchaseIndentCSV = (warehouseId, date, catalogId) => `${SCM_URL}/api/v1/delivery-route-pick-list/by-warehouse/${warehouseId}/csv?date=${date}&catalogId=${catalogId}`;
export const investmentDetail = '/api/v1/investment-details/active';
export const emplyeeInvestment = id => `/api/v1/investment-details/${id}`;
export const investmentPath = '/api/v1/investment-details';
export const ordersAfterDatePath = (customerId, date) => `api/v1/customer-orders/order-after-date?customerId=${customerId}&fromDate=${date}`;
export const findProductItemsByCatalog = (catalogId, query = '') => `/api/v1/product-items/search/findProductItemsByCatalog?catalogId=${catalogId}&query=${query}`;
export const findVendorProductItemsByCatalog = (catalogId, query = '') => `/api/v1/vendor-product-items/search/findProductItemsByCatalog?catalogId=${catalogId}&query=${query}`;
export const fetchVendorProductItemsByCatalogAndRateType = (catalogId, query = '', taxRateType = '', page = 0, size = 100) => `/api/v1/vendor-product-items/search/by-catalog?catalogId=${catalogId}&query=${query}&taxRateType=${taxRateType}&page=${page}&size=${size}`;
export const findGSTByVendorProductId = vendorProductId => `/api/v1/vendor-products/search/gst-by-vendor-product?id=${vendorProductId}`;
export const getVendorProductDetailsByCatalogIdAndVendorProductId = (catalogId, vendorProductId) => `/api/v1/vendor-catalogs/${catalogId}/vendor-products/${vendorProductId}`;
export const getVendorProductItemsByCatalog = (catalogId, query = '') => `/api/v1/vendor-product-items/search/getProductItemsByCatalog?catalogId=${catalogId}&query=${query}`;
export const findProductItemsByCatalogProductIdAndName = (productId, catalogId, query = '') => `/api/v1/vendor-product-items/search/findProductItemsByCatalogProductIdAndName?productId=${productId}&catalogId=${catalogId}&query=${query}`;
export const findVendorCatalogByProductId = id => `/api/v1/vendor-catalogs/search/findVendorCatalogByProductId?productId=${id}`;
export const createVendorProductById = (productId, uomId) => `/api/v1/products/${productId}/create-vendor-product-item?uomId=${uomId}`;
export const vendorProductMappingPath = productItemId => `/api/v1/vendor-product-items/vpi-mapping/${productItemId}`;
export const productMappingPath = vpItemId => `/api/v1/product-items/pi-mapping/${vpItemId}`;
export const catalogPubilshPath = catalogId => `/api/v1/product-catalog/${catalogId}/schedule-publish`;
export const catalogImmediatePubilshPath = catalogId => `/api/v1/product-catalog/${catalogId}/publish-now`;
export const publishAllCatalog = '/api/v1/product-catalogs/publish-all';
export const downloadMOQStats = '/api/v1/vendor-stock-ledger/moq-stats';
export const fetchVendorProductsByCatalogId = (catalogId, query, size, page) => `api/v1/vendor-products/search/by-catalog-and-name?catalogId=${catalogId}&name=${query || ''}&size=${size}&page=${page}`;
export const fetchMOQStatsByProductItemId = (productItemId) => `api/v1/moq/${productItemId}`;
export const updateMOQ = 'api/v1/moq/update-moq';
export const monitoringPath = (date, warehouseId) => {
  const url = `/api/v1/dashboard/${date}/monitor?`;
  return generateUrlWithQueryParams(url, { warehouseId });
};
export const allHubsPurchaseIndentZip = (warehouseId, date) => `/api/v1/delivery-route-pick-list/by-warehouse/${warehouseId}/csv/all-hubs?date=${date}`;
export const productsMonitoring = (date, warehouseId) => `/api/v1/dashboard/${date}/products_na?warehouseId=${warehouseId}`;
export const getSegregationLogsForHubsPath = deliveryDate => `/api/v1/segregations/${deliveryDate}/hubs-lock-status`;
export const getSegregationLogsForRoutesPath = deliveryDate => `/api/v1/segregations/${deliveryDate}/routes-lock-status`;
export const unlockUserFromZonePath = (deliveryDate, hubId, zoneId) => `/api/v1/segregations/${deliveryDate}/hubs/${hubId}/unlock-zone?zoneId=${zoneId}`;
export const unlockUserFromRoutePath = (deliveryDate, routeId) => `/api/v1/segregations/${deliveryDate}/routes/${routeId}/unlock`;
export const appLinksPath = '/api/v1/internal-apps';
export const allHubsPurchaseIndentCsv = (filters = {}) => {
  const url = `/api/v1/delivery-route-pick-list/by-warehouse/${filters.warehouseId}/csv/all?`;
  return generateUrlWithQueryParams(url, filters);
};
export const hubSegregator = '/api/v1/hub-segregator';
export const searchHubSegregator = id => `/api/v1/hub-segregator/search/findHubSegregator?hubId=${id}`;
export const getAllUsers = (query, role) => `/api/v1/users/search/findUsers?query=${query}&roleId=${role}`;
export const getPaginatedUsers = (page = 0, size = 10) => `/api/v1/users?page=${page}&size=${size}`;
export const getAllUserGroups = (query = '', page = 0, size = 10) => `/api/v1/groups/search?name=${query}&page=${page}&size=${size}`;
export const createUserGroups = '/api/v1/groups';
export const updateUserGroups = groupId => `/api/v1/groups/${groupId}`;
export const getOrAssociateXPathsToGroup = groupId => `/api/v1/groups/${groupId}/x-path`;
export const getOrAssociateUsersToGroup = groupId => `/api/v1/groups/${groupId}/users`;
export const getAllGroups = '/api/v1/groups/all';
export const getAndSetGroupsByUserId = userId => `/api/v1/users/${userId}/groups`;
// export const searchPackaging = (query = '') => `/api/v1/packaging/search/findByName?query=${query}`;
export const getPackaging = (page = 0, size = 25) => `/api/v1/packaging?page=${page}&size=${size}`;
export const fetchUserByIdPath = id => `/api/v1/users/${id}`;
export const fetchHubByIdPath = hubId => `/api/v1/hub/${hubId}`;
export const getRoles = '/api/v1/roles?page=0&size=100';
export const routeTravelledPath = routeId => `/api/delivery-routes/${routeId}/route-travelled/`;
export const fetchStaticRouteByIdPath = id => `/api/v1/static-routes/${id}`;
export const staticRouteSequenceListPath = (staticRouteId, id, sequence) => `api/v1/static-routes/${staticRouteId}/lineitems/${id}?targetSequence=${sequence}`;
export const markAttendance = '/api/v1/userAttendance/markAttendance';
export const userAttendance = mobile => `/api/v1/userAttendance/getAttendance?mobile=${mobile}`;

export const findTicketsByFilter = (filters, page = 0) => {
  const url = `/api/v1/tickets/filter?page=${page}&size=20&`;
  return generateUrlWithQueryParams(url, filters);
};
export const findTicketById = id => `/api/v1/tickets/${id}`;
export const disposeTicket = '/api/v1/tickets/dispose';
export const findTicketsbyCustomerId = customerId => `/api/v1/tickets/search/findAllByCustomerId?customerId=${customerId}`;
export const agentById = id => `/api/v1/agent-state/search/findByAgentId?agentId=${id}`;
export const agentStatusById = (id, status) => `/api/v1/agent-state/${id}/${status}`;
export const agentList = '/api/v1/agent-state/all-agent-status';
export const ticketsPath = '/api/v1/tickets';
export const ticketAuditPath = ticketId => `/api/v1/tickets/search/findAllAuditForTicket?ticketId=${ticketId}`;
export const ticketHistoryPath = ticketId => `/api/v1/tickets/get-history?ticketId=${ticketId}`;
export const ticketCommentPath = ticketId => `/api/v1/tickets/${ticketId}/comments`;
export const postCommentPath = '/api/v1/ticket-comments';
export const agentsReportPath = '/api/v1/agent-activity/getAgentActivities';
export const agentsAcitivityDownloadPath = `${SCM_URL}/api/v1/agent-activity/downloadAgentActivities/csv`;
export const ticketReportDownloadPath = (filters = {}) => {
  const url = `${SCM_URL}/api/v1/tickets/downloadTickets/csv?`;
  return generateUrlWithQueryParams(url, filters);
};
export const ubonaTransactionPath = '';
export const ticketCallHistoryPath = ticketId => `/api/v1/call-history/search/findAllCallHistoryByTicketId?ticketId=${ticketId}`;
export const ticketTypesAndSubTypes = '/api/v1/tickets/ticket-type-subtype-map';
export const customerOrderById = orderId => `/api/v1/customer-orders/${orderId}`;
export const emailsByTicketId = ticketId => `/api/v1/ticket-emails/search/findAllEmailsByTicketId?ticketId=${ticketId}`;
export const sendEmail = '/api/v1//agent-state/sendEmail';
export const fetchDeliveryBoyById = employeeCode => `/api/v1/delivery-boys/${employeeCode}`;
export const fetchAllDepartmentPath = 'api/v1/departments';
export const fetchDepartmentById = departmentId => `api/v1/departments/${departmentId}`;
export const fetchAllZoneIds = '/api/v1/zones';
export const processFaultyProductRefund = `${APP_URL}/customers/process-refund`;
export const getFullProductItemDataByProductItemId = productItemId => `api/v1/product-item/${productItemId}/fetch-all`;
export const getRefundStatus = (customerId, orderId, lineItemId) => `${APP_URL}/customers/has-refund-given?customerId=${customerId}&orderId=${orderId}&orderLineitemId=${lineItemId}`;
export const revertRefundByRefundId = (customerId, refundId) => `${APP_URL}/customers/${customerId}/revert-refund?refundId=${refundId}`;
export const getOrderLineItemDeliveryLogs = (lineItemId = '') => `/api/v1/oli-delivery-log/by-line-item-id/${lineItemId}`;
export const getOfferDetailsByLineItemId = lineItemId => `/api/v1/customer-order-lineitems/get-offer-details?orderLineItemId=${lineItemId}`;
export const getAccountStatusByCustomerId = cId => `${APP_URL}/customers/${cId}/account`;
export const togglePlanStatusByCustomerId = cId => `${APP_URL}/customers/${cId}/toggle-status`;
export const checkAutoPauseStatus = `${APP_URL}/auto-pause/batch`;
export const checkMobileAvailability = (mobile) => `${APP_URL}/customers/is-mobile-already-registered?mobile=${mobile}`;
export const checkEmailAvailability = (email) => `${APP_URL}/customers/is-email-already-registered?email=${email}`;
export const generateOtp = (customerId, source) => `/api/v1/customers/${customerId}/change-mobile-request-otp?source=${source}`;
export const generateOtpForEmailChange = (customerId, source) => `/api/v1/customers/${customerId}/change-email-request-otp?source=${source}`;
export const validateOtp = (customerId, source) => `/api/v1/customers/${customerId}/change-mobile-validate-otp?source=${source}`;
export const validateOtpForEmailChange = (customerId, source) => `/api/v1/customers/${customerId}/change-email-validate-otp?source=${source}`;
export const changeMobileNumber = (customerId, mobile) => `${APP_URL}/customers/${customerId}/change-mobile?newMobile=${mobile}`;
export const getChangeMobileOtp = (customerId = '', source = '') => `/api/v1/customers/${customerId}/show-common-otp?source=${source}`;
export const getInventoryProductItems = (warehouseId, query, page, size) => `/api/v1/stock-inventory-product-item?warehouseId=${warehouseId}&query=${query}&page=${page}&size=${size}`;
export const getProductItemQuantity = (warehouseId, productItemId) => `/api/v1/stock-ledger/stock-inventory?warehouseId=${warehouseId}&productItemId=${productItemId}`;
export const purchaseOrderItemAnalytics = (warehouseId, vendorProductItemId) => `/api/v1/purchase-orders/analytics?warehouseId=${warehouseId}&vendorProductItemId=${vendorProductItemId}`;

export const getExternalReferrerByFilters = (filters = {}, page = 0, size = 25) => {
  const url = `${APP_URL}/external-referrer/get-refer?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const generateExternalReferralCode = `${APP_URL}/external-referrer/generate-external-referrer-code`;
export const getCouponsByCouponType = couponType => `${APP_URL}/get-coupons?couponType=${couponType}`;
export const getHistory = () => `api/v1/audit-info/get-audits`;

export const getTotMarginsByFilters = (filters = {}, page = 0, size = 25) => {
  const url = `/api/v1/tot-margin/search?page=${page}&size=${size}&`;
  return generateUrlWithQueryParams(url, filters);
};
export const createTotMargin = '/api/v1/tot-margin';
export const updateTotMarginById = id => `/api/v1/tot-margin/${id}`;
export const downloadTotMarginCSV = warehouseId => `/api/v1/tot-margin/download/csv?warehouseId=${warehouseId}`;
export const uploadTotMarginCSV = warehouseId => `/api/v1/tot-margin/upload/csv?warehouseId=${warehouseId}`;
export const getTotMarginByVendorProductItemId = (vendorProductItemId, warehouseId) => `/api/v1/tot-margin/vendor-product-item/${vendorProductItemId}?warehouseId=${warehouseId}`;
export const getTotMarginByVendorProductItemIds = (vendorProductItemIds, warehouseId) => `/api/v1/tot-margin/vendor-product-items?ids=${vendorProductItemIds}&warehouseId=${warehouseId}`;
export const validateTotMargin = '/api/v1/purchase-orders/validate-margin';
export const searchVendorsProductsPathByWarehouse = (searchText, warehouseId) => `/api/v1/vendor-product-items/search/by-warehouse/${warehouseId}?page=0&size=25&query=${searchText}`;
export const getAllXPaths = 'api/v1/xpath';
export const getAndSetXPathByUserId = userId => `api/v1/xpath/user/${userId}`;
export const xPathValidation = 'api/v1/xpath/user/allowed';
export const getUserRolesByUserId = userId => `api/v1/users/${userId}/roles`;
export const searchUSers = query => `/api/v1/users/search/findUsers?query=${query}`;

export const getSegregators = (query, role, page = 0, size = 25) => `api/v1/users/get-segregators?query=${query}&role=${role}&page=${page}&size=${size}`;
export const createSegregator = `/api/v1/users/segregator`;
export const updateSegregator = id => `/api/v1/users/segregator/${id}`;

export const getHomeOffers = (query, region, active, page = 0, size = 25) => `api/v1/home-offers/get-home-offers?query=${query}&region=${region}&active=${active}&page=${page}&size=${size}`;
export const createHomeOffer = `/api/v1/home-offers`;
export const updateHomeOffer = id => `/api/v1/home-offers/${id}`;

const cookies = new Cookies();

const processRequest = (method, endPoint, requestData, headerConfig) => {
  if (method === 'get') {
    return api.get(endPoint, headerConfig);
  } if (method === 'post') {
    return api.post(endPoint, requestData, headerConfig);
  } if (method === 'put') {
    return api.put(endPoint, requestData, headerConfig);
  } if (method === 'patch') {
    return api.patch(endPoint, requestData, headerConfig);
  } if (method === 'delete') {
    return api.delete(endPoint, headerConfig);
  }
  throw new Error('Invalid method passed');
};

const getHeaders = (baseQuery) => {
  const token = (baseQuery.includes(APP_URL) || baseQuery.includes(APP_URL_V2)) ? cookies.get('app_token') : cookies.get('access_token');
  let isTokenRequired = baseQuery.indexOf("/api/v1/products/by-location/") != -1;
  let headers = null;
  if (isTokenRequired) {
    headers = {
      'Content-Type': 'application/json',
    };
  } else {
    headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    };
  }
  return headers;
};

const validateXPath = (pathname, callback) => {
  const postData = {
    path: pathname,
  };
  const HEADER_CONFIG = {
    headers: getHeaders(xPathValidation),
  };
  processRequest('post', xPathValidation, postData, HEADER_CONFIG)
    .then(res => {
      callback(null, res)
    })
    .catch(err => {
      callback(err, null)
    })
};

var pathDict = {
  "prevPath": ''
};

const doubleBackPaths = [
  '/non-availability',
  '/catalog-view',
  '/customer-addresses',
  '/monthly-invoices',
  '/po',
  '/survey'
];

const isPathnameIncluded = (pathName) => {
  return doubleBackPaths.some(el => pathName.startsWith(el));
};

export default {
  sendRequest(method, baseQuery, requestData = null, additionalHeaders = null) {
    const pathName = location.pathname;
    if (!(['/', '/login', '/set-password'].includes(pathName) || pathName === pathDict.prevPath)) {
      validateXPath(pathName, (err, res) => {
        if (err) {
          if (err.response.status === 403) {
            Toast.error('You are not allowed to access this page!');
            if (location.search || isPathnameIncluded(pathName)) {
              history.go(-2);
            } else {
              history.go(-1);
            }
            return;
          }
        }
        if (res) {
          console.log(res);
        }
      });
    }
    const HEADER_CONFIG = {
      headers: getHeaders(baseQuery),
    };
    if (additionalHeaders) {
      // TODO: handle app and scm endpoint here
      HEADER_CONFIG.headers = {
        ...HEADER_CONFIG.headers,
        ...additionalHeaders,
      };
    }
    pathDict.prevPath = pathName;
    return processRequest(method, baseQuery, requestData, HEADER_CONFIG)
      .then((response) => {
        if (method !== 'get') {
          if (response.data && typeof response.data.data === 'string')
            Toast.show(response.data.data);
          else if (response.data && response.data.message && typeof response.data.message === 'string')
            Toast.show(response.data.message);
          else
            Toast.show('Success');
        }
        return response;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Toast.error('Please login again!');
          cookies.remove('access_token');
          history.pushState('/login');
          return;
        }
        if (method !== 'get') {
          if (error.response && error.response.data && error.response.data.message)
            Toast.error(error.response.data.message);
          else
            Toast.error('Sorry your request could not be processed');
        }
        throw error;
      });
  },
  downloadFile(url, fileName) {
    axios({
      url,
      method: 'GET',
      headers: getHeaders(url),
      responseType: 'blob', // important
    }).then((response) => {
      const urlToOpen = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlToOpen;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      Toast.error('Sorry your request could not be processed');
    });
  },
};
