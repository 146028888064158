import React from 'react';
import NonAvailabilityComponent from './NonAvailabilityComponent';


class NonAvailabilityContainer extends React.Component {
  render() {
    return (
      <div>
        <NonAvailabilityComponent />
      </div>
    );
  }
}

export default NonAvailabilityContainer;
