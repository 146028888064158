import React, { Component } from 'react';
import {  Typography, 
          Button, 
          Table, 
          TableBody, 
          TableCell, 
          TablePagination, 
          TableRow,
        } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import apiCall, { allCustomerProfile } from '../../../api/NetworkHandler';
import NavBar from '../../../components/NavBar';
import PlanSearchBar from './PlanSearchBar';
import TableHeader from '../../Commons/components/TableHeader';


const columnData = [
  {
    id: 'customerId',
    numeric: false,
    disablePadding: true,
    label: 'Customer ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'mobile',
    numeric: false,
    disablePadding: true,
    label: 'Mobile',
  },
  {
    id: 'localityName',
    numeric: false,
    disablePadding: true,
    label: 'Locality',
  },
  {
    id: 'cityName',
    numeric: false,
    disablePadding: false,
    label: 'City',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const styles = {

  wrapper: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },

  container: {
    height: '300',
    color: 'white',
    width: '80%',
  },
};

class PlanComponent extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { customerId: selectedCustomerId = '' } = params;
    this.state = {
      selectedCustomerId,
      customers: [],
      links: {},
      page: 0,
      rowsPerPage: 20,
      totalElements: 0,
      searchedResult: false,
    };
  }


  onResultsRetrieved = (customers, totalElements, searchedResult) => {
    if (customers && customers.length > 0) {
      this.setState({ customers: [] });
    }
    this.setState({
      customers, selectedCustomerId: '', totalElements, searchedResult,
    });
  }

  clearAll = () => {
    this.setState({ customers: [], totalElements: 0 });
    const allAddressURL = 'api/v1/customer-address?page=0&size=20';
    apiCall.sendRequest('get', allAddressURL)
      .then((response) => {
        const { data } = response;
        const { _embedded = {}, page = {} } = data;
        const customerAddresses = _embedded['customer-address'];
        console.log('-----customerAddresses------', customerAddresses);
        const { totalElements = 0 } = page;
        this.setState({ customers: customerAddresses, totalElements, page: 0 });
      });
  };


  fetchPaginatedAddresses = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        const { data: { data = [], _links: links } } = response;
        this.setState({
          // customers: data,
          links,
          selectedCustomer: {},
          searchedResult: false,
        });
      });
  }

  handleClick = (selectedCustomer) => {
    const { selectedCustomerId } = selectedCustomer;
    this.setState({
      selectedCustomerId,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  renderAddressDetails = () => {
    const { selectedCustomer } = this.state;
    const addressKeys = Object.keys(selectedCustomer);
    if (!addressKeys.length) {
      return null;
    }
    return addressKeys.map(item => item !== 'deviceId' && item !== '_links' &&
    <Typography variant="body1" color="inherit">
        {`${item} : ${selectedCustomer[item]}`}
    </Typography>);
  }


  render() {
    const {
      customers,
      rowsPerPage,
      page,
      selectedCustomerId,
      totalElements,
    } = this.state;
    return (
      <div>
        <NavBar />
        <PlanSearchBar customerId={selectedCustomerId} onResultsRetrieved={this.onResultsRetrieved} clearAll={this.clearAll} page={page} />
        <div style={styles.calendar}>
          <Typography variant="body2" color="secondary" >
            <Button color="secondary" onClick={() => { this.props.history.goBack(); }}>Back</Button>
          </Typography>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.container}>
            <div>
              <Table
                style={{
                  marginRight: '2.5%',
}}
                aria-labelledby="tableTitle"
              >
              <TableHeader columns={columnData} />
                <TableBody>
                  {
                    customers.map(n => (
                      <TableRow
                        hover
                        onClick={() => this.handleClick(n)}
                        tabIndex={-1}
                        key={n.customerId}
                        lg={4}
                        md={6}
                        sm={6}
                        xs={6}
                        selected={selectedCustomerId === n.customerId}
                      >
                        <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                          {n.customerId}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <div>
                            {n.customerName}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <div>
                            {n.mobile}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <div>
                            {n.localityName}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <div>
                            {n.cityName}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <span>
                            <Button size="flatSecondary" color="secondary">
                              {/* <Link to={`/create-customer-plan/${n.customerId}`} style={{ textDecoration: 'none', color: 'inherit' }} >Create</Link> */}
                              <Link to={{ pathname: `/create-customer-plan/${n.customerId}`, state: { customerAddress: n } }}> Create </Link>
                            </Button>
                          </span>
                       &emsp;|&emsp;
                          <span>
                            <Button size="flatSecondary" color="secondary">
                              <Link to={`/view-customer-plan/${n.customerId}/${n.id}`} style={{ textDecoration: 'none', color: 'inherit' }} >View</Link>
                            </Button>
                          </span>
                        </TableCell>
                      </TableRow>
                ))}
                </TableBody>
              </Table>
            </div>

            {(this.state.searchedResult) ?
              <TablePagination
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
                nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
                onChangePage={this.handleChangePage}
              />
            : <TablePagination
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  // disabled: !this.state.links.prev,
                }}
              nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  // disabled: !this.state.links.next,
                }}
              onChangePage={this.handleChangePage}
            />
          }
          </div>
        </div>
      </div>

    );
  }
}

PlanComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PlanComponent;
