import React from 'react';
import PropTypes from 'prop-types';
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { findActiveProductsWithCatalogId } from '../../api/NetworkHandler';

const onLoad = async (searchTerm, categoryId) => {
  const response = await apiCall.sendRequest('get', findActiveProductsWithCatalogId(categoryId, searchTerm));
  const { data: { _embedded } } = response;
  const { products } = _embedded;

  return products.map(items => ({
    label: items.name,
    value: items.id,
  }));
};

function ProductSelector(props) {
  const { selected, onSelect, categoryId } = props;

  return (
    <SearchAndSelect
    onLoad={searchTerm => onLoad(searchTerm, categoryId)}
    selected={selected}
    onSelect={onSelect}
    />
  );
}

ProductSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.arrayOf(PropTypes.object),
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,

  categoryId: PropTypes.number,
};

ProductSelector.defaultProps = {
  selected: null,
};

export default ProductSelector;
