import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class CRMContainer extends Component {
//   componentDidMount() {
//     setInterval(() => {
//       this.checkIfUserLoggedIn();
//     }, 10000);
//   }

//   componentWillUnmount() {
//     console.log('componentWillUnmount', this.props);
//   }

//  checkIfUserLoggedIn = () => {
//    console.log('this.==Props===>', this.props);
//  }

  render() {
    const { children } = this.props;
    return (
     <div> { children }</div>
    );
  }
}

CRMContainer.propTypes = {
  children: PropTypes.element,
};

export default withRouter(CRMContainer);
