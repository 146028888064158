import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import apiCall, { getOrderAPIStatus } from '../../../api/NetworkHandler';
import OrderStatusList from '../components/OrderStatusList';
import NavBar from '../../../components/NavBar';
import Colors from '../../../utils/Colors';


const Constants = {
  title: 'Order Status',
};

const styles = {
    topBar: {
        display: "flex",
        backgroundColor: Colors.grey[100],
    },
    title: {
        width: "20%",
        height: '80px',
        paddingLeft: '2em',
        paddingTop: "30px",
    },
  }

var versionDict = {
  'orderStatusRequestId': 0,
};

class OrderStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderStatusData: [],
    };
  }

  componentDidMount= () => {
    this.fetchOrderAPIStatus();
  };

  fetchOrderAPIStatus = async () => {
    try {
        versionDict.orderStatusRequestId += 1;
        let prevRequestId = versionDict.orderStatusRequestId;
        const response = await apiCall.sendRequest('get', getOrderAPIStatus);
        const { data: { data = [] } = {} } = response;
        if (prevRequestId == versionDict.orderStatusRequestId) {
          this.setState({
            OrderStatusData: data,
          });
        }
    } catch (err) {
      this.setState({
        OrderStatusData: [],
      });
    }
  };

  handleRefresh = () => {
    this.fetchOrderAPIStatus();
  };

  render() {
    const { OrderStatusData } = this.state;

    return (
      <div>
        <NavBar />
        <div style={styles.topBar}>
          <div style={styles.title}>
            <Typography variant="title" color="inherit">{Constants.title}</Typography>
          </div>
        </div>
        <OrderStatusList
          OrderStatusData={OrderStatusData}
          onRefresh={this.handleRefresh}
        />
      </div>
    );
  }
}

export default withRouter(OrderStatusContainer);
