import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  Checkbox,
  FormControlLabel,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import apiCall, { getProductCatalogVendor, getVendorLocationById } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import AddIcon from '@material-ui/icons/Add';
import { INDIA_STATE_LIST, UAE_CITIES_LIST } from '../../../constants';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  }
};

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: {
        house: '',
        floor: '',
        block: '',
        building: '',
        street: '',
        subLocality: '',
        locality: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        country: '',
        phoneNumber: '',
        email: '',
        contact: null,
        contactMobile: '',
        name: '',
        cutOffTime: null,
        region: '',
        regionName: '',
        localPurchase: false,
      },
      vendorPatchData: {},
      temporaryEmailText: '',
      emails: [],
      emailHelperText: 'Please type email and press add button',
      invalidEmail: false,
    };
  }

  handleInputChange = (event) => {
    const { vendor, vendorPatchData } = this.state;
    const { name, value } = event.target;
    this.setState((prevState) => {
      return {
        vendor: {
          ...vendor,
          [name]: value,
        },
        vendorPatchData: {
          ...vendorPatchData,
          [name]: value,
        }
      }
    })
  }

  componentDidMount = () => {
    const { vendor = {} } = this.props;
    if (!vendor.hasOwnProperty("name")) {
      vendor.name = '';
      vendor.country = '';
      vendor.state = '';
    }
    this.setState({ vendor });
    const { email = '' } = vendor;
    if (email) {
      this.setState({ vendor, emails: email.split(',').map(e => e.trim()) });
    } else {
      this.setState({ vendor, emails: [] });
    }
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    });
  }

  isPropertyValid = (property, value) => {
    if (!value) {
      this.setError(property, true);
      return false;
    } else {
      this.setError(property, false);
    }
    return true;
  }

  validateForm = () => {
    let { vendor } = this.state;
    let isFormValid = true;

    const validEntityTypes = ["name", "country", "state"];

    for (const property in vendor) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, vendor[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  handleSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { vendor, vendorPatchData } = this.state;
    const { productCatalog, onClose, onRefresh } = this.props;
    const { id } = productCatalog;
    const { regions } = productCatalog;
    vendor.region = regions[0];
    try {
      if (vendor.id) {
        await apiCall.sendRequest("patch", getVendorLocationById(vendor.id), vendorPatchData);
      } else {
        await apiCall.sendRequest("post", getProductCatalogVendor(id), vendor);
      }
      onClose();
      onRefresh();
    } catch (error) {
      const { message } = error.response.data;
      this.setState({ errorMessage: message })
    }
  }

  handleSelectCheckBox = (event, name) => {
    const { vendor } = this.state;
    if (name === 'localPurchase') {
      const { checked } = event.target;
      vendor[name] = checked;
      this.setState({
        vendor,
      });
    }
  }

  handleEmailChange = (event) => {
    const { value } = event.target;
    this.setState({ temporaryEmailText: value.trim().toLowerCase() });
  };

  handleEmailAdd = () => {
    let { temporaryEmailText, emails, vendor, vendorPatchData } = this.state;

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(temporaryEmailText)) {
      this.setState({ emailHelperText: 'Invalid email', invalidEmail: true });
      return;
    }

    if (emails.includes(temporaryEmailText.toLowerCase())) {
      this.setState({ 
        duplicateEmailText: "Duplicate email",
        temporaryEmailText: '',
      });
      return;
    }
    emails = [...emails, temporaryEmailText];
    vendor = {
      ...vendor,
      email: emails.join(),
    }
    vendorPatchData = {
      ...vendorPatchData,
      email: emails.join(),
    }
    this.setState({ 
      emails,
      temporaryEmailText: '',
      vendor,
      vendorPatchData,
      emailHelperText: 'Please type email and press add button',
      invalidEmail: false,
      duplicateEmailText: '',
    });
  };

  handleEmailDelete = (email) => {
    let { emails, vendor, vendorPatchData } = this.state;
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    vendor = {
      ...vendor,
      email: emails.join(),
    }
    vendorPatchData = {
      ...vendorPatchData,
      email: emails.join(),
    }
    this.setState({ emails, vendor, vendorPatchData });
  };

  render() {
    const { vendor = {}, errorMessage, error = {}, emails = [], temporaryEmailText, emailHelperText, invalidEmail, duplicateEmailText } = this.state;
    const {
      name = '',
      active = true,
      building,
      house,
      floor,
      street,
      locality,
      subLocality,
      landmark,
      city,
      pincode,
      phoneNumber,
      email,
      contact,
      contactMobile,
      cin,
      gstin,
      pan,
      companyName,
      state,
      country
    } = vendor;
    const { onClose, classes, productCatalog } = this.props;
    const { regions } = productCatalog;
    const newEmails = emails.filter(el => el);
    const stateOrCityList = country === 'India' ? INDIA_STATE_LIST : UAE_CITIES_LIST;
    return (
      <Grid className={classes.container}>
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  className={classes.arrow}
                  onClick={() => { onClose() }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  Vendor Location Form
                </Typography>
                <Typography color="error" variant="caption">* required</Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose() }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={() => { this.handleSave() }} variant="contained" color="secondary">
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        {errorMessage && <Row>
          <ColMod lg={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.vendor.localPurchase == true}
                  value={this.state.vendor.localPurchase}
                  onChange={event => this.handleSelectCheckBox(event, 'localPurchase')}
                  color="secondary"
                  />
                }
                label="Local Purchase"
              />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={name || ''}
              label="Name"
              name="name"
              fullWidth
              onChange={this.handleInputChange}
              required
              error={error.name}
            />
          </ColMod>
          <ColMod md={6}>
            <FormControl>
              <Typography variant="caption"> Status </Typography>
              <RadioGroup onChange={this.handleInputChange} name='active' value={active + ""} row>
                <FormControlLabel value="true" control={<Radio />} label="Active" />
                <FormControlLabel value="false" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </FormControl>
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={regions[0] ? regions[0].name : ''}
              label="Region"
              fullWidth
              disabled
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={building || ''}
              label="Building"
              name="building"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={house || ''}
              label="House No."
              name="house"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={floor || ''}
              label="Floor"
              name="floor"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={street || ''}
              label="Street"
              name="street"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={locality || ''}
              label="Locality"
              name="locality"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={subLocality || ''}
              label="Sub Locality"
              name="subLocality"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={landmark || ''}
              label="Landmark"
              name="landmark"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={city || ''}
              label="City"
              name="city"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={pincode || ''}
              label="Pincode"
              name="pincode"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <FormControl fullWidth>
              <InputLabel> Country </InputLabel>
              <Select
                name="country"
                value={country || ''}
                fullWidth
                onChange={this.handleInputChange}
                error={!country}
              >
                {['India', 'UAE'].map((country, index) => (<MenuItem key={index} value={country}> {country} </MenuItem>))}
              </Select>
              <FormHelperText error>{!country && '*required'}</FormHelperText>
            </FormControl>
          </ColMod>
          <ColMod md={6}>
            <FormControl fullWidth>
              <InputLabel> State </InputLabel>
              <Select
                name="state"
                value={state || ''}
                fullWidth
                onChange={this.handleInputChange}
                disabled={!country}
                error={!state}
              >
                {stateOrCityList.map((state, index) => (<MenuItem key={index} value={state}> {state} </MenuItem>))}
              </Select>
              <FormHelperText error>{!state && '*required'}</FormHelperText>
            </FormControl>
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={phoneNumber || ''}
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          {/* <ColMod md={6}>
            <TextField
              value={email || ''}
              label="Email"
              name="email"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod> */}
          <ColMod md={6}>
            <TextField
              value={temporaryEmailText || ''}
              label="Email"
              name="email"
              helperText={emailHelperText}
              error={invalidEmail}
              style={{ width: '290px', marginRight: '5px' }}
              onChange={this.handleEmailChange}
            />
            <Button onClick={this.handleEmailAdd} variant="fab" mini color="secondary" aria-label="add">
              <AddIcon />
            </Button>
          </ColMod>
        </Row>
          {newEmails.length != 0 && 
          <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
            {newEmails.map((e, index) => (
              <Chip
                key={index}
                label={e}
                variant="outlined"
                color="primary"
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onDelete={() => { this.handleEmailDelete(e) }}
              />
            ))}
          </div>}
          {duplicateEmailText && <span style={{ color: 'red' }}>{duplicateEmailText}</span>}
        <Row>
          <ColMod md={6}>
            <TextField
              value={contact || ''}
              label="Contact"
              name="contact"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={contactMobile || ''}
              label="Contact Mobile"
              name="contactMobile"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={cin || ''}
              label="CIN"
              name="cin"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={gstin || ''}
              label="GSTIN"
              name="gstin"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod md={6}>
            <TextField
              value={pan || ''}
              label="PAN"
              name="pan"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              value={companyName || ''}
              label="Company Name"
              name="companyName"
              fullWidth
              onChange={this.handleInputChange}
            />
          </ColMod>
        </Row>
      </Grid >
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(ProductForm);
