import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall, { gstPath, getGstPathById } from '../api/NetworkHandler';
import isEmpty from 'lodash/isEmpty';

const styles = {
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    width: '70%',
    height: '85%',
  },
  label: {
    fontSize: '0.9em',
  }
}


class GstDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstData: {},
      gstPatchData: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState.gstData)) {
      const { gstData } = nextProps;
      return {
        gstData: {
          ...gstData
        }
      };
    } else {
      return {
        ...prevState
      }
    }
  }

  handleInputChange = property => (event) => {
    const value = event.target.value;

    this.setState(prevState => {
      const gstData = prevState.gstData;
      const gstPatchData = prevState.gstPatchData;
      gstData[property] = value;
      gstPatchData[property] = value;
      return {
        gstData,
        gstPatchData,
      }
    })
  }

  validateForm = (gstData) => {
    const {
      hsnCode,
      igst,
      cgst,
      sgst
    } = gstData;
    const calculatedGST = igst && (parseFloat(igst) / 2).toFixed(2);

    let isFormValid = true;

    if (!hsnCode) {
      isFormValid = false;
    }

    if (!cgst || parseFloat(sgst) != calculatedGST) {
      isFormValid = false;
    }

    if (!sgst || parseFloat(sgst) != calculatedGST) {
      isFormValid = false;
    }

    if (!igst || parseFloat(igst) != parseFloat(cgst) + parseFloat(sgst)) {
      isFormValid = false;
    }

    return isFormValid;
  }

  handleSave = async () => {
    const { gstPatchData, gstData } = this.state;
    if (!this.validateForm(gstData)) {
      return;
    }
    try {
      if (gstData.id) {
        await apiCall.sendRequest('patch', getGstPathById(gstData.id), gstPatchData);
      } else {
        gstData.chapterCode = '';
        gstData.headingCode = '';
        await apiCall.sendRequest('post', gstPath, gstData);
      }
      this.props.handleSave();
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {
      gstData,
    } = this.state;

    const {
      hsnCode,
      igst,
      cgst,
      sgst
    } = gstData;

    const { handleCancel } = this.props;
    const { id } = gstData;
    const isIGSTValid = parseFloat(igst) === parseFloat(cgst) + parseFloat(sgst);
    const calculatedGST = igst && (parseFloat(igst) / 2).toFixed(2);

    return (
      <Grid>
        <Row>
          <ColMod>
            <Row>
              <Typography variant="title" color="inherit">
                {id ? 'Edit HSN Data' : 'Add HSN Data'}
              </Typography>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='Name'
              value={gstData.name || ''}
              onChange={this.handleInputChange('name')}
            />
          </ColMod>
          <ColMod lg={6}>
            <InputLabel style={styles.label}>Status</InputLabel>
            <RadioGroup value={gstData.active + ''} onChange={this.handleInputChange('active')} row>
              <FormControlLabel
                value='true'
                control={<Radio color="secondary" />}
                label='Active'
                labelPlacement="end"
              />
              <FormControlLabel
                value='false'
                control={<Radio color="secondary" />}
                label="Inactive"
                labelPlacement="end"
              />
            </RadioGroup>
          </ColMod>
        </Row>
        {/* <Row>
          <ColMod lg={12}>
            <TextField
              fullWidth
              label='Description'
              value={gstData.description}
              onChange={this.handleInputChange('description')}
              multiline
              rowsMax={4}
              rows={2}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='Chapter Code'
              value={gstData.chapterCode || ''}
              onChange={this.handleInputChange('chapterCode')}
              // required
              // error={emptyChapterCode}
              // helperText={emptyChapterCode && 'Chapter Code is required'}
            />
          </ColMod>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='Heading Code'
              value={gstData.headingCode || ''}
              onChange={this.handleInputChange('headingCode')}
              // required
              // error={emptyHeadingCode}
              // helperText={emptyHeadingCode && 'Heading Code is required'}
            />
          </ColMod>
        </Row> */}
        <Row>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='HSN Code'
              type="number"
              value={hsnCode || ''}
              onChange={this.handleInputChange('hsnCode')}
              required
              error={!hsnCode}
              helperText={!hsnCode && '*required'}
            />
          </ColMod>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='IGST'
              type="number"
              value={igst || ''}
              onChange={this.handleInputChange('igst')}
              required
              error={!igst || !isIGSTValid}
              helperText={!igst && '*required' || !isIGSTValid && 'IGST is combination of SGST and CGST'}
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='CGST'
              type="number"
              value={cgst || ''}
              onChange={this.handleInputChange('cgst')}
              required
              error={!cgst || parseFloat(cgst) != calculatedGST}
              helperText={!cgst && '*required' || parseFloat(cgst) != calculatedGST && 'Invalid CGST'}
            />
          </ColMod>
          <ColMod lg={6}>
            <TextField
              fullWidth
              label='SGST'
              type="number"
              value={sgst || ''}
              onChange={this.handleInputChange('sgst')}
              required
              error={!sgst || parseFloat(sgst) != calculatedGST}
              helperText={!sgst && '*required' || parseFloat(sgst) != calculatedGST && 'Invalid SGST'}
            />
          </ColMod>
        </Row>
        <Row end="sm">
          <ColMod>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </ColMod>
          <ColMod>
            <Button variant="contained" color="primary" onClick={this.handleSave}>
              Save
            </Button>
          </ColMod>
        </Row>
      </Grid>
    )
  }
}

GstDataForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  gstData: PropTypes.instanceOf(Object).isRequired,
};

GstDataForm.defaultProps = {
  handleCancel: () => { },
  handleSave: () => { },
  gstData: {},
}

export default GstDataForm