import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Row } from 'react-flexbox-grid';
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ColMod } from '../../../components/lib';
import NavBar from '../../../components/NavBar';
import apiCall, {
  allOffersPath,
  offerByIdPath, getOfferById,
  allProductItemsPath, productItemPath,

} from '../../../api/NetworkHandler';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import isEmpty from 'lodash/isEmpty';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
};

let COMPONENT_TITLE = 'Edit Offer Details';
const re = /^[0-9\b]+$/;

class EditOfferComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerData: {},
      error: {},
      itemId: '',
      products: [],
      productName: '',
      freeItemName: '',
    };
  }

  componentWillMount() {
    const { match = {} } = this.props;
    const { params = { } } = match;
    const { itemId = null } = params;
    this.setState({
      itemId,
    });
    if (!itemId) COMPONENT_TITLE = 'Create New Offer';
    else COMPONENT_TITLE = 'Update Offer';
  }

  componentDidMount() {
    this.setOffer();
  }

  setOffer = () => {
    const { itemId, offerData } = this.state;
    if (itemId) {
      this.fetchOfferById();
    } else {
      this.setState({ offerData });
    }
  }

  getAllProducts = async (query = '') => {
    const url = allProductItemsPath(query);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data: products = [] } } = response;
      this.setState({ products });
    } catch (e) {
      this.setState({
        products: [],
      });
    }
  }

  getProductItemId = (offer) => {
    const { offerType, productOffers, ...content } = offer;
    let modifiedOffer = offer;
    if (offerType !== 'PRODUCT_OFFER' || productOffers.length === 0) {
      this.setState({
        offerData: modifiedOffer,
      });
    } else {
      const [productWithOffer] = productOffers;
      modifiedOffer = { ...productWithOffer, ...content, offerType };
      this.fetchProductName(modifiedOffer);
      this.fetchfreeItemName(modifiedOffer);
    }
  }


  fetchProductName = async (productItem) => {
    const { productItemId: id } = productItem;
    try {
      const url = productItemPath(id);
      const res = await apiCall.sendRequest('get', url);
      const { data = [] } = res;
      this.setState({
        offerData: productItem,
        productName: data.name,
      });
    } catch (e) {
      // error handling
    }
  }

  fetchOfferById = async () => {
    const { itemId } = this.state;
    try {
      const url = getOfferById(itemId);
      const response = await apiCall.sendRequest('get', url);
      const { data: { data: { content = [] } } } = response;
      if (content.length > 0) this.getProductItemId(content[0]);
    } catch (e) {
      // error handling
    }
  }

  fetchfreeItemName = async (productItem) => {
    const { freeItemId: id } = productItem;
    try {
      const url = productItemPath(id);
      const res = await apiCall.sendRequest('get', url);
      const { data = [] } = res;
      this.setState({
        offerData: productItem,
        freeItemName: data.name,
      });
    } catch (e) {
      // error handling
    }
  }

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    if (isEmpty(value)) {
      this.setError(key, true);
      return false;
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const offer = {};
    offer.title = this.state.offerData.title;
    offer.offerType = this.state.offerData.offerType;
    offer.startTime = this.state.offerData.startTime;
    offer.endTime = this.state.offerData.endTime;
    let isValid = true;

    const validateEntities = ['title', 'offerType', 'startTime', 'endTime'];

    for (const property in offer) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, offer[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleOfferDetails = async () => {
    if (!this.isFormValid()) {
      return;
    }
    const { itemId: id, offerData } = this.state;
    const { history } = this.props;
    try {
      if (id) {
        await apiCall.sendRequest('put', offerByIdPath(id), offerData);
      } else {
        await apiCall.sendRequest('post', allOffersPath, offerData);
      }
      history.goBack();
    } catch (e) {
      // error handling
    }
  }

  handleProductSearch = (query) => {
    this.getAllProducts(query);
  }


isValidDateRange = (value, key) => {
  const { offerData: { endTime, startTime } } = this.state;
  const isEmpty = value === '';
  const endDateValidation = (key === 'endTime' && (startTime === '' || moment(value).isSameOrAfter(moment(startTime))));
  const startDateValidation = (key === 'startTime' && (endTime === '' || moment(value).isSameOrBefore(moment(endTime))));
  return isEmpty || endDateValidation || startDateValidation;
}

handleChange = (value, key) => {
  let { offerData } = this.state;
  const { products } = this.state;
  const isNotDateField = (key !== 'endTime') && (key !== 'startTime');
  if (isNotDateField || this.isValidDateRange(value, key)) {
    offerData = {
      ...offerData,
      [key]: value,
    };
  }
  const numberKeys = ['limitPerUser', 'paybackSubType', 'paybackType', 'paybackValue', 'minQuantityForOffer', 'minOrderForOffer','minAmountForOffer', 'price'];
  if (numberKeys.includes(key)) {
    if (value === '' || re.test(value)) {
      this.setState({
        offerData,
        products,
      });
    }
  } else {
    this.setState({
      offerData,
      products,
    });
  }
};


render() {
  const {
    offerTypes, payBackType, payBackSubType, offerSubType, offerAppliedFor, offerFrequency,
    history,
  } = this.props;

  const {
    RECHARGE_OFFER, REFERRAL_OFFER, HAPPY_HOUR_OFFER, ...offerSelection
  } = offerTypes;

  const {
    offerData, products, productName, freeItemName,
  } = this.state;

  const {
    id,
    title,
    description,
    appliedFor,
    limitPerUser = 0,
    startTime,
    endTime,
    maxPaybackAmount = 0,
    paybackSubType = 0,
    paybackType = 0,
    paybackValue = 0,
    deepLink = 'null',
    offerType,
    shortDescription,
    frequency,
    minQuantityForOffer = 0,
    minOrderForOffer = 0,
    offerSubtype,
    subtypeIds = 0,
    overlayId = 0,
    minAmountForOffer = 0,
    price = 0,
    // imageUrl,
  } = offerData;
  return (
    <div>
      <NavBar />
      <div style={styles.div}>
        <Grid style={{ padding: '2em' }}>
          <Row>
            <ColMod xs md lg={15}>
              <Row>
                <Typography variant="body2" color="secondary" style={{ fontSize: '20px' }}>
                  {COMPONENT_TITLE}
                </Typography>
              </Row>
              <Row>
                <Typography variant="caption" color="error">
                  *Required
                </Typography>
              </Row>
              <Row>
                <ColMod lg={4}>
                  <FormHelperText>Id</FormHelperText>
                  <TextField name="Id" fullWidth value={id || ''} disabled />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Title*</FormHelperText>
                  <TextField
                    name="title"
                    fullWidth
                    required
                    error={this.state.error.title}
                    value={title}
                    onChange={e => this.handleChange(e.target.value, 'title')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4} style={{ paddingRight: '0px' }}>
                  <FormHelperText>Description</FormHelperText>
                  <TextField
                    name="description"
                    fullWidth
                    value={description}
                    onChange={e => this.handleChange(e.target.value, 'description')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Selected Offer Type*</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="offerType"
                    required
                    error={this.state.error.offerType}
                    value={offerType}
                    onChange={e => this.handleChange(e.target.value, 'offerType')}
                    disabled={id}
                  >
                    {Object.keys(offerSelection).map(type => (
                      <MenuItem value={type}>{offerSelection[type]}</MenuItem>
                                    ))}
                  </Select>
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>PayBack Type</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="paybackType"
                    value={paybackType}
                    onChange={e => this.handleChange(e.target.value, 'paybackType')}
                    disabled={id}
                  >
                    {Object.keys(payBackType).map(type => (
                      <MenuItem value={type}>{type}</MenuItem>
                                    ))}
                  </Select>
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>PayBack SubType</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="paybackSubType"
                    value={paybackSubType}
                    onChange={e => this.handleChange(e.target.value, 'paybackSubType')}
                    disabled={id}
                  >
                    {Object.keys(payBackSubType).map(subType => (
                      <MenuItem value={subType}>{subType}</MenuItem>
                                    ))}
                  </Select>
                </ColMod>

                <ColMod lg={4}>
                  <FormHelperText>Applied For</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="appliedFor"
                    value={appliedFor}
                    onChange={e => this.handleChange(e.target.value, 'appliedFor')}
                    disabled={id}
                  >
                    {Object.keys(offerAppliedFor).map(applied => (
                      <MenuItem value={applied}>{offerAppliedFor[applied]}</MenuItem>
                                    ))}
                  </Select>
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Frequency</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="frequency"
                    value={frequency}
                    onChange={e => this.handleChange(e.target.value, 'frequency')}
                    disabled={id}
                  >
                    {
                      Object.keys(offerFrequency).map(frequencyOffer => (
                      <MenuItem value={frequencyOffer}>{offerFrequency[frequencyOffer]}</MenuItem>
                      ))}
                  </Select>
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Offer Subtype</FormHelperText>
                  <Select
                    style={{ marginTop: 16 }}
                    fullWidth
                    name="offerSubtype"
                    value={offerSubtype}
                    onChange={e => this.handleChange(e.target.value, 'offerSubtype')}
                    disabled={id}
                  >
                    {Object.keys(offerSubType).map(Subtype => (
                      <MenuItem value={Subtype}>{offerSubType[Subtype]}</MenuItem>))}
                  </Select>

                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Max Payback Amount</FormHelperText>
                  <TextField
                    name="maxPaybackAmount"
                    fullWidth
                    value={maxPaybackAmount}
                    onChange={e => this.handleChange(e.target.value, 'maxPaybackAmount')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Min Amount For Offer</FormHelperText>
                  <TextField
                    name="minAmountForOffer"
                    fullWidth
                    value={minAmountForOffer}
                    onChange={e => this.handleChange(e.target.value, 'minAmountForOffer')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>limit Per User</FormHelperText>
                  <TextField
                    name="limitPerUser"
                    fullWidth
                    value={limitPerUser}
                    onChange={e => this.handleChange(e.target.value, 'limitPerUser')}
                    disabled={id}
                  />
                </ColMod>

                <ColMod lg={4}>
                  <FormHelperText>PayBack Value</FormHelperText>
                  <TextField
                    name="paybackValue"
                    fullWidth
                    value={paybackValue}
                    onChange={e => this.handleChange(e.target.value, 'paybackValue')}
                    disabled={paybackType === 'FREEBIE' || id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Short Description</FormHelperText>
                  <TextField
                    fullWidth
                    name="shortDescription"
                    value={shortDescription}
                    onChange={e => this.handleChange(e.target.value, 'shortDescription')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Min Quantity For Offer</FormHelperText>
                  <TextField
                    name="minQuantityForOffer"
                    fullWidth
                    value={minQuantityForOffer}
                    onChange={e => this.handleChange(e.target.value, 'minQuantityForOffer')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Min OrderForOffer</FormHelperText>
                  <TextField
                    name="minOrderForOffer"
                    fullWidth
                    value={minOrderForOffer}
                    onChange={e => this.handleChange(e.target.value, 'minOrderForOffer')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Subtype Ids</FormHelperText>
                  <TextField
                    name="subtypeIds"
                    fullWidth
                    value={subtypeIds}
                    onChange={e => this.handleChange(e.target.value, 'subtypeIds')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Overlay Id</FormHelperText>
                  <TextField
                    name="overlayId"
                    fullWidth
                    value={overlayId}
                    onChange={e => this.handleChange(e.target.value, 'overlayId')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>Start Time*</FormHelperText>
                  <TextField
                    id="datetime-local"
                    type="datetime-local"
                    name="startTime"
                    required
                    error={this.state.error.startTime}
                    fullWidth
                    value={startTime}
                    onChange={e => this.handleChange(e.target.value, 'startTime')}
                    disabled={id}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <FormHelperText>End Time*</FormHelperText>
                  <TextField
                    id="datetime-local"
                    type="datetime-local"
                    name="endTime"
                    required
                    error={this.state.error.endTime}
                    fullWidth
                    value={endTime}
                    onChange={e => this.handleChange(e.target.value, 'endTime')}
                    disabled={id}
                  />
                </ColMod>

                <ColMod lg={4}>
                  <FormHelperText>Deep Link</FormHelperText>
                  <TextField
                    name="deepLink"
                    fullWidth
                    value={deepLink}
                    onChange={e => this.handleChange(e.target.value, 'deepLink')}
                    disabled={id}
                  />
                </ColMod>
              </Row>
            </ColMod>
          </Row>
          <Grid>
            { offerType === 'PRODUCT_OFFER' &&
            <div>
              <h3>PRODUCT_OFFER</h3>
              <Row>
                <ColMod xs md lg={15}>
                  <Row>
                    <ColMod lg={4}>
                      <FormHelperText>Select Product Item</FormHelperText>
                      <SearchWithSuggestions
                        key={productName}
                        searchQuery={productName}
                        suggestions={products}
                        onChange={this.handleProductSearch}
                        onSelect={(product) => { this.handleChange(product.id, 'productItemId'); }}
                      />
                    </ColMod>
                    <ColMod lg={4}>
                      <FormHelperText>Product MRP</FormHelperText>
                      <TextField
                        name="price"
                        fullWidth
                        value={price}
                        onChange={e => this.handleChange(e.target.value, 'price')}
                        disabled={id}
                      />
                    </ColMod>
                  </Row>
                </ColMod>
              </Row>
            </div>
            }
            { paybackType === 'FREEBIE' &&
              <ColMod lg={4}>
                <FormHelperText>Select Buy One Get One Item</FormHelperText>
                <SearchWithSuggestions
                  key={freeItemName}
                  searchQuery={freeItemName}
                  suggestions={products}
                  onChange={this.handleProductSearch}
                  onSelect={(product) => { this.handleChange(product.id, 'freeItemId'); }}
                />
              </ColMod>
            }
          </Grid>
          <div style={{ float: 'right', paddingRight: '2em' }}>
            <Button onClick={() => { history.goBack(); }} style={{ marginRight: '2em' }} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={this.handleOfferDetails}
              variant="contained"
              color="secondary"
              disabled={id}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
}
}

EditOfferComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      itemId: PropTypes.number.isRequired,
    }),
  }),
  history: PropTypes.instanceOf(Object),
  offerTypes: PropTypes.instanceOf(Object),
  payBackType: PropTypes.instanceOf(Object),
  payBackSubType: PropTypes.instanceOf(Object),
  offerSubType: PropTypes.instanceOf(Object),
  offerAppliedFor: PropTypes.instanceOf(Object),
  offerFrequency: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status } = state;
  const {
    offerTypes,
    payBackType,
    payBackSubType,
    offerSubType,
    offerAppliedFor,
    offerFrequency,
  } = Status;
  return {
    offerTypes,
    payBackType,
    payBackSubType,
    offerSubType,
    offerAppliedFor,
    offerFrequency,
  };
};
export default withRouter(connect(mapStateToProps, null)(EditOfferComponent));
