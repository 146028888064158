import React from 'react';
import QuantityComponent from "../component/QuantityComponent";

class QuantityContainer extends React.Component {
  render() {
    return (
      <div>
        <QuantityComponent />
      </div>
    );
  }
}


export default (QuantityContainer);

