import React from 'react';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Select,
  Grid,
  FormHelperText,
  Drawer,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import apiCall, { allProductItemsPath, productItemPath } from '../../../api/NetworkHandler';
import SearchWithSuggestions from '../../Commons/components/SearchWithSuggestions';
import CreateOfferCampaign from './CreateOfferCampaign';
import RegionSelector from '../../../components/Region/Selector';
import { message } from 'antd';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
  buttonAlignment: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
};

let clearFilters = false;

class OfferCampaignSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      products: [],
      searchQuery: '',
      region: null,
    };
  }

  componentDidMount() {
    const { queryId } = this.props;
    if (queryId) {
      this.getProductById(queryId);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { queryId } = this.props;
    if (queryId !== prevProps.queryId && queryId) {
      this.getProductById(queryId);
    }
  }

  getProductById = async (productItemId) => {
    const url = productItemPath(productItemId);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data = {} } = response;
      const { name = '' } = data;
      this.setState({
        products: [data],
        searchQuery: name,
      });
    } catch (e) {
      this.setState({
        products: [],
        searchQuery: '',
      });
    }
  }

  getAllProducts = async (query = '') => {
    const url = allProductItemsPath(query);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { data = [] } } = response;
      this.setState({
        products: data,
      });
    } catch (e) {
      this.setState({
        products: [],
      });
    }
  }

  handleKeyPress = (event) => {
    const { onButtonClick } = this.props;
    switch (event.keyCode) {
      case 13:
        onButtonClick('search', 0);
        break;
      default: break;
    }
  }


  handleProductSearch = (query) => {
    this.setState({ searchQuery: query });
    this.getAllProducts(query);
  }

  handleProductSelection = (product) => {
    const { id } = product;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        productItemId: id,
      },
    }));
  }

  handleClear = () => {
    const { onButtonClick } = this.props;
    const newFilters = {};
    onButtonClick('clear', newFilters);
    this.setState({
      filters: newFilters,
      searchQuery: '',
    }, () => {
      clearFilters = !clearFilters;
      onButtonClick('search', 0);
    });
  }


  isValidDateRange = (value, key) => {
    const { filters: { startTime, endTime } } = this.state;
    const isEmpty = value === '';
    const endTimeValidation = (key === 'endTime' && (startTime === '' || moment(value).isSameOrAfter(moment(startTime))));
    const startTimeValidation = (key === 'startTime' && (endTime === '' || moment(value).isSameOrBefore(moment(endTime))));
    return isEmpty || endTimeValidation || startTimeValidation;
  }

  handleChange = (value, key) => {
    const { onFilterChange } = this.props;
    const isNotDateField = (key !== 'endTime') && (key !== 'startTime');
    if (isNotDateField || this.isValidDateRange(value, key)) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [key]: value,
        },
      }));
      onFilterChange(key, value);
    }
  }

  handleOpenNewOffer = () => {
    this.setState({
        openNewOfferWindow: true,
    });
  }

  handleCloseNewOffer = () => {
    this.setState({ openNewOfferWindow: false });
  }

  handleRegionChange = (region) => {
    this.setState({
      region,
      disableCreateButton: false,
    });
  }

  render() {
    const { onButtonClick } = this.props;

    const {
      filters, openNewOfferWindow, region, 
    } = this.state;

    const {
      startTime,
      endTime,
      query = '',
    } = filters;

    return (
      <div style={styles.container} key={clearFilters}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={4}>
                <TextField
                  label="Quick Search (By Name)"
                  fullWidth
                  name="query"
                  onChange={e => this.handleChange(e.target.value, 'query')}
                  value={query.replace(/%20/g, " ")}
                  // onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  label="Start Date (Delivery)"
                  type="date"
                  value={startTime}
                  defaultValue={startTime}
                  InputLabelProps={{
                  shrink: true,
                }}
                  onChange={e => this.handleChange(e.target.value, 'startTime')}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  fullWidth
                  label="End Date (Delivery)"
                  type="date"
                  value={endTime}
                  min={startTime}
                  defaultValue={endTime}
                  InputLabelProps={{
                  shrink: true,
                }}
                  onChange={e => this.handleChange(e.target.value, 'endTime')}
                />
              </Grid>
              
              {/* <Grid item xs={6} md={3}>
                <Typography variant="caption"> Region </Typography>
                <RegionSelector
                  onSelect={this.handleRegionChange}
                  selected={region}
                  isClearable={false}
                />
              </Grid> */}

              {/* <Grid item xs={6} md={3}>
                <Select
                  style={{ marginTop: 16 }}
                  native
                  fullWidth
                  label="Offer Type"
                  value={offerType}
                  onChange={e => this.handleChange(e.target.value, 'offerType')}
                >
                  <option value="">Offers Type</option>
                  {Object.keys(offerSelection).map(type => (
                    <option value={type}>{type}</option>
                ))}
                </Select>
              </Grid> */}

              {/* <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Product</FormHelperText>
                <SearchWithSuggestions
                  suggestions={products}
                  searchQuery={searchQuery}
                  onChange={this.handleProductSearch}
                  onSelect={(product) => { this.handleChange(product.id, 'productItemId'); }}
                />
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => onButtonClick('search', 0)}
                >
                  Search
                </Button>
              </Grid>
              <Grid item xs={3} md={3} style={styles.buttonAlignment}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={this.handleClear}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={6} md={6} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                <Button color="primary" variant="outlined" onClick={this.handleOpenNewOffer}> Create Campaign </Button>
              </Grid>
            </Grid>
          </Grid>
          <span style={{ color: 'red' }}>** Start Date and End Date in the offer reffered to delivery start date and end date.</span>
        </Grid>
        <Drawer anchor="right" open={openNewOfferWindow} onClose={() => { this.handleCloseNewOffer() }}>
          <CreateOfferCampaign
            onClose={this.handleCloseNewOffer}
            region={region}
            onButtonClick={onButtonClick}
          />
        </Drawer>
      </div>
    );
  }
}

OfferCampaignSearch.propTypes = {
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  filters: PropTypes.func,
  offerTypes: PropTypes.instanceOf(Object),
  queryId: PropTypes.string,
};


export default withRouter(OfferCampaignSearch);
