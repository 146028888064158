import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavBar from './NavBar';
import VendorTable from './VendorTable';
import SearchBar from './SearchBar';
import apiCall, { getVendors } from "../api/NetworkHandler";


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      links: {},
      vendor: null,
      vendorLocations: [],
      vendorCatalogs: [],
      searchQuery: '',
      searchedResult: false,
    };
  }

  componentDidMount() {
    this.fetchVendors(false);
  }

  getVendorDetails = (vendor) => {
    console.log(vendor);
    this.setState({
      vendorLocations: vendor.vendorLocations,
      vendorCatalogs: [],
      vendor,
    });
  }

  searchVendors = (event) => {
    if (event.key === 'Enter') {
      this.fetchVendors(true);
    }
  }

  handleQueryChange = (event) => {
    const {value} = event.target;
    this.setState({
      searchQuery: value,
    });
  }


  fetchVendors = (search) => {
    if (search) {
      apiCall.sendRequest('get', `/api/v1/vendors/search/findVendors?query=${this.state.searchQuery}`)
        .then(({ data }) => {
          this.setState({
            data: data._embedded.vendors,
            links: data._links,
            searchedResult: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // apiCall.sendRequest('get', `/api/v1/vendors`)
      apiCall.sendRequest('get', getVendors)
        .then(({ data }) => {
          this.setState({
            data: data._embedded.vendors,
            links: data._links,
            searchedResult: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  fetchPaginatedVendor = (url) => {
    apiCall.sendRequest('get', url)
      .then(({ data }) => {
        this.setState({
          data: data._embedded.vendors,
          links: data._links,
          searchedResult: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  clearAll = () => {
    this.setState({
      searchQuery: '',
    }, () => {
      this.fetchVendors(true);
    });
  }

  renderLocations = () => (this.state.vendorLocations ? this.state.vendorLocations.map(location => 
    (<div>
      <div style={{ display: 'flex' }}>
        <Typography variant="body1" color="inherit" style={{ flex: 1 }}>
          <b>{location.city}</b>
        </Typography>
        <Typography variant="body1" color="inherit" style={{ flex: 1, color: '#f50057', marginLeft: '20px' }}>
          <b>{ location.active ? 'Active' : 'Inactive' }</b>
        </Typography>
      </div>
      <Typography variant="body1" color="inherit">
        { `House No.: ${location.house || 'NA'}, Floor: ${location.floor || 'NA'}, Block: ${location.block || 'NA'}, Building: ${location.building || 'NA'}` }
      </Typography>
      <Typography variant="body1" color="inherit">
        { `Street: ${location.street || 'NA'}, Sub Locality: ${location.subLocality || 'NA'}, Locality: ${location.locality || 'NA'}` }
      </Typography>
      <Typography variant="body1" color="inherit">
        { `City: ${location.city || 'NA'}, PIN Code: ${location.pincode || 'NA'}` }
      </Typography>
      <Typography variant="body1" color="inherit">
        Phone: { location.phoneNumber || 'NA' }
      </Typography>
      <Typography variant="body1" color="inherit">
        Email: { location.email ? location.email.split(',').map(email => (<div><span style={{ marginLeft: '30px' }}>{email}</span><br /></div>)) : 'No Email' }
      </Typography>
      <br />
    </div>)) : <div />)

  renderCatalogs = () => (this.state.vendorCatalogs ? this.state.vendorCatalogs.map(catalog => (<div>
    <div style={{ display: 'flex' }}>
      <Typography variant="body1" color="inherit" style={{ flex: 1 }}>
        <b>{catalog.name}</b>
      </Typography>
      <Typography variant="body1" color="inherit" style={{ flex: 1, color: '#f50057', marginLeft: '20px' }}>
        <b>{ catalog.active ? 'Active' : 'Inactive' }</b>
      </Typography>
    </div>
  </div>)) : <div />)


  render() {
    return (
      <div>
        <NavBar />
        <SearchBar 
          searchQuery={this.state.searchQuery}
          handleQueryChange={this.handleQueryChange}
          searchVendors={this.searchVendors}
          onClear={this.clearAll}
        />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <VendorTable getVendorData={this.getVendorDetails} data={this.state.data} links={this.state.links} fetchPaginatedVendor={this.fetchPaginatedVendor} searchedResult={this.state.searchedResult} />
          </div>
          <div style={styles.paperRight}>
            { this.state.vendor
              ?
                <div>
                  <Typography variant="body2" color="inherit" style={{ borderBottom: 'solid 1px #e0e0e0', height: '40px' }}>
                  Vendor Details
                  </Typography>
                  <Typography variant="title" color="secondary" style={{ marginTop: '10px' }}>
                    {this.state.vendor.name}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '20px', marginBottom: '10px', textDecoration: 'underline' }}>
                    <Link to={`/catalog/${this.state.vendor.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    Products Catalogs
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  Payment Details
                  </Typography>
                  <Typography variant="body1" color="inherit">
                    {this.state.vendor.bankName}
                  </Typography>
                  <Typography variant="body1" color="inherit" style={{ marginBottom: '10px' }}>
                    {this.state.vendor.bankAddress}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  Account Number: {this.state.vendor.bankAccountNo}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  IFSC Code: {this.state.vendor.bankIfscCode}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  GSTIN: {this.state.vendor.gstin}
                  </Typography>
                  <Typography variant="body1" color="inherit">
                  PAN: {this.state.vendor.pan}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ marginTop: '20px', marginBottom: '10px' }}>
                  Vendor Locations
                  </Typography>
                  {this.renderLocations()}
                </div>
              : <div />}
          </div>
        </div>
      </div>
    );
  }
}


export default Dashboard;
