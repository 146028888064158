import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { ColMod } from './lib';
import apiCall, { searchProductsPath, searchProductsByRegionId } from "../api/NetworkHandler";
import { debounce } from "lodash";

var versionDict = {
  "productRequestId": 0
};
class ProductSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
    };
  }

handleSearch = (searchText) => {
  this.setState({ searchText }, () => {
    this.fetchProducts();
  });
}

// searchProducts = async () => {
//   const { searchUrl, customerId = '' } = this.props;
//   const { searchText } = this.state;
//   let url = searchProductsPath(searchText);
//   if (searchUrl) {
//     url = searchUrl(customerId, searchText);
//   }
//   const response = await apiCall.sendRequest('get', url);
//   if (searchUrl) {
//     const { data } = response.data;
//     this.setState({ suggestions: data });
//   } else {
//     const { _embedded } = response.data;
//     this.setState({ suggestions: _embedded['product-items'] });
//   }
// }

fetchProducts = debounce( async () => {
  const { searchText } = this.state;
  const { regionId = null } = this.props;
  try {
    versionDict.productRequestId += 1;
    let prevRequestId = versionDict.productRequestId;
    const response = await apiCall.sendRequest('get', searchProductsByRegionId(searchText, regionId));
    const { data: { data = [] } = {} } = response;
    const productItems = this.getProductItemsFromProduct(data);
    if (versionDict.productRequestId == prevRequestId) {
      this.setState({
        suggestions: productItems,
      });
    }
  } catch (error) {
    this.setState({
      suggestions: [],
    });
  }
}, 750);

getProductItemsFromProduct = (data) => {
  let productItems = [];
  data.forEach(item => {
    item.productItems.forEach(productItem => {
      productItems.push(productItem);
    })
  });
  return productItems;
};

render() {
  const { showOfferPopup } = this.props;
  const calculateZoom = (75 + ((window.innerHeight - 640) / 12.48)).toFixed(2);
  document.body.style.zoom = `${calculateZoom}%`;
	return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={showOfferPopup}
      onClose={() => this.props.handleClose(false)}
      onBackdropClick={this.props.handleClose}
    >
      <div style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            width: '70%',
            height: '90%',
          }}
      >
        <Button style={{ float: 'right' }} onClick={() => this.props.handleClose(false)} color="secondary" aria-label="close">
          <CloseIcon />
        </Button>
        <Grid style={{ padding: '2em', background:'#FFFFFF' }}>
          <Row>
            <ColMod xs md lg={15}>
              <Row>
                <Typography variant="body2" color="secondary">
                      Search Products
                </Typography>
              </Row>
              <Row>
                <ColMod lg={6}>
                  <TextField
                    value={this.state.searchText}
                    onChange={(event) => { this.handleSearch(event.target.value); }}
                    label="Product Name"
                  />
                </ColMod>
                <ColMod lg={6}>
                  <Button color="secondary" variant="contained" onClick={this.fetchProducts}>
                        Search
                  </Button>
                </ColMod>
              </Row>
              <Row>
                <ColMod lg={12}>
                  <div style={{ overflowX: 'auto', overflowY: 'auto', height: window.innerHeight * 0.6 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>SKU</TableCell> */}
                          <TableCell>Product</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell>Selling Price</TableCell>
                          <TableCell>Offer Price</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.suggestions.map((suggestion, index) => (
                          <TableRow key={index}>
                            {/* <TableCell>{suggestion.sku}</TableCell> */}
                            <TableCell>{suggestion.name}</TableCell>
                            <TableCell>{suggestion.unitMeasure}</TableCell>
                            <TableCell>{suggestion.sellingPrice}</TableCell>
                            <TableCell>{suggestion.offerPrice ? suggestion.offerPrice : "No offer" }</TableCell>
                            <TableCell><Button variant="contained" color="secondary" onClick={() => { this.props.selectProduct(suggestion); }}>Select</Button></TableCell>
                          </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </div>
                </ColMod>
              </Row>
            </ColMod>
          </Row>
        </Grid>
      </div>
    </Modal>
)}};

ProductSelection.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  searchUrl: PropTypes.func,
  customerId: PropTypes.string,
};

export default ProductSelection;
