import { Button, Grid, InputAdornment, TextField, FormHelperText, Select, MenuItem } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
};

class TicketSearch extends Component {
  onChangeFilter = (event) => {
    const { name, value } = event.target;
    const { onFilterChange } = this.props;
    onFilterChange(name, value);
  };

  clearAll = () => {
    const { onButtonClick } = this.props;
    onButtonClick('clear', {});
  };

  render() {
    const {
      onButtonClick, filters = {}, agents, ticketStatuses = {},
    } = this.props;
    const {
      query,
      mobile,
      customerId,
      fromDate,
      toDate,
      status,
      agentId,
    } = filters;
    return (
      <div style={styles.container}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="query"
                  label="Quick Search"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={query || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="fromDate"
                  fullWidth
                  type="date"
                  label="From Date"
                  name="fromDate"
                  onChange={this.onChangeFilter}
                  value={fromDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="toDate"
                  fullWidth
                  type="date"
                  label="To Date"
                  name="toDate"
                  onChange={this.onChangeFilter}
                  value={toDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="customerId"
                  label="Customer Id"
                  fullWidth
                  type="number"
                  name="customerId"
                  onChange={this.onChangeFilter}
                  value={customerId || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="mobile"
                  label="Mobile No."
                  fullWidth
                  type="number"
                  name="mobile"
                  onChange={this.onChangeFilter}
                  value={mobile || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Status</FormHelperText>
                <Select
                  name="status"
                  style={{ marginTop: -3 }}
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={status || ''}
                >
                {
                  Object.keys(ticketStatuses).map(ticketStatus => (
                    <MenuItem value={ticketStatus}>{ticketStatuses[ticketStatus]}</MenuItem>
                  ))
                }
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Agent</FormHelperText>
                <Select
                  name="agentId"
                  style={{ marginTop: -3 }}
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={agentId || ''}
                >
                  {agents.map(agent => (
                    <MenuItem value={agent.id}>{agent.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={6} md={6}>
                <Button color="secondary" variant="contained" onClick={() => onButtonClick('search', 0)}>
                  Search
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button color="secondary" variant="contained" onClick={() => onButtonClick('add_ticket')}>
                  Create Ticket
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button color="secondary" variant="outlined" onClick={this.clearAll}>
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onButtonClick('download')}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TicketSearch.propTypes = {
  filters: PropTypes.instanceOf(Object),
  onFilterChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  agents: PropTypes.instanceOf(Array),
  ticketStatuses: PropTypes.instanceOf(Object),
};

export default TicketSearch;
