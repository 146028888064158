import React, { Component } from 'react';
import moment from 'moment';
import NavBar from '../components/NavBar';
import SearchBar from '../modules/Commons/containers/SearchBar';
import ConsolidatedReportsSearch from '../modules/DeliveryBoys/components/CRSearch';
import apiCall, { fetchDeliveryBoysCRByPhone } from '../api/NetworkHandler';
import CRList from '../modules/DeliveryBoys/components/CRList';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';

const defaultDate = moment()
  .add(1, 'days')
  .format('YYYY-MM-DD');

class DeliveryConsolidatedReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        query: '',
        startDate: defaultDate,
        endDate: defaultDate,
        status: null,
      },
      ConsolidatedReportsList: [],
      totalElements: 0,
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.fetchCrList();
  }

  fetchCrList = async (page = 0) => {
    const { filters } = this.state;
    const { query } = filters;
    const response = await apiCall.sendRequest('get', fetchDeliveryBoysCRByPhone(query));
    if (response.data) {
      const { data: { content = [], totalElements = 0 } = {}, data = {} } = response.data;
      this.setState({
        ConsolidatedReportsList: data,
        totalElements,
      });
    }
  };

  handleFilterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  handleChange = (filters, refresh = false) => {
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.fetchCrList();
        }
      },
    );
  };

  handleSearch = (page = 0) => {
    this.setState({
      currentPage: page,
    });
    this.fetchCrList(page);
  };

  handleClick = (name, params = null) => {
    switch (name) {
      case 'search':
        this.handleSearch();
        break;
      default:
        break;
    }
  };

  clearAll = () => {
    this.setState(
      {
        filters: {
          query: '',
          startDate: defaultDate,
          endDate: defaultDate,
          prId: '',
        },
      },
      () => {
        this.handleSearch();
      },
    );
  };

  render() {
    const {
      filters, ConsolidatedReportsList = [], totalElements,
      currentPage = 0,
    } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title="Delivery Boys Consolidated Reports"
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
          data={ConsolidatedReportsList}
        >
          <ConsolidatedReportsSearch />
        </SearchBar>
        <div>
          <CRList
            data={ConsolidatedReportsList}
            phoneNumber={filters.query}
            totalElements={totalElements}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={currentPage}
            onPageChange={this.handleSearch}
          />
        </div>
      </div>
    );
  }
}

export default DeliveryConsolidatedReports;
