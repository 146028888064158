import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const styles = {
  title: {
    padding: 10,
  },
};

const Title = (props) => {
  const { title } = props;
  return (

    <div style={styles.title}>
      <Typography variant="title" color="inherit">
        {title}
      </Typography>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
};


export default Title;
