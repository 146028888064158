import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import ArrowBack from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import BASE_URL from '../../../api/config';
import apiCall, { getAllBrandsPath } from '../../../api/NetworkHandler';
import { Button } from '../../../ui';
import { default as ReactSelect } from 'react-select';
import ImageContainer from './ImageContainer';
import sortBy from 'lodash/sortBy';
import ProgressBar from '../../../components/States/ProgressBar';
import SectionMessage from '@atlaskit/section-message';
import GstSelection from '../../../components/GstSelection';
import { stringFormatting, removeSpaceFromString } from '../../../utils/stringFormatting';


class ProductForm extends Component {
  constructor(props) {
    super(props);
    const { product } = this.props;
    this.state = {
      product,
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
      productPatchData: {},
      invalidName: false,
      invalidTitle: false,
      invalidBrandName: false,
      invalidCategoryName: false,
      invalidDescription: false,
      errorMessage: '',
      isSubmitInProcess: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { product } = nextProps;
    const { product: prevProduct } = prevState;
    return {
      product: {
        ...product,
        ...prevProduct,
      },
    };
  }

  updateImageUrls = () => {
    const { urls: prevUrls = [], product } = this.state;
    let { stagingProductImages = [] } = product;
    if (!stagingProductImages) {
      stagingProductImages = []
    }
    let urls = [];
    urls = prevUrls.map((url) => {
      const image = stagingProductImages.find(img => img.position === url.position);
      if (image && !url.imageUrl) return image;
      return {
        ...url,
        stagingProduct: product.id ? `${BASE_URL.BASE_URL}/api/v1/vendor-products/${product.id}` : null,
      };
    });
    return urls;
  }

  handleInputChange = prop => (event) => {
    const { product } = this.state;
    const { value } = event.target;
    product[prop] = value;
    this.setState((prevState) => {
      const productPatchData = { ...prevState.productPatchData };
      productPatchData[prop] = value;
      return {
        product,
        productPatchData
      }
    })
  }

  selectBrand = (brand) => {
    let { product, productPatchData } = this.state;
    if (!brand) {
      brand = {};
    }
    product = {
      ...product,
      brandId: brand.value,
      brandName: brand.label,
      brand: `${BASE_URL.BASE_URL}/api/v1/brands/${brand.value}`,
    };
    productPatchData = {
      ...productPatchData,
      brandId: brand.value,
      brandName: brand.label,
      brand: `${BASE_URL.BASE_URL}/api/v1/brands/${product.brandId}`,
    }
    this.setState({
      product,
      productPatchData,
    });
  }

  selectCategory = (category) => {
    let { product, productPatchData } = this.state;
    if (!category) {
      category = {};
    }
    product = {
      ...product,
      categoryId: category.value,
      categoryName: category.label,
      category: `${BASE_URL.BASE_URL}/api/v1/categories/${category.value}`,
    };
    productPatchData = {
      ...productPatchData,
      categoryId: category.value,
      categoryName: category.label,
      category: `${BASE_URL.BASE_URL}/api/v1/categories/${product.categoryId}`,
    }
    this.setState({
      product,
      productPatchData,
    });
  }

  validateForm = () => {
    const { product } = this.state;
    const {
      name,
      title,
      description,
      brandName,
      categoryName,
    } = product;
    let isFormValid = true;
    if (!name) {
      isFormValid = false;
      this.setState({
        invalidName: true,
        nameText: 'Name Field is Required',
      })
    } else {
      this.setState({
        invalidName: false,
        nameText: '',
      })
    }
    if (!title) {
      isFormValid = false;
      this.setState({
        invalidTitle: true,
        titleText: 'Title Field is Required',
      })
    } else {
      this.setState({
        invalidTitle: false,
        titleText: '',
      })
    }
    if (!description) {
      isFormValid = false;
      this.setState({
        invalidDescription: true,
        descriptionText: 'Description Field is Required',
      })
    } else {
      this.setState({
        invalidDescription: false,
        descriptionText: '',
      })
    }
    if (!brandName) {
      isFormValid = false;
      this.setState({
        invalidBrandName: true,
        brandText: 'Brand Field is Required',
      })
    } else {
      this.setState({
        invalidBrandName: false,
        brandText: '',
      })
    }
    if (!categoryName) {
      isFormValid = false;
      this.setState({
        invalidCategoryName: true,
        categoryText: 'Category Field is Required',
      })
    } else {
      this.setState({
        invalidCategoryName: false,
        categoryText: ''
      })
    }
    return isFormValid;
  }

  saveProduct = async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ isSubmitInProcess: true });
    const { productPatchData, product, urls } = this.state;
    const { onClose, onRefresh, match: { params } } = this.props;
    product.name = stringFormatting(product.name);
    if (Object.keys(productPatchData).length > 0 && productPatchData.name) {
      productPatchData.name = stringFormatting(productPatchData.name);
    }
    product.title = stringFormatting(product.title);
    if (Object.keys(productPatchData).length > 0 && productPatchData.title) {
      productPatchData.title = stringFormatting(productPatchData.title);
    }
    const stagingProductImages = urls.filter((url) => { if (url.imageUrl) return true; });
    if (stagingProductImages.length) {
      productPatchData.stagingProductImages = stagingProductImages;
    }
    try {
      if (product.id) {
        await apiCall.sendRequest('patch', `/api/v1/vendor-products/${product.id}`, productPatchData);
      } else {
        product.vendorCatalog = `${BASE_URL.BASE_URL}/api/v1/vendor-catalogs/${params.id}`;
        await apiCall.sendRequest('post', `/api/v1/vendor-products/`, product);
      }
      onClose();
      onRefresh();
      this.clearState();
      this.setState({ isSubmitInProcess: false });
    } catch (e) {
      // error handling
      const { data } = e.response;
      this.setState({
        errorMessage: data,
        isSubmitInProcess: false,
      });
    }
  }

  handleImageUpload = (imageUrl, position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  handleAttrChange = (event, i, field) => {
    const { product, productPatchData } = this.state;
    const { value } = event.target;
    let { stagingProductAttributes = [] } = product;
    if (!stagingProductAttributes) {
      stagingProductAttributes = [];
    }
    const tempObj = stagingProductAttributes[i] || {};
    if (field === 'value') {
      tempObj.value = value;
    } else {
      tempObj.name = value;
    }
    stagingProductAttributes[i] = tempObj;
    this.setState({
      product: {
        ...product,
        stagingProductAttributes,
      },
      productPatchData: {
        ...productPatchData,
        stagingProductAttributes,
      }
    });
  }

  clearImage = (position) => {
    this.setState((prevState) => {
      const index = prevState.urls.findIndex(url => position === url.position);
      return {
        urls: [
          ...prevState.urls.slice(0, index),
          {
            ...prevState.urls[index],
            imageUrl: null,
          },
          ...prevState.urls.slice(index + 1),
        ],
      };
    });
  }

  clearState = () => {
    const { onClear } = this.props;
    this.setState({
      product: {},
      productPatchData: {},
      urls: [{
        position: 'FRONT',
        imageUrl: null,
        id: null,
      }, {
        position: 'BACK',
        imageUrl: null,
        id: null,
      }, {
        position: 'LEFT_SIDE',
        imageUrl: null,
        id: null,
      }, {
        position: 'RIGHT_SIDE',
        imageUrl: null,
        id: null,
      }],
    });
    onClear();
  }

  brandOptions = () => {
    const searchText = '';
    apiCall.sendRequest('get', getAllBrandsPath(searchText))
      .then((response) => {
        const { data = {} } = response;
        const { _embedded = {} } = data;
        const { brands } = _embedded;
        let brandOptions = brands.map(brand => {
          return {
            label: brand.name,
            value: brand.id
          }
        })
        brandOptions = sortBy(brandOptions, 'label');
        this.setState({
          brandOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  categoryOptions = () => {
    const searchText = '';
    apiCall.sendRequest('get', `api/v1/categories/search/findCategories?query=${searchText}`)
      .then((response) => {
        let categoryOptions = response.data._embedded.categories.map(category => {
          return {
            label: category.name,
            value: category.id,
          }
        })
        categoryOptions = sortBy(categoryOptions, ['label']);
        this.setState({
          categoryOptions
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount = () => {
    this.brandOptions();
    this.categoryOptions();
  }

  openGstSelector = () => {
    this.setState({
      openGstSelector: true,
    });
  }

  handleGstSelectorClose = () => {
    this.setState({
      openGstSelector: false,
    });
  }

  selectGst = (gstData) => {
    let { product, productPatchData } = this.state;
    product = {
      ...product,
      gstRateId: gstData.id,
      hsnCode: gstData.hsnCode,
    };
    productPatchData = {
      ...productPatchData,
      gstRateId: gstData.id,
      hsnCode: gstData.hsnCode,
    }
    this.setState({
      product,
      productPatchData,
      openGstSelector: false,
    });
  }

  render() {
    const {
      product = {},
      brandOptions = [],
      categoryOptions = [],
      invalidName,
      invalidTitle,
      invalidBrandName,
      invalidCategoryName,
      invalidDescription,
      nameText,
      titleText,
      brandText,
      categoryText,
      descriptionText,
      errorMessage,
      isSubmitInProcess,
    } = this.state;

    const {
      name,
      title,
      description,
      stagingProductAttributes = [],
      tags,
      active,
      brandId,
      categoryId,
      hsnCode,
    } = product;

    const { onClose, isNewProductForm, taxType } = this.props;
    const urls = this.updateImageUrls();
    let attributes = [{ name: '', value: '' }];

    if (stagingProductAttributes && stagingProductAttributes.length > 0) attributes = stagingProductAttributes;

    return (
      <Grid style={{ width: '50vw' }}>
        <ProgressBar isLoading={isSubmitInProcess} />
        <Row between="lg">
          <ColMod>
            <Row start="lg">
              <ColMod>
                <ArrowBack
                  color="secondary"
                  style={{ fontSize: '1.6em', cursor: 'pointer' }}
                  onClick={() => { onClose(); this.clearState(); }}
                />
              </ColMod>
              <ColMod>
                <Typography variant="title" style={{ fontWeight: 'bold' }}>
                  Product Form
              </Typography>
              </ColMod>
            </Row>
          </ColMod>
          <ColMod>
            <Row>
              <ColMod>
                <Button onClick={() => { onClose(); this.clearState(); }} style={{ marginRight: '2em', color: 'black' }}>
                  Cancel
                </Button>
                <Button onClick={() => { this.saveProduct() }} variant="contained" color="secondary">
                  Save
                </Button>
              </ColMod>
            </Row>
          </ColMod>
        </Row>
        {errorMessage && <Row>
          <ColMod lg={12}>
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        <Row>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Basic Details
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <TextField
              label="Name"
              value={name || ''}
              onChange={this.handleInputChange('name')}
              error={invalidName}
              helperText={nameText}
              fullWidth
              inputProps={{ readOnly: !isNewProductForm }}
            />
          </ColMod>
          <ColMod lg={6}>
            <TextField
              value={title || ''}
              onChange={this.handleInputChange('title')}
              error={invalidTitle}
              helperText={titleText}
              label="Display Name"
              fullWidth
            />
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6}>
            <InputLabel error={invalidBrandName} style={{ fontSize: '0.7em' }}>Brand</InputLabel>
            <ReactSelect style={{ border: (invalidBrandName ? '1px solid red' : '') }} value={brandId || ''} options={brandOptions} onChange={(brand) => { this.selectBrand(brand) }} />
            <small style={{ color: 'red' }}>{brandText}</small>
          </ColMod>
          <ColMod lg={6}>
            <InputLabel error={invalidCategoryName} style={{ fontSize: '0.7em' }}>Category</InputLabel>
            <ReactSelect style={{ border: (invalidCategoryName ? '1px solid red' : '') }} value={categoryId || ''} options={categoryOptions} onChange={(category) => { this.selectCategory(category) }} />
            <small style={{ color: 'red' }}>{categoryText}</small>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={taxType === 'gst' ? 4 : 6}>
            <TextField
              fullWidth
              label="Tags"
              value={tags || ''}
              onChange={this.handleInputChange('tags')}
            />
          </ColMod>
          <ColMod lg={taxType === 'gst' ? 4 : 6}>
            <InputLabel style={{ fontSize: '0.7em' }}>Status</InputLabel>
            <RadioGroup onChange={this.handleInputChange('active')} value={active + ''} style={{ flexDirection: 'row' }}>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </ColMod>
          {taxType === 'gst' &&
          <ColMod lg={4}>
            <TextField
              value={hsnCode || ''}
              name="gstData"
              fullWidth
              onChange={() => { }}
              label="HSN Code"
              onClick={this.openGstSelector}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <Search color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
          </ColMod>}
        </Row>
        <Row>
          <ColMod lg={12}>
            <TextField
              value={description || ''}
              onChange={this.handleInputChange('description')}
              label="Description"
              fullWidth
              error={invalidDescription}
              helperText={descriptionText}
              multiline={true}
              rows={2}
              rowsMax={4}
            />
          </ColMod>
        </Row>
        <Row style={{ marginTop: '1.5em' }}>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Product Attributes
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod md={12} sm={12}>
            {
              attributes.map((x, i) => (
                <div key={i} style={{ paddingTop: '0.7em', display: 'flex' }}>
                  <TextField
                    value={attributes[i].name || ''}
                    onFocus={() => { this.setState({ tracer: i, valueSelect: false }); }}
                    onChange={(event) => { this.handleAttrChange(event, i, 'name'); }}
                    label="Name"
                  />
                  <TextField
                    style={{ marginLeft: '0.4em' }}
                    value={attributes[i].value || ''}
                    onFocus={() => { this.setState({ tracer: i, valueSelect: true }); }}
                    onChange={(event) => { this.handleAttrChange(event, i, 'value'); }}
                    label="Value"
                  />
                  {(
                    attributes.length === (i + 1) ?
                      <AddIcon
                        color="secondary"
                        style={{ fontSize: '1.6em' }}
                        onClick={() => {
                          this.setState({
                            product: {
                              ...product,
                              stagingProductAttributes: [
                                ...attributes,
                                { name: '', value: '' },
                              ],
                            },
                          });
                        }}
                      />
                      :
                      <CloseIcon
                        color="secondary"
                        style={{ fontSize: '1.6em' }}
                        onClick={() => {
                          const d = attributes.slice(0); d.splice(i, 1);
                          this.setState({
                            product: {
                              ...product,
                              stagingProductAttributes: d,
                            }
                          });
                        }}
                      />
                  )}
                </div>))

            }
          </ColMod>
        </Row>
        <Row style={{ marginTop: '1.5em' }}>
          <ColMod>
            <Typography variant="headline" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              Files
           </Typography>
          </ColMod>
        </Row>
        <Row>
          <ColMod lg={6} md={12}>
            <ImageContainer
              urls={urls}
              handleImageUpload={this.handleImageUpload}
              clearImage={this.clearImage}
            />
          </ColMod>
        </Row>
        <GstSelection openSearchBox={this.state.openGstSelector} handleClose={this.handleGstSelectorClose} selectGst={this.selectGst} />
      </Grid >
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.instanceOf(Object),
};

export default ProductForm;
