import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import AuditSearch from '../components/AuditSearch';
import apiCall, { findAuditEntries, getUsers } from '../../../api/NetworkHandler';
import AuditList from '../components/AuditList';
import globalStyle from '../../../components/lib/style';

class AuditReportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        startDate: '',
        endDate: '',
        modifiedBy: { label: 'All', value: '' },
        action: { label: 'All', value: '' },
        entity: { label: 'All SCM', value: 'All SCM', dbName: 'scm' },
        entityKey: '',
        entityText: '',
      },
      users: [],
      data: null,
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isLoading: false,
      order: 'asc',
      sortedBy: 'timeCreated',
      selectedData: {},
      selectedindex: null,
    };
  }

  componentDidMount() {
    // this.fetchAuditInfo();
    this.fetchAllUsers();
  }

  onChangeFilter = (event) => {

    let { name, value } = event.target;
    if (value == 'all') {
      value = '';
    }
    this.setState((prevState) => {
      const { filters } = prevState;
      return {
        filters: {
          ...filters,
          [name]: value,
        },
      };
    });
  }

  onRequestSort = (property) => {
    const { data, order } = this.state;
    const newOrder = order === 'asc' ? 'desc' : 'asc';

    const sortedData = orderBy(data, property, newOrder);

    this.setState({
      data: sortedData,
      order: newOrder,
      sortedBy: property,
    });
  }

  onSearchHandler = () => {
    const { filters } = this.state;
    const {
      startDate,
      endDate,
      modifiedBy,
      action,
      entity,
      entityKey,
      entityText,
    } = filters;
    if (entityText) {
      if (startDate && endDate && (modifiedBy.value || action.value || entity.value || entityKey)) {
        this.fetchAuditInfo();
        return;
      }
      return;
    }
    this.fetchAuditInfo();
  }

  onCloseHandler = () => {
    this.setState({ selectedData: {}, selectedindex: null });
  }

  fetchAuditInfo = async () => {
    const { filters, page, rowsPerPage } = this.state;
    const {
      modifiedBy,
      action,
      entity,
    } = filters;
    try {
      this.toggleLoading();
      const response = await apiCall.sendRequest('get', findAuditEntries(
        {
          ...filters,
          modifiedBy: modifiedBy.value,
          action: action.value,
          entity: entity.value === 'All SCM'|| entity.value === 'All Customer'? '' : entity.value,
          dbName: entity.dbName,
        },
        page, rowsPerPage,
      ));
      const { data: { _embedded: { 'audit-info': auditData }, page: { totalElements } } } = response;

      this.setState({
        data: auditData,
        count: totalElements,
        selectedData: {},
        selectedindex: null,
      });
      this.toggleLoading();
    } catch (error) {
      this.toggleLoading();
    }
  }

  fetchAllUsers = async () => {
    const response = await apiCall.sendRequest('get', getUsers);
    const { data: { _embedded: { users } } } = response;
    const userOptions = users.map(user => ({
      label: user.name,
      value: user.email,
    }));
    userOptions.unshift({
      label: 'All',
      value: '',
    });
    this.setState({
      users: userOptions,
    });
  }

  clearFilters = () => {
    this.setState((prevState) => {
      const { filters } = prevState;

      for (const key in filters) {
        if (key == "modifiedBy" || key == "action") {
          filters[key] = { label: "All", value: "" };
        }
        else if (key === "entity") {
          filters[key] = { label: "All SCM", value: "All SCM", dbName: 'scm' };
        } else {
          filters[key] = '';
        }
      }

      return {
        filters,
      };
    }, () => { this.fetchAuditInfo(); });
  }

  toggleLoading = () => {
    const { isLoading } = this.state;
    this.setState({
      isLoading: !isLoading,
    });
  }

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.fetchAuditInfo(); });
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.fetchAuditInfo(); });
  }

  handleClickRow = (row, index) => {
    const { data } = this.state;
    const filteredData = data.filter((item) => item.id === row.id);
    const [selectedRow] = filteredData;
    this.setState({ selectedData: selectedRow, selectedindex: index });
  }

  render() {
    const {
      filters,
      users,
      isLoading,
      data,
      count,
      page,
      rowsPerPage,
      order,
      sortedBy,
      selectedData,
      selectedindex,
    } = this.state;
    const {
      startDate,
      endDate,
      modifiedBy,
      action,
      entity,
      entityKey,
      entityText,
    } = filters;

    return (
      <div style={globalStyle.hideOverflowContainer}>
        <div id="auditHead">
          <NavBar />
          <ProgressBar isLoading={isLoading} />
          <AuditSearch
            startDate={startDate}
            endDate={endDate}
            modifiedBy={modifiedBy}
            action={action}
            entity={entity}
            onChangeFilter={this.onChangeFilter}
            onClearFilters={this.clearFilters}
            users={users}
            entityKey={entityKey}
            entityText={entityText}
            onSearchHandler={this.onSearchHandler}
          />
        </div>
        <AuditList
          data={data}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleChangePage={this.handleChangePage}
          onRequestSort={this.onRequestSort}
          order={order}
          orderBy={sortedBy}
          onClickRow={true}
          handleClickRow={this.handleClickRow}
          selectedData={selectedData}
          selectedindex={selectedindex}
          height={document.getElementById('auditHead') && document.getElementById('auditHead').clientHeight}
          onCloseHandler={this.onCloseHandler}
        />
      </div>
    );
  }
}

export default AuditReportContainer;
