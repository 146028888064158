export default {
  title: {
    fontSize: '1em',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  info: {
    borderBottom: '1px solid rgb(221, 221, 221)',
    padding: '16px 24px',
    display: 'flex',
  },
  infoItem: {
    marginRight: '36px',
  },
  csItem: {
    border: '2px solid #3f51b5',
    padding: '10px',
  },
  csItemContent: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  status: {
    background: 'beige',
    border: '1px solid rgb(204, 204, 204)',
    padding: '2px 5px',
    display: 'inline-block',
  },
  small: {
    fontWeight: '400',
    margin: '0 -2px 0 0',
    fontSize: '12px',
    // color: 'rgba(0, 0, 0, 0.54)',
  },
  DELIVERED: {
    background: 'chartreuse',
  },
  PARTIALLY_DELIVERED: {
    background: 'aquamarine',
  },
};
