import React, { Component } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

const columnData = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Product Name',
    value: 'hello',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Offer Type',
  },
  {
    id: 'isCashBackAmount',
    numeric: false,
    disablePadding: true,
    label: 'CashBack Amount',
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: true,
    label: 'quantity',
  },
  {
    id: 'noOfDays',
    numeric: false,
    disablePadding: true,
    label: 'noOfDays',
  },
  {
    id: 'offerEndDate',
    numeric: false,
    disablePadding: true,
    label: 'offerEndDate',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

// showOfferDetails = () => {
//   const { offer } = this.state;
//   this.setState({
//     offer: {
//       ...offer,
//       [label]: event.target.value,
//     },

//   });
// };

export default class OfferTable extends React.Component {
  render() {
    const {
      order,
      orderBy,
      offers,
      displayOffer,

    } = this.props;

    console.log('Json check', offers);

    return (
      <Table style={{ width: '95%', marginRight: '2.5%' }} aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {columnData.map(
            column => (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.label}
              </TableCell>
            ),
            this,
          )}
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.map(
            offer => (
              <TableRow onClick={() => this.props.showOfferDetails(offer)}>
                <TableCell>{offer.productItemId}</TableCell>
                <TableCell>{offer.offerType}</TableCell>
                <TableCell>{offer.cashBackAmount}</TableCell>
                <TableCell>{offer.quantity}</TableCell>
                <TableCell>{offer.noOfDays}</TableCell>
                <TableCell>{offer.offerEndDate}</TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <Button size="small" variant="contained" color="secondary" onClick={() => this.props.editOffer(offer)}>
                Edit
                  </Button>
                </TableCell>
              </TableRow>

            ),
            this,
          )}

        </TableBody>
      </Table>
    );
  }
}
