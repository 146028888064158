import React from 'react';
import PropTypes from 'prop-types';
import DetailModal from '../DetailModal';
import apiCall, { getHistory } from './../../../api/NetworkHandler';
import PlanList from './PlanList';
import PlanSearch from './PlanSearch';

class PlanHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        startDate: '',
        endDate: '',
        //             modifiedBy: { label: 'All', value: '' },
        action: { label: 'All', value: '' },
        //             entity: { label: 'All SCM', value: 'All SCM', dbName: 'scm' },
        //             entityKey: '',
        //             entityText: '',
      },
      //         users: [],
      data: null,
      count: 0,
      //         page: 0,
      //         rowsPerPage: 10,
      // isLoading: false,
      order: 'asc',
      sortedBy: 'timeCreated',
      selectedData: {},
      selectedindex: null,
    };
  }
  componentDidMount() {
    this.getHistory();

  }
  getHistory = () => {
    const { filters } = this.state;
    const { startDate, endDate, action } = filters
    const { planItemId } = this.props;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": "PlanHistory",
        "params": JSON.stringify({
          "entityId": planItemId,
          "startDate": startDate,
          "endDate": endDate,
          "action": action.value,
        })
      })
    )
      .then((response) => {
        let { data } = response.data;
        const count = data.length-1;
        data = data.splice(0, data.length -1);
        this.setState({
          data: data,
          count: count,
          selectedData: {},
          selectedindex: null,
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }
  handleClickRow = (row, index) => {
    const { data } = this.state;
    const filteredData = data.filter((item) => item.ID === row.ID)
    const [selectedRow] = filteredData;
    this.setState({ selectedData: selectedRow, selectedindex: index });
  }
  onCloseHandler = () => {
    this.setState({ selectedData: {}, selectedindex: null });
  }
  onChangeFilter = (event) => {

    let { name, value } = event.target;
    if (value == 'all') {
      value = '';
    }
    this.setState((prevState) => {
      const { filters } = prevState;
      return {
        filters: {
          ...filters,
          [name]: value,
        },
      };
    });
  }

  clearFilters = () => {
    this.setState((prevState) => {
      const { filters } = prevState;

      for (const key in filters) {
        if (key == "modifiedBy" || key == "action") {
          filters[key] = { label: "All", value: "" };
        }
        else if (key === "entity") {
          filters[key] = { label: "All SCM", value: "All SCM", dbName: 'scm' };
        } else {
          filters[key] = '';
        }
      }

      return {
        filters,
      };
    }, () => { this.getHistory(); });
  }

  onSearchHandler = () => {


    this.getHistory();
  }

  render() {
    const { data, selectedindex, selectedData, filters, isLoading } = this.state;
    const { startDate, endDate, action } = filters;
    const { onClose } = this.props;
    console.log(data);
    return (

      <DetailModal title="Plan History" unMountComponent={onClose}>
        <PlanSearch
          startDate={startDate}
          endDate={endDate}
          // modifiedBy={modifiedBy}
          action={action}
          // entity={entity}
          onChangeFilter={this.onChangeFilter}
          onClearFilters={this.clearFilters}
          // users={users}
          // entityKey={entityKey}
          // entityText={entityText}
          onSearchHandler={this.onSearchHandler}
        />
        <PlanList
          data={data}
          onClickRow={true}
          handleClickRow={this.handleClickRow}
          selectedData={selectedData}
          selectedindex={selectedindex}
          height={document.getElementById('auditHead') && document.getElementById('auditHead').clientHeight}
          onCloseHandler={this.onCloseHandler}
        />

      </DetailModal>
    );
  }
}

PlanHistory.propTypes = {
  data: PropTypes.array,
  // rowsPerPage: PropTypes.number.isRequired,
  // page: PropTypes.number.isRequired,
  // order: PropTypes.string,
  // orderBy: PropTypes.string,
  count: PropTypes.number.isRequired,
  // handleChangeRowsPerPage: PropTypes.func.isRequired,
  // handleChangePage: PropTypes.func.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onClickRow: PropTypes.bool,
  handleClickRow: PropTypes.func,
  selectedData: PropTypes.object,
  selectedindex: PropTypes.number,
  height: PropTypes.number,
  onCloseHandler: PropTypes.func,
  onClose: PropTypes.func,
};

PlanHistory.defaultProps = {
  // order: 'asc',
  // orderBy: '',
  onClickRow: false,
  handleClickRow: () => { },
  height: 0,
  onCloseHandler: () => { },
  onViewHistory: () => { },

};

export default PlanHistory;
