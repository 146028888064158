import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function RegionFilter(props) {
  const { regions = [], regionId, onChangeFilter } = props;

  return (
    <div>
      <FormHelperText style={{ marginTop: '4px' }}>Region</FormHelperText>
      <Select
        name="regionId"
        fullWidth
        onChange={onChangeFilter}
        value={(+regionId) || ''}
      >
        {regions.map((region, idx) => (
          <MenuItem key={idx} value={region.id}>{region.name}</MenuItem>
        ))}
      </Select>
    </div>
  )
}

RegionFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
};

RegionFilter.defaultProps = {
  onChangeFilter: () => {},
  regions: [],
}
