export default {
  link: {
    textDecoration: 'none',
  },
  header: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  q: {
    fontWeight: 'bold',
    padding: '0 0 0 10px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  errorQ: {
    fontWeight: 'bold',
    padding: '0 0 0 10px',
    color: '#FF4500',
  },
  content: {},
  item: {
    borderBottom: '1px solid #ddd',
    display: 'flex',
    padding: '16px 24px',
    background: 'white',
  },
  itemHover: {
    background: '#fafafa',
  },
  name: {
    fontWeight: '500',
    fontSize: '1em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    display: 'flex',
    fontSize: '0.8em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoItem: {
    padding: '0 25px 0 0',
  },
  id: {
    fontWeight: '400',
    fontSize: '0.8em',
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '24px',
    minWidth: '40px',
  },
};
