import React from 'react';
import BuildingsComponent from '../components/BuildingsComponent';


class BuildingsContainer extends React.Component {
  render() {
    return (
      <div>
        <BuildingsComponent />
      </div>
    );
  }
}

export default BuildingsContainer;
