import React, { Component } from "react";
import { Grid, Row, Col } from 'react-flexbox-grid'
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from "../../../components/lib";
import { ArrowBack } from "@material-ui/icons";
import { Typography, Button, TextField } from "@material-ui/core";
import RegionSelector from "../../../components/Region/Selector";
import DeeplinkUrlGenerator from "../../../utils/DeeplinkUrlGenerator";
import ProductImageManager from "../../../components/Core/ProductImageManager/ProductImageManager";
import apiCall, { createHomeOffer, updateHomeOffer } from './../../../api/NetworkHandler';
import { debounce } from "lodash";
import SectionMessage from "@atlaskit/section-message";
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';


const styles = {
  container: {
    width: window.innerWidth * 0.35,
    paddingBottom: 20,
  },
  arrow: {
    cursor: "pointer",
  },
  label: {
    marginBottom: '1em',
  },
  error: {
    color: 'red',
  }
}

class HomeOfferForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      selectedRegion: null,
      selectedOffer: {
        title: '',
        regionId: '',
        deepLink: '',
        order: null,
        imageUrl: '',
        active: true,
      },
      openDeeplinkModal: false,
      disableButton: false,
    }

  }

  componentDidMount() {
    const { selectedOffer, regions } = this.props;
    if (selectedOffer !== null) {
      let region = regions.filter(reg => {
        if (reg.id === selectedOffer.regionId) return reg;
      })
      if (region.length > 0) {
        let selectedRegion = {
          value: region[0].id,
          label: region[0].name,
        }
        this.setState({ selectedRegion });
      }
      this.setState({ selectedOffer });
    }
  }

  onRegionChange = (val) => {
    this.setState(prev => {
      const { selectedOffer } = prev;
      return {
        ...prev,
        selectedRegion: val,
        selectedOffer: {
          ...selectedOffer,
          regionId: val.id,
        }
      }
    })
  }

  handleOpenDeeplinkGenerator = () => {
    this.setState({
      openDeeplinkModal: true
    });
  };

  handleCloseDeeplinkGenerator = () => {
    this.setState({
      openDeeplinkModal: false
    });
  };

  handleCopyUrl = (url) => {
    const { selectedOffer } = this.state;
    this.setState({
      selectedOffer: {
        ...selectedOffer,
        deepLink: url,
      }
    });
  };

  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => {
      const { selectedOffer } = prevState;
      return {
        selectedOffer: {
          ...selectedOffer,
          [name]: value
        }
      }
    })
  }

  onImageUpload = (imageUrl) => {
    this.setState(prevState => {
      const { selectedOffer } = prevState;
      return {
        selectedOffer: {
          ...selectedOffer,
          imageUrl
        }
      }
    })
  }

  onDeleteImage = () => {
    this.setState(prevState => {
      const { selectedOffer } = prevState;
      return {
        selectedOffer: {
          ...selectedOffer,
          imageUrl: ''
        },
      }
    })
  }

  onSave = debounce(() => {
    if (!this.onValidate()) {
      return;
    }
    this.setState({ disableButton: true })
    const { selectedOffer } = this.state;
    this.saveOffer(selectedOffer);
  }, 500)

  onValidate = () => {
    const validateFields = ['title', 'order', 'regionId', 'deepLink', 'imageUrl'];
    let isFormValid = true;
    const { selectedOffer } = this.state;
    for (const key in selectedOffer) {
      if (validateFields.includes(key)) {
        if (!this.isPropertyValid(key, selectedOffer[key]))
          isFormValid = false;
      }
    }
    return isFormValid;
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })

  }

  isPropertyValid = (property, value) => {
    if (!value) {
      this.setError(property, true);
      return false;
    }
    this.setError(property, false);
    return true;
  }

  saveOffer = async (data) => {
    const { toggleLoading, onRefresh, onClose } = this.props;

    try {
      toggleLoading(true);
      if (data.id) {
        await apiCall.sendRequest('put', updateHomeOffer(data.id), data);
      }
      else {
        await apiCall.sendRequest('post', createHomeOffer, data);
      }
      onRefresh();
      onClose();

    } catch (error) {
      console.error(error);
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }

    toggleLoading(false);
    this.setState({ disableButton: false });

  };

  handleStatusChange = (value) => {
    this.setState(prevState => {
      const { selectedOffer } = prevState;
      return {
        selectedOffer: {
          ...selectedOffer,
          active: value,
        },
      };
    })
  }

  render() {
    const { classes, onClose, } = this.props;
    const {
      errorMessage,
      selectedRegion,
      selectedOffer,
      openDeeplinkModal = false,
      error,
      disableButton,
    } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Home Offer Form</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary" variant="outlined">Cancel</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" disabled={disableButton} onClick={this.onSave}>Save</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod custompadding="true" bumphigh="true">
            <Typography color="error" variant="caption">* Required</Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            {errorMessage && <SectionMessage appearance="error">{errorMessage}</SectionMessage>}
          </ColMod>
        </Row>}
        <Row md={12}>
          <ColMod md={12}>
            <TextField
              label="Title"
              name="title"
              required
              fullWidth
              value={selectedOffer.title || ""}
              onChange={this.onInputChange}
              error={error && error.title}
            />

          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            <Typography variant="body2" color="inherit">
              Status
              &nbsp;&nbsp;&nbsp;&nbsp;
              <small style={{ color: 'red' }}>Inactive</small>
              &nbsp;
              <Switch
                id="status"
                checked={selectedOffer.active}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(value) => { this.handleStatusChange(value) }}
              />
              &nbsp;
              <small style={{ color: '#1E90FF' }}>Active</small>
            </Typography>
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={8}>
            <Typography className={[classes.label, error && error.regionId ? classes.error : null]} variant="caption"> Regions<sup>*</sup>  </Typography>
            <RegionSelector selected={selectedRegion} onSelect={this.onRegionChange} isClearable={false} name="region" />

          </ColMod>
          <ColMod md={4}>
            <TextField
              label="Order"
              name="order"
              required
              fullWidth
              type="number"
              value={selectedOffer.order || ''}
              onChange={this.onInputChange}
              error={error && error.order}
              onKeyPress={(event) => {
                if (event.key === '-' || event.key === '+' || event.key === '.') {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
            />

          </ColMod>
        </Row>

        <Row md={12}>
          <ColMod md={12}>
            <TextField
              label="Deeplink Url"
              value={selectedOffer.deepLink || ''}
              name="deepLink"
              onChange={this.onInputChange}
              fullWidth
              required
              helperText={<a onClick={this.handleOpenDeeplinkGenerator}>*click to generate url</a>}
              error={error && error.deepLink}
              autoComplete="off"
            />

          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            {error && error.imageUrl && <span style={{ color: 'red', paddingBottom: '10px' }}>Please upload one image..</span>}
            <ProductImageManager
              images={[{
                imageUrl: selectedOffer.imageUrl,
                position: "FRONT",
                name: ''
              }]}
              singleImage={true}
              onImageUpload={this.onImageUpload}
              onDelete={this.onDeleteImage}
            />
          </ColMod>
        </Row>
        <DeeplinkUrlGenerator open={openDeeplinkModal} onClose={this.handleCloseDeeplinkGenerator} parentComponent="home-offer" onCopyUrl={this.handleCopyUrl} />

      </Grid>
    );
  }
}
export default withStyles(styles)(HomeOfferForm);