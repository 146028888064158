import React, { Component } from 'react';

class PageNotFound extends Component {
  render() {
    return (
      <div style={{
        padding: 30,
        width: '70%',
        margin: '30px auto',
        textAlign: 'center',
        background: ' #f2f2f2',
        }}
      >
        <h2>
          ERROR 404
        </h2>
        <h2>PAGE NOT FOUND</h2>
      </div>

    );
  }
}

export default PageNotFound;
