import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import TableHeader from '../../Commons/components/TableHeader';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import Lozenge from '@atlaskit/lozenge';


const columnData = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'Order Number',
  },
  // {
  //   id: 'vendorName', numeric: false, disablePadding: true, label: 'Vendor',
  // },
  // {
  //   id: 'customerId', numeric: false, disablePadding: true, label: 'Customer Id',
  // },
  // {
  //   id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile',
  // },
  // {
  //   id: 'customerName', numeric: false, disablePadding: true, label: 'Customer Name',
  // },
  {
    id: 'warehouseName', numeric: false, disablePadding: true, label: 'Warehouse Name',
  },
  {
    id: 'hubName', numeric: false, disablePadding: true, label: 'Hub Name',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Order Updated',
  },
  {
    id: 'deliveryDate', numeric: false, disablePadding: true, label: 'Delivery Date',
  },
  {
    id: 'totalPrice', numeric: false, disablePadding: true, label: 'Amount',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'priority', numeric: false, disablePadding: true, label: 'Priority',
  },
  {
    id: 'packageStatus', numeric: false, disablePadding: true, label: 'Package Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const style = {
  cellStyle: {
    textAlign: 'center',
  },
};

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      data: [],
      page: 0,
      // rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data !== this.state.data) {
        this.setState({
          data: nextProps.data,
        });
      }
    }
  }

  handleRequestSort = (event, property) => {
    if (['actions', 'mobile', 'status', 'priority'].includes(property)) {
      return;
    }
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    this.setState({ selected: data.id });
    this.props.getCustomerOrder(data);
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;

    onRowsPerPageChange(value);
  };

  isSelected = id => this.state.selected === id;

  render() {
    const { classes, totalElements, rowsPerPage, isOrderPackaged } = this.props;
    const {
      data, order, orderBy, selected, page, rowsPerPageOptions
    } = this.state;
    moment.locale('en');
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader 
              columns={isOrderPackaged ? columnData : columnData.filter(col => col.id !== 'packageStatus')}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort} 
              cellStyle={style.cellStyle}
            />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    style={{ backgroundColor: (isOrderPackaged && n.packageStatus === 'NOT_PACKED') ? 'rgb(255, 255, 220)' : (isOrderPackaged && n.packageStatus === 'INCOMPLETE') ? '#DCDCDC' : 'transparent' }}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      {['IN_DELIVERY', 'NEW'].includes(n.status) ?
                      <Link to={`/edit-co/${n.id}/view`} style={{ textDecoration: 'none', color: 'inherit' }}>
                       {n.id}
                      </Link>
                      :
                      <Link to={`/edit-co/${n.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {n.id}
                      </Link>}
                    </TableCell>
                    {/* <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">{n.vendorName}</TableCell> */}
                    {/* <TableCell component="th" scope="row" padding="none">{n.customerId}</TableCell> */}
                    {/* <TableCell component="th" scope="row" padding="none">{n.mobile || ''}</TableCell> */}
                    {/* <TableCell component="th" scope="row" padding="none">{n.customerName}</TableCell> */}
                    <TableCell component="th" scope="row" padding="none">{n.warehouseName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.hubName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{moment(n.timeUpdated).fromNow()}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{moment(n.deliveryDate).format('LL')}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.totalPrice ? n.totalPrice.toFixed(2) : 0.0}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.status}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.priority}</TableCell>
                    {isOrderPackaged && <TableCell component="th" scope="row" padding="none">
                      <div>
                        <Lozenge appearance={n.packageStatus === 'PACKED' ? "success" : "removed"}>{n.packageStatus}</Lozenge>
                      </div>
                    </TableCell>}
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      { ['IN_DELIVERY', 'NEW', 'CLOSED', 'CANCELLED'].includes(n.status)  ?
                        <Button size="small" variant="contained" color="primary" onClick={() => { this.props.history.push(`/edit-co/${n.id}/view`); }}>
                          View
                        </Button>
                        :
                        <Button size="small" variant="contained" color="secondary" onClick={() => { this.props.history.push(`/edit-co/${n.id}`); }}>
                          Edit
                        </Button>}
                    </TableCell>
                    {/* <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      <Button size="small" variant="contained" color="secondary" onClick={() => { this.props.history.push(`/edit-co/${n.id}`); }}>
                        Edit
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            disabled: !this.props.links.prev,
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            disabled: !this.props.links.next,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  totalElements: PropTypes.number,
};

export default withRouter(withStyles(styles)(EnhancedTable));
