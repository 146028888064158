import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import MaterialSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class SmsTemplate extends React.Component {
  render() {
    const {
      updateStatus,
      data: templates,
      value,
      templateText,
    } = this.props;
    return (
      <div style={{ marginLeft: 40 }}>
        <MaterialSelect
          style={{ minWidth: 300 }}
          value={value}
          onChange={e => updateStatus(e)}
        >
          {Object.keys(templates).map(status => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
            ))}
        </MaterialSelect>
        <FormHelperText>Select Delivery Status<small style={{ color: 'red', marginLeft: '5px', fontSize: '11px' }}>{templateText}</small></FormHelperText>
      </div>
    );
  }
}


export default withStyles(styles)(SmsTemplate);
