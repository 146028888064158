import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import moment from 'moment';
import { ColMod } from '../../../components/lib';
import CustomerOrderItemTable from './CustomerOrderItemTable';
import SaveBar from './SaveCustomerOrder';
import apiCall, {
  editCustomerOrdersPath,
  fetchCustomerAddressByAddressId,
  fetchPackagingDetailsByOrderId,
  customerOrderById,
  getIsPackaged,
  fetchRouteDetailsByCustomerOrderId,
  regionIdPath,
} from '../../../api/NetworkHandler';
import COPackagingView from './COPackagingView';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import utils from '../../../utils/queryBuilder';
import { cloneDeep, isEmpty } from "lodash";
import MultiProductItemSelector from './MultiProductItemSelector';


const canView = utils.isCurrentUserAuthorizedToView;

const { confirm } = Modal;


var versionDict = {
  "packageRequestId": 0,
  "addressRequestId": 0,
  "routeRequestId": 0,
  "orderRequestId": 0,
  "regionRequestId": 0,
}

var initialData = {};

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherCharges: 0,
      co: null,
      items: [],
      data: {},
      crErrorMessage: '',
      statusErrorMessage: '',
      printableOrderId: null,
      openPackagingView: false,
      packageNames: [],
      customerAddressDetails: {},
      packagingData: [],
      isOrderPackaged: false,
      packagingResponse: '',
      customerRouteDetails: {},
      openProductSelector: false,
      customerRegionData: null,
    }
  }

  componentDidMount() {  
    this.fetchPackageStatus();
  };

  getOrderStatus = (status) => {
    if (['IN_DELIVERY', 'NEW'].includes(status)) {
      message.info(`This order is not editable, Order status is ${status}`, 5)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data && (nextProps.data !== this.state.data)) {
        initialData = cloneDeep(nextProps.data),
        this.setState({
          data: nextProps.data,
          items: nextProps.data.customerOrderLineItems ? nextProps.data.customerOrderLineItems : [],
        }, () => {
          this.getOrderStatus(this.state.data.status);
          this.generatePackageNames(this.state.data);
          this.getCustomerAddressByAddressId(this.state.data.customerAddressId);
          this.getRouteDetailsByCustomerOrderId(this.state.data.id);
        });
      }
    }
  }

  fetchPackageStatus = async () => {
    try {
      versionDict.packageRequestId += 1;
      let prevRequestId = versionDict.packageRequestId;
      const response = await apiCall.sendRequest('get', getIsPackaged);
      const { data: { data = {} } } = response;
      const { isPackaged = false } = data;
      if (versionDict.packageRequestId == prevRequestId) {
        this.setState({
          isOrderPackaged: isPackaged,
        });
      }
    } catch (err) {
      this.setState({ isOrderPackaged: false });
    }
  }

  generatePackageNames = (data) => {
    if (Object.keys(data).length == 0) return [];
    let packageNames = [];
    if (data.id) {
      for (var i = 1; i < 11; ++i) {
        packageNames.push({label: `Order#${data.id}-${i}`, value: `${data.id}-${i}`});
      }
    }
    packageNames.unshift({
      label: 'All',
      value: 'all',
    });
    this.setState({
      packageNames,
    });
  }

  getRegionDataByRegionId = async (regionId) => {
    try {
      versionDict.regionRequestId += 1;
      let prevRequestId = versionDict.regionRequestId;
      const response = await apiCall.sendRequest('get', regionIdPath(regionId));
      const { data = {} } = response;
      if (prevRequestId == versionDict.regionRequestId) {
        this.setState({
          customerRegionData: {...data, label: data.name, value: data.id},
        });
      }
    } catch (err) {
      this.setState({
        customerRegionData: null,
      });
    }
  };

  getCustomerAddressByAddressId = async (customerAddressId) => {
    try {
      versionDict.addressRequestId += 1;
      let prevRequestId = versionDict.addressRequestId;
      const response = await apiCall.sendRequest('get', fetchCustomerAddressByAddressId(customerAddressId));
      const { data = {} } = response;
      if (versionDict.addressRequestId == prevRequestId) {
        this.setState({
          customerAddressDetails: data,
        }, () => this.getRegionDataByRegionId(data.regionId));
      }
    } catch (err) {
      this.setState({
        customerAddressDetails: {},
      });
    }
  }

  getRouteDetailsByCustomerOrderId = async (orderId) => {
    try {
      versionDict.routeRequestId += 1;
      let prevRequestId = versionDict.routeRequestId;
      const response = await apiCall.sendRequest('get', fetchRouteDetailsByCustomerOrderId(orderId));
      const { data = {} } = response;
      if (versionDict.routeRequestId == prevRequestId) {
        this.setState({
          customerRouteDetails: data,
        });
      }
    } catch (err) {
      this.setState({
        customerRouteDetails: {},
      });
    }
  }

  onChangeItem = (items) => {
    this.setState({
      items,
    });
  }

  handleOpenProductSelector = () => {
    this.setState({
      openProductSelector: true,
    });
  };

  handleCloseProductSelector = () => {
    this.setState({
      openProductSelector: false,
    });
  };

  handleSelectProductItems = (products) => {
    this.setState({
      items: products.map(el => { return {...el, status: el.isAdded ? "DELIVERED" : el.status, deliveredQuantity: el.isAdded ? 1 : el.deliveredQuantity} }),
    });
  };

  checkQuantity = () => {
    let isQuantityMismatched = true;
    let { items = [] } = this.state;
    this.state.items.forEach((item, index) => {
      if (!isQuantityMismatched) {
        return isQuantityMismatched;
      }
      if (!item.deliveredQuantity) {
        items[index].deliveredQuantity = 0;
        this.setState({ items });
      }
      if (item.quantity != item.deliveredQuantity && item.status === 'NOT_DELIVERED' && !item.complaintReason) {
        isQuantityMismatched = false;
        this.setState({
          crErrorMessage: 'select a reason',
        })
        return;
      }
      else if (item.quantity != item.deliveredQuantity && !item.status & !item.complaintReason) {
        isQuantityMismatched = false;
        this.setState({
          crErrorMessage: 'select a reason',
          statusErrorMessage: '*required'
        })
        return;
      }
      else if (item.quantity != item.deliveredQuantity && item.status === 'PENDING' && (item.complaintReason == 'none' || !item.complaintReason)) {
        isQuantityMismatched = false;
        this.setState({
          crErrorMessage: 'select a reason',
        })
        return;
      }
      else if (item.isAdded && !item.complaintReason) {
        isQuantityMismatched = false;
        this.setState({
          crErrorMessage: 'select a reason',
        })
        return;
      }
      else if (item.isAdded && item.deliveredQuantity < 1) {
        isQuantityMismatched = false;
        return;
      }
      else if (item.quantity == item.deliveredQuantity && item.status === 'PENDING' && (item.complaintReason == 'none' || !item.complaintReason)) {
        isQuantityMismatched = true;
        this.setState({
          crErrorMessage: '',
        })
        return;
      }
      else {
        isQuantityMismatched = true;
        this.setState({
          crErrorMessage: '',
          statusErrorMessage: ''
        })
      }
    });
    return isQuantityMismatched;
  }

  getModifiedLineItemStatusOnOrderStatus = (items, status) => {
    let modifiedData = [];
    items.map(el => {
      if (status === 'CLOSED') {
        modifiedData.push({...el, status: el.status === 'PENDING' ? 'NOT_DELIVERED' : el.status})
      } else {
        modifiedData.push(el)
      }
    });
    return modifiedData;
  };

  onSave = (status) => {
    if (!this.checkQuantity()) {
      return;
    }
    const modifiedLineItems = this.getModifiedLineItemStatusOnOrderStatus(this.state.items, this.state.data.status);
    const data = {
      ...this.state.data,
      customerOrderLineItems: modifiedLineItems,
      status: this.state.data.status || status,
    };
    let modifiedData = data;
    const { remarks, ...rest } = data;
    if (remarks === 'none') {
      modifiedData = rest;
    }
    apiCall.sendRequest('post', editCustomerOrdersPath, modifiedData)
      .then((res) => {
        this.props.history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  removeItem = (i) => {
    const { items } = this.state;
    const newItems = items.filter(item => item.productItemId != i.productItemId);

    this.setState({
      items: newItems,
    });
  }


  addOtherCharges = (event) => {
    this.setState({
      otherCharges: event.target.value,
    });
  }

  prepareLabelPrint = (selectedOrder) => {
    return (
      `<div style="width: 188.97px; height: 113.38px; border: 1px solid black; border-collapse: collapse;" rules="all">
        <p style="font-size: 7px;  line-height: 1px; text-align: center; margin-top: 4px; margin-bottom: 0; padding: 0"> FRESH TO HOME FOODS FACTORY L.L.C </p>
        <div>
          <img src='../../../../public/images/FTHDailyIcon.png' style="width:30px; height:30px;">
          <p style="font-size: 12px;  line-height: 1px; float: right; margin-right: 5px"> ${moment(selectedOrder.deliveryDate).format("DD/MM/YYYY")} </p>
        </div>
        <p style="font-size: 16px; line-height: 1px; text-align: center; margin-top: 2px"> Order# <b> ${selectedOrder.id} </b> </p>
        <hr style="margin-top: -0.4em;" />
        <div style="font-size: 10px; margin-left: 5px; text-align: left; word-wrap: break-word;">
          <p><b>${selectedOrder.customerName || ''}</b><br/>${selectedOrder.customerAddress}</p>
        </div>
      </div>
      <br>
      <br>`
    );
  }

  getPackageNamesFromPackagingData = (packagingData) => {
    let packageNames = [];
    packagingData.map(el => {
      if (el.primaryPackageName) {
        packageNames.push(el.primaryPackageName);
      }
      if (el.secondaryPackageName) {
        packageNames.push(el.secondaryPackageName);
      }
    });
    return [...new Set(packageNames)];
  }
  
  displayLabel = async (selectedOrder) => {
    const { customerRouteDetails, printableOrderId } = this.state;
    const { customerAddress = '', hubName = '', warehouseName = '' } = customerRouteDetails;
    let content = '';
    let modifiedPrintOrder = {};
    if (!printableOrderId) {
      this.setState({
        disablePrintOrder: true,
      });
      message.warning('Please select order id to print the label...')
      return;
    } else {
      this.setState({
        disablePrintOrder: false,
      });
      if (printableOrderId === 'all') {
        const packagingDetails = await this.getPackagingDetailsByOrderId();
        const { packagingData } = packagingDetails;
        let packageNames = this.getPackageNamesFromPackagingData(packagingData);
        packageNames.map(el => {
          modifiedPrintOrder = {...selectedOrder, id: el, customerAddress: customerAddress};
          content += this.prepareLabelPrint(modifiedPrintOrder);
        });
      } else {
        modifiedPrintOrder = {...selectedOrder, id: printableOrderId, customerAddress: customerAddress};
        content = this.prepareLabelPrint(modifiedPrintOrder);
      }
      let orderHtml = '<html><head><title></title></head><body onLoad="window.print();window.close();">' + content + '</body></html>';
      let winPrint = window.open("", "", 200, 120);
      winPrint.document.write(orderHtml);
      winPrint.document.close();
      winPrint.focus();
    }
  }

  handleLabelChange = (event) => {
    const { value } = event.target;
    this.setState({
      printableOrderId: value,
      disablePrintOrder: false,
    });
  }


  handleOpenPackagingView = async () => {
    const packagingDetails = await this.getPackagingDetailsByOrderId();
    const { packagingData, packagingResponse } = packagingDetails;
    this.setState({
      openPackagingView: true,
      packagingData,
      packagingResponse,
    });
  }

  getCustomerOrderByOrderId = async (id) => {
    try {
      versionDict.orderRequestId += 1;
      let prevRequestId = versionDict.orderRequestId;
      const response = await apiCall.sendRequest('get', customerOrderById(id));
      const { data = {} } = response;
      if (versionDict.orderRequestId == prevRequestId) {
        this.setState({
          data,
        });
      }
    } catch (err) {
      // this.setState({
      //   data: {},
      // });
    }
  }

  handleClosePackagingView = () => {
    const { data } = this.state;
    this.setState({
      openPackagingView: false,
    }, () => this.getCustomerOrderByOrderId(data.id));
  }

  getPackagingDetailsByOrderId = async () => {
    const { data = {} } = this.state;
    const { id = null } = data;
    try {
        const response = await apiCall.sendRequest('get', fetchPackagingDetailsByOrderId(id));
        const { data: { data = [], message = '' } } = response;
        return { packagingData: data, packagingResponse: message };
    } catch (err) {
      return { packagingData: [], packagingResponse: '' };
    }
  }

  showConfirm = () => {
    confirm({
      title: 'All items with status PENDING will be changed to NOT DELIVERED',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => {
        this.onSave();
      },
      onCancel: () => { },
    });
  }

  getOrderStatusChangePermission = (status) => {
    if (typeof status === 'undefined') return;
    if (canView(["ADMIN", "CSR"])) {
      return false;
    } else {
      if (status === "ORDERED") {
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const items = this.state.items.map((item) => {
      const newItem = item;
      if (item.deliveredQuantity === null && item.status == 'DELIVERED') {
        newItem.deliveredQuantity = item.quantity;
      } else if (item.deliveredQuantity === null) {
        newItem.deliveredQuantity = 0;
      }
      return newItem;
    });

    const { 
      data,
      otherCharges,
      printableOrderId,
      openPackagingView,
      packageNames,
      disablePrintOrder,
      packagingData,
      isOrderPackaged,
      packagingResponse,
      customerAddressDetails,
      openProductSelector,
      customerRegionData,
      crErrorMessage,
      statusErrorMessage
    } = this.state;

    const { location = {} } = this.props;
    const { pathname = '' } = location;
    const { fullAddress = '', regionId = null } = customerAddressDetails;
    const isShowConfirmRequired = data.status === 'CLOSED' && data.customerOrderLineItems.some(el => el.status === 'PENDING');
    const shouldRegionDisabled = isEmpty(customerRegionData);

    return (
      <div>
        <SaveBar
          header={pathname.split('/').length == 4 ? "View Customer Order" : "Edit Customer Order"}
          onSave={isShowConfirmRequired ? this.showConfirm : this.onSave}
          parentComponent="customer-order"
          status={data.status} 
          id={data.id}
        />
        <div style={{ width: "95%", position: "absolute" }}>
          <Grid container spacing={32}>
            <Grid xs md lg={12} style={{ margin: 'auto', paddingLeft: "20px" }}>
              {(data.priority && !isOrderPackaged) &&
                  <Grid lg={12} style={{ marginTop: "20px", paddingLeft: "20px" }}>
                    <span>Order Priority : </span>
                    <span style={{ color: '#f50057', fontWeight: 600 }}>{data.priority}</span>
                  </Grid>
              }
              {isOrderPackaged &&
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '10px' }}>
                  <div>
                    <span><b>Order Priority : </b></span>
                    <span style={{ color: '#f50057', fontWeight: 600 }}>{data.priority}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ paddingRight: '20px' }}>
                      <span><b>Packaging Status : </b></span>
                      <span style={{ color: ['INCOMPLETE', 'NOT_PACKED'].includes(data.packageStatus) ? '#f50057' : 'green', fontWeight: 'bold', fontSize: '16px' }}>{data.packageStatus}</span>
                    </div>
                    <Button size="small" variant="contained" color="secondary" onClick={this.handleOpenPackagingView}> Packaging </Button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ paddingRight: '10px' }}>
                        <Select
                          style={{ width: '150px' }}
                          value={printableOrderId}
                          onChange={this.handleLabelChange}
                          error={!printableOrderId}
                          >
                          {packageNames.map((el, index) => (<option key={index} value={el.value}>{el.label}</option>))}
                        </Select>
                    </div>
                    <Button size="small" variant="outlined" color="primary" onClick={() => this.displayLabel(data)} disabled={disablePrintOrder}> Print Label </Button>
                  </div>
                </div>
              }
              <Row>
                <ColMod custompadding="true" lg={12}>
                  {data.id && 
                  <div>
                    <Row>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <TextField
                          label="Customer Order Number"
                          InputLabelProps={{ shrink: true }}
                          value={data.id}
                          fullWidth
                          disabled={(pathname.split('/').length === 4)}
                        />
                      </div>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <TextField
                          label="Customer Id"
                          InputLabelProps={{
                              shrink: true,
                            }}
                          name="customerID"
                          value={data.customerId}
                          fullWidth
                          onChange={this.handleChange}
                          disabled
                        />
                      </div>
                    </Row>
                    <Row>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <TextField
                          label="Customer Name"
                          InputLabelProps={{
                              shrink: true,
                            }}
                          name="customerName"
                          value={data.customerName}
                          fullWidth
                          onChange={this.handleChange}
                          disabled={(pathname.split('/').length === 4)}
                        />
                      </div>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Customer Address</InputLabel>
                        <TextField
                          name="locationName"
                          value={fullAddress}
                          fullWidth
                          multiline
                          onChange={this.handleChange}
                          disabled
                        >
                        </TextField>
                      </div>
                    </Row>
                    <Row>
                      {(pathname.split('/').length === 4) ? 
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Order Status</InputLabel>
                        <TextField
                          name="status"
                          value={data.status}
                          fullWidth
                          onChange={this.handleChange}
                          disabled
                        >
                        { Object.keys(this.props.COStatuses).map((status, i) => (<MenuItem key={i} value={status}>{status}</MenuItem>)) }
                        </TextField>
                      </div> : 
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Order Status</InputLabel>
                        <Select
                            name="status"
                            value={data.status ? data.status : 'none'}
                            fullWidth
                            onChange={this.handleChange}
                            disabled={this.getOrderStatusChangePermission(initialData.status)}
                          >
                            { Object.keys(this.props.COStatuses).filter(n => ['CANCELLED', 'CLOSED', 'ON_HOLD'].includes(n)).map((status, i) => (<MenuItem key={i} value={status}>{status}</MenuItem>)) }
                          </Select>
                      </div>}
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Delivery Date</InputLabel>
                        <TextField
                          id="date"
                          fullWidth
                          type="date"
                          value={data.deliveryDate}
                          name="deliveryDate"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        onChange={this.handleChange}
                        disabled
                        />
                      </div>
                    </Row>
                  </div> }
                  {!data.id &&
                  <div>
                    {data.id &&
                    <Row>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <TextField
                          label="Customer Id"
                          name="customerID"
                          value={data.customerId}
                          fullWidth
                          onChange={this.handleChange}
                        />
                      </div>
                    </Row> }
                    <Row>
                    <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Customer Name</InputLabel>
                        <Select
                          name="customerName"
                          value={data.customerName}
                          fullWidth
                          onChange={this.handleChange}
                        >
                        </Select>
                      </div>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Customer Address</InputLabel>
                        <TextField
                          name="locationName"
                          value={data.localityName}
                          fullWidth
                          onChange={this.handleChange}
                          disabled
                        >
                        </TextField>
                      </div>
                    </Row>
                    <Row>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Order Status</InputLabel>
                        <Select
                          name="status"
                          value={data.status ? data.status : 'none'}
                          fullWidth
                          onChange={this.handleChange}
                        >
                        { Object.keys(this.props.COStatuses).filter(n => ['CANCELLED', 'CLOSED', 'ON_HOLD'].includes(n)).map((status, i) => (<MenuItem key={i} value={status}>{status}</MenuItem>)) }
                        </Select>
                      </div>
                      <div style={{ flex: 1, margin: '10px' }}>
                        <InputLabel htmlFor="name-readonly">Delivery Date</InputLabel>
                        <TextField
                          id="date"
                          fullWidth
                          type="date"
                          value={data.deliveryDate}
                          name="deliveryDate"
                          InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.handleChange}
                        />
                      </div>
                    </Row>
                    </div> }
                  <Row>
                    <div style={{ flex: 1, marginBottom: "10px", paddingLeft: "10px" }}>
                      <CustomerOrderItemTable 
                        items={items} 
                        removeItem={this.removeItem} 
                        onChange={this.onChangeItem}
                        crErrorMessage={crErrorMessage}
                        statusErrorMessage={statusErrorMessage}
                        pathname={pathname}
                        regionId={regionId}
                        isEditingAllowed={!this.getOrderStatusChangePermission(initialData.status)}
                        initialData={initialData}
                      />
                    </div>
                  </Row>
                  <Row style={{ paddingLeft: "10px" }}>
                    <Button variant="outlined" color="primary" onClick={this.handleOpenProductSelector} disabled={pathname.split('/').length === 4}> Add Item </Button>
                  </Row>
                  <Row style={{ borderBottom: 'solid 1px grey', paddingBottom: '30px' }}>
                  </Row>
                  <Row style={{ marginBottom: '10px', marginTop: '10px', marginRight: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}>
                      <Typography variant="title" style={{ marginLeft: '20px', color: 'blue' }}>
                        {data ? (items.reduce((prev, next) => prev + ((next.deliveredQuantity) * next.unitPrice), 0) + (otherCharges ? parseFloat(otherCharges) : 0)).toFixed(2) : 0}
                      </Typography>
                      <Typography variant="subheading" color="inherit">
                          <b>Total Order Amount:</b>
                      </Typography>
                    </div>
                  </Row>
                </ColMod>
              </Row>
            </Grid>
          </Grid>
        </div>
        <COPackagingView
          openPackagingView={openPackagingView}
          handleClose={this.handleClosePackagingView}
          data={data}
          packageNames={packageNames}
          key={Date.now()}
          packagingData={packagingData}
          packagingResponse={packagingResponse}
        />
        <MultiProductItemSelector
          open={openProductSelector}
          items={items}
          handleClose={this.handleCloseProductSelector}
          onChange={this.handleSelectProductItems}
          region={customerRegionData}
          disabled={!shouldRegionDisabled}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  COStatuses: state.Status.costatuses,
});


export default connect(mapStateToProps, null)(withRouter(DashboardComponent));
