import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row } from 'react-flexbox-grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import apiCall, { getAddressWithCustomerIdPath, getSubmitAddressPath, fetchStaticRouteByIdPath, getPincodeAbsent } from '../../../api/NetworkHandler';
import UserSelection from '../../../components/UserSelection';
import NavBar from '../../../components/NavBar';
import { ColMod } from '../../../components/lib';
import Lozenge from '@atlaskit/lozenge';
import SaveBar from '../../../modules/DiscountOffers/components/SaveBarOfferCampaign';
import { debounce, isEmpty } from 'lodash';

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#f0f0f0',
    height: '66px',
    alignItems: 'center',
  },
  title: {
    flex: 2,
    marginLeft: '30px',
  },
  calendar: {
    flex: 1,
    textAlign: 'center',
  },
  searchBar: {
    flex: 4,
  },
  add: {
    flex: 1,
    textAlign: 'center',
  },
};

let COMPONENT_TITLE = 'Edit Customer Address';

var versionDict = {
  "getAddressRequestId": 0,
  "patchAddressRequestId": 0,
  "routeRequestId": 0,
  "pincodeRequestId": 0,
};

class EditAddressComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {},
      openUserSelector: false,
      addressStatus: {},
      customerId: '',
      disableSubmitButton: false,
      routeName: '',
      pincodeAbsent: false,
    };
  }

  componentWillMount() {
    const { match = {} } = this.props;
    const { params = { } } = match;
    const { addressId = null, customerId = '' } = params;
    this.setState({
      addressId,
      customerId,
    });
    if (!addressId) COMPONENT_TITLE = 'NEW ADDRESS';
    else COMPONENT_TITLE = 'Edit Customer Address';
  }

  componentDidMount() {
    this.setAddress();
    this.getPincodeAbsentStatus();
  }

  getPincodeAbsentStatus = async () => {
    try {
      versionDict.pincodeRequestId += 1;
      let prevRequestId = versionDict.pincodeRequestId;
      const response = await apiCall.sendRequest('get', getPincodeAbsent);
      const { data: { data: { pincodeAbsent = false } = {} } = {} } = response;
      if (prevRequestId == versionDict.pincodeRequestId) {
        this.setState({
          pincodeAbsent,
        });
      }
    } catch (err) {
      this.setState({
        pincodeAbsent: false,
      });
    }
  };

  onChangeValue = (event, id) => {
    const { address } = this.state;
    const val = event.target.value;
    if (id === 'active') {
      address[id] = val === 'ACTIVE';
      this.setState({ address });
    } else if (id === 'ringBell' || id === 'verified') {
      address[id] = event.target.checked;
      this.setState({ address });
    } else {
      address[id] = event.target.value;
      this.setState({ address });
    }
  };


  setSelectedData = (data) => {
    const { address, userType } = this.state;
    const { name } = data;
    if (userType === 'Building') {
      address.buildingName = name;
      this.setState({ address, openUserSelector: false, userType: '' });
    } else if (userType === 'City') {
      address.cityName = name;
      this.setState({ });
      this.setState({ address, openUserSelector: false, userType: '' });
    } else if (userType === 'Locality') {
      address.localityName = name;
      this.setState({ address, openUserSelector: false, userType: '' });
    }
  };

  setAddress = () => {
    const { addressId, customerId, address } = this.state;
    if (addressId) {
      this.fetchAddressById();
    } else {
      this.setState({
        address: {
          ...address,
          customerId,
        },
      });
    }
  }

  fetchAddressById = async () => {
    const { addressId } = this.state;
    try {
      versionDict.getAddressRequestId += 1;
      let prevRequestId = versionDict.getAddressRequestId;
      const response = await apiCall.sendRequest('get', getSubmitAddressPath(addressId));
      const { data = {} } = response;
      const { staticRouteId = null } = data;
      if (versionDict.getAddressRequestId == prevRequestId) {
        this.setState({
          address: data,
        }, () => this.fetchStaticRouteById(staticRouteId));
      }
    } catch (err) {
      this.setState({
        address: {},
      });
    }
  };

  fetchStaticRouteById = async (routeId) => {
    try {
      versionDict.routeRequestId += 1;
      let prevRequestId = versionDict.routeRequestId;
      const response = await apiCall.sendRequest('get', fetchStaticRouteByIdPath(routeId));
      const { data = {} } = response;
      const { name = '' } = data;
      if (versionDict.routeRequestId == prevRequestId) {
        this.setState({
          routeName: name,
        });
      }
    } catch (err) {
      this.setState({
        routeName: '',
      });
    }
  };

  openUserSelector = userType => () => {
    this.setState({
      openUserSelector: true,
      userType,
    });
  };

  handleSelectorClose = () => {
    this.setState({
      openUserSelector: false,
      userType: '',
    });
  };

  updateStatus = (event) => {
    const { value } = event.target;
    const { address = {} } = this.state;
    this.setState({
      address: {
        ...address,
        status: value,
      },
    });
  };

  handleDisableButton = (state) => {
    this.setState({
      disableSubmitButton: state,
    });
  };

  handleSubmitAddress = debounce( async () => {
    this.handleDisableButton(true);
    const { address } = this.state;
    const updatedAddress = {
      ...address,
      street: address.streetDetails,
      fullAddress: this.generateAddress(),
    };

    try {
      versionDict.patchAddressRequestId += 1;
      let prevRequestId = versionDict.patchAddressRequestId;
      const response = updatedAddress.id && await apiCall.sendRequest('patch', getSubmitAddressPath(updatedAddress.id), updatedAddress);
      const { data = {} } = response;
      if (versionDict.patchAddressRequestId == prevRequestId) {
        this.setState({
          address: data,
          disableSubmitButton: false
        });
      }
    } catch (err) {
      this.handleDisableButton(false);
    }
  }, 300);



  generateAddress = () => {
    const { address } = this.state;
    const {
      floor,
      houseNumber,
      buildingName,
      landmark,
      blockName,
      streetDetails,
      subLocalityName,
      localityName,
      cityName,
      pincode,
      addressStatus,
    } = address;


    return `${floor ? `${floor},` : ''}${addressStatus ? `${addressStatus},` : ''}${houseNumber ? ` ${houseNumber},` : ''}
            ${buildingName ? ` ${buildingName},` : ''}${landmark ? ` ${landmark},` : ''}
            ${blockName ? ` ${blockName},` : ''}${streetDetails ? ` ${streetDetails},` : ''}
            ${subLocalityName ? ` ${subLocalityName},` : ''}${localityName ? ` ${localityName},` : ''}
            ${cityName ? ` ${cityName},` : ''}${pincode ? ` ${pincode}` : ''}`;
  };

  renderDeliveryView = (disabled) => {
    const { addressStatuses } = this.props;
    const { status } = this.state.address;
    return (
      <div>
        <span>
          <MaterialSelect
            style={{ width: window.innerWidth * 0.17 }}
            value={status}
            onChange={this.updateStatus}
            // disabled={disabled}
          >
            {Object.keys(addressStatuses).map(status => (<MenuItem key={status} value={status}>{status}</MenuItem>))}
          </MaterialSelect>
        </span>
      </div>
    );
  };

  render() {
    // const { onClose } = this.props;
    const { address, openUserSelector = false, userType, disableSubmitButton, routeName, pincodeAbsent } = this.state;
    const {
      customerId,
      customerName,
      active,
      floor,
      houseNumber,
      blockName,
      buildingName,
      streetDetails,
      subLocalityName,
      localityName,
      cityName,
      pincode,
      landmark,
      latitude,
      longitude,
      mobile,
      ringBell = false,
      verified = false,
      type,
    } = address;

    let isDisable = false;
    let searchIconCllor = 'secondary';
    if (address && address.id) {
      isDisable = true;
      searchIconCllor = 'disabled';
    }
    return (
      <div>
        <NavBar />
        <UserSelection
          userType={userType}
          openSearchBox={openUserSelector}
          handleClose={this.handleSelectorClose}
          selectUser={this.setSelectedData}
          address={address}
        />

        <SaveBar 
          headerTitle={COMPONENT_TITLE}
          submitButtonText="Save"
          onSubmit={this.handleSubmitAddress}
          parentComponent="edit-address"
          isDisable={disableSubmitButton}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: window.innerWidth * 0.21, marginRight: window.innerWidth * 0.21, height: '20px', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'initial' }}>
            <div style={{ paddingRight: '10px' }}>
              <Typography variant="body2" color="default"> Ring Bell: </Typography>
            </div>
            <FormControlLabel
              control={<Switch checked={ringBell} value="ringBell" onChange={event => this.onChangeValue(event, 'ringBell')} />}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'initial' }}>
            <div style={{ paddingRight: '10px' }}>
              <Typography variant="body2" color="default"> Verified: </Typography>
            </div>
            <Lozenge appearance={verified ? "success" : "removed"} isBold>{verified ? 'Yes' : 'No'}</Lozenge>
          </div>
          <div style={{ display: 'flex', justifyContent: 'initial', paddingLeft: '10px' }}>
            <div style={{ paddingRight: '10px' }}>
              <Typography variant="body2" color="default"> Route Name: </Typography>
            </div>
            <Typography variant="body2" color="primary"> {routeName} </Typography>
          </div>
        </div>

        <Grid style={{ padding: '0.5em' }}>
          <Row>
            <ColMod xs md lg={15}>
              <Row>
                <ColMod lg={4}>
                  <TextField label="Customer Id" fullWidth value={customerId || ''} disabled />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Customer Name"
                    fullWidth
                    disabled
                    value={customerName || ''}
                    onChange={event => this.onChangeValue(event, 'customerName')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Mobile"
                    fullWidth
                    disabled
                    value={mobile || ''}
                    onChange={event => this.onChangeValue(event, 'mobile')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="City Name"
                    fullWidth
                    value={cityName || ''}
                    onChange={event => this.onChangeValue(event, 'cityName')}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="start" onClick={!isDisable && this.openUserSelector('City')}>
                    //       <Search color={searchIconCllor} />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    disabled={isDisable}
                  />
                </ColMod>
                {!pincodeAbsent && <ColMod lg={4}>
                  <TextField
                    label="Pincode"
                    fullWidth
                    disabled
                    value={pincode || ''}
                    onChange={event => this.onChangeValue(event, 'pincode')}
                  />
                </ColMod>}
                <ColMod lg={4}>
                  <TextField
                    label="Locality Name"
                    fullWidth
                    value={localityName || ''}
                    onChange={event => this.onChangeValue(event, 'localityName')}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment
                    //       position="start"
                    //       onClick={!isDisable && this.openUserSelector('Locality')}
                    //     >
                    //       <Search color={searchIconCllor} />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    disabled={isDisable}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Sub Locality Name"
                    fullWidth
                    disabled
                    value={subLocalityName || ''}
                    onChange={event => this.onChangeValue(event, 'subLocalityName')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <InputLabel style={{ fontSize: '0.9em' }} htmlFor="select-type"> Building Type </InputLabel>
                  <MaterialSelect
                    value={type}
                    inputProps={{
                      name: 'type',
                      id: 'select-status',
                    }}
                    onChange={event => this.onChangeValue(event, 'type')}
                    fullWidth
                  >
                    <MenuItem value="APARTMENT">Apartment</MenuItem>
                    <MenuItem value="INDIVIDUAL_HOUSE">Individual House</MenuItem>
                  </MaterialSelect>
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Building Name"
                    fullWidth
                    value={buildingName || ''}
                    onChange={event => this.onChangeValue(event, 'buildingName')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Block Name"
                    fullWidth
                    value={blockName || ''}
                    onChange={event => this.onChangeValue(event, 'blockName')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Street Details"
                    fullWidth
                    value={streetDetails || ''}
                    onChange={event => this.onChangeValue(event, 'streetDetails')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    type="Number"
                    label="Floor"
                    helperText="Please enter floor numer only"
                    fullWidth
                    value={floor || ''}
                    onChange={event => this.onChangeValue(event, 'floor')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="House Number"
                    fullWidth
                    value={houseNumber || ''}
                    onChange={event => this.onChangeValue(event, 'houseNumber')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Landmark"
                    fullWidth
                    value={landmark || ''}
                    onChange={event => this.onChangeValue(event, 'landmark')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <InputLabel style={{ fontSize: '0.9em' }} htmlFor="select-status"> Status </InputLabel>
                  <MaterialSelect
                    value={active ? 'ACTIVE' : 'INACTIVE'}
                    inputProps={{
                      name: 'selectedStatus',
                      id: 'select-status',
                    }}
                    onChange={event => this.onChangeValue(event, 'active')}
                    fullWidth
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                  </MaterialSelect>
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Latitude"
                    fullWidth
                    disabled
                    value={latitude || ''}
                    onChange={event => this.onChangeValue(event, 'latitude')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <TextField
                    label="Longitude"
                    fullWidth
                    disabled
                    value={longitude || ''}
                    onChange={event => this.onChangeValue(event, 'longitude')}
                  />
                </ColMod>
                <ColMod lg={4}>
                  <InputLabel style={{ fontSize: '0.9em' }} htmlFor="select-destination">
                    Address Status *
                  </InputLabel>
                  {this.renderDeliveryView(!verified)}
                </ColMod>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'initial', paddingLeft: '10px' }}>
                <div style={{ paddingRight: '10px' }}>
                  <Typography variant="body2" color="secondary">
                    Full Address:
                  </Typography>
                </div>
                <Typography variant="body2" color="primary">
                  {this.generateAddress()}
                </Typography>
              </div>
            </ColMod>
          </Row>
        </Grid>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Status } = state;
  const { addressStatuses } = Status;
  return {
    addressStatuses,
  };
};

export default withRouter(connect(
  mapStateToProps,
  null,
)(EditAddressComponent));
