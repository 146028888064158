const AddressPageTitle = {
  NEW_CUSTOMER: 'New Customers',
  UNASSIGNED: 'Unassigned Customers',
  CUSTOMER_ADDRESSES: 'Customer Addresses',
  ADDRESSES: 'Customer Addresses',
};

const isDev = process.env.NODE_ENV === 'development';

export default { ...AddressPageTitle, isDev };

