import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const columnData = [
  {
    id: 'product', numeric: false, disablePadding: true, label: 'Product',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'variant', numeric: false, disablePadding: true, label: 'Unit of Measure',
  },
  {
    id: 'sellingPrice', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  {
    id: 'offerPrice', numeric: false, disablePadding: true, label: 'Offer Price',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Action',
  },

];

const styles = {
  cell: {
    padding: 0,
  },
  button: {
    textAlign: 'center',
    margin: '30px 0px',
  },
};

class CustomerDelivery extends Component {
    handleChange = (index, name, value) => {
      const { onChange } = this.props;
      onChange(index, name, value);
    }

  renderDilveryItem = (item, index) => {
    const { product } = item;
    const { productItems = [] } = product;
    const [productItem] = productItems;
    const { unitMeasure, uom = {} } = productItem;
    const { onSave } = this.props;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={product.id}
      >
        <TableCell style={styles.cell}>{product.name}</TableCell>
        <TableCell style={styles.cell}>
          <div style={{ flex: 1 }}>
            <TextField
              type="number"
              style={{ width: '50px' }}
              value={item.quantity}
              onChange={e => this.handleChange(index, 'quantity', e.target.value)}
            />
          </div>
        </TableCell>
        <TableCell style={styles.cell}>{`${unitMeasure} ${uom.code}`}</TableCell>
        <TableCell style={styles.cell}>{productItem.sellingPrice}</TableCell>
        <TableCell style={styles.cell}>{productItem.offerPrice ? productItem.offerPrice : "No offer" }</TableCell>
        <TableCell style={styles.cell}>
          <Button color="secondary" onClick={() => onSave(index, false)}>Save</Button>
          <Button color="default" onClick={() => onSave(index, true)}><DeleteIcon /></Button>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { deliveries = [] } = this.props;
    return (
      <div>
        <Table className="table" aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {
                columnData.map(col => (
                  <TableCell
                    numeric={col.numeric}
                    padding={col.disablePadding ? 'none' : 'default'}
                  >
                    {col.label}
                  </TableCell>
                ))
            }
            </TableRow>
          </TableHead>
          <TableBody>
            {
                deliveries.map((deliveryItem, index) => (
                    this.renderDilveryItem(deliveryItem, index)
                ))
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

CustomerDelivery.propTypes = {
  deliveries: PropTypes.array,
};

export default CustomerDelivery;
