import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Select,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ProgressBar from '../../../components/States/ProgressBar';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Search from '@material-ui/icons/Search';
import { MenuItem } from 'material-ui';
import UserSelection from '../../../components/UserSelection';

const styles = {
  container: {
    width: window.innerWidth * 0.4,
    paddingBottom: "30px"
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  }
}

class SegregatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSegregator:
      {
        name: '',
        mobile: "",
        email: "",
        status: true,
        supervisorId: null,
        departmentIds: [],
        roles: [],
        scmUiAccess: false,
        supervisorName: null,
        appAccess: false,
      },
      openUserSelector: false,
      invalidEmailMsg: '',
      invalidMobileMsg: '',
      selectedDepartment: null,
    }
  }
  componentDidMount() {
    const { selectedSegregator, departments } = this.props;
    let selectedDepartment = null;

    departments.map(dept => {
      if (dept.name === 'Supply Chain') selectedDepartment = dept.id;
    })

    if (selectedSegregator !== null) {
      if (!selectedSegregator['scmUiAccess']) selectedSegregator['scmUiAccess'] = false;
      if (!selectedSegregator['appAccess']) selectedSegregator['appAccess'] = false;

      selectedSegregator['departmentIds'] = [];
      selectedSegregator['departments'].map(dept => {
        if (dept.id !== selectedDepartment) selectedSegregator['departmentIds'].push(+dept.id);
      });

      selectedSegregator['departmentIds'].push(selectedDepartment);
      this.setState({ selectedSegregator });

    }
    this.setState({ selectedDepartment });
  }

  handleChangeScmUiAccess = () => {
    this.setState(prevState => {
      const { selectedSegregator } = prevState;
      return {
        selectedSegregator: {
          ...selectedSegregator,
          scmUiAccess: !selectedSegregator.scmUiAccess,
        },
      }
    })
  }

  handleStatusChange = (value) => {
    this.setState(prevState => {
      const { selectedSegregator } = prevState;
      return {
        selectedSegregator: {
          ...selectedSegregator,
          status: value,
        },
      };
    })
  }

  handleChange = (event) => {
    const { id, value, name = "" } = event.target;
    this.setState(prevState => {
      const { selectedSegregator } = prevState;
      if (name === 'roles') {
        const { roles } = selectedSegregator;
        const index = roles.findIndex(role => value === role);
        if (index >= 0) {
          roles.splice(index, 1);
        } else {
          roles.push(value);
        }
        return {
          selectedSegregator: {
            ...selectedSegregator,
            ['roles']: roles,
          },
        };
      }
      if (id === 'appAccess' || id === 'scmUiAccess') {
        return {
          selectedSegregator: {
            ...selectedSegregator,
            [id]: !selectedSegregator[id],
          },
        };
      }
      return {
        selectedSegregator: {
          ...selectedSegregator,
          [id]: value,
        },
      };
    })
  }

  onSave = () => {
    if (!this.validateFields()) {
      return;
    }
    const { selectedSegregator } = this.state;
    if (selectedSegregator.id) this.props.onSave(selectedSegregator);
    else this.props.onCreateSegregator(selectedSegregator);
  }

  validateFields = () => {
    const validateFields = ['name', 'email', 'mobile', 'roles', 'supervisorId', 'appAccess'];
    const { selectedSegregator = {} } = this.state;
    let valid = true;

    for (const key in selectedSegregator) {
      if (validateFields.includes(key)) {
        if (!this.isPropertyValid(key, selectedSegregator[key])) {
          valid = false;
        }
      }
    }
    return valid;
  }

  isPropertyValid = (property, value) => {
    // value = value.trim()
    if (property === 'appAccess') {
      if (!(value || this.state.selectedSegregator.scmUiAccess)) {
        this.setError(property, true);
        return false;
      } else {
        this.setError(property, false);
        return true;
      }
    }
    if (property === "email") {
      if (this.state.selectedSegregator.scmUiAccess) {
        if (!value) {
          this.setState({ invalidEmailMsg: 'required' });
          this.setError(property, true);
          return false;
        }
      }
      else if (!value) {
        this.setState({ invalidEmailMsg: '' });
        this.setError(property, false);
        return true;
      }
      if (!(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(value))) {
        this.setError(property, true);
        this.setState({ invalidEmailMsg: 'Email is not valid' });
        return false;
      }
    }
    if (property === 'mobile') {
      if (!value) {
        this.setState({ invalidMobileMsg: 'required' });
        this.setError(property, true);
        return false;
      }
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(value)) {
        this.setState({ invalidMobileMsg: "Please enter only numbers." });
        this.setError(property, true);
        return false;

      } else if (value.length != 10) {
        this.setState({ invalidMobileMsg: "Please enter valid mobile number." });
        this.setError(property, true);
        return false;

      }
    }

    if (property === "roles") {
      if (value.length === 0) {
        this.setError(property, true);
        return false;
      }
    }
    if (!value) {

      this.setError(property, true);
      return false;
    }


    this.setError(property, false);
    return true;
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  onCloseUserSelector = () => {
    this.setState({
      openUserSelector: false,
    });
  }

  openUserSelector = userType => () => {
    this.setState({
      openUserSelector: true,
      userType,
    });
  }

  selectUser = selectedSupervisor => {
    this.setState(prevState => {
      return {
        selectedSegregator: {
          ...prevState.selectedSegregator,
          supervisorId: selectedSupervisor.id,
          supervisorName: selectedSupervisor.name,
        }
      }
    })
    this.onCloseUserSelector();
  }

  isRoleChecked = (el) => {
    const { selectedSegregator } = this.state;
    const { roles } = selectedSegregator;
    let data = false;
    roles.find(role => {
      if (el === role) data = true;
    });

    return data;
  }
  
  isDummyMail = () => {
    const { selectedSegregator: { email = '' } } = this.state;
    if (email.includes('@dummyemail.com')) return true;
    return false;

  }

  render() {
    const { onClose, segregatorRoles, departments, disableButton } = this.props;
    const {
      isLoading,
      selectedSegregator,
      openUserSelector,
      error,
      invalidEmailMsg,
      invalidMobileMsg,
      selectedDepartment,
    } = this.state;

    return (
      <Grid className={styles.container} fluid>
        <ProgressBar isLoading={isLoading} />
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={styles.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{!selectedSegregator.id ? 'Add Segregator' : 'Edit Segregator'}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary" variant="outlined">Cancel</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" disabled={disableButton}
                  onClick={this.onSave}
                >{!selectedSegregator.id ? 'Add' : 'Save'}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" color="secondary"> * Required Fields </Typography>
          </ColMod>
        </Row>

        <ColMod md={12}>
          <Typography variant="body2" color="inherit"> Access </Typography>

          <FormGroup row>
            <FormControlLabel
              control={<Checkbox
                checked={selectedSegregator.appAccess}
                onChange={this.handleChange} id='appAccess' />}
              label={"App Access"}
            />
            <FormControlLabel
              control={<Checkbox
                checked={selectedSegregator.scmUiAccess}
                onChange={this.handleChange} id='scmUiAccess' />}
              label={"SCM UI Access"}
            />

          </FormGroup>
          <FormHelperText error={error && error.appAccess}>
            {error && error.appAccess ? 'required' : ''}
          </FormHelperText>
        </ColMod>

        <ColMod md={12}>
          <Typography variant="body2" color="inherit">
            Status
            &nbsp;&nbsp;&nbsp;&nbsp;
            <small style={{ color: 'red' }}>Inactive</small>
            &nbsp;
            <Switch
              id="status"
              checked={selectedSegregator.status}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(value) => { this.handleStatusChange(value) }}
            />
            &nbsp;
            <small style={{ color: '#1E90FF' }}>Active</small>
          </Typography>
        </ColMod>

        <ColMod md={12}>
          <TextField
            id="name"
            type="text"
            label="Name"
            // name="name"
            value={selectedSegregator.name}
            onChange={this.handleChange}
            error={error && error.name && !selectedSegregator.name}
            helperText={error && error.name && 'required'}
            required
            fullWidth
            autoComplete="off"
          />
        </ColMod>
        <ColMod md={12}>
          <TextField
            id="email"
            type="text"
            label="Email"
            // name="email"
            value={selectedSegregator.email}
            onChange={this.handleChange}
            error={error && error.email}
            helperText={(error && error.email && invalidEmailMsg) || (selectedSegregator.id && this.isDummyMail(email) && 'Mail cannot be sent to this dummy email')}
            required={selectedSegregator.scmUiAccess}
            fullWidth
            autoComplete="form-email"
          />
        </ColMod>
        <ColMod md={12}>
          <TextField
            type="text"
            label="Mobile"
            id="mobile"
            value={selectedSegregator.mobile}
            onChange={this.handleChange}
            error={error && error.mobile}
            helperText={error && error.mobile && (invalidMobileMsg !== '' ? invalidMobileMsg : 'required')}
            required
            fullWidth
            autoComplete="form-mobile"
          />
        </ColMod>
        <ColMod md={12}>
          <TextField
            type="text"
            label="Supervisor"
            id="supervisorId"
            value={selectedSegregator.supervisorName || ''}
            onClick={this.openUserSelector('Supervisor')}
            onChange={this.handleChange}
            error={error && error.supervisorId}
            helperText={error && error.supervisorId && 'required'}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
            autoComplete="form-supervisor"
          />


        </ColMod>
        <ColMod md={12}>
          <Typography variant="body2" > Department * </Typography>
          <Select
            onChange={this.handleChange}
            value={selectedDepartment || ""}
            disabled
            fullWidth
          >

            {departments.map(dept => (
              <MenuItem value={dept.id} key={dept.id}>{dept.name}</MenuItem>
            ))}

          </Select>
        </ColMod>
        <ColMod md={12}>
          <Typography variant="body2" color="inherit">
            Role
            <FormGroup row>
              {Object.keys(segregatorRoles).map(key => (
                <FormControlLabel
                  control={<Checkbox
                    checked={this.isRoleChecked(key)}
                    onChange={this.handleChange}
                    name='roles'
                    value={key} />}
                  label={segregatorRoles[key]}
                />
              ))}
            </FormGroup>
            <FormHelperText error={error && error.roles}>
              {error && error.roles ? 'required' : ''}
            </FormHelperText>
          </Typography>
        </ColMod>
        <UserSelection
          userType={'Supervisor'}
          openSearchBox={openUserSelector}
          handleClose={this.onCloseUserSelector}
          selectUser={this.selectUser}
        />
      </Grid >
    )
  }
}

export default withStyles(styles)(SegregatorForm);
