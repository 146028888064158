import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GoogleLogin from 'react-google-login';
import apiCall, { resendOpt, validatePassword } from "../../api/NetworkHandler";
import { loginUser } from "../../actions/index";
import { validateEmails } from '../../utils/Validators';
import { Grid, Row, Col } from 'react-flexbox-grid';

const placeholder = {
  color: '#f50057',
};

const theme = createMuiTheme({
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #ffffff',
        },
        '&:after': {
          borderBottom: '2px solid #f50057',
        },
        '&:hover:not(:focus):before': {
          borderBottom: '2px solid #f50057 !important',
        },
        '&:active': {
          borderBottom: '1px solid #f50057',
        },
      },
      root: {
        color: '#f50057',
      },
      input: {
        color: '#ffffff !important',
        '&::-webkit-input-placeholder': placeholder,
        '&::-moz-placeholder': placeholder, // Firefox 19+
        '&:-ms-input-placeholder': placeholder, // IE 11
        '&::-ms-input-placeholder': placeholder, // Edge
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 0.54) !important',
      },
      focused: {
        color: 'rgb(255, 255, 255) !important',
      },
    },
  },
});

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        otp: '',
        newPassword: '',
        confirmPassword: '',
      },
      isPasswordSame: true,
      isPasswordLengthSix: true,
      isEmailAvailable: false,
      validationMessage: ''
    };
  }

  componentDidMount() {
    this.setEmail();
  }

  setEmail = () => {
    const { state } = this.props.location;
    const isEmailAvailable = state ? state.email : state;

    this.setState(prevState => {
      const { formData } = prevState;

      return {
        formData: {
          ...formData,
          email: isEmailAvailable ? state.email : '',
        },
        isEmailAvailable,
      }
    })
  }

handleChange = (event) => {
  const {name} = event.target;
  const {value} = event.target;
  this.setState(prevState => ({
    isPasswordSame: true,
    isPasswordLengthSix: true,
    formData: {
      ...prevState.formData,
      [name]: value,
    },
  }));
}

saveNewPassword = async () => {
  if (!this.validateEmailAndDisplayMessage()) {
    return;
  }
  if (!this.validateOtp()) {
    return;
  }
  
  if (this.state.formData.newPassword !== this.state.formData.confirmPassword) {
    this.setState({
      isPasswordSame: false,
    });
    return;
  }
  if (this.state.formData.newPassword.length < 6) {
    this.setState({
      isPasswordLengthSix: false,
    });
    return;
  }

  const { formData: { email, otp, newPassword } } = this.state;

  const passwordData = {
    email,
    otp,
    password: newPassword,
  }

  try {
    await apiCall.sendRequest('post', validatePassword, passwordData);
    this.setState({ validationMessage: '' });
    this.props.history.push('/login');
  } catch (error) {
    const { response: { data: { message } } } = error;
    this.setState({ validationMessage: message });
  }
}

resendOtp = async () => {
  const { formData: { email } } = this.state;

  if(!this.validateEmailAndDisplayMessage()){
    return;
  }
  
  const postData = {
    email
  }

  try {
    await apiCall.sendRequest('post', resendOpt, postData);
    this.setState({ validationMessage: '' });
  } catch (error) {
    const { response: { data: { message } } } = error;
    this.setState({ validationMessage: message });
  }
}

validateEmailAndDisplayMessage = () => {
  const { formData: { email } } = this.state;

  if (!email) {
    this.setState({ validationMessage: 'email is required' });
    return false;
  }
  const isEmailValid = validateEmails(email);

  if (!isEmailValid) {
    this.setState({ validationMessage: 'email is invalid' });
    return false;
  }
  this.setState({ validationMessage: '' });
  return true;
}

validateOtp = () => {
  const { formData: { otp } } = this.state;

  if (!otp) {
    this.setState({ validationMessage: 'otp is required' });
    return false;
  }

  this.setState({ validationMessage: '' });
    return true;
}

render() {
  const { isEmailAvailable, formData: { email, otp }, validationMessage } = this.state;
	  return (
  <MuiThemeProvider theme={theme}>
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000000' }}>
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        flexDirection: 'column',
        width: '30%',
        }}
      >
        <img
            src="../../../public/images/FTHDailyIcon.png"
            alt="Logo"
            style={{height: '128px', width: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
          />
        {!this.state.isPasswordSame &&
        <Typography color="secondary">
					Password not same
        </Typography>
						}
        {!this.state.isPasswordLengthSix &&
        <Typography color="secondary">
								Minimum 6 characters
        </Typography>
						}
        {validationMessage &&
        <Typography color="secondary">
								{validationMessage}
        </Typography>
						}
        {(!this.state.isPasswordSame || !this.state.isPasswordLengthSix)}
        <TextField 
          type="text"
          name="email"
          label="Email"
          value={email}
          onChange={this.handleChange}
          style={{ marginTop: '10px' }}
          inputProps={{ readOnly: isEmailAvailable }}
          fullWidth
        />
        <TextField 
          type="text"
          name="otp"
          label="OTP"
          value={otp}
          onChange={this.handleChange}
          inputProps={{ maxLength: 4 }}
          style={{ marginTop: '10px' }}
          fullWidth
        />
        <TextField
          name="newPassword"
          type="password"
          label="New Password"
          value={this.state.formData.newPassword}
          onChange={this.handleChange}
          fullWidth
          style={{ marginTop: '10px' }}
        />
        <TextField
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          value={this.state.formData.confirmPassword}
          onChange={this.handleChange}
          fullWidth
          style={{ marginTop: '10px' }}
        />       
        <Grid style={{ marginTop: '15px' }}>
          <Row md={12}>
            <Col md={6}>
              <Button variant="contained" color="secondary" fullWidth onClick={this.saveNewPassword}>
                Set Password
              </Button>
            </Col>
            <Col md={6}>
              <Button variant="outlined" color="secondary" fullWidth onClick={this.resendOtp}>
                Resend OTP
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  </MuiThemeProvider>
	  );
}
}

export default withRouter(SetPassword);
