import React, { Component } from 'react';
import UnAssignedCustomerComponent from '../Component/UnAssignedCustomerComponent';
import apiCall, { getUnAssignedCustomer } from '../../../api/NetworkHandler';

class UnAssignedCustomerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCustomersData: [],
    };
  }

  fetchUnAssignedCustomers = (filters, page = 0) => {
    this.setState({
      newCustomersData: [],
    });
    apiCall
      .sendRequest('get', getUnAssignedCustomer(filters, page))
      .then((response) => {
        const { data : { data = {}} } = response;
        const { content = [], totalPages = 1, totalElements = 0 } = data;
        this.setState({ newCustomersData: content, totalPages, totalElements });
      }).catch((error) => {
        this.setState({
          newCustomersData: [],
        });
      });
  }

  render() {
    const { newCustomersData = [], totalPages, totalElements } = this.state;
    return (
      <div>
        <UnAssignedCustomerComponent
          data={newCustomersData}
          totalElements={totalElements}
          totalPages={totalPages}
          updateData={this.fetchUnAssignedCustomers}
          {...this.props}
        />
      </div>
    );
  }
}

export default UnAssignedCustomerContainer;
