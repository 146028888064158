import React from 'react';
import PlanComponent from '../Component/PlanComponent';

class PlanContainer extends React.Component {
  render() {
    return (
      <div>
        <PlanComponent {...this.props} />
      </div>
    );
  }
}

export default PlanContainer;
