import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core/';
import { Search, Clear } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import styles from './styles';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    const { query } = this.props;
    this.state = {
      csId: '',
      query,
    };
  }

  onChangeCsId = (event) => {
    const { value } = event.target;
    if (value < 0) return;
    this.setState({ 
      csId: value,
    });
  };

  handleQuickSearch = (event) => {
    const { value } = event.target;
    const { quickSearch } = this.props;
    this.setState({
      query: value
    }, () => quickSearch(value));
  };

  handleClearQuickSearch = () => {
    const { quickSearch } = this.props;
    this.setState({
      query: '',
    }, () => quickSearch(''));
  }

  handleCustomerIdSelector = (event) => {
    const { value } = event.target;
    const cid = value.trim();
    const { history, path, onPressEnter } = this.props;
    if (event.key === 'Enter' && !isEmpty(cid)) {
      onPressEnter();
      history.push({
        pathname: `/${path}/customer/${cid}`,
      });
      this.setState({ csId: '' });
      event.preventDefault();
    }
  };

  render() {
    const { customerId } = this.props;
    const { query, csId } = this.state;
    return (
      <div style={styles.filter}>
        <div style={styles.mainFilter}>
          <TextField
            fullWidth
            style={styles.quickSearchBox}
            label="Search for Customer Mobile, Name, or Email"
            // value={q}
            value={query}
            onChange={this.handleQuickSearch}
            autoComplete="new-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {' '}
                  <Search />{' '}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start" onClick={this.handleClearQuickSearch}>
                  {' '}
                  <Clear />{' '}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={styles.pseudoFilter}>
          <TextField
            fullWidth
            style={styles.quickSearchBox}
            label="Customer Id"
            type="number"
            placeholder={customerId}
            value={csId}
            onChange={this.onChangeCsId}
            onKeyPress={this.handleCustomerIdSelector}
            autoComplete="new-input"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  history: PropTypes.instanceOf(Object),
  q: PropTypes.string,
  customerId: PropTypes.string,
  clearQuickSearch: PropTypes.func,
  quickSearch: PropTypes.func,
  path: PropTypes.string,
};

export default SearchBar;
