export default {
  csAssitBox: {
    backgroundColor: 'rgb(248, 248, 248)',
    minHeight: '100vh',
  },
  filter: {
    display: 'flex',
    padding: '10px 15px',
  },
  mainFilter: {
    width: '85%',
  },
  pseudoFilter: {
    width: '20%',
    boxSizing: 'border-box',
    paddingLeft: '32px',
  },
  quickSearchBox: {
    flexGrow: 1,
    paddingBottom: '10px',
  },
  autoCompleteBox: {
    padding: '10px 15px 0 15px',
    position: 'relative',
    zIndex: 1,
  },
  contentBox: {
    padding: '0px 15px',
  },
  emptyPage: {
    minHeight: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
