import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const colors = ['#000000', '#01a417', '#fa9917', '#f75f96'];

const styles = {
  number: (i, lastIndex) => ({
    fontSize: 40,
    borderRight: lastIndex ? 0 : '1px solid #000000',
    width: '98%',
    textAlign: 'center',
    lineHeight: 0.8,
    color: colors[i],
  }),
  numberTitle: {
    textAlign: 'center',
  },
};

class MonitorCard extends Component {
  render() {
    const { name = '', data = [] } = this.props;
    return (
      <Paper elevation={1} style={{ padding: 30, borderRadius: 0 }}>
        <Grid container spacing={8}>
          <Grid item md={6}>{name.replaceAll('_', ' ')}</Grid>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            <Typography>
              {/*
              <a
              style={{ color: Colors.primary, textDecoration: 'none' }}
              href="/purchase-order">Details</a>
               */}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
          {
            data.map((item, i) => (
              <Grid md={Math.floor(12 / data.length)}>
                <a href={item.url} style={{ textDecoration: 'none' }}>
                  <div style={styles.number(i % 4, i === (data.length - 1))}>{item.value}</div>
                </a>
              </Grid>
            ))
          }
        </Grid>
        <Grid container>
          {
            data.map(item => (
              <Grid md={Math.floor(12 / data.length)}>
                <div style={styles.numberTitle}>{item.label}</div>
              </Grid>
            ))
          }
        </Grid>
      </Paper>
    );
  }
}

MonitorCard.propTypes = {
  name: PropTypes.string,
  data: PropTypes.instanceOf(Array),
};


// eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export default MonitorCard;
