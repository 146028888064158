import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import Colors from '../../../utils/Colors';
import TableHeader from '../../Commons/components/TableHeader';
import apiCall, { hubIdPathUrl } from '../../../api/NetworkHandler';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';

const columnData = [
  {
    id: 'deliveryDate',
    numeric: false,
    disablePadding: true,
    label: 'Delivery Date',
  },
  {
    id: 'warehouseName',
    numeric: false,
    disablePadding: true,
    label: 'Ware House',
  },
  {
    id: 'hubName',
    numeric: false,
    disablePadding: true,
    label: 'Hub',
  },
  {
    id: 'totalQty',
    numeric: false,
    disablePadding: true,
    label: 'Required Qty',
  },
  {
    id: 'segregatedQty',
    numeric: false,
    disablePadding: true,
    label: 'Segregated Qty',
  },

];

const columnData1 = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Product Item Name',
  },
  {
    id: 'totalQuantity',
    numeric: false,
    disablePadding: true,
    label: 'Required Qty',
  },
  {
    id: 'existingQuantity',
    numeric: false,
    disablePadding: true,
    label: 'Segregated Qty',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 6,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: 10,
  },
  tableCell: {
    textAlign: 'center',
  },
  titleCell: {
    textAlign: 'left',
  },
};

class HubSegregationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
      hubData: [],
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  handleClick = (hub) => {
    const { deliveryDate, hubId } = hub;
    apiCall.sendRequest('get', hubIdPathUrl(deliveryDate, hubId))
      .then((response) => {
        const { data: { data = [] } } = response;
        this.setState({
          hubData: data,
        });
      });
  }

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };


  render() {
    const {
      segregationList = [], rowsPerPage = 20, page = 0,
    } = this.props;
    const { selectedData = {}, hubData, rowsPerPageOptions } = this.state;
    const pagination = rowsPerPage - Math.min(rowsPerPage, segregationList.length - page * rowsPerPage);

    return (
      <div>

        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <Table aria-labelledby="tableTitle">
              <TableHeader columns={columnData} cellStyle={styles.tableCell} />
              <TableBody key={page}>
                {
                  segregationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(hub => (
                    <TableRow
                      hover
                      onClick={() => this.handleClick(hub)}
                      tabIndex={-1}
                      key={hub.hubId}
                      selected={!isEmpty(selectedData) && selectedData.id === hub.hubId}
                    >
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          { hub.deliveryDate || '' }
                        </div>
                      </TableCell>
                       <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        {hub.warehouseName}
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          { hub.hubName || ''}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          { hub.totalQty || ''}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          {hub.segregatedQty }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            {segregationList && segregationList.length > 0 ?
              <TablePagination
                component="div"
                count={segregationList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
              : null
          }
          </div>
        <div style={styles.paperRight}>
          <Table aria-labelledby="tableTitle">
              <TableHeader columns={columnData1} cellStyle={styles.tableCell} />
              <TableBody key={page}>
                {
                  hubData.map(route => (
                    <TableRow
                      hover
                      tabIndex={-1}
                    >
                      <TableCell style={styles.titleCell} component="th" scope="row" padding="none">
                        <div>
                          { route.productItem.name || ''}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          { route.totalQuantity || 0}
                        </div>
                      </TableCell>
                      <TableCell style={styles.tableCell} component="th" scope="row" padding="none">
                        <div>
                          { route.existingQuantity || 0 }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
        </div>
        </div>
      </div>
    );
  }
}

HubSegregationList.propTypes = {
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  segregationList: PropTypes.instanceOf(Array),
};

export default HubSegregationList;
