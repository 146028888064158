import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { AppstoreAddOutlined, HomeOutlined } from '@ant-design/icons';
import { Link, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';
import utils from '../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;

const { SubMenu } = Menu;

class SideBarMenu extends React.Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9', 'sub10', 'sub11', 'sub12', 'sub13', 'sub14', 'sub15'];
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isMenuSelected: '',
      current: '',
      openKeys: [this.findKeys()],
    };
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleClick = (e) => {
    console.log(e.key);
    const { history } = this.props;
    history.push(e.key)
  }

  handleChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  }

  findPath = () => {
    let path = '';
    if (this.props.history.location.pathname.split("/").slice(2)[0] === 'hub') { 
      path = ['manage-hub-segregation'];
    }
    else if (this.props.history.location.pathname.split("/").slice(2)[0] === 'route') {
      path = ['manage-route-segregation'];
    } 
    else {
      path = this.props.history.location.pathname.split("/").slice(1);
    }
    return path;
  }

  findKeys = () => {
    let defaultPath = '';
    const path = this.props.history.location.pathname.split("/").slice(1)
    if (['vendors', 'vendor-catalog-view', 'vendor-stock-ledger'].includes(path[0])) { defaultPath = 'sub1'};
    if (['users', 'groups', 'user-groups', 'segregators', 'delivery-boys', 'delivery-boys-detailed-report', 'delivery-boys-consolidated-report', 'attendance-summary'].includes(path[0])) { defaultPath = 'sub2'};
    if (['warehouses', 'hubs', 'hub-segregation', 'route-segregation', 'segregation-management'].includes(path[0])) { defaultPath = 'sub3'};
    if (['static-routes', 'delivery-routes', 'delivery-route-orders', 'customer-order', 'non-availability', 'default-route-mapping'].includes(path[0])) { defaultPath = 'sub4'};
    if (['purchase-order', 'purchase-indent', 'purchase-requisition', 'grn'].includes(path[0])) { defaultPath = 'sub5'};
    if (['catalog-view', 'brand', 'category', 'packaging', 'quantity', 'catalog-approval', 'products', 'price-list'].includes(path[0])) { defaultPath = 'sub6'};
    if (['stock-adjustment', 'stock-inventory', 'stock-ledger'].includes(path[0])) { defaultPath = 'sub7'};
    if (['region', 'survey', 'orders', 'pincode'].includes(path[0])) { defaultPath = 'sub8'};
    if (['cs-assist', 'customer-addresses', 'buildings', 'localities', 'new-customers', 'unassigned-customers', 'monthly-invoices'].includes(path[0])) { defaultPath = 'sub9'};
    if (['offers', 'offer-campaign'].includes(path[0])) { defaultPath = 'sub10'};
    if (['send-sms'].includes(path[0])) { defaultPath = 'sub11'};
    if (['investment'].includes(path[0])) { defaultPath = 'sub12'};
    if (['customer-complaints', 'customer-complaints-by-cc', 'customer-complaints-by-hub-route', 'tickets'].includes(path[0])) { defaultPath = 'sub13'};
    if (['banner-management', 'deal-of-the-day-products', 'product-widgets', 'home-offers','app-widgets'].includes(path[0])) { defaultPath = 'sub14'};
    if (['audit-report'].includes(path[0])) { defaultPath = 'sub15'};
    if (['tot-margin'].includes(path[0])) { defaultPath = 'sub16'};
    return defaultPath;
  }

  render() {
    const path = this.findPath();
    return (
      <div style={{ width: 285 }}>
        <Menu
          // defaultSelectedKeys={path}
          // defaultOpenKeys={defaultPath}
          mode="inline"
          theme="light"
          // inlineCollapsed={this.state.collapsed}
          // onClick={this.handleClick}
          selectedKeys={path}
          openKeys={this.state.openKeys}
          onOpenChange={this.handleChange}
        >
          <Menu.Item key='home' icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          {canView(['ADMIN', 'VENDOR_MANAGER', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub1" icon={<AppstoreAddOutlined />} title="Vendor Management">
              {canView(['ADMIN', 'VENDOR_MANAGER']) && (<Menu.Item key="vendors"><Link to="/vendors">Vendors</Link></Menu.Item>)}          
              {/* {canView(['ADMIN', 'VENDOR_MANAGER']) && (<Menu.Item key="vendor-catalog-view"><Link to="/vendor-catalog-view">Vendor Catalog</Link></Menu.Item>)} */}
              {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="vendor-stock-ledger"><Link to="/vendor-stock-ledger">Vendor Stock Ledger</Link></Menu.Item>)}
            </SubMenu>)}
          {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR','WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub2" icon={<AppstoreAddOutlined />} title="User Management">
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="users"><Link to="/users">Users</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="groups"><Link to="/groups">Groups</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="user-groups"><Link to="/user-groups">User Groups</Link></Menu.Item>)}
              {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="segregators"><Link to="/segregators">Segregators</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="delivery-boys"><Link to="/delivery-boys">Delivery Boys</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="delivery-boys-detailed-report"><Link to="/delivery-boys-detailed-report">Delivery Boys Detailed Report</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="delivery-boys-consolidated-report"><Link to="/delivery-boys-consolidated-report">Delivery Boys Consolidated Report</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'HR']) && (<Menu.Item key="attendance-summary"><Link to="/attendance-summary">Attendance Summary</Link></Menu.Item>)}
            </SubMenu>)}
          {canView(['ADMIN', 'ADMIN_MASTERDATA', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub3" icon={<AppstoreAddOutlined />} title="Warehouse Management">
              {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (<Menu.Item key="warehouses"><Link to="/warehouses">Warehouses</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="hubs"><Link to="/hubs">Hubs</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="hub-segregation"><Link to="/hub-segregation">Hub Segregation</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="route-segregation"><Link to="/route-segregation">Route Segregation</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION','WAREHOUSE_MANAGER']) && (<Menu.Item key="manage-hub-segregation"><Link to="/segregation-management/hub">Manage Hub Segregation</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'SEGREGATION', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="manage-route-segregation"><Link to="/segregation-management/route">Manage Route Segregation</Link></Menu.Item>)}
            </SubMenu>)}
          {canView(['ADMIN', 'ADMIN_DELIVERY', 'DELIVERY_MANAGER', 'SUPERVISOR', 'PICKER', 'SEGREGATION_BOY', 'ROUTE_MANAGER', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub4" icon={<AppstoreAddOutlined />} title="Delivery Management">
              {canView(['ADMIN', 'ADMIN_DELIVERY', 'ROUTE_MANAGER']) && (<Menu.Item key="static-routes"><Link to="/static-routes">Static Routes</Link></Menu.Item>)}
              {canView(['ADMIN', 'DELIVERY_MANAGER', 'SUPERVISOR', 'PICKER', 'SEGREGATION_BOY',]) && (<Menu.Item key="delivery-routes"><Link to="/delivery-routes">Delivery Routes</Link></Menu.Item>)}
              {canView(['ADMIN', 'DELIVERY_MANAGER', 'SUPERVISOR', 'PICKER', 'SEGREGATION_BOY',]) && (<Menu.Item key="delivery-route-orders"><Link to="/delivery-route-orders">Delivery Route Order</Link></Menu.Item>)}
              {canView(['ADMIN', 'DELIVERY_MANAGER', 'WAREHOUSE_MANAGER', 'CSR']) && (<Menu.Item key="customer-order"><Link to="/customer-order">Customer Orders</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_DELIVERY', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="non-availability"><Link to="/non-availability">Non Availability</Link></Menu.Item>)}
              {canView(['ADMIN']) && (<Menu.Item key="default-route-mapping"><Link to="/default-route-mapping">Dummy Route Mapping</Link></Menu.Item>)}
            </SubMenu>)}
          {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub5" icon={<AppstoreAddOutlined />} title="Purchasing">
              {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="purchase-order"><Link to="/purchase-order">Purchase Orders</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="purchase-indent"><Link to="/purchase-indent">Purchase Indent</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="purchase-requisition"><Link to="/purchase-requisition">Purchase Requisition</Link></Menu.Item>)}
              {canView(['ADMIN', 'ADMIN_MASTERDATA', 'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="grn"><Link to="/grn">GRN</Link></Menu.Item>)}
            </SubMenu>)}
          {canView(['ADMIN', 'PURCHASING_MANAGER']) && (
            <SubMenu key="sub6" icon={<AppstoreAddOutlined />} title="Product Catalog Management">
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="catalog-view"><Link to="/catalog-view">Product Catalog</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="brand"><Link to="/brand">Brands</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="category"><Link to="/category">Categories</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="packaging"><Link to="/packaging">Packaging</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="quantity"><Link to="/quantity">UOM's</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="catalog-approval"><Link to="/catalog-approval">Vendor Catalog Approval</Link></Menu.Item>)}
              {canView(['ADMIN', 'PURCHASING_MANAGER']) && (<Menu.Item key="products"><Link to="/products">Products</Link></Menu.Item>)}
              {/* {canView(['ADMIN']) && (<Menu.Item key="price-list"><Link to="/price-list">Price List</Link></Menu.Item>)} */}
            </SubMenu>)}
          {canView(['ADMIN', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub7" icon={<AppstoreAddOutlined />} title="Stock Management">
              {/* <Menu.Item key="stock-adjustment"><Link to="/stock-adjustment">Stock Adjustment</Link></Menu.Item>           */}
              <Menu.Item key="stock-inventory"><Link to="/stock-inventory">Stock Inventory</Link></Menu.Item>
              <Menu.Item key="stock-ledger"><Link to="/stock-ledger">Stock Ledger</Link></Menu.Item>
            </SubMenu>)}
          {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
            <SubMenu key="sub8" icon={<AppstoreAddOutlined />} title="Admin">
              <Menu.Item key="region"><Link to="/region">Regions</Link></Menu.Item>
              <Menu.Item key="survey"><Link to="/survey">Survey</Link></Menu.Item>
              <Menu.Item key="orders"><Link to="/orders">Orders</Link></Menu.Item>
              <Menu.Item key="pincode"><Link to="/pincode">Pincodes</Link></Menu.Item>
            </SubMenu>)}
        {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER', 'FINANCE']) && (
          <SubMenu key="sub9" icon={<AppstoreAddOutlined />} title="Customer Management">
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (<Menu.Item key="cs-assist"><Link to="/cs-assist">Customer Dashboard</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (<Menu.Item key="customer-addresses"><Link to="/customer-addresses">Customer Addresses</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (<Menu.Item key="buildings"><Link to="/buildings">Buildings</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (<Menu.Item key="localities"><Link to="/localities">Localities</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (<Menu.Item key="new-customers"><Link to="/new-customers">New Customers</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (<Menu.Item key="unassigned-customers"><Link to="/unassigned-customers">Unassigned Customers</Link></Menu.Item>)}
            {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER', 'FINANCE']) && (<Menu.Item key="monthly-invoices"><Link to="/monthly-invoices">Monthly Invoices</Link></Menu.Item>)}
          </SubMenu>)}
        {canView(['ADMIN', 'ADMIN_MASTERDATA']) && (
          <SubMenu key="sub10" icon={<AppstoreAddOutlined />} title="Offer Management">
            {/* <Menu.Item key="offers"><Link to="/offers">Offers</Link></Menu.Item> */}
            <Menu.Item key="offer-campaign"><Link to="/offer-campaign"> Offer Campaign </Link></Menu.Item>
          </SubMenu>)}
        {canView(['ADMIN', 'ADMIN_MASTERDATA', 'DELIVERY_MANAGER']) && (
          <SubMenu key="sub11" icon={<AppstoreAddOutlined />} title="Notification Management">
            <Menu.Item key="send-sms"><Link to="/send-sms">SMS</Link></Menu.Item>          
          </SubMenu>)}
        {canView(['INVEST_WITH_US']) && (
          <SubMenu key="sub12" icon={<AppstoreAddOutlined />} title="Invest With Us">
            <Menu.Item key="investment"><Link to="/investment">Invest Now</Link></Menu.Item>          
          </SubMenu>)}
        {canView(['ADMIN', 'DELIVERY_MANAGER', 'CC_AGENT', 'CC_MANAGER']) && (
          <SubMenu key="sub13" icon={<AppstoreAddOutlined />} title="Customer Complaints">
            {canView(['ADMIN', 'DELIVERY_MANAGER']) && (<Menu.Item key="customer-complaints"><Link to="/customer-complaints">Customer Complaints</Link></Menu.Item>)}
            {canView(['ADMIN', 'DELIVERY_MANAGER']) && (<Menu.Item key="customer-complaints-by-cc"><Link to="/customer-complaints-by-cc">Customer Complaints (CC Executive)</Link></Menu.Item>)}
            {canView(['ADMIN', 'DELIVERY_MANAGER']) && (<Menu.Item key="customer-complaints-by-hub-route"><Link to="/customer-complaints-by-hub-route">Customer Complaints (Hub & Route)</Link></Menu.Item>)}
            {canView(['ADMIN', 'DELIVERY_MANAGER', 'CC_AGENT', 'CC_MANAGER']) && (<Menu.Item key="tickets"><Link to="/tickets">Ticket Management</Link></Menu.Item>)}
          </SubMenu>)}
        {canView(['ADMIN', 'APP_MANAGER']) && (
          <SubMenu key="sub14" icon={<AppstoreAddOutlined />} title="App Management">
            <Menu.Item key="banner-management"><Link to="/banner-management">Banners</Link></Menu.Item>          
            <Menu.Item key="deal-of-the-day-products"><Link to="/deal-of-the-day-products">Deal of The Day</Link></Menu.Item>
            <Menu.Item key="product-widgets"><Link to="/product-widgets">Product Widgets</Link></Menu.Item>
            <Menu.Item key="app-widgets"><Link to="/app-widgets">App Widgets</Link></Menu.Item>
            <Menu.Item key="home-offers"><Link to ="/home-offers">Home Offers</Link></Menu.Item>
          </SubMenu>)}
        {canView(['ADMIN']) && (
          <SubMenu key="sub15" icon={<AppstoreAddOutlined />} title="Audit">
            <Menu.Item key="audit-report"><Link to="/audit-report">Audit Report</Link></Menu.Item>          
          </SubMenu>)}
        {canView(['ADMIN', 'VENDOR_MANAGER',  'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (
            <SubMenu key="sub16" icon={<AppstoreAddOutlined />} title="Margin Management">
              {canView(['ADMIN', 'VENDOR_MANAGER',  'PURCHASING_MANAGER', 'WAREHOUSE_MANAGER']) && (<Menu.Item key="tot-margin"><Link to="/tot-margin">TOT Margin</Link></Menu.Item>)}          
            </SubMenu>)}
        </Menu>
      </div>
    );
  }
}

export default withRouter(SideBarMenu);