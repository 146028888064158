import React from 'react';

const QuantityPicker = (props) => {
  const {
    sku, counter, onIncrement, onDecrement,
  } = props;
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{ padding: 5, backgroundColor: 'rgb(238, 238, 238)', cursor: 'pointer' }}
        onClick={() => onDecrement(sku)}
      >
        -
      </div>
      <div style={{ padding: 5, border: '1px solid rgb(238, 238, 238)' }}>{counter}</div>
      <div
        style={{ padding: 5, backgroundColor: 'rgb(238, 238, 238)', cursor: 'pointer' }}
        onClick={() => onIncrement(sku)}
      >
        +
      </div>
    </div>
  );
};

export default QuantityPicker;
