import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AddCircleOutline, RemoveCircleOutline, PlayArrow } from '@material-ui/icons';
import { Grid } from '@material-ui/core';


const styles = {
  email: {
    padding: 10,
    border: '1px solid #f1f1f1',
    marginBottom: 10,
    background: '#f5f5f5',
  },
  icon: {
    fontSize: 17,
    verticalAlign: 'bottom',
    margin: '0px 5px',
    color: '#f50057',
  },
  messageCardArrow: {
    color: '#f5f5f5',
    position: 'relative',
    top: -30,
    left: -5,
    fontSize: 40,
  },
};

class EmailHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  handleIconClick = (value) => {
    this.setState({
      expand: value,
    });
  }

  renderMessageCard = (message, icon, expand, timeSent, isReceived) => {
    let arrowStyle = styles.messageCardArrow;
    let cardStyle = styles.email;
    if (!isReceived) {
      arrowStyle = { ...styles.messageCardArrow, transform: 'rotate(65deg)', color: 'rgb(255, 237, 243)' };
      cardStyle = { ...styles.email, background: 'rgb(255, 237, 243)' };
    }

    return (
      <div style={{ marginBottom: -25, width: '90%' }}>
      <Grid container style={cardStyle}>
        {
          message.length <= 200
          ? <div>message</div>
          : <div>{expand ? message : message.substr(0, 200)}{icon}</div>
        }
        <div style={{ textAlign: 'right', width: '100%' }}>{moment(timeSent).format('lll')}</div>
      </Grid>
      <div style={{ textAlign: isReceived ? 'left' : 'right' }}>
        <PlayArrow style={arrowStyle} />
        </div>
      </div>
    );
  }

  render() {
    const { email = {} } = this.props;
    const {
      subject,
      timeUpdated,
      emailContent: message,
      messageType = 'RECEIVED',
    } = email;
    const { expand } = this.state;
    let icon = <AddCircleOutline style={styles.icon} onClick={() => this.handleIconClick(true)}/>;
    if (expand) {
      icon = <RemoveCircleOutline style={styles.icon} onClick={() => this.handleIconClick(false)}/>;
    }
    return (
      <div style={{ display: 'flex', justifyContent: messageType === 'SENT' ? 'flex-end' : 'flex-start' }}>
        {this.renderMessageCard(message, icon, expand, timeUpdated, messageType === 'RECEIVED')}
      </div>
    );
  }
}

EmailHistory.propTypes = {

};

export default EmailHistory;
