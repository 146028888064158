import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, get, isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import moment from 'moment';
import Actions from './Actions';
import EditPlan from './EditPlan';
import PlanDateHelper from './PlanDate';
import styles from './styles';
import { PRODUCT_TIME_FORMAT } from '../../../constants';
import HistoryContainer from '../../History/containers/HistoryContainer';

import apiCall, { getHistory } from '../../../api/NetworkHandler';

import ToolTip from '@material-ui/core/Tooltip';

import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../../constants';

class Scheduled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ...this.getPauseStatus(props.customerInfo, props.items),
      editItemObj: {},
      newItemObj: {},
      data: [],
      showHistory: false,
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  onEditItem = (item) => {
    this.setState({ editItemObj: item });
  };

  onCloseEdit = (isNewItem = false) => {
    if (isNewItem) {
      this.setState({ newItemObj: {} });
      this.props.cancelNewScheduledItem();
    } else {
      this.setState({ editItemObj: {} });
    }
  };

  onEditItemChange = (editedObjet, isNewItem = false) => {
    if (editedObjet.scheduleData) {
      const { scheduleData } = editedObjet;
      const { id = '', scheduleType, name } = scheduleData;
      const { cutOffTime, customFrequency, daysOfWeek } = this.state.editItemObj;
      const currentDate = moment().format('YYYY-MM-DD');
      const startDate = PlanDateHelper.getPlanDateOfProductFor(
        currentDate,
        {
          scheduleType,
          customFrequency,
          daysOfWeek,
        },
        cutOffTime,
        true,
      );
      if (isNewItem) {
        this.setState({
          newItemObj: {
            ...this.state.newItemObj,
            scheduleId: id,
            scheduleType,
            planName: name,
            startDate: moment(startDate).format('YYYY-MM-DD'),
          },
        });
      } else {
        this.setState({
          editItemObj: {
            ...this.state.editItemObj,
            scheduleId: id,
            scheduleType,
            planName: name,
            startDate: moment(startDate).format('YYYY-MM-DD'),
          },
        });
      }
    } else if (isNewItem) {
      this.setState({
        newItemObj: { ...this.state.newItemObj, ...editedObjet },
      });
    } else {
      this.setState({
        editItemObj: { ...this.state.editItemObj, ...editedObjet },
      });
    }
  };

  onUpdateEditedItem = () => {
    const { editItemObj } = this.state;
    const {
      quantity,
      unitMeasure,
      code,
      scheduleId,
      scheduleType,
      planItemId,
      pauseFrom = null,
      pauseTill = null,
      startDate,
    } = editItemObj;
    const bodyParams = {
      quantity,
      scheduleId,
      scheduleType,
      id: planItemId,
      pauseFrom,
      pauseTill,
      startDate,
      unitMeasure,
      code,
    };
    this.props.onUpdateItems(bodyParams);
    this.setState({ editItemObj: {} });
  };

  onCreateNewCustomPlan = (obj, isNewItem = false) => {
    const { customerId, onCreateCustomPlan } = this.props;
    onCreateCustomPlan(customerId, obj, (newSchedule) => {
      const { id, name, scheduleType } = newSchedule;
      if (isNewItem) {
        this.setState({
          newItemObj: {
            ...this.state.newItemObj,
            scheduleId: id,
            scheduleType,
            planName: name,
          },
        });
      } else {
        this.setState({
          editItemObj: {
            ...this.state.editItemObj,
            scheduleId: id,
            scheduleType,
            planName: name,
          },
        });
      }
    });
  };

  onUpdateNewItem = () => {
    const { newItemObj } = this.state;
    const { newScheduledItem } = this.props;
    const {
      quantity, scheduleId, scheduleType, startDate,
    } = newItemObj;
    const { id, categoryId } = newScheduledItem;
    const bodyParams = {
      quantity,
      scheduleId,
      scheduleType,
      productItemId: id,
      categoryId,
      startDate,
      status: 'ACTIVE',
    };
    this.props.onUpdateItems(bodyParams, true);
    this.setState({ newItemObj: {} });
  };
  getCustomComponents = (data) => {
    const customComponents = {
      modifiedBy: {},
      TIME_CREATED: {},
    };

    data && data.map((auditEntry, index) => {

      customComponents['TIME_CREATED'][index] = <ToolTip title={moment(auditEntry.TIME_CREATED).format(PRODUCT_TIME_FORMAT)}><span>{moment(auditEntry.TIME_CREATED).fromNow()}</span></ToolTip>;

      if (auditEntry.MODIFIED_BY) {
        customComponents['modifiedBy'][index] = auditEntry.MODIFIED_BY.Name;
      } else customComponents['modifiedBy'][index] = '';
    })
    return customComponents;
  };
  setPlanItemId = (data) => {
    const { planItemId } = data;
    this.setState({
      planItemId: planItemId,
      count: 0,
      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    }, () => {
      this.onViewHistory()
    });
  }
  onViewHistory = () => {
    const { page, rowsPerPage, planItemId } = this.state;
    apiCall.sendRequest('post', getHistory(), (
      {
        "methodName": 'PlanHistory',
        "params": JSON.stringify({
          "entityId": planItemId,
          "limit": rowsPerPage,
          "page": page,
        })
      })
    )
      .then((response) => {
        let { data } = response.data;
        // data = JSON.parse(data);
        const { PlanHistory, TotalElements } = data;
        this.setState({
          data: PlanHistory,
          showHistory: true,
          count: TotalElements,
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }
  handleChangePage = (event, page) => {
    this.setState({ page }, () => { this.onViewHistory() });
  };

  handleChangeRowsPerPage = (event) => {
    const { value: rowsPerPage } = event.target;
    this.setState({ rowsPerPage }, () => { this.onViewHistory() });
  };


  getPauseStatus = (info, items) => {
    const isCustomerPaused = this.customerPauseStatus(info);
    const isItemPaused = !isEmpty(items.filter(el => el.categoryPause === true));
    let warningNote = null;
    if (isItemPaused) warningNote = 'Few categories has been paused by the customer.';
    if (isCustomerPaused) {
      warningNote =
        'Customer has been autopaused/paused. If autopaused, it might be low wallet balance, or he manually paused it';
    }
    return {
      isCustomerPaused,
      warningNote,
    };
  };

  customerPauseStatus = (customerInfo) => {
    const { status, pauseFrom, pauseTill } = customerInfo;
    if (status === 'AUTO_PAUSED') {
      return true;
    }
    if (pauseFrom && pauseTill) {
      if (moment().isBetween(pauseFrom, pauseTill, null, '[]')) return true;
    }
    return false;
  };

  geItemsConfig = () => [
    {
      text: 'Item',
      pick: 'productName',
      style: styles.items.productName,
    },
    {
      text: 'Measure',
      pick: 'unitMeasure',
      style: styles.items.measure,
    },
    {
      text: 'Unit',
      pick: 'code',
      style: styles.items.code,
    },
    {
      text: 'Unit Price',
      pick: 'unitPrice',
      style: styles.items.unitPrice,
    },
    {
      text: 'Quantity',
      pick: 'quantity',
      componetType: 'input',
      props: {
        type: 'number',
        name: 'quantity',
        inputStyle: styles.inputItems,
      },
      style: styles.items.quantity,
    },
    {
      text: 'Start Date',
      pick: 'startDate',
      style: styles.items.date,
    },
    {
      text: 'Pause from',
      pick: 'pauseFrom',
      style: styles.items.date,
    },
    {
      text: 'Pause till',
      pick: 'pauseTill',
      style: styles.items.date,
    },
    {
      text: 'Plan Name',
      pick: 'planName',
      style: styles.items.plan,
    },
    {
      componet: true,
      componetType: 'action',
      style: styles.items.actions,
    },
  ];
  handleCloseHistory = () => {
    this.setState({
      showHistory: false,
    });
  };

  renderRow = (header = false, data = {}) => {
    const config = this.geItemsConfig();
    const { schedules, openModal, showDeletedSchedules } = this.props;
    const { isCustomerPaused, editItemObj } = this.state;
    const { categoryPause } = data;
    const cartItemStyle = isCustomerPaused
      ? { ...styles.cartItem, ...styles.itemPaused }
      : styles.cartItem;
    const itemPausedStyle = categoryPause ? styles.itemPaused : null;
    const editing = !header && editItemObj.planItemId === data.planItemId;
    return (
      <div style={editing ? { ...styles.itemEditing } : null}>
        <div
          style={
            header
              ? { ...styles.cartItem, ...styles.light }
              : { ...cartItemStyle, ...itemPausedStyle }
          }
        >
          {config.map((el) => {
            if (header) {
              return <div style={{ ...styles.itemStyle, ...el.style }}>{el.text}</div>;
            }
            if (
              el.componet &&
              el.componetType === 'action'
            ) {
              return (

                <Actions
                  isPlans
                  isCustomerPaused = {isCustomerPaused}
                  categoryPause = {categoryPause}
                  onEdit={() => this.onEditItem(data)}
                  onDelete={() => this.props.onDeleteItem(data)}
                  viewHistory={() => this.setPlanItemId(data)}
                  showDeletedSchedules={showDeletedSchedules}
                />
              );
            }
            return (
              <div style={{ ...styles.itemStyle, ...el.style }}>{get(data, el.pick) || '_'}</div>
            );
          })}
        </div>
        {editing && (
          <EditPlan
            data={editItemObj}
            schedules={schedules}
            closeEdit={this.onCloseEdit}
            onChange={this.onEditItemChange}
            updateEdit={this.onUpdateEditedItem}
            onCreateCustomPlan={this.onCreateNewCustomPlan}
          />
        )}
      </div>
    );
  };

  renderTable = d => (
    <div style={styles.orderItems}>
      {this.renderRow(true)}
      {map(d, el => this.renderRow(false, el))}
    </div>
  );

  renderNewItem = (newItem) => {
    const { newItemObj } = this.state;
    const { schedules } = this.props;
    return (
      <div style={styles.newItemEditing}>
        <div style={styles.newItem}>{newItem.name}</div>
        <EditPlan
          newItem
          data={newItemObj}
          schedules={schedules}
          onChange={this.onEditItemChange}
          onCreateCustomPlan={this.onCreateNewCustomPlan}
          updateEdit={this.onUpdateNewItem}
          closeEdit={this.onCloseEdit}
        />
      </div>
    );
  };

  render() {
    const { items, newScheduledItem, openModal, getCancelledPlans, showDeletedSchedules } = this.props;
    const { warningNote,
      showHistory, count, rowsPerPage, rowsPerPageOptions, data, page,
    } = this.state;
    return (
      <div style={styles.upContent}>
        {!isEmpty(items) && this.renderTable(items)}
        {!isEmpty(newScheduledItem) && this.renderNewItem(newScheduledItem)}
        {!isEmpty(items) && warningNote && <div style={styles.warning}>{warningNote}</div>}
        {isEmpty(items) && isEmpty(newScheduledItem) && (
          <div style={styles.empty}>Customer has no Plans</div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {!showDeletedSchedules &&
            <Button
              style={styles.addNewBtn}
              onClick={() => openModal('productSearch', 'schedule')}
              color="primary"
              variant="outlined"
              size="small"
            >
              + Add New Item
            </Button>
          }
          {!showDeletedSchedules &&
            <Button
              style={styles.addNewBtn}
              onClick={() => getCancelledPlans()}
              color="primary"
              variant="outlined"
              size="small"
            >
              DELETED PLANS
            </Button>
          }

        </div>
        <HistoryContainer openHistory={showHistory} handleClose={this.handleCloseHistory}
          data={data}
          title='Plan History'
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={this.handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

Scheduled.propTypes = {
  items: PropTypes.instanceOf(Array),
  customerInfo: PropTypes.instanceOf(Object),
  customerId: PropTypes.string,
  onUpdateItems: PropTypes.func,
  onCreateCustomPlan: PropTypes.func,
  newScheduledItem: PropTypes.instanceOf(Object),
  openModal: PropTypes.func,
  onDeleteItem: PropTypes.func,
  schedules: PropTypes.instanceOf(Array),
  cancelNewScheduledItem: PropTypes.func,
  // showDeletedSchedules:PropTypes.func,
  getCancelledPlans: PropTypes.func,
  showDeletedSchedules: PropTypes.bool,
};
Scheduled.defa

export default Scheduled;
