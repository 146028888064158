import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import NavBar from '../../../../components/NavBar';
import OfferDetail from '../components/OfferDetail';
import ProductOfferSearchBar from '../components/SearchBar';
import OfferTable from '../components/OfferTable';

const data = require('../data.json');

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffer: {},
      showOffer: false,
    };
  }

  handleOfferDetailPopup = (value) => {
    this.setState({
      showOffer: value,
      selectedOffer: {},
    });
  }

  handleEditOffer = (offer) => {
    console.log('offers', offer);
    this.setState({
      showOffer: true,
      selectedOffer: offer,
    });
  }

  displayOfferDetails = (offer) => {
    this.setState({
      selectedOffer: offer,
    });
  }

  render() {
    const {
      selectedOffer, showOffer,
    } = this.state;

    return (
      <div>
        <NavBar />
        <ProductOfferSearchBar
          openAddOffer={this.handleOfferDetailPopup}
          onChangeFilter={this.onChangeFilter}
        />
        <OfferDetail
          offer={selectedOffer}
          showOfferPopup={showOffer}
          handlePopup={this.handleOfferDetailPopup}

        />
        <div style={styles.div}>
          <div style={styles.paperLeft}>
            <OfferTable
              offers={data}
              editOffer={this.handleEditOffer}
              showOfferDetails={this.displayOfferDetails}
            />
          </div>
          <div style={styles.paperRight} >

            <Typography variant="body2" color="inherit" style={{ borderBottom: 'solid 1px #e0e0e0', height: '40px' }}>
                  Offer Details
            </Typography>
            {selectedOffer.productItemId}
            {selectedOffer.offerType}
            {selectedOffer.cashBackAmount}
            {selectedOffer.quantity}
            {selectedOffer.noOfDays}
          </div>
        </div>
      </div>

    );
  }
}


export default Dashboard;
