export default {
  title: {
    fontSize: '1em',
  },
  action: {
    display: 'flex',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  editFields: {
    display: 'flex',
    margin: '16px 0 32px',
    paddingBottom: '16px',
    justifyContent: 'space-between',
    // borderBottom: '1px solid rgb(221, 221, 221)',
  },
  editFieldsLeft: {
    display: 'flex',
  },
  staticCs: {
    minWidth: '20%',
    marginRight: '32px',
  },
  editCs: {
    minWidth: '20%',
    marginLeft: '32px',
  },
  editCsFields: {
    minWidth: '100%',
  },
  btn: {
    width: '128px',
    marginLeft: '10px',
  },
  addNewBtn: {
    width: '128px',
  },
  orderItems: {
    width: '100%',
  },
  editItems: {
    display: 'flex',
    margin: '0 0 24px',
  },
  cartItem: {
    display: 'flex',
    fontWeight: '500',
    margin: '2px 0 4px',
    borderBottom: '1px solid rgb(221, 221, 221)',
    height: '32px',
  },
  newCartItem: {
    background: 'beige',
  },
  light: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 16px',
    fontSize: '0.875em',
    borderBottom: 'none',
  },
  itemStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.875em',
  },
  items: {
    productName: {
      minWidth: '30%',
      maxWidth: '30%',
      boxSizing: 'border-box',
      margin: '0 2px',
    },
    status: {
      minWidth: '10%',
      maxWidth: '15%',
      boxSizing: 'border-box',
      margin: '0 3px',
    },
    deliveredQuantity: {
      minWidth: '10%',
      maxWidth: '8%',
      boxSizing: 'border-box',
      margin: '0 3px',
    },
    unitPrice: {
      minWidth: '8%',
      maxWidth: '7%',
      boxSizing: 'border-box',
      margin: '0 2px',
    },
    quantity: {
      minWidth: '10%',
      maxWidth: '7%',
      boxSizing: 'border-box',
      margin: '0 2px',
    },
    timeUpdated: {
      minWidth: '12%',
      maxWidth: '12%',
      boxSizing: 'border-box',
      margin: '0 2px',
    },
    reason: {
      minWidth: '20%',
      maxWidth: '20%',
      boxSizing: 'border-box',
      margin: '0 3px',
    },
  },
  inputItems: {
    width: '100%',
  },
};
