import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import NavBar from '../../../components/NavBar';
import API, { getUnAssignedCustomersCSV } from '../../../api/NetworkHandler';
import AddressList from './AddressList';
import AddressSearch from './AddressSearch';
import Constants from '../../../utils/Constant';
import SearchBar from '../../Commons/containers/SearchBar';
import { withRouter } from 'react-router-dom';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';

class UnAssignedCustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        fromDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        toDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        status: '',
      },
      currentPage: 0,
    };
  }

  componentDidMount= () => {
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.onSearch(getFilterParams);
    }

  onSearch = (page = 0) => {
    const { updateData } = this.props;
    const { filters = {} } = this.state;
    this.setState({
      currentPage: page,
    });
    updateData(filters, page);
  };

  handleChange = (filters, refresh = false) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  handleClick = (type, params = null) => {
    switch (type) {
      case 'search':
        this.onSearch(params);
        break;
      case 'download':
        this.downloadCustomers();
        break;
      case 'create_new':
        break;
      default:
        break;
    }
  };

  downloadCustomers = () => {
    const { filters } = this.state;
    API.downloadFile(getUnAssignedCustomersCSV(filters), 'UnAssignedCustomers.csv');
  };

  render() {
    const { data, totalElements } = this.props;
    const { filters, currentPage } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.UNASSIGNED}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <AddressSearch
            title={Constants.UNASSIGNED}
            onSearch={this.onSearch}
            onChange={this.handleChange}
          />
        </SearchBar>
        <AddressList
          page={currentPage}
          addresses={data}
          reloadAddresses={this.onSearch}
          onPageChange={this.onSearch}
          totalElements={totalElements}
          rowsPerPage={50}
        />
      </div>
    );
  }
}

UnAssignedCustomerComponent.propTypes = {
  data: PropTypes.instanceOf(Array),
  updateData: PropTypes.func,
  totalElements: PropTypes.number,
};

export default UnAssignedCustomerComponent;
