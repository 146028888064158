import React from 'react';
import PurchaseIndentComponent from '../components/PurchaseIndentComponent';


class PurchaseIndentContainer extends React.Component {
  render() {
    return (
      <div>
        <PurchaseIndentComponent />
      </div>
    );
  }
}

export default PurchaseIndentContainer;
