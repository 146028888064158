import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import apiCall, { fetchVendorProductsByCatalogId } from '../../../api/NetworkHandler';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ProductForm from './ProductForm';
import Lozenge from '@atlaskit/lozenge';
import Modal from '@material-ui/core/Modal';
import SubProductsList from './SubProductsList';
import ProductItemForm from './ProductItemForm';
import CatalogProductSearch from './CatalogProductSearch';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';


const productsColumnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'brandName', numeric: false, disablePadding: true, label: 'Brand',
  },
  {
    id: 'categoryName', numeric: false, disablePadding: true, label: 'Category',
  },
  {
    id: 'tags', numeric: false, disablePadding: true, label: 'Tags',
  },
  {
    id: 'active', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'timeUpdated', numeric: false, disablePadding: true, label: 'Last Updated At',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = {
  modal: {
    background: 'white',
    width: '70%',
    marginLeft: '15%',
    height: '80%',
    marginTop: window.innerHeight * 0.09,
    marginBottom: window.innerHeight * 0.3,
    padding: '10px',
    overflow: 'scroll',
  }
}

var requestId = 0;

class CatalogProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      rowsPerPage: 10,
      order: 'asc',
      orderBy: 'name',
      showProductEdit: false,
      showSubProducts: false,
      currentProductId: 0,
      currentProductName: '',
      query: '',
      isNew: false,
      isNewProductForm: false,
    }
  }

  componentDidMount() {
    this.fetchVendorProducts();
  }

  fetchVendorProducts = debounce( async () => {
    const { match: { params }, toggleProgressBar } = this.props;
    const { page, rowsPerPage, orderBy, query } = this.state;
    try {
      requestId += 1;
      let prevRequestId = requestId;
      toggleProgressBar(true);
      const response = await apiCall.sendRequest('get', fetchVendorProductsByCatalogId(params.id, query, rowsPerPage, page));
      const { data: { _embedded, page: { totalElements } } } = response;
      let products = _embedded['vendor-products'];
      products = sortBy(products, orderBy);
      if (requestId == prevRequestId) {
        this.setState({
          data: products,
          count: totalElements,
        });
      }
      toggleProgressBar(false);
    } catch (error) {
      toggleProgressBar(false);
    }
  }, 750);

  // searchVendorProducts = debounce(async () => {
  //   const { toggleProgressBar } = this.props;
  //   const { query, orderBy } = this.state;
  //   if (query) {
  //     try {
  //       toggleProgressBar(true);
  //       const response = await apiCall.sendRequest('get', `api/v1/vendor-products/search/findVendorProducts/?query=${query}`);
  //       const { data: { _embedded } } = response;
  //       let products = _embedded['vendor-products'];
  //       products = sortBy(products, orderBy)
  //       this.setState({
  //         data: products,
  //         count: products.length,
  //       });
  //       toggleProgressBar(false);
  //     } catch (error) {
  //       toggleProgressBar(false);
  //     }
  //   } else {
  //     this.fetchVendorProducts();
  //   }
  // }, 400)

  handleChangeRowsPerPage = (event) => {
    const perPage = event.target.value;

    this.setState({
      rowsPerPage: perPage
    }, () => { this.fetchVendorProducts(); });
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    }, () => { this.fetchVendorProducts(); });
  }

  onRequestSort = (property) => {
    const { data, order: dataOrder, orderBy: dataOrderBy } = this.state;
    const orderBy = property;
    let order = 'desc';

    if (dataOrderBy === property && dataOrder === 'desc') {
      order = 'asc';
    }

    let sortedData = order === 'desc' ?
      data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)) :
      data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))


    this.setState({
      data: sortedData,
      order,
      orderBy,
    })
  }

  actions = (product) => {
    return (
      <Typography color="secondary" style={{ fontSize: '0.9em' }}>
        <span onClick={() => { this.handleProductEdit(product) }} style={{ cursor: 'pointer' }}>EDIT&emsp;</span>
        |
        <span onClick={() => { this.showSubProductForm(product) }} style={{ cursor: 'pointer' }}>&emsp;ADD ITEM&emsp;</span>
        |
        <span onClick={() => { this.showSubProducts(product) }} style={{ cursor: 'pointer' }}>&emsp;VIEW/EDIT ITEM</span>
      </Typography>
    )
  }

  handleProductEdit = (product) => {
    this.setState({
      showProductEdit: true,
      editingProduct: product,
      isNewProductForm: false,
    })
  }

  handleClear = () => {
    this.setState({
      editingProduct: {},
    });
  }

  handleFormClose = () => {
    this.setState({
      showProductEdit: false,
      showSubProductForm: false,
      currentProductId: 0,
      currentProductName: '',
    })
  }

  showSubProducts = (product) => {
    this.setState({
      showSubProducts: true,
      currentProductId: product.id,
      currentProductName: product.name,
    })
  }

  showSubProductForm = (product) => {
    this.setState({
      showSubProductForm: true,
      currentProductId: product.id,
      currentProductName: product.name,
      isNew: true,
    })
  }

  customComponents = () => {
    const { data } = this.state;
    //name of this object must match with property of object in data array
    const active = {};
    data.forEach((dataObject, index) => {
      active[index] = <Lozenge appearance={dataObject.active ? 'success' : 'removed'}> {dataObject.active ? 'Active' : 'Inactive'} </Lozenge>
    })
    return {
      active
    };
  }

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      query: value
    }, () => { this.fetchVendorProducts() });
  }

  addNewProduct = () => {
    this.setState({
      showProductEdit: true,
      editingProduct: {},
      isNewProductForm: true,
    })
  }

  closeSubProductsModal = () => {
    this.setState({ showSubProducts: false });
  }

  render() {
    const {
      data,
      page,
      rowsPerPage,
      count,
      order,
      orderBy,
      editingProduct,
      showProductEdit,
      showSubProducts,
      currentProductId,
      currentProductName,
      showSubProductForm,
      query,
      isNew,
      isNewProductForm,
    } = this.state;

    const { taxType } = this.props;

    return (
      <React.Fragment>
        <CatalogProductSearch
          query={query}
          onChange={this.handleSearchChange}
          addNewProduct={this.addNewProduct}
        />
        <PaginatedTable
          columnData={productsColumnData}
          data={data}
          count={count}
          actions={this.actions}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleChangePage={this.handleChangePage}
          onRequestSort={this.onRequestSort}
          order={order}
          orderBy={orderBy}
          customComponents={this.customComponents()}
        />
        <Drawer anchor="right" open={showProductEdit || showSubProductForm} onClose={() => { this.handleFormClose() }}>
          {showProductEdit &&
            <ProductForm
              product={editingProduct}
              onRefresh={this.fetchVendorProducts}
              onClear={this.handleClear}
              onClose={() => this.handleFormClose()}
              isNewProductForm={isNewProductForm}
              taxType={taxType}
              {...this.props}
            />}
          {showSubProductForm &&
            <ProductItemForm
              onClear={this.handleClear}
              onClose={() => this.handleFormClose()}
              vendorProductId={currentProductId}
              vendorProductName={currentProductName}
              isNew={isNew}
            />
          }
        </Drawer>
        <Modal
          open={showSubProducts}
          onClose={() => { this.closeSubProductsModal() }}
        >
          <div style={styles.modal}>
            <SubProductsList
              productId={currentProductId}
              onClose={this.closeSubProductsModal}
              vendorProductName={currentProductName}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  taxType: state.taxType
});

export default connect(mapStateToProps, null)(CatalogProductList);
