import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import apiCall from "../api/NetworkHandler";
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../constants';

// Move to api
import data from './data';
import vendorData from './vendorData';
import StockInventoryQuantity from './StockInventoryQuantity';

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

const columnData = [
  { id: 'productItemName', numeric: false, disablePadding: true, label: 'Product Name' },
  { id: 'unitMeasure', numeric: false, disablePadding: true, label: 'Unit Measure' },
  { id: 'packaging', numeric: false, disablePadding: true, label: 'Packaging' },
  { id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity' },
  { id: 'actions', numeric: false, disablePadding: true, label: 'Action' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'products',
      selected: [],
      data: [],
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.data !== this.state.data) {
        this.setState({
          data: nextProps.data
        });
      }
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    this.setState({ selected: data.id });
  };

  isSelected = id => this.state.selected === id;

  getDisplayUom = (productItem) => {
    const { uom, unitMeasure } = productItem;
    if(uom){
      return `${ unitMeasure } ${ uom.code}`
    } else {
      return '-';
    }
  }

  render() {
    const { classes, totalElements, handleChangePage, rowsPerPage, page, handleChangeRowsPerPage, onUpdateStock, refreshItems, warehouseId  } = this.props;
    const { data, order, orderBy, selected,  rowsPerPageOptions } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead orderBy={orderBy} order={order}/>
            <TableBody>
              {data.map(n => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">{n.productItemName}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{this.getDisplayUom(n)}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.packaging}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{refreshItems[n.productItemId] ? <StockInventoryQuantity productItemId={n.productItemId} warehouseId={warehouseId} /> : "-"}</TableCell>
                    <TableCell component="th" scope="row" padding="none"><Button color="secondary" onClick={()=>{onUpdateStock(n)}}>Update Stock</Button></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  totalElements: PropTypes.number,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(EnhancedTable));