import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { getAllUsers } from '../../api/NetworkHandler';

const onLoad = async (searchTerm) => {
  const response = await apiCall.sendRequest('get', getAllUsers(searchTerm, ''));
  const { data: { _embedded } } = response;
  const { users } = _embedded;
  
  return users.map(user => {
    return {
      ...user,
      label: user.name,
      value: user.id,
    }
  })
}

function UserSelector(props) {
  const { selected, onSelect } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isMulti
    />
  )
}

UserSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.instanceOf(Array),
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func,
};

UserSelector.defaultProps = {
  selected: null
};

export default UserSelector;
