import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import apiCall, { createTotMargin, updateTotMarginById } from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import { debounce, isEmpty } from 'lodash';
import ProgressBar from '../../../components/States/ProgressBar';
import VendorProductItemSelector from '../../../utils/VendorProductItemSelector';


const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
};

class CreateTOTMargin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postTotMarginData: {
        name: '',
        vendorProductItemId: null,
        marginType: 'REGULAR',
        marginPercentage: null,
        warehouseId: null,
      },
      errorMessage: '',
      isLoading: false,
      invalidItem: false,
      invalidMarginPercentage: false,
      invalidMarginType: false,
      disableButton: false,
      selectedItem: null,
    }
  }

  componentDidMount() {
    const { selectedTotMargin } = this.props;
    if (!isEmpty(selectedTotMargin)) {
      this.setState({
        postTotMarginData: selectedTotMargin,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { postTotMarginData } = this.state;
    
    this.setState({
      postTotMarginData: {
          ...postTotMarginData,
          [name]: value,
      }
    });
  };

  validateForm = () => {
    let isFormValid = true;
    const { postTotMarginData, selectedItem } = this.state;
    const { id, marginPercentage, marginType } = postTotMarginData;

    if (!id && isEmpty(selectedItem)) {
      isFormValid = false;
      this.setState({ invalidItem: true });
    } else {
      this.setState({ invalidItem: false });
    }

    if (marginType !== 'COMMODITY' && (!marginPercentage || marginPercentage < 0 || marginPercentage > 99)) {
      isFormValid = false;
      this.setState({ invalidMarginPercentage: true });
    } else {
      this.setState({ invalidMarginPercentage: false });
    }

    if(!marginType) {
      isFormValid = false;
      this.setState({ invalidMarginType: true });
    } else {
      this.setState({ invalidMarginType: false });
    }

    return isFormValid;
  }

  toggleProgressBar = (loadingState, buttonState) => {
    this.setState({
      isLoading: loadingState,
      disableButton: buttonState
    });
  };

  onSave = debounce( async () => {
    if (!this.validateForm()) {
      return;
    }

    this.toggleProgressBar(true, true);
    const { postTotMarginData, selectedItem } = this.state;
    const { id = null } = postTotMarginData;
    const { onClose, onRefresh, selectedWarehouse } = this.props;
    if (!id) {
      postTotMarginData.vendorProductItemId = selectedItem.value;
      postTotMarginData.name = selectedItem.label;
    }
    postTotMarginData.warehouseId = selectedWarehouse.value;

    try {
      if (id) {
        await apiCall.sendRequest("put", updateTotMarginById(id), postTotMarginData);
      } else {
        await apiCall.sendRequest("post", createTotMargin, postTotMarginData);
      }
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
      onClose();
      onRefresh();
    }
    this.toggleProgressBar(false, false)
  }, 500);

  handleSelectItem = (selectedItem) => {
    this.setState({
      selectedItem: isEmpty(selectedItem) ? null : selectedItem,
    });
  };

  render() {
    const { classes, onClose, marginTypes, selectedWarehouse } = this.props;
    const { 
      postTotMarginData,
      errorMessage,
      isLoading,
      invalidItem,
      invalidMarginPercentage,
      invalidMarginType,
      disableButton,
      selectedItem,
    } = this.state;
    const { id, name, vendorProductItemId, marginType, marginPercentage } = postTotMarginData;

    return (
      <Grid className={classes.container} fluid>
        <ProgressBar isLoading={isLoading} />
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{postTotMarginData.id ? "Edit TOT Margin" : "Add New TOT Margin"}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary" variant="outlined" >Cancel</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" onClick={this.onSave} disabled={disableButton}>{id ? "Save" : "Add"}</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod>
            <Typography variant="caption" className={classes.error}> * required fields </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            <SectionMessage appearance="error">{errorMessage}</SectionMessage>
          </ColMod>
        </Row>}
        <Row md={12}>
          {!id && <ColMod md={12}>
            <Typography variant="caption" color={invalidItem ? "error" : "default"}> Vendor Product Item <sup>*</sup> </Typography>
            <VendorProductItemSelector 
              selected={selectedItem}
              onSelect={this.handleSelectItem}
              warehouseId={selectedWarehouse.value}
            />
          </ColMod>}
          {id &&<ColMod md={12}>
            <TextField
              type="text"
              label="Vendor Product Item ID"
              value={vendorProductItemId || ''}
              fullWidth
              disabled
            />
          </ColMod>}
          {id && <ColMod md={12}>
            <TextField
              type="text"
              label="Vendor Product Item Name"
              value={name || ''}
              fullWidth
              disabled
            />
          </ColMod>}
          <ColMod md={12}>
            <TextField
              type="number"
              label="Margin Percentage"
              name="marginPercentage"
              value={marginPercentage || ''}
              onChange={this.handleChange}
              required
              fullWidth
              autoComplete="off"
              error={marginType !== 'COMMODITY' && (invalidMarginPercentage || marginPercentage < 0)}
              helperText={marginType !== 'COMMODITY' && (marginPercentage < 0 && "Enter value more than 0" || marginPercentage > 99 && "Max allowed 99")}
            />
          </ColMod>
          <ColMod md={12}>
            <Typography variant="caption"> Margin Type <sup>*</sup> </Typography>
            <Select
              value={marginType}
              fullWidth
              name="marginType"
              onChange={this.handleChange}
              error={invalidMarginType}
            >
              {Object.keys(marginTypes).map((type, index) => (<MenuItem key={index} value={type}>{marginTypes[type]}</MenuItem>)) }
            </Select>
          </ColMod>
        </Row>
        
      </Grid>
    )
  }
}

export default withStyles(styles)(CreateTOTMargin);
