import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import apiCall from '../../../api/NetworkHandler';


class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const image = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(image),
    });
    const formData = new FormData();
    formData.append('images', image);
    apiCall.sendRequest('post', '/api/v1/images', formData)
      .then((response) => {
        this.props.handleImageUpload(Object.values(response.data)[0], this.props.side);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  handleAttrChange=(event, i, field) => {
    const tem = this.state.new_product_attrs;
    if (field === 'value') {
      tem[i].value = event.target.value;
    } else {
      tem[i].name = event.target.value;
    }
    this.setState({ new_product_attrs: tem });
  }

  render() {
    const { url, clearImage, side } = this.props;
    const { file } = this.state;
    if (file || url) {
      return (
        <div style={{
                height: '5em',
                width: '5em',
                display: 'inline-block',
                backgroundColor: '#EEEEEE',
                textAlign: 'center',
                position: 'relative',
                margin: '5px',
            }}
        >
          <img style={{ height: '5em', width: 'auto' }} alt="" src={url || file} />
          <CloseIcon
            color="secondary"
            style={{
                fontSize: '1.6em',
                position: 'absolute',
                top: '5px',
                right: '5px',
                cursor: 'pointer',
            }}
            onClick={() => { clearImage(side); this.setState({ file: null }); }}
          />
        </div>
      );
    }
    let textDisplay = side;
    if (textDisplay === 'RIGHT_SIDE') {
      textDisplay = 'RIGHT';
    } else if (textDisplay === 'LEFT_SIDE') {
      textDisplay = 'LEFT';
    }
    return (
      <div style={{
            height: '5em',
            width: '5em',
            display: 'inline-block',
            backgroundColor: '#EEEEEE',
            textAlign: 'center',
            position: 'relative',
            margin: '5px',
        }}
      >
        <label style={{ lineHeight: '5em' }}>{textDisplay}
          <input accept="image/*" type="file" style={{ visibility: 'hidden' }} onChange={this.handleChange} />
        </label>
      </div>
    );
  }
}

export default Upload;
