import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card, CardContent, Button, Typography } from '@material-ui/core';
import ItemText from '../ItemText';
import styles from './styles';
import apiCall, { customerInvoiceDownloadPath } from '../../../api/NetworkHandler';

const MAIN_INFO = [
  {
    text: 'Invoice Id',
    param: 'invoiceId',
  },
  {
    text: 'Total',
    param: 'grandtotal',
  },
  {
    text: 'Month',
    param: 'monthWithYear',
  },
];

class InvoiceList extends Component {
  handleDownload = async (item) => {
    const { customerId } = this.props;
    const url = customerInvoiceDownloadPath(customerId, item.invoiceId);
    apiCall.downloadFile(url, `Monthly_Invoice_${item.invoiceId}.pdf`);
  };


  renderMainInfo = (item) => {
    const { currency } = this.props;
    if (!isEmpty(item)) {
      return (
        <React.Fragment>
          {
            MAIN_INFO.map((el) => {
              if (item[el.param] && el.param === 'grandtotal') {
                return <ItemText name={el.text} value={`${currency} ${(item[el.param]).toFixed(2)}`} style={styles.mainInfoItem} />;
              } else if (item[el.param]) {
                return <ItemText name={el.text} value={item[el.param]} style={styles.mainInfoItem} />;
              }
              return null;
            })
          }
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    const {
      invoices,
      hasMoreInvoices,
      showMoreInvoices,
    } = this.props;

    if (!isEmpty(invoices)) {
      return (
        <div>
          <div>
            <Typography color="textSecondary" style={styles.title}>
              INVOICE LIST
            </Typography>
            <div>
              {invoices.map(el => (
                <Card style={styles.card}>
                  <CardContent style={styles.content}>
                    <div style={styles.mainInfo}>
                      {this.renderMainInfo(el)}
                    </div>
                    <Button
                      onClick={() => this.handleDownload(el)}
                      style={styles.btn}
                      color="primary"
                      variant="outlined"
                      size="small"
                    >
                      Download
                </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
            {hasMoreInvoices && (
              <div style={styles.load}>
                <Button
                  onClick={showMoreInvoices}
                  style={styles.btn}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  SHOW MORE
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return 'LOADING INVOICES...';
  }
}

InvoiceList.propTypes = {
  invoices: PropTypes.instanceOf(Array),
  customerId: PropTypes.string,
  currency: PropTypes.string,
  hasMoreInvoices: PropTypes.bool,
  showMoreInvoices: PropTypes.func,
};

export default InvoiceList;
