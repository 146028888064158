import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import API, { getNewCustomerCSV } from '../../../api/NetworkHandler';
import AddressList from './AddressList';
import AddressSearch from './AddressSearch';
import Constants from '../../../utils/Constant';
import SearchBar from '../../Commons/containers/SearchBar';
import { withRouter } from 'react-router-dom';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';

class NewCustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        fromDate: moment().subtract(20, 'days').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        customerStatus: '',
      },
      currentPage: 0,
    };
  }

  componentDidMount= () => {
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.onSearch(getFilterParams);
    }

  onSearch = (page = 0) => {
    const { updateData } = this.props;
    const { filters = {} } = this.state;
    this.setState({
      currentPage: page,
    });
    if (!Object.keys(filters).length) {
      return;
    } else {
      updateData(filters, page);
    }
  };

  handleChange = (filters, refresh = 0) => {
    const { currentPage } = this.state;
    this.setState(
      {
        filters,
      },
      () => {
        if (refresh) {
          this.onSearch(currentPage);
        }
      },
    );
  };

  handleClick = (type, params = null) => {
    switch (type) {
      case 'search':
        this.onSearch(params);
        break;
      case 'download':
        this.downloadNewCustomers();
        break;
      case 'create_new':
        break;
      default:
        break;
    }
  };

  downloadNewCustomers = () => {
    const { filters } = this.state;
    API.downloadFile(getNewCustomerCSV(filters), 'New_Customers.csv');
  };

  render() {
    const { data, totalElements, dateErrorText } = this.props;
    const { filters, currentPage } = this.state;
    const isDateEmpty = Object.keys(filters).length == 0 ? true : false;
    return (
      <div>
        <NavBar />
        <SearchBar
          title={Constants.NEW_CUSTOMER}
          filters={filters}
          onChange={this.handleChange}
          onButtonClick={this.handleClick}
        >
          <AddressSearch
            title={Constants.NEW_CUSTOMER}
            onSearch={this.onSearch}
            onChange={this.handleChange}
            dateErrorText={dateErrorText}
          />
        </SearchBar>
        {isDateEmpty ? 
          <div style={{ textAlign: 'center', marginTop: '100px', color: 'red' }}>
            <p>Please enter delivery dates to see the results..</p>
          </div> :
        <AddressList
          page={currentPage}
          addresses={data}
          reloadAddresses={this.onSearch}
          onPageChange={this.onSearch}
          totalElements={totalElements}
          rowsPerPage={50}
        />}
      </div>
    );
  }
}

NewCustomerComponent.propTypes = {
  data: PropTypes.instanceOf(Array),
  updateData: PropTypes.func,
  totalElements: PropTypes.number,
};

export default withRouter(NewCustomerComponent);
