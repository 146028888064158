import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography,
    Button,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import apiCall, { createAppWidgets } from '../../../api/NetworkHandler';
import debounce from 'lodash/debounce';
import ProgressBar from '../../../components/States/ProgressBar';

const styles = {
    container: {
        width: window.innerWidth * 0.35,
    },
    arrow: {
        cursor: "pointer",
    },
    error: {
        color: "red",
    }
}

class AppWidgetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postWidget: {
                title: '',
                order: null,
                active: true,
                widgetType: 'BANNER',
                widgetDataType: 'BANNER',
                subTitle: '',
                widgetFilterType: null,
            },
            isLoading: false,
            selectedWidget: 'BANNER',
        }
    }

    handleWidgetTypeChange = (event) => {
        const { name, value } = event.target;
        const { postWidget } = this.state;
        this.setState({
            selectedWidget: value,
            postWidget: {
                ...postWidget,
                widgetType: value === "PRODUCT" ? "HORIZONTAL_VIEW" : value,
                widgetDataType: value,
                widgetFilterType: value === "PRODUCT" ? "PRODUCT" : null,
            }
        });
    };

    onChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            const { postWidget} = prevState;

            return {
                postWidget: {
                    ...postWidget,
                    [name]: value,
                }
            }
        })
    }

    onSave = debounce( async () => {
        const { postWidget } = this.state;
        if (!postWidget.title) return;
        const { onClose, onRefresh, totalElements } = this.props;
        postWidget.order = totalElements + 1;
        this.setState({ isLoading: true });
        try {
            await apiCall.sendRequest('post', createAppWidgets, postWidget);
            onClose();
            onRefresh();
        } catch (err) {
            console.log(err.response);
        }
    }, 300);

    render() {
        const { classes, onClose } = this.props;
        const { 
            postWidget,
            isLoading,
            selectedWidget,
        } = this.state;
        const { active, title, subTitle } = postWidget;
        return (
            <Grid className={classes.container} fluid>
                <ProgressBar isLoading={isLoading} />
                <Row md={12} between="md">
                    <ColMod>
                        <Row>
                            <Col>
                                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
                            </Col>
                            &nbsp;&nbsp;
                            <Col>
                                <Typography variant="title"> New App Widget </Typography>
                            </Col>
                        </Row>
                    </ColMod>
                    <ColMod>
                        <Row md={12}>
                            <Col md={12}>
                                <Button size="small" onClick={onClose} color="secondary" variant="outlined" > Cancel </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button size="small" type="submit" variant="contained" color="primary" onClick={this.onSave}> Save </Button>
                            </Col>
                        </Row>
                    </ColMod>
                </Row>
                <Row>
                    <ColMod>
                        <Typography variant="caption" className={classes.error}> * required fields </Typography>
                    </ColMod>
                </Row>
                <Row md={12}>
                    <ColMod md={12}>
                        <RadioGroup onChange={this.handleWidgetTypeChange} name="selectedWidget" value={selectedWidget} row>
                            <FormControlLabel
                                value="BANNER"
                                control={<Radio />}
                                label="Banner Widget"
                            />
                            <FormControlLabel
                                value="PRODUCT"
                                control={<Radio />}
                                label="Product Widget"
                            />
                        </RadioGroup>
                    </ColMod>
                </Row>
                <Row md={12}>
                    <ColMod md={12}>
                        <TextField
                            type="text"
                            label="Widget Title"
                            name="title"
                            value={title}
                            onChange={this.onChange}
                            error={!!(!title)}
                            helperText={!title && 'required'}
                            required
                            fullWidth
                            autoComplete="off"
                        />
                    </ColMod>
                    <ColMod md={12}>
                        <TextField
                            type="text"
                            label="Description"
                            name="subTitle"
                            value={subTitle}
                            onChange={this.onChange}
                            fullWidth
                            autoComplete="off"
                        />
                    </ColMod>
                    {selectedWidget === "PRODUCT" && <ColMod md={12}>
                        <Typography variant="caption"> Product Widget Status </Typography>
                        <RadioGroup onChange={this.onChange} name="active" value={(active + '') || 'true'} row>
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Home Screen"
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Hidden"
                            />
                        </RadioGroup>
                    </ColMod>}
                    <ColMod md={12}>
                        {active === "false" && <span style={{ color: 'red' }}>After creating this widget you will not be able to see in the app home screen</span>}
                    </ColMod>
                </Row>
            </Grid>
        )
    }
}

export default withStyles(styles)(AppWidgetForm);
