import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';

import { ColMod } from '../../../components/lib';

import { Grid, Row, Col } from 'react-flexbox-grid';
import { MenuItem } from 'material-ui';

const styles = {
  searchContainer: {
    background: '#f0f0f0',
    height: '100px',
    width: '100%',
  },
};

class SegregatorSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: 'All',
      query: "",
    };
  }

  changeRole = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    this.setState({ selectedRole: value });
    this.props.handleRoleChange(value);
  }

  changeSearchText = (event) => {
    this.setState({ query: event.target.value })
  }

  onSearch = (query) => {
    if (query) {
      this.props.onSearch(query);
    }
  }

  onClearSearch = () => {
    this.setState({ selectedRole: 'All' });
    this.props.onClearSearch();
  }

  render() {
    const { query, handleQueryChange, segregatorRoles } = this.props;
    const { selectedRole } = this.state;
    return (
      <Grid fluid style={styles.searchContainer}>
        <Row md={12} middle="md">
          <Col md={2}>
            <Typography variant="title" color="inherit">
              Segregator
            </Typography>
          </Col>
          <Col md={8}>
            <Row md={12} middle="md">
              <Col md={12}>
                <Row md={12} middle="md">
                  <ColMod md={5}>
                    <TextField
                      id="input-with-icon-textfield"
                      label="Search Segregators"
                      fullWidth
                      name="searchText"
                      value={query || ''}
                      onChange={handleQueryChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="new-search"
                      helperText='(By Name, Email, Mobile)'
                    />
                  </ColMod>
                  <ColMod md={3}>
                    <Typography variant="caption" >Roles</Typography>
                    <Select
                      name='role'
                      value={selectedRole}
                      onChange={this.changeRole}
                      fullWidth
                    >
                      <MenuItem value='All' key={'All'}>{'All'}</MenuItem>
                      {Object.keys(segregatorRoles).map(key => (
                        <MenuItem value={key} key={key}>{segregatorRoles[key]}</MenuItem>
                      ))}
                    </Select>

                  </ColMod>
                  <ColMod md={2}>
                    <Button color="inherit" variant="outlined"
                      onClick={this.props.onSearch}
                    >
                      Search
                    </Button>
                  </ColMod>
                  <ColMod md={2}>
                    <Button color="secondary" variant="outlined"
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </Button>
                  </ColMod>


                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={2}>
            <Button color="primary" variant="outlined" onClick={() => { this.props.openAddSegregator(null); }}
            >Add Segregator</Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default SegregatorSearch;