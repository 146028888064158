import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TextField,
  Grid,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  Typography
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import apiCall, { getVendors } from '../../../api/NetworkHandler';
import { Modal, Select as AntdSelect } from 'antd';

const { Option } = AntdSelect;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15,
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  rightContainer: {
    padding: 18,
  },
  rightAlign: {
    textAlign: 'right',
  },
  label: {
    marginTop: 4,
  },
};

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    const { filters = {} } = this.props;
    this.state = {
      filters,
      vendors: [],
      openRateSelection: false,
      selectedRateType: '',
      selectedWarehouse: null,
    };
  }

  componentDidMount() {
    this.fetchVendors();
  }

  onChangeFilter = (event) => {
    const { name, value } = event.target;
    const { onFilterChange } = this.props;
    const isNotDateField = (name !== 'startDate') && (name !== 'endDate');
    if (isNotDateField || this.isValidDateRange(value, name)) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }));
      onFilterChange(name, value);
    }
  }

  onKeyPress = (event) => {
    const { onChangeFilter } = this.props;
    if (event.key === 'Enter') {
      onChangeFilter(this.state.filters);
    }
  }

  onSearch = () => {
    const { onButtonClick } = this.props;
    onButtonClick('search', 0);
  }

  fetchVendors = async () => {
    try {
      const res = await apiCall.sendRequest('get', getVendors);
      const { data = {} } = res;
      const { _embedded = {} } = data;
      this.setState({
        vendors: _embedded.vendors,
      });
    } catch (e) {
      // error handling
    }
  }

  isValidDateRange = (value, key) => {
    const { filters = {} } = this.state;
    const { startDate: fromDate, endDate: toDate } = filters;
    const isEmpty = value === '';
    const endDateValidation = (key === 'endDate' && (fromDate === '' || moment(value).isSameOrAfter(moment(fromDate))));
    const fromDateValidation = (key === 'startDate' && (toDate === '' || moment(value).isSameOrBefore(moment(toDate))));
    return isEmpty || (endDateValidation || fromDateValidation);
  }

  clearAll = () => {
    const { onButtonClick } = this.props;
    this.setState({
      filters: {
        query: '',
        id: '',
        startDate: '',
        endDate: '',
        status: '',
      },
    }, () => {
      onButtonClick('clear', {});
    });
  }

  handleOpenRateSelection = () => {
    this.setState({
      openRateSelection: true,
    });
  };

  handleCloseRateSelection = () => {
    this.setState({
      openRateSelection: false,
    });
  };

  handleChangeRate = (rateType) => {
    this.setState({
      selectedRateType: rateType,
    });
  };

  handleCreatePO = () => {
    const { history, warehouses, taxType } = this.props;
    const { selectedRateType, selectedWarehouse } = this.state;
    history.push({
      pathname: '/new-po',
      state: { rateType: taxType === 'gst' ? selectedRateType : "TAX_COMBINED", warehouse: warehouses.find(el => el.id == selectedWarehouse) },
    });
  };

  handleChangeWarehouse = (selectedWarehouse) => {
    this.setState({
      selectedWarehouse,
    });
  };

  renderWarehouseAndTaxTypeModal = (newTaxRateTypes) => {
    const { openRateSelection, selectedRateType, selectedWarehouse } = this.state;
    const { warehouses = [], taxType } = this.props;
    const disableButton = taxType === 'gst' ? !(selectedWarehouse && selectedRateType) : !selectedWarehouse;
    return (
      <Modal
        title="Create PO"
        centered
        visible={openRateSelection}
        onOk={this.handleCreatePO}
        onCancel={this.handleCloseRateSelection}
        okText="Create"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: disableButton }}
      >
        <div style={{ marginBottom: "10px" }}>
          <Typography variant="caption"> Warehouse <sup>*</sup> </Typography>
          <AntdSelect value={selectedWarehouse} style={{ width: 350 }} onChange={this.handleChangeWarehouse}>
            {warehouses.map(el => <Option value={el.id}>{el.name}</Option>)}
          </AntdSelect>
        </div>
        {taxType === 'gst' &&
          <div style={{ marginBottom: "10px" }}>
            <Typography variant="caption"> Tax Rate Type <sup>*</sup> </Typography>
            <AntdSelect value={selectedRateType} style={{ width: 200 }} onChange={this.handleChangeRate}>
              {Object.keys(newTaxRateTypes).map(el => <Option value={el}>{newTaxRateTypes[el]}</Option>)}
            </AntdSelect>
          </div>}
      </Modal>
    );
  }

  render() {
    const { POStatuses = {}, warehouses = [], taxRateTypes, taxType } = this.props;
    const { vendors = [], filters = {} } = this.state;
    const {
      query = '',
      id,
      startDate,
      endDate,
      status,
      vendorName,
      warehouseId = '',
      editable = true,
      taxRateType,
    } = filters;
    const disabled = !editable || editable === 'false';
    const newTaxRateTypes = typeof taxRateTypes === 'undefined' ? {} : taxRateTypes;
    const modifiedTaxRateTypes = { ...newTaxRateTypes, TAX_COMBINED: 'Tax Combined' };
    return (
      <div style={styles.container}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6} md={3}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Quick Search"
                  fullWidth
                  name="query"
                  onChange={this.onChangeFilter}
                  value={query}
                  onKeyPress={this.onKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="id"
                  type="text"
                  label="PO Number"
                  name="id"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={id || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  type="date"
                  label="Start Date"
                  name="startDate"
                  fullWidth
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={startDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="date"
                  type="date"
                  name="endDate"
                  label="End Date"
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={endDate || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Status</FormHelperText>
                <Select
                  name="status"
                  label="Status"
                  fullWidth
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={status || ''}
                >
                  {Object.keys(POStatuses).map((postatus, index) => (
                    <MenuItem value={postatus}>{POStatuses[postatus]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Vendor</FormHelperText>
                <Select
                  name="vendorName"
                  label="Vendor"
                  fullWidth
                  onChange={this.onChangeFilter}
                  value={vendorName || ''}
                >
                  {vendors.map(vendor => (
                    <MenuItem value={vendor.name}>{vendor.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Warehouse</FormHelperText>
                <Select
                  name="warehouseId"
                  label="Warehouse"
                  fullWidth
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={(+warehouseId) || ''}
                >
                  {warehouses.map(warehouse => (
                    <MenuItem value={warehouse.id}>{warehouse.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              {taxType === 'gst' && <Grid item xs={6} md={3}>
                <FormHelperText style={styles.label}>Tax Rate Type</FormHelperText>
                <Select
                  name="taxRateType"
                  label="Tax Rate Type"
                  fullWidth
                  disabled={disabled}
                  onChange={this.onChangeFilter}
                  value={(taxRateType) || ''}
                >
                  <MenuItem value="All">All</MenuItem>
                  {Object.keys(modifiedTaxRateTypes).map(el => (<MenuItem value={el}>{modifiedTaxRateTypes[el]}</MenuItem>))}
                </Select>
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={styles.rightContainer}>
            <Grid container spacing={32}>
              <Grid item xs={4} md={4}>
                <Button color="inherit" variant="contained" onClick={this.onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item xs={8} md={8}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handleOpenRateSelection}
                >
                  Create Purchase Order
                </Button>
              </Grid>
              <Grid item xs={4} md={4}>
                <Button color="secondary" variant="outlined" onClick={this.clearAll}>Clear All</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.renderWarehouseAndTaxTypeModal(newTaxRateTypes)}
      </div>
    );
  }
}

SearchBar.propTypes = {
  POStatuses: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  onChangeFilter: PropTypes.func,
  warehouses: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  onButtonClick: PropTypes.func,
};

const mapStateToProps = state => ({
  taxRateTypes: state.Status.taxRateTypes,
  User: state.User,
});

export default connect(mapStateToProps, null)(withRouter(SearchBar));