import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tabs,
  Tab,
  Typography,
  Tooltip
} from '@material-ui/core';
import moment from 'moment';
import NavBar from '../../../components/NavBar';
import apiCall, { purchaseOrderById, getMaxExtraAllowed, printGRN, fetchAllGrnByPoId, updateStockByGrnId, purchaseOrderByIdPath } from '../../../api/NetworkHandler';
import TableHeader from '../../Commons/components/TableHeader';
import SearchBar from '../../Commons/containers/SearchBar';
import Colors from '../../../utils/Colors';
import SectionMessage from '@atlaskit/section-message';
import { reactLocalStorage } from 'reactjs-localstorage';
import debounce from 'lodash/debounce';
import PrintIcon from '@material-ui/icons/Print';
import utils from '../../../utils/queryBuilder';
import { Modal, Checkbox, Input, Popconfirm } from 'antd';


const canView = utils.isCurrentUserAuthorizedToView;


const columnData = [
  {
    id: 'grn', numeric: false, disablePadding: true, label: 'GRN #',
  },
  {
    id: 'v_name', numeric: false, disablePadding: true, label: 'Vendor Name',
  },
  {
    id: 'po_n', numeric: false, disablePadding: true, label: 'PO Number',
  },
  {
    id: 'warehouse', numeric: false, disablePadding: true, label: 'Warehouse',
  },
  {
    id: 'CreatedOn', numeric: false, disablePadding: true, label: 'Created On',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

const styles = {
  link: {
    textDecoration: 'none',
  },
};

var versionDict = {
  "grnRequestId": 0,
  "stockUpdateRequestId": 0,
  "getPORequestId": 0
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      poId: '',
      page: 0,
      rowsPerPage: 100,
      data: [],
      errorMessage: '',
      disableAddToStock: false,
      maxExtraAllowed: null,
      totalQuantity: null,
      purchaseOrderLineItems: [],
      openInvoiceNumberInputModal: false,
      invoiceNumber: null,
      hasInvoiceNumber: false,
      poData: {},
    };
  }

  componentWillMount() {
    const { match: { params } } = this.props;
    this.setState({ poId: params.id });
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.fetchAllGrns();
    }, 20000);
    this.fetchAllGrns();
    let currLocation = this.props.history.location.pathname;
    reactLocalStorage.set('curLocation', currLocation);
    this.getMaxExtraAllowed();
    this.getTotalPurchaseOrderQuantityById(this.state.poId);
    this.fetchPurchaseOrderByPoId(this.state.poId);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchPurchaseOrderByPoId = async (poId) => {
    try {
      versionDict.getPORequestId += 1;
      let prevRequestId = versionDict.getPORequestId;
      const response = await apiCall.sendRequest('get', purchaseOrderByIdPath(poId));
      const { data = {} } = response;
      if (prevRequestId == versionDict.getPORequestId) {
        this.setState({
          poData: data,
        });
      }
    } catch (err) {
      this.setState({
        poData: {}
      });
    }
  }

  fetchAllGrns = async () => {
    const { match: { params: { id: poId } = {} } = {} } = this.props;
    try {
      versionDict.grnRequestId += 1;
      let prevRequestId = versionDict.grnRequestId;
      const response = await apiCall.sendRequest('get', fetchAllGrnByPoId(poId));
      const { data: { _embedded: { grn = [] } = {} } = {} } = response;
      if (prevRequestId == versionDict.grnRequestId) {
        this.setState({
          data: grn
        });
      }
    } catch (err) {
      this.setState({
        data: []
      });
    }
  };

  getMaxExtraAllowed = async () => {
    try {
      const response = await apiCall.sendRequest('get', getMaxExtraAllowed);
      const { data: { data = {} } } = response;
      const { maxExtraAllowed = null } = data;
      this.setState({ maxExtraAllowed });
    } catch (err) {
      this.setState({ maxExtraAllowed: null });
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  }

  addGRNToStock = debounce(async (id) => {
    this.setState({ disableAddToStock: true });
    try {
      await apiCall.sendRequest('post', updateStockByGrnId(id));
      this.fetchAllGrns();
      this.setState({ errorMessage: '' });
    } catch (e) {
      console.error(e);
      const { message } = e.response.data;
      this.setState({ errorMessage: message });
    }
    this.setState({ disableAddToStock: false });
  }, 300);

  handleViewOrEditClick = (grnId) => {
    const { history } = this.props;
    const { poId } = this.state;
    history.push({
      pathname: `/po/${poId}/grn/${grnId}`,
    });
  };

  renderGRN = () => {
    const {
      rowsPerPage, page, data, poId, errorMessage, disableAddToStock,
    } = this.state;
    return (
      <div>
        {errorMessage && <div style={{ width: '90%', marginLeft: '5%' }}>
          <SectionMessage appearance="error">{errorMessage}</SectionMessage>
        </div>}
        <Table style={{ width: '90%', marginLeft: '5%', marginTop: '2em' }} aria-labelledby="tableTitle">
          <TableHeader columns={columnData} />
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => (
              <TableRow
                hover
                tabIndex={-1}
                key={n.id}
              >
                <TableCell style={{ color: '#f50057', width: '2em' }} component="th" scope="row" padding="none">{n.id}</TableCell>
                <TableCell component="th" scope="row" padding="none">{n.vendorName}</TableCell>
                <TableCell component="th" scope="row" padding="none">{n.poNumber}</TableCell>
                <TableCell component="th" scope="row" padding="none">{n.warehouseName}</TableCell>
                <TableCell component="th" scope="row" padding="none">{moment(n.createdOn).format('DD-MMM-YYYY')}</TableCell>
                <TableCell component="th" scope="row" padding="none">
                  <span style={{ marginRight: '10px' }}>
                    <Button size="small" color="primary" variant="outlined" onClick={() => this.handleViewOrEditClick(n.id)}>
                      {n.status === 'STOCKUPDATED' ? 'View' : 'Edit'}
                    </Button>
                  </span>
                  <span style={{ marginRight: '10px' }}>
                    <Popconfirm
                      title="Are you sure to add this GRN to the stock?"
                      onConfirm={() => this.addGRNToStock(n.id)}
                      onCancel={() => { }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="small" color="secondary" variant="contained" disabled={n.status === 'STOCKUPDATED'} >Add to Stock</Button>
                    </Popconfirm>
                  </span>
                  <Tooltip title="Print GRN">
                    <Button size="small" color="primary" variant="outlined" onClick={() => this.handlePrintGRN(n.id)} style={{ padding: "4px 8px" }}>  <PrintIcon /> </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Table>

      </div>
    );
  }

  getTotalPurchaseOrderQuantityById = async (poId) => {
    try {
      const response = await apiCall.sendRequest('get', purchaseOrderById(poId));
      const { data = {} } = response;
      const { totalQuantity = null, purchaseOrderLineItems = [] } = data;
      this.setState({
        totalQuantity,
        purchaseOrderLineItems,
      });
    } catch (e) {
      this.setState({
        totalQuantity: null,
        purchaseOrderLineItems: [],
      });
    }
  }

  getTotalReceivedQuantity = () => {
    const { data } = this.state;
    const totalReceivedQuantity = data.map(item => item.totalReceivedQuantity);
    return totalReceivedQuantity.reduce((n, m) => n + m, 0);
  }

  getTotalPurchaseOrderQuantity = () => {
    const { purchaseOrderLineItems, maxExtraAllowed } = this.state;
    const totalOrderedQuantity = purchaseOrderLineItems.map(item => Math.round(item.quantity * (1.0 + (maxExtraAllowed / 100)).toFixed(1)));
    return totalOrderedQuantity.reduce((n, m) => n + m, 0);
  }

  statusChecker = () => {
    const { data, poData } = this.state;
    if (['CLOSED', 'CANCELLED'].includes(poData.status)) return true;
    let disableAddGrn = false;
    const maxAllowedOrderedQuantity = this.getTotalPurchaseOrderQuantity();
    const totalReceivedQuantity = this.getTotalReceivedQuantity();
    const grnStatus = data.map(item => item.status);
    if (grnStatus.includes('RECEIVED')) {
      disableAddGrn = true
    }
    else if (maxAllowedOrderedQuantity == totalReceivedQuantity) {
      disableAddGrn = true;
    } else {
      disableAddGrn;
    }
    return disableAddGrn;
  }

  handlePrintAllGRN = async () => {
    const { data } = this.state;
    let grnIds = data.map(grn => grn.id);
    const url = printGRN(grnIds, true);
    try {
      await apiCall.downloadFile(url, `PO-${this.state.poId}-GRN_All.pdf`);
    } catch (error) {
      console.log(error.response);
    }
  }

  handlePrintGRN = async (grnId) => {
    const url = printGRN(grnId, true);
    try {
      await apiCall.downloadFile(url, `PO-${this.state.poId}-GRN-${grnId}.pdf`);
    } catch (error) {
      console.log(error.response);
    }
  }

  toggleInvoiceNumberInputModal = (state) => {
    this.setState({
      openInvoiceNumberInputModal: state,
      invoiceNumber: null,
      hasInvoiceNumber: false,
    });
  };

  handleOpenGRN = () => {
    const { hasInvoiceNumber, invoiceNumber, poId } = this.state;
    const { history } = this.props;
    if (!hasInvoiceNumber && !invoiceNumber) return;
    history.push({
      pathname: `/po/${poId}/grn`,
      state: { detail: invoiceNumber || '' },
    });
  };

  handleChangeCheckbox = (event) => {
    const { checked } = event.target
    this.setState({
      hasInvoiceNumber: checked,
      invoiceNumber: null,
    });
  };

  handleChangeInvoiceNumer = (event) => {
    const { value } = event.target;
    this.setState({
      invoiceNumber: value,
    });
  };

  render() {
    const { value, poId, data, openInvoiceNumberInputModal, hasInvoiceNumber, invoiceNumber } = this.state;
    return (
      <div>
        <NavBar />
        <SearchBar title={`Purchase Order - ${poId}`}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            padding: '0em 2em',
          }}
          >
            <div style={{ paddingLeft: '7em', paddingTop: '1.2em' }}>
              <Tabs
                value={value}
                onChange={this.handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="GRN" />
              </Tabs>
            </div>
            <div>
              <span style={{ marginRight: '50px' }}>
                <Button size="small" color="primary" variant="outlined" disabled={data.length <= 1} onClick={this.handlePrintAllGRN}> Print All GRN </Button>
              </span>
              <Button color="secondary" variant="contained" disabled={this.statusChecker()} onClick={() => this.toggleInvoiceNumberInputModal(true)}>
                Add GRN
              </Button>
            </div>
          </div>
        </SearchBar>
        <div style={{ margin: '1em' }}>
          {this.renderGRN()}
        </div>
        <Modal
          title="Invoice Number"
          centered
          visible={openInvoiceNumberInputModal}
          onOk={this.handleOpenGRN}
          onCancel={() => this.toggleInvoiceNumberInputModal(false)}
          okText="Proceed"
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { background: "#01579B", border: "none", width: "20%", borderRadius: "5px" } }}
        >
          <div>
            <span style={{ color: "blue" }}>Please enter invoice number if any or check "Not Received" and proceed</span>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5%" }}>
              <Checkbox onChange={this.handleChangeCheckbox} style={{ paddingTop: "5%", width: "30%" }}> Not Received </Checkbox>
              <div style={{ width: "70%" }}>
                <Typography variant="caption" style={{ paddingBottom: "5px" }}> Invoice Number </Typography>
                <Input placeholder="Invoice Number" type="text" value={invoiceNumber} onChange={this.handleChangeInvoiceNumer} disabled={hasInvoiceNumber} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  UOM: state.UOM,
});

export default connect(mapStateToProps, null)(withRouter(App));

