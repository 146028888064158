import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from './lib';
import apiCall, { searchStaticRoutes, searchStaticRoutesByCustomerAddressId } from "../api/NetworkHandler";
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { debounce, isEmpty } from 'lodash';
import ProgressBar from '../components/States/ProgressBar';

var requestId = 0;
class StaticRouteSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      suggestions: [],
      enableAutomaticRouting: false,
      errMessage: '',
    };
  }

  componentDidMount() {
    this.searchRoutes();
  }

  search = () => {
    this.searchRoutes();
  };

  handleSearch= (event) => {
    this.setState({
      searchText: event.target.value,
      suggestions: [],
    }, () => this.search());
  }

  searchRoutes = debounce( async () => {
    const { searchText, enableAutomaticRouting } = this.state;
    const { selectedAddress = {} } = this.props;
    const { id = null } = selectedAddress;
    this.setState({
      loadingData: true,
    });
    if (id) {
      try {
        requestId += 1;
        let prevRequestId = requestId;
        const response = await apiCall.sendRequest('get', searchStaticRoutesByCustomerAddressId(id, searchText, enableAutomaticRouting));
        const { data: { data = [] } } = response;
        if (requestId == prevRequestId) {
          this.setState({
            suggestions: data,
            loadingData: false,
            errMessage: '',
          });
        }
      } catch (err) {
        this.setState({
          suggestions: [],
          loadingData: false,
          errMessage: err.response.data.message,
        });
      }
    }
  }, 750);

  handleAutoAssignedRoute = () => {
    const { enableAutomaticRouting } = this.state;
    this.setState({
      enableAutomaticRouting: !enableAutomaticRouting,
      searchText: '',
    }, () => this.searchRoutes());
  };

  handleCloseModal = () => {
    const { handleClose } = this.props;
    const { enableAutomaticRouting } = this.state;
    this.setState({
      suggestions: [],
      searchText: '',
      errMessage: '',
      enableAutomaticRouting: !enableAutomaticRouting,
    });
    handleClose();
  }

  render() {
    const { openSearchBox } = this.props;
    const { enableAutomaticRouting, suggestions, errMessage, loadingData } = this.state;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openSearchBox}
        onClose={this.handleCloseModal}
        onBackdropClick={this.handleCloseModal}
      >
        <div
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            height: '80%',
            width: '70%',
            overflowX: 'auto',
            overflowY: 'auto',
          }}
        >
          <ProgressBar isLoading={loadingData}/>
          <Button
            style={{ float: 'right' }}
            onClick={this.handleCloseModal}
            color="secondary"
            aria-label="close"
          >
            <CloseIcon />
          </Button>
          <Grid style={{ padding: '2em' }}>
            <Row>
              <ColMod xs md lg={15}>
                <Row>
                  <Typography variant="body2" color="secondary">
                    Change Route
                  </Typography>
                </Row>
                <Row>
                  <ColMod lg={3}>
                    <TextField
                      value={this.state.searchText}
                      label="Route Name"
                      onChange={this.handleSearch}
                    />
                  </ColMod>
                  <ColMod lg={3}>
                    <Button color="secondary" variant="contained" onClick={this.search}>
                      Search
                    </Button>
                  </ColMod>
                  <ColMod lg={6}>
                    <Typography variant="body2" color="inherit">
                      System Suggested Route Only
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <small style={{ color: 'red' }}>NO</small>
                      &nbsp;
                      <Switch
                        checked={enableAutomaticRouting}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={this.handleAutoAssignedRoute}
                      />
                      &nbsp;
                      <small style={{ color: '#1E90FF' }}>YES</small>
                    </Typography>
                  </ColMod>
                </Row>
                <span style={{ fontSize: '13px', color: 'red', marginLeft: '10px' }}>{errMessage || ''}</span>
                <Row>
                  <ColMod lg={12}>
                    <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '480px' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Warehouse</TableCell>
                            <TableCell>Hub</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suggestions.map((suggestion, index) => (
                            <TableRow key={index}>
                              <TableCell>{suggestion.name}</TableCell>
                              <TableCell>{suggestion.warehouseName}</TableCell>
                              <TableCell>{suggestion.hubName}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    this.props.selectStaticRoute(suggestion);
                                    this.setState({ suggestions: [], searchText: '' });
                                  }}
                                >
                                  Select
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </ColMod>
                </Row>
              </ColMod>
            </Row>
          </Grid>
        </div>
      </Modal>
    );
  }
}

StaticRouteSelection.propTypes = {
  openSearchBox: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectStaticRoute: PropTypes.func.isRequired,
};

export default StaticRouteSelection;
