import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DEEPLINKING_URLS } from '../../constants';
import { sortBy } from 'lodash';

function DescriptionTable() {
  // sort table data
  const data = sortBy(DEEPLINKING_URLS, ['type']);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>App Section</TableCell>
            <TableCell>Url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.text}
                </TableCell>
                <TableCell>{row.url}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

DescriptionTable.propTypes = {
};

export default DescriptionTable;
