import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  Typography,
  Button,
  TextField,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
} from '@material-ui/core';
import CategorySelector from '../../../components/Category/Selector';
import ProductSelector from '../../../components/Product/Selector';
import CatalogSelector from '../../../components/Catalog/Selector';
import BrandSelector from '../../../components/Brand/Selector';
import ProductImageManager from '../../../components/Core/ProductImageManager/ProductImageManager';
import RegionMultiSelector from '../../../components/Region/MultiSelector';
import apiCall, {
  getProductById,
  getBrandById,
  getCategoryById,
  getBannerById,
  getCatalogById,
  bannersPath,
  fetchWidgetsByWidgetType,
  getWidgetsByWidgetId,
  fetchProductWidgets,
  updateOverlaysById,
} from '../../../api/NetworkHandler';
import SectionMessage from '@atlaskit/section-message';
import DeeplinkUrlDescription from '../../../components/Support/DeeplinkUrlDescription.js'
import DisplayOptionsSelector from './DisplayOptionsSelector';
import WidgetSelector from './WidgetSelector';
import moment from 'moment';
import DeeplinkUrlGenerator from '../../../utils/DeeplinkUrlGenerator';
import AudienceSetSelector from '../../../modules/Audience/components/AudienceSetSelector';
import { isEmpty } from "lodash";


const styles = {
  container: {
    width: window.innerWidth * 0.4,
  },
  arrow: {
    cursor: "pointer",
  },
  label: {
    marginBottom: '1em',
  },
  error: {
    color: 'red',
  }
}

var versionDict = {
  "bannerWidgetRequestId": 0,
  "productWidgetRequestId": 0,
  "overlayRequestId": 0,
  "selectedOverlayRequestId": 0,
}

class BannerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: {
        name: '',
        title: '',
        active: 'true',
        entityType: '',
        entityId: null,
        parentId: null,
        sortId: 0,
        bannerRegions: [],
        imageUrl: '',
        deepLink: '',
        bannerScreens: [],
        startTime: null,
        endTime: null,
      },
      bannerData: {},
      selectedEntity: {
        label: '',
        value: '',
      },
      selectedCatalog: {
        label: '',
        value: '',
        regions: [],
      },
      errorMessage: '',
      regions: [],
      error: {},
      selectedDisplayOptions: [],
      displayCategories: false,
      selectedDisplayCategories: [],
      bannerWidgets: [],
      productWidgets: [],
      selectedOverlay: null,
      openDeeplinkModal: false,
    }
  }

  componentDidMount() {
    this.getBannerWidgets();
    this.getProductWidgets();
    const { banner } = this.props;
    if (banner.bannerRegions) {
      const regions = banner.bannerRegions.map(region => {
        return {
          value: region.regionId,
          label: region.name,
        }
      })
      this.setState({
        banner,
        regions
      }, () => { this.fetchEntityDetails() });
    }
    if (banner.bannerScreens) {
      let isCategorySelected = false;
      const selectedDisplayOptions = banner.bannerScreens.map(screen => {
        const label = screen.screenType == "HOME_SCREEN" ? "Home Screen" : screen.screenType == "CATEGORY" ? "Category" : "";
        if (label == "Category" && !isCategorySelected) {
          isCategorySelected = true;
        }
        return {
          label,
          value: screen.screenType,
        }
      })
      if (isCategorySelected) {
        this.getAllSelestedCategories();
      }
      this.setState({
        selectedDisplayOptions,
        displayCategories: isCategorySelected
      })
    }
    if (banner.overlayId) {
      this.getOverlayById(banner.overlayId);
    }
  }

  getBannerWidgets = async () => {
    try {
      versionDict.bannerWidgetRequestId += 1;
      let prevRequestId = versionDict.bannerWidgetRequestId;
      const response = await apiCall.sendRequest('get', fetchWidgetsByWidgetType('BANNER'));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.bannerWidgetRequestId && data.length > 0) {
        this.setState({
          bannerWidgets: data.map(el => ({ label: el.title, value: el.id }))
        });
      }
    } catch (err) {
      this.setState({
        bannerWidgets: [],
      });
    }
  }

  getProductWidgets = async () => {
    try {
      versionDict.productWidgetRequestId += 1;
      let prevRequestId = versionDict.productWidgetRequestId;
      const response = await apiCall.sendRequest('get', fetchProductWidgets);
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.productWidgetRequestId && data.length > 0) {
        this.setState({
          productWidgets: data.map(el => ({ label: el.title, value: el.id }))
        });
      }
    } catch (err) {
      this.setState({
        productWidgets: [],
      });
    }
  }

  getOverlayById = async (overlayId) => {
    try {
      versionDict.selectedOverlayRequestId += 1;
      let prevRequestId = versionDict.selectedOverlayRequestId;
      const response = await apiCall.sendRequest('get', updateOverlaysById(overlayId));
      const { data: { data = {} } = {} } = response;
      if (prevRequestId == versionDict.selectedOverlayRequestId) {
        this.setState({
          selectedOverlay: {...data, label: data.title, value: data.id},
        });
      }
    } catch (err) {
      this.setState({
        selectedOverlay: {},
      });
    }
  }

  getAllSelestedCategories = () => {
    const { banner } = this.props;

    banner.bannerScreens.map(async (screen) => {
      if (screen.screenType == "CATEGORY") {
        const response = await apiCall.sendRequest('get', getCategoryById(screen.screenAttribute));
        const { data } = response;
        const category = {
          label: data.name,
          value: data.id,
        }
        this.setState(prevState => {
          const { selectedDisplayCategories } = prevState;

          return {
            selectedDisplayCategories: [
              ...selectedDisplayCategories,
              category
            ]
          }
        })
      }
    })
  }

  fetchEntityDetails = async () => {
    const { banner } = this.state;
    const { entityId, parentId } = banner;
    let response;
    let catalogDetails;

    if (banner.entityType === 'PRODUCT' && entityId) {
      response = await apiCall.sendRequest('get', getProductById(entityId));
      if (response) {
        catalogDetails = await apiCall.sendRequest('get', getCatalogById(response.data.id));
      }
    }
    if (banner.entityType == "CATEGORY" && entityId) {
      response = await apiCall.sendRequest('get', getCategoryById(entityId));
    }
    if (banner.entityType == "BRAND" && entityId) {
      response = await apiCall.sendRequest('get', getBrandById(entityId));
    }
    if (banner.entityType === "PRODUCT_WIDGET" && entityId) {
      response = await apiCall.sendRequest('get', getWidgetsByWidgetId(entityId));
    }
    if (response) {
      const { data } = response;
      let selectedCatalog = {
        value: '',
        label: '',
      }
      const selectedEntity = banner.entityType === "PRODUCT_WIDGET" ? {label: data.title, value: data.id} : {label: data.name, value: data.id}
      if (catalogDetails) {
        selectedCatalog = {
          label: catalogDetails.data.data.name,
          value: catalogDetails.data.data.id,
        };
      }
      this.setState({ selectedEntity, selectedCatalog });
    }
    if (parentId) {
      response = await apiCall.sendRequest('get', getWidgetsByWidgetId(parentId));
      const { data = {} } = response;
      const selectedWidget = {
        label: data.title,
        value: data.id,
      };
      response && this.setState({ selectedWidget });
    }

  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const { banner, bannerData } = prevState;
      if (name == "entityType") {
        banner.entityId = null;
        bannerData.entityId = null;
      }
      return {
        banner: {
          ...banner,
          [name]: value,
        },
        bannerData: {
          ...bannerData,
          [name]: value
        },
        selectedEntity: name == "entityType" ?  {
          label:'',
          value:''
        } : prevState.selectedEntity,
      }
    })
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;
      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    });
  }

  isPropertyValid = (property, value) => {
    if (property == "bannerRegions") {
      if (!value.length) {
        this.setError(property, true);
        return false;
      } else {
        this.setError(property, false);
      }
    } else if (property == "bannerScreens") {
      const { displayCategories, selectedDisplayCategories } = this.state;
      if (!value.length && !displayCategories) {
        this.setError(property, true);
        return false;
      } else {
        this.setError(property, false);
      }
      if(displayCategories && (!selectedDisplayCategories || !selectedDisplayCategories.length)){
        this.setError("selectedCategories", true);
        return false;
      } else {
        this.setError("selectedCategories", false);
      }
    } else {
      if (property == "active") {
        value = value + "";
      }
      if (!value) {
        this.setError(property, true);
        return false;
      } else {
        this.setError(property, false);
      }
    }
    return true;
  }

  validateForm = () => {
    let { banner } = this.state;
    let isFormValid = true;

    let validEntityTypes = ["name", "active", "parentId", "entityType", "entityId", "sortId", "bannerRegions", "imageUrl", "bannerScreens"];
    if (banner["entityType"] == "OTHERS") {
      validEntityTypes = ["name", "active", "parentId", "entityType", "sortId", "bannerRegions", "imageUrl", "deepLink", "bannerScreens"];
    }
    else if (banner["entityType"] == "PRODUCT_WIDGET") {
      validEntityTypes = ["name", "title", "active", "parentId", "entityType", "sortId", "bannerRegions", "imageUrl", "bannerScreens"];
    }

    for (const property in banner) {
      if (validEntityTypes.includes(property)) {
        if (!this.isPropertyValid(property, banner[property])) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  }

  getFormatedDateTime = (dateTime) => {
    if(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  onSave = async () => {
    if (!this.validateForm()) {
      return;
    }
    const { bannerData, banner } = this.state;
    const { onClose, onRefresh } = this.props;

    bannerData.startTime = this.getFormatedDateTime(banner.startTime);
    bannerData.endTime = this.getFormatedDateTime(banner.endTime);
    bannerData.overlayId = banner.overlayId;

    try {
      if (banner.id) {
        bannerData.bannerRegions = banner.bannerRegions.map(region => ({ regionId: region.regionId }));
        bannerData.bannerScreens = banner.bannerScreens;
        if (banner.entityType == "OTHERS") {
          bannerData.deepLink = banner.deepLink;
          bannerData.entityId = null
        }
        bannerData.active = banner.active;
        await apiCall.sendRequest('patch', getBannerById(banner.id), bannerData);
      } else {
        await apiCall.sendRequest('post', bannersPath, bannerData);
      }
      onClose();
      onRefresh();
    } catch (error) {
      const { response: { data } } = error;
      this.setState({ errorMessage: data.message });
    }
  }

  onEntitySelect = (entity) => {
    if (entity) {
      this.setState({
        regions: [],
        selectedEntity: {
          value: '',
          id: '',
        },
      }, () => { this.setState({ selectedEntity: entity }); });
      const target = { name: 'entityId', value: entity.value };
      this.onInputChange({ target });
    } else {
      this.setState({
        regions: [],
        selectedEntity: {
          value: '',
          id: '',
        },
      });
    }
  }


  onCatalogSelect = (entity) => {
    if (entity) {
      this.setState({
        regions: [],
        selectedEntity: {
          value: '',
          id: '',
        },
        selectedCatalog: {
          value: '',
          id: '',
          regions: [],
        },
      }, () => { this.setState({ selectedCatalog: entity }); });
      const target = { name: 'catalogType', value: entity.value };
      this.onInputChange({ target });
    } else {
      this.setState({
        regions: [],
        selectedCatalog: {
          value: '',
          id: '',
          regions: [],
        },
        selectedEntity: {
          value: '',
          id: '',
        },
      });
    }
  }

  selectRegion = (regions) => {
    if (!regions) {
      regions = [];
    }
    const bannerRegions = regions.map((region) => {
      return {
        regionId: region.value
      }
    })
    this.setState((prevState) => {
      const { banner, bannerData } = prevState;
      return {
        regions,
        banner: {
          ...banner,
          bannerRegions: bannerRegions
        },
        bannerData: {
          ...bannerData,
          bannerRegions: bannerRegions
        }
      }
    });
  }

  onImageUpload = (imageUrl) => {
    this.setState(prevState => {
      const { banner, bannerData } = this.state;
      return {
        banner: {
          ...banner,
          imageUrl
        },
        bannerData: {
          ...bannerData,
          imageUrl
        }
      }
    })
  }

  onDeleteImage = () => {
    this.setState(prevState => {
      const { banner, bannerData } = this.state;
      return {
        banner: {
          ...banner,
          imageUrl: null
        },
        bannerData: {
          ...bannerData,
          imageUrl: null
        }
      }
    })
  }

  onDisplayOptionChange = (displayOptions) => {
    this.setState(prevState => {
      const { banner, bannerData, selectedDisplayCategories } = prevState;

      let { displayCategories } = prevState;
      let bannerScreens = banner.bannerScreens || [];

      if (displayOptions) {
        displayCategories = displayOptions.some(option => option.value == "CATEGORY");
        const homeSelected = displayOptions.some(option => option.value == "HOME_SCREEN");
        const homeAlreadySelected = bannerScreens.some(type => type.screenType == "HOME_SCREEN");

        if (!displayCategories) {
          bannerScreens = [];
          if (homeAlreadySelected) {
            bannerScreens.push({ screenType: "HOME_SCREEN" })
          }
        }

        if (homeSelected && !homeAlreadySelected) {
          bannerScreens.push({ screenType: "HOME_SCREEN" })
        } else if (!homeSelected){
          bannerScreens = bannerScreens.filter(type => type.screenType !== "HOME_SCREEN")
        }
      } else {
        displayCategories = false;
        bannerScreens = [];
      }

      return {
        selectedDisplayOptions: displayOptions || [],
        displayCategories,
        selectedDisplayCategories: !displayCategories ? [] : selectedDisplayCategories,
        banner: {
          ...banner,
          bannerScreens: bannerScreens
        },
        bannerData: {
          ...bannerData,
          bannerScreens: bannerScreens
        },
      }
    })
  }

  onDisplayCategoryChange = (displayCategories) => {
    this.setState(prevState => {
      const { banner, bannerData } = prevState;
      let bannerScreens = [];

      const isHomeSelected = banner.bannerScreens.some(type => type.screenType == "HOME_SCREEN");
      if (isHomeSelected) {
        bannerScreens.push({ screenType: "HOME_SCREEN" })
      }

      if (displayCategories) {
        displayCategories.forEach(category => {
          bannerScreens.push({
            screenType: "CATEGORY",
            screenAttribute: category.value,
          })
        })
      }

      return {
        selectedDisplayCategories: displayCategories,
        banner: {
          ...banner,
          bannerScreens
        },
        bannerData: {
          ...bannerData,
          bannerScreens
        }
      }
    })
  }

  handleWidgetChange = (selectedWidget) => {
    const target = { name: 'parentId', value: selectedWidget.value };
    this.setState({
      selectedWidget,
    }, this.onInputChange({ target }));
  }

  handleOverlaySelection = (selectedOverlay) => {
    const { banner, bannerData } = this.state;
    this.setState({
      banner: {
        ...banner,
        overlayId:!isEmpty(selectedOverlay) ? selectedOverlay.value : null,
      },
      bannerData: {
        ...bannerData,
        overlayId: !isEmpty(selectedOverlay) ? selectedOverlay.value : null,
      },
      selectedOverlay,
    });
  };

  handleOpenDeeplinkGenerator = () => {
    this.setState({
      openDeeplinkModal: true
    });
  };

  handleCloseDeeplinkGenerator = () => {
    this.setState({
      openDeeplinkModal: false
    });
  };

  handleCopyUrl = (url) => {
    const { banner } = this.state;
    this.setState({
      banner: {
        ...banner,
        deepLink: url,
      }
    });
  };

  render() {
    const { classes, onClose, bannerTypes, bannerDisplayOptions } = this.props;
    const { banner,
      selectedEntity,
      errorMessage,
      regions,
      error,
      selectedCatalog,
      selectedDisplayOptions,
      displayCategories,
      selectedDisplayCategories,
      bannerWidgets,
      productWidgets,
      selectedWidget,
      selectedOverlay,
      openDeeplinkModal,
    } = this.state;
    const {
      name,
      title,
      active,
      entityType,
      sortId,
      imageUrl,
      deepLink,
      startTime,
      endTime,
      entityId
    } = banner;

    return (
      <Grid className={classes.container} fluid>
        <Row md={12} between="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">Banner Form</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Row md={12}>
              <Col md={12}>
                <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
                <Button type="submit" variant="contained" color="secondary" onClick={this.onSave}>Save</Button>
              </Col>
            </Row>
          </ColMod>
        </Row>
        <Row>
          <ColMod custompadding="true" bumphigh="true">
            <Typography color="error" variant="caption">* Required</Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row md={12}>
          <ColMod md={12}>
            {errorMessage && <SectionMessage appearance="error">{errorMessage}</SectionMessage>}
          </ColMod>
        </Row>}
        <Row md={12}>
          <ColMod md={(entityType === "PRODUCT_WIDGET") ? 6 : 12}>
            <TextField
              value={name || ''}
              label="Name"
              name="name"
              onChange={this.onInputChange}
              fullWidth
              required
              error={error.name}
              autoComplete="off"
            />
          </ColMod>
          {(entityType === "PRODUCT_WIDGET") &&
            <ColMod md={6}>
              <TextField
                value={title || ''}
                label="Product Listing Title"
                name="title"
                onChange={this.onInputChange}
                fullWidth
                required
                error={error.title}
                autoComplete="off"
              />
            </ColMod>}
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            <Typography variant="caption"> Audience Set </Typography>
            <AudienceSetSelector selected={selectedOverlay} onSelect={this.handleOverlaySelection} />
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={6}>
            <Typography variant="caption" className={error.status ? classes.error : null}> Status<sup>*</sup> </Typography>
            <RadioGroup onChange={this.onInputChange} name="active" value={(active + '') || 'true'} row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </ColMod>
          <ColMod md={6}>
            <Typography className={[classes.label, error.parentId ? classes.error : null]} variant="caption"> Banner Set<sup>*</sup> </Typography>
            <WidgetSelector options={bannerWidgets} selected={selectedWidget} onWidgetChange={this.handleWidgetChange}/>
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={6}>
            <Typography className={[classes.label, error.entityType ? classes.error : null]} variant="caption"> Entity Type<sup>*</sup>  </Typography>
            <Select
              value={entityType || ''}
              fullWidth
              onChange={this.onInputChange}
              name="entityType"
            >
              {/* {BANNER_ENTITIES.map((entityType, index) => (
                <MenuItem key={index} value={entityType}>{capitalize(entityType)}</MenuItem>
              ))} */}
              {Object.keys(bannerTypes || {}).map((type, index) => (<MenuItem key={index} value={type}>{bannerTypes[type]}</MenuItem>)) }
            </Select>
          </ColMod>
          <ColMod md={6}>
            <Typography className={[classes.label, error.entityId ? classes.error : null]} variant="caption">
              {(entityType == "CATEGORY") && "Category"}
              {(entityType == "BRAND") && "Brand"}
              {(entityType == "OTHERS") && "Deeplink URL"}
              {(entityType === "PRODUCT_WIDGET") && "Product Widget"}
              {(['PRODUCT', 'SMS'].includes(entityType)) && 'Catalog'}
              {!entityType && "Entity Id"}
              <sup>*</sup>
            </Typography>
            {(entityType == "CATEGORY") && <CategorySelector selected={selectedEntity} onSelect={this.onEntitySelect} />}
            {(entityType == "BRAND") && <BrandSelector selected={selectedEntity} onSelect={this.onEntitySelect} />}
            {(entityType == "OTHERS") && <TextField
              type="text"
              value={deepLink || ''}
              name="deepLink"
              onChange={this.onInputChange}
              fullWidth
              required
              helperText={<a onClick={this.handleOpenDeeplinkGenerator}>*click to generate url</a>}
              error={error.deepLink} />}
            {(entityType === "PRODUCT_WIDGET") && <WidgetSelector options={productWidgets} selected={selectedEntity} onWidgetChange={this.onEntitySelect}/>}
            {(['PRODUCT', 'SMS'].includes(entityType)) && <CatalogSelector selected={selectedCatalog} onSelect={this.onCatalogSelect} />}
            {!entityType &&
              <TextField
                value="Select Entity Type First"
                disabled />
            }
          </ColMod>
        </Row>
        <Row md={12} bottom="md">
        { (['PRODUCT', 'SMS'].includes(entityType)) && (selectedCatalog.value !== '') &&
          <ColMod md={6}>
            <Typography className={[classes.label, error.bannerRegions ? classes.error : null]} variant="caption"> Products<sup>*</sup>  </Typography>
            <ProductSelector
              selected={selectedEntity}
              onSelect={this.onEntitySelect}
              categoryId={selectedCatalog.value}
            />
          </ColMod>}
          { (entityType === 'BRAND') && (selectedEntity.value !== '') &&
          <ColMod md={6}>
            <Typography className={[classes.label, error.bannerRegions ? classes.error : null]} variant="caption"> Regions<sup>*</sup>  </Typography>
            <RegionMultiSelector
              selected={regions}
              onSelect={this.selectRegion}
              searchTerm={selectedEntity.value}
              defaultRegions={false}
            />
          </ColMod>}
          { (['PRODUCT', 'SMS'].includes(entityType)) && (selectedCatalog.value !== '') &&
          <ColMod md={6}>
            <Typography className={[classes.label, error.bannerRegions ? classes.error : null]} variant="caption"> Regions<sup>*</sup>  </Typography>
            <RegionMultiSelector
              selected={regions}
              onSelect={this.selectRegion}
              options={selectedCatalog.regions}
              defaultRegions={false}
            />
          </ColMod>}
          { (['CATEGORY', 'OTHERS', 'PRODUCT_WIDGET'].includes(entityType)) &&
          <ColMod md={6}>
            <Typography className={[classes.label, error.bannerRegions ? classes.error : null]} variant="caption"> Regions<sup>*</sup>  </Typography>
            <RegionMultiSelector
              selected={regions}
              onSelect={this.selectRegion}
              defaultRegions
            />
          </ColMod>}
          <ColMod md={6}>
            <TextField
              label="Sort Id"
              type="number"
              value={sortId || ''}
              name="sortId"
              onChange={this.onInputChange}
              fullWidth
              required
              error={error.sortId}
            />
          </ColMod>
          <ColMod md={6}>
            <Typography className={[classes.label, error.bannerScreens ? classes.error : null]} variant="caption"> Display Options<sup>*</sup>  </Typography>
            <DisplayOptionsSelector
              selected={selectedDisplayOptions}
              options={bannerDisplayOptions}
              onDisplayOptionChange={this.onDisplayOptionChange}
            />
          </ColMod>
          {displayCategories && <ColMod md={6}>
            <Typography className={[classes.label, error.selectedCategories ? classes.error : null]} variant="caption"> Categories<sup>*</sup>  </Typography>
            <CategorySelector
              onSelect={this.onDisplayCategoryChange}
              selected={selectedDisplayCategories}
              isMulti={true}
            />
          </ColMod>}
          <ColMod md={6}>
            <TextField
              type="datetime-local"
              label="Start Time"
              name="startTime"
              value={moment(startTime).format("YYYY-MM-DDTHH:mm")}
              onChange={this.onInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
          <ColMod md={6}>
            <TextField
              type="datetime-local"
              label="End Time"
              name="endTime"
              value={moment(endTime).format("YYYY-MM-DDTHH:mm")}
              onChange={this.onInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            {error.imageUrl && <span style={{ color: 'red', paddingBottom: '10px' }}>Please upload one image..</span>}
            <ProductImageManager
              images={[{
                imageUrl: imageUrl,
                position: "FRONT",
                name: name
              }]}
              singleImage={true}
              onImageUpload={this.onImageUpload}
              onDelete={this.onDeleteImage}
            />
          </ColMod>
        </Row>
        <Row md={12}>
          <ColMod md={12}>
            <DeeplinkUrlDescription />
          </ColMod>
        </Row>
        <DeeplinkUrlGenerator open={openDeeplinkModal} onClose={this.handleCloseDeeplinkGenerator} parentComponent="banner" onCopyUrl={this.handleCopyUrl}/>
      </Grid>
    )
  }
}

BannerForm.propTypes = {
  banner: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired
}

BannerForm.defaultProps = {
  banner: {}
}

const mapStateToProps = state => ({
  bannerTypes: state.Status.bannerTypes,
  bannerDisplayOptions: state.Status.bannerScreenTypes,
});

export default connect(mapStateToProps, null)(withStyles(styles)(BannerForm));