import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FileUpload from '@material-ui/icons/FileUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import apiCall, { vendorsLocationIdPath } from '../api/NetworkHandler';


const columnData = [
  {
    id: 'Catalog', numeric: false, disablePadding: true, label: 'Catalog',
  },
  {
    id: 'VendorName', numeric: false, disablePadding: true, label: 'Vendor',
  },
  {
    id: 'Actions', numeric: false, disablePadding: true, label: 'Actions',
  },
  // { id: 'Status', numeric: false, disablePadding: true, label: 'Status' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.label}
            </TableCell>
            ), this)}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 20,
      vendorId: '',
      selectedFile: null,
      selectedVendor: {},
      venderLocationId: [],
      vendorLocations: [],
    };
  }


  componentDidMount() {
    this.fetchVendorLocation();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };


  handleClick = (event, data) => {
    this.setState({ selected: data.id });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    this.setState({ rowsPerPage: value });
  };

  isSelected = id => this.state.selected === id

  updateCatalogToPending = (id) => {
    apiCall.sendRequest('patch', `/api/v1/vendor-catalogs/${id}`, { status: 'PENDING' })
      .then((response) => {
        this.props.fetchAllDetails();
      })
      .error((error) => {
        console.error(error);
      });
  }

  handleFileUpload = (event) => {
    const { files } = event.target;
    const [file] = files;
    this.setState({
      selectedFile: file,
    });
  }

  fetchVendorLocation = async () => {
    const { vendorId } = this.props;
    const url = vendorsLocationIdPath(vendorId);
    try {
      const response = await apiCall.sendRequest('get', url);
      const { data: { vendorLocations = [] } } = response;
      this.setState({
        vendorLocations,
      });
    } catch (e) {
      console.log('error');
    }
  }

  handleBulkUpload = async () => {
    const { vendorId } = this.props;
    const { selectedFile, selectedVendor, vendorLocations } = this.state;
    const { id: vendorCatalogueId, name: vendorCatalogueName } = selectedVendor;

    const locations = [];
    vendorLocations.forEach((location) => {
      if (selectedVendor.id === location.id) {
        locations.push(location.id);
      }
    });
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', vendorCatalogueName);
    formData.append('locations', locations);

    apiCall.sendRequest('put', `/api/v1/vendors/${vendorId}/vendor-catalogs/${vendorCatalogueId}/upload`, formData)
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
      });
    this.setState({
      open: false,
    });
  }


  render() {
    const { classes, data, vendorId } = this.props;
    const { rowsPerPage, page, open } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell component="th" scope="row" padding="none"><Typography variant="body2">{n.name}</Typography></TableCell>
                      <TableCell component="th" scope="row" padding="none"><Typography variant="body2">{n.vendorName}</Typography></TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Typography variant="body2" color="secondary">
                          {n.status !== 'PENDING' ?
                            <Link to={`/edit-catalog/${n.id}/${vendorId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                              <Button color="secondary">
                              Edit
                              </Button>
                            </Link>
                          : <Button color="secondary" disabled>Edit</Button>}  &emsp; | &emsp;
                          <Button onClick={() => { this.setState({ open: true, selectedVendor: n }); }} size="small" style={{ paddingRight: '2em' }} color="secondary">
                            <FileUpload />
                                Upload
                          </Button>
                        </Typography>
                      </TableCell>
                      {/* <TableCell component="th" scope="row" padding="none">
                        <Typography variant="body2">
                          {(n.status !== 'NEW' ? n.status : <Button variant="contained" color="secondary" onClick={() => this.updateCatalogToPending(n.id)}>SUBMIT</Button>) }
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
        <Dialog
          open={open}
          onClose={() => { this.setState({ open: false }); }}
        >
          <DialogTitle><Typography variant="title" color="secondary"> Upload Catalog </Typography></DialogTitle>
          <DialogContent style={{ marginTop: '2em' }}>
            <div>

              <input type="file" onChange={this.handleFileUpload} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ open: false }); }} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" onClick={() => { this.handleBulkUpload(); }} color="secondary">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  vendorId: PropTypes.number,
};

export default withStyles(styles)(EnhancedTable);
