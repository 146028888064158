import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableRow, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ProductSelection from '../../../components/ProductSelection';
import { productSearchPath } from '../../../api/NetworkHandler';
import TableHeader from '../../Commons/components/TableHeader';


const columnData = [
  {
    id: 'product', numeric: false, disablePadding: true, label: 'Product',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantity',
  },
  {
    id: 'uom', numeric: false, disablePadding: true, label: 'Unit of Measure',
  },
  {
    id: 'price', numeric: false, disablePadding: true, label: 'Unit Price',
  },
  {
    id: 'startDate', numeric: false, disablePadding: true, label: 'Start Date',
  },
  {
    id: 'pauseFrom', numeric: false, disablePadding: true, label: 'Pause From',
  },
  {
    id: 'pauseTill', numeric: false, disablePadding: true, label: 'Pause To',
  },
  {
    id: 'schedule', numeric: false, disablePadding: true, label: 'Schedule',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Action',
  },

];


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
    padding: '0px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      showOffer: false,
    };
  }

  isSelected = id => this.state.selected === id

  suggestProduct =() => {
    this.setState({
      showOffer: true,
    });
  }

  handleClose = () => {
    this.setState({
      showOffer: false,
    });
  }

  selectProduct = (product) => {
    const { onSelect } = this.props;
    onSelect(product);
    this.handleClose();
  }

  selectFrequency = (index, value) => {
    const { onFieldChange, schedules, onCreateSchedule } = this.props;
    let frequency = {};
    if (value === 'createPlan') {
      onCreateSchedule(index);
    } else {
      frequency = {
        name: 'Get Once',
        scheduleType: 'GET_ONCE',
        id: '',
      };
      if (value !== 'Get Once') {
        frequency = schedules.find(schedule => schedule.name === value);
      }
      onFieldChange(index, 'frequency', frequency);
    }
  }

  render() {
    const {
      classes,
      items,
      schedules,
      onFieldChange,
      removeItem,
      onModify,
      handleSave,
      isCustomerPaused,
      customerId,
    } = this.props;
    const {
      showOffer,
    } = this.state;
    let warningMessage = '';
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader columns={columnData} />
            <TableBody>
              {items.map((product, index) => {
                const {
                  categoryPause,
                  categoryPauseFrom,
                  categoryPauseTill,
                } = product;
                const isSelected = this.isSelected(product.customerId);
                const { newEntry = false } = product;
                let isDisabled = false;
                let isPaused = false;
                let searchIconColor = 'secondary';
                if (!newEntry) {
                  isDisabled = true;
                  searchIconColor = 'disabled';
                }

                if (isCustomerPaused) isPaused = true;
                if (categoryPause) isPaused = true;
                if (categoryPauseFrom && categoryPauseTill
                    && moment(categoryPauseFrom).isBefore(moment().add(1, 'days').endOf('day'))) {
                      isPaused = true;
                    }
                if (isPaused) warningMessage = 'Few categories has been paused by customer, so you cant modify items belong to those categories';
                if (isCustomerPaused) warningMessage = 'Customer has been autopaused/paused , if autopaused it might be low walet balance, or manually he paused it';
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={product.customerId}
                    selected={isSelected}
                  >
                    <TableCell style={{ color: '#f50057', padding: '0px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 5 }}>
                        <TextField
                          value={product.productName}
                          onClick={!isDisabled && this.suggestProduct}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="start">
                                <Search color={searchIconColor} />
                              </InputAdornment>
                            ),
                          }}
                          disabled={isDisabled}
                        />
                      </div>
                      <div>
                        <ProductSelection
                          showOfferPopup={showOffer}
                          handleClose={this.handleClose}
                          selectProduct={this.selectProduct}
                          searchUrl={productSearchPath}
                          customerId={customerId}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={{ padding: '0px' }}component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          style={{ width: '50px' }}
                          value={product.quantity}
                          onChange={e => onFieldChange(index, 'quantity', e.target.value)}
                          disabled={isDisabled && isPaused}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ padding: '0px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="text"
                          style={{ width: '50px' }}
                          value={`${product.unitMeasure} ${product.code}`}
                          disabled
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ padding: '0px' }} component="th" scope="row" padding="none">
                      <div style={{ flex: 1 }}>
                        <TextField
                          type="number"
                          style={{ width: '50px' }}
                          value={product.unitPrice}
                          disabled
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ padding: '0px' }}>
                      <div style={{ flex: 2 }}>
                        <TextField
                          id="date"
                          type="date"
                          name="selectDate"
                          onChange={e => onFieldChange(index, 'startDate', e.target.value)}
                          value={product.startDate}
                          InputLabelProps={{
                          shrink: true,
                          }}
                          disabled={isDisabled && isPaused}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ padding: '0px' }}>
                      <div style={{ flex: 2 }}>
                        <TextField
                          id="date"
                          type="date"
                          name="selectDate"
                          onChange={e => onFieldChange(index, 'pauseFrom', e.target.value)}
                          value={product.pauseFrom}
                          InputLabelProps={{
                          shrink: true,
                          }}
                          disabled={isPaused}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={{ padding: '0px' }}>
                      <div style={{ flex: 2 }}>
                        <TextField
                          id="date"
                          type="date"
                          name="selectDate"
                          onChange={e => onFieldChange(index, 'pauseTill', e.target.value)}
                          value={product.pauseTill}
                          InputLabelProps={{
                          shrink: true,
                          }}
                          disabled={isPaused}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={{ padding: '0px' }}>
                      <div style={{ flex: 1 }}>
                        <Select
                          name="schedule"
                          onChange={e => this.selectFrequency(index, e.target.value)}
                          value={product.planName}
                          disabled={isDisabled && isPaused}
                        >
                          {
                          schedules.map(status =>
                            (
                              <MenuItem key={status} value={status.name}>
                                {status.name}
                              </MenuItem>
                            ))}
                          <MenuItem key="getOnce" value="Get Once">
                            Get Once
                          </MenuItem>
                          <MenuItem key="createPlan" value="createPlan" style={{ color: '#f44336' }}>
                            CREATE PLAN
                          </MenuItem>

                        </Select>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div style={{ cursor: 'pointer' }}>
                        {!newEntry &&
                        <Button
                          size="small"
                          color="secondary"
                          onClick={() => onModify(product)}
                          disabled={isPaused}
                        >
                          Modify
                        </Button>}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      {newEntry &&
                      <div style={{ cursor: 'pointer' }}>
                        <Button size="small" color="secondary" onClick={() => handleSave(product)}>
                          Submit
                        </Button>
                      </div>}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div style={{ cursor: 'pointer' }}>
                        <Button disabled={isPaused} onClick={() => removeItem(index, product)}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div style={{ color: '#f44336' }}><p>{warningMessage}</p></div>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  schedules: PropTypes.array,
  items: PropTypes.array,
  onSelect: PropTypes.func,
  onCreateSchedule: PropTypes.func,
  onFieldChange: PropTypes.func,
  removeItem: PropTypes.func,
  onModify: PropTypes.func,
  handleSave: PropTypes.func,
  isCustomerPaused: PropTypes.bool,
  customerId: PropTypes.string,
};

EnhancedTable.defaultProps = {
  onFieldChange: () => {},
  schedules: [],
  classes: {},
  items: [],
  onCreateSchedule: () => {},
  onSelect: () => {},
  removeItem: () => {},
};

const mapStateToProps = state => ({
  UOM: state.UOM,
  deliveryRemarks: state.Status.deliveryRemarks,
});

export default connect(mapStateToProps, null)(withStyles(styles)(EnhancedTable));
