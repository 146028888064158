import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableHeader from '../../Commons/components/TableHeader';


const columnData = [
  {
    id: 'agent',
    numeric: false,
    disablePadding: false,
    label: 'Agent Name',
  },
  {
    id: 'agentStatus',
    numeric: false,
    disablePadding: false,
    label: 'status',
  },
  {
    id: 'ticketsAssigned',
    numeric: false,
    disablePadding: false,
    label: 'Tickets',
  },
  {
    id: 'workingHours',
    numeric: false,
    disablePadding: false,
    label: 'Time Spent (Hrs)',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];


class ReportingTable extends Component {
  render() {
    const { data } = this.props;
    return (
      <Table>
        <TableHeader columns={columnData} />
        <TableBody>
          {
            data.map(item => (
              <TableRow>
                <TableCell> {item.agent_name}</TableCell>
                <TableCell>{item.current_status}</TableCell>
                <TableCell>{item.total_tickets}</TableCell>
                <TableCell>{item.total_time_spent}</TableCell>
                <TableCell> View</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

ReportingTable.propTypes = {
  data: PropTypes.instanceOf(Array),
};

export default ReportingTable;
