import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@material-ui/core';


const styles = {
  callTicket: {
    padding: 10,
    border: '1px solid #f1f1f1',
    // borderRadius: 5,
    marginBottom: 10,
    background: '#f5f5f5',
    fontSize: 15,
  },
};

class CallTicekt extends Component {
  render() {
    const { callDetail = {} } = this.props;
    const {
      phone,
      callStatus,
      totalCallDuration,
      timeCreated,
      empCode,
      disposition,
      callRecording,
      campaign,
    } = callDetail;
    return (
      <Grid container style={styles.callTicket}>
        <Grid item xs={4}>
          <div>{phone}</div>
          <div>Status: {callStatus}</div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <div />
          <div>Duration: {totalCallDuration}</div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <div>{moment(timeCreated).format('lll')}</div>
          <div>{empCode}</div>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={4}>Disposition: {disposition}</Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>Campaign: {campaign}</Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}><a href={callRecording}>View Call recording</a></Grid>
        </Grid>
      </Grid>
    );
  }
}

CallTicekt.propTypes = {
  callDetail: PropTypes.instanceOf(Object),
};

export default CallTicekt;
