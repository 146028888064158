import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEmpty, toUpper, debounce } from 'lodash';
import SearchBar from './SearchBar';
import * as actions from '../../actions/csActions';
import NavBar from '../../components/NavBar';
import CustomerInfoCard from './CustomerInfoCard';
import DetailPanel from './DetailPanel';
import CustomerList from './CustomerList';
import OrderDetails from './OrderDetails';
import AllOrders from './AllOrders';
import EditAddress from './EditAddress';
import EditProfile from './EditProfile';
import TrackOrder from './TrackOrder';
import UserTransactions from './UserTransactions';
import LocalitySearch from './modals/LocalitySearch';
import SubLocalitySearch from './modals/SubLocalitySearch';
import BuildingSearch from './modals/BuildingSearch';
import RouteSearch from './modals/RouteSearch';
import ProductSearch from './modals/ProductSearch';
import Plans from './Plans';
import styles from './styles';
import { Drawer, Button, Paper } from '@material-ui/core';
import RefundForm from './FaultyProductRefundForm/RefundForm';
import apiCall, {
  getRefundStatus,
  customerProfileByFilters,
  getAccountStatusByCustomerId,
  checkAutoPauseStatus,
  getAppEnums,
  revertRefundByRefundId,
  getOrderLineItemDeliveryLogs,
  getHistory,
  getOfferDetailsByLineItemId
} from '../../api/NetworkHandler';
import ChangeMobileForm from './ChangeMobileForm/ChangeMobileForm';
import Dialog from '../../components/Core/Dialog/Dialog';
import ProgressBar from '../../components/States/ProgressBar';
import { Collapse } from 'antd';
import Lozenge from '@atlaskit/lozenge';
import DeletedPlans from './Plans/DeletedPlans';
import utils from '../../utils/queryBuilder';
import LineItemSummary from './OrderInfo/LineItemSummary';
import HistoryContainer from '../History/containers/HistoryContainer';
import moment from 'moment';
import Toast from '../../utils/Toast';

const canView = utils.isCurrentUserAuthorizedToView;

const { Panel } = Collapse;

const DT_ACTION_CONST = {
  editAddress: EditAddress,
  editProfile: EditProfile,
  transactions: UserTransactions,
  plans: Plans,
  editOrder: OrderDetails,
  trackOrder: TrackOrder,
};


const DT_ACTION_MODAL_CONST = {
  productSearch: ProductSearch,
  buildingSearch: BuildingSearch,
  localitySearch: LocalitySearch,
  subLocalitySearch: SubLocalitySearch,
  routeSearch: RouteSearch,
  deletedPlans: DeletedPlans,
};

var versionDict = {
  "profileRequestId": 0,
  "accountStatusRequestId": 0,
  "enumsRequestId": 0
};

class CsAssist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchPage: true,
      detailHasComponent: false,
      detailHasModalComponent: false,
      dtComponent: null,
      dtModalComponent: null,
      dtModalComponentTarget: null,
      dtModalProps: null,
      paramType: null,
      paramId: null,
      openFaultyRefundForm: false,
      refundingItem: {},
      refundStatusMessage: '',
      openRefundStatusDialog: false,
      openChangeMobileForm: false,
      openChangeEmailForm: false,
      openDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      customerProfiles: [],
      query: '',
      loading: false,
      accountStatus: {},
      isSearchItemClicked: false,
      customerOrderComplaintReasons: {},
      customerProfileStatuses: {},
      isRefundMoreVisible: false,
      leftButtonText: '',
      isRefundMoreDisable: false,
      openLineItemSummary: false,
      lineItemData: {},
      refundData: [],
      deliveryData: {},
      openOrderHistory: false,
      selectedOrderId: null,
      currentOfferData: {},
    };
  }

  componentDidMount() {
    if (!canView(["CSR"])) {
      Toast.error('You are not allowed to access this page!');
      this.props.history.goBack();
    } else {
      this.updateComponentState();
      this.fetchAppEnums();
    }
  }

  fetchAppEnums = async () => {
    try {
      versionDict.enumsRequestId += 1;
      let prevRequestId = versionDict.enumsRequestId;
      const response = await apiCall.sendRequest('get', getAppEnums);
      const { data: { data: { customerOrderComplaintReasons = {}, customerProfileStatuses = {} } = {} } = {} } = response;
      if (prevRequestId == versionDict.enumsRequestId) {
        this.setState({
          customerOrderComplaintReasons,
          customerProfileStatuses,
        });
      }
    } catch (err) {
      this.setState({
        customerOrderComplaintReasons: {},
        customerProfileStatuses: {}
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      const { customerId = null } = nextProps;
      if (customerId) {
        this.setState({
          customerId,
        }, () => this.getAccountStatus(customerId));
      }
    }
  }

  componentDidUpdate() {
    const { type = null, id = null } = this.props.match.params;
    const { paramType, paramId, isSearchPage } = this.state;
    if (!type && !isSearchPage) {
      this.resetState(); // put in hoc
    }
    if (type !== paramType || id !== paramId) {
      this.getData(type, id);
    }
  }

  componentWillUpdate(props, state) {
    if (state && state.query !== this.state.query) {
      this.setState({
        isSearchItemClicked: false,
      });
    }
  }

  getAccountStatus = async (customerId) => {
    try {
      versionDict.accountStatusRequestId += 1;
      let prevRequestId = versionDict.accountStatusRequestId;
      const response = await apiCall.sendRequest('get', getAccountStatusByCustomerId(customerId));
      const { data: { data = {} } = {} } = response;
      if (prevRequestId == versionDict.accountStatusRequestId) {
        this.setState({
          accountStatus: data,
        });
      }
    } catch (err) {
      this.setState({
        accountStatus: {}
      });
    }
  }

  onDTAction = (name) => {
    const { customerId, selectedAddress = {}, customerInfo: { email, mobile } } = this.props;
    if (name == "changeMobile") {
      if (!email) {
        this.setState({
          openDialog: true,
          dialogMessage: 'Email needs to be updated from app to continue.',
          dialogTitle: 'Email Unavailable'
        })
      } else {
        this.setState({
          openChangeMobileForm: true,
        })
      }
      return;
    }
    if (name == "changeEmail") {
      if (!mobile) {
        this.setState({
          openDialog: true,
          dialogMessage: 'Mobile number needs to be updated from app to continue.',
          dialogTitle: 'Mobile Unavailable'
        })
      } else {
        this.setState({
          openChangeEmailForm: true,
        })
      }
      return;
    }
    if (name === 'transactions') {
      this.props.getTransactionDetails(customerId);
    }
    if (name === 'plans') {
      this.props.getUpcomingOrder(customerId, selectedAddress.id);
      this.props.getPlanDetails(customerId, selectedAddress.id);
    }
    this.setState({
      detailHasComponent: true,
      dtComponent: DT_ACTION_CONST[name],
    });
  };

  onDTModalOpen = (name, from, props) => {
    this.setState({
      detailHasModalComponent: true,
      dtModalComponent: DT_ACTION_MODAL_CONST[name],
      dtModalComponentTarget: from,
      dtModalProps: props,
    });
  };

  onChangeUpcomingDate = (date) => {
    const { getUpcomingOrder, customerId, selectedAddress } = this.props;
    getUpcomingOrder(customerId, selectedAddress.id, date);
  };

  getData = (type, id) => {
    const { getCustomerInfo, getCustomerOrders, getCustomerAddresses } = this.props;
    this.setState({
      isSearchPage: false,
      paramId: id,
      paramType: type,
      detailHasComponent: false,
      detailHasModalComponent: false,
      dtComponent: null,
      dtModalComponent: null,
      dtModalComponentTarget: null,
    });
    if (type === 'customer') {
      getCustomerInfo(id);
      getCustomerOrders(id);
      getCustomerAddresses(id);
    }
  };

  resetState = () => {
    this.setState({
      isSearchPage: true,
      detailHasComponent: false,
      detailHasModalComponent: false,
      dtComponent: null,
      dtModalComponent: null,
      dtModalComponentTarget: null,
      paramType: null,
      paramId: null,
    });
  };

  searchItemClicked = () => {
    this.unMountFromDetail();
    // this.props.quickSearchItemSelect();
    this.handleCustomerProfileSearchItemClicked();
  };

  updateComponentState = () => {
    const { type = null, id = null } = this.props.match.params;
    if (type && id) {
      this.getData(type, id);
    }
  };

  editOrder = (order) => {
    this.onDTAction('editOrder');
    this.props.dtEditOrder(order);
  };

  showMoreOrders = async () => {
    const { currentOrdersPage, customerId, getCustomerOrders } = this.props;
    const newPage = currentOrdersPage + 1;
    getCustomerOrders(customerId, newPage);
  };

  unMountFromDetail = () => {
    this.setState({
      detailHasComponent: false,
      dtComponent: null,
    });
  };

  unMountModal = () => {
    this.setState({
      detailHasModalComponent: false,
      dtModalComponent: null,
      dtModalComponentTarget: null,
    });
  };

  addNewItemFromModal = (obj) => {
    const { dtModalComponentTarget } = this.state;
    if (dtModalComponentTarget === 'order') {
      const {
        name, id, sku, sellingPrice, unitMeasure,
      } = obj;
      this.props.addNewOrderLineItem({
        productName: name,
        productItemId: id,
        status: '',
        sku,
        sellingPrice,
        unitMeasure,
        quantity: 0,
        newItem: true,
      });
    }
    if (dtModalComponentTarget === 'upcoming') {
      const {
        name, id, sellingPrice, offerPrice, unitMeasure, uom: { code: unitCode },
      } = obj;
      this.props.addNewUpcomingItem({
        productItemId: id,
        quantity: 1,
        newItem: true,
        shouldUpdate: true,
        product: {
          name,
          productItems: [
            {
              sellingPrice: offerPrice ? offerPrice : sellingPrice,
              unitMeasure,
              uom: { code: toUpper(unitCode) }
            },
          ],
        },
      });
    }
    if (dtModalComponentTarget === 'schedule') {
      const {
        name, id, categoryId
      } = obj;
      this.props.addNewScheduledItem({
        id,
        name,
        categoryId,
      });
    }
    if (dtModalComponentTarget === 'address') {
      const { locality, route, building, subLocality } = obj;
      const { newAddress, selectedAddress } = this.props;
      if (!isEmpty(locality)) {
        const { name: localityName, pincode, city: cityName } = locality;
        if (Object.keys(newAddress).length > 0) {
          this.props.addressEdit({ localityName, pincode, cityName }, true);
        } else {
          this.props.addressEdit({ ...selectedAddress, localityName, pincode, cityName }, false);
        }
      }
      if (!isEmpty(subLocality)) {
        const { name: sublocalityName, pincode, city: cityName, locality: localityName } = subLocality;
        if (Object.keys(newAddress).length > 0) {
          this.props.addressEdit({ sublocalityName, localityName, pincode, cityName }, true);
        } else {
          this.props.addressEdit({ ...selectedAddress, sublocalityName, localityName, pincode, cityName }, false);
        }
      }
      // if (!isEmpty(building)) {
      //   const { name: buildingName, city: cityName } = building;
      //   if (Object.keys(newAddress).length > 0) {
      //     this.props.addressEdit({buildingName, cityName}, true);
      //   } else {
      //     this.props.addressEdit({...selectedAddress, buildingName, cityName}, false);
      //   }
      // }

      if (!isEmpty(route)) {
        const { selectedAddress, customerId } = this.props;
        this.props.setRoute(route.id, selectedAddress.id, customerId);
      }
    }
    this.setState({
      detailHasModalComponent: false,
      dtModalComponent: null,
      dtModalComponentTarget: null,
    });
  };

  updateOrderDetails = (obj) => {
    this.props.updateOrderDetails(obj);
    this.setState({
      detailHasComponent: false,
      dtComponent: null,
    });
  };

  trackOngoingOrder = (obj) => {
    this.onDTAction('trackOrder');
    this.props.getOngoingRouteOrders(obj);
  };

  initiateRevertRefund = debounce(async (selectedRefund, selectedItem) => {
    const { customerId, refundId } = selectedRefund;
    try {
      await apiCall.sendRequest('post', revertRefundByRefundId(customerId, refundId));
      this.openFaultyRefundForm(selectedItem);
      this.getData('customer', customerId);
    } catch (err) {
      console.error(err.response);
    }
  }, 500);

  getHeaderTitle = (refundId, revertId) => {
    if (revertId) {
      const tag = <Lozenge appearance='removed'>Reverted</Lozenge>;
      return <div style={{ display: 'flex', flexDirection: 'row' }}>Refund ID #{refundId} {tag}</div>;
    } else {
      return `Refund ID #${refundId}`;
    }
  };

  prepareRefundMessage = (refundData, selectedItem) => {
    return (
      <div>
        <Collapse>
          {refundData.map(el => {
            return (
              <Panel header={this.getHeaderTitle(el.refundId, el.revertId)} key={el.refundId}>
                <div style={{ float: "left" }}>
                  <span style={{ fontWeight: 'bold' }}>Issued By: </span>{el.issuedBy}<br />
                  <span style={{ fontWeight: 'bold' }}>Customer Id: </span>{el.customerId}<br />
                  <span style={{ fontWeight: 'bold' }}>Order Id: </span>{el.orderId}<br />
                  <span style={{ fontWeight: 'bold' }}>Product Item Id: </span>{el.orderLineItemId}<br />
                  <span style={{ fontWeight: 'bold' }}>Product Name: </span>{el.productName}<br />
                  <span style={{ fontWeight: 'bold' }}>Refund Type: </span>{el.refundType}<br />
                  <span style={{ fontWeight: 'bold' }}>Refund Reason: </span>{el.refundReason}<br />
                  <span style={{ fontWeight: 'bold' }}>Refund Quantity: </span>{el.refundQuantity}<br />
                  {el.productMRP && <div><span style={{ fontWeight: 'bold' }}>Product MRP: </span>{el.productMRP.toFixed(2)}<br /></div>}
                  {el.selectedProductId && <div><span style={{ fontWeight: 'bold' }}>Substituted Product Id: </span>{el.selectedProductId}<br /></div>}
                  {el.selectedProductName && <div><span style={{ fontWeight: 'bold' }}>Substituted Product Name: </span>{el.selectedProductName}<br /></div>}
                  <span style={{ fontWeight: 'bold' }}>Refund Amount: </span>{el.amount.toFixed(2)}<br />
                  <span style={{ fontWeight: 'bold' }}>Remarks: </span>{el.remark}<br />
                  <span style={{ fontWeight: 'bold' }}>Defect Item Image URL: <a href={el.defectItemImageUrl}>Click to open</a></span><br />
                  <span style={{ fontWeight: 'bold' }}>Complaint Reason: </span>{el.complaintReason || ''}<br />
                  <span style={{ fontWeight: 'bold' }}>CS Role: </span>{el.csrType || ''}<br />
                </div>
                <Button onClick={() => this.initiateRevertRefund(el, selectedItem)} color="secondary" variant="outlined" style={{ float: "right" }} disabled={el.revertId || !canView(['CC_MANAGER', 'CSR_ADMIN'])}>Revert</Button>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  };

  openFaultyRefundForm = async (orderItem) => {
    const { customerId, orderId, id } = orderItem;
    if (orderId === 23970002) {
      this.setState({
        openDialog: true,
        dialogMessage: "Refund is not allowed for this order",
        dialogTitle: 'Refund Status'
      });
      return;
    }
    try {
      const response = await apiCall.sendRequest('get', getRefundStatus(customerId, orderId, id));
      const { data: { data: { refundData = [], canRefundMore = false } = {}, message } = {} } = response;

      if (canRefundMore && refundData.length == 0) {
        this.setState({
          openFaultyRefundForm: true,
          refundingItem: orderItem,
        });
      } else {
        const uiMessage = refundData.length > 0 ? this.prepareRefundMessage(refundData, orderItem) : message;
        this.setState({
          openDialog: true,
          dialogMessage: uiMessage,
          dialogTitle: 'Refund Status',
          leftButtonText: 'Refund More',
          isRefundMoreVisible: refundData.length > 0 ? true : false,
          isRefundMoreDisable: !canRefundMore,
          refundStatusMessage: canRefundMore ? '' : message,
          refundingItem: orderItem,
        });
      }
    } catch (error) {
      this.setState({
        openDialog: true,
        dialogMessage: 'Unable to process refund.',
        dialogTitle: 'Refund'
      });
    }
  }

  closeFalutyRefundForm = (buttonClicked) => {
    this.setState({
      openFaultyRefundForm: false,
      refundingItem: {},
    });
    if (buttonClicked) {
      const { customerId } = this.props;
      this.getData('customer', customerId);
    }
  }

  closeChangeMoblieForm = (refresh) => {
    if (refresh) {
      const { customerId } = this.props;
      this.getData('customer', customerId);
      this.setState({
        openDialog: true,
        dialogMessage: 'Ask customer to logout and re-login into app to get updated phone number in app.',
        dialogTitle: 'Relogin required'
      })
    }
    this.setState({
      openChangeMobileForm: false
    })
  }
  closeChangeEmailForm = (refresh) => {
    if (refresh) {
      const { customerId } = this.props;
      this.getData('customer', customerId);
      this.setState({
        openDialog: true,
        dialogMessage: 'Ask customer to logout and re-login into app to get updated email in app.',
        dialogTitle: 'Relogin required'
      })
    }
    this.setState({
      openChangeEmailForm: false
    })
  }

  onCloseDialog = () => {
    this.setState({
      openDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      leftButtonText: '',
      isRefundMoreVisible: false,
      isRefundMoreDisable: false,
      refundStatusMessage: '',
    })
  }

  toggleProgressBar = (state) => {
    this.setState({
      loading: state,
    });
  }

  handleCustomerProfileSearch = debounce(async (query) => {
    if (!isEmpty(query)) {
      this.toggleProgressBar(true);
      this.handleCloseDetailRequest();
      try {
        versionDict.profileRequestId += 1;
        let prevRequestId = versionDict.profileRequestId;
        const response = await apiCall.sendRequest('get', customerProfileByFilters(query, true));
        const { data: { data = [] } } = response;
        if (versionDict.profileRequestId == prevRequestId) {
          this.setState({
            customerProfiles: data,
            query,
          });
        }
        this.toggleProgressBar(false);
      } catch (err) {
        this.setState({
          customerProfiles: [],
        });
        this.toggleProgressBar(false);
      }
    } else {
      this.setState({
        customerProfiles: [],
        query: '',
        isSearchPage: false,
      });
      this.toggleProgressBar(false);
    }
  }, 750);

  handleCustomerProfileSearchItemClicked = () => {
    this.setState({
      customerProfiles: [],
      query: '',
      isSearchItemClicked: true,
    });
  }

  handleCheckAutoPauseStatus = async () => {
    const { customerId, getCustomerInfo } = this.props;
    try {
      await apiCall.sendRequest('post', checkAutoPauseStatus, [customerId]);
      getCustomerInfo(customerId);
    } catch (err) {
      getCustomerInfo(customerId);
    }
  };

  showDeletedSchedules = () => {
    return true;
  }

  handleClickRefundMore = () => {
    this.setState({
      openFaultyRefundForm: true,
      openDialog: false
    });
  };

  getOrderLineItemDeliveryLogsByLineItemId = async (lineItemId) => {
    try {
      const response = await apiCall.sendRequest('get', getOrderLineItemDeliveryLogs(lineItemId));
      const { data: { data = {} } = {} } = response;
      this.setState({
        deliveryData: data
      });
    } catch (err) {
      this.setState({
        deliveryData: {}
      });
    }
  };

  getRefundStatus = async (lineItemData, orderId) => {
    const { customerId } = this.props;
    const { id } = lineItemData;
    try {
      const response = await apiCall.sendRequest('get', getRefundStatus(customerId, orderId, id));
      const { data: { data: { refundData = [] } = {} } = {} } = response;
      this.setState({
        refundData,
      });
    } catch (err) {
      this.setState({
        refundData: []
      });
    }
  };

  handleRequestDetail = (data, orderId) => {
    this.setState({
      openLineItemSummary: true,
      lineItemData: data,
      refundData: [],
      deliveryData: {},
      selectedOrderId: orderId
    }, () => {
      if (data.status === 'DELIVERED') {
        this.getRefundStatus(data, orderId);
        this.getOrderLineItemDeliveryLogsByLineItemId(data.id);
      }
    });
  };

  handleCloseDetailRequest = () => {
    this.setState({
      openLineItemSummary: false,
      lineItemData: {}
    });
  };

  handleOrderHistoryClick = async (orderId) => {
    //TODO: need to implement this later
    return;
    console.log(`${orderId} - Order History Clicked`);
    try {
      const postData = {
        methodName: "CustomerOrderHistory",
        params: JSON.stringify({
          entityId: orderId,
          limit: 10,
          page: 0
        })
      };

      const response = await apiCall.sendRequest('post', getHistory, postData);
      console.log(response);
    } catch (err) {

    }
  };

  toggleOrderHistory = (state) => {
    this.setState({
      openOrderHistory: state
    });
  };

  getOfferData = async (lineItemId) => {
    try {
      const response = await apiCall.sendRequest('get', getOfferDetailsByLineItemId(lineItemId));
      const { data: { data = {} } = {} } = response;
      this.handleOpenOfferDialog(data);
    } catch (err) {

    }
  };

  renderOfferData = (offerData) => {
    return (
      <Paper elevation={2} style={{ padding: "10px", textAlign: "center" }}>
        <div style={{ display: "flex" }}><b>Title: </b><p style={{ marginBottom: 0, color: "black", fontSize: "14px", paddingLeft: "5px" }}>{offerData.title || 'NA'}</p></div>
        <div style={{ display: "flex" }}><b>Description: </b><p style={{ marginBottom: 0, color: "black", fontSize: "14px", paddingLeft: "5px" }}>{offerData.description || 'NA'}</p></div>
        <div style={{ display: "flex" }}><b>Offer Type: </b><p style={{ marginBottom: 0, color: "black", fontSize: "14px", paddingLeft: "5px" }}>{offerData.offerType || 'NA'}</p></div>
        <div style={{ display: "flex" }}><b>Start Time: </b><p style={{ marginBottom: 0, color: "black", fontSize: "14px", paddingLeft: "5px" }}>{moment(offerData.startTime).format('lll')}</p></div>
        <div style={{ display: "flex" }}><b>End Time: </b><p style={{ marginBottom: 0, color: "black", fontSize: "14px", paddingLeft: "5px" }}>{moment(offerData.endTime).format('lll')}</p></div>
      </Paper>
    );
  };

  handleOpenOfferDialog = (offerData) => {
    const offerMessage = this.renderOfferData(offerData);
    this.setState({
      openDialog: true,
      dialogTitle: "Offer Data",
      dialogMessage: offerMessage
    });
  };

  render() {
    const {
      history,
      q,
      csList,
      noCsList,
      customerId,
      customerInfo,
      profileEdit,
      editableCustomerInfo,
      updateProfileChange,
      customerInfoFailed,
      allOrders,
      upComingOrder,
      upComingOrderDate,
      updateUpcomingDetails,
      changeUpcomingDetails,
      getUpcomingOrder,
      customerPlans,
      customerPlanItems,
      newScheduledItem,
      ongoingOrder,
      customerHasNoOrders,
      hasMoreOrders,
      customerAddresses,
      selectedAddress,
      defaultAddress,
      newAddress,
      updateAddressChange,
      setDefaultAddress,
      transactionDetails,
      initiatedTransactions,
      refundTxns,
      walletBalance,
      totalWalletBalance,
      refundAmount,
      maxRefundAmount,
      handleRefundAmount,
      processRefund,
      selectedOrder,
      orderId,
      orderStatus,
      selectedOrderLineItems,
      orderDetailsChange,
      changeSelectedAddress,
      addressEdit,
      currentDeliveryRouteInfo,
      currentDeliveryRouteList,
      addressSatusList,
      coStatusList,
      coLineStatusList,
      deliveryRemarks,
      complaintReasons,
      genderTypes,
      inFullScreen,
      quickSearch,
      clearQuickSearch,
      editPlannedItem,
      deletePlannedItem,
      onCreateCustomPlan,
      addPlannedItem,
      cancelNewScheduledItem,
      currency,
    } = this.props;
    const {
      isSearchPage,
      detailHasComponent,
      dtComponent,
      detailHasModalComponent,
      dtModalComponent,
      dtModalProps,
      openFaultyRefundForm,
      refundingItem,
      openChangeMobileForm,
      openChangeEmailForm,
      openDialog,
      dialogTitle,
      dialogMessage,
      customerProfiles,
      query,
      loading,
      accountStatus,
      isSearchItemClicked,
      customerOrderComplaintReasons,
      customerProfileStatuses,
      leftButtonText,
      isRefundMoreVisible,
      isRefundMoreDisable,
      refundStatusMessage,
      openLineItemSummary,
      lineItemData,
      refundData,
      deliveryData,
      openOrderHistory,
      selectedOrderId,
    } = this.state;

    return (
      <div style={styles.csAssitBox}>
        {!inFullScreen && <NavBar />}
        <ProgressBar isLoading={loading} color="primary" />
        {!inFullScreen && (
          <SearchBar
            history={history}
            customerId={customerId}
            quickSearch={this.handleCustomerProfileSearch}
            onPressEnter={this.handleCloseDetailRequest}
            query={query}
            path="cs-assist"
            key={isSearchItemClicked && Date.now()}
          />
        )}
        {!isEmpty(customerProfiles) && (
          <div style={styles.autoCompleteBox}>
            <CustomerList data={customerProfiles} query={query} searchItemClicked={this.searchItemClicked} path="cs-assist" />
          </div>
        )}
        {!isEmpty(query) && isEmpty(customerProfiles) && (
          <div style={styles.emptyPage}>No customers found with the search term.</div>
        )}
        {isSearchPage && isEmpty(query) && (
          <div style={styles.emptyPage}>
            Please Search for Customer Mobile Number, Name, or Email to start.
          </div>
        )}
        {!isSearchPage && !customerInfoFailed && (
          <div style={{ ...styles.contentBox, display: !isEmpty(query) ? 'none' : 'block' }}>
            <CustomerInfoCard
              info={customerInfo}
              customerAddresses={customerAddresses}
              onClickHandler={this.onDTAction}
              openFaultyRefundForm={this.openFaultyRefundForm}
              showExtras
              accountStatus={accountStatus}
              onAutoPauseCheck={this.handleCheckAutoPauseStatus}
              key={customerInfo.customerId}
            />
            <DetailPanel unMountFromDetail={this.unMountFromDetail} showCross={detailHasComponent}>
              {detailHasComponent && (
                <div style={{ display: detailHasModalComponent ? 'none' : 'block' }}>
                  {React.createElement(dtComponent, {
                    customerId,
                    customerInfo,
                    editableCustomerInfo,
                    onProfileEdit: profileEdit,
                    updateProfileChange,
                    orderId,
                    order: selectedOrder,
                    orderStatus,
                    orderLineItems: selectedOrderLineItems,
                    onOrderDetailsChange: orderDetailsChange,
                    updateOrderDetails: this.updateOrderDetails,
                    customerAddresses,
                    selectedAddress,
                    defaultAddress,
                    newAddress,
                    setDefaultAddress,
                    changeSelectedAddress,
                    onAddressEdit: addressEdit,
                    updateAddressChange,
                    transactionDetails,
                    initiatedTransactions,
                    refundTxns,
                    walletBalance,
                    totalWalletBalance,
                    refundAmount,
                    maxRefundAmount,
                    handleRefundAmount,
                    processRefund,
                    upComingOrder,
                    upComingOrderDate,
                    onChangeUpcomingDate: this.onChangeUpcomingDate,
                    onUpcomingDetailsChange: changeUpcomingDetails,
                    updateUpcomingItems: updateUpcomingDetails,
                    undoUpcomingItems: getUpcomingOrder,
                    customerPlans,
                    customerPlanItems,
                    newScheduledItem,
                    cancelDTAction: this.unMountFromDetail,
                    openModal: this.onDTModalOpen,
                    ongoingOrder,
                    currentDeliveryRouteInfo,
                    currentDeliveryRouteList,
                    addressSatusList,
                    coStatusList,
                    coLineStatusList,
                    deliveryRemarks,
                    complaintReasons,
                    genderTypes,
                    editPlannedItem,
                    deletePlannedItem,
                    onCreateCustomPlan,
                    addPlannedItem,
                    cancelNewScheduledItem,
                    customerProfileStatuses,
                    currency,
                    // showDeletedSchedules:this.showDeletedSchedules
                  })}
                </div>
              )}
              {detailHasComponent &&
                detailHasModalComponent &&
                React.createElement(dtModalComponent, {
                  onClose: this.unMountModal,
                  onSelectLineItem: this.addNewItemFromModal,
                  defaultAddress: defaultAddress,
                  selectedAddress: selectedAddress,
                  ...dtModalProps,
                })}
              {!detailHasComponent && !customerHasNoOrders && (
                <div>
                  {openLineItemSummary &&
                    <LineItemSummary
                      data={lineItemData}
                      open={openLineItemSummary}
                      currency={currency}
                      refundData={refundData}
                      openRefundForm={this.openFaultyRefundForm}
                      deliveryData={deliveryData}
                      onClose={this.handleCloseDetailRequest}
                      selectedOrderId={selectedOrderId}
                      onOpenOfferData={this.getOfferData}
                    />
                  }
                  <AllOrders
                    orders={allOrders}
                    hasMoreOrders={hasMoreOrders}
                    showMoreOrders={this.showMoreOrders}
                    editOrder={this.editOrder}
                    trackOngoingOrder={this.trackOngoingOrder}
                    ongoingOrder={ongoingOrder}
                    openRefundForm={this.openFaultyRefundForm}
                    currency={currency}
                    onDetailRequest={this.handleRequestDetail}
                    isDetailedViewOpen={openLineItemSummary}
                    onOrderHistoryClick={this.handleOrderHistoryClick}
                    selectedLineItemData={lineItemData}
                    selectedOrderId={selectedOrderId}
                  />
                </div>
              )}
              {!detailHasComponent && customerHasNoOrders && (
                <div style={styles.emptyPage}>Customer is yet to place an order.</div>
              )}
            </DetailPanel>
          </div>
        )}
        {customerInfoFailed && <div style={styles.emptyPage}>No Customer records found!</div>}
        <Drawer open={openFaultyRefundForm} anchor="right" onClose={this.closeFalutyRefundForm}>
          <RefundForm
            orderItem={refundingItem}
            onClose={this.closeFalutyRefundForm}
            customerDefaultAddress={defaultAddress}
            customerOrderComplaintReasons={customerOrderComplaintReasons}
          />
        </Drawer>
        <Drawer open={openChangeMobileForm} anchor="right" onClose={() => { this.closeChangeMoblieForm(null) }}>
          <ChangeMobileForm
            onClose={this.closeChangeMoblieForm}
            customerId={customerId}
            email={customerInfo.email}
          />
        </Drawer>
        <Drawer open={openChangeEmailForm} anchor="right" onClose={() => { this.closeChangeEmailForm(null) }}>
          <ChangeMobileForm
            onClose={this.closeChangeEmailForm}
            customerId={customerId}
            mobile={customerInfo.mobile}
            customerInfo={customerInfo}
          />
        </Drawer>
        <Dialog
          open={openDialog}
          onClose={this.onCloseDialog}
          dialogTitle={dialogTitle}
          dialogMessage={dialogMessage}
          onLeftButtonClick={this.handleClickRefundMore}
          leftButtonVisible={isRefundMoreVisible}
          leftButtonDisable={isRefundMoreDisable}
          leftButtonText={leftButtonText}
          extraMessage={refundStatusMessage}
        />
        <HistoryContainer
          openHistory={openOrderHistory}
          handleClose={() => this.toggleOrderHistory(false)}
          data={[]}
          title='Customer Order History'
          count={1}
          parent="customerOrderHistory"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { csReducer, Status, currency } = state;
  const {
    addressStatuses,
    costatuses,
    colineStatuses,
    deliveryRemarks,
    complaintReasons,
    genderTypes,
  } = Status;
  const {
    q,
    csList,
    noCsList,
    customerId,
    customerInfo,
    editableCustomerInfo,
    customerInfoFailed,
    allOrders,
    ongoingOrder,
    upComingOrder,
    upComingOrderDate,
    customerPlans,
    customerPlanItems,
    newScheduledItem,
    customerHasNoOrders,
    currentOrdersPage,
    hasMoreOrders,
    customerAddresses,
    selectedAddress,
    defaultAddress,
    newAddress,
    selectedOrder,
    orderId,
    orderStatus,
    selectedOrderLineItems,
    transactionDetails,
    initiatedTransactions,
    refundTxns,
    walletBalance,
    totalWalletBalance,
    refundAmount,
    maxRefundAmount,
    currentDeliveryRouteInfo,
    currentDeliveryRouteList,
  } = csReducer;
  return {
    q,
    csList,
    noCsList,
    customerId,
    customerInfo,
    editableCustomerInfo,
    customerInfoFailed,
    allOrders,
    ongoingOrder,
    upComingOrder,
    upComingOrderDate,
    customerPlans,
    customerPlanItems,
    newScheduledItem,
    customerHasNoOrders,
    currentOrdersPage,
    hasMoreOrders,
    customerAddresses,
    selectedAddress,
    defaultAddress,
    newAddress,
    orderId,
    orderStatus,
    selectedOrder,
    selectedOrderLineItems,
    transactionDetails,
    initiatedTransactions,
    refundTxns,
    walletBalance,
    totalWalletBalance,
    refundAmount,
    maxRefundAmount,
    currentDeliveryRouteInfo,
    currentDeliveryRouteList,
    addressSatusList: addressStatuses,
    coStatusList: costatuses,
    coLineStatusList: colineStatuses,
    deliveryRemarks,
    complaintReasons,
    genderTypes,
    currency,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    quickSearch,
    clearQuickSearch,
    quickSearchItemSelect,
    getCustomerInfo,
    profileEdit,
    updateProfileChange,
    getCustomerOrders,
    getCustomerAddresses,
    changeSelectedAddress,
    setAddressDefault,
    dtEditOrder,
    orderDetailsChange,
    addNewOrderLineItem,
    updateOrderDetails,
    addressEdit,
    updateAddressChange,
    getUpcomingOrder,
    updateUpcomingDetails,
    changeUpcomingDetails,
    addNewUpcomingItem,
    getTransactionDetails,
    getOngoingRouteOrders,
    getPlanDetails,
    handleRefundAmount,
    processRefund,
    setRoute,
    editPlannedItem,
    deletePlannedItem,
    onCreateCustomPlan,
    addNewScheduledItem,
    addPlannedItem,
    cancelNewScheduledItem,
  } = actions;
  return {
    getCustomerInfo: bindActionCreators(getCustomerInfo, dispatch),
    profileEdit: bindActionCreators(profileEdit, dispatch),
    updateProfileChange: bindActionCreators(updateProfileChange, dispatch),
    getCustomerOrders: bindActionCreators(getCustomerOrders, dispatch),
    getCustomerAddresses: bindActionCreators(getCustomerAddresses, dispatch),
    changeSelectedAddress: bindActionCreators(changeSelectedAddress, dispatch),
    addressEdit: bindActionCreators(addressEdit, dispatch),
    updateAddressChange: bindActionCreators(updateAddressChange, dispatch),
    setDefaultAddress: bindActionCreators(setAddressDefault, dispatch),
    dtEditOrder: bindActionCreators(dtEditOrder, dispatch),
    orderDetailsChange: bindActionCreators(orderDetailsChange, dispatch),
    addNewOrderLineItem: bindActionCreators(addNewOrderLineItem, dispatch),
    updateOrderDetails: bindActionCreators(updateOrderDetails, dispatch),
    quickSearch: bindActionCreators(quickSearch, dispatch),
    clearQuickSearch: bindActionCreators(clearQuickSearch, dispatch),
    quickSearchItemSelect: bindActionCreators(quickSearchItemSelect, dispatch),
    getUpcomingOrder: bindActionCreators(getUpcomingOrder, dispatch),
    addNewUpcomingItem: bindActionCreators(addNewUpcomingItem, dispatch),
    getTransactionDetails: bindActionCreators(getTransactionDetails, dispatch),
    getOngoingRouteOrders: bindActionCreators(getOngoingRouteOrders, dispatch),
    getPlanDetails: bindActionCreators(getPlanDetails, dispatch),
    handleRefundAmount: bindActionCreators(handleRefundAmount, dispatch),
    processRefund: bindActionCreators(processRefund, dispatch),
    updateUpcomingDetails: bindActionCreators(updateUpcomingDetails, dispatch),
    changeUpcomingDetails: bindActionCreators(changeUpcomingDetails, dispatch),
    setRoute: bindActionCreators(setRoute, dispatch),
    editPlannedItem: bindActionCreators(editPlannedItem, dispatch),
    deletePlannedItem: bindActionCreators(deletePlannedItem, dispatch),
    onCreateCustomPlan: bindActionCreators(onCreateCustomPlan, dispatch),
    addNewScheduledItem: bindActionCreators(addNewScheduledItem, dispatch),
    addPlannedItem: bindActionCreators(addPlannedItem, dispatch),
    cancelNewScheduledItem: bindActionCreators(cancelNewScheduledItem, dispatch),
  };
};

CsAssist.propTypes = {
  q: PropTypes.string,
  csList: PropTypes.instanceOf(Array),
  noCsList: PropTypes.bool,
  customerId: PropTypes.number,
  customerInfo: PropTypes.instanceOf(Object),
  profileEdit: PropTypes.func,
  editableCustomerInfo: PropTypes.instanceOf(Object),
  customerInfoFailed: PropTypes.bool,
  allOrders: PropTypes.instanceOf(Array),
  upComingOrder: PropTypes.instanceOf(Array),
  customerPlans: PropTypes.instanceOf(Array),
  ongoingOrder: PropTypes.instanceOf(Object),
  customerHasNoOrders: PropTypes.bool,
  hasMoreOrders: PropTypes.bool,
  customerAddresses: PropTypes.instanceOf(Array),
  selectedAddress: PropTypes.instanceOf(Object),
  defaultAddress: PropTypes.instanceOf(Object),
  newAddress: PropTypes.instanceOf(Object),
  setDefaultAddress: PropTypes.func,
  transactionDetails: PropTypes.instanceOf(Array),
  initiatedTransactions: PropTypes.instanceOf(Array),
  refundTxns: PropTypes.bool,
  walletBalance: PropTypes.number,
  totalWalletBalance: PropTypes.instanceOf(Object),
  refundAmount: PropTypes.number,
  selectedOrder: PropTypes.instanceOf(Object),
  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
  selectedOrderLineItems: PropTypes.instanceOf(Array),
  orderDetailsChange: PropTypes.func,
  changeSelectedAddress: PropTypes.func,
  addressEdit: PropTypes.func,
  currentDeliveryRouteInfo: PropTypes.instanceOf(Object),
  currentDeliveryRouteList: PropTypes.instanceOf(Array),
  getCustomerInfo: PropTypes.func,
  updateProfileChange: PropTypes.func,
  getCustomerOrders: PropTypes.func,
  getCustomerAddresses: PropTypes.func,
  updateAddressChange: PropTypes.func,
  dtEditOrder: PropTypes.func,
  addNewOrderLineItem: PropTypes.func,
  updateOrderDetails: PropTypes.func,
  quickSearch: PropTypes.func,
  clearQuickSearch: PropTypes.func,
  quickSearchItemSelect: PropTypes.func,
  getTransactionDetails: PropTypes.func,
  getOngoingRouteOrders: PropTypes.func,
  getUpcomingOrder: PropTypes.func,
  getPlanDetails: PropTypes.func,
  addressSatusList: PropTypes.instanceOf(Object),
  coStatusList: PropTypes.instanceOf(Object),
  coLineStatusList: PropTypes.instanceOf(Object),
  deliveryRemarks: PropTypes.instanceOf(Object),
  complaintReasons: PropTypes.instanceOf(Object),
  genderTypes: PropTypes.instanceOf(Object),
  inFullScreen: PropTypes.bool,
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  upComingOrderDate: PropTypes.string,
  updateUpcomingDetails: PropTypes.func,
  changeUpcomingDetails: PropTypes.func,
  addNewUpcomingItem: PropTypes.func,
  addNewScheduledItem: PropTypes.func,
  customerPlanItems: PropTypes.instanceOf(Array),
  maxRefundAmount: PropTypes.number,
  handleRefundAmount: PropTypes.func,
  processRefund: PropTypes.func,
  setRoute: PropTypes.func,
  editPlannedItem: PropTypes.func,
  deletePlannedItem: PropTypes.func,
  onCreateCustomPlan: PropTypes.func,
  newScheduledItem: PropTypes.instanceOf(Object),
  addPlannedItem: PropTypes.func,
  cancelNewScheduledItem: PropTypes.func,
  currency: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CsAssist);
