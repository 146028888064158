export default {
  card: {
    borderRadius: '0',
    marginTop: '10px',
    minHeight: 'calc(100vh - 275px)',
    position: 'relative',
  },
  cross: {
    position: 'absolute',
    right: '16px',
    top: '10px',
    fontSize: '2em',
  },
};
