import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Search from '@material-ui/icons/Search';
import { isEmpty } from 'lodash';
import { Modal, Button, Grid, TextField, InputAdornment, MenuItem, Select, InputLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import EntitySelection from '../../Commons/components/EntitySelection';

const styles = {
  modal: {
    width: '70%',
    background: '#FFF',
    margin: '100px auto',
    outline: 'none',
    borderRadius: 4,
    padding: 30,
  },
  title: {
    fontWeight: 400,
    fontSize: '1.5em',
    marginBottom: 10,
  },
  errorMessage: {
    color: 'red',
    marginBottom: 10,
    fontSize: 14,
  },
};


const TicketForm = (props) => {
  const {
    ticketType,
    source,
    onChange,
    openSearch,
    customerOrderId = '',
    customer = {},
    ticketSubType,
    priority,
    agents,
    agentId,
    title,
    ticketPriorities,
    ticketSources,
    typeMapping,
  } = props;
  return (
    <Grid container spacing={16}>
      <Grid item xs md={4}>
        <TextField
          label="Title"
          value={title}
          fullWidth
          name="title"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs md={4}>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Ticket Type</InputLabel>
        <Select
          placeholder="Type"
          fullWidth
          name="ticketType"
          style={{ marginTop: -3 }}
          value={ticketType || 'Select Reason'}
          onChange={onChange}
        >
          {
            Object.keys(typeMapping).map(ttype => (
              <MenuItem key={ttype} value={ttype}>
                {ttype}
              </MenuItem>
            ))
          }
        </Select>
      </Grid>
      <Grid item xs md={4}>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Complaint Reason</InputLabel>
        <Select
          placeholder="Sub Type"
          fullWidth
          name="ticketSubType"
          style={{ marginTop: -3 }}
          value={ticketSubType || 'Select Reason'}
          onChange={onChange}
        >
          {
            ticketType && typeMapping[ticketType].map(subType => (
              <MenuItem key={subType} value={subType}>
                {subType}
              </MenuItem>
            ))
          }
        </Select>
      </Grid>
      <Grid item xs md={4}>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Source</InputLabel>
        <Select
          fullWidth
          name="source"
          style={{ marginTop: -3 }}
          value={source}
          onChange={onChange}
        >
          {
            Object.keys(ticketSources).map(item => (
              <MenuItem key={item} value={item}>
                {ticketSources[item]}
              </MenuItem>
            ))
          }
        </Select>
      </Grid>
      <Grid item xs md={4}>
        <TextField
          label="Customer"
          value={customer.name || ''}
          onClick={() => openSearch('Customer')}
          name="customer"
          disabled
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs md={4}>
        <TextField
          label="Order Number"
          value={customerOrderId}
          name="customerOrderId"
          fullWidth
          onChange={onChange}
        />
      </Grid>
      <Grid item xs md={4}>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Priority</InputLabel>
        <Select
          fullWidth
          name="priority"
          style={{ marginTop: -3 }}
          value={priority}
          onChange={onChange}
        >
          {
            Object.keys(ticketPriorities).map(item => (
              <MenuItem key={item} value={item}>
                {ticketPriorities[item]}
              </MenuItem>
            ))
          }
        </Select>
      </Grid>
      <Grid item xs md={4}>
        <InputLabel style={{ fontSize: '0.7em' }} htmlFor="status-type">Assigned To</InputLabel>
        <Select
          fullWidth
          name="agentId"
          style={{ marginTop: -3 }}
          value={agentId}
          onChange={onChange}
        >
          {
            agents.map(agent => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.name}
              </MenuItem>
            ))
          }
        </Select>
      </Grid>
    </Grid>
  );
};

TicketForm.propTypes = {
  ticketType: PropTypes.string,
  source: PropTypes.string,
  ticketSources: PropTypes.instanceOf(Object),
  typeMapping: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  openSearch: PropTypes.func,
  customerOrderId: PropTypes.number,
  agentId: PropTypes.number,
  customer: PropTypes.instanceOf(Object),
  ticketSubType: PropTypes.string,
  priority: PropTypes.string,
  agents: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  ticketPriorities: PropTypes.instanceOf(Object),
};

class CreateTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityType: '',
      isSearchOpen: false,
      customer: {},
      customerOrder: {},
    };
  }

  openSearch =(entityType) => {
    this.setState({
      entityType,
      isSearchOpen: true,
      errorMessage: null,
    });
  }

  closeSearch = () => {
    this.setState({
      entityType: '',
      isSearchOpen: false,
    });
  }

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({
      customer: {},
      errorMessage: null,
    });
    onClose();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { onChange } = this.props;
    let { ticket } = this.props;
    ticket = {
      ...ticket,
      [name]: value,
    };
    onChange(ticket);
  }

  handleEntitySelection = (entity) => {
    const { entityType } = this.state;
    switch (entityType) {
      case 'Customer':
        this.setState({ customer: entity });
        break;
      case 'CustomerOrder':
        this.setState({ customerOrder: entity });
        break;
      default: break;
    }
    this.closeSearch();
  }

  handleCreate = async () => {
    const { customer = {} } = this.state;
    const { ticket = {}, onSave } = this.props;
    const { customerOrderId = '' } = ticket;
    if (!isEmpty(customer) || customerOrderId !== '') {
      const { customerId, name, mobile } = customer;
      const ticketData = {
        ...ticket,
        customerId,
        customerName: name,
        mobile,
      };
      onSave(ticketData);
    } else {
      this.setState({ errorMessage: '*Please assign a customer' });
    }
  }

  render() {
    const {
      isVisible = false,
      complaintReasons,
      ticket = {},
      agents,
      ticketPriorities,
      ticketSource,
      ticketTypeMapping,
    } = this.props;
    const {
      isSearchOpen,
      customer,
      customerOrder,
      entityType,
      errorMessage,
    } = this.state;
    return (
      <div>
        <Modal
          open={isVisible}
          onClose={this.handleClose}
        >
          <div style={styles.modal}>
            <div style={styles.title}>
              {ticket.id ? 'Edit' : 'Create'}
              {' '}
                Ticket
            </div>
            {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
            <TicketForm
              onChange={this.handleChange}
              openSearch={this.openSearch}
              customer={customer}
              order={customerOrder}
              complaintReasons={complaintReasons}
              agents={agents}
              ticketPriorities={ticketPriorities}
              ticketSources={ticketSource}
              typeMapping = {ticketTypeMapping}
              {...ticket}
            />
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs md={12} style={{ textAlign: 'right' }}>
                <Button variant="contained" color="default" onClick={this.handleClose}>Cancel</Button>
                <Button variant="contained" color="secondary" style={{ marginLeft: 10 }} onClick={this.handleCreate}>Create</Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <EntitySelection
          open={isSearchOpen}
          entityType={entityType}
          handleClose={this.closeSearch}
          onSelect={this.handleEntitySelection}
        />
      </div>
    );
  }
}

CreateTicket.propTypes = {
  isVisible: PropTypes.bool,
  ticket: PropTypes.instanceOf(Object),
  agents: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  complaintReasons: PropTypes.instanceOf(Object),
  ticketPriorities: PropTypes.instanceOf(Object),
  ticketSource: PropTypes.instanceOf(Object),
  ticketTypeMapping: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  const { Status, information } = state;
  const { ticketTypeMapping } = information;
  return {
    complaintReasons: Status.complaintReasons,
    ticketSource: Status.ticketSource,
    ticketPriorities: Status.ticketPriority,
    ticketStatuses: Status.ticketStatus,
    ticketTypeMapping,
  };
};

export default connect(mapStateToProps, null)(CreateTicket);
