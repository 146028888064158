import React from 'react';
import PropTypes from "prop-types";
import SearchAndSelect from '../Core/SearchAndSelect';
import apiCall, { getProductCatalogByFilter } from '../../api/NetworkHandler';

const onLoad = async (searchTerm, params) => {
  const filters = { ...params, query: searchTerm };
  const response = await apiCall.sendRequest('get', getProductCatalogByFilter(filters, 0, 25));
  const { data: { data: { content = [] } = {} } = {} } = response;

  return content.map(catalog => {
    return {
      value: catalog.id,
      label: catalog.name,
      regions: catalog.regions.map(region => ({
        value: region.id,
        label: region.name,
      })),
    }
  })
}

function CatalogSelector(props) {
  const { selected, onSelect, isClearable, warehouseId, regionIds } = props;

  return (
    <SearchAndSelect
      onLoad={onLoad}
      selected={selected}
      onSelect={onSelect}
      isClearable={isClearable}
      params={{ warehouseId, regionIds }}
    />
  )
}

CatalogSelector.propTypes = {
  /**
   * value should be an object {value: String, label: String}
   */
  selected: PropTypes.object,
  /**
   * Triggered on option selection
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Selected item can be cleared or not
   */
  isClearable: PropTypes.bool,
};

CatalogSelector.defaultProps = {
  selected: null,
  isClearable: true,
  regionIds: '',
  warehouseId: ''
};

export default CatalogSelector;
