import React from "react";
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button } from "@material-ui/core";
import Lozenge from '@atlaskit/lozenge';

const columnData = [
  {
    id: 'title', numeric: false, disablePadding: true, label: 'Title',
  },
  {
    id: "order", numeric: false, disablePadding: true, label: 'Sort',
  },
  {
    id: "region", numeric: false, disablePadding: true, label: 'Region',
  },
  {
    id: "deepLink", numeric: false, disablePadding: true, label: 'DeepLink Url',
  },
  {
    id: "status", numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Action',
  }
];


function HomeOfferList(props) {

  const {
    data,
    rowsPerPage,
    page,
    count,
    isNoData,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    onRequestSort,
    onEdit,
    regions,
  } = props;

  const getActions = (el) => (
    <React.Fragment>
      <Button color="primary" variant="outlined" size="small" onClick={() => { onEdit(el) }}>Edit</Button>
    </React.Fragment>
  )

  const getCustomComponents = () => {
    const customComponents = {
      region: {},
      status: {},
    };

    data.map((el, index) => {
      customComponents['region'][index] = regions.map(region => {
        if (region.id === el.regionId) return region.name;
      })
      customComponents['status'][index] = <Lozenge appearance={el.active ? "success" : "removed"}>{el.active ? 'Active' : 'Inactive'}</Lozenge>;

    })

    return customComponents;
  }

  return (
    <PaginatedTable
      columnData={columnData}
      data={data}
      count={count}
      actions={getActions}
      rowsPerPage={rowsPerPage}
      page={page}
      isNoData={isNoData}
      customComponents={getCustomComponents()}
      handleChangePage={onChangePage}
      handleChangeRowsPerPage={onChangeRowsPerPage}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
    />
  )
}

HomeOfferList.propTypes = {
  data: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  regions: PropTypes.arrayOf(object),
  onEdit: PropTypes.func,
}

export default HomeOfferList