import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import { Button, Chip } from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';
import { Tag } from 'antd';


const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'email', numeric: false, disablePadding: true, label: 'Username (Email ID)',
  },
  {
    id: 'mobile', numeric: false, disablePadding: true, label: 'Mobile',
  },
  {
    id: 'supervisorName', numeric: false, disablePadding: true, label: 'Supervisor',
  },
  {
    id: 'roles', numeric: false, disablePadding: true, label: 'Roles',
  },
  {
    id: 'status', numeric: false, disablePadding: true, label: 'Status',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class SegregatorList extends Component {

  getCustomComponents = () => {
    const customComponents = {
      roles: {},
      status: {}
    };
    const { data } = this.props;

    data.map((el, index) => {
      customComponents['status'][index] = <Lozenge appearance={el.status ? "success" : "removed"}>{el.status ? 'Active' : 'Inactive'}</Lozenge>;
      customComponents['roles'][index] = el.roles.map(role => <Tag>{role}</Tag>);
    })
    return customComponents;
  }

  getActions = (el) => (
    <React.Fragment>
      <Button color="primary" variant="outlined" size="small" onClick={() => { this.props.openAddSegregator(el); }}>
        Edit
      </Button>
      {/* &nbsp;&nbsp;&nbsp;
      <Button color="primary" size="small" onClick={(e) => { e.stopPropagation(); this.resetPassword(el.id); }}>
        Reset
      </Button> */}
    </React.Fragment>
  )



  render() {
    const {
      data,
      rowsPerPage,
      page,
      count,
      isNoData,
      onChangePage,
      onChangeRowsPerPage
    } = this.props;

    return (
      <PaginatedTable
        columnData={columnData}
        data={data}
        count={count}
        actions={this.getActions}
        rowsPerPage={rowsPerPage}
        page={page}
        isNoData={isNoData}
        customComponents={this.getCustomComponents()}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

SegregatorList.propTypes = {
  data: PropTypes.arrayOf(object),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isNoData: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}

export default SegregatorList;
