import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import utils from '../../../utils/queryBuilder';
import Colors from '../../../utils/Colors';

const canView = utils.isCurrentUserAuthorizedToView;

const styles = {
    div: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        background: '#f0f0f0',
        height: '66px',
        alignItems: 'center',
    },
    title: {
        flex: 2,
        marginLeft: '30px',
    },
    searchBar: {
        flex: 4,
    },
    calendar: {
        flex: 1,
        textAlign: 'center',
    },
    add: {
        flex: 1,
        textAlign: 'center',
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    searchField: {
        flex: 5,
    },
    clear: {
        flex: 1,
        marginLeft: '10px',
        color: Colors.primary,
    },
};

class SaveBar extends React.Component {

    handleBack = () => {
        const { history = {} } = this.props;
        history.goBack();
    }

  render() {
        const {
            headerTitle, submitButtonText, onSubmit, isDisable,
        } = this.props;
        return (
            <div style={styles.div}>
                <div style={styles.title}>
                    <Typography variant="title" color="inherit">
                        { headerTitle }
                    </Typography>
                </div>
                <div style={styles.searchBar} />
                <div style={styles.calendar}>
                    <Typography variant="body2" color="secondary">
                        <Button color="primary" variant="outlined" onClick={this.handleBack}> Back </Button>
                    </Typography>
                </div>
                <div style={styles.add}>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        size="large"
                        color="secondary"
                        disabled={isDisable}
                    >
                        { submitButtonText }
                    </Button>
                </div>
            </div>
        );
  }
}

SaveBar.propTypes = {
  headerTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  isDisable: PropTypes.instanceOf(Boolean),
};

export default withRouter(SaveBar);
