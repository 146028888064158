const globalStyle = {
  codeStyle: {
    color: '#1565C0',
    marginLeft: 10,
    fontSize: 30,
  },
  styleFont1: {
    margin: 20,
    fontSize: 45,
  },
  styleFont2: {
    color: '#1565C0',
    Hover: '#ffff',
    margin: 10,
    fontSize: 20,
  },
  divider: {
    marginBottom: 20,
    marginTop: 10,
  },
  paper: {
    padding: 30,
    marginTop: 20,
  },
  textField: {
    marginTop: 10,
    marginBottom: 15,
    width: 200,
  },
  col: {
    padding: window.innerHeight * 0.025,
  },
  customCol: {
    paddingTop: window.innerHeight * 0.0099,
    paddingLeft: window.innerHeight * 0.023,
    paddingRight: window.innerHeight * 0.023,
    paddingBottom: window.innerHeight * 0.023
  },
  customCol2: {
    paddingTop: window.innerHeight * 0.023,
    paddingLeft: window.innerHeight * 0.023,
    paddingRight: window.innerHeight * 0.023,
    paddingBottom: window.innerHeight * 0.01
  },
  customCol3: {
    paddingTop: window.innerHeight * 0.003,
    paddingLeft: window.innerHeight * 0.023,
    paddingRight: window.innerHeight * 0.023,
    paddingBottom: window.innerHeight * 0.023
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  headerList: {
    color: '#1565C0',
  },
  hideOverflowContainer: {
    overflow: 'hidden',
    height: '100vh',
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  paperLeft: {
    overflow: 'scroll',
  },
};

export default globalStyle;

