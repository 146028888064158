import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../../components/NavBar';
import QuantityTable from './QuantityTable';
import QuantitySearchBar from './QuantitySearchBar';
import apiCall, { uomUrl, allBrandsPathImages } from '../../../api/NetworkHandler';

import { ColMod } from '../../../components/lib';
import isEmpty from "lodash/isEmpty";

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '50%',
    height: '200px',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
  paperRight: {
    height: 600,
    flex: 2,
    background: '#f9f9f9',
    paddingTop: '50px',
    paddingLeft: '20px',
  },
  fullScreen: {
    flex: 1,
    height: '100%',
    marginLeft: 10,
    textAlign: 'center',
    padding: 10,
  },
};


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: null,
      quantities: [],
      uoms: [],
      links: {},
      openAddUom: false,
      selectedQuantity: {},
      selectedName: '',
      imageUrl: null,
      isEdit: false,
      error: {},
      filters: {
        query: '',
      },
      searchedResult: false,
      nameError: '',
      invalidName: false,
    };
  }

  componentDidMount() {
    this.fetchAllUoms();
  }

  onSearch = () => {
    this.fetchAllUoms();
  }

  fetchAllUoms = () => {
    if (this.state.filters.query) {
      // TODO Add search api
      apiCall.sendRequest('get', `/api/v1/uoms/search/findByCode?query=${this.state.filters.query}`)
        .then((response) => {
          this.setState({
            uoms: response.data._embedded.uoms,
            links: response.data._links,
            searchedResult: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiCall.sendRequest('get', 'api/v1/uoms')
        .then((response) => {
          this.setState({
            uoms: response.data._embedded.uoms,
            links: response.data._links,
            searchedResult: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  fetchPaginatedUoms = (url) => {
    apiCall.sendRequest('get', url)
      .then((response) => {
        this.setState({
          uoms: response.data._embedded.uoms,
          links: response.data._links,
          searchedResult: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

handleQueryChange = (event) => {
  const query = event.target.value;
  this.setState(prevState => ({
    filters: {
      ...prevState.filters,
      query,
    },
  }));
}

  clearSearch = () => {
    this.setState({
      filters: {
        query: '',
      },
    }, () => {
      this.onSearch();
    });
  }

  handleClick = (selectedQuantity) => {
    this.setState({
      selectedQuantity,
      selectedName: selectedQuantity.code,
      imageUrl: selectedQuantity.logo,
    });
  }

openAddUom = (quantity) => {
  if (quantity) {
    this.setState({
      selectedName: quantity.code,
      imageUrl: quantity.logo,
    });
  }
  // console.log('Called');
  this.setState({
    openAddUom: true,
    isEdit: !!quantity,
    selectedQuantity: {},
    selectedName: '',
  });
}

handleClose = () => {
  this.setState({
    openAddUom: false,
    error: {},
    isEdit: false,
    invalidName: false,
    nameError: '',
  });
}

handleImageUpload = (imageUrl) => {
  this.setState({
    imageUrl,
  });
}

  clearImage = () => {
    this.setState({
      imageUrl: null,
    });
  };

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };
  isFormValid = () => {
    if (isEmpty(this.state.selectedName)) {
      this.setError('selectedName', true);
      return false;
    }
    this.setError('selectedName', false);
    return true;
  };

  validateUomInput = () => {
    const { selectedName } = this.state;
    const alphanumPattern = new RegExp(/[^a-zA-Z0-9\s]/g);
    const numPattern = new RegExp(/[^0-9\s]/g);
    if (alphanumPattern.test(selectedName) || !numPattern.test(selectedName) || !selectedName) {
      this.setState({
        nameError: 'Invalid Uom',
        invalidName: true,
      });
      return false;
    } else {
      this.setState({
        nameError: '',
        invalidName: false
      });
      return true
    }
  }

  postUom = () => {
    // if (!this.isFormValid()) {
    //   return;
    // }
    if (!this.validateUomInput()) return;

    const postData = { ...this.state.selectedQuantity };
    postData.code = this.state.selectedName.split(" ").filter(el => el).join(" ");
    postData.logo = this.state.imageUrl;
    apiCall.sendRequest('post', uomUrl, postData)
      .then((response) => {
        // console.log(response.data);
        this.fetchAllUoms();
      })
      .catch((error) => {
        console.error(error);
      });
    this.handleClose();
  }

  render() {
    const { 
      isEdit = false, 
      selectedName, 
      filters: { query }, 
      error, 
      searchedResult, 
      uoms, 
      openAddUom,
      selectedQuantity = {},
      nameError,
      invalidName,
    } = this.state;
    const { id = null } = selectedQuantity;
    return (
      <div>
        <NavBar />
        <QuantitySearchBar 
          openAddUom={this.openAddUom} 
          handleQueryChange={this.handleQueryChange} 
          onSearch={this.onSearch} 
          clearSearch={this.clearSearch} 
          searchQuery={query} 
        />
        <div style={styles.div}>
          <div style={styles.fullScreen}>
            <QuantityTable 
              uoms={uoms} 
              openAddUom={this.openAddUom} 
              handleClick={this.handleClick} 
              links={this.state.links} 
              fetchPaginatedUoms={this.fetchPaginatedUoms} 
              searchedResult={searchedResult} 
              clearData={this.clearData} 
            />
          </div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openAddUom}
            onClose={this.handleClose}
          >
            <div style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            width: '70%',
            marginLeft: '15%',
            height: '70%',
            }}
            >
              <Button style={{ float: 'right' }} onClick={this.handleClose} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
              <Grid style={{ padding: '2em' }}>
                <Row>
                  <ColMod xs md lg={15}>
                    <Row>
                      <Typography variant="body2" color="secondary">
                        {isEdit ? 'Update Uom' : 'Create Uom'}
                      </Typography>
                    </Row>
                    <Row>
                    <Typography variant="caption" color="error">
                      *Required Field
                    </Typography>
                    </Row>
                    <Row>
                      <ColMod lg={6}>
                        <TextField
                          label="Name"
                          fullWidth
                          value={selectedName}
                          required
                          // error={error.selectedName}
                          error={invalidName}
                          helperText={nameError}
                          disabled={id}
                          onChange={(event) => {
														const {value} = event.target;
														this.setState({
															selectedName: value,
														});
													}}
                        />
                      </ColMod>
                    </Row>
                  </ColMod>
                </Row>
              </Grid>
              <div style={{ float: 'right', paddingRight: '2em' }}>
                <Button onClick={this.props.onClose} style={{ marginRight: '2em' }} color="secondary" onClick={this.handleClose}>
								  Cancel
                </Button>
                <Button onClick={() => { this.postUom() }} variant="contained" color="secondary">
                  {isEdit ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const image = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(image),
    });
    const formData = new FormData();
    formData.append('images', image);
    // apiCall.sendRequest('post', '/api/v1/images', formData)
    apiCall.sendRequest('post', allBrandsPathImages, formData)
      .then((response) => {
        console.log(response.data);
        this.props.handleImageUpload(Object.values(response.data)[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (this.state.file || this.props.url) {
      return (
        <div style={{
height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px',
}}
        >
          <img style={{ height: '5em', width: 'auto' }} src={this.props.url || this.state.file} />
          <CloseIcon
            color="secondary"
            style={{
 fontSize: '1.6em', position: 'absolute', top: '5px', right: '5px', cursor: 'pointer',
}}
            onClick={() => { this.props.clearImage(this.props.side); this.setState({ file: null }); }}
          />
        </div>
      );
    }
    const text_display = 'LOGO';
    return (
      <div style={{
height: '5em', width: '5em', display: 'inline-block', backgroundColor: '#EEEEEE', textAlign: 'center', position: 'relative', margin: '5px',
 }}
      >
        <label style={{ lineHeight: '5em' }}>{text_display}
          <input accept="image/*" type="file" style={{ visibility: 'hidden' }} onChange={this.handleChange} />
        </label>
      </div>
    );
  }
}


export default Dashboard;
