import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import NaMonitorCard from '../components/NaMonitorCard';
import API, { productsMonitoring } from '../../../api/NetworkHandler';
import SearchBar from '../../Commons/containers/SearchBar';
import NavBar from '../../../components/NavBar';

class NaMonitoring extends Component {
  constructor(props) {
    super(props);
    const { location: { search = '' } = '' } = this.props;
    const queryParams = search.substring(1, search.length).split('&');
    const filters = {};
    queryParams.forEach((param) => {
      const [key, value] = param.split('=');
      filters[key] = value;
    });
    this.state = {
      productsMatrix: [],
      ...filters,
    };
  }
  componentDidMount() {
    const { location: { search = '' } = '' } = this.props;
    const queryParams = search.substring(1, search.length).split('&');
    const filters = {};
    queryParams.forEach((param) => {
      const [key, value] = param.split('=');
      filters[key] = value;
    });
    this.fetchNaMonitoringData();
  }

  fetchNaMonitoringData = async () => {
    const { warehouse, date } = this.state;
    const response = await API.sendRequest('get', productsMonitoring(date, warehouse || ''));
    const { data: { data = {} } = {} } = response;
    this.setState({ productsMatrix: data });
  }
  render() {
    const { productsMatrix } = this.state;
    return (
      <div>
        <NavBar />
        <Grid container>
          <Grid item xs={12}>
            <SearchBar title="Products Monitoring" />
          </Grid>
          {/* <Grid item md={11} style={{ margin: '10px auto' }}>
          <Paper square>
            <Tabs value={0} indicatorColor="primary" textColor="primary" onChange={() => {}}>
              <Tab label="NA 1" />
              <Tab label="NA 2" />
              <Tab label="NA 3" />
            </Tabs>
          </Paper>
        </Grid> */}
          <Grid item md={11} style={{ margin: '10px auto' }}>
            <NaMonitorCard
              data={productsMatrix}
              labelPosition="top"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

NaMonitoring.propTypes = {
  location: PropTypes.instanceOf(Object),
};

export default withRouter(NaMonitoring);
