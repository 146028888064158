import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core/';
import moment from 'moment';
import SearchBar from '../../Commons/containers/SearchBar';
import Colors from '../../../utils/Colors';
import { TextField } from '@material-ui/core/';

const styles = {
  key: {
    color: Colors.primary,
  },
  value: {
    padding: '0px 5px',
  },
};

const GrnTitleBar = (props) => {
  const { title, grn = {}, onChange, viewOnly, poReceivableDate } = props;
  let today = new Date;
  const { deliveryDate } = grn;
  return (
    <SearchBar title={title}>
      <div style={{ padding: '1em 2em' }}>
        <Grid container>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Purchase Order : </span>
            <span style={styles.value}>{grn.poNumber}</span>
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Vendor :</span>
            <span style={styles.value}> {grn.vendorName}</span>
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Invoice No :&nbsp;&nbsp;</span>
            {/* <span style={styles.value}> {grn.invoiceNumber}</span> */}
            <TextField
              style={{ width: '65%' }}
              name="invoiceNumber"
              value={grn.invoiceNumber}
              onChange={onChange}
            />
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Warehouse :</span>
            <span style={styles.value}>{grn.warehouseName}</span>
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>GRN No :</span>
            <span style={styles.value}>{grn.id}</span>
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Status :</span>
            <span style={styles.value}>{grn.status}</span>
          </Grid>
          <Grid items xs={6} md={3} style={{ marginTop: 10 }}>
            <span style={styles.key}>Delivery Date :&nbsp;&nbsp;</span>
            {/* <span style={styles.value}>
              {grn.deliveryDate !== '' ? moment(grn.deliveryDate).format('LL') : ''}
            </span> */}
            <TextField
              id="date"
              style={{ width: '65%' }}
              type="date"
              name="deliveryDate"
              helperText={`Valid Date Range: ${moment(poReceivableDate).format("LL")} to ${moment(today).format("LL")}`}
              value={deliveryDate}
              disabled={viewOnly}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </div>
    </SearchBar>
  );
};

GrnTitleBar.propTypes = {
  title: PropTypes.string,
  grn: PropTypes.instanceOf(Object),
};

export default GrnTitleBar;
