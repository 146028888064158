import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PaginatedTable from '../../Commons/components/PaginatedTable';
import apiCall from '../../../api/NetworkHandler';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ProductItemForm from './ProductItemForm';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ColMod } from '../../../components/lib';
import { Row } from 'react-flexbox-grid';

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'unitPrice', numeric: false, disablePadding: true, label: 'Unit Price',
  },
  {
    id: 'sellingPrice', numeric: false, disablePadding: true, label: 'Selling Price',
  },
  {
    id: 'discount', numeric: false, disablePadding: true, label: 'Discount(%)',
  },
  {
    id: 'cgst', numeric: false, disablePadding: true, label: 'CGST',
  },
  {
    id: 'vendorProductItemImages', numeric: false, disablePadding: true, label: 'Images',
  },
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class SubProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showSubProductForm: false,
      editingSubProduct: {},
    }
  }

  componentDidMount() {
    this.fetchSubProducts();
  }

  fetchSubProducts = async () => {
    const { productId } = this.props;
    const response = await apiCall.sendRequest('get', `api/v1/vendor-products/${productId}/vendor-product-items`);
    const { data: { _embedded } } = response;
    const subProducts = _embedded['vendor-product-items'];
    this.setState({
      data: subProducts,
      count: subProducts.length,
    })
  }

  customComponents = () => {
    const { data } = this.state;
    const vendorProductItemImages = {}
    data.forEach((subProduct, index) => {
      vendorProductItemImages[index] = subProduct.vendorProductItemImages.map((image, i) => <img alt="imageUrl" key={i} style={{ width: '60px', height: 'auto' }} src={image.imageUrl} />)
    })
    return {
      vendorProductItemImages
    }
  }

  actions = (subProduct) => {
    return (
      <Typography color="secondary" style={{ fontSize: '0.9em' }}>
        <span onClick={() => { this.handleProductEdit(subProduct) }} style={{ cursor: 'pointer' }}>EDIT&emsp;</span>
      </Typography>
    )
  }

  handleProductEdit = (subProduct) => {
    this.setState({
      showSubProductForm: true,
      editingSubProduct: subProduct,
    })
  }

  handleFormClose = () => {
    this.setState({
      showSubProductForm: false,
      editingSubProduct: {},
    })
  }

  render() {
    const {
      data,
      count,
      showSubProductForm,
      editingSubProduct
    } = this.state;

    const { productId, onClose, vendorProductName, taxType } = this.props;

    const newColumnData = taxType !== 'gst' ? columnData.filter(col => col.id !== 'cgst') : columnData;

    return (
      <React.Fragment>
        <Row between='lg' middle='lg'>
          <ColMod lg={3}>
            <Typography variant='title'>
              Product Items
          </Typography>
          </ColMod>
          <ColMod lg={2}>
            <Button style={{ float: 'right' }} onClick={() => { onClose() }} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </ColMod>
        </Row>
        <PaginatedTable
          columnData={newColumnData}
          data={data}
          count={count}
          actions={this.actions}
          customComponents={this.customComponents()}
        />
        <Drawer anchor="right" open={showSubProductForm} onClose={() => { this.handleFormClose() }}>
          <ProductItemForm
            subProduct={editingSubProduct}
            onRefresh={this.fetchSubProducts}
            onClear={this.handleClear}
            onClose={() => this.handleFormClose()}
            vendorProductId={productId}
            vendorProductName={vendorProductName}
          />
        </Drawer>
      </React.Fragment>
    )
  }
}

SubProductsList.propTypes = {
  productId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

SubProductsList.defaultProps = {
  onClose: () => { },
}


const mapStateToProps = state => ({
  taxType: state.taxType,
});

export default connect(mapStateToProps, null)(SubProductsList);
