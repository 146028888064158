import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  TextField,
  Button,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ColMod } from '../../../components/lib';
import apiCall, { allOffersPath } from '../../../api/NetworkHandler';
import { isEmpty, debounce } from 'lodash';
import { ArrowBack } from 'material-ui-icons';
import { getOfferItemStartDate } from './OfferUtility';
import AudienceSetSelector from '../../../modules/Audience/components/AudienceSetSelector';


const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
    height: '200px',
  },
};

let COMPONENT_TITLE = 'Create Offer Campaign';
const re = /^[0-9\b]+$/;
const calculatedStartTime = getOfferItemStartDate();

class CreateOfferCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerData: {
        title: '',
        description: '',
        startTime: moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DD"),
        endTime: moment(calculatedStartTime).add(3, 'days').format("YYYY-MM-DD"),
        paybackType: 'DISCOUNT',
        overlayId: null,
      },
      disableButton: false,
      error: {},
      selectedOverlay: null,
    };
  }

  setError = (key, value) => {
    this.setState((prevState) => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [key]: value,
        },
      };
    });
  };

  isPropertyValid = (key, value) => {
    if (isEmpty(value)) {
      this.setError(key, true);
      return false;
    }
    this.setError(key, false);
    return true;
  };

  isFormValid = () => {
    const offer = {};

    offer.title = this.state.offerData.title;
    offer.description = this.state.offerData.description;
    offer.startTime = this.state.offerData.startTime;
    offer.endTime = this.state.offerData.endTime;
    offer.paybackType = this.state.offerData.paybackType;

    let isValid = true;

    const validateEntities = ['title', 'description', 'startTime', 'endTime', 'paybackType'];

    for (const property in offer) {
      // eslint-disable-next-line max-len
      if (validateEntities.includes(property) && !this.isPropertyValid(property, offer[property])) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleCreateCampaign = debounce( async () => {
    if (!this.isFormValid()) {
      return;
    }
    this.toggleCreateButton(true);
    let { offerData } = this.state;
    let { startTime, endTime } = offerData;
    const { onClose, onButtonClick } = this.props;
    offerData.startTime = moment(startTime).format('YYYY-MM-DDT00:00:00');
    offerData.endTime = moment(endTime).format('YYYY-MM-DDT23:59:59');
    offerData.offerType = "PRODUCT_OFFER";
    if (offerData.paybackType === "PERCENT DISCOUNT") {
      offerData.paybackType = "PRICE_DISCOUNT";
    }
    try {
      await apiCall.sendRequest('post', allOffersPath, offerData);
      onClose();
      onButtonClick('search', 0);
      this.toggleCreateButton(false);
    } catch (err) {
      console.log(err.response);
      this.toggleCreateButton(false);
    }
  }, 500);

  toggleCreateButton = (state) => {
    this.setState({
      disableButton: state,
    });
  }

  handleSetState = (key, value) => {
    const { offerData } = this.state;
    this.setState({
      offerData: {
        ...offerData,
        [key]: value,
      }
    });
  };

  handleChange = (value, key) => {
    const { offerData } = this.state;
    const { startTime, endTime } = offerData;
    const defaultStartDate = moment(calculatedStartTime).add(2, 'days').format("YYYY-MM-DD");
    const defaultEndDate = moment(calculatedStartTime).add(3, 'days').format("YYYY-MM-DD");
    const maxEndDate = moment(startTime).add(30, 'days').format("YYYY-MM-DD");

    if (key === "startTime" && (moment(value).isBefore(defaultStartDate, 'days'))) {
      this.handleSetState(key, startTime);
      return;
    }
    else if (key === "endTime" && (moment(value).isBefore(defaultStartDate, 'days') || moment(value).isBefore(startTime, 'days') || moment(value).isAfter(maxEndDate, 'days'))) {
      this.handleSetState(key, endTime);
      return;
    } else {
      if (key === "startTime") {
        this.setState({
          offerData: {
            ...offerData,
            [key]: value,
            endTime: moment(value).add(1, 'days').format("YYYY-MM-DD")
          }
        });
      } else {
        this.handleSetState(key, value);
      }
    }
  };

  handleOverlaySelection = (selectedOverlay) => {
    const { offerData } = this.state;
    this.setState({
      offerData: {
        ...offerData,
        overlayId: !isEmpty(selectedOverlay) ? selectedOverlay.value : null,
      },
      selectedOverlay,
    });
  };

  render() {
    const { onClose } = this.props;

    const { offerData, selectedOverlay, error } = this.state;

    const {
      id,
      title,
      startTime,
      endTime,
      description,
      paybackType
    } = offerData;

    return (
      <div>
        <div style={styles.div}>
          <Grid style={{ padding: '2em', width: '40vw' }}>
            <Row md={12} between="md">
              <ColMod>
                <Row>
                  <Col>
                    <ArrowBack color="secondary" style={{ fontSize: '1.6em', cursor: 'pointer' }} onClick={onClose} />
                  </Col>
                  <Col>
                    <Typography variant="title" style={{ fontWeight: 'bold' }}>
                      {COMPONENT_TITLE}
                    </Typography>
                  </Col>
                </Row>
              </ColMod>
              <ColMod>
                <Row>
                  <Col>
                    <Button onClick={onClose} variant="outlined" color="secondary" style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button onClick={this.handleCreateCampaign} variant="contained" color="primary">
                      Create
                    </Button>
                  </Col>
                </Row>
              </ColMod>
            </Row>
            <Row lg={12}>
              <ColMod zerobottom lg={12}>
                <TextField
                  label="Campaign Title"
                  name="title"
                  fullWidth
                  required
                  error={this.state.error.title}
                  value={title}
                  onChange={e => this.handleChange(e.target.value, 'title')}
                  disabled={id}
                  autoComplete="off"
                />
              </ColMod>
            </Row>
            <Row lg={12}>
              <ColMod zerobottom lg={12}>
                <TextField
                  label="Label"
                  name="description"
                  required
                  fullWidth
                  error={this.state.error.description}
                  value={description}
                  onChange={e => this.handleChange(e.target.value, 'description')}
                  autoComplete="off"
                />
              </ColMod>
            </Row>
            <Row md={12}>
              <ColMod md={12}>
                <Typography variant="caption"> Audience Set </Typography>
                <AudienceSetSelector selected={selectedOverlay} onSelect={this.handleOverlaySelection} />
              </ColMod>
            </Row>
            <Row lg={12}>
              <ColMod zerobottom lg={6}>
                <FormHelperText>Start Date (Delivery) *</FormHelperText>
                <TextField
                  type="date"
                  name="startTime"
                  error={this.state.error.startTime}
                  fullWidth
                  value={startTime}
                  onChange={e => this.handleChange(e.target.value, 'startTime')}
                />
              </ColMod>
              <ColMod zerobottom lg={6}>
                <FormHelperText>End Date (Delivery) *</FormHelperText>
                <TextField
                  type="date"
                  name="endTime"
                  error={this.state.error.endTime}
                  fullWidth
                  value={endTime}
                  onChange={e => this.handleChange(e.target.value, 'endTime')}
                />
              </ColMod>
            </Row>
            <span style={{ color: 'red', paddingLeft: '20px' }}>** Please maintain date duration within 30 days.</span>
            <Row>
              <ColMod zerobottom lg={6}>
                <FormHelperText> Offer Type* </FormHelperText>
                <Select
                  fullWidth
                  value={paybackType}
                  error={error.paybackType}
                  onChange={e => this.handleChange(e.target.value, 'paybackType')}
                >
                  {['PERCENT DISCOUNT', 'DISCOUNT'].map(el => (<MenuItem value={el}>{el}</MenuItem>))}
                </Select>
              </ColMod>
              <ColMod zerobottom lg={6}>
                {paybackType &&
                  <Typography color="error" style={{ paddingTop: '15px' }}>
                    {paybackType === 'PERCENT DISCOUNT' && 'Note: You have to provide discount percent'}
                    {paybackType === 'DISCOUNT' && 'Note: You have to provide offer price and mrp'}
                  </Typography>
                }
              </ColMod>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}


export default CreateOfferCampaign;
