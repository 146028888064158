import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {sortBy} from 'lodash';

export default function CategoryFilter(props) {
  let { categories = [], categoryId, onChangeCategory } = props;
  categories = sortBy(categories, ['name']);

  return (
    <div>
      <FormHelperText style={{ marginTop: '4px' }}>Category</FormHelperText>
      <Select
        name="category"
        label="category"
        fullWidth
        onChange={onChangeCategory}
        value={(+categoryId) || 'all'}
      >
        <MenuItem value='all'>All</MenuItem>
        {categories.sort().map((category, idx) => (
          <MenuItem key={idx} value={category.id}>{category.name}</MenuItem>
        ))}
      </Select>
    </div>
  )
}

CategoryFilter.propTypes = {
  onChangeCategory: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

CategoryFilter.defaultProps = {
  onChangeCategory: () => {},
  categories: [],
}
