import * as ActionType from '../actions';

const initialState = [];

const uom = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ALL_UOM:
      return action.payload;
    default:
      return state;
  }
};


export default uom;

