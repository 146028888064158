import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiCall, {
  getAllXPaths,
  getOrAssociateUsersToGroup,
  getOrAssociateXPathsToGroup
} from '../../../api/NetworkHandler';
import {
  Button,
  Typography,
  Grid,
  Modal,
  Tab,
  Tabs,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { debounce, sortBy, isEmpty } from 'lodash';
import UserSelector from '../../../components/Users/Selector';
import { Row, Col } from 'react-flexbox-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { Transfer } from 'antd';
import utils from '../../../utils/queryBuilder';
import ProgressBar from '../../../components/States/ProgressBar';

const canView = utils.isCurrentUserAuthorizedToView;



const styles = {
  deleteIcon: {
    color: "#f50057",
    width: "18px",
    height: "18px",
    marginLeft: "10px",
    cursor: "pointer"
  }
};

var versionDict = {
  "allXPathRequestId": 0,
  "userGroupsRequestedId": 0,
  "xPathRequestedId": 0
};

class GroupAssociation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetKeys: [],
      selectedKeys: [],
      allXPaths: [],
      userXPaths: [],
      disableButton: false,
      currentTab: 0,
      selectedUsers: [],
      userListToPost: [],
      loading: false
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.fetchAllXPaths();
    this.fetchAssociatedUsersByGroupId(data.id);
    this.fetchAssociatedXPathsByGroupId(data.id);
  };

  toggleLoading = (loadingState) => {
    this.setState({
      loading: loadingState
    });
  };

  fetchAssociatedUsersByGroupId = async (groupId) => {
    this.toggleLoading(true);
    try {
      versionDict.userGroupsRequestedId += 1;
      let prevRequestId = versionDict.userGroupsRequestedId;
      const response = await apiCall.sendRequest('get', getOrAssociateUsersToGroup(groupId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.userGroupsRequestedId) {
        this.setState({
          userListToPost: data.map(el => {return { ...el, id: el.userId }})
        });
      }
    } catch (err) {

    }
    this.toggleLoading(false);
  };

  fetchAssociatedXPathsByGroupId = async (groupId) => {
    this.toggleLoading(true);
    try {
      versionDict.xPathRequestedId += 1;
      let prevRequestId = versionDict.xPathRequestedId;
      const response = await apiCall.sendRequest('get', getOrAssociateXPathsToGroup(groupId));
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.xPathRequestedId) {
        this.setState({
          targetKeys: data.map(el => el.xpathId)
        });
      }
    } catch (err) {

    }
    this.toggleLoading(false);
  };

  fetchAllXPaths = async () => {
    this.toggleLoading(true);
    try {
      versionDict.allXPathRequestId += 1;
      let prevRequestId = versionDict.allXPathRequestId;
      const response = await apiCall.sendRequest('get', getAllXPaths);
      const { data: { data = [] } = {} } = response;
      if (prevRequestId === versionDict.allXPathRequestId) {
        this.setState({
          allXPaths: data
        });
      }
    } catch (err) {
      this.setState({
        allXPaths: []
      });
    }
    this.toggleLoading(false);
  };

  handleModalClose = () => {
    const { onClose } = this.props;
    this.setState({
      userListToPost: []
    });
    onClose(false);
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({
      targetKeys: nextTargetKeys,
    });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  toggleButtonState = (buttonState) => {
    this.setState({
      disableButton: buttonState
    });
  };

  getSortedTargetKeys = (data = []) => {
    const { allXPaths } = this.state;
    const result = allXPaths.filter(el => data.some(elm => el.id === elm));
    return sortBy(result, 'xpath').map(el => el.id);
  };

  handleTabChange = (event, currentTab) => {
    this.setState({
      currentTab
    });
  };

  handleSelectUser = (selectedUsers) => {
    this.setState({
      selectedUsers,
    });
  };

  addUser = () => {
    const { selectedUsers, userListToPost } = this.state;
    if (!isEmpty(selectedUsers)) {
      const mergedUserData = [...userListToPost, ...selectedUsers];
      const uniqueUserData = [...new Map(mergedUserData.map(el => [el.id, el])).values()];
      this.setState({
        userListToPost: uniqueUserData,
        selectedUsers: []
      });
    }
  };

  deleteUser = (user) => {
    const { userListToPost } = this.state;
    this.setState({
      userListToPost: userListToPost.filter(el => el.id !== user.id)
    });
  };

  associateUsersAndXPath = debounce(async () => {
    const { data: { id = '' } } = this.props;
    const { userListToPost, currentTab, targetKeys } = this.state;
    this.toggleLoading(true);
    switch (currentTab) {
      case 0:
        const postData = userListToPost.map(el => el.id);
        try {
          await apiCall.sendRequest('post', getOrAssociateUsersToGroup(id), postData);
        } catch (err) {

        }
        this.fetchAssociatedUsersByGroupId(id);
        break;
      case 1:
        try {
          await apiCall.sendRequest('post', getOrAssociateXPathsToGroup(id), targetKeys);
        } catch (err) {

        }
        this.fetchAssociatedXPathsByGroupId(id);
        break;
    }
    this.toggleLoading(false);
  }, 500);


  render() {
    const { open = false, data } = this.props;
    const {
      targetKeys,
      selectedKeys,
      allXPaths,
      disableButton,
      currentTab,
      selectedUsers,
      userListToPost,
      loading
    } = this.state;
    const sourceData = sortBy(allXPaths.map(el => { return { key: el.id, title: el.xpath } }), ['title']);
    const sortedTargetKeys = this.getSortedTargetKeys(targetKeys);

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={this.handleModalClose}
        disableBackdropClick
      >
        <div style={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          width: '60%',
          height: '90%',
          borderRadius: '5px',
        }}
        >
          <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", width: "100%", top: 0 }}>
            <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: window.innerWidth * 0.02, paddingTop: "10px" }}>
              <Typography variant="title" color="inherit"> {`Associate User Info [${data.name}]`} </Typography>
            </div>
            <Button style={{ float: 'right' }} onClick={this.handleModalClose} color="secondary" aria-label="close">
              <CloseIcon />
            </Button>
          </div>
          <ProgressBar isLoading={loading} color="primary" />
          <Grid style={{ padding: '1em', background: '#FFFFFF', marginTop: window.innerHeight * 0.05, overflowX: "auto", height: "90%", position: "absolute", width: "100%" }}>
            <div>
              <Tabs
                value={currentTab}
                onChange={this.handleTabChange}
                fullWidth
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Associate User" style={{ background: 'lightgrey', borderRadius: "10px" }} />
                {canView(['ADMIN']) && <Tab label="Associate XPath" style={{ background: 'lightgrey', borderRadius: "10px" }} />}
              </Tabs>
              {currentTab === 0 &&
                <div>
                  <div style={{ marginTop: "10px", marginLeft: window.innerWidth * 0.08 }}>
                    <Typography variant="caption">Select User</Typography>
                    <Row>
                      <Col lg={8}>
                        <UserSelector selected={selectedUsers} onSelect={this.handleSelectUser} />
                      </Col>
                      <Col lg={4}>
                        <Button color="primary" variant="contained" size="small" onClick={this.addUser}>
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Paper elevation={2} style={{ marginTop: "20px", height: window.innerHeight * 0.6, overflow: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ color: "black" }}>Name</TableCell>
                          <TableCell style={{ color: "black" }}>Email</TableCell>
                          <TableCell style={{ color: "black" }}>Mobile</TableCell>
                          <TableCell style={{ color: "black" }}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userListToPost.map((el, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">{el.name}</TableCell>
                              <TableCell component="th" scope="row">{el.email}</TableCell>
                              <TableCell component="th" scope="row">{el.mobile}</TableCell>
                              <TableCell component="th" scope="row">
                                <Tooltip title="Delete">
                                  <DeleteIcon
                                    style={styles.deleteIcon}
                                    onClick={() => this.deleteUser(el)}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              }
              {currentTab == 1 &&
                <div style={{ marginTop: "10px" }}>
                  <Transfer
                    dataSource={sourceData}
                    titles={['All X-path', 'Group X-path']}
                    targetKeys={sortedTargetKeys}
                    selectedKeys={selectedKeys}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    render={item => item.title}
                    listStyle={{ width: '50%', height: window.innerHeight * 0.65 }}
                  />
                </div>
              }
              <div style={{ position: "fixed", bottom: "15px", width: "55%", display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" variant="contained" onClick={this.associateUsersAndXPath} >
                  Associate
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </Modal>
    );
  }
}

GroupAssociation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

GroupAssociation.defaultProps = {
  open: false,
  onClose: () => { },
  data: {}
};

export default GroupAssociation;
