import React from 'react';
import NavBar from '../../../components/NavBar';
import MainComponent from '../components/CustomerOrder';

class App extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <MainComponent />
      </div>
    );
  }
}


export default (App);

