import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, Typography } from '@material-ui/core';
import OrderInfo from '../OrderInfo';
import styles from './styles';

class AllOrders extends Component {
  render() {
    const {
      orders,
      hasMoreOrders,
      showMoreOrders,
      editOrder,
      trackOngoingOrder,
      ongoingOrder,
      openRefundForm,
      currency,
      onDetailRequest,
      isDetailedViewOpen,
      onOrderHistoryClick,
      selectedLineItemData
    } = this.props;
    if (!isEmpty(orders)) {
      return (
        <div style={{ height: window.innerHeight * 0.65, overflow: "auto" }}>
          {!isEmpty(ongoingOrder) && (
            <div style={styles.ongoing}>
              <Typography color="textSecondary" style={styles.title}>
                ORDER IN PROGRESS
              </Typography>
              <div>
                <OrderInfo
                  order={ongoingOrder}
                  trackOngoingOrder={trackOngoingOrder}
                  isOngoing
                  openRefundForm={openRefundForm}
                  currency={currency}
                  onDetailRequest={onDetailRequest}
                  isDetailedViewOpen={isDetailedViewOpen}
                  onOrderHistoryClick={onOrderHistoryClick}
                  selectedLineItemData={selectedLineItemData}
                />
              </div>
            </div>
          )}
          <div>
            <Typography color="textSecondary" style={styles.title}>
              ALL ORDERS
            </Typography>
            <div>
              {orders.map((el, index) => (
                <OrderInfo
                  order={el}
                  editOrder={editOrder}
                  isListItem
                  openRefundForm={openRefundForm}
                  currency={currency}
                  onDetailRequest={onDetailRequest}
                  isDetailedViewOpen={isDetailedViewOpen}
                  itemIndex={index}
                  onOrderHistoryClick={onOrderHistoryClick}
                  selectedLineItemData={selectedLineItemData}
                />
              ))}
            </div>
            {hasMoreOrders && (
              <div style={styles.load}>
                <Button
                  onClick={showMoreOrders}
                  style={styles.btn}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  SHOW MORE
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return "Oops! No Orders available";
  }
}

AllOrders.propTypes = {
  orders: PropTypes.instanceOf(Array),
  hasMoreOrders: PropTypes.bool,
  showMoreOrders: PropTypes.func,
  editOrder: PropTypes.func,
  trackOngoingOrder: PropTypes.func,
  ongoingOrder: PropTypes.instanceOf(Object),
};

export default AllOrders;
