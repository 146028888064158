import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import apiCall from '../../../api/NetworkHandler';
import BASE_URL from '../../../api/config';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import { Popconfirm } from 'antd';

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {
    id: counter, name, calories, fat, carbs, protein,
  };
}

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'description', numeric: false, disablePadding: true, label: 'Description',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'Actions',
  },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, page
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <Tooltip
                title="Sort"
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            ), this)}
        </TableRow>
      </TableHead>
    );
  }
}


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
    overflow: 'hidden',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class PackagingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.packaging,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.packaging !== this.state.data) {
        this.setState({
          data: nextProps.packaging,
        });
      }
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  // handleChangePage = (event, page) => {
  //   //TODO pagination
  //   this.props.fetchPaginatedPackaging(page);
  // };

  // handleChangeRowsPerPage = (event) => {
  //   this.setState({ rowsPerPage: event.target.value });
  // };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value = 25 } = event.target;

    onRowsPerPageChange(value);
  };

  handleConfirm = (id) => {
    const { deletePackaging } = this.props;
    console.log(id);
    deletePackaging(id);
  };

  isSelected = id => this.state.selected === id

  render() {
    const { classes, rowsPerPage, totalElements, page } = this.props;
    const {
      data, order, orderBy, selected,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {/* {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => { */}
              {data.map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    onClick={() => { this.props.handleClick(n); }}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                  >
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">{n.name}</TableCell>
                    <TableCell component="th" scope="row" padding="none">{n.description}</TableCell>
                    <TableCell style={{ color: '#f50057' }} component="th" scope="row" padding="none">
                      <Button variant="outlined" color="secondary" size="small" onClick={() => { this.props.openAddPackaging(n); }}>
												Edit
                      </Button>
                      {/* &emsp;|&emsp;
                      <Popconfirm
                        title="Are you sure delete this Packaging?"
                        onConfirm={e => this.handleConfirm(n.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button color="secondary" size="small">
                          Delete
                        </Button>
                      </Popconfirm> */}
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        {(this.props.searchedResult) ?
          <TablePagination
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          />
          : <TablePagination
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          />
        }
      </div>
    );
  }
}

PackagingTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackagingTable);
