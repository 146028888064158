import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { ColMod } from '../../../components/lib';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SectionMessage from '@atlaskit/section-message';
import ProgressBar from '../../../components/States/ProgressBar';
import apiCall,
{
  checkMobileAvailability,
  generateOtp,
  validateOtp,
  changeMobileNumber,
  generateOtpForEmailChange,
  validateOtpForEmailChange,
  getChangeMobileOtp
} from '../../../api/NetworkHandler';
import { Checkbox } from 'antd';


const innerWidth = window.innerWidth;
const containerWidth = innerWidth < 600 ? innerWidth * 0.7 : innerWidth * 0.4;

const styles = {
  container: {
    width: containerWidth,
  },
  arrow: {
    cursor: "pointer",
  },
  error: {
    color: 'red',
  }
}

var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

class RefundForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        mobileNumber: '',
        otp: '',
        emailOtp: '',
        email: '',
        newEmail: '',
      },
      isAvailable: false,
      isOtpGenerated: false,
      isOtpValidated: false,
      error: {},
      otpGeneratedOnce: false,
      resendTime: 0,
      isEmailVerified: false,
      emailOtpGeneratedOnce: false,
      inProgress: false,
      isMobileVerified: false,
      emailOtpGeneratedOnce: false,
      callCustomerTime: 0,
      showCallCustomerInfoForMobileChange: false,
      showCallCustomerInfoForEmailChange: false,
      emailOtpByCustomer: '',
      customerEmail: '',
      invalidEmail: false,
      showCheckbox: false,
    }
  }

  generateEmailOtp = async () => {
    const { customerId, email } = this.props;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', generateOtp(customerId, "customer-existing-email"), { email });
      this.setState({ emailOtpGeneratedOnce: true, resendTime: 30, errorMessage: '' })
      this.startTimer();
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  resetState = () => {
    this.setState({
      showCallCustomerInfoForEmailChange: false,
      showCallCustomerInfoForMobileChange: false,
      invalidEmail: false,
      callCustomerTime: 0,
      showCheckbox: false,
    });
    clearInterval(this.oneMinTimeInterval);
  };

  generatemobileOtp = async () => {
    const { customerId, email, mobile } = this.props;
    const mobileNo = mobile;
    this.toggleInProgress();
    this.resetState();
    try {
      await apiCall.sendRequest('post', generateOtpForEmailChange(customerId, "customer-existing-mobile"), { mobileNo });
      this.setState({ emailOtpGeneratedOnce: true, resendTime: 30, errorMessage: '' })
      this.startTimer();
      this.startOneMinTimer();
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      console.log(error);
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  validateEmaiOtp = async () => {
    if (!this.validateFields('emailOtp')) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { emailOtp } } = this.state;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', validateOtp(customerId, "customer-existing-email"), { otp: emailOtp });
      this.setState({ isEmailVerified: true, resendTime: 0, errorMessage: '' })
      clearInterval(this.timeInterval);
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  validateMobileOtp = async () => {
    if (!this.validateFields('emailOtp')) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { emailOtp } } = this.state;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', validateOtpForEmailChange(customerId, "customer-existing-mobile"), { otp: emailOtp });
      this.setState({ isMobileVerified: true, resendTime: 0, errorMessage: '' });
      clearInterval(this.timeInterval);
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }


  validateFields = (field) => {
    const validateFields = [];
    const { formData } = this.state;
    if (field) {
      if (field == 'emailOtp') {
        validateFields.push(field)
      } else if (field == 'newEmail') {
        validateFields.push(field, 'newEmail')
      } 
      else if (field == 'otp') {
        validateFields.push(field, 'otp')
      } else {
        validateFields.push(field, 'mobileNumber');
      }
    } else {
      validateFields.push('mobileNumber');
      

    }
    let valid = true;

    for (const key in formData) {
      if (validateFields.includes(key)) {
        if (!this.isPropertyValid(key, formData[key])) {
          valid = false;
        }
      }
    }
    return valid;
  }

  isPropertyValid = (property, value) => {
    value = value.trim();
    if (!value) {
      this.setError(property, true);
      return false;
    }
    if (property === "newEmail") {

      if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value))) {
        this.setError(property, false);
        this.setState({ errorMessage: "Email is not valid" })
        return false;
      }
    }
    this.setError(property, false);
    return true;
  }

  setError = (property, value) => {
    this.setState(prevState => {
      const { error } = prevState;

      return {
        error: {
          ...error,
          [property]: value,
        }
      }
    })
  }

  generateOtp = async () => {
    if (!this.validateFields()) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { mobileNumber: mobileNo } } = this.state;
    this.toggleInProgress();
    this.resetState();
    try {
      await apiCall.sendRequest('post', generateOtp(customerId, "customer-new-mobile"), { mobileNo });
      this.setState({
        isOtpGenerated: true,
        otpGeneratedOnce: true,
        resendTime: 30,
        errorMessage: '',
      });
      this.toggleInProgress();
      this.startTimer();
      this.startOneMinTimer();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }
  generateOtpForEmailChange = async () => {
    if (!this.validateFields('newEmail')) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { newEmail: newEmail } } = this.state;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', generateOtpForEmailChange(customerId, "customer-new-email"), { email: newEmail });
      this.setState({
        isOtpGenerated: true,
        otpGeneratedOnce: true,
        resendTime: 30,
        errorMessage: '',
      });
      this.toggleInProgress();
      this.startTimer();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }
  checkAvailability = async () => {
    if (!this.validateFields()) {
      return;
    }
    this.toggleInProgress();
    try {
      const { formData: { mobileNumber } } = this.state;
      const response = await apiCall.sendRequest('get', checkMobileAvailability(mobileNumber));
      const { data: { data: isAlreadyregistered, message } } = response;
      if (isAlreadyregistered) {
        this.setState({
          errorMessage: message,
          isAvailable: false,
        });
      } else {
        this.setState({
          errorMessage: '',
          isAvailable: true,
        });
      }
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  validateOtp = async () => {
    if (!this.validateFields('otp')) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { otp } } = this.state;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', validateOtp(customerId, "customer-new-mobile"), { otp });
      this.setState({ isOtpValidated: true, resendTime: 0, errorMessage: '' });
      clearInterval(this.timeInterval);
      this.toggleInProgress();
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }
  validateOtpForEmailChange = async () => {
    const { onClose } = this.props;
    if (!this.validateFields('otp')) {
      return;
    }
    const { customerId } = this.props;
    const { formData: { otp, newEmail } } = this.state;
    const email=newEmail;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', validateOtpForEmailChange(customerId, "customer-new-email"), { otp, email });
      this.toggleInProgress();
      onClose('refresh');
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  changeMobileNumber = async () => {
    const { onClose } = this.props;
    const { customerId } = this.props;
    const { formData: { mobileNumber } } = this.state;
    this.toggleInProgress();
    try {
      await apiCall.sendRequest('post', changeMobileNumber(customerId, mobileNumber));
      this.toggleInProgress();
      onClose('refresh');
    } catch (error) {
      this.toggleInProgress();
      const { response: { data } } = error;
      let errorMessage = '';
      if (!data.message) {
        errorMessage = data;
      } else {
        errorMessage = data.message;
      }
      this.setState({ errorMessage });
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const { formData } = prevState;
      return {
        formData: {
          ...formData,
          [name]: isNaN(value) ? formData[name] : value,
        }
      }
    })
  }
  handleEmailInputChange = (event) => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const { formData } = prevState;
      return {
        formData: {
          ...formData,
          [name]: value,
        }
      }
    })
  }

  startTimer = () => {
    let seconds = 30;
    this.timeInterval = setInterval(() => {
      seconds--;
      if (seconds == 0) {
        clearInterval(this.timeInterval);
      }
      this.setState({ resendTime: seconds });
    }, 1000);
  }

  startOneMinTimer = () => {
    let seconds = 60;
    this.oneMinTimeInterval = setInterval(() => {
      seconds--;
      if (seconds == 0) {
        clearInterval(this.oneMinTimeInterval);
      }
      this.setState({ callCustomerTime: seconds, showCheckbox: true });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    clearInterval(this.oneMinTimeInterval);
  }

  toggleInProgress = () => {
    this.setState(prevState => {
      const { inProgress } = prevState;

      return {
        inProgress: !inProgress,
      }
    })
  }

  handleShowCallCustomerInfoForEmailChange = (event) => {
    const { checked } = event.target;
    this.setState({
      showCallCustomerInfoForEmailChange: checked,
    });
  };

  handleShowCallCustomerInfoForMobileChange = (event) => {
    const { checked } = event.target;
    this.setState({
      showCallCustomerInfoForMobileChange: checked,
    });
  };

  handleEmailOtpChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  getGeneratedOtpBySource = async (action, source = '') => {
    const { customerId } = this.props;
    const { formData } = this.state;
    try {
      const response = await apiCall.sendRequest('get', getChangeMobileOtp(customerId, source));
      const { data: { data: { OTP } = {} } = {} } = response;
      if (action === 'mobile') {
        this.setState({
          formData: {
            ...formData,
            otp: String(OTP)
          },
          errorMessage: '',
          showCallCustomerInfoForMobileChange: false,
          showCheckbox: false,
        });
      } else {
        this.setState({
          formData: {
            ...formData,
            emailOtp: String(OTP)
          },
          errorMessage: '',
          showCallCustomerInfoForEmailChange: false,
          showCheckbox: false
        });
      }
    } catch(err) {
      const { response: { data } } = err;
     console.error(data);
     if (action === 'mobile') {
      this.setState({
        formData: {
          ...formData,
          otp: ''
        },
        errorMessage: data.message || 'Faild to get mobile OTP. Please try again later',
      });
     } else {
      this.setState({
        formData: {
          ...formData,
          emailOtp: ''
        },
        errorMessage: data.message || 'Faild to get mobile OTP. Please try again later',
      });
     }
    }
  };

  validateEmailOtpByCustomer = () => {
    const { emailOtpByCustomer, formData: { emailOtp = '' } = {} } = this.state;
    if (emailOtp === emailOtpByCustomer) {
      this.getGeneratedOtpBySource("mobile", "customer-new-mobile");
      this.setState({
        errorMessage: '',
      });
    } else {
      this.setState({
        errorMessage: 'Please enter valid OTP!'
      });
    }
  };

  handleCustomerEmailChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };

  validateCustomerEmail = () => {
    const { customerEmail } = this.state;
    const { customerInfo: { email = '' } = {} } = this.props;
    if (!emailPattern.test(customerEmail)) {
      this.setState({ invalidEmail: true });
      return;
    } else {
      this.setState({ invalidEmail: false });
    }

    if (customerEmail === email) {
      this.getGeneratedOtpBySource("email", "customer-existing-mobile");
    } else {
      this.setState({
        errorMessage: 'Please enter valid Email!'
      });
    }
  };

  render() {
    const { classes, onClose, email, mobile } = this.props;
    const {
      errorMessage,
      error,
      isAvailable,
      formData: { mobileNumber, otp, emailOtp, newEmail },
      isOtpGenerated,
      isOtpValidated,
      otpGeneratedOnce,
      resendTime,
      isEmailVerified,
      emailOtpGeneratedOnce,
      isMobileVerified,
      inProgress,
      callCustomerTime,
      showCallCustomerInfoForMobileChange,
      emailOtpByCustomer,
      showCallCustomerInfoForEmailChange,
      customerEmail,
      invalidEmail,
      showCheckbox
    } = this.state;

    return (
      <Grid className={classes.container} fluid>
        <ProgressBar isLoading={inProgress} />
        <Row md={12} between="md" bottom="md">
          <ColMod>
            <Row>
              <Col>
                <ArrowBack onClick={() => { onClose() }} className={classes.arrow} color="secondary" />
              </Col>
              <Col>
                <Typography variant="title">{email ? 'Change Mobile Number' : 'Change Email'}</Typography>
              </Col>
            </Row>
          </ColMod>
          <ColMod>
            <Button onClick={() => { onClose() }} color="secondary">Cancel</Button>
          </ColMod>
        </Row>
        <Row>
          <ColMod custompadding="true">
            <Typography variant="caption" className={classes.error}> Required * </Typography>
          </ColMod>
        </Row>
        {errorMessage && <Row xs={12}>
          <ColMod xs={12} custompadding="true">
            <SectionMessage appearance="error">
              {errorMessage}
            </SectionMessage>
          </ColMod>
        </Row>}
        {((!isEmailVerified && email) || (!isMobileVerified && mobile)) &&
          <Row bottom="sm">
            <ColMod md={6}>
              <TextField
                value={email ? email : mobile}
                label={email ? "Email" : "Mobile"}
                disabled
                fullWidth
                helperText={email ? "OTP will be sent to this email id to validate the customer." : "OTP will be sent to this mobile number to validate the customer."}
              />
            </ColMod>
            <ColMod md={6}>
              <Button variant="contained" color="secondary" onClick={email ? this.generateEmailOtp : this.generatemobileOtp} disabled={resendTime > 0 || inProgress}>
                {emailOtpGeneratedOnce ? `Resend Otp ${resendTime ? `(${resendTime})` : ''}` : "Generate OTP"}
              </Button>
            </ColMod>
            {(mobile && showCheckbox) && <ColMod md={12}>
              <Checkbox onChange={this.handleShowCallCustomerInfoForEmailChange} disabled={callCustomerTime > 0}>
                {`Click here if customer didn't receive the OTP ${callCustomerTime > 0 ? `(${callCustomerTime})` : ''}.`}
              </Checkbox>
            </ColMod>}
          </Row>}
        {(!isEmailVerified && !isMobileVerified && emailOtpGeneratedOnce && !showCallCustomerInfoForEmailChange) && <Row bottom="sm">
          <ColMod md={6}>
            <TextField
              value={emailOtp || ''}
              label="OTP"
              name="emailOtp"
              onChange={this.handleInputChange}
              fullWidth
              required
              inputProps={{ maxLength: 4 }}
              error={error.emailOtp}
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6}>
            <Button variant="contained" color="secondary" onClick={email ? this.validateEmaiOtp : this.validateMobileOtp} disabled={inProgress}>
              Validate OTP
            </Button>
          </ColMod>
        </Row>}
        {showCallCustomerInfoForEmailChange && <Row bottom="sm">
          <ColMod md={12} custompadding="true">
            <Typography variant="body1" color="secondary">
              **Please ask for customer existing email id and validate to proceed.
            </Typography>
          </ColMod>
          <ColMod md={6} custompadding="true">
            <TextField
              value={customerEmail || ''}
              label="Customer Email"
              name="customerEmail"
              onChange={this.handleCustomerEmailChange}
              fullWidth
              required
              error={invalidEmail}
              helperText={invalidEmail ? "Invalid Email" : "Enter customer email"}
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6} custompadding="true">
            <Button variant="contained" color="secondary" onClick={this.validateCustomerEmail}>
              Validate Email
            </Button>
          </ColMod>
        </Row>}
        {isEmailVerified && <Row bottom="sm">
          <ColMod md={6}>
            <TextField
              value={mobileNumber || ''}
              label="New Mobile Number"
              name="mobileNumber"
              onChange={this.handleInputChange}
              fullWidth
              required
              disabled={isAvailable}
              error={error.mobileNumber}
              helperText="Enter new mobile number of the customer. OTP will be sent for validation."
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6}>
            <Button variant="contained" color="secondary" onClick={isAvailable ? this.generateOtp : this.checkAvailability} disabled={resendTime > 0 || isOtpValidated || inProgress || showCallCustomerInfoForMobileChange}>
              {isAvailable ? (otpGeneratedOnce ? `Resend Otp ${resendTime ? `(${resendTime})` : ''}` : "Generate OTP") : "Check Availability"}
            </Button>
          </ColMod>
          {showCheckbox && <ColMod md={12}>
            <Checkbox onChange={this.handleShowCallCustomerInfoForMobileChange} disabled={callCustomerTime > 0}>
              {`Click here if customer didn't receive the OTP ${callCustomerTime > 0 ? `(${callCustomerTime})` : ''}.`}
            </Checkbox>
          </ColMod>}
        </Row>}
        {isMobileVerified && <Row bottom="sm">
          <ColMod md={6}>
            <TextField
              value={newEmail || ''}
              label="New Email"
              name="newEmail"
              onChange={this.handleEmailInputChange}
              fullWidth
              required
              disabled={otpGeneratedOnce}
              error={error.newEmail}
              helperText="Enter new email of the customer. OTP will be sent for validation."
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6}>
            <Button variant="contained" color="secondary" onClick={this.generateOtpForEmailChange} disabled={resendTime > 0 || isOtpValidated || inProgress}>
              {otpGeneratedOnce ? `Resend Otp ${resendTime ? `(${resendTime})` : ''}` : "Generate OTP"}
            </Button>
          </ColMod>
        </Row>}
        {(isOtpGenerated && !showCallCustomerInfoForMobileChange) && <Row bottom="sm">
          <ColMod md={6}>
            <TextField
              value={otp || ''}
              label="OTP"
              name="otp"
              onChange={this.handleInputChange}
              fullWidth
              required
              inputProps={{ maxLength: 4 }}
              disabled={isOtpValidated}
              error={error.otp}
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6}>
            <Button variant="contained" color="secondary" onClick={email ? this.validateOtp : this.validateOtpForEmailChange} disabled={isOtpValidated || inProgress}>
              Validate OTP
            </Button>
          </ColMod>
        </Row>}
        {showCallCustomerInfoForMobileChange && <Row bottom="sm">
          <ColMod md={12} custompadding="true">
            <Typography variant="body1" color="secondary">
              **Please call the customer in the new number <b style={{ color: 'black' }}>{mobileNumber}</b> and ask for the OTP received by email.
            </Typography>
          </ColMod>
          <ColMod md={6} custompadding="true">
            <TextField
              value={emailOtpByCustomer || ''}
              label="Email OTP"
              name="emailOtpByCustomer"
              onChange={this.handleEmailOtpChange}
              fullWidth
              required
              inputProps={{ maxLength: 4 }}
              helperText="Enter email OTP received by customer"
              autoComplete="off"
            />
          </ColMod>
          <ColMod md={6} custompadding="true">
            <Button variant="contained" color="secondary" onClick={this.validateEmailOtpByCustomer}>
              Validate Email OTP
            </Button>
          </ColMod>
        </Row>}
        {isOtpValidated && email && <Row bottom="sm">
          <ColMod md={6}>
            <Button variant="contained" color="secondary" onClick={this.changeMobileNumber} disabled={inProgress}>
              Change Mobile Number
            </Button>
          </ColMod>
        </Row>}
      </Grid>
    )
  }
}

export default withStyles(styles)(RefundForm);

