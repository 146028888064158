import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Refresh';
import { Grid, Row } from 'react-flexbox-grid';
import { ColMod } from '../../../components/lib';
import NavBar from '../../../components/NavBar';
import BASE_URL from '../../../api/config';
import apiCall, {
  getStatusCountByDate,
  resolveOrderPath,
  complaintReasonPath,
  deliveryRouteOrdersPath,
  searchDeliverOrderChats,
  deliveryOrderChats,
  getWarehouseHubs,
  routesByHubIdPath,
  fetchPackageStatusesByCustomerOrders,
  fetchPackagingDetailsByOrderId,
} from '../../../api/NetworkHandler';
import FilterStore from '../../../store/TempStore';
import SearchBar from '../../Commons/containers/SearchBar';
import DeliveryRouteSearch from '../components/DeliveryRouteSearch';
import OrderCard from '../components/OrderCard';
import CounterStrip from '../components/CounterStrip';
import OrderChat from '../components/OrderChat';
import DeliveryOrderImage from '../../Commons/components/DeliveryOrderImage';
import { Drawer } from '@material-ui/core';
import DownloadReportForm from '../components/DownloadReportForm';
import { withRouter } from 'react-router-dom';
import { getParamsSearchQuery } from '../../../utils/getParamsSearchQuery';


var requestId = 0;

class DeliveryRouteOrders extends Component {
  constructor(props) {
    super(props);
    const page = 'DeliveryRouteOrder';
    let filtersLocal = {
      query: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      status: '',
      orderId: '',
      customerId: '',
      priority: null,
      warehouseId:'',
      hubId:'',
      routeName:''
    };
    if (FilterStore.getFilters(page)) {
      filtersLocal = FilterStore.getFilters(page);
    }
    this.state = {
      page: 'DeliveryRouteOrder',
      deliveryRouteOrders: [],
      selectedDeliveryRouteOrder: {},
      deliveryOrderChats: [],
      deliveryStatusSummary: [],
      message: '',
      filters: { ...filtersLocal },
      currentPage: 0,
      complaintReasons: [],
      hubs:[],
      routes:[],
      openDownloadForm: false,
      deliveryRouteOrdersWithStatus: [],
    };
  }

  componentDidMount= () => {
    setTimeout(this.fetchParams, 1000);
  }

  fetchParams = () => {
    const { filters } = this.state;
    const { location } = this.props;
    const { search } = location;
    const getFilterParams = getParamsSearchQuery(search, filters);
    this.setState({ filters: getFilterParams });
    this.fetchAllDeliveryRouteOrders(getFilterParams);
    }


  // Note: Complaint reason is available in csReducer, hence no need to fetch seperately

  // getComplaintResons = async () => {
  //   const { selectedDeliveryRouteOrder = {} } = this.state;
  //   const { customerOrder } = selectedDeliveryRouteOrder;
  //   const path = complaintReasonPath(customerOrder);
  //   try {
  //     const response = await apiCall.sendRequest('get', path);
  //     const { data = [] } = response;
  //     this.setState({
  //       complaintReasons: data,
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }


fetchAllDeliveryOrderChats = (id) => {
  apiCall.sendRequest('get', searchDeliverOrderChats(id))
    .then((response) => {
      const { _embedded: embedded } = response.data;
      this.setState({
        deliveryOrderChats: embedded['delivery-order-chats'],
      });
    });
}


fetchAllDeliveryRouteOrders = async (currentPage = 0) => {
  const { filters } = this.state;
  const {
    query,
    fromDate, toDate,
    status, customerId,
    orderId, priority,
    warehouseId,
    hubId,
    routeName
  } = filters;

  const urlPath = deliveryRouteOrdersPath({
    query,
    fromDate,
    toDate,
    status,
    customerId,
    orderId,
    priority,
    warehouseId,
    hubId,
    routeName
  }, currentPage);

  this.setState({
    currentPage,
  });

  const orderResponse = await apiCall.sendRequest('get', urlPath);
  const { data = {} } = orderResponse;
  const { _embedded: embedded } = data;
  const counterResponse = await apiCall.sendRequest('get', getStatusCountByDate(filters));
  this.setState({
    deliveryRouteOrders: embedded['delivery-route-order'],
    deliveryStatusSummary: counterResponse.data,
  }, () => this.getPackageStatusesByCustomerOrders());
}

getAllCustomerOrders = () => {
  const { deliveryRouteOrders = [] } = this.state;
  return deliveryRouteOrders.map(el => el.customerOrder);
}

insertPackageStatus = (data) => {
  const { deliveryRouteOrders = [] } = this.state;
  let newDeliveryRouteOrders = [];
  deliveryRouteOrders.map(el => {
    data.map(elm => {
      if (el.customerOrder == elm.customerOrderId) {
        newDeliveryRouteOrders.push({...el, packageStatus: elm.packageStatus});
      }
    });
  });
  this.setState({
    deliveryRouteOrdersWithStatus: newDeliveryRouteOrders,
  });
}

getPackageStatusesByCustomerOrders = async () => {
  const { deliveryRouteOrders } = this.state;
  if (deliveryRouteOrders.length === 0) return;
  const customerOrders = this.getAllCustomerOrders();
  try {
    requestId += 1;
    let prevRequestId = requestId;
    const response = await apiCall.sendRequest('post', fetchPackageStatusesByCustomerOrders, customerOrders);
    const { data: { data = [] } } = response;
    if (requestId == prevRequestId) {
      this.insertPackageStatus(data);
    }
  } catch (err) {
    this.setState({
      deliveryRouteOrdersWithStatus: [],
    });
  }
}

fetchHubs = async (warehouseId) => {
  const response = await apiCall.sendRequest('get', getWarehouseHubs(warehouseId));
  const { data: { data } } = response;
  this.setState({ hubs: data });
}

fetchRoutes = async (hubId) => {
  const { hubs } = this.state;
  const hub = hubs.find(h => h.id === hubId);
  const response = await apiCall.sendRequest('get', routesByHubIdPath(hub.id));
  const { data: { _embedded = {} } = {} } = response;
  this.setState({ routes: _embedded['static-routes'] });
}

handleChange = (filters, refresh = false) => {
  const { currentPage } = this.state;
  this.setState({
    filters,
  }, () => {
    if (refresh) {
      this.fetchAllDeliveryRouteOrders(currentPage);
    }
  });
}

searchDeliveryRouteOrders = () => {
  this.setState({
    selectedDeliveryRouteOrder: {},
  });
  this.fetchAllDeliveryRouteOrders(0);
}

clearSearch = () => {
  this.setState({
    filters: {
      query: '',
      toDate: moment().format('YYYY-MM-DD'),
      fromDate: moment().format('YYYY-MM-DD'),
      status: '',
      orderId: '',
      customerId: '',
      priority: null,
      warehouseId:'',
      hubId:'',
      routeName:''
    },
  }, () => {
    this.searchDeliveryRouteOrders();
  });
}

selectDeliveryRouteOrder = (order) => {
  this.setState({
    selectedDeliveryRouteOrder: order,
  }, () => {
    this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id);
    // this.getComplaintResons();
  });
}

updateStatus = (value, name) => {
  const postData = {};
  const { deliveryRouteOrders } = this.state;
  if (name === "complaintReason" && value === "None") {
    postData[name] = null;
  } else {
    postData[name] = value;
  }
  apiCall.sendRequest('patch', `api/v1/delivery-route-order/${this.state.selectedDeliveryRouteOrder.id}`, postData)
    .then((response) => {
      const index = deliveryRouteOrders.findIndex(order =>
        order.id === this.state.selectedDeliveryRouteOrder.id);
      this.setState(prevState => ({
        deliveryRouteOrders: [
          ...prevState.deliveryRouteOrders.slice(0, index),
          {
            ...prevState.deliveryRouteOrders[index],
            [name]: value,
          },
          ...prevState.deliveryRouteOrders.slice(index + 1),
        ],
        selectedDeliveryRouteOrder: response.data,
      }));
    })
    .catch((error) => {
      console.error(error);
    });
  // this.getComplaintResons();
}

updateMessage = (event) => {
  const { value } = event.target;
  this.setState({
    message: value,
  });
}

sendMessage = async () => {
  const { User = {} } = this.props;
  const postData = {
    message: this.state.message,
    deliveryRouteOrder: `${BASE_URL.BASE_URL}/api/v1/delivery-route-order/${this.state.selectedDeliveryRouteOrder.id}`,
    userId: User.id,
    userName: User.name,
  };
  await apiCall.sendRequest('post', deliveryOrderChats, postData);
  this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id);
  this.setState({
    message: '',
  });
}

handleResolve = async () => {
  const { selectedDeliveryRouteOrder = {} } = this.state;
  const { customerOrder, complaintReason } = selectedDeliveryRouteOrder;
  const path = resolveOrderPath(customerOrder);
  await apiCall.sendRequest('put', path, { remarks: complaintReason });
  this.fetchAllDeliveryRouteOrders(0);
}

handleClick = (name, params = null) => {
  switch (name) {
    case 'search':
      this.searchDeliveryRouteOrders(params);
      break;
    case 'clear':
      this.clearSearch();
      break;
    default:
      break;
  }
};

handleDownloadOrders = () => {
  this.setState({ openDownloadForm: true });
}

handleDownloadFormCLose = () => {
  this.setState({ openDownloadForm: false });
}

// prepareLabelPrint = (selectedOrder) => {
//   return (
//     `<div style="width: 188.97px; height: 113.38px; border: 1px solid black; border-collapse: collapse;" rules="all">
//       <p style="font-size: 7px;  line-height: 1px; text-align: center; margin-top: 4px; margin-bottom: 0; padding: 0"> FRESH TO HOME FOODS FACTORY L.L.C </p>
//       <div>
//         <img src='../../../../public/images/FTHDailyIcon.png' style="width:30px; height:30px;">
//         <p style="font-size: 12px;  line-height: 1px; float: right; margin-right: 5px"> ${moment(selectedOrder.deliveryDate).format("DD/MM/YYYY")} </p>
//       </div>
//       <p style="font-size: 16px; line-height: 1px; text-align: center; margin-top: 2px"> Order# <b> ${selectedOrder.customerOrder} </b> </p>
//       <hr style="margin-top: -0.4em;" />
//       <div style="font-size: 10px; margin-left: 5px; text-align: left;">
//         <p><b>${selectedOrder.customerName || ''}</b><br/>${selectedOrder.address}</p>
//       </div>
//     </div>`
//   );
// }

// displayLabel = (selectedOrder) => {
//   let content = this.prepareLabelPrint(selectedOrder);
//   let orderHtml = '<html><head><title></title></head><body onLoad="window.print();window.close();">' + content + '</body></html>';
//   let winPrint = window.open("", "", 200, 120);
//   winPrint.document.write(orderHtml);
//   winPrint.document.close();
//   winPrint.focus();
// }

render() {
  const {
    selectedDeliveryRouteOrder = {}, deliveryStatusSummary, filters = {}, complaintReasons = [],
    deliveryRouteOrders = [],
    currentPage = 0,
    hubs,
    routes,
    openDownloadForm,
    selectedOrder = {},
    deliveryRouteOrdersWithStatus,
  } = this.state;
  const {
    deliveryImageUrl,
    latitude,
    longitude,
  } = selectedDeliveryRouteOrder;
  const { complaintReason, status: orderStatus } = selectedDeliveryRouteOrder;
  const {
    reasonMapping = {}, orderPriority = {}, DeliveryStatuses = {}, warehouses = [],
  } = this.props;
  const nextStatus = orderStatus === 'CUSTOMER_COMPLAINT' ? orderStatus : 'CUSTOMER_COMPLAINT';
  return (
    <div>
      <NavBar />
      <SearchBar
        title="Delivery Route Orders"
        filters={filters}
        onChange={this.handleChange}
        onButtonClick={this.handleClick}
      >
        <DeliveryRouteSearch
          orderPriority={orderPriority}
          deliveryStatuses={DeliveryStatuses}
          onClearAll={this.clearSearch}
          warehouses={warehouses}
          hubs={hubs}
          routes={routes}
          onWarehouseSelection={this.fetchHubs}
          onHubSelection={this.fetchRoutes}
          handleDownload={this.handleDownloadOrders}
        />
      </SearchBar>
      <div style={{ margin: '0.5em' }}>
        <Grid fluid>
          <CounterStrip counters={deliveryStatusSummary} />
          <Row>
            <span style={{ color: 'grey' }}>
              <Typography
                onClick={() => (
                  currentPage > 0 ? this.fetchAllDeliveryRouteOrders(currentPage - 1) : null)
                }
                color={currentPage > 0 ? 'secondary' : 'inherit'}
                variant="body1"
                style={{ cursor: currentPage > 0 ? 'pointer' : 'initial' }}
              >
                <ArrowBack style={{ fontSize: '20', verticalAlign: 'top', marginLeft: '16' }} />&nbsp;Prev
              </Typography>
            </span>&emsp;
            <span style={{ color: 'grey' }}>
              <Typography
                onClick={() => (
                  deliveryRouteOrders.length > 19 ?
                    this.fetchAllDeliveryRouteOrders(currentPage + 1) : null)
                }
                color={deliveryRouteOrders.length > 19 ? 'secondary' : 'inherit'}
                variant="body1"
                style={{ cursor: deliveryRouteOrders.length > 19 ? 'pointer' : 'initial' }}
              >
                <ArrowForward style={{ fontSize: '20', verticalAlign: 'top' }} />&nbsp;Next
              </Typography>
            </span>
          </Row>
          <Row>
            <ColMod lg={6}>
              {deliveryRouteOrdersWithStatus.map(order => (
                <OrderCard
                  key={order.id}
                  selected={selectedDeliveryRouteOrder.id === order.id}
                  order={order}
                  onClick={() => this.selectDeliveryRouteOrder(order)}
                  // displayLabel={() => this.displayLabel(order)}
                />
              ))
              }
            </ColMod>
            <ColMod lg={6}>
              {Object.keys(selectedDeliveryRouteOrder).length ?
                <div>
                  <Typography variant="display1" gutterBottom headlineMapping={{ display1: 'h6' }}>
                    <span>
                        Message Board
                    </span>
                    <span style={{ float: 'right' }}>
                      <Refresh color="secondary" onClick={() => this.fetchAllDeliveryOrderChats(this.state.selectedDeliveryRouteOrder.id)} />
                    </span>
                  </Typography>
                  <Typography variant="title" gutterBottom>
                    {selectedDeliveryRouteOrder.customerName}
                  </Typography>
                  <Typography variant="body2" color="secondary" style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '3px solid #eeeeee' }}>
                    <span>
                        Order #{String(selectedDeliveryRouteOrder.customerOrder).padStart(6, '0')}
                    </span>
                    {
                      orderStatus === 'CUSTOMER_COMPLAINT' ?
                        <span style={{ marginLeft: '10%', marginRight: '10%' }}>
                          <span style={{ margin: '0px 5px' }}>Reason: </span>
                          <Select
                            // value={complaintReason || 'Select Reason'}
                            value={!complaintReason ? "None" : complaintReason}
                            onChange={e => this.updateStatus(e.target.value, 'complaintReason')}
                            style={{ width: window.innerWidth * 0.15 }}
                          >
                            {/* {reasonMapping.map(status => (<MenuItem key={status} value={status}>{reasonMapping[status]}</MenuItem>))} */}
                            <MenuItem value="None">
                              <em>None</em>
                            </MenuItem>
                            { Object.keys(reasonMapping).map((reason, index) => (<MenuItem key={index} value={reason}>{reasonMapping[reason]}</MenuItem>)) }
                          </Select>
                        </span>
                      : null
                      }
                    <span style={{ float: 'right' }}>
                      {
                          orderStatus !== 'CUSTOMER_COMPLAINT' &&
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => this.updateStatus(nextStatus, 'status')}
                          >
                            Complaint
                          </Button>
                        }
                      {
                          orderStatus === 'CUSTOMER_COMPLAINT' &&
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={this.handleResolve}
                            disabled={!complaintReason || complaintReason === ''}
                            style={{ marginLeft: 10 }}
                          >
                          Resolve
                          </Button>
                        }
                    </span>
                  </Typography>
                  {
                    this.state.deliveryOrderChats.map(chat => (
                      <OrderChat chat={chat} user={this.props.User} />
                    ))
                  }
                  <div>
                    <textarea
                      value={this.state.message}
                      style={{
                          resize: 'none',
                          width: '97%',
                          height: '75px',
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '5px',
                          borderColor: '#bbbbbb',
                          fontSize: '100%',
                        }}
                      placeholder="Enter a message"
                      onChange={this.updateMessage}
                      onKeyPress={(event) => { if (event.key === 'Enter') { this.sendMessage(); } }}
                    />
                    <Button color="secondary" variant="contained" style={{ float: 'right', marginTop: '10px' }} onClick={this.sendMessage}>
                        Post
                    </Button>
                  </div>
                  {
                    deliveryImageUrl &&
                    <DeliveryOrderImage
                      latitude={+latitude}
                      longitude={+longitude}
                      imageUrl={deliveryImageUrl}
                    />
                  }
                </div>
              : null}
            </ColMod>
          </Row>
        </Grid>
      </div>
      <Drawer open={openDownloadForm} anchor="right" onClose={this.handleDownloadFormCLose}>
        <DownloadReportForm
          onClose={this.handleDownloadFormCLose}
        />
      </Drawer>
    </div>
  );
}
}

DeliveryRouteOrders.propTypes = {
  reasonMapping: PropTypes.instanceOf(Object),
  User: PropTypes.instanceOf(Object),
  orderPriority: PropTypes.instanceOf(Object),
  DeliveryStatuses: PropTypes.instanceOf(Object),
  warehouses: PropTypes.instanceOf(Array),

};

const mapStateToProps = state => ({
  warehouses: state.information.warehouses,
  User: state.User,
  DeliveryStatuses: state.Status.deliveryStatuses,
  reasonMapping: state.Status.complaintReasons,
  orderPriority: state.Status.orderPriority,
});

export default connect(mapStateToProps, null)(withRouter(DeliveryRouteOrders));
