import React from 'react';
import Select from '@atlaskit/select/Select';
import PropTypes from 'prop-types';


function WidgetSelector(props) {
  const { onWidgetChange, selected, options } = props;

  return (
    <Select
      options={options}
      value={selected}
      onChange={onWidgetChange}
    />
  )
}

WidgetSelector.propTypes = {
  onWidgetChange: PropTypes.func.isRequired,
  selected: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
}

WidgetSelector.defaultProps = {
  selected: {},
  options: [],
}

export default WidgetSelector;
