import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableBody, TableRow, Button } from '@material-ui/core';
import TableHeader from './TableHeader';
import Colors from '../../../utils/Colors';

const styles = {
  error: {
    padding: 20,
    textAlign: 'center',
    fontSize: 20,
    color: Colors.grey[400],
  },
};

class TableList extends Component {
  render() {
    const {
      data = [], columns, keys,
      actions = [],
      cellStyle = {}, rowStyle = {}, bodyStyle = {},
      handleClick,
      errorMessage = 'No Data',
    } = this.props;
    return (
      <Table aria-labelledby="tableTitle">
        <TableHeader columns={columns} cellStyle={cellStyle} />
        <TableBody style={bodyStyle}>
          {
            data && data.length > 0 ? data.map((item, i) => (
              <TableRow
                hover
                style={rowStyle}
                onClick={() => handleClick('select', item)}
              >
                {
                    keys.map(key => (
                      <TableCell style={{ cellStyle }}>{item[key]}</TableCell>
                    ))
                }
                <TableCell>
                  {
                  actions.length > 0 && actions.map(action => (
                    <Button
                      variant="contained"
                      onClick={() => handleClick(action, i)}
                      color="secondary"
                    >{action}
                    </Button>
                  ))
                }
                </TableCell>
              </TableRow>
              )) :
            <TableCell colSpan={columns.length} style={styles.error} >{errorMessage}</TableCell>
            }
        </TableBody>
      </Table>
    );
  }
}

TableList.propTypes = {
  data: PropTypes.instanceOf(Array),
  actions: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  keys: PropTypes.instanceOf(Array),
  cellStyle: PropTypes.instanceOf(Object),
  rowStyle: PropTypes.instanceOf(Object),
  bodyStyle: PropTypes.instanceOf(Object),
  handleClick: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default TableList;
