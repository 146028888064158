import React, { Component } from 'react';
import moment from 'moment';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import utils from '../utils/queryBuilder';

const canView = utils.isCurrentUserAuthorizedToView;

const { confirm } = Modal;


export const showStaticRouteChangeErrorDialog = (staticRouteChangeMinTime, staticRouteChangeMaxTime) => {
    confirm({
        title: 'Warning!',
        icon: <ExclamationCircleOutlined />,
        content: `Static route change is not allowed in between ${staticRouteChangeMaxTime} to ${staticRouteChangeMinTime}`,
        okText: 'Ok',
        cancelText: 'No',
        cancelButtonProps: { style: { display: 'none' } },
        centered: true,
        onOk: () => { },
        onCancel: () => {},
    });
};

export const isStaticRouteChangeAllowed = (staticRouteChangeMinTime, staticRouteChangeMaxTime) => {
    let currTime = moment();
    const startTime = moment(staticRouteChangeMaxTime, 'HH:mm:ss');
    const endTime = moment(staticRouteChangeMinTime, 'HH:mm:ss');

    if (canView(['ADMIN'])) {
        return true;
    } else {
        let cycle = true;
        if (moment(currTime).isBefore(startTime)) {
            cycle = true;
        }
        else {
            cycle = false;
        }
        if (moment(endTime).isBefore(startTime)) {
            if (moment(currTime).isAfter(endTime)) {
                return cycle;
            } else {
                return !cycle;
            }
        } else {
            if (moment(currTime).isBefore(endTime)) {
                return cycle;
            } else {
                return !cycle;
            }
        }
    }
};