import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    TableBody,
    TablePagination,
    Typography,
    Table,
    Button,
    TableCell,
    TableRow,
    Grid,
    Drawer,
    Modal,
} from '@material-ui/core';
import TableHeader from '../../Commons/components/TableHeader';
import Colors from '../../../utils/Colors';
import { ROWS_PER_PAGE_OPTIONS } from '../../../constants';
import Lozenge from '@atlaskit/lozenge';
import EditSurvey from "./EditSurvey";
import apiCall, { getOrSetSurveyDataById } from '../../../api/NetworkHandler';
import { Empty } from 'antd';


const surveyColumnData = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Survey ID',
  },
  {
    id: 'question',
    numeric: false,
    disablePadding: true,
    label: 'Question',
  },
  {
    id: 'timeCreated',
    numeric: false,
    disablePadding: true,
    label: 'Created On',
  },
  {
    id: 'timeUpdated',
    numeric: false,
    disablePadding: true,
    label: 'Updated On',
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: true,
    label: 'Priority',
  },
  {
    id: 'choices',
    numeric: false,
    disablePadding: true,
    label: 'No of Choices',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  },
];

const styles = {
  div: {
    display: 'flex',
    flexDirection: 'row wrap',
    width: '100%',
  },
  paperLeft: {
    flex: 5,
    height: '100%',
    marginLeft: 5,
    textAlign: 'center',
    padding: 2,
  },
  paperRight: {
    flex: 2,
    background: '#f9f9f9',
    padding: '20px',
    overflow: 'scroll',
  },
  link: {
    color: Colors.primary,
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: 10,
  },
  tableCell: {
    textAlign: 'center',
  },
//   titleCell: {
//     textAlign: 'left',
//   },
  tableData: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '30px',
    padding: 5,
    fontSize: 15,
  },
  titleRow: {
    borderBottom: 'solid 1px #e0e0e0',
    height: '25px',
    padding: 5,
    // paddingBottom: 20,
    fontSize: 16,
  },
};

var versionDict = {
    "getSurveyRequestId": 0,
}

class SurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSurvey: {},
      rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
      openEditSurvey: false,
      surveyId: null,
    };
  }

  getSurveyDataById = async (surveyId) => {
    try {
        versionDict.getSurveyRequestId += 1;
        let prevRequestId = versionDict.getSurveyRequestId;
        const response = await apiCall.sendRequest('get', getOrSetSurveyDataById(surveyId));
        const { data: { data = {} } = {} } = response;
        if (prevRequestId == versionDict.getSurveyRequestId) {
            this.setState({
                selectedSurvey: data
            });
            this.toggleEditSurvey(true);
        }
    } catch (err) {
        this.setState({
            selectedSurvey: {}
        });
    }
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  handleChangeRowsPerPage = (event) => {
    const { onRowsPerPageChange } = this.props;
    const { value } = event.target;

    onRowsPerPageChange(value);
  };

  calculateChoices = (item) => {
    let count = ["firstChoice", "secondChoice", "thirdChoice", "fourthChoice", "fifthChoice"].filter(k => k in item && item[k] != null).length;
    return count;
  };

  handleOpenEditSurvey = (surveyId) => {
    this.setState({
        surveyId,
    }, () => this.getSurveyDataById(surveyId));
  };

  toggleEditSurvey = (state) => {
    this.setState({
        openEditSurvey: state,
    });
  };

  render() {
    const {
      surveyData, totalElements = 0, rowsPerPage = 20, page = 0, surveyConditionTypes, surveyTypes, onRefresh
    } = this.props;
    const { rowsPerPageOptions, openEditSurvey, selectedSurvey } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={12} style={{ paddingLeft: '15px' }}>
          {surveyData.length > 0 ?
            <Table>
              <TableHeader columns={surveyColumnData} cellStyle={styles.tableCell} />
                <TableBody>
                  {surveyData.map(n => (
                      <TableRow
                          hover
                          // onClick={() => this.handleClick(n)}
                          role="checkbox"
                          tabIndex={-1}
                          key={n.id}
                          selected={!isEmpty(surveyData) && surveyData.id === n.id}
                      >
                          <TableCell component="th" scope="row" padding="default">{n.id}</TableCell>
                          <TableCell component="th" scope="row" padding="none">{n.question}</TableCell>
                          <TableCell component="th" scope="row" padding="none" >{moment(n.timeCreated).format('lll')}</TableCell>
                          <TableCell component="th" scope="row" padding="none" >{moment(n.timeUpdated).format('lll')}</TableCell>
                          <TableCell component="th" scope="row" padding="default">{n.priority}</TableCell>
                          <TableCell component="th" scope="row" padding="default">{this.calculateChoices(n)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">{n.type}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                          <div>
                              <Lozenge appearance={n.active ? "success" : "removed"}>{n.active ? "Active" : "Inactive"}</Lozenge>
                          </div>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                              <Button size="small" color="secondary" variant="outlined" onClick={() => this.handleOpenEditSurvey(n.id)}> Edit </Button>
                          </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
            </Table>
            :
            <div style={{ margin: '0 auto', width: '50%', paddingTop: '10%' }}>
              <Empty />
            </div>
            }
            {(surveyData && surveyData.length > 0) &&
                <TablePagination
                    component="div"
                    count={totalElements}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    onChangePage={this.handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    style={{ paddingRight: window.innerWidth * 0.035 }}
                />
            }
            <Drawer anchor="right" open={openEditSurvey} onClose={() => this.toggleEditSurvey(false)}>
                <EditSurvey
                    onClose={() => this.toggleEditSurvey(false)}
                    selectedSurvey={selectedSurvey}
                    parentComponentId="Edit Survey"
                    surveyConditionTypes={surveyConditionTypes}
                    surveyTypes={surveyTypes}
                    onRefresh={onRefresh}
                    key={selectedSurvey.id}
                />
            </Drawer>
        </Grid>
      </Grid>
    );
  }
}


SurveyList.propTypes = {
  totalElements: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  surveyData: PropTypes.instanceOf(Array),
};

export default SurveyList;

