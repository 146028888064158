import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Typography
} from '@material-ui/core';
import styles from './styles';
import utils from '../../../utils/queryBuilder';
import Lozenge from '@atlaskit/lozenge';
import HistoryIcon from '@material-ui/icons/History';
import { isEmpty } from 'lodash';
import { Tooltip } from 'antd';


const canView = utils.isCurrentUserAuthorizedToView;

const ORDER_ITEMS = [
  {
    text: 'Item',
    param: 'productName',
  },
  {
    text: 'Category',
    param: 'category',
  },
  {
    text: 'Item Type',
    param: 'productItemType',
  },
  {
    text: 'Price',
    param: 'unitPrice',
  },
  {
    text: 'Del/Ord Qty',
    param: 'quantity',
  },
  {
    text: 'Status',
    param: 'status',
  },
  {
    text: 'Complaint',
    param: 'complaintReason',
  },
  {
    text: 'Time Updated',
    param: 'timeUpdated',
  },
  {
    text: '',
    param: 'refund',
  },
];

class OrderInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      curSelectedOrderId: null
    };
  }

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  handleClickLineItemHistory = () => {
    console.log('Line Item History Clicked!!');
    //TODO: Need to implement it later
  };

  handleClickDetail = (lineItem, orderId) => {
    const { onDetailRequest } = this.props;
    this.setState({
      curSelectedOrderId: orderId
    }, () => onDetailRequest(lineItem, orderId));
  };

  truncate = (text, len) => text.slice(0, len) + (text.length > len ? '...' : '')

  renderCOLineItem = (entity, data, isFTHCustomer) => {
    const { openRefundForm, order: { id: orderId } = {}, isDetailedViewOpen, selectedLineItemData } = this.props;
    const buttonColor = (data.id === selectedLineItemData.id && isDetailedViewOpen) ? "white" : !isDetailedViewOpen ? "#3f51b5" : "grey";
    const calculatedUnitPrice = data.unitPrice * (1 - data.discount / 100);

    if (entity.includes('time')) return moment(data[entity]).format('MMM DD, h:mm A');
    else if (entity == "refund") {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {(data.status == "DELIVERED" && canView(["CSR_ADMIN", "CC_MANAGER", "CC_MANAGER_RESTRICTED"]) && data.unitPrice && !['FREEBIE', 'SAMPLE'].includes(data.productItemType) && data.quantity > 0 && !isFTHCustomer) ?
            <div style={{ display: 'flex' }}>
              <Tooltip title={data.id !== selectedLineItemData.id && isDetailedViewOpen ? "close detail panel" : "click to refund"}>
                <button
                  style={{ ...styles.actionButton, marginRight: "10px", color: buttonColor }}
                  onClick={() => { openRefundForm(data) }}
                  disabled={data.id !== selectedLineItemData.id && isDetailedViewOpen}
                >
                  REFUND
                </button>
              </Tooltip>
            </div>
            : <Button style={{ padding: 0, minHeight: 0 }}></Button>
          }
          <Tooltip title={data.id !== selectedLineItemData.id && isDetailedViewOpen ? "close detail panel" : "click to see detail"}>
            <button
              style={{ ...styles.actionButton, color: buttonColor }}
              onClick={() => this.handleClickDetail(data, orderId)}
              disabled={data.id !== selectedLineItemData.id && isDetailedViewOpen}
            >
              DETAIL
            </button>
          </Tooltip>
          {/* <button style={{ ...styles.actionButton, color: "#3f51b5" }} onClick={this.handleClickLineItemHistory}>
            <HistoryIcon style={{ fontSize: "16px" }} />
          </button> */}
        </div>
      );
    }
    else if (entity === 'productItemType') return ['SAMPLE', 'FREEBIE'].includes(data[entity]) ? data[entity] : 'PAID';
    else if (entity === 'quantity') return (`${data.deliveredQuantity} / ${data.quantity}`);
    else if (entity === 'complaintReason' && !data[entity]) return 'NA';
    else if (entity === 'unitPrice') return calculatedUnitPrice.toFixed(2);
    else return (data[entity] || '_');
  };

  renderRow = (header = false, data = {}, comment) => {
    const { order, isDetailedViewOpen, selectedLineItemData } = this.props;
    data.orderId = order.id;
    data.customerId = order.customerId;
    let isFTHCustomer = false;
    if (comment) {
      isFTHCustomer = comment.startsWith('FTH_ORDER');
    }
    const newOrderItems = isDetailedViewOpen ? ORDER_ITEMS.filter(el => el.param !== 'timeUpdated') : ORDER_ITEMS;
    let newCartItemStyle = { ...styles.cartItem };
    if (!isEmpty(selectedLineItemData) && selectedLineItemData.id == data.id) {
      newCartItemStyle = { ...styles.cartItem, backgroundColor: "#194D33", color: "white", padding: "2px 0px" }
    }
    return (
      <div style={header ? { ...newCartItemStyle, ...styles.light } : newCartItemStyle}>
        {newOrderItems.map(el =>
        (header ? (
          <div style={isDetailedViewOpen ? styles.newItems[el.param] : styles.items[el.param]}>{el.text}</div>
        ) : (
          <div style={isDetailedViewOpen ? styles.newItems[el.param] : styles.items[el.param]}>
            {this.renderCOLineItem(el.param, data, isFTHCustomer)}
          </div>
        )))}
      </div>
    )
  };

  renderTable = (d, comment) => {
    return (<div style={styles.orderItems}>
      {this.renderRow(true)}
      {d.map(el => this.renderRow(false, el, comment))}
    </div>
    );
  }

  renderOrderStatus = (status) => {
    switch (status) {
      case "ORDERED": return <Lozenge appearance="moved">{status}</Lozenge>;
      case "IN_DELIVERY": return <Lozenge appearance="inprogress" >{status}</Lozenge>;
      case "CLOSED": return <Lozenge appearance="success" >{status}</Lozenge>;
      case "ON_HOLD": return <Lozenge appearance="new" >{status}</Lozenge>;
      case "CANCELLED": return <Lozenge appearance="removed" >{status}</Lozenge>;
      default: return <small>No Status</small>
    }
  };

  render() {
    const {
      order = {},
      editOrder,
      trackOngoingOrder,
      isViewOnly = false,
      isListItem = false,
      isOngoing = false,
      currency,
      itemIndex,
      onOrderHistoryClick
    } = this.props;
    const { hover, curSelectedOrderId } = this.state;
    const { customerOrderLineItems = [], priority = null, id, status, deliveryDate, totalPrice = 0, comment = '' } = order;

    let orderInfoStyle =
      isListItem && hover ? { ...styles.orderInfoCard, ...styles.hover } : styles.orderInfoCard;
    orderInfoStyle = isOngoing ? { ...orderInfoStyle, ...styles.ongoing } : itemIndex % 2 == 0 ? { ...orderInfoStyle, ...styles.highlight } : orderInfoStyle;
    const orderIdStyle = priority ? { ...styles.orderId, ...styles[priority] } : styles.orderId;
    return (
      <div style={curSelectedOrderId === id ? { ...orderInfoStyle, border: "2px solid black" } : orderInfoStyle} onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: "50%" }}>
            <div style={styles.orderIdbox}>
              <div style={styles.priority}>{priority || 'Order'}</div>
              <h5 style={orderIdStyle}>{id}</h5>
            </div>
            <div style={styles.orderStatus}>
              <Typography variant="caption">Status</Typography>
              {this.renderOrderStatus(status)}
            </div>
            <div style={styles.orderStatus}>
              <Typography variant="caption">Delivery Date</Typography>
              <Typography variant="body2">{deliveryDate}</Typography>
            </div>
            <div style={styles.orderStatus}>
              <Typography variant="caption">Total Order Amount</Typography>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant="caption" style={{ paddingTop: "7px", paddingRight: "2px" }}>{totalPrice ? currency : ''}</Typography>
                <Typography variant="body2" style={{ color: 'green', fontSize: "18px", fontWeight: "bold" }}>{totalPrice ? `${totalPrice.toFixed(2)}` : 'NA'}</Typography>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isViewOnly &&
              isOngoing && <div style={{ ...styles.orderActions, marginRight: "20px" }}>
                <Button
                  onClick={() => trackOngoingOrder(order)}
                  style={styles.btn}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Track
                </Button>
              </div>}
            {/* <Button
              onClick={() => onOrderHistoryClick(id)}
              color="primary"
              variant="outlined"
              size="small"
              style={{ width: "50px", height: "35px" }}
            >
              <HistoryIcon style={{ fontSize: "20px" }} />
            </Button> */}
          </div>
        </div>
        {this.renderTable(customerOrderLineItems, comment)}
      </div>
    );
  }
}

OrderInfo.propTypes = {
  order: PropTypes.instanceOf(Object),
  editOrder: PropTypes.func,
  trackOngoingOrder: PropTypes.func,
  isViewOnly: PropTypes.bool,
  isListItem: PropTypes.bool,
  isOngoing: PropTypes.bool,
};

export default OrderInfo;
