import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Modal,
	Button,
	TextField,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Avatar
} from '@material-ui/core';
import { Grid, Row } from 'react-flexbox-grid';
import CloseIcon from '@material-ui/icons/Close';
import { ColMod } from './lib';
import apiCall, {
	getFetchAllCategories
} from "../api/NetworkHandler";
import { debounce } from 'lodash';


var versionDict = {
	"categoryRequestId": 0
};
class CategorySelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: "",
			suggestions: [],
		};
	}

	searchCategories = debounce(async () => {
		const { searchText = '' } = this.state;
		try {
			versionDict.categoryRequestId += 1;
			let prevRequestId = versionDict.categoryRequestId;
			const response = await apiCall.sendRequest('get', getFetchAllCategories(searchText));
			const { data: { _embedded: { categories = [] } = {} } = {} } = response;
			if (prevRequestId === versionDict.categoryRequestId) {
				this.setState({
					suggestions: categories
				});
			}
		} catch (err) {
			this.setState({
				suggestions: []
			});
		}
	}, 750);

	handleSearch = () => {
		this.searchCategories();
	}

	setEmpty = () => {
		this.props.selectCategory({
			id: "",
			name: "",
		});
	}

	handleModalClose = () => {
		const { handleClose } = this.props;
		this.setState({
			suggestions: [],
			searchText: ''
		}, () => handleClose());
	};

	handleSelectCategory = (selectedCategory) => {
		const { selectCategory } = this.props;
		this.setState({
			suggestions: [],
			searchText: ''
		}, () => selectCategory(selectedCategory));
	};

	render() {
		return (
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={this.props.openSearchBox}
				onClose={this.handleModalClose}
				onBackdropClick={this.handleModalClose}
			>
				<div style={{
					position: 'relative',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					background: 'white',
					width: '70%',
					height: '70%',
				}}>
					<Button style={{ float: 'right' }} onClick={this.handleModalClose} color="secondary" aria-label="close">
						<CloseIcon />
					</Button>
					<Grid style={{ padding: '2em' }}>
						<Row>
							<ColMod xs md lg={15}>
								<Row>
									<Typography variant="body2" color="secondary">
										Search Categories
									</Typography>
								</Row>
								<Row>
									<ColMod lg={6}>
										<TextField
											value={this.state.searchText}
											onChange={(event) => { this.setState({ searchText: event.target.value }); }}
											placeholder="Name"
											fullWidth
										/>
									</ColMod>
									<ColMod lg={3}>
										<Button color="default" variant="contained" onClick={this.handleSearch}>
											Search
										</Button>
									</ColMod>
									<ColMod lg={3}>
										<Button color="secondary" variant="outlined" onClick={this.setEmpty}>
											Set Empty
										</Button>
									</ColMod>
								</Row>
								<Row>
									<ColMod lg={12}>
										<div style={{ overflowX: "auto", overflowY: "auto", height: window.innerHeight * 0.45 }}>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Category</TableCell>
														<TableCell>Action</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{this.state.suggestions.map((suggestion, index) => (
														<TableRow key={index}>
															<TableCell>
																<div style={{ display: 'flex' }}>
																	<Avatar alt={suggestion.name} src={encodeURI(suggestion.imageUrl)} style={{ marginRight: '20px', width: '56px', height: '56px' }} />
																	<Typography style={{ marginTop: '20px' }}>{suggestion.name}</Typography>
																</div>
															</TableCell>
															<TableCell>
																<Button
																	variant="contained"
																	color="primary"
																	onClick={() => this.handleSelectCategory(suggestion)}
																	disabled={!suggestion.parentName}
																>
																	Select
																</Button>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</div>
									</ColMod>
								</Row>
							</ColMod>
						</Row>
					</Grid>
				</div>
			</Modal>
		);
	}
}

CategorySelection.propTypes = {
	openSearchBox: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	selectCategory: PropTypes.func.isRequired,
};

export default CategorySelection;
