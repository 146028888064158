export default {
  card: {
    display: 'flex',
    borderRadius: '0',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    minWidth: '68%',
    padding: '6px 16px',
  },
  actions: {
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%'

  },
  mainInfo: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mainInfoPrimaryItem: {
    flexGrow: 1,
    minWidth: '160px',
    margin: '5px'
  },
  mainInfoPrimaryItemSmall: {
    flexGrow: 1,
    minWidth: '120px',
    margin: '5px'
  },
  mainInfoSecondaryItem: {
    flexGrow: 1,
    minWidth: '180px',
  },
  btn: {
    minWidth: '128px',
    margin: '4px',
  },
};
