import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import {
  Drawer,
  Modal,
  Typography,
  Button,
  Snackbar,
} from '@material-ui/core';
import apiCall, {
  getProductWidgetsList,
  getProductListForWidgets,
  regionsPath,
  downloadProductItemCsvByWidgetId,
  validateProductWidgetItems,
  associateProductItemsByWidgetId,
  postProductToProductWidget
} from '../../../api/NetworkHandler';
import WidgetProductList from '../components/WidgetProductList';
import WidgetProductForm from '../components/WidgetProductForm';
import dataOrderBy from 'lodash/orderBy';
import WidgetSearch from '../components/WidgetSearch';
import { saveAs } from 'file-saver/FileSaver';
import { Table as AntdTable, Alert , Spin} from 'antd';
import CloseIcon from '@material-ui/icons/Close';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const styles = {
  container: {
    margin: 0,
  },
};

const productStatusTypes = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'IN ACTIVE',
};

var versionDict = {
  "widgetItemValidateRequestId": 0,
};

const columnData = [
  {title: "Sort Order", dataIndex: "sortOrder", width: 50},
  {title: "Product Item ID", dataIndex: "productItemId", width: 80},
  {title: "Product Item Name", dataIndex: "name", width: 280},
  {title: "Region", dataIndex: "regions", width: 100},

]

class ProductWidgetsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealForm: false,
      deals: [],
      page: 0,
      rowsPerPage: 50,
      order: 'asc',
      orderBy: 'sortId',
      count: 0,
      region: null,
      isLoading: false,
      editDeal: null,
      filters: {
        query: '',
      },
      productStatus: 'ALL',
      productWidget: null,
      openProductPreview: false,
      uploadedData: [],
      previewData: [],
      openSnackbar: false,
      snackbarMessage: "",
      isStatusLoading: false,
    };
  }

  componentDidMount() {
    this.fetchProductWidgets();
  }

  fetchProductWidgets = async () => {
    const response = await apiCall.sendRequest("get", getProductWidgetsList);
    const { data: { data: productWidgets } } = response;
    const defaultWidget = {
      label: productWidgets[0].title,
      value: productWidgets[0].id,
    }

    const res = await apiCall.sendRequest("get", regionsPath);
    const { data: { _embedded: { regions } } } = res;
    const defaultRegion = {
      label: regions[0].name,
      value: regions[0].id,
    }

    this.setState({
      productWidget: defaultWidget,
      region: defaultRegion,
    }, () => { this.fetchWidgetProducts() })
  }

  fetchWidgetProducts = async () => {
    const { productWidget, region, order, orderBy, filters, productStatus } = this.state;
    try {
      this.toggleProgressBar();
      const response = await apiCall.sendRequest("get", getProductListForWidgets(productWidget.value, region.value));
      let { data: { data } } = response;

      // console.log('data', data, 'filters', filters);

      if (productStatus === 'ACTIVE') {
        data = data.filter(x => x.active);
      } else if (productStatus === 'INACTIVE') {
        data = data.filter(x => !x.active);
      }

      if (filters.query && filters.query.length > 1) {
        data = data.filter(x => x.name.toLowerCase().includes(filters.query));
      }

      // console.log('data1', data)
      let deals;

      if (orderBy === 'sortId') {
        deals = dataOrderBy(data, orderBy, order);
      } else {
        deals = dataOrderBy(data, (deal) => {
          if (orderBy === 'updatedAt') {
            return new Date(deal.timeUpdated);
          }
          return deal.productItem[orderBy];
        }, [order]);
      }
      // console.log('deals1', deals)

      this.setState({
        deals,
        count: deals.length,
      });
      this.toggleProgressBar();
    } catch (error) {
      console.log('error', error);
      this.toggleProgressBar();
    }
  }

  OnSearch = () => {
    this.fetchWidgetProducts();
  }

  onProductWidgetChange = (productWidget) => {
    this.setState({
      productWidget,
    }, () => this.OnSearch());
  }

  onRegionChange = (region) => {
    this.setState({
      region,
    }, () => this.OnSearch());
  }

  addNewProduct = () => {
    this.setState({
      dealForm: true,
    });
  }

  onRequestSort = (property) => {
    if (["actions"].includes(property)) {
      return;
    }
    const { deals, order } = this.state;
    const newOrder = order == 'asc' ? 'desc' : 'asc';
    let sortedData;
    if (property == "sortId") {
      sortedData = dataOrderBy(deals, property, newOrder);
    } else {
      sortedData = dataOrderBy(deals, (deal) => {
        if (property == "updatedAt") {
          return new Date(deal.timeUpdated);
        }
        return deal.productItem[property]
      }, [newOrder]);
    }
    this.setState({
      deals: sortedData,
      order: newOrder,
      orderBy: property
    })
  }

  onClose = () => {
    this.setState({
      dealForm: false,
      editDeal: null,
    });
  }

  onDealEdit = (deal) => {
    this.setState({
      dealForm: true,
      editDeal: deal,
    })
  }

  toggleProgressBar = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading })
  }

  handleQueryChange = (event) => {
    console.log('event', event.target.value);
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value.toLowerCase(),
      },
    }));
  }

  onStatusChange = (value) => {
    this.setState({
      productStatus: value,
    });
  }

  downloadProductItemsCsv = debounce(async () => {
    this.toggleProgressBar();
    const { productWidget } = this.state;
    const { value = '', label = '' } = productWidget;
    const url = downloadProductItemCsvByWidgetId(value);
    const filename = `${label}-${moment().format("DD-MM-YYYY")}.csv`;
    try {
      const response = await apiCall.sendRequest('get', url, null, { Accept: 'text/csv' });
      const { data = {} } = response;
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);
      this.toggleProgressBar();
      this.toggleSnackbar(true, 'Download Complete!');
    } catch (err) {
      console.log(err.response);
      this.toggleProgressBar();
      this.toggleSnackbar(true, 'Download Failed!')
    }
  }, 500);

  toggleProductsPreview = (state) => {
    this.setState({
      openProductPreview: state,
    }, () => this.OnSearch());
  };

  validateWigetProducts = async (productItemIds) => {
    try {
      versionDict.widgetItemValidateRequestId += 1;
      let prevRequestId = versionDict.widgetItemValidateRequestId;
      const response = await apiCall.sendRequest('post', validateProductWidgetItems, productItemIds);
      const { data: { data = [] } = {} } = response;
      if (prevRequestId == versionDict.widgetItemValidateRequestId) {
        this.setState({
          previewData: data.map((el, i) => {return {...el, sortOrder: i + 1}})
        });
        this.toggleProductsPreview(true);
      }
    } catch (err) {
      this.setState({
        previewData: [],
      });
      this.toggleProductsPreview(false);
    }
  };

  handleUploadFile = (uploadedData, fileInfo) => {
    this.setState({
      uploadedData,
    });
    const productItemIds = uploadedData.map(el => el.product_item_id).filter(el => el);
    if (productItemIds.length > 0) {
      this.validateWigetProducts([...new Set(productItemIds)]);
    } else {
      this.toggleSnackbar(true, "Incorrect File, please donwload to proceed!")
    }
    document.getElementById("fileControl").value = '';
  };

  handleUploadError = (err) => {
    console.log(err);
  };

  handleSaveWidgetProducts = debounce (async () => {
    const { previewData, productWidget } = this.state;
    const { value = '' } = productWidget;
    const productItemIds = previewData.map(el => el.productItemId);
    try {
      await apiCall.sendRequest('post', associateProductItemsByWidgetId(value), productItemIds);
      this.toggleProductsPreview(false);
    } catch (err) {
      this.toggleProductsPreview(false);
    }
  }, 500);

  toggleSnackbar = (state, message) => {
    this.setState({
      openSnackbar: state,
      snackbarMessage: message,
    });
  };

  toggleStatusLoading = (state) => {
    this.setState({
      isStatusLoading: state,
    });
  };

  handleStatusChange = debounce (async (checked, selectedDeal) => {
    const { productWidget } = this.state;
    this.toggleStatusLoading(true);
    const postDeal = {
      active: checked,
      id: selectedDeal.id,
      sortId: selectedDeal.sortId,
    }
    try {
      await apiCall.sendRequest("post", postProductToProductWidget(productWidget.value), postDeal);
      this.toggleStatusLoading(false);
      this.fetchProductWidgets();
    } catch (error) {
      this.toggleStatusLoading(false);
      this.fetchProductWidgets();
    }
  }, 500);

  render() {
    const { classes } = this.props;
    const {
      dealForm,
      deals,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      isLoading,
      editDeal,
      productWidget,
      productStatus,
      region,
      openProductPreview,
      previewData,
      snackbarMessage,
      openSnackbar,
      isStatusLoading
    } = this.state;

    const isProductWidgetSelected = productWidget && productWidget.value;
    const isNoData = isEmpty(deals);

    return (
      <React.Fragment>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <WidgetSearch
          onRegionChange={this.onRegionChange}
          region={region}
          onProductWidgetChange={this.onProductWidgetChange}
          onAddNew={this.addNewProduct}
          productWidget={productWidget}
          handleQueryChange={this.handleQueryChange}
          query={this.state.filters.query}
          OnSearch={this.OnSearch}
          productStatusTypes={productStatusTypes}
          currentStatus={productStatus}
          onStatusChange={this.onStatusChange}
          isProductWidgetSelected={isProductWidgetSelected}
          onDownloadProductItemsCsv={this.downloadProductItemsCsv}
          onUploadFile={this.handleUploadFile}
          onUploadError={this.handleUploadError}
        />
        <Spin indicator={antIcon} tip="loading..." spinning={isStatusLoading}>
          <Grid className={classes.container} fluid style={{ height: window.innerHeight * 0.7, overflow: "auto" }}>
            <Row lg={12}>
              <Col lg={12}>
                <WidgetProductList
                  deals={deals}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  order={order}
                  orderBy={orderBy}
                  count={count}
                  onRequestSort={this.onRequestSort}
                  onEdit={this.onDealEdit}
                  isNoData={isNoData}
                  onStatusChange={this.handleStatusChange}
                  isStatusLoading={isStatusLoading}
                />
              </Col>
            </Row>
          </Grid>
        </Spin>
        <Drawer anchor="right" open={dealForm} onClose={this.onClose}>
          <WidgetProductForm
            onClose={this.onClose}
            onRefresh={this.fetchWidgetProducts}
            deal={editDeal}
            productWidget={productWidget}
          />
        </Drawer>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openProductPreview}
          onClose={() => this.toggleProductsPreview(false)}
          disableBackdropClick
        >
          <div style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            height: '70%',
            width: '60%',
            borderRadius: "5px"
            }}
          >
            <div style={{ backgroundColor: 'lightgray', height: window.innerHeight * 0.05, borderRadius: "5px", position: "fixed", width: "100%" }}>
              <div style={{ float: "left", display: "flex", flexDirection: "row", paddingLeft: "50px", paddingTop: "10px" }}>
                <Typography variant="title" color="inherit"> Product Preview </Typography>
              </div>
              <Button style={{ float: 'right' }} onClick={() => this.toggleProductsPreview(false)} color="secondary" aria-label="close">
                <CloseIcon />
              </Button>
            </div>
            <div style={{ paddingLeft: "10px", marginTop: window.innerHeight * 0.05, height: "82%", position: "absolute", overflow: "auto", width: "100%" }}>
              {previewData.length === 0 ? <div style={{ marginTop: "20px", marginRight: "10px" }}>
                <Alert
                  message="Inactive Products Found!"
                  description="Please upload active products"
                  type="error"
                  showIcon
                />
              </div> :
              <AntdTable columns={columnData} dataSource={previewData} size="middle" sticky pagination={false} />}
            </div>
            {previewData.length > 0 && <div style={{ position: "fixed", bottom: 0, right: 0 }}>
              <div style={{ paddingRight: '45px', paddingTop: '20px', paddingBottom: '15px' }}>
                <Button onClick={this.handleSaveWidgetProducts} variant="contained" color="primary">
                  Save
                </Button>
              </div>
          </div>}
          </div>
        </Modal>
        <Snackbar
          open={openSnackbar}
          onClose={() => this.toggleSnackbar(false)}
          message={snackbarMessage}
          style={{ marginBottom: "20px" }}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ProductWidgetsContainer);
