import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NavBar from '../../../components/NavBar';
import ProgressBar from '../../../components/States/ProgressBar';
import Drawer from '@material-ui/core/Drawer';
import apiCall, { getAllDealOfTheDay, regionsPath } from '../../../api/NetworkHandler';
import DealOfTheDayProductList from '../components/DealOfTheDayList';
import DealOfTheDayForm from '../components/DealOfTheDayForm';
import dataOrderBy from 'lodash/orderBy';
import DealSearch from '../components/DealSearch';

const styles = {
  container: {
    margin: 0,
  },
};

const productStatusTypes = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'IN ACTIVE',
};

class DealOfTheDayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealForm: false,
      deals: [],
      page: 0,
      rowsPerPage: 50,
      order: 'asc',
      orderBy: 'sortId',
      count: 0,
      region: null,
      isLoading: false,
      editDeal: null,
      filters: {
        query: '',
      },
      productStatus: 'ALL',
      isNoData: false,
    };
  }

  componentDidMount() {
    this.fetchRegions();
  }

  fetchRegions = async () => {
    const response = await apiCall.sendRequest("get", regionsPath);
    const { data: { _embedded: { regions } } } = response;
    const defaultRegion = {
      label: regions[0].name,
      value: regions[0].id,
    }

    this.setState({
      region: defaultRegion,
    }, () => { this.fetchDealOfTheDay() })
  }

  fetchDealOfTheDay = async () => {
    const { region, order, orderBy, productStatus, filters } = this.state;
    const dealUrl = `${getAllDealOfTheDay}?regionId=${region.value}`;
    this.toggleProgressBar();
    try {
      const response = await apiCall.sendRequest('get', dealUrl);
      let { data } = response;

      if (productStatus === 'ACTIVE') {
        data = data.filter(x => x.active);
      } else if (productStatus === 'INACTIVE') {
        data = data.filter(x => !x.active);
      }

      if (filters.query && filters.query.length > 1) {
        data = data.filter(x => x.productItem.name.toLowerCase().includes(filters.query));
      }

      let deals;

      if (orderBy === 'sortId') {
        deals = dataOrderBy(data, orderBy, order);
      } else {
        deals = dataOrderBy(data, (deal) => {
          if (orderBy === 'updatedAt') {
            return new Date(deal.timeUpdated);
          }
          return deal.productItem[orderBy];
        }, [order]);
      }
      const isNoData = !!(deals && deals.length);
      this.setState({
        deals,
        count: deals.length,
        isNoData: !isNoData,
      });
      this.toggleProgressBar();
    } catch (error) {
      this.toggleProgressBar();
      this.setState({ isNoData: true });
    }
  }

  OnSearch = () => {
    this.fetchDealOfTheDay();
  }

  onRegionChange = (region) => {
    this.setState({
      region,
    });
  }

  addNewProduct = () => {
    this.setState({
      dealForm: true,
    });
  }

  onRequestSort = (property) => {
    if (["actions"].includes(property)) {
      return;
    }
    const { deals, order } = this.state;
    const newOrder = order == 'asc' ? 'desc' : 'asc';
    let sortedData;
    if (property == "sortId") {
      sortedData = dataOrderBy(deals, property, newOrder);
    } else {
      sortedData = dataOrderBy(deals, (deal) => {
        if (property == "updatedAt") {
          return new Date(deal.timeUpdated);
        }
        return deal.productItem[property]
      }, [newOrder]);
    }
    this.setState({
      deals: sortedData,
      order: newOrder,
      orderBy: property
    })
  }

  onClose = () => {
    this.setState({
      dealForm: false,
      editDeal: null,
    });
  }

  onDealEdit = (deal) => {
    this.setState({
      dealForm: true,
      editDeal: deal,
    })
  }

  toggleProgressBar = () => {
    const { isLoading } = this.state;
    this.setState({ isLoading: !isLoading })
  }

  handleQueryChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        query: value.toLowerCase(),
      },
    }));
  }

  onStatusChange = (value) => {
    this.setState({
      productStatus: value,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      dealForm,
      deals,
      rowsPerPage,
      page,
      order,
      orderBy,
      count,
      isLoading,
      editDeal,
      region,
      productStatus,
      isNoData,
      filters,
    } = this.state;

    const { query = '' } = filters;
    return (
      <React.Fragment>
        <NavBar />
        <ProgressBar isLoading={isLoading} />
        <DealSearch
          onRegionChange={this.onRegionChange}
          onAddNew={this.addNewProduct}
          region={region}
          handleQueryChange={this.handleQueryChange}
          query={this.state.filters.query}
          OnSearch={this.OnSearch}
          productStatusTypes={productStatusTypes}
          currentStatus={productStatus}
          onStatusChange={this.onStatusChange}
        />
        <Grid className={classes.container} fluid>
          <Row lg={12}>
            <Col lg={12}>
              <DealOfTheDayProductList
                deals={deals}
                rowsPerPage={rowsPerPage}
                page={page}
                order={order}
                orderBy={orderBy}
                count={count}
                onRequestSort={this.onRequestSort}
                onEdit={this.onDealEdit}
                isLoading={isLoading}
                isNoData={isNoData}
                searchTerm={query}
              />
            </Col>
          </Row>
        </Grid>
        <Drawer anchor="right" open={dealForm} onClose={this.onClose}>
          <DealOfTheDayForm
            onClose={this.onClose}
            onRefresh={this.fetchDealOfTheDay}
            deal={editDeal}
          />
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DealOfTheDayContainer);
