import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, get, debounce } from 'lodash';
import { Typography, Button } from '@material-ui/core';
import DetailModal from '../DetailModal';
import QuickSearch from '../QuickSearch';
import apiCall, { searchProductsPath, getCategoryPath, searchProductsByRegionId } from '../../../api/NetworkHandler';
import styles from './styles';
import ProgressBar from '../../../components/States/ProgressBar';
import { message } from 'antd';

var versionDict = {
  "productRequestId": 0,
};
class ProductSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      data: [],
      selectedObj: {},
      loadingData: false,
      emptyResult: false,
    };
  }

  onProductSearch = (e) => {
    const searchTerm = e.target.value;
    if (!isEmpty(searchTerm)) {
      this.setState({
        query: searchTerm,
        data: [],
      }, () => this.fetchProducts());
    } else {
      this.setState({
        query: '',
        data: [],
      });
    }
  };

  onProductSearchClear = () => {
    this.setState({
      query: '',
      data: [],
    });
  };

  onSelectData = (obj) => {
    this.setState({ selectedObj: obj });
    // const { name } = obj;
    // const productName = name.split('-')[0];
    // apiCall
    //   .sendRequest('get', getCategoryPath(productName))
    //   .then((res) => {
    //     const { data } = res;
    //     this.setState({ selectedObj: { ...obj, categoryId: data.data } });
    //   })
    //   .catch((error) => {
    //     console.warn(error);
    //   });
  };

  getTableConfig = () => ({
    table: [
      {
        label: '',
        pick: 'productItemImages[0].imageUrl',
        image: true,
      },
      {
        label: 'Item Name',
        pick: 'name',
      },
      {
        label: 'Quantity',
        pick: 'unitMeasure',
      },
      {
        label: 'Price',
        pick: 'unitPrice',
      },
    ],
  });

  fetchProducts = debounce( async () => {
    const { query } = this.state;
    const { defaultAddress } = this.props;
    if (!defaultAddress) { 
      message.info("Please update default address !!");
      return;
     } 
     else if (!defaultAddress.regionId) {
      message.info("Please update region Id to the default address !!");
      return;
     }
    const { regionId } = defaultAddress;
    this.setState({
      loadingData: true,
    });
    try {
      versionDict.productRequestId += 1;
      let prevRequestId = versionDict.productRequestId;
      const response = await apiCall.sendRequest('get', searchProductsByRegionId(query, regionId));
      const { data } = response.data;
      if (data.length == 0) { 
        this.setState({ emptyResult: true }); 
      } else {
        this.setState({ emptyResult: false })
      }
      if (versionDict.productRequestId == prevRequestId) {
        this.setState({
          data: data,
          loadingData: false,
        });
      }
    } catch (error) {
      this.setState({
        data: [],
        loadingData: false,
      });
    }
  }, 750);

  cancelSelect = () => {
    this.setState({ selectedObj: {} });
  };

  updateSelect = () => {
    this.props.onSelectLineItem(this.state.selectedObj);
  };

  getData = (data) => {
    let modifiedData = [];
    data.map(item => {
      item.productItems.map(product => {
        modifiedData.push({...product, categoryId: item.category.id, unitPrice: product.offerPrice ? product.offerPrice : product.sellingPrice})
      });
    });
    return modifiedData;
  }

  render() {
    const { onClose, defaultAddress, currency } = this.props;
    const {
      query, data, selectedObj, loadingData, emptyResult, 
    } = this.state;
    const image = get(selectedObj, 'productItemImages[0].imageUrl');
    let modifiedData = this.getData(data);
    return (
      <DetailModal title="Find Product" unMountComponent={onClose}>
        <ProgressBar isLoading={loadingData}/>
        {!isEmpty(selectedObj) && (
          <div style={styles.selectedItemBox}>
            <Typography style={styles.subHeader}>Selected Item</Typography>
            <div style={styles.selectedItem}>
              <div style={styles.itemInfo}>
                {image && (
                  <div style={styles.itemImage}>
                    <img src={image} alt="" height="100" width="100" />
                  </div>
                )}
                <div style={styles.itemDesc}>
                  <div style={styles.itemName}>{selectedObj.name}</div>
                  {/* <div style={styles.itemDetails}>Rs. {selectedObj.productItems[0].offerPrice ? selectedObj.productItems[0].offerPrice : selectedObj.productItems[0].sellingPrice}</div> */}
                  {/* <div style={styles.itemDetails}>Rs. {selectedObj.productItems[0].sellingPrice}</div> */}
                  <div>
                    <span style={selectedObj.offerPrice ? styles.itemDetailsOfferPrice : styles.itemDetails}>
                      MRP: {currency} {selectedObj.sellingPrice}
                    </span> &nbsp; &nbsp;
                    {selectedObj.offerPrice && <span style={styles.itemDetails}>Price: {currency} {selectedObj.offerPrice}</span>}
                  </div>
                </div>
              </div>
              <div style={styles.actions}>
                <Button
                  style={styles.btn}
                  onClick={this.cancelSelect}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  style={styles.btn}
                  onClick={this.updateSelect}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        )}
        {isEmpty(selectedObj) && (
          <QuickSearch
            label="Search product name"
            query={query}
            onSearchChange={this.onProductSearch}
            onClearSearch={this.onProductSearchClear}
            data={modifiedData}
            onSelectRow={this.onSelectData}
            loadingData={loadingData}
            tableConfig={this.getTableConfig()}
            emptyResult={emptyResult}
          />
        )}
      </DetailModal>
    );
  }
}

ProductSearch.propTypes = {
  onClose: PropTypes.func,
  onSelectLineItem: PropTypes.func,
};

const mapStateToProps = state => ({
  currency: state.currency,
});

export default connect(mapStateToProps, null)(ProductSearch);
